import React from 'react';
import RegisterAttendee from '../RegisterAttendee';
import { Link } from 'react-router-dom';

class Razorpay extends React.Component {
    constructor(props) {
        super(props);
        //console.log("razorpay");
        this.state = {
            amount: global.$regamount,
            paid: false,
        };
        this.openCheckout = this.openCheckout.bind(this);
        this.hideRegisterButtonFromRazorpay = this.hideRegisterButtonFromRazorpay.bind(this);
    }


    openCheckout() {
        var userinfo = global.$regattendeeinfo;

        let options = {
            "key": `${global.$registrationform.payment.razorpay.clientkey}`,   //Apps4org - Live Key
            "amount": this.state.amount * 100, // 2000 paise = INR 20, amount in paisa  // LIVE
            //"amount": 1*100, // 2000 paise = INR 20, amount in paisa    // TEST
            "name": userinfo.fname + " " + userinfo.lname,
            "description": "",
            "image": "",
            'handler': this.paymentCapture.bind(this),
            // "handler": function (response) {
            //    //console.log(response);
            //     if(response.razorpay_payment_id != null)
            //     {
            //         global.$paymentid = response.razorpay_payment_id;
            //         //this.state.paid = true;
            //     }
            // },
            "prefill": {
                "name": userinfo.fname + " " + userinfo,
                "email": userinfo.email
            },
            "notes": {
                "address": userinfo.street + "," + userinfo.city + "," + userinfo.state + "," + userinfo.zip + "," + userinfo.country
            },
            "theme": {
                "color": `${global.$registrationform.payment.razorpay.color}`
            }
        };

        let rzp = new window.Razorpay(options);
        rzp.open();
    }
    paymentCapture(response) {
        //console.log("payment id " + response);
        global.$paymentid = response.razorpay_payment_id;
        this.setState({ paid: true });
        // this.state.paid = true;
    }
    render() {
        //console.log("Razor Pay")
        return (
            <div>
                <div className="payment-summary-div-razorpay">
                    <p className="payment-amount">Total Amount = &#8377;{global.$regamount}</p>
                </div>

                {
                    this.state.paid == true ?
                        (
                            <div className="main">
                                <RegisterAttendee regattendeestatus={this.props.regattendeestatus} reggueststatus={this.props.reggueststatus} type={this.props.type} hideRegisterButtonFromRazorpay={this.hideRegisterButtonFromRazorpay} />
                            </div>
                        )
                        :
                        (
                            <div className="razorpay-btn-div">
                                <button onClick={this.openCheckout} className="razorpay-pay-button">Pay</button>
                            </div>

                        )
                }
            </div>
        );

    }

    hideRegisterButtonFromRazorpay() {
        this.props.hideRegisterButton()
    }
}
export default Razorpay;


