import React from 'react';
import { Row, Container, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { API, Analytics } from "aws-amplify";
import { mdiAccount, mdiEmail, mdiPhone, mdiMessage } from '@mdi/js';
import Icon from '@mdi/react';
var serviceNames = require('../ServiceNameMapper');

class FooterContactForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            code: props.code,
            s3: props.s3,
            activeIndex: 0,
            name: '',
            email: '',
            phone: '',
            message: '',
            note: '',
            clearbuttonstyles: global.$contact.buttons.clear.styles,
            submitbuttonstyles: global.$contact.buttons.submit.styles,
            pagestyle: global.$contact.pagestyle,
            formstyles: global.$contact.form.styles
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClear = this.handleClear.bind(this);
        //console.log(this.state.code)
    }

    render() {
        //console.log("footer contact form");
        var styles = global.$contact.footer.styles;
        return (
            <div>
                <div>
                    <Row>
                        <Col md={11} className="offset-md-1">
                            <div className="footer-contact-info" style={styles.maindiv}>
                                <div dangerouslySetInnerHTML={{ __html: global.$contact.footer.header }}></div>
                                <form className="footer-contact-form">
                                    <FormGroup>
                                        <Label className="label" for="name" style={styles.label}><Icon path={mdiAccount} size={0.9} />Name*</Label>
                                        <Input type="text" name="name" id="name" value={this.state.name || ''} onChange={this.handleInputChange} required />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label className="label" for="enail" style={styles.label}><Icon path={mdiEmail} size={0.9} />Email*</Label>
                                        <Input type="text" name="email" id="email" value={this.state.email || ''} onChange={this.handleInputChange} required />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label className="label" for="message" style={styles.label}><Icon path={mdiMessage} size={0.9} />Message*</Label>
                                        <Input type="textarea" className="footer-contact-textarea" name="message" id="message" rows="1" cols="50" value={this.state.message || ''} onChange={this.handleInputChange} required />
                                    </FormGroup>
                                    <FormGroup>
                                        <button type="button" className="btn footer-contact-button" style={styles.submitbutton} onClick={this.handleSubmit}>{global.$contact.footer.buttontext}</button>
                                    </FormGroup>
                                </form>
                                <h6 style={styles.message} id="note">{this.state.note}</h6>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        );

    }

    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        let value = target.value

        //console.log( "Target = " + name + ", Value = " + value);
        this.setState({ [name]: value });
    }

    validateInputs() {
        if (this.state.name === "" || this.state.email === "" || this.state.message === "") {
            this.state.note = "Enter all the required fields"
            this.forceUpdate();
            return false;
        }
        return true;
    }

    handleSubmit(event) {
        event.preventDefault();
        if (this.validateInputs()) {
            this.submitRegistration(event);
        }
    }

    handleClear(event) {
        event.preventDefault();
        this.clearInputs();
        this.state.note = '';
    }

    clearInputs() {
        this.state.name = "";
        this.state.email = "";
        this.state.phone = "";
        this.state.message = "";
        this.state.note = "";
        this.forceUpdate();
    }
    submitRegistration(event) {

        let apiName = serviceNames.getWebsiteService();
        let path = ''; //replace this with the path you have configured on your API
        var date = new Date();
        var longFormat = date / 1000;
        var today = new Date();

        var date = today.toLocaleString();

        //console.log(date);
        if (this.state.phone == "" || this.state.phone == null) {
            this.state.phone = " ";
        }

        let myInit =
        {
            body:
            {
                'command': 'postContacts',
                'code': global.$code,
                'id': longFormat.toString(),
                'name': this.state.name,
                'phone': this.state.phone,
                'email': this.state.email,
                'subject': this.state.message,
                'created': date
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        this.setState({ disabled: true });
        //console.log(myInit);
        ////console.log(this.state.code + "," + this.state.message + "," + this.state.type );
        API.post(apiName, path, myInit).then(response => {
            ////console.log ("response.data ===> " + response.data + "response.err ===> " + response.err);
            this.setState({ disabled: false });

            if (response.err == null) {
                document.getElementById('note').innerHTML = "<p>Message submitted successfully</p>";
                this.state.note = "Message submitted successfully"
                //console.log("postMessage - Success!!");
                this.clearInputs();
            }
            else {
                this.state.note = "Error!! Unable to submit message. Please try again.";
                this.setState({ isLoading: false });
                this.forceUpdate();
            }

        }).catch(error => {

            //console.log(error.response)
            this.setState({ disabled: false });
            this.setState({ isLoading: false });
        });
    }

}
export default FooterContactForm;