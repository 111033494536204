import React from 'react';
import { API, Analytics } from 'aws-amplify';
import { Link } from 'react-router-dom';
import Sponsors from '../Components/Sponsors';
import { Row, Col } from 'reactstrap';
import SessionComponents from './SessionComponents';
import "./Schedule.css";
import Stopwatch from './Stopwatch';
import UpdatePoints from '../Points/UpdatePoints.jsx';
import Icon from '@mdi/react';
import { mdiBackspace, mdiArrowLeft, mdiArrowLeftCircle } from '@mdi/js';

const moment = require('moment-timezone');
/*
---------------------------  Session Flow (Schedule & Posters) ----------------------------------

- SessionInfo   - Transition
                - Back button
                - Sponsors
                - SessionComponents     - SessionWithtabs       - SessionVideos
                                                                - SessionHandouts
                                                                - ScheduleSpeakers
                                                                - SessionTabs
                                                                - SessionCreditTimer


                                        - SessionWithoutTabs    - SessionVideos
                                                                - SessionHandouts
                                                                - ScheduleSpeakers

 */
var timer;
class SessionInfo extends React.Component {
    constructor(props) {
        super(props);
        //console.log(props.match.params.value);
        var [sessionId, stag, activeTab, type] = props.match.params.value.split("==");

        var timerdivstyle = '';

        if (global.$session.styles.sessioncountdowndiv == null) {
            timerdivstyle = {};
        }
        else {
            timerdivstyle = global.$session.styles.sessioncountdowndiv;
        }

        var sessioninfostyle = ''
        if (global.$session.styles.sessioninfo == null) {
            sessioninfostyle = {}
        }
        else {
            sessioninfostyle = global.$session.styles.sessioninfo;
        }
        var backarraowstyles = '';
        if (global.$session.styles.backbutton == null) {
            backarraowstyles = { paddingRight: "10px" };
        }
        else {
            backarraowstyles = global.$session.styles.backbutton
        }

        this.state = {
            code: global.$code,
            s3: global.$s3,
            bucket: global.$s3bucket,
            dataloaded: false,
            sessionid: sessionId,
            stag: stag,
            activetab: activeTab,
            showfeedback: "Y",
            begindate: '',
            enddate: '',
            backbuttonstyle: global.$settings.styles.backbutton,
            type: type,
            sessiontitle: '',
            begindate: '',
            enddate: '',
            titlestyle: global.$session.styles.sessiontitle,
            timestyle: global.$session.styles.sessiontime,
            showtime: ' ',
            timerdivstyle: timerdivstyle,
            divstyles: sessioninfostyle,
            backarraowstyles: backarraowstyles,
            curenttime: '',
            sessionstarttime: '',
            sessionendtime: '',
        };

       //console.log(this.state);
        timer = setInterval(() => {

            var currentdate = moment().tz(`${global.$settings.timezone}`).format('L LTS')
            var sessionstarttime = Date.parse(global.$sessionobj.BEGIN); // 12/05/2020 05:30 PM
            var sessionendtime = Date.parse(global.$sessionobj.END); // 12/05/2020 05:30 PM

            //console.log(`SessionInfo: Timer: current = ${currentdate}, start = ${global.$sessionobj.BEGIN}, end = ${global.$sessionobj.END}`);

            //console.log(currentdate);
            var mode = global.$mode;
            if (mode == null || mode == '') {
                mode = 'live';
            }

            if (mode == "live") {
                //Nothing - Just retunr the current date
            }
            else if (mode == "preview") {
                // Make current date/time as same as session begin date/time
                currentdate = global.$sessionobj.BEGIN;
            }
            else if (mode == "dryrun") {

                var eventdate = moment(sessionstarttime).format('L');
                var currenttime = moment().tz(`${global.$settings.timezone}`).format('LTS');
                currentdate = eventdate + " " + currenttime

                //console.log(`SessionInfo: Timer: Adjustred Current = ${currentdate}, start = ${sessionstarttime}, end = ${sessionendtime}`);
            }
            else if (mode == "ondemand") {   //Add 365 days to current date                
                var postdate = moment().add(365, 'days').calendar();
                var posttime = moment().tz(`${global.$settings.timezone}`).format('LTS');
                currentdate = postdate + " " + posttime;
            }

            var curenttime = Date.parse(currentdate);  // Date.Parse() converts the date into milliseconds
            global.$timezonecurrenttime = curenttime;

            //console.log(`SessionInfo: Timer: current = ${curenttime}, start = ${sessionstarttime}, end = ${sessionendtime}`);

            if (curenttime >= sessionstarttime && curenttime <= sessionendtime) {
                //console.log('SessionInfo.isSessionLive - Live');

                this.getSession();

                //Stop the timer once session is transitioned
                clearInterval(timer)
            }

        }, 2000);
        this.handleScheduleTab = this.handleScheduleTab.bind(this);
    }

    handleScheduleTab() {

        if (this.state.stag.includes("dec9")) {
            global.$activeScheduleTab = "December 9";
            global.$activeScheduleSubTab = this.state.activetab;
        }
        else if (this.state.stag.includes("dec10")) {
            global.$activeScheduleTab = "December 10";
            global.$activeScheduleSubTab = this.state.activetab;
        }
        else if (this.state.stag.includes("dec11")) {
            global.$activeScheduleTab = "December 11";
            global.$activeScheduleSubTab = this.state.activetab;
        }
        else if (this.state.stag.includes("dec12")) {
            global.$activeScheduleTab = "December 12";
            global.$activeScheduleSubTab = this.state.activetab;
        }
        else {
            global.$activeScheduleTab = this.state.activetab;
        }
        //console.log(global.$activeScheduleTab);
    }

    componentDidMount() {
        //console.log("componentDidMount")
        window.scrollTo(0, 0)
    }

    componentWillUnmount() {
        //console.log("componentWillUnmount")
        clearInterval(timer)
    }

    render() {

        //console.log("SessionDetails: render")
        //console.log(this.state.showfeedback);
        //console.log(this.state)
        Analytics.record({ name: 'PageView', attributes: { name: 'eventsites-' + this.state.code + '-sessiondetails' } });
        if (this.state.dataloaded) {
            return (
                <div className="session-info">
                    <div style={this.state.divstyles}>
                        <br />
                        {
                            this.state.type.includes("schedule") ?
                                (
                                    <div>
                                        <Row>
                                            <Col md={8}>
                                                {
                                                    global.$sessionobj.STAG != "ondemand" ?
                                                        (
                                                            this.state.curenttime >= this.state.sessionstarttime && this.state.curenttime <= this.state.sessionendtime ?
                                                                (
                                                                    <p style={this.state.timestyle} className="session-date">{this.state.begindate} - {this.state.enddate}<p style={{ color: "white" }} className="live-text-session">LIVE</p></p>
                                                                )
                                                                :
                                                                (
                                                                    <p style={this.state.timestyle} className="session-date">{this.state.begindate} - {this.state.enddate}</p>
                                                                )
                                                        )
                                                        :
                                                        ('')
                                                }
                                                <div className="session-back-title">
                                                    <Link to={`/Schedule`} onClick={this.handleScheduleTab} style={this.state.backbuttonstyle} className="session-back-button"><Icon path={mdiArrowLeftCircle} size={1.2} /></Link>
                                                    <br />
                                                    <div className="session-date-time">
                                                        <div style={this.state.titlestyle} className="session-title">{this.state.sessiontitle}</div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <div>
                                                    <div className="session-timer-display" style={this.state.timerdivstyle}>
                                                        <Stopwatch type={"schedule"} />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                )
                                :
                                ('')
                        }
                        {
                            this.state.type.includes("posters") ?
                                (
                                    <div>
                                        <div className="session-back-title">
                                            <Link to={`/Posters`} onClick={this.handleScheduleTab} style={this.state.backbuttonstyle} className="session-back-button"><Icon path={mdiArrowLeftCircle} size={1.2} /></Link>
                                            <div style={this.state.titlestyle} className="session-title">{this.state.sessiontitle}</div>
                                        </div>
                                        {/*<p style={this.state.timestyle} className="session-date">{this.state.begindate} - {this.state.enddate}</p>*/}
                                    </div>
                                )
                                :
                                ('')
                        }
                        {
                            global.$points.sessionattend != null && global.$points.sessionattend.enable.includes("Y") ?
                                (
                                    <UpdatePoints session={global.$sessionobj} component="session" area="sessionattend" points={global.$points.sessionattend.points} />
                                )
                                :
                                ('')
                        }
                        <SessionComponents
                            session={global.$sessionobj}
                            showfeedback={this.state.showfeedback}
                            stag={this.state.stag}
                            activetab={this.state.activetab}
                        />
                        <br />
                    </div>
                    <hr />
                    <br />
                    {
                        global.$sponsorscrollonsessiondetails.includes('Y') ?
                            (
                                <Sponsors />
                            )
                            :
                            ('')
                    }
                    <br />
                </div>
            )
        }
        else {
            this.getSession()
            return (
                <div>
                    <Row>
                        <Col md={6} className="offset-5">
                            <div className="loading-div">
                                <div className="loading-text">Loading....</div>
                                {/*<ReactSpinner/>*/}
                            </div>
                        </Col>
                    </Row>
                </div>)
        }

    }
    getSession() {
        //////console.log(global.$schedulejson);

        var sessionObj = global.$schedulejson.find(globalSession => (globalSession.SID === this.state.sessionid) && (globalSession.STAG === this.state.stag))

        //////console.log(sessionObj);
        global.$sessionobj = sessionObj;
        var array = [];
        //console.log(sessionObj);

        if (sessionObj.T4 !== " " || sessionObj.T4 !== "") {
            array = sessionObj.T4;
            array = array.split(";");
            array = array[0];
            this.getResponses(array)
        }
        var d1 = new Date(sessionObj.BEGIN);
        var d2 = new Date(sessionObj.END);

        var beginDateFormatter = moment(d1).format('MMM D, h:mm A');
        var endDateFormatter = moment(d2).format('h:mm A');

        var currentdate = this.getAdjustedCurrentDate();
        var curenttime = Date.parse(currentdate);
        var sessionstarttime = Date.parse(sessionObj.BEGIN);
        var sessionendtime = Date.parse(sessionObj.END);

        this.setState({
            sessiontitle: sessionObj.TITLE,
            begindate: beginDateFormatter,
            enddate: endDateFormatter,
            curenttime: curenttime,
            sessionstarttime: sessionstarttime,
            sessionendtime: sessionendtime,
            dataloaded: true
        });
    }
    getAdjustedCurrentDate() {
        var mode = global.$mode;
        if (mode == null || mode == '') {
            mode = 'live';
        }

        var currentdate = moment().tz(`${global.$settings.timezone}`).format('L LTS');

        if (mode == "live") {
            //Nothing - Just return the current date
        }
        else if (mode == "preview") {
            // Make current date/time as same as session begin date/time
            currentdate = global.$sessionobj.BEGIN;
        }
        else if (mode == "dryrun") {
            var eventdate = moment(global.$sessionobj.BEGIN).format('L');
            var currenttime = moment().tz(`${global.$settings.timezone}`).format('LTS');
            currentdate = eventdate + " " + currenttime
        }
        else if (mode == "ondemand") {   //Add 365 days to current date

            var postdate = moment().add(365, 'days').calendar();
            var posttime = moment().tz(`${global.$settings.timezone}`).format('LTS');
            currentdate = postdate + " " + posttime;
        }

        return currentdate;
    }

    getResponseTableObjects(objs) {
        //console.log(objs)
        var tableObjs = []
        for (var i = 0; i < objs.length; i++) {

            var responseObj = objs[i]
            //console.log(objs)
            if (this.state.sessionid == responseObj.sessionid.S) {
                //console.log("true1")
                //console.log(responseObj.userid.S);
                //console.log(global.$loginid);
                global.$showfeedback = "Y";
                if (global.$loginid == responseObj.userid.S) {
                    //console.log("true2");
                    this.setState({ showfeedback: "N" });
                    //this.state.showfeedback = "N";
                    //global.$showfeedback = "N";
                }
            }
            //console.log(this.state.showfeedback);
        }
        //console.log(this.state.showfeedback);
        return tableObjs

    }
    getResponses(item) {
        let apiName = 'PollingService';
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'code': this.state.code,
                'command': 'getPollResponses',
                'sessionid': this.state.sessionid,
                'questionid': item,
                'responsetype': "SessionFeedback"
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //console.log(myInit);

        API.post(apiName, path, myInit).then(response => {
            //console.log(response);

            if (response.err == null && response.data != null) {
                //console.log(response.data.Items);
                var responses = this.getResponseTableObjects(response.data.Items)
            }
            else {
                //console.log("getReportsTableObjects - failed!!");
                //console.log(response.err);
            }

        }
        ).catch(error => {
            //console.log(error)
        });
    }

}
export default SessionInfo;


