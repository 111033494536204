import CommonConstants from "../../Common/CommonConstants";

function isTravconExhibitor(code) {
    var result = false;
    if (code == "travcon2022exhibitor" || code == "travcon2023exhibitor" || code == "travcon2024exhibitor") {
        result = true;
    }
    return result;
}
function isTravconExhibitor2022(code) {
    var result = false;
    if (code == "travcon2022exhibitor") {
        result = true;
    }
    return result;
}
function isTravconExhibitor2023(code) {
    var result = false;
    if (code == "travcon2023exhibitor") {
        result = true;
    }
    return result;
}
function isTravconExhibitor2024(code) {
    var result = false;
    if (code == "travcon2024exhibitor") {
        result = true;
    }
    return result;
}

function isTravconAttendee(code) {
    var result = false;
    if (code == "travcon2022" || code == "travcon2023" || code == "travcon2024") {
        result = true;
    }
    return result;
}

function isMetro(code) {
    if (code == "metro2023convention") {
        return true;
    }
    else {
        return false;
    }
}
function isMetromeeting(code) {
    if (code == "metro2023meeting") {
        return true;
    }
    else {
        return false;
    }
}

function CommonStrings(inputs) {
    var commonstrings = "";
    var commonstringsjson = ''
    var repcounts = 0;

    if (isTravconExhibitor2022(global.$code)) {
        commonstrings = CommonConstants.travconExhibitor2022RegItems;
        repcounts = CommonConstants.travcon2022exhibitorreps
    }
    else if (isTravconExhibitor2023(inputs)) {
        commonstrings = CommonConstants.travconExhibitor2023RegItems;
        repcounts = CommonConstants.travcon2023exhibitorreps;
        commonstringsjson = CommonConstants.travconExhibitor2023RegItemsjson;
    }
    else if (isTravconExhibitor2024(inputs)) {
        commonstrings = CommonConstants.travconExhibitor2024RegItems;
        commonstringsjson = CommonConstants.travconExhibitor2024RegItemsjson;
        repcounts = CommonConstants.travcon2024exhibitorreps
    }
    
    return {commonstrings,repcounts,commonstringsjson};
}


export default { isTravconExhibitor, isTravconAttendee, isMetro, isTravconExhibitor2022, isTravconExhibitor2023,isMetromeeting,isTravconExhibitor2024,CommonStrings }