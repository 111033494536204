import React from 'react';
import { Row, Col, Input } from 'reactstrap';
import { API, Analytics } from 'aws-amplify';
import './Speakers3.css';
import SpeakerInfo3 from './SpeakerInfo3';
import Sponsors from '../Components/Sponsors';
var serviceNames = require('../ServiceNameMapper');


let order = 'desc';
var imgdata = '';
class Speakers3 extends React.Component {
    constructor(props) {
        super(props);

        this.state =
        {
            dataloaded: false,
            code: global.$code,
            bucket: global.$s3bucket,
            search: '',
        }
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        let value = target.value

        //console.log("Target = " + name + ", Value = " + value);

        this.setState({ [name]: value });
        //this.setState({selectedCategory:value})
    }
    render() {

        //console.log("AttendeeList: render");

        Analytics.record({ name: 'PageView', attributes: { name: 'eventsites-' + this.state.code + '-speakers' } });
        if (this.state.dataloaded) {
            return (
                <div>
                    <img className="banner" src={global.$config["speakers3"].banner} />
                    <div dangerouslySetInnerHTML={{ __html: global.$config["speakers3"].header }}></div>
                    <div>
                        <Row>
                            <Col md={10} className="offset-md-1">
                                <br />
                                {<div className="attendee-search-div">
                                    <Input type="text" name="search" className="attendee-search" value={this.state.search || ""} onChange={this.handleInputChange} placeholder="Search"><i class='fas fa-search'></i></Input>
                                </div>}
                                <br />
                                <br />
                                <br />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className='speader3-wrapper'>
                                    {this.showSearchResults()}
                                </div>
                               
                                {
                                    global.$config["speakers3"].sponsors.enable == true ?
                                        (
                                            <div className="speaker-sponsor">
                                                <br />
                                                <br />
                                                <Sponsors />
                                                <br />
                                            </div>
                                        )
                                        :
                                        ('')
                                }
                            </Col>

                        </Row>
                        <br />
                    </div>
                </div>
            );
        }
        else {
            this.getSpeakerjson();
            return (
                <div>
                    <Row>
                        <Col md={6} className="offset-5">
                            <div className="loading-div">
                                <div className="loading-text">Loading....</div>
                            </div>
                        </Col>
                    </Row>
                </div>
            )
        }

    }


    showSearchResults() {
        //console.log(this.state.search)

        const results = global.$speakerjson.filter(speaker => {
            if (this.state.search == "")
                return true;
            else {
                if (speaker.DESCRIPTION.toLocaleLowerCase().includes(this.state.search.toLocaleLowerCase()) || speaker.NAME.toLocaleLowerCase().includes(this.state.search.toLocaleLowerCase()))
                    return true;
                else
                    return false;
            }

        })

        return results.map(speaker => {
            //console.log(attendee);
            return (
                    <div className="speakers3-main-div" style={global.$config["speakers3"].styles.maindiv} key={speaker.email}>
                        <SpeakerInfo3
                            code={this.state.code}
                            speaker={speaker}
                        />
                    </div>
            )
        })
    }

    getSpeakerjson() {
        ////console.log("sponsor json")
        let apiName = serviceNames.getWebsiteService();
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'code': this.state.code,
                'command': 'getJsonFileFromS3',
                'bucket': this.state.bucket,
                'filename': "speakers"
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //console.log(myInit);

        API.post(apiName, path, myInit).then(response => {
            //console.log ("response.data ===> " + response.data + "response.err ===> " + response.err);

            if (response.err == null && response.data != null) {
                let buf = Buffer.from(response.data.Body);
                //console.log(buf)
                //console.log(buf.toString());
                global.$speakerjson = JSON.parse(buf.toString());
                this.setState({ dataloaded: true });

            }
            else {
                ////console.log("getAttendeesTableObjects - failed!!");
                //console.log(response.err);
            }
        }
        ).catch(error => {
            ////console.log(error.response)
        });
    }
}
export default Speakers3;