//components that are using registration service => RegHome, RegHome2, discounts, myinfo, payment, registerattendee, regoptions, paypalaslatx, paypalsnawa
function getRegistrationService() {
    return "RegistrationService";
    //return "RegistrationDevService";
}

// StripeCheckoutForm, Authorizenet
function getPaymentService() {
    return "PaymentService";
    //return "PaymentsDevService";
}

//ProfilePage, LoadPrivateImg, DelegatesList, DelegatesList2, LoginForm, MeetupAttendees,  PostImage, PostInput, MyInfo, Payment, RegisterAttendee 
function getUserService() {
    return "UserService";
    //return "UserDevService";
}

//AppointMentsList, AvailableAppointments
function getAppointmentService() {
    return "AppointmentService";
}

// Committee, FooterContactForm, Menubar, ProfilePage, Sponsors, LoadPrivateImg, SponsorsByCategory, SponsorsVertical, SpeakersLayout, SponsorsLayout
// Contact, DelegatesList2, Exhibitors, LoginForm, LoginHome, FindExhibitorMeetup, PostImage, SessionPicker, Schedule, ScheduleSpeakers,
// SessionInfo2, Speakers, Speakers2, Speakers3, ExhibitorLayout, Sponsors, VirtualExpoAll, VirtualExpoMultiRoom, VirtualExpoRoom,  
function getWebsiteService() {
    return "WebsiteService";
}

//ProfilePage, RegisterAttendee, SessionCheckins2, ExhibitorSubmitForm, FormPreview
function getEventPlannerService() {
    
    return "EventPlannerService";
    //return "EventPlannerDevService";
}

//AddBid, AuctionItems, ItemBids, ListBids
function getAuctionService() {
    return "AuctionService";
}

//ChatInput, ChatMessages, CommentInput, CommentsView, PostInput, PostsVIew
function getMessageService() {
    return "MessageService";
    //return "MessageServiceDev";
}

//ChatInput, ChatMessages, CommentInput, CommentsView, PostInput, PostsVIew
function getDocumentGeneratorService() {
    return "DocumentGenerator";
    //return "DocumentGeneratorDev";
}

// Sponsor Contact form
function getEmailService()
{
    return "EmailService";
    //return "EmailDevService";
}
function getDataService()
{
    return "DataService";
}

function getZoomService()
{
    return "ZoomService";
    //return "ZoomDevService";
}
function getBadgeScanService()
{
    return "BadgeScanService";
}
function getSignupService(){
    return 'SignupService'
    //return 'SignupDevService'
}
function getAnalyticsService() {

    return "AnalyticsService"
    //return "AnalyticsDevService"
}
// function getNotificationService()
// {
//     return "NotificationService";
// }
module.exports = {
    getRegistrationService,
    getPaymentService,
    getUserService,
    getAppointmentService,
    getWebsiteService,
    getEventPlannerService,
    getAuctionService,
    getMessageService,
    getDocumentGeneratorService,
    getEmailService,
    getDataService,
    getZoomService,
    getBadgeScanService,
    getSignupService,
    getAnalyticsService
    //getNotificationService
}