import React from 'react';
import { Col, Row } from 'reactstrap';
import { HourGlass } from "react-awesome-spinners";
import { API, Analytics } from 'aws-amplify';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { Link } from 'react-router-dom';
import Sponsors from '../Components/Sponsors';
import commonProfile from '../images/common_profile.png';

const moment = require('moment-timezone');
/*
var speakerjson =
[

]
*/

class SessionTrackItem extends React.Component {
    constructor(props) {

        //console.log(props.tag)
        super(props);
        this.state = {
            code: global.$code,
            tag: props.tag,
            scheduleheadertimestyles: global.$schedule.styles.scheduleheadertime,
            scheduleheadertitlestyles: global.$schedule.styles.scheduleheadertitle,
            scheduleheaderspeakerstyles: global.$schedule.styles.scheduleheaderspeakers,
            scheduletimestyles: global.$schedule.styles.scheduletime,
            scheduletitlestyles: global.$schedule.styles.scheduletitle,
            scheduledescstyles: global.$schedule.styles.scheduledesc,
            schedulespeakerstyles: global.$schedule.styles.schedulespeakers,
            videoiconstyles: global.$schedule.styles.videoicon,
            dataloaded: false
        };
        //console.log(global.$speakers)
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {

        Analytics.record({ name: 'PageView', attributes: { name: 'eventsites-' + this.state.code + '-speakerinfo' } });
        return (
            <div>
                <div>
                    <div className="session-track-item">
                        {this.displaySchedule(this.state.tag)}
                    </div>
                </div>
            </div>
        );

    }
    getAdjustedCurrentDate(schedule, sessionstarttime)
    {
        var mode = global.$mode;
        if (mode == null || mode == '')
        {
            mode = 'live';
        }

        var currentdate = moment().tz(`${global.$settings.timezone}`).format('L, LTS');

       //console.log(`getAdjustedCurrentDate: mode=${mode}, currentdate = {currentdate}`);

        if (mode == "live")
        {
            //Nothing - Just retunr the current date
        }
        else if (mode == "preview")
        {
            // Make current date/time as same as session begin date/time
            currentdate = schedule.BEGIN; 
        }
        else if (mode == "dryrun")
        {
            var eventdate = moment(sessionstarttime).format('L');            
            var currenttime = moment().tz(`${global.$settings.timezone}`).format('LTS');
            
            currentdate = eventdate + " " + currenttime
        }
        else if (mode == "ondemand")
        {   //Add 365 days to current date
            var postdate = moment().add(365,'days').calendar();
            var posttime = moment().tz(`${global.$settings.timezone}`).format('LTS');
            currentdate = postdate + " " + posttime;
        }

        //console.log('getAdjustedCurrentDate:' + currentdate);
        return currentdate;
    }
    displaySchedule(date) {
        const { pageNumber, numPages } = this.state;
        return global.$schedulejson.map(schedule => {

            //console.log(schedule.TAG, date)
            if (schedule.SP == "") {
                schedule.SP = "empty";
            }

            if (schedule.FILE1 == "" || schedule.FILE1 == " ") {
                schedule.FILE1 = "empty";
            }

            //console.log(schedule.SP);
            
            var sessionstarttime = Date.parse(schedule.BEGIN);
            var sessionendtime = Date.parse(schedule.END);
            var currentdate = this.getAdjustedCurrentDate(schedule, sessionstarttime);

           //console.log(currentdate);
            
            var curenttime = Date.parse(currentdate);
            var desc = schedule.DESCRIPTION
            //console.log("file1:" + schedule.FILE1)
            //console.log("https://event360live.s3.amazonaws.com/public/"+this.state.code+"/schedule/"+schedule.FILE1);
            schedule.SP = schedule.SP.replace(/;/g, "");

            if (schedule.ATTENDEELINK == "") {
                if (schedule.SESSIONVIDEOLINK != "") {
                    schedule.ATTENDEELINK = schedule.SESSIONVIDEOLINK
                }
                else {
                    schedule.ATTENDEELINK = "emptyurl";
                }
            }
            //console.log(schedule.ATTENDEELINK)


            if (schedule.TAG.includes(date)) {
                //console.log(schedule)
                return (
                    <div key={schedule.SID}>
                        <div>
                            {
                                schedule.TYPE.includes("H") ?
                                    (
                                        <Row>
                                            <Col md={12}>
                                                {
                                                    desc.includes(" ") ?
                                                        (
                                                            schedule.TITLE
                                                        )
                                                        :
                                                        (
                                                            <div>
                                                                <h6 style={this.state.scheduleheadertimestyles}>{schedule.T1}</h6>
                                                                <h6 style={this.state.scheduleheadertitlestyles}>{schedule.TITLE}</h6>
                                                            </div>
                                                        )
                                                }

                                            </Col>
                                        </Row>
                                    )
                                    :
                                    (
                                        <div>

                                            <Row>
                                                <Col md={12}>
                                                    <div className="time-div">

                                                        <Row>
                                                            <Col md={6}>
                                                                <h6 style={this.state.scheduletimestyles}>{schedule.T1}</h6>
                                                            </Col>
                                                            <Col md={6}>
                                                                {
                                                                    schedule.ATTENDEELINK.includes("emptyurl") ?
                                                                        (
                                                                            ''
                                                                        )
                                                                        :
                                                                        (
                                                                            <div>
                                                                                {
                                                                                    global.$loginstatus.includes("true") ?
                                                                                        (
                                                                                            <div>
                                                                                                {(() => {
                                                                                                    if (curenttime >= sessionstarttime && curenttime <= sessionendtime) {
                                                                                                        //console.log("spinner")
                                                                                                        return (
                                                                                                            <i class='fas fa-circle' style={{ fontSize: "12px", color: "lightgreen", paddingLeft: "5px" }}></i>
                                                                                                        )
                                                                                                    }
                                                                                                })()}

                                                                                                <Link to={`/SessionDetails/${schedule.SID + "==" + schedule.STAG}`} style={this.state.videoiconstyles}><i className="fa fa-video-camera" style={{ paddingRight: "10px" }}></i>Watch Now</Link>
                                                                                            </div>
                                                                                        )
                                                                                        :
                                                                                        (
                                                                                            <div>
                                                                                                {(() => {
                                                                                                    if (curenttime >= sessionstarttime && curenttime <= sessionendtime) {
                                                                                                        //console.log("spinner")
                                                                                                        return (
                                                                                                            <i class='fas fa-circle' style={{ fontSize: "12px", color: "lightgreen", paddingLeft: "5px" }}></i>
                                                                                                        )
                                                                                                    }
                                                                                                })()}
                                                                                                <Link to={`/LogInForm`} style={this.state.videoiconstyles}><i className="fa fa-video-camera" style={{ paddingRight: "10px" }}></i>Watch Now</Link>

                                                                                            </div>

                                                                                        )
                                                                                }

                                                                            </div>
                                                                        )
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    {
                                                        desc.includes(" ") ?
                                                            (<div>
                                                                <details open={global.$posters.showdesc}>
                                                                    <summary style={this.state.scheduletitlestyles}>{schedule.TITLE}
                                                                    </summary>
                                                                    <br />
                                                                    <p style={this.state.scheduledescstyles}>{ReactHtmlParser(desc)}</p>
                                                                    {
                                                                        schedule.SP.includes("empty") ?
                                                                            (
                                                                                ''
                                                                            )
                                                                            :
                                                                            (
                                                                                <div>
                                                                                    {this.processSpeakers(schedule.SP)}
                                                                                </div>
                                                                            )
                                                                    }
                                                                    <h6 style={this.state.schedulespeakerstyles}></h6>
                                                                </details>
                                                            </div>
                                                            )
                                                            :
                                                            (
                                                                <div>
                                                                    <h6 style={this.state.scheduletitlestyles}>{schedule.TITLE}</h6>
                                                                    {
                                                                        schedule.SP.includes("empty") ?
                                                                            (
                                                                                ''
                                                                            )
                                                                            :
                                                                            (
                                                                                <div>
                                                                                    {this.processSpeakers(schedule.SP)}
                                                                                </div>
                                                                            )
                                                                    }
                                                                    <h6 style={this.state.schedulespeakerstyles}></h6>

                                                                </div>
                                                            )
                                                    }

                                                </Col>

                                            </Row>

                                        </div>
                                    )
                            }
                            <hr />
                        </div>
                    </div>

                );
            }

        })
    }
    processSpeakers(names) {
        //console.log(names);

        names = names.replace(/;/g, "");
        names = names.split(",");

        //console.log("processSpeakers:" + names);
        //console.log("globalSpeakers:" + global.$speakerjson);

        var speakersUI = names.map((name) => {
            //console.log("speaker name:"+name);
            if (global.$speakerjson != undefined) {
                //console.log(global.$speakerjson);
                var sp = global.$speakerjson.find(globalSpeaker => {
                    if (globalSpeaker == null || globalSpeaker.NAME == null) {
                        return false;
                    }
                    else {
                        return globalSpeaker.NAME === name
                    }
                })
                //console.log(sp);
                if (sp != undefined) {
                    return this.getSpeakerUI(sp);
                }
            }
        }
        );

        return (
            <div>
                {
                    speakersUI
                }
            </div>
        );
    }

    getSpeakerUI(speaker) {
        //console.log("displaySpeakers" + speakers);
        //console.log(speaker.PHOTO);

        if (speaker.PHOTO == "profile.png" || speaker.PHOTO == " " || speaker.PHOTO == "") {
            var image = commonProfile;
        }
        else {
            var image = `https://s3.amazonaws.com/event360live/public/${this.state.code}/speakers/${(speaker.PHOTO)}`
        }
        //console.log(image);

        return (
            <div>
                <Link to={`/SpeakerInfo/${speaker.NAME + "==ScheduleRouter"}`} target="_self">
                    <div style={{ float: "left" }}>
                        <Row>
                            <Col md={1}>
                                <img src={image} width="50" height="50" className="speaker-img" /><br />
                            </Col>
                            <Col md={10}>
                                <p className="speaker-name">{speaker.NAME}</p>
                            </Col>
                        </Row>
                    </div>
                </Link>
            </div>
        );
    }

}

export default SessionTrackItem;