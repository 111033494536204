import React from 'react';
import { Row, Col } from 'reactstrap';

class Menu5 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div>
                <div dangerouslySetInnerHTML={{ __html: global.$config["menu5"].info }}></div>
            </div>
        )
    }
}
export default Menu5;
