import React from 'react';
import { Row, Col, CarouselItem, CarouselIndicators, CarouselControl, Carousel } from 'reactstrap';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Avatar from 'react-avatar';
import ReactHtmlParser from 'react-html-parser';

class SpeakerPopup extends React.Component {
    constructor(props) {

        super(props);
        this.state = {
        };
    }


    render() {

        return (
            <div>
                <Tabs className="tabs-menu" defaultActiveKey="info" transition={false} id="tabs" >
                    <Tab eventKey="info" title="Info" className="tab-item">
                        {this.displaySpeakerInfo(this.props.speaker)}
                    </Tab>
                </Tabs>
            </div>
        )
    }

    displaySpeakerInfo(speaker) {
        var image = "";
        if (speaker.PHOTO == "profile.png" || speaker.PHOTO == " " || speaker.PHOTO == "") {
            image = "empty";
        }
        else {
            image = `https://s3.amazonaws.com/event360live/public/${global.$code}/speakers/` + speaker.PHOTO
        }
        var styles = '';
        
        if(global.$config['speakersscroll'] != null)
        {
            styles = global.$config['speakersscroll'].popup.styles;
        }
        else
        {
            styles = {
                "maindiv": {},
                "imgdiv":{},
                "avatarimg":{},
                "img":{},
                "infodiv":{},
                "name":{},
                "title":{},
                "company":{},
                "desc":{}
            }
        }
        return (
            <div className="speaker-popup-div" style={styles.maindiv}>
                <Row>
                    <Col md={3}>
                        <div className="speaker-popup-img-div" style={styles.imgdiv}>
                            {
                                image.includes("empty") ?
                                    (
                                        <div>
                                            <Avatar className="speaker-layout-avatar" name={`${speaker.NAME}`} style={styles.avatarimg} />
                                        </div>
                                    )
                                    :
                                    (
                                        <div>
                                            <img src={image} className="speaker-layout-img" style={styles.img} />
                                        </div>
                                    )
                            }
                        </div>
                    </Col>
                    <Col md={9}>
                        <div className="speaker-popup-div2" style={styles.infodiv}>
                            <h4 className="speaker-popup-name" style={styles.name}><b>{speaker.NAME}</b></h4>
                            <p className="speaker-popup-title" style={styles.title}>{speaker.TITLE}</p>
                            <p className="speaker-popup-company" style={styles.company}>{speaker.COMPANY}</p>
                            <p className="speaker-popup-desc" style={styles.desc}>{ReactHtmlParser(speaker.DESCRIPTION)}</p>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}
export default SpeakerPopup;
