import React from 'react';
import { Col, Row } from 'reactstrap';
import { HourGlass } from "react-awesome-spinners";
import { API, Analytics } from 'aws-amplify';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { Link } from 'react-router-dom';
import Sponsors from '../Components/Sponsors';
//import '../Exhibitors/Exhibitors.css';
import ReactPlayer from 'react-player';
import SponsorContactForm from './SponsorContactForm';

/*
var exhibitorjson =
[

]
*/

class SponsorInfo extends React.Component {
    constructor(props) {

        var [name, type] = props.match.params.value.split("==");
       //console.log(props.match.params.value)
        var contactform = "N";
        if(global.$sponsors.enablecontactform == null)
        {
            contactform = "N";
        }
        else
        {
            contactform = global.$sponsors.enablecontactform;
        }

        super(props);
        this.state = {
            code: global.$code,
            s3: global.$s3,
            bucket: global.$s3bucket,
            name: name,
            type: type,
            cname: '',
            title: '',
            photo: '',
            desc: '',
            phone: '',
            email: '',
            country: '',
            state: '',
            city: '',
            street: '',
            zip: '',
            company: '',
            videourl: '',
            bannerad: '',
            backbuttonstyle: global.$settings.styles.backbutton,
            exhibitorbannerstyle: global.$sponsors.styles.sponsorinfo.banner,
            exhibitornamestyle: global.$sponsors.styles.sponsorinfo.name,
            exhibitortitlestyle: global.$sponsors.styles.sponsorinfo.title,
            exhibitorimagestyle: global.$sponsors.styles.sponsorinfo.image,
            exhibitordescstyle: global.$sponsors.styles.sponsorinfo.desc,
            dataloaded: false,
            enablecontactform: contactform
        };
       //console.log(this.state);
        //console.log(global.$exhibitors)
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {

        //console.log("exhibitorInfo: render")

        Analytics.record({ name: 'PageView', attributes: { name: 'eventsites-' + this.state.code + '-exhibitorinfo' } });
        if (this.state.dataloaded) {
            //console.log(this.state)
            return (
                <div>
                    <div>
                        {this.getExhibitorBanner()}
                    </div>
                    <br />
                    <div>
                        {this.getExhibitorBannerAd()}
                    </div>
                    <Row>
                        <Col md={10}>
                            <div className="exhibitor-desc offset-md-2">
                                <Row>
                                    <Col md={12}>
                                        <div className="exhibitor-desc-info">
                                            {this.getExhibitorDesc()}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <div>
                        {this.getExhibitorVideo()}
                    </div>
                    <Row>
                        <Col md={10}>
                            <div className="exhibitor-contact offset-md-2">
                                <Row>
                                    <Col md={12}>
                                        <div className="exhibitor-contact-info">
                                            {this.getExhibitorContact()}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <br />
                    {
                        this.state.enablecontactform.includes("Y")?
                        (
                            <Row>
                                <Col md={10}>
                                    <div className="exhibitor-contact offset-md-2">
                                        <SponsorContactForm email={this.state.email}/>
                                    </div>
                                </Col>
                            </Row>
                        )
                        :
                        ('')
                    }
                </div>
            );
        }
        else {
            this.getExhibitorData();
            return (
                <div>
                    <Row>
                        <Col md={6} className="offset-5">
                            <div className="loading-div">
                                <div className="loading-text">Loading....</div>
                                {/*<ReactSpinner/>*/}
                            </div>
                        </Col>
                    </Row>
                </div>
            )
        }
    }




    getExhibitorData() {
        //console.log(global.$sponsorjson);
        var exhibitorObj = "";
        if(this.state.type == "sponsors")
        {
            exhibitorObj = global.$sponsorjson.find(globalSponsor => globalSponsor.NAME === this.state.name)
        }
        if(this.state.type == "exhibitors")
        {
            exhibitorObj = global.$exhibitorjson.find(globalSponsor => globalSponsor.NAME === this.state.name)
        }

       //console.log(exhibitorObj);
        this.setState({ title: exhibitorObj.TITLE })
        this.setState({ photo: exhibitorObj.LOGO })
        this.setState({ desc: exhibitorObj.DESCRIPTION })
        this.setState({ cname: exhibitorObj.CNAME })
        this.setState({ phone: exhibitorObj.PHONE })
        this.setState({ email: exhibitorObj.EMAIL })
        this.setState({ country: exhibitorObj.COUNTRY })
        this.setState({ state: exhibitorObj.STATE })
        this.setState({ city: exhibitorObj.CITY })
        this.setState({ street: exhibitorObj.STREET })
        this.setState({ zip: exhibitorObj.ZIP })
        this.setState({ videourl: exhibitorObj.VIDEOURL })
        this.setState({ url: exhibitorObj.URL })
        this.setState({ bannerad: exhibitorObj.ADFILE })


        this.setState({ dataloaded: true })
    }

    getExhibitorBannerAd() {
        if (this.state.bannerad !== "") {
            return (
                <div className="exhibitor-banner-ad">
                    {
                        this.state.type.includes("sponsors")?
                        (
                            <img src={`https://s3.amazonaws.com/event360live/public/${this.state.code}/exhibitors/` + this.state.bannerad} className="exhibitor-ad-img" />
                        )
                        :
                        ('')
                    }
                    {
                        this.state.type.includes("exhibitors")?
                        (
                            <img src={`https://s3.amazonaws.com/event360live/public/${this.state.code}/exhibitors/` + this.state.bannerad} className="exhibitor-ad-img" />
                        )
                        :
                        ('')
                    }
                </div>
            )
        }
    }
    getExhibitorVideo() {

        //console.log(this.state.videourl);
        if (this.state.videourl !== "") {
            return (
                <div className="exhibitor-video-header">
                    <div className="exhibitor-video offset-md-3">
                        <ReactPlayer
                            url={this.state.videourl}
                            controls={true}
                            playing
                        />
                    </div>
                </div>
            )
        }

    }
    getExhibitorBanner() {
       //console.log(this.state.type);
        //var exhibitor = this.state.exhibitorInfo;
       //console.log(this.state.photo);
        var image = "";
        if(this.state.type == "sponsors")
        {
            image = `https://s3.amazonaws.com/event360live/public/${this.state.code}/exhibitors/` + this.state.photo
        }
        if(this.state.type == "exhibitors")
        {
            image = `https://s3.amazonaws.com/event360live/public/${this.state.code}/exhibitors/` + this.state.photo
        }
        return (
            <div>
                <Row style={this.state.exhibitorbannerstyle} className="exhibitor-banner">
                    <Col md={3} className="offset-md-2">
                        <br />
                        <br />
                        {
                            this.state.photo != ""?
                            (
                                <img src={image} style={this.state.exhibitorimagestyle} className="info-img" />
                            )
                            :
                            ('')
                        }
                        <br />
                        <br />
                    </Col>
                    <Col md={6}>
                        <div style={this.state.exhibitornamestyle}>{this.state.name}</div>
                    </Col>
                </Row>

            </div>
        )
    }
    getExhibitorDesc() {
        //console.log(this.state.desc)
        this.state.desc = this.state.desc.trim();
        if (this.state.desc == "" || this.state.desc == " " || this.state.desc == null) {
            return (
                <div></div>
            )
        }
        else {
            return (
                <div className="exhibitor-info">
                    <p style={this.state.exhibitordescstyle} className="exhibitor-desc">{ReactHtmlParser(this.state.desc)}</p>
                    <hr />
                </div>
            );
        }

    };


    getExhibitorContact() {
        return (
            <div className="exhibitor-info">
                <div>
                    <Row>
                        <Col md={6}>
                            {this.showContact()}
                        </Col>
                        <Col md={5} className="offset-md-1">
                            {this.showAddress()}
                        </Col>
                    </Row>
                </div>
            </div>
        );
    };

    showContact() {
        if (this.state.title == "" && this.state.cname == "" && this.state.phone == "" && this.state.email == "" && this.state.url == "") {
            //console.log("empty")
            return '';
        }
        else {
            //console.log("exist");
            return (
                <div>
                    <p className="exhibitor-contact-header1">Contact</p>
                    <hr />
                    <div className="exhibitor-contact-info1">{this.state.cname}</div>
                    <div className="exhibitor-contact-info1">{this.state.title}</div>
                    <div className="exhibitor-contact-info1">{this.state.phone}</div>
                    <div className="exhibitor-contact-info1">{this.state.email}</div>
                    <div className="exhibitor-contact-info1"><a href={this.state.url} target="_blank">{this.state.url}</a></div>
                </div>
            )

        }
        //console.log("exist2");
    }
    showAddress() {
        if (this.state.country == "" || this.state.country == null && this.state.state == "" && this.state.city == "" && this.state.street == "" && this.state.zip == "") {
            return '';
        }
        else {
            return (
                <div>
                    <p className="exhibitor-contact-header2">Address</p>
                    <hr />
                    <div className="exhibitor-contact-info2">{this.state.country}</div>
                    <div className="exhibitor-contact-info2">{this.state.state}</div>
                    <div className="exhibitor-contact-info2">{this.state.city}</div>
                    <div className="exhibitor-contact-info2 ">{this.state.street}</div>
                    <div className="exhibitor-contact-info2 ">{this.state.zip}</div>
                </div>
            )
        }
    }
}

export default SponsorInfo;