import React from 'react';
import { API } from 'aws-amplify';
import { Button } from 'reactstrap';
var serviceNames = require('../ServiceNameMapper');


/*

            https://d1y1dr9xzw7t4i.cloudfront.net/zoom/zoom.html

            https://eventsiteslive.s3.amazonaws.com/zoom/zoom.html

*/
const moment = require('moment-timezone');
class EmbedZoom extends React.Component {
    constructor(props) {
        super(props);
       //console.log(props);

        this.state = {
            code: global.$code,
            s3: global.$s3,
            bucket: global.$s3bucket,
            dataloaded: false,
            zoomurl: props.zoomurl,
            zoomid: '',
            zoompwd: '',
            signature: ''
        };
        //console.log(this.state.zoomid);
        if (this.state.zoomurl.includes("pwd")) {
            var id = (this.state.zoomurl).substring(
                (this.state.zoomurl).lastIndexOf("/j/") + 3,
                (this.state.zoomurl).lastIndexOf("?pwd="));
            //console.log(id);
            this.state.zoomid = id;

            var pwd = (this.state.zoomurl).substring(
                (this.state.zoomurl).lastIndexOf("?pwd=") + 5);

            this.state.zoompwd = pwd;
        }
        else {

            var id = (this.state.zoomurl).substring(
                (this.state.zoomurl).lastIndexOf("/j/") + 3);
            //console.log(id);
            this.state.zoomid = id;
            this.state.zoompwd = this.state.code;
        }
        //this.getZoomSignature(id);
    }

    render() {
       //console.log(this.props);
       //console.log(this.state);
        if (this.state.dataloaded) {
            if (this.props.component == "session-layout1") {
                return (
                    <div class="iframe-embed-container-layout1" >
                        <iframe allow="microphone; camera; fullscreen" allowfullscreen className="zoom-embed-iframe-layout1" src={`https://d1y1dr9xzw7t4i.cloudfront.net/zoom/zoom.html?zoomid=${this.state.zoomid}&zoompwd=${this.state.zoompwd}&fname=${global.$fname}&lname=${global.$lname}&useremail=${global.$userid}&signature=${this.state.signature}`} frameBorder="0"></iframe>
                        <br />
                        {
                            global.$session.enablezoomembedlink != null && global.$session.enablezoomembedlink.includes("Y") ?
                                (
                                    <div className="embed-zoom-message-layout1">
                                        <a href={this.props.zoomurl} target="_blank">{global.$session.embedzoomlinktext}</a>
                                    </div>
                                )
                                :
                                (
                                    ''
                                )
                        }
                    </div>
                )
            }
            else {
                return (
                    <div class="iframe-embed-container" >
                        <iframe allow="microphone; camera; fullscreen" allowfullscreen className="zoom-embed-iframe" src={`https://d1y1dr9xzw7t4i.cloudfront.net/zoom/zoom.html?zoomid=${this.state.zoomid}&zoompwd=${this.state.zoompwd}&fname=${global.$fname}&lname=${global.$lname}&useremail=${global.$userid}&signature=${this.state.signature}`} frameBorder="0"></iframe>
                        <br />
                        {
                            global.$session.enablezoomembedlink != null && global.$session.enablezoomembedlink.includes("Y") ?
                                (
                                    <div className="embed-zoom-message">
                                        <a href={this.props.zoomurl} target="_blank">{global.$session.embedzoomlinktext}</a>
                                    </div>
                                )
                                :
                                (
                                    ''
                                )
                        }
                    </div>
                )
            }
        }
        else {
            this.getZoomSignature(this.state.zoomid);
            return (
                <div></div>
            )
        }
    }

    getZoomSignature(zoomid) {

        //console.log(this.state.zoomid);
        // let apiName = 'ZoomService'; 
        let apiName = serviceNames.getZoomService();
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'code': this.state.code,
                'command': 'getZoomSessionSignature',
                'meetingid': zoomid
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //console.log(myInit);

        API.post(apiName, path, myInit).then(response => {
           //console.log(response);

            if (response.err == null && response.data != null) {
               //console.log(response.data)
                this.setState({
                    signature: response.data,
                    dataloaded: true
                });
            }
            else {
                //console.log(response.err);
            }

        }
        ).catch(error => {
            //console.log(error)
        });
    }

}
export default EmbedZoom;


