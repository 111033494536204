import React from 'react';
import './Schedule.css';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { Input, Row, Col } from 'reactstrap';
import ScheduleItem from './ScheduleItem';
import LiveSessions from './LiveSessions';

const moment = require('moment-timezone');

/*

- Schedule tabs component

*/

var timer;

class ScheduleSubTabs extends React.Component {
    constructor(props) {
        super(props);

        var activeschedulesubtab = '';
        //console.log(global.$activeScheduleSubTab);
        //console.log(activeschedulesubtab);
        if (global.$activeScheduleSubTab == null) {
            if (props.subtabs.tab1.text == "posters") {
                activeschedulesubtab = props.subtabs.tab1.tag;
            }
            else {
                activeschedulesubtab = props.subtabs.tab1.tag;
            }
        }
        else {
            activeschedulesubtab = global.$activeScheduleSubTab;
        }
        //console.log(props.subtabs.tab1.text)

        if (props.subtabs.tab1.text == "Posters") {
            //console.log(props.subtabs.tab1.text)
            activeschedulesubtab = props.subtabs.tab1.tag;
        }

        var defaulttab = activeschedulesubtab;

       //console.log(activeschedulesubtab);
        //console.log(global.$activeScheduleSubTab);

        if (props.subtabs.tab1 != null && props.subtabs.tab1.tag.includes(activeschedulesubtab)) {
           //console.log(props.subtabs.tab1.text)
            defaulttab = activeschedulesubtab;
        }
        else if (props.subtabs.tab2 != null && props.subtabs.tab2.tag.includes(activeschedulesubtab)) {
           //console.log(props.subtabs.tab2.text)
            defaulttab = activeschedulesubtab;
        }
        else if (props.subtabs.tab3 != null && props.subtabs.tab3.tag.includes(activeschedulesubtab)) {
           //console.log(props.subtabs.tab3.text)
            defaulttab = activeschedulesubtab;
        }
        else if (props.subtabs.tab4 != null && props.subtabs.tab4.tag.includes(activeschedulesubtab)) {
           //console.log(props.subtabs.tab4.text)
            defaulttab = activeschedulesubtab;
        }
        else if (props.subtabs.tab5 != null && props.subtabs.tab5.tag.includes(activeschedulesubtab)) {
           //console.log(props.subtabs.tab5.text)
            defaulttab = activeschedulesubtab;
        }
        else if (props.subtabs.tab6 != null && props.subtabs.tab6.tag.includes(activeschedulesubtab)) {
           //console.log(props.subtabs.tab6.text)
            defaulttab = activeschedulesubtab;
        }
        else if (props.subtabs.tab7 != null && props.subtabs.tab7.tag.includes(activeschedulesubtab)) {
           //console.log(props.subtabs.tab7.text)
            defaulttab = activeschedulesubtab;
        }
        else {
            defaulttab = props.subtabs.tab1.tag
        }

        this.state = {
            code: global.$code,
            s3: global.$s3,
            bucket: global.$s3bucket,
            dataloaded: false,
            open: false,
            activeTab: '1',
            setActiveTab: '1',
            userid: '',
            userpassword: '',
            search: props.search,
            numPages: null,
            pageNumber: 1,
            activeschedule: defaulttab,
            subtabs: props.subtabs
        };
       //console.log(this.state);
    }

    render() {

        return (
            <div>
                <Row>
                    <Col md={12}>

                        {
                            this.state.subtabs.numberoftabs.includes("1") ?
                                (
                                    <Tabs className="justify-content-center" defaultActiveKey={this.state.activeschedule} transition={false} id="tabs" vertical>
                                        <Tab eventKey={this.state.subtabs.tab1.tag} title={this.state.subtabs.tab1.text} className="tab-item">
                                            {this.getSchedule(this.state.subtabs.tab1.tag, this.state.subtabs.tab1.text)}
                                        </Tab>
                                    </Tabs>
                                )
                                :
                                ('')
                        }
                        {
                            this.state.subtabs.numberoftabs.includes("2") ?
                                (
                                    <Tabs className="tabs-menu justify-content-center" defaultActiveKey={this.state.activeschedule} transition={false} id="tabs" >
                                        <Tab eventKey={this.state.subtabs.tab1.tag} title={this.state.subtabs.tab1.text} className="tab-item">
                                            {this.getSchedule(this.state.subtabs.tab1.tag, this.state.subtabs.tab1.text)}
                                        </Tab>
                                        <Tab eventKey={this.state.subtabs.tab2.tag} title={this.state.subtabs.tab2.text} className="tab-item">
                                            {this.getSchedule(this.state.subtabs.tab2.tag, this.state.subtabs.tab2.text)}
                                        </Tab>
                                    </Tabs>

                                )
                                :
                                ('')
                        }
                        {
                            this.state.subtabs.numberoftabs.includes("3") ?
                                (
                                    <Tabs className="tabs-menu justify-content-center" defaultActiveKey={this.state.activeschedule} transition={false} id="tabs" >
                                        <Tab eventKey={this.state.subtabs.tab1.tag} title={this.state.subtabs.tab1.text} className="tab-item">
                                            {this.getSchedule(this.state.subtabs.tab1.tag, this.state.subtabs.tab1.text)}
                                        </Tab>
                                        <Tab eventKey={this.state.subtabs.tab2.tag} title={this.state.subtabs.tab2.text} className="tab-item">
                                            {this.getSchedule(this.state.subtabs.tab2.tag, this.state.subtabs.tab2.text)}
                                        </Tab>
                                        <Tab eventKey={this.state.subtabs.tab3.tag} title={this.state.subtabs.tab3.text} className="tab-item">
                                            {this.getSchedule(this.state.subtabs.tab3.tag, this.state.subtabs.tab3.text)}
                                        </Tab>
                                    </Tabs>
                                )
                                :
                                ('')
                        }
                        {
                            this.state.subtabs.numberoftabs.includes("4") ?
                                (
                                    <Tabs className="tabs-menu justify-content-center" defaultActiveKey={this.state.activeschedule} transition={false} id="tabs" >
                                        <Tab eventKey={this.state.subtabs.tab1.tag} title={this.state.subtabs.tab1.text} className="tab-item">
                                            {this.getSchedule(this.state.subtabs.tab1.tag, this.state.subtabs.tab1.text)}
                                        </Tab>
                                        <Tab eventKey={this.state.subtabs.tab2.tag} title={this.state.subtabs.tab2.text} className="tab-item">
                                            {this.getSchedule(this.state.subtabs.tab2.tag, this.state.subtabs.tab2.text)}
                                        </Tab>
                                        <Tab eventKey={this.state.subtabs.tab3.tag} title={this.state.subtabs.tab3.text} className="tab-item">
                                            {this.getSchedule(this.state.subtabs.tab3.tag, this.state.subtabs.tab3.text)}
                                        </Tab>
                                        <Tab eventKey={this.state.subtabs.tab4.tag} title={this.state.subtabs.tab4.text} className="tab-item">
                                            {this.getSchedule(this.state.subtabs.tab4.tag, this.state.subtabs.tab4.text)}
                                        </Tab>
                                    </Tabs>
                                )
                                :
                                ('')
                        }
                        {
                            this.state.subtabs.numberoftabs.includes("5") ?
                                (
                                    <Tabs className="tabs-menu justify-content-center" defaultActiveKey={this.state.activeschedule} transition={false} id="tabs" >
                                        <Tab eventKey={this.state.subtabs.tab1.tag} title={this.state.subtabs.tab1.text} className="tab-item">
                                            {this.getSchedule(this.state.subtabs.tab1.tag, this.state.subtabs.tab1.text)}
                                        </Tab>
                                        <Tab eventKey={this.state.subtabs.tab2.tag} title={this.state.subtabs.tab2.text} className="tab-item">
                                            {this.getSchedule(this.state.subtabs.tab2.tag, this.state.subtabs.tab2.text)}
                                        </Tab>
                                        <Tab eventKey={this.state.subtabs.tab3.tag} title={this.state.subtabs.tab3.text} className="tab-item">
                                            {this.getSchedule(this.state.subtabs.tab3.tag, this.state.subtabs.tab3.text)}
                                        </Tab>
                                        <Tab eventKey={this.state.subtabs.tab4.tag} title={this.state.subtabs.tab4.text} className="tab-item">
                                            {this.getSchedule(this.state.subtabs.tab4.tag, this.state.subtabs.tab4.text)}
                                        </Tab>
                                        <Tab eventKey={this.state.subtabs.tab5.tag} title={this.state.subtabs.tab5.text} className="tab-item">
                                            {this.getSchedule(this.state.subtabs.tab5.tag, this.state.subtabs.tab5.text)}
                                        </Tab>
                                    </Tabs>
                                )
                                :
                                ('')
                        }
                        {
                            this.state.subtabs.numberoftabs.includes("6") ?
                                (
                                    <Tabs className="tabs-menu justify-content-center" defaultActiveKey={this.state.activeschedule} transition={false} id="tabs" >
                                        <Tab eventKey={this.state.subtabs.tab1.tag} title={this.state.subtabs.tab1.text} className="tab-item">
                                            {this.getSchedule(this.state.subtabs.tab1.tag, this.state.subtabs.tab1.text)}
                                        </Tab>
                                        <Tab eventKey={this.state.subtabs.tab2.tag} title={this.state.subtabs.tab2.text} className="tab-item">
                                            {this.getSchedule(this.state.subtabs.tab2.tag, this.state.subtabs.tab2.text)}
                                        </Tab>
                                        <Tab eventKey={this.state.subtabs.tab3.tag} title={this.state.subtabs.tab3.text} className="tab-item">
                                            {this.getSchedule(this.state.subtabs.tab3.tag, this.state.subtabs.tab3.text)}
                                        </Tab>
                                        <Tab eventKey={this.state.subtabs.tab4.tag} title={this.state.subtabs.tab4.text} className="tab-item">
                                            {this.getSchedule(this.state.subtabs.tab4.tag, this.state.subtabs.tab4.text)}
                                        </Tab>
                                        <Tab eventKey={this.state.subtabs.tab5.tag} title={this.state.subtabs.tab5.text} className="tab-item">
                                            {this.getSchedule(this.state.subtabs.tab5.tag, this.state.subtabs.tab5.text)}
                                        </Tab>
                                        <Tab eventKey={this.state.subtabs.tab6.tag} title={this.state.subtabs.tab6.text} className="tab-item">
                                            {this.getSchedule(this.state.subtabs.tab6.tag, this.state.subtabs.tab6.text)}
                                        </Tab>
                                    </Tabs>
                                )
                                :
                                ('')
                        }
                        {
                            this.state.subtabs.numberoftabs.includes("7") ?
                                (
                                    <Tabs className="tabs-menu justify-content-center" defaultActiveKey={this.state.activeschedule} transition={false} id="tabs" >
                                        <Tab eventKey={this.state.subtabs.tab1.tag} title={this.state.subtabs.tab1.text} className="tab-item">
                                            {this.getSchedule(this.state.subtabs.tab1.tag, this.state.subtabs.tab1.text)}
                                        </Tab>
                                        <Tab eventKey={this.state.subtabs.tab2.tag} title={this.state.subtabs.tab2.text} className="tab-item">
                                            {this.getSchedule(this.state.subtabs.tab2.tag, this.state.subtabs.tab2.text)}
                                        </Tab>
                                        <Tab eventKey={this.state.subtabs.tab3.tag} title={this.state.subtabs.tab3.text} className="tab-item">
                                            {this.getSchedule(this.state.subtabs.tab3.tag, this.state.subtabs.tab3.text)}
                                        </Tab>
                                        <Tab eventKey={this.state.subtabs.tab4.tag} title={this.state.subtabs.tab4.text} className="tab-item">
                                            {this.getSchedule(this.state.subtabs.tab4.tag, this.state.subtabs.tab4.text)}
                                        </Tab>
                                        <Tab eventKey={this.state.subtabs.tab5.tag} title={this.state.subtabs.tab5.text} className="tab-item">
                                            {this.getSchedule(this.state.subtabs.tab5.tag, this.state.subtabs.tab5.text)}
                                        </Tab>
                                        <Tab eventKey={this.state.subtabs.tab6.tag} title={this.state.subtabs.tab6.text} className="tab-item">
                                            {this.getSchedule(this.state.subtabs.tab6.tag, this.state.subtabs.tab6.text)}
                                        </Tab>
                                        <Tab eventKey={this.state.subtabs.tab7.tag} title={this.state.subtabs.tab7.text} className="tab-item">
                                            {this.getSchedule(this.state.subtabs.tab7.tag, this.state.subtabs.tab7.text)}
                                        </Tab>
                                    </Tabs>
                                )
                                :
                                ('')
                        }
                    </Col>
                </Row>
                <br />
                <br />
            </div>

        );
    }

    getSchedule(stag, activetab) {
        //console.log(stag);
        //console.log(global.$schedulejson);
        const results = global.$schedulejson.filter(schedule => {
            if (this.props.search == "")
                return true;
            else {
                if (schedule.TITLE.toLocaleLowerCase().includes(this.props.search.toLocaleLowerCase()) || schedule.DESCRIPTION.toLocaleLowerCase().includes(this.props.search.toLocaleLowerCase()) || schedule.T1.toLocaleLowerCase().includes(this.props.search.toLocaleLowerCase()) || schedule.SP.toLocaleLowerCase().includes(this.props.search.toLocaleLowerCase()))
                    return true;
                else
                    return false;
            }
        })
        //console.log(stag, activetab);
        return results.map(schedule => {

            //console.log(schedule)
            if (schedule.SP == "") {
                schedule.SP = "empty";
            }

            if (schedule.FILE1 == "" || schedule.FILE1 == " ") {
                schedule.FILE1 = "empty";
            }

            var currentdate = this.getAdjustedCurrentDate(schedule.BEGIN, schedule.END)//moment().tz(`${global.$settings.timezone}`).format('L LTS')

            var currenttime = Date.parse(currentdate);
            var sessionstarttime = Date.parse(schedule.BEGIN);
            var sessionendtime = Date.parse(schedule.END);
            //schedule.SP = schedule.SP.replace(/;/g, "");
            //console.log(schedule.SP);

            if (schedule.T3 == "") {
                schedule.T3 = "emptyurl";
            }
            if (schedule.TRACKS == "") {
                schedule.TRACKS = "emptyurl";
            }
            //console.log(schedule.ATTENDEELINK)
            if (schedule.TAG.includes(stag)) {
              //console.log(schedule);
                return (
                    <div key={schedule.SID}>
                        {
                            schedule.TYPE == "F" ?
                                (
                                    ''
                                )
                                :
                                (
                                    <ScheduleItem
                                        stag={stag}
                                        currentdate={currentdate}
                                        currenttime={currenttime}
                                        sessionstarttime={sessionstarttime}
                                        sessionendtime={sessionendtime}
                                        schedule={schedule}
                                        activetab={stag}
                                        type={this.props.type}
                                    />
                                )
                        }
                    </div>
                );
            }
        })
    }
    getLiveSessions(stags, activetab) {
        var scheduletags = stags;
        //console.log(scheduletags);
        scheduletags = scheduletags.split(",");
        //console.log(scheduletags);
        return scheduletags.map(stag => {
            //console.log(stag);
            return (
                <LiveSessions stag={stag} activetab={activetab} search={this.props.search} type={this.props.type} />
            )
        });
    }
    getAdjustedCurrentDate(begin, end) {
        var mode = global.$mode;
        if (mode == null || mode == '') {
            mode = 'live';
        }

        var currentdate = moment().tz(`${global.$settings.timezone}`).format('L LTS');

        if (mode == "live") {
            //Nothing - Just return the current date
        }
        else if (mode == "preview") {
            // Make current date/time as same as session begin date/time
            currentdate = begin;
        }
        else if (mode == "dryrun") {
            var eventdate = moment(begin).format('L');
            var currenttime = moment().tz(`${global.$settings.timezone}`).format('LTS');
            currentdate = eventdate + " " + currenttime
        }
        else if (mode == "ondemand") {   //Add 365 days to current date

            var postdate = moment().add(365, 'days').calendar();
            var posttime = moment().tz(`${global.$settings.timezone}`).format('LTS');
            currentdate = postdate + " " + posttime;
        }

        return currentdate;
    }
}
export default ScheduleSubTabs;


