import React from 'react';
import { API, Analytics } from 'aws-amplify';
import ScheduleTabs from './ScheduleTabs';
import { Row, Col, Button } from 'reactstrap';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import ScheduleSpeakers from './ScheduleSpeakers';
import SessionTracks from './SessionTracks';
import ScheduleWatchNow from './ScheduleWatchNow';
import { Document, Page, pdfjs } from "react-pdf/dist/entry.webpack";
import "react-pdf/dist/Page/AnnotationLayer.css";
import SessionRegistration from './SessionRegistration';
import AddToCalendar from 'react-add-to-calendar';
import Icon from '@mdi/react';
import { mdiMicrosoftOutlook, mdiCalendar, mdiDownload } from '@mdi/js';
import './Schedule.css';
var serviceNames = require('../ServiceNameMapper');
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const moment = require('moment-timezone');

class ScheduleItem extends React.Component {
    constructor(props) {
        super(props);
        //console.log(props);
        //console.log("schedule Item")
        //console.log("session begin date = "+props.schedule.BEGIN, "session end date = "+props.schedule.END);
        var schedulet2styles = {};
        var schedulet3styles = {};
        var calenderstyles = {};
        if (global.$schedule.styles.schedulet2 != null) {
            schedulet2styles = global.$schedule.styles.schedulet2
        }
        if (global.$schedule.styles.schedulet3 != null) {
            schedulet3styles = global.$schedule.styles.schedulet3
        }

        if (global.$schedule.styles.calender != null) {
            calenderstyles = global.$schedule.styles.calender
        }

        this.state = {
            code: global.$code,
            s3: global.$s3,
            bucket: global.$s3bucket,
            dataloaded: false,
            stag: props.stag,
            activetab: props.day,
            numPages: null,
            pageNumber: 1,
            search: '',
            scheduleheadertimestyles: global.$schedule.styles.scheduleheadertime,
            scheduleheadertitlestyles: global.$schedule.styles.scheduleheadertitle,
            scheduleheaderspeakerstyles: global.$schedule.styles.scheduleheaderspeakers,
            scheduletimestyles: global.$schedule.styles.scheduletime,
            scheduletitlestyles: global.$schedule.styles.scheduletitle,
            scheduledescstyles: global.$schedule.styles.scheduledesc,
            schedulespeakerstyles: global.$schedule.styles.schedulespeakers,
            file1: props.schedule.FILE1,
            schedulet2styles: schedulet2styles,
            schedulet3styles: schedulet3styles,
            calenderstyles: calenderstyles
        };

        if (this.state.file1 == "" || this.state.file1 == " ") {
            return this.state.file1 == "empty";
        }
        this.refreshScheduleItem = this.refreshScheduleItem.bind(this);
        //console.log(global.$schedule.styles.scheduledesc);
    }


    render() {

        
        var sessiondate = moment(this.props.schedule.BEGIN).format("MMM Do");
        return (
            <div>
                {
                    this.props.schedule.TRACKS.includes("emptyurl") ?
                        (
                            <div>
                                {
                                    this.props.schedule.TYPE.includes("H") ?
                                        (
                                            <Row>
                                                <Col md={3} >
                                                    <h5 style={this.state.scheduleheadertimestyles}>{this.props.schedule.T1}</h5>
                                                    {
                                                        this.props.schedule.T2 != "" && this.props.schedule.T2 != "emptyurl" ?
                                                            (
                                                                <div style={this.state.schedulet2styles}>{this.props.schedule.T2}</div>
                                                            )
                                                            :
                                                            ('')
                                                    }

                                                    {
                                                        this.props.schedule.T3 != "" && this.props.schedule.T3 != "emptyurl" ?
                                                            (
                                                                <div style={this.state.schedulet3styles}>{this.props.schedule.T3}</div>
                                                            )
                                                            :
                                                            ('')
                                                    }
                                                </Col>
                                                <Col md={8} className='shedule-details-wrapper'>
                                                    {
                                                        this.props.schedule.DESCRIPTION.includes(" ") ?
                                                            (
                                                                this.props.schedule.TITLE
                                                            )
                                                            :
                                                            (
                                                                <div>
                                                                    <h5 style={this.state.scheduleheadertitlestyles}>{this.props.schedule.TITLE}</h5>
                                                                </div>
                                                            )
                                                    }

                                                </Col>
                                            </Row>
                                        )
                                        :
                                        (
                                            <Row className="shedule-item-wrapper-div">
                                                    <Col md={3} >
                                                        {
                                                            this.props.from != null && this.props.from == "Mysessions" ?
                                                                (
                                                                    <h5 style={this.state.scheduletimestyles} >{sessiondate}</h5>
                                                                )
                                                                :
                                                                ('')
                                                        }
                                                        <h5 style={this.state.scheduletimestyles} >{this.props.schedule.T1}</h5>
                                                        {
                                                            this.props.schedule.T2 != "" && this.props.schedule.T2 != "emptyurl" ?
                                                                (
                                                                    <div style={this.state.schedulet2styles}>{this.props.schedule.T2}</div>
                                                                )
                                                                :
                                                                ('')
                                                        }

                                                        {
                                                            this.props.schedule.T3 != "" && this.props.schedule.T3 != "emptyurl" ?
                                                                (
                                                                    <div style={this.state.schedulet3styles}>{this.props.schedule.T3}</div>
                                                                )
                                                                :
                                                                ('')
                                                        }
                                                        
                                                        {
                                                            this.props.type.includes("posters") ?
                                                                (
                                                                    <div>
                                                                        {
                                                                            this.state.file1.includes("empty") ?
                                                                                (<div></div>)
                                                                                :
                                                                                (
                                                                                    <div>
                                                                                        <a href={"https://event360live.s3.amazonaws.com/public/" + this.state.code + "/schedule/" + this.state.file1} target="_blank">
                                                                                            {
                                                                                                this.state.file1.includes(".pdf") ?
                                                                                                    (
                                                                                                        <Document
                                                                                                            file={"https://event360live.s3.amazonaws.com/public/" + this.state.code + "/schedule/" + this.state.file1}
                                                                                                            onLoadSuccess={this.onDocumentLoadSuccess}
                                                                                                            className="pdf-thumbnail"
                                                                                                            loading="Loading..."
                                                                                                        >
                                                                                                            <Page pageNumber={this.state.pageNumber} width={150} />
                                                                                                        </Document>
                                                                                                    )
                                                                                                    :
                                                                                                    ('')
                                                                                            }
                                                                                        </a>
                                                                                    </div>
                                                                                )
                                                                        }
                                                                    </div>
                                                                )
                                                                :
                                                                ('')
                                                        }

                                                    </Col>
                                                    <Col md={7}>
                                                        <div className='shedule-details-wrapper'>
                                                            {
                                                                this.props.schedule.DESCRIPTION.includes(" ") ?
                                                                    (<div>
                                                                        <details open={global.$posters.showdesc}>
                                                                            <summary>
                                                                                <h5 style={this.state.scheduletitlestyles}>{this.props.schedule.TITLE}</h5>
                                                                                {
                                                                                    this.props.schedule.TYPE == "F" ?
                                                                                        ('')
                                                                                        :
                                                                                        (
                                                                                            <ScheduleWatchNow
                                                                                                type={this.props.type}
                                                                                                stag={this.props.stag}
                                                                                                currentdate={this.props.currentdate}
                                                                                                currenttime={this.props.currenttime}
                                                                                                sessionstarttime={this.props.sessionstarttime}
                                                                                                sessionendtime={this.props.sessionendtime}
                                                                                                schedule={this.props.schedule}
                                                                                                activetab={this.props.activetab}
                                                                                                action={'live'}
                                                                                            />
                                                                                        )
                                                                                }
                                                                            </summary>
                                                                            
                                                                            <div style={this.state.scheduledescstyles}>{ReactHtmlParser(this.props.schedule.DESCRIPTION)}</div>
                                                                            {
                                                                                this.props.schedule.SP.includes("empty") ?
                                                                                    (
                                                                                        ''
                                                                                    )
                                                                                    :
                                                                                    (
                                                                                        <div>
                                                                                            <ScheduleSpeakers speakers={this.props.schedule.SP} SID={this.props.schedule.SID} />
                                                                                        </div>
                                                                                    )
                                                                            }
                                                                            <h6 style={this.state.schedulespeakerstyles}></h6>
                                                                            
                                                                            {/* <div className='download-presentation-wrapper'>
                                                                               {this.props.schedule.FILE1.trim() !== "" && this.props.schedule.FILE1 !== "empty" ? <a href={"https://event360live.s3.amazonaws.com/public/" + global.$code + "/schedule/" + this.props.schedule.FILE1} target="_blank"><Icon path={mdiDownload} size={0.9} />Download</a>:""}
                                                                               {this.props.schedule.FILE2.trim() !== "" && this.props.schedule.FILE2 !== "empty" ? <a href={"https://event360live.s3.amazonaws.com/public/" + global.$code + "/schedule/" + this.props.schedule.FILE2} target="_blank"><Icon path={mdiDownload} size={0.9} />Download</a>:""}
                                                                               {this.props.schedule.FILE3.trim() !== "" && this.props.schedule.FILE3 !== "empty" ? <a href={"https://event360live.s3.amazonaws.com/public/" + global.$code + "/schedule/" + this.props.schedule.FILE3} target="_blank"><Icon path={mdiDownload} size={0.9} />Download</a>:""}
                                                                            </div> */}

                                                                            <div className='download-presentation-wrapper'>
                                                                               {this.props.schedule.FILE1.trim() !== "" && this.props.schedule.FILE1 !== "empty" ? <a href="#" onClick={(event) => this.generateSingedUrl(event, this.props.schedule.FILE1)} target="_blank"><Icon path={mdiDownload} size={0.9} />Download</a>:""}
                                                                               {this.props.schedule.FILE2.trim() !== "" && this.props.schedule.FILE2 !== "empty" ? <a href="#" onClick={(event) => this.generateSingedUrl(event, this.props.schedule.FILE2)} target="_blank"><Icon path={mdiDownload} size={0.9} />Download</a>:""}
                                                                               {this.props.schedule.FILE3.trim() !== "" && this.props.schedule.FILE3 !== "empty" ? <a href="#" onClick={(event) => this.generateSingedUrl(event, this.props.schedule.FILE3)} target="_blank"><Icon path={mdiDownload} size={0.9} />Download</a>:""}
                                                                            </div>                                                                            

                                                                        </details>
                                                                    </div>
                                                                    )
                                                                    :
                                                                    (
                                                                        <div>
                                                                            <div  className="shedule-titles">
                                                                                <h5 style={this.state.scheduletitlestyles}>{this.props.schedule.TITLE} </h5>
                                                                                {
                                                                                    this.props.schedule.TYPE == "F" ?
                                                                                        ('')
                                                                                        :
                                                                                        (
                                                                                            <ScheduleWatchNow
                                                                                                type={this.props.type}
                                                                                                stag={this.props.stag}
                                                                                                currentdate={this.props.currentdate}
                                                                                                currenttime={this.props.currenttime}
                                                                                                sessionstarttime={this.props.sessionstarttime}
                                                                                                sessionendtime={this.props.sessionendtime}
                                                                                                schedule={this.props.schedule}
                                                                                                activetab={this.props.activetab}
                                                                                                action={'live'}
                                                                                            />
                                                                                        )
                                                                            }
                                                                            </div>
                                                                            {
                                                                                this.props.schedule.SP.includes("empty") ?
                                                                                    (
                                                                                        ''
                                                                                    )
                                                                                    :
                                                                                    (
                                                                                        <div >
                                                                                            <ScheduleSpeakers speakers={this.props.schedule.SP} SID={this.props.schedule.SID} />
                                                                                        </div>
                                                                                    )
                                                                            }
                                                                            <h6 style={this.state.schedulespeakerstyles}></h6>
                                                                            {/* <div className='download-presentation-wrapper'>
                                                                               {this.props.schedule.FILE1.trim() !== "" && this.props.schedule.FILE1 !== "empty" ? <a href={"https://event360live.s3.amazonaws.com/public/" + global.$code + "/schedule/" + this.props.schedule.FILE1} target="_blank"><Icon path={mdiDownload} size={0.9} />Download</a>:""}
                                                                               {this.props.schedule.FILE2.trim() !== "" && this.props.schedule.FILE2 !== "empty" ? <a href={"https://event360live.s3.amazonaws.com/public/" + global.$code + "/schedule/" + this.props.schedule.FILE2} target="_blank"><Icon path={mdiDownload} size={0.9} />Download</a>:""}
                                                                               {this.props.schedule.FILE3.trim() !== "" && this.props.schedule.FILE3 !== "empty" ? <a href={"https://event360live.s3.amazonaws.com/public/" + global.$code + "/schedule/" + this.props.schedule.FILE3} target="_blank"><Icon path={mdiDownload} size={0.9} />Download</a>:""}
                                                                            </div> */}

                                                                            <div className='download-presentation-wrapper'>
                                                                               {this.props.schedule.FILE1.trim() !== "" && this.props.schedule.FILE1 !== "empty" ? <a href="#" onClick={(event) => this.generateSingedUrl(event, this.props.schedule.FILE1)} target="_blank"><Icon path={mdiDownload} size={0.9} />Download</a>:""}
                                                                               {this.props.schedule.FILE2.trim() !== "" && this.props.schedule.FILE2 !== "empty" ? <a href="#" onClick={(event) => this.generateSingedUrl(event, this.props.schedule.FILE2)} target="_blank"><Icon path={mdiDownload} size={0.9} />Download</a>:""}
                                                                               {this.props.schedule.FILE3.trim() !== "" && this.props.schedule.FILE3 !== "empty" ? <a href="#" onClick={(event) => this.generateSingedUrl(event, this.props.schedule.FILE3)} target="_blank"><Icon path={mdiDownload} size={0.9} />Download</a>:""}
                                                                            </div>                                                                            
                                                                        </div>
                                                                    )
                                                            }
                                                        </div>

                                                    </Col>

                                                    <Col md={2} className="shedule-action-wrapper">

                                                        {
                                                            global.$schedule.enableaddtocalender != null && global.$schedule.enableaddtocalender == true ?
                                                                (
                                                                    global.$loginstatus.includes("true") ?
                                                                        (
                                                                            <div>
                                                                                {/*this.displayAddToCalender()*/}
                                                                                <div className="add-to-outlook" style={this.state.calenderstyles} onClick={() => { this.generateICSFile() }}>Add to Calender</div>
                                                                            </div>
                                                                        )
                                                                        :
                                                                        ('')
                                                                )
                                                                :
                                                                ('')
                                                        }
                                                        
                                                        <div className="register-btn-div">
                                                            {
                                                                this.props.schedule.TYPE == "F" ? ('') :
                                                                    (
                                                                            <ScheduleWatchNow
                                                                                type={this.props.type}
                                                                                stag={this.props.stag}
                                                                                currentdate={this.props.currentdate}
                                                                                currenttime={this.props.currenttime}
                                                                                sessionstarttime={this.props.sessionstarttime}
                                                                                sessionendtime={this.props.sessionendtime}
                                                                                schedule={this.props.schedule}
                                                                                activetab={this.props.activetab}
                                                                                action={'watchnow'}
                                                                            />
                                                                    )
                                                            }
                                                            {
                                                                this.props.schedule.REGREQUIRED == "Yes" && this.props.schedule.REGSTATUS == "Open" ?
                                                                    (
                                                                        <SessionRegistration
                                                                            sessionstarttime={this.props.sessionstarttime}
                                                                            schedule={this.props.schedule}
                                                                            refreshScheduleItem={this.refreshScheduleItem}
                                                                        />
                                                                    )
                                                                    :
                                                                    ('')
                                                            }
                                                        </div>
                                                        
                                                    </Col>
                                                </Row>
                                        )
                                }
                            </div>
                        )
                        :
                        (
                            <Row>
                                <Col md={12}>
                                    <SessionTracks sessionid={this.props.schedule.SID} />
                                </Col>
                            </Row>

                        )
                }
                < hr />
            </div>
        )
    }

    refreshScheduleItem() {
        //console.log("refresh")
        this.props.refreshScheduleTabs();
    }


    generateICSFile() {
        let apiName = serviceNames.getDataService();
        //let apiName = 'DataService';
        let path = ''; //replace this with the path you have configured on your API
        var date = new Date();
        var longformat = date / 1000;
        //console.log("generateICSFile");
        let myInit = {
            body:
            {
                'command': "generateICSFile",
                'code': global.$code,
                'bucket': "event360live",
                'table': global.$code,
                'title': this.props.schedule.TITLE,
                'begindate': moment(this.props.schedule.BEGIN).format("YYYYMMDDTHHmmss"),
                'enddate': moment(this.props.schedule.END).format("YYYYMMDDTHHmmss"),
                'filename': `${global.$code}_${this.props.schedule.SID}_${longformat.toString()}`
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //console.log(myInit);

        API.post(apiName, path, myInit).then(response => {
            //console.log("response.data ===> " + response.data + "response.err ===> " + response.err);
            this.setState({ isLoading: false })
            //console.log(response);
            if (response.data == null) {

                var filepath = `https://event360live.s3.amazonaws.com/public/${this.state.code}/generated/ical/${global.$code}_${this.props.schedule.SID}_${longformat.toString()}.ics`;

                //console.log(filepath);
                window.open(filepath);
            }
            else {
                //console.log("getAttendeesTableObjects - failed!!");
                //console.log(response.err);

            }
        }
        ).catch(error => {
            //console.log(error.response)
        });
    }

    async generateSingedUrl(event, filename) {
        console.log("generateSingedUrl");

        event.preventDefault();
    
        try {
          let apiName = serviceNames.getUserService();
          let path = ""; //replace this with the path you have configured on your API
    
          let myInit = {
            body: {
              code: this.state.code,
              command: "getSignedUrl",
              bucket: "wesicore",
              key: `event360/${this.state.code}/upload/documents/${filename}`
            },
            response: true,
            headers: {},
          };
      
          console.log({myInit});
      
          let result = await API.post(apiName, path, myInit);
          console.log(result);
    
          if(result.data) {
            window.open(result.data)
          }
    
        } catch (error) {
          console.log(error);
        }
      }

    /*displayAddToCalender() {
        //var bdate = moment(this.props.schedule.BEGIN).format();
        var begindate = moment(this.props.schedule.BEGIN).format("YYYY-MM-DD HH:MM");
       //console.log(begindate);
        var bdate = moment().tz(`${begindate}`,`${global.$settings.timezone}`).format();
        
        var enddate = moment(this.props.schedule.END).format("YYYY-MM-DD HH:MM");
       //console.log(enddate);
        var edate = moment().tz(`${enddate}`,`${global.$settings.timezone}`).format();

       //console.log(bdate,edate);

        let icon = { 'calendar-plus-o': 'left' };
        let items = [
            { apple: 'Apple' },
            { google: 'Google' },
            { outlook: 'Outlook' },
            { yahoo: 'Yahoo!' }
        ];
        var calender = {
            title: this.props.schedule.TITLE,
            //description: this.props.schedule.DESCRIPTION,
            description:"",
            location: global.$settings.timezone,
            startTime: `${bdate}`,
            endTime: `${edate}`
        }
        //console.log(calender);
        return (
            <div className="mysession-add-to-calender">
                <AddToCalendar
                    event={calender}
                    buttonLabel="Add to Calender"
                    buttonTemplate={icon}
                    listItems={items}
                    displayItemIcons={false}
                >
                </AddToCalendar>
            </div>
        )
    }*/
}
export default ScheduleItem;


