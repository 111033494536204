/* eslint-disable */
import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import { API } from 'aws-amplify';
import LoadPrivateImg from '../Components/Shared/LoadPrivateImg';
var serviceNames = require('../ServiceNameMapper')

class MeetupAttendees extends React.Component {
    constructor(props) {

        super(props);

        this.state = {
            code: global.$code,
            dataloaded: false,
            signedupusers: []
        };
    }


    render() {
        if (this.state.dataloaded) {
           //console.log(this.state.signedupusers.length)
            if (this.state.signedupusers.length != 0) {
                return this.state.signedupusers.map(signup => {
                    return (
                        <div className="meetup-tabs-users-list">
                            {
                                this.props.meetup.meetupid == signup.data3 ?
                                    (
                                        this.displayAttendees(this.props.meetup, signup)
                                    )
                                    :
                                    ('')
                            }
                        </div>
                    )
                })
            }
            else {
                return (
                    <div className="no-attendees-signedup">
                        No attendees are signed up
                    </div>
                )
            }
        }
        else {
            if (global.$meetupattendees == null) {
                this.getAttendees();
            }
            else {
                this.getSignups(this.props.meetup);
            }
            return (
                <div>
                    <Row>
                        <Col md={6} className="offset-5">
                            <div className="loading-div">
                                <div className="loading-text">Loading....</div>
                            </div>
                        </Col>
                    </Row>
                </div>
            )
        }
    }

    displayAttendees(meetup, signup) {

        var meetupconfig = global.$config["meetups"].tabs.attendees;

        return global.$meetupattendees.map(item => {
            if (item.userid == signup.userid) {
                return (
                    <div className="meetup-attendee" style={meetupconfig.styles.maindiv}>
                        <Row>
                            <Col md={1} className="meetup-attendee-img" style={meetupconfig.styles.imgdiv}>
                                <LoadPrivateImg id={item.userid} name={item.fullname} component="meetups" code={global.$code} width="50" height="50" />
                            </Col>
                            <Col md={3} className="meetup-attendee-name" style={meetupconfig.styles.name}>
                                {item.fullname}
                            </Col>
                            {
                                meetupconfig.enableemail == true ?
                                    <Col md={3} className="meetup-attendee-email" style={meetupconfig.styles.email}>
                                        {item.userid}
                                    </Col>
                                    :
                                    null
                            }
                            {
                                meetupconfig.enableemail == true ?
                                    <Col md={3} className="meetup-attendee-phone" style={meetupconfig.styles.phone}>
                                        {item.phone}
                                    </Col>
                                    :
                                    null
                            }
                            <Col md={2}>

                            </Col>
                        </Row>
                        <hr />
                    </div>
                )
            }
        });

    }

    getAttendees() {
        let apiName = serviceNames.getUserService();
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'code': this.state.code,
                'command': 'getUsers'
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //console.log(myInit);

        API.post(apiName, path, myInit).then(response => {
            //console.log ("response.data ===> " + response.data + "response.err ===> " + response.err);
            //console.log(response);
            if (response.err == null && response.data != null) {
                //console.log("getAttendeesTableObjects - Success!!");
                //console.log(response.data);

                var attendees = this.getAttendeesTableObjects(response.data)
                global.$meetupattendees = attendees;

                this.getSignups(this.props.meetup);
            }
            else {
                //console.log(response.err);
            }
        }
        ).catch(error => {
            //console.log(error)
        });
    }

    getAttendeesTableObjects(objs) {
        //console.log(objs.length);

        const tableObjs = []

        for (var i = 0; i < objs.length; i++) {
            var attendeeObj = objs[i]

            //console.log(attendeeObj)

            var newObj = Object()

            newObj.userid = attendeeObj.userid.S
            newObj.fullname = attendeeObj.fname.S + " " + attendeeObj.lname.S
            newObj.phone = attendeeObj.phone.S

            tableObjs.push(newObj)
        }

        //console.log(tableObjs)

        return tableObjs
    }

    getSignups(meetup) {
        let apiName = 'MeetupService';
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'code': global.$code,
                'command': 'getsignedupusers',
                'ownerid': meetup.ownerid,
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }

        //console.log(myInit);
        API.post(apiName, path, myInit).then(response => {
            //console.log(response);

            if (response.err == null && response.data != null) {
                //console.log(response.data.Items);
                var signupdata = this.getSignupTableObjects(response.data.Items);
                //console.log(signupdata);
                this.setState({
                    signedupusers: signupdata,
                    dataloaded: true
                });

            }
            else {
                //console.log(response.err);
            }
        }
        ).catch(error => {
            //console.log(error)
        });
    }
    getSignupTableObjects(objs) {
        //console.log(objs);

        const tableObjs = []

        for (var i = 0; i < objs.length; i++) {
            var signupObj = objs[i]

            //console.log(signupObj)

            var newObj = Object()

            newObj.userid = signupObj.userid.S
            newObj.ownerid = signupObj.ownerid.S
            newObj.signupid = signupObj.signupid.S
            newObj.status = signupObj.signupstatus.S
            newObj.signuptype = signupObj.signuptype.S
            newObj.data1 = signupObj.data1.S
            newObj.data2 = signupObj.data2.S
            newObj.data3 = signupObj.data3.S
            newObj.data4 = signupObj.data4.S
            newObj.groupname = signupObj.groupname.S

            tableObjs.push(newObj)
        }

        //console.log(tableObjs)

        return tableObjs
    }
} //Class

export default MeetupAttendees;