/* eslint-disable */
import React, { Component } from 'react';
import { FormGroup, Label, Input, FormText, Card, CardHeader, CardBlock, Row, Col, Spinner, CardBody, Button } from 'reactstrap';
import { Storage, API } from 'aws-amplify';
import { Link } from 'react-router-dom';
import { mdiFormatTitle, mdiCalendar, mdiClock, mdiNote, mdiLink, mdiWeb, mdiStarFourPoints, mdiCounter } from '@mdi/js';
import Icon from '@mdi/react';
import "./Meetups.css";
import Avatar from 'react-avatar';

const moment = require('moment-timezone'); //moment-timezone
var serviceNames = require('../ServiceNameMapper');

class MeetupForm extends Component {
    render() {
        var meetupconfig = global.$config["meetups"].form;
        var styles = global.$config["meetups"].form.styles;
        return (
            <div>
                <Row>
                    <Col md={12}>
                        <form className="meetup-form" style={styles.form}>
                            <Card>
                                <CardHeader className="meetup-form-header" style={styles.header}>{meetupconfig.header}</CardHeader>
                                <CardBody className="meetup-card-block">

                                    <FormGroup className="form-labels" style={this.state.photodivstyles}>
                                        <div id="meetup-form-photo-div">
                                            <Label className="meetup-form-label" for="photo" className="meetup-form-custom-fileupload">
                                                {
                                                    this.state.imageUrl != null && this.state.imageUrl == "" || this.state.imageUrl == " " ?
                                                        (
                                                            <Avatar className="meetup-form-avatar" name="Profile Photo" />
                                                        )
                                                        :
                                                        (
                                                            <img id="meetup-form-photo" src={this.state.imageUrl || ''} />
                                                        )
                                                }
                                            </Label>
                                            <input ref="uploadImg" type="file" name="photo" className="meetup-form-upload-file" id="photo" accept=".gif,.jpg,.jpeg,.png" onChange={this.fileUploader} />
                                            <p class="meetup-form-penicon-text"><span className="meetup-form-pen-icon">&#9998;</span></p>
                                        </div>
                                        <br />
                                        <div id="meetup_form_photo_upload_message"></div>
                                    </FormGroup>

                                    <FormGroup className="meetup-form-group" style={styles.formgroup}>
                                        {/* <Label for="title"><Icon path={mdiFormatTitle} size={0.9} color="lightgray" className="meetup-form-icon" />Title*</Label> */}
                                        <Label for="title" style={styles.label} className="meetup-form-label meetup-form-title">Title*</Label>
                                        <Input type="text" name="title" className="form-control-sm meetup-form-title-input" id="title" value={this.state.title || ''} onChange={this.handleInputChange} required />
                                    </FormGroup>
                                    <Row >
                                        <Col md={6}>
                                            <FormGroup className="meetup-form-group">
                                                <Label for="begindate" style={styles.label} className="meetup-form-label meetup-form-begindate">Begin Date*</Label>
                                                <Input type="date" name="begindate" className="form-control-sm" id="begindate" value={this.state.begindate || ''} onChange={this.handleInputChange} required />
                                            </FormGroup>
                                        </Col>

                                        <Col md={6} >
                                            <FormGroup className="meetup-form-group">
                                                <Label for="begintime" style={styles.label} className="meetup-form-label meetup-form-begintime">Begin Time*</Label>
                                                <Input type="time" name="begintime" className="form-control-sm" id="begintime" value={this.state.begintime || ''} onChange={this.handleInputChange} required />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6} >
                                            <FormGroup className="meetup-form-group">
                                                <Label for="enddate" style={styles.label} className="meetup-form-label meetup-form-enddate">End Date*</Label>
                                                <Input type="date" name="enddate" className="form-control-sm" id="enddate" value={this.state.enddate || ''} onChange={this.handleInputChange} required />
                                            </FormGroup>
                                        </Col>

                                        <Col md={6} >
                                            <FormGroup className="meetup-form-group">
                                                <Label for="endtime" style={styles.label} className="meetup-form-label meetup-form-endtime">End Time*</Label>
                                                <Input type="time" name="endtime" className="form-control-sm" id="endtime" value={this.state.endtime || ''} onChange={this.handleInputChange} required />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6} >
                                            <FormGroup className="meetup-form-group">
                                                <Label for="connectiontype" style={styles.label} className="meetup-form-label meetup-form-connectiontype">Connection Type*</Label>
                                                <Input type="select" name="connectiontype" className="form-control-sm" id="connectiontype" value={this.state.connectiontype || ''} onChange={this.handleInputChange} required>
                                                    <option value="Select">Select</option>
                                                    <option value="Zoom">Zoom</option>
                                                    <option value="DailyCo">Default</option>
                                                    <option value="Other">Other</option>
                                                </Input>
                                                <FormText className="meetup-form-formtext">Note</FormText>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6} >
                                            <FormGroup className="meetup-form-group">
                                                <Label for="connectionurl" style={styles.label} className="meetup-form-label meetup-form-cconnectionurl">Connection URL</Label>
                                                <Input type="text" name="connectionurl" className="form-control-sm meetup-form-connectionurl-input" id="connectionurl" value={this.state.connectionurl || ''} onChange={this.handleInputChange} required />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6} >
                                            <FormGroup className="meetup-form-group">
                                                <Label for="meetupstatus" style={styles.label} className="meetup-form-label meetup-form-status">Status*</Label>
                                                <Input type="select" name="meetupstatus" className="form-control-sm" id="meetupstatus" value={this.state.meetupstatus || ''} onChange={this.handleInputChange} required>
                                                    <option value="Select">Select</option>
                                                    <option value="Open">Open</option>
                                                    <option value="Close">Close</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup className="meetup-form-group">
                                                <Label for="signuprequired" style={styles.label} className="meetup-form-label meetup-form-signup">Signup Required*</Label>
                                                <Input type="select" name="signuprequired" className="form-control-sm" id="signuprequired" value={this.state.signuprequired || ''} onChange={this.handleInputChange} required>
                                                    <option value="Select">Select</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <FormGroup className="meetup-form-group">
                                        <Label for="signupmax" style={styles.label} className="meetup-form-label meetup-form-maxsignups">Max Signups Allowed*</Label>
                                        <Input type="number" min="1" name="signupmax" className="form-control-sm meetup-form-maxsignups-input" id="signupmax" value={this.state.signupmax || ''} onChange={this.handleInputChange} required />
                                    </FormGroup>
                                    <FormGroup className="meetup-form-group">
                                        <Label for="desc" style={styles.label} className="meetup-form-label meetup-form-desc">Description</Label>
                                        <Input type="textarea" name="desc" className="form-control-sm meetup-form-desc-input" id="desc" value={this.state.desc || ''} onChange={this.handleInputChange} />
                                    </FormGroup>
                                </CardBody>
                            </Card>

                            <div className="meetup-form-message" id="message" style={styles.message}>
                                {this.state.message}
                            </div>
                            <div className="meetup-form-btn-div" style={styles.buttondiv}>
                                <Button type="submit" className="btn meetup-form-submit-button" onClick={this.handleSubmit} style={styles.button}>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        aria-hidden="true"
                                        className="meetup-form-loading-button"
                                        variant="success"
                                        hidden={!this.state.isLoading} />
                                            Submit
                                    </Button>
                            </div>
                        </form>
                    </Col>
                </Row>
            </div>
        );
    }

    constructor(props) {
        //console.log("userid =" + props.match.params.value);
        var meetupconfig = global.$config["meetups"];
       //console.log(meetupconfig);

       //console.log(props);
        //console.log(poolid);

        super(props);
        this.state =
        {
            code: global.$code,
            bucket: props.bucket,
            title: "",
            begindate: "",
            begintime: "",
            enddate: "",
            endtime: "",
            connectiontype: "DailyCo",
            connectionurl: "",
            meetupstatus: "Open",
            signupmax: "10",
            desc: "",
            signuprequired: "Yes",
            signupcount: "0",
            data1: " ",
            data2: " ",
            data3: " ",
            data4: " ",
            data5: " ",
            isLoading: false,
            photoFileName: '',
            photoFileHandle: '',
            imageUrl: '',
            video: ''
            //code: '',
        };
        if(props.action == "edit")
        {
            this.state.meetupid = props.meetup.meetupid;
            this.state.ownerid = props.meetup.ownerid;
            this.state.title = props.meetup.title;

           //console.log(props.meetup.begintime);
            var [bdate, btime] = props.meetup.begintime.split(",");
            bdate = moment(bdate, "MM/DD/YYYY").format("YYYY-MM-DD");
            btime = moment(btime, "hh:mm A").format("HH:mm");

            var [edate, etime] = props.meetup.endtime.split(",");
            edate = moment(edate, "MM/DD/YYYY").format("YYYY-MM-DD");
            etime = moment(etime, "hh:mm A").format("HH:mm");

           //console.log(bdate, edate, btime, etime);
            this.state.begindate = bdate;
            this.state.begintime = btime;
            this.state.enddate = edate;
            this.state.endtime = etime;
            this.state.connectiontype = props.meetup.connectiontype;
            this.state.connectionurl = props.meetup.connectionurl;
            this.state.meetupstatus = props.meetup.status;
            this.state.signupmax = props.meetup.signupmax;
            this.state.signuprequired = props.meetup.signuprequired;
            this.state.signupcount = props.meetup.signupcount;
            this.state.desc = props.meetup.desc;
            this.state.data1 = props.meetup.data1;
            this.state.data2 = props.meetup.data2;
            this.state.data3 = props.meetup.data3;
            this.state.data4 = props.meetup.data4;
            this.state.data5 = props.meetup.data5;
            this.state.video = props.meetup.video;
            this.state.photoFileName = props.meetup.photo;
            this.state.imageUrl = `https://s3.amazonaws.com/event360live/public/${this.state.code}/meetups/${this.state.photoFileName}?${new Date().getTime()}`;

        }
        else
        {
            var date = new Date();
            var timestamp = date/1000;

            this.state.meetupid = timestamp.toString();
            this.state.ownerid = global.$userid;
        }
        //console.log(this.state.userid)
        this.state.message = "";
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.fileUploader = this.fileUploader.bind(this);

    }

    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        let value = target.value

       //console.log( "Target = " + name + ", Value = " + value);

        this.setState({ [name]: value });

        //console.log(this.state);
        //this.setState({registrationtype:value})
    }

    validateInputs() {

        if(this.state.connectiontype == "Zoom")
        {
            if (this.props.code === "" || this.state.title === "" || this.state.begindate === "" || this.state.enddate === "" || this.state.begintime === "" || this.state.endtime === "" || this.state.signuprequired === "" || this.state.signupmax === "" || this.state.connectiontype === "" || this.state.meetupstatus === "" || this.state.connectionurl === "") {
                this.state.message = "Enter all the required fields and submit";
                this.forceUpdate();
                return false;
            }
        }
        else if (this.props.code === "" || this.state.title === "" || this.state.begindate === "" || this.state.enddate === "" || this.state.begintime === "" || this.state.endtime === "" || this.state.signuprequired === "" || this.state.signupmax === "" || this.state.connectiontype === "" || this.state.meetupstatus === "") {
            this.state.message = "Enter all the required inputs and click submit";
            this.forceUpdate();
            return false;
        }
        return true;
    }
    clearInputs = () => {
        this.state.title = "";
        this.state.begindate = "";
        this.state.begintime = "";
        this.state.enddate = "";
        this.state.endtime = "";
        this.state.connectiontype = "";
        this.state.connectionurl = "";
        this.state.meetupstatus = "";
        this.state.signupmax = "";
        this.state.desc = "";
        this.state.signuprequired = "";
        this.state.photoFileHandle = "";
        this.state.imageUrl = "";
        this.state.video = "";
    }
    handleSubmit(event) {
        event.preventDefault();

        if (this.validateInputs()) {
            if(this.state.connectiontype == "DailyCo" && this.props.action == "add")
            {
                this.createDailyCoMeeting();
            }
            else
            {
                this.submitProfile(event);
            }
            this.setState({ isLoading: true });
        }
    }

    getFileName(fileName) {
        var timestamp = (new Date())/1000;
        var key = timestamp.toString();
        //key = key.replace(/,/g, '_');
        //key = key.replace(/ /g, '_');
        //key = key.replace(/@/g, '_');
        //key = key.replace(/&/g, '_');
        key = key.replace(/\./g, '');
        //key = key.replace(/__/g, '_');
        //console.log(key);
        key = key.toLowerCase();

        fileName = fileName.toLowerCase();

        if (fileName.indexOf("jpg") !== -1) {
            key = key + ".png";
        }
        else if (fileName.indexOf("jpeg") !== -1) {
            key = key + ".png";
        }
        else if (fileName.indexOf("gif") !== -1) {
            key = key + ".png";
        }
        else if (fileName.indexOf("png") !== -1) {
            key = key + ".png";
        }
        else {
            this.state.photoFileName = "Format not supported";
        }

        //console.log(key);
        if (key == "-1" || key == "-1.png" || key == "-1.jpg" || key == "-1.jpeg") {
            if (global.$userid != null) {
                key = global.$userid + ".png";
            }
            else {
                key = this.state.userid + ".png";
            }
        }

        return key;
    }

    getS3FileName() {
        return global.$code + "/meetups/" + this.state.photoFileName;
    }

    //files
    fileUploader = (event) => {

        document.getElementById("meetup_form_photo_upload_message").innerHTML = "<h6 style='color: green'>Uploading...</h6>";
        //file preview
        const file = this.refs.uploadImg.files[0]
        const reader = new FileReader();

        //////console.log("fileUploader");
        reader.onloadend = () => {
            this.setState({
                imageUrl: reader.result
            })
        }
        if (file) {
            reader.readAsDataURL(file);
            this.setState({
                imageUrl: reader.result
            })
        }
        else {
            this.setState({
                imageUrl: " "
            })
        }

        //file handler
        const target = event.target;
        const name = target.name;
        let value = target.value

        if (name === 'photo') {
            this.state.photoFileHandle = event.target.files[0];

            this.state.photoFileName = this.getFileName(this.state.photoFileHandle.name);
            //console.log("photoFileName = " + this.state.photoFileHandle.name);
        }
        else {
            this.setState(
                {
                    [name]: value
                }
            );
        }

        //file storage
        const s3File = this.getS3FileName();
        //console.log(s3File);
        //console.log('file=' +this.refs.uploadImg.files[0].name+", Des ="+s3File);

        Storage.put(s3File, this.state.photoFileHandle,
            {
                contentType: 'image/png'
            })
            .then(
                result => {
                    //console.log(result);
                    document.getElementById("meetup_form_photo_upload_message").innerHTML = "<h6 style='color: green'></h6>";

                    this.forceUpdate();
                }
            )
            .catch(err => {
                //console.log(s3File)
                //console.log(this.state.photoFileHandle)
                //console.log(err)
                document.getElementById("meetup_form_photo_upload_message").innerHTML = "<h6 style='color: red'>Unable to upload image. Please try again</h6>";
                //this.state.message = "Unable to upload photo. Please try again"
                this.forceUpdate();

            }
            );

    }

    submitProfile(event) {
        let apiName = 'MeetupService';
        let path = ''; //replace this with the path you have configured on your API

        var date = new Date();
        var timestamp = date / 1000;
        var bdate = moment(this.state.begindate + "," + this.state.begintime).format("MM/DD/YYYY, hh:mm A");
        var edate = moment(this.state.enddate + "," + this.state.endtime).format("MM/DD/YYYY, hh:mm A");

        let myInit =
        {
            body:
            {
                'command': 'addMeetup',
                'code': global.$code,
                'meetupid': this.state.meetupid,
                'title': this.state.title,
                'ownerid': this.state.ownerid,
                'begintime': bdate,
                'endtime': edate,
                'description': this.state.desc,
                'signuprequired': this.state.signuprequired,
                'meetupstatus': this.state.meetupstatus,
                'connectiontype': this.state.connectiontype,
                'connectionurl': this.state.connectionurl,
                'signupcount': this.state.signupcount,
                'signupmax': this.state.signupmax,
                'photo': this.state.photoFileName,
                'video': this.state.video,
                'data1': this.state.data1,
                'data2': this.state.data2,
                'data3': this.state.data3,
                'data4': this.state.data4,
                'data5': this.state.data5
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
    //console.log(myInit);

        API.post(apiName, path, myInit).then(response => {

         //console.log(response);
            if (response.err == null) {
               //console.log("refresh");
                this.state.message = "Meetup successfully added";
                this.props.refreshAddMeetup();
                this.clearInputs()
                this.setState({ isLoading: false });
               //console.log("refresh");
            }
            else {
                this.state.message = "Error! Unable to add meetup. Please try again.";
                this.setState({ isLoading: false });
            }

        }).catch(error => {
           //console.log(error)
            this.setState({ isLoading: false });
        });
    }

    createDailyCoMeeting()
    {
        let apiName = serviceNames.getZoomService();
        // let apiName = 'ZoomService';
        let path = ''; //replace this with the path you have configured on your API
        var date = new Date();
        var timestamp = date/1000;
        timestamp = timestamp.toString();
        timestamp = timestamp.replace(/\./g, "");
        timestamp = timestamp.substring(timestamp.length - 8, timestamp.length);

       //console.log(global.$code+"_"+timestamp+"_"+global.$userid);
        var name = global.$code+"_"+timestamp+"_"+global.$userid;
        name = name.replace(/@/g, "_")
       //console.log(name, name.length);
        if(Number(name.length) > Number(30))
        {
            name = name.slice(0, 30);
        }
       //console.log(name);
        let myInit = {
            body:
            {
                'code'      : global.$code,
                'command'   : 'createDailCoMeeting',
                'name'      : name
            },
            response: true,
            convertEmptyValues:true,
            headers: {}
        }
        //console.log(myInit);

        API.post(apiName, path, myInit).then( response =>
            {
            //console.log(response);

                if (response.err == null && response.data!= null)
                {
                   this.setState({ connectionurl: response.data });
                  //console.log(this.state.connectionurl)
                   this.submitProfile();
                }
                else
                {
                    //console.log("getReportsTableObjects - failed!!");
                 //console.log(response.err);
                }

            }
        ).catch(error =>
        {
           //console.log(error)
        });
    }
}

export default MeetupForm;
