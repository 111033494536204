import React from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { Input, Row, Col, Button } from 'reactstrap';
import SponsorContactForm from '../Sponsors/SponsorContactForm';
import ReactPlayer from 'react-player';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import './VirtualExpo.css';
import Appointments from '../Appointments/Appointments';
import VirtualExhibitorInfo from './VirtualExhibitorInfo';
import AvailableAppointments from '../Appointments/AvailableAppointments';
import { Link } from 'react-router-dom';
import ConversationView from '../Delegates/ConversationView';
import UpdatePoints from '../Points/UpdatePoints';

const moment = require('moment-timezone');

class VirtualPopupTabs extends React.Component {
    constructor(props) {
        super(props);
        //console.log(props)
        //console.log(props.exhibitor);
        var videochatlogo = '';
        var popuptabstyle = {};
        if (global.$virtualexpo == null) {
            videochatlogo = global.$session.zoomlogo
        }
        else {
            videochatlogo = global.$virtualexpo.videochat.logo
            popuptabstyle = global.$virtualexpo.popuptabs.style
        }
        this.state = {
            code: global.$code,
            s3: global.$s3,
            bucket: global.$s3bucket,
            videourl: props.exhibitor.VIDEO1,
            email: props.exhibitor.EMAIL,
            //chaturl: 'https://apps4org.daily.co/virtualsummit-exhibitor1'
            chaturl: props.exhibitor.MEETING1,
            schedule: props.exhibitor.SCHEDULE,
            enablechat: false,
            data1: props.exhibitor.DATA1,
            videochatlogo: videochatlogo,
            popuptabstyle: popuptabstyle,
            playing: false
        };
        //console.log(this.state);
        if (this.state.schedule == "" || this.state.schedule == " ") {
            this.state.schedule = "empty"
        }
        if (this.state.videourl == "" || this.state.videourl == " ") {
            this.state.videourl = "empty";
        }
    }

    render() {
        return (
            <div>
                <Tabs className="tabs-menu" defaultActiveKey="info" transition={false} id="tabs" >
                    <Tab eventKey="info" title="Info" className="tab-item">
                        <div className="virtual-info-div" width="100%" height="calc(80vh - 100px)" style={this.state.popuptabstyle}>
                            <VirtualExhibitorInfo exhibitor={this.props.exhibitor} />
                            {
                                global.$points.exhibitorvisit != null && global.$points.exhibitorvisit.enable.includes("Y") ?
                                    (
                                        <UpdatePoints sponsor={this.props.exhibitor} component="exhibitor" area="exhibitorvisit" points={global.$points.exhibitorvisit.points} />
                                    )
                                    :
                                    ('')
                            }
                        </div>
                    </Tab>
                    {
                        this.state.videourl.includes("empty") ?
                            ('')
                            :
                            (
                                <Tab eventKey="video" title="Video" className="tab-item">

                                    <div className="virtual-video-div">
                                        <ReactPlayer
                                            url={this.state.videourl}
                                            width="100%"
                                            height="450px"
                                            controls={true}
                                            className="virtual-video-player"
                                            playing={this.state.playing}
                                            onContextMenu={e => e.preventDefault()}
                                            config={{
                                                file: {
                                                    attributes: {
                                                        controlsList: 'nodownload',
                                                        disablepictureinpicture: 'true',
                                                    }
                                                }
                                            }}
                                            onPlay={() => { this.setState({ playing: true }) }}
                                        />
                                        {console.log(this.state.playing)}
                                        {
                                            this.state.playing ?
                                                (
                                                    <div>
                                                        {
                                                            global.$points.exhibitorvideo != null && global.$points.exhibitorvideo.enable.includes("Y") ?
                                                                (
                                                                    <UpdatePoints sponsor={this.props.exhibitor} component="exhibitor" area="exhibitorvideo" points={global.$points.exhibitorvideo.points} />
                                                                )
                                                                :
                                                                ('')
                                                        }
                                                    </div>
                                                )
                                                :
                                                ('')
                                        }
                                    </div>
                                </Tab>
                            )
                    }
                    {
                        this.state.data1.includes("videochat") ?
                            (

                                <Tab eventKey="videochat" title="Video Chat" className="tab-item">
                                    {
                                        global.$loginstatus.includes(true) ?
                                            (
                                                <div>
                                                    {this.getVideoChat()}
                                                    <div className="virtual-videochat-div" style={this.state.popuptabstyle}>
                                                        {
                                                            this.state.enablechat == true ?
                                                                (
                                                                    <div>
                                                                        {
                                                                            this.state.chaturl.includes("zoom") ?
                                                                                (
                                                                                    <div style={this.state.loginbuttonstyle} className="zoom-logo">
                                                                                        <div dangerouslySetInnerHTML={{ __html: this.state.videochatlogo }}></div>
                                                                                        <br />
                                                                                        <a href={this.state.chaturl} target="_blank" style={{ color: 'white' }}><Button type="submit" className={" btn btn-" + global.$session.styles.loginbuttonbgcolor} style={this.state.loginbuttonstyle}><i className='fa fa-video' style={{ fontSize: "15px", color: "white", background: "#129CF3", padding: "7px", borderRadius: "12px", marginRight: "15px" }}></i>Join</Button></a>
                                                                                    </div>
                                                                                )
                                                                                :
                                                                                ('')
                                                                        }

                                                                        {
                                                                            this.state.chaturl.includes("daily") ?
                                                                                (
                                                                                    <div>
                                                                                        {
                                                                                            global.$virtualexpo.videochat.externallink != null && global.$virtualexpo.videochat.externallink.enable == true ?
                                                                                            (
                                                                                                <div>
                                                                                                    <div dangerouslySetInnerHTML={{ __html: global.$virtualexpo.videochat.externallink.div }}></div>
                                                                                                    <div className="dailyco-launch-meeting offset-md-5" style={global.$virtualexpo.videochat.externallink.style}><a href={ `https://wesicore.s3.amazonaws.com/event360/${this.state.code}/public/dailyco.html?id=${this.state.chaturl}`} target="_blank">{global.$virtualexpo.videochat.externallink.text}</a></div>                                                                                                  
                                                                                              </div>
                                                                                            )
                                                                                            :
                                                                                            (
                                                                                                <div className='dailyco-wrapper'>
                                                                                                    <iframe src={this.state.chaturl}
                                                                                                        title="dailyco"
                                                                                                        id="myId"
                                                                                                        className="virtual-video-chat"
                                                                                                        display="initial"
                                                                                                        position="relative"
                                                                                                        width="100%"
                                                                                                        allow="camera *;microphone *"
                                                                                                        allowFullScreen={true}                                                   
                                                                                                    />
                                                                                                    <a href={this.state.chaturl} style={global.$sponsors.videochatbuttonstyles} className="new-tab-btn" target="_blank" onClick={()=>this.props.onClose()}>Open Full Screen</a>
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                        {/* <div className="virtual-video-chat" dangerouslySetInnerHTML={{ __html: `<object type="text/html" style='width: 600px; height:600px' data=${this.state.chaturl} ></object>` }}></div> */}
                                                                                        
                                                                                    </div>
                                                                                )
                                                                                :
                                                                                ('')

                                                                        }
                                                                    </div>
                                                                )
                                                                :
                                                                (
                                                                    <div className="sponsor-schedule-div" style={this.state.popuptabstyle}>
                                                                        <Row>
                                                                            <Col md={3}>
                                                                                <div className="virtual-exhibitor-logo-div">
                                                                                    <img className="virtual-exhibitor-logo" src={`https://s3.amazonaws.com/event360live/public/${global.$code}/exhibitors/${this.props.exhibitor.LOGO}`} />
                                                                                </div>
                                                                            </Col>
                                                                            <Col md={8} className="offset-md-1">
                                                                                <p className="sponsor-schedule-header">Video Chat Schedule</p>
                                                                                <hr />
                                                                                {this.getVideoChatSchedule()}
                                                                                <p className="video-chat-offline-message">*Currently video chat is offline</p>
                                                                                <a href={this.state.chaturl} target="_blank" className="new-tab-btn" onClick={()=>this.props.onClose()}>Test Video Chat</a>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                )
                                                        }
                                                    </div>
                                                </div>
                                            )
                                            :
                                            (
                                                <div className="videochat-login-message">
                                                    You must login to see the video chat <br />
                                                    <Link to={`/LogInForm`} className="videochat-login">Click here to login</Link>
                                                </div>
                                            )
                                    }
                                </Tab>

                            )
                            :
                            (
                                ''
                            )
                    }
                    {
                        this.state.data1.includes("contact") ?
                            (
                                <Tab eventKey="contact" title="Contact" className="tab-item">
                                    <div className="virtual-contact-div" style={this.state.popuptabstyle}>
                                        <SponsorContactForm email={this.state.email} exhibitor={this.props.exhibitor} />
                                    </div>
                                </Tab>
                            )
                            :
                            (
                                ''
                            )
                    }
                    {
                        this.state.data1.includes("chatexhibitor") ?
                            (
                                <Tab eventKey="chat" title="Chat" className="tab-item">
                                    {
                                        global.$loginstatus.includes(true) ?
                                            (
                                                global.$userstype == "Exhibitor" ?
                                                    (
                                                        <div className="appointment-login-message" style={this.state.popuptabstyle}>
                                                            You must go to Attendee List see the chat<br />
                                                            <Link to={`/DelegatesList2`} className="videochat-login">Click here to go to Attendee List</Link>
                                                        </div>
                                                    )
                                                    :
                                                    (
                                                        <div className="virtual-chat-div" style={this.state.popuptabstyle}>
                                                            <ConversationView delegateid={this.state.email} delegatename={this.props.exhibitor.NAME} loginid={global.$userid} type="exhibitors" />
                                                        </div>
                                                    )
                                            )
                                            :
                                            (
                                                <div className="appointment-login-message" style={this.state.popuptabstyle}>
                                                    You must login to see the chat<br />
                                                    <Link to={`/LogInForm`} className="videochat-login">Click here to login</Link>
                                                </div>
                                            )
                                    }
                                </Tab>
                            )
                            :
                            (
                                ''
                            )
                    }
                    {
                        this.state.data1.includes("appointments") ?
                            (
                                <Tab eventKey="appointments" title="Appointments" className="appointments-tab tab-item">
                                    {
                                        global.$loginstatus.includes(true) ?
                                            (
                                                <div className="virtual-appointments-div" style={this.state.popuptabstyle}>
                                                    <AvailableAppointments exhibitorid={this.state.email} exhibitor={this.props.exhibitor} />
                                                </div>
                                            )
                                            :
                                            (
                                                <div className="appointment-login-message" style={this.state.popuptabstyle}>
                                                    You must login to see the appointments<br />
                                                    <Link to={`/LogInForm`} className="videochat-login">Click here to login</Link>
                                                </div>
                                            )
                                    }
                                </Tab>
                            )
                            :
                            (
                                ''
                            )
                    }
                </Tabs>
            </div >

        );
    }
    getAdjustedCurrentDate() {
        var mode = global.$mode;
        if (mode == null || mode == '') {
            mode = 'live';
        }

        var currentdate = moment().tz(`${global.$settings.timezone}`).format('L LTS');

        if (mode == "live") {
            //Nothing - Just return the current date
        }
        else if (mode == "preview") {
            // Make current date/time as same as session begin date/time
            currentdate = this.state.begindate;
        }
        else if (mode == "dryrun") {
            var eventdate = moment(this.state.begindate).format('L');
            var currenttime = moment().tz(`${global.$settings.timezone}`).format('LTS');
            currentdate = eventdate + " " + currenttime
        }
        else if (mode == "ondemand") {   //Add 365 days to current date

            var postdate = moment().add(365, 'days').calendar();
            var posttime = moment().tz(`${global.$settings.timezone}`).format('LTS');
            currentdate = postdate + " " + posttime;
        }

        //console.log(`SessionVideos:  current = ${currentdate}, start = ${this.state.begindate}, end = ${this.state.enddate}`);

        return currentdate;
    }
    getVideoChat() {
        if (this.state.schedule !== "empty") {
            var json = JSON.parse(this.state.schedule);
            var scheduledata = [];
            var mode = global.$mode;

            if (mode == null || mode == '') {
                mode = 'live';
            }

            json.forEach((item) => {
                var edate = moment(item.End).format('LT')
                scheduledata.push(item.Begin + " - " + edate + "<br>");
            });

            //console.log(json);
            var results = json.forEach((item) => {
                //console.log(item);
                var beginDate = Date.parse(item.Begin);

                var currentdate = moment().tz(`${global.$settings.timezone}`).format('L LTS');
                var presentDate = Date.parse(currentdate);

                var endDate = Date.parse(item.End);
                //console.log(currentdate, item.Begin, item.end, presentDate >= beginDate, presentDate <= endDate);

                if (presentDate >= beginDate && presentDate <= endDate) {
                    //console.log("chat");
                    //this.setState({ enablechat: true });
                    this.state.enablechat = true;
                }
                else {
                    if (mode == "live") {
                        //Nothing - Just return the current date
                        this.state.begindate = scheduledata;
                        //this.state.enablechat = false;
                    }
                    else if (mode == "preview") {
                        // Make current date/time as same as session begin date/time
                        this.state.enablechat = true;
                    }
                    else if (mode == "dryrun") {
                        var eventdate = moment(this.state.begindate).format('L');
                        var currenttime = moment().tz(`${global.$settings.timezone}`).format('LTS');
                        currentdate = eventdate + " " + currenttime
                        var presentDate = Date.parse(currentdate);

                        if (presentDate >= beginDate && presentDate <= endDate) {
                            //console.log("chat");
                            //this.setState({ enablechat: true });
                            this.state.enablechat = true;
                        }
                        else {
                            this.state.begindate = scheduledata;
                            //this.state.enablechat = false;
                        }
                    }
                    else if (mode == "ondemand") {
                        this.state.begindate = scheduledata;
                        this.state.enablechat = "false";
                    }
                    //console.log("schedule");
                    //this.setState({ enablechat: false });

                }
            })
            return results;
        }
    }
    getVideoChatSchedule() {

        var scheduleJSON = "[]";

        //console.log("scheduleJSON: " + this.state.schedule);

        //if (this.state.schedule != null && this.state.schedule.trim() != "")
        if (this.state.schedule != "empty") {
            scheduleJSON = this.state.schedule;
        }

        //console.log(scheduleJSON);

        scheduleJSON = JSON.parse(scheduleJSON);

        //console.log(scheduleJSON);

        //var json = JSON.parse(this.state.schedule);

        return scheduleJSON.map(item => {
            var edate = moment(item.End).format('LT')
            var dateformat = item.Begin + " - " + edate;
            return (
                <div key={item.id}>
                    <p className="sponsor-schedule-time offset-md-1">{dateformat}</p>
                </div>
            )
        })
    }
}
export default VirtualPopupTabs;


