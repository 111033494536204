import React from 'react';
import { Row, Col } from 'reactstrap';

class LoadExternalLinks extends React.Component {
    constructor(props) {
       //console.log(props.match.params.value)
        
       //console.log(props.match.params.value)
        var iframestyle = ''
        var imgsrc = ''
        
        if(props.match.params.value.includes("questionmark"))
        {
          props.match.params.value = props.match.params.value.replace(/questionmark/g, "?");
          
          if(global.$settings.externallinks.nominations.styles == null)
          {
            iframestyle = {"height": "3000px"};
          }
          else
          {
            iframestyle = global.$settings.externallinks.nominations.styles;
          }
          imgsrc = "https://wesicore.s3.amazonaws.com/event360/"+global.$code+"/public/"+props.match.params.value 
        }
        else
        {
          iframestyle =  global.$settings.externallinks[`${props.match.params.value}`].styles
          imgsrc = global.$settings.externallinks[`${props.match.params.value}`].url
        }
        
        
      super(props);
      this.state = {
        iframestyles: iframestyle,
        //link: props.match.params.value,
        src: imgsrc
      };
     //console.log(this.state)
    }
    
  componentDidMount() {
    window.scrollTo(0, 0)
  }
render()
{
    return(
        <div>
            <br/>
            <br/>
            <Row>
                    <Col md={12}>
                    <iframe src={this.state.src}
                            id="myId"
                            className="myClassname"
                            style={this.state.iframestyles}
                            />
                    </Col>
                </Row>
        </div>
    )
}
}
export default LoadExternalLinks;
