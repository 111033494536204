import React from 'react';
import { Row, Col, CarouselItem, CarouselIndicators, CarouselControl, Carousel } from 'reactstrap';
import ReactPlayer from 'react-player';
import ImageMapper from 'react-image-mapper';
import { mdiConsoleNetworkOutline } from '@mdi/js';
import DaysToGoBanner from './DaysToGoBanner';

class HomeSlider extends React.Component {
    constructor(props) {

        super(props);
        this.state = {
            slider: global.$home.slides,
            activeIndex: 0,
            imageWidth: window.innerWidth,
            imageHeight: window.innerHeight,
            daystogostyle: '',
        };
        window.addEventListener("resize", this.onWindowResize, false);

        if (global.$home.daystogobanner && global.$home.daystogobanner.styles.div) {
            this.state.daystogostyle = global.$home.daystogobanner.styles.div
        }
    }

    onWindowResize = event => {
        
        this.setState({
            imageWidth: window.innerWidth,
            imageHeight: window.innerHeight,
        });
    };

    componentDidMount() {
        if (this.props != null && this.props.onMenuReload != null) {
            this.props.onMenuReload();
        }
    }

    onExiting = () => {
        this.animating = true;
    }

    onExited = () => {
        this.animating = false;
    }

    next = () => {
        //console.log(this.state.slider.length);
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === this.state.slider.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous = () => {
        //console.log(this.state.slider.length);
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.state.slider.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex = (newIndex) => {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }

    imageMapClicked = (area) => {
        
        if (area.route == 'signin') {
            this.props.history.push('/LoginForm')
        }
        else if (area.route == 'contact') {
            this.props.history.push('/Contact')
            this.props.onMenuReload();
        }
        else if (area.route == 'sponsors') {
            this.props.history.push('/Sponsors')
        }
        else if (area.route == 'speakers') {
            this.props.history.push('/Speakers')
        }
        else if (area.route == 'virtualexporoom') {
            this.props.history.push('/VirtualExpoRoom')
        }
        else if (area.route == 'schedule') {
            if (area.data1 == "login") {
                if (global.$loginstatus.includes("true")) {
                    this.props.history.push('/Schedule')
                }
                else {
                    this.props.history.push('/LoginForm');
                }
            }
            else {
                this.props.history.push('/Schedule')
            }
        }
        else if (area.route == 'register') {
            this.props.history.push('/Registrationform')
        }
    }

    render() {
        //console.log(this.state.slider);
        const { activeIndex } = this.state;
        const slides = this.state.slider.map((item) => {
            //console.log(item)
            return (
                <CarouselItem
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                    key={item.src}
                >
                    {
                        item.format != null && item.format == "video" ?
                            (
                                <div className="home-slider-video-div">
                                    <ReactPlayer
                                        url={item.src}
                                        width="100%"
                                        className="home-slider-video"
                                        controls={false}
                                        loop={false}
                                        playing={true}
                                        //light={item.cover}
                                        muted
                                        onContextMenu={e => e.preventDefault()}
                                        config={{
                                            file: {
                                                attributes: {
                                                    controlsList: 'nodownload',
                                                    disablepictureinpicture: 'true',
                                                }
                                            }
                                        }}
                                    />
                                </div>
                            )
                            :
                            (
                                <div className="home-banner-div" >
                                    <img className="banner" src={item.src} alt={item.altText} />
                                    { global.$home.daystogobanner && global.$home.daystogobanner.enable.includes('Y') ?
                                        (
                                            <div className='banner-timer' style={this.state.daystogostyle}>
                                                <DaysToGoBanner />
                                            </div>
                                        ) : ('')
                                    }
                                    
                                </div>
                            )
                    }
                </CarouselItem>
            );
        });

        var topBanner = "slides";
        if (global.$home.topbanner != null && global.$home.topbanner == 'imagemap') {
            topBanner = "imagemap";
        }
        //console.log(this.state.slider);
        //console.log(this.state.slider.length);
        return (
            <div>
                {
                    global.$home.topbanner != null && global.$home.topbanner.includes('imagemap') ?
                        (
                            <div className="img-map">
                                <ImageMapper
                                    src={global.$home.imagemap.image}
                                    map={global.$home.imagemap}
                                    imgWidth={1920}
                                    width={this.state.imageWidth}
                                    onClick={area => this.imageMapClicked(area)}
                                    className="image-mapper"
                                />
                            </div>
                        )
                        :
                        (
                            <div>
                                {
                                    Number(this.state.slider.length) == 1 ?
                                        (
                                            <Carousel activeIndex={activeIndex} next={this.next} previous={this.previous} ride="carousel" interval={7000}>
                                                {slides}
                                            </Carousel>
                                        )
                                        :
                                        (
                                            <Carousel activeIndex={activeIndex} next={this.next} previous={this.previous} ride="carousel" interval={7000}>
                                                <CarouselIndicators items={this.state.slider} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                                                {slides}
                                                <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                                                <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                                            </Carousel>
                                        )
                                }
                            </div>
                        )
                }
            </div>
        )
    }

}
export default HomeSlider;
