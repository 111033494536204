import React from 'react';
import { Row, Col } from 'reactstrap';

class Evaluation extends React.Component {
    constructor(props) {
        //console.log(props.match.params.value)
        var iframestyle = ''
        var imgsrc = ''
                
          props.match.params.value = props.match.params.value.replace(/questionmark/g, "?");
          iframestyle = {"height": "1900px"}
          imgsrc = "https://wesicore.s3.amazonaws.com/event360/"+global.$code+"/public/"+props.match.params.value
        
      super(props);
      this.state = {
        iframestyles: iframestyle,
        //link: props.match.params.value,
        src: imgsrc
      };
      //console.log(this.state)
    }
    
  componentDidMount() {
    window.scrollTo(0, 0)
  }
render()
{
    return(
        <div>
            <br/>
            <br/>
            <Row>
                    <Col md={12}>
                    <iframe src={this.state.src}
                            id="myId"
                            className="myClassname"
                            style={this.state.iframestyles}
                            />
                    </Col>
                </Row>
        </div>
    )
}
}
export default Evaluation;
