import React from 'react';
import { API, Analytics } from 'aws-amplify';
import ScheduleTabs from './ScheduleTabs';
import { Row, Col, Input } from 'reactstrap';
import clickicon from "../images/app-logos/click-here.gif"
import Sponsors from "../Components/Sponsors";
import './Schedule.css';
var serviceNames = require('../ServiceNameMapper');

/*

------------------------   Schedule Flow (Schedule & Posters)  ----------------------------   

- Schedule2 - Schedule Transition

            - ScheduleSearch(It's a feature not a separate Component)

            - Sponsors (Sponsors component in components folder)            

            - ScheduleTabs  
                - ScheduleItem  
                                - ScheduleWatchNow  
                                    - LogInForm(Link to component in login folder) before login
                                    - SessionInfo(after login)

                                - ScheduleSpeakers  
                                    - SpeakerInfo(Link to component in speakers folder)
                                            
                                - SessionTracks     
                                    - SessionTrackItem

*/
var timer;

class Schedule extends React.Component {
    constructor(props) {
        super(props);
        //console.log(props);
        this.state = {
            code: global.$code,
            s3: global.$s3,
            bucket: global.$s3bucket,
            dataloaded: false,
            search: ''
        };

        timer = setInterval(() => {
            if (global.$schedulejson != null) {
                this.setState({ dataloaded: true })
            }
        }, 60000);

        this.handleInputChange = this.handleInputChange.bind(this);
    }
    
    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        let value = target.value

        ////console.log( "Target = " + name + ", Value = " + value);
        this.setState({ [name]: value });
    }
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    componentWillUnmount() {
        //console.log("componentWillUnmount")
        clearInterval(timer)
    }
    render() {

        //console.log("Schedule:render()");

        Analytics.record({ name: 'PageView', attributes: { name: 'eventsites-' + this.state.code + '-schedule' } });

        if (this.state.dataloaded) {
            return (
                <div>
                    {
                        this.props.type.includes("schedule") ?
                            (
                                <div className="home-banner-div">
                                    <img className="banner" src={global.$schedule.banner} />
                                    
                                </div>
                            )
                            :
                            ('')
                    }
                    {
                        this.props.type.includes("posters") ?
                            (
                                <div className="home-banner-div">
                                    <img className="banner" src={global.$posters.banner} />
                                    
                                </div>
                            )
                            :
                            ('')
                    }
                    <div className="offset-md-1">

                        <Row>
                            <Col md={11} className="download-program">

                                {
                                    this.props.type.includes("schedule") ?
                                        (
                                            <div>
                                                <div dangerouslySetInnerHTML={{ __html: global.$schedule.header }}></div>
                                                <div className="schedule-search-div">
                                                    <Input type="text" name="search" className="schedule-search" value={this.state.search || ""} onChange={this.handleInputChange} placeholder="Search"><i class='fas fa-search'></i></Input>
                                                </div>
                                            </div>
                                        )
                                        :
                                        ('')
                                }
                                {
                                    this.props.type.includes("posters") ?
                                        (
                                            <div>
                                                <div dangerouslySetInnerHTML={{ __html: global.$posters.header }}></div>
                                                <div className="schedule-search-div">
                                                    <Input type="text" name="search" className="schedule-search" value={this.state.search || ""} onChange={this.handleInputChange} placeholder="Search"><i class='fas fa-search'></i></Input>
                                                </div>
                                            </div>
                                        )
                                        :
                                        ('')
                                }
                            </Col>
                        </Row>
                        {
                            global.$schedule.download.enable.includes("Y") ?
                                (
                                    <Row>
                                        <Col md={12} className="download-program">
                                            <img src={clickicon} className="click-button" />
                                            <a href={global.$schedule.download.link} target="_blank" className="download-program-button">Download</a>
                                        </Col>
                                    </Row>
                                )
                                :
                                (
                                    ''
                                )
                        }
                        <ScheduleTabs
                            search={this.state.search}
                            type={this.props.type}
                        />
                    </div>
                    <div>
                        <Sponsors />
                    </div>
                    <br />
                </div>
            );
        }
        else {
            //console.log(global.$schedulejson);
            if (global.$schedulejson != null) {
                this.setState({ dataloaded: true })
            }
            else {
                this.getSchedulejson()
            }
            return (
                <div>
                    <Row>
                        <Col md={6} className="offset-5">
                            <div className="loading-div">
                                <div className="loading-text">Loading....</div>
                            </div>
                        </Col>
                    </Row>
                </div>
            )
        }
    }

    getSchedulejson(event) {
        //console.log("getSchedulejson");
        let apiName = serviceNames.getWebsiteService();
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'code': this.state.code,
                'command': 'getJsonFileFromS3',
                'bucket': this.state.bucket,
                'filename': "allschedule"
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //console.log(myInit);

        API.post(apiName, path, myInit).then(response => {
            //console.log ("response.data ===> " + response.data + "response.err ===> " + response.err);

            //console.log(response);

            if (response.err == null && response.data != null) {
                let buf = Buffer.from(response.data.Body);
                //console.log(buf)
                //console.log(buf.toString());
                //console.log(response.data.errorMessage);                   
                global.$schedulejson = JSON.parse(buf.toString());
                global.$schedulespeakers = JSON.parse(buf.toString());
                //console.log(global.$schedulejson);

                //global.$schedulejson = JSON.parse(response.data);

                //this.setState({ dataloaded: true })

                //console.log(global.$speakerjson);
                if (global.$speakerjson == null) {
                    this.getSpeakerjson();
                }
                else {
                    this.setState({ dataloaded: true })
                }
            }
            else {
                ////console.log("getAttendeesTableObjects - failed!!");
                //console.log(response.err);
            }
        }
        ).catch(error => {
            //console.log(error)
        });
    }

    getSpeakerjson() {
        //console.log("getSpeakerjson()")
        let apiName = serviceNames.getWebsiteService();
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'code': this.state.code,
                'command': 'getJsonFileFromS3',
                'bucket': this.state.bucket,
                'filename': "speakers"
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //console.log(myInit);

        API.post(apiName, path, myInit).then(response => {
            //console.log ("response.data ===> " + response.data + "response.err ===> " + response.err);

            //console.log(response);

            if (response.err == null && response.data != null) {
                let buf = Buffer.from(response.data.Body);
                //console.log(buf)
                //console.log(buf.toString());
                global.$speakerjson = JSON.parse(buf.toString());
                //console.log(global.$schedule.enablesessionregistration);
                //console.log(global.$speakerjson);
                this.setState({ dataloaded: true });
            }
            else {
                ////console.log("getAttendeesTableObjects - failed!!");
                //console.log(response.err);
            }
        }
        ).catch(error => {
            //console.log(error.response)
        });

    }
}
export default Schedule;


