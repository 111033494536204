import React from 'react';
import { API, Analytics } from 'aws-amplify';
import { Row, Col, Button } from 'reactstrap';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { Document, Page, pdfjs } from "react-pdf/dist/entry.webpack";
import "react-pdf/dist/Page/AnnotationLayer.css";
import { Link } from 'react-router-dom';
import SessionInfo2 from './SessionInfo2';
import SessionSpeakers2 from './SessionSpeakers2';
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const moment = require('moment-timezone');
class ScheduleItem2 extends React.Component {
    constructor(props) {
        super(props);
        var maindiv = {};
        if (global.$schedule.styles.sessioninfo2.maindiv != null) {
            maindiv = global.$schedule.styles.sessioninfo2.maindiv;
        }
        this.state = {
            code: global.$code,
            s3: global.$s3,
            bucket: global.$s3bucket,
            dataloaded: false,
            stag: props.stag,
            scheduleheadertimestyles: global.$schedule.styles.scheduleheadertime,
            scheduleheadertitlestyles: global.$schedule.styles.scheduleheadertitle,
            scheduleheaderspeakerstyles: global.$schedule.styles.scheduleheaderspeakers,
            scheduletitlestyles: global.$schedule.styles.scheduletitle,
            scheduledescstyles: global.$schedule.styles.scheduledesc,
            livesessiondiv: global.$schedule.styles.sessioninfo2.livesessiondiv,
            scheduletimestyles: global.$schedule.styles.scheduletime,
            livesessiondate: global.$schedule.styles.sessioninfo2.livesessiondate,
            livesessiontitle: global.$schedule.styles.sessioninfo2.livesessiontitle,
            showComponent: false,
            maindiv: maindiv
        };

        this.handleClick = this.handleClick.bind(this);
        //console.log(props);
    }
    isSessionLive() {
        //console.log(`ScheduleWatchNow: isSessionLive: current = ${this.props.currenttime}, start = ${this.props.sessionstarttime}, end = ${this.props.sessionendtime}` );

        var mode = global.$mode

        if (mode == "live") {
            if ((this.props.currenttime) >= (this.props.sessionstarttime) && (this.props.currenttime) <= (this.props.sessionendtime)) {
                //console.log('ScheduleWatchNow.isSessionLive - Live');
                return true;
            }
            else {
                //console.log('ScheduleWatchNow.isSessionLive - Not Live');
                return false;
            }
        }
        else if (mode == "preview") {
            return true;
        }
        else if (mode == "dryrun") {
            var eventdate = moment(this.props.sessionstarttime).format('L');
            var currenttime = moment().tz(`${global.$settings.timezone}`).format('LTS');

            var dateformat = eventdate + " " + currenttime

            //console.log('ScheduleWatchNow.isSessionLive - Adjusted Time ' + dateformat);

            var timeformat = Date.parse(dateformat);
            if ((timeformat) >= (this.props.sessionstarttime) && (timeformat) <= (this.props.sessionendtime)) {
                //console.log('ScheduleWatchNow.isSessionLive - Live');
                return true;
            }
            else {
                //console.log('ScheduleWatchNow.isSessionLive - Not Live');
                return false;
            }
        }
        else if (mode == "ondemand") {
            return false;
        }   
    }
    handleClick(event) {  // switch the value of the showModal state
        this.setState({
            showComponent: !this.state.showComponent
        });
    }
    getComponent() {
        if (this.state.showComponent) {
            return <SessionInfo2 sid={this.props.schedule.SID} stag={this.props.schedule.STAG} activetab={this.props.activetab} type={this.props.type} component="sessiontosession" />;
        }
        else {
            return null;
        }
    }
    render() {
        //console.log(this.props.schedule);
        var begindate = this.props.schedule.BEGIN
        begindate = moment(begindate).format('LT')
        var date = new Date();
        var timestamp = date / 1000;
        //console.log(this.props.schedule.SP);
        return (
            <div style={this.state.maindiv} className="session-info2-schedule-section">
                {
                    this.props.schedule.TYPE.includes("H") ?
                        (
                            <div className="sessioninfo2-sessionheader">
                                <Row>
                                    <Col md={9}>
                                        <h5 className="sessioninfo2-sessionheader-title" style={this.state.scheduleheadertitlestyles}>{this.props.schedule.TITLE}</h5>
                                    </Col>
                                    <Col md={3}>
                                        <h5 className="sessioninfo2-sessionheader-time" style={this.state.scheduleheadertimestyles}>{this.props.schedule.T2}</h5>
                                    </Col>
                                </Row>
                            </div>
                        )
                        :
                        (
                            <div>
                                {(() => {
                                    if (this.isSessionLive()) {
                                        //console.log("spinner")
                                        return (
                                            <div className="sessioninfo2-sessiondiv-live">
                                                {
                                                    global.$sessionobj.SID == this.props.schedule.SID ?
                                                        (
                                                            <div className="active-session-sessionpage" style={global.$schedule.styles.sessioninfo2.activesessiondiv}>
                                                                <Row className="live-session-row">
                                                                    <Col md={9}>
                                                                        <div className="sessioninfo2-session-title" style={this.state.livesessiontitle}>
                                                                        </div>
                                                                        <div className="live-sessions-sessionpage-div">
                                                                            <Link to={`/SessionInfo2/${this.props.schedule.SID + "==" + this.props.schedule.STAG + '==' + this.props.activetab + '==' + this.props.type}`} className="sessioninfo2-session-title live-session-title-sessionpage">{this.props.schedule.TITLE}</Link>
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={3}>
                                                                        <h5 className="session-time" style={this.state.livesessiondate}>{begindate}</h5>
                                                                        <p style={{ color: "white" }} className="sessioninfo2-live-session-sessionpage">LIVE</p>
                                                                    </Col>
                                                                </Row>

                                                            </div>
                                                        )
                                                        :
                                                        (
                                                            <div className="live-session-div" style={this.state.livesessiondiv}>
                                                                <Row className="live-session-row">
                                                                    <Col md={9}>
                                                                        <div className="sessioninfo2-session-title" style={this.state.livesessiontitle}>
                                                                        </div>
                                                                        <div className="live-sessions-sessionpage-div">
                                                                            <Link to={`/SessionInfo2/${this.props.schedule.SID + "==" + this.props.schedule.STAG + '==' + this.props.activetab + '==' + this.props.type}`} className="sessioninfo2-session-title live-session-title-sessionpage">{this.props.schedule.TITLE}</Link>
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={3}>
                                                                        <h5 className="session-time" style={this.state.livesessiondate}>{begindate}</h5>
                                                                        <p style={{ color: "white" }} className="sessioninfo2-live-session-sessionpage">LIVE</p>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        )
                                                }

                                            </div>
                                        )
                                    }
                                    else {
                                        return (
                                            <div className="sessioninfo2-sessiondiv-live">
                                                {
                                                    global.$sessionobj.SID == this.props.schedule.SID ?
                                                        (
                                                            <div className="active-session-sessionpage" style={global.$schedule.styles.sessioninfo2.activesessiondiv}>
                                                                <Row>
                                                                    <Col md={9}>
                                                                        <Link className="sessioninfo2-session-title" to={`/SessionInfo2/${this.props.schedule.SID + "==" + this.props.schedule.STAG + '==' + this.props.activetab + '==' + this.props.type}`}>{this.props.schedule.TITLE}</Link>
                                                                        {/*<div style={this.state.scheduletitlestyles}>{this.props.schedule.TITLE}</div>*/}
                                                                    </Col>
                                                                    <Col md={3}>
                                                                        <h5 className="session-time" style={this.state.scheduletimestyles}>{begindate}</h5>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        )
                                                        :
                                                        (

                                                            <Row>
                                                                <Col md={9}>
                                                                    <Link className="sessioninfo2-session-title" to={`/SessionInfo2/${this.props.schedule.SID + "==" + this.props.schedule.STAG + '==' + this.props.activetab + '==' + this.props.type}`}>{this.props.schedule.TITLE}</Link>
                                                                    {/*<div style={this.state.scheduletitlestyles}>{this.props.schedule.TITLE}</div>*/}
                                                                </Col>
                                                                <Col md={3}>
                                                                    <h5 className="session-time" style={this.state.scheduletimestyles}>{begindate}</h5>
                                                                </Col>
                                                            </Row>
                                                        )
                                                }
                                            </div>
                                        )
                                    }
                                })()}

                            </div>
                        )
                }
                {
                    // this.props.schedule.SP == "" || this.props.schedule.SP == " " || this.props.schedule.SP.includes("empty") ?
                    //     (
                    //         ''
                    //     )
                    //     :
                    //     (
                    //         <div>
                    //             <SessionSpeakers2 speakers={this.props.schedule.SP} SID={this.props.schedule.SID} />
                    //         </div>
                    //     )
                }
            </div>
        )
    }

}
export default ScheduleItem2;
/*
<div className="session-info2-schedule-section">
{
    this.props.schedule.TYPE.includes("H") ?
        (
            <Row>
                <Col md={3}>
                    <h5 style={this.state.scheduleheadertimestyles}>{this.props.schedule.T2}</h5>
                </Col>
                <Col md={8}>
                    {
                        this.props.schedule.DESCRIPTION.includes(" ") ?
                            (
                                this.props.schedule.TITLE
                            )
                            :
                            (
                                <div>
                                    <h5 style={this.state.scheduleheadertitlestyles}>{this.props.schedule.TITLE}</h5>
                                </div>
                            )
                    }

                </Col>
            </Row>
        )
        :
        (
            <div>
                {(() => {
                    if (this.isSessionLive()) {
                        //console.log("spinner")
                        return (
                            <div>
                                {
                                    global.$sessionobj.SID == this.props.schedule.SID ?
                                        (
                                            <div className="active-session-sessionpage" style={global.$schedule.styles.sessioninfo2.activesessiondiv}>
                                                <Row className="live-session-row">
                                                    <Col md={3}>
                                                        <h5 className="session-time" style={this.state.livesessiondate}>{begindate}</h5>
                                                    </Col>
                                                    <Col md={8}>
                                                        <div style={this.state.livesessiontitle}>
                                                        </div>
                                                        <div className="live-sessions-sessionpage-div">
                                                            <Link to={`/SessionInfo2/${this.props.schedule.SID + "==" + this.props.schedule.STAG + '==' + this.props.activetab + '==' + this.props.type}`} className="sessioninfo2-session-title live-session-title-sessionpage">{this.props.schedule.TITLE}</Link>
                                                            <p style={{ color: "white" }} className="live-session-sessionpage">LIVE</p>
                                                        </div>
                                                        {
                                                // <div onClick={this.handleClick}>{this.props.schedule.TITLE}</div>
                                                // {this.getComponent()}
                                                }
                                                    </Col>
                                                </Row>
                                            </div>
                                        )
                                        :
                                        (
                                            <div className="live-session-div" style={this.state.livesessiondiv}>
                                                <Row className="live-session-row">
                                                    <Col md={3}>
                                                        <h5 className="session-time" style={this.state.livesessiondate}>{begindate}</h5>
                                                    </Col>
                                                    <Col md={8}>
                                                        <div style={this.state.livesessiontitle}>
                                                        </div>
                                                        <div className="live-sessions-sessionpage-div">
                                                            <Link to={`/SessionInfo2/${this.props.schedule.SID + "==" + this.props.schedule.STAG + '==' + this.props.activetab + '==' + this.props.type}`} className="sessioninfo2-session-title live-session-title-sessionpage">{this.props.schedule.TITLE}</Link>
                                                            <p style={{ color: "white" }} className="live-session-sessionpage">LIVE</p>
                                                        </div>
                                                        {
                                                // <div onClick={this.handleClick}>{this.props.schedule.TITLE}</div>
                                                // {this.getComponent()}
                                                }
                                                    </Col>
                                                </Row>
                                            </div>
                                        )
                                }
                            </div>
                        )
                    }
                    else {
                        return (
                            <div>
                                {
                                    global.$sessionobj.SID == this.props.schedule.SID ?
                                        (
                                            <div className="active-session-sessionpage" style={global.$schedule.styles.sessioninfo2.activesessiondiv}>
                                                <Row>
                                                    <Col md={3}>
                                                        <h5 className="session-time" style={this.state.scheduletimestyles}>{begindate}</h5>
                                                    </Col>
                                                    <Col md={8}>
                                                        <Link to={`/SessionInfo2/${this.props.schedule.SID + "==" + this.props.schedule.STAG + '==' + this.props.activetab + '==' + this.props.type}`}>{this.props.schedule.TITLE}</Link>
                                                        {
                                                            // <div style={this.state.scheduletitlestyles}>{this.props.schedule.TITLE}</div>
                                                        }
                                                    </Col>
                                                </Row>
                                            </div>
                                        )
                                        :
                                        (

                                            <Row>
                                                <Col md={3}>
                                                    <h5 className="session-time" style={this.state.scheduletimestyles}>{begindate}</h5>
                                                </Col>
                                                <Col md={8}>
                                                    <Link to={`/SessionInfo2/${this.props.schedule.SID + "==" + this.props.schedule.STAG + '==' + this.props.activetab + '==' + this.props.type}`}>{this.props.schedule.TITLE}</Link>
                                                    {
                                                    //<div style={this.state.scheduletitlestyles}>{this.props.schedule.TITLE}</div>
                                                </Col>
                                            </Row>
                                        )
                                }
                            </div>
                        )
                    }
                })()}

            </div>
        )
}
< hr />
</div>
*/

