import React from "react";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import HeartlandCreditCard from "../PaymentMethods/HeartlandCreditCard";
import Razorpay from "../PaymentMethods/Razorpay";
import PaypalHome from "../PaymentMethods/PaypalHome";
import RegisterAttendee from "../RegisterAttendee";
import Stripe from "../PaymentMethods/Stripe";
import Authorizenet from "../PaymentMethods/Authorizenet";
import StripeACH from "../PaymentMethods/StripeACH";
import ReaderList from "../PaymentMethods/ReaderList";


class PaymentPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openpaymentpopup: props.openpopup,
            selectedPaymentOption: props.selectedPaymentOption,
            // regattendeestatus: global.$regattendeestatus,
            regattendeestatus: true,
            reggueststatus: global.$reggueststatus,
        };
       //console.log(this.state, this.props)
        this.hideRegisterButton = this.hideRegisterButton.bind(this)
    }

    componentDidMount() {
        this.setState({ openpaymentpopup: true })
    }

    hideRegisterButton() {
        //console.log("refresh");
        global.$hideregisterbutton = true;
        this.props.refreshComponent();
    }

    onOpenPayment = () => {
        
        global.$openpaymentpopup = false;
        this.setState(prevState => ({
            openpaymentpopup: !prevState.openpaymentpopup,
            regattendeestatus: false
        }));
    };
    getPopupStyle(){
        if (this.state.selectedPaymentOption.includes("ACH")) {
            return "paymentpopup";   
        }
        else{
            return "";
        }
    }
    render() {
        this.state.selectedPaymentOption = this.props.selectedPaymentOption;

        var configpayment = global.$registrationform.payment;
        var popupstyle = this.getPopupStyle();
    
        if (global.$regamount > 0) {
            return (
                <div>
                    <Modal
                        open={global.$openpaymentpopup}
                        onClose={this.onOpenPayment}
                        blockScroll={false}
                        closeOnOverlayClick={false}
                        styles={{
                            modal: { maxWidth: '95%',minWidth: '350px',Height: 'height: 100;', margin: 'auto' }, // adjust the margin for centering
                            closeIcon: { top: '10px', right: '10px' } // adjust the close icon position
                          }}
                        classNames={{
                            modal: `${popupstyle}`,
                        }}
                        center>
                        {this.renderPaymentMethod(configpayment)}
                    </Modal>
                </div>
            );
        }
        else {
            return (
                <RegisterAttendee regattendeestatus={this.state.regattendeestatus} reggueststatus={this.state.reggueststatus} tabid={this.props.tabid} type={this.props.type} hideRegisterButton={this.hideRegisterButton} />
            )
        }
    }

    renderPaymentMethod(configpayment) {
       //console.log(configpayment)
       //console.log(this.state.selectedPaymentOption)
        if (this.state.selectedPaymentOption.includes("Credit")) {
            return (
                this.renderCreditCardPayment(configpayment)
            )
        }
        else if (this.state.selectedPaymentOption.includes("ACH")) {
            return (
                this.renderACHPayment(configpayment)
            )
        }
        else if (this.state.selectedPaymentOption.includes("Reader")) {
            return (
                this.renderCardReaderPayment(configpayment)
            )
        }
        else if (this.state.selectedPaymentOption.includes("Payroll")) {
            return (
                this.renderPayrollPayment()
            )
        }
        else if (this.state.selectedPaymentOption.includes("Check")) {
            return (
                this.renderCheckPayment()
            )
        }
        else if (this.state.selectedPaymentOption.includes("Pay Later")) {
            return (
                this.renderPayLaterPayment()
            )
        }
        else if (this.state.selectedPaymentOption.includes("Pay Online")) {
            return (
                this.renderPayonlinePayment()
            )
        }
    }

    renderCreditCardPayment(configpayment) {
        if (configpayment.credit.enable == true) {

            if (configpayment.credit.paymentmode.includes("paypal")) {
                return (
                    <PaypalHome regattendeestatus={this.state.regattendeestatus} reggueststatus={this.state.reggueststatus} type={this.props.type} hideRegisterButton={this.hideRegisterButton} />
                )
            }
            else if (configpayment.credit.paymentmode.includes("heartland")) {
                return (
                    <HeartlandCreditCard regattendeestatus={this.state.regattendeestatus} reggueststatus={this.state.reggueststatus} type={this.props.type} hideRegisterButton={this.hideRegisterButton} />
                )
            }
            else if (configpayment.credit.paymentmode.includes("stripe")) {
                return (
                    <Stripe regattendeestatus={this.state.regattendeestatus} reggueststatus={this.state.reggueststatus} type={this.props.type} hideRegisterButton={this.hideRegisterButton} />
                )
            }
        }
    }

    renderACHPayment(configpayment) {
        var achtype = configpayment.ach && configpayment.ach.enable && configpayment.ach.achproviders ? configpayment.ach.achproviders : ''
       //console.log(configpayment)
        if (configpayment.ach.enable == true) {
            
            if(achtype == "stripe") {
                return(
                    <StripeACH regattendeestatus={this.state.regattendeestatus} reggueststatus={this.state.reggueststatus} type={this.props.type} hideRegisterButton={this.hideRegisterButton} />
                )
            }
            else if(achtype == "authorizenet"){
                return(
                    <Authorizenet regattendeestatus={this.state.regattendeestatus} reggueststatus={this.state.reggueststatus} type={this.props.type} hideRegisterButton={this.hideRegisterButton} />
                )
            }
            else if(achtype == ""){

                return(
                    <h1>ACH client is not enabled</h1>
                )              
            }
        }
    }

    renderCardReaderPayment(configpayment) {
        var readertype = configpayment.cardreader && configpayment.cardreader.enable && configpayment.cardreader.cardreader ? configpayment.cardreader.cardreader : '';
        console.log(configpayment.cardreader);
        
        if(configpayment.cardreader.enable == true) {
            if(readertype == "stripe") {
                return(
                    <ReaderList regattendeestatus={this.state.regattendeestatus} reggueststatus={this.state.reggueststatus} type={this.props.type} hideRegisterButton={this.hideRegisterButton} />                    
                )
            }
        }
    }

    renderPayrollPayment() {
        return (
            <RegisterAttendee regattendeestatus={this.state.regattendeestatus} reggueststatus={this.state.reggueststatus} type={this.props.type} hideRegisterButton={this.hideRegisterButton} />
        )
    }

    renderCheckPayment() {
        return (
            <RegisterAttendee regattendeestatus={this.state.regattendeestatus} reggueststatus={this.state.reggueststatus} type={this.props.type} hideRegisterButton={this.hideRegisterButton} />
        )
    }

    renderPayLaterPayment() {
        return (
            <RegisterAttendee regattendeestatus={this.state.regattendeestatus} reggueststatus={this.state.reggueststatus} type={this.props.type} hideRegisterButton={this.hideRegisterButton} />
        )
    }

    renderPayonlinePayment() {
        return (
            <Razorpay regattendeestatus={this.state.regattendeestatus} reggueststatus={this.state.reggueststatus} type={this.props.type} hideRegisterButton={this.hideRegisterButton} />
        )
    }

}
export default PaymentPopup;