import React from 'react';
import { Row, Col, Input } from 'reactstrap';
import { API, Analytics } from 'aws-amplify';
import './SpeakersLayout.css';
import SpeakerLayoutInfo from './SpeakerLayoutInfo';
import SpeakersSlider from './SpeakersSlider';
var serviceNames = require('../../../ServiceNameMapper');

let order = 'desc';
var imgdata = '';
class SpeakersLayout extends React.Component {
    constructor(props) {
        super(props);

        this.state =
        {
            dataloaded: false,
            code: global.$code,
            bucket: global.$s3bucket,
            search: '',
        }
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        let value = target.value

        //console.log("Target = " + name + ", Value = " + value);

        this.setState({ [name]: value });
        //this.setState({selectedCategory:value})
    }
    render() {

        //console.log("AttendeeList: render");
        var styles = global.$config['speakersscroll'].styles;
        Analytics.record({ name: 'PageView', attributes: { name: 'eventsites-' + this.state.code + '-attendees' } });
        if (this.state.dataloaded) {
            return (
                <div>
                    <div className="speakers-layout-slider-div" style={styles.sliderdiv}>
                        <div dangerouslySetInnerHTML={{ __html: global.$config['speakersscroll'].headerdiv }}></div>
                        <SpeakersSlider
                            code={this.state.code}
                            speakers={global.$speakerscrolljson}
                        />
                    </div>
                </div>
            );
        }
        else {
            if (global.$speakerscrolljson != null) {
                this.setState({ dataloaded: true })
            }
            else {
                this.getSpeakerjson();
            }
            return (
                <div>
                    <Row>
                        <Col md={6} className="offset-5">
                            <div className="loading-div">
                                <div className="loading-text">Loading....</div>
                            </div>
                        </Col>
                    </Row>
                </div>
            )
        }
    }

    getSpeakerjson() {
        ////console.log("sponsor json")
        var filename = "speakers";
        if (global.$config["speakersscroll"].filename != null) {
            filename = global.$config["speakersscroll"].filename;
        }
        let apiName = serviceNames.getWebsiteService();
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'code': this.state.code,
                'command': 'getJsonFileFromS3',
                'bucket': this.state.bucket,
                'filename': filename
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //console.log(myInit);

        API.post(apiName, path, myInit).then(response => {
            //console.log ("response.data ===> " + response.data + "response.err ===> " + response.err);

            if (response.err == null && response.data != null) {
                let buf = Buffer.from(response.data.Body);
                //console.log(buf)
                //console.log(buf.toString());
                global.$speakerscrolljson = JSON.parse(buf.toString());
                this.setState({ dataloaded: true });

            }
            else {
                ////console.log("getAttendeesTableObjects - failed!!");
                //console.log(response.err);
            }
        }
        ).catch(error => {
            ////console.log(error.response)
        });
    }
}
export default SpeakersLayout;