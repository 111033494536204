import React from 'react';
import NotifyMe from 'react-notification-timeline';
import { API } from 'aws-amplify';
import './NotifyMe.css';
var serviceNames = require('../ServiceNameMapper');

var testdata = [
    {
        "update": "",
        "timestamp": 1596119688264
    },
    {
        "update": "",
        "timestamp": 1596119688264
    }
]
var data = [];
var notificationslength = 0;
var timer;
class Notifications extends React.Component {
    constructor(props) {
        super(props);
        this.state =
        {
            dataloaded: false,
            notificationcount: '0',
            notifications: ''
        }
        timer = setInterval(() => {
            this.getNotfications()
        }, 60000)
    }

    render() {
        //console.log(this.state.notifications.length);
        if (this.state.dataloaded) {
            return (
                <div>
                    <NotifyMe
                        data={this.state.notifications}
                        storageKey='notific_key'
                        notific_key='timestamp'
                        notific_value='update'
                        heading={global.$header.menu.notifications.heading}
                        //heading="Notifications"
                        sortedByKey={false}
                        showDate={true}
                        //size={34}
                        size={global.$header.menu.notifications.size}
                        //color="yellow"
                        color={global.$header.menu.notifications.color}
                        className="menu-notification"
                    />
                </div>
            )
        }
        else {
            this.getNotfications()
            return (
                <div>
                </div>
            )
        }
    }
    getNotfications() {
        // let apiName = 'NotificationService';
        let apiName = serviceNames.getEventPlannerService();
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'code': global.$code,
                'command': 'getNotifications',
                'status': 'All'

            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //console.log(myInit);

        API.post(apiName, path, myInit).then(response => {
            //console.log(notificationslength)
            ////console.log(response);
            if (response.err == null && response.data != null) {
               //console.log(response.data);
                if (notificationslength < response.data.data.length) {
                   //console.log(response.data.data.length)
                    var notifications = this.getNotificationsTableObjects(response.data.data)
                    //this.setState({ notifications: notifications });
                }
                else {
                    this.setState({ notifications: data });
                }
                notificationslength = response.data.data.length
                this.setState({ dataloaded: true });
            }
            else {
                //console.log("getNotificationsTableObjects - failed!!");
                //console.log(response.err);
            }
        }
        ).catch(error => {
            //console.log(error)
        });
    }

    getNotificationsTableObjects(objs) {
        //console.log(notificationslength)
       //console.log(obj)
        const tableObjs = []

        for (var i = 0; i < objs.length; i++) {
            var notificationObj = objs[i]

            var newObj = Object()
            if (notificationObj.nstatus.S == "L") {
                newObj.message = notificationObj.message.S
                newObj.nid = notificationObj.nid.N
            }
            else {
                newObj.message = "";
                newObj.nid = "";
            }
            tableObjs.push(newObj)
        }
        //console.log(tableObjs);
        tableObjs.forEach(function (item) {
            //console.log(item);
            if (item.message != "") {
                data.push({
                    "update": item.message,
                    "timestamp": Number(item.nid)
                })
            }
        });
        var obj = {};
       //console.log(data);
        for (var i = 0, len = data.length; i < len; i++)  //to remove duplicate objects ina an array
            obj[data[i]['timestamp']] = data[i];

        data = new Array();

        for (var key in obj)  //to remove duplicate objects ina an array
            data.push(obj[key]);
        //console.log(data);
        //console.log(data.length);
        this.setState({ notifications: data })
        //console.log(this.state.notifications);
        return tableObjs
    }
}
export default Notifications;
