/* eslint-disable */
import React, { Component } from 'react';
import { FormGroup, Label, Input, FormText, Card, div, CardBody, Row, Col, Button } from 'reactstrap';
import Amplify, { API, Auth, Storage, Analytics } from 'aws-amplify';
import { Link } from 'react-router-dom';
import Icon from '@mdi/react';
import { Spinner } from 'react-bootstrap';
import { mdiAccountTie, mdiKeyboardBackspace, mdiIdCard, mdiTextboxPassword, mdiExpansionCard, mdiHand, mdiFile, mdiDomain, mdiFormatTitle, mdiGoogleStreetView, mdiMapMarker, mdiZipBox, mdiMap, mdiGlobeModel, mdiEmail, mdiCellphoneIphone, mdiLinkedin, mdiTwitter, mdiFacebook, mdiIdentifier, mdiFileDocumentBox, mdiStarThreePoints, mdiStarFourPoints, mdiScanner, mdiOnepassword, mdiKey, mdiGooglePhotos, mdiAccount, mdiCamera, mdiStar } from '@mdi/js';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import './ProfilePage.css';
import Avatar from 'react-avatar';
var serviceNames = require('../ServiceNameMapper');

var passwordValidation = "Make sure password length is at least 8 characters";
var successChangePasswordMessage = "Password successfully changed";
var failedChangePasswordMessage = "Unable to change password";

class ProfilePage extends Component {
    render() {

        Analytics.record({ name: 'PageView', attributes: { name: 'eventsites-' + this.state.code + '-profilepage' } });
        if (this.state.dataloaded) {
            //console.log(this.state.imageUrl);
            return (
                <div className="profile-page" style={this.state.profilepagestyle}>
                    <br />
                    <br />
                    <Row>
                        <Col md={10} className="offset-md-1">
                            <form className="profile-form" >
                                <Row>
                                    <Col md={5}>
                                        <div className="profile-general-info">

                                            <FormGroup className="form-labels" style={this.state.photodivstyles}>
                                                <div id="profilediv">
                                                    <Label className="profile-form-labels" for="photo" className="custom-fileupload">
                                                        {
                                                            this.state.imageUrl != null && this.state.imageUrl.includes("common_profile") ?
                                                                (
                                                                    <Avatar className="profile-avatar" style={this.state.imgstyles} name={`${this.state.fname + " " + this.state.lname}`} />
                                                                )
                                                                :
                                                                (
                                                                    <img id="profilephoto" src={this.state.imageUrl || ''} style={this.state.imgstyles} />
                                                                )
                                                        }
                                                    </Label>
                                                    <input ref="uploadImg" type="file" name="photo" className="profile-form-control upload-file" id="photo" accept=".gif,.jpg,.jpeg,.png" onChange={this.fileUploader} hidden />
                                                    <p class="text"><span className="pen-icon">&#9998;</span></p>
                                                </div>
                                                <div className="camera-div offset-md-2" id="cameradiv">
                                                    <video id="video" width="150" height="150"></video>
                                                    <canvas id="canvas" width="150" height="150"></canvas>
                                                </div>
                                                <div className="camera-icon-div">
                                                    {/*<i class='fas fa-camera camera-icon' style={this.state.cameraiconstyles} id="camera" onClick={this.startCamera}></i>*/}
                                                    <div ><Icon path={mdiCamera} size={1.5} color="lightgray" className="camera-icon" id="camera" onClick={this.startCamera} /></div>
                                                </div>
                                                <Button id="takephoto" className="offset-md-4" onClick={this.takePhoto} style={this.state.takephotostyles}>Take Photo</Button>
                                                {/*<Button id="upload">
                                            <input ref="uploadImg" type="file" name="photo" className="profile-form-control upload-file" id="photo" accept=".gif,.jpg,.jpeg,.png" onChange={this.fileUploader} hidden />
                                            Upload
                                            </Button>*/}
                                                <br />
                                                <div id="photouploadingmessage"></div>

                                            </FormGroup>
                                            <br />
                                            <div className="profile-general-header">
                                                <p className="my-profile-header">My Profile</p>
                                                {
                                                    global.$points != null && global.$points.attendeebadgelevels != null && global.$points.attendeebadgelevels.enable == true ?
                                                        (
                                                            <div className="profile-badge-level-div offset-md-6">
                                                                {this.displayAttendeeBadgeLevel()}
                                                            </div>
                                                        )
                                                        :
                                                        ('')
                                                }
                                            </div>
                                            <hr />
                                            <div>
                                                Signed in as<b style={{ float: "right" }}>{global.$loginid}</b>
                                                <br />
                                            </div>
                                            <br />

                                            <div>
                                                <button type="button" style={this.state.changepasswordstyles} onClick={this.handleDisplayNewPassword} >{global.$profile.changepassword.text}</button>
                                            </div>
                                            <br />
                                            <Row className="profile-changepwd-div">
                                                <Col md={8}>
                                                    <FormGroup className="form-labels">
                                                        <Label className="profile-form-labels profile-newpwdlabel" for="newpwd" id="newpwdlabel" style={{ display: "none" }}><Icon path={mdiOnepassword} size={1} color="lightgray" className="form-label-icon" />New Password</Label>
                                                        <Input type="text" style={{ display: "none" }} name="newpwd" className="profile-form-control profile-newpwd" id="newpwd" value={this.state.newpwd || ''} onChange={this.handleInputChange} />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={4}>
                                                    <div>
                                                        <button type="button" id="newpwdbutton" className="profile-newpwdbutton" style={this.state.changepasswordstyles} onClick={this.handleDisplyOTPField}>OK</button>
                                                    </div>
                                                </Col>
                                                <Col md={8}>
                                                    <FormGroup className="form-labels">
                                                        <Label className="profile-form-labels profile-otplabel" for="otp" id="otplabel" style={{ display: "none" }}><Icon path={mdiOnepassword} size={1} color="lightgray" className="form-label-icon" />Enter verification code sent to your email</Label>
                                                        <Input type="text" style={{ display: "none" }} name="otp" className="profile-form-control profile-otp" id="otp" value={this.state.otp || ''} onChange={this.handleInputChange} />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={4}>
                                                    <div>
                                                        <button type="button" id="otpbutton" className="profile-otpbutton" style={this.state.changepasswordstyles} onClick={this.processChangePassword} >Change</button>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <div id="changepassword-result"></div>
                                            <FormGroup className="form-labels">
                                                <Label className="profile-form-labels" for="fname"><Icon path={mdiAccountTie} size={1} color="lightgray" className="form-label-icon" />First Name*</Label>
                                                <Input type="text" name="fname" className="profile-form-control" id="fname" value={this.state.fname || ''} onChange={this.handleInputChange} disabled={this.state.disablefname}/>
                                            </FormGroup>
                                            <FormGroup className="form-labels">
                                                <Label className="profile-form-labels" for="lname"><Icon path={mdiAccountTie} size={1} color="lightgray" className="form-label-icon" />Last Name*</Label>
                                                <Input type="text" name="lname" className="profile-form-control" id="lname" value={this.state.lname || ''} onChange={this.handleInputChange} disabled={this.state.disablelname} />
                                            </FormGroup>
                                            <FormGroup className="form-labels">
                                                <Label className="profile-form-labels" for="email"><Icon path={mdiEmail} size={1} color="lightgray" className="form-label-icon" />Email*</Label>
                                                <Input type="text" name="email" className="profile-form-control" id="email" value={this.state.email || ''} onChange={this.handleInputChange} disabled={this.state.disableemail} />
                                            </FormGroup>
                                            <FormGroup className="form-labels">
                                                <Label className="profile-form-labels" for="phone"><Icon path={mdiCellphoneIphone} size={1} color="lightgray" className="form-label-icon" />Phone</Label>
                                                <Input type="text" name="phone" className="profile-form-control" id="phone" value={this.state.phone || ''} onChange={this.handleInputChange} disabled={this.state.disablephone} />
                                            </FormGroup>
                                            <FormGroup className="form-labels">
                                                <Label className="profile-form-labels" for="memberid"><Icon path={mdiIdCard} size={1} color="lightgray" className="form-label-icon" />Member ID</Label>
                                                <Input type="text" name="memberid" className="profile-form-control" id="memberid" value={this.state.memberid || ''} onChange={this.handleInputChange} />
                                            </FormGroup>
                                        </div>
                                        {this.showCertificateSection()}
                                    </Col>
                                    <br />
                                    <Col md={6} className="offset-md-1">
                                        <Row>
                                            <Col>
                                                <div className="profile-company-info">
                                                    <div className="profile-company-header">Company</div>
                                                    <hr />
                                                    <FormGroup className="form-labels" hidden={this.state.hidecompany}>
                                                        <Label className="profile-form-labels" for="company"><Icon path={mdiDomain} size={1} color="lightgray" className="form-label-icon" />Company</Label>
                                                        <Input type="text" name="company" className="profile-form-control" id="company" value={this.state.company || ''} onChange={this.handleInputChange} />
                                                    </FormGroup>

                                                    <FormGroup className="form-labels" hidden={this.state.hidetitle}>
                                                        <Label className="profile-form-labels" for="title"><Icon path={mdiFormatTitle} size={1} color="lightgray" className="form-label-icon" />Title</Label>
                                                        <Input type="text" name="title" className="profile-form-control" id="title" value={this.state.title || ''} onChange={this.handleInputChange} />
                                                    </FormGroup>
                                                    <FormGroup className="form-labels">
                                                        <Label className="profile-form-labels" for="street"><Icon path={mdiGoogleStreetView} size={1} color="lightgray" className="form-label-icon" />Street</Label>
                                                        <Input type="text" name="street" className="profile-form-control" id="street" value={this.state.street || ''} onChange={this.handleInputChange} />
                                                    </FormGroup>
                                                    <Row>
                                                        <Col md={6}>
                                                            <FormGroup className="form-labels">
                                                                <Label className="profile-form-labels" for="city"><Icon path={mdiMapMarker} size={1} color="lightgray" className="form-label-icon" />City</Label>
                                                                <Input type="text" name="city" className="profile-form-control" id="city" value={this.state.city || ''} onChange={this.handleInputChange} />
                                                            </FormGroup>
                                                        </Col>

                                                        <Col md={6} >
                                                            <FormGroup className="form-labels">
                                                                <Label className="profile-form-labels" for="zip"><Icon path={mdiZipBox} size={1} color="lightgray" className="form-label-icon" />Zip</Label>
                                                                <Input type="text" name="zip" className="profile-form-control" id="zip" value={this.state.zip || ''} onChange={this.handleInputChange} />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={6}>
                                                            <FormGroup className="form-labels">
                                                                <Label className="profile-form-labels" for="country"><Icon path={mdiGlobeModel} size={1} color="lightgray" className="form-label-icon" />Country</Label>
                                                                <CountryDropdown name="country" className="profile-form-control" id="country" value={this.state.country || ''} onChange={(val) => this.selectCountry(val)}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={6}>
                                                            <FormGroup className="form-labels">
                                                                <Label className="profile-form-labels" for="state"><Icon path={mdiMap} size={1} color="lightgray" className="form-label-icon" />State</Label>
                                                                <RegionDropdown name="state" className="profile-form-control" id="region" placeholder="Select" country={this.state.country || ''} value={this.state.state || ''} onChange={(val) => this.selectRegion(val)}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col>
                                                <div className="profile-social-info">
                                                    <div className="profile-social-header">Social</div>
                                                    <hr />

                                                    <FormGroup className="form-labels">
                                                        <Label className="profile-form-labels" for="linkedin"><Icon path={mdiLinkedin} size={1} color="lightgray" className="form-label-icon" />LinkedIn</Label>
                                                        <Input type="text" name="linkedin" className="profile-form-control" id="linkedin" value={this.state.linkedin || ''} onChange={this.handleInputChange} />
                                                    </FormGroup>

                                                    <FormGroup className="form-labels">
                                                        <Label className="profile-form-labels" for="twitter"><Icon path={mdiTwitter} size={1} color="lightgray" className="form-label-icon" />Twitter</Label>
                                                        <Input type="text" name="twitter" className="profile-form-control" id="twitter" value={this.state.twitter || ''} onChange={this.handleInputChange} />
                                                    </FormGroup>

                                                    <FormGroup className="form-labels">
                                                        <Label className="profile-form-labels" for="fb"><Icon path={mdiFacebook} size={1} color="lightgray" className="form-label-icon" />Facebook</Label>
                                                        <Input type="text" name="fb" className="profile-form-control" id="fb" value={this.state.fb || ''} onChange={this.handleInputChange} />
                                                    </FormGroup>

                                                    <FormGroup className="form-labels">
                                                        <Label className="profile-form-labels" for="myexpertise"><Icon path={mdiAccount} size={1} color="lightgray" className="form-label-icon" />My Expertise</Label>
                                                        <Input type="text" name="myexpertise" className="profile-form-control" id="myexpertise" value={this.state.myexpertise || ''} onChange={this.handleInputChange} />
                                                    </FormGroup>

                                                    <FormGroup className="form-labels">
                                                        <Label className="profile-form-labels" for="myinterests"><Icon path={mdiAccount} size={1} color="lightgray" className="form-label-icon" />My Interests</Label>
                                                        <Input type="text" name="myinterests" className="profile-form-control" id="myinterests" value={this.state.myinterests || ''} onChange={this.handleInputChange} />
                                                    </FormGroup>
                                                    <br />
                                                    <FormGroup className="form-labels offset-md-1">
                                                        <Label>
                                                            <Input type="checkbox" id="showdirectory" name="showdirectory" checked={this.state.showdirectory} value={this.state.showdirectory} onChange={this.handleCheckbox} disabled={this.state.disableshowdirectory}/>I would like to show my details in attendee list
                                                    </Label>
                                                    </FormGroup>
                                                    <div id="message_status" style={this.state.messagestatusstyle}></div>
                                                    <div className="profile-save-group">
                                                        <div>
                                                            <button
                                                                style={this.state.savestyles}
                                                                onClick={this.handleSubmit}
                                                                disabled={this.state.disabled}
                                                                type="submit"
                                                                isLoading={this.state.isLoading}
                                                                text="Submit"
                                                                loadingText="Submit"
                                                            ><Spinner
                                                                    as="span"
                                                                    animation="border"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                    className="Loading-button"
                                                                    variant="success"
                                                                    hidden={!this.state.isLoading}
                                                                />
                                                                {global.$profile.save.text}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                            </form>
                            <br />
                            <br />
                        </Col>
                    </Row>
                </div>
            );
        }
        else {
            this.getUser()
            return (
                <div>
                    <Row>
                        <Col md={6} className="offset-5">
                            <div className="loading-div">
                                <div className="loading-text">Loading....</div>
                            </div>
                        </Col>
                    </Row>
                </div>
            )
        }
    }

    constructor(props) {
        //const globalvariables = global.$variables; // Accessing Global variables
        //console.log(globalvariables)
        //console.log("Attendee id =" + props.match.params.value)
        //console.log(global.$profile.showeducationalcredits);
        //console.log(global.$profile);
        var certificatetype = "single";
        var submissiontype = "feedback";
        var disablefname = false;
        var disablelname = false;
        var disableemail = false;
        var disablephone = false;
        var hidecompany = true;
        var hidetitle = true;
        var disableshowdirectory = false;

        if (global.$profile.certificatetype == null) {
            certificatetype = "single";
        }
        else {
            certificatetype = global.$profile.certificatetype;
        }

        if (global.$profile.submissiontype == null) {
            submissiontype = "feedback";
        }
        else {
            submissiontype = global.$profile.submissiontype;
        }
        var memberidrequired = "N";
        if (global.$profile.memberidrequired == null) {
            memberidrequired = "N";
        }
        else {
            memberidrequired = global.$profile.memberidrequired
        }

        if(global.$profile.disablefname != null)
        {
            disablefname = global.$profile.disablefname;
        }
        if(global.$profile.disablelname != null)
        {
            disablelname = global.$profile.disablelname;
        }
        if(global.$profile.disableemail != null)
        {
            disableemail = global.$profile.disableemail;
        }
        if(global.$profile.disablephone != null)
        {
            disablephone = global.$profile.disablephone;
        }
        if(global.$profile.hidecompany != null)
        {
            hidecompany = global.$profile.hidecompany;
        }
        if(global.$profile.hidetitle != null)
        {
            hidetitle = global.$profile.hidetitle;
        }
        if(global.$profile.disableshowdirectory != null)
        {
            disableshowdirectory = global.$profile.disableshowdirectory;
        }


        super(props);
        this.state =
        {
            //code: '',
            userid: global.$loginid,
            code: global.$code,
            submittedby: global.$loginid,
            poolid: global.$poolid,
            cauthtype: '',
            fname: '',
            lname: '',
            company: '',
            title: '',
            phone: '',
            email: '',
            linkedin: '',
            twitter: '',
            fb: '',
            country: 'United States',
            state: '',
            city: '',
            street: '',
            zip: '',
            regid: '',
            regtype: [],
            memberid: '',
            photoFileName: '',
            photoFileHandle: '',
            imageUrl: '',
            vpoints: '',
            apoints: '',
            tpoints: '',
            spoints: '',
            regstatus: '',
            password: '',
            selectedRegStatus: '',
            disabled: false,
            isLoading: false,
            hiddenqrcode: false,
            myexpertise: '',
            myinterests: '',
            registrationType: '',
            validscans: '',
            selectedRegType: '',
            newpwd: '',
            otp: '',
            changepasswordstyles: global.$profile.changepassword.styles,
            savestyles: global.$profile.save.styles,
            photodivstyles: global.$profile.photo.divstyles,
            imgstyles: global.$profile.photo.imgstyles,
            image: '',
            profilepagestyle: global.$profile.pagestyle,
            cameraiconstyles: global.$profile.photo.cameraicon,
            takephotostyles: global.$profile.photo.takephotobutton,
            showProfilePhoto: true,
            showcamera: true,
            currentlocation: '',
            lastseen: '',
            showdirectory: true,
            showsertificatesection: false,
            messagestatusstyle: global.$profile.message,
            downloadcertificate: true,
            downloadcertificatemessage: '',
            submissiontype: submissiontype,
            certificatetype: certificatetype,
            memberidrequired: memberidrequired,
            selected: '',
            officephone: "",
            location: "",
            locationtime: "",
            signindate: "",
            data1: "",
            data2: "",
            data3: "",
            data4: "",
            data5: "",
            q1: "",
            q2: "",
            q3: "",
            q4: "",
            q5: "",
            q6: "",
            q7: "",
            q8: "",
            userstype: "",
            guests: "",
            sessions: "",
            regnotes: "",
            checkinstatus: "",
            mname: "",
            totalamount: "0",
            amountpaid: "0",
            promoamount: "0",
            pendingamount: "0",
            refundamount: "0",
            paymentmode: "",
            promocodes: "",
            guestname: "",
            disablefname: disablefname,
            disablelname: disablelname,
            disableemail: disableemail,
            disablephone: disablephone,
            hidecompany: hidecompany,
            hidetitle: hidetitle,
            disableshowdirectory: disableshowdirectory,
            regitemsjson:""
        };

        //this.getusersTofindSessionPoints();
        //console.log(this.state.imgstyles);
        if(this.state.code == "travcon2022exhibitor")
        {
            this.state.code = "travcon2022";
        }
        
        if(this.state.code == "travcon2023exhibitor")
        {
            this.state.code = "travcon2023";
        }

        this.state.message = "";
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCheckbox = this.handleCheckbox.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.fileUploader = this.fileUploader.bind(this);
        this.selectCountry = this.selectCountry.bind(this);
        this.selectRegion = this.selectRegion.bind(this);
        this.handleDropdownChange = this.handleDropdownChange.bind(this);
        this.handleRegType = this.handleRegType.bind(this);
        this.handleDisplayNewPassword = this.handleDisplayNewPassword.bind(this);
        this.handleDisplyOTPField = this.handleDisplyOTPField.bind(this);
        this.processChangePassword = this.processChangePassword.bind(this);
        this.handleRegType = this.handleRegType.bind(this);
        this.downloadCertificate = this.downloadCertificate.bind(this);
        this.startCamera = this.startCamera.bind(this);
        this.takePhoto = this.takePhoto.bind(this);
        this.handleCertificate = this.handleCertificate.bind(this);


        if (this.state.country == "Select Country") {
            this.state.country = "Select"
        }

        this.hideTakePhoto();
    }

    showCertificateSection() {
       //console.log(global.$memberid);
       //console.log(this.state.memberidrequired);
        if (this.state.memberidrequired == "Y") {
            
           //console.log(global.$memberid);
           //console.log(this.state.memberidrequired);

            if (global.$memberid == null) {
                global.$memberid = "";
            }
            global.$memberid = global.$memberid.trim();
            if (global.$memberid == "" || global.memberid == " " || global.memberid == "none" || global.memberid == "None" || global.memberid == "NONE") {
                global.$memberid = "none";
            }

            if (global.$memberid != "none") {
                if (this.state.showsertificatesection == true) {
                    this.state.downloadcertificate = true
                }
                else {
                    this.state.downloadcertificate = false
                }
            }
            else {
                this.state.downloadcertificate = false
            }
            if (global.$messages == " ") {
                global.$messages.profile.message1 = "Please check your Member ID and submit feedback to get your certificate."
            }
        }
        else {
            if (this.state.showsertificatesection == true) {
                this.state.downloadcertificate = true
            }
            else {
                this.state.downloadcertificate = false
            }

            if (global.$messages == " ") {
                global.$messages.profile.message1 = "Please submit feedback to get your certificate."
            }

        }


        //console.log(global.$memberid);
        if (global.$profile.showeducationalcredits == "Y") {
            if (this.state.downloadcertificate == true) {
                return (
                    <div className="profile-certificate-info">
                        <div className="profile-certificate-header">Educational Credits</div>
                        <hr />
                        <div id="certificate_status"></div>
                        <div className="profile-certificate-button">
                            <Button onClick={this.downloadCertificate}>Send Certificate</Button>
                        </div>
                        <br />
                    </div>
                )
            }
            else {
                return (
                    <div className="profile-certificate-info">
                        <div className="profile-certificate-header">Educational Credits</div>
                        <hr />
                        <div id="download_certificate_message">{this.state.downloadcertificatemessage}</div>
                        <div className="profile-certificate-button">
                            <Button onClick={this.handleCertificate}>Send Certificate</Button>
                        </div>
                        <br />
                    </div>
                )
            }
        }
    }
    handleCertificate() {
        //console.log(global.$messages)
        if (global.$messages == "null") {
            this.setState({ downloadcertificatemessage: "Please check your Member ID and submit feedback to get your certificate." });
        }
        else {
            this.setState({ downloadcertificatemessage: global.$messages.profile.message1 });
        }
        //document.getElementById("download_certificate_message").innerHTML = "<p>You need to have a valid Member ID and make sure you submit a feedback for evalution to get certificate.</p>"
        //alert("You need to have a valid Member ID and make sure you submit a feedback for evalution to get certificate.");
    }
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    selectCountry(val) {
        this.setState({ country: val });
    }

    selectRegion(val) {
        this.setState({ state: val })
    }

    downloadCertificate(e) {
        e.preventDefault();
        document.getElementById("certificate_status").innerHTML = "<h6 style='color: green'>Sending certificate...</h6>";
        this.sendCertificate();
    }
    handleCheckbox = (event) => {
        const target = event.target;
        const name = target.name;
        let value = target.value

        if ([name] == "showdirectory") {
            this.setState({ [name]: !this.state.showdirectory });
        }
    }
    getFileName(userid, fileName) {
        var key = userid;
        key = key.replace(/,/g, '_');
        key = key.replace(/ /g, '_');
        key = key.replace(/@/g, '_');
        key = key.replace(/&/g, '_');
        //key = key.replace(/\./g, '');
        key = key.replace(/__/g, '_');
        //console.log(key);
        key = key.toLowerCase();

        fileName = fileName.toLowerCase();

        if (fileName.indexOf("jpg") !== -1) {
            key = key + ".png";
        }
        else if (fileName.indexOf("jpeg") !== -1) {
            key = key + ".png";
        }
        else if (fileName.indexOf("gif") !== -1) {
            key = key + ".png";
        }
        else if (fileName.indexOf("png") !== -1) {
            key = key + ".png";
        }
        else {
            this.state.photoFileName = "Format not supported";
        }

        //console.log(key);  
        if(key == "-1" || key == "-1.png" || key == "-1.jpg" || key == "-1.jpeg")
        {
            if(global.$userid != null)
            {
                key = global.$userid + ".png"; 
            }
            else
            {
                key = this.state.userid + ".png";     
            }
        } 

        return key;
    }

    getS3FileName() {
        return this.state.code + "/attendees/" + this.state.photoFileName;
    }

    //files
    fileUploader = (event) => {

        document.getElementById("photouploadingmessage").innerHTML = "<h6 style='color: green'>Uploading...</h6>";
        //file preview
        const file = this.refs.uploadImg.files[0]
        const reader = new FileReader();

        //////console.log("fileUploader");
        reader.onloadend = () => {
            this.setState({
                imageUrl: reader.result
            })
        }
        if (file) {
            reader.readAsDataURL(file);
            this.setState({
                imageUrl: reader.result
            })
        }
        else {
            this.setState({
                imageUrl: " "
            })
        }

        //file handler
        const target = event.target;
        const name = target.name;
        let value = target.value

        if (name === 'photo') {
            this.state.photoFileHandle = event.target.files[0];

            this.state.photoFileName = this.getFileName(this.state.userid, this.state.photoFileHandle.name);
            //console.log("photoFileName = " + this.state.photoFileHandle.name);
        }
        else {
            this.setState(
                {
                    [name]: value
                }
            );
        }

        //file storage
        const s3File = this.getS3FileName();
        //console.log(s3File);
        //console.log('file=' +this.refs.uploadImg.files[0].name+", Des ="+s3File);

        Storage.put(s3File, this.state.photoFileHandle,
            {
                contentType: 'image/png'
            })
            .then(
                result => {
                    //console.log(result);
                    document.getElementById("photouploadingmessage").innerHTML = "<h6 style='color: green'></h6>";

                    this.forceUpdate();
                }
            )
            .catch(err => {
                //console.log(s3File)
                //console.log(this.state.photoFileHandle)
                //console.log(err)
                document.getElementById("photouploadingmessage").innerHTML = "<h6 style='color: red'>Unable to upload photo. Please try again</h6>";
                //this.state.message = "Unable to upload photo. Please try again"
                this.forceUpdate();

            }
            );

    }

    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        let value = target.value

        //console.log( "Target = " + name + ", Value = " + value);

        this.setState({ [name]: value });
        //this.setState({registrationType:value})
    }
    handleRegType(event) {
        const target = event.target;
        const name = target.name;
        let value = target.value

        //console.log( "Target = " + name + ", Value = " + value);
        this.setState({ selectedRegType: event.target.value });
        //console.log(this.state.selectedRegType);

    }
    handleDropdownChange(event) {
        const target = event.target;
        const name = target.name;
        let value = target.value;

        ////console.log( name + " = " + value);

        this.setState({ selectedRegStatus: event.target.value })
    }

    handleValidation() {
        var password = this.state.password;
        if (password.length < 6) {
            document.getElementById("message_status").innerHTML = "<h6 style='color: red'>" + passwordValidation + "</h6>";
            this.forceUpdate();
            return false;
        }
        return true;
    }

    validateInputs() {

        if (this.state.code === "" || this.state.fname === "" || this.state.lname === "" || this.state.email === "" || this.state.userid === "") {
            document.getElementById("message_status").innerHTML = "<h6 style='color: red'>Enter all the required fields and click submit</h6>"
            this.forceUpdate();
            return false;
        }
        if (this.state.cauthtype == "C") {
            if (this.state.password === "") {
                document.getElementById("message_status").innerHTML = "<h6 style='color: red'>Enter all the required fields and click submit</h6>"
                this.forceUpdate();
                return false;
            }
        }
        else if (this.state.password == "N") {
            this.state.password = " ";
        }

        return true;
    }
    handleCategory(selectedRegType) {
        this.setState({ selectedRegType });

        var value = []
        //console.log("Ready")
        //console.log(selectedRegType);
        if (selectedRegType == null) {
            selectedRegType = [];
        }
        //console.log(selectedRegType);

        if (selectedRegType.length == 0 || selectedRegType == null) {
            //console.log("zero length");
            this.setState({ selected: ' ' });
            selectedRegType.length = 0;
        }
        for (var i = 0; i < selectedRegType.length; i++) {
            //console.log(selectedRegType.length);
            var newObj = Object()

            newObj.value = selectedRegType[i].value
            value.push(newObj.value + ";");
            //console.log(value)
            this.setState({ selected: value.toString() });
            //console.log(selectedRegType);
            //console.log(value.toString());
            //console.log(this.state.selected);
        }

        //console.log("Close")

    };

    handleSubmit(event) {
        event.preventDefault();

        if (this.validateInputs()) {
            if (this.state.cauthtype == "C") {
                if (this.handleValidation()) {
                    this.submitProfile(event);
                    this.setState({ isLoading: true });

                }
            }
            else {
                this.submitProfile(event);
                this.setState({ isLoading: true });
            }
        }
    }

    getDropdownCategoriesTableObjects(objs) {
        //////console.log(objs);
        const tableObjs = []

        for (var i = 0; i < objs.length; i++) {
            var categoryObj = objs[i]

            //console.log( categoryObj)

            var newObj = Object()

            newObj.name = categoryObj.name.S
            newObj.title = categoryObj.title.S
            //console.log(newObj.fullname)

            var options = []
            options = { value: newObj.name, label: newObj.title }
            tableObjs.push(options);

            //tableObjs.push(<option key={newObj.name} value={newObj.name} >{newObj.title}</option>)

        }

        //console.log(tableObjs)

        return tableObjs
    }
    getRegTypes() {
        let apiName = serviceNames.getEventPlannerService();
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'code': this.state.code,
                'command': 'getCategory',
                'type': 'attendee-regtype'
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //console.log(myInit);

        API.post(apiName, path, myInit).then(response => {
            //console.log(response.data);

            if (response.err == null && response.data != null) {
                //console.log(response.data.Items);

                let categoryObj = this.getDropdownCategoriesTableObjects(response.data.data.Items)

                this.setState({ regtype: categoryObj })

                if (this.state.selectedRegType == "") {
                    this.setState({ selectedRegType: this.state.regtype[0].props.value });
                    //console.log(this.state.selectedDropdown)                        
                }
                //console.log(this.state.regtype)

            }
            else {
                //////console.log("getCategoriesTableObjects - failed!!");
                //////console.log(response.err);
            }

        }
        ).catch(error => {
            //////console.log(error.response)
        });
    }


    handleClear(event) {
        event.preventDefault();
        this.setState({ disabled: false });
        this.state.message = "";
        this.clearInputs();
    }

    clearInputs() {
        //this.state.code         = '';
        this.state.userid = '';
        this.state.fname = '';
        this.state.lname = '';
        this.state.company = '';
        this.state.title = '';
        this.state.phone = '';
        this.state.email = '';
        this.state.country = '';
        this.state.state = '';
        this.state.city = '';
        this.state.street = '';
        this.state.zip = '';
        this.state.imageUrl = '';
        this.state.photoFileName = '';
        this.state.photoFileHandle = '';
        this.state.linkedin = '';
        this.state.twitter = '';
        this.state.fb = '';
        this.state.regid = '';
        this.state.regtype = '';
        this.state.regstatus = '';
        this.state.memberid = '';
        this.state.vpoints = '';
        this.state.apoints = '';
        this.state.tpoints = '';
        this.state.spoints = '';
        this.state.selectedRegStatus = '';
        this.state.password = '';
        this.state.myinterests = '';
        this.state.myexpertise = '';
        this.state.validscans = '';
        this.state.selectedRegType = '';
        this.state.currentlocation = '';
        this.state.lastseen = '';
        this.state.showdirectory = false;
        this.state.officephone = "";
        this.state.location = "";
        this.state.locationtime = "";
        this.state.signindate = "";
        this.state.data1 = "";
        this.state.data2 = "";
        this.state.data3 = "";
        this.state.data4 = "";
        this.state.data5 = "";
        this.state.q1 = "";
        this.state.q2 = "";
        this.state.q3 = "";
        this.state.q4 = "";
        this.state.q5 = "";
        this.state.q6 = "";
        this.state.q7 = "";
        this.state.q8 = "";
        this.state.userstype = "";
        this.state.guests = "";
        this.state.sessions = "";
        this.state.regnotes = "";
        this.state.checkinstatus = "";
        this.state.mname = "";
        this.state.totalamount = "0";
        this.state.amountpaid = "0";
        this.state.promoamount = "0";
        this.state.pendingamount = "0";
        this.state.refundamount = "0";
        this.state.paymentmode = "";
        this.state.promocodes = "";
        this.state.guestname = "";
        this.setState({ hiddenqrcode: true });

        this.forceUpdate();
    }

    submitProfile(event) {
        let apiName = serviceNames.getUserService();
        let path = ''; //replace this with the path you have configured on your API
        var date = new Date();
        var longFormat = date / 1000;

        if (this.state.lname == "") {
            this.state.lname = " ";
        }
        if (this.state.company == "") {
            this.state.company = " ";
        }
        if (this.state.title == "") {
            this.state.title = " ";
        }
        if (this.state.phone == "") {
            this.state.phone = " ";
        }
        if (this.state.linkedin == "") {
            this.state.linkedin = " ";
        }
        if (this.state.twitter == "") {
            this.state.twitter = " ";
        }
        if (this.state.street == "") {
            this.state.street = " ";
        }
        if (this.state.city == "") {
            this.state.city = " ";
        }
        if (this.state.state == "") {
            this.state.state = " ";
        }
        if (this.state.zip == "") {
            this.state.zip = " ";
        }
        if (this.state.country == "") {
            this.state.country = " ";
        }
        if (this.state.fb == "") {
            this.state.fb = " ";
        }
        if (this.state.regtype == "") {
            this.state.regtype = " ";
        }
        if (this.state.regid == "") {
            this.state.regid = " ";
        }
        if (this.state.memberid == "") {
            this.state.memberid = " ";
        }
        if (this.state.photoFileName == ".png") {
            this.state.photoFileName = this.state.id + ".png"
        }
        if (this.state.photoFileName == "") {
            this.state.photoFileName = " ";
        }
        if (this.state.photoFileHandle == "") {
            this.state.photoFileHandle = " ";
        }
        if (this.state.vpoints == "") {
            this.state.vpoints = "0";
        }
        if (this.state.tpoints == "") {
            this.state.tpoints = "0";
        }
        if (this.state.apoints == "") {
            this.state.apoints = "0";
        }
        if (this.state.spoints == "") {
            this.state.spoints = "0";
        }
        if (this.state.regstatus == "") {
            this.state.regstatus = " ";
        }
        if (this.state.password == "") {
            this.state.password = " ";
        }
        if (this.state.myexpertise == "") {
            this.state.myexpertise = " ";
        }
        if (this.state.myinterests == "") {
            this.state.myinterests = " ";
        }
        if (this.state.selectedRegStatus == "") {
            this.state.selectedRegStatus = " ";
        }
        if (this.state.poolid == "") {
            this.state.poolid = " ";
        }
        if (this.state.currentlocation == "") {
            this.state.currentlocation = " ";
        }
        if (this.state.lastseen == "") {
            this.state.lastseen = " ";
        }
        if (this.state.selected == "") {
            this.state.selected = "Delegate";
        }
        if (this.state.data1 == "") {
            this.state.data1 = " ";
        }
        if (this.state.data2 == "") {
            this.state.data2 = " ";
        }
        if (this.state.data3 == "") {
            this.state.data3 = " ";
        }
        if (this.state.data4 == "") {
            this.state.data4 = " ";
        }
        if (this.state.data5 == "") {
            this.state.data5 = " ";
        }
        if (this.state.q1 == "") {
            this.state.q1 = " ";
        }
        if (this.state.q2 == "") {
            this.state.q2 = " ";
        }
        if (this.state.q3 == "") {
            this.state.q3 = " ";
        }
        if (this.state.q4 == "") {
            this.state.q4 = " ";
        }
        if (this.state.q5 == "") {
            this.state.q5 = " ";
        }
        if (this.state.q6 == "") {
            this.state.q6 = " ";
        }
        if (this.state.q7 == "") {
            this.state.q7 = " ";
        }
        if (this.state.q8 == "") {
            this.state.q8 = " ";
        }
        if (this.state.officephone == "") {
            this.state.officephone = " ";
        }
        if (this.state.location == "") {
            this.state.location = " ";
        }
        if (this.state.locationtime == "") {
            this.state.locationtime = " ";
        }
        if (this.state.signindate == "") {
            this.state.signindate = " ";
        }
        if (this.state.userstype == "") {
            this.state.userstype = " ";
        }
        if (this.state.guests == "") {
            this.state.guests = " ";
        }
        if (this.state.sessions == "") {
            this.state.sessions = " ";
        }
        if (this.state.regnotes == "") {
            this.state.regnotes = " ";
        }
        if (this.state.checkinstatus == "") {
            this.state.checkinstatus = " ";
        }
        if (this.state.mname == "") {
            this.state.mname = " ";
        }
        if (this.state.totalamount == "") {
            this.state.totalamount = "0";
        }
        if (this.state.amountpaid == "") {
            this.state.amountpaid = "0";
        }
        if (this.state.promoamount == "") {
            this.state.promoamount = "0";
        }
        if (this.state.pendingamount == "") {
            this.state.pendingamount = "0";
        }
        if (this.state.refundamount == "") {
            this.state.refundamount = "0";
        }
        if (this.state.promocodes == "") {
            this.state.promocodes = " ";
        }
        if (this.state.guestname == "") {
            this.state.guestname = " ";
        }
        if (this.state.regitemsjson == "") {
            this.state.regitemsjson = " ";
        }
        if (this.state.validscans == "") {
            if (this.state.selected.includes("Delegate")) {
                this.state.command = "attendee-delegate";
                this.getValidScans();
            }
            if (this.state.selected.includes("Faculty")) {
                this.state.command = "attendee-faculty";
                this.getValidScans();
            }
            if (this.state.selected.includes("Exhibitor")) {
                this.state.command = "attendee-exhibitor";
                this.getValidScans();
            }
            if (this.state.selected.includes("Committee")) {
                this.state.command = "attendee-committee";
                this.getValidScans();
            }
            if (this.state.selected.includes("Member")) {
                this.state.command = "attendee-member";
                this.getValidScans();
            }
            if (this.state.selected.includes("Non-Member")) {
                this.state.command = "attendee-nonmember";
                this.getValidScans();
            }
            else {
                this.state.validscans = " ";
            }
        }
        global.$memberid = this.state.memberid;

        this.state.userid = (this.state.userid).toLowerCase();

        let myInit =
        {
            body:
            {
                'code': this.state.code,
                'command': 'addUserProfile',
                'userid': this.state.userid,
                'fname': this.state.fname,
                'lname': this.state.lname,
                'company': this.state.company,
                'title': this.state.title,
                'phone': this.state.phone,
                'email': this.state.email,
                'linkedin': this.state.linkedin,
                'twitter': this.state.twitter,
                'fb': this.state.fb,
                'country': this.state.country,
                'state': this.state.state,
                'city': this.state.city,
                'street': this.state.street,
                'zip': this.state.zip,
                'regid': this.state.regid,
                'regtype': this.state.selected,
                'photo': this.state.photoFileName,
                'memberid': this.state.memberid,
                'tag': 'tag',
                'ep': ' ',
                'submittedby': this.state.submittedby,
                'udate': longFormat.toString(),
                'visitpoints': this.state.vpoints,
                'activitypoints': this.state.apoints,
                'totalpoints': this.state.tpoints,
                'regstatus': this.state.selectedRegStatus,
                'sessionpoints': this.state.spoints,
                'poolid': this.state.poolid,
                'password': this.state.password,
                'myexpertise': this.state.myexpertise,
                'myinterests': this.state.myinterests,
                'validscans': this.state.validscans,
                'officephone': this.state.officephone,
                'currentlocation': this.state.currentlocation,
                'lastseen': this.state.lastseen,
                'location': this.state.location,
                'locationtime': this.state.locationtime,
                'signindate': this.state.signindate,
                'data1': this.state.data1,
                'data2': this.state.data2,
                'data3': this.state.data3,
                'data4': this.state.data4,
                'data5': this.state.data5,
                'showdirectory': this.state.showdirectory.toString(),
                "q1": this.state.q1,
                "q2": this.state.q2,
                "q3": this.state.q3,
                "q4": this.state.q4,
                "q5": this.state.q5,
                "q6": this.state.q6,
                "q7": this.state.q7,
                "q8": this.state.q8,
                "userstype": this.state.userstype,
                "guests": this.state.guests,
                "sessions": this.state.sessions,
                "regnotes": this.state.regnotes,
                "checkinstatus": this.state.checkinstatus,
                "mname": this.state.mname,
                "totalamount": this.state.totalamount.toString(),
                "amountpaid": this.state.amountpaid.toString(),
                "promoamount": this.state.promoamount.toString(),
                "pendingamount": this.state.pendingamount.toString(),
                "refundamount": this.state.refundamount.toString(),
                "paymentmode": this.state.paymentmode,
                "promocodes": this.state.promocodes,
                "guestname": this.state.guestname,
                "regitemsjson": this.state.regitemsjson
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        this.setState({ disabled: true });

        //console.log(myInit);

        API.post(apiName, path, myInit).then(response => {

            //console.log("response.data ==> " + response.data + "response.err ==> " + response.err);
            this.setState({ disabled: false });

            if (response.err == null) {
                //console.log(myInit.body);
                global.$attendeeinfo = myInit.body;
                //console.log("postExhibitorProfile - Success!!");
                document.getElementById("message_status").innerHTML = "<h6>Profile successfully updated</h6>";
                this.setState({
                    isLoading: false,
                    hiddenqrcode: true
                });
                this.getRegTypes();
                this.copyFiles(myInit.body.photo);
                this.getUsers();
                this.getUser()
                //this.clearInputs();
                //this.state.userid = "addnew"
                this.forceUpdate();
            }
            else {
                //////console.log("postExhibitorProfile - failed!!");
                document.getElementById("message_status").innerHTML = "<h6 style='color: red'>Error!! Unable to submit profile. Please try again.</h6>"
                this.setState({ isLoading: false });
                this.forceUpdate();
            }

        }).catch(error => {
            //////console.log(error.response)
            this.setState({ disabled: false });
        });
    }

    copyFiles(photo) {
        let apiName = serviceNames.getUserService();
        let path = ''; //replace this with the path you have configured on your API

        let myInit =
        {
            body:
            {
                'code': this.state.code,
                'command': 'copyS3Files',
                'srcbucket': 'event360live',
                'desbucket': 'wesicore',
                'fileFrom': 'attendees',
                'fileTo': 'upload/profiles',
                'photo': photo
            },
            response: true,
            headers: {}

        }
        //console.log(myInit);
        API.post(apiName, path, myInit).then(response => {

            //console.log ("response.data ==> " + response.data + "response.err ==> " + response.err);
            if (response.err == null && response.data != null) {
                //console.log("Change Password - Success!!");
                this.getS3PrivateImg(photo) //Suresh:  Is this required?    
            }
            else {
                //console.log("Change Password - failed!!");
            }

        }).catch(error => {
            //console.log(error.response)
        });
    }

    getUser() {

        let apiName = serviceNames.getUserService();
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'command': 'getUserProfile',
                'code': this.state.code,
                'userid': this.state.userid,
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //console.log(myInit);

        API.post(apiName, path, myInit).then(response => {
            //console.log(response.data);

            if (response.err == null && response.data != null) {
                //console.log(response.data.Item);

                let userObj = this.getUserObject(response.data.Item);
                //console.log(userObj);

                this.setState({
                    userid: userObj.userid,
                    fname: userObj.fname,
                    lname: userObj.lname,
                    company: userObj.company,
                    title: userObj.title,
                    email: userObj.email,
                    phone: userObj.phone,
                    photoFileName: userObj.photo,
                    photoFileHandle: userObj.photo,
                    country: userObj.country,
                    street: userObj.street,
                    city: userObj.city,
                    state: userObj.state,
                    zip: userObj.zip,
                    linkedin: userObj.linkedin,
                    twitter: userObj.twitter,
                    fb: userObj.fb,
                    regid: userObj.regid,
                    selected: userObj.regtype,
                    memberid: userObj.memberid,
                    vpoints: userObj.visitpoints,
                    apoints: userObj.activitypoints,
                    tpoints: userObj.totalpoints,
                    spoints: userObj.sessionpoints,
                    selectedRegStatus: userObj.regstatus,
                    password: userObj.password,
                    myexpertise: userObj.myexpertise,
                    myinterests: userObj.myinterests,
                    validscans: userObj.validscans,
                    currentlocation: userObj.currentlocation,
                    lastseen: userObj.lastseen,
                    showdirectory: userObj.showdirectory,
                    officephone: userObj.officephone,
                    location: userObj.location,
                    locationtime: userObj.locationtime,
                    signindate: userObj.signindate,
                    data1: userObj.data1,
                    data2: userObj.data2,
                    data3: userObj.data3,
                    data4: userObj.data4,
                    data5: userObj.data5,
                    q1: userObj.q1,
                    q2: userObj.q2,
                    q3: userObj.q3,
                    q4: userObj.q4,
                    q5: userObj.q5,
                    q6: userObj.q6,
                    q7: userObj.q7,
                    q8: userObj.q8,
                    userstype: userObj.userstype,
                    guests: userObj.guests,
                    sessions: userObj.sessions,
                    regnotes: userObj.regnotes,
                    checkinstatus: userObj.checkinstatus,
                    mname: userObj.mname,
                    totalamount: userObj.totalamount,
                    amountpaid: userObj.amountpaid,
                    promoamount: userObj.promoamount,
                    pendingamount: userObj.pendingamount,
                    refundamount: userObj.refundamount,
                    paymentmode: userObj.paymentmode,
                    promocodes: userObj.promocodes,
                    guestname: userObj.guestname,
                    regitemsjson:userObj.regitemsjson,
                    dataloaded: true
                })
                if (Number(userObj.sessionpoints) > 0) {
                    //console.log("true points");
                    this.setState({ showsertificatesection: true });
                }
                //console.log(this.state.showdirectory);
                if (this.state.showdirectory == "true" || this.state.showdirectory == "TRUE") {
                    //console.log("true")
                    this.setState({ showdirectory: true });
                }
                else {
                    //console.log("false")
                    this.setState({ showdirectory: false });
                }
                //console.log(this.state.photoFileName)
                
                //this.setState({ imageUrl: 'https://s3.amazonaws.com/event360live/public/' + this.state.code + '/attendees/' + this.state.photoFileName });
                if (this.state.photoFileName == "" || this.state.photoFileName == " " || this.state.photoFileName == "common_profile.png" || this.state.photoFileName == "-1") {
                    this.setState({ imageUrl: "common_profile.png" })
                }
                else {
                    this.getS3PrivateImg(this.state.photoFileName)
                }
                //console.log(this.state.imageUrl);

                if (this.state.selected != " ") {
                    var array = this.state.selected.split(",")
                    var value = [];
                    //array.forEach(function(item)
                    for (var i = 0; i <= array.length; i++) {
                        //console.log(array[i])

                        if (array[i] == undefined) {
                            //console.log(this.state.registrationType)    
                        }
                        else {
                            var newObj = Object();

                            newObj = { value: array[i], label: array[i] }

                            //console.log(newObj)

                            value.push(newObj);
                            //console.log(value)
                        }

                        this.state.selectedRegType = value;
                        //console.log(this.state.registrationType)        
                    }
                }
                else {
                    this.setState({ selected: "" })
                }

            }
            else {
                //////console.log("getExhibitorsTableObjects - failed!!");
                //////console.log(response.err);

            }

        }
        ).catch(error => {
            //////console.log(error.response)
        });
    }

    getUserObject(objs) {
        //var obj = objs[0] 
        //console.log( objs)

        var newObj = Object()

        newObj.userid = objs.userid.S
        newObj.fname = objs.fname.S
        newObj.lname = objs.lname.S
        newObj.photo = objs.photo.S
        newObj.company = objs.company.S
        newObj.phone = objs.phone.S
        newObj.title = objs.title.S
        newObj.email = objs.email.S
        newObj.country = objs.country.S
        newObj.state = objs.state.S
        newObj.street = objs.street.S
        newObj.city = objs.city.S
        newObj.state = objs.state.S
        newObj.zip = objs.zip.S
        newObj.linkedin = objs.linkedin.S
        newObj.twitter = objs.twitter.S
        newObj.fb = objs.fb.S
        newObj.regid = objs.regid.S
        newObj.regtype = objs.regtype.S
        newObj.memberid = objs.memberid.S
        newObj.visitpoints = objs.visitpoints.N
        newObj.activitypoints = objs.activitypoints.N
        newObj.totalpoints = objs.totalpoints.N
        newObj.sessionpoints = objs.sessionpoints.N
        newObj.regstatus = objs.regstatus.S
        newObj.password = objs.password.S
        newObj.myexpertise = objs.myexpertise.S
        newObj.myinterests = objs.myinterests.S
        newObj.validscans = objs.validscans.S
        newObj.currentlocation = objs.currentlocation.S
        newObj.lastseen = objs.lastseen.S
        newObj.showdirectory = objs.showdirectory.S
        newObj.officephone = objs.officephone.S
        newObj.location = objs.location.S
        newObj.locationtime = objs.locationtime.S
        newObj.signindate = objs.signindate.S
        newObj.data1 = objs.data1.S
        newObj.data2 = objs.data2.S
        newObj.data3 = objs.data3.S
        newObj.data4 = objs.data4.S
        newObj.data5 = objs.data5.S
        newObj.q1 = objs.q1.S
        newObj.q2 = objs.q2.S
        newObj.q3 = objs.q3.S
        newObj.q4 = objs.q4.S
        newObj.q5 = objs.q5.S
        newObj.q6 = objs.q6.S
        newObj.q7 = objs.q7.S
        newObj.q8 = objs.q8.S
        newObj.userstype = objs.userstype.S
        newObj.guests = objs.guests.S
        newObj.sessions = objs.sessions.S
        newObj.regnotes = objs.regnotes.S
        newObj.checkinstatus = objs.checkinstatus.S
        newObj.mname = objs.mname.S
        newObj.totalamount = objs.totalamount.N
        newObj.amountpaid = objs.amountpaid.N
        newObj.promoamount = objs.promoamount.N
        newObj.pendingamount = objs.pendingamount.N
        newObj.refundamount = objs.refundamount.N
        newObj.paymentmode = objs.paymentmode.S
        newObj.promocodes = objs.promocodes.S
        newObj.guestname = objs.guestname.S
        newObj.regitemsjson = objs.regitemsjson.S
        //console.log(newObj)
        //////console.log(newObj.category);

        return newObj
    }

    getCategoriesTableObjects(objs) {
        //var obj = objs[0] 
        //console.log( objs)

        const tableObjs = []

        for (var i = 0; i < objs.length; i++) {
            var categoryObjs = objs[i]
            //console.log(categoryObjs)
            var newObj = Object()

            newObj.name = categoryObjs.name.S

            //console.log(newObj)


        }

        //console.log(tableObjs)

        return newObj
    }
    getValidScans() {
        let apiName = serviceNames.getEventPlannerService();
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'code': this.state.code,
                'command': 'getCategory',
                'type': this.state.command
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //console.log(myInit);

        API.post(apiName, path, myInit).then(response => {
            //console.log (response.data);

            if (response.err == null && response.data != null) {
                //console.log(response.data.Items);

                let categoryObj = this.getCategoriesTableObjects(response.data.data.Items)

                //console.log(categoryObj.name)
                //this.state.validscans = categoryObj.name
                this.setState({ validscans: categoryObj.name })
                //console.log(this.state.validscans)

            }
            else {
                //////console.log("getCategoriesTableObjects - failed!!");
                //////console.log(response.err);
            }

        }
        ).catch(error => {
            //////console.log(error.response)
        });
    }

    handleDisplayNewPassword = event => {
        document.getElementById("newpwd").style.display = "block";
        document.getElementById("newpwdlabel").style.display = "block";
        document.getElementById("newpwdbutton").style.display = "block";
    }
    handleDisplyOTPField = event => {
        if (this.state.newpwd.length >= 8) {
            document.getElementById("changepassword-result").innerHTML = "<p></p>";
            Amplify.configure({
                Auth: {
                    //identityPoolId:         'us-east-1:f21ee086-a0ae-4bfc-a1b7-58913104d709', //exhibitors_sponsors_ip
                    //region:                 'us-east-1',

                    //userPoolId:             'us-east-1_LtVLdp322', //us-east-1_LtVLdp322 - AppAdmin 
                    //userPoolWebClientId:    '2cbpuu5f2vql93d28pk0he39do' //2cbpuu5f2vql93d28pk0he39do  - AppAdmin

                    //userPoolId:             'us-east-1_cx9zgTYXf',        //us-east-1_cx9zgTYXf - devtest 
                    //userPoolWebClientId:    '5dfap44mptvq1b5bie84eaoav8'  //4ul2rsbo8viha7u2lfv7s32pej  - devtest without secretkey
                    //userPoolWebClientId:    '4ul2rsbo8viha7u2lfv7s32pej' //4ul2rsbo8viha7u2lfv7s32pej  - devtest with secretkey

                    userPoolId: global.$poolid,    //us-east-1_cx9zgTYXf - devtest 
                    userPoolWebClientId: global.$clientid,  //4ul2rsbo8viha7u2lfv7s32pej  - devtest without secretkey
                },
            })
            global.$loginid = global.$loginid.toLowerCase()

            this.handleChangePassword();
        }
        else {
            document.getElementById("changepassword-result").innerHTML = "<p style='color: red'>Minimum password length should be at least 8 characters</p>";
        }



    }
    handleChangePassword() {
        Auth.forgotPassword(global.$loginid)
            .then(data => {
                if (this.state.newpwd != "") {
                    document.getElementById("newpwd").style.display = "none";
                    document.getElementById("newpwdlabel").style.display = "none";
                    document.getElementById("newpwdbutton").style.display = "none";
                    document.getElementById("otp").style.display = "block";
                    document.getElementById("otplabel").style.display = "block";
                    document.getElementById("otpbutton").style.display = "block";
                }
            })
            .catch(err => {
                //console.log(err)
                document.getElementById("changepassword-result").innerHTML = "<p style='color: red'>Unable to change password try again</p>";
            })

    }
    processChangePassword = event => {

        var new_password = this.state.newpwd;
        var code = this.state.otp;

        document.getElementById("newpwd").style.display = "none";
        document.getElementById("newpwdlabel").style.display = "none";
        document.getElementById("newpwdbutton").style.display = "none";
        document.getElementById("otp").style.display = "none";
        document.getElementById("otplabel").style.display = "none";
        document.getElementById("otpbutton").style.display = "none";

        //console.log(new_password);
        //console.log(otp);

        //var new_password = prompt("Enter New Password:", "")

        //var code = prompt("Enter Code Sent to " + data.CodeDeliveryDetails.Destination, "")


        Auth.forgotPasswordSubmit(global.$loginid, code, new_password)
            .then(data => {
                //console.log(data);
                document.getElementById("changepassword-result").innerHTML = "<p style='color: green'>Password successfully changed</p>"
                //this.setState({ message: 'Password successfully changed' });
            })
            .catch(err => {
                ////console.log(err)
                document.getElementById("changepassword-result").innerHTML = "<p style='color: red'>Unable to change password try again</p>";
                //this.setState({ message: 'Unable to change password' });
            })

        //////console.log("preocessChangePassword - success")
    }

    getS3PrivateImg(userphoto) {
        global.$userphoto = userphoto;
        let apiName = serviceNames.getWebsiteService();
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'code': this.state.code,
                'command': 'getS3PrivateImg',
                'bucket': 'wesicore',
                'key': 'event360/' + this.state.code + '/upload/profiles/' + userphoto //CHECK: Hardcoded path
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }

        //console.log(myInit);

        API.post(apiName, path, myInit).then(response => {
            //console.log ("response.data ===> " + response.data + "response.err ===> " + response.err);

            if (response.err == null && response.data != null) {
                //console.log(response.data);
                this.setState({ imageUrl: "data:image/jpeg;base64," + response.data });
                global.$attendeeimg = this.state.imageUrl;
                global.$imageUrl = this.state.imageUrl;
                //console.log(this.state.imageUrl);

                this.setState({ dataloaded: true })
            }
            else {
                //console.log("getAttendeesTableObjects - failed!!");
                //console.log(response.err);
            }
        }
        ).catch(error => {
            //console.log(error.response)
        });
    }

    sendCertificate(userid) {
        let apiName = serviceNames.getDocumentGeneratorService();
        let path = ''; //replace this with the path you have configured on your API

        let myInit = {
            body:
            {
                'code': this.state.code,
                'command': 'sendCertificateFromApp',
                'bucket': "wesicore",
                'userid': global.$loginid,
                'certificatetype': this.state.certificatetype, // single || multiple
                'submissiontype': this.state.submissiontype // badgeScan|| feedback
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //console.log(myInit);

        API.post(apiName, path, myInit).then(response => {
            //console.log("response.data ===> " + response.data + "response.err ===> " + response.err);
            this.setState({ isLoading: false })

            if (response.err == null) {
                /* var filepath = `https://s3.amazonaws.com/event360/${this.state.code}/public/certificates/${userid}_certificate.pdf`;
                    //var filepath = `s3://event360live/public/${this.state.code}/badges/${userid}_badge.pdf` 
                //console.log(filepath);
                    window.open(filepath);*/
                document.getElementById("certificate_status").innerHTML = "<h6 style='color: green'>Your certificate was sent to your email.</h6>";
            }
            else {
                //console.log("getAttendeesTableObjects - failed!!");
                //console.log(response.err);
                document.getElementById("certificate_status").innerHTML = "<h6 style='color: red'>Error!! Unable to send email.</h6>";

            }
        }
        ).catch(error => {
            //console.log(error.response)
        });
    }

    //
    //  Capture Photo crom Camera
    // 

    showTakePhoto() {
        //Show Take Photo button
        var takephoto = document.getElementById('takephoto');
        this.setState({
            showProfilePhoto: false,
            showcamera: false
        });
        //takephoto.style.display = "block";
        document.getElementById("cameradiv").style.display = "block";
        document.getElementById("camera").style.display = "none";
        //document.getElementById("upload").style.display = "none";
        document.getElementById("profilediv").style.display = "none";
        document.getElementById("takephoto").style.display = "block";
    }

    hideTakePhoto() {
        //Hide Take Photo button

        var takephoto = document.getElementById('takephoto');
        //document.getElementById("profilediv").style.display = "block";
        //document.getElementById("takephoto").style.display = "none";
        //document.getElementById("camera").style.display = "block";
        //takephoto.style.display = "none";       
    }

    startCamera(e) {
        var video = document.getElementById('video');

        this.showTakePhoto();

        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {

            navigator.mediaDevices.getUserMedia({ video: true })
                .then(function (stream) {
                    video.srcObject = stream;
                    video.play();
                })
                .catch(function (err) {
                    //console.log("An error occurred: " + err);
                });
        }
    }

    stopCamera() {
        var video = document.getElementById('video');

        var stream = video.srcObject;
        //console.log(stream);
        if (stream == null)
            return;

        var tracks = stream.getTracks();

        //console.log(tracks);
        if (tracks == null)
            return;

        tracks.forEach(function (track) {
            track.stop();
        });

        video.srcObject = null;
    }

    uploadPhotoFromCamera(imageData) {
        const s3File = this.getS3FileName();
        //console.log(s3File);

        Storage.put(s3File, imageData,
            {
                contentType: 'image/png'
            })
            .then(
                result => {

                    //console.log(result);

                    document.getElementById("photouploadingmessage").innerHTML = "<h6 style='color: green'></h6>";
                    this.forceUpdate();

                    //console.log('copyFiles ' + this.state.photoFileName);

                    this.copyFiles(this.state.photoFileName);
                })
            .catch(err => {
                document.getElementById("photouploadingmessage").innerHTML = "<h6 style='color: red'>Unable to upload photo. Please try again</h6>";
                this.forceUpdate();
            });
    }

    takePhoto(e) {
        //var height = 250;

        this.setState({
            showProfilePhoto: true,
            showcamera: true
        });

        var video = document.getElementById('video');
        var canvas = document.getElementById('canvas');
        var context = canvas.getContext('2d');
        var photo = document.getElementById('profilephoto');

        canvas.style.display = "none";

        var width = 150;
        var height = 150;

        //var height = video.videoHeight / (video.videoWidth/width);

        // NOTE:  Image control uses data:Base64 and S3 requires blob.  

        context.drawImage(video, 0, 0, width, height);
        var data = canvas.toDataURL('image/png');
        photo.setAttribute('src', data);

        this.stopCamera();

        canvas.toBlob(blob => {
            this.uploadPhotoFromCamera(blob);
        });

        this.hideTakePhoto();
        document.getElementById("cameradiv").style.display = "none";
        document.getElementById("profilediv").style.display = "block";
        document.getElementById("takephoto").style.display = "none";
        document.getElementById("camera").style.display = "block";
        //document.getElementById("upload").style.display = "block";

    }
    getAttendeesTableObjects(objs) {
        //console.log(objs);

        const tableObjs = []

        for (var i = 0; i < objs.length; i++) {
            var attendeeObj = objs[i]

            //console.log(attendeeObj)

            var newObj = Object()

            newObj.fname = attendeeObj.fname.S
            newObj.lname = attendeeObj.lname.S
            newObj.photo = attendeeObj.photo.S
            newObj.title = attendeeObj.title.S
            newObj.userid = attendeeObj.userid.S
            newObj.email = attendeeObj.email.S
            newObj.phone = attendeeObj.phone.S
            newObj.company = attendeeObj.company.S
            newObj.myinterests = attendeeObj.myinterests.S
            newObj.myexpertise = attendeeObj.myexpertise.S
            newObj.street = attendeeObj.street.S
            newObj.city = attendeeObj.city.S
            newObj.state = attendeeObj.state.S
            newObj.zip = attendeeObj.zip.S
            newObj.linkedin = attendeeObj.linkedin.S
            newObj.twitter = attendeeObj.twitter.S
            newObj.facebook = attendeeObj.fb.S
            newObj.showdirectory = attendeeObj.showdirectory.S
            newObj.data1 = attendeeObj.data1.S
            newObj.data2 = attendeeObj.data2.S
            newObj.data3 = attendeeObj.data3.S
            newObj.data4 = attendeeObj.data4.S
            newObj.q1 = attendeeObj.q1.S.substring(
              attendeeObj.q1.S.lastIndexOf(";answer:") + 8);
      
            newObj.q2 = attendeeObj.q2.S.substring(
              attendeeObj.q2.S.lastIndexOf(";answer:") + 8);

            tableObjs.push(newObj)
        }

        //console.log(tableObjs)

        return tableObjs
    }


    // Suresh:  Is this required?

    getUsers() {

        //console.log("getUsers");

        let apiName = serviceNames.getUserService();
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'code': this.state.code,
                'command': 'getUsers'
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //console.log(myInit);

        API.post(apiName, path, myInit).then(response => {
            //console.log ("response.data ===> " + response.data + "response.err ===> " + response.err);

            if (response.err == null && response.data != null) {
                //console.log("getAttendeesTableObjects - Success!!");
                //console.log(response);

                var attendees = this.getAttendeesTableObjects(response.data)
                //console.log(attendees);
                global.$attendeejson = attendees;
                //console.log(global.$attendeejson)
                //console.log(attendees.photo)

                //this.setState({ dataloaded: true })
                //console.log(this.state.dataloaded);
                //console.log(this.state.data);
            }
            else {
                //console.log("getAttendeesTableObjects - failed!!");
                //console.log(response);
            }
        }
        ).catch(error => {
            //console.log(error.response)
        });
    }
    getUsersTableObjects(objs) {
        //console.log(objs);

        const tableObjs = []

        for (var i = 0; i < objs.length; i++) {
            var responseObj = objs[i]
            if (responseObj.userid.S == global.$loginid) {
                var newObj = Object()
                newObj.userid = responseObj.userid.S
                newObj.points = responseObj.sessionpoints.N
                tableObjs.push(newObj)
            }

        }
        //console.log(tableObjs)

        return tableObjs;
    }

    displayAttendeeBadgeLevel() {
        //console.log(global.$points);
        //console.log(global.$points.attendeebadgelevels.levels.gold);
        var levels = global.$points.attendeebadgelevels.levels;
        if (Number(this.state.tpoints) >= Number(levels.platinum.minpoints) && Number(this.state.tpoints) <= Number(levels.platinum.maxpoints)) {
            return (
                <div className="profile-badge-level-platinum" style={levels.platinum.styles}>
                    {levels.platinum.title} <Icon path={mdiStar} size={0.9} className="profile-badge-level-icon" />{this.state.tpoints}
                </div>
            )
        }
        else {
            if (Number(this.state.tpoints) >= Number(levels.gold.minpoints) && Number(this.state.tpoints) <= Number(levels.gold.maxpoints)) {
                return (
                    <div className="profile-badge-level-gold" style={levels.gold.styles}>
                        {levels.gold.title} <Icon path={mdiStar} size={0.9} className="profile-badge-level-icon" />{this.state.tpoints}
                    </div>
                )
            }
            else {
                if (Number(this.state.tpoints) >= Number(levels.silver.minpoints) && Number(this.state.tpoints) <= Number(levels.silver.maxpoints)) {
                    return (
                        <div className="profile-badge-level-silver" style={levels.silver.styles}>
                            {levels.silver.title} <Icon path={mdiStar} size={0.9} className="profile-badge-level-icon" />{this.state.tpoints}
                        </div>
                    )
                }
                else {
                    if (Number(this.state.tpoints) >= Number(levels.bronze.minpoints) && Number(this.state.tpoints) <= Number(levels.bronze.maxpoints)) {
                        return (
                            <div className="profile-badge-level-bronze" style={levels.bronze.styles}>
                                {levels.bronze.title} <Icon path={mdiStar} size={0.9} className="profile-badge-level-icon" />{this.state.tpoints}
                            </div>
                        )
                    }
                }
            }
        }
    }

    /*
    // Suresh:  Is this required?

    getusersTofindSessionPoints() {

        //console.log("getUsers");

        let apiName = serviceNames.getUserService();
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'code': this.state.code,
                'command': 'getUsers'
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //console.log(myInit);

        API.post(apiName, path, myInit).then(response => {
            //console.log ("response.data ===> " + response.data + "response.err ===> " + response.err);

            //console.log(response.data);
            if (response.err == null && response.data != null) {
                //console.log("getAttendeesTableObjects - Success!!");

                var responses = this.getUsersTableObjects(response.data.Items)
                //console.log(responses);
                //console.log(responses[0].points);

                if (Number(responses[0].points) > 0) {
                    //console.log("true");
                    this.setState({ showsertificatesection: true });
                }
            }
            else {
                //console.log(response.err);
            }
        }
        ).catch(error => {
            //console.log(error.response)
        });
    }
    */
}
export default ProfilePage;
