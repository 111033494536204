import { mdiDownload } from '@mdi/js';
import Icon from '@mdi/react';
import React,{Component} from 'react';
import { Document } from 'react-pdf';
import { Page } from 'react-pdf/dist/entry.webpack';


class SessionGame extends Component{
    constructor(props){
        super(props)
        this.state = {
            
        }
    }

    render(){
        return(             
            <div>
                {global.$config.session.bingo ?
                    <div className="handout-div ">
                        {
                            global.$config.session.bingo.showbingo === true && global.$config.session.bingo.sessionid === this.props.session.SID ?
                                <div>
                                    <a href={"https://event360live.s3.amazonaws.com/public/" + global.$code + "/schedule/games/" + global.$loginid+".pdf"} target="_blank" >
                                    <Document
                                        file={"https://event360live.s3.amazonaws.com/public/" + global.$code + "/schedule/games/" + global.$loginid+".pdf"}
                                        onLoadSuccess={this.onDocumentLoadSuccess}
                                        className="pdf-thumbnail"
                                        loading="Loading..."
                                    >
                                    </Document>
                                    <p style={global.$schedule.styles.scheduleheadertitle} className='game-btn'><Icon path={mdiDownload} size={0.9} />Download Bingo Card</p>
                                    </a> 
                                </div>
                            : ""
                            
                        }
                        
                    </div>
                :""}
            </div>
        )
    }
}
export default SessionGame;