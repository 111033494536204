import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripeCheckoutForm from "./StripeCheckoutForm";
import "./Stripe.css";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// loadStripe is initialized with a fake API key.
// Sign in to see examples pre-filled with your key.
var promise = "";

class Stripe extends React.Component {
  constructor(props) {
    super(props);
   //console.log(props);
 //console.log(global.$registrationform.payment);
   var payment = global.$registrationform.payment;
   
   var stripekey = "";  
   
   if(payment.credit != null && payment.credit.paymentmode == "stripe")
   {
      stripekey = payment.credit.key;
   }
   else if(payment.debit != null && payment.debit.paymentmode == "stripe")
   {
      stripekey = payment.debit.key;
   }
   
  //console.log(stripekey);
   
   promise = loadStripe(stripekey);
    
   this.state = {
        code: global.$code,
    };   

    global.$regattendeestatus = this.props.regattendeestatus;
    global.$reggueststatus = this.props.reggueststatus;
    global.$regusertype = this.props.type;
    this.hideRegisterButtonFromStripeHome = this.hideRegisterButtonFromStripeHome.bind(this);
}

render() {
 //console.log(this.props);
  return (
    <div>
      <Elements stripe={promise}>
        <StripeCheckoutForm regattendeestatus={this.props.regattendeestatus} reggueststatus={this.props.reggueststatus} hideRegisterButton={this.props.hideRegisterButton}/>
      </Elements>
    </div>
  );
}

hideRegisterButtonFromStripeHome()
{
   //console.log("hideRegisterButtonFromPaypalHome()");
    this.props.hideRegisterButton();
}
}
export default Stripe;