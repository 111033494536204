import React from 'react';
import { Row, Col } from 'reactstrap';
import LoadPrivateImg from '../Components/Shared/LoadPrivateImg';
import commonProfile from '../images/common_profile.png';
import './Delegates.css';
import Avatar from 'react-avatar';
import { mdiEmail, mdiPhone, mdiLinkedin, mdiTwitter, mdiFacebook, mdiStar } from '@mdi/js';
import Icon from '@mdi/react';

class DelegateProfile extends React.Component {
    constructor(props) {

        if (props.showdirectory == null) {
            props.showdirectory = "true"
        }
        var showcompany = true;
        var showtitle = true;
        var showq1 = false;
        var showq2 = false;

        if (global.$attendees.showcompany != null) {
            showcompany = global.$attendees.showcompany;
        }
        if (global.$attendees.showtitle != null) {
            showtitle = global.$attendees.showtitle;
        }
        if (global.$attendees.showq1 != null) {
            showq1 = global.$attendees.showq1;
        }
        if (global.$attendees.showq2 != null) {
            showq2 = global.$attendees.showq2;
        }
        super(props);
        this.state = {
            code: props.code,
            userid: props.userid,
            company: props.company,
            fname: props.fname,
            lname: props.lname,
            title: props.title,
            photo: props.photo,
            phone: props.phone,
            email: props.email,
            street: props.street,
            city: props.city,
            state: props.state,
            zip: props.zip,
            linkedin: props.linkedin,
            showdirectory: props.showdirectory,
            twitter: props.twitter,
            facebook: props.facebook,
            badges: props.data4,
            cardstyles: global.$attendees.styles.photosubdiv,
            imgdivstyle: global.$attendees.styles.imgdivstyle,
            showcompany: showcompany,
            showtitle: showtitle,
            showq1: showq1,
            showq2: showq2

        };
        //console.log(this.state);
        if (this.state.street == " " || this.state.street == "") {
            this.state.street = "empty";
        }
        else {
            this.state.street = this.state.street + ","
        }

        if (this.state.city == " " || this.state.city == "") {
            this.state.city = "empty";
        }
        else {
            this.state.city = this.state.city
        }

        if (this.state.state == " " || this.state.state == "") {
            this.state.state = "empty";
        }
        else {
            this.state.state = ", " + this.state.state
        }

        if (this.state.zip == " " || this.state.zip == "") {
            this.state.zip = "empty";
        }
        else {
            this.state.zip = " - " + this.state.zip
        }

        if (this.state.linkedin == " " || this.state.linkedin == "") {
            this.state.linkedin = "empty";
        }
        if (this.state.twitter == " " || this.state.twitter == "") {
            this.state.twitter = "empty";
        }
        if (this.state.facebook == " " || this.state.facebook == "") {
            this.state.facebook = "empty";
        }
    }
    render() {
        //console.log('auctions - render');
        //console.log(this.state.showdirectory)
        if (this.state.showdirectory == "true" || this.state.showdirectory == "TRUE") {
            return (
                this.displayAttendees()
            )
        }
        else {
            return (
                ''
            )
        }
    }

    displayAttendees() {
        return (
            <div className="container">
                <div className="card" style={this.state.cardstyles}>
                    <div className="attendee-img" style={this.state.imgdivstyle}>
                        {
                            this.state.photo.includes("-1") ?
                                (
                                    <div>
                                        {/*<img src={commonProfile} width="150" height="150" className="common-profile" />*/}
                                        <Avatar className="attendee-avatar" name={`${this.state.fname + " " + this.state.lname}`} />
                                    </div>
                                )
                                :
                                (
                                    <LoadPrivateImg id={this.state.userid} name={this.state.fname} component="delegates" code={this.state.code} width="100" height="100" />
                                )
                        }
                    </div>
                    {
                        global.$attendees.showsocial.includes("Y") ?
                            (
                                <div className="social-div">
                                    {
                                        global.$attendees.showemail.includes("Y") ?
                                            (
                                                this.state.email != "" && this.state.email != " " && this.state.email != "empty" ?
                                                    (
                                                        <a href={"mailto:" + this.state.email}><Icon path={mdiEmail} size={1.1} color="#828783" className="attendee2-card-icon" /></a>
                                                    )
                                                    :
                                                    ('')
                                            )
                                            :
                                            ('')
                                    }
                                    {
                                        global.$attendees.showphone.includes("Y") ?
                                            (
                                                this.state.phone != "" && this.state.phone != " " && this.state.phone != "empty" ?
                                                    (
                                                        <a href={"tel:" + this.state.phone}><Icon path={mdiPhone} size={1.1} color="#828783" className="attendee2-card-icon" /></a>
                                                    )
                                                    :
                                                    ('')
                                            )
                                            :
                                            ('')
                                    }
                                    {
                                        this.state.facebook.includes("empty") ?
                                            ('')
                                            :
                                            (
                                                <a href={this.state.facebook} target="_blank"><Icon path={mdiFacebook} size={1.1} color="#828783" className="attendee-card-icon" /></a>
                                            )
                                    }
                                    {
                                        this.state.twitter.includes("empty") ?
                                            ('')
                                            :
                                            (
                                                <a href={this.state.twitter} target="_blank"><Icon path={mdiTwitter} size={1.1} color="#828783" className="attendee-card-icon" /></a>
                                            )
                                    }
                                    {
                                        this.state.linkedin.includes("empty") ?
                                            ('')
                                            :
                                            (
                                                <a href={this.state.linkedin} target="_blank"><Icon path={mdiLinkedin} size={1.1} color="#828783" className="attendee-card-icon" /></a>
                                            )
                                    }
                                </div>
                            )
                            :
                            (
                                <div>
                                    {
                                        global.$attendees.showemail.includes("Y") ?
                                            (
                                                <div className="email-div">
                                                    <a href={"mailto:" + this.state.email}>{this.state.email}</a>
                                                </div>
                                            )
                                            :
                                            ('')
                                    }
                                    {
                                        global.$attendees.showphone.includes("Y") ?
                                            (
                                                <div>
                                                    <br />
                                                    <div className="phone-div">
                                                        <a href={"tel:" + this.state.phone}>{this.state.phone}</a>
                                                    </div>
                                                </div>
                                            )
                                            :
                                            ('')
                                    }
                                </div>
                            )
                    }
                    {
                        global.$points != null && global.$points.attendeebadgelevels != null && global.$points.attendeebadgelevels.enable == true ?
                            (
                                <div className="attendee2-badge-level-div">
                                    {this.displayAttendeeBadgeLevel()}
                                </div>
                            )
                            :
                            ('')
                    }
                    <hr />
                    <div className="attendee-card-info">
                        <h4><b>{this.state.fname + " " + this.state.lname}</b></h4>
                        {
                            this.state.showcompany == true ?
                                (
                                    <p className="attendee-company">{this.state.company}</p>
                                )
                                :
                                ('')
                        }
                        {
                            this.state.showtitle == true ?
                                (
                                    <p className="attendee-title">{this.state.title}</p>
                                )
                                :
                                ('')
                        }
                        {
                            this.state.showq1 == true ?
                                (
                                    <p className="attendee-q1">{global.$attendees.q1title}{this.props.attendee.q1}</p>
                                )
                                :
                                ('')
                        }
                        {
                            this.state.showq2 == true ?
                                (
                                    <p className="attendee-q2">{global.$attendees.q2title}{this.props.attendee.q2}</p>
                                )
                                :
                                ('')
                        }

                        {
                            global.$attendees.showaddress.includes("Y") ?
                                (
                                    <div className="address-div">
                                        {
                                            this.state.street.includes("empty") ?
                                                ('')
                                                :
                                                (
                                                    <p className="address-street">{this.state.street}</p>
                                                )
                                        }
                                        {
                                            this.state.city.includes("empty") ?
                                                ('')
                                                :
                                                (
                                                    <div>
                                                        {
                                                            this.state.state.includes("empty") ?
                                                                (
                                                                    <p className="address-city">{this.state.city}</p>
                                                                )
                                                                :
                                                                (
                                                                    <div>
                                                                        {
                                                                            this.state.zip.includes("empty") ?
                                                                                (
                                                                                    <p className="address-city">{this.state.city}{this.state.state}</p>
                                                                                )
                                                                                :
                                                                                (
                                                                                    <p className="address-city">{this.state.city}{this.state.state}{this.state.zip}</p>
                                                                                )
                                                                        }
                                                                    </div>
                                                                )
                                                        }
                                                    </div>
                                                )
                                        }
                                    </div>
                                )
                                :
                                ('')
                        }
                        {
                            global.$attendees.showbadges != null && global.$attendees.showbadges == "Y" ?
                                (
                                    <div className="attendee-badge-div">
                                        {this.displayBadges(this.state.badges)}
                                    </div>
                                )
                                :
                                ('')
                        }


                    </div>
                </div>
            </div>
        )
    }

    displayBadges(badges) {
        //console.log(badges);
        if (badges != "" && badges != " " && badges != null) {
            badges = badges.split(",");
            //console.log(badges);
            return badges.map((badge, key) => {
                //console.log(badge,key);
                var badgestyles = global.$attendees.styles.badges;

                //var style = this.state["badge"+key];
                //console.log(style);
                return (
                    <div className="attendee-badge" style={badgestyles[badge]}>{badge}</div>
                )
            })
        }
        else {
            return null;
        }
    }
    displayAttendeeBadgeLevel() {
        //console.log(global.$points);
        //console.log(global.$points.attendeebadgelevels.levels.gold);
        var levels = global.$points.attendeebadgelevels.levels;

        if (Number(this.props.attendee.totalpoints) >= Number(levels.platinum.minpoints) && Number(this.props.attendee.totalpoints) <= Number(levels.platinum.maxpoints)) {
            return (
                <div className="attendee2-badge-level-platinum" style={levels.platinum.styles}>
                    {levels.platinum.title} <Icon path={mdiStar} size={0.9} className="attendee-badge-level-icon" />{/*this.props.attendee.totalpoints*/}
                </div>
            )
        }
        else {
            if (Number(this.props.attendee.totalpoints) >= Number(levels.gold.minpoints) && Number(this.props.attendee.totalpoints) <= Number(levels.gold.maxpoints)) {
                return (
                    <div className="attendee2-badge-level-gold" style={levels.gold.styles}>
                        {levels.gold.title} <Icon path={mdiStar} size={0.9} className="attendee-badge-level-icon" />{/*this.props.attendee.totalpoints*/}
                    </div>
                )
            }
            else {
                if (Number(this.props.attendee.totalpoints) >= Number(levels.silver.minpoints) && Number(this.props.attendee.totalpoints) <= Number(levels.silver.maxpoints)) {
                    return (
                        <div className="attendee2-badge-level-silver" style={levels.silver.styles}>
                            {levels.silver.title} <Icon path={mdiStar} size={0.9} className="attendee-badge-level-icon" />{/*this.props.attendee.totalpoints*/}
                        </div>
                    )
                }
                else {
                    if (Number(this.props.attendee.totalpoints) >= Number(levels.bronze.minpoints) && Number(this.props.attendee.totalpoints) <= Number(levels.bronze.maxpoints)) {
                        return (
                            <div className="attendee2-badge-level-bronze" style={levels.bronze.styles}>
                                {levels.bronze.title} <Icon path={mdiStar} size={0.9} className="attendee-badge-level-icon" />{/*this.props.attendee.totalpoints*/}
                            </div>
                        )
                    }
                }
            }
        }
    }
}
export default DelegateProfile;
