/* eslint-disable */

import React from 'react';
import { Row, Col } from 'reactstrap';
import ItemInfo from './ItemInfo.jsx';

class AuctionsItem extends React.Component {
    constructor(props) {

        super(props);
        this.state = {
            code: props.code,
            itemid: props.itemid,
            datalaoded: false,
            lastbid: '',
            lastbidder: '',
            bidinc: '',
            startbid: '',
            title: '',
            desc: '',
            img: '',
            open: '',
            close: '',
            status: '',
            bidcount: ''
        };

    }

    render() {
        if (this.state.datalaoded) {
            return (
                <div>
                    <ItemInfo
                        code={this.state.code}
                        itemid={this.state.itemid}
                        title={this.state.title}
                        img={this.state.img}
                        desc={this.state.desc}
                        open={this.state.open}
                        close={this.state.close}
                        startbid={this.state.startbid}
                        bidinc={this.state.bidinc}
                        lastbid={this.state.lastbid}
                        lastbidder={this.state.lastbidder}
                        status={this.state.status}
                        bidcount={this.state.bidcount}
                    />
                </div>
            )
        }
        else {
            this.getAuctionItem()
            return (
                <div>
                    <Row>
                        <Col md={6} className="offset-5">
                            <div className="loading-div">
                                <div className="loading-text">Loading....</div>
                            </div>
                        </Col>
                    </Row>
                </div>
            )
        }
    }

    getAuctionItem() {
        global.$auctionitemsjson.find(auctionitem => {
            if (auctionitem.itemid == this.state.itemid) {
                global.$auctionitem = auctionitem;
                this.setState({
                    title: auctionitem.title,
                    img: auctionitem.photo,
                    desc: auctionitem.desc,
                    startbid: auctionitem.startbid,
                    lastbid: auctionitem.lastbid,
                    bidinc: auctionitem.bidinc,
                    lastbidder: auctionitem.lastbidder,
                    open: auctionitem.open,
                    close: auctionitem.close,
                    status: auctionitem.status,
                    bidcount: auctionitem.bidcount,
                    datalaoded: true
                })
            }
        })
    }
}
export default AuctionsItem;
