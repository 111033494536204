/* eslint-disable */

import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import ItemBids from './ItemBids.jsx';
import AddBid from './AddBid.jsx';
import { Link } from 'react-router-dom';

class ItemInfo extends React.Component {
    constructor(props) {
        //console.log(props)

        super(props);
        this.state = {
            code: props.code,
            itemid: props.itemid,
            title: props.title,
            desc: props.desc,
            photo: props.img,
            open: props.open,
            close: props.close,
            startbid: props.startbid,
            bidinc: props.bidinc,
            lastbid: props.lastbid,
            lastbidder: props.lastbidder,
            status: props.status,
            bidcount: props.bidcount,
            bidusernamestyle: global.$auctions.styles.bid.username,
            biddatestyle: global.$auctions.styles.bid.date,
            bidamountstyle: global.$auctions.styles.bid.amount,
            dollericonstyles: global.$auctions.styles.dollaricon,
            bidbuttonstyles: global.$auctions.styles.bidbutton,
            imgstyle: global.$auctions.styles.item.img,
            itemtitlestyle: global.$auctions.styles.item.title,
            itemdescstyle: global.$auctions.styles.item.desc,
            bidstyle: global.$auctions.styles.item.bid,
            lastbidstyle: global.$auctions.styles.item.lastbid,
            bidsdivstyle: global.$auctions.styles.bidsmaindiv,
            bidlablestyle: global.$auctions.styles.bidamountlabel,
            bidmessagestyle: global.$auctions.styles.bidmessagestatus,
            bidssubdiv: global.$auctions.styles.bidssubdiv,
            selectedauctionitem: [],
            bidamount: '',
            minbidamount: '',
            biddingAmountLoading: false,
            bidspaynow: global.$auctions.styles.bidspaynow,
            bidspaynowamount: global.$auctions.styles.bidspaynowamountlabel,
            itemstylebeforelogin: global.$auctions.styles.item.beforelogin
        };

    }

    render() {

        //console.log('ItemInfo - render');
        return (
            <div>
                {this.displayItemInfo()}
            </div>
        )
    }

    displayItemInfo() {
        //console.log(global.$auctionitem)
        let options = {
            timeZone: global.$settings.timezone,
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
        },
            formatter = new Intl.DateTimeFormat([], options);

        //console.log(formatter.format(new Date()));

        var currentdate = formatter.format(new Date());
        var curenttime = Date.parse(currentdate);
        var bidopentime = Date.parse(this.state.open);
        var bidclosetime = Date.parse(this.state.close);

        //console.log(this.state.imgstyle)
        return (
            <div>
                {
                    global.$loginstatus.includes(true) ?
                        (
                            <div>
                                <Row>
                                    <Col md={6}>
                                            <h4 style={this.state.itemtitlestyle}>{this.state.title}</h4>

                                        <br />
                                        <img src={"https://s3.amazonaws.com/event360live/public/" + this.state.code + "/auctions/" + this.state.photo} style={this.state.imgstyle} />
                                        <br />
                                        <br />
                                        <p style={this.state.itemdescstyle}>{this.state.desc}</p>
                                        {(() => {
                                            if ((curenttime >= bidopentime && curenttime <= bidclosetime) && this.state.status == 'O') {
                                                //console.log("spinner")
                                                return (
                                                    <div>
                                                        {
                                                            global.$loginstatus.includes(false) ?
                                                                (
                                                                    <div className="bid-now-button">
                                                                        <Link to={`/LogInForm`} style={this.state.dollericonstyles}><div dangerouslySetInnerHTML={{ __html: global.$auctions.item.bidnowbutton }}></div></Link>
                                                                    </div>
                                                                )
                                                                :
                                                                ('')
                                                        }
                                                    </div>
                                                )
                                            }
                                        })()}

                                    </Col>
                                    <Col md={6}>
                                        {(() => {
                                            /*
                                            if ((curenttime >= bidopentime && curenttime <= bidclosetime) && this.state.status == 'O') {
                                                //console.log("spinner")
                                                
                                                return (
                                                    <div style={this.state.bidsdivstyle}>
                                                        <div style={this.state.bidssubdiv}>
                                                            <div className="bids-div-1">
                                                                <AddBid
                                                                    code={this.state.code}
                                                                    itemid={this.state.itemid}
                                                                    startbid={this.state.startbid}
                                                                    bidinc={this.state.bidinc}
                                                                    lastbid={this.state.lastbid}
                                                                    bidcount={this.state.bidcount}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="bids-div-3">
                                                            <BidInfo itemid={this.state.itemid} code={this.state.code} />
                                                        </div>
                                                    </div>
                                                )
                                                

                                                return   <ItemBids itemid={this.state.itemid} 
                                                                  code={this.state.code}
                                                                  startbid={this.state.startbid}
                                                                  bidinc={this.state.bidinc}
                                                                 lastbid={this.state.lastbid}
                                                                 bidcount={this.state.bidcount}
                                                                 bidsdivstyle={this.state.bidsdivstyle}
                                                                 bidssubdiv={this.state.bidssubdiv} />
                                            }
                                            else {
                                                return (
                                                    <div style={this.state.bidsdivstyle}>
                                                        {
                                                            global.$loginid.includes(this.state.lastbidder) ?
                                                                (
                                                                    <div style={this.state.bidspaynow}>
                                                                        <p style={this.state.bidspaynowamountlabel}>Amount($):  {this.state.lastbid}</p>
                                                                        <Button style={this.state.bidbuttonstyles}><Link to={"./LoadExternalLinks/" + global.$code + "_paypal_auctions.htmlquestionmarkfname=" + global.$attendeeDetails.fname + "&lname=" + global.$attendeeDetails.lname + "&email=" + global.$attendeeDetails.email + "&userid=" + global.$attendeeDetails.userid + "&amount=" + this.state.lastbid + "&itemname=" + this.state.title} >Pay Now</Link></Button>
                                                                    </div>
                                                                )
                                                                :
                                                                ('')
                                                        }
                                                        <div className="bids-div-3">
                                                            <ItemBids itemid={this.state.itemid} code={this.state.code} />
                                                        </div>
                                                    </div>)

                                            } */


                                            return <ItemBids itemid={this.state.itemid}
                                                code={this.state.code}
                                                startbid={this.state.startbid}
                                                bidinc={this.state.bidinc}
                                                lastbid={this.state.lastbid}
                                                bidcount={this.state.bidcount}
                                                bidsdivstyle={this.state.bidsdivstyle}
                                                bidssubdiv={this.state.bidssubdiv}
                                                open={this.state.open}
                                                close={this.state.close}
                                                status={this.state.status}
                                            />

                                        })()}
                                    </Col>
                                </Row>
                                <hr />
                            </div>
                        )
                        :
                        (
                            <div>
                                <Row>
                                    <Col md={12} className="auction-item" style={this.state.itemstylebeforelogin}>
                                        <h4 style={this.state.itemtitlestyle}>{this.state.title}</h4>
                                        <br />
                                        <img src={"https://s3.amazonaws.com/event360live/public/" + this.state.code + "/auctions/" + this.state.photo} style={this.state.imgstyle} />
                                        <br />
                                        <br />
                                        <p style={this.state.itemdescstyle}>{this.state.desc}</p>
                                        {(() => {
                                            if ((curenttime >= bidopentime && curenttime <= bidclosetime) && this.state.status == 'O') {
                                                //console.log("spinner")
                                                return (
                                                    <div className="bid-now-button offset-md-5">
                                                        <Link to={`/LogInForm`} style={this.state.dollericonstyles}><div dangerouslySetInnerHTML={{ __html: global.$auctions.item.bidnowbutton }}></div></Link>
                                                    </div>
                                                )
                                            }
                                        })()}

                                    </Col>
                                </Row>
                            </div>
                        )
                }
            </div>
        )
    }

}
export default ItemInfo;
