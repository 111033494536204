import React from 'react';
import { Row, Col } from 'reactstrap';

class Html2 extends React.Component {
    constructor(props) {
       //console.log(props.match.params.value)
        
      //console.log(props.match.params.value)
        var iframestyle = ''
        var imgsrc = ''
        var date = new Date();
        var timestamp = date/1000;
        
        if (props.match.params.value == null) {
            iframestyle = global.$header.menu.html2.styles
            imgsrc = global.$header.menu.html2.link + "?v=" +timestamp
        }
        else {

            if (props.match.params.value.includes("questionmark")) {
                props.match.params.value = props.match.params.value.replace(/questionmark/g, "?");

                if (global.$header.menu.html2.styles == null) {
                    iframestyle = { "height": "3000px" };
                }
                else {
                    iframestyle = global.$header.menu.html2.styles;
                }
                imgsrc = "https://wesicore.s3.amazonaws.com/event360/" + global.$code + "/public/" + props.match.params.value + "?v="+ timestamp
            }
        }
        
        
      super(props);
      this.state = {
        iframestyles: iframestyle,
        //link: props.match.params.value,
        src: imgsrc
      };
    //console.log(this.state)
    }
    
  componentDidMount() {
    window.scrollTo(0, 0)
  }
render()
{
    return(
        <div>
            <br/>
            <br/>
            <Row>
                    <Col md={12}>
                    <iframe src={this.state.src}
                            id="myId"
                            className="myClassname"
                            style={this.state.iframestyles}
                            />
                    </Col>
                </Row>
        </div>
    )
}
}
export default Html2;
