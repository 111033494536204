import React from "react";
import Paypal from "./Paypal";

class PaypalHome extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            code: global.$code,
        };
        this.hideRegisterButtonFromPaypalHome = this.hideRegisterButtonFromPaypalHome.bind(this);
    }

    render() {
        return (
            <>
                <Paypal regattendeestatus={this.props.regattendeestatus} reggueststatus={this.props.reggueststatus} type={this.props.type} hideRegisterButton={this.hideRegisterButtonFromPaypalHome} />
            </>
        );
    }

    hideRegisterButtonFromPaypalHome() {
        this.props.hideRegisterButton();
    }

}


/**
 * <div>
                {
                    this.state.code == "virtualsummit" ?
                        (
                            <PaypalVirtualSummit regattendeestatus={this.props.regattendeestatus} reggueststatus={this.props.reggueststatus} type={this.props.type} />
                        )
                        :
                        ('')
                }
                {
                    this.state.code == "wspma2021" ?
                        (
                            <PaypalWspma regattendeestatus={this.props.regattendeestatus} reggueststatus={this.props.reggueststatus} type={this.props.type} />
                        )
                        :
                        ('')
                }
                {
                    this.state.code == "metro2021conference" ?
                        (
                            <PaypalMetroconference regattendeestatus={this.props.regattendeestatus} reggueststatus={this.props.reggueststatus} type={this.props.type} />
                        )
                        :
                        ('')
                }
                {
                    this.state.code == "travcon2021" ?
                        (
                            <PaypalTravcon regattendeestatus={this.props.regattendeestatus} reggueststatus={this.props.reggueststatus} type={this.props.type} hideRegisterButtonFromPaypalHome={this.hideRegisterButtonFromPaypalHome} />
                        )
                        :
                        ('')
                }
                {
                    this.state.code == "travcon2022exhibitor" ?
                        (
                            <PaypalTravcon22Exhibitor regattendeestatus={this.props.regattendeestatus} reggueststatus={this.props.reggueststatus} type={this.props.type} hideRegisterButtonFromPaypalHome={this.hideRegisterButtonFromPaypalHome} />
                        )
                        :
                        ('')
                }
                {
                    this.state.code == "travcon2023exhibitor" ?
                        (
                            <PaypalTravcon23Exhibitor regattendeestatus={this.props.regattendeestatus} reggueststatus={this.props.reggueststatus} type={this.props.type} hideRegisterButtonFromPaypalHome={this.hideRegisterButtonFromPaypalHome} />
                        )
                        :
                        ('')
                }
                {
                    this.state.code == "travcon2022" ?
                        (
                            <PaypalTravcon22 regattendeestatus={this.props.regattendeestatus} reggueststatus={this.props.reggueststatus} type={this.props.type} hideRegisterButtonFromPaypalHome={this.hideRegisterButtonFromPaypalHome} />
                        )
                        :
                        ('')
                }
                {
                    this.state.code == "travcon2023" ?
                        (
                            <PaypalTravcon23 regattendeestatus={this.props.regattendeestatus} reggueststatus={this.props.reggueststatus} type={this.props.type} hideRegisterButtonFromPaypalHome={this.hideRegisterButtonFromPaypalHome} />
                        )
                        :
                        ('')
                }
                {
                    this.state.code == "nwvt2021" ?
                        (
                            <PaypalNwvt regattendeestatus={this.props.regattendeestatus} reggueststatus={this.props.reggueststatus} type={this.props.type} hideRegisterButtonFromPaypalHome={this.hideRegisterButtonFromPaypalHome} />
                        )
                        :
                        ('')
                }
                {
                    this.state.code == "learnpat2021" ?
                        (
                            <PaypalLearnpat regattendeestatus={this.props.regattendeestatus} reggueststatus={this.props.reggueststatus} type={this.props.type} hideRegisterButtonFromPaypalHome={this.hideRegisterButtonFromPaypalHome} />
                        )
                        :
                        ('')
                }
                {
                    this.state.code == "aksus2023" ?
                        (
                            <PaypalAksus2023 regattendeestatus={this.props.regattendeestatus} reggueststatus={this.props.reggueststatus} type={this.props.type} hideRegisterButton={this.hideRegisterButtonFromPaypalHome} />
                        )
                        :
                        ('')
                } 
                {
                    this.state.code == "aksus2023" ?
                        (
                            <Paypal regattendeestatus={this.props.regattendeestatus} reggueststatus={this.props.reggueststatus} type={this.props.type} hideRegisterButton={this.hideRegisterButtonFromPaypalHome} />
                        )
                        :
                        ('')
                }
            </div>
 */
export default PaypalHome;