import React from 'react';
import { Col, Row } from 'reactstrap';

class LoadingUI extends React.Component {
    constructor(props) {
        super(props);

        var loadingdivstyles = {
            color: "white"
        }
        if (global.$registrationform.styles.loading != null) {
            loadingdivstyles = global.$registrationform.styles.loading;
        }

        this.state = {
            loadingdivstyles: loadingdivstyles
        };
    }

    render() {
        return (
            <div>
                <Row>
                    <Col md={6} className="offset-5">
                        <div className="loading-div" style={this.state.loadingdivstyles}>
                            <div className="reg-loading-text" >Loading....</div>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}
export default LoadingUI;


