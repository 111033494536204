import React from 'react';
import SpeakerLayoutInfo from './AdsLayoutInfo';
import Carousel from 'react-elastic-carousel'; //https://sag1v.github.io/react-elastic-carousel
import AdsLayoutInfo from './AdsLayoutInfo';

class AdsSlider extends React.Component {
    constructor(props) {

        super(props);
        this.state = {
            slider: global.$config["adsscroll"].images,
        };
    }

    render() {
        const slides = this.state.slider.map((item) => {
            //console.log(item)
            return (
                <AdsLayoutInfo image={item} />
            );
        });
        const breakPoints = [
            { width: 0, itemsToShow: 1 },
            { width: 550, itemsToShow: 2 },
            { width: 850, itemsToShow: 3 },
            { width: 1024, itemsToShow: 3 }
        ]
        return (
            <div>
                <Carousel
                    breakPoints={breakPoints}
                    onResize={currentBreakPoint => console.log()}
                    enableAutoPlay
                    autoPlaySpeed={5000}
                    pagination={false}
                >
                    {slides}
                </Carousel>
            </div>
        )
    }

}
export default AdsSlider;
