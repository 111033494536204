import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import Avatar from 'react-avatar';
import SponsorPopup from './SponsorPopup';
import Modal from "react-responsive-modal";
import 'react-responsive-modal/styles.css';
import VirtualExhibitorInfo from '../../../VirtualExpo/VirtualExhibitorInfo';
import sponsorPopup from './SponsorPopup';

class SponsorLayoutInfo extends React.Component {
    constructor(props) {

        super(props);
        this.state = {
            //code: props.code,
            alloptionsopen: false,
        };
    }

    onOpenAllOptions = () => {
        //console.log('onOpenAllOptions');
        this.setState(prevState => ({
            alloptionsopen: !prevState.alloptionsopen
        }));
        //console.log(this.state.alloptionsopen);
    };
    render() {
        return (
            <div className="Sponsors-popup-maindiv">
                <Row>
                    {this.displaySponsors()}
                </Row>
                <Modal
                    open={this.state.alloptionsopen}
                    onClose={this.onOpenAllOptions}
                    blockScroll={false}
                    styles={{
                        modal: {
                            width: "100%"
                        }
                    }}
                    center>
                    <SponsorPopup sponsor={global.$sponsor} />
                </Modal>
            </div>
        )
    }

    displaySponsors() {
        var sponsor = this.props.sponsor;
        //console.log(Sponsor);

        if (sponsor.PHOTO == "" || sponsor.PHOTO == " ") {
        }
        var image = "";
        if (sponsor.PHOTO == "profile.png" || sponsor.PHOTO == " " || sponsor.PHOTO == "") {
            image = "empty";
        }
        else {
            image = `https://s3.amazonaws.com/event360live/public/${global.$code}/exhibitors/` + sponsor.LOGO
        }
        var styles = global.$config['sponsorsscroll'].styles;
        //console.log(image);
        return (
            <Col md={12} key={sponsor.NAME}>
                <div className="sponsor-layout-div" style={styles.maindiv}>

                    <div className="sponsor-layout-img-div" style={styles.imgdiv}>
                        {
                            image.includes("empty") ?
                                (
                                    <div>
                                        <Avatar className="sponsor-layout-avatar" name={`${sponsor.NAME}`} style={styles.avatarimg} />
                                    </div>
                                )
                                :
                                (
                                    <div>
                                        <img src={image} className="sponsor-layout-img" style={styles.img} />
                                    </div>
                                )
                        }
                    </div>
                    {
                        global.$config['sponsorsscroll'].button.enable != null && global.$config['sponsorsscroll'].button.enable == true ?
                            (
                                <div className="sponsor-layout-div2" style={styles.infodiv}>
                                    <Button className="sponsor-learnmore-button" style={styles.button} onClick={() => this.popup(sponsor)}>{global.$config['sponsorsscroll'].button.text}</Button>
                                </div>
                            )
                            :
                            ('')
                    }
                </div>
            </Col>
        )
    }

    popup(sponsor) {
        //console.log(sponsor);
        global.$sponsor = sponsor;
        this.onOpenAllOptions()
    }

}
export default SponsorLayoutInfo;
