/* eslint-disable */
import React from 'react';
import { API } from 'aws-amplify';
import { Row, Col, Button } from 'reactstrap';
import './Appointments.css';

const moment = require('moment-timezone');
var timer;
class Appointments extends React.Component {
    constructor(props) {
        var exhibitor = [
            {
                "id": "1234",
                "begintime": "11/12/2020 10:00 AM",
                "endtime": "11:00 AM",
                "ownerid": "Exhibitor ID 1",
                "ownername": "Exhibitor Name 1",
                "userid": " ",
                "username": " ",
                "status": "Available",
                "meeting": " ",
                "notes": " ",
                "data1": " ",
                "data2": " ",
                "data3": " ",
            },
            {
                "id": "12345",
                "begintime": "11/12/2020 11:00 AM",
                "endtime": "12:00 PM",
                "ownerid": "Exhibitor ID 2",
                "ownername": "Exhibitor Name 2",
                "userid": " ",
                "username": " ",
                "status": "Available",
                "meeting": " ",
                "notes": " ",
                "data1": " ",
                "data2": " ",
                "data3": " ",
            },
            {
                "id": "123456",
                "begintime": "11/12/2020 02:00 PM",
                "endtime": "03:00 PM",
                "ownerid": "Exhibitor ID 3",
                "ownername": "Exhibitor Name 3",
                "userid": " ",
                "username": " ",
                "status": "Available",
                "meeting": " ",
                "notes": " ",
                "data1": " ",
                "data2": " ",
                "data3": " ",
            },
            {
                "id": "1234567",
                "begintime": "11/12/2020 04:00 PM",
                "endtime": "05:00 PM",
                "ownerid": "Exhibitor ID 4",
                "ownername": "Exhibitor Name 4",
                "userid": " ",
                "username": " ",
                "status": "Available",
                "meeting": " ",
                "notes": " ",
                "data1": " ",
                "data2": " ",
                "data3": " ",
            },
            {
                "id": "12345678",
                "begintime": "11/12/2020 05:00 PM",
                "endtime": "05:30 PM",
                "ownerid": "Exhibitor ID 5",
                "ownername": "Exhibitor Name 5",
                "userid": " ",
                "username": " ",
                "status": "Available",
                "meeting": " ",
                "notes": " ",
                "data1": " ",
                "data2": " ",
                "data3": " ",
            }
        ]

        super(props);
        this.state = {
            code: global.$code,
            s3: global.$s3,
            bucket: global.$s3bucket,
            exhibitordata: exhibitor
        };

    }
    render() {
        return (
            <div className="appointment-div">
                <div>
                    <div className="appointments-header">
                        Available Appointments
                        <hr className="appointment-hr"/>
                    </div>
                    {this.showAppointments()}
                </div>
            </div>
        )
    }
    getAppointmentTableObjects(objs) {
        const tableObjs = []

        for (var i = 0; i < objs.length; i++) {
            var Obj = objs[i]
            var newObj = Object()

            newObj.begintime = Obj.begintime
            newObj.endtime = Obj.endtime
            newObj.status = Obj.status

            tableObjs.push(newObj);
        }
        return tableObjs;
    }
    
    showAppointments() {
        var exhibitordata = this.state.exhibitordata
       //console.log(exhibitordata);
        return exhibitordata.map(exhibitor => {
            return (
                <div key={exhibitor.id}>
                    <Row>
                        <Col md={5} className="appointment-time">
                            {exhibitor.begintime} - {exhibitor.endtime}
                        </Col>
                        <Col md={2} className="offset-md-1">
                            <Button className="btn" id="appointment_button" onClick={''} >Schedule</Button>
                        </Col>
                    </Row>
                </div>
            )
        })
    }
}
export default Appointments;


