import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import LoadPrivateImg from '../LoadPrivateImg';
import Avatar from 'react-avatar';
import SpeakerPopup from './SpeakerPopup';
import Modal from "react-responsive-modal";
import 'react-responsive-modal/styles.css';

class SpeakerLayoutInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            alloptionsopen: false,
        };
    }

    onOpenAllOptions = () => {
        //console.log('onOpenAllOptions');
        this.setState(prevState => ({
            alloptionsopen: !prevState.alloptionsopen
        }));
        //console.log(this.state.alloptionsopen);
    };
    render() {
        return (
            <div className="speakers-popup-maindiv">
                <Row>
                    {this.displaySpeakers()}
                </Row>
                <Modal
                    open={this.state.alloptionsopen}
                    onClose={this.onOpenAllOptions}
                    blockScroll={false}
                    styles={{
                        modal: {
                            width: "100%"
                        }
                    }}
                    center>
                    <SpeakerPopup speaker={global.$speaker} />
                </Modal>
            </div>
        )
    }

    displaySpeakers() {
        var speaker = this.props.speaker;
        //console.log(speaker);

        if (speaker.PHOTO == "" || speaker.PHOTO == " ") {
        }
        var image = "";
        if (speaker.PHOTO == "profile.png" || speaker.PHOTO == " " || speaker.PHOTO == "") {
            image = "empty";
        }
        else {
            image = `https://s3.amazonaws.com/event360live/public/${global.$code}/speakers/` + speaker.PHOTO
        }
        var styles = global.$config['speakersscroll'].styles;
        //console.log(image);
        return (
            <Col md={12} key={speaker.NAME}>
                <div className="speaker-layout-div" style={styles.maindiv}>

                    <div className="speaker-layout-img-div" style={styles.imgdiv}>
                        {
                            image.includes("empty") ?
                                (
                                    <div>
                                        <Avatar className="speaker-layout-avatar" name={`${speaker.NAME}`} style={styles.avatarimg} />
                                    </div>
                                )
                                :
                                (
                                    <div>
                                        <img src={image} className="speaker-layout-img" style={styles.img}/>
                                    </div>
                                )
                        }
                    </div>
                    <div className="speaker-layout-div2" style={styles.infodiv}>
                        <h4 className="speaker-layout-name" style={styles.name}><b>{speaker.NAME}</b></h4>
                        <p className="speaker-layout-title" style={styles.title}>{speaker.TITLE}</p>
                        <p className="speaker-layout-company" style={styles.company}>{speaker.COMPANY}</p>
                    <Button className="speaker-learnmore-button" style={styles.button} onClick={() => this.popup(speaker)}>{global.$config['speakersscroll'].button.text}</Button>
                    </div>
                </div>
            </Col>
        )
    }

    popup(speaker) {
        //console.log(sponsor);
        global.$speaker = speaker;
        this.onOpenAllOptions()
    }

}
export default SpeakerLayoutInfo;
