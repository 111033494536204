import React from 'react';
import CommonFunctions from '../RegCommon/CommonFunctions';
import Select from 'react-select';
import { Input } from 'reactstrap';

/*
    
    data5: item id
    data1: item order
    data3: dropdownprice ?? 
    data4: 
            multiselect - for multiple select
            blank - for single select

    data2: Has sub-items

            "Select" is the first item in the droopdown
            Select~~sub item title==sub item id~~sub item title==sub item id
            Select~~June 2022==6080~~July 2022==6090~~August 2022==6100 (Select~~sub item name==data5field as id ~~)
    
*/
let selectedValue = '';

class RegItemdropdown extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            regitem: props.regitem,
            groupname: props.groupname,
            disableregitem: props.disableregitem,
            signupsremaining: props.signupsremaining,
            selectedregdropdownitem: '',
            selecteditems:[],
            selecteditem:''
        }
    }

    render() {
        if (CommonFunctions.isUserLoggedin()) {
            return (
                <>
                    {this.renderRegDropdownOptionsInUpdate(this.state.regitem, this.state.groupname)}
                </>
            )
        }
        else {
            return (
                <>
                    {this.renderRegDropdownOptions(this.state.regitem, this.state.groupname)}
                </>
            );
        }
    }

    renderRegDropdownOptionsInUpdate(item, groupname) {
        var dropdownOptions = [];
        if (global.$loggedinuserregitems != null) {
            var regitems = global.$loggedinuserregitems;
           // console.log(regitems);
            if (item.subitems != "" && item.subitems != " ") {
                dropdownOptions = eval(item.subitems);           
                // this is for Registration update and if you are already registered for this item
                if (regitems.includes(item.name)) {
                    regitems = JSON.parse(regitems);
                   // console.log(regitems);
                    // Filter and get all the selected dropdown values for this reg dropdown
                    var selectedmultiplevalues = [];
                    regitems.map(regitem => {
                        if (regitem.name === item.name) {                      
                            selectedmultiplevalues.push(regitem.name);
                        }
                    })               
                    var selecteditemnames = [];

                    // This is to handle multiselection dropdown
                    if (item.data4 == "multiselect") {
                        let dropdownItems = dropdownOptions.map(dropdownoption => {
                            var title = dropdownoption.name 
                            var id = dropdownoption.id

                            // Using this variable to check that reopdown items are available or soldout
                            var disabled = false;
                            global.$regdropdownoptions.map(dropdownitem => {
                                //console.log(dropdownitem);
                                if (dropdownitem.name == title && dropdownitem.data5 == id) {
                                    var maxsignups = dropdownitem.maxsignups;
                                    var currentsignups = dropdownitem.currentsignups;

                                    if ((Number(maxsignups) - Number(currentsignups)) > Number(0)) {
                                        disabled = false;
                                    }
                                    else {
                                        disabled = true;
                                        title = title + " (SOLD OUT)"
                                    }
                                }
                            })
                            // filtering already registered dropdown items 
                            var options = [];
                            if (selectedmultiplevalues.includes(title)) {
                                title = title + " (Purchased)"
                                options = {value: title, label: title,id: id,name:title,itemid:id,price:dropdownoption.price, disabled: true, selected: true };
                                selecteditemnames.push(options)
                            }
                            else {
                                if (title.includes("SOLD OUT")) {
                                    options = { value: title, label: title,id: id, disabled: true };
                                }
                                else {
                                    
                                    options = { value: title, label: title,id: id,name:title,itemid:id,price:dropdownoption.price }
                                }
                            }
                            return options;
                        })

                        return (
                            <Select
                                isMulti
                                className="basic-multi-select"
                                value={this.state.selectedregdropdownitem[item.name]}
                                options={dropdownItems}
                                onChange={this.handleMultipleRegDropdown(item, groupname)}
                                isOptionDisabled={(option) => option.disabled}
                            />

                        )
                    }
                    //this is to handle regular dropdown
                    else {
                        let options = []
                        options = regitems.flatMap(regitem => {
                            if (regitem.name.includes(item.name)) {
                                return dropdownOptions.map(dropdownoption => {
                                    const title = dropdownoption.name;
                                    const id = dropdownoption.id;                                   
                                    const subitem = global.$regsubitems.find(item => item.data5 === id);
                                    let amount = subitem ? subitem.amount : item.amount;   
                                    if (amount.length >= 5) {
                                        amount = parseFloat(amount).toLocaleString()
                                    }                                 
                                    const label = title.toLowerCase() === "select" ? "select" : `${title} - $${amount}`;                                   
                                    const select = regitem.status === "active" && regitem.subitems.length > 0 && regitem.subitems[0].itemid === id;
                                    
                                    return {
                                        id,
                                        value: title,
                                        label: label,
                                        name: title,
                                        itemid: id,
                                        price: amount,
                                        select: select
                                    };
                                });
                            } else {
                                return []; // Return an empty array if the condition is not met
                            }
                        });   

                        // REMOVE DUPLICATE OBJECTS
                        const uniqueOptions = options.filter((option, index, self) =>
                            index === self.findIndex(obj => obj.id === option.id)
                        );
                        // STORE SELECTED ITEM NAME
                        const selectedOption = uniqueOptions.find(option => option.select);
                        const selectedValue = selectedOption ? selectedOption.value : '';
                        
                        // IDENTIFY THE SELECTED ITEM IS IN THE PURCHASE ITEM LIST
                        item.updated = true           
                        return (               
                            <>                      
                                <select
                                    className="basic-multi-select-single"
                                    value={this.state.selectedregdropdownitem || selectedValue}
                                    onChange={(event) => this.handleRegDropdown(item, groupname)(event)}
                                >
                                    {uniqueOptions.map((option, index) => (
                                        <option key={index} value={option.value} disabled={option.select}>
                                        {option.label}
                                        </option>
                                    ))}
                                </select>
                                <p className='remaining-count'>{this.state.signupsremaining}</p>
                            </>
            
                            )
                    }
                }
                //this is for update and not registered for this item
                else {
                    return (
                        this.renderRegDropdownOptions(item, groupname)
                    )
                }
            
                
            }
            
        }
    }

    renderRegDropdownOptions(regitem, groupname) {
        
        let dropdownItems;
        if (regitem.subitems && regitem.subitems != undefined && regitem.subitems != " ") {
            var array1 = eval(regitem.subitems)

            dropdownItems = array1.map((itemname) => {
                var title = itemname.name 
                var id = itemname.id
                var disabled = false;
    
                // // Using this variable to check that reopdown items are available or soldout
                global.$regsubitems.map(dropdownitem => {
                    if (dropdownitem.name == title && dropdownitem.data5 == id) {                  
                        var maxsignups = dropdownitem.maxsignups;
                        var currentsignups = dropdownitem.currentsignups;
    
                        if ((Number(maxsignups) - Number(currentsignups)) > Number(0)) {
                            disabled = false;
                        }
                        else {
                            disabled = true;
                            title = title + " (SOLD OUT)"
                        }
                    }
                })
                if (regitem.data4 == "multiselect") {
                    var options = []
                    if (title.includes("SOLD OUT")) {
                        options = { value: title, label: title,id: id,name:title,itemid:id,price:itemname.price, disabled: true }
                    }
                    else {
                        options = { value: title, label: title,id: id,name:title,itemid:id,price:itemname.price }
                    }                     
                    
                    return options;
                }
                else {
                    let price = itemname.price;
                    if (price.length >= 5) {
                        price = parseFloat(price).toLocaleString()
                    }
                    const label = title.toLowerCase() === "select" ? "select" : `${title} - $${price}`;
                    return options = { 
                        id,
                        value: title,
                        label: label,
                        name:title,
                        itemid:id,
                        price:itemname.price 
                    }
                }
            })
        }

       
        const styles = {
            menu: base => ({
                ...base,
                marginTop: 0
            })
        };
        if (regitem.data4 == "multiselect") {
            return (
                <Select
                    isMulti
                    className="basic-multi-select"
                    value={this.state.selectedregdropdownitem[regitem.name]}
                    options={dropdownItems}
                    onChange={this.handleMultipleRegDropdown(regitem, groupname)}
                    isOptionDisabled={(option) => option.disabled}
                    styles={styles} />
            )
        }
        else {
           
            return (
                <>
                    <select
                        className="basic-multi-select-single"
                        value={this.state.selectedregdropdownitem[regitem.name]}
                        onChange={(event) => this.handleRegDropdown(regitem, groupname)(event)}
                    >
                        {dropdownItems.map((option, index) => (
                            <option key={index} value={option.value}>
                            {option.label}
                            </option>
                        ))}
                    </select>
                    <p className='remaining-count available-count-text'>{this.state.signupsremaining}</p>
                </>

            )
        }

    }
    renderSignupsRemaining(signupsremaining) {
        return (
            <div className="reg-signups-remaining">{signupsremaining}</div>
        )
    }

    handleRegDropdown = (selected, groupname) => (event) => {
        
        global.$regitems.map((regitem) => {
            //filtering the reg item(by group, inputtype and name) from array and updating its status
            if (regitem.groupname == groupname) {
                if (regitem.data5 == selected.data5) {

                    var {value,id,price,selectedsubitem} = this.getSelectedItemValueandId(regitem,event.target.value);

                    // selected values from dropdown should not be empty or Select(default value as first item) 
                    if (event.target.value != "" && event.target.value != " ") {

                        // using this variable to tell from which reg item to take the price
                        var result = false;
                        

                        global.$regsubitems.map(subitem => {

                            // filtering the sub item and updating its atatus and getting amount from sub item
                            if (subitem.data5 == id && subitem.data3 == "dropdownprice") {
                                
                                regitem.isChecked = true;
                                regitem.itemname = regitem.name + " - " + value;
                                regitem.itemid = id;
                                regitem.dropdownvalue = value;
                                regitem.itemprice = subitem.amount;
            
                                this.setState({ selectedregdropdownitem: value});

                                result = true;
                            }
                        })

                        // using this to take the amount from main reg item
                        if (result == false) {
                           
                            regitem.isChecked = true;
                            regitem.itemname = regitem.name + " - " + value;
                            regitem.itemid = id;
                            regitem.dropdownvalue = value;
                            regitem.itemprice = regitem.amount;
                            regitem.price = price;
                            regitem.selecteddropdown = selectedsubitem
                            regitem.selectedamount = `$${selectedsubitem[0].price}`
                            this.setState({ selectedregdropdownitem: value});
                        }

                        let results = this.props.conditionalRegitems(regitem)
                       
                        // CHECK REQUIRED REGITEM SELECTED OR NOT
                        if (!results) {
                            const selectedregdropdownitem = {
                                ...this.state.selectedregdropdownitem,
                                [selected.name]: ""
                            };
                            regitem.isChecked = false;
                            regitem.itemname = "";
                            regitem.itemid = "";
                            regitem.price = 0
                            regitem.dropdownvalue = "";
                            regitem.itemprice = "0";

                            this.setState({ selectedregdropdownitem }, () => {
                                regitem.selectedamount = ""
                                this.props.refreshComponent(); 
                            });
                        
                            if (selected.updated) {
                                this.setState({ selectedregdropdownitem: selectedValue});
                            }
                        }
                    }
                    else {
                        regitem.isChecked = false;
                        regitem.itemname = "";
                        regitem.itemid = "";
                        regitem.dropdownvalue = "";
                        regitem.itemprice = "0";
                        regitem.selectedamount = null
                        this.setState({ selectedregdropdownitem: value });
                    }
                    
                }
            }
        })
        
        this.props.refreshComponent();
    }
    handleMultipleRegDropdown = (selected, groupname) => (event) => {

        global.$regitems.map((regitem) => {
            //filtering the reg item(by group, inputtype and name) from array and updating its status
            if (regitem.groupname == groupname) {
                if (regitem.data5 == selected.data5) {
                    var selecteddropdownoptions = [];
                    var selecteddropdownitemprice = 0;
                    var selecteddropdownoptionids = [];
                    

                    event.map(eventvalue => {   // selection loop                      
                        var value = eventvalue.value
                        var id = eventvalue.id

                        // selected values from dropdown should not be empty or Select(default value as first item) 
                        if (value != "Select") {

                            this.handleSelectMultipleItems(eventvalue);
                            // using this variable to tell from which reg item to take the price
                           
                            var result = false;

                            global.$regsubitems.map(subitem => {

                                // filtering the sub item and updating its atatus and getting amount from sub item
                                if (subitem.data5 == id) {
                                    selecteddropdownoptions.push(value);
                                    selecteddropdownoptionids.push(id);
                                    selecteddropdownitemprice = Number(selecteddropdownitemprice) + Number(subitem.amount);
                                    result = true;
                                }
                            })
                            // using this to take the amount from main reg item
                            if (result == false) {
                                selecteddropdownoptions.push(value);
                                selecteddropdownoptionids.push(id);
                                selecteddropdownitemprice = Number(selecteddropdownitemprice) + Number(regitem.amount);
                            }
                        }
                    })                   

                    // Updating the main reg item with all the seleted dropdown items 
                    if (selecteddropdownoptions.length != 0) {
                                             
                        
                        const ids = this.state.selecteditems.map(o => o.itemid)
                        this.state.selecteditems = this.state.selecteditems.filter(({itemid}, index) => !ids.includes(itemid, index + 1))
                        
                        regitem.isChecked = true;
                        selecteddropdownoptions = selecteddropdownoptions.toString();
                        regitem.itemname = regitem.name + " - " + selecteddropdownoptions;
                        regitem.itemid = selecteddropdownoptionids.toString();
                        regitem.dropdownvalue = selecteddropdownoptions;
                        regitem.itemprice = selecteddropdownitemprice.toString();
                        regitem.selecteddropdown = this.state.selecteditems.filter((item)=>selecteddropdownoptions.includes(item.name))
                        
                    }
                    else {
                        regitem.isChecked = false;
                        // item.data3 = "";
                        regitem.itemname = "";
                        regitem.itemid = "";
                        regitem.dropdownvalue = "";
                        regitem.itemprice = "0";

                    }
                }
                
            }
        })
        this.props.refreshComponent();
    };

    handleSelectMultipleItems(regitems){
        
        this.state.selecteditems.push({
            itemid: regitems.id,
            name:regitems.name,
            price:regitems.price,
            qty:"1",
            data1:"",
            data2:"",
            data3:"",
            data4:"",
            data5:""
        })
        
    }

    getSelectedItemValueandId(regitem,targetvalue){
        var dropdownItems = eval(regitem.subitems)
        var value = "";
        var id = "";
        var price = 0;
        var selectedsubitem = []
        
        dropdownItems.map((item)=>{
            if (item.name == targetvalue) {
                value = item.name
                id = item.id
                price = item.price
                selectedsubitem.push({
                    itemid: item.id,
                    name:item.name,
                    price:item.price,
                    qty:"1",
                    data1:"",
                    data2:"",
                    data3:"",
                    data4:"",
                    data5:""
                });
            }
        })
        return {value,id,price,selectedsubitem}
    }
}

export default RegItemdropdown;