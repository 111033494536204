import React from 'react';
import ReactHtmlParser from 'react-html-parser';

var reginfo = '';
class RegInfo extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            code: global.$code,
            s3: global.$s3,
            bucket: global.$s3bucket,
        };

        this.props.data.map(item => {
            if (item.type == "registration-info") {
                reginfo = item.desc;
            }
        })

    }

    render() {
        var regconfigs = global.$registrationform.setup[this.props.tabid];
        return (
            <div className="reg-info-div">
                
                {this.renderTabRegInfo(regconfigs)}
            </div>
        );
    }

    renderCommonRegInfo() {
        if (global.$registrationform.reginfo != null && global.$registrationform.reginfo.required == true) {
            return (
                <div className="reg-info" style={global.$registrationform.styles.reginfo.maindiv}>
                    {ReactHtmlParser(reginfo)}
                </div>
            )
        }
    }

    // SET SELECTED TAB DESCRIPTION
    renderTabRegInfo(regconfigs) {
        if (regconfigs.reginfo != null && regconfigs.reginfo.enable == true) {
            return (
                <div className="reg-info" style={global.$registrationform.styles.reginfo.maindiv}>
                    <div dangerouslySetInnerHTML={{ __html: regconfigs.reginfo.desc }}></div>
                </div>
            )
        }
        else {
            return (
                this.renderCommonRegInfo()
            )
        }
    }

    /*

    {this.renderAttendeeRegInfo(regconfig)}
    {this.renderExhibitorRegInfo(regconfig)}
    {this.renderSponsorRegInfo(regconfig)}

    var regconfig = global.$registrationform;

    renderAttendeeRegInfo(regconfig) {
        if (this.props.tabid == "attendee") {
            if (regconfig.attendee.reginfo != null && regconfig.attendee.reginfo.enable == true) {
                return (
                    <div className="reg-info" style={global.$registrationform.styles.reginfo.maindiv}>
                        <div dangerouslySetInnerHTML={{ __html: regconfig.attendee.reginfo.desc }}></div>
                    </div>
                )
            }
            else {
                return (
                    this.renderCommonRegInfo()
                )
            }
        }
    }
    renderExhibitorRegInfo(regconfig) {
        if (this.props.tabid == "exhibitor") {
            if (regconfig.exhibitor.reginfo != null && regconfig.exhibitor.reginfo.enable == true) {
                return (
                    <div className="reg-info" style={global.$registrationform.styles.reginfo.maindiv}>
                        <div dangerouslySetInnerHTML={{ __html: regconfig.exhibitor.reginfo.desc }}></div>
                    </div>
                )
            }
            else {
                return (
                    this.renderCommonRegInfo()
                )
            }
        }
    }
    renderSponsorRegInfo(regconfig) {
        if (this.props.tabid == "sponsor") {
            if (regconfig.sponsor.reginfo != null && regconfig.sponsor.reginfo.enable == true) {
                return (
                    <div className="reg-info" style={global.$registrationform.styles.reginfo.maindiv}>
                        <div dangerouslySetInnerHTML={{ __html: regconfig.sponsor.reginfo.desc }}></div>
                    </div>
                )
            }
            else {
                return (
                    this.renderCommonRegInfo()
                )
            }
        }
    }

     */
}
export default RegInfo;


