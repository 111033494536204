import React from 'react';
import LoadPrivateImg from '../Components/Shared/LoadPrivateImg';
import CommentsView from './CommentsView';
import PostImage from './PostImage';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { Row, Col } from 'reactstrap';

const moment = require('moment-timezone');
class PostItemView extends React.Component {
  constructor(props) {

    super(props);

    //console.log(global.$chat);
    this.state = {
      sentmessagestyle: global.$chat.styles.sentmessagestyle,
      receivedmessagestyle: global.$chat.styles.receivedmessagestyle
    };
    //console.log(this.props);
    this.loadMessages = this.loadMessages.bind(this);
  }

  loadMessages() {
   //console.log('Posts:loadMessages()');

    this.props.loadMessages();
  }
  render() {

    // Was the message sent by the current user. If so, add a css class
    //const fromMe = this.props.fromMe ? 'from-me' : '';
    //const fromMe = '';
    var fromMe = 'empty';

    //console.log(global.$loginid);
    //console.log("senderid " + this.props.senderid);

    if (global.$loginid != null) {
      //console.log(global.$attendeeinfo)
      if (global.$loginid == this.props.senderid) {
        fromMe = 'from-me'
      }
    }

    //console.log(this.props)
    var d = new Date(this.props.messageid * 1000);

    var dateFormatter = moment(d).format('MMM D, h:mm A')
    //console.log(dateFormatter);

    return (

      <div className="post-main-div">
        {
          <div className={`message`}>
            <div className="main-div">
              <Row>
                <Col md={1}>
                  <div className="sub-div1">
                    <LoadPrivateImg id={this.props.senderid} name={this.props.sendername} component="posts" code={global.$code} height="40" width="40" />
                  </div>
                </Col>
                <Col md={11}>
                  <div className="sub-div2">
                    <div className='username'>
                      {this.props.sendername}
                    </div>
                    <div className={`message-date message-date`}>
                      {dateFormatter}
                    </div>
                    <div className='message-body' style={this.state.receivedmessagestyle}>
                      {ReactHtmlParser(this.props.message)}
                    </div>
                    {
                      /*
                      if (this.prop.image != '-1')
                      {

                      }
                      */
                      <PostImage id={this.props.image} code={global.$code} height="auto" width="100%" />

                    }

                  </div>
                </Col>
              </Row>
            </div>
            {
              <CommentsView
                code={global.$code}
                messageid={this.props.messageid}
                lcount={this.props.lcount}
                ccount={this.props.ccount}
                senderid={this.props.senderid}
                sendername={this.props.sendername}
                message={this.props.message}
                receiverid={this.props.receiverid}
                likes={this.props.likes}
                loadMessages={this.loadMessages}
              />
            }
          </div>
        }
      </div>
    );
  }
}


export default PostItemView;