import API from '@aws-amplify/api';
import { getRegistrationService, getUserService } from '../../ServiceNameMapper';
import CommonFunctions from './CommonFunctions';

async function getRegistrationDetails() {
    var resultObj = new Object();
    try {
        let apiName = getRegistrationService();
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'code': global.$code,
                'command': 'getRegistrationOptions',
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }

        //console.log(myInit);

        var result = await API.post(apiName, path, myInit);

        if (result.err) {
            resultObj.err = result.err;
            resultObj.data = "";
        }
        else {
            var data = getCategoryTableObjects(result.data.Items);
            //console.log(data);
            var sortdata = CommonFunctions.sortRegItems(data)
            global.$regitems = sortdata;
            resultObj.err = "";
            resultObj.data = sortdata;
        }
        //console.log(resultObj);
        return resultObj;
    }
    catch (error) {

        resultObj.err = error;
        resultObj.data = "";

        //console.log(resultObj);
        return resultObj;
    }
}
function getCategoryTableObjects(objs) {
    //console.log(objs.length);

    const tableObjs = [];
    const subItemTableObjs = [];
    for (var i = 0; i < objs.length; i++) {
        var categoryObj = objs[i]
        //console.log(categoryObj)

        var newObj = Object()
        if (categoryObj.type.S.includes("registration-item")) {
            //console.log(categoryObj)

            newObj.type = categoryObj.type.S
            newObj.name = categoryObj.name.S
            newObj.amount = categoryObj.amount.N
            newObj.begindate = categoryObj.begindate.S
            newObj.enddate = categoryObj.enddate.S
            newObj.code = categoryObj.code.S
            if (categoryObj.currency != null) {
                newObj.currency = categoryObj.currency.S
            }
            else {
                newObj.currency = "dollar";
            }
            newObj.desc = categoryObj.desc.S
            newObj.groupname = categoryObj.groupname.S
            if (categoryObj.inputtype != null) {
                newObj.inputtype = categoryObj.inputtype.S
            }
            else {
                newObj.inputtype = "radio";
            }
            if (categoryObj.subitems) {
                newObj.subitems = categoryObj.subitems.S
              }
            else {
                newObj.subitems = " "
              }
            newObj.isChecked = false;
            newObj.itemname = "";
            newObj.itemid = "";
            newObj.dropdownvalue = "";
            newObj.quantity = "";
            newObj.data1 = categoryObj.data1.S
            newObj.data2 = categoryObj.data2.S
            newObj.data3 = categoryObj.data3.S
            newObj.data4 = categoryObj.data4.S
            newObj.data5 = categoryObj.data5.S
            newObj.maxsignups = categoryObj.maxsignups.N
            newObj.currentsignups = categoryObj.currentsignups.N

            tableObjs.push(newObj)

        }
        if (categoryObj.type.S.includes("registration-sub-item")) {
            //console.log(categoryObj)

            newObj.type = categoryObj.type.S
            newObj.name = categoryObj.name.S
            newObj.amount = categoryObj.amount.N
            newObj.begindate = categoryObj.begindate.S
            newObj.enddate = categoryObj.enddate.S
            newObj.code = categoryObj.code.S
            if (categoryObj.currency != null) {
                newObj.currency = categoryObj.currency.S
            }
            else {
                newObj.currency = "dollar";
            }
            newObj.desc = categoryObj.desc.S
            newObj.groupname = categoryObj.groupname.S
            if (categoryObj.inputtype != null) {
                newObj.inputtype = categoryObj.inputtype.S
            }
            else {
                newObj.inputtype = "radio";
            }
            newObj.isChecked = false; //new
            newObj.itemname = "";
            newObj.itemid = "";
            newObj.itemprice = "0"; //new
            newObj.dropdownvalue = ""; //new
            newObj.quantity = "";
            newObj.data1 = categoryObj.data1.S
            newObj.data2 = categoryObj.data2.S
            newObj.data3 = categoryObj.data3.S
            newObj.data4 = categoryObj.data4.S
            newObj.data5 = categoryObj.data5.S
            newObj.maxsignups = categoryObj.maxsignups.N
            newObj.currentsignups = categoryObj.currentsignups.N
            subItemTableObjs.push(newObj);
        }

    }

    //console.log(tableObjs)
    global.$regsubitems = subItemTableObjs;
    global.$regdropdownoptions = subItemTableObjs;
    return tableObjs
}

async function validateRegItemUpgrade(registereditems, selecteditems) {
    var resultObj = new Object();
    //console.log(selecteditems);
    try {
        let apiName = getRegistrationService();
        //console.log(apiName);
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'command': 'validateRegItems',
                'code': global.$code,
                'registereditems': registereditems.toString(),
                "selecteditems": selecteditems,
                'regitem': 'travcon-upgrade'
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //console.log(myInit);
        var result = await API.post(apiName, path, myInit);
        //console.log(result)
        if (result.err) {
            resultObj.err = result.err;
            resultObj.data = "";
            return resultObj;
        }
        else {
            resultObj.err = result.data.message;
            resultObj.data = result.data.result;
            return resultObj;
        }
        //console.log(resultObj);
    }
    catch (error) {

        resultObj.err = error;
        resultObj.data = "";

        //console.log(resultObj);
        return resultObj;
    }
}
async function validateRegItemToBlock(regitemsforvalidation, selectedregoptions) {
    var resultObj = new Object();
    try {
        let apiName = getRegistrationService();
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'command': 'validateRegItems',
                'code': global.$code,
                'regitems': regitemsforvalidation,
                'regitem': 'travcon-afterparty'
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }

        //console.log(myInit);

        var result = await API.post(apiName, path, myInit);
        if (result.err) {
            resultObj.err = result.err;
            resultObj.data = "";
        }
        else {
            resultObj.err = result.data.message;
            resultObj.data = result.data.status;
        }
        //console.log(resultObj);
        return resultObj;
    }
    catch (error) {

        resultObj.err = error;
        resultObj.data = "";

        //console.log(resultObj);
        return resultObj;
    }
}
async function validateRegItemSignupCount(selectedregitems) {
    var resultObj = new Object();
    console.log(selectedregitems);
    try {
        let apiName = getRegistrationService();
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'command': 'validateRegItemSignupCount',
                'code': global.$code,
                'regitems': selectedregitems,
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
       //console.log(myInit)
        var result = await API.post(apiName, path, myInit);
       //console.log(result)
        if (result.err) {
            resultObj.err = result.err;
            resultObj.data = "";
        }
        else {
            resultObj.err = result.data.message;
            resultObj.data = result.data.result;
        }
        //console.log(resultObj);
        return resultObj;
    }
    catch (error) {

        resultObj.err = error;
        resultObj.data = "";

        //console.log(resultObj);
        return resultObj;
    }
}

async function getAttendeeInfo(userid) {
    var resultObj = new Object();
    try {
        let apiName = getUserService();
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'command': 'getUserProfile',
                'code': global.$code,
                'userid': userid,
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //console.log(apiName);
        //console.log(myInit);
        var result = await API.post(apiName, path, myInit);
        if (result.err == null && result.data != null) {
            var userObj = getUserObject(result.data.Item);
            resultObj.err = "";
            resultObj.data = userObj;
            resultObj.status = true;
        }
        else {
            resultObj.err = result.err;
            resultObj.data = "";
            resultObj.status = false;
        }
        //console.log(resultObj);
        return resultObj;
    }
    catch (error) {

        resultObj.err = error;
        resultObj.data = "";
        resultObj.status = false;

        //console.log(resultObj);
        return resultObj;
    }
}

function getUserObject(objs) {
    //var obj = objs[0] 
    //console.log(objs) 
    //console.log(objs.amountpaid.N)

    var newObj = Object()

    newObj.userid = objs.userid.S
    newObj.fname = objs.fname.S
    newObj.lname = objs.lname.S
    newObj.photo = objs.photo.S
    newObj.company = objs.company.S
    newObj.phone = objs.phone.S
    newObj.title = objs.title.S
    newObj.email = objs.email.S
    newObj.country = objs.country.S
    newObj.state = objs.state.S
    newObj.street = objs.street.S
    newObj.city = objs.city.S
    newObj.state = objs.state.S
    newObj.zip = objs.zip.S
    newObj.linkedin = objs.linkedin.S
    newObj.twitter = objs.twitter.S
    newObj.fb = objs.fb.S
    newObj.regid = objs.regid.S
    newObj.regtype = objs.regtype.S
    newObj.memberid = objs.memberid.S
    newObj.visitpoints = objs.visitpoints.N
    newObj.activitypoints = objs.activitypoints.N
    newObj.totalpoints = objs.totalpoints.N
    newObj.sessionpoints = objs.sessionpoints.N
    newObj.regstatus = objs.regstatus.S
    newObj.password = objs.password.S
    newObj.myexpertise = objs.myexpertise.S
    newObj.myinterests = objs.myinterests.S
    newObj.validscans = objs.validscans.S
    newObj.currentlocation = objs.currentlocation.S
    newObj.lastseen = objs.lastseen.S
    newObj.showdirectory = objs.showdirectory.S
    newObj.officephone = objs.officephone.S
    newObj.location = objs.location.S
    newObj.locationtime = objs.locationtime.S
    newObj.signindate = objs.signindate.S
    newObj.data1 = objs.data1.S
    newObj.data2 = objs.data2.S
    newObj.data3 = objs.data3.S
    newObj.data4 = objs.data4.S
    newObj.data5 = objs.data5.S
    newObj.q1 = objs.q1.S
    newObj.q2 = objs.q2.S
    newObj.q3 = objs.q3.S
    newObj.q4 = objs.q4.S
    newObj.q5 = objs.q5.S
    newObj.q6 = objs.q6.S
    newObj.q7 = objs.q7.S
    newObj.q8 = objs.q8.S
    newObj.userstype = objs.userstype.S
    newObj.guests = objs.guests.S
    newObj.sessions = objs.sessions.S
    newObj.regnotes = objs.regnotes.S
    newObj.checkinstatus = objs.checkinstatus.S
    newObj.mname = objs.mname.S
    newObj.poolid = objs.poolid.S
    newObj.totalamount = objs.totalamount.N
    newObj.amountpaid = objs.amountpaid.N
    newObj.promoamount = objs.promoamount.N
    newObj.pendingamount = objs.pendingamount.N
    newObj.refundamount = objs.refundamount.N
    newObj.paymentmode = objs.paymentmode.S
    newObj.promocodes = objs.promocodes.S
    newObj.guestname = objs.guestname.S

    //console.log(newObj)
    //////console.log(newObj.category);

    return newObj
}
export default {
    getRegistrationDetails,
    validateRegItemUpgrade,
    validateRegItemToBlock,
    validateRegItemSignupCount,
    getAttendeeInfo
}