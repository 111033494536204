import React from 'react';
import { Row, Col, Input } from 'reactstrap';
import { API, Analytics } from 'aws-amplify';
import './AdsLayout.css';
import AdsSlider from './AdsSlider';

let order = 'desc';
var imgdata = '';
class AdsLayout extends React.Component {
    constructor(props) {
        super(props);

        this.state =
        {
            code: global.$code,
        }
    }
    render() {

        //console.log("AttendeeList: render");
        var styles = global.$config['adsscroll'].styles;
        Analytics.record({ name: 'PageView', attributes: { name: 'eventsites-' + this.state.code + '-attendees' } });
        return (
            <div>
                <div className="ads-layout-slider-div" style={styles.sliderdiv}>
                    <div dangerouslySetInnerHTML={{ __html: global.$config['adsscroll'].headerdiv }}></div>
                    <AdsSlider
                        code={this.state.code}
                    />
                </div>
            </div>
        );
    }

}
export default AdsLayout;