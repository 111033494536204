import React from 'react';
import { API } from 'aws-amplify';
import { Row, Col, Button } from 'reactstrap';
import './Appointments.css';
import UpdatePoints from '../Points/UpdatePoints';
var serviceNames = require('../ServiceNameMapper');

const moment = require('moment-timezone');
var timer;
class AvailableAppointments extends React.Component {
    constructor(props) {

        super(props);
        this.state = {
            code: global.$code,
            s3: global.$s3,
            bucket: global.$s3bucket,
            availableappointments: '',
            dataloaded: false,
            showappointments: false,
            showappointmentsmessage: false,
            updatepoints: false,
            schedulebuttonstyle: global.$appointments.styles.buttons.schedule,
            reservedbuttonstyle: global.$appointments.styles.buttons.reserved,
            timestyle: global.$appointments.styles.time
        };
        timer = setInterval(() => {
            this.getAppointments()
        }, 1000)
    }

    render() {
        if (this.state.dataloaded) {
            return (
                <div className="appointment-div">
                    <div>
                        <div className="appointments-header">
                            Available Appointments
                        <hr className="appointment-hr" />
                        </div>
                        <Row>
                            <Col md={3}>
                                <div className="virtual-exhibitor-logo-div">
                                    <img className="virtual-exhibitor-logo" src={`https://s3.amazonaws.com/event360live/public/${global.$code}/exhibitors/${this.props.exhibitor.LOGO}`} />
                                </div>
                            </Col>
                            <Col md={9}>
                                {this.showAppointments()}
                            </Col>
                        </Row>
                    </div>
                    {
                        this.state.updatepoints ?
                            (
                                <div>
                                    {
                                        global.$points.exhibitorappointment != null && global.$points.exhibitorappointment.enable.includes("Y") ?
                                            (
                                                <UpdatePoints sponsor={this.props.exhibitor} component="exhibitor" area="exhibitorappointment" points={global.$points.exhibitorappointment.points} />
                                            )
                                            :
                                            ('')
                                    }
                                </div>
                            )
                            :
                            ('')
                    }
                </div>
            )
        }
        else {
            this.getAppointments();
            return (
                <div>
                    <Row>
                        <Col md={6} className="offset-5">
                            <div className="loading-div">
                                <div className="loading-text">Loading....</div>
                            </div>
                        </Col>
                    </Row>
                </div>
            )
        }

    }

    showAppointments() {
        var exhibitordata = this.state.availableappointments
        //console.log(exhibitordata);
        return exhibitordata.map(exhibitor => {
            //console.log(exhibitor)
            var etime = moment(exhibitor.endtime).format('LT');
            return (
                <div key={exhibitor.id}>
                    {
                        this.state.showappointments ?
                            (
                                <Row>
                                    <Col md={8} className="appointment-time" style={this.state.timestyle}>
                                        {exhibitor.begintime} - {etime}
                                    </Col>
                                    {
                                        exhibitor.status.includes("A") ?
                                            (
                                                <Col md={2} className="offset-md-1">
                                                    <Button className="btn" id="appointment_schedule_button" style={this.state.schedulebuttonstyle} onClick={() => this.updateAppointment(exhibitor)} >Schedule</Button>
                                                </Col>
                                            )
                                            :
                                            (
                                                <Col md={2} className="offset-md-1">
                                                    <Button className="btn" id="appointment_reserved_button" style={this.state.reservedbuttonstyle} onClick={this.submitAppointment} disabled>Reserved</Button>
                                                </Col>
                                            )
                                    }
                                </Row>
                            )
                            :
                            (
                                <div>
                                    {
                                        this.state.showappointmentsmessage ?
                                            (
                                                <div className="appointments-no-data offset-md-5">
                                                    Sorry, Don't have any appointments.
                                                </div>
                                            )
                                            :
                                            ('')
                                    }
                                </div>
                            )
                    }
                </div>
            )
        })
    }

    getAppointmentTableObjects(objs) {
        //console.log(objs);
        const tableObjs = []

        for (var i = 0; i < objs.length; i++) {
            var Obj = objs[i]
            //console.log(Obj);
            var newObj = Object()
            //console.log(Obj.ownerid.S, this.props.exhibitorid)
            if (Obj.ownerid.S == this.props.exhibitorid) {
                this.state.showappointments = true;
                //console.log(this.state.showappointments);

                newObj.id = Obj.id.S
                newObj.begintime = Obj.begintime.S
                newObj.endtime = Obj.endtime.S
                newObj.ownerid = Obj.ownerid.S
                newObj.ownername = Obj.ownername.S
                newObj.status = Obj.status.S
                newObj.meeting = Obj.meeting.S
                newObj.notes = Obj.notes.S
                newObj.data1 = Obj.data1.S
                newObj.data2 = Obj.data2.S
                newObj.data3 = Obj.data3.S
                tableObjs.push(newObj);
                //console.log(tableObjs);
                tableObjs.sort(function (a, b) {
                    var nameA = a.begintime.toLowerCase(), nameB = b.begintime.toLowerCase()
                    //console.log(nameA, nameB)
                    if (nameA < nameB) //sort string ascending
                        return -1
                    if (nameA > nameB)
                        return 1
                    return 0 //default return value (no sorting) 
                })

            }
            else {
                this.state.showappointmentsmessage = true;
            }
        }
        //console.log(tableObjs);
        return tableObjs;
    }

    getAppointments() {
        let apiName = serviceNames.getAppointmentService();
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'code': this.state.code,
                'command': 'getAllAppointments',
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //console.log(myInit);

        API.post(apiName, path, myInit).then(response => {
            //console.log ("response.data ===> " + response.data + "response.err ===> " + response.err);
            //console.log(response);

            if (response.err == null && response.data != null) {
                //console.log(response.data);
                var Appointments = this.getAppointmentTableObjects(response.data.Items);
                //console.log(Appointments);

                this.setState({
                    availableappointments: Appointments,
                    dataloaded: true
                });
            }
            else {
                ////console.log("getAttendeesTableObjects - failed!!");
                //console.log(response.err);
            }
        }
        ).catch(error => {
            //console.log(error.response)
        });
    }

    updateAppointment(exhibitor) {
        //console.log(this.state);
        var exhibitorinfo = this.props.exhibitor
        //console.log(exhibitorinfo);
        let apiName = serviceNames.getAppointmentService();
        let path = ''; //replace this with the path you have configured on your API
        if (exhibitor.data1 == "" || exhibitor.data1 == " ") {
            exhibitor.data1 = `ED#NAME:${exhibitorinfo.CNAME};COMPANY:${exhibitorinfo.NAME};EMAIL:${exhibitorinfo.EMAIL};PHONE:${exhibitorinfo.PHONE}`
        }
        let myInit =
        {
            body:
            {
                'code': this.state.code,
                'command': "addAppointment",
                'id': exhibitor.id,
                'begintime': exhibitor.begintime,
                'endtime': exhibitor.endtime,
                'ownerid': exhibitor.ownerid,
                'ownername': exhibitor.ownername,
                'userid': global.$userid,
                'username': global.$fname + " " + global.$lname,
                'status': "R",
                'meeting': exhibitor.meeting,
                'notes': exhibitor.notes,
                'data1': exhibitor.data1,
                'data2': exhibitor.data2,
                'data3': exhibitor.data3,
                'appointmentstatus': 'schedule'
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //console.log(myInit);
        API.post(apiName, path, myInit).then(response => {
            ////console.log (response.data);
            this.setState({updatepoints: true});
        }
        ).catch(error => {
            ////console.log(error.response)
        });
    }

}
export default AvailableAppointments;


