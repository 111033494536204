import React from 'react';
import SessionVideos2 from './SessionVideos2';
import SessionTabs2 from './SessionTabs2.jsx';
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators, Row, Jumbotron, Container, Col, Button, Form, FormGroup, Label, Input, Modal, Header, NavLink } from 'reactstrap';
import SessionSponsorSlider from './SessionSponsorSlider';
import SpeakersLayout from '../Components/Shared/SpeakersLayout/SpeakersLayout';
import SponsorsLayout from '../Components/Shared/SponsorsLayout/SponsorsLayout';
import { Link } from 'react-router-dom';
import AdsLayout from '../Components/Shared/AdsLayout/AdsLayout';
//import SessionCheckIns2 from './SessionCheckIns2.js';
import SessionCheckIns3 from './SessionCheckIns3';

const moment = require('moment-timezone');
var enablesquareads = 'Y';
var enablereactads = 'Y';
var enablesponsorscroll = 'Y';
class SessionWithTabs2 extends React.Component {
    constructor(props) {
        super(props);
        var sessioncheckins = "Y";
        var conferencebanner = '';

        if (global.$schedule.sessioninfo2 == null) {
            conferencebanner = ""
        }
        else {
            conferencebanner = global.$schedule.sessioninfo2.banner
        }
        //console.log(global.$schedule.sessioninfo2);
        if (global.$schedule.sessioninfo2.enablesquareads != null) {
            enablesquareads = global.$schedule.sessioninfo2.enablesquareads
        }

        if (global.$schedule.sessioninfo2.enablereactads != null) {
            enablereactads = global.$schedule.sessioninfo2.enablereactads
        }


        if (global.$schedule.sessioninfo2.enablesponsorscroll != null) {
            enablesponsorscroll = global.$schedule.sessioninfo2.enablesponsorscroll
        }
        if (global.$schedule.sessioninfo2.enablesessioncheckins != null) {
            sessioncheckins = global.$schedule.sessioninfo2.enablesessioncheckins;
        }

        this.state = {
            code: global.$code,
            titlestyle: global.$session.styles.sessiontitle,
            descstyle: global.$session.styles.sessiondesc,
            timestyle: global.$session.styles.sessiontime,
            speakers: this.props.session.SP,
            curenttime: '',
            sessionstarttime: '',
            sessionendtime: '',
            enablesessioncheckins: sessioncheckins,
            checkins: this.props.session.CHECKINS,
            activeIndex: 0,
            conferencebanner: conferencebanner
        };

        //console.log(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
    }
    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === global.$sponsorjson.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? global.$sponsorjson.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }

    render() {
        //console.log(this.props);
        //console.log("sessionwithtabs2: render");
        //console.log(this.props.curenttime >= this.props.sessionstarttime, this.state)
        var sponsorlogos = global.$sponsorjson.map(sponsor => {
            //console.log(sponsor.LOGO)
            var logo = `https://s3.amazonaws.com/event360live/public/${this.state.code}/exhibitors/` + sponsor.LOGO
            if (sponsor.LOGO == "" || sponsor.LOGO == " " || sponsor.LOGO == null) {
                return ('');
            }
            else {
                return (
                    <img className="session-sponsor-logos" src={logo} alt={"logo"} />
                )
            }
        });
        return (
            <div>
                {
                    enablereactads.includes("Y") ?
                        (
                            <div>
                                <Row>
                                    <Col md={8}>
                                        <div className="banner-div">
                                            <img className="session-conference-banner" src={this.state.conferencebanner} />
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="presenting-logo-div">
                                            <SessionSponsorSlider type="ad1" />
                                        </div>
                                    </Col>
                                </Row>
                                <br />
                            </div>
                        )
                        :
                        (
                            ''
                        )
                }
                <div dangerouslySetInnerHTML={{ __html: global.$schedule.sessioninfo2.topdiv }}></div>
                <Row>
                    <Col md={8}>
                        {
                            (this.props.curenttime >= this.props.sessionstarttime) ?
                                (
                                    <div>
                                        {
                                            this.state.enablesessioncheckins.includes("Y") ?
                                                (
                                                    <div>
                                                        {
                                                            this.state.checkins == "" || this.state.checkins == " " || this.state.checkins == "empty" ?
                                                                (
                                                                    global.$schedule.sessioninfo2.checkintype != null && global.$schedule.sessioninfo2.checkintype == "single" ?
                                                                        (
                                                                            <SessionCheckIns3 session={this.props.session} />
                                                                        )
                                                                        :
                                                                        ('')
                                                                )
                                                                :
                                                                (
                                                                    <SessionCheckIns3 session={this.props.session} />
                                                                )
                                                        }
                                                    </div>
                                                )
                                                :
                                                ('')
                                        }
                                    </div>
                                )
                                :
                                ('')
                        }
                        <div className="livestream-div">
                            <SessionVideos2 session={this.props.session} />
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="tabs-div">
                            <SessionTabs2
                                session={this.props.session}
                                showfeedback={this.props.showfeedback}
                                sessiondate={this.props.sessiondate}
                                activetab={this.props.activetab}
                                type={this.props.type}
                            />
                        </div>
                    </Col>
                </Row>
                <hr />
                {
                    enablesquareads.includes("Y") && enablesponsorscroll.includes("Y") ?
                        (
                            <Row>
                                <Col md={2}>
                                    <div className="rotating-banner-div">
                                        <SessionSponsorSlider type="ad2" />
                                    </div>
                                </Col>
                                <Col md={10}>
                                    <div className="scrolling-sponsors-div">
                                        <marquee direction="left" scrollamount="5" className="scrolling-sponsors">
                                            {sponsorlogos}
                                        </marquee>
                                    </div>
                                </Col>
                            </Row>
                        )
                        :
                        (
                            <div>
                                {
                                    enablesquareads.includes("Y") ?
                                        (
                                            <Row>
                                                <Col md={12}>
                                                    <div className="rotating-banner-div">
                                                        <SessionSponsorSlider type="ad2" />
                                                    </div>
                                                </Col>
                                            </Row>
                                        )
                                        :
                                        ('')
                                }
                                {
                                    enablesponsorscroll.includes("Y") ?
                                        (
                                            <Row>
                                                <Col md={12}>
                                                    <div className="scrolling-sponsors-div">
                                                        <marquee direction="left" scrollamount="5" className="scrolling-sponsors">
                                                            {sponsorlogos}
                                                        </marquee>
                                                    </div>
                                                    <br />
                                                </Col>
                                            </Row>
                                        )
                                        :
                                        ('')
                                }
                            </div>
                        )
                }
                {
                    global.$schedule.sessioninfo2.rows != null ?
                        (
                            this.getRows()
                        )
                        :
                        ('')
                }
            </div>
        )
    }

    getRows() {
        var objs = global.$schedule.sessioninfo2.rows
        //console.log(objs)
        const tableObjs = []

        const rowsObj = objs.map((item) =>
            <div>
                {
                    item.type.includes("image-text") ?
                        (
                            <div style={item.style}>
                                <div dangerouslySetInnerHTML={{ __html: item.title }}></div>
                                <Row className="justify-content-center">
                                    <Col md={5} className="offset-1">
                                        <div dangerouslySetInnerHTML={{ __html: item.image }}></div>
                                    </Col>
                                    <Col md={5}>
                                        <div dangerouslySetInnerHTML={{ __html: item.text }}></div>
                                        {
                                            item.learnmore.enable.includes("Y") ?
                                                (
                                                    <div style={this.state.leranmoredivstyle}>
                                                        <Button className="btn btn-primary" style={this.state.leranmorestyle}><Link to={item.learnmore.link} style={this.state.leranmorestyle}>{item.learnmore.title}</Link></Button>
                                                    </div>
                                                )
                                                :
                                                ('')
                                        }
                                        <div className="offset-1"></div>
                                    </Col>
                                </Row>
                            </div>
                        )
                        :
                        ('')
                }
                {
                    item.type.includes("text-image") ?
                        (
                            <div style={item.style}>
                                <div dangerouslySetInnerHTML={{ __html: item.title }}></div>
                                <Row>
                                    <Col md={5} className="offset-1" >
                                        <div dangerouslySetInnerHTML={{ __html: item.text }}></div>
                                        {
                                            item.learnmore.enable.includes("Y") ?
                                                (
                                                    <div style={this.state.leranmoredivstyle}>
                                                        <Button className="btn btn-primary" style={this.state.leranmorestyle}><Link to={item.learnmore.link} style={this.state.leranmorestyle}>{item.learnmore.title}</Link></Button>
                                                    </div>
                                                )
                                                :
                                                ('')
                                        }
                                    </Col>
                                    <Col md={5} className="offset-1">
                                        <div dangerouslySetInnerHTML={{ __html: item.image }}></div>
                                    </Col>
                                </Row>

                            </div>
                        )
                        :
                        ('')
                }
                {
                    item.type.includes("text-only") ?
                        (
                            <div style={item.style}>
                                <div dangerouslySetInnerHTML={{ __html: item.title }}></div>
                                <Row>
                                    <Col md={10} className="offset-1">
                                        <div dangerouslySetInnerHTML={{ __html: item.text }}></div>
                                    </Col>
                                </Row>
                                {
                                    item.learnmore.enable.includes("Y") ?
                                        (
                                            <div style={this.state.leranmoredivstyle} className="home-reg-button-div">
                                                <Button className="btn btn-primary home-reg-button" style={this.state.leranmorestyle}><Link to={item.learnmore.link} style={this.state.leranmorestyle}>{item.learnmore.title}</Link></Button>
                                            </div>
                                        )
                                        :
                                        ('')
                                }
                            </div>
                        )
                        :
                        ('')
                }
                {
                    item.type.includes("image-only") ?
                        (
                            <div style={item.style}>
                                <div dangerouslySetInnerHTML={{ __html: item.title }}></div>
                                <Row>
                                    <Col md={10} className="offset-1">
                                        <div dangerouslySetInnerHTML={{ __html: item.image }}></div>
                                    </Col>
                                </Row>
                                {
                                    item.learnmore.enable.includes("Y") ?
                                        (
                                            <div style={this.state.leranmoredivstyle}>
                                                <Button className="btn btn-primary" style={this.state.leranmorestyle}><Link to={item.learnmore.link} style={this.state.leranmorestyle}>{item.learnmore.title}</Link></Button>
                                            </div>
                                        )
                                        :
                                        ('')
                                }
                            </div>
                        )
                        :
                        ('')
                }
                {
                    item.type.includes("speakers-section") ?
                        (
                            <SpeakersLayout />
                        )
                        :
                        ('')
                }
                {
                    item.type.includes("sponsors-section") ?
                        (
                            <SponsorsLayout />
                        )
                        :
                        ('')
                }
                {
                    item.type.includes("ads-section") ?
                        (
                            <AdsLayout />
                        )
                        :
                        ('')
                }
            </div>
        );

        return (
            <div>
                {rowsObj}
            </div>
        )

    }
}
export default SessionWithTabs2;


