import { BootstrapTable, TableHeaderColumn, SearchField } from 'react-bootstrap-table';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import { Row, Col, Spinner, Button, Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { API } from 'aws-amplify';
import { ReactSpinner } from 'react-spinning-wheel';
import 'react-spinning-wheel/dist/style.css';
import Icon from '@mdi/react';
import { mdiAccountEdit, mdiDeleteForever, mdiGamepadRoundOutline, mdiPrinter, mdiQrcode, mdiQrcodeEdit, mdiRefresh, mdiPrinterWireless, mdiEmailSend, mdiRegisteredTrademark, mdiCurrencyUsd, mdiCash100, mdiCashRegister, mdiCashUsd, mdiCashUsdOutline, mdiDownloadMultiple, mdiDownload, mdiDotsVertical, mdiPlus, mdiClipboardList, mdiHelpCircleOutline } from '@mdi/js';
import './RepresentativeList.css';
import CommonConstants from '../Common/CommonConstants';
import EventcodeFunctions from '../Registration/RegCommon/EventcodeFunctions';
import TravconFunctions from '../Registration/RegCommon/CustomerFunctions/TravconFunctions';
import CommonFunctions from '../Registration/RegCommon/CommonFunctions';
var serviceNames = require('../ServiceNameMapper');

var attendees =
  [

  ];

class RepresentativeList extends React.Component {

  constructor(props) {
    //console.log(global);
    super(props);
    this.state =
    {
      loggedinuserid: global.$userid,
      loggedinusertype: global.$userstype,
      code: global.$code,
      dataloaded: false,
      data: attendees,
      company: global.$company,
      poolid: global.$poolid,
      maxrepcount: 0,
      dropdownStatus: '',
      currentrepcount: 0
    };
    //console.log(props);

    if (this.state.code == "travcon2023exhibitor") {
      this.state.code = "travcon2023";
    }


    this.idFormatter = this.idFormatter.bind(this);
    this.dropdownOpen = this.dropdownOpen.bind(this);
    this.toggleStatus = this.toggleStatus.bind(this);
  }

  renderPaginationShowsTotal(start, to, total) {
    return (
      <p style={{ color: 'blue' }}>
        From {start} to {to}, total is {total}
      </p>
    );
  }

  componentDidMount() {
    var regitemsjson = JSON.parse(global.$regitemsjson);
    this.getMaxRepCountForExhibtior(regitemsjson);
  }

  handleAttendeeEdit = (cell) => {
    //console.log(cell)
    if (cell == this.state.loggedinuserid) {
      return (
        <Link to={`/RepresentativeEditor/${cell}`} style={{ color: 'red' }}>{cell}</Link>
      )
    }
    else {
      return (
        <Link to={`/RepresentativeEditor/${cell}`}>{cell}</Link>
      )
    }
  }

  dropdownOpen(id) {
    this.setState({ dropdownStatus: id });
  }

  toggleStatus() {
    if (this.state.dropdownStatus.length != 0) {
      this.setState({ dropdownStatus: "" });
    }
  }

  idFormatter(id) {
    //console.log(userid)
    var [userid, name, regstatus, regitems, guestemail, totalamount, amountpaid, promoamount, pendingamount, usertype] = id.split("==");
    //console.log(userid, usertype, this.state.loggedinuserid, this.state.loggedinusertype)
    return (<div className="speaker-actions-div">
      <Dropdown isOpen={this.state.dropdownStatus == id ? true : false} toggle={this.toggleStatus} direction="right">
        <DropdownToggle className="action-dropdown" aria-expanded={this.state.dropdownStatus == name ? true : false}>
          <Link to="#" value={name} onClick={() => this.dropdownOpen(id)}>
            <Icon path={mdiDotsVertical} size={1} color="gray" className="actions-verticaldots-icon" />
          </Link>
        </DropdownToggle>
        <DropdownMenu style={{ minWidth: "9rem" }}>
          <div>
            <Link to={`./RepresentativeEditor/${userid}`} className="dropdown-content">
              <Icon path={mdiAccountEdit} size={0.9} color="gray" /><span>&nbsp;&nbsp;Edit</span>
            </Link>
          </div>
          <div>
            {
              this.state.loggedinuserid == userid && this.state.loggedinusertype == usertype ?
                (
                  ''
                )
                :
                (
                  <Link to="#" className="dropdown-content" onClick={() => { if (window.confirm(`Do you want to delete ${userid}?`)) { this.deleteRepresentative(userid) }; }}>
                    <Icon path={mdiDeleteForever} size={0.9} color="gray" /><span>&nbsp;&nbsp;Delete</span>
                  </Link>
                )
            }
          </div>
          <div>
            {
              this.state.loggedinuserid == userid && this.state.loggedinusertype == usertype ?
                (
                  ''
                )
                :
                (
                  <Link to="#" className="dropdown-content" onClick={() => { if (window.confirm(`Do you want to send instructions email to ${userid} ?`)) { this.SendEmailToExhibitorRepresentative(userid) }; }}>
                    <Icon path={mdiEmailSend} size={0.9} color="gray" /><span>&nbsp;&nbsp;Send Email</span>
                  </Link>
                )
            }
          </div>
        </DropdownMenu>
      </Dropdown>
    </div>
    )
  }

  createCustomExportCSVButton(onClick) {
    return (
      <Link to="#" className="new-button" onClick={onClick} style={{ backgroundColor: "#4ce1b6" }}><Icon path={mdiDownload} size={0.9} color="white" /></Link>
      // <ExportCSVButton btnText='' style={{ borderRadius: '50%', width: "50px", color: 'white' }}><Icon path={mdiDownload} size={1} style={{marginLeft: "-3px"}}/></ExportCSVButton>
    )
  }

  render() {
    const options =
    {
      paginationShowsTotal: this.renderPaginationShowsTotal,
      // exportCSVText: 'Download',
      // exportCSVPosition: '',
      exportCSVBtn: this.createCustomExportCSVButton,
      sizePerPageList: [{
        text: '5', value: 5
      }, {
        text: '10', value: 10
      }, {
        text: 'All', value: this.state.data.length
      }], // you can change the dropdown list for size per page
      sizePerPage: this.state.data.length,  // which size per page you want to locate as default
      defaultSortOrder: "asce"
    }

    if (this.state.dataloaded) {
      return (
        <div>
          <div className="offset-md-1">
            <Row style={{ marginTop: "1.8rem", marginBottom: "1.8rem" }}>
              <Col md={12}>
                <Row style={{ marginRight: "0px" }}>
                  <Col>
                    {/* <h4>Maximum Representatives Allowed - {this.state.maxrepcount}</h4> */}
                    <h4 className='total-representative-count'>Total Representatives ({this.state.currentrepcount} of {this.state.maxrepcount})</h4>
                  </Col>
                  {
                    this.state.currentrepcount < this.state.maxrepcount ?
                      (
                        <Link to="./RepresentativeEditor/addnew" className="new-button rep-new-btn" title="Add New"><Icon path={mdiPlus} size={0.9} color="white" /></Link>
                      )
                      :
                      ''
                  }
                  {
                    global.$config != null && global.$config.faqlink != null ?
                      (
                        <Link to={{ pathname: global.$config.faqlink }} target="_blank" className="new-button rep-new-btn" title="Frequently Asked Questions"><Icon path={mdiHelpCircleOutline} size={0.9} color="white" /></Link>
                      )
                      :
                      ''
                  }
                </Row>
                <BootstrapTable
                  tableContainerClass="data-table"
                  data={this.state.data}
                  options={options}
                  // multiColumnSearch={true}
                  headerStyle={{ position: 'sticky', top: '0' }}
                  bodyStyle={{ minHeight: '200px', maxHeight: '700px', width: '100%' }}
                  tableStyle={{ marginTop: '1.5rem' }}
                  // containerStyle = {{ paddingTop: '50px' }}
                  // hover
                  // condensed
                  // striped
                  bordered
                //pagination
                >
                  <TableHeaderColumn width="60px" dataField='id' isKey={true} dataFormat={this.idFormatter} tdStyle={{ 'verticalAlign': 'middle' }} thStyle={{ 'fontWeight': 'bold', 'backgroundColor': '#00afeb', 'color': 'white' }} headerAlign='center'></TableHeaderColumn>
                  <TableHeaderColumn width="250px" dataField="userid" dataSort={true} dataFormat={this.handleAttendeeEdit} tdStyle={{ 'verticalAlign': 'middle' }} thStyle={{ 'fontWeight': 'bold', 'backgroundColor': '#00afeb', 'color': 'white' }} headerAlign='center' >User ID</TableHeaderColumn>
                  <TableHeaderColumn width="250px" dataField="fullname" dataSort={true} tdStyle={{ 'verticalAlign': 'middle' }} thStyle={{ 'fontWeight': 'bold', 'backgroundColor': '#00afeb', 'color': 'white' }} headerAlign='center' >Name</TableHeaderColumn>
                  <TableHeaderColumn width="250px" dataField="regid" dataSort={true} tdStyle={{ 'verticalAlign': 'middle' }} thStyle={{ 'fontWeight': 'bold', 'backgroundColor': '#00afeb', 'color': 'white' }} headerAlign='center' >Reg ID</TableHeaderColumn>
                  <TableHeaderColumn width="250px" dataField="company" dataSort={true} tdStyle={{ 'verticalAlign': 'middle' }} thStyle={{ 'fontWeight': 'bold', 'backgroundColor': '#00afeb', 'color': 'white' }} headerAlign='center' >Company</TableHeaderColumn>
                  <TableHeaderColumn width="250px" dataField="userstype" dataSort={true} tdStyle={{ 'verticalAlign': 'middle' }} thStyle={{ 'fontWeight': 'bold', 'backgroundColor': '#00afeb', 'color': 'white' }} headerAlign='center' >Registration Type</TableHeaderColumn>
                </BootstrapTable>
              </Col>
            </Row>
          </div>
        </div>
      )
    }
    else {
      this.getUsers();
      return (
        <div>
          <ReactSpinner />
        </div>
      )
    }
  }

  SendEmailToExhibitorRepresentative(userid) {
    let apiName = serviceNames.getRegistrationService();
    let path = ''; //replace this with the path you have configured on your API
    let myInit = {
      body:
      {
        'command': 'SendEmailToExhibitorRepresentative',
        'code': global.$code,
        'userid': userid,
        'emailtype': 'exhibitor-representative'
      },
      response: true,
      convertEmptyValues: true,
      headers: {}
    }
    //console.log(myInit);

    API.post(apiName, path, myInit).then(response => {
      //console.log(response);

      if (response.err == null) {
        //////console.log(response.data.Items);
      }
      else {
        //console.log(response.err);
      }
    }

    ).catch(error => {
      //console.log(error)
    });
  }

  getAttendeesTableObjects(objs) {
    const tableObjs = []
    const repObjs = []

    //console.log(objs)
    for (var i = 0; i < objs.length; i++) {
      var attendeeObj = objs[i]

      //console.log(attendeeObj)
      // Not counting the admin as rep
      if (attendeeObj.userstype.S == "Exhibitor" || attendeeObj.userstype.S == "Exhibitor Representative" || attendeeObj.userstype.S == "Exhibitor Admin") {
        var newObj = Object()
        newObj.id = attendeeObj.userid.S + "==" + attendeeObj.fname.S + " " + attendeeObj.lname.S + "==" + attendeeObj.regstatus.S + "==" + attendeeObj.regtype.S + "==" + attendeeObj.guests.S + "==" + attendeeObj.totalamount.N + "==" + attendeeObj.amountpaid.N + "==" + attendeeObj.promoamount.N + "==" + attendeeObj.pendingamount.N + "==" + attendeeObj.userstype.S
        newObj.fullname = attendeeObj.fname.S + " " + attendeeObj.lname.S
        newObj.company = attendeeObj.company.S
        newObj.userid = attendeeObj.userid.S
        newObj.regstatus = attendeeObj.regstatus.S
        newObj.regid = attendeeObj.regid.S
        newObj.userstype = attendeeObj.userstype.S

        if (attendeeObj.userstype.S != "Exhibitor Admin") {
          repObjs.push(newObj);
        }
        //console.log(newObj)

        tableObjs.push(newObj)
      }
    }

    //console.log(repObjs)
    //console.log(repObjs.length)

    this.setState({ currentrepcount: repObjs.length })

    return tableObjs;
  }

  getUsers() {
    let apiName = serviceNames.getUserService();
    let path = ''; //replace this with the path you have configured on your API
    let myInit = {
      body:
      {
        'code': this.state.code,
        'command': 'getUsersById',
        'companyid': global.$companyid
      },
      response: true,
      convertEmptyValues: true,
      headers: {}
    }
    //console.log(myInit);

    API.post(apiName, path, myInit).then(response => {
      //console.log ("response.data ===> " + response.data + "response.err ===> " + response.err);
      console.log(response);
      if (response.err == null && response.data != null) {
        //console.log("getAttendeesTableObjects - Success!!");
        //console.log(response.data);

        attendees = this.getAttendeesTableObjects(response.data.data)
        //global.$attendeelist = attendees;
        //this.setState({dataloaded :true} )
        //this.setState({data :attendees} )

        this.setState({
          data: attendees,
          dataloaded: true
        });

        //console.log(this.state.dataloaded);
        //console.log(this.state.data);
      }
      else {
        //console.log("getAttendeesTableObjects - failed!!");
        //console.log(response.err);

      }
    }
    ).catch(error => {
      //console.log(error.response)
    });
  }

  deleteRepresentative(userid) {
    ////console.log("deleteExhibitor");
    let apiName = serviceNames.getUserService();
    let path = ''; //replace this with the path you have configured on your API
    let myInit =
    {
      body:
      {
        'code': this.state.code,
        'command': "deleteUserProfile",
        'userid': userid,
        'pool': this.state.poolid,
        'submittedby': global.$userid
      },
      response: true,
      convertEmptyValues: true,
      headers: {}
    }
    //console.log(myInit);

    API.post(apiName, path, myInit).then(response => {
      //console.log(response);

      if (response.err == null) {
        //////console.log(response.data.Items);
        //this.setState({dataloaded :true} )

        ////console.log ("Removing id = " +" "+ id);
        //alert("your"+" "+ id + " " +"has been deleted successfully");
        var arr = this.state.data.filter(item => item.userid !== userid)
        this.setState({ data: arr })
      }
      else {
        ////console.log("deleteUser- failed!!");
        //console.log(response.err);
      }
    }

    ).catch(error => {
      //console.log(error)
    });
  }

  getMaxRepCountForExhibtior(regitemsjson) {
    var count = 0;
    var defaultTab = CommonFunctions.getDefaultTab()
    if (global.$code == "travcon2023exhibitor" ||global.$code == "travcon2024exhibitor" ) {
      count = TravconFunctions.getMaxRepCountForExhibtior(regitemsjson);
      
    }
    else if(global.$registrationform.setup[defaultTab].enablereplimits){
        count = this.getExhibitorRepsCount(regitemsjson,defaultTab)
    }
    
    this.setState({
      maxrepcount: Number(count)
    });

    global.$maxrepcount = Number(count);

  }

  getExhibitorRepsCount(regitemsjson,defaultTab) {
    var count = 0
    var regitemsreps = global.$registrationform.registrationitems;

    // get additional reps regitem id
    var additionalrepsregitemid = global.$registrationform.setup[defaultTab].additionalrepsregitemid;
      
    // add regitem reps count
    regitemsjson.map((regitem) => {     
      if (regitemsreps) {
          regitemsreps.map((item) => {
              if(item.itemid == regitem.itemid) count = Number(item.repscount) 
          })
      }
    })

    // add additional reps count + regitem reps count
    if (additionalrepsregitemid) {
      regitemsjson.map((regitem) => { 
          if(regitem.itemid == additionalrepsregitemid) count = count + Number(regitem.qty)
      })
    }

    return count;
  }

}


export default RepresentativeList;