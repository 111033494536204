/* eslint-disable */
import React, { Component } from 'react';
import { FormGroup, Label, Input, FormText, Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import { Storage, API } from 'aws-amplify';
import { Link } from "react-router-dom";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Select from 'react-select';
import Icon from '@mdi/react';
import { Spinner } from 'react-bootstrap';
import './SubmitForms.css';
import { mdiGoogleStreetView, mdiMapMarkerMultiple, mdiZipBox, mdiFileDocumentBox, mdiDomain, mdiImage, mdiMessageText, mdiCellphoneIphone, mdiWeb, mdiFormatTitle, mdiFormatListBulletedType, mdiSort, mdiEmail, mdiAccount, mdiAccountMultiple, mdiHomeGroup, mdiClipboardList, mdiKeyboardBackspace, mdiFileDocument, mdiMapMarker, mdiKeyPlus, mdiAccessPoint, mdiBook, mdiLink, mdiVideo, mdiDatabase, mdiCalendar, mdiFile } from '@mdi/js';
var serviceNames = require('../ServiceNameMapper');

class Products extends Component {
    render() {
        return (
            <div>
                {/* <div className="container"> */}
                    {
                        this.state.key == "exhibitorlist" ?
                        (
                            <div className="btn-group-top" role="group" aria-label="First group">
                                 <Row style={{ marginRight: "0px" }}>
                                    <Col>
                                    <Link type="button" to={{ pathname: "/ExhibitorList" }} className="form-back-button2" title="Back"><Icon path={mdiKeyboardBackspace} size={0.9} color="white" className="form-back-button2-icon" /></Link>
                                    </Col>
                                    <Link to="#" className="form-product-report-btn" onClick={() => { if (window.confirm(`Do you want to download the product reviews report for ${ this.state.sname}?`)) { this.generateProductReviewsReport() }; }} title="Product Reviews Report"><Icon path={mdiClipboardList} size={0.9} color="white" /></Link>
                                </Row>
                            </div>
                        ): ' '
                    }
                    <br />
                    {/* <div className="row">
                        <div className="col-12 ">
                            <form className="shadow-form" > */}
                                <Card className='company_card_wrapper'>
                                    <CardHeader className="form-header-1 company_card_header">Products</CardHeader>
                                    <CardBody>
                                    {/* {
                                            global.$config != null && global.$config.exhibitorproductsectionenable != null && global.$config.exhibitorproductsectionenable ?
                                            ( */}
                                                <>
                                                {/* <div className="content-sub-header" >Products</div>
                                                <hr />
                                                <br /> */}
                                                <FormGroup className="form-labels">    
                                                <div className="p1">
                                                    <Row>
                                                        <Col>
                                                        <Label for="" className="login-labels" >Product 1</Label>
                                                        </Col>
                                                        <Col>
                                                        <Label for="" className="login-labels" >Product 2</Label>
                                                        </Col>
                                                        <Col>
                                                        <Label for="" className="login-labels" >Product 3</Label>                                                
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col>
                                                            <FormGroup className="form-labels">
                                                                <Label for="title1" className="login-labels" ><Icon path={mdiDatabase} size={0.9} color="lightgray" className="form-label-icon" />Title</Label>
                                                                <Input type="text" name="title1" className="form-control-sm" id="title1" value={this.state.title1 || ''} onChange={this.handleInputChange} />
                                                            </FormGroup>
                                                        </Col>

                                                        <Col>
                                                            <FormGroup className="form-labels">
                                                                <Label for="title2" className="login-labels" ><Icon path={mdiDatabase} size={0.9} color="lightgray" className="form-label-icon" />Title</Label>
                                                                <Input type="text" name="title2" className="form-control-sm" id="title2" value={this.state.title2 || ''} onChange={this.handleInputChange} />
                                                            </FormGroup>
                                                        </Col>
                                                        
                                                        <Col>
                                                            <FormGroup className="form-labels">
                                                                <Label for="title3" className="login-labels" ><Icon path={mdiDatabase} size={0.9} color="lightgray" className="form-label-icon" />Title</Label>
                                                                <Input type="text" name="title3" className="form-control-sm" id="title3" value={this.state.title3 || ''} onChange={this.handleInputChange} />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>                                                    
        
                                                    <Row>
                                                        <Col>
                                                            <FormGroup className="form-labels">
                                                                <Label for="image1"><Icon path={mdiImage} size={0.9} color="lightgray" className="form-label-icon" />Image</Label>
                                                                <input ref="uploadImg1" type="file" name="image1" className="form-control-sm" id="proimage1" accept=".gif,.jpg,.jpeg,.png" onChange={this.image1FileUploader} />
                                                                <FormText>Upload image as jpg, png or gif format only</FormText>
                                                                <img src={this.state.image1Url} className="img-responsive offset-md-4" />
                                                            </FormGroup>
                                                        </Col>
        
                                                        <Col>
                                                            <FormGroup className="form-labels">
                                                                <Label for="image2"><Icon path={mdiImage} size={0.9} color="lightgray" className="form-label-icon" />Image</Label>
                                                                <input ref="uploadImg2" type="file" name="image2" className="form-control-sm" id="proimage2" accept=".gif,.jpg,.jpeg,.png" onChange={this.image2FileUploader} />
                                                                <FormText>Upload image as jpg, png or gif format only</FormText>
                                                                <img src={this.state.image2Url} className="img-responsive offset-md-4" />
                                                            </FormGroup>
                                                        </Col>
        
                                                        <Col>
                                                            <FormGroup className="form-labels">
                                                                <Label for="image3"><Icon path={mdiImage} size={0.9} color="lightgray" className="form-label-icon" />Image</Label>
                                                                <input ref="uploadImg3" type="file" name="image3" className="form-control-sm" id="proimage3" accept=".gif,.jpg,.jpeg,.png" onChange={this.image3FileUploader} />
                                                                <FormText>Upload image as jpg, png or gif format only</FormText>
                                                                <img src={this.state.image3Url} className="img-responsive offset-md-4" />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
        
                                                    <Row>
                                                        <Col>
                                                            <Label for="prodesc1" className="login-labels" ><Icon path={mdiMessageText} size={0.9} color="lightgray" className="form-label-icon" />Description</Label>
                                                            <Input type="textarea" name="prodesc1" className="form-control-sm" id="prodesc1" value={this.state.prodesc1 || ''} onChange={this.handleInputChange} />
                                                        </Col>
        
                                                        <Col>
                                                            <Label for="prodesc2" className="login-labels" ><Icon path={mdiMessageText} size={0.9} color="lightgray" className="form-label-icon" />Description</Label>
                                                            <Input type="textarea" name="prodesc2" className="form-control-sm" id="prodesc2" value={this.state.prodesc2 || ''} onChange={this.handleInputChange} />
                                                        </Col>
        
                                                        <Col>
                                                            <Label for="prodesc2" className="login-labels" ><Icon path={mdiMessageText} size={0.9} color="lightgray" className="form-label-icon" />Description</Label>
                                                            <Input type="textarea" name="prodesc3" className="form-control-sm" id="prodesc3" value={this.state.prodesc3 || ''} onChange={this.handleInputChange} />
                                                        </Col>
                                                    </Row>
                                                </div>

                                                </FormGroup>
                                                <div className="alert alert-warning" role="alert" id="message">
                                                    {this.state.message}
                                                </div>
                                                <div className="offset-md-6"  hidden>
                                                    <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                                                        <button
                                                            className="btn form-submit-button submit_btn"
                                                            onClick={this.handleSubmit}
                                                            disabled={this.state.disabled}
                                                            type="submit"
                                                            isLoading={this.state.isLoading}
                                                            text="Submit"
                                                            loadingText="Submit"
                                                        ><Spinner
                                                            as="span"
                                                            animation="border"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                            className="Loading-button"
                                                            variant="success"
                                                            hidden={!this.state.isLoading} />Submit</button>
                                                </div>          
                                                </div>
                                                </>
                                            {/* ):
                                            (
                                                ''
                                            )
                                        } */}
                                    </CardBody>    
                                </Card>
                            {/* </form>
                        </div>
                    </div> */}
                {/* </div>                     */}
            </div>
        )
    }

    constructor(props) {
        //console.log(global);
        //const globalvariables = global.$variables; // Accessing Global variables
        //console.log(props.match.params.value);
        //console.log(globalvariables)
        var date = new Date();
        var longFormat1 = date / 1000;
        var longFormat2 = longFormat1 + 3000;
        var longFormat3 = longFormat2 + 6000;
        var id = '', company = '', exhibitormailid = '', pagekey = '';   
    
        // if(props.match.params.value.includes("exhibitorlist")) {
        //     var [key, exhibitorid, exhibitorcompany, exhibitoremail] = props.match.params.value.split("==");
        //     pagekey = key,id = exhibitorid, company = exhibitorcompany, exhibitormailid = exhibitoremail;
        // }
        // else {
            id = global.$exhibitorid, company = global.$company, exhibitormailid = global.$exhibitoremail
        //}

        super(props);
        this.state =
        {
            //code: '',
            key: pagekey,
            id: id, //globalvariables.exhibitorid
            code: global.$code,
            userid: global.$userid,
            receiverid: id, //globalvariables.exhibitorid
            messageid1: longFormat1.toString(),
            messageid2: longFormat2.toString(),
            messageid3: longFormat3.toString(),
            sname: company, //globalvariables.company
            likes: [],
            w: "0",
            h: "0",
            senderid: exhibitormailid, //globalvariables.exhibitoremail
            message: "",
            message1: [],
            message2: [],
            message3: [],
            title1: " ",
            title2: " ",
            title3: " ",
            image1: '',
            image2: '',
            image3: '',
            image1Url: '',
            image2Url: '',
            image3Url: '',
            image1FileName: ' ',
            image2FileName: ' ',
            image3FileName: ' ',
            image1FileHandle: '',
            image2FileHandle: '',
            image3FileHandle: '',
            prodesc1: " ",
            prodesc2: " ",
            prodesc3: " "
        };

        //this.getmessageid = this.getmessageid.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.image1FileUploader = this.image1FileUploader.bind(this);
        // this.image2FileUploader = this.image2FileUploader.bind(this);
        // this.image3FileUploader = this.image3FileUploader.bind(this);
        this.getproducts();
    }

    getImage1FileName(id, fileName) {
        var key = id;
        key = key.replace(/,/g, '_');
        key = key.replace(/ /g, '_');
        key = key.replace(/&/g, '_');
        key = key.replace(/\./g, '');
        key = key.replace(/__/g, '_');
        //console.log(key);
        key = key.toLowerCase();

        fileName = fileName.toLowerCase();

        if (fileName.indexOf("jpg") !== -1) {
            key = key + ".jpg";
        }
        else if (fileName.indexOf("gif") !== -1) {
            key = key + ".gif";
        }
        else if (fileName.indexOf("png") !== -1) {
            key = key + ".png";
        }
        else {
            this.state.image1FileName = "Format not supported";
        }

        return key;
    }

    getImage1S3FileName() {
        return this.state.code + "/upload/posts/" + this.state.image1FileName;
    }

    image1FileUploader = (event) => {

        //file preview
        const file = this.refs.uploadImg1.files[0]
        const reader = new FileReader();

        //////console.log("fileUploader");
        reader.onloadend = () => {
            this.setState({
                image1Url: reader.result
            })
        }
        if (file) {
            reader.readAsDataURL(file);
            this.setState({
                image1Url: reader.result
            })
        }
        else {
            this.setState({
                image1Url: " "
            })
        }

        //file handler
        const target = event.target;
        const name = target.name;
        let value = target.value

        if (name === 'image1') {
            this.state.image1FileHandle = event.target.files[0];
            //////console.log("logoFileName = " + this.state.logoFileHandle.name)
            this.state.image1FileName = this.getImage1FileName(this.state.messageid1, this.state.image1FileHandle.name);
        }
        else {
            this.setState(
                {
                    [name]: value
                }
            );
        }

        //file storage
        const s3Video1File = this.getImage1S3FileName();

        ////console.log('file=' +this.refs.uploadImg.files[0].name+", Des ="+s3File);

        Storage.put(s3Video1File, this.state.image1FileHandle,
            {
                contentType: 'image/png'
            })
            .then(
                result => {
                    //////console.log(result);
                    //this.state.message = "logo successfully submitted"

                    this.forceUpdate();
                }
            )
            .catch(err => {
                //////console.log(err)
                this.state.message = "Unable to upload logo. Please try again"
                this.forceUpdate();

            }
            );

    }

    getImage2FileName(id, fileName) {
        var key = id;
        key = key.replace(/,/g, '_');
        key = key.replace(/ /g, '_');
        key = key.replace(/&/g, '_');
        key = key.replace(/\./g, '');
        key = key.replace(/__/g, '_');
        //console.log(key);
        key = key.toLowerCase();

        fileName = fileName.toLowerCase();

        if (fileName.indexOf("jpg") !== -1) {
            key = key + ".jpg";
        }
        else if (fileName.indexOf("gif") !== -1) {
            key = key + ".gif";
        }
        else if (fileName.indexOf("png") !== -1) {
            key = key + ".png";
        }
        else {
            this.state.image2FileName = "Format not supported";
        }

        return key;
    }

    getImage2S3FileName() {
        return this.state.code + "/upload/posts/" + this.state.image2FileName;
    }

    image2FileUploader = (event) => {

        //file preview
        const file = this.refs.uploadImg2.files[0]
        const reader = new FileReader();

        //////console.log("fileUploader");
        reader.onloadend = () => {
            this.setState({
                image2Url: reader.result
            })
        }
        if (file) {
            reader.readAsDataURL(file);
            this.setState({
                image2Url: reader.result
            })
        }
        else {
            this.setState({
                image2Url: " "
            })
        }

        //file handler
        const target = event.target;
        const name = target.name;
        let value = target.value

        if (name === 'image2') {
            this.state.image2FileHandle = event.target.files[0];
            //////console.log("logoFileName = " + this.state.logoFileHandle.name)
            this.state.image2FileName = this.getImage2FileName(this.state.messageid2, this.state.image2FileHandle.name);
        }
        else {
            this.setState(
                {
                    [name]: value
                }
            );
        }

        //file storage
        const s3Video1File = this.getImage2S3FileName();

        ////console.log('file=' +this.refs.uploadImg.files[0].name+", Des ="+s3File);

        Storage.put(s3Video1File, this.state.image2FileHandle,
            {
                contentType: 'image/png'
            })
            .then(
                result => {
                    //////console.log(result);
                    //this.state.message = "logo successfully submitted"

                    this.forceUpdate();
                }
            )
            .catch(err => {
                //////console.log(err)
                this.state.message = "Unable to upload logo. Please try again"
                this.forceUpdate();

            }
            );

    }

    getImage3FileName(id, fileName) {
        var key = id;
        key = key.replace(/,/g, '_');
        key = key.replace(/ /g, '_');
        key = key.replace(/&/g, '_');
        key = key.replace(/\./g, '');
        key = key.replace(/__/g, '_');
        //console.log(key);
        key = key.toLowerCase();

        fileName = fileName.toLowerCase();

        if (fileName.indexOf("jpg") !== -1) {
            key = key + ".jpg";
        }
        else if (fileName.indexOf("gif") !== -1) {
            key = key + ".gif";
        }
        else if (fileName.indexOf("png") !== -1) {
            key = key + ".png";
        }
        else {
            this.state.image3FileName = "Format not supported";
        }

        return key;
    }

    getImage3S3FileName() {
        return this.state.code + "/upload/posts/" + this.state.image3FileName;
    }

    image3FileUploader = (event) => {

        //file preview
        const file = this.refs.uploadImg3.files[0]
        const reader = new FileReader();

        //////console.log("fileUploader");
        reader.onloadend = () => {
            this.setState({
                image3Url: reader.result
            })
        }
        if (file) {
            reader.readAsDataURL(file);
            this.setState({
                image3Url: reader.result
            })
        }
        else {
            this.setState({
                image3Url: " "
            })
        }

        //file handler
        const target = event.target;
        const name = target.name;
        let value = target.value

        if (name === 'image3') {
            this.state.image3FileHandle = event.target.files[0];
            //////console.log("logoFileName = " + this.state.logoFileHandle.name)
            this.state.image3FileName = this.getImage3FileName(this.state.messageid3, this.state.image3FileHandle.name);
        }
        else {
            this.setState(
                {
                    [name]: value
                }
            );
        }

        //file storage
        const s3Video1File = this.getImage3S3FileName();

        ////console.log('file=' +this.refs.uploadImg.files[0].name+", Des ="+s3File);

        Storage.put(s3Video1File, this.state.image3FileHandle,
            {
                contentType: 'image/png'
            })
            .then(
                result => {
                    //////console.log(result);
                    //this.state.message = "logo successfully submitted"

                    this.forceUpdate();
                }
            )
            .catch(err => {
                //////console.log(err)
                this.state.message = "Unable to upload logo. Please try again"
                this.forceUpdate();

            }
            );

    }

    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        let value = target.value

        //////console.log( "Target = " + name + ", Value = " + value);

        this.setState({ [name]: value });
        //this.setState({selectedCategory:value})
    }
    
    validateproductInputs(title, description) {
        //console.log(title + ',' + description)
        if(title != null && description != null && title != "" && description != "") {
            return true
        }
        else {
            this.state.message = "Enter all the required inputs and click submit"
            return false
        }
    }

    handleSubmit = async(event) => {
        event.preventDefault();
        //console.log(global);
        //console.log(this.state);

        this.state.id = global.$exhibitorid
        this.state.receiverid = global.$exhibitorid
        this.state.sname = global.$company
        this.state.senderid = global.$exhibitoremail

        //console.log(this.state);

        var date = new Date();
        var longFormat = date / 1000;
        var messagearr1 = []
        var messagearr2 = []
        var messagearr3 = []
        var message1  = {}
        var message2  = {}
        var message3  = {}

        if (this.validateproductInputs(this.state.title1, this.state.prodesc1)) {
            message1.productid = longFormat.toString()
            message1.title = this.state.title1
            message1.desc = this.state.prodesc1
            //messagearr1.push(message1);
            //console.log(messagearr1)
            //this.setState({ messageid1: longFormat.toString() })
            this.state.message1[0] = message1
            this.forceUpdate()
            //this.setState({ message1: [...messagearr1] })
            //console.log(this.state.message1)
            
            if (this.validateSubmitProduct(this.state.image1FileName,message1.title,message1.desc)) {
                await this.submitProducts(JSON.stringify(this.state.message1), this.state.image1FileName, this.state.messageid1);
            }
            this.setState({ isLoading: true });
        }

        if (this.validateproductInputs(this.state.title2, this.state.prodesc2)) {
            message2.productid = longFormat.toString()
            message2.title = this.state.title2
            message2.desc = this.state.prodesc2
            //messagearr2.push(message2);
            //this.setState({ messageid2: longFormat.toString() })
            //this.setState({ message2: [...this.state.message2, messagearr2] })
            this.state.message2[0] = message2
            this.forceUpdate()
            if (this.validateSubmitProduct(this.state.image2FileName,message2.title,message2.desc)) {
                await this.submitProducts(JSON.stringify(this.state.message2), this.state.image2FileName, this.state.messageid2);
            }
            this.setState({ isLoading: true });
        }

        if (this.validateproductInputs(this.state.title3, this.state.prodesc3)) {
            message3.productid = longFormat.toString()
            message3.title = this.state.title3
            message3.desc = this.state.prodesc3
            //messagearr3.push(message3);
            //this.setState({ messageid3: longFormat.toString() })
            //this.setState({ message3: [...this.state.message3, messagearr3] })
            this.state.message3[0] = message3
            this.forceUpdate()
            if (this.validateSubmitProduct(this.state.image3FileName,message3.title,message3.desc)) {
                await this.submitProducts(JSON.stringify(this.state.message3), this.state.image3FileName, this.state.messageid3);
            }
            this.setState({ isLoading: true });
        }

    }

    async submitProducts(message, imagefilename, messageid) {
        //console.log(this.state);
        //console.log(message)
        let apiName = serviceNames.getMessageService();
        let path = ''; //replace this with the path you have configured on your API
        //var date = new Date();
        //var longFormat = date / 1000;
        //this.setState({ messageid: longFormat.toString() })

        let myInit =
        {
            body:
            {
                "code": this.state.code,
                'command': "postMessage",
                "receiverid": this.state.receiverid,
                "messageid": messageid,
                //"ccount": this.state.ccount,
                "sname": this.state.sname,
                "likes": this.state.likes,
                "image": imagefilename,
                "w": this.state.w,
                "h": this.state.h,
                "senderid": this.state.senderid,
                "message": message,
                //"lcount": this.state.lcount               
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //console.log(myInit)
        API.post(apiName, path, myInit).then(response => {

            //console.log (response);
            this.setState({ disabled: false });

            if (response.err == null) {
                this.state.message = "Product successfully submitted";
                this.setState({ isLoading: false });
                this.copyFiles(imagefilename)
            }

        }).catch(error => {
            console.log(error)
            this.setState({ disabled: false });
        });

    }
    validateSubmitProduct(image,title,desc){
        if (image.trim() != "" || title.trim() != "" || desc.trim() != "") {
            return true
        }
        else return false
    }

    copyFiles(photo) {
        let apiName = serviceNames.getUserService();
        let path = ''; //replace this with the path you have configured on your API

        let myInit =
        {
            body:
            {
                'code': this.state.code,
                'command': 'copyS3Files',
                'srcbucket': 'event360live',
                'desbucket': 'wesicore',
                'fileFrom': 'upload/posts',
                'fileTo': 'upload/posts',
                'photo': photo
            },
            response: true,
            headers: {}

        }
        //console.log(myInit);
        API.post(apiName, path, myInit).then(response => {
            //console.log (response.data)
            //console.log ("response.data ==> " + response.data + "response.err ==> " + response.err);
            if (response.err == null && response.data != null) {
                //console.log("Change Password - Success!!");
                //this.getS3PrivateImg(photo)
            }
            else {
                //console.log("Change Password - failed!!");
            }

        }).catch(error => {
            //console.log(error.response)
        });
    }

    getproducts() {
        this.state.id = global.$exhibitorid
        //console.log(this.state);
        let apiName = serviceNames.getMessageService();
        let path = ''; //replace this with the path you have configured on your API       

        let myInit =
        {
            body:
            {
                "code": this.state.code,
                'command': 'getMessages',
                "receiverid": this.state.id
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        
        //console.log(myInit)
        API.post(apiName, path, myInit).then(response => {

            //console.log (response);
            this.setState({ disabled: false });

            if (response.err == null) {
                var products = response.data.Items;

                if(products.length != 0) {
                    this.setState({ message1: []})
                    this.setState({ message2: []})
                    this.setState({ message3: []})

                    for(let i=0; i<products.length ;i++) {
                        var message = JSON.parse(products[i].message.S)
                        this.state[`title${i+1}`] = message[0].title
                        this.state[`prodesc${i+1}`] = message[0].desc
                        this.setState({ [`image${i+1}FileName`]: products[i].image.S })
                        this.setState({ [`messageid${i+1}`]: products[i].messageid.N.toString() })
                        //this.setState({ [`message${i+1}`] : [...this.state[`message${i+1}`], message ] })
                        this.setState({ [`receiverid${i+1}`] : products[i].receiverid.S })
                        this.setState({ [`message${i+1}`] : message })
                        this.setState({ [`prodesc${i+1}`] : message[0].desc })
                        this.setState({ [`title${i+1}`] : message[0].title })
                        this.setState({ [`image${i+1}Url`] : `https://s3.amazonaws.com/event360live/public/${this.state.code}/upload/posts/${products[i].image.S}` })

                        // if(products[i].image.S.includes('_i1')) {
                        //     this.setState({ [`image1FileName`]: products[i].image.S })
                        //     this.setState({ [`messageid1`]: products[i].messageid.N.toString() })
                        //     this.setState({ [`receiverid1`] : products[i].receiverid.S })
                        //     this.setState({ [`message1`] : message })
                        //     this.setState({ [`prodesc1`] : message[0].desc })
                        //     this.setState({ [`title1`] : message[0].title })
                        //     this.setState({ [`image1Url`] : `https://s3.amazonaws.com/event360live/public/${this.state.code}/upload/posts/${products[i].image.S}` })                            
                        // }

                        // if(products[i].image.S.includes('_i2')) {
                        //     this.setState({ [`image2FileName`]: products[i].image.S })
                        //     this.setState({ [`messageid2`]: products[i].messageid.N.toString() })
                        //     this.setState({ [`receiverid2`] : products[i].receiverid.S })
                        //     this.setState({ [`message2`] : message })
                        //     this.setState({ [`prodesc2`] : message[0].desc })
                        //     this.setState({ [`title2`] : message[0].title })
                        //     this.setState({ [`image2Url`] : `https://s3.amazonaws.com/event360live/public/${this.state.code}/upload/posts/${products[i].image.S}` })                            
                        // }

                        // if(products[i].image.S.includes('_i3')) {
                        //     this.setState({ [`image3FileName`]: products[i].image.S })
                        //     this.setState({ [`messageid3`]: products[i].messageid.N.toString() })
                        //     this.setState({ [`receiverid3`] : products[i].receiverid.S })
                        //     this.setState({ [`message3`] : message })
                        //     this.setState({ [`prodesc3`] : message[0].desc })
                        //     this.setState({ [`title3`] : message[0].title })
                        //     this.setState({ [`image3Url`] : `https://s3.amazonaws.com/event360live/public/${this.state.code}/upload/posts/${products[i].image.S}` })                            
                        // }

                    }
                }

                //console.log(this.state.image1Url);
                //console.log(this.state.message1);
                //console.log(this.state.message2);
                //console.log(this.state.message3);
            }

        }).catch(error => {
            console.log(error)
        });
    }
    generateProductReviewsReport() {
        let apiName = serviceNames.getAnalyticsService();
        let path = ''; //replace this with the path you have configured on your API
        if (global.$variables.s3region == "AP_SOUTH_1") {
            this.state.s3bucket = "wesicorein";
        }
        else {
            this.state.s3bucket = "wesicore";
        }
        let myInit = {
            body:
            {
                'command': 'generateExhibitorProductsReport',
                'receiverid': this.state.receiverid,
                //'userid': this.state.senderid,// this person will recieve the excel report through mail
                'userid': 'customers@apps4org.com',
                'code': this.state.code,
                'bucket': this.state.s3bucket,
                'sendmail': false
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        console.log(myInit);
      
        API.post(apiName, path, myInit).then(response => {
            //console.log("response.data ===> " + response.data + "response.err ===> " + response.err);
            this.setState({ isLoading: false })
            console.log(response);
            if (response.data == null) {
                //console.log(response.data)
                var date = new Date();
                var month = ("0" + (date.getMonth() + 1)).slice(-2);
                var day = ("0" + date.getDate()).slice(-2);
                date = [month, day, date.getFullYear()].join("-");
      
                var filepath = `https://event360live.s3.amazonaws.com/public/${this.state.code}/generated/exhibitors/${this.state.code}_exhibitor_productreviews_${date}.xls`;
      
                //https://wesicore.s3.amazonaws.com/event360/metro2021conference/export/metro2021conference_signups_1618398025.755.tsv
                //var filepath = `s3://event360live/public/${this.state.code}/badges/${userid}_badge.pdf` 
                console.log(filepath);
                window.open(filepath);
                // setTimeout(() => {
                //     this.deleteGeneretedFileFromS3(date);
                // }, 60000)
            }
            else {
                //console.log("getAttendeesTableObjects - failed!!");
                //console.log(response.err);
      
                if (response.err == null && response.data != null) {
      
                    var filepath = response.data.data.url;
      
                    setTimeout(() => {
                        window.open(filepath);
                    }, 8000)
      
                    // setTimeout(() => {
                    //     this.deleteFileFromS3(response.data.data.key);
                    // }, 60000)
                }
      
            }
        }
        ).catch(error => {
            //console.log(error.response)
        });
      }
}    


export default Products;