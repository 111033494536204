import React from 'react';
import { Col, Row} from 'reactstrap';
import {API, Analytics} from 'aws-amplify';
import ReactHtmlParser from 'react-html-parser';
import Sponsors from '../Components/Sponsors';
import commonProfile from '../images/common_profile.png';

class SpeakerInfo extends React.Component  {
  constructor(props) {

      var [name, backroute]= props.match.params.value.split("==")
     //console.log(props.match.params.value)
    super(props);
    this.state = {
      code: global.$code,
      s3: global.$s3,
      bucket: global.$s3bucket,
      name: name,
      title: '',
      photo: '',
      desc: '',
      phone: '',
      email: '',
      company: '',
      backbuttonlink: "/"+backroute,
      backbuttonstyle: global.$settings.styles.backbutton,
      speakerbannerstyle: global.$speakers.styles.speakerinfo.speakerbanner,
      speakernamestyle: global.$speakers.styles.speakerinfo.speakername,
      speakertitlestyle: global.$speakers.styles.speakerinfo.speakertitle,
      speakerimagestyle: global.$speakers.styles.speakerinfo.sepakerimage,
      speakerdescstyle: global.$speakers.styles.speakerinfo.speakerdesc,
      dataloaded: false
     };
    //console.log(global.$speakers)
  }
  
componentDidMount() {
    window.scrollTo(0, 0)
  }
  
 render(){

   //console.log("SpeakerInfo: render")

    Analytics.record({ name: 'PageView', attributes: { name: 'eventsites-'+this.state.code+'-speakerinfo'}});
    if(this.state.dataloaded)
    {
        return (
          <div>
              <div>
                  {this.getSpeakerBanner()}
              </div>
              <div>
                  <div>
                      { this.getSpeakerInfo() }
                  </div>
              </div>
              {
                global.$speakers.sponsors.enable.includes('Y')?
                (
                    <div>
                        <br/>
                        <br/>
                        <Sponsors />
                        <br/>
                        <br/>
                        <br/>
                    </div>
                )
            :
            ('')                
            }
          </div>  
        );
    }
    else
    {
        this.getSpeakerData();
        return(
            <div>
                <Row>
                <Col md={6} className="offset-5">
                    <div className="loading-div">
                        <div className="loading-text">Loading....</div>
                        {/*<ReactSpinner/>*/}
                    </div>
                </Col>
            </Row>
            </div>
        )
    }
 }

getSpeakerData()
{
    var speakerObj = global.$speakerjson.find( globalSpeaker => globalSpeaker.NAME === this.state.name )

  //console.log(speakerObj);
    this.setState({title : speakerObj.TITLE})
    this.setState({photo : speakerObj.PHOTO})
    this.setState({desc : speakerObj.DESCRIPTION})
   //console.log(this.state);
    this.setState({dataloaded :true})
}

getSpeakerBanner()
{
  //console.log(this.state.photo);
    //var speaker = this.state.speakerInfo;
    //console.log(speaker);
    if(this.state.photo == "profile.png" || this.state.photo == " " || this.state.photo == "")
    {
        var image = commonProfile;
    }
    else
    {
        var image = `https://s3.amazonaws.com/event360live/public/${this.state.code}/speakers/`+this.state.photo
    }
    return(
        <div>
            <Row style={this.state.speakerbannerstyle}>
              <Col md={3} className="offset-md-2">
                    <br></br>             <br></br>
                    <img src={image} style={this.state.speakerimagestyl} className="info-img"/>
                    <br></br>             <br></br>
                </Col>
                <Col md={6}>
                    <div style={this.state.speakernamestyle}>{this.state.name}</div>
                    <div style={this.state.speakertitlestyle}>{this.state.title}</div>
                </Col>
            </Row>
        </div>
    )
}
getSpeakerInfo()
{
    return(
      <div className="speaker-info">
         <div>    
            <Row>    
              <Col md={8} className="offset-md-2">
                   <p style={this.state.speakerdescstyle}>{ReactHtmlParser(this.state.desc)}</p>
              </Col>
            </Row>
         </div>
      </div>
      );
};

}

export default SpeakerInfo;