import React from 'react';
import { API } from 'aws-amplify';
import { Row, Col, Button } from 'reactstrap';
import './Appointments.css';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Icon from '@mdi/react';
import { mdiDeleteForever, mdiCalendar } from '@mdi/js';
import { Link } from 'react-router-dom';
import AddToCalendar from 'react-add-to-calendar';
var serviceNames = require('../ServiceNameMapper');

const moment = require('moment-timezone');
var timer = '';
let order = 'desc';
class AppointmentsList extends React.Component {
    constructor(props) {
        const displayName = 'Example';

        super(props);
        var attendeetype = '';
        if (global.$attendeetype == null) {
            //console.log("null")
            attendeetype = "Attendee"
        }
        else {
            attendeetype = global.$attendeetype
        }

        this.state = {
            code: global.$code,
            s3: global.$s3,
            bucket: global.$s3bucket,
            appointments: '',
            showtable: true,
            attendeetype: attendeetype
        };
        timer = setInterval(() => {
            this.getAppointments()
        }, 1000)
        this.idFormatter = this.idFormatter.bind(this);
    }
    deleteAppointment(exhibitor) {
        let apiName = serviceNames.getAppointmentService();
        let path = ''; //replace this with the path you have configured on your API
        var cancelfrom = ' ';
        if(global.$attendeetype.includes("Delegate"))
        {
            cancelfrom = global.$userid;
        }
        if(global.$attendeetype.includes("Exhibitor"))
        {
            cancelfrom = exhibitor.userid.S;
        }
        let myInit =
        {
            body:
            {
                'code': this.state.code,
                'command': "addAppointment",
                'id': exhibitor.id.S,
                'begintime': exhibitor.begintime.S,
                'endtime': exhibitor.endtime.S,
                'ownerid': exhibitor.ownerid.S,
                'ownername': exhibitor.ownername.S,
                'userid': " ",
                'username': " ",
                'status': "A",
                'meeting': exhibitor.meeting.S,
                'notes': exhibitor.notes.S,
                'data1': exhibitor.data1.S,
                'data2': exhibitor.data2.S,
                'data3': exhibitor.data3.S,
                "appointmentstatus": "cancel",
                "cancelfrom": cancelfrom
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //console.log(myInit);
        API.post(apiName, path, myInit).then(response => {
            ////console.log (response.data);
            this.getAppointments();
            this.setState({ dataloaded: true });
        }
        ).catch(error => {
            ////console.log(error.response)
        });
    }

    idFormatter(cell) {
        //console.log(cell);
        var bdate = moment(cell.begintime.S).format();
        var edate = moment(cell.endtime.S).format();
        //console.log(bdate,edate);
        let icon = { 'calendar-plus-o': 'left' };
        let items = [
            { apple: 'Apple' },
            { google: 'Google' },
            { outlook: 'Outlook' },
            { yahoo: 'Yahoo!' }
        ];
        var calender = {
            title: 'Appointment',
            description: cell.notes.S,
            location: global.$settings.timezone,
            startTime: bdate,
            endTime: edate
        }
        //console.log(calender);
        return (<div>
            <Row>
                <Col md={3}>
                    <Link to="#" onClick={() => { if (window.confirm(`Do you wish to cancel this appointment?`)) { this.deleteAppointment(cell) }; }}><Icon path={mdiDeleteForever} size={1} color="gray" /></Link>
                </Col>
                <Col md={9}>
                    <AddToCalendar
                        event={calender}
                        buttonLabel=""
                        buttonTemplate={icon}
                        listItems={items}
                        displayItemIcons={false}>
                    </AddToCalendar>
                </Col>
            </Row>
        </div>)
    }
    handleBtnClick = () => {
        if (order === 'desc') {
            this.refs.table.handleSort('asc', 'name');
            order = 'asc';
        } else {
            this.refs.table.handleSort('desc', 'name');
            order = 'desc';
        }
    }

    renderPaginationShowsTotal(start, to, total) {
        return (
            <p style={{ color: 'blue' }}>
                From { start} to { to}, total is { total}
            </p>
        );
    }

    render() {
        const options =
        {
            paginationShowsTotal: this.renderPaginationShowsTotal,
            exportCSVText: 'Download',
            sizePerPageList: [{
                text: '10', value: 10
            }, {
                text: '25', value: 25
            }, {
                text: '50', value: 50
            }, {
                text: '100', value: 100
            }, {
                text: 'All', value: this.state.appointments.length
            }], // you can change the dropdown list for size per page
            sizePerPage: 10,  // which size per page you want to locate as default

        }

        if (this.state.dataloaded) {
            //console.log(this.state.attendeetype, this.state.showtable)


            return (
                <div className="appointment-div" >
                    <div>
                        <br />
                        <div className="appointments-header">
                            Available Appointments
                            <hr className="appointment-hr" />
                        </div>
                        <Row>
                            <Col md={10} className="offset-md-1">
                                {
                                    this.state.showtable ?
                                        (
                                            <div>
                                                {
                                                    this.state.attendeetype.includes("Exhibitor") ?
                                                        (
                                                            <BootstrapTable
                                                                tableContainerClass="data-table"
                                                                data={this.state.appointments}
                                                                exportCSV={true}
                                                                options={options}
                                                                search={true}
                                                                multiColumnSearch={true}
                                                                hover
                                                                condensed
                                                                pagination
                                                                striped>
                                                                <TableHeaderColumn width="150px" dataField='id' isKey={true} dataFormat={this.idFormatter} thStyle={{ 'fontWeight': 'bold' }} headerAlign='center'>Actions</TableHeaderColumn>
                                                                <TableHeaderColumn width="200px" dataField='username' thStyle={{ 'fontWeight': 'bold' }} headerAlign='center'>Name</TableHeaderColumn>
                                                                <TableHeaderColumn width="200px" dataField='userid' dataSort={true} thStyle={{ 'fontWeight': 'bold' }} headerAlign='center' columnTitle>Email</TableHeaderColumn>
                                                                <TableHeaderColumn width="200px" dataField='begintime' dataSort={true} thStyle={{ 'fontWeight': 'bold' }} headerAlign='center' columnTitle>Begin Time</TableHeaderColumn>
                                                                <TableHeaderColumn width="200px" dataField='endtime' dataSort={true} thStyle={{ 'fontWeight': 'bold' }} headerAlign='center' columnTitle>End Time</TableHeaderColumn>
                                                                <TableHeaderColumn width="200px" dataField='meeting' dataSort={true} thStyle={{ 'fontWeight': 'bold' }} tdStyle={{'overflow': 'hidden'}} headerAlign='center' columnTitle>Meeting URL</TableHeaderColumn>
                                                            </BootstrapTable>

                                                        )
                                                        :
                                                        (
                                                            <BootstrapTable
                                                                tableContainerClass="data-table"
                                                                data={this.state.appointments}
                                                                options={options}
                                                                exportCSV={true}
                                                                search={true}
                                                                multiColumnSearch={true}
                                                                hover
                                                                condensed
                                                                pagination
                                                                striped>
                                                                <TableHeaderColumn width="170px" dataField='id' isKey={true} dataFormat={this.idFormatter} thStyle={{ 'fontWeight': 'bold' }} headerAlign='center'>Actions</TableHeaderColumn>
                                                                <TableHeaderColumn width="200px" dataField='ownername' thStyle={{ 'fontWeight': 'bold' }} headerAlign='center'>Name</TableHeaderColumn>
                                                                <TableHeaderColumn width="200px" dataField='ownerid' dataSort={true} thStyle={{ 'fontWeight': 'bold' }} headerAlign='center' columnTitle>Email</TableHeaderColumn>
                                                                <TableHeaderColumn width="200px" dataField='begintime' dataSort={true} thStyle={{ 'fontWeight': 'bold' }} headerAlign='center' columnTitle>Begin Time</TableHeaderColumn>
                                                                <TableHeaderColumn width="200px" dataField='endtime' dataSort={true} thStyle={{ 'fontWeight': 'bold' }} headerAlign='center' columnTitle>End Time</TableHeaderColumn>
                                                                <TableHeaderColumn width="200px" dataField='meeting' dataSort={true} thStyle={{ 'fontWeight': 'bold' }} tdStyle={{'overflow': 'hidden'}} headerAlign='center' columnTitle>Meeting URL</TableHeaderColumn>
                                                            </BootstrapTable>
                                                        )
                                                }
                                            </div>
                                        )
                                        :
                                        (
                                            <dvi className="appointments-no-data offset-md-5">
                                                You don't have any appointments
                                            </dvi>
                                        )
                                }
                            </Col>
                        </Row>

                    </div>
                </div>
            )
        }
        else {
            this.getAppointments();
            return (
                <div>
                    <Row>
                        <Col md={6} className="offset-5">
                            <div className="loading-div">
                                <div className="loading-text">Loading....</div>
                            </div>
                        </Col>
                    </Row>
                </div>
            )
        }
    }

    getAppointmentTableObjects(objs) {
        //console.log(objs);
        const tableObjs = []

        for (var i = 0; i < objs.length; i++) {
            var Obj = objs[i]
            //console.log(Obj);
            var newObj = Object()

            //console.log(this.state.attendeetype);
            ///console.log(global.$attendeetype);
            //console.log(Obj.ownerid.S, global.$userid)
            if (this.state.attendeetype.includes("Exhibitor")) {
                if (Obj.ownerid.S == global.$userid) {
                    //console.log("exhibitor")
                    this.state.showtable = true;

                    newObj.id = Obj
                    newObj.begintime = Obj.begintime.S
                    newObj.endtime = Obj.endtime.S
                    newObj.userid = Obj.userid.S
                    newObj.username = Obj.username.S
                    newObj.meeting = Obj.meeting.S
                    tableObjs.push(newObj);
                    //console.log(tableObjs);
                }
            }
            else if (this.state.attendeetype.includes("Attendee")) {
                //console.log("delegate")
                if (Obj.userid.S == global.$userid) {
                    //console.log("delegate")
                    if (Obj.status.S == "R") {
                        this.state.showtable = true;
                        newObj.id = Obj
                        newObj.begintime = Obj.begintime.S
                        newObj.endtime = Obj.endtime.S
                        newObj.ownerid = Obj.ownerid.S
                        newObj.ownername = Obj.ownername.S
                        newObj.meeting = Obj.meeting.S
                        tableObjs.push(newObj);
                    }
                }

            }
            if (tableObjs == "[]") {
                this.state.showtable = false;
            }
        }
        return tableObjs;
    }

    getAppointments() {
        let apiName = serviceNames.getAppointmentService();
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'code': this.state.code,
                'command': 'getAllAppointments',
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //console.log(myInit);

        API.post(apiName, path, myInit).then(response => {
            //console.log ("response.data ===> " + response.data + "response.err ===> " + response.err);
            //console.log(response);

            if (response.err == null && response.data != null) {
                //console.log(response.data);
                var Appointments = this.getAppointmentTableObjects(response.data.Items);
                //console.log(Appointments);

                this.setState({
                    appointments: Appointments,
                    dataloaded: true
                });
            }
            else {
                ////console.log("getAttendeesTableObjects - failed!!");
                //console.log(response.err);
            }
        }
        ).catch(error => {
            //console.log(error.response)
        });

    }

}
export default AppointmentsList;


