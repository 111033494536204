import React from 'react';
import { API } from 'aws-amplify';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import { Row, Col, Button } from 'reactstrap';
import LoadPrivateImg from '../Components/Shared/LoadPrivateImg';
import UpdatePoints from '../Points/UpdatePoints';
var serviceNames = require('../ServiceNameMapper');

const styles = {
  getEmojiButton: {
    border: "none",
    margin: 0,
    cursor: "pointer"
  },
  emojiPicker: {

  }
};

class ChatInput extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      code: this.props.code,
      sessionid: this.props.sessionid,
      chatInput: '',
      showEmojis: false,
      sendbuttonstyle: global.$chat.styles.sendbutton,
      chatinputstyle: global.$chat.styles.chatinputmaindiv,
      textareastyle: global.$chat.styles.textarea,
      updatepoints: false
    };

    // React ES6 does not bind 'this' to event handlers by default
    this.submitHandler = this.submitHandler.bind(this);
    this.textChangeHandler = this.textChangeHandler.bind(this);
  }

  submitHandler(event) {

    // Stop the form from refreshing the page on submit
    event.preventDefault();

    // Clear the input box
    this.setState({ chatInput: '' });

    // Call the onSend callback with the chatInput message
    //this.props.onSend(this.state.chatInput);

    this.postMessage();
  }

  textChangeHandler(event) {
    this.setState({ chatInput: event.target.value });
  }

  addEmoji = e => {
    let sym = e.unified.split('-')
    let codesArray = []
    sym.forEach(el => codesArray.push('0x' + el))
    let emoji = String.fromCodePoint(...codesArray)
    this.setState({
      chatInput: this.state.chatInput + emoji
    })
  }

  showEmojis = e => {
    this.setState(
      {
        showEmojis: true
      },
      () => document.addEventListener("click", this.closeMenu)
    );
  };

  closeMenu = e => {
    //console.log(this.emojiPicker);
    if (this.emojiPicker !== null && !this.emojiPicker.contains(e.target)) {
      this.setState(
        {
          showEmojis: false
        },
        () => document.removeEventListener("click", this.closeMenu)
      );
    }
  };

  render() {
    return (
      <div>
        <form className="chat-input" style={this.state.chatinputstyle}>
          <div id="error_message"></div>
          <Row className="chat-input-div">
            <Col md={1}>
              <LoadPrivateImg id={global.$loginid} name={global.$fname} component="chat" code={global.$code} height="40" width="40" />
            </Col>
            <Col md={9}>
              <textarea
                className="chat-input-textarea"
                style={this.state.textareastyle}
                rows="1"
                onChange={this.textChangeHandler}
                value={this.state.chatInput}
                placeholder="Enter a message..."
                required
              ></textarea>
            </Col>
            <Col md={1}>
              <Button type="submit" className="chat-input-send" onClick={this.submitHandler} style={this.state.sendbuttonstyle}>Send</Button>
            </Col>
          </Row>
          {
            this.state.code != "afpikar2021" ?
              (
                <Row>
                  <Col md={1}>
                    <div className="emoji-picker">
                      {this.state.showEmojis ? (
                        <span style={styles.emojiPicker} ref={el => (this.emojiPicker = el)}>
                          <Picker
                            onSelect={this.addEmoji}
                            emojiTooltip={true}
                            title="Apps4org"
                            className="emoji"
                            disableSearchBar={true}
                          />
                        </span>
                      ) : (
                        <p style={styles.getEmojiButton} onClick={this.showEmojis}>
                          {String.fromCodePoint(0x1f60a)}
                        </p>
                      )}
                    </div>
                  </Col>
                </Row>
              )
              :
              ('')
          }
        </form>
        {
          this.state.updatepoints ?
            (
              <div>
                {
                  global.$points.sessionqa != null && global.$points.sessionqa.enable.includes("Y") ?
                    (
                      <UpdatePoints session={global.$sessionobj} component="session" area="sessionqa" points={global.$points.sessionqa.points} />
                    )
                    :
                    ('')
                }
              </div>
            )
            :
            ('')
        }
      </div>
    );
  } //render

  postMessage() {
    let apiName = serviceNames.getMessageService();
    let path = '';

    var date = new Date()
    var timestamp = date / 1000

    //this.setState({ showSpinner: true })
    //console.log("input="+this.state.chatInput+"value")

    this.state.chatInput = this.state.chatInput.trim();

    if (this.state.chatInput == " " || this.state.chatInput == "" || this.state.chatInput == "  ") {
      document.getElementById("error_message").innerHTML = "<p style='text-align: center; color: red'></p>"
    }
    else {
      var str = this.state.chatInput;
      str = str.replace(/(?:\r\n|\r|\n)/g, '<br>');
      //console.log(str);
      let myInit =
      {
        body:
        {
          'code': this.state.code,
          'command': 'postMessage',
          'messageid': timestamp.toString(),
          'sname': global.$attendeeDetails.fname + " " + global.$attendeeDetails.lname,
          'senderid': global.$attendeeDetails.userid,
          'receiverid': this.state.sessionid,
          'message': str,
          'w': '0',
          'h': '0',
          'image': '-1'
        },
        response: true,
        headers: {}
      }

      //console.log(myInit);
      API.post(apiName, path, myInit).then(response => {

        //console.log(response);

        if (response.err == null) {

          // Call the onSend callback with the chatInput message
          //console.log('ChatInput: calling onSend');
          //this.props.onSend(this.state.chatInput);
          this.setState({ updatepoints: true });
          this.props.onSend();
        }
        else {

          //console.log(response.err)
          /*
          this.setState({ 
              showSpinner: false,
              message: "Unable to place your bid. Please try again",
              bidmessagecolor:'color: red'
          });
          */
        }

      }
      ).catch(error => {
        /*
        this.setState({ showSpinner: false,
        message: "Unable to place your bid. Please try again",
        bidmessagecolor:'color: red'});
        */
        //console.log(error);
      });
    }
  }
}

/*
ChatInput.defaultProps = {

};
*/

export default ChatInput;