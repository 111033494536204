import React from 'react';
import { Row, Col, Input } from 'reactstrap';
import { Collapse, Button, CardBody, Card } from 'reactstrap';
import Icon from '@mdi/react';
import { mdiArrowDownDropCircleOutline,mdiArrowUpDropCircleOutline } from '@mdi/js';

import CommonFunctions from '../RegCommon/CommonFunctions';
import RegItemContainer from './RegItemContainer';

var moment = require('moment-timezone');



class RegOptionsSection extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            formtype: this.props.formtype,
            tabid:this.props.tabid,
            groupid: this.props.groupid,
            groupname: '',
            collapse: true,
            status:''
        }
        this.refreshComponent = this.refreshComponent.bind(this);
        this.onEntering = this.onEntering.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    refreshComponent() {
        this.props.refreshComponent();
    }

    toggle() {
        this.setState({ collapse: !this.state.collapse });
    }
    onEntering() {
        this.setState({ status: 'Opening' });
    }
    onExiting() {
        this.setState({ status: "Closing" });
    }

    render() {
       //console.log("regoptions section")
        var groupTitle = this.getGroupTitle()
        // var total = 0

        if (this.isSectionEnabled()) {
            return (
                <>
                    <h4 
                        className="reg-option-section reg-option-section-title" 
                        onClick={this.toggle} 
                        style={global.$registrationform.styles.regoptions.groupheader}>
                        {groupTitle} {this.state.status === "Closing" ? <Icon path={mdiArrowDownDropCircleOutline} size={1} /> : <Icon path={mdiArrowUpDropCircleOutline} size={1} /> }
                    </h4>
                    <Collapse 
                        isOpen={this.state.collapse}
                        onEntering={this.onEntering}
                        onExiting={this.onExiting}
                    >
                        <Card className="reg-collapse-card" style={global.$registrationform.styles.regoptions.maindiv}>
                            <CardBody className="reg-collapse-card-body">
                                {this.renderSectionDesc()}
                                {this.renderRegItemColumnHeaders()}
                                {this.renderRegItemContainer()}
                            </CardBody>
                        </Card>
                    </Collapse>
                </>
            )
        }
        else {
            return ("")
        }
    }

    /*
        config.json:
        "groupnames": "names#:one:Booth Choice;three:Additional Sponsor Options;four:Specialty Booths;five:Additional Representative Badges",
        "grouptitles": "titles#:one:Booth Choice;three:Additional Sponsorship Options;four:Specialty Booths;five:Additional Representative(s)/Attendee(s)",
    */

    isSectionEnabled() {
       //console.log(global.$registrationform.setup[this.state.formtype]);
        var groupnames = global.$registrationform.setup[this.state.tabid].groupnames;
        var groupname = CommonFunctions.getValueFromMeCard(groupnames, this.state.groupid);
       //console.log(groupname)
        if (groupname == '') {
            return false
        }
        else {
            this.state.groupname = groupname;
            return true
        }
    }

    getGroupTitle() {
        var grouptitles = global.$registrationform.setup[this.state.tabid].grouptitles;
        var title = CommonFunctions.getValueFromMeCard(grouptitles, this.state.groupid);
        return title
    }

    renderSectionDesc() {

        var sectionid = CommonFunctions.covertGroupIDToSectionID(this.state.groupid)
        var tagname = sectionid + 'desc'

        var cfg = global.$registrationform.setup[this.state.tabid];
        var description = cfg[tagname]

        return (
            <div dangerouslySetInnerHTML={{ __html: description }} className="reg-option-description"></div>
        )
    }

    renderRegItemColumnHeaders() {

        var headers = global.$registrationform.setup[this.state.tabid].headers;
        var header1 = CommonFunctions.getValueFromMeCard(headers, 'header1');
        var header2 = CommonFunctions.getValueFromMeCard(headers, 'header2');
        var header3 = CommonFunctions.getValueFromMeCard(headers, 'header3');

        return (
            <Row className="reg-options-table-header" style={global.$registrationform.styles.regoptions.tableheaders}>
                <Col md={6}><b>{header1}</b></Col>
                <Col md={3}><b>{header2}</b></Col>
                <Col md={3} className="reg-options-table-header3"><b>{header3}</b></Col>
            </Row>
        )
    }

    
    
    renderRegItemContainer() {
        // Get all the regitems for the current group "1509290"
        
        var groupregitems = CommonFunctions.getRegItemsForGroup(this.state.groupname);

        return groupregitems.map(regitem => {
            if (!regitem.hidden) {
                return (
                    <RegItemContainer 
                        key={regitem.data5} 
                        regitem={regitem} 
                        itemid={regitem.data5} 
                        groupname={this.state.groupname} 
                        formtype={this.props.formtype} 
                        tabid={this.props.tabid}
                        conditionalRegitems={this.props.conditionalRegitems}
                        refreshComponent={this.refreshComponent}
                    />
                )
            }
        })
    }

    

    findMatchingObject(firstArray, secondArray) {
        for (const valueToMatch of firstArray) {
          const matchedObject = secondArray.find((obj) => {
            if (obj.inputtype === "dropdown") {
                obj.selecteddropdown.map((subitem) => {
                    
                })
            }
            else if (obj.data5 === valueToMatch) {
                return obj
            }
          });
          if (matchedObject) {
            return matchedObject;
          }
        }
        return null; // If no match is found
      }
}


export default RegOptionsSection;

/*
    # webconfig - hideregitems = ['12343','54643556','5683463'];

    # webconfig - conditionRegitems =  [
        {
            id:"1000",
            selectedRegitems:['1343','43454'],
            showingregitems:['12343'],
            hideregitems:['45441']
            condition:'&&'
        },
        {
            id:"1001",
            selectedRegitems:['1343,34311'],
            showingregitems:['12343'],
            condition:'||'
        }
    ]

    # show without hideregitems

    # selecte regitem
        - includes conditionRegitems.selectedRegitems 
        - check the conditionRegitems.condition
        - true ( showing the regitems)

*/