import EventcodeFunctions from "./EventcodeFunctions";
var commonConstants = {
    "platinumboothtitle": "Platinum Booth Registration",
    "doubleboothtitle": "Double Booth Registration",
    "singleboothtitle": "Single Booth Registration",
    "eventvendortitle": "Event Vendor Registration",
    "eventvendorplatinumtitle": "Event Vendor + Platinum Booth Bundle Registration",
    "eventvendorplatinumdoubletitle": "Event Vendor + Platinum Double Booth Bundle Registration",
    "eventvendorplatinumtripletitle": "Event Vendor + Platinum Triple Booth Bundle Registration",
    "eventvendorplatinumquadtitle": "Event Vendor + Platinum Quad Booth Bundle Registration",
    "eventvendorplatinumhexatitle": "Event Vendor + Platinum Hexa Booth Bundle Registration",
    "eventvendorhexatitle": "Event Vendor + Hexa Booth Bundle Registration",
    "eventvendorquadtitle": "Event Vendor + Quad Booth Bundle Registration",
    "eventvendortripletitle": "Event Vendor + Triple Booth Bundle Registration",
    "eventvendordoubletitle": "Event Vendor + Double Booth Bundle Registration",
    "eventvendorsingletitle": "Event Vendor + Single Booth Bundle Registration",
    "platinumquadboothtitle": "Platinum Quad Booth Registration",
    "platinumdoubleboothtitle": "Platinum Double Booth Registration",
    "platinumtripleboothtitle": "Platinum Triple Booth Registration",
    "platinumhexaboothtitle": "Platinum Hexa Booth Registration",
    "tripleboothtitle": "Triple Booth Registration",
    "quadboothtitle": "Quad Booth Registration",
    "hexaboothtitle": "Hexa Booth Registration",
    "afterparty": "TravCon AfterParty Tickets",
    "medicalspanishwed": "Medical Spanish Bundle - 'Wed Post Con'",
    "medicalspanishsun": "Medical Spanish Bundle - 'Sunday Pre-Con'",
    "travcon2023medicalspanishwed": "Medical Spanish Bundle 'Wed Post Con'",
    "travcon2023medicalspanishsun": "Medical Spanish Bundle 'Sunday Pre Con'",    
    "travcon2024medicalspanishwed": "Medical Spanish Bundle 'Wed Post Con'",
    "travcon2024medicalspanishsun": "Medical Spanish Bundle 'Sunday Pre Con'",    
    "newbiebootcamp": "Newbie BootCamp Bundle",
    "mainstageticket": "Main Stage Ticket",
    "boothdowngradeerrormessage": "Booth downgrade is not allowed after purchase. Please update your booth choice and try again.",
    "attendeedowngradeerrormessage": "Downgrade is not allowed after purchase. Please try again."
}
var travconExhibitor2022RegItems = {
    "platinumbooth": "Platinum Booth Registration - $25000",
    "doublebooth": "Double Booth Registration - $13000",
    "singlebooth": "Single Booth Registration - $6500",
    "eventvendor": "Event Vendor Registration - $6500",
    "eventvendorplatinum": "Event Vendor + Platinum Booth Bundle Registration - $28000",
    "eventvendorplatinumdouble": "Event Vendor + Platinum Double Booth Bundle Registration - $32000", // Not using
    "eventvendorplatinumtriple": "Event Vendor + Platinum Triple Booth Bundle Registration - $37000", // Not using
    "eventvendorplatinumquad": "Event Vendor + Platinum Quad Booth Bundle Registration - $42000", // Not using
    "eventvendorplatinumhexa": "Event Vendor + Platinum Hexa Booth Bundle Registration - $51000", // Not using
    "eventvendortriple": "Event Vendor + Triple Booth Bundle Registration - $24000", // Not using
    "eventvendorquad": "Event Vendor + Quad Booth Bundle Registration - $28000", // Not using
    "eventvendorhexa": "Event Vendor + Hexa Booth Bundle Registration - $37500", // Not using
    "eventvendordouble": "Event Vendor + Double Booth Bundle Registration - $16250",
    "eventvendorsingle": "Event Vendor + Single Booth Bundle Registration - $9500",
    "platinumquadbooth": "Platinum Quad Booth Registration - $34000",
    "platinumdoublebooth": "Platinum Double Booth Registration - $29000",
    "platinumtriplebooth": "Platinum Triple Booth Registration - $30000",
    "platinumhexabooth": "Platinum Hexa Booth Registration - $42000",
    "triplebooth": "Triple Booth Registration - $18000",
    "quadbooth": "Quad Booth Registration - $22000",
    "hexabooth": "Hexa Booth Registration - $30000",
    "afterparty": "TravCon AfterParty Tickets",
    "maxplatinumboothcount": "11",
    "eventvendortitle": "Event Vendor",
    "deposit": 2900
}
var travconExhibitor2023RegItems = {
    "platinumbooth": "Platinum Booth Registration - $25000", // Not using
    "doublebooth": "Double Booth Registration - $15000",
    "singlebooth": "Single Booth Registration - $7500",
    "eventvendor": "Event Vendor Registration - $7500",
    "eventvendorplatinum": "Event Vendor + Platinum Booth Bundle Registration - $11000", // Not using
    "eventvendorplatinumdouble": "Event Vendor + Platinum Double Booth Bundle Registration - $32000",
    "eventvendorplatinumtriple": "Event Vendor + Platinum Triple Booth Bundle Registration - $37000",
    "eventvendorplatinumquad": "Event Vendor + Platinum Quad Booth Bundle Registration - $42000",
    "eventvendorplatinumhexa": "Event Vendor + Platinum Hexa Booth Bundle Registration - $51000",
    "eventvendortriple": "Event Vendor + Triple Booth Bundle Registration - $24000",
    "eventvendorquad": "Event Vendor + Quad Booth Bundle Registration - $28000",
    "eventvendorhexa": "Event Vendor + Hexa Booth Bundle Registration - $37500",
    "eventvendordouble": "Event Vendor + Double Booth Bundle Registration - $18250",
    "eventvendorsingle": "Event Vendor + Single Booth Bundle Registration - $11000",
    "platinumdoublebooth": "Platinum Double Booth Registration - $29000",
    "platinumtriplebooth": "Platinum Triple Booth Registration - $34000",
    "platinumquadbooth": "Platinum Quad Booth Registration - $39000",
    "platinumhexabooth": "Platinum Hexa Booth Registration - $48000",
    "triplebooth": "Triple Booth Registration - $21000",
    "quadbooth": "Quad Booth Registration - $25000",
    "hexabooth": "Hexa Booth Registration - $34500",
    "afterparty": "TravCon AfterParty Tickets",
    "maxplatinumboothcount": "10",
    "eventvendortitle": "Event Vendor",
    "deposit": 3500
}
var travconExhibitor2024RegItems = {
    "platinumbooth": "Platinum Booth Registration - $25000", // Not using
    "doublebooth": "Double Booth Registration - $15000",
    "singlebooth": "Single Booth Registration - $7500",
    "eventvendor": "Event Vendor Registration - $7500",
    "eventvendorplatinum": "Event Vendor + Platinum Booth Bundle Registration - $11000", // Not using
    "eventvendorplatinumdouble": "Event Vendor + Platinum Double Booth Bundle Registration - $32000",
    "eventvendorplatinumtriple": "Event Vendor + Platinum Triple Booth Bundle Registration - $37000",
    "eventvendorplatinumquad": "Event Vendor + Platinum Quad Booth Bundle Registration - $42000",
    "eventvendorplatinumhexa": "Event Vendor + Platinum Hexa Booth Bundle Registration - $51000",
    "eventvendortriple": "Event Vendor + Triple Booth Bundle Registration - $24000",
    "eventvendorquad": "Event Vendor + Quad Booth Bundle Registration - $28000",
    "eventvendorhexa": "Event Vendor + Hexa Booth Bundle Registration - $37500",
    "eventvendordouble": "Event Vendor + Double Booth Bundle Registration - $18250",
    "eventvendorsingle": "Event Vendor + Single Booth Bundle Registration - $11000",
    "platinumdoublebooth": "Platinum Double Booth Registration - $29000",
    "platinumtriplebooth": "Platinum Triple Booth Registration - $34000",
    "platinumquadbooth": "Platinum Quad Booth Registration - $39000",
    "platinumhexabooth": "Platinum Hexa Booth Registration - $48000",
    "triplebooth": "Triple Booth Registration - $21000",
    "quadbooth": "Quad Booth Registration - $25000",
    "hexabooth": "Hexa Booth Registration - $34500",
    "afterparty": "TravCon AfterParty Tickets",
    "maxplatinumboothcount": "10",
    "eventvendortitle": "Event Vendor",
    "deposit": 3500
}

var travcon2022exhibitorreps = {
    "platinumhexareps": 16,
    "platinumquadreps": 13,
    "platinumtriplereps": 11,
    "platinumdoublereps": 0, // this is not used
    "platinumreps": 10,
    "hexareps": 12,
    "quadreps": 9,
    "triplereps": 7,
    "doublereps": 5,
    "singlereps": 2,
    "eventvendorhexareps": 14,
    "eventvendorquadreps": 11,
    "eventvendortriplereps": 9,
    "eventvendordoublereps": 7,
    "eventvendorsinglereps": 4,
    "eventvendorreps": 2,
    "eventvendorplatinumreps": 12,
    "eventvendorplatinumdoublereps": 13,
    "eventvendorplatinumtriplereps": 13,
    "eventvendorplatinumquadreps": 15,
    "eventvendorplatinumhexareps": 18,
}
var travcon2023exhibitorreps = {
    "platinumhexareps": 16,
    "platinumquadreps": 13,
    "platinumtriplereps": 11,
    "platinumdoublereps": 0, // we need to get the count
    "platinumreps": 10,
    "hexareps": 12,
    "quadreps": 9,
    "triplereps": 7,
    "doublereps": 5,
    "singlereps": 2,
    "eventvendorhexareps": 14,
    "eventvendorquadreps": 11,
    "eventvendortriplereps": 9,
    "eventvendordoublereps": 7,
    "eventvendorsinglereps": 4,
    "eventvendorreps": 2,
    "eventvendorplatinumreps": 12,
    "eventvendorplatinumdoublereps": 13,
    "eventvendorplatinumtriplereps": 13,
    "eventvendorplatinumquadreps": 15,
    "eventvendorplatinumhexareps": 18,
}

var travcon2024exhibitorreps = {
    "platinumhexareps": 16,
    "platinumquadreps": 13,
    "platinumtriplereps": 11,
    "platinumdoublereps": 0, // we need to get the count
    "platinumreps": 10,
    "hexareps": 12,
    "quadreps": 9,
    "triplereps": 7,
    "doublereps": 5,
    "singlereps": 2,
    "eventvendorhexareps": 14,
    "eventvendorquadreps": 11,
    "eventvendortriplereps": 9,
    "eventvendordoublereps": 7,
    "eventvendorsinglereps": 4,
    "eventvendorreps": 2,
    "eventvendorplatinumreps": 12,
    "eventvendorplatinumdoublereps": 13,
    "eventvendorplatinumtriplereps": 13,
    "eventvendorplatinumquadreps": 15,
    "eventvendorplatinumhexareps": 18,
}

function getTravconExhibitorRegItemStrings() {
    if (EventcodeFunctions.isTravconExhibitor2022(global.$code)) {
        return travconExhibitor2022RegItems;
    }
    else if (EventcodeFunctions.isTravconExhibitor2023(global.$code)) {
        return travconExhibitor2023RegItems;
    }
    else if (EventcodeFunctions.isTravconExhibitor2024(global.$code)) {
        return travconExhibitor2024RegItems;
    }
}

export default { commonConstants, travcon2022exhibitorreps, travcon2023exhibitorreps, getTravconExhibitorRegItemStrings, travconExhibitor2023RegItems, travconExhibitor2022RegItems,travconExhibitor2024RegItems,travcon2024exhibitorreps}