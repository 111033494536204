import React from 'react';
import { API } from 'aws-amplify';
import { Row, Col, Button, FormGroup, Label, Input } from 'reactstrap';
import "./Schedule.css";
import Icon from '@mdi/react';
import { mdiCheck, mdiCodeTags, mdiDatabaseCheck } from '@mdi/js';
import Modal from "react-responsive-modal";
import 'react-responsive-modal/styles.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import UpdatePoints from '../Points/UpdatePoints';

const moment = require('moment-timezone');
var serviceNames = require('../ServiceNameMapper');

const soundUrl = `https://event360live.s3.amazonaws.com/public/metro2022meeting/web/ringing_bell.mp3`
const notificationSound = new Audio(soundUrl)

var checkinRefreshTimer; //This timer checks if check-ins are triggered
var sessionRefreshTimer; //This timer downloads latest check-ins

class SessionCheckIns2 extends React.Component {
    constructor(props) {
        /*
        var timerintervals = [
        {
            "sid": "1274",
            "begin": "11/11/2020 03:30 AM",
            "end": "11/11/2020 03:35 AM",
            "data": "checkin2 1",
            "title": "checkin2 1",
            "tag": "S1C1"
        }
        ]
        */

        var checkinmaindivstyles = '';
        var checkinsubmitbuttonstyles = '';
        var checkintime = '';
        var popupdesc = " ";
        var showcheckintimer = false;
        var checkincodestyle = {};

        if (global.$session.styles.checkin == null) {
            checkinmaindivstyles = global.$session.styles.timer.maindiv;
            checkinsubmitbuttonstyles = global.$session.styles.timer.submitbutton
            checkintime = global.$session.styles.timer.text
        }
        else {
            checkinmaindivstyles = global.$session.styles.checkin.maindiv;
            checkinsubmitbuttonstyles = global.$session.styles.checkin.submitbutton;
            checkintime = global.$session.styles.checkin.text;
        }

        if (global.$session.checkinpopupdesc != null) {
            popupdesc = global.$session.checkinpopupdesc;
        }

        if (global.$session.showcheckintimer != null) {
            showcheckintimer = global.$session.showcheckintimer;
        }

        if (global.$session.styles.checkincode != null) {
            checkincodestyle = global.$session.styles.checkincode;
        }

        var numberofminutesforsessiontorefresh = 1;
        if (global.$config["session"].numberofminutesforsessiontorefresh != null) {
            numberofminutesforsessiontorefresh = global.$config["session"].numberofminutesforsessiontorefresh;
        }

        var numberofminutesforcheckintorefresh = 0.5;
        if (global.$config["session"].numberofminutesforcheckintorefresh != null) {
            numberofminutesforcheckintorefresh = global.$config["session"].numberofminutesforcheckintorefresh;
        }
        var sessionid = "";
        var sessiontag = "";
        var waitForCheckinMsgData = "";
        var isGlobalCheckin = false;
        if (global.$config["session"].waitforcheckinmsgdiv != null) {
            waitForCheckinMsgData = global.$config["session"].waitforcheckinmsgdiv
        }

        if (global.$config["session"].checkintype != null) {
            if (global.$config["session"].checkintype == "single")   // single/multiple
            {
                sessionid = global.$config["session"].checkinsessionid
                sessiontag = global.$config["session"].checkinsessiontag
                isGlobalCheckin = true;
            }
            else {
                sessionid = props.session.SID.toString();
                sessiontag = props.session.TAG;
            }
        }
        else {
            sessionid = props.session.SID.toString();
            sessiontag = props.session.TAG;
        }

        var playcheckinsound = false;
        if (global.$config["session"].playcheckinsound != null) {
            playcheckinsound = global.$config["session"].playcheckinsound;
        }

        super(props);
        this.state = {
            code: global.$code,
            s3: global.$s3,
            bucket: global.$s3bucket,
            timerintervals: [],
            timer: '', // count down ui
            secondsElapsed: 0,
            hidecheckinbutton: true,
            begin: '',
            end: '',
            data: '',
            sid: '',
            checkinname: 'CheckIn',
            checkinmaindivstyles: checkinmaindivstyles,
            checkinsubmitbuttonstyles: checkinsubmitbuttonstyles,
            checkintime: checkintime,
            checkstatus: 'not checked',
            tag: '',
            checkinname: '',
            dataloaded: false,
            lastcheckin: '',
            checkinvalue: '',
            checkinonce: '',
            overwrite: '',
            group: '',
            sessioncheckinids: [],
            checkinexist: false,
            alreadycheckedin: false,
            checkinbuttontype: 'image',
            openpopup: false,
            message: '',
            enablebuttons: false,
            popupdesc: popupdesc,
            successmsg: '',
            errormsg: '',
            checkcode: '',
            checkincode: '',
            checkindata: '',
            showcheckintimer: showcheckintimer,
            checkincodestyle: checkincodestyle,
            updateActivityPoints: false,
            sessionid: sessionid,
            sessiontag: sessiontag,
            isGlobalCheckin: isGlobalCheckin,
            waitForCheckinMsg: false,
            waitForCheckinMsgData: waitForCheckinMsgData,
            numberofminutesforcheckintorefresh: numberofminutesforcheckintorefresh,
            playcheckinsound: playcheckinsound
        };
        //console.log(this.state);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.submitCheckin = this.submitCheckin.bind(this);
        this.submitCheckinWithCode = this.submitCheckinWithCode.bind(this);
        this.submitCheckinWithoutCode = this.submitCheckinWithoutCode.bind(this);
        this.handleCheckIn = this.handleCheckIn.bind(this);

        //This timer checks for changes to checkin in session
        sessionRefreshTimer = setInterval(() => {
            this.getSession();
        }, 60000 * Number(numberofminutesforsessiontorefresh))

        //this.startTimer(); KD
    }
    componentWillUnmount() {
        //console.log("componentWillUnmount")
        clearInterval(checkinRefreshTimer);
        clearInterval(sessionRefreshTimer);
    }

    onOpenPopup = () => {
        this.setState(prevState => ({
            openpopup: !prevState.openpopup
        }));
    };

    playSound() {
        if (this.state.hidecheckinbutton == false && this.state.playcheckinsound == true) {
            notificationSound.play()
        }
        return null;
    }
    render() {
        //console.log("SessionCheckIns3: render");
        //console.log(this.state);

        //console.log(this.props.session);
        if (this.state.dataloaded) {
            return (
                <div style={this.state.checkinmaindivstyles}>
                    {/*this.startTimer()*/}
                    <div id="checkin2div">
                        <div className="checkin2-time" id="checkin2_time" style={this.state.checkintime} hidden={!this.state.showcheckintimer}>{this.state.timer}</div>
                        {
                            this.state.hidecheckinbutton == true ?
                                (
                                    <div className="wait-for-checkinmsg-data" dangerouslySetInnerHTML={{ __html: this.state.waitForCheckinMsgData }}></div>
                                )
                                :
                                ('')
                        }
                        {
                            this.state.checkinbuttontype == "image" ?
                                (
                                    <div>
                                        {
                                            this.state.checkcode == "Y" ?
                                                (
                                                    <div>
                                                        <div className="checkin2-success-msg">{this.state.successmsg}</div>
                                                        <div hidden={this.state.hidecheckinbutton}>
                                                            <div className="checkin2-div">
                                                                <FormGroup className="form-labels checkin2code-input" >
                                                                    <Label for="checkin2code" className="checkin2-input-label" >Enter the code and checkin</Label>
                                                                    <div className="checkin2-code offset-md-3" style={this.state.checkincodestyle}>{this.state.checkindata.code}</div>
                                                                    <Input type="text" name="checkincode" className=" offset-md-3" value={this.state.checkincode || ''} onChange={this.handleInputChange} />
                                                                </FormGroup>
                                                                <div className="checkin2-error-msg">{this.state.errormsg}</div>
                                                                <Button className="btn-btn-primary" id="checkin2_button" onClick={this.submitCheckinWithCode} style={this.state.checkinsubmitbuttonstyles}><img src={`https://event360live.s3.amazonaws.com/public/${this.state.code}/web/${this.state.checkinname}`} className="checkin2-img" /></Button>

                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                                :
                                                (
                                                    <div>
                                                        <div className="checkin2-success-msg">{this.state.successmsg}</div>
                                                        <div className="checkin2-div">
                                                            <Button className="btn-btn-primary" id="checkin2_button" onClick={this.submitCheckinWithoutCode} style={this.state.checkinsubmitbuttonstyles} hidden={this.state.hidecheckinbutton}><img src={`https://event360live.s3.amazonaws.com/public/${this.state.code}/web/${this.state.checkinname}`} className="checkin2-img" /></Button>
                                                        </div>
                                                    </div>
                                                )
                                        }
                                        {
                                            this.playSound()
                                        }

                                    </div>
                                )
                                :
                                ('')
                        }
                        {
                            this.state.checkinbuttontype == "text" ?
                                (
                                    <div>
                                        {
                                            this.state.checkcode == "Y" ?
                                                (
                                                    <div>
                                                        <div className="checkin2-success-msg">{this.state.successmsg}</div>
                                                        <div hidden={this.state.hidecheckinbutton}>
                                                            <div className="checkin2-div">
                                                                <FormGroup className="form-labels checkin2code-input">
                                                                    <Label for="checkin2code" className="checkin2-input-label" >Enter the code and checkin</Label>
                                                                    <div className="checkin2-code offset-md-3" style={this.state.checkincodestyle}>{this.state.checkindata.code}</div>
                                                                    <Input type="text" name="checkincode" className="checkin2-code-input offset-md-3" value={this.state.checkincode || ''} onChange={this.handleInputChange} />
                                                                </FormGroup>
                                                                <div className="checkin2-error-msg">{this.state.errormsg}</div>
                                                                <Button className="btn-btn-primary" id="checkin2_button" onClick={this.submitCheckinWithCode} style={this.state.checkinsubmitbuttonstyles} hidden={this.state.hidecheckinbutton}><Icon path={mdiCheck} size={1} color="lightgray" className="form-label-icon" />{this.state.checkinname}</Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                                :
                                                (
                                                    <div>
                                                        <div className="checkin2-success-msg">{this.state.successmsg}</div>
                                                        <div className="checkin2-div">
                                                            <Button className="btn-btn-primary" id="checkin2_button" onClick={this.submitCheckinWithoutCode} style={this.state.checkinsubmitbuttonstyles} hidden={this.state.hidecheckinbutton}><Icon path={mdiCheck} size={1} color="lightgray" className="form-label-icon" />{this.state.checkinname}</Button>
                                                        </div>
                                                    </div>
                                                )
                                        }
                                        {
                                            this.playSound()
                                        }
                                    </div>
                                )
                                :
                                ('')
                        }
                    </div>
                    <Modal
                        open={this.state.openpopup}
                        onClose={this.onOpenPopup}
                        blockScroll={false}
                        className="checkin2-session-popup"
                        styles={{
                            modal: {
                                width: "100%"
                            }
                        }}
                        center>
                        <div className="popup-buttons-div">
                            {
                                this.state.enablebuttons == true ?
                                    (
                                        <div>
                                            <div dangerouslySetInnerHTML={{ __html: this.state.popupdesc }}></div>
                                            <div className="session-checkin-message">{this.state.message}</div>
                                            <div className="checkin2-popup-btn-div">
                                                <Button className="btn popup-confirm-button" onClick={this.handleCheckIn}>Yes</Button>
                                                <Button className="btn popup-confirm-button" onClick={this.onOpenPopup}>No</Button>
                                            </div>
                                        </div>
                                    )
                                    :
                                    (
                                        <div>
                                            <div className="session-registration-message">{this.state.message}</div>
                                        </div>

                                    )
                            }
                        </div>
                    </Modal>
                    {
                        global.$points.sessioncheckin != null && global.$points.sessioncheckin.enable.includes("Y") ?
                            (
                                this.state.updateActivityPoints == true ?
                                    (
                                        <UpdatePoints session={global.$sessionobj} checkinid={this.state.checkindata.tag} component="session-checkin" area="sessioncheckin" points={this.state.checkindata.points} />
                                    )
                                    :
                                    ('')
                            )
                            :
                            ('')
                    }
                </div>
            )
        }
        else {
            this.getSession();
            return null;
        }
    }
    getAdjustedCurrentDate() {
        var mode = global.$mode;
        if (mode == null || mode == '') {
            mode = 'live';
        }

        var currentdate = moment().tz(`${global.$settings.timezone}`).format('L LTS');

        if (mode == "live") {
            //Nothing - Just return the current date
        }
        else if (mode == "preview") {
            // Make current date/time as same as session begin date/time
            currentdate = global.$sessionobj.BEGIN;
        }
        else if (mode == "dryrun") {
            var eventdate = moment(global.$sessionobj.BEGIN).format('L');
            var currenttime = moment().tz(`${global.$settings.timezone}`).format('LTS');
            currentdate = eventdate + " " + currenttime
        }
        else if (mode == "ondemand") {   //Add 365 days to current date

            var postdate = moment().add(365, 'days').calendar();
            var posttime = moment().tz(`${global.$settings.timezone}`).format('LTS');
            currentdate = postdate + " " + posttime;
        }

        return currentdate;
    }

    processCheckIn() {

        if (this.state.timerintervals.length != 0) {
            var json = this.state.timerintervals;
            

            var activeCheckin = 0

            json.forEach((item, index, array) => {
               
                var beginDate = Date.parse(item.begin);

                var currentdate = moment().tz(`${global.$settings.timezone}`).format('L LTS');
                var presentDate = Date.parse(currentdate);
                
                var endDate = Date.parse(item.end);
                var time = moment().tz(`${global.$settings.timezone}`).format('hh:mm:ss A')

                if (presentDate >= beginDate && presentDate <= endDate) {
                    
                    activeCheckin++
                    if (this.state['show' + item.tag] == null || this.state['show' + item.tag] == true)    //condition to hide the checkin buton after checkin
                    {
                        this.setState({
                            begin: item.begin,
                            end: item.end,
                            data: item.tag,
                            sid: item.sid,
                            checkinname: item.title,
                            checkinbuttontype: item.buttontype,
                            tag: item.tag,
                            checkinonce: item.checkinonce,
                            group: item.group,
                            hidecheckinbutton: false,   //Show the button
                            checkcode: item.checkcode,
                            checkindata: item,
                            successmsg: '',
                            ['show' + item.tag]: true,
                            //waitForCheckinMsg: false
                        });
                        
                        if (presentDate == endDate) {
                            //console.log("end")
                            this.state.hidecheckinbutton = true;
                            //this.state.waitForCheckinMsg = true;
                        }

                    }
                }
                else if (index === array.length - 1) {

                    //This takes care of hiding check clock at the end

                    //console.log(this.state.lastcheckin);
                    //console.log("true");
                    if (presentDate >= endDate) {
                        //console.log("true");
                        if (document.getElementById("checkin2_time") != null) {
                            document.getElementById("checkin2_time").style.display = "none"
                            //this.state.waitForCheckinMsg = true;
                            //this.state.hidecheckinbutton = true;
                        }
                    }
                }



                // if(this.state.hidecheckinbutton == true)
                // {
                //     notificationSound.loop = false;
                // }
                // else
                // {
                //     notificationSound.loop = true
                // }

                this.setState({ timer: time })  //TBD: Is this required?
                //console.log(this.state.timer);
                //console.log(this.state.checkstatus);
                //console.log(this.state.hidecheckinbutton);
            })

            //Hide missed check-ins
            if (activeCheckin == 0 && this.state.hidecheckinbutton == false) {
                //No checkins are active, but check in visible. 
                //console.log('Hiding missed check-in')

                this.setState({
                    hidecheckinbutton: true,   //Hide checkin
                    ['show' + this.state.tag]: false
                })
            }
        }
        else {
            //console.log("don't have checkin data");
        }

    }

    /*
    startTimer() {
        //console.log(this.state.timerintervals)
        checkinRefreshTimer = setInterval(() => {
            if (this.state.timerintervals.length != 0) {
                var json = this.state.timerintervals;
                //console.log(json.length);
                json.forEach((item, index, array) => {
                   //console.log(item);
                    var beginDate = Date.parse(item.begin);

                    var currentdate = moment().tz(`${global.$settings.timezone}`).format('L LTS');
                    var presentDate = Date.parse(currentdate);
                    //console.log(item.begin, currentdate, item.end);
                    var endDate = Date.parse(item.end);
                    //console.log(presentDate >= beginDate, presentDate <= endDate);
                    var time = moment().tz(`${global.$settings.timezone}`).format('hh:mm:ss A')

                    if (presentDate >= beginDate && presentDate <= endDate) {
                       //console.log("true");
                       //console.log(this.state['show' + item.tag]);
                        if (this.state['show' + item.tag] == null || this.state['show' + item.tag] == true)    //condition to hide the checkin buton after checkin
                        {
                            this.setState({
                                begin: item.begin,
                                end: item.end,
                                data: item.tag,
                                sid: item.sid,
                                checkinname: item.title,
                                checkinbuttontype: item.buttontype,
                                tag: item.tag,
                                checkinonce: item.checkinonce,
                                group: item.group,
                                hidecheckinbutton: false,   //Show the button
                                checkcode: item.checkcode,
                                checkindata: item,
                                successmsg: '',
                                ['show' + item.tag]: true,
                                //waitForCheckinMsg: false
                            });
                            //console.log(this.state);
                            if (presentDate == endDate) {
                               //console.log("end")
                                this.state.hidecheckinbutton = true;
                                //this.state.waitForCheckinMsg = true;
                            }

                        }
                    }
                    else if (index === array.length - 1) {
                        //console.log(this.state.lastcheckin);
                        //console.log("true");
                        if (presentDate >= endDate) {
                            //console.log("true");
                            if (document.getElementById("checkin2_time") != null) {
                                document.getElementById("checkin2_time").style.display = "none"
                                //this.state.waitForCheckinMsg = true;
                                //this.state.hidecheckinbutton = true;
                            }
                        }
                    }


                    // if(this.state.hidecheckinbutton == true)
                    // {
                    //     notificationSound.loop = false;
                    // }
                    // else
                    // {
                    //     notificationSound.loop = true
                    // }

                    this.setState({ timer: time })
                    //console.log(this.state.timer);
                    //console.log(this.state.checkstatus);
                    //console.log(this.state.hidecheckinbutton);
                })
            }
            else {
                //console.log("don't have checkin data");
            }
        }, 60000 * Number(this.state.numberofminutesforcheckintorefresh));
    }
    */

    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        let value = target.value

        //console.log( "Target = " + name + ", Value = " + value);

        this.setState({ [name]: value });
    }

    handleCheckIn(event) {
        event.preventDefault();
        this.postBadgeScan();
    }
    // Not using
    submitCheckin(event) {
        event.preventDefault();
        this.findCheckIn()
    }

    validateCheckinCode() {
        //console.log(this.state.checkindata);
        //console.log(this.state.checkincode, this.state.checkincode == this.state.checkindata.code);
        if (this.state.checkincode == this.state.checkindata.code) {
            return true;
        }
        else {
            return false;
        }
    }

    submitCheckinWithCode(event) {
        event.preventDefault();

        //console.log(this.state.checkindata);
        //console.log(this.state.checkindata.code);
        if (this.validateCheckinCode()) {
            this.findCheckIn()
        }
        else {
            this.setState({ errormsg: this.state.checkindata.errormessage });
        }
    }

    submitCheckinWithoutCode(event) {
        event.preventDefault();
        this.findCheckIn()
    }
    getBadgeScanTableObjects(objs) {
        //console.log(objs);

        const tableObjs = []

        for (var i = 0; i < objs.length; i++) {
            var badgeObj = objs[i]

            //console.log(badgeObj)

            var newObj = Object()
            var tagid = badgeObj.tagid.S

            tableObjs.push(newObj);
        }

        return tableObjs;
    }
    /*
        - Checks if check in is done or not
        - If not, checks in
        - else 
    */
    findCheckIn() {
        let apiName = serviceNames.getBadgeScanService();
        let path = ''; //replace this with the path you have configured on your API

        let myInit = {
            body:
            {
                'code': this.state.code,
                'command': 'getCheckIn',
                'userid': global.$userid,
                'tagid': this.state.tag
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //console.log(myInit);

        API.post(apiName, path, myInit).then(response => {
            //console.log(response);

            if (response.err == null && response.data != null) {
                //console.log(response.data);
                if (response.data.result == false) {
                    //console.log("User already Checked in");
                    this.performSessionValidation(response.data);
                }
                else if (response.data.result == true) {
                    //console.log("User not checked in");
                    this.postBadgeScan();

                }
                //var badgescans = this.getBadgeScanTableObjects(response.data.Items)

            }
            else {
                //console.log("getBadgeScanTableObjects - failed!!");
                //console.log(response.err);
            }
        }
        ).catch(error => {
            //console.log(error.response)
        });
    }
    performSessionValidation(result) {
        result.data.map(item => {
            //console.log(item);
            var tag = item.title.S
            var sessionid = tag.substring(
                tag.lastIndexOf("C#ID:") + 5,
                tag.lastIndexOf(";N:"));
            //console.log(sessionid, this.props.session.SID)
            // if (sessionid != this.props.session.SID) {
            //console.log(this.state.isGlobalCheckin);
            if (sessionid != this.state.sessionid) {
                console.log("different session with same checkin id");
                if (this.state.isGlobalCheckin == false) {
                    this.setState({
                        openpopup: true,
                        enablebuttons: true,
                        message: "Please do all the checkins from one session to get the credits, You have already done the checkin in one of the parallel sessions, do you want to cancel that and continue with this session."
                    })
                    // notificationSound.loop = false;
                }
                else {
                    this.setState({
                        openpopup: true,
                        enablebuttons: false,
                        hidecheckinbutton: true,
                        ['show' + this.state.data]: false,
                        message: "You have already checked in this session."
                    })
                    // notificationSound.loop = false;
                }
            }
            else {
                //console.log("Already checkedin for this session");
                this.setState({
                    openpopup: true,
                    enablebuttons: false,
                    hidecheckinbutton: true,
                    ['show' + this.state.data]: false,
                    message: "You have already checked in this session."
                })
                // notificationSound.loop = false;
            }
        })
    }

    postBadgeScan() {
        let apiName = serviceNames.getBadgeScanService();
        let path = ''; //replace this with the path you have configured on your API

        var time = moment().tz(global.$settings.timezone).format('L, LTS');
        var btime = moment(this.props.session.BEGIN).format('LTS');
        var etime = moment(this.props.session.END).format('LTS');
        var bdate = moment(this.props.session.BEGIN).format('L');
        var edate = moment(this.props.session.END).format('L');

        if (this.state.group == "Y") {
            if (this.state.checkinonce == "Y") {
                this.state.overwrite = "Y";
            }
            else {
                this.state.overwrite = "N";
            }
        }
        else {
            this.state.overwrite = "N";
        }
        let myInit = {
            body:
            {
                'code': global.$code,
                'command': 'processCheckIn',
                'checkinonce': this.state.checkinonce,
                'overwrite': this.state.overwrite,
                'userid': global.$userid,
                'name': global.$fname + " " + global.$lname,
                'time': time,
                'title': `C#ID:${this.props.session.SID};N:${this.props.session.TITLE};P:${this.props.session.POINTS};BD:${bdate};BT:${btime};ED:${edate};ET:${etime};D:${this.state.data};GROUP:${this.state.group};TAG:${this.state.tag};CHECKINCODE:${this.state.checkincode}`,
                'phone': global.$phone,
                'email': global.$email,
                'company': global.$company,
                'points': '0',
                'regid': global.$regid,
                'scannedby': global.$userid,
                'tagid': this.state.tag
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //console.log(myInit);

        API.post(apiName, path, myInit).then(response => {
            //console.log(response);
            //var json = this.state.timerintervals;
            //json = JSON.parse(json); 
            //console.log('show' + this.state.data)
            this.setState({
                openpopup: false,
                hidecheckinbutton: true,
                lastcheckin: this.state.data,
                ['show' + this.state.data]: false,
                successmsg: this.state.checkindata.successmessage,
                errormsg: '',
                checkincode: '',
                updateActivityPoints: true
            });
            //console.log(this.state);

            // if(this.state.hidecheckinbutton == true)
            // {
            //     notificationSound.loop = false;
            // }
        }
        ).catch(error => {
            //console.log(error)
        });
    }

    /*
        Gets check-in info from session and updates if changed
        Starts check-in timer
    */
    getSession() {

        let apiName = serviceNames.getEventPlannerService();
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'code': this.state.code,
                'command': 'getSession',
                'id': this.state.sessionid,
                'stag': this.state.sessiontag
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        
        API.post(apiName, path, myInit).then(response => {
     
            if (response.err == null && response.data != null) {
                let sessionObj = this.getScheduleObjects(response.data)
                this.props.session.CHECKINS = sessionObj.checkins;  //TBD
    
                // calling the timer very fist time and updating the checkins
                if (this.state.timerintervals.length == 0) {
                    this.state.timerintervals = JSON.parse(sessionObj.checkins);

                    // this.setState({
                    //     timerintervals: sessionObj.checkins,
                    // });

                    //this.startTimer(); kd
                }

                this.processCheckIn() //kd

                // if there is an update in the checkins onnly we are updating the stat variable
                if (this.state.timerintervals == sessionObj.checkins) {

                }
                else {
                    this.state.timerintervals = JSON.parse(sessionObj.checkins);
                }
                this.setState({
                    dataloaded: true
                });
            }
            else {
            }
        }
        ).catch(error => {
            console.log(error.response)
        });
    }

    getScheduleObjects(objs) {
        var obj = objs[0]
        //console.log(obj)

        var newObj = Object()

        newObj.checkins = obj.checkins.S

        //console.log(newObj);
        return newObj
    }
}
export default SessionCheckIns2;


