let paymenttype = {
    ACH:"ACH",
    CARD_READER:"Card Reader",
    CREDIT_CARD:"Credit Card",
    DEBIT_CARD:"Debit Card",
    PAY_ONLINE:"Pay Online",
    PAY_BY_CHECK:"Pay by Check",
    PAYROLL:"Payroll Deduction",
    PAY_LATER:"Pay Later",
    CHECK:"Check",
    FREE:"No Payment"
}


let users = {
    ATTENDEE:"attendee",
    GUEST:"guest"
}

/*  status using
        1.paymentstatus
        2.regstatus
*/
let status = {
    FREE:"free",
    PENDING:"pending",
    CONFIRMED:"Confirmed",
    PAID:"paid"
}

export default { paymenttype,users,status }