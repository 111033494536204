import React from 'react';
import { API, Storage } from 'aws-amplify';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import { Row, Col, Button } from 'reactstrap';
import LoadPrivateImg from '../Components/Shared/LoadPrivateImg';
import UpdatePoints from '../Points/UpdatePoints';

const styles = {
  getEmojiButton: {
    border: "none",
    margin: 0,
    cursor: "pointer"
  },
  emojiPicker: {
  }
};

class ConversationInput extends React.Component {

  constructor(props) {
   //console.log(props);

    super(props);
    this.state = {
      code: this.props.code,
      receiverid: this.props.receiverid,
      chatInput: '',
      sendbuttonstyle: global.$conversations.styles.sendbutton,
      postinputstyle: global.$conversations.styles.postinputmaindiv,
      textareastyle: global.$conversations.styles.textarea,
      capturebuttonstyles: global.$conversations.capturebutton,
      imgloading: '',
      updatepoints: false
    };

    // React ES6 does not bind 'this' to event handlers by default
    this.submitHandler = this.submitHandler.bind(this);
    this.textChangeHandler = this.textChangeHandler.bind(this);
  }

  submitHandler(event) {

    // Stop the form from refreshing the page on submit
    event.preventDefault();

    //console.log(this.state.chatInput);
    this.postMessage();

  }

  textChangeHandler(event) {
    this.setState({ chatInput: event.target.value });
    //console.log(this.state.chatInput);
  }
  addEmoji = e => {
    let sym = e.unified.split('-')
    let codesArray = []
    sym.forEach(el => codesArray.push('0x' + el))
    let emoji = String.fromCodePoint(...codesArray)
    this.setState({
      chatInput: this.state.chatInput + emoji
    })
  }

  showEmojis = e => {
    this.setState(
      {
        showEmojis: true
      },
      () => document.addEventListener("click", this.closeMenu)
    );
  };

  closeMenu = e => {
    //console.log(this.emojiPicker);
    if (this.emojiPicker !== null && !this.emojiPicker.contains(e.target)) {
      this.setState(
        {
          showEmojis: false
        },
        () => document.removeEventListener("click", this.closeMenu)
      );
    }
  };

  render() {
    return (
      <div>
        <form className="conversation-input" style={this.state.postinputstyle}>
          <div className="attendee-conversation-input-section">
            <div className="input-image attendee-conversation-input-img">
              <LoadPrivateImg id={global.$loginid} name={global.$fname} component="posts" code={global.$code} height="40" width="40" />
            </div>
            <textarea
              className="attendee-conversation-text-area"
              style={this.state.textareastyle}
              rows="1"
              onChange={this.textChangeHandler}
              value={this.state.chatInput}
              placeholder="Post a message..."
            ></textarea>
            <div className="attendee-conversation-emoji-picker">
              {this.state.showEmojis ? (
                <span style={styles.emojiPicker} ref={el => (this.emojiPicker = el)}>
                  <Picker
                    onSelect={this.addEmoji}
                    emojiTooltip={true}
                    title="Apps4org"
                    className="emoji"
                  />
                </span>
              ) : (
                  <p style={styles.getEmojiButton} onClick={this.showEmojis}>
                    {String.fromCodePoint(0x1f60a)}
                  </p>
                )}
            </div>
            <Button type="submit" className="attendee-conversation-input-send" onClick={this.submitHandler} style={this.state.sendbuttonstyle}>Send</Button>
           </div>
        </form>
        {
          this.state.updatepoints ?
            (
              <div>
                {
                  global.$points.attendeechat != null && global.$points.attendeechat.enable.includes("Y") ?
                    (
                      <UpdatePoints receiverid={this.props.receiverid} component="conversation" area="attendeechat" points={global.$points.attendeechat.points} />
                    )
                    :
                    ('')
                }
              </div>
            )
            :
            ('')
        }
      </div>
    );
  } //render

  postMessage() {
    let apiName = 'ChatService';
    let path = '';

    var date = new Date()
    var timestamp = date / 1000

    //this.setState({ showSpinner: true })

    var cid;
    var senderid = this.props.senderid;
    var receiverid = this.props.receiverid;
    //console.log(senderid, receiverid);
    var array = [];
    array.push(senderid, receiverid);
    //console.log(array);
    array.sort();
    //console.log(array);
    var array1 = array[0];
    var array2 = array[1];
    cid = array1 + "-" + array2
    //console.log(cid);
    
    var msg = this.state.chatInput;
    msg = msg.replace(/(?:\r\n|\r|\n)/g, '<br>');
    
    let myInit =
    {
      body:
      {
        'code': this.state.code,
        'command': 'postMessage',
        'msg': msg,
        'timestamp': timestamp.toString(),
        'cid': cid,
        'senderid': senderid
      },
      response: true,
      headers: {}
    }

   //console.log(myInit);
    API.post(apiName, path, myInit).then(response => {

      //console.log(response);

      if (response.err == null) {
        //console.log('postMessage ok');
        // Call the onSend callback with the chatInput message
        //console.log('ChatInput: calling onSend');
        //this.props.onSend(this.state.chatInput);
        this.updateNewMsgInConversations();
        this.setState({
          chatInput: '',
          updatepoints: true
        })
        this.props.onSend();
        
      }
      else {

      }

    }
    ).catch(error => {
      //console.log(error);
    });
  }

  updateNewMsgInConversations() {
    let apiName = 'ChatService';
    let path = '';

    var cid;
    var senderid = this.props.senderid;
    var receiverid = this.props.receiverid;
    //console.log(senderid, receiverid);
    var array = [];
    array.push(senderid, receiverid);
    //console.log(array);
    array.sort();
    //console.log(array);
    var array1 = array[0];
    var array2 = array[1];
    cid = array1 + "-" + array2

    let myInit =
    {
      body:
      {
        'code': this.state.code,
        'command': 'updateNewMsgInConversation',
        'cid': cid,
        'senderid': senderid,
        'p1': this.props.conversation.p1,
        'p2': this.props.conversation.p2,
      },
      response: true,
      headers: {}
    }
   //console.log(myInit);
    API.post(apiName, path, myInit).then(response => {
      //console.log(response);
      if (response.err == null) {
        
      }
      else {

      }

    }
    ).catch(error => {
      //console.log(error);
    });
  }

}



export default ConversationInput;