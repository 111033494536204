import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import React, { useState } from 'react'
import { useEffect } from 'react';
import RegisterAttendee from '../RegisterAttendee';

// TEST 
// clientId: "AbAQuikNYjLoVbUhMxDzWo-bqwYtqEOUNzEJLhZzffZrmEzZITEj4TMxCMjjTzgp2xFEPe3X4cdE32Zn&currency=USD",

let paymentoptions = {};
function Paypal(props) {

    const [show, setShow] = useState(false);
    const [paymentsuccess, setPaymentSuccess] = useState(false);
    const [orderID, setOrderID] = useState(false);
    const [doPayment, setDoPayment] = useState(true); //Live: true, Test: false
    
    // GET CONFIG PAYMENT DETAILS
    const config = global.$config.registrationform;
    if (config.payment.credit && config.payment.credit.enable === true) {
        if (config.payment.credit.paymentmode === "paypal") {
            paymentoptions['clientId'] = config.payment.credit.clientid
            paymentoptions['disable-funding'] = config.payment.credit.disablepayments
        }
    }

    useEffect(()=>{
        
        if (doPayment) {
            setShow(true)
        }
        else setPaymentSuccess(true)
   
    },[doPayment])

    

    // CREATES A PAYPAL ORDER
    const createOrder = (data, actions) => {
        console.log(global.$regamount);
        return actions.order.create({
            purchase_units: [
                {
                    description: global.$totalregitems,
                    amount: {
                        currency_code: "USD",
                        value: global.$regamount,
                    },
                },
                
            ],
        }).then((orderID) => {
                setOrderID(orderID);
                return orderID;
        });
            
    };

    // CHECK APPROVAL
    const onApprove = (data, actions) => {
        return actions.order.capture().then(function (details) {
            setPaymentSuccess(true);
        });
    };

    // SHOW SELECTED REGITEMS AND ITS PRICE AND TOTAL AMOUNTS
    const displayRegItems = () => {
        let totalregitem = JSON.parse(global.$totalregitems)
        return totalregitem.map(item => <div className="payment-items">{item.name } - ${Number(item.totalamount).toFixed(2)}</div>)
    }


    return (
        <PayPalScriptProvider options={paymentoptions}>
            <>
                <div className="payment-summary-div">
                    {global.$totalregitems != null ? displayRegItems() : ''}
                    <p className="payment-amount">Total Amount - ${Number(global.$regamount).toFixed(2)}</p>
                </div>

                {show ? (
                    <PayPalButtons
                        style={{ layout: "vertical" }}
                        createOrder={createOrder}
                        onApprove={onApprove}
                    />
                ) : null}

                {paymentsuccess ? ( <RegisterAttendee regattendeestatus={props.regattendeestatus} reggueststatus={props.reggueststatus} type={props.type} hideRegisterButton={props.hideRegisterButton}/> ) : null }
            </>
        </PayPalScriptProvider>
    );
}

export default Paypal