
import React, { Component } from "react";
import * as BABYLON from "babylonjs";
import { API, Analytics } from 'aws-amplify';
import { Row, Col } from 'reactstrap';
import { Modal } from 'react-responsive-modal';
import ReactPlayer from 'react-player';
import SponsorContactForm from '../Sponsors/SponsorContactForm';
import VirtualPopupTabs from "../VirtualExpo/VirtualPopupTabs";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { mdiTruckCheckOutline } from "@mdi/js";
var serviceNames = require('../ServiceNameMapper');

const moment = require('moment-timezone');
var timer;
var scene;

/*

Notes:

Canvas width/height

			style={{ width: window.innerWidth, height: window.innerHeight }}

 			width: 100%;
            height: 100%;
			touch-action: none;
			overflow: hidden;
            width   : 100%;
            height  : 100%;
            margin  : 0;
            padding : 0;


Popup with Tabcontrol to show  video, videochat and contact form

Popup to show:
	Use Up, Down, Left and Right arrow keys to move
	Mouse Press + Move to look around

			
*/

class LoginHOme extends Component {

	constructor(props) {
		super(props);
		this.state = {
			useWireFrame: false,
			shouldAnimate: false,
			code: props.code,
			s3: props.s3,
			bucket: props.bucket,
			dataloaded: false,
			playing: true,
			playvideoopen: false,
			videourl: '',
			email: '',
			videocallopen: false,
			contactformopen: false,
			alloptionsopen: false,
			videoscheduleopen: false
		};

		this.getFilePath = this.getFilePath.bind(this);
	}

	componentDidMount = () => {

		console.log('componentDidMount');


		if (global.$exhibitorjson == null) {
			this.getExhibitorjson()
		}
		else {
			this.createScene();
		}


	};

	componentWillUnmount() {
		window.removeEventListener("resize", this.onWindowResize, false);
	}

	onWindowResize = event => {


		console.log('VirtualExpoRoom:onWindowResize');

		this.engine.resize();
	};

	onOpenVideoCall = () => {
		console.log('onOpenVideoCall');
		this.setState(prevState => ({
			videocallopen: !prevState.videocallopen
		}));
	};

	onOpenVideoSchedule = () => {
		//console.log('onOpenVideoSchedule');
		this.setState(prevState => ({
			videoscheduleopen: !prevState.videoscheduleopen
		}));
	};
	onOpenPlayVideo = () => {
		//console.log('onOpenPlayVideoCall');
		this.setState(prevState => ({
			playvideoopen: !prevState.playvideoopen
		}));
	};

	onOpenContactForm = () => {
		////console.log('onOpenContactForm');
		this.setState(prevState => ({
			contactformopen: !prevState.contactformopen
		}));
	};

	onOpenAllOptions = () => {
		//console.log('onOpenAllOptions');
		this.setState(prevState => ({
			alloptionsopen: !prevState.alloptionsopen
		}));
	};

	render() {

		//console.log('VirtualExpoRoom:render');

		Analytics.record({ name: 'PageView', attributes: { name: 'eventsites-' + this.state.code + '-virtualexpo' } });

		console.log(this.state);
		return (
			<div>
				<canvas
					style={{ width: "100%", height: "100%" }}
					tabindex="1"
					ref={canvas => {
						this.canvas = canvas;

						//console.log('calling createScene in ref!!');
						//this.createScene();

					}}
				/>

				<Modal
					open={this.state.playvideoopen}
					onClose={this.onOpenPlayVideo}
					styles={{
						modal: {
							width: "100%"
						}
					}}
					center>
					<ReactPlayer
						url={this.state.videourl}
						width="100%"
						height="calc(80vh - 100px)"
						controls={true}
						playing={this.state.playing}
					/>
				</Modal>

				<Modal
					open={this.state.videocallopen}
					onClose={this.onOpenVideoCall}
					styles={{
						modal: {
							width: "100%"
						}
					}}
					center>
					<iframe src={this.state.chaturl}
						id="myId"
						className="myClassname"
						display="initial"
						position="relative"
						width="100%"
						height="calc(80vh - 100px)"
						allow="camera *;microphone *"
					/>
				</Modal>
				<Modal
					open={this.state.videoscheduleopen}
					onClose={this.onOpenVideoSchedule}
					styles={{
						modal: {
							width: "100%"
						}
					}}
					center>
					{ReactHtmlParser(this.state.scheduledata)}
				</Modal>
				<Modal
					open={this.state.contactformopen}
					onClose={this.onOpenContactForm}
					styles={{
						modal: {
							width: "100%"
						}
					}}
					center>
					<SponsorContactForm email={this.state.email} />
				</Modal>
				<Modal
					open={this.state.alloptionsopen}
					onClose={this.onOpenAllOptions}
					styles={{
						modal: {
							width: "100%"
						}
					}}
					center>
					<VirtualPopupTabs exhibitor={global.$virtualexhibitor} />
				</Modal>
			</div>
		)

	}//render

	getExhibitorjson() {
		console.log("exhibitor json")

		let apiName = serviceNames.getWebsiteService();
		let path = ''; //replace this with the path you have configured on your API
		let myInit = {
			body:
			{
				'code': this.state.code,
				'command': 'getJsonFileFromS3',
				'bucket': this.state.bucket,
				'filename': 'exhibitors'
			},
			response: true,
			convertEmptyValues: true,
			headers: {}
		}
		//console.log(myInit);

		API.post(apiName, path, myInit).then(response => {
			//console.log (response);

			if (response.err == null && response.data != null) {
				let buf = Buffer.from(response.data.Body);
				//console.log(buf)
				//console.log(buf.toString());
				global.$exhibitorjson = JSON.parse(buf.toString());

				//this.setState({ dataloaded: true });
				this.createScene();


			}
			else {
				console.log(response.err);
			}
		}
		).catch(error => {
			console.log(error)
		});
	}

	getFilePath(filename) {
		return `https://s3.amazonaws.com/event360live/public/${this.state.code}/exhibitors/` + filename;
	}

	createScene() {
		console.log('createScene');

		if (global.$exhibitorjson == null) {
			console.log('exhibitorjson is null, so createScene is done!');
			return;
		}

		//var totalBooths = 40;

		//var totalBooths = global.$exhibitorjson.length;
		//console.log('totalBooths = ' + totalBooths);

		//var boothWidth = 8;
		//var space = 3;
		//var boothsPerSide = Math.floor(totalBooths / 4);

		//console.log('boothsPerSide = ' + boothsPerSide);

		//var floorW = boothWidth * boothsPerSide + space * (boothsPerSide - 1) + space * 2;


		// start ENGINE
		this.engine = new BABYLON.Engine(this.canvas, true);

		//Create Scene
		scene = new BABYLON.Scene(this.engine);

		//This creates a basic Babylon Scene object (non-mesh)
		//var scene = new BABYLON.Scene(engine);
		scene.gravity = new BABYLON.Vector3(0, -9.81, 0);
		scene.collisionsEnabled = true;

		//(floorW/2) + 1

		this.initCameras(scene, 0, 5, 0);
		//this.initCameras(scene, 0, 10, 0);

		this.initLights(scene);

		//var groundW = floorW + 4; //Add a few more space for walls
		//var groundW = floorW; //Add a few more space for walls

		var groundW = 40; //Add a few more space for walls
		var groundH = 40; //Add a few more space for walls
		var wallH 	= groundW/2; //Add a few more space for walls

		var boothWidth 	= 4; //Add a few more space for walls

		this.createGround(scene, groundW, groundH);

		//var wallH = boothWidth * 8;
		this.createWalls(scene, groundW, wallH, boothWidth);

		//this.createCircularRoom(scene, groundW, wallH);
		//this.createBooths(scene, totalBooths, floorW, boothWidth, space);

		// Add Events
		window.addEventListener("resize", this.onWindowResize, false);

		// Render Loop
		this.engine.runRenderLoop(() => {
			scene.render();
		});

		//return scene;
	}

	initCameras(scene, x, y, z) {

		var camera = new BABYLON.UniversalCamera("UniversalCamera", new BABYLON.Vector3(x, y, z), scene);

		camera.attachControl(this.canvas, false);  //NOTE:  False is required to avoid webpage scrolling

		camera.applyGravity = true;
		camera.ellipsoid = new BABYLON.Vector3(1, 1, 1);
		camera.checkCollisions = true;


	  //camera.minZ = 0.0001;
	  camera.speed = 0.02;
	  camera.angularSpeed = 0.05;
	  camera.angle = Math.PI/2;
	  camera.direction = new BABYLON.Vector3(Math.cos(camera.angle), 0, Math.sin(camera.angle));

	      //First remove the default management.
		  camera.inputs.removeByType("FreeCameraKeyboardMoveInput");
		  camera.inputs.removeByType("FreeCameraMouseInput");
		   
		  //Key Input Manager To Use Keys to Move Forward and BackWard and Look to the Left or Right
		  var FreeCameraKeyboardWalkInput = function () {
			  this._keys = [];
			  this.keysUp = [38];
			  this.keysDown = [40];
			  this.keysLeft = [37];
			  this.keysRight = [39];
		  }
		  
		  //Add attachment controls
		  FreeCameraKeyboardWalkInput.prototype.attachControl = function (noPreventDefault) {
				  var _this = this;
				  var engine = this.camera.getEngine();
				  var element = engine.getInputElement();
				  if (!this._onKeyDown) {
					  element.tabIndex = 1;
					  this._onKeyDown = function (evt) {                 
						  if (_this.keysUp.indexOf(evt.keyCode) !== -1 ||
							  _this.keysDown.indexOf(evt.keyCode) !== -1 ||
							  _this.keysLeft.indexOf(evt.keyCode) !== -1 ||
							  _this.keysRight.indexOf(evt.keyCode) !== -1) {
							  var index = _this._keys.indexOf(evt.keyCode);
							  if (index === -1) {
								  _this._keys.push(evt.keyCode);
							  }

							  //if (!noPreventDefault) {
								  evt.preventDefault();
							  //}
						  }
					  };
					  this._onKeyUp = function (evt) {
						  if (_this.keysUp.indexOf(evt.keyCode) !== -1 ||
							  _this.keysDown.indexOf(evt.keyCode) !== -1 ||
							  _this.keysLeft.indexOf(evt.keyCode) !== -1 ||
							  _this.keysRight.indexOf(evt.keyCode) !== -1) {
							  var index = _this._keys.indexOf(evt.keyCode);
							  if (index >= 0) {
								  _this._keys.splice(index, 1);
							  }
							  //if (!noPreventDefault) {
								  evt.preventDefault();
							  //}
						  }
					  };
					  element.addEventListener("keydown", this._onKeyDown, false);
					  element.addEventListener("keyup", this._onKeyUp, false);
				  }
			  };
	  
			  //Add detachment controls
			  FreeCameraKeyboardWalkInput.prototype.detachControl = function () {
				  var engine = this.camera.getEngine();
				  var element = engine.getInputElement();
				  if (this._onKeyDown) {
					  element.removeEventListener("keydown", this._onKeyDown);
					  element.removeEventListener("keyup", this._onKeyUp);
					  BABYLON.Tools.UnregisterTopRootEvents([
						  { name: "blur", handler: this._onLostFocus }
					  ]);
					  this._keys = [];
					  this._onKeyDown = null;
					  this._onKeyUp = null;
				  }
			  };
	  			  
			//Keys movement control by checking inputs
			FreeCameraKeyboardWalkInput.prototype.checkInputs = function () {
				if (this._onKeyDown) {
					var camera = this.camera;
					for (var index = 0; index < this._keys.length; index++) {
						var keyCode = this._keys[index];
						var speed = camera.speed;
						if (this.keysLeft.indexOf(keyCode) !== -1) {
							camera.rotation.y -= camera.angularSpeed;
							camera.direction.copyFromFloats(0, 0, 0);                
						}
						else if (this.keysUp.indexOf(keyCode) !== -1) {
							camera.direction.copyFromFloats(0, 0, speed);               
						}
						else if (this.keysRight.indexOf(keyCode) !== -1) {
							camera.rotation.y += camera.angularSpeed;
							camera.direction.copyFromFloats(0, 0, 0);
						}
						else if (this.keysDown.indexOf(keyCode) !== -1) {
							camera.direction.copyFromFloats(0, 0, -speed);
						}
						if (camera.getScene().useRightHandedSystem) {
							camera.direction.z *= -1;
						}
						camera.getViewMatrix().invertToRef(camera._cameraTransformMatrix);
						BABYLON.Vector3.TransformNormalToRef(camera.direction, camera._cameraTransformMatrix, camera._transformedDirection);
						camera.cameraDirection.addInPlace(camera._transformedDirection);
					}
				}
			};
			  
	  
			  //Add the onLostFocus function
			  FreeCameraKeyboardWalkInput.prototype._onLostFocus = function (e) {
				  this._keys = [];
			  };
			  
			  //Add the two required functions for the control Name
			  FreeCameraKeyboardWalkInput.prototype.getClassName = function () {
				  return "FreeCameraKeyboardWalkInput";
			  };
	  
			  FreeCameraKeyboardWalkInput.prototype.getSimpleName = function () {
				  return "keyboard";
			  };
		  
		  //Add the new keys input manager to the camera.
		camera.inputs.add(new FreeCameraKeyboardWalkInput());


		//The Mouse Manager to use the mouse (touch) to search around including above and below
		var FreeCameraSearchInput = function (touchEnabled) {
			if (touchEnabled === void 0) { touchEnabled = true; }
			this.touchEnabled = touchEnabled;
			this.buttons = [0, 1, 2];
			this.angularSensibility = 2000.0;
			this.restrictionX = 100;
			this.restrictionY = 60;
		}
	
		//add attachment control which also contains the code to react to the input from the mouse 
		FreeCameraSearchInput.prototype.attachControl = function (noPreventDefault) {
			var _this = this;
			var engine = this.camera.getEngine();
			var element = engine.getInputElement();
			var angle = {x:0, y:0};
			if (!this._pointerInput) {
				this._pointerInput = function (p, s) {
					var evt = p.event;
					if (!_this.touchEnabled && evt.pointerType === "touch") {
						return;
					}
					if (p.type !== BABYLON.PointerEventTypes.POINTERMOVE && _this.buttons.indexOf(evt.button) === -1) {          
						return;
					}
					if (p.type === BABYLON.PointerEventTypes.POINTERDOWN) {          
						try {
							evt.srcElement.setPointerCapture(evt.pointerId);
						}
						catch (e) {
							//Nothing to do with the error. Execution will continue.
						}
						_this.previousPosition = {
							x: evt.clientX,
							y: evt.clientY
						};
						if (!noPreventDefault) {
							evt.preventDefault();
							element.focus();
						}
					}
					else if (p.type === BABYLON.PointerEventTypes.POINTERUP) {          
						try {
							evt.srcElement.releasePointerCapture(evt.pointerId);
						}
						catch (e) {
							//Nothing to do with the error.
						}
						_this.previousPosition = null;
						if (!noPreventDefault) {
							evt.preventDefault();
						}
					}
					else if (p.type === BABYLON.PointerEventTypes.POINTERMOVE) {            
						if (!_this.previousPosition || engine.isPointerLock) {
							return;
						}
						var offsetX = evt.clientX - _this.previousPosition.x;
						var offsetY = evt.clientY - _this.previousPosition.y;                   
						angle.x +=offsetX;
						angle.y -=offsetY;  
						if(Math.abs(angle.x) > _this.restrictionX )  {
							angle.x -=offsetX;
						}
						if(Math.abs(angle.y) > _this.restrictionY )  {
							angle.y +=offsetY;
						}       
						if (_this.camera.getScene().useRightHandedSystem) {
							if(Math.abs(angle.x) < _this.restrictionX )  {
								_this.camera.cameraRotation.y -= offsetX / _this.angularSensibility;
							}
						}
						else {
							if(Math.abs(angle.x) < _this.restrictionX )  {
								_this.camera.cameraRotation.y += offsetX / _this.angularSensibility;
							}
						}
						if(Math.abs(angle.y) < _this.restrictionY )  {
							_this.camera.cameraRotation.x += offsetY / _this.angularSensibility;
						}
						_this.previousPosition = {
							x: evt.clientX,
							y: evt.clientY
						};
						if (!noPreventDefault) {
							evt.preventDefault();
						}
					}
				};
			}
			this._onSearchMove = function (evt) {       
				if (!engine.isPointerLock) {
					return;
				}       
				var offsetX = evt.movementX || evt.mozMovementX || evt.webkitMovementX || evt.msMovementX || 0;
				var offsetY = evt.movementY || evt.mozMovementY || evt.webkitMovementY || evt.msMovementY || 0;
				if (_this.camera.getScene().useRightHandedSystem) {
					_this.camera.cameraRotation.y -= offsetX / _this.angularSensibility;
				}
				else {
					_this.camera.cameraRotation.y += offsetX / _this.angularSensibility;
				}
				_this.camera.cameraRotation.x += offsetY / _this.angularSensibility;
				_this.previousPosition = null;
				if (!noPreventDefault) {
					evt.preventDefault();
				}
			};
			this._observer = this.camera.getScene().onPointerObservable.add(this._pointerInput, BABYLON.PointerEventTypes.POINTERDOWN | BABYLON.PointerEventTypes.POINTERUP | BABYLON.PointerEventTypes.POINTERMOVE);
			element.addEventListener("mousemove", this._onSearchMove, false);
		};
	
		//Add detachment control
		FreeCameraSearchInput.prototype.detachControl = function () {
			var engine = this.camera.getEngine();
			var element = engine.getInputElement();
			if (this._observer && element) {
				this.camera.getScene().onPointerObservable.remove(this._observer);
				element.removeEventListener("mousemove", this._onSearchMove);
				this._observer = null;
				this._onSearchMove = null;
				this.previousPosition = null;
			}
		};
	
		//Add the two required functions for names
		FreeCameraSearchInput.prototype.getClassName = function () {
			return "FreeCameraSearchInput";
		};
	
		FreeCameraSearchInput.prototype.getSimpleName = function () {
			return "MouseSearchCamera";
		};
	
		//Add the new mouse input manager to the camera
		camera.inputs.add(new FreeCameraSearchInput());

	}

	initLights(scene) {
		// This creates a light, aiming 0,1,0 - to the sky (non-mesh)
		var light = new BABYLON.HemisphericLight("light", new BABYLON.Vector3(0, 1, 0), scene);

		// Default intensity is 1. Let's dim the light a small amount
		light.intensity = 0.7;
	}

	createGround(scene, floorW, floorH) {
		// Our built-in 'ground' shape.
		var ground = BABYLON.MeshBuilder.CreateGround("ground", { width: floorW, height: floorH }, scene);
		ground.checkCollisions = true;

		//createReferenceMarker(scene);

		var groundM = new BABYLON.StandardMaterial("groundM", scene);
		groundM.diffuseTexture = new BABYLON.Texture(global.$config['loginhome'].walls.floor.background, scene);
		groundM.specularTexture = new BABYLON.Texture(global.$config['loginhome'].walls.floor.background, scene);
		groundM.emissiveTexture = new BABYLON.Texture(global.$config['loginhome'].walls.floor.background, scene);
		groundM.ambientTexture = new BABYLON.Texture(global.$config['loginhome'].walls.floor.background, scene);
		ground.material = groundM;
		
		var centerMat = BABYLON.MeshBuilder.CreateCylinder("cone", { diameterTop: floorW / 4, diameterBottom: floorW / 4, height: .1 }, scene);
		centerMat.position.x = 0;
		centerMat.position.y = 0;
		centerMat.position.z = 0;
		centerMat.checkCollisions = false;

		var centerMatM = new BABYLON.StandardMaterial("centerMatM", scene);
		centerMatM.diffuseTexture = new BABYLON.Texture(global.$config['loginhome'].walls.floor.centermat, scene);
		centerMatM.specularTexture = new BABYLON.Texture(global.$config['loginhome'].walls.floor.centermat, scene);
		centerMatM.emissiveTexture = new BABYLON.Texture(global.$config['loginhome'].walls.floor.centermat, scene);
		centerMatM.ambientTexture = new BABYLON.Texture(global.$config['loginhome'].walls.floor.centermat, scene);
		centerMat.material = centerMatM;
		
	}

	createCircularRoom(scene, diameter, wallHeight) 
	{
		//var wallDepth = .01;

		var cylinder = BABYLON.MeshBuilder.CreateCylinder("cone", {diameterTop: diameter, diameterBottom: diameter, height:wallHeight, sideOrientation:BABYLON.Mesh.DOUBLESIDE}, scene);
		cylinder.position.x = 0;  
		//cylinder.position.y = wallHeight/2;
		cylinder.position.y = 0;
		cylinder.position.z =  0;
		//cylinder.checkCollisions = true;

		var frontwallM = new BABYLON.StandardMaterial("frontwallM", scene);
		
		var texture = new BABYLON.Texture(global.$config['loginhome'].walls.front.background, scene);
		texture.uScale = -1; // Mirror Horizontally
		texture.vScale = -1; // Mirror Vertically

		frontwallM.diffuseTexture  	= texture;
		frontwallM.specularTexture  = texture;
		frontwallM.emissiveTexture  = texture;
		frontwallM.ambientTexture  	= texture;
		cylinder.material 			= frontwallM;
		
	}

	
	createWalls(scene, wallWidth, wallHeight, boothWidth) {
		var wallDepth = .01;

		var frontwall = BABYLON.MeshBuilder.CreateBox("frontwall", { height: wallHeight, width: wallWidth, depth: wallDepth }, scene);

		//Place it in the center and move back
		frontwall.position.x = 0;
		frontwall.position.y = wallHeight / 2;
		frontwall.position.z = wallWidth / 2 * -1;
		frontwall.checkCollisions = true;


		var backwall = BABYLON.MeshBuilder.CreateBox("backwall", { height: wallHeight, width: wallWidth, depth: wallDepth }, scene);

		backwall.position.x = 0;
		backwall.position.y = wallHeight / 2;
		backwall.position.z = wallWidth / 2;
		backwall.checkCollisions = true;

		
		var frontwallM = new BABYLON.StandardMaterial("frontwallM", scene);
		
		var texture = new BABYLON.Texture(global.$config['loginhome'].walls.front.background, scene);
		texture.uScale = -1; // Mirror Horizontally
		texture.vScale = -1; // Mirror Vertically

		frontwallM.diffuseTexture  	= texture;
		frontwallM.specularTexture  = texture
		frontwallM.emissiveTexture  = texture
		frontwallM.ambientTexture  	= texture
		frontwall.material = frontwallM;
	
		//console.log('Wall background = ' + global.$config['loginhome'].walls.back.background)

		var backwallM = new BABYLON.StandardMaterial("backwallM", scene);
		texture = new BABYLON.Texture(global.$config['loginhome'].walls.back.background, scene);
		texture.uScale = -1; // Mirror Horizontally
		texture.vScale = -1; // Mirror Vertically

		backwallM.diffuseTexture = texture;
		backwallM.specularTexture = texture;
		backwallM.emissiveTexture = texture;
		backwallM.ambientTexture = texture;
		//backwall.material = backwallM;

		var rightwallM = new BABYLON.StandardMaterial("rightwallM", scene);
		texture = new BABYLON.Texture(global.$config['loginhome'].walls.right.background, scene);
		texture.uScale = -1; // Mirror Horizontally
		texture.vScale = -1; // Mirror Vertically

		rightwallM.diffuseTexture  	= texture;
		rightwallM.specularTexture  = texture;
		rightwallM.emissiveTexture  = texture;
		rightwallM.ambientTexture  	= texture;
		//rightwall.material = rightwallM;
		
		var leftwallM = new BABYLON.StandardMaterial("leftwallM", scene);
		texture = new BABYLON.Texture(global.$config['loginhome'].walls.left.background, scene);
		texture.uScale = -1; // Mirror Horizontally
		texture.vScale = -1; // Mirror Vertically

		leftwallM.diffuseTexture  	= texture;
		leftwallM.specularTexture  = texture;
		leftwallM.emissiveTexture  = texture;
		leftwallM.ambientTexture  	= texture;
		//leftwall.material = leftwallM;
		
		var rightwall = BABYLON.MeshBuilder.CreateBox("rightwall", { height: wallHeight, width: wallWidth, depth: wallDepth }, scene);
		rightwall.position.x = wallWidth / 2 * -1;
		rightwall.position.y = wallHeight / 2;
		rightwall.position.z = 0;
		rightwall.rotate(BABYLON.Axis.Y, Math.PI / 2, BABYLON.Space.WORLD);
		rightwall.checkCollisions = true;

		var leftwall = BABYLON.MeshBuilder.CreateBox("leftwall", { height: wallHeight, width: wallWidth, depth: wallDepth }, scene);
		//Place it right and rotate
		leftwall.position.x = wallWidth / 2;
		leftwall.position.y = wallHeight / 2;
		leftwall.position.z = 0;
		leftwall.rotate(BABYLON.Axis.Y, 3 * Math.PI / 2, BABYLON.Space.WORLD);
		leftwall.checkCollisions = true;

		frontwall.material = backwallM;
		backwall.material = backwallM;
		rightwall.material = backwallM;
		leftwall.material = backwallM;

		var topwall = BABYLON.MeshBuilder.CreateBox("topwall", { height: wallWidth, width: wallWidth, depth: wallDepth }, scene);

		topwall.position.x = 0;
		topwall.position.y = wallHeight;
		topwall.position.z = 0;
		topwall.rotate(BABYLON.Axis.X, Math.PI / 2, BABYLON.Space.WORLD);
		topwall.checkCollisions = true;

		var topwallM = new BABYLON.StandardMaterial("topwallM", scene);
		topwallM.diffuseTexture = new BABYLON.Texture(global.$config['loginhome'].walls.top.background, scene);
		topwallM.specularTexture = new BABYLON.Texture(global.$config['loginhome'].walls.top.background, scene);
		topwallM.emissiveTexture = new BABYLON.Texture(global.$config['loginhome'].walls.top.background, scene);
		topwallM.ambientTexture = new BABYLON.Texture(global.$config['loginhome'].walls.top.background, scene);
		topwall.material = topwallM;

		/*
		var bannerW = boothWidth * 2;
		var bannerH = bannerW / 4;
		var bannerD = .05;

		
		this.createWallBanners(scene, frontwall, 5, bannerW, bannerH, bannerD, "F");
		this.createWallBanners(scene, backwall, 5, bannerW, bannerH, bannerD, "B");
		this.createWallBanners(scene, leftwall, 5, bannerW, bannerH, bannerD, "L");
		this.createWallBanners(scene, rightwall, 5, bannerW, bannerH, bannerD, "R");
		*/


		var bannerW = wallWidth / 2;
		var bannerH = bannerW / 2;
		var bannerD = .05;
		var bannerY = bannerH/2 * -1;

		this.createWallBanners(scene, frontwall, bannerY, bannerW, bannerH, bannerD, "F");
		this.createWallBanners(scene, backwall, bannerY, bannerW, bannerH, bannerD, "B");
		this.createWallBanners(scene, leftwall, bannerY, bannerW, bannerH, bannerD, "L");
		this.createWallBanners(scene, rightwall, bannerY, bannerW, bannerH, bannerD, "R");

	}

	

	createWallBanners(scene, parent, bannerY, bannerW, bannerH, bannerD, side) {
		var banner = BABYLON.MeshBuilder.CreateBox("banner", { height: bannerH, width: bannerW, depth: bannerD }, scene);
		banner.parent = parent;

		banner.position.x = 0;
		banner.position.y = bannerY;
		banner.position.z = 0;

		banner.actionManager = new BABYLON.ActionManager(scene);

		var bannerM = new BABYLON.StandardMaterial("bannerM", scene);
		//tvM.emissiveColor = new BABYLON.Color3(1, 1, 1);
		//var videoT = new BABYLON.VideoTexture("video", "./wallbanner1.mp4", scene, true);
		var path = global.$config['loginhome'].walls.back.video
		var videoT = new BABYLON.VideoTexture("video", path, scene, true);

		if (side == 'F') {
			videoT.uScale = -1; // Mirror Horizontally
			videoT.vScale = -1; // Mirror Vertically
		}
		else if (side == 'B') { //not ok (This is the back wall from room perspective

			videoT.uScale = 1; // Mirror Horizontally
			videoT.vScale = 1; // Mirror Vertically
		}

		else {
			videoT.uScale = -1; // Mirror Horizontally
			videoT.vScale = -1; // Mirror Vertically
		}

		bannerM.diffuseTexture 	= videoT;
		bannerM.specularTexture = videoT;
		bannerM.emissiveTexture = videoT;
		bannerM.ambientTexture 	= videoT;

		banner.material = bannerM;
		banner.material.diffuseTexture.video.play();
	}

	/*
		NOTE Z -ve is forward
	*/

	createBooths(scene, totalBooths, floorW, platformW, space) {
		var start = 0;
		var boothsPerSide = Math.floor(totalBooths / 4);

		var startX = floorW / 2 * -1 + space + platformW / 2;
		var startY = 1;
		var startZ = floorW / 2;
		//var platformR = 0;  //90 - 1.5 

		// Front
		//for (var i = 1; i<= totalBooths; i++)
		for (var i = start; i < boothsPerSide; i++) {
			this.createBooth(scene, i, platformW, startX, startY, startZ, 'F');
			startX = startX + platformW + space;
		}

		// Behind
		startX = floorW / 2 * -1 + space + platformW / 2;
		startY = 0;
		startZ = floorW / 2 * -1;
		platformR = 3.14159;  //90 - 1.5 

		start = boothsPerSide;
		for (var i = start; i < (start + boothsPerSide); i++) {
			this.createBooth(scene, i, platformW, startX, startY, startZ, 'B');
			startX = startX + platformW + space;
		}

		//Right
		startX = (floorW / 2) * -1
		startY = 0;
		startZ = floorW / 2 * 1 - space - platformW / 2;
		var platformR = -1.508;  //90 - 1.5

		start = start + boothsPerSide;
		//for (var i = 1; i <= totalBooths; i++)
		for (var i = start; i < (start + boothsPerSide); i++) {
			this.createBooth(scene, i, platformW, startX, startY, startZ, 'R');
			startZ = startZ - (platformW + space);
		}

		//Left
		startX = (floorW / 2) * 1
		startY = 0;
		startZ = floorW / 2 * 1 - space - platformW / 2;
		var platformR = 1.508;  //90 - 1.5

		start = start + boothsPerSide;
		//for (var i = 1; i <= totalBooths; i++)
		for (var i = start; i < (start + boothsPerSide); i++) {
			this.createBooth(scene, i, platformW, startX, startY, startZ, 'L');
			startZ = startZ - (platformW + space);
		}
	}

	createBooth(scene, boothNo, platformWidth, platformX, platformY, platformZ, platformR) {
		//console.log('createBooth ' + boothNo);

		var exhibitor = null;

		//console.log(global.$exhibitorjson);

		if (global.$exhibitorjson != null) {
			exhibitor = global.$exhibitorjson[boothNo];
		}

		//console.log(exhibitor);

		var platformW = platformWidth;
		var platformH = .1;
		var platformD = .5;

		//var backwallW = platformW/2;
		var backwallW = platformW;
		var backwallH = backwallW / 2;
		var backwallD = .1;

		var backwallX = 0;
		var backwallY = backwallH;
		var backwallZ = 0;

		//var rightColW = backwallW / 2;
		//var rightColH = rightColW * 2;

		//var platformW = backwallW + rightColW +1;
		//var platformH = .1;

		//Bottom Platform
		var platform = BABYLON.MeshBuilder.CreateBox("platform", { height: platformH, width: platformW, depth: platformD }, scene);

		platform.position.x = platformX;
		platform.position.y = 3;
		platform.position.z = platformZ;

		//platform.scaling.z = .25;
		//platform.rotation.y = platformR;
		platform.checkCollisions = true;

		//Backwall
		var backwall = BABYLON.MeshBuilder.CreateBox("backwall", { height: backwallH, width: backwallW, depth: backwallD }, scene);
		backwall.parent = platform

		backwall.position.x = 0;
		backwall.position.y = backwallH / 2;
		backwall.position.z = 0;
		//backwall.scaling.z = .10;
		backwall.checkCollisions = true;

		//var tvW = backwallW/2;
		//var tvH = backwallH/2;
		var tvW = backwallW - .2;
		var tvH = backwallH - .2;
		var tvD = backwallD / 4
		this.createTV(scene, backwall, exhibitor, tvW, tvH, tvD);


		var commandX = 0;
		var commandH = 1;
		var commandY = tvH / 2 + commandH / 2 + .2;
		//var commandD = tvD;
		var commandD = .01;
		//var commandD = 0;

		//this.createCommandPanel(scene, backwall, exhibitor, commandX, commandY, commandD);
		//this.createTV(scene, backwall, exhibitor, tvW, tvH, tvD);

		//Right column
		/*
		var rightColumn = BABYLON.MeshBuilder.CreateCylinder("cone", {diameterTop: rightColW, diameterBottom: rightColW, height:rightColH}, scene);
		rightColumn.parent = platform;
	
		//Place right column at the end of backwall and tilt
		rightColumn.position.x = backwallW/2;
		rightColumn.position.y = rightColH/2;
		rightColumn.position.z = .5;
	    
		rightColumn.scaling.z = .25;
		rightColumn.rotation.y = -15;
		rightColumn.checkCollisions = true;
		*/
		//createTable(scene, platform);

		var platformM = new BABYLON.StandardMaterial("platformM", scene);
		platformM.diffuseColor = new BABYLON.Color3(1, 1, 1);
		platformM.specularColor = new BABYLON.Color3(1, 1, 1);
		platformM.emissiveColor = new BABYLON.Color3(1, 1, 1);
		platformM.ambientColor = new BABYLON.Color3(1, 1, 1);
		platform.material = platformM;

		var backwallM = new BABYLON.StandardMaterial("backwallM", scene);
		backwallM.diffuseColor = new BABYLON.Color3(0, 0, 0);
		backwallM.specularColor = new BABYLON.Color3(0, 0, 0);
		backwallM.emissiveColor = new BABYLON.Color3(0, 0, 0);
		backwallM.ambientColor = new BABYLON.Color3(0, 0, 0);
		backwall.material = backwallM;

		/*
		var rightColumnM = new BABYLON.StandardMaterial("rightColumnM", scene);
		rightColumnM.diffuseColor = new BABYLON.Color3(1, 1, 1);
		rightColumn.material = rightColumnM;
		*/

		this.createPerson(scene, backwall, exhibitor, platformW/2, -1.1, 1);	

		if (platformR == 'F') {
			//Wall camera is facing by default
			platform.rotate(BABYLON.Axis.Y, Math.PI, BABYLON.Space.WORLD);		
		}
		else if (platformR == 'B') {
			platform.rotate(BABYLON.Axis.Y, 2 * Math.PI, BABYLON.Space.WORLD);
		}
		else if (platformR == 'R') {
			platform.rotate(BABYLON.Axis.Y, Math.PI / 2, BABYLON.Space.WORLD);
		}
		else if (platformR == 'L') {
			platform.rotate(BABYLON.Axis.Y, 3 * Math.PI / 2, BABYLON.Space.WORLD);
		}
	}

	createTV(scene, parent, exhibitor, tvW, tvH, tvD) {
		var self = this;

		//var tvD = .25;
		var tv = BABYLON.MeshBuilder.CreateBox("tv", { height: tvH, width: tvW, depth: tvD }, scene);
		tv.parent = parent;

		tv.position.x = 0;
		tv.position.y = 0;
		tv.position.z = tvD * 2;
		tv.checkCollisions = true;

		var logoM = new BABYLON.StandardMaterial("logoM", scene);

		var path = '';
		if (exhibitor.TYPE == 'T') {
			path = global.$config['loginhome'].default.background
		}
		else {
			
			if ( exhibitor.IMAGE1 == '' )
			{
				path = this.getFilePath('blank-booth.png');
			}
			else
			{
				path = this.getFilePath(exhibitor.IMAGE1);
			}
		}
		//console.log(path);

		var texture = new BABYLON.Texture(path, scene);

		//texture.invertY = true;

		texture.uScale = -1; // Mirror Horizontally
		texture.vScale = -1; // Mirror Vertically

		logoM.diffuseTexture = texture;
		logoM.specularTexture = texture;
		logoM.emissiveTexture = texture;
		logoM.ambientTexture = texture;

		tv.material = logoM;

		tv.actionManager = new BABYLON.ActionManager(scene);

		tv.actionManager.registerAction(
			new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger,
				function (event) {
					//console.log("tv onPickTrigger");
					global.$virtualexhibitor = exhibitor;
					self.onOpenAllOptions();
				})//ExecuteCodeAction
		);//registerAction		
	}

	createPerson(scene, parent, exhibitor, x, y, z ) 
	{
		//var self = this;

		if ( exhibitor.IMAGE3 == '' )
		{
			return;
		}

		var path = this.getFilePath(exhibitor.IMAGE3);
		console.log(path);
		
		var person = BABYLON.MeshBuilder.CreatePlane("person", { height: 8, width: 4, sideOrientation: BABYLON.Mesh.DOUBLESIDE }, scene);
		person.parent = parent;
		person.position = new BABYLON.Vector3(x,y,z);
		person.billboardMode = BABYLON.Mesh.BILLBOARDMODE_ALL;

		var personM = new BABYLON.StandardMaterial("logoM", scene);
		var texture = new BABYLON.Texture(path, scene);

		//texture.invertY = true;
		//texture.uScale = -1; // Mirror Horizontally
		//texture.vScale = -1; // Mirror Vertically

		personM.diffuseTexture = texture;
		personM.specularTexture = texture;
		personM.emissiveTexture = texture;
		personM.ambientTexture = texture;

		personM.diffuseTexture.hasAlpha = true;
		personM.specularTexture.hasAlpha = true;
		personM.emissiveTexture.hasAlpha = true;
		personM.ambientTexture.hasAlpha = true;

		person.material = personM;
		

		//person.rotate(BABYLON.Axis.Y, rotation, BABYLON.Space.WORLD);

	}

	createReferenceMarker(scene)
	{
		var width = .5;
		var height = 1;

		var marker = BABYLON.MeshBuilder.CreateCylinder("cone", { diameterTop: width, diameterBottom: width, height: height }, scene);
		marker.position.x = 0;
		marker.position.y = height / 2;
		marker.position.z = 0;

		var markerM = new BABYLON.StandardMaterial("markerM", scene);
		markerM.diffuseColor = new BABYLON.Color3(1, 0, 0);
		markerM.specularColor = new BABYLON.Color3(1, 0, 0);
		markerM.emissiveColor = new BABYLON.Color3(1, 0, 0);
		markerM.ambientColor = new BABYLON.Color3(1, 0, 0);
		marker.material = markerM;
	}

	/*
	createTable(scene, parent) 
	{
		//Table Leg
		var tableLegW = .1;
		var tableLegH = 1;
		var tableLeg = BABYLON.MeshBuilder.CreateCylinder("cone", {diameterTop: tableLegW, diameterBottom: tableLegW, height:tableLegH}, scene);
		tableLeg.parent = parent;
	
		tableLeg.position.x = -2.5;  //Fix: Make relative
		tableLeg.position.y = tableLegH/2;
		tableLeg.position.z =  - 1;
		tableLeg.checkCollisions = true;
	
		//Table Top
		var tableTopW = 1;
		var tableTopH = .1;
		var tableTop = BABYLON.MeshBuilder.CreateCylinder("cone", {diameterTop: tableTopW, diameterBottom: tableTopW, height:tableTopH}, scene);
		tableTop.parent = tableLeg;
	
		tableTop.position.x = 0;
		tableTop.position.y = tableLegH/2;
		tableTop.position.z = 0;
		tableTop.checkCollisions = true;
	}
	
	getRandomInt(max) 
	{
	  return Math.floor(Math.random() * Math.floor(max));
	}
	
	https://s3.amazonaws.com/event360live/public/virtualsummit/exhibitors/1603343418753-image1.png
	
	*/

		/*
	createCommandPanel(scene, parent, exhibitor, panelX, panelY, panelD) {
		//console.log('createCommandPanel');

		var self = this;

		var path = '';

		var panelW = 3;
		var panelH = 1;
		//var panelD = 0;

		var commandW = 1;
		var commandH = 1;
		var commandZ = .1; // This is needed to get onPick event as commands have to out outside of the panel

		var panel = BABYLON.MeshBuilder.CreateBox(exhibitor.ID + "panel", { height: panelH, width: panelW, depth: panelD }, scene);
		panel.parent = parent;
		panel.position.x = panelX;
		panel.position.y = panelY;
		panel.position.z = panelD * 2;

		var panelM = new BABYLON.StandardMaterial("panelM", scene);
		panelM.diffuseColor = new BABYLON.Color3(255, 0, 0);
		panelM.specularColor = new BABYLON.Color3(255, 0, 0);
		panelM.emissiveColor = new BABYLON.Color3(255, 0, 0);
		panelM.ambientColor = new BABYLON.Color3(255, 0, 0);
		panelM.alpha = 0;

		panel.material = panelM;


		//Video Option
		var video = BABYLON.MeshBuilder.CreateBox("video", { height: commandH, width: commandW, depth: panelD }, scene);
		video.parent = panel;
		video.position.x = 1;
		video.position.y = 0;
		video.position.z = commandZ;

		var videoM = new BABYLON.StandardMaterial("videoM", scene);

		path = this.getFilePath('icon_video.png');
		//console.log(path);
		var videoT = new BABYLON.Texture(path, scene);
		videoT.hasAlpha = true;
		videoT.uScale = -1; // Mirror Horizontally
		videoT.vScale = -1; // Mirror Vertically

		videoM.diffuseTexture = videoT;
		videoM.specularTexture = videoT;
		videoM.emissiveTexture = videoT;
		videoM.ambientTexture = videoT;
		video.material = videoM;

		video.actionManager = new BABYLON.ActionManager(scene);
		//video.checkCollisions 	= true;

		video.actionManager.registerAction(
			new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger,
				function (event) {
					//console.log("video onPickTrigger");
					global.$virtualexhibitor = exhibitor;
					self.setState({ videourl: exhibitor.VIDEO1 });
					self.onOpenPlayVideo();
				})//ExecuteCodeAction
		);//registerAction	


		var videoCall = BABYLON.MeshBuilder.CreateBox(exhibitor.ID + "videoCall", { height: commandH, width: commandW, depth: panelD }, scene);

		videoCall.parent = panel;
		videoCall.position.x = 0;
		videoCall.position.y = 0;
		videoCall.position.z = commandZ;

		path = this.getFilePath('icon_videocall.png');
		//console.log(path);

		var videoCallM = new BABYLON.StandardMaterial("videoM", scene);

		var videoCallT = new BABYLON.Texture(path, scene);

		videoCallT.uScale = -1; // Mirror Horizontally
		videoCallT.vScale = -1; // Mirror Vertically
		videoCallT.hasAlpha = true;

		videoCallM.diffuseTexture = videoCallT;
		videoCallM.specularTexture = videoCallT;
		videoCallM.emissiveTexture = videoCallT;
		videoCallM.ambientTexture = videoCallT;
		videoCall.material = videoCallM;
		videoCall.actionManager = new BABYLON.ActionManager(scene);
		//videoCall.checkCollisions 	= true;

		videoCall.actionManager.registerAction(
			new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger,
				function (event) {

					//console.log("videoCall onPickTrigger");
					global.$virtualexhibitor = exhibitor;

					//self.setState({chaturl: 'https://apps4org.daily.co/virtualsummit-exhibitor1'});
					self.setState({ chaturl: exhibitor.MEETING1 });
					if (exhibitor.MEETING1 !== "" && exhibitor.SCHEDULE !== "") {
						var json = JSON.parse(exhibitor.SCHEDULE);
						var scheduledata = [];
						json.forEach((item) => {
							var edate = moment(item.End).format('LT')
							scheduledata.push(item.Begin + " - " + edate + "<br>");
						});
						self.setState({scheduledata: scheduledata});
					
						json.forEach((item) => {
							//console.log(item);
							var beginDate = Date.parse(item.Begin);
				
							var currentdate = moment().tz(`${global.$settings.timezone}`).format('L LTS');
							var presentDate = Date.parse(currentdate);
				
							var endDate = Date.parse(item.End);
							//console.log(presentDate >= beginDate, presentDate <= endDate);
							
							if (presentDate >= beginDate && presentDate <= endDate) {
								//console.log("true1");
								self.onOpenVideoCall();	
							}
							else
							{
								self.setState({
									begindate: scheduledata
								});
								self.onOpenVideoSchedule();
							}
						})
				}
				})//ExecuteCodeAction
		);//registerAction	

		//Contact Option
		var contact = BABYLON.MeshBuilder.CreateBox("contact", { height: commandH, width: commandW, depth: panelD }, scene);
		contact.parent = panel;
		contact.position.x = -1;
		contact.position.y = 0;
		contact.position.z = commandZ;

		var contactM = new BABYLON.StandardMaterial("contactM", scene);

		path = this.getFilePath('icon_contact.png');
		//console.log(path);
		var contactT = new BABYLON.Texture(path, scene);
		contactT.hasAlpha = true;
		contactT.uScale = -1; // Mirror Horizontally
		contactT.vScale = -1; // Mirror Vertically

		contactM.diffuseTexture = contactT;
		contactM.specularTexture = contactT;
		contactM.emissiveTexture = contactT;
		contactM.ambientTexture = contactT;
		contact.material = contactM;

		contact.actionManager = new BABYLON.ActionManager(scene);
		//video.checkCollisions 	= true;

		contact.actionManager.registerAction(
			new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger,
				function (event) {
					//console.log("contact onPickTrigger");
					self.setState({ email: exhibitor.EMAIL });
					global.$virtualexhibitor = exhibitor;
					self.onOpenContactForm();
				})//ExecuteCodeAction
		);//registerAction	



		//console.log('createCommandPanel done');
	}
	*/


}
export default LoginHOme;