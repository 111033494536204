/* eslint-disable */
import { Link } from 'react-router-dom';
import React from 'react';
import Icon from '@mdi/react';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import {Row,Col,Button,Dropdown, FormGroup, Label, Input, DropdownMenu, DropdownToggle} from 'reactstrap';
import { API } from 'aws-amplify';
import { mdiCreditCardOutline } from '@mdi/js';
import {ReactSpinner} from 'react-spinning-wheel';
var serviceNames = require('../../ServiceNameMapper');
import StripeReaderFunctions from './StripeReaderFunctions';
import RegisterAttendee from '../RegisterAttendee';
var doPayment = true; //Live: true, Test: false

class ReaderList extends React.Component {
    constructor(props) {
        const globalvariables = global.$variables; // Accessing Global variables
        var configpayment = global.$registrationform.payment;
        var location = configpayment.cardreader && configpayment.cardreader.stripe.location ? configpayment.cardreader.stripe.location : '';
        super(props);
        this.state =
        {
            dataloaded:false,
            enablepay: false,
            code: global.$code,
            totalAmount: 0,
            regAmountBeforeFee: global.$regamount,
            readers: '',
            selectedDropdown: '',
            dropdown: [ ],
            selectedDropdown: '',
            succeeded: false,
            location: location
        }

        global.$regattendeestatus = this.props.regattendeestatus;
        global.$reggueststatus = this.props.reggueststatus;
        global.$regusertype = this.props.type;

        this.hideRegisterButtonFromReader = this.hideRegisterButtonFromReader.bind(this);
        this.discoverReaderHandler = this.discoverReaderHandler.bind(this);
        this.handleReaders = this.handleReaders.bind(this);    
        this.handleConnect = this.handleConnect.bind(this);
        this.handleCreatePaymentIntent = this.handleCreatePaymentIntent.bind(this)

        //this.getReaderOptions();
        this.calculatePayment();
    }

    hideRegisterButtonFromReader() {
        this.props.hideRegisterButton();
    }

    calculatePayment() {
        var discount = '';
        if (global.$regdiscountinfo != null && global.$regdiscountinfo != "" && global.$regdiscountinfo.discountprice != null) {
            //console.log(global.$regdiscountinfo.discountprice);
            discount = "Discount - " + global.$regdiscountinfo.discountprice;
        }
        var stripetrasactionfee = "0";
        if (global.$registrationform.payment.stripe != null && global.$registrationform.payment.stripe.transactionfee != null) {
            stripetrasactionfee = global.$registrationform.payment.stripe.transactionfee;
        }
       
        var totalamount = Number(global.$regamount) + (Number(global.$regamount) * Number(stripetrasactionfee) / Number(100))
        global.$transactionfee = ((Number(global.$regamount) * Number(stripetrasactionfee)) / Number(100));
       //console.log(global.$regamount);
        global.$regamount = Number(global.$regamount) + (Number(global.$regamount) * Number(stripetrasactionfee) / Number(100));
        global.$regamount = parseFloat(global.$regamount).toFixed(2)
        
       //console.log(global.$regamount);
        
       //console.log("Fee = " + global.$transactionfee)
        totalamount = totalamount * 100
        //console.log("total amount = " + totalamount);
        this.setState({
            totalAmount: totalamount
        });
    }

    render() {
        if (this.state.dataloaded) {
            return (
                <div>
                {
                    global.$code == "travcon2022exhibitor" || global.$code == "travcon2023exhibitor" || global.$code == "travcon2024exhibitor" ?
                        (
                            <div>
                                <p className="payment-amount">Total registration amount = ${parseFloat(this.state.regAmountBeforeFee).toFixed(2)}</p>
                                <p className="payment-amount">Transaction fee = ${(global.$transactionfee)}</p>
                                <p className="payment-amount">Total Amount = ${parseFloat(Number(this.state.totalAmount) / 100).toFixed(2)}</p>

                                <p className="payment-amount"><b>NOTE: While payment is in progress, do not close this page or retry payment</b></p>

                            </div>
                        )
                        :
                        (
                            <div>
                                <p className="payment-amount">Total Amount ${parseFloat(global.$regamount).toFixed(2)}</p>
                                <p className="payment-amount"><b>NOTE: While payment is in progress, do not close this page or retry payment</b></p>
                            </div>    
                        )
                }      
                <hr />            
                        <Col md={8}>
                            <FormGroup className="form-labels">
                                <Label for="groupname" ><Icon path={mdiCreditCardOutline} size={0.9} color="lightgray" className="form-label-icon" />Stripe Machines</Label>
                                <Input type="select" name="dropdown" id="dropdown" className="form-control-sm" value={this.state.selectedDropdown || '' } onChange={this.handleReaders}> 
                                <option value='select'>Select Reader</option>
                                {
                                    this.state.dropdown
                                }  
                                </Input>
                            </FormGroup>
                        </Col>
                        <Row>
                        <Col>
                            <button
                                className="form-back-button"
                                onClick={this.handleConnect}
                                type="submit"
                                text="Connect"
                                style={{ marginBottom: "10px" }}
                            >Connect</button>                        
                        </Col>

                        {
                            this.state.enablepay ?
                            (
                                <Col>
                                <button
                                    id="stripe_submit"
                                    className="btn form-submit-button"
                                    onClick={this.handleCreatePaymentIntent}
                                    type="submit"
                                    text="Pay Now"
                                    style={{ marginBottom: "10px" }}
                                >Pay Now</button>                        
                                </Col>
                            )
                            :
                            ''
                        }
                        </Row>
                        {
                            this.state.succeeded == true
                            ?
                            (
                                <p className={this.state.succeeded ? "result-message" : "result-message hidden"}>
                                    <RegisterAttendee regattendeestatus={global.$regattendeestatus} reggueststatus={global.$reggueststatus} type={global.$regusertype} hideRegisterButton={this.hideRegisterButtonFromReader} />                                
                                </p>
                            )
                            :
                            ''
                        }
                </div>
            )
        }
        else {
            //this.getStripeReaders();
            this.discoverReaderHandler();

            return(
                <div>
                    <ReactSpinner />
                </div>
            )            
        }
    } 

    handleReaders(event) {
        const target = event.target;
        const name = target.name;
        let  value = target.value    

        this.setState({
            selectedDropdown: event.target.value
        })        
       
    }

    async handleConnect() {
        if(doPayment) {
            if(this.state.selectedDropdown != 'select') {
                var selectedreader = this.state.readers.filter(reader => reader.id == this.state.selectedDropdown)
                var connectreader = await StripeReaderFunctions.connectReaderHandler(selectedreader);
                //console.log(connectreader);
                if(connectreader.error) {
                    alert('Could not communicate with the Reader')
                }
                else {
                    this.setState({
                        enablepay: true
                    })
                }
            }            
        }
        else {
            this.setState({
                succeeded: true
            })
        }
    }

    async handleCreatePaymentIntent() {
        // if it is a exhibitor make sure transaction fee(3%) is added (todo)
        var amount = this.state.totalAmount  // LIVE     
        //var amount = 0.5 * 100               // TEST 50 cents  = 0.5 dollar 
        
        var createPaymentIntent = await StripeReaderFunctions.createPaymentIntent(amount)
        //console.log(createPaymentIntent);

        if(createPaymentIntent.error) {
            alert('Could not process the payment')
        }
        else {
            var capturePaymentIntent = await StripeReaderFunctions.capturePaymentIntent(createPaymentIntent.paymentIntent.id)
            if(capturePaymentIntent.err == null) {
                //alert('Payment is processed');
                {/* onPaymentCompleted call back to StripeReader.jsx */}
                //this.props.onPaymentCompleted()
                //console.log(paymentIntent);
                this.setState({
                    succeeded: true
                })
            }
        }
    }

    getReaderOptions() {
        ////console.log(objs);
        const tableObjs = []
        const readers = this.state.readers

        for (var i=0; i<readers.length; i++)
        {
            var readerObj = readers[i] 

            ////console.log( categoryObj)

            var newObj = Object()

            newObj.type = readerObj.device_type
            newObj.id = readerObj.id
            newObj.status = readerObj.status

            tableObjs.push(<option key={newObj.id} value={newObj.id} >{newObj.type}({newObj.id})</option>)
        }

       //console.log(tableObjs)

       return tableObjs

    }

    discoverReaderHandler = async () => {
        //console.log(this.state.location);

        if(this.state.location != null && this.state.location != '') {
            var readerslist = await StripeReaderFunctions.discoverReaderHandler(this.state.location);
            //console.log(readerslist);
            this.setState({
                readers: readerslist
            })
    
            this.getStripeReaders()
        }
    }

    getStripeReaders() {
        let readerlist = this.getReaderOptions();

        this.setState({
            dropdown: readerlist,
            dataloaded: true
        })      
    }

}

export default ReaderList;