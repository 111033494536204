import React from 'react';
import { API, Storage } from 'aws-amplify';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import { Row, Col, Button, Input } from 'reactstrap';
import LoadPrivateImg from '../Components/Shared/LoadPrivateImg';
import UpdatePoints from '../Points/UpdatePoints';
import Icon from '@mdi/react';
import ReactPlayer from 'react-player';
import { mdiCamera, mdiImage, mdiFileVideo, mdiCameraOutline, mdiFileVideoOutline, mdiImageOutline } from '@mdi/js';
var serviceNames = require('../ServiceNameMapper')

const styles = {
  getEmojiButton: {
    cssFloat: "right",
    border: "none",
    margin: 0,
    cursor: "pointer"
  },
  emojiPicker: {
    bottom: 10,
    right: 0,
    cssFloat: "right",
  }
};

class PostInput extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      code: this.props.code,
      receiverid: this.props.receiverid,
      videotos3FileName: ' ',
      videotos3FileHandle: '',
      videos3progress: 0,
      videos3progresstext: '',
      videos3: '',
      chatInput: '',
      showEmojis: false,
      photo: '',
      photoFileName: '',
      photoFileHandle: '',
      imageUrl: '',
      sendbuttonstyle: global.$conversations.styles.sendbutton,
      postinputstyle: global.$conversations.styles.postinputmaindiv,
      textareastyle: global.$conversations.styles.textarea,
      capturebuttonstyles: global.$conversations.capturebutton,
      imgloading: ''
    };

    // React ES6 does not bind 'this' to event handlers by default
    this.uploadVideoToS3 = this.uploadVideoToS3.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
    this.textChangeHandler = this.textChangeHandler.bind(this);
    this.fileUploader = this.fileUploader.bind(this);
    this.startCamera = this.startCamera.bind(this);
    this.takePhoto = this.takePhoto.bind(this);
  }

  submitHandler(event) {

    // Stop the form from refreshing the page on submit
    event.preventDefault();

    // Clear the input box
    this.setState({
      chatInput: '',
      photoFileName: '',
      updatepoints: false
    });

    // Call the onSend callback with the chatInput message
    //this.props.onSend(this.state.chatInput);
    if (this.state.photoFileName == "") {
      this.state.photoFileName = "-1"
    }

    //console.log(this.state.chatInput);
    //this.state.chatInput = this.state.chatInput.trim();

    //console.log(this.state.chatInput);
    if (this.state.chatInput == " " && this.state.photoFileName == "-1") {
      document.getElementById("error_message").innerHTML = "<p style='text-align: center; color: red'></p>"
    }
    if (this.state.chatInput == " " && this.state.photoFileName !== "-1") {
      this.postMessage();
    }
    if (this.state.chatInput !== " " && this.state.photoFileName == "-1") {
      this.postMessage();
    }
    if (this.state.chatInput !== " " && this.state.photoFileName !== "-1") {
      this.postMessage();
    }

  }

  textChangeHandler(event) {
    this.setState({ chatInput: event.target.value });
  }

  addEmoji = e => {
    let sym = e.unified.split('-')
    let codesArray = []
    sym.forEach(el => codesArray.push('0x' + el))
    let emoji = String.fromCodePoint(...codesArray)
    this.setState({
      chatInput: this.state.chatInput + emoji
    })
  }

  showEmojis = e => {
    this.setState(
      {
        showEmojis: true
      },
      () => document.addEventListener("click", this.closeMenu)
    );
  };

  closeMenu = e => {
    //console.log(this.emojiPicker);
    if (this.emojiPicker !== null && !this.emojiPicker.contains(e.target)) {
      this.setState(
        {
          showEmojis: false
        },
        () => document.removeEventListener("click", this.closeMenu)
      );
    }
  };

  render() {
    return (
      <div>
        <form className="post-input" style={this.state.postinputstyle}>
          <div className="post-input-section">
            <div className="post-input-image">
              <LoadPrivateImg id={global.$loginid} name={global.$fname} component="posts" code={global.$code} height="40" width="40" />
            </div>
            <textarea
              className="post-input-textarea"
              style={this.state.textareastyle}
              rows="1"
              onChange={this.textChangeHandler}
              value={this.state.chatInput}
              placeholder="Post a message..."
            ></textarea>
            <div className="post-emoji-picker">
              {this.state.showEmojis ? (
                <span style={styles.emojiPicker} ref={el => (this.emojiPicker = el)}>
                  <Picker
                    onSelect={this.addEmoji}
                    emojiTooltip={true}
                    title="Apps4org"
                    className="emoji"
                  />
                </span>
              ) : (
                <p style={styles.getEmojiButton} onClick={this.showEmojis}>
                  {String.fromCodePoint(0x1f60a)}
                </p>
              )}
            </div>
            <Button type="submit" className="post-input-send" onClick={this.submitHandler} style={this.state.sendbuttonstyle}>Send</Button>
          </div>
          <div className="post-icons-div">
            <label><Icon path={mdiImageOutline} size={1.5} className="conversation-icons" />
              <input ref="uploadImg" type="file" name="photo" className="upload-photo form-control-sm" id="photo" accept=".gif,.jpg,.jpeg,.png" onChange={this.fileUploader} />
            </label>
            <div onClick={this.startCamera}><Icon path={mdiCameraOutline} size={1.5} className="conversation-icons" id="camera" /></div>
            <div className="video-upload-div">
              <label className="custom-fileupload"><Icon path={mdiFileVideoOutline} size={1.5} className="conversation-icons" />
                <Input type="file" name="videos3" className="form-control-sm upload-video" id="videos3" value={this.state.videos3} onChange={this.uploadVideoToS3} accept="video/mp4,video/x-m4v,video/*" ref={ref => this.prevideos3 = ref} />
              </label>
            </div>
          </div>
          <div className="camera-div offset-md-2" id="cameradiv">
            <video id="video" width="300" height="300"></video>
            <canvas id="canvas" width="300" height="300"></canvas>
            <Button id="takephoto" className="take-photo-div offset-md-4" onClick={this.takePhoto} style={this.state.capturebuttonstyles}>Capture</Button>
          </div>

          <div className="show-uploaded-image" id="show-uploaded-image">
            <img src={this.state.imageUrl || ''} id="showphoto" className="img-responsive" />
          </div>
          <div className="video-progress-text">{this.state.videos3progresstext}</div>
          <div id="show-uploaded-video">
            <ReactPlayer
              url={"https://event360live.s3.amazonaws.com/public/" + this.state.code + "/posts/" + this.state.photoFileName}
              controls={true}
              playing={true}
              className="post-preview-video"
              onContextMenu={e => e.preventDefault()}
              config={{
                file: {
                  attributes: {
                    controlsList: 'nodownload',
                    disablepictureinpicture: 'true'
                  }
                }
              }}
            />
          </div>
          <div id="img_loading">{this.state.imgloading}</div>
          <div id="error_message"></div>
        </form>
        {
          this.state.updatepoints ?
            (
              <div>
                {
                  global.$points.conversationpost != null && global.$points.conversationpost.enable.includes("Y") ?
                    (
                      <UpdatePoints receiverid={this.props.receiverid} component="conversation" area="conversationpost" points={global.$points.conversationpost.points} />
                    )
                    :
                    ('')
                }
              </div>
            )
            :
            ('')
        }
      </div>
    );
  } //render

  getVideoS3FileName(id, fileName) {

    var key = id + '_' + 's3';
    key = key.replace(/,/g, '_');
    key = key.replace(/ /g, '_');
    key = key.replace(/&/g, '_');
    key = key.replace(/\./g, '');
    key = key.replace(/__/g, '_');
    key = key.toLowerCase();

    key = "post_" + key + ".mp4";

    fileName = fileName.toLowerCase();

    //this.setState({ videos3progress: 50 });

    return key;
  }

  getS3VideoFileName() {
    this.setState({ videos3progress: 75 });
    return this.state.code + "/posts/" + this.state.videotos3FileName;
  }

  uploadVideoToS3 = (event) => {
    const target = event.target;
    const name = target.name;
    let value = target.value

    if (name === 'videos3') {
      this.state.videotos3FileHandle = event.target.files[0];
      this.setState({ videos3progress: 25 });
      this.setState({ videos3progresstext: "Uploading..." });

      ////console.log("file3FileName = " + this.state.file3FileHandle.name)
      var date = new Date();
      var longFormat = date / 1000;
      var id = longFormat.toString();
      this.state.videotos3FileName = this.getVideoS3FileName(id, this.state.videotos3FileHandle.name);
      this.state.photoFileName = this.getVideoS3FileName(id, this.state.videotos3FileHandle.name);
    }
    else {
      this.setState(
        {
          [name]: value
        }
      );
    }

    const s3File = this.getS3VideoFileName();
    ////console.log('Uploading File3 = ' + this.state.file3FileHandle.name +" , Des = " + s3File );      
    Storage.put(s3File, this.state.videotos3FileHandle,
      {

        contentType: '/\Avideo/'
      })

      .then(
        result => {
          //////console.log(result);
          this.setState({ videos3progress: 100 });
          //this.setState({ videos3progresstext: "Video uploaded successfully" });
          this.setState({ videos3progresstext: "" });
          document.getElementById("show-uploaded-video").style.display = "block";
          this.forceUpdate();
        }
      )
      .catch(err => {
        //////console.log(err)
        this.setState({ videos3progresstext: "Unable to upload Video. Please try again" });
        //this.state.message = "Unable to upload file. Please try again"
        this.forceUpdate();
      }
      );


  }
  postMessage() {
    let apiName = serviceNames.getMessageService();
    let path = '';

    var date = new Date()
    var timestamp = date / 1000

    //this.setState({ showSpinner: true })

    //console.log(this.state.chatInput);
    var str = this.state.chatInput;
    str = str.replace(/(?:\r\n|\r|\n)/g, '<br>');
    //console.log(str);

    let myInit =
    {
      body:
      {
        'code': this.state.code,
        'command': 'postMessage',
        'messageid': timestamp.toString(),
        'sname': global.$attendeeDetails.fname + " " + global.$attendeeDetails.lname,
        'senderid': global.$attendeeDetails.userid,
        'receiverid': this.state.receiverid,
        'message': str,
        'image': this.state.photoFileName,
        'w': '0',
        'h': '0',
      },
      response: true,
      headers: {}
    }

    //console.log(myInit);
    API.post(apiName, path, myInit).then(response => {

      //console.log(response);

      if (response.err == null) {
        //console.log('postMessage ok');
        // Call the onSend callback with the chatInput message
        //console.log('ChatInput: calling onSend');
        //this.props.onSend(this.state.chatInput);
        document.getElementById("show-uploaded-image").style.display = "none";
        document.getElementById("show-uploaded-video").style.display = "none";
        this.setState({
          updatepoints: true,
          videos3progresstext: ""
        })
        this.state.imageUrl = '';
        this.state.photoFileName = '';
        this.props.onSend();
      }
      else {

        //console.log(response.err)
        /*
        this.setState({ 
            showSpinner: false,
            message: "Unable to place your bid. Please try again",
            bidmessagecolor:'color: red'
        });
        */
      }

    }
    ).catch(error => {
      /*
      this.setState({ showSpinner: false,
      message: "Unable to place your bid. Please try again",
      bidmessagecolor:'color: red'});
      */
      //console.log(error);
    });

  }
  getFileName(id, fileName) {
    var key = id;
    key = key.replace(/,/g, '_');
    key = key.replace(/ /g, '_');
    key = key.replace(/&/g, '_');
    key = key.replace(/\./g, '');
    key = key.replace(/__/g, '_');
    //console.log(key);
    key = key.toLowerCase();

    fileName = fileName.toLowerCase();

    if (fileName.indexOf("jpg") !== -1) {
      key = key + ".jpg";
    }
    else if (fileName.indexOf("gif") !== -1) {
      key = key + ".gif";
    }
    else if (fileName.indexOf("png") !== -1) {
      key = key + ".png";
    }
    else {
      this.state.photoFileName = "Format not supported";
    }

    return "post_" + key;
  }

  getS3FileName() {
    return this.state.code + "/posts/" + this.state.photoFileName;
  }

  //files
  fileUploader = (event) => {

    //file preview
    const file = this.refs.uploadImg.files[0]
    const reader = new FileReader();

    //////console.log("fileUploader");
    reader.onloadend = () => {
      this.setState({
        imageUrl: reader.result,
        imgloading: "Loading..."
      })
    }
    if (file) {
      reader.readAsDataURL(file);
      this.setState({
        imageUrl: reader.result
      })
    }
    else {
      this.setState({
        imageUrl: " "
      })
    }

    //file handler
    const target = event.target;
    const name = target.name;
    let value = target.value

    if (name === 'photo') {
      this.state.photoFileHandle = event.target.files[0];
      //////console.log("photoFileName = " + this.state.photoFileHandle.name)
      var date = new Date()
      var timestamp = date / 1000;

      this.state.photoFileName = this.getFileName(timestamp.toString(), this.state.photoFileHandle.name);
    }
    else {
      this.setState(
        {
          [name]: value
        }
      );
    }
    //file storage

    const s3File = this.getS3FileName();

    ////console.log('file=' +this.refs.uploadImg.files[0].name+", Des ="+s3File);

    //console.log(this.state.photoFileName);
    Storage.put(s3File, this.state.photoFileHandle,
      {
        contentType: 'image/png'
      })
      .then(
        result => {
          //////console.log(result);
          //this.state.message = "photo successfully submitted"
          this.setState({ imgloading: "" });
          this.copyFiles(this.state.photoFileName);
          this.forceUpdate();
          document.getElementById("show-uploaded-image").style.display = "block";
        }
      )
      .catch(err => {
        //////console.log(err)
        this.state.message = "Unable to upload photo. Please try again"
        this.forceUpdate();

      }
      );

  }

  copyFiles(photo) {
    let apiName = serviceNames.getUserService();
    let path = ''; //replace this with the path you have configured on your API

    let myInit =
    {
      body:
      {
        'code': this.state.code,
        'command': 'copyS3Files',
        'srcbucket': 'event360live',
        'desbucket': 'wesicore',
        'fileFrom': 'posts',
        'fileTo': 'upload/posts',
        'photo': photo
      },
      response: true,
      headers: {}

    }
    //console.log(myInit);
    API.post(apiName, path, myInit).then(response => {

      //console.log ("response.data ==> " + response.data + "response.err ==> " + response.err);
      if (response.err == null && response.data != null) {
        //this.getS3PrivateImg(photo) //Suresh:  Is this required?
      }
      else {
      }

    }).catch(error => {
      //console.log(error.response)
    });
  }


  //
  //  Capture Photo crom Camera
  // 

  showTakePhoto() {
    //Show Take Photo button
    var takephoto = document.getElementById('takephoto');
    document.getElementById("cameradiv").style.display = "block";
    document.getElementById("camera").style.display = "none";
    document.getElementById("takephoto").style.display = "block";
    //takephoto.style.display = "block";
  }

  hideTakePhoto() {
    //Hide Take Photo button

    var takephoto = document.getElementById('takephoto');
    //takephoto.style.display = "none";       
  }

  setPhotoFileForCamera() {
    var date = new Date()
    var timestamp = date / 1000;
    this.state.photoFileName = this.getFileName(timestamp.toString(), 'fromcamera.png');

    //console.log(this.state.photoFileName);
  }

  startCamera(e) {
    var video = document.getElementById('video');
    document.getElementById("show-uploaded-image").style.display = "none";
    document.getElementById("show-uploaded-video").style.display = "none";

    this.showTakePhoto();

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {

      navigator.mediaDevices.getUserMedia({ video: true })
        .then(function (stream) {
          video.srcObject = stream;
          video.play();
        })
        .catch(function (err) {
          //console.log("An error occurred: " + err);
        });
    }
  }

  stopCamera() {
    var video = document.getElementById('video');

    var stream = video.srcObject;
    if (stream == null)
      return;

    var tracks = stream.getTracks();
    if (tracks == null)
      return;

    tracks.forEach(function (track) {
      track.stop();
    });

    video.srcObject = null;
  }

  uploadPhotoFromCamera(imageData) {
    this.setPhotoFileForCamera();
    const s3File = this.getS3FileName();
    //console.log(s3File);

    Storage.put(s3File, imageData,
      {
        contentType: 'image/png'
      })
      .then(
        result => {

          //console.log(result);

          /*
          document.getElementById("photouploadingmessage").innerHTML = "<h6 style='color: green'>Photo successfully updated</h6>";
          this.forceUpdate();
         //console.log('copyFiles ' + this.state.photoFileName);
         */
          this.copyFiles(this.state.photoFileName);

        })
      .catch(err => {
        document.getElementById("photouploadingmessage").innerHTML = "<h6 style='color: red'>Unable to upload photo. Please try again</h6>";
        this.forceUpdate();
      });
  }

  takePhoto(e) {
    //var height = 250;

    var video = document.getElementById('video');
    var canvas = document.getElementById('canvas');
    var context = canvas.getContext('2d');
    var photo = document.getElementById('showphoto');

    canvas.style.display = "none";

    var width = 250;
    var height = 250;

    //var height = video.videoHeight / (video.videoWidth/width);

    // NOTE:  Image control uses data:Base64 and S3 requires blob.  

    context.drawImage(video, 0, 0, width, height);

    var data = canvas.toDataURL('image/png');
    photo.setAttribute('src', data);
    //this.setState({imageUrl: data});

    document.getElementById("show-uploaded-image").style.display = "block";
    this.stopCamera();

    canvas.toBlob(blob => {
      this.uploadPhotoFromCamera(blob);
    });

    this.hideTakePhoto();
    document.getElementById("cameradiv").style.display = "none";
    document.getElementById("takephoto").style.display = "none";
    document.getElementById("camera").style.display = "block";
  }

}



export default PostInput;