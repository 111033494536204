import React from 'react';
import { Row, Col, Input, Label } from 'reactstrap';

class Questions extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataloaded: false,
            question1: '',
            question1title: '',
            question1value: '',
            question1required: false,
            question2: '',
            question2title: '',
            question2value: '',
            question2required: false,
            question3: '',
            question3title: '',
            question3value: '',
            question3required: false,
            question4: '',
            question4title: '',
            question4value: '',
            question4required: false,
            question5: '',
            question5title: '',
            question5value: '',
            question5required: false,
            question6: '',
            question6title: '',
            question6value: '',
            question6required: false,
            question7: '',
            question7title: '',
            question7value: '',
            question7required: false,
            question8: '',
            question8title: '',
            question8value: '',
            question8required: false,
        };
    }
    handleDropdownChange = (event) => {
        const target = event.target;
        const name = target.name;
        let value = target.value;
        this.setState({ [name]: event.target.value })
    }
    getQuestions() {
        var questions = "";
        if (this.props.type == "attendee") {
            questions = global.$registrationform.questions;
        }
        else if (this.props.type == "sponsor") {
            questions = global.$registrationform.sponsor.questions;
        }
        else if (this.props.type == "exhibitor") {
            questions = global.$registrationform.exhibitor.questions;
        }
        else if (this.props.type == "tab1") {
            questions = global.$registrationform.tab1.questions;
        }
        else if (this.props.type == "tab2") {
            questions = global.$registrationform.tab2.questions;
        }
        else if (this.props.type == "tab3") {
            questions = global.$registrationform.tab3.questions;
        }
        else if (this.props.type == "tab4") {
            questions = global.$registrationform.tab4.questions;
        }
        else if (this.props.type == "tab5") {
            questions = global.$registrationform.tab5.questions;
        }
       
        return questions;
    }
    render() {

        var questions = this.getQuestions();

        global.$regquestionsinfo = this.state;

        if (this.state.dataloaded) {
            if (questions.required != null && questions.required == true) {
                return (
                    <div className="reg-questions-div" style={global.$registrationform.styles.questions.maindiv}>
                        {this.renderSectionHeader(questions)}
                        {this.renderSectionDesc(questions)}
                        {this.renderQuestions(questions)}
                    </div>
                );
            }
            else {
                return null;
            }
        }
        else {
            if (global.$loggedinuser != null) {
                var user = global.$loggedinuser;

                // fill in question answers from logged in user in update registration
                this.updateQuestionAnswers(user);
            }
            else {
                this.setState({ dataloaded: true });
            }
            return null;
        }
    }

    renderSectionHeader(questions) {
        return (
            <h4 className="reg-option-section" style={global.$registrationform.styles.questions.header}>{questions.header}</h4>
        )
    }
    renderSectionDesc(questions) {
        return (
            <div dangerouslySetInnerHTML={{ __html: questions.description }}></div>
        )
    }
    renderQuestions(questions) {
        return (
            <div>
                {
                    questions.question1 !== null ?
                        (
                            this.displayRegQuestions(questions.question1, "question1")
                        )
                        :
                        ('')
                }
                {
                    questions.question2 !== null ?
                        (
                            this.displayRegQuestions(questions.question2, "question2")
                        )
                        :
                        ('')
                }
                {
                    questions.question3 !== null ?
                        (
                            this.displayRegQuestions(questions.question3, "question3")
                        )
                        :
                        ('')
                }
                {
                    questions.question4 !== null ?
                        (
                            this.displayRegQuestions(questions.question4, "question4")
                        )
                        :
                        ('')
                }
                {
                    questions.question5 !== null ?
                        (
                            this.displayRegQuestions(questions.question5, "question5")
                        )
                        :
                        ('')
                }
                {
                    questions.question6 !== null ?
                        (
                            this.displayRegQuestions(questions.question6, "question6")
                        )
                        :
                        ('')
                }
                {
                    questions.question7 !== null ?
                        (
                            this.displayRegQuestions(questions.question7, "question7")
                        )
                        :
                        ('')
                }
                {
                    questions.question8 !== null ?
                        (
                            this.displayRegQuestions(questions.question8, "question8")
                        )
                        :
                        ('')
                }
            </div>
        )
    }
    displayRegQuestions(question, variable) {
        var variablevalue = variable + 'value';
        var questiontitle = variable + 'title';
        var questionrequired = variable + 'required';
        if (question != null) {
            this.state[questiontitle] = question.choice;
            this.state[questionrequired] = question.required;
            var array = question.values.split(",");
            //console.log(array);
            
            if (this.state[variable] == "") {
                this.state[variable] = array[0];
                //console.log(array[0])
            }

            if (this.state[variable] == 'Input'){
                this.state[variable] = ''
                //console.log(this.state[variable]);
            }

            let dropdownItems = array.map((item) => {
                return (<option key={item} value={item}>{item}</option>)
            });
            return (
                <div>
                    <Row className="reg-questions-table-header">
                        <Col md={8} className="reg-questions-header1" style={global.$registrationform.styles.questions.fieldlabel}>
                            <p className="reg-question">{question.choice}</p>
                        </Col>
                        {
                        this.state[variable] !== '' && array[0] !== 'Input' ?
                            <Col md={4} className="reg-questions-header2">
                                
                                    <Input type="select" className="form-control" id="reg_dropdown_item" name={variable} value={this.state[variable]} onChange={this.handleDropdownChange}>
                                        {dropdownItems}
                                    </Input>
                            </Col>
                            :<Row>
                                <Col md={12}>
                                    <Input type="text" name={variable} value={this.state[variable]}  className="reg-question-input form-control-sm" onChange={this.handleDropdownChange}></Input>
                                </Col>
                            </Row>
                        }
                    </Row>
                    {this.renderCommentQuestion(question, variable, variablevalue)}
                    <br />
                </div>
            )
        }
        else {
            return null;
        }
    }
    renderCommentQuestion(question, variable, variablevalue) {
        return (
            question.enableinputfield != null && question.enableinputfield == true ?
                (
                    <div className="reg-question-field">
                        {
                            this.state[variable] == "Other" || this.state[variable] == "Yes" ?
                                (
                                    <Row>
                                        <Col md={12}>
                                            <Label for="field" className="reg-question-label" style={global.$registrationform.styles.questions.fieldlabel}>{question.inputfieldlabel}</Label>
                                            <Input type="text" name={variablevalue} className="reg-question-input form-control-sm" value={this.state[variablevalue]} onChange={this.handleDropdownChange}></Input>
                                        </Col>
                                    </Row>
                                )
                                :
                                ('')
                        }
                    </div>
                )
                :
                ('')

        )
    }

    // NOTE: sample question format. Common function can be used. 
    
    updateQuestionAnswers(user) {
        var q1 = "";
        var q2 = "";
        var q3 = "";
        var q4 = "";
        var q5 = "";
        var q6 = "";
        var q7 = "";
        var q8 = "";
        if (user.q1.includes("answer")) {
            q1 = user.q1.substring(
                user.q1.lastIndexOf(";answer:") + 8);
        }
        if (user.q2.includes("answer")) {
            q2 = user.q2.substring(
                user.q2.lastIndexOf(";answer:") + 8);
        }

        if (user.q3.includes("answer")) {
            q3 = user.q3.substring(
                user.q3.lastIndexOf(";answer:") + 8);
        }

        if (user.q4.includes("answer")) {
            q4 = user.q4.substring(
                user.q4.lastIndexOf(";answer:") + 8);
        }

        if (user.q5.includes("answer")) {
            q5 = user.q5.substring(
                user.q5.lastIndexOf(";answer:") + 8);
        }

        if (user.q6.includes("answer")) {
            q6 = user.q6.substring(
                user.q6.lastIndexOf(";answer:") + 8);
        }

        if (user.q7.includes("answer")) {
            q7 = user.q7.substring(
                user.q7.lastIndexOf(";answer:") + 8);
        }

        if (user.q8.includes("answer")) {
            q8 = user.q8.substring(
                user.q8.lastIndexOf(";answer:") + 8);
        }
        //console.log(q1, q2, q3, q4)
        this.setState({
            question1: q1,
            question2: q2,
            question3: q3,
            question4: q4,
            question5: q5,
            question6: q6,
            question7: q7,
            question8: q8,
            dataloaded: true,
        })
    }
}
export default Questions;


