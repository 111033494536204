import React from "react";
import CommonFunctions from "../RegCommon/CommonFunctions";
import EventcodeFunctions from "../RegCommon/EventcodeFunctions";
import TravconFunctions from '../RegCommon/CustomerFunctions/TravconFunctions';

class PaymentTotalAmount extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            code: global.$code,
        };
    }

    render() {

        var configstyles = global.$registrationform.styles.payment
        var configpayment = global.$registrationform.setup[this.props.tabid].payment;

        return (
            <div>
                {this.renderTotalAmount(configstyles, configpayment)}
            </div>
        );
    }

    renderTotalAmount(configstyles, configpayment) {
        return (
            <div className="reg-option-section" style={configstyles.header}>
                <p>{configpayment.header}</p>
                <p className="section-total"><div dangerouslySetInnerHTML={{ __html: "Total = " + this.renderCurrency() + this.renderTotal() }}></div></p>
            </div>
        )
    }

    renderTotal() {
        //console.log("render total")
        var pendingamount = CommonFunctions.getLoggedInUserPendingAmount(); // previous balance
        var registeredItems = CommonFunctions.getLoggedInUserRegItems(); // Previously registered Items
        var selectedRegItems = CommonFunctions.getUserSelectedRegItems(); // Currently  selected items to purchase

        var totalamount = pendingamount;
        global.pendingamounts = pendingamount || 0
        if (selectedRegItems.length != 0) {
            global.$selectedregoptions.map((regitem) => {
                if (regitem.dropdownvalue != "select") {
                    if (CommonFunctions.isUserLoggedin() && (EventcodeFunctions.isTravconExhibitor(global.$code) || EventcodeFunctions.isTravconAttendee(global.$code))) {
                        
                         var amount = TravconFunctions.findTheAmountfromSelectedItem(regitem, registeredItems);
                         if (regitem.inputtype == "dropdown" && regitem.selecteddropdown) {
                            for (const item of regitem.selecteddropdown) {
                                totalamount += Number(item.price);
                                regitem.selectedprice = item.price
                            }
                        }                         
                        else {
                            if (regitem.quantity == "") {
                                totalamount = Number(totalamount) + Number(amount)
                            }
                            else if (regitem.quantity != "") {   // only when the raffle quantity exists
                                totalamount = Number(totalamount) + (Number(amount) * Number(regitem.quantity))
                            }
                        }
                         
                     }
                    else {

                        if (regitem.inputtype == "dropdown" && regitem.selecteddropdown) {
                            for (const item of regitem.selecteddropdown) {
                                totalamount += Number(item.price);
                                regitem.selectedprice = item.price
                            }
                        }
                        else {
                            if (regitem.quantity == "") {
                                totalamount = Number(totalamount) + Number(regitem.itemprice)
                           
                            }
                            else if (regitem.quantity != "") {   // only when the raffle quantity exists
                                totalamount = Number(totalamount) + (Number(regitem.itemprice) * Number(regitem.quantity))
                            }
                        }
                    
                         
                    }

                    totalamount = CommonFunctions.getBoothUpgradeRegitemPrice(registeredItems,regitem,totalamount,this.props.tabid)
                    
                }
                
            })
           
            // Update the balance with discount amount
            if (CommonFunctions.isRegDiscountExist()) {
                totalamount = Number(totalamount) - Number(global.$regdiscountinfo.discountprice)
            }

            // To prevent negative amount
            if (Number(totalamount) < 0) {
                totalamount = 0;
            }
            console.log(totalamount);
            global.$totalamountfordiscount = totalamount;

            return `${totalamount}`
        }
        else {
            // Update the balance with discount amount
            if (CommonFunctions.isRegDiscountExist()) {
                totalamount = Number(totalamount) - Number(global.$regdiscountinfo.discountprice)
            }

            // To prevent negative amount
            if (Number(totalamount) < 0) {
                totalamount = 0;
            }
            global.$totalamountfordiscount = totalamount;

            return `${totalamount}`
        }
    }

    renderCurrency() {
        // Return rupee currency;
        if (global.$registrationform.setup[this.props.tabid].currency != null && global.$registrationform.setup[this.props.tabid].currency == "rupee") {
            return `&#8377;`
        }
        // Return dollar currency
        else {
            return `$`
        }
    }
}
export default PaymentTotalAmount;