import React from 'react';
import { Col, Row } from 'reactstrap';
import { HourGlass } from "react-awesome-spinners";
import { API, Analytics } from 'aws-amplify';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { Link } from 'react-router-dom';
import Sponsors from '../Components/Sponsors';
import commonProfile from '../images/common_profile.png';
import SessionTrackItem from './SessionTrackItem';

/*
var speakerjson =
[

]
*/

class SessionTracks extends React.Component {
    constructor(props) {

       //console.log(props.sessionid)
        super(props);
        this.state = {
            code: global.$code,
            sid: props.sessionid,
            scheduleheadertimestyles: global.$schedule.styles.scheduleheadertime,
            scheduleheadertitlestyles: global.$schedule.styles.scheduleheadertitle,
            scheduleheaderspeakerstyles: global.$schedule.styles.scheduleheaderspeakers,
            scheduletimestyles: global.$schedule.styles.scheduletime,
            scheduletitlestyles: global.$schedule.styles.scheduletitle,
            scheduledescstyles: global.$schedule.styles.scheduledesc,
            schedulespeakerstyles: global.$schedule.styles.schedulespeakers,
            videoiconstyles: global.$schedule.styles.videoicon,
            dataloaded: false
        };
        //console.log(global.$speakers)
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {

        //console.log("SpeakerInfo: render")

        Analytics.record({ name: 'PageView', attributes: { name: 'eventsites-' + this.state.code + '-speakerinfo' } });
        if (this.state.dataloaded) {
            return (
                <div>
                    <div>
                        <div>
                            <h3>{global.$sessionObj.TITLE}</h3>
                            <hr />
                            <Row className="session-tracks">
                                {this.getSessionInfo()}
                            </Row>
                        </div>
                    </div>
                </div>
            );
        }
        else {
            this.getSessionData();
            return (
                <div>
                    <Row>
                        <Col md={6} className="offset-5">
                            <div className="loading-div">
                                <div className="loading-text">Loading....</div>
                                {/*<ReactSpinner/>*/}
                            </div>
                        </Col>
                    </Row>
                </div>
            )
        }
    }




    getSessionData() {
        var sessionObj = global.$schedulejson.find(globalSession => globalSession.SID === this.state.sid)

        global.$sessionObj = sessionObj
       //console.log(global.$sessionObj);
        //console.log(this.state);

        this.setState({ dataloaded: true })
    }

    getSessionInfo() {
        var array = [];
        array = global.$sessionObj.FILE3;
        array = array.split(",");
        return array.map(item => {
           //console.log(item);
            return (
                <Col md={4}>
                    <SessionTrackItem tag={item} />
                </Col>
            )
        })

    }

}

export default SessionTracks;