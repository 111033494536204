import React from "react";
import { Button, Input, Row, Col, Label } from 'reactstrap';
import CommonFunctions from "../RegCommon/CommonFunctions";
import EventCodeFunctions from "../RegCommon/EventcodeFunctions";
import TravconFunctions from "../RegCommon/CustomerFunctions/TravconFunctions";
import APIFunctions from "../RegCommon/APIFunctions";
import PaymentPopup from "./PaymentPopup";
import { Spinner } from 'react-bootstrap';
import Validations from "./Validations";
import RegItemStrings from "../RegCommon/RegItemStrings";

//var Validations  = require("./Validations");
//var TravconFunctions = require('../RegCommon/CustomerFunctions/TravconFunctions');

let guestregerror = "You have filled in the guest name, Please select guest registration item to register guest.";
let guestnameerror = "You have selected guest registration item, please fill in the guest name to continue.";
let commonEmailError = "Attendee and Guest Emails should not be same."
let guesterror = "Guest information is entered but guest registration option is not selected. Please select a guest registration option."


class RegisterButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            code: global.$code,
            hideregister: false,
            isLoading: false,
            proceedtopayment: false,
            message: "",
            dataloaded: false
        };
        this.refreshComponent = this.refreshComponent.bind(this);
        this.errorCommonFn = this.errorCommonFn.bind(this);
    }

    refreshComponent() {
        this.setState({
            dataloaded: true,
            message: ""
        })
        
    }

    errorCommonFn(dataloaded,message) {
        console.log(dataloaded,message);
        this.setState({
            dataloaded: dataloaded,
            message: message
        })
        return false;
    }

    render() {

        var configstyles = global.$registrationform.styles.payment
        var configpayment = global.$registrationform.payment;

        return (
            <div>
                <div className="payment-message" style={global.$registrationform.styles.payment.message}>{this.state.message}</div>
                {this.renderRegisterButton(configstyles)}
            </div>
        );
    }

    renderRegisterButton(configstyles) {
        var buttontext = "Register";
        if (CommonFunctions.isUserLoggedin()) {
            buttontext = "Submit";
        }
        

        if (this.state.message != "" && this.state.message != " ") {
            global.$openpaymentpopup = false;
            this.state.proceedtopayment = false;
        }
        return (
            <div className="reg-continue-div">

                <div className="loading-button-div"
                >
                    <Spinner
                        animation="border"
                        role="status"
                        size="sm"
                        hidden={!this.state.isLoading}
                        className="loading-button-register"
                    />
                </div>
                {
                    global.$hideregisterbutton == null ?
                        (
                            <Button className="btn btn-reg-continue" style={configstyles.button} onClick={() => { this.onRegisterClicked() }}>{buttontext}</Button>
                        )
                        :
                        ('')
                }
                {
                    this.state.proceedtopayment == true ?
                        (
                            <PaymentPopup selectedPaymentOption={this.props.selectedpaymentoption} tabid={this.props.tabid}  type={this.props.formtype} refreshComponent={this.refreshComponent} openpopup={true} />
                        ) :
                        ('')
                }
            </div>
        )
    }

    /*
        Starts registration process
    */
    async onRegisterClicked() 
    {
       
        var result = false
        
        var result = await this.doCommonValidations()
        if (!result) {
            console.log('common validations failed.')
            return
        }

        result = this.checkEmailsMatch(global.$confirmemail,global.$regemail);
        if (!result) {
            console.log('EmailValidation failed.');
            return
        }

        result = this.validateEventGuestSection()
        if (!result) {
            console.log('validateGuestSection failed.')
            return
        }

        result = await this.processRegItems()
        if (!result) {
            console.log('processRegItems failed.')
            return
        }
       
        result = this.doEventValidations()
        if (!result) {
            console.log('EventValidations failed.')
            return
        }

        //Proceed with payment
        global.$openpaymentpopup = true;
        this.setState({
            proceedtopayment: true,
            isLoading: false,
            message: ""
        })
    }
    
    selectAnyRequiredRegitems() {
        const tabId = this.props.tabid;
        const regSetup = global.$registrationform.setup[tabId];
        
        if (regSetup?.registrationconditions?.requiredregitems) {
            const jsonRegIdArray = regSetup.registrationconditions.requiredregitems;
            
            const selectedRegItems = global.$regitems.filter(
                regitem => regitem.isChecked === true && regitem.dropdownvalue !== "select"
            );
            
            if (CommonFunctions.isUserLoggedin()) {
                const purchaseItems = global.$regitems.filter(regitem => {
                    return JSON.parse(global.$regitemsjson).some(item => item.itemid === regitem.data5);
                });
                selectedRegItems.push(...purchaseItems);
            }
            
            const result = selectedRegItems.some(obj => jsonRegIdArray.includes(obj.data5));
            return result;
        }
        return true;
    }
    

    checkEmailsMatch(email1,email2) {
        console.log(global.$confirmemailcheck);
        if (email1 !== email2 && global.$confirmemailcheck == true) {
            this.setState({
                isLoading: false,
                message: "Email and confirm email do not match. Please enter matching emails."
            });
            return false
        }
        return true;
    }

    async doCommonValidations() {

        var loggedin = CommonFunctions.isUserLoggedin();
        
        if (!Validations.validateEmailFormat(global.$regemail)) {
            this.setState({
                isLoading: false,
                message: "Enter a valid email ID"
            });
            return false
        }
        
        //Validate Terms and Conditions        
        if (!Validations.validateTermsAndConditions(this.props.formtype)) {
            this.setState({
                isLoading: false,
                message: "Please agree to terms and conditions"
            });
            return false
        }
        
        if (!Validations.validateFormFields(this.props.tabid, global.$registrationform, global.$regattendeeinfo)) {
            
            this.setState({
                isLoading: false,
                message: "Please fill all the required (*) fields to continue."
            });
            return false
        }
        
        if (!Validations.validateQuestions()) {
            this.setState({
                isLoading: false,
                message: "Please answer all the required (*) questions to continue."
            });
            return false;
        }
        
        //User id and login check
        var isUserAlreadyRegistered = await Validations.isUserAlreadyRegistered(global.$regemail)
        if (isUserAlreadyRegistered && !loggedin) {
            this.setState({
                isLoading: false,
                message: `${global.$regemail} is already registered`
            });
            return false
        }

        // check guest email 
        if (CommonFunctions.isGuestEnabledFromConfig()) {
            var isGuestUserAlreadyRegistered = await Validations.isUserAlreadyRegistered(global.$reguestemail)
            if (isGuestUserAlreadyRegistered) {
                this.setState({
                    isLoading: false,
                    message: `${global.$reguestemail} is already registered`
                });
                return false
            }
        }

        // First section selection - check
        var groupnames = global.$registrationform.setup[this.props.tabid].groupnames;
        var section1 = CommonFunctions.getValueFromMeCard(groupnames, "one");
        var section1required = global.$registrationform.setup[this.props.tabid].section1required;
        var selectedRegItems = CommonFunctions.getUserSelectedRegItems(); // Currently  selected items to purchase
        var regitemcheck = false 
        
        //console.log(selectedRegItems)
        // Check if any item is selected from first section
        if (section1required && !loggedin) {

            for(const regItem of selectedRegItems) {
                //console.log(regItem)
                if (regItem.groupname == section1) {
                    regitemcheck = true;
                    break
                }
            }
        }
        else regitemcheck = true


        if (!this.selectAnyRequiredRegitems()) {
            this.setState({
                message: "Please select any required registration option",
                isLoading: false
            });
            
            return false;
        }

        if (!regitemcheck) {

            this.setState({
                message: "Please select a registration option from the first section to continue.",
                isLoading: false
            });
            
            return false;
        }    

        //console.log('doCommonValidations() - ok')
        return true
    }

    

    /*
        - Updates global payment amounts
        - Validates signup counts
        - Prepares regitems
    */
    async processRegItems()
    {
        this.addSelectedRegitemsinArray();  // add selected regitems in the global.$selectedRegItems array
        var registeredItems = CommonFunctions.getLoggedInUserRegItems();    // Previously registered Items
        var selectedRegItems = CommonFunctions.getUserSelectedRegItems();   // Currently  selected items to purchase
        var regItemsForSignupCount = CommonFunctions.getRegItemsForSignupCount(selectedRegItems);
        
       
        var regitemsforvalidation = [];        
        regitemsforvalidation.push(registeredItems);

        var selecteditemsJson = [];
        var regitemsforvalidationJson = [];

        var pendingamount = CommonFunctions.getLoggedInUserPendingAmount(); // previous balance
        var totalamount = pendingamount;

        // handles reg option validation and adds total price
        // Step8: add final regitems and final price
        //console.log(selectedRegItems);
        if (selectedRegItems.length != 0) {

            var totalregitemsjson = [];
            var attendeeregitemsjson = [];
            var guestregitemsjson = [];

            for (const regitem of selectedRegItems) {

                // get total amount to pay
                if (CommonFunctions.isUserLoggedin() && (EventCodeFunctions.isTravconExhibitor(global.$code) || EventCodeFunctions.isTravconAttendee(global.$code))) {
                    
                    var amount = TravconFunctions.findTheAmountfromSelectedItem(regitem, registeredItems);
                    
                    if (regitem.inputtype == "dropdown" && regitem.selecteddropdown) {
                        for (const item of regitem.selecteddropdown) {
                            totalamount += Number(item.price);
                            regitem.selectedprice = item.price
                        }
                    }                         
                    else {
                        if (regitem.quantity == "") {
                            totalamount = Number(totalamount) + Number(amount)
                        }
                        else if (regitem.quantity != "") {   // only when the raffle quantity exists
                            totalamount = Number(totalamount) + (Number(amount) * Number(regitem.quantity))
                        }
                    }
                     
                 }
                 else {

                    if (regitem.inputtype == "dropdown" && regitem.selecteddropdown) {
                        for (const item of regitem.selecteddropdown) {
                            totalamount += Number(item.price);
                            regitem.selectedprice = item.price
                        }
                    }
                    else {
                        if (regitem.quantity == "") {
                            totalamount = Number(totalamount) + Number(regitem.itemprice)
                       
                        }
                        else if (regitem.quantity != "") {   // only when the raffle quantity exists
                            totalamount = Number(totalamount) + (Number(regitem.itemprice) * Number(regitem.quantity))
                        }
                    }
                }
                // USED TO UPDATED THE BOOTHUPGRADE REGITEM PRICE ( PREVIOUSE REGITEM PRICE - NEW REGITEM )
                totalamount = CommonFunctions.getBoothUpgradeRegitemPrice(registeredItems,regitem,totalamount,this.props.tabid)


                if (regitem.groupname.includes("guest") || regitem.groupname.includes("Guest")) {
                    totalregitemsjson.push(this.formatregitemsjson(regitem))
                    guestregitemsjson.push(this.formatregitemsjson(regitem));
                    global.$guestexist = true;                 
                }
                else {
                    totalregitemsjson.push(this.formatregitemsjson(regitem));
                    attendeeregitemsjson.push(this.formatregitemsjson(regitem));
                }

                if (regitem.quantiyty != "" && regitem.quantity != "0") {
                    regitemsforvalidationJson.push(this.regItemJson(regitem));
                    selecteditemsJson.push(this.regItemJson(regitem))
                }
                else {
                    regitemsforvalidationJson.push(this.regItemJson(regitem));
                    selecteditemsJson.push(this.regItemJson(regitem))
                }
            }
           

            // Update the balance with discount amount
            if (CommonFunctions.isRegDiscountExist()) {
                totalamount = Number(totalamount) - Number(global.$regdiscountinfo.discountprice)
            }

            // To prevent negative amount
            if (Number(totalamount) < 0) {
                totalamount = 0;
            }
       
            global.$regamount = totalamount;
            global.$finalamount = totalamount;
            global.$regattendeestatus = true

            global.$registereditems     = registeredItems;
            global.$totalregitems       = JSON.stringify(totalregitemsjson) //totalregitems.toString();
            global.$attendeeregitems    = JSON.stringify(attendeeregitemsjson) //attendeeregitems.toString();
            global.$guestregitems       = JSON.stringify(guestregitemsjson) //guestregitems.toString();
            global.$regItemsForSignupCount = JSON.stringify(regItemsForSignupCount);
        }
        else {

            if (CommonFunctions.isUserLoggedin()) {

                if (totalamount != null && totalamount != "" && totalamount != 0) {

                    global.$totalregitems = "";
                    global.$attendeeregitems = "";
                    global.$guestregitems = "";

                    // Update the balance with discount amount
                    if (CommonFunctions.isRegDiscountExist()) {
                        totalamount = Number(totalamount) - Number(global.$regdiscountinfo.discountprice)
                    }

                    // To prevent negative amount
                    if (Number(totalamount) < 0) {
                        totalamount = 0;
                    }
                    global.$regamount = totalamount;
                    global.$finalamount = totalamount;
                    global.$regattendeestatus = true
                }
            }
            else {

                this.setState({
                    message: "Please select a registration option to continue.",
                    isLoading: false
                });

                return false;
            }
        }
        if (CommonFunctions.isUserLoggedin() && regItemsForSignupCount.length <= 0) {          
            if (EventCodeFunctions.isTravconExhibitor(global.$code) || EventCodeFunctions.isMetro(global.$code) || EventCodeFunctions.isTravconAttendee(global.$code)) {
                return true
            }
        }
        else {         
            var validateRegItems = await APIFunctions.validateRegItemSignupCount(JSON.stringify(regItemsForSignupCount));           
            if (validateRegItems.data == true) {
                return true
            }
            else {
                console.log('processRegItems: validateRegItemSignupCount failed')
                return false
            }
        }

        return true
    }
    
    validateGuestSection()
    {     
       
        var selectedregitems = CommonFunctions.getUserSelectedRegItems(); // Currently  selected items to purchase
        var loggedin = CommonFunctions.isUserLoggedin();
       
        if (CommonFunctions.isGuestEnabledFromConfig()) {
            if (CommonFunctions.isUserSelectedGuest(selectedregitems)) {
                if (Validations.validateEmailFormat(global.$reguestemail) ) {
                    if (Validations.validateAttendeeAndGuestEmail(global.$regemail, global.$reguestemail)) {                      
                            if (Validations.validateFormFields("guest", global.$registrationform, global.$regguestinfo)) {
                                //console.log("guest ok")
                                global.$reggueststatus = true;
                            }
                            else {
                                this.setState({
                                    isLoading: false,
                                    message: "Please fill all the required (*) fields to continue."
                                });
                                return false;
                            }                       
                    }
                    else {
                        this.setState({ message: "Attendee and Guest Emails should not be same" });
                        return false;
                    }
                }
                else {
                    this.setState({ message: "Enter a valid guest email ID" });
                    return false;
                }
            }
        }
        return true
    }

    /*
       event guest registration validation 
    */
    validateEventGuestSection(){
        if (EventCodeFunctions.isMetro(global.$code)){  // metro2023convention guest validation
            var guestEmail = global.$registrationform.guests.personal.email.required;
            if (guestEmail === true){
                return this.validateGuestSection()
            }
            else return true;
        }
        else return this.validateGuestSection()     // other event guest validation
    }

    doEventValidations()
    {
        if (EventCodeFunctions.isTravconAttendee(global.$code)) {
            return this.validateTravConAttendee()
        }
        else if (EventCodeFunctions.isTravconExhibitor(global.$code)) {
            return this.validateTravConExhibitor()
        }
        else if (EventCodeFunctions.isMetro(global.$code) || EventCodeFunctions.isMetromeeting(global.$code)) {
            return this.validateMetroGuest()
        }

        return true
    }

    /*
        Attendee validations
    */
    validateTravConAttendee()
    {
        var selectedregitems = CommonFunctions.getUserSelectedRegItems(); // Currently  selected items to purchase
        var loggedin = CommonFunctions.isUserLoggedin();
        var selecteditems = global.$totalregitems;


        if (!TravconFunctions.ValidateTravelerEntrepreneurMarketplace(selectedregitems)) {
            this.setState({
                isLoading: false,
                message: "Enter the valid code provided to register for Traveler Entrepreneur Marketplace"
            })
            return false;
        }

        // Guest selection checks
       
        var guestUserDetails = CommonFunctions.isGuestUserDetails(); //  guest details
        var isGuestUserRegItem = CommonFunctions.isGuestUserRegItem(selectedregitems) // guest regitems
        var isGuestUpdate = CommonFunctions.isGuestUpdate();
        var isCommonEmail = CommonFunctions.isCommonEmail();


        if (isCommonEmail) {
            return this.errorCommonFn(false,commonEmailError)
        }
    
        if (loggedin === false) {
            if (guestUserDetails && isGuestUserRegItem === false) {
                return this.errorCommonFn(false,guestregerror)
            }
    
            if (guestUserDetails === false && isGuestUserRegItem) {
                return this.errorCommonFn(false,guestnameerror)
            }
        }

        if (loggedin === true) {
            if(isGuestUpdate){
                if (guestUserDetails === false) {
                    return this.errorCommonFn(false,guestnameerror)
                }
            }
            if(isGuestUpdate === false){
                if (guestUserDetails && isGuestUserRegItem === false) {
                    return this.errorCommonFn(false,guestregerror)
                }
        
                if (guestUserDetails === false && isGuestUserRegItem) {
                    return this.errorCommonFn(false,guestnameerror)
                }
            }
        }

        //Upgrade checks
        var commonstrings = RegItemStrings.commonConstants;
        // console.log(commonstrings);
        // console.log(selecteditems);
        if (loggedin) {

            if (selecteditems != null && selecteditems !== "") {
                if (selecteditems.includes(commonstrings.mainstageticket) || selecteditems.includes(commonstrings.newbiebootcamp) || selecteditems.includes(commonstrings.medicalspanishsun) || selecteditems.includes(commonstrings.medicalspanishwed)) {
                    //console.log(Strings)
                    // Check for upgrade regitem validation
                    var result = TravconFunctions.validateTravconRegItemsForUpgrade()
                    if (result.result == false) {
                        this.setState({
                            message: result.message,
                            isLoading: false
                        });
                        return false;
                    }
                }
            }
            else{
                this.setState({
                    message: "Please select a registration options to continue.",
                    isLoading: false
                });
                return false;
            }
        }
        
        //console.log('validateTravConAttendee() - ok')
        return true
    }
    
    /*
        Exhibitor validations
    */
    validateTravConExhibitor()
    {
        var registeredItems = CommonFunctions.getLoggedInUserRegItems(); 
        var selectedregoptions = CommonFunctions.getUserSelectedRegItems(); // Currently  selected items to purchase
        var loggedin = CommonFunctions.isUserLoggedin();
        var selecteditems = global.$totalregitems

        //Golf Player Check => allow this item only with right promocode from config
        if (!TravconFunctions.ValidateGolfPlayerFee(selectedregoptions)) {
            this.setState({
                isLoading: false,
                message: "Enter the valid code provided to purchase Golf Tournament Player item."
            })
            return false;
        }

        if (!TravconFunctions.checkRuleTravConAfterPartyTickets(selecteditems,registeredItems)){
            this.setState({
                message: "TravCon AfterParty Tickets is available only for TravCon AfterParty Sponsors and Event Vendors.",
                isLoading: false
            });
            return false;
        }

        //Upgrade checks
        if (loggedin && selecteditems != null) {

            if (selecteditems.includes("Booth Registration") || selecteditems.includes("Booth Bundle Registration")) {
                //console.log(Strings)
                // Check for upgrade regitem validation
                //var result = await APIFunctions.validateRegItemUpgrade(registereditems, selecteditems.toString());
                var result = TravconFunctions.validateTravconRegItemsForUpgrade()
                //console.log(result);
                if (result.result == false) {
                    this.setState({
                        message: result.message,
                        isLoading: false
                    });
                    return false;
                }
            }
        }
       

        //console.log('validateTravConExhibitor() - ok')
        return true
    }

    /*
    inputtype = number, dropdown, other

    number:  Item Name - quantity - $price  
    dropdown:   Item Name - drown value - $price
    Other: Item Naem - $price
    */

    formatRegItems(regitem) {
        var result = "";
        //console.log(regitem);
        if (regitem.inputtype == "number") {
            result = regitem.name + " - " + regitem.quantity + ' - $' + Number(regitem.quantity) * Number(regitem.itemprice);
        }
        else if (regitem.inputtype == "dropdown") {
            result = regitem.name + " - " + regitem.dropdownvalue + ' - $' + regitem.itemprice;
        }
        else {
            result = regitem.name + ' - $' + regitem.itemprice;
        }
        return result;
    }

    formatregitemsjson(regitem) {
        var result = {}

        if (regitem.inputtype == "number") {
            result.name = regitem.name;
            result.qty = regitem.quantity
            result.totalamount = Number(regitem.quantity) * Number(regitem.itemprice)
        }
        else if (regitem.inputtype == "dropdown") {
            result.name = regitem.name;
            result.qty = '1'
            result.totalamount = regitem.itemprice
            result.subitems = regitem.dropdownvalue
        }
        else {
            result.name = regitem.name;
            result.totalamount = regitem.itemprice
        }
        return result;
    }

    regItemJson(regitem) {
        var result = {};

        result.name = regitem.name;
        result.qty = regitem.quantity ? regitem.quantity : '1'
        result.totalamount = regitem.amount
        return result;
    }

    //  validate metro guest registraion
    validateMetroGuest(){
        var guestEmail = global.$registrationform.guests.personal.email.required;
        console.log(guestEmail);
        var personal = false;
        var company = false;
        var contact = false;
        var other = false;
        if (CommonFunctions.validateMetro()) {
            if (global.$regguestinfo && guestEmail === false) {

                if (global.$regguestinfo.email != "" || global.$regguestinfo.fname != "" || global.$regguestinfo.mname != "" || global.$regguestinfo.lname != "" || global.$regguestinfo.phone != "") {
                    personal = true;
                }
                if (global.$regguestinfo.company != "" || global.$regguestinfo.title != "" || global.$regguestinfo.officephone != "") {
                    company = true;
                }
                if (global.$regguestinfo.street != "" || global.$regguestinfo.city != "" || global.$regguestinfo.state != "" || global.$regguestinfo.zip != "") {
                    contact = true;
                }
                if (global.$regguestinfo.data1 != "" || global.$regguestinfo.data2 != "" || global.$regguestinfo.data3 != "") {
                    other = true;
                }
                // console.log(personal,company,contact,other);

                if (personal || company || contact || other) {
                    this.setState({
                        isLoading: false,
                        message: guesterror
                    })
                    return false;
                }
                else return true;
            }
            else return true;
        
        }
    }

    

    addSelectedRegitemsinArray(){
        global.$selectedRegItems = []; // create selected regitem array
        global.$guestRegitemsjson = [];
        global.$purchaseRegitems = []
        var defaulttab = CommonFunctions.getDefaultTab()
        let selectedItems = [];
        var registeredItems;
        var ids;
        var groupname = ''; 
        
        let boothupgrade = (global.$registrationform && global.$registrationform.boothupgrade) || false; // Set to true if booth upgrade is applicable
        let boothupgradeRegitems = '';
        let updatedRegisteredItems = [];
        const updatedSelectedItems = [];
        const updatedPurchaseRegItems = [];

        

    // GETTING THE BOOTHUPGRADE REGITEMS ARRAY IN THE WEBCONFIG
    

        // GET SELECTED TABID
        global.$tabid = this.props.tabid;
        const regSetup = global.$registrationform.setup[this.props.tabid];

        if (EventCodeFunctions.isTravconExhibitor2024(global.$code)) {

            if (CommonFunctions.isUserLoggedin()) {
                registeredItems = JSON.parse(global.$loggedinuserregitems); 
                if (regSetup?.registrationconditions?.boothupgraderegitems) {
                    boothupgradeRegitems = regSetup.registrationconditions.boothupgraderegitems
                }
            }

            global.$regitems.forEach((regitem) => {
                if (regitem.isChecked === true && regitem.dropdownvalue.toLowerCase() !== "select") {
                    if (CommonFunctions.isUserLoggedin()) {
            
                        if (boothupgrade && boothupgradeRegitems.includes(regitem.itemid)) {
                            registeredItems.map((item) => {
                                let matchingRegisteredItem = item.subitems ?
                                    item.subitems.some(el => boothupgradeRegitems.includes(el.itemid)) :
                                    boothupgradeRegitems.includes(item.itemid);
                            
                                if (matchingRegisteredItem && item.status === "active") {
                                    item.status = "inactive";
                                    console.log("After setting status: item.status =", item.status); // Debug log
                                    global.$regitemtoreducecount = item;
                                    updatedRegisteredItems.push(CommonFunctions.selectedRegOptions(regitem));
                                }
                            });
                        } 
                        else {
                            updatedSelectedItems.push(CommonFunctions.selectedRegOptions(regitem));
                        }
                        
                    } else {
                        updatedSelectedItems.push(CommonFunctions.selectedRegOptions(regitem));
                    }
                    updatedPurchaseRegItems.push(CommonFunctions.selectedRegOptions(regitem));
                }
            });
           
            global.$purchaseRegitems = updatedPurchaseRegItems;
           
            if (CommonFunctions.isUserLoggedin()) {
                global.$selectedRegItems = global.$selectedRegItems.concat(updatedRegisteredItems,updatedSelectedItems,registeredItems)
            }
            else{
                global.$selectedRegItems = global.$selectedRegItems.concat(updatedSelectedItems);
            }
          
        }
        else {
            if (CommonFunctions.isUserLoggedin()) {
                registeredItems = JSON.parse(global.$loggedinuserregitems); 
                ids = registeredItems.map(el => el.itemid)
    
                // get booth upgrade regitem group name
                if (global.$registrationform.setup[defaulttab].upgraderegitemsgroupnames) {
                    groupname = global.$registrationform.setup[defaulttab].upgraderegitemsgroupnames;
                }
            }
                            
                global.$regitems.map((regitem)=> {
                    if (regitem.isChecked == true && regitem.dropdownvalue.toLowerCase() !== "select" ) {
                        if (CommonFunctions.isUserLoggedin()) {
                            if (ids.includes(regitem.data5) || regitem.groupname == "Booth Choice" || regitem.groupname == "Attendee Registration" || regitem.groupname == groupname) {
                                registeredItems.map((item) => {
                                    // booth upgrade
                                    if (regitem.inputtype == "radio" && item.type == "radio" && item.status == "active" && (regitem.groupname == "Booth Choice" || regitem.groupname == "Attendee Registration" || regitem.groupname == groupname)) {
                                        item.status = "inactive"
                                        if (item.status == "inactive") {
                                            global.$regitemtoreducecount = item
                                        }  
                                                                  
                                        registeredItems.push({
                                            id:CommonFunctions.createdatetime().timestamp,
                                            name : regitem.name,
                                            price : regitem.amount,
                                            itemid : regitem.data5,
                                            udate : CommonFunctions.createdatetime().datatime,
                                            cdate:item.cdate,
                                            status : "active",
                                            type : regitem.inputtype,
                                            qty:"1",
                                            data1:"",
                                            data2:"",
                                            data3:"",
                                            data4:"",
                                            data5:""
                                        })
                                    }
                                    // change quantity
                                    else if (regitem.inputtype == "number" && item.itemid == regitem.data5) {
                                        item.name = regitem.name
                                        item.price = regitem.amount
                                        item.itemid = regitem.data5
                                        item.udate = CommonFunctions.createdatetime().datatime
                                        item.qty = Number(item.qty) + Number(regitem.quantity)
                                    }
                                    // add dropdown
                                    else if (regitem.inputtype == "dropdown" && item.itemid == regitem.data5) {
    
                                        let selectedArr = item.subitems.map((item) => item.itemid)
                                        regitem.selecteddropdown.map((selected) => {
                                            if (!selectedArr.includes(selected.itemid)) {
                                                item.subitems.push(selected)
                                            }
                                        })     
                                        let regArray = item.subitems.map((item) => item.itemid)
                                        item.subitems = item.subitems.filter(({itemid}, index) => !regArray.includes(itemid, index + 1))                      
                                        item.udate = CommonFunctions.createdatetime().datatime
                                        
                                    }
                                })
                               
                            }
                            else {                     
                                // select new reg option
                                selectedItems.push(CommonFunctions.selectedRegOptions(regitem))
                            }
                        }else {                  
                            selectedItems.push(CommonFunctions.selectedRegOptions(regitem))
                        }
                        
                        global.$purchaseRegitems.push(CommonFunctions.selectedRegOptions(regitem))
                        
                    }
                })
    
                
                if (CommonFunctions.isUserLoggedin()) {
                    selectedItems = selectedItems.filter(({itemid}, index) => !ids.includes(itemid, index + 1))
                    global.$selectedRegItems = global.$selectedRegItems.concat(registeredItems,selectedItems);
                }
                else {
                    global.$selectedRegItems = global.$selectedRegItems.concat(selectedItems);
                }
        }

   
    }

}

export default RegisterButton;


/*
    async validateSelectedRegItems() {

        this.setState({ isLoading: true });
        // Get user selected items to purchase
        var selectedregoptions = CommonFunctions.getUserSelectedRegItems();

        var result = true;
        var regitemsforvalidation = [];


        // Step1: Validate Email Id
        var validateEmail = false;
        if (Validations.validateEmailFormat(global.$regemail)) {
            validateEmail = true;
        }
        else {
            this.setState({
                isLoading: false,
                message: "Enter a valid email ID"
            });
            return;
        }

        // Step2: Validate Terms and Conditions        
        var validateTerms = false;
        if (Validations.validateTermsAndConditions(this.props.formtype)) {
            validateTerms = true;
        }
        else {
            this.setState({
                isLoading: false,
                message: "Please agree to terms and conditions"
            });
            return;
        }

        // Step3: Validate Form Fields
        var validateFormFields = false;
        ////console.log(Validations.validateFormFields(this.props.formtype));
        var isUserAlreadyRegistered = await Validations.isUserAlreadyRegistered(global.$regemail)
        //console.log(isUserAlreadyRegistered);
        var loggedin = CommonFunctions.isUserLoggedin();
        //console.log(loggedin)

        if (isUserAlreadyRegistered || (isUserAlreadyRegistered == false && loggedin)) {
           //console.log(Validations.validateFormFields("attendee", global.$registrationform, global.$regattendeeinfo))
            if (Validations.validateFormFields("attendee", global.$registrationform, global.$regattendeeinfo)) {
                validateFormFields = true;
            }
            else {
                this.setState({
                    isLoading: false,
                    message: "Please fill all the required (*) fields to continue."
                });
                return;
            }
        }
        else {
            this.setState({
                isLoading: false,
                message: `${global.$regemail} is already registered`
            });
            return;
        }


        // Validate Guest fields for travcon attendee
        if (EventCodeFunctions.isTravconAttendee(global.$code)) {

            // Guest name is added but guest ticket did not selected
            if (global.$regattendeeinfo != null && global.$regattendeeinfo.guestname != null && global.$regattendeeinfo.guestname != "" && global.$regattendeeinfo.guestname != " ") {
                if (CommonFunctions.isUserSelectedGuest(selectedregoptions)) {
                   //console.log("user registered")
                }
                else {
                    if(CommonFunctions.isUserLoggedin()){
                        
                       //console.log("user registered")
                    }
                    else
                    {
                        this.setState({
                            isLoading: false,
                            message: "You have filled in the guest name, Please select guest registration item to register guest."
                        });
                        return;
                    }
                }
            }
            // guest regitem is selected but guest name is not added
            if (CommonFunctions.isUserSelectedGuest(selectedregoptions)) {
                if (global.$regattendeeinfo != null && global.$regattendeeinfo.guestname != null && global.$regattendeeinfo.guestname != "" && global.$regattendeeinfo.guestname != " ") {
                
                }
                else {
                    this.setState({
                        isLoading: false,
                        message: "You have selected guest registration item, please fill in the guest name to continue."
                    });
                    return;
                }
            }
        }

        // Step4: Validate Questions
        var validateQuestions = false;
       //console.log(Validations.validateQuestions());
        if (Validations.validateQuestions()) {
            validateQuestions = true;

        }
        else {
            this.setState({
                isLoading: false,
                message: "Please answer all the required (*) questions to continue."
            });
            return;
        }

        // Step7: Validate RegItems selected

        var section1required = true;

        var groupnames = global.$registrationform.setup[this.props.formtype].groupnames;
        var section1 = CommonFunctions.getValueFromMeCard(groupnames, "one");
        //console.log(section1)
        section1required = global.$registrationform.setup[this.props.formtype].section1required;

       //console.log(section1required)
        // validate Section1 reg items
        //console.log(selectedregoptions)

        //New registration - Check an item from first section (Booth choice or registration type) is selected. 
        if (section1required == true && !CommonFunctions.isUserLoggedin()) {
            var result = false;
            selectedregoptions.map((regitem) => {
                //console.log(regitem)
                if (regitem.groupname == section1) {
                    result = true;
                }
            })
            //console.log(result);
            if (result == false) {
                this.setState({
                    message: "Please select the registration options to continue.",
                    isLoading: false
                });
                return;
            }
        }

        var pendingamount = CommonFunctions.getLoggedInUserPendingAmount(); // previous balance
        var registeredItems = CommonFunctions.getLoggedInUserRegItems(); // Previously registered Items
        var selectedRegItems = CommonFunctions.getUserSelectedRegItems(); // Currently  selected items to purchase
        global.$registereditems = registeredItems;
        var totalamount = pendingamount;


        // handles reg option validation and adds total price
        // Step8: add final regitems and final price
       //console.log(selectedRegItems);
        if (selectedRegItems.length != 0) {

            var attendeeregitems = [];
            var guestregitems = [];
            var totalregitems = [];
            selectedregoptions.map((regitem) => {
                // get total amount to pay
                if (CommonFunctions.isUserLoggedin() && (EventCodeFunctions.isTravconExhibitor(global.$code) || EventCodeFunctions.isTravconAttendee(global.$code))) {
                    //console.log(regitem);
                    //console.log(registereditems);
                    var amount = TravconFunctions.findTheAmountfromSelectedItem(regitem, registeredItems);
                    //console.log(amount);
                    if (regitem.quantity == "") {
                        totalamount = Number(totalamount) + Number(amount)
                    }
                    else if (regitem.quantity != "") {   // only when the raffle quantity exists
                        totalamount = Number(totalamount) + Number(amount) * Number(regitem.quantity)
                    }
                }
                else {
                    //console.log(regitem)
                    if (regitem.quantity == "") {
                        totalamount = Number(totalamount) + Number(regitem.itemprice)
                    }
                    else if (regitem.quantity != "") {   // only when the raffle quantity exists
                        totalamount = Number(totalamount) + (Number(regitem.itemprice) * Number(regitem.quantity))
                    }
                    //console.log(totalamount)
                }
                //console.log(regitem);
                if (regitem.groupname.includes("guest") || regitem.groupname.includes("Guest")) {
                    totalregitems.push(this.formatRegItems(regitem));
                    guestregitems.push(this.formatRegItems(regitem));
                    global.$guestexist = true;
                }
                else {
                    totalregitems.push(this.formatRegItems(regitem));
                    attendeeregitems.push(this.formatRegItems(regitem));
                }
            })
            //console.log(attendeeregitems);
            global.$totalregitems = totalregitems.toString();
            global.$attendeeregitems = attendeeregitems.toString();
            global.$guestregitems = guestregitems.toString();

            // Update the balance with discount amount
            if (CommonFunctions.isRegDiscountExist()) {
                totalamount = Number(totalamount) - Number(global.$regdiscountinfo.discountprice)
            }

            // To prevent negative amount
            if (Number(totalamount) < 0) {
                totalamount = 0;
            }
            //console.log(totalamount);
            global.$regamount = totalamount;
            global.$finalamount = totalamount;
            global.$regattendeestatus = true
            //console.log(global.$regamount);
            this.setState({
                isLoading: false
            });
        }
        else {
            if (CommonFunctions.isUserLoggedin()) {
                if (totalamount != null && totalamount != "" && totalamount != 0) {

                    global.$totalregitems = "";
                    global.$attendeeregitems = "";
                    global.$guestregitems = "";

                    // Update the balance with discount amount
                    if (CommonFunctions.isRegDiscountExist()) {
                        totalamount = Number(totalamount) - Number(global.$regdiscountinfo.discountprice)
                    }

                    // To prevent negative amount
                    if (Number(totalamount) < 0) {
                        totalamount = 0;
                    }
                    global.$regamount = totalamount;
                    global.$finalamount = totalamount;
                    global.$regattendeestatus = true
                    this.setState({
                        isLoading: false
                    });
                }
            }
            else {
                this.setState({
                    message: "Please select the registration options to continue.",
                    isLoading: false
                });
                return;
            }
        }
        //console.log(CommonFunctions.isGuestEnabledFromConfig())
        // Step6: Check for guest
        if (CommonFunctions.isGuestEnabledFromConfig()) {

            if (CommonFunctions.isUserSelectedGuest(selectedregoptions)) {
                if (Validations.validateEmailFormat(global.$reguestemail)) {
                    if (Validations.validateAttendeeAndGuestEmail(global.$regemail, global.$reguestemail)) {
                        if (Validations.validateFormFields("guest", global.$registrationform, global.$regguestinfo)) {
                            //console.log("guest ok")
                            global.$reggueststatus = true;
                        }
                        else {
                            this.setState({
                                isLoading: false,
                                message: "Please fill all the required (*) fields to continue."
                            });
                            return;
                        }
                    }
                    else {
                        this.setState({ message: "Attendee and Guest Emails should not be same" });
                        return;
                    }
                }
                else {
                    this.setState({ message: "Enter a valid guest email ID" });
                    return;
                }
            }
        }

        // TravconAttendee : TravelerEntrepreneurMarketplace Check => allow this item only with right promocode from config
        if (EventCodeFunctions.isTravconAttendee(global.$code)) {
            if (TravconFunctions.ValidateTravelerEntrepreneurMarketplace(selectedregoptions)) {

            }
            else {
                this.setState({
                    isLoading: false,
                    message: "Enter the valid code provided to register for Traveler Entrepreneur Marketplace"
                })
                return;
            }
        }

         // TravconExhibitor : Golf Player Check => allow this item only with right promocode from config
         if (EventCodeFunctions.isTravconExhibitor(global.$code)) {
            if (TravconFunctions.ValidateGolfPlayerFee(selectedregoptions)) {

            }
            else {
                this.setState({
                    isLoading: false,
                    message: "Enter the valid code provided to purchase Golf Tournament Player item."
                })
                return;
            }
        }

        var registereditems = CommonFunctions.getLoggedInUserRegItems();
        regitemsforvalidation.push(registereditems);

        var selecteditems = [];
        selectedregoptions.map((item) => {
            if (item.quantiyty != "" && item.quantity != "0") {
                regitemsforvalidation.push(item.name + " - " + item.quantity + " - " + item.amount)
                selecteditems.push(item.name + " - " + item.quantity + " - $" + item.amount)
            }
            else {
                regitemsforvalidation.push(item.name + " - " + item.amount)
                selecteditems.push(item.name + " - $" + item.amount)
            }
        })
        // TravconExhibtior: handle booth upgrade and afterparty ticket purchase 
        var Strings = RegItemStrings.getTravconExhibitorRegItemStrings();

        if (EventCodeFunctions.isTravconExhibitor(global.$code)) {
            //console.log("travcon")
            selecteditems = selecteditems.toString();
            //console.log(selecteditems);
            regitemsforvalidation = regitemsforvalidation.toString();

            if (CommonFunctions.isUserLoggedin()) {
                //console.log("loggedin")
                //if (selecteditems.includes("Single Booth Registration") || selecteditems.includes("Double Booth Registration") || selecteditems.includes("Triple Booth Registration") || selecteditems.includes("Quad Booth Registration") || selecteditems.includes("Hexa Booth Registration") || selecteditems.includes("Platinum Booth Registration") || selecteditems.includes("Platinum Quad Booth Registration") || selecteditems.includes("Platinum Triple Booth Registration") || selecteditems.includes("Platinum Hexa Booth Registration")) {
                if (selecteditems.includes("Booth Registration") || selecteditems.includes("Booth Bundle Registration")) {
                    //console.log(Strings)
                    // Check for upgrade regitem validation
                    //var result = await APIFunctions.validateRegItemUpgrade(registereditems, selecteditems.toString());
                    var result = TravconFunctions.validateTravconRegItemsForUpgrade()
                    //console.log(result);
                    if (result.result == false) {
                        this.setState({
                            message: result.message,
                            isLoading: false
                        });
                        return;
                    }
                }
            }

            // Check for after-party purchase(only available for who purchase eventvendors/for all bundles)
            if (regitemsforvalidation.includes(Strings.afterparty)) {
                //if (regitemsforvalidation.includes("TravCon AfterParty Tickets")) {

                var result = await APIFunctions.validateRegItemToBlock(regitemsforvalidation, selectedregoptions);
                if (result.data == false) {
                    this.setState({
                        message: result.err,
                        isLoading: false
                    });
                    return;
                }
            }
        }
        else if (EventCodeFunctions.isTravconAttendee(global.$code)) {
           //console.log("travcon")
            selecteditems = selecteditems.toString();
            //console.log(selecteditems);
            regitemsforvalidation = regitemsforvalidation.toString();

            var commonstrings = RegItemStrings.commonConstants;
            //console.log(commonstrings);
            if (CommonFunctions.isUserLoggedin()) {
                //console.log("loggedin")
                //if (selecteditems.includes("Single Booth Registration") || selecteditems.includes("Double Booth Registration") || selecteditems.includes("Triple Booth Registration") || selecteditems.includes("Quad Booth Registration") || selecteditems.includes("Hexa Booth Registration") || selecteditems.includes("Platinum Booth Registration") || selecteditems.includes("Platinum Quad Booth Registration") || selecteditems.includes("Platinum Triple Booth Registration") || selecteditems.includes("Platinum Hexa Booth Registration")) {
                if (selecteditems.includes(commonstrings.mainstageticket) || selecteditems.includes(commonstrings.newbiebootcamp) || selecteditems.includes(commonstrings.medicalspanishsun) || selecteditems.includes(commonstrings.medicalspanishwed)) {
                    //console.log(Strings)
                    // Check for upgrade regitem validation
                    //var result = await APIFunctions.validateRegItemUpgrade(registereditems, selecteditems.toString());
                    var result = TravconFunctions.validateTravconRegItemsForUpgrade()
                    //console.log(result);
                    if (result.result == false) {
                        this.setState({
                            message: result.message,
                            isLoading: false
                        });
                        return;
                    }
                }
            }
        }
        // Step5: Get regitems for signup count
        var regItemsForSignupCount = CommonFunctions.getRegItemsForSignupCount(selectedregoptions);
        global.$regItemsForSignupCount = regItemsForSignupCount;
        regItemsForSignupCount = regItemsForSignupCount.trim();
        //console.log(regItemsForSignupCount)
       //console.log(selecteditems);
        // This check is for metro and travcon to just login and clear the pending balance without purchasing any items 
        if (CommonFunctions.isUserLoggedin() && regItemsForSignupCount == "") {
            if (EventCodeFunctions.isTravconExhibitor(global.$code) || EventCodeFunctions.isMetro(global.$code) || EventCodeFunctions.isTravconAttendee(global.$code)) {
                global.$openpaymentpopup = true;
                this.setState({
                    proceedtopayment: true,
                    isLoading: false
                })
            }
        }
        else {
            var validateRegItems = await APIFunctions.validateRegItemSignupCount(regItemsForSignupCount);

            /////////// Final Values ///////////////////
            //console.log(CommonFunctions.printGlobalRegItems())
            //console.log(CommonFunctions.printGlobalAmounts())
            //console.log(`Totals: currentTotal = ${totalamount}, pending = ${pendingamount}, discount = ${global.$regdiscountinfo.discountprice}, `)

            //console.log(validateRegItems)
            if (validateRegItems.data == true) {
                global.$openpaymentpopup = true;
                this.setState({
                    proceedtopayment: true,
                    isLoading: false,
                    message: ""
                })
            }
            else {
                this.setState({
                    message: result.err,
                    isLoading: false
                });
                return;
            }
        }

    }
*/    


    
        /*
        convertSelectedItemsToString()
        {
            var selectedregoptions = CommonFunctions.getUserSelectedRegItems();
            var selecteditems = [];
            selectedregoptions.map((item) => {
                if (item.quantiyty != "" && item.quantity != "0") {
                    regitemsforvalidation.push(item.name + " - " + item.quantity + " - " + item.amount)
                    selecteditems.push(item.name + " - " + item.quantity + " - $" + item.amount)
                }
                else {
                    regitemsforvalidation.push(item.name + " - " + item.amount)
                    selecteditems.push(item.name + " - $" + item.amount)
                }
            })
            return selecteditems.toString()
        }
        */