/* eslint-disable */
import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import AddMeetup from './AddMeetup';
import "./Meetups.css";
import GetMeetups from './GetMeetups';
class MeetupsHome extends React.Component {
    constructor(props) {

        super(props);

        this.state = {
            code: props.code,
            dataloaded: false,
            bucket: props.bucket,
        };       
    }

    render() {
        var meetupconfig = global.$config["meetups"];
        //console.log(meetupconfig);
      //console.log("meetup home render");
        return (
            <div>
                <img className="banner" src={meetupconfig.banner} alt="Meetup_banner" />
                <div dangerouslySetInnerHTML={{ __html: meetupconfig.header }}></div>
                <GetMeetups />
            </div>
        );
    }

} //Class

export default MeetupsHome;