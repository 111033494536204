import EventcodeFunctions from "../Registration/RegCommon/EventcodeFunctions";
var commonConstants = {
    "platinumboothtitle": "Platinum Booth Registration",
    "doubleboothtitle": "Double Booth Registration",
    "singleboothtitle": "Single Booth Registration",
    "eventvendortitle": "Event Vendor Registration",
    "eventvendorplatinumtitle": "Event Vendor + Platinum Booth Bundle Registration",
    "eventvendorplatinumdoubletitle": "Event Vendor + Platinum Double Booth Bundle Registration",
    "eventvendorplatinumtripletitle": "Event Vendor + Platinum Triple Booth Bundle Registration",
    "eventvendorplatinumquadtitle": "Event Vendor + Platinum Quad Booth Bundle Registration",
    "eventvendorplatinumhexatitle": "Event Vendor + Platinum Hexa Booth Bundle Registration",
    "eventvendorhexatitle": "Event Vendor + Hexa Booth Bundle Registration",
    "eventvendorquadtitle": "Event Vendor + Quad Booth Bundle Registration",
    "eventvendortripletitle": "Event Vendor + Triple Booth Bundle Registration",
    "eventvendordoubletitle": "Event Vendor + Double Booth Bundle Registration",
    "eventvendorsingletitle": "Event Vendor + Single Booth Bundle Registration",
    "platinumquadboothtitle": "Platinum Quad Booth Registration",
    "platinumdoubleboothtitle": "Platinum Double Booth Registration",
    "platinumtripleboothtitle": "Platinum Triple Booth Registration",
    "platinumhexaboothtitle": "Platinum Hexa Booth Registration",
    "tripleboothtitle": "Triple Booth Registration",
    "quadboothtitle": "Quad Booth Registration",
    "hexaboothtitle": "Hexa Booth Registration",
    "afterparty": "TravCon AfterParty Tickets",
    "medicalspanishwed": "Medical Spanish Bundle - 'Wed Post Con'",
    "medicalspanishsun": "Medical Spanish Bundle - 'Sunday Post Con'",
    "newbiebootcamp": "Newbie BootCamp Bundle",
    "mainstageticket": "Main Stage Ticket",
    "boothdowngradeerrormessage": "Booth downgrade is not allowed after purchase. Please update your booth choice and try again.",
    "attendeedowngradeerrormessage": "Downgrade is not allowed after purchase. Please try again."
}
var travconExhibitor2022RegItems = {
    "platinumbooth": "Platinum Booth Registration - $25000",
    "doublebooth": "Double Booth Registration - $13000",
    "singlebooth": "Single Booth Registration - $6500",
    "eventvendor": "Event Vendor Registration - $6500",
    "eventvendorplatinum": "Event Vendor + Platinum Booth Bundle Registration - $28000",
    "eventvendorplatinumdouble": "Event Vendor + Platinum Double Booth Bundle Registration - $32000", // Not using
    "eventvendorplatinumtriple": "Event Vendor + Platinum Triple Booth Bundle - $37000", // Not using
    "eventvendorplatinumquad": "Event Vendor + Platinum Quad Booth Bundle - $42000", // Not using
    "eventvendorplatinumhexa": "Event Vendor + Platinum Hexa Booth Bundle - $51000", // Not using
    "eventvendortriple": "Event Vendor + Triple Booth Bundle Registration - $24000", // Not using
    "eventvendorquad": "Event Vendor + Quad Booth Bundle Registration - $28000", // Not using
    "eventvendorhexa": "Event Vendor + Hexa Booth Bundle Registration - $37500", // Not using
    "eventvendordouble": "Event Vendor + Double Booth Bundle Registration - $16250",
    "eventvendorsingle": "Event Vendor + Single Booth Bundle Registration - $9500",
    "platinumquadbooth": "Platinum Quad Booth Registration - $34000",
    "platinumdoublebooth": "Platinum Double Booth Registration - $29000",
    "platinumtriplebooth": "Platinum Triple Booth Registration - $30000",
    "platinumhexabooth": "Platinum Hexa Booth Registration - $42000",
    "triplebooth": "Triple Booth Registration - $18000",
    "quadbooth": "Quad Booth Registration - $22000",
    "hexabooth": "Hexa Booth Registration - $30000",
    "afterparty": "TravCon AfterParty Tickets",
    "maxplatinumboothcount": "11",
    "eventvendortitle": "Event Vendor",
    "deposit": 2900
}
var travconExhibitor2023RegItems = {
    "platinumbooth": "Platinum Booth Registration - $25000", // Not using
    "doublebooth": "Double Booth Registration - $15000",
    "singlebooth": "Single Booth Registration - $7500",
    "eventvendor": "Event Vendor Registration - $7500",
    "eventvendorplatinum": "Event Vendor + Platinum Booth Bundle Registration - $11000", // Not using
    "eventvendorplatinumdouble": "Event Vendor + Platinum Double Booth Bundle Registration - $32000",
    "eventvendorplatinumtriple": "Event Vendor + Platinum Triple Booth Bundle Registration - $37000",
    "eventvendorplatinumquad": "Event Vendor + Platinum Quad Booth Bundle Registration - $42000",
    "eventvendorplatinumhexa": "Event Vendor + Platinum Hexa Booth Bundle Registration - $51000",
    "eventvendortriple": "Event Vendor + Triple Booth Bundle Registration - $24000",
    "eventvendorquad": "Event Vendor + Quad Booth Bundle Registration - $28000",
    "eventvendorhexa": "Event Vendor + Hexa Booth Bundle Registration - $37500",
    "eventvendordouble": "Event Vendor + Double Booth Bundle Registration - $18250",
    "eventvendorsingle": "Event Vendor + Single Booth Bundle Registration - $9500",
    "platinumdoublebooth": "Platinum Double Booth Registration - $29000",
    "platinumtriplebooth": "Platinum Triple Booth Registration - $34000",
    "platinumquadbooth": "Platinum Quad Booth Registration - $39000",
    "platinumhexabooth": "Platinum Hexa Booth Registration - $48000",
    "triplebooth": "Triple Booth Registration - $21000",
    "quadbooth": "Quad Booth Registration - $25000",
    "hexabooth": "Hexa Booth Registration - $34500",
    "afterparty": "TravCon AfterParty Tickets",
    "maxplatinumboothcount": "10",
    "eventvendortitle": "Event Vendor",
    "deposit": 3500
}
var travconExhibitor2024RegItems = {
    "platinumbooth": "Platinum Booth Registration - $25000", // Not using
    "doublebooth": "Double Booth Registration - $15000",
    "singlebooth": "Single Booth Registration - $7500",
    "eventvendor": "Event Vendor Registration - $7500",
    "eventvendorplatinum": "Event Vendor + Platinum Booth Bundle Registration - $11000", // Not using
    "eventvendorplatinumdouble": "Event Vendor + Platinum Double Booth Bundle Registration - $32000",
    "eventvendorplatinumtriple": "Event Vendor + Platinum Triple Booth Bundle Registration - $37000",
    "eventvendorplatinumquad": "Event Vendor + Platinum Quad Booth Bundle Registration - $42000",
    "eventvendorplatinumhexa": "Event Vendor + Platinum Hexa Booth Bundle Registration - $51000",
    "eventvendortriple": "Event Vendor + Triple Booth Bundle Registration - $24000",
    "eventvendorquad": "Event Vendor + Quad Booth Bundle Registration - $28000",
    "eventvendorhexa": "Event Vendor + Hexa Booth Bundle Registration - $37500",
    "eventvendordouble": "Event Vendor + Double Booth Bundle Registration - $18250",
    "eventvendorsingle": "Event Vendor + Single Booth Bundle Registration - $9500",
    "platinumdoublebooth": "Platinum Double Booth Registration - $29000",
    "platinumtriplebooth": "Platinum Triple Booth Registration - $34000",
    "platinumquadbooth": "Platinum Quad Booth Registration - $39000",
    "platinumhexabooth": "Platinum Hexa Booth Registration - $48000",
    "triplebooth": "Triple Booth Registration - $21000",
    "quadbooth": "Quad Booth Registration - $25000",
    "hexabooth": "Hexa Booth Registration - $34500",
    "afterparty": "TravCon AfterParty Tickets",
    "maxplatinumboothcount": "10",
    "eventvendortitle": "Event Vendor",
    "deposit": 3500
}


var travcon2022exhibitorreps = {
    "platinumhexareps": 16,
    "platinumquadreps": 13,
    "platinumtriplereps": 11,
    "platinumdoublereps": 0, // this is not used
    "platinumreps": 10,
    "hexareps": 12,
    "quadreps": 9,
    "triplereps": 7,
    "doublereps": 5,
    "singlereps": 2,
    "eventvendorhexareps": 14,
    "eventvendorquadreps": 11,
    "eventvendortriplereps": 9,
    "eventvendordoublereps": 7,
    "eventvendorsinglereps": 4,
    "eventvendorreps": 2,
    "eventvendorplatinumreps": 12,
    "eventvendorplatinumdoublereps": 13,
    "eventvendorplatinumtriplereps": 13,
    "eventvendorplatinumquadreps": 15,
    "eventvendorplatinumhexareps": 18,
}
var travcon2023exhibitorreps = {
    "platinumhexareps": 16,
    "platinumquadreps": 13,
    "platinumtriplereps": 11,
    "platinumdoublereps": 10, 
    "platinumreps": 10,
    "hexareps": 12,
    "quadreps": 9,
    "triplereps": 7,
    "doublereps": 5,
    "singlereps": 2,
    "eventvendorhexareps": 14,
    "eventvendorquadreps": 11,
    "eventvendortriplereps": 9,
    "eventvendordoublereps": 7,
    "eventvendorsinglereps": 4,
    "eventvendorreps": 2,
    "eventvendorplatinumreps": 12,
    "eventvendorplatinumdoublereps": 12,
    "eventvendorplatinumtriplereps": 13,
    "eventvendorplatinumquadreps": 15,
    "eventvendorplatinumhexareps": 18,
}
var travcon2024exhibitorreps = {
    "platinumhexareps": 16,
    "platinumquadreps": 13,
    "platinumtriplereps": 11,
    "platinumdoublereps": 10, 
    "platinumreps": 10,
    "hexareps": 12,
    "quadreps": 9,
    "triplereps": 7,
    "doublereps": 5,
    "singlereps": 2,
    "eventvendorhexareps": 14,
    "eventvendorquadreps": 11,
    "eventvendortriplereps": 9,
    "eventvendordoublereps": 7,
    "eventvendorsinglereps": 4,
    "eventvendorreps": 2,
    "eventvendorplatinumreps": 12,
    "eventvendorplatinumdoublereps": 12,
    "eventvendorplatinumtriplereps": 13,
    "eventvendorplatinumquadreps": 15,
    "eventvendorplatinumhexareps": 18,
}


var travconExhibitor2024RegItemsjson = [
    {
        name:'Platinum Hexa Booth',
        price:'51000',
        itemid:'42898017',
        repscount:16
    },
    {
        name:'Platinum Quad Booth',
        price:'41000',
        itemid:'43026648',
        repscount:13
    },
    {
        name:'Platinum Triple Boothw++',
        price:'37000',
        itemid:'43072893',
        repscount:11
    },
    {
        name:'Platinum Double Booth',
        price:'31000',
        itemid:'43134835',
        repscount:10
    },
    {
        name:'Industry Supplier + Platinum Hexa Booth Bundle',
        price:'54500',
        itemid:'43191512',
        repscount:18
    },
    {
        name:'Industry Supplier + Platinum Quad Booth Bundle',
        price:'44500',
        itemid:'43228549',
        repscount:15
    },
    {
        name:'Industry Supplier+Platinum Triple Booth Bundle',
        price:'40000',
        itemid:'43259365',
        repscount:13
    },
    {
        name:'Industry Supplier+Platinum Double Booth Bundle',
        price:'34000',
        itemid:'43313545',
        repscount:12
    },
    {
        name:'Gold Hexa Booth',
        price:'44000',
        itemid:'36374953',
        repscount:14
    },
    {
        name:'Gold Quad Booth',
        price:'34000',
        itemid:'36434428',
        repscount:11
    },
    {
        name:'Gold Triple Booth',
        price:'30000',
        itemid:'36481297',
        repscount:9
    },
    {
        name:'Gold Double Booth',
        price:'23000',
        itemid:'36509453',
        repscount:7
    },
    {
        name:'Industry Supplier + Gold Hexa Booth Bundle',
        price:'47500',
        itemid:'36636518',
        repscount:16
    },
    {
        name:'Industry Supplier + Gold Quad Booth Bundle',
        price:'37500',
        itemid:'36679906',
        repscount:13
    },
    {
        name:'Industry Supplier + Gold Triple Booth Bundle',
        price:'33000',
        itemid:'36724499',
        repscount:11
    },
    {
        name:'Industry Supplier + Gold Double Booth Bundle',
        price:'26500',
        itemid:'36755950',
        repscount:9
    },
    {
        name:'Hexa Booth',
        price:'37000',
        itemid:'91523390',
        repscount:12
    },
    {
        name:'Quad Booth',
        price:'27000',
        itemid:'91588927',
        repscount:9
    },
    {
        name:'Triple Booth',
        price:'23000',
        itemid:'91631190',
        repscount:7
    },
    {
        name:'Double Booth',
        price:'16000',
        itemid:'91663966',
        repscount:5
    },
    {
        name:'Premium Single',
        price:'10000',
        itemid:'91708271',
        repscount:3
    },
    {
        name:'Single Booth',
        price:'8000',
        itemid:'91745799',
        repscount:2
    },
    {
        name:'Industry Supplier + Hexa Booth Bundle',
        price:'40500',
        itemid:'92198646',
        repscount:14
    },
    {
        name:'Industry Supplier + Quad Booth Bundle',
        price:'30500',
        itemid:'92267372',
        repscount:11
    },
    {
        name:'Industry Supplier + Triple Booth Bundle',
        price:'26000',
        itemid:'92295630',
        repscount:9
    },
    {
        name:'Industry Supplier + Double Booth Bundle',
        price:'19500',
        itemid:'92332048',
        repscount:7
    },
    {
        name:'Industry Supplier + Premium Single Booth Bundle',
        price:'14000',
        itemid:'92379896',
        repscount:5
    },
    {
        name:'Industry Supplier + Single Booth Bundle',
        price:'12000',
        itemid:'92419247',
        repscount:4
    },
    {
        name:'Industry Supplier',
        price:'8000',
        itemid:'49105741',
        repscount:2
    },
    {
        name:'Premium Single Booth',
        price:'10000',
        itemid:'48979748',
        repscount:3
    },
    {
        name:'maxplatinumboothcount',
        count:'10',
        itemid:'00010'
    },
    {
        name:'deposit',
        price:'3500',
        itemid:'00011'
    }
]

var travconExhibitor2023RegItemsjson = [
    {
        name:'Single Booth Registration',
        price:'7500',
        itemid:'1020',
        repscount:2
    },
    {
        name:'Double Booth Registration',
        price:'15000',
        itemid:'1010',
        repscount:5
    },
    {
        name:'Triple Booth Registration',
        price:'21000',
        itemid:'9500',
        repscount:7
    },
    {
        name:'Hexa Booth Registration',
        price:'34500',
        itemid:'9350',
        repscount:12
    },
    {
        name:'Quad Booth Registration',
        price:'25000',
        itemid:'9450',
        repscount:9
    },
    {
        name:'Platinum Booth Registration',
        price:'25000',
        itemid:'',
        repscount:10
    },
    {
        name:'Platinum Double Booth Registration',
        price:'29000',
        itemid:'1000',
        repscount:10
    },
    {
        name:'Platinum Triple Booth Registration',
        price:'34000',
        itemid:'9440',
        repscount:11
    },
    {
        name:'Platinum Quad Booth Registration',
        price:'39000',
        itemid:'9430',
        repscount:13
    },
    {
        name:'Platinum Hexa Booth Registration',
        price:'48000',
        itemid:'9420',
        repscount:16
    },
    {
        name:'Event Vendor Registration',
        price:'7500',
        itemid:'1030',
        repscount:2
    },
    {
        name:'Event Vendor + Single Booth Bundle Registration',
        price:'9500',
        itemid:'1060',
        repscount:4
    },
    {
        name:'Event Vendor + Double Booth Bundle Registration',
        price:'18250',
        itemid:'1050',
        repscount:7
    },
    {
        name:'Event Vendor + Triple Booth Bundle Registration',
        price:'24000',
        itemid:'67498586',
        repscount:9
    },
    {
        name:'Event Vendor + Quad Booth Bundle Registration',
        price:'28000',
        itemid:'67621697',
        repscount:11
    },
    {
        name:'Event Vendor + Hexa Booth Bundle Registration',
        price:'37500',
        itemid:'67649645',
        repscount:14
    },
    {
        name:'Event Vendor + Platinum Double Booth Bundle Registration',
        price:'32000',
        itemid:'9510',
        repscount:12
    },
    {
        name:'Event Vendor + Platinum Triple Booth Bundle Registration',
        price:'37000',
        itemid:'67783113',
        repscount:13
    },
    {
        name:'Event Vendor + Platinum Quad Booth Bundle Registration',
        price:'42000',
        itemid:'67912835',
        repscount:15
    },
    {
        name:'Event Vendor + Platinum Hexa Booth Bundle Registration',
        price:'51000',
        itemid:'68014276',
        repscount:18
    },
    {
        name:'maxplatinumboothcount',
        count:'10',
        itemid:'00010'
    },
    {
        name:'deposit',
        price:'3500',
        itemid:'00011'
    }
]



function getTravconExhibitorRegItemStrings() {
    if (EventcodeFunctions.isTravconExhibitor2022(global.$code)) {
        return travconExhibitor2022RegItems;
    }
    else if (EventcodeFunctions.isTravconExhibitor2023(global.$code)) {
        return travconExhibitor2023RegItems;
    }
    else if (EventcodeFunctions.isTravconExhibitor2024(global.$code)) {
        return travconExhibitor2024RegItems;
    }
}

export default { commonConstants, travcon2022exhibitorreps, travcon2023exhibitorreps,travconExhibitor2023RegItemsjson, getTravconExhibitorRegItemStrings, travconExhibitor2023RegItems, travconExhibitor2022RegItems,travconExhibitor2024RegItems,travcon2024exhibitorreps,travconExhibitor2024RegItemsjson }