/* eslint-disable */
import React from 'react';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import ReactPlayer from 'react-player';
import SucessfullLogin from './SucessfullLogin';

class LoginIntro extends React.Component {

    constructor(props) {

        var videourl = "";
        if (global.$login.introvideo != null) {
            videourl = global.$login.introvideo;
        }

        super(props);
        this.state = {
            code: global.$code,
            videourl: videourl,
            openpopup: props.openpopup,
            updatingstate: false
        };
        //console.log(this.state);
    }

    onOpenModal = () => {
        this.setState(prevState => ({
            updatingstate: true,
            openpopup: !prevState.openpopup
        }));
    }

    render() {
        //console.log(this.state);
        if(this.state.updatingstate == false)
        {
            this.state.openpopup = this.props.openpopup;
        }
        else
        {
            //console.log("not updating");
            this.state.updatingstate = false;
        }
        return (
            <div>
                <Modal
                    open={this.state.openpopup}
                    onClose={this.onOpenModal}
                    blockScroll={false}
                    className="login-popup"
                    styles={{
                        modal: {
                            width: "100%"
                        }
                    }}
                    center>
                        <div className="login-popup-video-div">
                            <ReactPlayer
                                url={this.state.videourl}
                                width="100%"
                                controls={true}
                                playing={true}
                                onContextMenu={e => e.preventDefault()}
                                config={{
                                    file: {
                                        attributes: {
                                            controlsList: 'nodownload',
                                            disablepictureinpicture: 'true',
                                        }
                                    }
                                }}
                            />
                        </div>
                </Modal>
                {
                    this.state.openpopup == false?
                    (
                        this.props !=null && this.props.type == "firstlogin"?
                        ('')
                        :
                        (
                            <SucessfullLogin {...this.props} />
                        )
                    )
                    :
                    ('')
                }
            </div>
        );
    }

}

export default LoginIntro;
