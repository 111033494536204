import React from 'react';
import { Tabs, Tab, TabPanel, TabList } from 'react-web-tabs';
import 'react-web-tabs/dist/react-web-tabs.css';
import './Registration2.css';
import EventcodeFunctions from './RegCommon/EventcodeFunctions';
import ConfigFunctions from './RegCommon/ConfigFunctions';
import CommonFunctions from './RegCommon/CommonFunctions';
import RegistrationFormContainer from './RegistrationFormContainer';


class RegistrationHome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            code: global.$code,
            s3: global.$s3,
            bucket: global.$s3bucket,
            regbanner: ConfigFunctions.getRegBanner(),
        };
        
    }
    componentDidMount() {
        window.scrollTo({ top:0,behavior:'smooth'})
        CommonFunctions.clearGlobalVariables();
    }

    render() {
        var styles = global.$registrationform.styles.home;
        // get default tab
        var defaulttab = CommonFunctions.getDefaultTab()
        return(
            <div>
                {this.renderRegBanner()}
                {this.renderTabs(styles, defaulttab)}
            </div>
        )
        
    }

    renderRegBanner() {
        return (
            <div className='registration-banner' dangerouslySetInnerHTML={{ __html: this.state.regbanner }}></div>
        )
    }

    renderTabs(styles, defaulttab) {
        return (
            <div className="reg-tabs" style={styles}>
                <Tabs
                    defaultTab={defaulttab}
                >
                    <TabList className="tabs-list reg-tabs-list">
                                    <div>                                   
                                        {
                                            CommonFunctions.isTab1Enabled() && 
                                            CommonFunctions.loginUserType(global.$registrationform.setup.tab1.updateregistration) ?
                                                (
                                                    <Tab tabFor="tab1" onClick={()=>this.refs.handleRefresh1.hanleRefresh("refresh")}>{global.$registrationform.setup.tab1.tabname}</Tab>
                                                )
                                                :
                                                (
                                                    ''
                                                )
                                        }
                                        {
                                            CommonFunctions.isTab2Enabled() && 
                                            CommonFunctions.loginUserType(global.$registrationform.setup.tab2.updateregistration)  ?
                                                (
                                                    <Tab tabFor="tab2" onClick={()=>this.refs.handleRefresh2.hanleRefresh("refresh")}>{global.$registrationform.setup.tab2.tabname}</Tab>
                                                )
                                                :
                                                (
                                                    ''
                                                )
                                        }
                                        {
                                            CommonFunctions.isTab3Enabled() && 
                                            CommonFunctions.loginUserType(global.$registrationform.setup.tab3.updateregistration)  ?
                                                (
                                                    <Tab tabFor="tab3" onClick={()=>this.refs.handleRefresh3.hanleRefresh("refresh")}>{global.$registrationform.setup.tab3.tabname}</Tab>
                                                )
                                                :
                                                (
                                                    ''
                                                )
                                        }
                                        {
                                            CommonFunctions.isTab4Enabled() && 
                                            CommonFunctions.loginUserType(global.$registrationform.setup.tab4.updateregistration)  ?
                                                (
                                                    <Tab tabFor="tab4" onClick={()=>this.refs.handleRefresh4.hanleRefresh("refresh")}>{global.$registrationform.setup.tab4.tabname}</Tab>
                                                )
                                                :
                                                (
                                                    ''
                                                )
                                        }
                                        {
                                            CommonFunctions.isTab5Enabled() && 
                                            CommonFunctions.loginUserType(global.$registrationform.setup.tab5.updateregistration)  ?
                                                (
                                                    <Tab tabFor="tab5" onClick={()=>this.refs.handleRefresh5.hanleRefresh("refresh")}>{global.$registrationform.setup.tab5.tabname}</Tab>
                                                )
                                                :
                                                (
                                                    ''
                                                )
                                        }
                                        
                                    </div>
                                
                        
                    </TabList>
                    
                    {
                        CommonFunctions.isTab1Enabled()?
                            (
                                <TabPanel tabId="tab1">
                                    <div className="registration-home">
                                        <RegistrationFormContainer 
                                            type={global.$registrationform.setup.tab1.tabtype} 
                                            tabid={global.$registrationform.setup.tab1.tabid}
                                            enablenewregistration={CommonFunctions.enableRegComponents("tab1") }
                                            enableupdateregistration={CommonFunctions.enableUpdateRegComponents("tab1")}
                                            ref='handleRefresh1'
                                        />
                                    </div>
                                </TabPanel>
                            )
                            :
                            ('')
                    }
                    {
                        CommonFunctions.isTab2Enabled()?
                            (
                                <TabPanel tabId="tab2">
                                    <div className="registration-home">
                                        <RegistrationFormContainer 
                                            type={global.$registrationform.setup.tab2.tabtype} 
                                            tabid={global.$registrationform.setup.tab2.tabid}
                                            enablenewregistration={CommonFunctions.enableRegComponents("tab2") }
                                            enableupdateregistration={CommonFunctions.enableUpdateRegComponents("tab2")}
                                            ref='handleRefresh2'
                                        />
                                    </div>
                                </TabPanel>
                            )
                            :
                            ('')
                    }
                    {
                        CommonFunctions.isTab3Enabled()?
                            (
                                <TabPanel tabId="tab3">
                                    <div className="registration-home">
                                        <RegistrationFormContainer 
                                            type={global.$registrationform.setup.tab3.tabtype} 
                                            tabid={global.$registrationform.setup.tab3.tabid}
                                            enablenewregistration={CommonFunctions.enableRegComponents("tab3") }
                                            enableupdateregistration={CommonFunctions.enableUpdateRegComponents("tab3")}
                                            ref='handleRefresh3'
                                        />
                                    </div>
                                </TabPanel>
                            )
                            :
                            ('')
                    }
                    {
                        CommonFunctions.isTab4Enabled()?
                            (
                                <TabPanel tabId="tab4">
                                    <div className="registration-home">
                                        <RegistrationFormContainer 
                                            type={global.$registrationform.setup.tab4.tabtype} 
                                            tabid={global.$registrationform.setup.tab4.tabid}
                                            enablenewregistration={CommonFunctions.enableRegComponents("tab4") }
                                            enableupdateregistration={CommonFunctions.enableUpdateRegComponents("tab4")}
                                            ref='handleRefresh4'
                                        />
                                    </div>
                                </TabPanel>
                            )
                            :
                            ('')
                    }
                    {
                        CommonFunctions.isTab5Enabled()?
                            (
                                <TabPanel tabId="tab5">
                                    <div className="registration-home">
                                        <RegistrationFormContainer 
                                            type={global.$registrationform.setup.tab5.tabtype} 
                                            tabid={global.$registrationform.setup.tab5.tabid}
                                            enablenewregistration={CommonFunctions.enableRegComponents("tab5")}
                                            enableupdateregistration={CommonFunctions.enableUpdateRegComponents("tab5")}
                                            ref='handleRefresh5'
                                        />
                                    </div>
                                </TabPanel>
                            )
                            :
                            ('')
                    }
                    
                </Tabs>
            </div>
        )
    }

    /*
    OLD CODE

    if (global.$registrationform.defaulttab != null) {
            defaulttab = global.$registrationform.defaulttab;
        }

        //Travcon2022 Exhibtor Check
        if (EventcodeFunctions.isTravconExhibitor(global.$code) && CommonFunctions.isUserLoggedin()) {
            if (CommonFunctions.isUserType("Exhibitor")) {
                defaulttab = "exhibitor";
            }
            else if (CommonFunctions.isUserType("Exhibitor Representative") || CommonFunctions.isUserType("Exhibitor AfterParty")) {
                defaulttab = "sponsor";
            }
        }

    render() {
        var styles = global.$registrationform.styles.home;
        var defaulttab = this.getDefaultTab();

        // If more than one tab
        if (global.$registrationform.numberoftabs != null && global.$registrationform.numberoftabs > 1) {
            return (
                <div>
                    {this.renderRegBanner()}
                    {this.renderTabs(styles, defaulttab)}
                </div>
            )
        }
        else {
            // show only one page without any tab
            //console.log("new Reg Home")
            return (
                <div>
                    {this.renderRegBanner()}
                    <div style={styles}>
                        {this.renderAttendeePage()}
                        {this.renderExhibitorPage()}
                        {this.renderSponsorPage()}
                    </div>
                </div>
            )
        }
    }

    renderAttendeePage() {
        if (CommonFunctions.isAttendeeTabEnabled()) {
            return (
                <div className="registration-home">
                    <RegistrationFormContainer type="attendee" tabid={"attendee"}/>
                </div>
            )
        }

    }

    renderExhibitorPage() {
        if (CommonFunctions.isExhibitorTabEnabled()) {
            return (
                <div className="registration-home">
                    <RegistrationFormContainer type="exhibitor" tabid={"exhibitor"}/>
                </div>
            )
        }
    }
    renderSponsorPage() {
        if (CommonFunctions.isSponsorTabEnabled()) {
            return (
                <div className="registration-home">
                    <RegistrationFormContainer type="sponsor" tabid={"sponsor"}/>
                </div>
            )
        }
    }


    {
                            EventcodeFunctions.isTravconExhibitor(global.$code) && global.$loginstatus.includes("true") ?
                                (
                                    CommonFunctions.isUserType("Exhibitor") ?
                                        (
                                            CommonFunctions.isExhibitorTabEnabled() ?
                                                (
                                                    <Tab tabFor="exhibitor">{global.$registrationform.setup.exhibitor.tabname}</Tab>
                                                )
                                                :
                                                ('')
                                        )
                                        :
                                        CommonFunctions.isUserType("Exhibitor Representative") || CommonFunctions.isUserType("Exhibitor AfterParty") ?
                                            (
                                                CommonFunctions.isSponsorTabEnabled() ?
                                                    (
                                                        <Tab tabFor="sponsor">{global.$registrationform.setup.sponsor.tabname}</Tab>
                                                    )
                                                    :
                                                    ('')
                                            )
                                            :
                                            ('')
                                )
                                :
                                (
    */

}
export default RegistrationHome;


