//import CommonFunctions from "../CommonFunctions";

import RegItemStrings from "../RegItemStrings";
import CommonFunctions from "../CommonFunctions";
import EventCodeFunctions from '../EventcodeFunctions';
import CommonConstants from "../../../Common/CommonConstants";
import EventcodeFunctions from "../EventcodeFunctions";


function findTheTravConUpgradePriceDifference(registereditems, selecteditem, usertype) {
    var price = 0;  
    var commonstrings = RegItemStrings.commonConstants; 
    registereditems.map(registereditem => {
        // This will allow only if the regitem is from booth choice 
        if (usertype == "exhibitor" && (registereditem.name.includes("Booth Registration") || registereditem.name.includes("Booth Bundle Registration") || registereditem.name.includes("Event Vendor"))) {            
            var selectedprice = selecteditem.amount;
            price = Number(selectedprice) - Number(registereditem.price);

            // if it is the downgrade, amount will be in negative this check will update negative to 0;
            if (price < 0) {
                global.$boothdowngrade = true;
                price = 0;
            }
            else {
                global.$boothdowngrade = false;
            }
        }
        else if (usertype == "attendee" && (registereditem.name.includes(commonstrings.mainstageticket) || registereditem.name.includes(commonstrings.newbiebootcamp) || registereditem.name.includes(commonstrings.medicalspanishsun) || registereditem.name.includes(commonstrings.medicalspanishwed) || registereditem.name.includes(commonstrings.travcon2023medicalspanishsun) || registereditem.name.includes(commonstrings.travcon2023medicalspanishwed))) {

            
            var selectedprice = selecteditem.amount;
            price = Number(selectedprice) - Number(registereditem.price);

            // if it is the downgrade, amount will be in negative this check will update negative to 0;
            if (price < 0) {
                global.$boothdowngrade = true;
                price = 0;
            }
            else {
                global.$boothdowngrade = false;
            }
        }
    })
    global.$upgradepricediff = price;
    return price;
}

/*
    Used during upgrade

    regitem - one of the selected items
    registeredItems - previously registered items

    Calculates price of currently selected items and if there is booth upgrade calculates the difference
*/

function findTheAmountfromSelectedItem(regitem, registeredItems) {
    var amount = 0;
    var strings = RegItemStrings.commonConstants;
   //console.log(strings);
    //item is not the booth choice 
    var selecteditemtitle = regitem.name + " - $" + regitem.itemprice;
    //console.log(selecteditemtitle);
    if (selecteditemtitle.includes("Booth Registration") || selecteditemtitle.includes("Booth Bundle Registration")) {
        //console.log(selecteditemtitle, Strings.quadbooth)
        // Item is from booth choice find the price diff
        var pricediff = findTheTravConUpgradePriceDifference(registeredItems, regitem, "exhibitor");
        //console.log(registeredItems);
        amount = Number(pricediff)
    }
    else if (selecteditemtitle.includes(strings.mainstageticket) || selecteditemtitle.includes(strings.newbiebootcamp) || selecteditemtitle.includes(strings.medicalspanishsun) || selecteditemtitle.includes(strings.medicalspanishwed) || selecteditemtitle.includes(strings.travcon2023medicalspanishsun) || selecteditemtitle.includes(strings.travcon2023medicalspanishwed)) {
        var pricediff = findTheTravConUpgradePriceDifference(registeredItems, regitem, "attendee");
       //console.log(pricediff);
        amount = Number(pricediff)
    }
    else {
        amount = Number(regitem.itemprice)
    }
    //console.log(amount);
    return amount;
}
/*
    6190 - item id for TravCon Entreprenuer Marketplance
*/
function ValidateTravelerEntrepreneurMarketplace(selectedregoptions) {

    var TravelerEntrepreneurMarketplaceSelected = false;
    var result = true;

    selectedregoptions.map((item) => {
        if (item.data5 == "6190" || item.data5 == "6190") {
            TravelerEntrepreneurMarketplaceSelected = true;
        }
    })

    if (TravelerEntrepreneurMarketplaceSelected == true) {
        if (CommonFunctions.isRegDiscountExist()) {
            if (global.$regdiscountinfo.discountvalue.includes(`${global.$registrationform.payment.discount.promocode}`)) {
                //console.log("promocode ok")
            }
            else {
                result = false;
            }
        }
        else {
            result = false;
        }
    }

    return result;
}

function ValidateGolfPlayerFee(selectedregoptions) {

    //console.log('ValidateGolfPlayerFee')

    var golfPlayerSelected = false;
    var result = true;
    var data2 = ''  //data4 in regitem is not used for number/quantity item

    selectedregoptions.map((item) => {
        if (item.name == 'Golf Tournament Player Fee' || item.name == 'Golf Tournament - Player Fee') {
            golfPlayerSelected = true;
            data2 = item.data2
        }
    })

    var val = CommonFunctions.isRegDiscountExist()
    //console.log(`golfPlayerSelected = ${golfPlayerSelected}, exists = ${val}, discountvalue = ${global.$regdiscountinfo.discountvalue}, data2=${data2}`)

    if (golfPlayerSelected == true) {

        if (CommonFunctions.isRegDiscountExist()) {

            //if (global.$regdiscountinfo.discountvalue.includes(`${global.$registrationform.payment.discount.promocode}`)) {
            if (global.$regdiscountinfo.discountvalue.includes(data2)) {

            }
            else {
                result = false;
            }
        }
        else {
            result = false;
        }
    }

    //console.log('ValidateGolfPlayerFee: Validation - ' + result)
    //return false
    return result;
}

/*
    TravCon 2022 - Max platinum booths is 11
    TravCon 2023 - Max platfinum booths is 10
*/
// Platinum and Event Vendor + platinum count
function getCurrentSignupsForPlatinumBooths() {
    var count = 0;
    global.$regitems.map(regitem => {
        if (regitem.name.includes("Platinum")) {
           
            count = Number(count) + Number(regitem.currentsignups);
        }
    })

    return count;
}

function getSignupsRemaining(regitem, availableCounts) {
    for (const keyword in availableCounts) {
        if (regitem.name.startsWith(keyword)) {
            return availableCounts[keyword] + " Available";
        }
    }
}

function getBoothCount(startswith1,startswith2,configmaxcount) {
    let maxsignups = "";
    let signupcount = global.$regsubitems.reduce((count, regitem) => {
        if (regitem.name.startsWith(startswith1) || regitem.name.startsWith(startswith2)) {
            return count + Number(regitem.currentsignups);
        }
        
        return count;
    }, 0);

    if (EventCodeFunctions.isTravconExhibitor2023(global.$code) || EventCodeFunctions.isTravconExhibitor2024(global.$code)) {
        maxsignups = global.$config.registrationform.exhibitor[configmaxcount]
    }
    else{
        maxsignups = RegItemStrings.travconExhibitor2023RegItems.maxplatinumboothcount;
    }
    var availablecount = Number(maxsignups) - Number(signupcount);
    return availablecount;
}

function getIndustrySupplierCount(startswith1,includes1,startswith2,configmaxcount) {
    let maxsignups = "";
    let signupcount1 = global.$regsubitems.reduce((count, regitem) => {
        if (regitem.name.includes(includes1) || regitem.name.startsWith(startswith1)) {
            return count + Number(regitem.currentsignups);
        }
        return count;
    }, 0);

    let signupcount2 = global.$regitems.reduce((count, regitem) => {
        if (regitem.name == startswith2) {
            return count + Number(regitem.currentsignups);
        }
        return count;
    }, 0);

    let signupcount = Number(signupcount1) + Number(signupcount2);

    if (EventCodeFunctions.isTravconExhibitor2024(global.$code)) {
        maxsignups = global.$config.registrationform.exhibitor[configmaxcount]
    }
    var availablecount = Number(maxsignups) - Number(signupcount);
    return availablecount ;
}

function getIndustrySupplierItemCount(startswith1,includes1,configmaxcount) {
    let maxsignups = "";
    let signupcount1 = global.$regitems.reduce((count, regitem) => {
        if (regitem.name == startswith1) {
            return count + Number(regitem.currentsignups);
        }
        return count;
    }, 0);

    let signupcount2 = global.$regsubitems.reduce((count, regitem) => {
        if (regitem.name.startsWith(includes1)) {
            return count + Number(regitem.currentsignups);
        }
        return count;
    }, 0);
  
    let signupcount = Number(signupcount1) + Number(signupcount2);

    if (EventCodeFunctions.isTravconExhibitor2024(global.$code)) {
        maxsignups = global.$config.registrationform.exhibitor[configmaxcount]
    }
    var availablecount = Number(maxsignups) - Number(signupcount);
    return availablecount ;
}

function getAvailableSignupsForPlatinumBooths() {
    var maxsignups = "";
    var signupcount = getCurrentSignupsForPlatinumBooths();

    if (EventCodeFunctions.isTravconExhibitor2023(global.$code) || EventCodeFunctions.isTravconExhibitor2024(global.$code)) {
        maxsignups = global.$config.registrationform.exhibitor.travconplatinummax
    }
    else{
        maxsignups = RegItemStrings.travconExhibitor2023RegItems.maxplatinumboothcount;
    }
     
    var availablecount = Number(maxsignups) - Number(signupcount);
    return availablecount;
}

// Event Vendor  count
function getCurrentSignupsForEventVendorBooths() {
    var count = 0;
    global.$regitems.map((regitem) => {
        if (regitem.name.startsWith("Event Vendor") || regitem.name.includes("+ Platinum")) {
            count = Number(count) + Number(regitem.currentsignups);
        }
    })
    return count;
}

function getAvailableSignupsForEventVendorBooths() {
    var maxsignups = "";
    var signupcount = getCurrentSignupsForEventVendorBooths();
    if (EventCodeFunctions.isTravconExhibitor2023(global.$code) || EventCodeFunctions.isTravconExhibitor2024(global.$code)) {
        maxsignups = global.$config.registrationform.exhibitor.travconeventvendormax
    }
    else {
        maxsignups = RegItemStrings.travconExhibitor2023RegItems.maxplatinumboothcount;
    }
   
    var availablecount = Number(maxsignups) - Number(signupcount);
    return availablecount;
}



function validateTravconRegItemsForUpgrade() {
    //console.log(global.$boothdowngrade);

    var commonConstants = RegItemStrings.commonConstants;
    var resultObj = new Object();
    resultObj.result = false;
    resultObj.message = "";
    //console.log(commonConstants);

    // this global variable is upddating in findTheTravConUpgradePriceDifference() function
    if (global.$boothdowngrade == true) {
        // downgrade
        resultObj.result = false;
        resultObj.message = commonConstants.boothdowngradeerrormessage;
    }
    else {
        resultObj.result = true; // Upgrade
    }
    //console.log(resultObj);
    return resultObj;
}
function replaceRegItemsForRegUpgrade(code, registereditems, selecteditems) {
   //console.log("replaceRegItemsForRegUpgrade")
   //console.log(registereditems)
    var regitems = [];
    if (EventCodeFunctions.isTravconExhibitor(global.$code)) {
        // already registered items
       
        registereditems.map(registereditem => {
            console.log(registereditem);
            var Strings = RegItemStrings.getTravconExhibitorRegItemStrings();
            if (registereditem.includes(Strings.singlebooth)) // true
            {
                global.$regitemtoreducecount = Strings.singlebooth;
                registereditem = registereditem.replace(Strings.singlebooth, "");
            }
            else if (registereditem.includes(Strings.doublebooth)) {

                global.$regitemtoreducecount = Strings.doublebooth;
                registereditem = registereditem.replace(Strings.doublebooth, "");
            }
            else if (registereditems.includes(Strings.triplebooth)) {

                global.$regitemtoreducecount = Strings.triplebooth;
                registereditem = registereditem.replace(Strings.triplebooth, "");
            }
            else if (registereditems.includes(Strings.quadbooth)) {

                global.$regitemtoreducecount = Strings.quadbooth;
                registereditem = registereditem.replace(Strings.quadbooth, "");
            }
            else if (registereditems.includes(Strings.hexabooth)) {
                global.$regitemtoreducecount = Strings.hexabooth;
                registereditem = registereditem.replace(Strings.hexabooth, "");
            }
            else if (registereditems.includes(Strings.platinumbooth)) {

                global.$regitemtoreducecount = Strings.platinumbooth;
                registereditem = registereditem.replace(Strings.platinumbooth, "");
            }
            else if (registereditems.includes(Strings.platinumdoublebooth)) {

                global.$regitemtoreducecount = Strings.platinumdoublebooth;
                registereditem = registereditem.replace(Strings.platinumdoublebooth, "");
            }
            else if (registereditems.includes(Strings.platinumtriplebooth)) {

                global.$regitemtoreducecount = Strings.platinumtriplebooth;
                registereditem = registereditem.replace(Strings.platinumtriplebooth, "");
            }
            else if (registereditems.includes(Strings.platinumquadbooth)) {

                global.$regitemtoreducecount = Strings.platinumquadbooth;
                registereditem = registereditem.replace(Strings.platinumquadbooth, "");
            }
            else if (registereditems.includes(Strings.platinumhexabooth)) {

                global.$regitemtoreducecount = Strings.platinumhexabooth;
                registereditem = registereditem.replace(Strings.platinumhexabooth, "");
            }
            else if (registereditem.includes(Strings.eventvendor)) {
                global.$regitemtoreducecount = Strings.eventvendor;
                registereditem = registereditem.replace(Strings.eventvendor, "");
            }
            else if (registereditem.includes(Strings.eventvendorsingle)) {
                global.$regitemtoreducecount = Strings.eventvendorsingle;
                registereditem = registereditem.replace(Strings.eventvendorsingle, "");
            }
            else if (registereditem.includes(Strings.eventvendordouble)) {
                global.$regitemtoreducecount = Strings.eventvendordouble;
                registereditem = registereditem.replace(Strings.eventvendordouble, "");
            }
            else if (registereditem.includes(Strings.eventvendortriple)) {
                global.$regitemtoreducecount = Strings.eventvendortriple;
                registereditem = registereditem.replace(Strings.eventvendortriple, "");
            }
            else if (registereditem.includes(Strings.eventvendorquad)) {
                global.$regitemtoreducecount = Strings.eventvendorquad;
                registereditem = registereditem.replace(Strings.eventvendorquad, "");
            }
            else if (registereditem.includes(Strings.eventvendorhexa)) {
                global.$regitemtoreducecount = Strings.eventvendorhexa;
                registereditem = registereditem.replace(Strings.eventvendorhexa, "");
            }
            else if (registereditem.includes(Strings.eventvendorplatinumdouble)) {
                global.$regitemtoreducecount = Strings.eventvendorplatinumdouble;
                registereditem = registereditem.replace(Strings.eventvendorplatinumdouble, "");
            }
            else if (registereditem.includes(Strings.eventvendorplatinumtriple)) {
                global.$regitemtoreducecount = Strings.eventvendorplatinumtriple;
                registereditem = registereditem.replace(Strings.eventvendorplatinumtriple, "");
            }
            else if (registereditem.includes(Strings.eventvendorplatinumquad)) {
                global.$regitemtoreducecount = Strings.eventvendorplatinumquad;
                registereditem = registereditem.replace(Strings.eventvendorplatinumquad, "");
            }
            else if (registereditem.includes(Strings.eventvendorplatinumhexa)) {
                global.$regitemtoreducecount = Strings.eventvendorplatinumhexa;
                registereditem = registereditem.replace(Strings.eventvendorplatinumhexa, "");
            }
            regitems.push(registereditem)
        })
        //console.log(global.$regitemtoreducecount, regitems);
        return regitems.toString();
    }
    else if (EventCodeFunctions.isTravconAttendee(global.$code)) {
        // already registered items

        var commonstrings = RegItemStrings.commonConstants;

        registereditems = registereditems.split(",")
        //console.log(registereditems);
        registereditems.map(registereditem => {
            if (registereditem.includes(commonstrings.mainstageticket)) // true
            {
                global.$regitemtoreducecount = commonstrings.mainstageticket;
                registereditem = "";
            }
            else if (registereditem.includes(commonstrings.newbiebootcamp)) // true
            {
                global.$regitemtoreducecount = commonstrings.newbiebootcamp;
                registereditem = "";
            }
            else if (registereditem.includes(commonstrings.medicalspanishsun)) // true
            {
                global.$regitemtoreducecount = commonstrings.medicalspanishsun;
                registereditem = "";
            }
            else if (registereditem.includes(commonstrings.medicalspanishwed)) // true
            {
                global.$regitemtoreducecount = commonstrings.medicalspanishwed;
                registereditem = "";
            }
            else if (registereditem.includes(commonstrings.travcon2023medicalspanishsun)) // true
            {
                global.$regitemtoreducecount = commonstrings.travcon2023medicalspanishsun;
                registereditem = "";
            }
            else if (registereditem.includes(commonstrings.travcon2023medicalspanishwed)) // true
            {
                global.$regitemtoreducecount = commonstrings.travcon2023medicalspanishwed;
                registereditem = "";
            }
            regitems.push(registereditem)
        })
        //console.log(global.$regitemtoreducecount, regitems);
       //console.log(regitems.toString());
        return regitems.toString();
    }
    else {
        return regitems.toString();
    }
}
function getMaxRepCountForExhibtior(regitemsjson) {

    var commonstringsjson = EventCodeFunctions.CommonStrings(global.$code).commonstringsjson;
    var repcounts = EventCodeFunctions.CommonStrings(global.$code).repcounts;;
    
     
    
    var noofreps = 0;
    var regitems = regitemsjson;

    regitems.map((regitem) => {
        commonstringsjson.map((item) => {
            if (regitem.status == 'active') {
                if (regitem.subitems) {
                    if (regitem.subitems[0].itemid == item.itemid) {
                        noofreps = item.repscount;
                    }
                }
                else {
                    if (regitem.itemid == item.itemid) {
                        noofreps = item.repscount;
                    }
                }
                
            }
        })
        
    })

    // if (regtype.includes(commonstrings.platinumhexabooth)) {  
    //     //console.log(repcounts.platinumhexareps)
    //     noofreps = repcounts.platinumhexareps;
    // }
    // else if (regtype.includes(commonstrings.platinumquadbooth)) {
    //     noofreps = repcounts.platinumquadreps;
    // }
    // else if (regtype.includes(commonstrings.platinumtriplebooth)) {
    //     noofreps = repcounts.platinumtriplereps;
    // }
    // else if (regtype.includes(commonstrings.platinumdoublebooth)) {
    //     noofreps = repcounts.platinumdoublereps;
    // }
    // else if (regtype.includes(commonstrings.platinumbooth)) {
    //     noofreps = repcounts.platinumreps;
    // }
    
    // else if (regtype.includes(commonstrings.hexabooth)) {
    //     noofreps = repcounts.hexareps;
    // }
    // else if (regtype.includes(commonstrings.quadbooth)) {
    //     noofreps = repcounts.quadreps;
    // }
    // else if (regtype.includes(commonstrings.triplebooth)) {
    //     noofreps = repcounts.triplereps;
    // }
    // else if (regtype.includes(commonstrings.doublebooth)) {
    //     noofreps = repcounts.doublereps;
    // }
    // else if (regtype.includes(commonstrings.singlebooth)) {
    //     noofreps = repcounts.singlereps;
    // }
    // else if (regtype.includes(commonstrings.eventvendor)) {
    //     noofreps = repcounts.eventvendorreps;
    // }
    // else if (regtype.includes(commonstrings.eventvendorplatinumhexa)) {
    //     noofreps = repcounts.eventvendorplatinumhexareps;
    // }
    // else if (regtype.includes(commonstrings.eventvendorplatinumquad)) {
    //     noofreps = repcounts.eventvendorplatinumquadreps;
    // }
    // else if (regtype.includes(commonstrings.eventvendorplatinumtriple)) {
    //     noofreps = repcounts.eventvendorplatinumtriplereps;
    // }
    // else if (regtype.includes(commonstrings.eventvendorplatinumdouble)) {
    //     noofreps = repcounts.eventvendorplatinumdoublereps;
    // }
    // else if (regtype.includes(commonstrings.eventvendorplatinum)) {
    //     noofreps = repcounts.eventvendorplatinumreps;
    // }
    // else if (regtype.includes(commonstrings.eventvendorhexa)) {
    //     noofreps = repcounts.eventvendorhexareps;
    // }
    // else if (regtype.includes(commonstrings.eventvendorquad)) {
    //     noofreps = repcounts.eventvendorquadreps;
    // }
    // else if (regtype.includes(commonstrings.eventvendortriple)) {
    //     noofreps = repcounts.eventvendortriplereps;
    // }
    // else if (regtype.includes(commonstrings.eventvendordouble)) {
    //     noofreps = repcounts.eventvendordoublereps;
    // }
    // else if (regtype.includes(commonstrings.eventvendorsingle)) {
    //     noofreps = repcounts.eventvendorsinglereps;
    // }
    // else if (regtype.includes(commonstrings.eventvendortitle)) {
    //     noofreps = repcounts.eventvendorreps;
    // }
    // else if (regtype.includes(commonstrings.eventvendorplatinumdouble)) {
    //     noofreps = repcounts.eventvendorplatinumdoublereps;
    // }
    // else if (regtype.includes(commonstrings.eventvendorplatinumtriple)) {
    //     noofreps = repcounts.eventvendorplatinumtriplereps;
    // }
    // else if (regtype.includes(commonstrings.eventvendorplatinumquad)) {
    //     noofreps = repcounts.eventvendorplatinumquadreps;
    // }
    // else if (regtype.includes(commonstrings.eventvendorplatinumhexa)) {
    //     noofreps = repcounts.eventvendorplatinumhexareps;
    // }

    //console.log(noofreps);
    var noofbadges = getAdditionalbadgesCount(regitems);

    //console.log(`getMaxRepCountForExhibtior: noofreps=${noofreps},noofbadges=${noofbadges}`)

    noofreps = Number(noofreps) + Number(noofbadges);
    return noofreps;
}

function getAdditionalbadgesCount(regitems) {
    var badges = 0;

    // travcon2024exhibitor rep regitem name -> Additional Exhibitor Rep Badge
    regitems.map(item => {
        if (item.name == "Additional Representative Badges" || item.name == "Additional Exhibitor Rep Badge") {
            badges += Number(item.qty)
        }
    })

    // var regitems = regtype.split(",");
    // //var totalbadges = 0;
    // regitems.map(item => {
    //     //console.log(item);
    //     if (item.includes("Additional Representative Badges")) {
    //         // get the quantity from the regitem
    //         //console.log(item);
    //         var quantity = item.substring(
    //             item.lastIndexOf("Additional Representative Badges - ") + 35, // 35 is the number of characters
    //             item.lastIndexOf(" - $"));
    //         //console.log(quantity);
    //         badges = Number(badges) + Number(quantity);
    //         //console.log(badges);
    //     }
    // })
    return badges;
}

/*
    AfterParty Tickets can be purchsed by AfterParty sponsors or Event Vendors.
    NOTE: There is string difference between 2022 and 2023
*/

function checkRuleTravConAfterPartyTickets(currentregitems,registeredItems)
{
    var Strings = RegItemStrings.getTravconExhibitorRegItemStrings();

    var afterparty = false
    var vendor_ap = false

    if (currentregitems !="" && currentregitems !=" ") {
        currentregitems = JSON.parse(currentregitems);
        if (currentregitems != null) { 

            currentregitems.map((regitem) => {
                if (regitem.name.includes(Strings.afterparty)){
                    afterparty = true
                }
                
                if (regitem.name.includes("TravCon AfterParty Tuesday Evening (Max 9)") ||
                    regitem.name.includes("TravCon AfterParty Sponsorship") || 
                    regitem.name.includes("Event Vendor")) {
                    vendor_ap = true
                }
            })
            
        }
    }
    
    if (registeredItems != null && registeredItems != "") { 

        registeredItems.map((regitem) => {
            if (regitem.name.includes(Strings.afterparty)){
                afterparty = true
            }
            
            if (regitem.name.includes("TravCon AfterParty Tuesday Evening (Max 9)") ||
                regitem.name.includes("TravCon AfterParty Sponsorship") || 
                regitem.name.includes("Event Vendor")) {
                vendor_ap = true
            }
        })

        // if ( registeredItems.includes(Strings.afterparty)){
        //     afterparty = true
        // }

        // if (registeredItems.includes("TravCon AfterParty Tuesday Evening (Max 9)") || 
        //     registeredItems.includes("TravCon AfterParty Sponsorship") || 
        //     registeredItems.includes("Event Vendor")) {
        //     vendor_ap = true
        // }
    }

    if (afterparty) {
        return vendor_ap
    }
    else 
        return true //After Party is not purchased. Validation is ok

    /*
    if (regitems != null ){
        if (regitems.includes(Strings.afterparty) ){
            if (regitems.includes("TravCon AfterParty Tuesday Evening (Max 9)") || regitems.includes("Event Vendor")) {
                return true        
            }
        else return false
        }
    }
        return true;
    */
}

export default {
    findTheTravConUpgradePriceDifference,
    findTheAmountfromSelectedItem,
    ValidateTravelerEntrepreneurMarketplace,
    ValidateGolfPlayerFee,
    getCurrentSignupsForPlatinumBooths,
    getAvailableSignupsForPlatinumBooths,
    validateTravconRegItemsForUpgrade,
    replaceRegItemsForRegUpgrade,
    getMaxRepCountForExhibtior,
    checkRuleTravConAfterPartyTickets,
    getAvailableSignupsForEventVendorBooths,
    getBoothCount,
    getSignupsRemaining,
    getIndustrySupplierCount,
    getIndustrySupplierItemCount
}
/*

function findTheTravConUpgradePriceDifference(registereditems, selecteditem) {
    var price = 0;
    // var Strings = RegItemStrings.getTravconExhibitorRegItemStrings();
    // var selecteditemtitle = selecteditem.name + " - $" + selecteditem.itemprice;
    //console.log(Strings);
    //console.log(registereditems)
    registereditems = registereditems.split(",")
    registereditems.map(registereditem => {

        // This will allow only if the regitem is from booth choice
        if (registereditem.includes("Booth Registration") || registereditem.includes("Booth Bundle Registration")) {
            var [title, registeredprice] = registereditem.split(" - $");
            var selectedprice = selecteditem.itemprice;
            //console.log(selectedprice, registeredprice);
            price = Number(selectedprice) - Number(registeredprice);

            // if it is the downgrade, amount will be in negative this check will update negative to 0;
            if (price < 0) {
                price = 0;
            }
        }

        //console.log(registereditem)
        //console.log(selecteditem);
        //console.log(selecteditemtitle);
        //console.log(registereditem, registereditem.includes(Strings.singlebooth), registereditem.includes(`${Strings.singlebooth}`));
        // if (registereditem.includes(Strings.singlebooth) || registereditem.includes(Strings.eventvendor)) // true
        // {
        //     //console.log(singlebooth)
        //     var [title, registeredprice] = registereditem.split(" - $");
        //     var selectedprice = selecteditem.itemprice;
        //     //console.log(selectedprice, registeredprice);
        //     price = Number(selectedprice) - Number(registeredprice);
        //     //console.log(price);
        // }
        // else if (registereditem.includes(Strings.eventvendorsingle)) // true
        // {
        //     if (!selecteditemtitle.includes(Strings.singlebooth) && !selecteditemtitle.includes(Strings.eventvendor)) {
        //         //console.log(singlebooth)
        //         var [title, registeredprice] = registereditem.split(" - $");
        //         var selectedprice = selecteditem.itemprice;
        //         //console.log(selectedprice, registeredprice);
        //         price = Number(selectedprice) - Number(registeredprice);
        //     }
        //     //console.log(price);
        // }
        // else if (registereditem.includes(Strings.eventvendordouble)) // true
        // {
        //     if (!selecteditemtitle.includes(Strings.singlebooth) && !selecteditemtitle.includes(Strings.doublebooth) && !selecteditemtitle.includes(Strings.eventvendor) && !selecteditemtitle.includes(Strings.eventvendorsingle)) {
        //         //console.log(singlebooth)
        //         var [title, registeredprice] = registereditem.split(" - $");
        //         var selectedprice = selecteditem.itemprice;
        //         //console.log(selectedprice, registeredprice);
        //         price = Number(selectedprice) - Number(registeredprice);
        //     }
        //     //console.log(price);
        // }
        // else if (registereditem.includes(Strings.eventvendorplatinumdouble)) // true
        // {
        //     if (!selecteditemtitle.includes(Strings.singlebooth) && !selecteditemtitle.includes(Strings.doublebooth) && !selecteditemtitle.includes(Strings.eventvendor) && !selecteditemtitle.includes(Strings.triplebooth) && !selecteditemtitle.includes(Strings.quadbooth) && !selecteditemtitle.includes(Strings.eventvendorsingle) && !selecteditemtitle.includes(Strings.eventvendordouble)) {
        //         //console.log(singlebooth)
        //         var [title, registeredprice] = registereditem.split(" - $");
        //         var selectedprice = selecteditem.itemprice;
        //         //console.log(selectedprice, registeredprice);
        //         price = Number(selectedprice) - Number(registeredprice);
        //     }
        //     //console.log(price);
        // }
        // else if (registereditem.includes(Strings.doublebooth)) {

        //     if (!selecteditemtitle.includes(Strings.singlebooth) && !selecteditemtitle.includes(Strings.eventvendor) && !selecteditemtitle.includes(Strings.eventvendorsingle)) {
        //         var [title, registeredprice] = registereditem.split(" - $");
        //         var selectedprice = selecteditem.itemprice;
        //         //console.log(registeredprice, selectedprice);
        //         price = Number(selectedprice) - Number(registeredprice);
        //     }
        //     //console.log(price);
        // }
        // else if (registereditem.includes(Strings.triplebooth)) {
        //     ////console.log(triplebooth);
        //     if (!selecteditemtitle.includes(Strings.singlebooth) && !selecteditemtitle.includes(Strings.doublebooth) && !selecteditemtitle.includes(Strings.eventvendor) && !selecteditemtitle.includes(Strings.eventvendorsingle)) {
        //         var [title, registeredprice] = registereditem.split(" - $");
        //         var selectedprice = selecteditem.itemprice;
        //         price = Number(selectedprice) - Number(registeredprice);
        //     }
        //     ////console.log(price);
        // }
        // else if (registereditem.includes(Strings.quadbooth)) {

        //     if (!selecteditemtitle.includes(Strings.singlebooth) && !selecteditemtitle.includes(Strings.doublebooth) && !selecteditemtitle.includes(Strings.triplebooth) && !selecteditemtitle.includes(Strings.eventvendor) && !selecteditemtitle.includes(Strings.eventvendorsingle) && !selecteditemtitle.includes(Strings.eventvendordouble)) {
        //         var [title, registeredprice] = registereditem.split(" - $");
        //         var selectedprice = selecteditem.itemprice;
        //         price = Number(selectedprice) - Number(registeredprice);
        //     }
        //     //console.log(price);
        // }
        // else if (registereditem.includes(Strings.hexabooth)) {

        //     if (!selecteditemtitle.includes(Strings.singlebooth) && !selecteditemtitle.includes(Strings.doublebooth) && !selecteditemtitle.includes(Strings.triplebooth) && !selecteditemtitle.includes(Strings.quadbooth) && !selecteditemtitle.includes(Strings.eventvendor) && !selecteditemtitle.includes(Strings.eventvendorsingle) && !selecteditemtitle.includes(Strings.eventvendordouble) && !selecteditemtitle.includes(Strings.eventvendorplatinumdouble)) {
        //         var [title, registeredprice] = registereditem.split(" - $");
        //         var selectedprice = selecteditem.itemprice;
        //         price = Number(selectedprice) - Number(registeredprice);
        //     }
        //     //console.log(price);
        // }
        // else if (registereditem.includes(Strings.platinumdoublebooth)) {

        //     if (!selecteditemtitle.includes(Strings.singlebooth) && !selecteditemtitle.includes(Strings.doublebooth) && !selecteditemtitle.includes(Strings.triplebooth) && !selecteditemtitle.includes(Strings.quadbooth) && !selecteditemtitle.includes(Strings.hexabooth) && !selecteditemtitle.includes(Strings.eventvendor) && !selecteditemtitle.includes(Strings.eventvendorsingle) && !selecteditemtitle.includes(Strings.eventvendordouble) && !selecteditemtitle.includes(Strings.eventvendorplatinumdouble)) {
        //         var [title, registeredprice] = registereditem.split(" - $");
        //         var selectedprice = selecteditem.itemprice;
        //         price = Number(selectedprice) - Number(registeredprice);
        //     }
        //     //console.log(price);
        // }
        // else if (registereditem.includes(Strings.platinumtriplebooth)) {

        //     if (!selecteditemtitle.includes(Strings.singlebooth) && !selecteditemtitle.includes(Strings.doublebooth) && !selecteditemtitle.includes(Strings.triplebooth) && !selecteditemtitle.includes(Strings.quadbooth) && !selecteditemtitle.includes(Strings.hexabooth) && !selecteditemtitle.includes(Strings.platinumdoublebooth) && !selecteditemtitle.includes(Strings.eventvendor) && !selecteditemtitle.includes(Strings.eventvendorsingle) && !selecteditemtitle.includes(Strings.eventvendordouble) && !selecteditemtitle.includes(Strings.eventvendorplatinumdouble)) {
        //         var [title, registeredprice] = registereditem.split(" - $");
        //         var selectedprice = selecteditem.itemprice;
        //         price = Number(selectedprice) - Number(registeredprice);
        //     }
        //     //console.log(price);
        // }
        // else if (registereditem.includes(Strings.platinumquadbooth)) {

        //     if (!selecteditemtitle.includes(Strings.singlebooth) && !selecteditemtitle.includes(Strings.doublebooth) && !selecteditemtitle.includes(Strings.triplebooth) && !selecteditemtitle.includes(Strings.quadbooth) && !selecteditemtitle.includes(Strings.hexabooth) && !selecteditemtitle.includes(Strings.platinumdoublebooth) && !selecteditemtitle.includes(Strings.platinumtriplebooth) && !selecteditemtitle.includes(Strings.eventvendor) && !selecteditemtitle.includes(Strings.eventvendorsingle) && !selecteditemtitle.includes(Strings.eventvendordouble) && !selecteditemtitle.includes(Strings.eventvendorplatinumdouble)) {
        //         var [title, registeredprice] = registereditem.split(" - $");
        //         var selectedprice = selecteditem.itemprice;
        //         price = Number(selectedprice) - Number(registeredprice);
        //     }
        //     //console.log(price);
        // }
        // else if (registereditem.includes(Strings.platinumhexabooth)) {

        //     if (!selecteditemtitle.includes(Strings.singlebooth) && !selecteditemtitle.includes(Strings.doublebooth) && !selecteditemtitle.includes(Strings.triplebooth) && !selecteditemtitle.includes(Strings.quadbooth) && !selecteditemtitle.includes(Strings.hexabooth) && !selecteditemtitle.includes(Strings.platinumdoublebooth) && !selecteditemtitle.includes(Strings.platinumtriplebooth) && !selecteditemtitle.includes(Strings.platinumquadbooth) && !selecteditemtitle.includes(Strings.eventvendor) && !selecteditemtitle.includes(Strings.eventvendorsingle) && !selecteditemtitle.includes(Strings.eventvendordouble) && !selecteditemtitle.includes(Strings.eventvendorplatinumdouble)) {
        //         var [title, registeredprice] = registereditem.split(" - $");
        //         var selectedprice = selecteditem.itemprice;
        //         price = Number(selectedprice) - Number(registeredprice);
        //     }
        //     //console.log(price);
        // }
    })
    //console.log(price);
    global.$upgradepricediff = price;
    return price;
}
*/