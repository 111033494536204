import React from 'react';
import { Row, Col } from 'reactstrap';
import './Home.css';
import { API } from 'aws-amplify';
var serviceNames = require('../ServiceNameMapper');

export default class sponsors extends React.Component {
  constructor(props) {
    super(props);
    var sponsorfilter = "All";
    if (global.$home.sponsors.filter != null) {
      sponsorfilter = global.$home.sponsors.filter
    }

    this.state =
    {
      code: global.$code,
      bucket: global.$s3bucket,
      sponsorfilter: sponsorfilter
    };

  }

  render() {
    if (this.state.dataloaded) {
      return (
        <div>
          <div dangerouslySetInnerHTML={{ __html: global.$home.sponsors.title }}></div>
          <div className="sponsors-section">
            {this.displaySponsors()}
            <br />
          </div>
          <br />
          <br />
        </div>

      );
    }
    else {
      if (global.$sponsorjson != null) {
        this.setState({ dataloaded: true })
      }
      else {
        this.getSponsorjson()
      }
      return (
        <div></div>
      )
    }
  }
  getSponsorjson() {
    //console.log("sponsor json")
    let apiName = serviceNames.getWebsiteService();
    let path = ''; //replace this with the path you have configured on your API
    let myInit = {
      body:
      {
        'code': this.state.code,
        'command': 'getSponsorsJson',
        'bucket': this.state.bucket
      },
      response: true,
      convertEmptyValues: true,
      headers: {}
    }
    //console.log(myInit);

    API.post(apiName, path, myInit).then(response => {
      //console.log ("response.data ===> " + response.data + "response.err ===> " + response.err);

      if (response.err == null && response.data != null) {
        let buf = Buffer.from(response.data.Body);
        //console.log(buf)
        //console.log(buf.toString());
        global.$sponsorjson = JSON.parse(buf.toString());
        this.setState({ dataloaded: true })

      }
      else {
        ////console.log("getAttendeesTableObjects - failed!!");
        //console.log(response.err);
      }
    }
    ).catch(error => {
      ////console.log(error.response)
    });

  }

  removeDuplicates(originalArray, prop) {
    //console.log(originalArray);
    var newArray = [];
    var lookupObject = {};

    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    //console.log(newArray);
    return newArray;
  }

  displaySponsors() {
    //console.log(this.state.code)

    var uniqueArray = this.removeDuplicates(global.$sponsorjson, "NAME");

    var array = this.state.sponsorfilter.split(",");
    return uniqueArray.map(sponsor => {
      //console.log(sponsor)
      var logo = `https://s3.amazonaws.com/event360live/public/${this.state.code}/exhibitors/` + sponsor.LOGO
      if (array.includes("All")) {
        if (sponsor.TYPE.includes("T")) {
          return ('')
        }
        else {
          if (sponsor.LOGO != "" && sponsor.LOGO != " ") {
            if (sponsor.URL == "") {
              return (
                <div className="sponsors-home">
                  <img src={logo} height="auto" width="150" />
                </div>
              )
            }
            else {

              return (
                <div className="sponsors-home">
                  <a href={sponsor.URL} target="_blank"><img src={logo} height="auto" width="150" /></a>
                </div>
              );
            }
          }
        }
      }
      else if (array.includes(sponsor.SPLEVEL)) {
        if (sponsor.TYPE.includes("T")) {
          return ('')
        }
        else {
          if (sponsor.LOGO != "" && sponsor.LOGO != " ") {
            if (sponsor.URL == "") {
              return (
                <div className="sponsors-home">
                  <img src={logo} height="auto" width="150" />
                </div>
              )
            }
            else {

              return (
                <div className="sponsors-home">
                  <a href={sponsor.URL} target="_blank"><img src={logo} height="auto" width="150" /></a>
                </div>
              );
            }
          }
        }
      }
    })
  }

}