import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from "aws-amplify";
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from "react-router-dom";


// App url 
// http://localhost:3000?code=virtualsummit
// http://eventsitesdev.s3-website.us-east-1.amazonaws.com/?code=virtualsummit
// http://eventsiteslive.s3-website.us-east-1.amazonaws.com/?code=virtualsummit


// Run command
// npm start

// Build Command
// npm run build
// Copy build directory

// Commands & packages for creating New React App

//1. npx create-react-app event360
//2.npm install reactstrap
//3.npm install aws-amplify --save
//4.npm install react-bootstrap-table --save
//5.npm install react-scroll-up-button
//6.npm install react-countdown-now --save //installed but not using
//7.npm i react-html-parser
//8.npm i react-bootstrap
//9.npm install react-awesome-spinners
//10.npm i react-styled-components
//12.npm i react-modal-login
//13.npm i react-responsive-modal
//14.npm i @zoomus/websdk
//npm i babylonjs
//npm i react-confirm-alert

// Resources
// https://www.fullstackreact.com/articles/deploying-a-react-app-to-s3/
//Add Lambda function access to role exhibitors_sponsors_ip

Amplify.configure({
    Auth: {
        identityPoolId:         'us-east-1:f21ee086-a0ae-4bfc-a1b7-58913104d709', //exhibitors_sponsors_ip
        region:                 'us-east-1',
        //userPoolId:             'us-east-1_LtVLdp322', //us-east-1_LtVLdp322 - AppAdmin 
        //userPoolWebClientId:    '2cbpuu5f2vql93d28pk0he39do' //2cbpuu5f2vql93d28pk0he39do  - AppAdmin
        
        //userPoolId:             pool, //us-east-1_cx9zgTYXf - devtest 
        //userPoolWebClientId:    '4ul2rsbo8viha7u2lfv7s32pej' //4ul2rsbo8viha7u2lfv7s32pej  - devtest
        //userPoolWebClientId:    '5dfap44mptvq1b5bie84eaoav8' //4ul2rsbo8viha7u2lfv7s32pej  - devtest without secretkey
    },
    Storage: {
        accessKeyId: 'AKIAIARZGSELD5JWB54Q',    //ContentUpdateer Second Creds
        secretAccessKey: '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', 
        bucket: 'event360live', 
        region: 'us-east-1'     
    },
    API: {
        endpoints: [
            {
                name: "EventPlannerService",
                endpoint: "https://lambda.us-east-1.amazonaws.com/2015-03-31/functions/EventPlannerService/invocations",
                service: "lambda",
                region: "us-east-1"
            },
            {
                name: "EventPlannerDevService",
                endpoint: "https://lambda.us-east-1.amazonaws.com/2015-03-31/functions/EventPlannerDevService/invocations",
                service: "lambda",
                region: "us-east-1"
            },
            {
                name: "UserService",
                endpoint: "https://lambda.us-east-1.amazonaws.com/2015-03-31/functions/UserService/invocations",
                service: "lambda",
                region: "us-east-1"
            },
            {
                name: "UserDevService",
                endpoint: "https://lambda.us-east-1.amazonaws.com/2015-03-31/functions/UserDevService/invocations",
                service: "lambda",
                region: "us-east-1"
            },
            {
                name: "EventsitesService",
                endpoint: "https://lambda.us-east-1.amazonaws.com/2015-03-31/functions/EventsitesService/invocations",
                service: "lambda",
                region: "us-east-1"
            },
            {
                name: "WebsiteService",
                endpoint: "https://lambda.us-east-1.amazonaws.com/2015-03-31/functions/WebsiteService/invocations",
                service: "lambda",
                region: "us-east-1"
            },
            {
                name: "MessageService",
                endpoint: "https://lambda.us-east-1.amazonaws.com/2015-03-31/functions/MessageService/invocations",
                service: "lambda",
                region: "us-east-1"
            },
            {
                name: "MessageServiceDev",
                endpoint: "https://lambda.us-east-1.amazonaws.com/2015-03-31/functions/MessageServiceDev/invocations",
                service: "lambda",
                region: "us-east-1"
            },
            {
                name: "AuctionService",
                endpoint: "https://lambda.us-east-1.amazonaws.com/2015-03-31/functions/AuctionService/invocations",
                service: "lambda",
                region: "us-east-1"
            },
            {
                name: "DocumentGenerator",
                endpoint: "https://lambda.us-east-1.amazonaws.com/2015-03-31/functions/DocumentGenerator/invocations",
                service: "lambda",
                region: "us-east-1"
            },
            {
                name: "DocumentGeneratorDev",
                endpoint: "https://lambda.us-east-1.amazonaws.com/2015-03-31/functions/DocumentGeneratorDev/invocations",
                service: "lambda",
                region: "us-east-1"
            },
            {
                name: "BadgeScanService",
                endpoint: "https://lambda.us-east-1.amazonaws.com/2015-03-31/functions/BadgeScanService/invocations",
                service: "lambda",
                region: "us-east-1"
            },
            {
                name: "PollingService",
                endpoint: "https://lambda.us-east-1.amazonaws.com/2015-03-31/functions/PollingService/invocations",
                service: "lambda",
                region: "us-east-1"
            },
            {
                name: "EmailService",
                endpoint: "https://lambda.us-east-1.amazonaws.com/2015-03-31/functions/EmailService/invocations",
                service: "lambda",
                region: "us-east-1"
            },
            {
                name: "NotificationService",
                endpoint: "https://lambda.us-east-1.amazonaws.com/2015-03-31/functions/NotificationService/invocations",
                service: "lambda",
                region: "us-east-1"
            },
            {
                name: "AppointmentService",
                endpoint: "https://lambda.us-east-1.amazonaws.com/2015-03-31/functions/AppointmentService/invocations",
                service: "lambda",
                region: "us-east-1"
            },
            {
                name: "ChatService",
                endpoint: "https://lambda.us-east-1.amazonaws.com/2015-03-31/functions/ChatService/invocations",
                service: "lambda",
                region: "us-east-1"
            },
            {
                name: "ZoomService",
                endpoint: "https://lambda.us-east-1.amazonaws.com/2015-03-31/functions/ZoomService/invocations",
                service: "lambda",
                region: "us-east-1"
            },
            {
                name: "ZoomDevService",
                endpoint: "https://lambda.us-east-1.amazonaws.com/2015-03-31/functions/ZoomDevService/invocations",
                service: "lambda",
                region: "us-east-1"
            },
            {
                name: "PaymentService",
                endpoint: "https://lambda.us-east-1.amazonaws.com/2015-03-31/functions/PaymentService/invocations",
                service: "lambda",
                region: "us-east-1"
            },
            {
                name: "PaymentsDevService",
                endpoint: "https://lambda.us-east-1.amazonaws.com/2015-03-31/functions/PaymentsDevService/invocations",
                service: "lambda",
                region: "us-east-1"
            },
            {
                name: "SignupService",
                endpoint: "https://lambda.us-east-1.amazonaws.com/2015-03-31/functions/SignupService/invocations",
                service: "lambda",
                region: "us-east-1"
            },
            {
                name: "SignupDevService",
                endpoint: "https://lambda.us-east-1.amazonaws.com/2015-03-31/functions/SignupDevService/invocations",
                service: "lambda",
                region: "us-east-1"
            },
            {
                name: "DataService",
                endpoint: "https://lambda.us-east-1.amazonaws.com/2015-03-31/functions/DataService/invocations",
                service: "lambda",
                region: "us-east-1"
            },
            {
                name: "MeetupService",
                endpoint: "https://lambda.us-east-1.amazonaws.com/2015-03-31/functions/MeetupService/invocations",
                service: "lambda",
                region: "us-east-1"
            },
            {
                name: "RegistrationService",
                endpoint: "https://lambda.us-east-1.amazonaws.com/2015-03-31/functions/RegistrationService/invocations",
                service: "lambda",
                region: "us-east-1"
            },
            {
                name: "RegistrationDevService",
                endpoint: "https://lambda.us-east-1.amazonaws.com/2015-03-31/functions/RegistrationDevService/invocations",
                service: "lambda",
                region: "us-east-1"
            }
        ]
    },
    Analytics: {
        disabled: false,
        autoSessionRecord: true,
        AWSPinpoint: {
            // Amazon Pinpoint App Client ID
            appId: 'b7925fb2878946f68a01e15652f92526',
            // Amazon service region
            region: 'us-east-1',
            mandatorySignIn: false,
      }
    }
});

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();


/*
Timers:
    - SessionCheckIns - Multiple CheckIns
    - MenuBar   

*/