import React from 'react';
import './Schedule2.css';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { Input, Row, Col } from 'reactstrap';
import ScheduleItem2 from './ScheduleItem2';

const moment = require('moment-timezone');

/*

- Schedule tabs component

*/

var timer;

class ScheduleTabs2 extends React.Component {
    constructor(props) {
        super(props);
        //console.log(props)
        this.state = {
            code: global.$code,
            s3: global.$s3,
            bucket: global.$s3bucket,
            dataloaded: false,
        };
       //console.log(this.state);
       //console.log(props);

       //KD
       timer = setInterval(()=>{
            this.setState({dataloaded: true})
       }, 60000)

    }

    componentWillUnmount() {
        //console.log("componentWillUnmount")
        clearInterval(timer)
    }


    render() {

        //console.log('ScheduleTabs2 - render')

        return (
            <div>
                <Row>
                    <Col md={12}>
                        {this.getSchedule()}
                    </Col>
                </Row>
                <br />
                <br />
            </div>

        );
    }

    getAdjustedCurrentDate(begindate) {
        var mode = global.$mode;
        if (mode == null || mode == '') {
            mode = 'live';
        }

        var currentdate = moment().tz(`${global.$settings.timezone}`).format('L LTS');

        if (mode == "live") {
            //Nothing - Just return the current date
        }
        else if (mode == "preview") {
            // Make current date/time as same as session begin date/time
            currentdate = begindate;
        }
        else if (mode == "dryrun") {
            var eventdate = moment(begindate).format('L');
            var currenttime = moment().tz(`${global.$settings.timezone}`).format('LTS');
            currentdate = eventdate + " " + currenttime
        }
        else if (mode == "ondemand") {   //Add 365 days to current date

            var postdate = moment().add(365, 'days').calendar();
            var posttime = moment().tz(`${global.$settings.timezone}`).format('LTS');
            currentdate = postdate + " " + posttime;
        }

       //console.log(`SessionVideos:  current = ${currentdate}, start = ${this.state.begindate}, end = ${this.state.enddate}`);

        return currentdate;
    }

    getSchedule() {
       
        /*kd
        timer = setInterval(()=>{
            this.setState({dataloaded: true})
        }, 60000)
        */

       //console.log("timer running");
        var stag = this.props.session.STAG;
       //console.log(stag);
       //console.log(global.$schedulejson);
        return global.$schedulejson.map(schedule => {

            //console.log(schedule)
            if (schedule.SP == "") {
                schedule.SP = "empty";
            }

            if (schedule.FILE1 == "" || schedule.FILE1 == " ") {
                schedule.FILE1 = "empty";
            }

            var currentdate = this.getAdjustedCurrentDate(schedule.BEGIN) //moment().tz(`${global.$settings.timezone}`).format('L LTS')

            var currenttime = Date.parse(currentdate);
            var sessionstarttime = Date.parse(schedule.BEGIN);
            var sessionendtime = Date.parse(schedule.END);
            schedule.SP = schedule.SP.replace(/;/g, "");

            if (schedule.T3 == "") {
                schedule.T3 = "emptyurl";
            }
            //console.log(schedule.ATTENDEELINK)
            if (schedule.TAG.includes(stag)) {
                return (
                    <div key={schedule.SID}>
                        <ScheduleItem2
                            stag={stag}
                            currentdate={currentdate}
                            currenttime={currenttime}
                            sessionstarttime={sessionstarttime}
                            sessionendtime={sessionendtime}
                            schedule={schedule}
                            activetab={this.props.activetab}
                            type={this.props.type} 
                        />
                    </div>
                );
            }
        })
    }
}
export default ScheduleTabs2;


