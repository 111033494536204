import React from 'react';
import Modal from "react-responsive-modal";
import 'react-responsive-modal/styles.css';
import { API } from 'aws-amplify';
import { Spinner } from 'react-bootstrap';
import { getSignupService } from '../ServiceNameMapper';
const moment = require('moment-timezone');

class SessionSignups extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            openpopup: false,
            enableregister: true,
            enableregistered: false,
            enablecancel: false,
            isRegistering: false,
            isCanceling: false,
            eligibletoregister: true,
            signupdata: props.signupdata,
            signupsessiongroupname: '',
        }
        //console.log(props.session);
        if (Number(global.$signupdata.length) != 0) {
            global.$signupdata.map(data => {
                if (data.ownerid == props.schedule.SID && data.userid == global.$userid) {
                    this.state.enableregister = false;
                    this.state.enableregistered = true;
                    this.state.enablecancel = true;
                }
            })
        }

    }

    getAdjustedCurrentDate() {
        var currentdate = moment().tz(`${global.$settings.timezone}`).format('L LTS')

        var mode = global.$mode;
        if (mode == null || mode == '') {
            mode = 'live';
        }

        if (mode == "live") {
            //Nothing - Just retunr the current date
        }
        else if (mode == "preview") {
            // Make current date/time as same as session begin date/time
            currentdate = this.props.schedule.BEGIN;
        }
        else if (mode == "dryrun") {
            var eventdate = moment(this.props.sessionstarttime).format('L');
            var timenow = moment().tz(`${global.$settings.timezone}`).format('LTS');
            currentdate = eventdate + " " + timenow
        }
        else if (mode == "ondemand") {   //Add 365 days to current date
            var postdate = moment().add(365, 'days').calendar();
            var posttime = moment().tz(`${global.$settings.timezone}`).format('LTS');
            currentdate = postdate + " " + posttime;
        }

        return currentdate;
    }

    handleRegister = () => {
        //console.log(this.props.schedule);

        var sessionstarttime = Date.parse(this.props.schedule.BEGIN);
        var sessionendtime = Date.parse(this.props.schedule.END);

        var currentdate = this.getAdjustedCurrentDate();
        var currenttime = Date.parse(currentdate);

        if (currenttime > sessionendtime) {
            this.setState({
                message: 'Session is completed now',
                openpopup: true,
                isRegistering: false,
                isCanceling: false
            });
        }
        else {
            this.setState({ isRegistering: true })
            var register = true;

            if (Number(global.$signupdata.length) != 0) {
                global.$signupdata.map(data => {
                    //console.log(data.groupname == this.props.schedule.DATA1 && data.userid == global.$userid, data.groupname, this.props.schedule.DATA1 ,data.userid, global.$userid);
                    if (data.groupname == this.props.schedule.DATA1 && data.userid == global.$userid) {
                        this.setState({
                            //message: 'Only one session can be registered from the parallel sessions, Please cancel the other session to register for this.',
                            message: 'You have already signed up for another parallel session. Please cancel the other session and try again.',
                            openpopup: true,
                            isRegistering: false,
                            isCanceling: false
                        });
                        register = false;
                    }
                })
                //console.log(register, register == true);
                if (register == true) {
                    this.registerSession();
                }
            }
            else {
                //console.log("register firsttime");
                this.registerSession();
            }
        }

    }

    handleCancel = () => {
        var sessionstarttime = Date.parse(this.props.schedule.BEGIN);
        var sessionendtime = Date.parse(this.props.schedule.END);

        var currentdate = this.getAdjustedCurrentDate();
        var currenttime = Date.parse(currentdate);

        if (currenttime > sessionendtime) {
            this.setState({
                message: 'Session is completed now',
                openpopup: true,
                isRegistering: false,
                isCanceling: false
            });
        }
        else {
            if (window.confirm("Do you want to cancel this session sign-up?")) {
                this.setState({ isCanceling: true })
                global.$signupdata.map(data => {
                    //console.log(data);
                    if (data.ownerid == this.props.schedule.SID && data.userid == global.$userid) {
                        this.cancelSession(data.signupid);
                    }
                })
            }
        }
    }

    onOpenPopup = () => {
        this.setState(prevState => ({
            openpopup: !prevState.openpopup
        }));
    };

    render() {
        //console.log(global.$signupdata);
        if (Number(global.$signupdata.length) != 0) {
            return (
                <div>
                    {
                        this.state.enableregister == true ?
                            (
                                this.displaySignupButton()
                            )
                            :
                            ('')
                    }
                    <div className="registered-btn-div">
                        {/*
                                this.state.enableregistered == true ?
                                    (
                                        <div className="btn-session-registered">Registered</div>
                                    )
                                    :
                                    ('')
                                    */}
                        {
                            this.state.enablecancel == true ?
                                (
                                    <div className="btn-session-cancel" onClick={this.handleCancel}>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            className="Loading-button"
                                            variant="light"
                                            hidden={!this.state.isCanceling}
                                        />
                                            Cancel Sign Up
                                    </div>
                                )
                                :
                                ('')
                        }
                    </div>
                    <div className="register-session-popup-div">
                        <Modal
                            open={this.state.openpopup}
                            onClose={this.onOpenPopup}
                            blockScroll={false}
                            className="register-session-popup"
                            styles={{
                                modal: {
                                    width: "100%"
                                }
                            }}
                            center>
                            <div className="session-registration-message">{this.state.message}</div>
                        </Modal>
                    </div>
                </div>
            );
        }
        else {
            return (
                <div className="btn-session-register-div">
                    <div className="btn-session-register" onClick={this.handleRegister}>
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="Loading-button"
                            variant="light"
                            hidden={!this.state.isRegistering}
                        />
                        Sign Up
                    </div>
                </div>
            )
        }
    }

    displaySignupButton() {
        var groupnames = [];
        global.$signupdata.map(data => {
            if (data.groupname != "") {
                groupnames.push(data.groupname);
            }
        })
        if (groupnames.includes(this.props.schedule.DATA1)) {
            return (
                <div className="btn-session-group-register" onClick={this.handleRegister}>
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="Loading-button"
                        variant="light"
                        hidden={!this.state.isRegistering}
                    />
                    Sign Up
                </div>
            )
        }
        else {
            return (

                <div className="btn-session-register-div">
                    <div className="btn-session-register" onClick={this.handleRegister}>
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="Loading-button"
                            variant="light"
                            hidden={!this.state.isRegistering}
                        />
                    Sign Up
                </div>
                </div>
            )
        }
    }
    registerSession() {
        //console.log("registering session");
        //console.log(this.props.session);
        let apiName = getSignupService();
        let path = ''; //replace this with the path you have configured on your API
        var date = new Date();
        var timestamp = date / 1000;
        var speakers = this.props.schedule.SP;
        if (speakers == "empty") {
            speakers = "";
        }
        var groupname = " ";
        if (this.props.schedule.DATA1 != "") {
            groupname = this.props.schedule.DATA1;
        }
        let myInit = {
            body:
            {
                'code': global.$code,
                'command': 'signupsession',
                'stag': this.props.schedule.STAG,
                'ownerid': this.props.schedule.SID,//sessionid
                'signupid': timestamp.toString(),
                'groupname': groupname, // for the parallel sessions to group
                'signuptype': 'session',
                'userid': global.$userid,
                'data1': this.props.schedule.STAG,
                'data2': `S#SID:${this.props.schedule.SID};TITLE:${this.props.schedule.TITLE};BEGIN:${this.props.schedule.BEGIN};END:${this.props.schedule.END};SPEAKERS:${speakers};FNAME:${global.$fname};LNAME:${global.$lname};REGITEMS:${global.$regtype};REGTYPE:${global.$userstype}`,
                'data3': ' ',
                'data4': ' '
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //console.log(myInit);
        API.post(apiName, path, myInit).then(response => {
            //console.log(response);
            if (response.data.status == true) {
                //console.log(response.data);
                this.getMySignups("register");
            }
            else {
                this.setState({
                    message: 'Error!!, Unable to register for the session, please try again.',
                    openpopup: true,
                    isRegistering: false,
                    isCanceling: false
                });
            }
        }
        ).catch(error => {
            //console.log(error)
        });
    }

    cancelSession(signupid) {
        //console.log("deleteExhibitor");
        let apiName = getSignupService();
        let path = ''; //replace this with the path you have configured on your API
        let myInit =
        {
            body:
            {
                'code': global.$code,
                'command': "cancelsignup",
                'signupid': signupid,
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //console.log(myInit);

        API.post(apiName, path, myInit).then(response => {
            //console.log(response);

            if (response.err == null) {
                this.getMySignups("cancel");
            }
            else {
                this.setState({
                    message: 'Error!!, Unable to delete the session, please try again.',
                    openpopup: true,
                    isRegistering: false,
                    isCanceling: false
                });
            }
        }

        ).catch(error => {
            //console.log(error.response)
        });
    }
    getMySignups(action) {
        let apiName = getSignupService();
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'code': global.$code,
                'command': 'getmysignups',
                'userid': global.$userid,
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }

        //console.log(myInit);
        API.post(apiName, path, myInit).then(response => {
            //console.log(response);

            if (response.err == null && response.data != null) {
                //console.log(response.data.Items);
                var signupdata = this.getSignupTableObjects(response.data.Items);
                global.$signupdata = signupdata;
                if (action == "register") {
                    this.setState({
                        signupdata: signupdata,
                        enableregister: false,
                        enableregistered: true,
                        enablecancel: true,
                        isRegistering: false,
                    });
                }
                else if (action == "cancel") {
                    this.setState({
                        signupdata: signupdata,
                        enableregister: true,
                        enableregistered: false,
                        enablecancel: false,
                        isCanceling: false
                    });
                }
                else if (action == "start") {
                    signupdata.map(data => {
                        if (data.ownerid == this.props.schedule.SID && data.userid == global.$userid) {
                            this.setState({
                                signupdata: signupdata,
                                enableregister: false,
                                enableregistered: true,
                                enablecancel: true,
                            });
                        }
                    })
                }
                this.props.refreshSessionRegistration()
                //this.setState({ dataloaded: true });
            }
            else {
                //console.log(response.err);
            }
        }
        ).catch(error => {
            //console.log(error)
        });
    }
    getSignupTableObjects(objs) {
        //console.log(objs);

        const tableObjs = []

        for (var i = 0; i < objs.length; i++) {
            var signupObj = objs[i]

            //console.log(signupObj)

            var newObj = Object()

            newObj.userid = signupObj.userid.S
            newObj.ownerid = signupObj.ownerid.S
            newObj.signupid = signupObj.signupid.S
            newObj.status = signupObj.signupstatus.S
            newObj.signuptype = signupObj.signuptype.S
            newObj.data1 = signupObj.data1.S
            newObj.data2 = signupObj.data2.S
            newObj.data3 = signupObj.data3.S
            newObj.data4 = signupObj.data4.S
            newObj.groupname = signupObj.groupname.S

            tableObjs.push(newObj)
        }

        //console.log(tableObjs)

        return tableObjs
    }
}

export default SessionSignups;