/* eslint-disable */
import React, { Component } from 'react';
import { API } from 'aws-amplify';
import { Col, Row } from 'reactstrap';

import PostItemView from './PostItemView';
import PostInput from './PostInput';
import PostsView from './PostsView';
import SponsorsVertical from '../Components/Shared/SponsorsVertical';

/*
conversations Menu : 
                    1. Conversations (public => group chat)
                        a. Chat Input
                        b. Chat Message
                        c. Chat Messages

                    2. People (Private => one to one chat)
                        a. List of People
                        b. Invitation
                        c. Chat Input
                        d. Chat Message
                        e. Chat Messages
*/


/*
Posts Classes:

PostsView
  PostInput
  PostItemView[]
    CommentsView
      CommentInput
      CommentItemView[]


TODO
----

Image posts and display
Take photo from camera
Show like and comment count
Timer refersh every one minutes
Post Image - Remove user loading, add stylesheet entry

Comments height
Expand and hide comments

DONE
----

*/


class Conversations extends React.Component {
    constructor(props) {

        super(props);

        this.state = {
            code: this.props.code,
            receiverid: this.props.receiverid,
            dataloaded: false
        };

    }

    render() {

       //console.log("Conversations.render()");
        return (
            <div>
                <Row>
                    <Col md={7} className="offset-md-1">
                        <br/>
                        <PostsView code={this.state.code} receiverid={this.state.receiverid} />
                    </Col>
                    <Col md={1} className="offset-md-1">
                        <div className="posts-sponsors">
                            <SponsorsVertical />
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }

} //Class

export default Conversations;