

/*

1. Modals can be moved to separate component (simialr to Newpassword dialog in eventslite)
2. Payment can be organized as step1, step2... (Simialr to Registration Service)
3. Attendee functions can be moved to Global common
4. TravCon functionality can be put in separate function(s)

*/
import React from "react";
import { Button, Input, Row, Col, Label } from 'reactstrap';
import Discounts from "./Discounts";
import CommonFunctions from "../RegCommon/CommonFunctions";
import PaymentDropdown from "./PaymentDropdown";
import PaymentTotalAmount from "./PaymentTotalAmount";
import RegisterButton from "./RegisterButton";

class PaymentContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            code: global.$code,
            updateamout: false,
            selectedpaymentoption: "",
            selectedpaymentdesc: "",
            hideregister: false,
            isLoading: false
        };
        this.onRefreshComponent = this.onRefreshComponent.bind(this);
    }

    onRefreshComponent() {
        //console.log('Payment:onRefreshComponent()');      
        global.$openpaymentpopup = false;
        this.setState({
            updateamout: true
        });
    }

    render() {

        var configstyles = global.$registrationform.styles.payment
        var configpayment = global.$registrationform.setup[this.props.tabid].payment;
        
        return (
            <div className="reg-payment-div" style={configstyles.maindiv}>
                {configpayment.hidden == null || configpayment.hidden === false ? (this.renderPaymentTotal()) : ("")}
                {this.renderSecDesc(configpayment)}
                {this.renderDiscount(configpayment)}
                {this.renderPaymentDrodpdown()}
                {/* {this.renderRegisterButton()} */}
            </div>
        );
    }

    renderPaymentTotal() {
        //console.log("renderPaymentTotal")
        return (
            <PaymentTotalAmount tabid={this.props.tabid}/>
        )
    }

    renderSecDesc(configpayment) {
        // Desc after login
        if (CommonFunctions.isUserLoggedin() && configpayment.descriptionafterlogin) {
            return (
                <div dangerouslySetInnerHTML={{ __html: configpayment.descriptionafterlogin }}></div>
            )
        }
        // Desc before login
        else {
            return (
                <div dangerouslySetInnerHTML={{ __html: configpayment.description }}></div>
            )
        }
    }

    renderDiscount(configpayment) {
        // Enable if if discounts enabled from config
        if (configpayment.discount.required == true) {
            return (
                <Discounts data={global.$regitems} onPromoCodeApplied={this.onRefreshComponent} formtype={this.props.type} tabid={this.props.tabid}/>
            )
        }
    }

    renderPaymentDrodpdown() {
        return (
            <PaymentDropdown onRefreshComponent={this.onRefreshComponent} formtype={this.props.type} tabid={this.props.tabid}/>
        )
    }

    renderRegisterButton() {
        return (
            <RegisterButton formtype={this.props.type} tabid={this.props.tabid}/>
        )
    }

}
export default PaymentContainer;