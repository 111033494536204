const { default: API } = require("@aws-amplify/api");
const { getUserService } = require("../ServiceNameMapper");

async function getUserProfile(userid) {
    try {
        let apiName = getUserService();
        let path = ''; //replace this with the path you have configured on your API
        let myInit =
        {
            body:
            {
                'code': global.$code,
                'command': 'getUserProfile',
                'userid': userid,
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        var result = await API.post(apiName, path, myInit);
        return result;
    }
    catch (e) {
        console.log(e)
        return null
    }
}

async function getAttendeeProfile(userid) {
    try {
        let apiName = getUserService();
        let path = ''; //replace this with the path you have configured on your API
        let myInit =
        {
            body:
            {
                'code': global.$code,
                'command': 'getUserProfile',
                'userid': userid,
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        var response = await API.post(apiName, path, myInit);

        if (response.err == null && response.data != null) {

            let userObj = getUserObject(response.data.Item)
            return userObj;
        }
        else {
            return null;
        }
    }
    catch (e) {
        console.log(e)
        return null
    }
}
function getUserObject(objs) {
    var newObj = Object()

    newObj.userid = objs.userid.S
    newObj.fname = objs.fname.S
    newObj.lname = objs.lname.S
    newObj.photo = objs.photo.S
    newObj.company = objs.company.S
    newObj.phone = objs.phone.S
    newObj.title = objs.title.S
    newObj.email = objs.email.S
    newObj.country = objs.country.S
    newObj.state = objs.state.S
    newObj.street = objs.street.S
    newObj.city = objs.city.S
    newObj.state = objs.state.S
    newObj.zip = objs.zip.S
    newObj.linkedin = objs.linkedin.S
    newObj.twitter = objs.twitter.S
    newObj.fb = objs.fb.S
    newObj.regid = objs.regid.S
    newObj.regtype = objs.regtype.S
    newObj.memberid = objs.memberid.S
    newObj.visitpoints = objs.visitpoints.N
    newObj.activitypoints = objs.activitypoints.N
    newObj.totalpoints = objs.totalpoints.N
    newObj.sessionpoints = objs.sessionpoints.N
    newObj.regstatus = objs.regstatus.S
    newObj.password = objs.password.S
    newObj.myexpertise = objs.myexpertise.S
    newObj.myinterests = objs.myinterests.S
    newObj.validscans = objs.validscans.S
    newObj.currentlocation = objs.currentlocation.S
    newObj.lastseen = objs.lastseen.S
    newObj.showdirectory = objs.showdirectory.S
    newObj.officephone = objs.officephone.S
    newObj.location = objs.location.S
    newObj.locationtime = objs.locationtime.S
    newObj.signindate = objs.signindate.S
    newObj.data1 = objs.data1.S
    newObj.data2 = objs.data2.S
    newObj.data3 = objs.data3.S
    newObj.data4 = objs.data4.S
    newObj.data5 = objs.data5.S
    newObj.q1 = objs.q1.S
    newObj.q2 = objs.q2.S
    newObj.q3 = objs.q3.S
    newObj.q4 = objs.q4.S
    newObj.q5 = objs.q5.S
    newObj.q6 = objs.q6.S
    newObj.q7 = objs.q7.S
    newObj.q8 = objs.q8.S
    newObj.userstype = objs.userstype.S
    newObj.guests = objs.guests.S
    newObj.sessions = objs.sessions.S
    newObj.regnotes = objs.regnotes.S
    newObj.checkinstatus = objs.checkinstatus.S
    newObj.mname = objs.mname.S
    newObj.poolid = objs.poolid.S
    newObj.totalamount = objs.totalamount.N
    newObj.amountpaid = objs.amountpaid.N
    newObj.promoamount = objs.promoamount.N
    newObj.pendingamount = objs.pendingamount.N
    newObj.refundamount = objs.refundamount.N
    newObj.paymentmode = objs.paymentmode.S
    newObj.promocodes = objs.promocodes.S
    newObj.guestname = objs.guestname.S
    newObj.regitemsjson = objs.regitemsjson.S
    return newObj
}

export default { getUserProfile, getAttendeeProfile }