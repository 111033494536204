import React from 'react';
import { Row, Col } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';
import SessionHandouts from './SessionHandouts.jsx';
import ScheduleSpeakers from './ScheduleSpeakers.jsx';
import SessionCreditTimer from './SessionCreditTimer.jsx';
import SessionVideos from './SessionVideos.jsx';
import SessionTabs from './SessionTabs.jsx';
import SessionCheckIns from './SessionCheckIns.jsx';
import SessionCheckIns2 from './SessionCheckIns2.jsx';
import SessionGame from './SessionGame.jsx';

const moment = require('moment-timezone');
class SessionWithTabs extends React.Component {
    constructor(props) {
        super(props);
        //console.log(props);
        var sessioncheckins = "N";
        if(global.$session.enablesessioncheckins == null)
        {
            sessioncheckins = "N";
        }
        else
        {
            sessioncheckins = global.$session.enablesessioncheckins
        }
        

        this.state = {
            code: global.$code,
            titlestyle: global.$session.styles.sessiontitle,
            descstyle: global.$session.styles.sessiondesc,
            timestyle: global.$session.styles.sessiontime,
            speakers: this.props.session.SP,
            curenttime: '',
            sessionstarttime: '',
            sessionendtime: '',
            enablesessioncheckins: sessioncheckins,
            checkins: this.props.session.CHECKINS
        };
       //console.log(global.$session)
        if (this.state.speakers == "" || this.state.speakers == " ") {
            this.state.speakers = "empty";
        }
        if(this.state.checkins == "" || this.state.checkins == " " || this.state.checkins == null)
        {
            this.state.checkins = "empty";
        }
        
       //console.log(this.props.showfeedback);
        /*var currentdate = moment().tz(`${global.$settings.timezone}`).format('L, LTS') // L = 9/29/2020  LTS = 10:37:01 PM 

       //console.log(currentdate);

        
            //Required format to get the current time = 9/29/2020, 10:37:01 PM
        

        this.state.curenttime = Date.parse(currentdate);
       //console.log(this.state.curenttime);

        this.state.sessionstarttime = Date.parse(this.props.session.BEGIN);
        this.state.sessionendtime = Date.parse(this.props.session.END);
       //console.log(this.state.sessionstarttime);
       //console.log(this.state.curenttime > this.state.sessionstarttime, this.props.curenttime, this.props.sessionstarttime, parseFloat(this.props.session.POINTS) > 0)*/
    }

    render() {
       //console.log(this.props.sessiondate);
        //console.log(this.state);
        //console.log(this.props)
        return (
            <div className="session-without-tabs">
                <Row>
                    <Col md={8}>
                    <div>
                            {
                                (this.props.curenttime >= this.props.sessionstarttime) ?
                                    (
                                        <div>
                                            {
                                                this.state.enablesessioncheckins.includes("Y")?
                                                (
                                                    <div>
                                                        {
                                                            this.state.checkins.includes("empty")?
                                                            (
                                                             ''   
                                                            )
                                                            :
                                                            (
                                                                <SessionCheckIns2 session={this.props.session}/>
                                                            )
                                                        }
                                                    </div>
                                                )
                                                :
                                                ('')
                                            }
                                            {
                                                parseFloat(this.props.session.POINTS) > 0 ?
                                                    (
                                                        <div>
                                                            {
                                                                global.$session.credittimer.includes("Y") ?
                                                                    (
                                                                        <SessionCreditTimer
                                                                            minutes={global.$session.creditminutes}
                                                                            code={global.$code}
                                                                            points={this.props.session.POINTS}
                                                                            sessionid={this.props.session.SID}
                                                                            title={this.props.session.TITLE}
                                                                            begindate={this.props.session.BEGIN}
                                                                            enddate={this.props.session.END}
                                                                        />
                                                                    )
                                                                    :
                                                                    ('')
                                                            }
                                                        </div>
                                                    )
                                                    :
                                                    ('')

                                            }
                                        </div>
                                    )
                                    :
                                    ('')
                            }
                        </div>
                        <div className="video-with-tabs">
                            <SessionVideos session={this.props.session} />
                        </div>
                        <br />
                        <br />
                        <div>
                            <div>
                                {/*<div style={this.state.titlestyle}>{this.props.session.TITLE}</div>
                                <p style={this.state.timestyle}>{this.props.begindate} - {this.props.enddate}</p>*/}
                                
                                <div style={this.state.descstyle}>{ReactHtmlParser(this.props.session.DESCRIPTION)}</div>
                                <div className="handouts">
                                    <SessionHandouts
                                        beginDateFormatter={this.props.begindate}
                                        endDateFormatter={this.props.enddate}
                                        session={this.props.session}
                                    />
                                </div>
                                <div className="game">
                                    <SessionGame session={this.props.session}/>
                                </div>
                                <br />
                                <div>
                                    {
                                        this.state.speakers.includes("empty") ?
                                            (
                                                ''
                                            )
                                            :
                                            (
                                                <div>
                                                    <div dangerouslySetInnerHTML={{ __html: global.$session.speakerstitle }}></div>
                                                    <ScheduleSpeakers speakers={this.props.session.SP} SID={this.props.session.SID}/>
                                                </div>

                                            )
                                    }
                                </div>
                            </div>
                        </div>
                        <br />
                    </Col>
                    <Col md={4}>
                        {/* <div>
                            {
                                (this.props.curenttime >= this.props.sessionstarttime) ?
                                    (
                                        <div>
                                            {
                                                this.state.enablesessioncheckins.includes("Y")?
                                                (
                                                    <div>
                                                        {
                                                            this.state.checkins.includes("empty")?
                                                            (
                                                             ''   
                                                            )
                                                            :
                                                            (
                                                                <SessionCheckIns session={this.props.session}/>
                                                            )
                                                        }
                                                    </div>
                                                )
                                                :
                                                ('')
                                            }
                                            {
                                                parseFloat(this.props.session.POINTS) > 0 ?
                                                    (
                                                        <div>
                                                            {
                                                                global.$session.credittimer.includes("Y") ?
                                                                    (
                                                                        <SessionCreditTimer
                                                                            minutes={global.$session.creditminutes}
                                                                            code={global.$code}
                                                                            points={this.props.session.POINTS}
                                                                            sessionid={this.props.session.SID}
                                                                            title={this.props.session.TITLE}
                                                                            begindate={this.props.session.BEGIN}
                                                                            enddate={this.props.session.END}
                                                                        />
                                                                    )
                                                                    :
                                                                    ('')
                                                            }
                                                        </div>
                                                    )
                                                    :
                                                    ('')

                                            }
                                        </div>
                                    )
                                    :
                                    ('')
                            }
                        </div> */}
                        <SessionTabs
                            session={this.props.session}
                            showfeedback={this.props.showfeedback}
                            sessiondate={this.props.sessiondate}
                            stag = {this.props.stag}
                            activetab = {this.props.activetab}
                        />
                    </Col>
                </Row>
            </div>
        )
    }
}
export default SessionWithTabs;


