import React from 'react';
import CommonFunctions from '../RegCommon/CommonFunctions';

let results;
class RegItemNumber extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            regitem: props.regitem,
            groupname: props.groupname,
            disableregitem: props.disableregitem,
            signupsremaining: props.signupsremaining,
            quantityvalue: props.quantityvalue
        }
    }

    render() {
        return (
            <div className='input-wrapper-section input-input-wrapper'>
                {this.renderQuantity(this.state.regitem, this.state.groupname, this.state.disableregitem, this.state.quantityvalue)}
                {this.renderSignupsRemaining(this.state.signupsremaining)}
            </div>
        );
    }

    renderQuantity(regitem, groupname, disableregitem, quantityvalue) {
        return (
            <input
                type={regitem.inputtype}
                className="reg-text"
                name={groupname}
                onChange={this.handleTextBox(regitem, groupname)}
                value={this.state[quantityvalue]}
                min="0"
                defaultValue={0}
                key={regitem.data5}
            />
        )
    }
    renderSignupsRemaining(signupsremaining) {
        return (
            <div className='remaining-count available-count-text'>{signupsremaining}</div>
        )
    }

    handleTextBox = (selected, groupname) => (event) => {
        //console.log(selected, section);
        const target = event.target;
        let value = target.value;
       
        var statevariable = selected.data5 + "value";
        this.updateSelectedRegItemNumber(selected, groupname, value, statevariable,target.value);
        
        // CHECK REQUIRED REGITEM CONDTIONS
        if (!results) target.value = 0
    }

    updateSelectedRegItemNumber(selected, groupname, value, statevariable,targetvalue) {
       //console.log(value)
        global.$regitems.map(regitem => {
            if (regitem.groupname == groupname) {
                if (regitem.data5 == selected.data5) {
                    // raffle: to tell that the selected quantity has to be multiplied with main reg item price
                    if (regitem.data3.includes("raffle")) {

                        var maxsignups = regitem.maxsignups;
                        var currentsignups = regitem.currentsignups;
                        var remianing = Number(maxsignups) - Number(currentsignups);
                        if (Number(value) <= Number(remianing)) {
                            regitem.isChecked = true;
                            regitem.quantity = value;
                            regitem.itemprice = Number(regitem.amount);
                            regitem.price = regitem.amount
                            regitem.itemid = regitem.data5
                            this.setState({ [statevariable]: value });
                        }
                    }
                    else {
                        regitem.isChecked = true;
                        regitem.amount = value;
                        regitem.itemprice = Number(regitem.amount);
                        regitem.price = regitem.amount
                        regitem.itemid = regitem.data5
                        this.setState({ [statevariable]: value });
                    }

                    results = this.props.conditionalRegitems(regitem);
                    if (!results) {

                        regitem.isChecked = false;
                        regitem.quantity = "0";
                        regitem.price =0
                        regitem.itemid = ''
                        this.setState({ [statevariable]: "0" });
                        this.setState({ quantityvalue: 0 }, () => {
                            this.props.refreshComponent(); 
                        });
                        
                    }
                    if (regitem.quantity == "") {
                        regitem.quantity = "0";
                    }
                    if (regitem.isChecked == true && regitem.quantity == "0") {
                        regitem.isChecked = false;
                        regitem.price = 0
                        regitem.itemid = ''
                    }
                }
            }
        })
        this.props.refreshComponent();
    }
}

export default RegItemNumber;