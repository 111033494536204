import React from 'react';
import { Link } from 'react-router-dom';
import commonProfile from '../images/common_profile.png';
import Avatar from 'react-avatar';
import { API } from 'aws-amplify';
import Modal from "react-responsive-modal";
import 'react-responsive-modal/styles.css';
import { Row, Col } from 'reactstrap';
import SpeakerPopup from '../Components/Shared/SpeakersLayout/SpeakerPopup';
var serviceNames = require('../ServiceNameMapper');

class SessionSpeakers2 extends React.Component {
    constructor(props) {
        super(props);
        //console.log(props);
        var showspeakerphoto = 'Y';
        if (global.$schedule.showspeakerphoto == null) {
            showspeakerphoto = 'Y';
        }
        else {
            showspeakerphoto = global.$schedule.showspeakerphoto;
        }

        var linktospeaker = 'Y';
        if (global.$schedule.linktospeaker == null) {
            linktospeaker = "Y";
        }
        else {
            linktospeaker = global.$schedule.linktospeaker;
        }

        this.state = {
            code: global.$code,
            s3: global.$s3,
            bucket: global.$s3bucket,
            showspeakerphoto: showspeakerphoto,
            linktospeaker: linktospeaker,
            alloptionsopen: false
        };
        //console.log(this.props.speakers);
    }

    onOpenAllOptions = () => {
        //console.log('onOpenAllOptions');
        this.setState(prevState => ({
            alloptionsopen: !prevState.alloptionsopen
        }));
        //console.log(this.state.alloptionsopen);
    };
    render() {
        return (
            <div>
                {
                    global.$schedulespeakers != null ?
                        (
                            this.processSpeakers(this.props.speakers)
                        )
                        :
                        (
                            this.getSchedulejson()
                        )
                }

                <Modal
                    open={this.state.alloptionsopen}
                    onClose={this.onOpenAllOptions}
                    blockScroll={false}
                    styles={{
                        modal: {
                            width: "100%",
                        },
                    }}
                    classNames={{
                        modal: 'speakersCustomModal',
                    }}
                    center>
                    <SpeakerPopup speaker={global.$speaker} />
                </Modal>
            </div>

        )
    }
    processSpeakers(names) {
       //console.log(names);
        return global.$schedulespeakers.map(item => {
           //console.log(item);
            if (item.SID == this.props.SID) {
               //console.log(names);
                var speakers = '';
                speakers = item.SP.replace(/;;,/g, ";,");
                speakers = speakers.replace(/;,/g, "==");
                speakers = speakers.replace(/;/g, "==");
                //console.log(speakers);
                speakers = speakers.split("==");

                //console.log("processSpeakers:" + names);
                //console.log("globalSpeakers:" + global.$speakerjson);

                var speakersUI = speakers.map((name) => {
                   //console.log("speaker name:" + name);
                   //console.log(global.$speakerjson)
                    if (global.$speakerjson != undefined) {
                       //console.log(global.$speakerjson);
                        var sp = global.$speakerjson.find(globalSpeaker => {
                           //console.log(globalSpeaker.NAME);
                            if (globalSpeaker == null || globalSpeaker.NAME == null) {
                               //console.log("false");
                                //console.log(globalSpeaker)
                                return false;
                            }
                            else {
                               //console.log(globalSpeaker.NAME === name)
                                return globalSpeaker.NAME === name
                            }
                        })
                       //console.log(sp);
                        if (sp != undefined) {
                           //console.log(sp);
                            return this.getSpeakerUI(sp);
                        }
                    }
                    else {
                        this.getSpeakerjson();
                    }
                }
                );

                return (
                    <div>
                        {
                            speakersUI
                        }
                    </div>
                );
            }
        })
    }

    getSpeakerUI(speaker) {
       //console.log("displaySpeakers" + speaker);
       //console.log(speaker.PHOTO);

        if (speaker.PHOTO == "profile.png" || speaker.PHOTO == " " || speaker.PHOTO == "") {
            return (
                <div>
                    {
                        this.state.linktospeaker.includes("Y") ?
                            (
                                <div className="sessioninfo2-speakers-div" className="sessioninfo2-speaker-popup" onClick={() => this.popup(speaker)}>
                                    <Row>
                                        <Col md={2}>
                                            {
                                                this.state.showspeakerphoto.includes("Y") ?
                                                    (
                                                        <div className="sessioninfo2-avatar-div">
                                                            <Avatar className="sessioninfo2-speaker-avatar" name={`${speaker.NAME}`} />
                                                            <br />
                                                        </div>
                                                    )
                                                    :
                                                    (
                                                        ''
                                                    )
                                            }
                                        </Col>
                                        <Col md={10}>
                                            <div className="sessioninfo2-speaker-name">
                                                {speaker.NAME}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            )
                            :
                            (
                                <div className="sessioninfo2-speakers-div">
                                    <Row>
                                        <Col md={2}>
                                            {
                                                this.state.showspeakerphoto.includes("Y") ?
                                                    (
                                                        <div className="sessioninfo2-avatar-div">
                                                            <Avatar className="sessioninfo2-speaker-avatar" name={`${speaker.NAME}`} />
                                                            <br />
                                                        </div>
                                                    )
                                                    :
                                                    (
                                                        ''
                                                    )
                                            }
                                        </Col>
                                        <Col md={10}>
                                            <div className="sessioninfo2-speaker-name">
                                                {speaker.NAME}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            )
                    }
                </div>
            );
        }
        else {
            var image = `https://s3.amazonaws.com/event360live/public/${this.state.code}/speakers/${(speaker.PHOTO)}`
            //console.log(image);
            //console.log(this.state.showspeakerphoto ,this.state.showspeakerphoto);
            //console.log(speaker.NAME)
            return (
                <div>
                    {
                        this.state.linktospeaker.includes("Y") ?
                            (
                                <div className="sessioninfo2-sepeakers-div" style={{ textAlign: "center", float: "left", paddingLeft: "15px" }} className="sessioninfo2-speaker-popup" onClick={() => this.popup(speaker)}>
                                    <Row>
                                        <Col md={2}>
                                            {
                                                this.state.showspeakerphoto.includes("Y") ?
                                                    (
                                                        <div>
                                                            <img src={image} className="sessioninfo2-speaker-img" /><br />
                                                        </div>
                                                    )
                                                    :
                                                    (
                                                        ''
                                                    )
                                            }
                                        </Col>
                                        <Col md={10}>
                                            <div className="sessioninfo2-speaker-name">
                                                {speaker.NAME}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            )
                            :
                            (
                                <div className="sessioninfo2-sepeakers-div" style={{ textAlign: "center", float: "left", paddingLeft: "15px" }}>
                                    <Row>
                                        <Col md={2}>
                                            {
                                                this.state.showspeakerphoto.includes("Y") ?
                                                    (
                                                        <div>
                                                            <img src={image} className="sessioninfo2-speaker-img" /><br />
                                                        </div>
                                                    )
                                                    :
                                                    (
                                                        ''
                                                    )
                                            }
                                        </Col>
                                        <Col md={10}>
                                            <div className="sessioninfo2-speaker-name">
                                                {speaker.NAME}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            )
                    }
                </div>
            );
        }
    }
    popup(speaker) {
        //console.log(sponsor);
        global.$speaker = speaker;
        this.onOpenAllOptions()
    }

    getSchedulejson(event) {
        //console.log("getSchedulejson");
        let apiName = serviceNames.getWebsiteService();
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'code': this.state.code,
                'command': 'getJsonFileFromS3',
                'bucket': this.state.bucket,
                'filename': "allschedule"
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //console.log(myInit);

        API.post(apiName, path, myInit).then(response => {
            //console.log ("response.data ===> " + response.data + "response.err ===> " + response.err);

           //console.log(response.data);

            if (response.err == null && response.data != null) {
                let buf = Buffer.from(response.data.Body);
                //console.log(buf)
                //console.log(buf.toString());
                //console.log(response.data.errorMessage);                   
                global.$schedulespeakers = JSON.parse(buf.toString());
                this.getSpeakerjson();
                // this.setState({ dataloaded: true })

            }
            else {
                ////console.log("getAttendeesTableObjects - failed!!");
                //console.log(response.err);
            }
        }
        ).catch(error => {
            //console.log(error.response)
        });
    }

    getSpeakerjson() {
        //console.log("getSpeakerjson()")
        let apiName = serviceNames.getWebsiteService();
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'code': this.state.code,
                'command': 'getJsonFileFromS3',
                'bucket': this.state.bucket,
                'filename': "speakers"
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //console.log(myInit);

        API.post(apiName, path, myInit).then(response => {
            //console.log ("response.data ===> " + response.data + "response.err ===> " + response.err);

            //console.log(response);

            if (response.err == null && response.data != null) {
                let buf = Buffer.from(response.data.Body);
                //console.log(buf)
                //console.log(buf.toString());
                global.$speakerjson = JSON.parse(buf.toString());
                //console.log(global.$schedule.enablesessionregistration);
                //console.log(global.$speakerjson);
                this.setState({ dataloaded: true });
            }
            else {
                ////console.log("getAttendeesTableObjects - failed!!");
                //console.log(response.err);
            }
        }
        ).catch(error => {
            //console.log(error.response)
        });

    }
}
export default SessionSpeakers2;


