/* eslint-disable */
import React, { Component } from 'react';
import { FormGroup, Label, Input, FormText, Card, CardHeader, CardBody, Row, Col, Container, Form, Button } from 'reactstrap';
import Modal from "react-responsive-modal";
import { Storage, API } from 'aws-amplify';
import { Link } from "react-router-dom";
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { EditorState, contentState, convertToRaw, convertFromRaw, convertFromHTML, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
//import { stateToHTML } from "draft-js-export-html";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Select from 'react-select';
import Icon from '@mdi/react';
import { Spinner } from 'react-bootstrap';
import { mdiGoogleStreetView, mdiMapMarkerMultiple, mdiZipBox,mdiDeleteForever, mdiDomain, mdiImage, mdiMessageText, mdiCellphoneIphone, mdiWeb, mdiFormatTitle, mdiFacebook, mdiTwitter, mdiLinkedin, mdiInstagram, mdiFormatListBulletedType, mdiSort, mdiEmail, mdiAccount, mdiAccountMultiple, mdiHomeGroup, mdiKeyboardBackspace, mdiFileDocument, mdiMapMarker, mdiKeyPlus, mdiAccessPoint, mdiBook, mdiLink, mdiVideo, mdiDatabase, mdiCalendar, mdiFile, mdiAccountCircle } from '@mdi/js';
import './SubmitForms.css';
import FormPreview from './FormPreview';
import { Progress } from 'reactstrap';
import Avatar from 'react-avatar';
import uploadLogo from './uploadlogo.png'
import Products from './Products';
import QRCode from 'qrcode.react';
var serviceNames = require('../ServiceNameMapper');

class SponsorSubmitForm extends Component {
    render() {
        var form = global.$config["exhibitorsubmitform"].form;
        var exhibitorlead = global.$config["exhibitorsubmitform"].exhibitorlead != null && global.$config["exhibitorsubmitform"].exhibitorlead.enable != null ? global.$config["exhibitorsubmitform"].exhibitorlead.enable : false;
        var productsenable = global.$config["exhibitorsubmitform"].products != null && global.$config["exhibitorsubmitform"].products.enable != null ? global.$config["exhibitorsubmitform"].products.enable : false; 
        var styles = global.$config["exhibitorsubmitform"].styles;

        let enablecategories = false
        let categorytitles = []
        if (form.exhibitorcategories && form.exhibitorcategories.enable == true) {
            enablecategories = true
            categorytitles = form.exhibitorcategories.title.split(",")
        }

        return (
            <>
                <div className='container' >
                    <Card className='company_card_wrapper'>
                        <CardHeader className="form-header-1 company_card_header">Company Information</CardHeader>
                        <CardBody>
                            <Row>
                                <Col  md={12} className="mt-4">
                                    {form.enablepreview ? <button className="preview_button" onClick={this.toggle}>Preview</button> : ""}
                                    <Modal
                                        open={this.state.modal}
                                        onClose={this.toggle}
                                        styles={{ modal: { width: "100%" } }}
                                        center>
                                        <FormPreview type={'S'}/>
                                    </Modal>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={7}>
                                    <FormGroup className="form-labels">
                                        <Label for="company"><Icon path={mdiDomain} size={0.9} color="lightgray" className="form-label-icon" />Company*</Label>
                                        <Input type="text" name="company" className="form-control-sm" id="company" value={this.state.company || ''} onChange={this.handleInputChange} />
                                    </FormGroup>

                                    <FormGroup className="form-labels">
                                        <Label for="url"><Icon path={mdiWeb} size={0.9} color="lightgray" className="form-label-icon" />Website</Label>
                                        <Input type="text" name="url" className="form-control-sm" id="url" value={this.state.url || ''} onChange={this.handleInputChange} placeholder="Enter http://www.yourcompany.com"/>
                                    </FormGroup>                                                
                                </Col>
                                <Col md={5} className="speaker_profile_icon_wrapper">
                                    <FormGroup className="form-labels">
                                        <Label for="logo" className="">
                                            {
                                                this.state.logoFileName.trim() == "" || this.state.logoFileName == null || this.state.logoFileName.trim() == 'blank.png' ?
                                                    (   <img className="profilephoto uploadlogo" src={uploadLogo} alt="profilephoto" /> )
                                                    :   (   <img className="profilephoto" src={this.state.imageUrl} alt="profilephoto" />  )
                                            }
                                        </Label>
                                        <input ref="uploadImg" type="file" name="logo" className="form-control-sm" id="logo" accept=".gif,.jpg,.jpeg,.png" onChange={this.fileUploader} hidden/>
                                        <p class="text"><span className="pen-icon">&#9998;</span></p>
                                    </FormGroup>
                                    <span className='delete-file-btn2 mb-3' onClick={() => { if (window.confirm(`Do you want to delete the image?`)) { this.removeLogo() }; }} title={'Delete Image'}><Icon path={mdiDeleteForever} size={1} color="gray"/></span>
                                    <FormGroup className="form-labels qrcode-wrapper">
                                        <div>
                                        <QRCode
                                            value={`V#:ID:${this.state.id};ORG:${this.state.company};N:${this.state.contact};EMAIL:${this.state.email};ADR:${this.state.street},${this.state.city},${this.state.state} ${this.state.zip};P:${this.state.points};`}
                                            id='qrCode'
                                            renderAs="canvas"
                                            size={110}
                                            level={"H"}
                                        />
                                        <br></br>
                                        <a className="qrcode-d-btn" onClick={this.download}>Download QR</a>
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            {this.state.selectedEtype.includes("A") ? 
                            (<Row>
                                <Col>
                                    <FormGroup className="form-labels">
                                        <Label for="adsrc"><Icon path={mdiMapMarker} size={0.9} color="lightgray" className="form-label-icon" />Choose location of Ad</Label>
                                        <Input type="select" name="adsrc" id="adsrc" value={this.state.selectedSrc || ''} onChange={this.handleDropdownChangeForSrc} >
                                            <option value='select'>Select SRC</option>
                                            {
                                                this.state.adsrc
                                            }</Input>
                                    </FormGroup>
                                    <FormGroup className="form-labels">
                                                <Label for="file"><Icon path={mdiFileDocument} size={0.9} color="lightgray" className="form-label-icon" />File</Label>
                                        <Input type="file" name="adfile" className="form-control-sm" id="adfile" value={this.state.adfile} onChange={this.uploadFile} accept=".html,.gif,.jpg,.jpeg,.png" ref={ref => this.adfile = ref} />
                                            <FormText className="exhibitor-submit-form-form-text">Upload file as html or image format only</FormText>
                                    </FormGroup>
                                        <div id="ad-message"></div>
                                </Col>                                                  
                            </Row>): ("")}
                            <Row className="mt-4">
                                <Col md={4} >
                                    <FormGroup className="form-labels">
                                        <Label for="street"><Icon path={mdiGoogleStreetView} size={0.9} color="lightgray" className="form-label-icon" />Street</Label>
                                        <Input type="text" name="street" className="form-control-sm" id="street" value={this.state.street || ''} onChange={this.handleInputChange} />
                                    </FormGroup>
                                </Col>

                                <Col md={4}>
                                    <FormGroup className="form-labels">
                                        <Label for="city"><Icon path={mdiMapMarkerMultiple} size={0.9} color="lightgray" className="form-label-icon" />City</Label>
                                        <Input type="text" name="city" className="form-control-sm" id="city" value={this.state.city || ''} onChange={this.handleInputChange} />
                                    </FormGroup>
                                </Col>

                                <Col md={4} >
                                    <FormGroup className="form-labels">
                                        <Label for="zip"><Icon path={mdiZipBox} size={0.9} color="lightgray" className="form-label-icon" />Zip</Label>
                                        <Input type="text" name="zip" className="form-control-sm" id="zip" value={this.state.zip || ''} onChange={this.handleInputChange} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col md={6}>
                                    <FormGroup className="form-labels">
                                        <Label for="country"><Icon path={mdiWeb} size={0.9} color="lightgray" className="form-label-icon" />Country</Label>
                                        <CountryDropdown name="country" className="form-control-sm select_c_dropdown" id="country" value={this.state.country || ''} onChange={(val) => this.selectCountry(val)} />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup className="form-labels">
                                        <Label for="state"><Icon path={mdiHomeGroup} size={0.9} color="lightgray" className="form-label-icon" />State</Label>
                                        <RegionDropdown name="state" className="form-control-sm select_c_dropdown" id="region" placeholder="Select State" country={this.state.country || ''} value={this.state.state || ''} onChange={(val) => this.selectRegion(val)}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row hidden>
                                                <Col md={9}>
                                                    <FormGroup className="exhibitor-submit-form-form-labels">
                                                        <Label for="videourl"><Icon path={mdiVideo} size={0.9} color="lightgray" className="form-label-icon" />Video</Label>
                                                        <Input type="text" name="videourl" className="form-control-sm" id="videourl" value={this.state.videourl || ''} onChange={this.handleInputChange} />
                                                        <FormText className="exhibitor-submit-form-form-text">Enter video link or upload file as video format</FormText>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={3}>
                                                    <Label for="videos3" className="custom-fileupload" style={{ marginTop: "23px", color: "white" }}><p className="btn btn-primary" style={{ color: "white" }}>Upload</p></Label><br /><br />
                                                    <Input type="file" name="videos3" className="form-control-sm upload-video" id="videos3" value={this.state.videos3} onChange={this.uploadVideoToS3} accept="video/mp4,video/x-m4v,video/*" ref={ref => this.videos3 = ref} />
                                                    <p style={{ marginTop: "-20px", marginLeft: "5px" }}>{this.state.videos3progresstext}</p>
                                                </Col>
                            </Row>
                            <FormGroup className="" hidden>
                                <Label for="etype"><Icon path={mdiFileDocument} size={0.9} color="lightgray" className="form-label-icon" />Profile Type</Label>
                                <Input type="select" name="etype" id="etype" value={this.state.selectedEtype || ''} onChange={this.handleDropdownChange} >
                                    <option value='select'>Select Profile Type</option>
                                    {
                                        this.state.etype
                                    }
                                </Input>
                            </FormGroup>

                            {enablecategories ? <Row className="mt-2">
                                <Col md={6}>
                                    {
                                        this.state.exhibitorcategories.includes("Y") ?
                                            (
                                            <FormGroup className="">
                                                <Label for="category" ><Icon path={mdiFormatListBulletedType} size={0.9} color="lightgray" className="form-label-icon" />{categorytitles[0]}</Label>                                                       
                                                <Select
                                                    isMulti
                                                    className=""
                                                    value={this.state.selectedCategory}
                                                    options={this.state.category}
                                                    onChange={this.handleMultipleCategory} />
                                            </FormGroup>) : (' ')
                                    }
                                </Col>
                                <Col md={6}>
                                    {
                                        this.state.exhibitorcategories.includes("Y") ?
                                            (
                                            <FormGroup className="">
                                                <Label for="category" ><Icon path={mdiFormatListBulletedType} size={0.9} color="lightgray" className="form-label-icon" />{categorytitles[1]}</Label>                                                       
                                                <Select
                                                    isMulti
                                                    className=""
                                                    value={this.state.selectedCategory1}
                                                    options={this.state.staffingcategory}
                                                    onChange={this.handleStaffingMultipleCategory} />
                                            </FormGroup>) : (' ')
                                    }
                                </Col>  
                                <Col hidden>
                                    {
                                        this.state.exhibitorcategories.includes("Y") ?
                                        (<FormGroup className="exhibitor-submit-form-form-labels" >
                                            <Label for="category1"><Icon path={mdiFormatListBulletedType} size={0.9} color="lightgray" className="form-label-icon" />Specify Category (If not listed above)</Label>
                                            <Input type="text" name="category1" id="Y" className="form-control-sm" value={this.state.category1 || ''} onChange={this.handleInputChange} />
                                        </FormGroup>)
                                        :(' ')
                                    }
                                </Col>                                  
                            </Row> : ""}
                            
                            <Row>
                                <Col md={12}>
                                    <FormGroup className="form-labels mt-2">
                                    <Label for="Description"><Icon path={mdiMessageText} size={0.9} color="lightgray" className="form-label-icon" />Description</Label>
                                    <Editor
                                        editorState={this.state.editorState}                                       
                                        editorClassName=""
                                        onEditorStateChange={this.onEditorStateChange}
                                        placeholder="Enter description"
                                    />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup className="form-labels" hidden>
                                        <Label for="auth"><Icon path={mdiAccountMultiple} size={0.9} color="lightgray" className="form-label-icon" />Authorized Users</Label>
                                        <Input type="text" name="auth" id="auth" className="form-control-sm" value={this.state.auth || ''} onChange={this.handleInputChange} />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                    <Card className='company_card_wrapper' hidden={!form.enablecontentsection}>
                        <CardHeader className="form-header-1 company_card_header">Primary Contact Information</CardHeader> 
                        <CardBody>
                            <Row>
                                <Col md={3}>
                                    <FormGroup className="form-labels">
                                        <Label for="contact"><Icon path={mdiAccount} size={0.9} color="lightgray" className="form-label-icon" />Contact Name</Label>
                                        <Input type="text" name="contact" className="form-control-sm" value={this.state.contact || ''} onChange={this.handleInputChange} />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup className="form-lables">
                                        <Label for="title"><Icon path={mdiFormatTitle} size={0.9} color="lightgray" className="form-label-icon" />Title</Label>
                                        <Input type="text" name="title" className="form-control-sm" id="title" value={this.state.title || ''} onChange={this.handleInputChange} />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>    
                                    <FormGroup className="form-lables">
                                        <Label for="phone"><Icon path={mdiCellphoneIphone} size={0.9} color="lightgray" className="form-label-icon" />Phone</Label>
                                        <Input type="text" name="phone" className="form-control-sm" id="phone" value={this.state.phone || ''} onChange={this.handleInputChange} placeholder="Enter in NNN-NNN-NNNN format" />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup className="form-lables">
                                        <Label for="email"><Icon path={mdiEmail} size={0.9} color="lightgray" className="form-label-icon" />Email*</Label>
                                        <Input type="text" name="email" className="form-control-sm" id="email" value={this.state.email || ''} onChange={this.handleInputChange} required />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup className="form-lables">
                                        <Label for="social1"><Icon path={mdiFacebook} size={0.9} color="lightgray" className="form-label-icon"/>Facebook</Label>
                                        <Input type="text" name="social1" className="form-control-sm" id="social1" value={this.state.social1 || ''} onChange={this.handleInputChange} />
                                    </FormGroup>
                                </Col>

                                <Col md={3}>
                                    <FormGroup className="form-lables">
                                    <Label for="social2"><Icon path={mdiTwitter} size={0.9} color="lightgray" className="form-label-icon"/>Twitter</Label>
                                    <Input type="text" name="social2" className="form-control-sm" id="social2" value={this.state.social2 || ''} onChange={this.handleInputChange} />
                                    </FormGroup>
                                </Col>
                                            
                                <Col md={3}>
                                    <FormGroup className="form-lables">
                                    <Label for="social3"><Icon path={mdiLinkedin} size={0.9} color="lightgray" className="form-label-icon"/>Linkedin</Label>
                                    <Input type="text" name="social3" className="form-control-sm" id="social3" value={this.state.social3 || ''} onChange={this.handleInputChange} />
                                    </FormGroup>
                                </Col>

                                <Col md={3}>
                                    <FormGroup className="form-lables">
                                    <Label for="social4"><Icon path={mdiInstagram} size={0.9} color="lightgray" className="form-label-icon"/>Instagram</Label>
                                    <Input type="text" name="social4" className="form-control-sm" id="social4" value={this.state.social4 || ''} onChange={this.handleInputChange} />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card> 

                    <Card className='company_card_wrapper'>
                        <CardHeader className="form-header-1 company_card_header">Content</CardHeader>
                        <CardBody>
                            <Row>
                             <Col md={6} hidden={!form.enableimage}>
                                    <h4 className="content_title_text" ><Icon path={mdiImage} size={0.9} color="lightgray" className="form-label-icon" /> Images</h4>
                                    <FormGroup className="form-labels mt-4">
                                        <input ref="uploadImg1" type="file" name="image1" className="form-control-sm upload_image_inputs" id="image1" accept=".gif,.jpg,.jpeg,.png" onChange={this.image1FileUploader} />
                                        <FormText id="uploadImg1">Upload image as jpg, png or gif format only</FormText> 
                                        { this.state.image1Url && this.state.image1Url ==" " ? "" : <img src={this.state.image1Url} className="img-responsive offset-md-4 uploaded_image_img" /> }
                                        <span className='delete-file-btn mb-3' onClick={() => { if (window.confirm(`Do you want to delete the image?`)) { this.removeImage1() }; }} title={'Delete Image'}><Icon path={mdiDeleteForever} size={1} color="gray"/></span>
                                    </FormGroup>
                                    <FormGroup className="form-labels mt-4" hidden>
                                        <input ref="uploadImg2" type="file" name="image2" className="form-control-sm upload_image_inputs" id="image2" accept=".gif,.jpg,.jpeg,.png" onChange={this.image1FileUploader} />
                                        <FormText id="uploadImg2">Upload image as jpg, png or gif format only</FormText> 
                                        { this.state.image2Url && this.state.image2Url ==" " ? "" : <img src={this.state.image2Url} className="img-responsive offset-md-4" /> }
                                    </FormGroup>
                                    <FormGroup className="form-labels mt-4" hidden>
                                        <input ref="uploadImg3" type="file" name="image3" className="form-control-sm upload_image_inputs" id="image3" accept=".gif,.jpg,.jpeg,.png" onChange={this.image1FileUploader} />
                                        <FormText id="uploadImg3">Upload image as jpg, png or gif format only</FormText> 
                                        { this.state.image3Url && this.state.image3Url ==" " ? "" : <img src={this.state.image3Url} className="img-responsive offset-md-4" /> }
                                    </FormGroup>
                                </Col>

                                <Col md={6} hidden={!form.enablevideo}>
                                    <h4 className="content_title_text" ><Icon path={mdiVideo} size={0.9} color="lightgray" className="form-label-icon" /> Videos</h4>
                                    
                                    <Row className="video_upload mt-4">
                                        <Col md={10} className="video-upload-input-wrapper">
                                            <FormGroup className="form-labels">
                                                <Input type="text" name="video1" className="form-control-sm" id="video1" value={this.state.video1 || ''} onChange={this.handleInputChange} placeholder="Enter video link or upload file as video format"/>
                                                {this.state.video1s3progress > 0 ? "" : <FormText className="">Enter video link or upload file as video format</FormText> }
                                                {this.state.video1s3progress > 0 ? <Progress animated color="success" value={this.state.video1s3progress} className='mt-1' />: ''}
                                                <p className='fileprogress_text'>{this.state.video1s3progresstext}</p>
                                            </FormGroup>
                                        </Col>
                                        <Col md={2} className=''>
                                            <Label for="video1s3" className="custom-fileupload" ><p className="video_upload_button" style={{ color: "white" }}>Upload</p></Label><br /><br />
                                            <Input type="file" name="video1s3" className="form-control-sm upload-video" id="video1s3" value={this.state.video1s3} onChange={this.uploadVideo1ToS3} accept="video/mp4,video/x-m4v,video/*" ref={ref => this.video1s3 = ref} />
                                        </Col>
                                    </Row>
                                    <Row className="video_upload" hidden>
                                        <Col md={10} className="video-upload-input-wrapper">
                                            <FormGroup className="form-labels">
                                                <Input type="text" name="video2" className="form-control-sm" id="video2" value={this.state.video2 || ''} onChange={this.handleInputChange} placeholder="Enter video link or upload file as video format"/>
                                                {this.state.video2s3progress > 0 ? "" : <FormText className="">Enter video link or upload file as video format</FormText> }
                                                {this.state.video2s3progress > 0 ? <Progress animated color="success" value={this.state.video2s3progress} className='mt-1' />: ''}
                                                <p className='fileprogress_text'>{this.state.video2s3progresstext}</p>
                                            </FormGroup>
                                        </Col>
                                        <Col md={2} className=''>
                                            <Label for="video2s3" className="custom-fileupload" ><p className="video_upload_button" style={{ color: "white" }}>Upload</p></Label><br /><br />
                                            <Input type="file" name="video2s3" className="form-control-sm upload-video" id="video2s3" value={this.state.video2s3} onChange={this.uploadVideo1ToS3} accept="video/mp4,video/x-m4v,video/*" ref={ref => this.video1s3 = ref} />
                                        </Col>
                                    </Row>
                                    <Row className="video_upload" hidden>
                                        <Col md={10} className="video-upload-input-wrapper">
                                            <FormGroup className="form-labels">
                                                <Input type="text" name="video3" className="form-control-sm" id="video3" value={this.state.video3 || ''} onChange={this.handleInputChange} placeholder="Enter video link or upload file as video format"/>
                                                {this.state.video3s3progress > 0 ? "" : <FormText className="">Enter video link or upload file as video format</FormText> }
                                                {this.state.video3s3progress > 0 ? <Progress animated color="success" value={this.state.video3s3progress} className='mt-1' />: ''}
                                                <p className='fileprogress_text'>{this.state.video3s3progresstext}</p>
                                            </FormGroup>
                                        </Col>
                                        <Col md={2} className=''>
                                            <Label for="video3s3" className="custom-fileupload" ><p className="video_upload_button" style={{ color: "white" }}>Upload</p></Label><br /><br />
                                            <Input type="file" name="video3s3" className="form-control-sm upload-video" id="video3s3" value={this.state.video3s3} onChange={this.uploadVideo1ToS3} accept="video/mp4,video/x-m4v,video/*" ref={ref => this.video1s3 = ref} />
                                        </Col>
                                    </Row>
                                    

                                </Col>
                                
                            </Row>
                            <Row hidden={!form.enabledocuments}>
                                <Col md={4}>
                                    <FormGroup className="form-labels">
                                        
                                        <Label for="file1"><Icon path={mdiFile} size={0.9} color="lightgray" className="form-label-icon" />Document 1</Label>
                                        <span className='delete-file-btn mb-3' onClick={() => { if (window.confirm(`Do you want to delete file?`)) { this.removeDocument1() }; }} title={'Delete file'}><Icon path={mdiDeleteForever} size={1} color="gray"/></span>
                                        <Input type="file" name="file1" className="form-control-sm upload_image_inputs" id="file1" accept=".doc, .docx, .pdf, .pptx" value={this.state.file1} onChange={this.uploadFile1} ref={ref => this.file1 = ref} />
                                        {this.state.file1progress > 0 ? <Progress animated color="success" value={this.state.file1progress} className="mt-1"/> : ""}
                                        <p className='fileprogress_text'>{this.state.file1progresstext}</p>
                                        {
                                            this.state.file1FileName.includes(" ") ?
                                                ('')
                                                :
                                                (<Link className="col-offset-md-1"
                                                    to="route"
                                                    onClick={(event) => { event.preventDefault(); window.open('https://s3.amazonaws.com/event360live/public/' + this.state.code + '/exhibitors/' + this.state.file1FileName); }}><i className="fa fa-download"></i>Download File
                                                </Link>)
                                        }
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup className="form-labels">
                                        <Label for="file2"><Icon path={mdiFile} size={0.9} color="lightgray" className="form-label-icon" />Document 2</Label>
                                        <span className='delete-file-btn mb-3' onClick={() => { if (window.confirm(`Do you want to delete file?`)) { this.removeDocument2() }; }} title={'Delete file'}><Icon path={mdiDeleteForever} size={1} color="gray"/></span>
                                        <Input type="file" name="file2" className="form-control-sm upload_image_inputs" id="file2" accept=".doc, .docx, .pdf, .pptx" value={this.state.file2} onChange={this.uploadFile2} ref={ref => this.file2 = ref} />
                                        { this.state.file2progress > 0 ? <Progress animated color="success" value={this.state.file2progress} className="mt-1"/> : ""}
                                        <p className='fileprogress_text'>{this.state.file2progresstext}</p>
                                        {
                                            this.state.file2FileName.includes(" ") ?
                                                ('')
                                                :
                                                (<Link className="col-offset-md-1"
                                                    to="route"
                                                    onClick={(event) => { event.preventDefault(); window.open('https://s3.amazonaws.com/event360live/public/' + this.state.code + '/exhibitors/' + this.state.file2FileName); }}><i className="fa fa-download"></i>Download File
                                                </Link>)
                                        }
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup className="form-labels">
                                        <Label for="file3"><Icon path={mdiFile} size={0.9} color="lightgray" className="form-label-icon" />Document 3</Label>
                                        <span className='delete-file-btn mb-3' onClick={() => { if (window.confirm(`Do you want to delete file?`)) { this.removeDocument3() }; }} title={'Delete file'}><Icon path={mdiDeleteForever} size={1} color="gray"/></span>
                                        <Input type="file" name="file3" className="form-control-sm upload_image_inputs" accept=".doc, .docx, .pdf, .pptx" id="file3" value={this.state.file3} onChange={this.uploadFile3} ref={ref => this.file3 = ref} />
                                        { this.state.file3progress > 0 ? <Progress animated color="success" value={this.state.file3progress} className="mt-1"/> : ""}
                                        <p className='fileprogress_text'>{this.state.file3progresstext}</p>
                                        {
                                            this.state.file3FileName.includes(" ") ?
                                                ('')
                                                :
                                                (<Link className="col-offset-md-1"
                                                    to="route"
                                                    onClick={(event) => { event.preventDefault(); window.open('https://s3.amazonaws.com/event360live/public/' + this.state.code + '/exhibitors/' + this.state.file3FileName); }}><i className="fa fa-download"></i>Download File
                                                </Link>)
                                        }
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody> 
                    </Card>

                    <Card className='company_card_wrapper' hidden={!exhibitorlead}>
                        <CardHeader className="form-header-1 company_card_header">Lead Generation</CardHeader>
                        <CardBody>
                            <Row>
                                <Col md={6}>
                                    <FormGroup className="form-labels">
                                        <Label for="auth"><Icon path={mdiAccountMultiple} size={0.9} color="lightgray" className="form-label-icon" />Booth Staff</Label>
                                        <Input type="text" name="auth" id="auth" className="form-control-sm" value={this.state.auth || ''} onChange={this.handleInputChange} />
                                        <FormText>Enter email ids for booth staff. Ex:staff1@company.com,staff2@company.com</FormText>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody> 
                    </Card>
 

                    <Card className='company_card_wrapper' hidden>
                        <CardHeader className="form-header-1 company_card_header">Meeting Info</CardHeader>
                        <CardBody>
                            <Row>
                                <Col md={4}>
                                    <FormGroup className="form-labels">
                                        <Label for="meeting1" className="login-labels" ><Icon path={mdiLink} size={0.9} color="lightgray" className="form-label-icon" />Meeting Info 1</Label>
                                        <Input type="text" name="meeting1" className="form-control-sm" id="meeting1" value={this.state.meeting1 || ''} onChange={this.handleInputChange} />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup className="form-labels">
                                        <Label for="meeting2" className="login-labels" ><Icon path={mdiLink} size={0.9} color="lightgray" className="form-label-icon" />Meeting Info 2</Label>
                                        <Input type="text" name="meeting2" className="form-control-sm" id="meeting2" value={this.state.meeting2 || ''} onChange={this.handleInputChange} />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup className="form-labels">
                                        <Label for="meeting3" className="login-labels" ><Icon path={mdiLink} size={0.9} color="lightgray" className="form-label-icon" />Meeting Info 3</Label>
                                        <Input type="text" name="meeting3" className="form-control-sm" id="meeting3" value={this.state.meeting3 || ''} onChange={this.handleInputChange} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup className="form-label" >
                                        <Label for="schedule"><Icon path={mdiCalendar} size={0.9} color="lightgray" className="form-label-icon" />Schedule</Label>
                                        <Input type="text" name="schedule" className="form-control-sm" id="schedule" value={this.state.schedule || ''} onChange={this.handleInputChange} />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                    <Card className="company_card_wrapper" hidden>
                        <CardHeader className="form-header-1 company_card_header">Internal Section</CardHeader>
                            <CardBody>
                                
                                <Row>
                                            <Col md={4}>
                                                <FormGroup className="form-labels">
                                                    <Label for="sortorder" className="login-labels" ><Icon path={mdiSort} size={0.9} color="lightgray" className="form-label-icon" />Sort Order</Label>
                                                    <Input type="text" name="sortorder" className="form-control-sm" id="sortorder" value={this.state.sortorder || ''} onChange={this.handleInputChange} />
                                                </FormGroup>
                                            </Col>
                                                <Col md={4}>
                                                    <FormGroup className="form-labels">
                                                        <Label for="data1" className="login-labels" ><Icon path={mdiDatabase} size={0.9} color="lightgray" className="form-label-icon" />Data 1</Label>
                                                        <Input type="text" name="data1" className="form-control-sm" id="data1" value={this.state.data1 || ''} onChange={this.handleInputChange} />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={4}>
                                                    <FormGroup className="form-labels">
                                                        <Label for="data2" className="login-labels" ><Icon path={mdiDatabase} size={0.9} color="lightgray" className="form-label-icon" />Data 2</Label>
                                                        <Input type="text" name="data2" className="form-control-sm" id="data2" value={this.state.data2 || ''} onChange={this.handleInputChange} />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={4}>
                                                    <FormGroup className="form-labels">
                                                        <Label for="data3" className="login-labels" ><Icon path={mdiDatabase} size={0.9} color="lightgray" className="form-label-icon" />Data 3</Label>
                                                        <Input type="text" name="data3" className="form-control-sm" id="data3" value={this.state.data3 || ''} onChange={this.handleInputChange} />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={4}>
                                                    <FormGroup className="form-labels">
                                                        <Label for="data4" className="login-labels" ><Icon path={mdiDatabase} size={0.9} color="lightgray" className="form-label-icon" />Data 4</Label>
                                                        <Input type="text" name="data4" className="form-control-sm" id="data4" value={this.state.data4 || ''} onChange={this.handleInputChange} />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={4}>
                                                    <FormGroup className="form-labels">
                                                        <Label for="data5" className="login-labels" ><Icon path={mdiDatabase} size={0.9} color="lightgray" className="form-label-icon" />Data 5</Label>
                                                        <Input type="text" name="data5" className="form-control-sm" id="data5" value={this.state.data5 || ''} onChange={this.handleInputChange} />
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                        </CardBody>

                    </Card>
                    {
                        productsenable
                        ? 
                        (
                            <Products ref={this.productsRef} />
                        ):''
                    }

                    {this.state.message ?<div className="alert alert-dark alert_message" role="alert" id="message">{this.state.message}</div>: ""}
                    <div className='action_btns_wrapper'>   
                    {this.state.isLoading ?
                        <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="Loading-btn"
                        variant="success"
                        hidden={!this.state.isLoading} /> : 
                        <button
                        className="btn form-submit-button submit_btn"
                        onClick={this.handleSubmit}
                        disabled={this.state.disabled}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Submit"
                        loadingText="Submitting…"
                        >Submit</button>}        
                        <button type="button" className="btn form-clear-btn" onClick={this.handleClear} >Clear</button>
                    </div>
                </div>
            </>
        );
    }

    constructor(props) {

        super(props);
        this.state =
        {
            //code: '',
            id: global.$userid,
            code: global.$code,
            userid: global.$userid,
            exhibitorcategories: "Y",
            type: 'S',
            etype: [],
            selectedEtype: '',
            company: '',
            url: '',
            country: 'United States',
            state: '',
            city: '',
            street: '',
            zip: '',
            booth: '',
            splevel: '',
            category: [],
            category1: '',
            staffingcategory: [],
            imageUrl: '',
            logoFileName: '',
            logoFileHandle: '',
            fileFileName: '',
            fileFileHandle: '',
            adfile: '',
            adsrc: [],
            selectedSrc: '',
            contact: '',
            title: '',
            phone: '',
            email: '',
            social1: '',
            social2: '',
            social3: '',
            social4: '',
            social5: '',
            social6: '',
            desc: '',
            cdate: '',
            disabled: false,
            isLoading: false,
            selectedCategory: "",
            selectedCategory1: "",
            selected: '',
            selected1: '',
            termsandconditons: '',
            editorState: EditorState.createEmpty(),
            rawDraftContentState: '',
            keywords: '',
            tag: '',
            submittedby: '',
            points: '',
            auth: '',
            createdby: '',
            sortorder: '',
            videos3: '',
            videotos3FileName: ' ',
            videotos3FileHandle: '',
            videos3progress: 0,
            videos3progresstext: '',
            video1s3: '',
            video1tos3FileName: ' ',
            video1tos3FileHandle: '',
            video1s3progress: 0,
            video1s3progresstext: '',
            video2s3: '',
            video2tos3FileName: ' ',
            video2tos3FileHandle: '',
            video2s3progress: 0,
            video2s3progresstext: '',
            video3s3: '',
            video3tos3FileName: ' ',
            video3tos3FileHandle: '',
            video3s3progress: 0,
            video3s3progresstext: '',
            videourl: '',
            videourl: '',
            video1: '',
            video2: '',
            video3: '',
            image1: '',
            image2: '',
            image3: '',
            image1Url: '',
            image2Url: '',
            image3Url: '',
            image1FileName: '',
            image2FileName: '',
            image3FileName: '',
            image1FileHandle: '',
            image2FileHandle: '',
            image3FileHandle: '',
            file1FileName: ' ',
            file1FileHandle: '',
            file2FileName: ' ',
            file2FileHandle: '',
            file3FileName: ' ',
            file3FileHandle: '',
            file1progress: 0,
            file1progresstext: '',
            file2progress: 0,
            file2progresstext: '',
            file3progress: 0,
            file3progresstext: '',
            meeting1: '',
            meeting2: '',
            meeting3: '',
            data1: '',
            data2: '',
            data3: '',
            data4: '',
            data5: '',
            schedule: '',
            modal: false,
            companyid: global.$sponsorid,
            updateexhibitor:true,
            
            
        };
        //console.log(this.state.id);
        if (this.state.code == "travcon2022exhibitor") {
            this.state.code = "travcon2022";
        }
        if (this.state.code == "travcon2023exhibitor") {
            this.state.code = "travcon2023";
        }
        this.productsRef = React.createRef();
        this.state.message = "";
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.fileUploader = this.fileUploader.bind(this);
        this.selectCountry = this.selectCountry.bind(this);
        this.selectRegion = this.selectRegion.bind(this);
        this.handleCategory = this.handleCategory.bind(this);
        this.handleMultipleCategory = this.handleMultipleCategory.bind(this);
        this.handleStaffingMultipleCategory = this.handleStaffingMultipleCategory.bind(this);
        this.handleDropdownChange = this.handleDropdownChange.bind(this);
        this.uploadVideoToS3 = this.uploadVideoToS3.bind(this);
        this.uploadVideo1ToS3 = this.uploadVideo1ToS3.bind(this);
        this.uploadVideo2ToS3 = this.uploadVideo2ToS3.bind(this);
        this.uploadVideo3ToS3 = this.uploadVideo3ToS3.bind(this);
        this.image1FileUploader = this.image1FileUploader.bind(this);
        this.image2FileUploader = this.image2FileUploader.bind(this);
        this.image3FileUploader = this.image3FileUploader.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.uploadFile1 = this.uploadFile1.bind(this);
        this.uploadFile2 = this.uploadFile2.bind(this);
        this.uploadFile3 = this.uploadFile3.bind(this);
        this.copyFiles = this.copyFiles.bind(this)
        this.handleDropdownChangeForSrc = this.handleDropdownChangeForSrc.bind(this);
        this.toggle = this.toggle.bind(this);

        this.getCategory();
        
        this.getTermsConditionCategory();
        this.getCategoryProfileTypes();
        
        this.getCategoryAdSrc();
        //this.checkEvent();
        var date = new Date();
        var longFormat = date * 1;
        this.state.id = longFormat.toString();
    }

    componentDidMount() {
        this.getExhibitor();
        
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });

    }

    selectCountry(val) {
        this.setState({ country: val });
    }

    selectRegion(val) {
        this.setState({ state: val })
    }

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }))
    }

    getFileName(id, fileName) {
        var key = id;
        key = key.replace(/,/g, '_');
        key = key.replace(/ /g, '_');
        key = key.replace(/&/g, '_');
        key = key.replace(/\./g, '');
        key = key.replace(/__/g, '_');
        key = key.toLowerCase();

        fileName = fileName.toLowerCase();

        if (fileName.indexOf("jpg") !== -1) {
            key = key + ".jpg";
        }
        else if (fileName.indexOf("gif") !== -1) {
            key = key + ".gif";
        }
        else if (fileName.indexOf("png") !== -1) {
            key = key + ".png";
        }
        else {
            this.state.logoFileName = "Format not supported";
        }

        return key;
    }

    getS3FileName() {
        return this.state.code + "/exhibitors/" + this.state.logoFileName;
    }

    //files
    fileUploader = (event) => {

        //file preview
        const file = this.refs.uploadImg.files[0]
        const reader = new FileReader();

        //////console.log("fileUploader");
        reader.onloadend = () => {
            this.setState({
                imageUrl: reader.result
            })
        }
        if (file) {
            reader.readAsDataURL(file);
            this.setState({
                imageUrl: reader.result
            })
        }
        else {
            this.setState({
                imageUrl: " "
            })
        }

        //file handler
        const target = event.target;
        const name = target.name;
        let value = target.value

        if (name === 'logo') {
            this.state.logoFileHandle = event.target.files[0];
            //////console.log("logoFileName = " + this.state.logoFileHandle.name)
            this.state.logoFileName = this.getFileName(this.state.id, this.state.logoFileHandle.name);
        }
        else {
            this.setState(
                {
                    [name]: value
                }
            );
        }

        //file storage
        const s3File = this.getS3FileName();

        //////console.log('file=' +this.refs.uploadImg.files[0].name+", Des ="+s3File);

        Storage.put(s3File, this.state.logoFileHandle,
            {
                contentType: 'image/png'
            })
            .then(
                result => {
                    //////console.log(result);
                    //this.state.message = "logo successfully submitted"

                    this.forceUpdate();
                }
            )
            .catch(err => {
                //////console.log(err)
                this.state.message = "Unable to upload logo. Please try again"
                this.forceUpdate();

            }
            );

    }
    // REMOVE DOCUMENT 1
    removeDocument1(){
        this.setState({ file1 : "",file1FileName: " ",file1progress:0,file1progresstext:''})
    }
    // REMOVE DOCUMENT 2
    removeDocument2(){
        this.setState({ file2 : "",file2FileName: " ",file2progress:0,file2progresstext:''})
    } 
    // REMOVE DOCUMENT 3
    removeDocument3(){
        this.setState({ file3 : "",file3FileName: " ",file3progress:0,file3progresstext:''})
    }
    // REMOVE IMAGE1
    removeImage1(){
        this.setState({ image1Url : "",image1FileName: " "})
    }
    // REMOVE Logo
    removeLogo(){
        this.setState({ imageUrl : "",logoFileName: " "})
    }

    download = () => {
        const canvas = document.getElementById("qrCode");
        const pngUrl = canvas
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = this.state.company + "-QR.png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    getExhibitorFile1FileName(id, fileName) {

        var key = id + '_' + 'p1';
        key = key.replace(/,/g, '_');
        key = key.replace(/ /g, '_');
        key = key.replace(/&/g, '_');
        key = key.replace(/\./g, '');
        key = key.replace(/__/g, '_');
        key = key.toLowerCase();

        fileName = fileName.toLowerCase();

       //console.log(fileName);
        var fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1);
       //console.log(fileExtension);

        key = key + "." + fileExtension;
       //console.log(key);

        this.setState({ file1progress: 50 });
        return key;
    }

    getS3ExhibitorFile1FileName() {
        this.setState({ file1progress: 75 });
        return this.state.code + "/exhibitors/" + this.state.file1FileName;
    }


    getExhibitorFile2FileName(id, fileName) {

        var key = id + '_' + 'p2';
        key = key.replace(/,/g, '_');
        key = key.replace(/ /g, '_');
        key = key.replace(/&/g, '_');
        key = key.replace(/\./g, '');
        key = key.replace(/__/g, '_');
        key = key.toLowerCase();

        fileName = fileName.toLowerCase();

       //console.log(fileName);
        var fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1);
       //console.log(fileExtension);

        key = key + "." + fileExtension;
       //console.log(key);

        this.setState({ file2progress: 50 });

        return key;
    }

    getS3ExhibitorFile2FileName() {
        this.setState({ file2progress: 75 });
        return this.state.code + "/exhibitors/" + this.state.file2FileName;
    }

    getExhibitorFile3FileName(id, fileName) {

        var key = id + '_' + 'p3';
        key = key.replace(/,/g, '_');
        key = key.replace(/ /g, '_');
        key = key.replace(/&/g, '_');
        key = key.replace(/\./g, '');
        key = key.replace(/__/g, '_');
        key = key.toLowerCase();

        fileName = fileName.toLowerCase();

       //console.log(fileName);
        var fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1);
       //console.log(fileExtension);

        key = key + "." + fileExtension;
       //console.log(key);

        this.setState({ file3progress: 50 });

        return key;
    }

    getS3ExhibitorFile3FileName() {
        this.setState({ file3progress: 75 });
        return this.state.code + "/exhibitors/" + this.state.file3FileName;
    }
    getImage1FileName(id, fileName) {
        var key = id + '_I1';
        key = key.replace(/,/g, '_');
        key = key.replace(/ /g, '_');
        key = key.replace(/&/g, '_');
        key = key.replace(/\./g, '');
        key = key.replace(/__/g, '_');
        //console.log(key);
        key = key.toLowerCase();

        fileName = fileName.toLowerCase();

        if (fileName.indexOf("jpg") !== -1) {
            key = key + ".jpg";
        }
        else if (fileName.indexOf("gif") !== -1) {
            key = key + ".gif";
        }
        else if (fileName.indexOf("png") !== -1) {
            key = key + ".png";
        }
        else {
            this.state.image1FileName = "Format not supported";
        }

        return key;
    }

    getImage1S3FileName() {
        return this.state.code + "/exhibitors/" + this.state.image1FileName;
    }
    getImage2FileName(id, fileName) {
        var key = id + '_I2';
        key = key.replace(/,/g, '_');
        key = key.replace(/ /g, '_');
        key = key.replace(/&/g, '_');
        key = key.replace(/\./g, '');
        key = key.replace(/__/g, '_');
        //console.log(key);
        key = key.toLowerCase();

        fileName = fileName.toLowerCase();

        if (fileName.indexOf("jpg") !== -1) {
            key = key + ".jpg";
        }
        else if (fileName.indexOf("gif") !== -1) {
            key = key + ".gif";
        }
        else if (fileName.indexOf("png") !== -1) {
            key = key + ".png";
        }
        else {
            this.state.image2FileName = "Format not supported";
        }

        return key;
    }

    getImage2S3FileName() {
        return this.state.code + "/Exhibitors/" + this.state.image2FileName;
    }
    getImage3FileName(id, fileName) {
        var key = id + '_I3';
        key = key.replace(/,/g, '_');
        key = key.replace(/ /g, '_');
        key = key.replace(/&/g, '_');
        key = key.replace(/\./g, '');
        key = key.replace(/__/g, '_');
        //console.log(key);
        key = key.toLowerCase();

        fileName = fileName.toLowerCase();

        if (fileName.indexOf("jpg") !== -1) {
            key = key + ".jpg";
        }
        else if (fileName.indexOf("gif") !== -1) {
            key = key + ".gif";
        }
        else if (fileName.indexOf("png") !== -1) {
            key = key + ".png";
        }
        else {
            this.state.image3FileName = "Format not supported";
        }

        return key;
    }

    getImage3S3FileName() {
        return this.state.code + "/exhibitors/" + this.state.image3FileName;
    }

    //files
    image1FileUploader = (event) => {

        //file preview
        const file = this.refs.uploadImg1.files[0]
        const reader = new FileReader();

        //////console.log("fileUploader");
        reader.onloadend = () => {
            this.setState({
                image1Url: reader.result
            })
        }
        if (file) {
            reader.readAsDataURL(file);
            this.setState({
                image1Url: reader.result
            })
        }
        else {
            this.setState({
                image1Url: " "
            })
        }

        //file handler
        const target = event.target;
        const name = target.name;
        let value = target.value

        if (name === 'image1') {
            this.state.image1FileHandle = event.target.files[0];
            //////console.log("logoFileName = " + this.state.logoFileHandle.name)
            this.state.image1FileName = this.getImage1FileName(this.state.id, this.state.image1FileHandle.name);
        }
        else {
            this.setState(
                {
                    [name]: value
                }
            );
        }

        //file storage
        const s3Video1File = this.getImage1S3FileName();

        ////console.log('file=' +this.refs.uploadImg.files[0].name+", Des ="+s3File);

        Storage.put(s3Video1File, this.state.image1FileHandle,
            {
                contentType: 'image/png'
            })
            .then(
                result => {
                    // DOWNLOAD COPY OF THE UPLOADED FILE
                    this.copyFiles(this.state.image1FileName);

                    this.forceUpdate();
                }
            )
            .catch(err => {
                //////console.log(err)
                this.state.message = "Unable to upload logo. Please try again"
                this.forceUpdate();

            }
            );

    }

    //files
    image2FileUploader = (event) => {

        //file preview
        const file = this.refs.uploadImg2.files[0]
        const reader = new FileReader();

        //////console.log("fileUploader");
        reader.onloadend = () => {
            this.setState({
                image2Url: reader.result
            })
        }
        if (file) {
            reader.readAsDataURL(file);
            this.setState({
                image2Url: reader.result
            })
        }
        else {
            this.setState({
                image2Url: " "
            })
        }

        //file handler
        const target = event.target;
        const name = target.name;
        let value = target.value

        if (name === 'image2') {
            this.state.image2FileHandle = event.target.files[0];
           //console.log("logoFileName = " + this.state.logoFileHandle.name)
            this.state.image2FileName = this.getImage2FileName(this.state.id, this.state.image2FileHandle.name);
        }
        else {
            this.setState(
                {
                    [name]: value
                }
            );
        }

        //file storage
        const s3Video2File = this.getImage2S3FileName();

        ////console.log('file=' +this.refs.uploadImg.files[0].name+", Des ="+s3File);

        Storage.put(s3Video2File, this.state.image2FileHandle,
            {
                contentType: 'image/png'
            })
            .then(
                result => {
                    //////console.log(result);
                    //this.state.message = "logo successfully submitted"

                    this.forceUpdate();
                }
            )
            .catch(err => {
                //////console.log(err)
                this.state.message = "Unable to upload logo. Please try again"
                this.forceUpdate();

            }
            );

    }

    //files
    image3FileUploader = (event) => {

        //file preview
        const file = this.refs.uploadImg3.files[0]
        const reader = new FileReader();

        //////console.log("fileUploader");
        reader.onloadend = () => {
            this.setState({
                image3Url: reader.result
            })
        }
        if (file) {
            reader.readAsDataURL(file);
            this.setState({
                image3Url: reader.result
            })
        }
        else {
            this.setState({
                image3Url: " "
            })
        }

        //file handler
        const target = event.target;
        const name = target.name;
        let value = target.value

        if (name === 'image3') {
            this.state.image3FileHandle = event.target.files[0];
            //////console.log("logoFileName = " + this.state.logoFileHandle.name)
            this.state.image3FileName = this.getImage3FileName(this.state.id, this.state.image3FileHandle.name);
        }
        else {
            this.setState(
                {
                    [name]: value
                }
            );
        }

        //file storage
        const s3Video3File = this.getImage3S3FileName();

        ////console.log('file=' +this.refs.uploadImg.files[0].name+", Des ="+s3File);

        Storage.put(s3Video3File, this.state.image3FileHandle,
            {
                contentType: 'image/png'
            })
            .then(
                result => {
                    //////console.log(result);
                    //this.state.message = "logo successfully submitted"

                    this.forceUpdate();
                }
            )
            .catch(err => {
                //////console.log(err)
                this.state.message = "Unable to upload logo. Please try again"
                this.forceUpdate();

            }
            );

    }
    getFileFileName(id, fileName) {

        var key = id + "_" + "ad";
        key = key.replace(/,/g, '_');
        key = key.replace(/ /g, '_');
        key = key.replace(/&/g, '_');
        key = key.replace(/\./g, '');
        key = key.replace(/__/g, '_');
        key = key.toLowerCase();

        fileName = fileName.toLowerCase();

        if (fileName.indexOf("html") !== -1) {
            key = key + ".html";
        }
        else if (fileName.indexOf("jpg") !== -1) {
            key = key + ".jpg";
        }
        else if (fileName.indexOf("gif") !== -1) {
            key = key + ".gif";
        }
        else if (fileName.indexOf("png") !== -1) {
            key = key + ".png";
        }
        else {
            this.state.fileFileName = "Format not supported";
        }

        return key;
    }

    getS3FileFileName() {
        return this.state.code + "/exhibitors/" + this.state.fileFileName;
    }
    uploadFile = (event) => {
        const target = event.target;
        const name = target.name;
        let value = target.value

        document.getElementById("ad-message").innerHTML = "<p style='color: green; margin-bottom: 10px'>Uploading...</p>";

        if (name === 'adfile') {
            this.state.fileFileHandle = event.target.files[0];
            ////console.log("fileFileName = " + this.state.fileFileHandle.name)
            this.state.fileFileName = this.getFileFileName(this.state.id, this.state.fileFileHandle.name);
        }
        else {
            this.setState(
                {
                    [name]: value
                }
            );
        }


        const s3File = this.getS3FileFileName();

        ////console.log('Uploading File1 = ' + this.state.file1FileHandle.name + " , Des = " + s3File );

        Storage.put(s3File, this.state.fileFileHandle,
            {
                contentType: 'html/image/png'
            })

            .then(
                result => {
                    //console.log(result);
                    document.getElementById("ad-message").innerHTML = "<p style='color: green; margin-bottom: 10px'>File uploaded successfully</p>";
                    //this.state.message = "File uploaded successfully"
                    this.adfile.value = "";

                    this.forceUpdate();
                }
            )
            .catch(err => {
                //////console.log(err)
                //this.state.message = "Unable to upload file. Please try again"
                document.getElementById("ad-message").innerHTML = "<p style='color: peru'>Unable to upload file. Please try again</p>";
                this.forceUpdate();
            }
            );
    }

    getVideoS3FileName(id, fileName) {

        var key = id + '_' + 's3';
        key = key.replace(/,/g, '_');
        key = key.replace(/ /g, '_');
        key = key.replace(/&/g, '_');
        key = key.replace(/\./g, '');
        key = key.replace(/__/g, '_');
        key = key.toLowerCase();

        key = key + ".mp4";

        fileName = fileName.toLowerCase();

        this.setState({ videos3progress: 50 });

        return key;
    }

    getS3VideoFileName() {
        this.setState({ videos3progress: 75 });
        return this.state.code + "/exhibitors/" + this.state.videotos3FileName;
    }

    getVideo1S3FileName(id, fileName) {

        var key = id + '_' + 's3';
        key = key.replace(/,/g, '_');
        key = key.replace(/ /g, '_');
        key = key.replace(/&/g, '_');
        key = key.replace(/\./g, '');
        key = key.replace(/__/g, '_');
        key = key.toLowerCase();

        key = key + ".mp4";

        fileName = fileName.toLowerCase();

        this.setState({ video1s3progress: 50 });

        return key;
    }

    getS3Video1FileName() {
        this.setState({ video1s3progress: 75 });
        return this.state.code + "/exhibitors/" + this.state.video1tos3FileName;
    }
    getVideo2S3FileName(id, fileName) {

        var key = id + '_' + 's3';
        key = key.replace(/,/g, '_');
        key = key.replace(/ /g, '_');
        key = key.replace(/&/g, '_');
        key = key.replace(/\./g, '');
        key = key.replace(/__/g, '_');
        key = key.toLowerCase();

        key = key + ".mp4";

        fileName = fileName.toLowerCase();

        this.setState({ video2s3progress: 50 });

        return key;
    }

    getS3Video2FileName() {
        this.setState({ video2s3progress: 75 });
        return this.state.code + "/exhibitors/" + this.state.video2tos3FileName;
    }
    getVideo3S3FileName(id, fileName) {

        var key = id + '_' + 's3';
        key = key.replace(/,/g, '_');
        key = key.replace(/ /g, '_');
        key = key.replace(/&/g, '_');
        key = key.replace(/\./g, '');
        key = key.replace(/__/g, '_');
        key = key.toLowerCase();

        key = key + ".mp4";

        fileName = fileName.toLowerCase();

        this.setState({ video3s3progress: 50 });

        return key;
    }

    getS3Video3FileName() {
        this.setState({ video3s3progress: 75 });
        return this.state.code + "/exhibitors/" + this.state.video3tos3FileName;
    }

    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        let value = target.value

        //////console.log( "Target = " + name + ", Value = " + value);

        this.setState({ [name]: value });
        //this.setState({selectedCategory:value})
    }

    handleMultipleCategory(selectedCategory) {
        this.setState({ selectedCategory });

        var value = []
        //console.log("Ready")
        //console.log(selectedCategory);
        if (selectedCategory == null) {
            selectedCategory = [];
        }
        //console.log(selectedCategory);

        if (selectedCategory.length == 0 || selectedCategory == null) {
            //console.log("zero length");
            this.setState({ selected: ' ' });
            selectedCategory.length = 0;
        }
        for (var i = 0; i < selectedCategory.length; i++) {
            //console.log(selectedCategory.length);
            var newObj = Object()

            newObj.value = selectedCategory[i].value
            value.push(newObj.value);
            //console.log(value)
            this.setState({ selected: value.toString() });
            //console.log(selectedCategory);
            //console.log(value.toString());
            //console.log(this.state.selected);
        }

        //console.log("Close")

    };
    handleStaffingMultipleCategory(selectedCategory1) {
        this.setState({ selectedCategory1 });

        var value = ""
        //console.log("Ready")
        //console.log(selectedCategory);
        if (selectedCategory1 == null) {
            selectedCategory1 = [];
        }
        //console.log(selectedCategory);

        if (selectedCategory1.length == 0 || selectedCategory1 == null) {
            //console.log("zero length");
            this.setState({ selected1: ' ' });
            selectedCategory1.length = 0;
        }
        for (var i = 0; i < selectedCategory1.length; i++) {
            //console.log(selectedCategory.length);
            var newObj = Object()

            newObj.value = selectedCategory1[i].value
            //value.push(newObj.value + '~~');
            value = value + newObj.value + '~~'
            //console.log(value)
            this.setState({ selected1: value.toString() });
            //console.log(selectedCategory);
            //console.log(value.toString());
            //console.log(this.state.selected1);
        }

        //console.log("Close")

    };

    
    getExhibitorFile1FileName(id, fileName) {

        var key = id + '_' + 'p1';
        key = key.replace(/,/g, '_');
        key = key.replace(/ /g, '_');
        key = key.replace(/&/g, '_');
        key = key.replace(/\./g, '');
        key = key.replace(/__/g, '_');
        key = key.toLowerCase();
    
        fileName = fileName.toLowerCase();
    
        //console.log(fileName);
        var fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1);
        //console.log(fileExtension);
    
        key = key + "." + fileExtension;
        //console.log(key);
    
        this.setState({ file1progress: 50 });
        return key;
    }
    
    handleCategory(event) {
        const target = event.target;
        const name = target.name;

        let value = target.value

        //////console.log( "Target = " + name + ", Value = " + value);
        this.setState({ selectedCategory: event.target.value });
    }

    handleDropdownChange(event) {
        const target = event.target;
        const name = target.name;
        let value = target.value

        //////console.log( "Target = " + name + ", Value = " + value);
        this.setState({ selectedEtype: event.target.value });

    }

    handleDropdownChangeForSrc(event) {
        const target = event.target;
        const name = target.name;
        let value = target.value

        //////console.log( "Target = " + name + ", Value = " + value);
        this.setState({ selectedSrc: event.target.value });
    }
    validateInputs() {

        if (this.state.code === "" || this.state.company === "" || this.state.email === "") {
            this.state.message = "Enter all the required inputs, choose your logo and click submit"
            this.forceUpdate();
            return false;
        }

        return true;
    }

    handleSubmit(event) {
        event.preventDefault();

        if (this.validateInputs()) {

            this.submitProfile(event);
            this.setState({ isLoading: true });

        }
    }

    handleClear(event) {
        event.preventDefault();
        this.setState({ disabled: false });
        this.state.message = "";
        this.clearInputs();
    }

    clearInputs() {
        //this.state.code         = '';
        this.state.type = 'E';
        this.state.selectedEtype = '';
        this.state.company = '';
        this.state.url = '';
        this.state.state = '';
        this.state.city = '';
        this.state.street = '';
        this.state.zip = '';
        this.state.booth = '';
        this.state.splevel = '';
        this.state.category1 = '';
        this.state.imageUrl = '';
        this.state.logoFileName = '';
        this.state.logoFileHandle = '';
        this.state.contact = '';
        this.state.title = '';
        this.state.phone = '';
        this.state.email = '';
        this.state.social1 = '';
        this.state.social2 = '';
        this.state.social3 = '';
        this.state.social4 = '';
        this.state.social5 = '';
        this.state.social6 = '';
        this.state.desc = '';
        this.state.selectedCategory = "";
        this.state.selected = '';
        //this.state.id             = "";
        //this.state.editorState    = '';
        this.state.keywords = '';
        this.state.tag = '';
        this.state.auth = '';
        this.state.points = '';
        this.state.sortorder = '';
        this.state.videourl = '';
        this.state.videos3 = '';
        this.state.videotos3FileName = '';
        this.state.videotos3FileHandle = '';
        this.state.video1tos3FileName = '';
        this.state.video1tos3FileHandle = '';
        this.state.video2tos3FileName = '';
        this.state.video2tos3FileHandle = '';
        this.state.video3tos3FileName = '';
        this.state.video3tos3FileHandle = '';
        this.state.video1 = '';
        this.state.video2 = '';
        this.state.video3 = '';
        this.state.image1 = '';
        this.state.image2 = '';
        this.state.image3 = '';
        this.state.image1FileHandle = '';
        this.state.image2FileHandle = '';
        this.state.image3FileHandle = '';
        this.state.image1FileName = '';
        this.state.image2FileName = '';
        this.state.image3FileName = '';
        this.state.file1progresstext = '';
        this.state.file2progresstext = '';
        this.state.file3progresstext = '';
        this.setState({ file1progress: 0 });
        this.setState({ file2progress: 0 });
        this.setState({ file3progress: 0 });
        this.state.file1FileName = ' ';
        this.state.file1FileHandle = '';
        this.state.file2FileName = ' ';
        this.state.file2FileHandle = '';
        this.state.file3FileName = ' ';
        this.state.file3FileHandle = '';
        this.state.image1Url = '';
        this.state.image2Url = '';
        this.state.image3Url = '';
        this.state.meeting1 = '';
        this.state.meeting2 = '';
        this.state.meeting3 = '';
        this.state.data1 = '';
        this.state.data2 = '';
        this.state.data3 = '';
        this.state.data4 = '';
        this.state.data5 = '';
        this.state.schedule = '';


        this.forceUpdate();
    }

    checkEvent() {
        const { code } = this.state;
        const apiName = serviceNames.getEventPlannerService();
        const path = ''; // replace this with the path you have configured on your API
        const myInit = {
            body: {
                'code': code,
                'command': 'getEventConfig',
            },
            response: true,
            convertEmptyValues: true,
            headers: {},
        };
        // this.setState({ disabled:true });
        //console.log(myInit);
        API.post(apiName, path, myInit).then(response => {
           //console.log(response);

            if (response.err == null && response.data != null) {

                let eventObj = response.data.data
               //console.log(eventObj);
                this.state.exhibitorcategories = eventObj.exhibitorcategories.S

            } else {
                //console.log("checkEvent - failed!!");
                //console.log(response.err);
                this.state.message = "Unable to access event. Please check Customer ID entered."
                // this.setState({ disabled: false });
                // this.setState({ isLoading: false });
                //this.forceUpdate();                    
            }
        }
        ).catch(error => {
           //console.log(error)
            //   this.setState({ disabled: false })
            //   this.setState({ isLoading: false })
            this.setState({ message: "Unable to access." })
        });
    }

    submitProfile(event) {
        let apiName = serviceNames.getEventPlannerService();
        let path = ''; //replace this with the path you have configured on your API
        var date = new Date();
        var longFormat = date / 1000;

        if (this.state.booth == "") {
            this.state.booth = " ";
        }
        if (this.state.splevel == "") {
            this.state.splevel = " ";
        }
        if (this.state.desc == "") {
            this.state.desc = " ";
        }
        if (this.state.social1 == "") {
            this.state.social1 = " ";
        }
        if (this.state.social2 == "") {
            this.state.social2 = " ";
        }
        if (this.state.social3 == "") {
            this.state.social3 = " ";
        }
        if (this.state.social4 == "") {
            this.state.social4 = " ";
        }
        if (this.state.social5 == "") {
            this.state.social5 = " ";
        }
        if (this.state.social6 == "") {
            this.state.social6 = " ";
        }
        if (this.state.contact == "") {
            this.state.contact = " ";
        }
        if (this.state.title == "") {
            this.state.title = " ";
        }
        if (this.state.phone == "") {
            this.state.phone = " ";
        }
        if (this.state.email == "") {
            this.state.email = " ";
        }
        if (this.state.selectedCategory == "") {
            this.state.selectedCategory = " ";
        }

        if (this.state.selectedCategory == "select") {
            this.state.selectedCategory = " ";
        }

        if (this.state.category1 == "") {
            this.state.category1 = " ";
        }

        if (this.state.logoFileName == ".png") {
            this.state.logoFileName = this.state.id + ".png"
        }
        if (this.state.logoFileName == "") {
            this.state.logoFileName = "blank.png";
        }
        if (this.state.logoFileHandle == "") {
            this.state.logoFileHandle = "blank.png";
        }
        if (this.state.file1FileName == "") {
            this.state.file1FileName = " ";
        }
        if (this.state.file2FileName == "") {
            this.state.file2FileName = " ";
        }
        if (this.state.file3FileName == "") {
            this.state.file3FileName = " ";
        }
        if (this.state.url == "") {
            this.state.url = " ";
        }
        if (this.state.street == "") {
            this.state.street = " ";
        }
        if (this.state.city == "") {
            this.state.city = " ";
        }
        if (this.state.state == "") {
            this.state.state = " ";
        }
        if (this.state.zip == "") {
            this.state.zip = " ";
        }
        if (this.state.country == "") {
            this.state.country = " ";
        }
        if (this.state.keywords == "") {
            this.state.keywords = " ";
        }
        if (this.state.tag == "") {
            this.state.tag = " ";
        }
        if (this.state.submittedby == "") {
            this.state.submittedby = this.state.userid;
        }
        if (this.state.createdby == "") {
            this.state.createdby = this.state.userid;
        }
        if (this.state.auth == "") {
            this.state.auth = " ";
        }
        if (this.state.points == "") {
            this.state.points = "10";
        }

        this.state.desc = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));

        if (this.state.selectedEtype == "") {
            this.state.selectedEtype = "D"
        }
        if (this.state.sortorder == "") {
            this.state.sortorder = "0"
        }
        if (this.state.videotos3FileName == "") {
            this.state.videotos3FileName = " ";
        }

        if (this.state.videourl == "") {
            this.state.videourl = " ";
        }

        if (this.state.video1 == "") {
            this.state.video1 = " ";
        }

        if (this.state.video2 == "") {
            this.state.video2 = " ";
        }
        if (this.state.video3 == "") {
            this.state.video3 = " ";
        }
        if (this.state.image1FileName == "") {
            this.state.image1FileName = " ";
        }
        if (this.state.image2FileName == "") {
            this.state.image2FileName = " ";
        }
        if (this.state.image3FileName == "") {
            this.state.image3FileName = " ";
        }
        if (this.state.data1 == "") {
            this.state.data1 = " ";
        }
        if (this.state.data2 == "") {
            this.state.data2 = " ";
        }
        if (this.state.data3 == "") {
            this.state.data3 = " ";
        }
        if (this.state.data4 == "") {
            this.state.data4 = " ";
        }
        if (this.state.data5 == "") {
            this.state.data5 = " ";
        }
        if (this.state.schedule == "") {
            this.state.schedule = " ";
        }
        if (this.state.meeting1 == "") {
            this.state.meeting1 = " ";
        }
        if (this.state.meeting2 == "") {
            this.state.meeting2 = " ";
        }
        if (this.state.meeting3 == "") {
            this.state.meeting3 = " ";
        }

        if (this.state.videourl == "") {
            this.state.videourl = " ";
        }
        if (this.state.selected == "") {
            this.state.selected = " "
        }

        let myInit =
        {
            body:
            {
                'code': this.state.code,
                'command': 'postExhibitorProfile',
                'type': this.state.type,
                'etype': this.state.selectedEtype,
                'company': this.state.company,
                'url': this.state.url,
                'country': this.state.country,
                'state': this.state.state,
                'city': this.state.city,
                'street': this.state.street,
                'zip': this.state.zip,
                'booth': this.state.booth,
                'category': this.state.selected,
                //'category1': this.state.category1,
                'category1': this.state.selected1,
                'splevel': this.state.splevel,
                'logo': this.state.logoFileName,
                'contact': this.state.contact,
                'title': this.state.title,
                'phone': this.state.phone,
                'email': this.state.email,
                'social1': this.state.social1,
                'social2': this.state.social2,
                'social3': this.state.social3,
                'social4': this.state.social4,
                'social5': this.state.social5,
                'social6': this.state.social6,
                'udate': longFormat.toString(),
                'cdate': longFormat.toString(),
                'desc': this.state.desc,
                'id': this.state.id,
                'keywords': this.state.keywords,
                'tag': this.state.tag,
                'submittedby': this.state.submittedby,
                'createdby': this.state.createdby,
                'adsrc': ' ',
                'adfile': ' ',
                'auth': this.state.auth,
                'points': this.state.points,
                'msg1': ' ',
                'msg2': ' ',
                'sortorder': this.state.sortorder,
                'videourl': this.state.videourl,
                'video1': this.state.video1,
                'video2': this.state.video2,
                'video3': this.state.video3,
                'image1': this.state.image1FileName,
                'image2': this.state.image2FileName,
                'image3': this.state.image3FileName,
                'file1': this.state.file1FileName,
                'file2': this.state.file2FileName,
                'file3': this.state.file3FileName,
                'meeting1': this.state.meeting1,
                'meeting2': this.state.meeting2,
                'meeting3': this.state.meeting3,
                'data1': this.state.data1,
                'data2': this.state.data2,
                'data3': this.state.data3,
                'data4': this.state.data4,
                'data5': this.state.data5,
                'schedule': this.state.schedule,
                "updateexhibitor": String(this.state.updateexhibitor),
                "userid": this.state.userid
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        console.log(myInit);
        this.setState({ disabled: true });
        
        API.post(apiName, path, myInit).then(response => {
            this.setState({ disabled: false });

            if (response.err == null) {
                
                this.state.message = "Profile successfully submitted";
                global.$companyid = String(this.state.id);
                this.setState({ isLoading: false });

                global.$exhibitorid = this.state.id
                global.$company = this.state.company
                global.$exhibitoremail = this.state.email

                this.productsRef.current.handleSubmit(event);
                this.getCategory();
                this.getCategoryProfileTypes();
                this.getCategoryAdSrc();
                this.forceUpdate();
                
                setTimeout(() => {
                    this.setState({ message: '' });
                }, 4000);
            }
            else {
                this.state.message = "Error!! Unable to submit profile. Please try again. "
                this.setState({ isLoading: false });
                this.forceUpdate();
            }

        }).catch(error => {
            this.setState({ disabled: false });
        });
    }

   async copyFiles(file) {
        try {
          let apiName = serviceNames.getUserService();
          let path = ""; //replace this with the path you have configured on your API
      
          let myInit = {
            body: {
              code: this.state.code,
              command: "copyS3Files",
              srcbucket: "event360live",
              desbucket: "wesicore",
              fileFrom: "exhibitors",
              fileTo: "upload/documents",
              photo: file,
            },
            response: true,
            headers: {},
          };
          let result = await API.post(apiName, path, myInit);

          return result;
        } catch (error) {
          console.log(error)
        }
    }

    uploadFile1 = (event) => {
        const target = event.target;
        const name = target.name;
        let value = target.value

        if (name === 'file1') {
            this.state.file1FileHandle = event.target.files[0];
            this.setState({ file1progress: 25 });
            this.setState({ file1progresstext: "Uploading..." });
            this.state.file1FileName = this.getExhibitorFile1FileName(this.state.id, this.state.file1FileHandle.name);
        }
        else {
            this.setState(
                {
                    [name]: value
                }
            );
        }


        const s3File = this.getS3ExhibitorFile1FileName();
        var extension = this.state.file1FileName.substring(this.state.file1FileName.lastIndexOf('.') + 1);

        Storage.put(s3File, this.state.file1FileHandle,
            {
                contentType: 'application/' + extension
            })
            .then(result => {
                    this.setState({ file1progress: 100 });
                    this.setState({ file1progresstext: "Presentation1 uploaded successfully" });
                    this.file1.value = "";

                    // DOWNLOAD COPY OF THE UPLOADED FILE
                    this.copyFiles(this.state.file1FileName);
                    this.forceUpdate();
                }
            )
            .catch(err => {
                this.state.message = "Unable to upload file. Please try again"
                this.forceUpdate();
            }
            );
    }
    uploadFile2 = (event) => {
        const target = event.target;
        const name = target.name;
        let value = target.value

        if (name === 'file2') {
            this.state.file2FileHandle = event.target.files[0];
            this.setState({ file2progress: 25 });
            this.setState({ file2progresstext: "Uploading..." });
            this.state.file2FileName = this.getExhibitorFile2FileName(this.state.id, this.state.file2FileHandle.name);
        }
        else {
            this.setState(
                {
                    [name]: value
                }
            );
        }

        const s3File = this.getS3ExhibitorFile2FileName();
        var extension = this.state.file2FileName.substring(this.state.file2FileName.lastIndexOf('.') + 1);
       
        Storage.put(s3File, this.state.file2FileHandle,
            {
                contentType: 'application/' + extension
            })
            .then(
                result => {
                    this.setState({ file2progress: 100 });
                    this.setState({ file2progresstext: "Presentation2 uploaded successfully" });
                    this.file2.value = "";

                    // DOWNLOAD COPY OF THE UPLOADED FILE
                    this.copyFiles(this.state.file2FileName);
                    this.forceUpdate();
                }
            )
            .catch(err => {
                this.setState({ file2progresstext: "Unable to upload file. Please try again" });
                this.forceUpdate();
            }
            );

    }
    uploadFile3 = (event) => {
        const target = event.target;
        const name = target.name;
        let value = target.value

        if (name === 'file3') {
            this.state.file3FileHandle = event.target.files[0];
            this.setState({ file3progress: 25 });
            this.setState({ file3progresstext: "Uploading..." });
            this.state.file3FileName = this.getExhibitorFile3FileName(this.state.id, this.state.file3FileHandle.name);
        }
        else {
            this.setState(
                {
                    [name]: value
                }
            );
        }

        const s3File = this.getS3ExhibitorFile3FileName();
        var extension = this.state.file3FileName.substring(this.state.file3FileName.lastIndexOf('.') + 1);

        Storage.put(s3File, this.state.file3FileHandle,
            {
                contentType: 'application/' + extension
            })
            .then(result => {
                    this.setState({ file3progress: 100 });
                    this.setState({ file3progresstext: "Presentation3 uploaded successfully" });
                    this.file1.value = "";

                    // DOWNLOAD COPY OF THE UPLOADED FILE
                    this.copyFiles(this.state.file3FileName);
                    this.forceUpdate();
                }
            )
            .catch(err => {
                this.setState({ file3progresstext: "Unable to upload file. Please try again" });
                this.forceUpdate();
            }
            );
    }
    uploadVideoToS3 = (event) => {
        const target = event.target;
        const name = target.name;
        let value = target.value

        if (name === 'videos3') {
            this.state.videotos3FileHandle = event.target.files[0];
            this.setState({ videos3progress: 25 });
            this.setState({ videos3progresstext: "Uploading..." });

            var date = new Date();
            var longFormat = date / 1000;
            var id = longFormat.toString();
            ////console.log("file3FileName = " + this.state.file3FileHandle.name)
            this.state.videotos3FileName = this.getVideoS3FileName(id, this.state.videotos3FileHandle.name);
        }
        else {
            this.setState(
                {
                    [name]: value
                }
            );
        }

        const s3File = this.getS3VideoFileName();
        ////console.log('Uploading File3 = ' + this.state.file3FileHandle.name +" , Des = " + s3File );      
        Storage.put(s3File, this.state.videotos3FileHandle,
            {

                contentType: '/\Avideo/'
            })

            .then(
                result => {
                    //////console.log(result);
                    this.setState({ videos3progress: 100 });
                    this.setState({ videos3progresstext: "Video uploaded successfully" });
                    //this.state.message = "postsentation3 uploaded successfully"

                    this.setState({ videourl: "https://event360live.s3.amazonaws.com/public/" + this.state.code + "/exhibitors/" + this.state.videotos3FileName })
                    this.forceUpdate();
                }
            )
            .catch(err => {
                //////console.log(err)
                this.setState({ videos3progresstext: "Unable to upload Video. Please try again" });
                //this.state.message = "Unable to upload file. Please try again"
                this.forceUpdate();
            }
            );
    }

    uploadVideo1ToS3 = (event) => {
        const target = event.target;
        const name = target.name;
        let value = target.value

        if (name === 'video1s3') {
            this.state.video1tos3FileHandle = event.target.files[0];
            this.setState({ video1s3progress: 25 });
            this.setState({ video1s3progresstext: "Uploading..." });

            var date = new Date();
            var longFormat = date / 1000;
            var id = longFormat.toString();
            ////console.log("file3FileName = " + this.state.file3FileHandle.name)
            this.state.video1tos3FileName = this.getVideo1S3FileName(id, this.state.video1tos3FileHandle.name);
        }
        else {
            this.setState(
                {
                    [name]: value
                }
            );
        }

        const s3File = this.getS3Video1FileName();
        ////console.log('Uploading File3 = ' + this.state.file3FileHandle.name +" , Des = " + s3File );      
        Storage.put(s3File, this.state.video1tos3FileHandle,
            {

                contentType: '/\Avideo/'
            })

            .then(
                result => {
                    //////console.log(result);
                    this.setState({ video1s3progress: 100 });
                    this.setState({ video1s3progresstext: "Video uploaded successfully" });
                    //this.state.message = "postsentation3 uploaded successfully"

                    this.setState({ video1: "https://event360live.s3.amazonaws.com/public/" + this.state.code + "/exhibitors/" + this.state.video1tos3FileName })
                    this.forceUpdate();
                }
            )
            .catch(err => {
                //////console.log(err)
                this.setState({ video1s3progresstext: "Unable to upload Video. Please try again" });
                //this.state.message = "Unable to upload file. Please try again"
                this.forceUpdate();
            }
            );
    }
    uploadVideo2ToS3 = (event) => {
        const target = event.target;
        const name = target.name;
        let value = target.value

        if (name === 'video2s3') {
            this.state.video2tos3FileHandle = event.target.files[0];
            this.setState({ video2s3progress: 25 });
            this.setState({ video2s3progresstext: "Uploading..." });

            var date = new Date();
            var longFormat = date / 1000;
            var id = longFormat.toString();
            ////console.log("file3FileName = " + this.state.file3FileHandle.name)
            this.state.video2tos3FileName = this.getVideo2S3FileName(id, this.state.video2tos3FileHandle.name);
        }
        else {
            this.setState(
                {
                    [name]: value
                }
            );
        }

        const s3File = this.getS3Video2FileName();
        ////console.log('Uploading File3 = ' + this.state.file3FileHandle.name +" , Des = " + s3File );      
        Storage.put(s3File, this.state.video2tos3FileHandle,
            {

                contentType: '/\Avideo/'
            })

            .then(
                result => {
                    //////console.log(result);
                    this.setState({ video2s3progress: 100 });
                    this.setState({ video2s3progresstext: "Video uploaded successfully" });
                    //this.state.message = "postsentation3 uploaded successfully"

                    this.setState({ video2: "https://event360live.s3.amazonaws.com/public/" + this.state.code + "/exhibitors/" + this.state.video2tos3FileName })
                    this.forceUpdate();
                }
            )
            .catch(err => {
                //////console.log(err)
                this.setState({ video2s3progresstext: "Unable to upload Video. Please try again" });
                //this.state.message = "Unable to upload file. Please try again"
                this.forceUpdate();
            }
            );
    }
    uploadVideo3ToS3 = (event) => {
        const target = event.target;
        const name = target.name;
        let value = target.value

        if (name === 'video3s3') {
            this.state.video3tos3FileHandle = event.target.files[0];
            this.setState({ video3s3progress: 25 });
            this.setState({ video3s3progresstext: "Uploading..." });

            var date = new Date();
            var longFormat = date / 1000;
            var id = longFormat.toString();
            ////console.log("file3FileName = " + this.state.file3FileHandle.name)
            this.state.video3tos3FileName = this.getVideo3S3FileName(id, this.state.video3tos3FileHandle.name);
        }
        else {
            this.setState(
                {
                    [name]: value
                }
            );
        }

        const s3File = this.getS3Video3FileName();
        ////console.log('Uploading File3 = ' + this.state.file3FileHandle.name +" , Des = " + s3File );      
        Storage.put(s3File, this.state.video3tos3FileHandle,
            {

                contentType: '/\Avideo/'
            })

            .then(
                result => {
                    //////console.log(result);
                    this.setState({ video3s3progress: 100 });
                    this.setState({ video3s3progresstext: "Video uploaded successfully" });
                    //this.state.message = "postsentation3 uploaded successfully"

                    this.setState({ video3: "https://event360live.s3.amazonaws.com/public/" + this.state.code + "/exhibitors/" + this.state.video3tos3FileName })
                    this.forceUpdate();
                }
            )
            .catch(err => {
                //////console.log(err)
                this.setState({ video3s3progresstext: "Unable to upload Video. Please try again" });
                //this.state.message = "Unable to upload file. Please try again"
                this.forceUpdate();
            }
            );
    }


    getCategoriesTableObjects(objs) {
        //////console.log(objs);
        const tableObjs = []

        for (var i = 0; i < objs.length; i++) {
            var categoryObj = objs[i]

            //////console.log( categoryObj)

            var newObj = Object()

            newObj.type = categoryObj.type.S
            newObj.name = categoryObj.name.S

            /*tableObjs.push(<option key={newObj.name} value={newObj.name} >{newObj.name}</option>)*/

            var options = []
            options = { value: newObj.name, label: newObj.name }

            tableObjs.push(options);
        }

        //console.log(tableObjs)

        return tableObjs
    }

    getCategory() {
        let apiName = serviceNames.getEventPlannerService();
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'code': this.state.code,
                'command': 'getCategory',
                'type': 'exhibitor'
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //////console.log(myInit);

        API.post(apiName, path, myInit).then(response => {
            ////console.log (response.data);

            if (response.err == null && response.data != null) {
                //////console.log(response.data.Items);

                let categoryObj = this.getCategoriesTableObjects(response.data.data.Items)
                ////////console.log(categoryObj);
                //this.state.category = categoryObj.name
                this.setState({ category: categoryObj })
                //////console.log(this.state.category);
                this.setState({ dataloaded: true })
                this.getStaffingCategory();
            }
            else {
                //////console.log("getCategoriesTableObjects - failed!!");
                //////console.log(response.err);

            }

        }
        ).catch(error => {
            //////console.log(error.response)
        });
    }

    getStaffingCategoriesObjects(items){
        const tableObjs = []
        const arr = items[0].title.S.split("~~");

        for (var i = 0; i < arr.length; i++) {

            //////console.log( categoryObj)

            var newObj = Object()

            newObj.name = arr[i]

            /*tableObjs.push(<option key={newObj.name} value={newObj.name} >{newObj.name}</option>)*/

            var options = []
            options = { value: newObj.name, label: newObj.name }

            tableObjs.push(options);
        }

        //console.log(tableObjs)

        return tableObjs
    }

    getStaffingCategory() {
        let apiName = serviceNames.getEventPlannerService();
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'code': this.state.code,
                'command': 'getCategory',
                'type': 'exhibitor-sub-categories'
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //////console.log(myInit);

        API.post(apiName, path, myInit).then(response => {
            //console.log (response.data);

            if (response.err == null && response.data != null) {
                //////console.log(response.data.Items);

                let categoryObj = this.getStaffingCategoriesObjects(response.data.data.Items)
                ////////console.log(categoryObj);
                //this.state.category = categoryObj.name
                this.setState({ staffingcategory: categoryObj })
                //////console.log(this.state.category);
                this.setState({ dataloaded: true })
            }
            else {
                //////console.log("getCategoriesTableObjects - failed!!");
                //////console.log(response.err);

            }

        }
        ).catch(error => {
            //////console.log(error.response)
        });        
    }

    getTermsCategoryObject(objs) {
        var obj = objs[0]
        //////console.log( obj)

        var newObj = Object()

        newObj.name = obj.name.S
        newObj.type = obj.type.S

        //////console.log(newObj)
        return newObj
    }

    getTermsConditionCategory() {
        let apiName = serviceNames.getEventPlannerService();
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'code': this.state.code,
                'command': 'getCategory',
                'type': 'exhibitor-terms'
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //////console.log(myInit);

        API.post(apiName, path, myInit).then(response => {
            ////console.log (response.data);

            if (response.err == null && response.data != null) {
                //////console.log(response.data.Items);

                let categoryObj = this.getTermsCategoryObject(response.data.data.Items)
                //////console.log(categoryObj);
                //this.state.category = categoryObj.name
                this.setState({ termsandconditons: categoryObj.name })
                //////console.log(this.state.termsandconditons);
                this.setState({ dataloaded: true })
            }
            else {
                //////console.log("getCategoriesTableObjects - failed!!");
                //////console.log(response.err);

            }

        }
        ).catch(error => {
            //////console.log(error.response)
        });
    }

    getExhibitor() {

        
        if (/Sponsor/.test(global.$userstype)) {
            this.state.type = "S"
        }

        let apiName = serviceNames.getEventPlannerService();
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'code': this.state.code,
                'command': 'getLoggedinExhibitorById',
                'companyid': this.state.companyid,
                'type': this.state.type
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //console.log(myInit);

        API.post(apiName, path, myInit).then(response => {
           //console.log(response);

            if (response.err == null && response.data != null) {
            //console.log(response.data);

                let exhibitorObj = this.getExhibitorObject(response.data.data.Items)

                this.setState({
                    id: exhibitorObj.id,
                    company: exhibitorObj.company,
                    url: exhibitorObj.url,
                    logoFileName: exhibitorObj.logo,
                    logoFileHandle: exhibitorObj.logo,
                    country: exhibitorObj.country,
                    street: exhibitorObj.street,
                    city: exhibitorObj.city,
                    state: exhibitorObj.state,
                    zip: exhibitorObj.zip,
                    booth: exhibitorObj.booth,
                    selected: exhibitorObj.category,
                    category1: exhibitorObj.category1,
                    selected1: exhibitorObj.category1,
                    splevel: exhibitorObj.splevel,
                    contact: exhibitorObj.contact,
                    title: exhibitorObj.title,
                    email: exhibitorObj.email,
                    social1: exhibitorObj.social1,
                    social2: exhibitorObj.social2,
                    social3: exhibitorObj.social3,
                    social4: exhibitorObj.social4,
                    social5: exhibitorObj.social5,
                    social6: exhibitorObj.social6,
                    phone: exhibitorObj.phone,
                    cdate: exhibitorObj.cdate,
                    udate: exhibitorObj.udate,
                    desc: exhibitorObj.desc,
                    type: exhibitorObj.type,
                    selectedEtype: exhibitorObj.etype,
                    keywords: exhibitorObj.keywords,
                    tag: exhibitorObj.tag,
                    submittedby: exhibitorObj.submittedby,
                    auth: exhibitorObj.auth,
                    points: exhibitorObj.points,
                    createdby: exhibitorObj.createdby,
                    sortorder: exhibitorObj.sortorder,
                    videourl: exhibitorObj.videourl,
                    video1: exhibitorObj.video1,
                    video2: exhibitorObj.video2,
                    video3: exhibitorObj.video3,
                    image1FileName: exhibitorObj.image1,
                    image2FileName: exhibitorObj.image2,
                    image3FileName: exhibitorObj.image3,
                    file1FileName: exhibitorObj.file1,
                    file2FileName: exhibitorObj.file2,
                    file3FileName: exhibitorObj.file3,
                    meeting1: exhibitorObj.meeting1,
                    meeting2: exhibitorObj.meeting2,
                    meeting3: exhibitorObj.meeting3,
                    data1: exhibitorObj.data1,
                    data2: exhibitorObj.data2,
                    data3: exhibitorObj.data3,
                    data4: exhibitorObj.data4,
                    data5: exhibitorObj.data5,
                    schedule: exhibitorObj.schedule,
                    dataloaded: true,
                    imageUrl: `https://s3.amazonaws.com/event360live/public/${this.state.code}/exhibitors/${exhibitorObj.logo}?${new Date().getTime()}`,
                    image1Url: `https://s3.amazonaws.com/event360live/public/${this.state.code}/exhibitors/${exhibitorObj.image1}?${new Date().getTime()}`,
                    image2Url: `https://s3.amazonaws.com/event360live/public/${this.state.code}/exhibitors/${this.state.image2FileName}?${new Date().getTime()}`,
                    image3Url: `https://s3.amazonaws.com/event360live/public/${this.state.code}/exhibitors/${this.state.image3FileName}?${new Date().getTime()}`
                })
                
                global.$exhibitorid = exhibitorObj.id
                global.$company = exhibitorObj.company
                global.$exhibitoremail = exhibitorObj.email

                if (this.productsRef.current != null) {
                    this.productsRef.current.getproducts();
                }
                

                //console.log(this.state.imageUrl);
                //////////////////////////////////
                //
                //      Edior State Conversion
                //
                //////////////////////////////////
                const html = this.state.desc;
                //console.log(this.state.rawDraftContentState)
                
                const blocksFromHTML = htmlToDraft(html);

                const contentstate = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks);

                this.setState({ editorState: EditorState.createWithContent(contentstate) });

                this.onEditorStateChange = (editorState) => {
                    this.setState({ editorState });
                }
                //console.log(this.state.selected);
               

                if (this.state.selected != " ") {
                    var array = this.state.selected.split(",")
                    var value = [];
                    //array.forEach(function(item)
                    for (var i = 0; i <= array.length; i++) {
                        //console.log(array[i])

                        if (array[i] == undefined) {
                            //console.log(this.state.selectedCategory)    
                        }
                        else {
                            var newObj = Object();

                            newObj = { value: array[i], label: array[i] }

                            //console.log(newObj)

                            value.push(newObj);
                            //console.log(value)
                        }

                        this.state.selectedCategory = value;
                        //console.log(this.state.selectedCategory)        
                    }
                }
                else {
                    this.setState({ selected: "" })
                }

                if (this.state.selected1 != " ") {
                    var array = this.state.selected1.split("~~")
                    var value = [];
                    //array.forEach(function(item)
                    for (var i = 0; i <= array.length; i++) {
                        //console.log(array[i])

                        if (array[i] == undefined) {
                            //console.log(this.state.selectedCategory)    
                        }
                        else {
                            if(array[i] != "") {
                                var newObj = Object();

                                newObj = { value: array[i], label: array[i] }
    
                                //console.log(newObj)
    
                                value.push(newObj);
                                //console.log(value)
                            }
                        }

                        this.state.selectedCategory1 = value;
                        //console.log(this.state.selectedCategory)        
                    }
                }
                else {
                    this.setState({ selected1: "" })
                }
                

            }
            else {
                //////console.log("getExhibitorsTableObjects - failed!!");
                //////console.log(response.err);

            }

        }
        ).catch(error => {
           //console.log(error)
        });
    }

    getExhibitorObject(objs) {
        var obj = objs[0]
        ////console.log( obj)

        var newObj = Object()

        newObj.id = obj.id.S
        newObj.type = obj.type.S
        newObj.etype = obj.etype.S
        newObj.logo = obj.logo.S
        newObj.company = obj.company.S
        newObj.street = obj.street.S
        newObj.city = obj.city.S
        newObj.state = obj.state.S
        newObj.zip = obj.zip.S
        newObj.booth = obj.booth.S
        newObj.category = obj.category.S
        newObj.category1 = obj.category1.S
        newObj.splevel = obj.splevel.S
        newObj.contact = obj.contact.S
        newObj.phone = obj.phone.S
        newObj.title = obj.title.S
        newObj.email = obj.email.S
        newObj.country = obj.country.S
        newObj.state = obj.state.S
        newObj.desc = obj.desc.S
        newObj.udate = obj.udate.N
        newObj.cdate = obj.cdate.N
        newObj.url = obj.url.S
        newObj.keywords = obj.keywords.S
        newObj.tag = obj.tag.S
        newObj.submittedby = obj.submittedby.S
        newObj.auth = obj.auth.S
        newObj.points = obj.points.N
        newObj.createdby = obj.createdby.S
        newObj.sortorder = obj.sortorder.N
        newObj.videourl = obj.videourl.S
        newObj.video1 = obj.video1.S
        newObj.video2 = obj.video2.S
        newObj.video3 = obj.video3.S
        newObj.image1 = obj.image1.S
        newObj.image2 = obj.image2.S
        newObj.image3 = obj.image3.S
        newObj.file1 = obj.file1.S
        newObj.file2 = obj.file2.S
        newObj.file3 = obj.file3.S
        newObj.meeting1 = obj.meeting1.S
        newObj.meeting2 = obj.meeting2.S
        newObj.meeting3 = obj.meeting3.S
        newObj.data1 = obj.data1.S
        newObj.data2 = obj.data2.S
        newObj.data3 = obj.data3.S
        newObj.data4 = obj.data4.S
        newObj.data5 = obj.data5.S
        newObj.schedule = obj.schedule.S
        if(obj.social1 == null)
        {
          newObj.social1 = " "
        }
        else{
        newObj.social1 = obj.social1.S;
        }
        if(obj.social2 == null)
        {
          newObj.social2 = " "
        }
        else{
          newObj.social2 = obj.social2.S;
          }
        if(obj.social3 == null)
        {
          newObj.social3 = " "
        }
        else{
          newObj.social3 = obj.social3.S;
          }
        if(obj.social4 == null)
        {
          newObj.social4 = " "
        }
        else{
          newObj.social4 = obj.social4.S;
          }
        if(obj.social5 == null)
        {
          newObj.social5 = " "
        }
        else{
          newObj.social5 = obj.social5.S;
          }
        if(obj.social6 == null)
        {
          newObj.social6 = " "
        }
        else{
          newObj.social6 = obj.social6.S;
          }

        //////console.log(newObj)
        //////console.log(newObj.category);

        return newObj
    }


    getCategoriesTableObjectsForProfileTypes(objs) {
        //////console.log(objs);
        const tableObjs = []

        for (var i = 0; i < objs.length; i++) {
            var categoryObj = objs[i]

            //////console.log( categoryObj)

            var newObj = Object()

            newObj.type = categoryObj.type.S
            newObj.name = categoryObj.name.S
            newObj.title = categoryObj.title.S

            /* name = name.replace(/[01-99]/g, '');
             name = name.replace(/-/g, '');
             */

            tableObjs.push(<option key={newObj.name} value={newObj.name} >{newObj.title}</option>)
        }

        //////console.log(tableObjs)

        return tableObjs
    }
    getCategoryProfileTypes() {
        let apiName = serviceNames.getEventPlannerService();
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'code': this.state.code,
                'command': 'getCategory',
                'type': 'eprofile-type'
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //////console.log(myInit);

        API.post(apiName, path, myInit).then(response => {
            ////console.log (response.data);

            if (response.err == null && response.data != null) {
                //////console.log(response.data.Items);

                let categoryObj = this.getCategoriesTableObjectsForProfileTypes(response.data.data.Items)

                this.setState({ etype: categoryObj })
                this.setState({ dataloaded: true })

            }
            else {
                //////console.log("getCategoriesTableObjects - failed!!");
                //////console.log(response.err);
            }

        }
        ).catch(error => {
            //////console.log(error.response)
        });
    }

    getCategoriesTableObjectsForAdSrc(objs) {
        //////console.log(objs);
        const tableObjs = []

        for (var i = 0; i < objs.length; i++) {
            var categoryObj = objs[i]

            //////console.log( categoryObj)

            var newObj = Object()

            newObj.type = categoryObj.type.S
            newObj.name = categoryObj.name.S
            newObj.title = categoryObj.title.S

            /* name = name.replace(/[01-99]/g, '');
             name = name.replace(/-/g, '');
             */

            tableObjs.push(<option key={newObj.name} value={newObj.name} >{newObj.title}</option>)
        }

        //////console.log(tableObjs)

        return tableObjs
    }
    getCategoryAdSrc() {
        let apiName = serviceNames.getEventPlannerService();
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'code': this.state.code,
                'command': 'getCategory',
                'type': 'exhibitor-adsrc'
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //////console.log(myInit);

        API.post(apiName, path, myInit).then(response => {
            ////console.log (response.data);

            if (response.err == null && response.data != null) {
                //////console.log(response.data.Items);

                let categoryObj = this.getCategoriesTableObjectsForAdSrc(response.data.data.Items)

                this.setState({ adsrc: categoryObj })
                this.setState({ dataloaded: true })

            }
            else {
                //////console.log("getCategoriesTableObjects - failed!!");
                //////console.log(response.err);
            }

        }
        ).catch(error => {
            //////console.log(error.response)
        });
    }

}

export default SponsorSubmitForm;


