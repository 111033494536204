import React from 'react';
import { Row, Col, Button } from 'reactstrap';

class AdsLayoutInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div className="ads-popup-maindiv">
                <Row>
                    {this.displayAds()}
                </Row>
            </div>
        )
    }

    displayAds() {
        var image = this.props.image;
     
        var styles = global.$config['adsscroll'].styles;
        //console.log(image);
        return (
            <Col md={12} key={image.altText}>
                <div className="ads-layout-div" style={styles.maindiv}>
                    <div className="ads-layout-img-div" style={styles.imgdiv}>
                        <a href={image.link} target="_blank"><img src={image.src} className="ads-layout-img" style={styles.img} /></a>
                    </div>
                </div>
            </Col>
        )
    }

}
export default AdsLayoutInfo;
