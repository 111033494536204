/* eslint-disable */
import React, { Component } from 'react';
import { FormGroup, Label, Input, FormText, Card, CardHeader, CardBody, Row, Col, Container, Form, Button } from 'reactstrap';
import Modal from "react-responsive-modal";
import { Storage, API } from 'aws-amplify';
import { Link } from "react-router-dom";
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { EditorState, contentState, convertToRaw, convertFromRaw, convertFromHTML, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
//import { stateToHTML } from "draft-js-export-html";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Select from 'react-select';
import Icon from '@mdi/react';
import { Spinner, Toast } from 'react-bootstrap';
import { mdiGoogleStreetView, mdiMapMarkerMultiple, mdiZipBox, mdiDomain, mdiImage, mdiMessageText, mdiCellphoneIphone, mdiWeb, mdiFormatTitle, mdiFormatListBulletedType, mdiSort, mdiEmail, mdiAccount, mdiAccountMultiple, mdiHomeGroup, mdiKeyboardBackspace, mdiFileDocument, mdiMapMarker, mdiKeyPlus, mdiAccessPoint, mdiBook, mdiLink, mdiVideo, mdiDatabase, mdiCalendar, mdiFile, mdiIdCard, mdiKey, mdiAccountTie, mdiPhone, mdiMap, mdiGlobeModel, mdiIdentifier, mdiRegisteredTrademark } from '@mdi/js';
import '../SubmissionForms/SubmitForms.css';
var serviceNames = require('../ServiceNameMapper');
var passwordValidation = "Make sure password must be greater than or equal to 8 characters";
var successChangePasswordMessage = "Password successfully changed";
var failedChangePasswordMessage = "Unable to change password";
var formStatus = '';
var regtype = [{ value: 'Exhibitor Representative', label: 'Exhibitor Representative' }];

class RepresentativeEditor extends Component {
    render() {
        var form = global.$config["exhibitorsubmitform"].form;
        var styles = global.$config["exhibitorsubmitform"].styles;
        if (this.state.userid == "addnew") {
            this.state.userid = "";
        }
        return (
            <>
                <div className='container'>
                    <Row className="mt-5">
                        <Col>
                            <div className='back_btn'>
                                <Link type="button" to="/Representative" className="btn form-clear-btn"><Icon path={mdiKeyboardBackspace} size={0.9} color="" className="form-back-button-icon" /></Link>
                            </div>
                        </Col>
                    </Row>
                    <Card className="card_wrapper" style={{marginTop:'15px'}}>
                            <CardHeader className="form-header-1 card_header">Login Credentials</CardHeader>
                            <CardBody>
                                <Row >
                                    <Col>
                                        <FormGroup className="form-labels">
                                            <Label for="userid"><Icon path={mdiIdCard} size={0.9} color="lightgray" className="form-label-icon" />User ID*</Label>
                                            {
                                                this.state.createtype == "update" ?
                                                    (
                                                        <Input type="text" name="userid" className="form-control-sm" id="userid" value={this.state.userid || ''} onChange={this.handleInputChange} disabled />
                                                    )
                                                    :
                                                    (
                                                        <Input type="text" name="userid" className="form-control-sm" id="userid" value={this.state.userid || ''} onChange={this.handleInputChange} placeholder="Enter email id"/>
                                                    )
                                            }
                                            
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup className="form-labels" >
                                            <Label for="password" ><Icon path={mdiKey} size={0.9} color="lightgray" className="form-label-icon" />Password*</Label>
                                            <Input type="text" name="password" className="form-control-sm" id="password" value={this.state.password || ''} onChange={this.handleInputChange} placeholder="Password should be at least eight characters"/>
                                            
                                        </FormGroup>
                                    </Col>

                                    { 
                                        this.state.createtype == "update" ? 
                                        <Col md={2}>
                                            <div className="btn-group-password" role="group" aria-label="First group">
                                                <button type="button" className="btn change-password" onClick={this.handleChangePassword} >Reset Password</button>
                                            </div>
                                        </Col> : ("")
                                    }
                                </Row>
                            </CardBody>
                    </Card>   

                    <Card className="card_wrapper">
                        <CardHeader className="form-header-1 card_header">Profile</CardHeader>
                        <CardBody>
                            <Row >
                                <Col md={4}>
                                    <FormGroup className="form-labels">
                                        <Label for="fname"><Icon path={mdiAccountTie} size={0.9} color="lightgray" className="form-label-icon" />First Name*</Label>
                                        <Input type="text" name="fname" className="form-control-sm" id="fname" value={this.state.fname || ''} onChange={this.handleInputChange} />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup className="form-labels">
                                        <Label for="mname"><Icon path={mdiAccountTie} size={0.9} color="lightgray" className="form-label-icon" />Middle Name</Label>
                                        <Input type="text" name="mname" className="form-control-sm" id="mname" value={this.state.mname || ''} onChange={this.handleInputChange} />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup className="form-labels">
                                        <Label for="lname"><Icon path={mdiAccountTie} size={0.9} color="lightgray" className="form-label-icon" />Last Name*</Label>
                                        <Input type="text" name="lname" className="form-control-sm" id="lname" value={this.state.lname || ''} onChange={this.handleInputChange} />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup className="form-labels">
                                        <Label for="email"><Icon path={mdiEmail} size={0.9} color="lightgray" className="form-label-icon" />Email*</Label>
                                        <Input type="text" name="email" className="form-control-sm" id="email" value={this.state.email || ''} onChange={this.handleInputChange} />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup className="form-labels">
                                        <Label for="phone"><Icon path={mdiCellphoneIphone} size={0.9} color="lightgray" className="form-label-icon" />Phone</Label>
                                        <Input type="text" name="phone" className="form-control-sm" id="phone" value={this.state.phone || ''} onChange={this.handleInputChange} placeholder="Enter in NNN-NNN-NNNN format"/>
                                        
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup className="form-labels">
                                        <Label for="title"><Icon path={mdiFormatTitle} size={0.9} color="lightgray" className="form-label-icon" />Title / Designation</Label>
                                        <Input type="text" name="title" className="form-control-sm" id="title" value={this.state.title || ''} onChange={this.handleInputChange} />
                                    </FormGroup>
                                </Col>
                            </Row> 
                            <Row>
                                <FormGroup className="form-labels terms-group">
                                    <input type="checkbox" id="showdirectory" name="showdirectory" checked={this.state.showdirectory} value={this.state.showdirectory} onChange={this.handleCheckbox} />
                                    {ReactHtmlParser(this.state.termsdataforreps.question)}
                                </FormGroup>
                                    {
                                        this.state.enableterms == true && this.state.repregstatus == "new" && this.state.termsdata != "" ?
                                        (<FormGroup className="form-labels terms-group">
                                            <input type="checkbox" id="termsstatus" name="termsstatus" checked={this.state.termsstatus} value={this.state.termsstatus} onChange={this.handleCheckbox} />
                                            {ReactHtmlParser(this.state.termsdata.question)}
                                            
                                        </FormGroup>) : ("")                  
                                    }
                            </Row>
                        </CardBody>
                    </Card>     

                    <Card className="card_wrapper" hidden>
                                        <CardHeader className="form-header-1 card_header">Company Information</CardHeader>
                                        <CardBody >
                                            <Row>
                                                <Col md={6}>
                                                    <FormGroup className="form-labels">
                                                        <Label for="company"><Icon path={mdiDomain} size={0.9} color="lightgray" className="form-label-icon" />Company</Label>
                                                        {
                                                            this.state.createtype == "update" ?
                                                                (
                                                                    <Input type="text" name="company" className="form-control-sm" id="company" value={this.state.company || ''} onChange={this.handleInputChange} disabled />
                                                                )
                                                                :
                                                                (
                                                                    <Input type="text" name="company" className="form-control-sm" id="company" value={this.state.company || ''} onChange={this.handleInputChange} />
                                                                )
                                                        }
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup className="form-labels">
                                                        <Label for="officephone"><Icon path={mdiPhone} size={0.9} color="lightgray" className="form-label-icon" />Office Phone</Label>
                                                        {
                                                            this.state.createtype == "update" ?
                                                                (
                                                                    <Input type="text" name="officephone" className="form-control-sm" id="officephone" value={this.state.officephone || ''} onChange={this.handleInputChange} disabled />
                                                                )
                                                                :
                                                                (
                                                                    <Input type="text" name="officephone" className="form-control-sm" id="officephone" value={this.state.officephone || ''} onChange={this.handleInputChange} />
                                                                )
                                                        }
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={4} >
                                                    <FormGroup className="form-labels">
                                                        <Label for="street"><Icon path={mdiGoogleStreetView} size={0.9} color="lightgray" className="form-label-icon" />Street</Label>
                                                        {
                                                            this.state.createtype == "update" ?
                                                                (
                                                                    <Input type="text" name="street" className="form-control-sm" id="street" value={this.state.street || ''} onChange={this.handleInputChange} disabled />
                                                                )
                                                                :
                                                                (
                                                                    <Input type="text" name="street" className="form-control-sm" id="street" value={this.state.street || ''} onChange={this.handleInputChange} />
                                                                )
                                                        }
                                                    </FormGroup>
                                                </Col>
                                                <Col md={4}>
                                                    <FormGroup className="form-labels">
                                                        <Label for="city"><Icon path={mdiMapMarker} size={0.9} color="lightgray" className="form-label-icon" />City</Label>
                                                        {
                                                            this.state.createtype == "update" ?
                                                                (
                                                                    <Input type="text" name="city" className="form-control-sm" id="city" value={this.state.city || ''} onChange={this.handleInputChange} disabled />
                                                                )
                                                                :
                                                                (
                                                                    <Input type="text" name="city" className="form-control-sm" id="city" value={this.state.city || ''} onChange={this.handleInputChange} />
                                                                )
                                                        }
                                                    </FormGroup>
                                                </Col>

                                                <Col md={4}>
                                                    <FormGroup className="form-labels">
                                                        <Label for="state"><Icon path={mdiMap} size={0.9} color="lightgray" className="form-label-icon" />State</Label>
                                                        {
                                                            this.state.createtype == "update" ?
                                                                (
                                                                    <RegionDropdown name="state" className="form-control-sm" id="region" placeholder="Select State" country={this.state.country || ''} value={this.state.state || ''} onChange={(val) => this.selectRegion(val)}
                                                                        disabled />
                                                                )
                                                                :
                                                                (
                                                                    <RegionDropdown name="state" className="form-control-sm" id="region" placeholder="Select State" country={this.state.country || ''} value={this.state.state || ''} onChange={(val) => this.selectRegion(val)}
                                                                    />
                                                                )
                                                        }
                                                    </FormGroup>
                                                </Col>
                                                <Col md={4} >
                                                    <FormGroup className="form-labels">
                                                        <Label for="zip"><Icon path={mdiZipBox} size={0.9} color="lightgray" className="form-label-icon" />Zip</Label>
                                                        {
                                                            this.state.createtype == "update" ?
                                                                (
                                                                    <Input type="text" name="zip" className="form-control-sm" id="zip" value={this.state.zip || ''} onChange={this.handleInputChange} disabled />
                                                                )
                                                                :
                                                                (
                                                                    <Input type="text" name="zip" className="form-control-sm" id="zip" value={this.state.zip || ''} onChange={this.handleInputChange} />
                                                                )
                                                        }
                                                    </FormGroup>
                                                </Col>

                                                <Col md={4}>
                                                    <FormGroup className="form-labels">
                                                        <Label for="country"><Icon path={mdiGlobeModel} size={0.9} color="lightgray" className="form-label-icon" />Country</Label>
                                                        {
                                                            this.state.createtype == "update" ?
                                                                (
                                                                    <CountryDropdown name="country" className="form-control-sm" id="country" value={this.state.country || ''} onChange={(val) => this.selectCountry(val)}
                                                                        disabled />
                                                                )
                                                                :
                                                                (
                                                                    <CountryDropdown name="country" className="form-control-sm" id="country" value={this.state.country || ''} onChange={(val) => this.selectCountry(val)}
                                                                    />
                                                                )
                                                        }
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </CardBody>
                    </Card>

                    <Card className="card_wrapper" hidden>
                                        <CardHeader className="form-header-1 card_header">Registration Details</CardHeader>
                                        <CardBody >
                                            <Row>
                                                <Col md={4}>
                                                    <FormGroup className="form-labels">
                                                        <Label for="regid"><Icon path={mdiIdentifier} size={0.9} color="lightgray" className="form-label-icon" />Registration Id</Label>
                                                        <Input type="text" name="regid" className="form-control-sm" id="regid" value={this.state.regid || ''} onChange={this.handleInputChange} />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={4}>
                                                    <FormGroup className="form-labels">
                                                        <Label for="regtype"><Icon path={mdiFileDocument} size={0.9} color="lightgray" className="form-label-icon" />Registration Options*{this.state.userid == "" ? '' : <Link to="#" onClick={() => this.regItemPopUpModal(this.state.regtype)}> view</Link>}</Label>
                                                        <Input type="text" name="regtype" className="form-control-sm" id="regtype" value={this.state.regtype || ''} onChange={this.handleInputChange} />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={4}>
                                                    <FormGroup className="form-labels">
                                                        <Label for="regstatus"><Icon path={mdiRegisteredTrademark} size={0.9} color="lightgray" className="form-label-icon" />Registration Status*</Label>
                                                        <Input type="select" className="form-control-sm" id="regstatus" name="regstatus" value={this.state.regstatus} onChange={this.handleInputChange}>
                                                            <option value=" ">Select</option>
                                                            <option value="Confirmed">Confirmed</option>
                                                            <option value="Pending">Pending</option>
                                                            <option value="Cancelled">Cancelled</option>
                                                            <option value="Transferred">Transferred</option>
                                                            <option value="Other">Other</option>
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={4}>
                                                    <FormGroup className="form-labels">
                                                        <Label for="userstype"><Icon path={mdiFileDocument} size={0.9} color="lightgray" className="form-label-icon" />User Type*</Label>
                                                        <Select
                                                            isMulti
                                                            className="basic-multi-select"
                                                            value={this.state.selectedUserType}
                                                            options={this.state.userstype}
                                                            onChange={this.handleUsertype} />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </CardBody>
                    </Card>    
                    {this.state.message ?<div className="alert alert-dark alert_message" role="alert" id="message">{this.state.message}</div>: ""}
    
                    <div className='action_btns_wrapper'>
                    
                    <div>
                        {this.state.isLoading ?
                            <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="Loading-btn"
                            variant="success"
                            hidden={!this.state.isLoading} /> : 
                            <button
                            className="btn form-submit-button submit_btn"
                            onClick={this.handleSubmit}
                            disabled={this.state.disabled}
                            type="submit"
                            isLoading={this.state.isLoading}
                            text="Submit"
                            loadingText="Submitting…"
                            >Save</button>
                        }
                        <button type="button" className="btn form-clear-btn" onClick={this.handleClear} >Clear</button>
                    </div>
                    <div>
                    {
                        formStatus.includes("addnew") ? ('') :
                            (
                            <button className='btn form-submit-button submit_btn' 
                                onClick={() => { if (window.confirm(`Do you want to send instructions email to ${this.state.userid} ?`)) { this.SendEmailToExhibitorRepresentative() }; }}
                                style={{marginLeft:'25px',backgroundColor:'gray'}}
                            >Send Email</button>
                            
                        )
                    }
                    <Link type="button" to="/Representative" className="btn form-clear-btn"><Icon path={mdiKeyboardBackspace} size={0.9} color="" className="form-back-button-icon" />  Back</Link>
                    </div>
                    
                    </div>
                </div>
            </>
        );
    }

    constructor(props) {

        //console.log("Attendee id =" + props.match.params.userid)
        //const globalvariables = global.$variables; // Accessing Global variables
        //console.log(globalvariables)
        //console.log(global.$loggedinuser.state);
        formStatus = props.match.params.userid;
        var enableterms = false;
        var termsdata = "";
        var termsdataforreps = "";
        if (global.$config["exhibitorsubmitform"] != null) {
            if(global.$config["exhibitorsubmitform"].terms != null) {
                enableterms = global.$config["exhibitorsubmitform"].terms.required;
                termsdata = global.$config["exhibitorsubmitform"].terms;
            }

            if(global.$config["exhibitorsubmitform"].termsforcompanyreps) {
                termsdataforreps = global.$config["exhibitorsubmitform"].termsforcompanyreps
            }
        }
        //console.log(formStatus)
        super(props);
        //console.log(formStatus)
        this.state =
        {
            //code: '',
            userid: props.match.params.userid,
            code: global.$code,
            submittedby: global.$userid,
            poolid: global.$poolid,
            cauthtype: global.$cauthtype,
            fname: '',
            lname: '',
            company: global.$loggedinuser.company,
            title: '',
            phone: '',
            email: '',
            linkedin: '',
            twitter: '',
            fb: '',
            country: global.$loggedinuser.country,
            state: global.$loggedinuser.state,
            city: global.$loggedinuser.city,
            street: global.$loggedinuser.street,
            zip: global.$loggedinuser.zip,
            regid: '',
            regtype: 'Exhibitor Representative - $0',
            memberid: '',
            photoFileName: '',
            photoFileHandle: '',
            imageUrl: '',
            vpoints: '',
            apoints: '',
            tpoints: '',
            spoints: '',
            regstatus: 'Confirmed',
            password: '',
            selectedRegStatus: '',
            disabled: false,
            isLoading: false,
            sendingmail: false,
            hiddenqrcode: false,
            myexpertise: '',
            myinterests: '',
            badgetype: [],
            registrationType: '',
            validscans: '',
            selectedBadgeType: '',
            officephone: global.$loggedinuser.officephone,
            currentlocation: '',
            lastseen: '',
            showdirectory: false,
            selectedBadge: '',
            location: "",
            locationtime: "",
            signindate: "",
            data1: "",
            data2: "",
            data3: "",
            data4: "",
            data5: "",
            q1: "",
            q2: "",
            q3: "",
            q4: "",
            q5: "",
            q6: "",
            q7: "",
            q8: "",
            q1title: "Question 1",
            q2title: "Question 2",
            q3title: "Question 3",
            q4title: "Question 4",
            q5title: "Question 5",
            q6title: "Question 6",
            q7title: "Question 7",
            q8title: "Question 8",
            //userstype: '',
            userstype: 'Exhibitor Representative',
            selectedUserType: 'Exhibitor Representative',
            selectedUser: 'Exhibitor Representative',
            guests: "",
            sessions: "",
            regnotes: "",
            checkinstatus: "",
            mname: "",
            totalamount: "0",
            amountpaid: "0",
            promoamount: "0",
            pendingamount: "0",
            refundamount: "0",
            paymentmode: "",
            promocodes: "",
            guestname: "",
            regitems: '',
            regitempopup: false,
            createtype: '',
            repregstatus: '',
            representativecount: 0,
            representativelist: [],
            showalert: false,
            enableterms: enableterms,
            termsdata: termsdata,
            termsdataforreps: termsdataforreps,
            termsstatus: false,
            regitemsjson:[{
                "id": '',
                "name": "Exhibitor Representative",
                "price": "0.00",
                "type": "",
                "itemid": "001",
                "qty": "1",
                "status": "active",
                "data1": "",
                "data2": "",
                "data3": "",
                "data4": "",
                "data5": "",
                "cdate": ""
              }]
        };


        //console.log(this.state.userid)
        if (this.state.code == "travcon2022exhibitor") {
            this.state.code = "travcon2022";
        }
        if (this.state.code == "travcon2023exhibitor") {
            this.state.code = "travcon2023";
        }
        this.state.message = "";
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClear = this.handleClear.bind(this);
        // this.fileUploader = this.fileUploader.bind(this);
        this.selectCountry = this.selectCountry.bind(this);
        this.selectRegion = this.selectRegion.bind(this);
        // //this.handleDropdownChange = this.handleDropdownChange.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
        // //this.handleBadgeType = this.handleBadgeType.bind(this);
        // this.handleSendEmail = this.handleSendEmail.bind(this);
        this.handleCheckbox = this.handleCheckbox.bind(this);
        // this.handleCategory = this.handleCategory.bind(this);
        this.handleUsertype = this.handleUsertype.bind(this);
        global.$attendeemailid = this.state.email;
        if (this.state.userid !== 'addnew') {
            this.state.createtype = 'update'
            this.state.repregstatus = 'update'
            this.getUser();
            //this.findImgFilename();
            //this.getS3PrivateImg();
            //////console.log(this.state.photoFileName);
        }
        else if (this.state.userid == "addnew") {
            this.state.userid = "";
            this.state.repregstatus = 'new'
            if (this.state.code == "metro2021conference") {
                this.state.q1title = "Can your mailing address receive packages from UPS or FedEx?*";
                this.state.q2title = "Pursuant to the Americans with Disabilities Act, do you require specific aids or services?";
                this.state.q3title = "Do you plan to attend the President's Reception";
                this.state.q4title = "Do you plan to attend the Monday night event?";
            }
            else if (this.state.code == "travcon2021") {
                this.state.q1title = "Profession (Designation)";
                this.state.q2title = "Specialty";
                this.state.q3title = "How many years as a travel medical professional?";
                this.state.q4title = "How many TravCons have you attended in the past?";
                this.state.q5title = "How did you hear about TravCon?"
            }
        }
        this.getUsers();
        //this.getBadgeTypes();
        //console.log(this.props.userid);        
    }

    updateAttendeeTab = () => {
        global.$activeattendeetab = "one";
    }

    regItemPopUpModal = (items) => {
        this.setState(prevState => ({
            regitems: items,
            regitempopup: !prevState.regitempopup
        }));
    }
    download = () => {
        const canvas = document.getElementById("qrCode");
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = this.state.title + "-QR.png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    selectCountry(val) {
        this.setState({ country: val });
    }

    selectRegion(val) {
        this.setState({ state: val })
    }

    handleCheckbox = (event) => {
        const target = event.target;
        const name = target.name;
        let value = target.value

        if ([name] == "showdirectory") {
            this.setState({ [name]: !this.state.showdirectory });
        }
        else if ([name] == "termsstatus") {
            this.setState({ [name]: !this.state.termsstatus });
        }
    }
    getFileName(userid, fileName) {
        var key = userid;
        key = key.replace(/,/g, '_');
        key = key.replace(/ /g, '_');
        key = key.replace(/@/g, '_');
        key = key.replace(/&/g, '_');
        //key = key.replace(/\./g, '');
        key = key.replace(/__/g, '_');
        //console.log(key);
        key = key.toLowerCase();

        fileName = fileName.toLowerCase();

        if (fileName.indexOf("jpg") !== -1) {
            key = key + ".png";
        }
        else if (fileName.indexOf("gif") !== -1) {
            key = key + ".png";
        }
        else if (fileName.indexOf("png") !== -1) {
            key = key + ".png";
        }
        else {
            this.state.photoFileName = "Format not supported";
        }

        return key;
    }

    getS3FileName() {
        return this.state.code + "/attendees/" + this.state.photoFileName;
    }

    //files
    fileUploader = (event) => {

        //file preview
        const file = this.refs.uploadImg.files[0]
        const reader = new FileReader();

        //////console.log("fileUploader");
        reader.onloadend = () => {
            this.setState({
                imageUrl: reader.result
            })
        }
        if (file) {
            reader.readAsDataURL(file);
            this.setState({
                imageUrl: reader.result
            })
        }
        else {
            this.setState({
                imageUrl: " "
            })
        }

        //file handler
        const target = event.target;
        const name = target.name;
        let value = target.value

        if (name === 'photo') {
            this.state.photoFileHandle = event.target.files[0];
            //////console.log("photoFileName = " + this.state.photoFileHandle.name)
            this.state.photoFileName = this.getFileName(this.state.userid, this.state.photoFileHandle.name);
        }
        else {
            this.setState(
                {
                    [name]: value
                }
            );
        }

        //file storage
        const s3File = this.getS3FileName();

        ////console.log('file=' +this.refs.uploadImg.files[0].name+", Des ="+s3File);

        Storage.put(s3File, this.state.photoFileHandle,
            {
                contentType: 'image/png'
            })
            .then(
                result => {
                    //////console.log(result);
                    //this.state.message = "photo successfully submitted"

                    this.forceUpdate();
                }
            )
            .catch(err => {
                //////console.log(err)
                this.state.message = "Unable to upload photo. Please try again"
                this.forceUpdate();

            }
            );

    }

    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        let value = target.value

        //console.log( "Target = " + name + ", Value = " + value);

        this.setState({ [name]: value });

        //this.setState({registrationType:value})
    }
    /*handleRegType(event) {
        const target = event.target;
        const name = target.name;
        let value = target.value

        //console.log( "Target = " + name + ", Value = " + value);
        this.setState({ selectedBadgeType: event.target.value });
        //console.log(this.state.selectedBadgeType);

    }*/

    /*handleRegType(registrationType)
    {
        this.setState({ registrationType});
       
        var value = []
        //console.log("Ready")
       //console.log(registrationType.length);
        if(registrationType.length == 0)
        {
            //console.log("zero length");
            this.setState({selectedBadgeType : ' '});
        }
        for(var i=0; i<registrationType.length; i++ )
        {
            //console.log(registrationType.length);
            var newObj = Object()
 
            newObj.value = registrationType[i].value
            value.push(newObj.value);
            //console.log(value)
            this.setState({selectedBadgeType :  value.toString()});
            //console.log(registrationType);
            //console.log(value.toString());
           //console.log(this.state.selectedBadgeType);
        }
    }
*/
    /*handleDropdownChange(event) {
        const target = event.target;
        const name = target.name;
        let value = target.value;

        ////console.log( name + " = " + value);

        this.setState({ selectedRegStatus: event.target.value })
    }*/
    handleCategory(selectedBadgeType) {
        this.setState({ selectedBadgeType });

        var value = []
        //console.log("Ready")
        //console.log(selectedBadgeType);
        if (selectedBadgeType == null) {
            selectedBadgeType = [];
        }
        //console.log(selectedBadgeType);

        if (selectedBadgeType.length == 0 || selectedBadgeType == null) {
            //console.log("zero length");
            this.setState({ selectedBadge: ' ' });
            selectedBadgeType.length = 0;
        }
        for (var i = 0; i < selectedBadgeType.length; i++) {
            //console.log(selectedBadgeType.length);
            var newObj = Object()

            newObj.value = selectedBadgeType[i].value
            value.push(newObj.value);
            //console.log(value)
            this.setState({ selectedBadge: value.toString() });
            //console.log(selectedBadgeType);
        }
    };

    handleUsertype(selectedUserType) {
        this.setState({ selectedUserType });

        var value = []
        //console.log("Ready")
        //console.log(selectedBadgeType);
        if (selectedUserType == null) {
            selectedUserType = [];
        }
        //console.log(selectedBadgeType);

        if (selectedUserType.length == 0 || selectedUserType == null) {
            //console.log("zero length");
            this.setState({ selectedUser: ' ' });
            selectedUserType.length = 0;
        }
        for (var i = 0; i < selectedUserType.length; i++) {
            //console.log(selectedBadgeType.length);
            var newObj = Object()

            newObj.value = selectedUserType[i].value
            value.push(newObj.value);
            //console.log(value)
            this.setState({ selectedUser: value.toString() });
            //console.log(selectedBadgeType);
        }
    }

    handleChangePassword(event) {
        event.preventDefault();

        if (this.validateInputs()) {
            if (this.handleValidation()) {
                this.changeCognitoUserPassword(event);
                this.setState({ isLoading: true });

            }
        }
    }

    handleCopyuserid(event) {
        event.preventDefault();
        navigator.clipboard.writeText(this.state.userid);
    }

    validateEmail() {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(this.state.userid);
    }

    handleValidation() {
        var password = this.state.password;
        //console.log('pwd:' + this.state.password)
        if (password.length < 8) {
            this.state.message = passwordValidation;
            this.setState({ isLoading: false });
            this.forceUpdate();
            return false;
        }
        return true;
    }

    validateInputs() {
        console.log(this.state);
        if (this.state.enableterms == true) {
            if (this.state.termsstatus == false) {
                this.state.message = this.state.termsdata.message;
                this.forceUpdate();
                return false;
            }
            else {
                if (this.state.code === "" || this.state.fname === "" || this.state.lname === "" || this.state.email === "" || this.state.userid === "" || this.state.password === "" || this.state.regstatus === "" || this.state.regstatus === " " ) {
                    this.state.message = "Enter all the required inputs and click submit"
                    this.forceUpdate();
                    return false;
                }
                else {
                    return true;
                }
            }
        }
        else if (this.state.code === "" || this.state.fname === "" || this.state.lname === "" || this.state.email === "" || this.state.userid === "" || this.state.password === "" || this.state.regstatus === "" || this.state.regstatus === " " ) {
            this.state.message = "Enter all the required inputs and click submit"
            this.forceUpdate();
            return false;
        }
        else {
            return true;
        }
    }

    validateUserIdExist() {
        var arr = this.state.representativelist
        var exist = (representative) => representative.userid.S == this.state.userid

        if (this.state.repregstatus == 'new' && arr.some(exist)) {
            this.state.message = `${this.state.userid} already exist`
            this.forceUpdate();
            return false;
        }

        return true;
    }

    checkmaxrepcount() {
        console.log(this.state.representativecount, global.$maxrepcount)
        //console.log(global.$attendeelist.length, global.$maxrepcount)
        if (this.state.representativecount < global.$maxrepcount) {
            return true;
        }
        else if (this.state.representativecount <= global.$maxrepcount && this.state.repregstatus != "new") {
            return true;
        }
        else {
            //console.log("Maximum Representative limit reached")
            this.state.message = "Unable to add representative as maximum limit is reached"
            this.setState({ isLoading: false });
            return false;
        }
    }

    handleSubmit(event) {
        event.preventDefault();

        //console.log(this.state.selectedUser)
        if (this.validateInputs()) {
            if (this.validateEmail()) {
                if (this.validateUserIdExist()) {
                    if (this.handleValidation()) {
                        if (this.checkmaxrepcount()) {
                            //console.log("coming in");
                            this.submitProfile(event);
                            this.setState({ isLoading: true });
                        }
                    }
                }
            }
        }
    }

    handleSendEmail(event) {
        event.preventDefault();

        if (this.validateInputs()) {
            if (this.handleValidation()) {
                this.sendEmailToAttendee();
                this.setState({ sendingmail: true });
            }
        }

    }

    getDropdownCategoriesTableObjects(objs) {
        //////console.log(objs);
        const tableObjs = []

        for (var i = 0; i < objs.length; i++) {
            var categoryObj = objs[i]

            //console.log( categoryObj)

            var newObj = Object()

            var name = categoryObj.name.S;

            name = name.split(",");

            var options = []
            name.map((item) => {
                //console.log(item);
                options = { value: item, label: item }
                tableObjs.push(options)
            })


        }

        //console.log(tableObjs)

        return tableObjs
    }
    getBadgeTypes() {
        let apiName = serviceNames.getEventPlannerService();
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'code': this.state.code,
                'command': 'getCategory',
                'type': 'attendee-badges'
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //console.log(myInit);

        API.post(apiName, path, myInit).then(response => {
            //console.log(response.data);

            if (response.err == null && response.data != null) {
                //console.log(response.data.Items);

                let categoryObj = this.getDropdownCategoriesTableObjects(response.data.data.Items)

                this.setState({ badgetype: categoryObj })

                /*if (this.state.selectedBadgeType == "") {   //default selection 
                    this.setState({ selectedBadgeType: this.state.regtype[0].props.value });
                    //console.log(this.state.selectedDropdown)                        
                }*/
                //console.log(this.state.regtype)

            }
            else {
                //////console.log("getCategoriesTableObjects - failed!!");
                //////console.log(response.err);
            }

        }
        ).catch(error => {
            //////console.log(error.response)
        });
    }

    SendEmailToExhibitorRepresentative() {
        let apiName = serviceNames.getRegistrationService();
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'command': 'SendEmailToExhibitorRepresentative',
                'code': global.$code,
                'userid': this.state.userid,
                'emailtype': 'exhibitor-representative'
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //console.log(myInit);

        API.post(apiName, path, myInit).then(response => {
            //console.log(response);

            if (response.err == null) {
                //////console.log(response.data.Items);
                this.state.message = "Email has sent successfully";
                this.setState({ sendingmail: false });
            }
            else {
                //console.log(response.err);
                this.state.message = "Unable send email, please try again";
                this.setState({ sendingmail: false });
            }
        }

        ).catch(error => {
            //console.log(error)
        });
    }

    handleClear(event) {
        event.preventDefault();
        this.setState({ disabled: false });
        this.state.message = "";
        this.clearInputs();
    }

    clearInputs() {
        //this.state.code         = '';
        this.state.userid = '';
        this.state.fname = '';
        this.state.lname = '';
        //this.state.company = '';
        this.state.title = '';
        this.state.phone = '';
        this.state.email = '';
        // this.state.country = '';
        // this.state.state = '';
        // this.state.city = '';
        // this.state.street = '';
        // this.state.zip = '';
        this.state.imageUrl = '';
        this.state.photoFileName = '';
        this.state.photoFileHandle = '';
        this.state.linkedin = '';
        this.state.twitter = '';
        this.state.fb = '';
        this.state.regid = '';
        // this.state.regtype = '';
        // this.state.regstatus = '';
        this.state.memberid = '';
        this.state.vpoints = '';
        this.state.apoints = '';
        this.state.tpoints = '';
        this.state.spoints = '';
        // this.state.selectedRegStatus = '';
        this.state.password = '';
        this.state.myinterests = '';
        this.state.myexpertise = '';
        this.state.validscans = '';
        this.state.selectedBadgeType = '';
        // this.state.officephone = '';
        this.state.currentlocation = '';
        this.state.lastseen = '';
        this.state.showdirectory = false;
        this.state.location = "";
        this.state.locationtime = "";
        this.state.signindate = "";
        this.state.data1 = "";
        this.state.data2 = "";
        this.state.data3 = "";
        this.state.data4 = "";
        this.state.data5 = "";
        this.state.q1 = "";
        this.state.q2 = "";
        this.state.q3 = "";
        this.state.q4 = "";
        this.state.q5 = "";
        this.state.q6 = "";
        this.state.q7 = "";
        this.state.q8 = "";
        // this.state.userstype = "";
        this.state.guests = "";
        this.state.sessions = "";
        this.state.regnotes = "";
        this.state.checkinstatus = "";
        this.state.mname = "";
        this.state.totalamount = "0";
        this.state.amountpaid = "0";
        this.state.promoamount = "0";
        this.state.pendingamount = "0";
        this.state.refundamount = "0";
        this.state.paymentmode = "";
        this.state.promocodes = "";
        this.state.guestname = "";

        this.state.createtype = ""
        this.setState({ hiddenqrcode: true });
        this.setState({ userid: "addnew" });
        this.setState({ 
            repregstatus: "new",
            termsstatus: false
        })
        formStatus = "addnew";
        this.getUsers();
        this.forceUpdate();
    }

    submitProfile(event) {
        //console.log(this.state.selectedUser)
        let apiName = serviceNames.getRegistrationService();
        let path = ''; //replace this with the path you have configured on your API
        var date = new Date();
        var longFormat = date / 1000;

        if (this.state.lname == "") {
            this.state.lname = " ";
        }
        if (this.state.company == "") {
            this.state.company = " ";
        }
        if (this.state.title == "") {
            this.state.title = " ";
        }
        if (this.state.phone == "") {
            this.state.phone = " ";
        }
        if (this.state.linkedin == "") {
            this.state.linkedin = " ";
        }
        if (this.state.twitter == "") {
            this.state.twitter = " ";
        }
        if (this.state.street == "") {
            this.state.street = " ";
        }
        if (this.state.city == "") {
            this.state.city = " ";
        }
        if (this.state.state == "") {
            this.state.state = " ";
        }
        if (this.state.zip == "") {
            this.state.zip = " ";
        }
        if (this.state.country == "") {
            this.state.country = " ";
        }
        if (this.state.fb == "") {
            this.state.fb = " ";
        }
        if (this.state.regtype == "") {
            this.state.regtype = " ";
        }
        if (this.state.regid == "") {
            this.state.regid = " ";
        }
        if (this.state.memberid == "") {
            this.state.memberid = " ";
        }
        if (this.state.photoFileName == ".png") {
            this.state.photoFileName = this.state.id + ".png"
        }
        if (this.state.photoFileName == "") {
            this.state.photoFileName = "common_profile.png";
        }
        if (this.state.photoFileHandle == "") {
            this.state.photoFileHandle = " ";
        }
        if (this.state.vpoints == "") {
            this.state.vpoints = "0";
        }
        if (this.state.tpoints == "") {
            this.state.tpoints = "0";
        }
        if (this.state.apoints == "") {
            this.state.apoints = "0";
        }
        if (this.state.spoints == "") {
            this.state.spoints = "0";
        }
        if (this.state.regstatus == "") {
            this.state.regstatus = " ";
        }
        if (this.state.password == "") {
            this.state.password = " ";
        }
        if (this.state.myexpertise == "") {
            this.state.myexpertise = " ";
        }
        if (this.state.myinterests == "") {
            this.state.myinterests = " ";
        }
        if (this.state.officephone == "") {
            this.state.officephone = " ";
        }
        if (this.state.selectedRegStatus == "") {
            this.state.selectedRegStatus = " ";
        }
        if (this.state.selectedBadge == "") {
            this.state.selectedBadge = " ";
        }
        if (this.state.currentlocation == "") {
            this.state.currentlocation = " ";
        }
        if (this.state.lastseen == "") {
            this.state.lastseen = " ";
        }
        if (this.state.data1 == "") {
            this.state.data1 = " ";
        }
        if (this.state.data2 == "") {
            this.state.data2 = " ";
        }
        if (this.state.data3 == "") {
            this.state.data3 = " ";
        }
        if (this.state.data4 == "") {
            this.state.data4 = " ";
        }
        if (this.state.data5 == "") {
            this.state.data5 = " ";
        }
        if (this.state.q1 == "") {
            this.state.q1 = " ";
        }
        if (this.state.q2 == "") {
            this.state.q2 = " ";
        }
        if (this.state.q3 == "") {
            this.state.q3 = " ";
        }
        if (this.state.q4 == "") {
            this.state.q4 = " ";
        }
        if (this.state.q5 == "") {
            this.state.q5 = " ";
        }
        if (this.state.q6 == "") {
            this.state.q6 = " ";
        }
        if (this.state.q7 == "") {
            this.state.q7 = " ";
        }
        if (this.state.q8 == "") {
            this.state.q8 = " ";
        }
        if (this.state.location == "") {
            this.state.location = " ";
        }
        if (this.state.locationtime == "") {
            this.state.locationtime = " ";
        }
        if (this.state.signindate == "") {
            this.state.signindate = " ";
        }
        if (this.state.userstype == "") {
            this.state.userstype = " ";
        }
        if (this.state.guests == "") {
            this.state.guests = " ";
        }
        if (this.state.sessions == "") {
            this.state.sessions = " ";
        }
        if (this.state.regnotes == "") {
            this.state.regnotes = " ";
        }
        if (this.state.checkinstatus == "") {
            this.state.checkinstatus = " ";
        }
        if (this.state.mname == "") {
            this.state.mname = " ";
        }
        if (this.state.totalamount == "") {
            this.state.totalamount = "0";
        }
        if (this.state.amountpaid == "") {
            this.state.amountpaid = "0";
        }
        if (this.state.promoamount == "") {
            this.state.promoamount = "0";
        }
        if (this.state.pendingamount == "") {
            this.state.pendingamount = "0";
        }
        if (this.state.refundamount == "") {
            this.state.refundamount = "0";
        }
        if (this.state.promocodes == "") {
            this.state.promocodes = " ";
        }
        if (this.state.guestname == "") {
            this.state.guestname = " ";
        }
        if (this.state.validscans == "") {
            if (this.state.userstype.includes("Delegate")) {
                this.state.command = "attendee-delegate";
                this.getValidScans();
            }
            if (this.state.userstype.includes("Faculty")) {
                this.state.command = "attendee-faculty";
                this.getValidScans();
            }
            if (this.state.userstype.includes("Exhibitor")) {
                this.state.command = "attendee-exhibitor";
                this.getValidScans();
            }
            if (this.state.userstype.includes("Committee")) {
                this.state.command = "attendee-committee";
                this.getValidScans();
            }
            if (this.state.userstype.includes("Member")) {
                this.state.command = "attendee-member";
                this.getValidScans();
            }
            if (this.state.userstype.includes("Non-Member")) {
                this.state.command = "attendee-nonmember";
                this.getValidScans();
            }
            else {
                this.state.validscans = " ";
            }
        }

        if (this.state.regid == "" || this.state.regid == " ") {
            var date = new Date();
            var timestamp = date / 1000;
            timestamp = timestamp.toString();
            timestamp = timestamp.replace(/\./g, "");
            this.state.regid = timestamp.substring(timestamp.length - 8, timestamp.length);
        }

        this.state.userid = (this.state.userid).toLowerCase();
        //console.log(this.state.selectedUser)
        let myInit =
        {
            body:
            {
                'code': global.$code,
                'command': 'processExhibitorRepresentativeRegistration',
                'userid': this.state.userid,
                'fname': this.state.fname,
                'lname': this.state.lname,
                'company': this.state.company,
                'title': this.state.title,
                'phone': this.state.phone,
                'email': this.state.email,
                'linkedin': this.state.linkedin,
                'twitter': this.state.twitter,
                'fb': this.state.fb,
                'country': this.state.country,
                'state': this.state.state,
                'city': this.state.city,
                'street': this.state.street,
                'zip': this.state.zip,
                'regid': this.state.regid,
                'regtype': this.state.regtype,
                'photo': this.state.photoFileName,
                'memberid': this.state.memberid,
                'tag': 'tag',
                'ep': ' ',
                'submittedby': this.state.submittedby,
                'udate': longFormat.toString(),
                'visitpoints': this.state.vpoints,
                'activitypoints': this.state.apoints,
                'totalpoints': this.state.tpoints,
                'regstatus': this.state.regstatus,
                'sessionpoints': this.state.spoints,
                'poolid': this.state.poolid,
                'password': this.state.password,
                'myexpertise': this.state.myexpertise,
                'myinterests': this.state.myinterests,
                'validscans': this.state.validscans,
                'officephone': this.state.officephone,
                'currentlocation': this.state.currentlocation,
                'lastseen': this.state.lastseen,
                'showdirectory': this.state.showdirectory.toString(),
                'currentlocation': this.state.currentlocation,
                'lastseen': this.state.lastseen,
                'location': this.state.location,
                'locationtime': this.state.locationtime,
                'signindate': this.state.signindate,
                'data1': this.state.data1,
                'data2': this.state.data2,
                'data3': `{ "companyid": "${global.$companyid}" }`,
                'data4': this.state.selectedBadge,
                'data5': this.state.data5,
                "q1": 'q#title:' + this.state.q1title + ';answer:' + this.state.q1,
                "q2": 'q#title:' + this.state.q2title + ';answer:' + this.state.q2,
                "q3": 'q#title:' + this.state.q3title + ';answer:' + this.state.q3,
                "q4": 'q#title:' + this.state.q4title + ';answer:' + this.state.q4,
                "q5": 'q#title:' + this.state.q5title + ';answer:' + this.state.q5,
                "q6": 'q#title:' + this.state.q6title + ';answer:' + this.state.q6,
                "q7": 'q#title:' + this.state.q7title + ';answer:' + this.state.q7,
                "q8": 'q#title:' + this.state.q8title + ';answer:' + this.state.q8,
                "userstype": this.state.selectedUser,
                "guests": this.state.guests,
                "sessions": this.state.sessions,
                "regnotes": this.state.regnotes,
                "checkinstatus": this.state.checkinstatus,
                "mname": this.state.mname,
                "totalamount": this.state.totalamount.toString(),
                "amountpaid": this.state.amountpaid.toString(),
                "promoamount": this.state.promoamount.toString(),
                "paymentmode": this.state.paymentmode,
                "promocodes": this.state.promocodes,
                "guestname": this.state.guestname,
                "pendingamount": this.state.pendingamount.toString(),
                "refundamount": this.state.refundamount.toString(),
                "repregstatus": this.state.repregstatus,
                "emailtype": "exhibitor-representative",
                'regitemsjson':JSON.stringify(this.state.regitemsjson),
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        this.setState({ disabled: true });
        console.log(myInit);
        ///console.log(this.state.code + "," + this.state.company + "," + this.state.type + "," + this.state.url);

        API.post(apiName, path, myInit).then(response => {
            console.log(response)
            console.log("response.data ==> " + response.data + "response.err ==> " + response.err);
            this.setState({ disabled: false });

            if (response.err == null) {
                console.log("postExhibitorProfile - Success!!");
                this.state.message = "Profile successfully submitted";
                this.setState({ isLoading: false });
                this.copyFiles(myInit.body.photo);
                this.setState({ hiddenqrcode: true });
                this.setState({ userid: "addnew" });
                formStatus = "addnew";
                this.getRegTypes();
                this.clearInputs();

                this.forceUpdate();
            }
            else {
                ////console.log("postExhibitorProfile - failed!!");
                this.state.message = "Error!! Unable to submit profile. Please try again. "
                this.setState({ isLoading: false });
                this.forceUpdate();
            }

        }).catch(error => {
            console.log(error)
            this.setState({ disabled: false });
        });
    }
    copyFiles(photo) {
        let apiName = serviceNames.getUserService();
        let path = ''; //replace this with the path you have configured on your API

        let myInit =
        {
            body:
            {
                'code': this.state.code,
                'command': 'copyS3Files',
                'srcbucket': 'event360live',
                'desbucket': 'wesicore',
                'fileFrom': 'attendees',
                'fileTo': 'upload/profiles',
                'photo': photo
            },
            response: true,
            headers: {}

        }
        //console.log(myInit);
        API.post(apiName, path, myInit).then(response => {

            //console.log ("response.data ==> " + response.data + "response.err ==> " + response.err);
            if (response.err == null && response.data != null) {
                //console.log("Change Password - Success!!");
                //this.getS3PrivateImg(photo)
            }
            else {
                //console.log("Change Password - failed!!");
            }

        }).catch(error => {
            //console.log(error.response)
        });
    }
    changeCognitoUserPassword() {
        let apiName = serviceNames.getUserService();
        let path = ''; //replace this with the path you have configured on your API

        let myInit =
        {
            body:
            {
                'code': this.state.code,
                'command': 'changeCognitoPassword',
                'userid': this.state.userid,
                'poolid': this.state.poolid,
                'password': this.state.password
            },
            response: true,
            headers: {}

        }
        this.setState({ disabled: true });
        //console.log(myInit);
        API.post(apiName, path, myInit).then(response => {

            //console.log ("response.data ==> " + response.data + "response.err ==> " + response.err);
            this.setState({ disabled: false });
            if (response.err == null && response.data != null) {
                //console.log("Change Password - Success!!");
                if (response.data.errorMessage == "User does not exist.") {
                    alert("Unable to change password, User does not exsit");
                }
                else {
                    this.state.message = successChangePasswordMessage;
                    this.setState({ isLoading: false });
                    alert("Password successfully changed!");
                }
            }
            else {
                //console.log("Change Password - failed!!");
                this.state.message = failedChangePasswordMessage;
                alert("Unable to change password");
            }

        }).catch(error => {
            //console.log(error.response)
        });
    }

    getS3PrivateImg() {
        var userid = this.state.userid
        // this.state.imgid = this.state.imgid.replace(/ /g, '_');
        // this.state.imgid = this.state.imgid.replace(/@/g, '_');
        userid = userid.replace(/ /g, '_');
        userid = userid.replace(/@/g, '_');
        //console.log(userid);
        userid = userid + ".png"
        let apiName = serviceNames.getWebsiteService();
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'code': this.state.code,
                'command': 'getS3PrivateImg',
                'bucket': 'wesicore',
                'key': 'event360/' + this.state.code + '/upload/profiles/' + userid
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //console.log(myInit);
        API.post(apiName, path, myInit).then(response => {

            //console.log(response);

            if (response.err == null && response.data != null) {
                if (response.data.errorType == "NoSuchKey") {
                    //console.log(response.data);
                    this.setState({
                        imageUrl: "common_profile.png",
                        dataloaded: true
                    })
                }
                else {
                    //console.log(JSON.stringify(response.data));
                    //console.log(this.state.imageUrl);
                    this.setState({
                        imageUrl: "data:image/jpeg;base64," + response.data,
                        dataloaded: true
                    });
                }

                //global.$imageUrl = "data:image/jpeg;base64,"+response.data;
                //console.log(global.$imageUrl);
                //console.log(this.state.imageUrl);

            }
            else {
                //console.log("getS3PrivateImg - updating state ")
            }
        }
        ).catch(error => {
            //console.log(error.response)
        });
    }

    getUser() {
        let apiName = serviceNames.getUserService();
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'command': 'getUserProfile',
                'code': this.state.code,
                'userid': this.state.userid,
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //console.log(myInit);

        API.post(apiName, path, myInit).then(response => {
            //console.log(response.data);

            if (response.err == null && response.data != null) {
                //console.log(response.data.Item);

                let userObj = this.getUserObject(response.data.Item)
                global.$attendeejson = userObj;

                userObj.showdirectory = userObj.showdirectory.toString();
                if (userObj.showdirectory == "true") {
                    userObj.showdirectory = true
                }
                else {
                    userObj.showdirectory = false
                }
                //console.log(userObj);
                if (userObj.q1 != " ") {
                    var question = userObj.q1;

                    var questiontitle = question.substring(
                        question.lastIndexOf("q#title:") + 8,
                        question.lastIndexOf(";answer:"));
                    var questionanswer = question.substring(
                        question.lastIndexOf(";answer:") + 8);

                    this.setState({
                        q1title: questiontitle,
                        q1: questionanswer
                    });
                }

                if (userObj.q2 != " ") {
                    var question = userObj.q2;

                    var questiontitle = question.substring(
                        question.lastIndexOf("q#title:") + 8,
                        question.lastIndexOf(";answer:"));
                    var questionanswer = question.substring(
                        question.lastIndexOf(";answer:") + 8);

                    this.setState({
                        q2title: questiontitle,
                        q2: questionanswer
                    });
                }
                if (userObj.q3 != " ") {
                    var question = userObj.q3;

                    var questiontitle = question.substring(
                        question.lastIndexOf("q#title:") + 8,
                        question.lastIndexOf(";answer:"));
                    var questionanswer = question.substring(
                        question.lastIndexOf(";answer:") + 8);

                    this.setState({
                        q3title: questiontitle,
                        q3: questionanswer
                    });
                }
                if (userObj.q4 != " ") {
                    var question = userObj.q4;

                    var questiontitle = question.substring(
                        question.lastIndexOf("q#title:") + 8,
                        question.lastIndexOf(";answer:"));
                    var questionanswer = question.substring(
                        question.lastIndexOf(";answer:") + 8);

                    this.setState({
                        q4title: questiontitle,
                        q4: questionanswer
                    });
                }
                if (userObj.q5 != " ") {
                    var question = userObj.q5;

                    var questiontitle = question.substring(
                        question.lastIndexOf("q#title:") + 8,
                        question.lastIndexOf(";answer:"));
                    var questionanswer = question.substring(
                        question.lastIndexOf(";answer:") + 8);

                    this.setState({
                        q5title: questiontitle,
                        q5: questionanswer
                    });
                }
                if (userObj.q6 != " ") {
                    var question = userObj.q6;

                    var questiontitle = question.substring(
                        question.lastIndexOf("q#title:") + 8,
                        question.lastIndexOf(";answer:"));
                    var questionanswer = question.substring(
                        question.lastIndexOf(";answer:") + 8);

                    this.setState({
                        q6title: questiontitle,
                        q6: questionanswer
                    });
                }
                if (userObj.q7 != " ") {
                    var question = userObj.q7;

                    var questiontitle = question.substring(
                        question.lastIndexOf("q#title:") + 8,
                        question.lastIndexOf(";answer:"));
                    var questionanswer = question.substring(
                        question.lastIndexOf(";answer:") + 8);

                    this.setState({
                        q7title: questiontitle,
                        q7: questionanswer
                    });
                }
                if (userObj.q8 != " ") {
                    var question = userObj.q8;

                    var questiontitle = question.substring(
                        question.lastIndexOf("q#title:") + 8,
                        question.lastIndexOf(";answer:"));
                    var questionanswer = question.substring(
                        question.lastIndexOf(";answer:") + 8);

                    this.setState({
                        q8title: questiontitle,
                        q8: questionanswer
                    });
                }
                if (userObj.data4 != " ") {
                    var array = userObj.data4.split(",")
                    var value = [];
                    //array.forEach(function(item)
                    for (var i = 0; i <= array.length; i++) {
                        //console.log(array[i])

                        if (array[i] == undefined) {
                            //console.log(this.state.registrationType)    
                        }
                        else {
                            var newObj = Object();

                            newObj = { value: array[i], label: array[i] }

                            //console.log(newObj)

                            value.push(newObj);
                            //console.log(value)
                        }

                        this.state.selectedBadgeType = value;
                        //console.log(this.state.registrationType)        
                    }
                }
                else {
                    this.setState({ selectedBadge: "" })
                }

                if (userObj.userstype != " ") {
                    var array = userObj.userstype.split(",")
                    var value = [];
                    //array.forEach(function(item)
                    for (var i = 0; i <= array.length; i++) {
                        //console.log(array[i])

                        if (array[i] == undefined) {
                            //console.log(this.state.registrationType)    
                        }
                        else {
                            var newObj = Object();

                            newObj = { value: array[i], label: array[i] }

                            //console.log(newObj)

                            value.push(newObj);
                            //console.log(value)
                        }

                        this.state.selectedUserType = value;
                        //console.log(this.state.registrationType)        
                    }
                }
                else {
                    this.setState({ selectedUser: "" })
                }

                this.setState({
                    userid: userObj.userid,
                    fname: userObj.fname,
                    lname: userObj.lname,
                    company: userObj.company,
                    title: userObj.title,
                    email: userObj.email,
                    phone: userObj.phone,
                    photoFileName: userObj.photo,
                    photoFileHandle: userObj.photo,
                    country: userObj.country,
                    street: userObj.street,
                    city: userObj.city,
                    state: userObj.state,
                    zip: userObj.zip,
                    linkedin: userObj.linkedin,
                    twitter: userObj.twitter,
                    fb: userObj.fb,
                    regid: userObj.regid,
                    regtype: userObj.regtype,
                    memberid: userObj.memberid,
                    vpoints: userObj.visitpoints,
                    apoints: userObj.activitypoints,
                    tpoints: userObj.totalpoints,
                    spoints: userObj.sessionpoints,
                    regstatus: userObj.regstatus,
                    password: userObj.password,
                    myexpertise: userObj.myexpertise,
                    myinterests: userObj.myinterests,
                    validscans: userObj.validscans,
                    currentlocation: userObj.currentlocation,
                    lastseen: userObj.lastseen,
                    showdirectory: userObj.showdirectory,
                    officephone: userObj.officephone,
                    location: userObj.location,
                    locationtime: userObj.locationtime,
                    signindate: userObj.signindate,
                    data1: userObj.data1,
                    data2: userObj.data2,
                    data3: userObj.data3,
                    selectedBadge: userObj.data4,
                    data5: userObj.data5,
                    //q1: userObj.q1,   //added above
                    //q2: userObj.q2,
                    //q3: userObj.q3,
                    //q4: userObj.q4,
                    //q5: userObj.q5,
                    //q6: userObj.q6,
                    //q7: userObj.q7,
                    //q8: userObj.q8,
                    selectedUser: userObj.userstype,
                    guests: userObj.guests,
                    sessions: userObj.sessions,
                    regnotes: userObj.regnotes,
                    checkinstatus: userObj.checkinstatus,
                    mname: userObj.mname,
                    totalamount: userObj.totalamount,
                    amountpaid: userObj.amountpaid,
                    promoamount: userObj.promoamount,
                    pendingamount: userObj.pendingamount,
                    refundamount: userObj.refundamount,
                    paymentmode: userObj.paymentmode,
                    promocodes: userObj.promocodes,
                    guestname: userObj.guestname,
                    termsstatus: true,
                    dataloaded: true
                })
                global.$attendeemailid = userObj.email
                //console.log(this.state)
                // this.setState({ imageUrl: 'https://s3.amazonaws.com/event360live/public/' + this.state.code + '/exhibitors/' + this.state.photoFileName });
                //this.findImgFilename()
                //console.log(this.state.imageUrl);
                //console.log(this.state.selectedBadge);
                if (this.state.selectedUser == "Exhibitor" || this.state.selectedUser == "Exhibitor Admin") {
                    this.setState({ regitemsjson: JSON.parse(userObj.regitemsjson) })
                }

            }
            else {
                //////console.log("getExhibitorsTableObjects - failed!!");
                //////console.log(response.err);

            }

        }
        ).catch(error => {
            //////console.log(error.response)
        });
    }

    getUsers() {
        let apiName = serviceNames.getUserService();
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'code': this.state.code,
                'command': 'getUsersByCompany',
                'company': global.$company
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //console.log(myInit);

        API.post(apiName, path, myInit).then(response => {
            //console.log ("response.data ===> " + response.data + "response.err ===> " + response.err);
            //console.log(response.data.data.length);
            if (response.err == null && response.data != null) {
                //console.log("getAttendeesTableObjects - Success!!");
                //console.log(response.data);

                //attendees = this.getAttendeesTableObjects(response.data.data)
                //global.$attendeelist = attendees;
                //this.setState({dataloaded :true} )
                //this.setState({data :attendees} )
               //console.log(response.data)
                var currentrepcount = [];
                response.data.data.map(item=>{
                    if(item.userstype.S != "Exhibitor Admin" && item.userstype.S != "Other"){
                        currentrepcount.push(item);
                    }
                })
                //console.log(currentrepcount.length)
                this.setState({
                    representativecount: currentrepcount.length,
                    representativelist: response.data.data
                });

                //console.log(this.state.representativecount)
                //console.log(this.state.representativelist)
                //console.log(this.state.dataloaded);
                //console.log(this.state.data);
            }
            else {
                //console.log("getAttendeesTableObjects - failed!!");
                //console.log(response.err);

            }
        }
        ).catch(error => {
            //console.log(error.response)
        });
    }

    findImgFilename() {
        //console.log(global.$attendeejson);
        // global.$attendeejson.find(globalAttendee => {
        //  //console.log(globalAttendee);
        //   if (globalAttendee.userid == this.state.userid) {
        //     this.setState({
        //       imgid: globalAttendee.photo,
        //       fname: globalAttendee.fname,
        //       lname: globalAttendee.lname
        //     });

        //    //console.log(this.state.imgid)
        //     if (this.state.imgid == " " || this.state.imgid == "" || this.state.imgid == "common_profile.png" || this.state.imgid.includes("common_profile")) {
        //       this.setState({ imageurl: "common_profile.png" });
        //     }
        //     else {
        //       this.getS3PrivateImg();
        //     }
        //   }
        // })
        if (global.$attendeejson.photo == " " || global.$attendeejson.photo == "" || global.$attendeejson.photo == "common_profile.png" || global.$attendeejson.photo.includes("common_profile")) {
            this.setState({ imageurl: "common_profile.png" });
        }
        else {
            this.getS3PrivateImg();
        }
    }

    getUserObject(objs) {
        //var obj = objs[0] 
        //console.log(objs)

        var newObj = Object()

        newObj.userid = objs.userid.S
        newObj.fname = objs.fname.S
        newObj.lname = objs.lname.S
        newObj.photo = objs.photo.S
        newObj.company = objs.company.S
        newObj.phone = objs.phone.S
        newObj.title = objs.title.S
        newObj.email = objs.email.S
        newObj.country = objs.country.S
        newObj.state = objs.state.S
        newObj.street = objs.street.S
        newObj.city = objs.city.S
        newObj.state = objs.state.S
        newObj.zip = objs.zip.S
        newObj.linkedin = objs.linkedin.S
        newObj.twitter = objs.twitter.S
        newObj.fb = objs.fb.S
        newObj.regid = objs.regid.S
        newObj.regtype = objs.regtype.S
        newObj.memberid = objs.memberid.S
        newObj.visitpoints = objs.visitpoints.N
        newObj.activitypoints = objs.activitypoints.N
        newObj.totalpoints = objs.totalpoints.N
        newObj.sessionpoints = objs.sessionpoints.N
        newObj.regstatus = objs.regstatus.S
        newObj.password = objs.password.S
        newObj.myexpertise = objs.myexpertise.S
        newObj.myinterests = objs.myinterests.S
        newObj.validscans = objs.validscans.S
        newObj.officephone = objs.officephone.S
        newObj.currentlocation = objs.currentlocation.S
        newObj.lastseen = objs.lastseen.S
        newObj.showdirectory = objs.showdirectory.S
        newObj.location = objs.location.S
        newObj.locationtime = objs.locationtime.S
        newObj.signindate = objs.signindate.S
        newObj.data1 = objs.data1.S
        newObj.data2 = objs.data2.S
        newObj.data3 = objs.data3.S
        newObj.data4 = objs.data4.S
        newObj.data5 = objs.data5.S
        newObj.q1 = objs.q1.S
        newObj.q2 = objs.q2.S
        newObj.q3 = objs.q3.S
        newObj.q4 = objs.q4.S
        newObj.q5 = objs.q5.S
        newObj.q6 = objs.q6.S
        newObj.q7 = objs.q7.S
        newObj.q8 = objs.q8.S
        newObj.userstype = objs.userstype.S
        newObj.guests = objs.guests.S
        newObj.sessions = objs.sessions.S
        newObj.regnotes = objs.regnotes.S
        newObj.checkinstatus = objs.checkinstatus.S
        newObj.mname = objs.mname.S
        newObj.totalamount = objs.totalamount.N
        newObj.amountpaid = objs.amountpaid.N
        newObj.promoamount = objs.promoamount.N
        newObj.pendingamount = objs.pendingamount.N
        newObj.refundamount = objs.refundamount.N
        newObj.paymentmode = objs.paymentmode.S
        newObj.promocodes = objs.promocodes.S
        newObj.guestname = objs.guestname.S
        newObj.regitemsjson = objs.regitemsjson.S
        //console.log(newObj)
        //console.log(newObj.category);

        return newObj
    }

    deleteUser() {
        let apiName = serviceNames.getUserService();
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'command': 'deleteUserProfile',
                'code': this.state.code,
                'userid': this.state.userid,
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //////console.log(myInit);

        API.post(apiName, path, myInit).then(response => {
            ////console.log (response.data);

            if (response.err == null && response.data != null) {
                //////console.log(response.data.Items);
                this.setState({ dataloaded: true })
            }
            else {
                //////console.log("deleteExhibitorsTableObjects - failed!!");
                //////console.log(response.err);  
            }
        }
        ).catch(error => {
            //////console.log(error.response)
        });
    }
    getCategoriesTableObjects(objs) {
        //var obj = objs[0] 
        //console.log( objs)

        const tableObjs = []

        for (var i = 0; i < objs.length; i++) {
            var categoryObjs = objs[i]
            //console.log(categoryObjs)
            var newObj = Object()

            newObj.name = categoryObjs.name.S

            //console.log(newObj)


        }

        //console.log(tableObjs)

        return newObj
    }
    getValidScans() {
        let apiName = serviceNames.getEventPlannerService();
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'code': this.state.code,
                'command': 'getCategory',
                'type': this.state.command
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //console.log(myInit);

        API.post(apiName, path, myInit).then(response => {
            //console.log (response.data);

            if (response.err == null && response.data != null) {
                //console.log(response.data.Items);

                let categoryObj = this.getCategoriesTableObjects(response.data.data.Items)

                //console.log(categoryObj.name)
                //this.state.validscans = categoryObj.name
                this.setState({ validscans: categoryObj.name })
                //console.log(this.state.validscans)

            }
            else {
                //////console.log("getCategoriesTableObjects - failed!!");
                //////console.log(response.err);
            }

        }
        ).catch(error => {
            //////console.log(error.response)
        });
    }

    sendEmailToAttendee(inputs) {
        var date = new Date();
        var timestamp = date / 1000;

        if (this.state.password == "") {
            this.state.password = timestamp.longFormat.substring(longFormat.length - 8, longFormat.length);
        }
        if (this.state.fname == "") {
            this.state.fname = " "
        }
        if (this.state.regid == "") {
            this.state.regid = " "
        }
        if (this.state.regtype == "") {
            this.state.regtype = " "
        }

        let apiName = serviceNames.getEmailService();
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'code': this.state.code,
                //'command': 'sendAttendeeInstructions',
                command: 'sendTemplateEmail',
                templatekey: 'attendeeinstructions',
                'fname': this.state.fname,
                'regid': this.state.regid,
                'userid': this.state.userid,
                'email': this.state.email,
                'pwd': this.state.password
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //console.log(myInit);

        API.post(apiName, path, myInit).then(response => {
            //console.log (response.data);

            if (response.err == null && response.data != null) {

                this.state.message = "Email has sent successfully";

                this.setState({ sendingmail: false });

            }
            else {
                this.state.message = "Unable send email, please try again";
                //////console.log("getCategoriesTableObjects - failed!!");
                //console.log(response.err);
                this.setState({ sendingmail: false });
            }

        }
        ).catch(error => {
            //console.log(error.response)
        });
    }

}

export default RepresentativeEditor;
