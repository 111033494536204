import React from 'react';
import LoadPrivateImg from '../Components/Shared/LoadPrivateImg';

import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
const moment = require('moment-timezone');
class ChatMessage extends React.Component {
  constructor(props) {

    super(props);

    //console.log(global.$chat);
    this.state = {
      sentmessagestyle: global.$chat.styles.sentmessagestyle,
      receivedmessagestyle: global.$chat.styles.receivedmessagestyle
    };
    //console.log(this.state);
  }
  render() {

    // Was the message sent by the current user. If so, add a css class
    //const fromMe = this.props.fromMe ? 'from-me' : '';
    //const fromMe = '';
    var fromMe = 'empty';

    //console.log(global.$loginid);
    //console.log("senderid " + this.props.senderid);

    if (global.$loginid != null) {
      //console.log(global.$attendeeinfo)
      if (global.$loginid == this.props.senderid) {
        fromMe = 'from-me'
      }
    }

    //console.log(this.props)
    var d = new Date(this.props.messageid * 1000);

    var dateFormatter = moment(d).format('MMM D, h:mm A');
    //console.log(dateFormatter);


    return (

      <div>
        {
          fromMe.includes('empty') ?
            (
              <div className={`message`}>
                <div className="main-div">
                  <div className="sub-div1 session-chat-div1">
                    <LoadPrivateImg id={this.props.senderid} name={this.props.sendername} component="chat" code={global.$code} height="40" width="40" />
                  </div>
                  <div className="sub-div2">
                    <div className='username'>
                      {this.props.sendername}
                    </div>
                    <div className='message-body' style={this.state.receivedmessagestyle}>
                      {ReactHtmlParser(this.props.message)}
                    </div>
                    <div className={`message-date message-date`}>
                      {dateFormatter}
                    </div>
                  </div>
                </div>
              </div>
            )
            :
            (
              <div className={`message ${fromMe}`}>
                <div className="sub-div-from-me">
                  <div className={`message-body-${fromMe}`} style={this.state.sentmessagestyle}>
                    {ReactHtmlParser(this.props.message)}
                  </div>
                </div>
                <div className={`message-date message-date-${fromMe}`}>
                  {dateFormatter}
                </div>
                <br />
              </div>
            )
        }
      </div>
    );
  }
}

/*
ChatMessage.defaultProps = {
  message: '',
  username: '',
  fromMe: false
};
*/

export default ChatMessage;