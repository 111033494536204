/* eslint-disable */
import React, { Component } from 'react';
import Icon from '@mdi/react';
import { mdiPlus } from '@mdi/js';
import { API } from 'aws-amplify';
import { Col, Row } from 'reactstrap';
var serviceNames = require('../ServiceNameMapper');

class FindExhibitorMeetup extends React.Component {
    constructor(props) {

        super(props);

        this.state = {
            code: global.$code,
            bucket: global.$s3bucket,
            dataloaded: false,
            allmeetups: []
        };
    }


    render() {
        var meetupconfig = global.$config["meetups"];
       //console.log(meetupconfig);
        if (this.state.dataloaded) {
            return (
                <div>
                    <Row>
                        {this.displayExhibitorMeetup()}
                    </Row>
                </div>
            );
        }
        else {
            if (global.$exhibitorjson != null) {
                this.setState({ dataloaded: true });
            }
            else {
                this.getExhibitorJson();
            }

            return null;
        }
    }

    displayExhibitorMeetup() {
       //console.log(global.$exhibitorjson);
       //console.log(this.state.allmeetups);
        var allexhibitors = global.$exhibitorjson;
        var allmeetups = this.props.meetups;
        var exhibitormeetups = [];

        allexhibitors.filter(exhibitor => {
           //console.log(exhibitor.EMAIL);
            allmeetups.map(meetup => {
               //console.log(meetup.ownerid);
               //console.log(exhibitor.EMAIL == meetup.ownerid);
                if (exhibitor.EMAIL == meetup.ownerid) {
                    var newObj = new Object()
                    newObj.logo = exhibitor.LOGO
                    newObj.company = exhibitor.NAME
                    newObj.title = meetup.title
                    newObj.ownerid = meetup.ownerid
                    newObj.begintime = meetup.begintime
                    newObj.endtime = meetup.endtime
                    newObj.status = meetup.meetupstatus
                    newObj.signuprequired = meetup.signuprequired
                    newObj.signupmax = meetup.signupmax.N
                    newObj.signupcount = meetup.signupcount.N
                    newObj.connectiontype = meetup.connectiontype
                    newObj.connectionurl = meetup.connectionurl
                    newObj.desc = meetup.description

                    exhibitormeetups.push(newObj);
                }
                else {
                   //console.log(meetup);
                }
            })
        })

       //console.log(exhibitormeetups)
        var uniqueexhibitormeetups = [...new Set(exhibitormeetups)];
       //console.log(uniqueexhibitormeetups)

        return uniqueexhibitormeetups.map(meetup => {
            var image = "";
            if (meetup.logo == "profile.png" || meetup.logo == " " || meetup.logo == "") {
                image = "empty";
            }
            else {
                image = `https://s3.amazonaws.com/event360live/public/${global.$code}/exhibitors/` + meetup.logo
            }

            return (
                <div className="meetup-card-div">
                    <Col md={12}>
                        <div className="meetup-card-img-div">
                        {
                            image.includes("empty") ?
                                (
                                    <div>
                                        <Avatar className="meetup-card-avatar" name={`${meetup.company}`} />
                                    </div>
                                )
                                :
                                (
                                    <div>
                                        <img src={image} className="meetup-card-img"/>
                                    </div>
                                )
                        }
                        </div>
                        <div className="meetup-card-name">
                            {meetup.title}
                        </div>
                    </Col>
                </div>
            )
        });
    }

    getExhibitorJson() {
        ////console.log("sponsor json")
        let apiName = serviceNames.getWebsiteService();
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'code': this.state.code,
                'command': 'getJsonFileFromS3',
                'bucket': this.state.bucket,
                'filename': "exhibitors"
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
       //console.log(myInit);

        API.post(apiName, path, myInit).then(response => {
            //console.log ("response.data ===> " + response.data + "response.err ===> " + response.err);

            if (response.err == null && response.data != null) {
                let buf = Buffer.from(response.data.Body);
                //console.log(buf)
                //console.log(buf.toString());
                global.$exhibitorjson = JSON.parse(buf.toString());
                this.setState({ dataloaded: true });

            }
            else {
                ////console.log("getAttendeesTableObjects - failed!!");
                //console.log(response.err);
            }
        }
        ).catch(error => {
            ////console.log(error.response)
        });

    }

} //Class

export default FindExhibitorMeetup;