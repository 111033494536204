/*
  This component is calling from both exhibitors and sponsors to play the videos
*/

import React from 'react';
import { Button } from 'reactstrap';
import './Sponsors.css';
import ReactPlayer from 'react-player';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

const moment = require('moment-timezone');
class SponsorVideoChat extends React.Component {
    constructor(props) {
        super(props);
        var styles = '';
        if (global.$sponsors.videochatbuttonstyles == null) {
            styles = global.$sponsors.playvideobuttonstyles
        }
        else {
            styles = global.$sponsors.videochatbuttonstyles
        }

        this.state =
        {
            chaturl: props.chaturl,
            open: false,
            videochatbuttonstyles: styles,
            schedule: props.schedule,
            begindate: '',
            enddate: '',
            scheduleopen: false,
            timestyle: global.$appointments.styles.time
        }
        //console.log(props)
        this.onSubmit = this.onSubmit.bind(this);

    }

    onOpenVideoChat = () => {
        this.setState(prevState => ({
            open: !prevState.open
        }));
    };
    onSubmit()
    {
        this.startTimer();
    }
    onOpenSchedule = () => {
        this.setState(prevState => ({
            scheduleopen: !prevState.scheduleopen
        }));
    };

    render() {
        if (this.state.chaturl !== "" && this.state.schedule !== "") {
            return (
                <div>
                    <Button className="btn-video-chat" style={this.state.videochatbuttonstyles} onClick={this.onSubmit}>Video Chat</Button>
                    {/*<Button className="btn-video-chat" hidden={this.state.hideschedule} style={this.state.videochatbuttonstyles} onClick={this.onOpenSchedule}>Video Chat Schedule</Button>*/}
                    <Modal
                        open={this.state.open}
                        onClose={this.onOpenVideoChat}
                        styles={{
                            modal: {
                                width: "100%"
                            }
                        }}
                        center
                    >
                        <iframe src={this.state.chaturl}
                            id="myId"
                            className="myClassname"
                            display="initial"
                            position="relative"
                            width="100%"
                            height="calc(80vh - 100px)"
                            allow="camera *;microphone *"
                        />
                    </Modal>
                    <Modal
                        open={this.state.scheduleopen}
                        onClose={this.onOpenSchedule}
                        styles={{
                            modal: {
                                width: "100%"
                            }
                        }}
                        center
                    >
                        <div className="sponsor-schedule-div" style={this.state.timestyle}>
                            <p className="sponsor-schedule-header">Schedule</p>
                            <p className="sponsor-schedule-time offset-md-1">{ReactHtmlParser(this.state.begindate)}</p>
                        </div>
                    </Modal>
                </div>
            )
        }
        else {
            return (
                <div></div>
            )
        }
    }
    startTimer() {
        var json = JSON.parse(this.state.schedule);
        var scheduledata = [];
        json.forEach((item) => {
            var edate = moment(item.End).format('LT')
            scheduledata.push(item.Begin + " - " + edate + "<br>");
        });
        //console.log(json);
        var results = json.forEach((item) => {
           //console.log(item);
            var beginDate = Date.parse(item.Begin);

            var currentdate = moment().tz(`${global.$settings.timezone}`).format('L LTS');
            var presentDate = Date.parse(currentdate);

            var endDate = Date.parse(item.End);
           //console.log(presentDate >= beginDate, presentDate <= endDate);

            if (presentDate >= beginDate && presentDate <= endDate) {
               //console.log("chat");
                return (
                    this.onOpenVideoChat()
                )
            }
            else {
               //console.log("schedule");
                this.setState({begindate: scheduledata});
                return (
                    this.onOpenSchedule()
                )
            }
        })
        return results;
    }
}
export default SponsorVideoChat;
