import React from 'react';
import LoadPrivateImg from '../Components/Shared/LoadPrivateImg';
import PostImage from '../Posts/PostImage';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { Row, Col, Button } from 'reactstrap';
import Modal from "react-responsive-modal";
import ConversationsInput from './ConversationInput';
import { API } from 'aws-amplify';
import ConversationView from './ConversationView';
import Icon from '@mdi/react';
import { mdiCircle } from '@mdi/js';
import Avatar from 'react-avatar';

const moment = require('moment-timezone');
class ConversationsItemView extends React.Component {
  constructor(props) {

    super(props);

    //console.log(global.$chat);
    this.state = {
      sentmessagestyle: global.$chat.styles.sentmessagestyle,
      receivedmessagestyle: global.$chat.styles.receivedmessagestyle,
      openpopup: false,
      conversation: [],
      showp1newmessageindicator: false,
      showp2newmessageindicator: false,
      imgurl: ''
    };
  }

  onOpenPopup = (conversations, senderid) => {
    //console.log('onopenpopup');
    if(this.state.openpopup == false)
    {
      if(conversations.p1 == global.$userid && conversations.p1newmsg == "false")
      {
        this.updateNewMsgInConversations(senderid)
      }
      else if(conversations.p2 == global.$userid && conversations.p2newmsg == "false")
      {
        this.updateNewMsgInConversations(senderid)
      }
    }
    this.setState(prevState => ({
      openpopup: !prevState.openpopup
    }));
  };
  
  render() {
    
    var styles = global.$attendees.profile2.styles.conversations;
    //console.log(this.props)
    //console.log(this.props.conversations);

    var d = new Date(this.props.conversations.lupdated * 1000);
    var dateFormatter = moment(d).format('MMM D, h:mm A')
    //console.log(dateFormatter);
    if (this.props.conversations.p2.includes(global.$userid)) {
      if (this.props.conversations.p1newmsg == "true" && this.props.conversations.p2newmsg == "false") {
        this.state.showp1newmessageindicator = true;
      }
      else {
        this.state.showp1newmessageindicator = false;
      }

      if (this.props.conversations.cstatus == "I") {
        return (
          <div className="conversations-main-div">
            {
              <div className={`message`}>
                <div className="main-div">
                  <div className="conversations-list-img-div">
                      <LoadPrivateImg id={this.props.conversations.p1} name={this.props.conversations.p1name} component="attendee-conversations" code={global.$code} height="40" width="40" />
                      {/*<Avatar className="attendee-conversations-avatar" name={`${this.props.conversations.p1name}`} />*/}
                  </div>
                  <div className="conversations-list-info-div">
                    <div className='attendee-conversations-username' style={styles.name}>
                      {this.props.conversations.p1name}
                    </div>
                    <div className={`message-date message-date`} style={styles.date}>
                      {dateFormatter}
                    </div>
                    <div className={`last-message`} style={styles.message}>
                      {ReactHtmlParser(this.props.conversations.lmsg)}
                    </div>
                  </div>
                  <div className="sent-invitation-button offset-md-2">
                    <Button className="btn accept-button" style={styles.acceptbtn} onClick={() => this.submitInvitation("A")}>Accept</Button>
                  </div>
                  <div className="sent-decline-button offset-md-1">
                    <Button className="btn decline-button" style={styles.declinebtn} onClick={() => this.submitInvitation("D")}>Decline</Button>
                  </div>
                </div>
                <hr />
              </div>
            }
          </div>
        )
      }
      else if (this.props.conversations.cstatus == "A") {
        return (

          <div className="conversations-main-div">
            {
              <div className={`message`} onClick={() => this.onOpenPopup(this.props.conversations,this.props.conversations.p2)}>
                <div className="main-div">
                  <div className="conversations-list-img-div">
                      <LoadPrivateImg id={this.props.conversations.p1} name={this.props.conversations.p1name} component="attendee-conversations" code={global.$code} height="40" width="40" />
                      {/*<Avatar className="attendee-conversations-avatar" name={`${this.props.conversations.p1name}`} />*/}
                  </div>
                  <div className="conversations-list-info-div">
                    <div className='attendee-conversations-username' style={styles.name}>
                      {this.props.conversations.p1name}
                    </div>
                    <div className={`message-date`} style={styles.date}>
                      {dateFormatter}
                    </div>
                    <div className={`last-message`} style={styles.message}>
                      {/*{ReactHtmlParser(this.props.conversations.lmsg)}*/}
                      {
                        this.state.showp1newmessageindicator == true ?
                          (
                            <div className="attendee-conversarion-new-msg-div">
                              {ReactHtmlParser(this.props.conversations.lmsg)}<p className="attendee-conversarion-new-msg">New</p>
                            </div>
                          )
                          :
                          (
                            ReactHtmlParser(this.props.conversations.lmsg)
                          )
                      }
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            }
            <Modal
              open={this.state.openpopup}
              onClose={this.onOpenPopup}
              blockScroll={false}
              className="conversation-modal-popup"
              styles={{
                modal: {
                  width: "70%"
                }
              }}
              center>
              <div className="conversations-popup">
                <ConversationView type="conversation" loginid={this.props.conversations.p2} delegateid={this.props.conversations.p1} delegatename={this.props.conversations.p1name} conversations={this.props.conversations} />
              </div>
            </Modal>
          </div>
        );
      }
      else if (this.props.conversations.cstatus == "D") {
        return (
          <div className="conversations-main-div">
            {
              <div className={`message`}>
                <div className="main-div">
                  <div className="conversations-list-img-div">
                      <LoadPrivateImg id={this.props.conversations.p1} name={this.props.conversations.p1name} component="attendee-conversations" code={global.$code} height="40" width="40" />
                      {/*<Avatar className="attendee-conversations-avatar" name={`${this.props.conversations.p1name}`} />*/}
                  </div>
                  <div className="conversations-list-info-div">
                    <div className='attendee-conversations-username' style={styles.name}>
                      {this.props.conversations.p1name}
                    </div>
                    <div className={`message-date message-date`} style={styles.date}>
                      {dateFormatter}
                    </div>
                    <div className={`last-message`} style={styles.message}>
                      {ReactHtmlParser(this.props.conversations.lmsg)}
                    </div>
                  </div>
                  <div className="sent-declined-button offset-md-4">
                    <Button className="btn declined-button" style={styles.declinedbtn} disabled>Declined</Button>
                  </div>
                </div>
                <hr />
              </div>
            }
          </div>
        )
      }
    }
    else if (this.props.conversations.p1.includes(global.$userid)) {
      if (this.props.conversations.p2newmsg == "true" && this.props.conversations.p1newmsg == "false") {
        this.state.showp2newmessageindicator = true;
      }
      else {
        this.state.showp2newmessageindicator = false;
      }
      if (this.props.conversations.cstatus == "I") {
        //console.log(this.props.conversations);
        return (
          <div className="conversations-main-div">
            {
              <div className={`message`}>
                <div className="main-div">
                  <div className="conversations-list-img-div">
                    {/*<LoadPrivateImg id={this.props.conversations.p2} name={this.props.conversations.p2name} component="attendee-conversations" code={global.$code} height="40" width="40" />*/}
                    <Avatar className="attendee-conversations-avatar" name={`${this.props.conversations.p2name}`} />
                  </div>
                  <div className="conversations-list-info-div">
                    <div className='attendee-conversations-username' style={styles.name}>
                      {this.props.conversations.p2name}
                    </div>
                    <div className={`message-date message-date`} style={styles.date}>
                      {dateFormatter}
                    </div>
                    <div className={`last-message`} style={styles.message}>
                      {ReactHtmlParser(this.props.conversations.lmsg)}
                    </div>
                  </div>
                  <div className="invited-button offset-md-4">
                    <Button className="btn invited-btn" style={styles.invitedbtn} disabled>Invited</Button>
                  </div>
                </div>
                <hr />
              </div>
            }
          </div>
        )
      }
      else if (this.props.conversations.cstatus == "A") {
        return (

          <div className="conversations-main-div">
            {
              <div className={`message`} onClick={() => this.onOpenPopup(this.props.conversations,this.props.conversations.p1)}>
                <div className="main-div">
                  <div className="conversations-list-img-div">
                    <LoadPrivateImg id={this.props.conversations.p2} name={this.props.conversations.p2name} component="attendee-conversations" code={global.$code} height="40" width="40" />
                      {/*<Avatar className="attendee-conversations-avatar" name={`${this.props.conversations.p2name}`} />*/}
                  </div>
                  <div className="conversations-list-info-div">
                    <div className='attendee-conversations-username' style={styles.name}>
                      {this.props.conversations.p2name}
                    </div>
                    <div className={`message-date message-date`} style={styles.date}>
                      {dateFormatter}
                    </div>
                    <div className={`last-message`} style={styles.message}>
                      {/*{ReactHtmlParser(this.props.conversations.lmsg)}*/}
                      {
                        this.state.showp2newmessageindicator == true ?
                          (
                            <div className="attendee-conversarion-new-msg-div">
                              {ReactHtmlParser(this.props.conversations.lmsg)}<p className="attendee-conversarion-new-msg">New</p>
                            </div>
                          )
                          :
                          (
                            ReactHtmlParser(this.props.conversations.lmsg)
                          )
                      }
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            }
            <Modal
              open={this.state.openpopup}
              onClose={this.onOpenPopup}
              blockScroll={false}
              className="conversation-modal-popup"
              styles={{
                modal: {
                  width: "70%"
                }
              }}
              center>
              <div className="conversations-popup">
                <ConversationView type="conversation" loginid={this.props.conversations.p1} delegateid={this.props.conversations.p2} delegatename={this.props.conversations.p2name} conversations={this.props.conversations} />
              </div>
            </Modal>
          </div>
        );
      }
      else if (this.props.conversations.cstatus == "D") {
        return (
          <div className="conversations-main-div">
            {
              <div className={`message`}>
                <div className="main-div">
                  <div className="conversations-list-img-div">
                    <LoadPrivateImg id={this.props.conversations.p2} name={this.props.conversations.p2name} component="attendee-conversations" code={global.$code} height="40" width="40" />
                      {/*<Avatar className="attendee-conversations-avatar" name={`${this.props.conversations.p2name}`} />*/}
                  </div>
                  <div className="conversations-list-info-div">
                    <div className='attendee-conversations-username' style={styles.name}>
                      {this.props.conversations.p2name}
                    </div>
                    <div className={`message-date message-date`} style={styles.date}>
                      {dateFormatter}
                    </div>
                    <div className={`last-message`} style={styles.message}>
                      {ReactHtmlParser(this.props.conversations.lmsg)}
                    </div>
                  </div>
                  <div className="sent-declined-button offset-md-4">
                    <Button className="btn declined-button" style={styles.declinedbtn} disabled>Declined</Button>
                  </div>
                </div>
                <hr />
              </div>
            }
          </div>
        )
      }
    }
    else {
      return (
        <div>

        </div>
      )
    }
  }
  submitInvitation(cstatus) {
    this.acceptInvitation(cstatus);
  }
  acceptInvitation(cstatus) {
    let apiName = 'ChatService';
    let path = ''; //replace this with the path you have configured on your API
    var date = new Date();
    var timestamp = date / 1000;
    var command = '';
    if (cstatus == "A") {
      command = "acceptInvite";
    }
    else if (cstatus == "D") {
      command = "declineInvite";
    }
    let myInit = {
      body:
      {
        'code': global.$code,
        'command': command,
        'cid': this.props.conversations.cid,
        'cstatus': cstatus
      },
      response: true,
      convertEmptyValues: true,
      headers: {}
    }

    //console.log(myInit);
    //console.log('ChatMessages:getMessages()');

    API.post(apiName, path, myInit).then(response => {
      //console.log(response);

      this.props.reloadHandler();
      if (response.err == null && response.data != null) {
        //console.log(response.data.Items);
      }
      else {
        //console.log(response.err);
      }
    }
    ).catch(error => {
      //console.log(error)
    });
  }

  updateNewMsgInConversations(senderid) {
    let apiName = 'ChatService';
    let path = '';

    let myInit =
    {
      body:
      {
        'code': global.$code,
        'command': 'updateNewMsgInConversation',
        'cid': this.props.conversations.cid,
        'senderid': senderid,
        'p1': this.props.conversations.p1,
        'p2': this.props.conversations.p2,
        'type' : "onclick"
      },
      response: true,
      headers: {}
    }
   //console.log(myInit);
    API.post(apiName, path, myInit).then(response => {
      //console.log(response);
      if (response.err == null) {

      }
      else {

      }

    }
    ).catch(error => {
      //console.log(error);
    });
  }
 
}


export default ConversationsItemView;