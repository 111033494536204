import React from 'react';
import { Row, Col } from 'reactstrap';

import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
import { FacebookProvider, Like, Feed, Page } from 'react-facebook';

class SocialMediaFeeds extends React.Component {
    constructor(props) {
        //console.log(props.match.params.value)
        super(props);
        this.state = {
            facebookpagestyle: global.$home.socialmediafeed.facebook.styles,
            instagrampagestyle: global.$home.socialmediafeed.instagram.styles
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <div>
                <div>
                    {
                        global.$home.socialmediafeed.numberoffeeds.includes('1') ?
                            (
                                <div>
                                    {
                                        global.$home.socialmediafeed.feedtitles.includes("twitter") ?
                                            (
                                                <div>
                                                    <Row>
                                                        <Col>
                                                            <TwitterTimelineEmbed
                                                                sourceType="profile"
                                                                screenName="saurabhnemade"
                                                                options={{ height: 400 }}
                                                                url={global.$home.socialmediafeed.twitter.link}//https://twitter.com/@westernhardwood
                                                            />
                                                        </Col>
                                                    </Row>
                                                </div>
                                            ) :
                                            ('')
                                    }
                                    {
                                        global.$home.socialmediafeed.feedtitles.includes("facebook") ?
                                            (
                                                <div>
                                                    <Row>
                                                        <Col>
                                                            <FacebookProvider appId={global.$home.socialmediafeed.facebook.appid}>
                                                                <Page href="https://www.facebook.com/apps4Org" tabs="timeline" style={this.state.facebookpagestyle}/>
                                                            </FacebookProvider>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            ) :
                                            ('')
                                    }
                                    {
                                        global.$home.socialmediafeed.feedtitles.includes("instagram") ?
                                            (
                                                <div>
                                                    <Row>
                                                        <Col>
                                                            <instagram-feed user={global.$home.socialmediafeed.instagram.user} token={global.$home.socialmediafeed.instagram.token} style={this.state.instagrampagestyle}></instagram-feed>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            ) :
                                            ('')
                                    }
                                </div>
                            )
                            :
                            ('')
                    }
                    {
                        global.$home.socialmediafeed.numberoffeeds.includes('2') ?
                            (
                                <div>
                                    {
                                        global.$home.socialmediafeed.feedtitles.includes('twitter,facebook') ?
                                            (
                                                <Row>
                                                    <Col className="offset-md-1" md={5}>
                                                        <TwitterTimelineEmbed
                                                            sourceType="profile"
                                                            screenName="saurabhnemade"
                                                            options={{ height: 400 }}
                                                            url={global.$home.socialmediafeed.twitter.link}//https://twitter.com/@westernhardwood
                                                        />
                                                    </Col>
                                                    <Col md={5}>
                                                        <FacebookProvider appId={global.$home.socialmediafeed.facebook.appid}>
                                                            <Page href="https://www.facebook.com/apps4Org" tabs="timeline" style={this.state.facebookpagestyle}/>
                                                        </FacebookProvider>
                                                    </Col>
                                                </Row>
                                            )
                                            :
                                            ('')
                                    }
                                    {
                                        global.$home.socialmediafeed.feedtitles.includes('facebook,twitter') ?
                                            (
                                                <Row>
                                                    <Col className="offset-md-1" md={5}>
                                                        <FacebookProvider appId={global.$home.socialmediafeed.facebook.appid}>
                                                            <Page href="https://www.facebook.com/apps4Org" tabs="timeline" style={this.state.facebookpagestyle}/>
                                                        </FacebookProvider>
                                                    </Col>
                                                    <Col md={5}>
                                                        <TwitterTimelineEmbed
                                                            sourceType="profile"
                                                            screenName="saurabhnemade"
                                                            options={{ height: 400 }}
                                                            url={global.$home.socialmediafeed.twitter.link}//https://twitter.com/@westernhardwood
                                                        />
                                                    </Col>
                                                </Row>
                                            )
                                            :
                                            ('')
                                    }
                                    {
                                        global.$home.socialmediafeed.feedtitles.includes('twitter,instagram') ?
                                            (
                                                <Row>
                                                    <Col className="offset-md-1" md={5}>
                                                        <TwitterTimelineEmbed
                                                            sourceType="profile"
                                                            screenName="saurabhnemade"
                                                            options={{ height: 400 }}
                                                            url={global.$home.socialmediafeed.twitter.link}//https://twitter.com/@westernhardwood
                                                        />
                                                    </Col>
                                                    <Col md={5}>
                                                        <instagram-feed user={global.$home.socialmediafeed.instagram.user} token={global.$home.socialmediafeed.instagram.token} style={this.state.instagrampagestyle}></instagram-feed>
                                                    </Col>
                                                </Row>
                                            )
                                            :
                                            ('')
                                    }
                                    
                                    {
                                        global.$home.socialmediafeed.feedtitles.includes('instagram,twitter') ?
                                            (
                                                <Row>
                                                    <Col className="offset-md-1" md={5}>
                                                        <instagram-feed user={global.$home.socialmediafeed.instagram.user} token={global.$home.socialmediafeed.instagram.token} style={this.state.instagrampagestyle}></instagram-feed>
                                                    </Col>
                                                    <Col md={5}>
                                                        <TwitterTimelineEmbed
                                                            sourceType="profile"
                                                            screenName="saurabhnemade"
                                                            options={{ height: 400 }}
                                                            url={global.$home.socialmediafeed.twitter.link}//https://twitter.com/@westernhardwood
                                                        />
                                                    </Col>
                                                </Row>
                                            )
                                            :
                                            ('')
                                    }
                                    {
                                        global.$home.socialmediafeed.feedtitles.includes('facebook,instagram') ?
                                            (
                                                <Row>
                                                    <Col className="offset-md-1" md={5}>
                                                        <FacebookProvider appId={global.$home.socialmediafeed.facebook.appid}>
                                                            <Page href="https://www.facebook.com/apps4Org" tabs="timeline" style={this.state.facebookpagestyle}/>
                                                        </FacebookProvider>
                                                    </Col>
                                                    <Col md={5}>
                                                        <instagram-feed user={global.$home.socialmediafeed.instagram.user} token={global.$home.socialmediafeed.instagram.token} style={this.state.instagrampagestyle}></instagram-feed>
                                                    </Col>
                                                </Row>
                                            )
                                            :
                                            ('')
                                    }
                                    {
                                        global.$home.socialmediafeed.feedtitles.includes('instagram,facebook') ?
                                            (
                                                <Row>
                                                    <Col className="offset-md-1" md={5}>
                                                        <instagram-feed user={global.$home.socialmediafeed.instagram.user} token={global.$home.socialmediafeed.instagram.token} style={this.state.instagrampagestyle}></instagram-feed>
                                                    </Col>
                                                    <Col md={5}>
                                                        <FacebookProvider appId={global.$home.socialmediafeed.facebook.appid}>
                                                            <Page href="https://www.facebook.com/apps4Org" tabs="timeline" style={this.state.facebookpagestyle}/>
                                                        </FacebookProvider>
                                                    </Col>
                                                </Row>
                                            )
                                            :
                                            ('')
                                    }
                                </div>
                            )
                            :
                            ('')

                    }

                    {
                        global.$home.socialmediafeed.numberoffeeds.includes('3') ?
                            (
                                <div>
                                    {
                                        global.$home.socialmediafeed.feedtitles.includes('twitter,facebook,instagram') ?
                                            (
                                                <Row>
                                                    <Col md={4}>
                                                        <TwitterTimelineEmbed
                                                            sourceType="profile"
                                                            screenName="saurabhnemade"
                                                            options={{ height: global.$home.socialmediafeed.twitter.height }}
                                                            url={global.$home.socialmediafeed.twitter.link}//https://twitter.com/@westernhardwood
                                                        />
                                                    </Col>
                                                    <Col md={4}>
                                                        <FacebookProvider appId={global.$home.socialmediafeed.facebook.appid}>
                                                            <Page href="https://www.facebook.com/apps4Org" tabs="timeline" style={this.state.facebookpagestyle}/>
                                                        </FacebookProvider>
                                                    </Col>
                                                    <Col md={4}>
                                                        <instagram-feed user={global.$home.socialmediafeed.instagram.user} token={global.$home.socialmediafeed.instagram.token} style={this.state.instagrampagestyle}></instagram-feed>
                                                    </Col>
                                                </Row>
                                            )
                                            :
                                            ('')
                                    }
                                    {
                                        global.$home.socialmediafeed.feedtitles.includes('facebook,twitter,instagram') ?
                                            (
                                                <Row>
                                                    <Col md={4}>
                                                        <FacebookProvider appId={global.$home.socialmediafeed.facebook.appid}>
                                                            <Page href="https://www.facebook.com/apps4Org" tabs="timeline" style={this.state.facebookpagestyle}/>
                                                        </FacebookProvider>
                                                    </Col>
                                                    <Col md={4}>
                                                        <TwitterTimelineEmbed
                                                            sourceType="profile"
                                                            screenName="saurabhnemade"
                                                            options={{ height: 400 }}
                                                            url={global.$home.socialmediafeed.twitter.link}//https://twitter.com/@westernhardwood
                                                        />
                                                    </Col>
                                                    <Col md={4}>
                                                        <instagram-feed user={global.$home.socialmediafeed.instagram.user} token={global.$home.socialmediafeed.instagram.token} style={this.state.instagrampagestyle}></instagram-feed>
                                                    </Col>
                                                </Row>
                                            )
                                            :
                                            ('')
                                    }
                                    {
                                        global.$home.socialmediafeed.feedtitles.includes('instagram,facebook,twitter') ?
                                            (
                                                <Row>
                                                    <Col md={4}>
                                                        <instagram-feed user={global.$home.socialmediafeed.instagram.user} token={global.$home.socialmediafeed.instagram.token} style={this.state.instagrampagestyle}></instagram-feed>
                                                    </Col>
                                                    <Col md={4}>
                                                        <FacebookProvider appId={global.$home.socialmediafeed.facebook.appid}>
                                                            <Page href="https://www.facebook.com/apps4Org" tabs="timeline" style={this.state.facebookpagestyle}/>
                                                        </FacebookProvider>
                                                    </Col>
                                                    <Col md={4}>
                                                        <TwitterTimelineEmbed
                                                            sourceType="profile"
                                                            screenName="saurabhnemade"
                                                            options={{ height: 400 }}
                                                            url={global.$home.socialmediafeed.twitter.link}//https://twitter.com/@westernhardwood
                                                        />
                                                    </Col>
                                                </Row>
                                            )
                                            :
                                            ('')
                                    }
                                    {
                                        global.$home.socialmediafeed.feedtitles.includes('instagram,twitter,facebook') ?
                                            (
                                                <Row>
                                                    <Col md={4}>
                                                        <instagram-feed user={global.$home.socialmediafeed.instagram.user} token={global.$home.socialmediafeed.instagram.token} style={this.state.instagrampagestyle}></instagram-feed>
                                                    </Col>
                                                    <Col md={4}>
                                                        <TwitterTimelineEmbed
                                                            sourceType="profile"
                                                            screenName="saurabhnemade"
                                                            options={{ height: 400 }}
                                                            url={global.$home.socialmediafeed.twitter.link}//https://twitter.com/@westernhardwood
                                                        />
                                                    </Col>
                                                    <Col md={4}>
                                                        <FacebookProvider appId={global.$home.socialmediafeed.facebook.appid}>
                                                            <Page href="https://www.facebook.com/apps4Org" tabs="timeline" style={this.state.facebookpagestyle}/>
                                                        </FacebookProvider>
                                                    </Col>
                                                </Row>
                                            )
                                            :
                                            ('')
                                    }
                                    {
                                        global.$home.socialmediafeed.feedtitles.includes('twitter,instagram,facebook') ?
                                            (
                                                <Row>
                                                    <Col md={4}>
                                                        <TwitterTimelineEmbed
                                                            sourceType="profile"
                                                            screenName="saurabhnemade"
                                                            options={{ height: 400 }}
                                                            url={global.$home.socialmediafeed.twitter.link}//https://twitter.com/@westernhardwood
                                                        />
                                                    </Col>
                                                    <Col md={4}>
                                                        <instagram-feed user={global.$home.socialmediafeed.instagram.user} token={global.$home.socialmediafeed.instagram.token} style={this.state.instagrampagestyle}></instagram-feed>
                                                    </Col>
                                                    <Col md={4}>
                                                        <FacebookProvider appId={global.$home.socialmediafeed.facebook.appid}>
                                                            <Page href="https://www.facebook.com/apps4Org" tabs="timeline" style={this.state.facebookpagestyle}/>
                                                        </FacebookProvider>
                                                    </Col>
                                                </Row>
                                            )
                                            :
                                            ('')
                                    }
                                </div>
                            )
                            :
                            ('')
                    }
                </div>
            </div>
        )
    }
}
export default SocialMediaFeeds;
