/* eslint-disable */

import React from 'react';
import { Row, Col, Input } from 'reactstrap';
import AuctionItem from './AuctionItem.jsx';
import { API } from 'aws-amplify';
var serviceNames = require('../ServiceNameMapper');

class AuctionsItems extends React.Component {
    constructor(props) {

        super(props);
        this.state = {
            code: props.code,
            dataloaded: false,
            search: ''
        };

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        let value = target.value

        //console.log"Target = " + name + ", Value = " + value);

        this.setState({ [name]: value });
    }

    render() {
        if (this.state.dataloaded) {
            return (
                <div>
                    <br/>
                    <div className="auctions-search-div">
                        <Input type="text" name="search" className="auctions-search" value={this.state.search || ""} onChange={this.handleInputChange} placeholder="Search"></Input>
                    </div>
                    <br/>
                    <br/>
                    <div>
                        <br/>
                        {this.displayAuctionItems()}
                    </div>
                </div>
            )
        }
        else {
            this.getAuctionItems();
            return (
                <div>
                    <Row>
                        <Col md={6} className="offset-5">
                            <div className="loading-div">
                                <div className="loading-text">Loading....</div>
                            </div>
                        </Col>
                    </Row>
                </div>
            )
        }
    }

    displayAuctionItems() {

       //console.log(global.$loginstatus)
        return global.$auctionitemsjson.map(auctionitem => {
            {/* Before Search*/}
            if (this.state.search == '') {
                if (global.$loginstatus.includes(false)) {
                    return (
                        <div key={auctionitem.itemid} className="auctionitemms-before-login">{/* Before login UI*/}
                            <br/>
                            <AuctionItem itemid={auctionitem.itemid} code={this.state.code} />
                        </div>
                    )
                }
                else {
                    return (
                        <div key={auctionitem.itemid}>{/* After login UI*/}
                            <Row>
                                <Col md={10} className="offset-md-1">
                                    <AuctionItem itemid={auctionitem.itemid} code={this.state.code} />
                                </Col>
                            </Row>
                        </div>
                    )
                }
            }
            else if (this.state.search !== "") {
                {/* Searching..*/}
                if (global.$loginstatus.includes(false)) {
                    return (
                        <div key={auctionitem.itemid} className="auctionitemms-before-login">{/* Before login UI*/}
                            {
                                auctionitem.title.toLocaleLowerCase().includes(this.state.search.toLocaleLowerCase()) ?
                                    (
                                        <AuctionItem itemid={auctionitem.itemid} code={this.state.code} />
                                    )
                                    :
                                    ('')
                            }
                        </div>
                    )
                }
                else {
                    return (
                        <div key={auctionitem.itemid}>{/* After login UI*/}
                            {
                                auctionitem.title.toLocaleLowerCase().includes(this.state.search.toLocaleLowerCase()) ?
                                    (
                                        <Row>
                                            <Col md={10} className="offset-md-1"> 
                                                <AuctionItem itemid={auctionitem.itemid} code={this.state.code} />
                                            </Col>
                                        </Row>

                                    )
                                    :
                                    ('')
                            }
                        </div>
                    )
                }
            }
        })
    }


    getAuctionItemsTableObjects(objs) {
        //console.log(objs);

        const tableObjs = []

        for (var i = 0; i < objs.length; i++) {
            var auctionItemObj = objs[i]

            ///console.log(auctionItemObj)

            var newObj = Object()

            newObj.itemid = auctionItemObj.itemid.S
            newObj.title = auctionItemObj.title.S
            newObj.open = auctionItemObj.open.S
            newObj.close = auctionItemObj.close.S
            newObj.desc = auctionItemObj.desc.S
            newObj.photo = auctionItemObj.image1.S
            newObj.lastbidder = auctionItemObj.lastbidder.S
            newObj.startbid = auctionItemObj.startbid.N
            newObj.bidinc = auctionItemObj.bidinc.N
            newObj.lastbid = auctionItemObj.lastbid.N
            newObj.category = auctionItemObj.category.S
            newObj.bidcount = auctionItemObj.bidcount.N
            newObj.value = auctionItemObj.value.N
            newObj.status = auctionItemObj.status.S
            //newObj.ptype = auctionItemObj.ptype.S
            newObj.payurl = auctionItemObj.payurl.S

            tableObjs.push(newObj)
        }

        //console.log(tableObjs)

        return tableObjs
    }

    getAuctionItems() {
        let apiName = serviceNames.getAuctionService();
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'code': this.state.code,
                'command': 'getAuctionItems'
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //console.log(myInit);

        API.post(apiName, path, myInit).then(response => {

            if (response.err == null && response.data != null) {
                //console.log(response.data.Items);

                global.$auctionitemsjson = this.getAuctionItemsTableObjects(response.data.Items);

                this.setState({ dataloaded: true })
            }
            else {
                //console.log(response.err);
            }
        }
        ).catch(error => {
            //console.log(error)
        });
    }


}
export default AuctionsItems;
