import React from 'react';
import { Link } from 'react-router-dom';

const moment = require('moment-timezone');
class SessionFeedbackTab extends React.Component {
    constructor(props) {
        super(props);

       //console.log("session Tab");
        this.state = {
            code: global.$code,
            s3: global.$s3,
            bucket: global.$s3bucket,
            iframestyle: global.$session.styles.iframe,
        };
    }

    render() {
       //console.log("session Tab");
        return (
            <div>
                {
                    this.props.showfeedback == "Y" ?
                        (
                            <iframe src={this.props.feedback + "?firstname=" + global.$attendeeDetails.fname + "&lastname=" + global.$attendeeDetails.lname + "&email=" + global.$attendeeDetails.email + "&userid=" + global.$attendeeDetails.userid + "&company=" + global.$attendeeDetails.company + "&sessionid=" + this.props.session.SID + "&sessionpoints=" + this.props.session.POINTS + "&sessiondate=" + this.props.sessiondate + "&sessionspeakers=" + this.props.session.SP + "&title=" + encodeURIComponent(this.props.session.TITLE) + "&header3=" + this.props.session.T3 + "&data1=" + this.props.session.DATA1 + "&data2=" + this.props.session.DATA2 + "&data3=" + this.props.session.DATA3 + "&data4=" + this.props.session.DATA4 + "&data5=" + this.props.session.DATA5}
                                id="myId"
                                className="myClassname"
                                display="initial"
                                position="relative"
                                style={this.state.iframestyle}
                            />
                        )
                        :
                        (
                            <div className="feedback-message">
                                <h4>Thank you for submitting feedback.</h4>
                            </div>
                        )
                }
            </div>
        )
    }
}
export default SessionFeedbackTab;


