import React from 'react';
import { API, Analytics } from 'aws-amplify';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import SessionComponents2 from './SessionComponents2.jsx';
import "./Schedule2.css";
import UpdatePoints from '../Points/UpdatePoints.jsx';
var serviceNames = require('../ServiceNameMapper');

const moment = require('moment-timezone');
// const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent); // detects mobile browser

var timer;
class SessionInfo2 extends React.Component {
    constructor(props) {
        super(props);
       //console.log(props);
        //console.log(props.component);
        var sessionId = '';
        var stag = ''; 
        var activeTab = '';
        var type = '';
        if (props.component == null) {
            var [sessionId, stag, activeTab, type] = props.match.params.value.split("==");

            var timerdivstyle = '';

            if (global.$session.styles.sessioncountdowndiv == null) {
                timerdivstyle = {};
            }
            else {
                timerdivstyle = global.$session.styles.sessioncountdowndiv;
            }
        }
        else {
            sessionId = props.sid;
            stag = props.stag;
            activeTab = props.activetab;
            type = props.type;
        }


        this.state = {
            code: global.$code,
            s3: global.$s3,
            bucket: global.$s3bucket,
            dataloaded: false,
            sessionid: sessionId,
            stag: stag,
            activetab: activeTab,
            type: type,
            showfeedback: "Y",
            begindate: '',
            enddate: '',
            backbuttonstyle: global.$settings.styles.backbutton,
            sessiontitle: '',
            begindate: '',
            enddate: '',
            titlestyle: global.$session.styles.sessiontitle,
            timestyle: global.$session.styles.sessiontime,
            showtime: ' ',
            timerdivstyle: timerdivstyle,
        };
       //console.log(this.state);
        if (global.$schedulejson == null) {
            this.getScheduleJson();
        }
        /*timer = setInterval(() => {

            var currentdate = moment().tz(`${global.$settings.timezone}`).format('L LTS')
            var sessionstarttime = Date.parse(global.$sessionobj.BEGIN); // 12/05/2020 05:30 PM
            var sessionendtime = Date.parse(global.$sessionobj.END); // 12/05/2020 05:30 PM

            //console.log(`SessionInfo: Timer: current = ${currentdate}, start = ${global.$sessionobj.BEGIN}, end = ${global.$sessionobj.END}`);

            //console.log(currentdate);
            var mode = global.$mode;
            if (mode == null || mode == '') {
                mode = 'live';
            }

            if (mode == "live") {
                //Nothing - Just retunr the current date
            }
            else if (mode == "preview") {
                // Make current date/time as same as session begin date/time
                currentdate = global.$sessionobj.BEGIN;
            }
            else if (mode == "dryrun") {

                var eventdate = moment(sessionstarttime).format('L');
                var currenttime = moment().tz(`${global.$settings.timezone}`).format('LTS');
                currentdate = eventdate + " " + currenttime

                //console.log(`SessionInfo: Timer: Adjustred Current = ${currentdate}, start = ${sessionstarttime}, end = ${sessionendtime}`);
            }
            else if (mode == "ondemand") {   //Add 365 days to current date
                var postdate = moment().add(365, 'days').calendar();
                var posttime = moment().tz(`${global.$settings.timezone}`).format('LTS');
                currentdate = postdate + " " + posttime;
            }

            var curenttime = Date.parse(currentdate);  // Date.Parse() converts the date into milliseconds
            global.$timezonecurrenttime = curenttime;

            //console.log(`SessionInfo: Timer: current = ${curenttime}, start = ${sessionstarttime}, end = ${sessionendtime}`);

            if (curenttime >= sessionstarttime && curenttime <= sessionendtime) {
               //console.log('SessionInfo.isSessionLive - Live');

                this.getSession();

                //Stop the timer once session is transitioned
                //clearInterval(timer)
            }

        }, 60000);*/
        this.handleScheduleTab = this.handleScheduleTab.bind(this);
    }

    handleScheduleTab() {
        global.$activeScheduleTab = this.state.activetab;
        //console.log(global.$activeScheduleTab);
    }

    componentDidMount() {
        //console.log("componentDidMount")
        window.scrollTo(0, 0)
        // if(isMobile)
        // {
        //     console.log("mobile browser");
        // }
        // else{
        //     console.log("Desktop browser");
        // }
    }

    componentWillUnmount() {
        //console.log("componentWillUnmount")
        //clearInterval(timer)
    }

    render() {
        //console.log("SessionInfo2: render");
       //console.log(this.props);
        var data = false;
        if (this.props.component == null) {

            var [sessionId, stag, activeTab, type] = this.props.match.params.value.split("==");
            if (this.state.sessionid == sessionId) {
                data = true;
            }
        }
        else {
            if (this.state.sessionid == this.props.sid) {
                data = true;
            }
        }
        //console.log(data);
        //console.log(this.state)
        //this.getScheduleJson()

        //console.log(this.state.showfeedback);
        Analytics.record({ name: 'PageView', attributes: { name: 'eventsites-' + this.state.code + '-sessiondetails' } });
        if (this.state.dataloaded == true && data == true) {
            return (
                <div>
                    {
                        global.$points.sessionattend != null && global.$points.sessionattend.enable.includes("Y") ?
                            (
                                <UpdatePoints session={global.$sessionobj} component="session" area="sessionattend" points={global.$points.sessionattend.points} />
                            )
                            :
                            ('')
                    }
                    <SessionComponents2
                        session={global.$sessionobj}
                        showfeedback={this.state.showfeedback}
                        activetab={this.state.activetab}
                        type={this.state.type}
                        sid={this.state.sessionid}
                        stag={this.state.stag}
                    />
                    <br />
                </div>
            )
        }
        else {
            if (global.$schedulejson == null) {
                this.getScheduleJson()
            }
            else {
                this.getSession()
            }
            return (
                <div>
                    <Row>
                        <Col md={6} className="offset-5">
                            <div className="loading-div">
                                <div className="loading-text">Loading....</div>
                                {/*<ReactSpinner/>*/}
                            </div>
                        </Col>
                    </Row>
                </div>)
        }

    }

    getScheduleJson() {
        //console.log("getSchedulejson");
        let apiName = serviceNames.getWebsiteService();
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'code': this.state.code,
                'command': 'getJsonFileFromS3',
                'bucket': this.state.bucket,
                'filename': "allschedule"
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //console.log(myInit);

        API.post(apiName, path, myInit).then(response => {
            //console.log ("response.data ===> " + response.data + "response.err ===> " + response.err);

           //console.log(response);

            if (response.err == null && response.data != null) {
                let buf = Buffer.from(response.data.Body);
                //console.log(buf)

                global.$schedulejson = JSON.parse(buf.toString());
               //console.log(global.$schedulejson);
                this.getSession()
            }
            else {
                ////console.log("getAttendeesTableObjects - failed!!");
                //console.log(response.err);
            }
        }
        ).catch(error => {
            //console.log(error.response)
        });
    }
    getAdjustedCurrentDate(begindate) {
        var mode = global.$mode;
        if (mode == null || mode == '') {
            mode = 'live';
        }

        var currentdate = moment().tz(`${global.$settings.timezone}`).format('L LTS');

        if (mode == "live") {
            //Nothing - Just return the current date
        }
        else if (mode == "preview") {
            // Make current date/time as same as session begin date/time
            currentdate = begindate;
        }
        else if (mode == "dryrun") {
            var eventdate = moment(begindate).format('L');
            var currenttime = moment().tz(`${global.$settings.timezone}`).format('LTS');
            currentdate = eventdate + " " + currenttime
        }
        else if (mode == "ondemand") {   //Add 365 days to current date

            var postdate = moment().add(365, 'days').calendar();
            var posttime = moment().tz(`${global.$settings.timezone}`).format('LTS');
            currentdate = postdate + " " + posttime;
        }

        //console.log(`SessionVideos:  current = ${currentdate}, start = ${this.state.begindate}, end = ${this.state.enddate}`);

        return currentdate;
    }

    getSession() {
       //console.log("get sessions");
        //console.log(this.state.sessionid);
        var sessionObj = "";
        if (this.props.component == null) {
            var [sessionId, stag, activeTab, type] = this.props.match.params.value.split("==");
           //console.log(sessionId);
            this.state.sessionid = sessionId;
            this.state.stag = stag;
            this.state.activetab = activeTab;
            this.state.type = type;
            //console.log(this.state);
            sessionObj = global.$schedulejson.find(globalSession => (globalSession.SID === this.state.sessionid) && (globalSession.STAG === this.state.stag))
        }
        else {
            /*this.state.sessionid = this.props.sid;
            this.state.stag = this.props.stag;
            this.state.activetab = this.props.activeTab;
            this.state.type = this.props.type;*/
            var livesessions = [];
            var upcomingsessions = [];
            var currentsession = [];
            global.$schedulejson.map(schedule => {
               //console.log(schedule.STAG, schedule.TAG, this.state.stag, schedule.STAG == this.state.stag)
                if (schedule.STAG == this.state.stag) {
                    if (schedule.SP == "") {
                        schedule.SP = "empty";
                    }

                    if (schedule.FILE1 == "" || schedule.FILE1 == " ") {
                        schedule.FILE1 = "empty";
                    }

                    var currentdate = this.getAdjustedCurrentDate(schedule.BEGIN) //moment().tz(`${global.$settings.timezone}`).format('L LTS')

                    var currenttime = Date.parse(currentdate);
                    var sessionstarttime = Date.parse(schedule.BEGIN);
                    var sessionendtime = Date.parse(schedule.END);
                    schedule.SP = schedule.SP.replace(/;/g, "");

                    if (schedule.T3 == "") {
                        schedule.T3 = "emptyurl";
                    }
                    //console.log(schedule.ATTENDEELINK)
                    if ((currenttime) >= (sessionstarttime) && (currenttime) <= (sessionendtime)) { //Live sessions
                        livesessions.push(schedule);
                    }
                    if ((currenttime) <= (sessionstarttime) && (currenttime) <= (sessionendtime)) { // Upcoming Sessions
                        upcomingsessions.push(schedule);
                    }
                }
            })

            //console.log(livesessions);
            //console.log(livesessions.length);
            //console.log(upcomingsessions);
            //console.log(upcomingsessions.length);
            if (livesessions.length > 0) {
                currentsession = livesessions[0].SID;
            }
            else if (upcomingsessions.length > 0) {
                currentsession = upcomingsessions[0].SID;
            }
            else {
                currentsession = this.props.sid;
                this.state.stag = this.props.stag;
                this.state.activetab = this.props.activeTab;
                this.state.type = this.props.type
            }
            //console.log(currentsession);
            //sessionObj = global.$schedulejson.find(globalSession => (globalSession.SID === this.state.sessionid) && (globalSession.STAG === this.state.stag))
            sessionObj = global.$schedulejson.find(globalSession => (globalSession.SID === currentsession) && (globalSession.STAG === this.state.stag))
        }
        //console.log(global.$schedulejson);
        //console.log(this.state.sessionid, this.state.stag);

        //////console.log(sessionObj);

        global.$sessionobj = sessionObj;
        var array = [];
       //console.log(sessionObj);

        if (sessionObj.T4 !== " " && sessionObj.T4 !== "") {
            array = sessionObj.T4;
            array = array.split(";");
            array = array[0];
            this.getResponses(array)
        }
        var d1 = new Date(sessionObj.BEGIN);
        var d2 = new Date(sessionObj.END);

        var beginDateFormatter = moment(d1).format('MMM D, h:mm A');
        var endDateFormatter = moment(d2).format('h:mm A');

        this.setState({
            sessiontitle: sessionObj.TITLE,
            begindate: beginDateFormatter,
            enddate: endDateFormatter,
            dataloaded: true
        });
    }

    getResponseTableObjects(objs) {
        //console.log(objs)
        var tableObjs = []
        for (var i = 0; i < objs.length; i++) {

            var responseObj = objs[i]
            //console.log(objs)
            if (this.state.sessionid == responseObj.sessionid.S) {
                //console.log("true1")
                //console.log(responseObj.userid.S);
                //console.log(global.$loginid);
                global.$showfeedback = "Y";
                if (global.$loginid == responseObj.userid.S) {
                    //console.log("true2");
                    this.setState({ showfeedback: "N" });
                    //this.state.showfeedback = "N";
                    //global.$showfeedback = "N";
                }
            }
            //console.log(this.state.showfeedback);
        }
        //console.log(this.state.showfeedback);
        return tableObjs

    }
    getResponses(item) {
        let apiName = 'PollingService';
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'code': this.state.code,
                'command': 'getPollResponses',
                'sessionid': this.state.sessionid,
                'questionid': item,
                'responsetype': "SessionFeedback"
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //console.log(myInit);

        API.post(apiName, path, myInit).then(response => {
            //console.log(response);

            if (response.err == null && response.data != null) {
                //console.log(response.data.Items);
                var responses = this.getResponseTableObjects(response.data.Items)
            }
            else {
                //console.log("getReportsTableObjects - failed!!");
                //console.log(response.err);
            }

        }
        ).catch(error => {
            //console.log(error)
        });
    }

}
export default SessionInfo2;


