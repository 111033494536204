import React from 'react';
import { Row, Col, Input } from 'reactstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { API, Analytics } from 'aws-amplify';
import './Delegates.css';
import LoadPrivateImg from '../Components/Shared/LoadPrivateImg';
import commonProfile from '../images/common_profile.png';
import DelegateProfile from './DelegateProfile';
import DelegateProfile2 from './DelegateProfile2';
var serviceNames = require('../ServiceNameMapper');

var attendees =
  [

  ]

let order = 'desc';
var imgdata = '';
class Delegateslist extends React.Component {
  constructor(props) {
    super(props);
    var profiletype = "profile1";

    if (global.$attendees.profiletype == null) {
      profiletype = "profile1";
    }
    else {
      profiletype = global.$attendees.profiletype
    }

    this.state =
    {
      dataloaded: false,
      code: props.code,
      data: attendees,
      imageUrl: '',
      search: '',
      itemcardstyles: global.$attendees.styles.photomaindiv,
      cardstyles: global.$attendees.styles.photosubdiv,
      imgdivstyle: global.$attendees.styles.imgdivstyle,
      imgstyle: global.$attendees.styles.imgstyle,
      profiletype: profiletype
    }
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  handleInputChange(event) {
    const target = event.target;
    const name = target.name;
    let value = target.value

    //console.log("Target = " + name + ", Value = " + value);

    this.setState({ [name]: value });
    //this.setState({selectedCategory:value})
  }
  render() {

    //console.log("AttendeeList: render");

    Analytics.record({ name: 'PageView', attributes: { name: 'eventsites-' + this.state.code + '-attendees' } });
    if (this.state.dataloaded) {
      return (
        <div>
          <img className="banner" src={global.$attendees.banner} />
          <div dangerouslySetInnerHTML={{ __html: global.$attendees.header }}></div>
          <div>
            <Row>
              <Col md={10} className="offset-md-1">
                <br />
                {<div className="attendee-search-div">
                  <Input type="text" name="search" className="attendee-search" value={this.state.search || ""} onChange={this.handleInputChange} placeholder="Search"><i class='fas fa-search'></i></Input>
                </div>}
                <br />
                <br />
                <br />
              </Col>
            </Row>
            <Row>
              {this.showSearchResults()}
            </Row>
            <br />
          </div>
        </div>
      );
    }
    else {
      if (global.$attendeejson != null) {
        //console.log("true");
        if (global.$points != null && global.$points.attendeebadgelevels != null && global.$points.attendeebadgelevels.enable == true) {
          this.getUsers();
        }
        else {
          this.setState({ dataloaded: true });
        }
      }
      else {
        this.getUsers();
      }
      return (
        <div>
          <Row>
            <Col md={6} className="offset-5">
              <div className="loading-div">
                <div className="loading-text">Loading....</div>
              </div>
            </Col>
          </Row>
        </div>
      )
    }

  }


  showSearchResults() {
    //console.log(this.state.search)

    const results = global.$attendeejson.filter(attendee => {
      if (attendee.showdirectory == "true" || attendee.showdirectory == "TRUE") {

        if (this.state.search == "")
          return true;
        else {
          if (attendee.userid.toLocaleLowerCase().includes(this.state.search.toLocaleLowerCase()) || attendee.fname.toLocaleLowerCase().includes(this.state.search.toLocaleLowerCase()) || attendee.lname.toLocaleLowerCase().includes(this.state.search.toLocaleLowerCase()) || attendee.company.toLocaleLowerCase().includes(this.state.search.toLocaleLowerCase()) || attendee.title.toLocaleLowerCase().includes(this.state.search.toLocaleLowerCase()) || attendee.myinterests.toLocaleLowerCase().includes(this.state.search.toLocaleLowerCase()) || attendee.myexpertise.toLocaleLowerCase().includes(this.state.search.toLocaleLowerCase()))
            return true;
          else
            return false;
        }
      }
    })

    return results.map(attendee => {
      //console.log(attendee);
      return (
        <Col md={3}>
          <div className="item-card" style={this.state.itemcardstyles} key={attendee.userid}>
            {
              this.state.profiletype.includes("profile2") ?
                (
                  <DelegateProfile2
                    code={this.state.code}
                    userid={attendee.userid}
                    company={attendee.company}
                    fname={attendee.fname}
                    lname={attendee.lname}
                    title={attendee.title}
                    email={attendee.email}
                    phone={attendee.phone}
                    photo={attendee.photo}
                    street={attendee.street}
                    city={attendee.city}
                    state={attendee.state}
                    zip={attendee.zip}
                    linkedin={attendee.linkedin}
                    twitter={attendee.twitter}
                    facebook={attendee.facebook}
                    showdirectory={attendee.showdirectory}
                    data4={attendee.data4}
                    attendee={attendee}
                  />
                )
                :
                (
                  <div>
                    <DelegateProfile
                      code={this.state.code}
                      userid={attendee.userid}
                      company={attendee.company}
                      fname={attendee.fname}
                      lname={attendee.lname}
                      title={attendee.title}
                      email={attendee.email}
                      phone={attendee.phone}
                      photo={attendee.photo}
                      street={attendee.street}
                      city={attendee.city}
                      state={attendee.state}
                      zip={attendee.zip}
                      linkedin={attendee.linkedin}
                      twitter={attendee.twitter}
                      facebook={attendee.facebook}
                      showdirectory={attendee.showdirectory}
                      data4={attendee.data4}
                      attendee={attendee}
                    />
                    <br />
                  </div>
                )
            }
          </div>
        </Col>
      )
    })
  }

  getAttendeesTableObjects(objs) {
    //console.log(objs);

    const tableObjs = []

    for (var i = 0; i < objs.length; i++) {
      var attendeeObj = objs[i]

      //console.log(attendeeObj)

      var newObj = Object()

      newObj.fname = attendeeObj.fname.S
      newObj.lname = attendeeObj.lname.S
      newObj.photo = attendeeObj.photo.S
      newObj.title = attendeeObj.title.S
      newObj.userid = attendeeObj.userid.S
      newObj.email = attendeeObj.email.S
      newObj.phone = attendeeObj.phone.S
      newObj.company = attendeeObj.company.S
      newObj.myinterests = attendeeObj.myinterests.S
      newObj.myexpertise = attendeeObj.myexpertise.S
      newObj.street = attendeeObj.street.S
      newObj.city = attendeeObj.city.S
      newObj.state = attendeeObj.state.S
      newObj.zip = attendeeObj.zip.S
      newObj.linkedin = attendeeObj.linkedin.S
      newObj.twitter = attendeeObj.twitter.S
      newObj.facebook = attendeeObj.fb.S
      newObj.showdirectory = attendeeObj.showdirectory.S
      newObj.data4 = attendeeObj.data4.S
      
      newObj.q1 = attendeeObj.q1.S.substring(
        attendeeObj.q1.S.lastIndexOf(";answer:") + 8);
      
      newObj.q2 = attendeeObj.q2.S.substring(
          attendeeObj.q2.S.lastIndexOf(";answer:") + 8);

      newObj.activitypoints = attendeeObj.activitypoints.N
      newObj.totalpoints = attendeeObj.totalpoints.N


      tableObjs.push(newObj)
    }

    //console.log(tableObjs)

    return tableObjs
  }

  getUsers() {

    //console.log("getUsers");

    let apiName = serviceNames.getUserService();
    let path = ''; //replace this with the path you have configured on your API
    let myInit = {
      body:
      {
        'code': this.state.code,
        'command': 'getUsers'
      },
      response: true,
      convertEmptyValues: true,
      headers: {}
    }
    //console.log(myInit);

    API.post(apiName, path, myInit).then(response => {
      //console.log ("response.data ===> " + response.data + "response.err ===> " + response.err);

      if (response.err == null && response.data != null) {
        //console.log("getAttendeesTableObjects - Success!!");
        //console.log(response.data.Items);

        attendees = this.getAttendeesTableObjects(response.data)

        global.$attendeejson = attendees;
        this.setState({ dataloaded: true })
        //console.log(attendees.photo)
        //console.log(attendees)

        //this.setState({ dataloaded: true })
        //console.log(this.state.dataloaded);
        //console.log(this.state.data);
      }
      else {
        //console.log("getAttendeesTableObjects - failed!!");
        //console.log(response.err);
      }
    }
    ).catch(error => {
      //console.log(error.response)
    });
  }

}
export default Delegateslist;