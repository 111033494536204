import React from 'react';
import Avatar from 'react-avatar';
import { mdiWeb, mdiEmail, mdiPhone, mdiLinkedin } from '@mdi/js';
import Icon from '@mdi/react';
import Modal from "react-responsive-modal";
import 'react-responsive-modal/styles.css';
import SpeakerPopup3 from './SpeakerPopup3';
import { Button } from 'reactstrap';
import './Speakers3.css'

class SpeakerInfo3 extends React.Component {
    constructor(props) {

        super(props);
        this.state = {
            code: props.code,
            alloptionsopen: false,
        };
    }

    onOpenAllOptions = () => {
        //console.log('onOpenAllOptions');
        this.setState(prevState => ({
            alloptionsopen: !prevState.alloptionsopen
        }));
        //console.log(this.state.alloptionsopen);
    };
    render() {
        return (
            <div >
                
                {this.displaySpeakers()}

                <Modal
                    open={this.state.alloptionsopen}
                    onClose={this.onOpenAllOptions}
                    blockScroll={false}
                    styles={{
                        modal: {
                            width: "100%"
                        }
                    }}
                    classNames={{
                      modal: 'speakersCustomModal',
                    }}
                    center>
                    <SpeakerPopup3 speaker={global.$speaker} />
                </Modal>
            </div>
        )
    }

    displaySpeakers() {
        var speakers = global.$config["speakers3"]
        var speaker = this.props.speaker;
        var image = "";
        if (speaker.PHOTO == "" || speaker.PHOTO == " ") {
            image = "empty";
        }
        else {
            image = `https://s3.amazonaws.com/event360live/public/${this.state.code}/speakers/` + speaker.PHOTO
        }

        return (
            <div className="speakers3-card-div" style={speakers.styles.carddiv}>
                <div className="speakers3-imgdiv" style={speakers.styles.imgdiv}>
                    {
                        image.includes("empty") ?
                            (
                                <div className="speakers3-img">
                                    <Avatar className="speakers3-avatar" name={`${speaker.NAME}`} style={speakers.styles.image} />
                                </div>
                            )
                            :
                            (
                                <img src={image} style={speakers.styles.image} className="speakers3-img" />
                            )
                    }
                </div>
                {
                    speakers.socialicons.enable == true ?
                        (
                            <div className="speakers3-socialicons-div">
                                {
                                    speakers.socialicons.url.enable == true && speaker.URL != null && speaker.URL != "" ?
                                        (
                                            <a href={speaker.URL} target="_blank" style={speakers.socialicons.url.style}><Icon path={mdiWeb} size={1.6} color="#828783" className="speakers3-contact-icon" /></a>
                                        )
                                        :
                                        ('')
                                }
                                {
                                    speakers.socialicons.email.enable == true && speaker.EMAIL != "" && speaker.EMAIL != " " ?
                                        (
                                            <a href={"mailto:" + speaker.EMAIL} style={speakers.socialicons.email.style}><Icon path={mdiEmail} size={1.6} color="#828783" className="speakers3-contact-icon" /></a>
                                        )
                                        :
                                        ('')
                                }
                                {
                                    speakers.socialicons.phone.enable == true && speaker.PHONE != "" && speaker.PHONE != " " ?
                                        (
                                            <a href={"tel:" + speaker.PHONE}><Icon path={mdiPhone} size={1.6} color="#828783" className="speakers3-contact-icon" /></a>
                                        )
                                        :
                                        ('')
                                }
                                {
                                    speakers.socialicons.linkedin.enable == true && speaker.LINKEDIN != "" && speaker.LINKEDIN != " " ?
                                        (
                                            <a href={speaker.LINKEDIN}><Icon path={mdiLinkedin} size={1.6} color="#828783" className="speakers3-contact-icon" /></a>
                                        )
                                        :
                                        ('')
                                }
                            </div>
                        )
                        :
                        ('')
                }
               
                <div className="speakers3-info-div">
                    {
                        speakers.contactinfo.name.enable == true && speaker.NAME != "" && speaker.NAME != " " ?
                            (
                                <p className="speakers3-contact-name" style={speakers.contactinfo.name.style}>{speaker.NAME}</p>
                            )
                            :
                            ('')
                    }
                    {
                        speakers.contactinfo.title.enable == true && speaker.TITLE != "" && speaker.TITLE != " " ?
                            (
                                <p className="speakers3-contact-title" title={speaker.TITLE} style={speakers.contactinfo.title.style}>{speaker.TITLE}</p>
                            )
                            :
                            ('')
                    }
                    {
                        speakers.contactinfo.company.enable == true && speaker.COMPANY != "" && speaker.COMPANY != " " ?
                            (
                                <p className="speakers3-contact-company" title={speaker.COMPANY} style={speakers.contactinfo.company.style}>{speaker.COMPANY}</p>
                            )
                            :
                            ('')
                    }
                </div>
                <div className="speakers3-btn-div">
                    <Button className="btn speakers3-profile-btn" onClick={() => this.popup(speaker)} style={speakers.button.style}>{speakers.button.text}</Button>
                </div>
            </div>
        )
    }

    popup(speaker) {
        //console.log(sponsor);
        global.$speaker = speaker;
        this.onOpenAllOptions()
    }

}
export default SpeakerInfo3;
