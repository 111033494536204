/* eslint-disable */
// var stripeapikey =  global.$registrationform && global.$registrationform.payment && global.$registrationform.payment.ach && global.$registrationform.payment.ach.stripe && global.$registrationform.payment.ach.stripe.clientkey ? global.$registrationform.payment.ach.stripe.clientkey : '';
// var stripe = ''

// if(stripeapikey != null && stripeapikey != ""){
//   stripe = Stripe(stripeapikey)
// }
// else {
//   stripe = 'nokey'
// }

// console.log(stripeapikey);

async function collectBankAccountForPayment(clientSecret, nameonaccount, email, stripe) {
    var result = ''
    var params = {
      payment_method_type: 'us_bank_account',
      payment_method_data: {
        billing_details: {
          name: nameonaccount,
          email: email,
        },
      },
    }

    if(stripe != "nokey") {
      var collectBankAccountForPaymentResult = await stripe.collectBankAccountForPayment({
        clientSecret: clientSecret,
        params,
        expand: ['payment_method'],
      })
  
      console.log(collectBankAccountForPaymentResult)
  
      if(collectBankAccountForPaymentResult.error) {
        console.log(collectBankAccountForPaymentResult.error);
        // PaymentMethod collection failed for some reason.
      } else if (collectBankAccountForPaymentResult.paymentIntent.status === 'requires_payment_method') {
        // Customer canceled the hosted verification modal. Present them with other
        // payment method type options.
        console.log("Customer canceled the hosted verification modal. Present them with other")
      } else if (collectBankAccountForPaymentResult.paymentIntent.status === 'requires_confirmation') {
        // We collected an account - possibly instantly verified, but possibly
        // manually-entered. Display payment method details and mandate text
        // to the customer and confirm the intent once they accept
        // the mandate.
        console.log("We collected an account - possibly instantly verified, but possibly")
  
        result = await acceptMandateConditions(clientSecret, stripe);
      }
    }

    return result
}

async function acceptMandateConditions(clientSecret, stripe) {
  var paymentIntentResult = '' 

  if(stripe != "nokey" && window.confirm("Do you want to agree mandate conditions?")) {

    var submitPayment = await stripe.confirmUsBankAccountPayment(clientSecret);

      if (submitPayment.error) {
        console.error(submitPayment.error);
        // The payment failed for some reason.
      } else if (submitPayment.paymentIntent.status === "requires_payment_method") {
        // Confirmation failed. Attempt again with a different payment method.
        console.log("Confirmation failed. Attempt again with a different payment method.")
      } else if (submitPayment.paymentIntent.status === "processing") {
        // Confirmation succeeded! The account will be debited.
        // Display a message to customer.
        console.log("Confirmation succeeded! The account will be debited.")
        paymentIntentResult = await confirmThePaymentIntentSucceeded(clientSecret, stripe)
      } else if (submitPayment.paymentIntent.next_action.type === "verify_with_microdeposits") {
        // The account needs to be verified via microdeposits.
        // Display a message to consumer with next steps (consumer waits for
        // microdeposits, then enters a statement descriptor code on a page sent to them via email).
      }
  }

  return paymentIntentResult
}

async function confirmThePaymentIntentSucceeded(clientSecret, stripe) {
  var succeededResponse = ''

  if(stripe != "nokey") {
    var response = await stripe.retrievePaymentIntent(clientSecret);

    if (response.paymentIntent && (response.paymentIntent.status === 'succeeded' || response.paymentIntent.status === 'processing')) {
      // Handle successful payment here
      console.log(response);
      succeededResponse = {
        status: 'success'
      };
    } else {
      // Handle unsuccessful, processing, or canceled payments and API errors here
    }
  }

  return succeededResponse
}



export default{ collectBankAccountForPayment }
