import React from 'react';
const moment = require('moment-timezone');

/*
  Session countdown timer(SessionInfo) and credit timer(SessionCreditTimer) using this component
*/

class Stopwatch extends React.Component {

  constructor(props) {

    var sessiontimertitlestyle = '';
    var sessiontimerstyle = '';
    
    if(global.$session.styles.sessiontimer == null)
    {
      sessiontimerstyle = {};
    }
    else
    {

      sessiontimerstyle = global.$session.styles.sessiontimer;
    }
    
    if(global.$session.styles.sessiontimertitle == null)
    {
      sessiontimertitlestyle = {};
    }
    else
    {
      sessiontimertitlestyle = global.$session.styles.sessiontimertitle;
    }

    super(props);
    this.state = {
      secondsElapsed: 0,
      minutes: '',
      sessiontimerstyle: sessiontimerstyle,
      sessiontimertitlestyle: sessiontimertitlestyle
    }
    //this.startTimer = this.startTimer.bind(this);
    this.startTimer();
    //console.log(this.state);
   //console.log(global.$sessionobj);

  }

  getMilliseconds() {
    return ('0' + this.state.secondsElapsed * 100).slice(-2);
  }
  getSeconds() {
    return ('0' + parseInt(this.state.secondsElapsed % 60)).slice(-2);
  }
  getMinutes() {
    return ('0' + Math.floor(this.state.secondsElapsed / 60)).slice(-2);
  }
  startTimer() {
    var runningTime = this;
    this.incrementer = setInterval(function () {
      runningTime.setState({
        secondsElapsed: (runningTime.state.secondsElapsed + 0.01)
      });
    }, 10)
  }
  render() {
    return (
      <div>
        {
          this.props.type.includes("schedule") ?
            (
              this.getSessionCountdownTimer()
            )
            :
            (
              <div className="display-timer">
                <h1>{this.getMinutes()}:{this.getSeconds()}</h1>
              </div>
            )
        }
      </div>
    );
  }

  getSessionCountdownTimer() {

    var currentdate = this.getAdjustedCurrentDate();
    var curenttime = Date.parse(currentdate);
    var sessionstarttime = Date.parse(global.$sessionobj.BEGIN);
    var sessionendtime = Date.parse(global.$sessionobj.END);

    var ms = moment(sessionstarttime).diff(moment(curenttime));
    var d = moment.duration(ms);
    var minutes;

    var cd = 24 * 60 * 60 * 1000,
      ch = 60 * 60 * 1000,
      days = Math.floor(ms / cd),
      hrs = Math.floor((ms - days * cd) / ch),
      mins = Math.round((ms - days * cd - hrs * ch) / 60000);

   //console.log(days, hrs, mins);

    if(hrs < 10)
    {
      hrs = "0" + hrs;
    }
    if(mins < 10)
    {
      mins = "0" + mins;
    }

    if (days == "00") {
      if (hrs == "00") {
        minutes = mins + " Mins";
      }
      else {
        minutes = hrs + ':' + mins
      }
    }
    else {
      minutes = days + " Day(s), " + hrs + ":" + mins
    }

   //console.log(minutes);

    if (sessionstarttime > curenttime) {
      //pre session
      //console.log("pre")
      return (
        <div className="session-timer" style={this.state.sessiontimerstyle}>
          <p className="session-timer-title" style={this.state.sessiontimertitlestyle}>Starts in {minutes}</p>
        </div>
      )
    }

    if (curenttime > sessionendtime) {
      //post session
      //console.log("post")
      return (
        <div className="session-timer">
          {/*<p className="session-timer-title">Session finished</p>*/}
        <p className="session-timer-title"></p>
      </div>
      )
    }

    /*if (curenttime >= sessionstarttime && curenttime <= sessionendtime) {
      //Live session
      //console.log("live")
      return (
        <div className="session-timer" >
          <p className="session-timer-title live-button-session"><p style={{color: "white"}} className="live-text-session">LIVE</p></p>
        </div>
      )
    }*/


  }

  getAdjustedCurrentDate() {
    var mode = global.$mode;
    if (mode == null || mode == '') {
      mode = 'live';
    }

    var currentdate = moment().tz(`${global.$settings.timezone}`).format('L LTS');

    if (mode == "live") {
      //Nothing - Just return the current date
    }
    else if (mode == "preview") {
      // Make current date/time as same as session begin date/time
      currentdate = global.$sessionobj.BEGIN;
    }
    else if (mode == "dryrun") {
      var eventdate = moment(global.$sessionobj.BEGIN).format('L');
      var currenttime = moment().tz(`${global.$settings.timezone}`).format('LTS');
      currentdate = eventdate + " " + currenttime
    }
    else if (mode == "ondemand") {   //Add 365 days to current date

      var postdate = moment().add(365, 'days').calendar();
      var posttime = moment().tz(`${global.$settings.timezone}`).format('LTS');
      currentdate = postdate + " " + posttime;
    }

    return currentdate;
  }

}

export default Stopwatch;