/*
  This component is calling from both exhibitors and sponsors to play the videos
*/

import React from 'react';
import {Button } from 'reactstrap';
import './Sponsors.css';
import ReactPlayer from 'react-player';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

class SponsorVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state =
    {
      videourl: props.videourl,
      open: false,
      playvideobuttonstyles: global.$sponsors.playvideobuttonstyles, 
      playing: false

    }
   //console.log(props)
  }

  onOpenModal = () => {
    this.setState(prevState => ({
      open: !prevState.open
    }));
    this.setState({playing: true})
  };

  render() {
    if(this.state.videourl !=="")
    {
      return(
        <div>
          <Button style={this.state.playvideobuttonstyles} onClick={this.onOpenModal}>Play Video </Button>
          <Modal
            open={this.state.open}
            onClose={this.onOpenModal}
            styles={{
              modal: {
                width: "100%"
              }
            }}
            center
          >
            <ReactPlayer
              url={this.state.videourl}
              width="100%"
              height="calc(80vh - 100px)"
              controls={true}
              playing={this.state.playing}
              onContextMenu={e => e.preventDefault()}
              config={{
                  file: {
                      attributes: {
                          controlsList: 'nodownload',
                          disablepictureinpicture: 'true',
                      }
                  }
              }}
            />
          </Modal>
        </div>
      )
    }
    else
    {
      return(
        <div></div>
      )
    }
  }
  
}
export default SponsorVideo;
