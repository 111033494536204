import React from "react";
import { Col, Row } from "reactstrap";
import "./Speakers.css"
import { Link } from "react-router-dom";
import { API, Analytics } from "aws-amplify";
import Sponsors from "../Components/Sponsors";
import commonProfile from "../images/common_profile.png";
import SpeakerPopup from "../Components/Shared/SpeakersLayout/SpeakerPopup";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import ReactHtmlParser from 'react-html-parser';
import SpeakerCategory from "./SpeakerCategory";
var serviceNames = require('../ServiceNameMapper');

var speakersdata = "";
class Speakers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code: props.code,
      s3: props.s3,
      bucket: global.$s3bucket,
      activeIndex: 0,
      name: "",
      title: "",
      photo: "",
      desc: "",
      phone: "",
      email: "",
      company: "",
      speakerimagestyle: global.$speakers.styles.speakerimage,
      speakertitlestyle: global.$speakers.styles.speakertitle,
      speakerheaderstyle: global.$speakers.styles.categoryheader,
      dataloaded: false,
      alloptionsopen: false,
      keynoteCategory : []
    };
    
    
  }

  onOpenAllOptions = () => {
    
    this.setState((prevState) => ({
      alloptionsopen: !prevState.alloptionsopen,
    }));

  };
  componentDidMount() {
    window.scrollTo(0, 0);
    this.getSpeakerjson();
    
  }

  render() {

    Analytics.record({ name: "PageView",attributes: { name: "eventsites-" + this.state.code + "-speakers" },});

    if (this.state.dataloaded) {
      return (
        <Col  className="speakers-home" >
          <img className="banner" src={global.$speakers.banner} />
          <div>
            <br />
            <div>
              <Row>
                <Col md={10} className="offset-md-1">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: global.$speakers.header,
                    }}
                  ></div>
                   
                  <SpeakerCategory speaker={speakersdata} code={this.state.code}/>                 
                 
                  {
                    !global.$speakers.speakercategory ? 
                      <Row  className="speaker-items">
                        {this.displaySpeakers()}
                      </Row>: ''
                  }
                 
                  {global.$speakers.sponsors.enable.includes("Y") ? (
                    <div className="speaker-sponsor">
                      <br />
                      <br />
                      <Sponsors />
                      <br />
                    </div>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
              <Modal
                open={this.state.alloptionsopen}
                onClose={this.onOpenAllOptions}
                blockScroll={false}
                styles={{
                  modal: {
                    width: "100%",
                  },
                }}
                classNames={{
                  modal: 'speakersCustomModal',
                }}
                center
              >
                <SpeakerPopup speaker={global.$speaker} />
              </Modal>
            </div>
          </div>
        </Col>
      );
    } else {
      if (speakersdata != "") {
        this.setState({ dataloaded: true });
      } else {
        this.getSpeakerjson();
      }
      return (
        <div>
          <Row>
            <Col md={6} className="offset-5">
              <div className="loading-div">
                <div className="loading-text">Loading....</div>
              </div>
            </Col>
          </Row>
        </div>
      );
    }
  }


  

  displaySpeakers() { 
    console.log(speakersdata);
    return  speakersdata.map((speaker) => {
    if (
      speaker.PHOTO == "profile.png" ||
      speaker.PHOTO == " " ||
      speaker.PHOTO == ""
    ) {
      var image = commonProfile;
    } else {
      var image =
        `https://s3.amazonaws.com/event360live/public/${this.state.code}/speakers/` +
        speaker.PHOTO;
    }
   
      if(speaker.TYPE === "D" ){
        
        return (
          <Col className="speaker-item-card" key={speaker.ID}>
            <div className="speaker-wrapper">
                {/* <Link to={`./speakerInfo/${speaker.NAME + "==SpeakerRouter"}`}> <img src={image} style={this.state.speakerimagestyle} /></Link> */}
                <div onClick={() => this.popup(speaker)} className="speaker-img-popup-div ">
                    <img src={image} alt="" style={this.state.speakerimagestyle} />
                </div>
                <div style={this.state.speakertitlestyle} onClick={() => this.popup(speaker)} className="speaker-img-popup-div speaker-name-div">
                    <h6 >{speaker.NAME}</h6>
                </div>
            </div>
                
          </Col>
        );


      }
    
  });
  }

  popup(speaker) {
    global.$speaker = speaker;
    this.onOpenAllOptions();
  }

  getSpeakerjson() {
    ////console.log("sponsor json")
    let apiName = serviceNames.getWebsiteService();
    let path = ""; //replace this with the path you have configured on your API
    let myInit = {
      body: {
        code: this.state.code,
        command: "getJsonFileFromS3",
        bucket: this.state.bucket,
        filename: "speakers",
      },
      response: true,
      convertEmptyValues: true,
      headers: {},
    };
    //console.log(myInit);

    API.post(apiName, path, myInit)
      .then((response) => {
        //console.log ("response.data ===> " + response.data + "response.err ===> " + response.err);

        if (response.err == null && response.data != null) {
          let buf = Buffer.from(response.data.Body);
          //console.log(buf)
          //console.log(buf.toString());
          speakersdata = JSON.parse(buf.toString());
          

          this.setState({ dataloaded: true });
          
          
        } else {
          ////console.log("getAttendeesTableObjects - failed!!");
          //console.log(response.err);
        }
      })
      .catch((error) => {
        ////console.log(error.response)
      });
  }

 

  
}

export default Speakers;
