import React from 'react';
import { Row, Col } from 'reactstrap';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Chat from '../Chat/Chat';
import { API } from 'aws-amplify';
import { Link } from 'react-router-dom';
import SessionFeedbackTab from './SessionFeedbackTab';
const moment = require('moment-timezone');

var timer;
var eventtimezonecurrentdate = '';
var feedbackenabledate = '';
class SessionTabs extends React.Component {
    constructor(props) {
        var enabletabsbeforelogin = "Y";
        var feedbackenableduration = '0';

        if (global.$session.enabletabsbeforelogin == null) {
            enabletabsbeforelogin = "N";
        }
        else {
            enabletabsbeforelogin = global.$session.enabletabsbeforelogin;
        }
        //console.log(props.session.DATA5)
        if (props.session.DATA5 != "" && props.session.DATA5 != " " && props.session.DATA5.includes("FB#")) {
            feedbackenableduration = (props.session.DATA5).substring(
                (props.session.DATA5).lastIndexOf("feedbackduration:") + 17);
        }


        super(props);
        //console.log(props);
        this.state = {
            code: global.$code,
            s3: global.$s3,
            bucket: global.$s3bucket,
            dataloaded: false,
            numPages: null,
            pageNumber: 1,
            showfeedback: props.showfeedback,
            feedback: props.session.FB,
            livepoll: props.session.VT,
            liveqa: props.session.QA,
            sessionid: props.session.SID,
            sessionpoints: props.session.POINTS,
            title: props.session.TITLE,
            begindate: props.session.BEGIN,
            enddate: props.session.END,
            iframestyle: global.$session.styles.iframe,
            enabletabsbeforelogin: enabletabsbeforelogin,
            feedbackenableduration: feedbackenableduration,
            enablefeedbackwithduration: false
        };

        //console.log(this.props.showfeedback);
        //console.log(this.state);
        //console.log(this.props.sessiondate, this.props.session.SP)

        /*
        // Added for to enable feedback based on time
        var sbdate = moment(this.props.session.BEGIN).format("YYYY-MM-DDTHH:mm:ss"); // return session begin date in YYYY-MM-DDTHH:mm:ss
       //console.log(sbdate);
        var sedate = moment(this.props.session.END).format("YYYY-MM-DDTHH:mm:ss"); // // return session end date in YYYY-MM-DDTHH:mm:ss
       //console.log(sedate);

        var bdate = moment(sbdate);
        var edate = moment(sedate);

        var mdiff = edate.diff(bdate, 'minutes'); // return difference in num of minutes
       //console.log(mdiff);

        var feedbackenableduration = this.state.feedbackenableduration;

        //var totalminutes = Number(mdiff) - Number(feedbackenableduration);
        var totalminutes = Number(feedbackenableduration);
       //console.log(totalminutes);
        eventtimezonecurrentdate = moment().tz(`${global.$settings.timezone}`).format('L LTS');
       //console.log(eventtimezonecurrentdate)
        feedbackenabledate = moment(eventtimezonecurrentdate, 'L LTS')
            .add(totalminutes, 'minutes')
            .format('L LTS');
        //console.log(feedbackenabledate);*/

    }

    componentWillUnmount() {
        //console.log("componentWillUnmount")
        clearInterval(timer)
    }
    render() {
        //console.log("Sessiontabs render")
        if (this.state.dataloaded) {
            //console.log(this.state);
            return (
                <div>
                    {this.getTabs()}
                </div>
            )
        }
        else {
            if (this.props.session.T4 == "" && this.props.session.QA == "Y") {
                this.setState({ dataloaded: true });
            }
            else {
                var array = this.props.session.T4;
                array = array.split(";");
                array = array[0];
                this.getResponses(array)
            }
            return (
                <div></div>
            )
        }
    }
    getResponseTableObjects(objs) {
        //console.log(objs)
        var tableObjs = []
        for (var i = 0; i < objs.length; i++) {

            var responseObj = objs[i]
            //console.log(objs)
            if (this.state.sessionid == responseObj.sessionid.S) {
                //console.log("true1")
                //console.log(responseObj.userid.S);
                //console.log(global.$loginid);
                global.$showfeedback = "Y";
                if (global.$loginid == responseObj.userid.S) {
                    //console.log("true2");
                    this.setState({ showfeedback: "N" });
                    //this.state.showfeedback = "N";
                    //global.$showfeedback = "N";
                }
            }
            //console.log(this.state.showfeedback);
        }
        this.setState({ dataloaded: true })
        //console.log(this.state.showfeedback);
        return tableObjs

    }
    getResponses(item) {
        let apiName = 'PollingService';
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'code': this.state.code,
                'command': 'getPollResponses',
                'sessionid': this.state.sessionid,
                'questionid': item,
                'responsetype': "SessionFeedback"
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //console.log(myInit);

        API.post(apiName, path, myInit).then(response => {
            //console.log(response);

            if (response.err == null && response.data != null) {
                //console.log(response.data.Items);
                var responses = this.getResponseTableObjects(response.data.Items)
            }
            else {
                //console.log("getReportsTableObjects - failed!!");
                //console.log(response.err);
            }

        }
        ).catch(error => {
            //console.log(error)
        });
    }

    getAdjustedCurrentDate() {
        var currentdate = moment().tz(`${global.$settings.timezone}`).format('L LTS')

        var mode = global.$mode;
        if (mode == null || mode == '') {
            mode = 'live';
        }

        if (mode == "live") {
            //Nothing - Just retunr the current date
        }
        else if (mode == "preview") {
            // Make current date/time as same as session begin date/time
            currentdate = this.state.begindate;
        }
        else if (mode == "dryrun") {
            var eventdate = moment(this.props.sessionstarttime).format('L');
            var timenow = moment().tz(`${global.$settings.timezone}`).format('LTS');
            currentdate = eventdate + " " + timenow
        }
        else if (mode == "ondemand") {   //Add 365 days to current date
            var postdate = moment().add(365, 'days').calendar();
            var posttime = moment().tz(`${global.$settings.timezone}`).format('LTS');
            currentdate = postdate + " " + posttime;
        }

        return currentdate;
    }

    // For Koscon
    showHalls() {
        return (<div>
            {
                this.props.stag.includes("hall") ?
                    (
                        <div>
                            <Row>
                                <Col md={12}>
                                    <Tabs defaultActiveKey="Halls" transition={false} className="myClass">
                                        <Tab eventKey="Halls" title="Halls" className="tab-item">
                                            {this.getHallTabs()}
                                        </Tab>
                                    </Tabs>
                                </Col>
                            </Row>
                        </div>
                    )
                    :
                    ('')
            }
        </div>)
    }
    getTabs() {

        ////eventtimezonecurrentdate = moment().tz(`${global.$settings.timezone}`).format('L LTS');
        if (this.props.session.DATA5.includes("FB#")) {
            timer = setInterval(() => {
                this.setState({ enablefeedbackwithduration: true })
            }, 60000 * Number(this.state.feedbackenableduration))
        }
        else {
            this.state.enablefeedbackwithduration = true;
        }

        /*timer = setInterval(() => {
           //console.log("timer running")
            this.setState({ enablefeedbackwithduration: true })
        }, 60000 * Number(this.state.feedbackenableduration))
        */

        var sessionstarttime = Date.parse(this.state.begindate);
        var sessionendtime = Date.parse(this.state.enddate);

        var currentdate = this.getAdjustedCurrentDate();
        var currenttime = Date.parse(currentdate);

       //console.log("getTabs()");
        //console.log(this.state.enabletabsbeforelogin);
        if (this.state.enabletabsbeforelogin == "Y") {
            if (sessionstarttime > currenttime) {
                return this.getLiveTabs();
            }
            if (currenttime >= sessionstarttime && currenttime <= sessionendtime) {
                return this.getLiveTabs();
            }
            else if (currenttime > sessionendtime) {
                return this.getPostTabs();
            }
        }
        else {
            // if (sessionstarttime > currenttime) {
            //     return (
            //         {/*this.showHalls()*/}
            //     )
            // }
            if (currenttime >= sessionstarttime && currenttime <= sessionendtime) {
                //console.log("currenttime check");
                return this.getLiveTabs();
            }
            else if (currenttime > sessionendtime) {
                return this.getPostTabs();
            }
        }

    }
    getLiveTabs() {
        var feedback = this.state.feedback
        var livepoll = this.state.livepoll
        var chat = this.state.liveqa
        //chat = "Y" //- Chat Testing

        if (chat == "N" || chat == "") {
            chat = "empty"
        }
        if (livepoll == " " || livepoll == "") {
            livepoll = "empty"
        }
        if (feedback == " " || feedback == "") {
            feedback = "empty"
        }
        //console.log("feedback=" + feedback, "livepoll=" + livepoll, "chat=" + chat)


        if (feedback !== "empty" && livepoll == "empty" && chat == "empty")    //feedback exsit and livepoll, chat not exsit
        {
            //console.log("feedback exsit and livepoll, chat not exsit");
            //console.log(this.state.feedback + "?firstname=" + global.$attendeeDetails.fname + "&lastname=" + global.$attendeeDetails.lname + "&email=" + global.$attendeeDetails.email + "&userid=" + global.$attendeeDetails.userid + "&company=" + global.$attendeeDetails.company + "&title=" + encodeURIComponent(this.state.title) + "&sessionid=" + this.state.sessionid + "&sessionpoints=" + this.state.sessionpoints + "&sessiondate=" + this.props.sessiondate + "&sessionspeakers=" + this.props.session.SP )
            return (
                <div>
                    <Row>
                        <Col md={12}>
                            {
                                this.state.enablefeedbackwithduration == true ?
                                    (
                                        <Tabs defaultActiveKey="feedback" transition={false} className="myClass">
                                            <Tab eventKey="feedback" title={global.$session.liveoptions.feedbacktitle} className="tab-item">
                                                <SessionFeedbackTab
                                                    showfeedback={this.state.showfeedback}
                                                    feedback={this.state.feedback}
                                                    session={this.props.session}
                                                    sessiondate={this.props.sessiondate} />
                                            </Tab>
                                        </Tabs>
                                    )
                                    :
                                    ('')
                            }
                            {/*this.showHalls()*/}
                        </Col>
                    </Row>
                </div>
            )
        }
        else if (livepoll !== "empty" && feedback == "empty" && chat == "empty")    //livepoll exsit and feedback, chat not exsit
        {
            return (
                <div>
                    <Row>
                        <Col md={12}>
                            <Tabs defaultActiveKey="livepoll" transition={false} className="myClass">
                                <Tab eventKey="livepoll" title={global.$session.liveoptions.livepolltitle} className="tab-item">
                                    <iframe src={this.state.livepoll}
                                        id="myId"
                                        className="myClassname"
                                        display="initial"
                                        position="relative"
                                        style={this.state.iframestyle}
                                    />
                                </Tab>

                                {/*this.showHalls()*/}
                            </Tabs>
                        </Col>
                    </Row>
                </div>
            )
        }
        else if (chat !== "empty" && feedback == "empty" && livepoll == "empty")    //chat exsit and livepoll, feedback not exsit
        {
            //console.log("only chat")
            return (
                <div>
                    <Row>
                        <Col md={12}>
                            <Tabs defaultActiveKey="chat" transition={false} className="myClass">
                                <Tab eventKey="chat" title={global.$session.liveoptions.chattitle} className="tab-item">
                                    <Chat code={this.state.code} sessionid={this.state.sessionid} />
                                </Tab>

                                {/*this.showHalls()*/}
                            </Tabs>
                        </Col>
                    </Row>
                </div>
            )
        }
        if (feedback !== "empty" && livepoll !== "empty" && chat == "empty")    //feedback and livepoll exsit and chat not exsit
        {
            return (
                <div>
                    <Row>
                        <Col md={12}>
                            <Tabs defaultActiveKey="livepoll" transition={false} className="myClass">
                                {
                                    this.state.enablefeedbackwithduration == true ?
                                        (
                                            <Tab eventKey="feedback" title={global.$session.liveoptions.feedbacktitle} className="tab-item">
                                                <SessionFeedbackTab
                                                    showfeedback={this.state.showfeedback}
                                                    feedback={this.state.feedback}
                                                    session={this.props.session}
                                                    sessiondate={this.props.sessiondate} />
                                            </Tab>
                                        )
                                        :
                                        ('')
                                }
                                <Tab eventKey="livepoll" title={global.$session.liveoptions.livepolltitle} className="tab-item">
                                    <iframe src={this.state.livepoll}
                                        id="myId"
                                        className="myClassname"
                                        display="initial"
                                        position="relative"
                                        style={this.state.iframestyle}
                                    />
                                </Tab>

                                {/*this.showHalls()*/}
                            </Tabs>
                        </Col>
                    </Row>
                </div>
            )
        }
        else if (livepoll !== "empty" && feedback == "empty" && chat !== "empty")   //chat and livepoll exsit and feedback not exsit
        {
            return (
                <div>
                    <Row>
                        <Col md={12}>
                            <Tabs defaultActiveKey="chat" transition={false} className="myClass">
                                <Tab eventKey="chat" title={global.$session.liveoptions.chattitle} className="tab-item">
                                    <Chat code={this.state.code} sessionid={this.state.sessionid} />
                                </Tab>
                                <Tab eventKey="livepoll" title={global.$session.liveoptions.livepolltitle} className="tab-item">
                                    <iframe src={this.state.livepoll}
                                        id="myId"
                                        className="myClassname"
                                        display="initial"
                                        position="relative"
                                        style={this.state.iframestyle}
                                    />
                                </Tab>

                                {/*this.showHalls()*/}
                            </Tabs>
                        </Col>
                    </Row>
                </div>
            )
        }
        else if (chat !== "empty" && feedback !== "empty" && livepoll == "empty")   //feedback and chat exsit and livepoll not exsit
        {
            //console.log("Adding chat.."); 

            return (
                <div>
                    <Row>
                        <Col md={12}>
                            <Tabs defaultActiveKey="chat" transition={false} className="myClass">
                                <Tab eventKey="chat" title={global.$session.liveoptions.chattitle} className="tab-item">
                                    <Chat code={this.state.code} sessionid={this.state.sessionid} />
                                </Tab>
                                {
                                    this.state.enablefeedbackwithduration == true ?
                                        (
                                            <Tab eventKey="feedback" title={global.$session.liveoptions.feedbacktitle} className="tab-item">
                                                <SessionFeedbackTab
                                                    showfeedback={this.state.showfeedback}
                                                    feedback={this.state.feedback}
                                                    session={this.props.session}
                                                    sessiondate={this.props.sessiondate} />
                                            </Tab>
                                        )
                                        :
                                        (
                                            ''
                                        )
                                }

                                {/*this.showHalls()*/}
                            </Tabs>
                        </Col>
                    </Row>
                </div>
            )
        }
        else if (chat !== "empty" && feedback !== "empty" && livepoll !== "empty")  // all exist
        {
            //console.log(this.state)
            //console.log(this.state.feedback + "?firstname=" + global.$attendeeDetails.fname + "&lastname=" + global.$attendeeDetails.lname + "&email=" + global.$attendeeDetails.email + "&userid=" + global.$attendeeDetails.userid + "&company=" + global.$attendeeDetails.company + "&title=" + encodeURIComponent(this.state.title) + "&sessionid=" + this.state.sessionid + "&sessionpoints=" + this.state.sessionpoints + "&sessiondate=" + this.props.sessiondate + "&sessionspeakers=" + this.props.session.SP )

            return (
                <div>
                    <Row>
                        <Col md={12}>
                            <Tabs defaultActiveKey="chat" transition={false} className="myClass">
                                <Tab eventKey="chat" title={global.$session.liveoptions.chattitle} className="tab-item">
                                    <Chat code={this.state.code} sessionid={this.state.sessionid} />
                                </Tab>
                                {
                                    this.state.enablefeedbackwithduration == true ?
                                        (
                                            <Tab eventKey="feedback" title={global.$session.liveoptions.feedbacktitle} className="tab-item">
                                                <SessionFeedbackTab
                                                    showfeedback={this.state.showfeedback}
                                                    feedback={this.state.feedback}
                                                    session={this.props.session}
                                                    sessiondate={this.props.sessiondate} />
                                            </Tab>
                                        )
                                        :
                                        ('')
                                }
                                <Tab eventKey="livepoll" title={global.$session.liveoptions.livepolltitle} className="tab-item">
                                    <iframe src={this.state.livepoll}
                                        id="myId"
                                        className="myClassname"
                                        display="initial"
                                        position="relative"
                                        style={this.state.iframestyle}
                                    />
                                </Tab>

                                {/*this.showHalls()*/}
                            </Tabs>
                        </Col>
                    </Row>
                </div>
            )
        }
    }
    getPostTabs() {
        //console.log("post tabs");
        //console.log(this.state);
       //console.log(this.state.enablefeedbackwithduration)
       //console.log(global.$session);
        if (this.state.feedback == " " || this.state.feedback == "") {
            this.state.feedback = "empty"
        }
        if (this.state.chat == " " || this.state.chat == "") {
            this.state.chat = "empty"
        }
        eventtimezonecurrentdate = moment().tz(`${global.$settings.timezone}`).format('L LTS');

        if (global.$session.enablefeedbackaftersession != null && global.$session.enablefeedbackaftersession == "Y" && global.$session.enablechataftersession != null && global.$session.enablechataftersession == "Y") {
            if (this.state.chat !== "empty" && this.state.feedback !== "empty") {
                return (
                    <div>
                        {
                            this.state.enablefeedbackwithduration == false ?
                                (
                                    global.$session.feedbackdivwhendurationadded != null ?
                                        (
                                            <div dangerouslySetInnerHTML={{ __html: global.$session.feedbackdivwhendurationadded }}></div>
                                        )
                                        :
                                        ('')
                                )
                                :
                                ('')
                        }
                        <Row>
                            <Col md={12}>
                                <Tabs defaultActiveKey="chat" transition={false} className="myClass">
                                    <Tab eventKey="chat" title={global.$session.liveoptions.chattitle} className="tab-item">
                                        <Chat code={this.state.code} sessionid={this.state.sessionid} />
                                    </Tab>
                                    {
                                        this.state.enablefeedbackwithduration == true ?
                                            (
                                                <Tab eventKey="feedback" title={global.$session.liveoptions.feedbacktitle} className="tab-item">
                                                    <SessionFeedbackTab
                                                        showfeedback={this.state.showfeedback}
                                                        feedback={this.state.feedback}
                                                        session={this.props.session}
                                                        sessiondate={this.props.sessiondate} />
                                                </Tab>
                                            )
                                            :
                                            ('')
                                    }
                                </Tabs>
                            </Col>
                        </Row>
                    </div>
                )
            }
        }
        else if (global.$session.enablefeedbackaftersession != null && global.$session.enablefeedbackaftersession == "Y") {
            //console.log("feedback");
            if (this.state.feedback !== "empty") {
                return (
                    <div>

                        {
                            this.state.enablefeedbackwithduration == false ?
                                (
                                    global.$session.feedbackdivwhendurationadded != null ?
                                        (
                                            <div dangerouslySetInnerHTML={{ __html: global.$session.feedbackdivwhendurationadded }}></div>
                                        )
                                        :
                                        ('')
                                )
                                :
                                ('')
                        }
                        <Row>
                            <Col md={12}>
                                {
                                    this.state.enablefeedbackwithduration == true ?
                                        (
                                            <Tabs defaultActiveKey="feedback" transition={false} className="myClass" className="myClass">
                                                <Tab eventKey="feedback" title={global.$session.liveoptions.feedbacktitle} className="tab-item">
                                                    <SessionFeedbackTab
                                                        showfeedback={this.state.showfeedback}
                                                        feedback={this.state.feedback}
                                                        session={this.props.session}
                                                        sessiondate={this.props.sessiondate} />
                                                </Tab>
                                            </Tabs>
                                        )
                                        :
                                        ('')
                                }

                                {/*this.showHalls()*/}
                            </Col>
                        </Row>
                    </div >
                )
            }
        }
        else if (global.$session.enablechataftersession != null && global.$session.enablechataftersession == "Y") {
            if (this.state.chat !== "empty") {
                return (
                    <div>
                        <Row>
                            <Col md={12}>
                                <Tabs defaultActiveKey="chat" transition={false} className="myClass">
                                    <Tab eventKey="chat" title={global.$session.liveoptions.chattitle} className="tab-item">
                                        <Chat code={this.state.code} sessionid={this.state.sessionid} />
                                    </Tab>
                                </Tabs>
                            </Col>
                        </Row>
                    </div>
                )
            }
        }
        else {
            return (
                <div>
                    {/*this.showHalls()*/}
                </div>
            )
        }
    }

    getHallTabs() {
        //console.log(this.props)
        if (global.$schedule.numberofdays == "5") {
            if (this.props.stag.includes(global.$schedule.stag1)) {
                return (
                    <div>
                        {this.getHallNames(global.$schedule.day1text)}
                    </div>
                )
            }
            else if (this.props.stag.includes(global.$schedule.stag2)) {
                return (
                    <div>
                        {this.getHallNames(global.$schedule.day2text)}
                    </div>
                )
            }
            else if (this.props.stag.includes(global.$schedule.stag3)) {
                return (
                    <div>
                        {this.getHallNames(global.$schedule.day3text)}
                    </div>
                )
            }
            else if (this.props.stag.includes(global.$schedule.stag4)) {
                return (
                    <div>
                        {this.getHallNames(global.$schedule.day4text)}
                    </div>
                )
            }
        }
    }
    getHallNames(stag) {
        return (
            <div>
                <Row>
                    <Col md={12}>
                        <div className="halla">
                            <Link to={`/Schedule`} onClick={() => this.handleScheduleTab(stag, "Hall A")} className="session-halls">Hall A</Link>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <div className="hallb">
                            <Link to={`/Schedule`} onClick={() => this.handleScheduleTab(stag, "Hall B")} className="session-halls">Hall B</Link>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <div className="hallc">
                            <Link to={`/Schedule`} onClick={() => this.handleScheduleTab(stag, "Hall C")} className="session-halls">Hall C</Link>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <div className="halld">
                            <Link to={`/Schedule`} onClick={() => this.handleScheduleTab(stag, "Hall D")} className="session-halls">Hall D</Link>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
    handleScheduleTab(stag, activetab) {
        //console.log(stag, activetab);
        global.$activeScheduleTab = stag;
        global.$activeScheduleSubTab = activetab;
    }
}
export default SessionTabs;


