import React from 'react';
import LoadPrivateImg from '../Components/Shared/LoadPrivateImg';
import PostImage from '../Posts/PostImage';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { Row, Col, Button } from 'reactstrap';
import Modal from "react-responsive-modal";
import ConversationsInput from './ConversationInput';
import { API } from 'aws-amplify';

const moment = require('moment-timezone');
class ConversationItemView extends React.Component {
  constructor(props) {

    super(props);

    //console.log(props)
    //console.log(global.$chat);
    this.state = {
      sentmessagestyle: global.$chat.styles.sentmessagestyle,
      receivedmessagestyle: global.$chat.styles.receivedmessagestyle,
      openpopup: false,
      conversation: [],
      receiverid: '',
      receivername: '',
      senderid: '',
      dataloaded: false
    };
  }
  render() {

    var fromMe = 'empty';

    //console.log(this.props.conversation);
    //console.log("senderid " + this.props.senderid);
    //console.log(global.$loginid);

      if (global.$loginid != null) {
        //console.log(global.$attendeeinfo)
        if (global.$loginid == this.props.conversation.senderid) {
          fromMe = 'from-me';
        }
      }

      var d = new Date(this.props.conversation.timestamp * 1000);

      var dateFormatter = moment(d).format('MMM D, h:mm A')
      //console.log(dateFormatter);
      //console.log(fromMe);
      //console.log(this.props.conversations);
      if(this.props.receiverid == this.props.conversations.p1)
      {
        this.state.receivername = this.props.conversations.p1name;
      }
      else if(this.props.receiverid == this.props.conversations.p2)
      {
        this.state.receivername = this.props.conversations.p2name;  
      }

      return (
        <div>
          {
            fromMe.includes('from-me') ?
              (
                <div className="conversation-main-div">
                  {
                    <div className={`message ${fromMe}`}>
                      <div className="sub-div-from-me">
                        <div className={`message-body-${fromMe}`} style={this.state.sentmessagestyle}>
                          {ReactHtmlParser(this.props.conversation.msg)}
                        </div>
                      </div>
                      <div className={`message-date message-date-${fromMe}`}>
                        {dateFormatter}
                      </div>
                      <br />
                    </div>
                  }
                </div>
              )
              :
              (
                <div className="conversation-main-div">
                  {
                    <div className={`message`}>
                      <div className="main-div">
                        <div className="conversation-popup-img-div">
                          <LoadPrivateImg id={this.props.receiverid} name={this.state.receivername} component="posts" code={global.$code} height="40" width="40" />
                        </div>
                        <div className="conversation-popup-info-div">
                          <div className='username'>
                            {this.state.receivername}
                          </div>
                          <div className='message-body' style={this.state.receivedmessagestyle}>
                            {ReactHtmlParser(this.props.conversation.msg)}
                          </div>
                          <div className={`message-date message-date`}>
                            {dateFormatter}
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              )
          }
        </div>
      );
  }
/*
  findConversation() {
    var cid;
    var senderid = this.props.senderid;
    var receiverid = this.props.receiverid;
    //console.log(senderid, receiverid);
    var array = [];
    array.push(senderid, receiverid);
    //console.log(array);
    array.sort();
    //console.log(array);
    var array1 = array[0];
    var array2 = array[1];
    cid = array1 + "-" + array2
    //console.log(cid);

   //console.log(this.props.conversations);

    if (this.props.conversations == null || this.props.conversations == "" || this.props.conversations == " " || this.props.conversations == [] || this.props.conversations.length == 0) {
      this.getConversations()
    }
    else {
      var conversationObj = this.props.conversations.map(item => item.cid === cid);
      //console.log(conversationObj);
      this.setState({
        receiverid: conversationObj.p1,
        receivername: conversationObj.p2name,
        senderid: conversationObj.p1,
        dataloaded: true
      });
    }
  }
  getConversations() {
    let apiName = 'ChatService';
    let path = ''; //replace this with the path you have configured on your API
    let myInit = {
      body:
      {
        'code': global.$code,
        'command': 'getConversations',
        'p1': global.$userid,
      },
      response: true,
      convertEmptyValues: true,
      headers: {}
    }

    //console.log(myInit);
    //console.log('ChatMessages:getMessages()');

    API.post(apiName, path, myInit).then(response => {
      //console.log(response);

      if (response.err == null && response.data != null) {
       //console.log(response.data.Items);
        var conversations = this.getChatTableObjects(response.data.Items);

        var cid;
        var senderid = this.props.senderid;
        var receiverid = this.props.receiverid;
        //console.log(senderid, receiverid);
        var array = [];
        array.push(senderid, receiverid);
        //console.log(array);
        array.sort();
        //console.log(array);
        var array1 = array[0];
        var array2 = array[1];
        cid = array1 + "-" + array2

        var conversationObj = conversations.find(item => item.cid === cid);
        //console.log(conversationObj);
        this.setState({
          receiverid: conversationObj.p2,
          receivername: conversationObj.p2name,
          senderid: conversationObj.p1,
          dataloaded: true
        });

      }
      else {
        //console.log(response.err);
      }
    }
    ).catch(error => {
      //console.log(error)
    });
  }
  getChatTableObjects(objs) {
    //console.log(objs);
    var cid;
    var senderid = this.props.loginid;
    var receiverid = this.props.delegateid;
    //console.log(senderid, receiverid);
    var array = [];
    array.push(senderid, receiverid);
    //console.log(array);
    array.sort();
    //console.log(array);
    var array1 = array[0];
    var array2 = array[1];
    cid = array1 + "-" + array2

    const tableObjs = []

    for (var i = 0; i < objs.length; i++) {
      var chatObj = objs[i]

      //console.log(chatObj)

      var newObj = Object()
      //console.log(cid, chatObj.cid.S)
      newObj.cid = chatObj.cid.S
      newObj.cstatus = chatObj.cstatus.S
      newObj.lmsg = chatObj.lmsg.S
      newObj.lupdated = chatObj.lupdated.N
      newObj.mcount = chatObj.mcount.N
      newObj.p1 = chatObj.p1.S
      newObj.p1name = chatObj.p1name.S
      newObj.p2 = chatObj.p2.S
      newObj.p2name = chatObj.p2name.S
      tableObjs.push(newObj)
    }


    return tableObjs
  }*/
}
export default ConversationItemView;