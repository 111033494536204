import React from 'react'
import Modal from "react-responsive-modal";

function RegMoreInfoPopup({showPopup,closePopup,moreInfo}) {
   
    const descriptionConvertHtml = (desc) => {
        return { __html: desc };
    };

    const Subitems = () => {
        const resultString = moreInfo && moreInfo.desc.replace(/<details(?!\s*open)/g, '<details open');
        return (
            moreInfo && moreInfo.subitems.trim() != "" ? (
                <>
                   <div className='more-info-reg-description' dangerouslySetInnerHTML={descriptionConvertHtml(resultString)}/>
                    <table className='more-info-table'>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Price</th>
                    </tr>
                    </thead>
                    <tbody>
                    {moreInfo.subitems.trim() !== "" ? (
                        JSON.parse(moreInfo.subitems).map((item, index) => {
                        if (item.name !== "Select") {
                           
                            const rows = global.$regsubitems.map((subitem) => {
                                if (subitem.data5 === item.id) {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <p className='more-info-title'>{item.name}</p>
                                                <div className='more-info-description' dangerouslySetInnerHTML={descriptionConvertHtml(subitem.desc)}/>
                                            </td>
                                            <td>${parseFloat(item.price).toLocaleString()}</td>
                                        </tr>
                                    );
                                } return null; 
                            });
                            return rows.filter(Boolean);
                        }
                        return null; 
                        })
                        ) : (null)
                    }
                    </tbody>
                </table>
                </>
            ) : ("")
        )
    }

    const RegitemDesc = () => {
        
        if (moreInfo && moreInfo.inputtype != "dropdown") {
            const resultString = moreInfo && moreInfo.desc.replace(/<details(?!\s*open)/g, '<details open');
            return (
                <>
                    <p className='total-amount-item'>Price: ${parseFloat(moreInfo.amount).toLocaleString()}</p>
                    <div className='more-info-reg-description' dangerouslySetInnerHTML={descriptionConvertHtml(resultString)}/>
                </>
            )
        }
        return (null)
    }
    
    return (
        <div>
            <Modal
                open={showPopup}
                onClose={closePopup}
                blockScroll={false}
                closeOnOverlayClick={true}
                className='more-info-popup'
                styles={{
                    modal: {minWidth: '350px',borderRadius:'10px' },
                    closeIcon: { display:'none' }
                }}
                center>
                <section className='more-info-wrapper-section'>
                    <h4 className='moreinfo-title'>{moreInfo.name}</h4>
                    <Subitems />
                    <RegitemDesc />
                </section>

            </Modal>
        </div>
  )
}

export default RegMoreInfoPopup