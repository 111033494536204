import React from 'react';
import { API } from 'aws-amplify';

const moment = require('moment-timezone');
var serviceNames = require('../ServiceNameMapper');

class UpdatePoints extends React.Component {

    constructor(props) {

       //console.log(props)
        super(props);

        this.state = {
        };

    }

    render() {
        return (
            <div className="container">
                {
                    this.updateAttendeeVisitPoints()
                }
            </div>
        );
    }

    updateAttendeeVisitPoints() {
        let apiName = serviceNames.getBadgeScanService();
        let path = ''; //replace this with the path you have configured on your API
        var tagid = '';
        
        if (this.props.component == "exhibitor") {
            tagid = `A#:ID:${this.props.area};Company:${this.props.sponsor.NAME};Name:${this.props.sponsor.CNAME};Title:${this.props.sponsor.TITLE};Email:${this.props.sponsor.EMAIL};Phone:${this.props.sponsor.PHONE};Web:${this.props.sponsor.WEB}`
        }
        
        if (this.props.component == "session") {
            tagid = `A#:ID:${this.props.area};Title:${this.props.session.TITLE};Begin:${this.props.session.BEGIN};End:${this.props.session.END};SID:${this.props.session.SID}`
        }

        if (this.props.component == "session-checkin") {

            tagid = `A#:ID:${this.props.area};Title:${this.props.session.TITLE};Begin:${this.props.session.BEGIN};End:${this.props.session.END};SID:${this.props.session.SID};CID:${this.props.checkinid}`
        }

        if (this.props.component == "conversation") {
            tagid = `A#:ID:${this.props.area};Receiverid:${this.props.receiverid}`
        }

        var date = moment().tz(global.$settings.timezone).format("DD/MM/YYYY, hh:mm:ss A");
        let myInit = {
            body:
            {
                'code': global.$code,
                'command': 'postBadgeScan',
                'userid': global.$userid,
                'tagid': tagid,
                'name': global.$fname + " " + global.$lname,
                'time': date,
                'company': global.$company,
                'regid': global.$regid,
                'scannedby': global.$userid,
                'title': global.$title,
                'phone': global.$phone,
                'email': global.$email,
                'points': this.props.points,
                'component': this.props.component
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
       //console.log(myInit);

        API.post(apiName, path, myInit).then(response => {
            //console.log ("response.data ===> " + response.data + "response.err ===> " + response.err);

            if (response.err == null && response.data != null) {
                //console.log(response.data);
            }
            else {
                //console.log(response.err);
            }
        }
        ).catch(error => {
            //console.log(error.response)
        });
    }

}

export default UpdatePoints;