import React from 'react';
import { API, Analytics } from 'aws-amplify';
import ChatMessages from './ChatMessages';
import ChatInput from './ChatInput';

require('./Chat.css');

/*

Message scrolling:
https://stackoverflow.com/questions/25505778/automatically-scroll-down-chat-div


TODO:

  - Post pictures
  - Timer to refresh messages
  - Correctly enable chat in sessions
  - Style/color for chat messages and div
  - styles to webconfig


  - Reacstrap round corner, send button
  - Emoji enter
  - Add color/date time below message
  - Add photo        
  - Global attendee issue, sender id style

    
*/

class Chat extends React.Component {
  
  constructor(props) {
    
    super(props);

    this.state = { 
      code: this.props.code,
      sessionid: this.props.sessionid,
      messages: [],
      refresh: false
    };

    this.sendHandler = this.sendHandler.bind(this);

  }

  sendHandler(message) 
  {
    /*
    const messageObject = {
      username: this.props.username,
      message
    };

    // Emit the message to the server
    //this.socket.emit('client:message', messageObject);

    messageObject.fromMe = true;
    this.addMessage(messageObject);
    
   //console.log('Chat:refresh()');

    this.setState({ 
        refresh: true
    });
    */

  }

  render() {
   //console.log('Chat render()');
    
    return (
      <div className="container">
        <ChatMessages code= {this.state.code} sessionid= {this.state.sessionid} />
      </div>
    );
  }

} //Class

/*
Chat.defaultProps = {
  username: 'Anonymous'
};
*/

export default Chat;