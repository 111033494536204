import React, { Component } from 'react'
import { FormGroup, Label, Input, FormText, Card, CardHeader, CardBlock, Row, Col } from 'reactstrap';
import { mdiAccountTie, mdiKeyboardBackspace, mdiAccountCircle, mdiFormatText, mdiFileDocumentBox, mdiShape, mdiDomain, mdiImage, mdiMessageText, mdiEmailCheck, mdiCellphoneIphone, mdiWeb, mdiLinkedin, mdiFormatTitle, mdiFormatListBulletedType, mdiMessageReplyText, mdiFileDocument, mdiSort, mdiVideo, mdiAccount, mdiDatabase, mdiDeleteForever, mdiTwitter, mdiFacebook, mdiInstagram } from '@mdi/js';
import Icon from '@mdi/react';
import { Editor } from 'react-draft-wysiwyg';
import { Progress } from 'reactstrap';
import { Link } from "react-router-dom";
import { Spinner } from 'react-bootstrap';
import { ReactSpinner } from 'react-spinning-wheel';
import './SpeakerProfile.css'
import ServiceName from '../ServiceNameMapper'
import { Storage, API } from 'aws-amplify';
import SpeakerProfileFunctions from './SpeakerProfileFunctions';
import draftToHtml from 'draftjs-to-html';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';

//var max_length = 12;

export default class SpeakerProfile extends Component {

  constructor(){
    super();
    this.state = {
      code: global.$code,
      id: global.$loggedinuser.userid,
	    company: '',
      dataloaded: false,
      data1: '',
      data2: '',
      data3: '',
      data4: '',
      data5: '',
      descp1: '',
      descp2: '',
      descp3: '',
      disabled: false,
      editorState: EditorState.createEmpty(),
      email: '',
      file1: '',
      file1FileHandle: '',
      file1FileName: ' ',
      file1progress: 0,
      file1progresstext: '',
      file2: '',
      file2FileHandle: '',
      file2FileName: ' ',
      file2progress: 0,
      file2progresstext: '',
      file3: '',
      file3FileHandle: '',
      file3FileName: ' ',
      file3progress: 0,
      file3progresstext: '',
      fullname: '',
      imageUrl: '',
      isLoading: false,
      phone: '',
      photoFileHandle: '',
      photoFileName: '',
      social1: '',
      social2: '',
      social3: '',
      social4: '',
      social5: '',
      social6: '',
      title: '',
      titlep1: '',
      titlep2: '',
      titlep3: '',
      typep1: '',
      typep2: '',
      typep3: '',
      type: '',
      url: '',
      userid: global.$loggedinuser.userid,
      videos3: '',
      videos3progress: 0,
      videos3progresstext: '',
      videourl: '',
      linkedin: '',
      scategory: '',
      stype: '',
      sortorder: '',
      updatespeaker:true,
      enablevideo: true,
      docPermission: "Yes",
      decError: "",
      maxlength:0
    }
    this.handleInputChange = this.handleInputChange.bind(this);
    this.uploadFile1 = this.uploadFile1.bind(this);
    this.uploadFile2 = this.uploadFile2.bind(this);
    this.uploadFile3 = this.uploadFile3.bind(this);
    this.fileUploader = this.fileUploader.bind(this);
    this.uploadVideoToS3 = this.uploadVideoToS3.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleDocpermission = this.handleDocpermission.bind(this);
     this.onEditorStateChange = this.onEditorStateChange.bind(this);

    if (global.$config.speakerprofile && global.$config.speakerprofile.enablevideo != null) {
      this.state.enablevideo = global.$config.speakerprofile.enablevideo
    }

    if(global.$config.speakerprofile && global.$config.speakerprofile.document1.doctype.value != null) {
      this.state.typep1 = global.$config.speakerprofile.document1.doctype.value
    }

    if(global.$config.speakerprofile && global.$config.speakerprofile.document2.doctype.value != null) {
      this.state.typep2 = global.$config.speakerprofile.document2.doctype.value
    }

    if(global.$config.speakerprofile && global.$config.speakerprofile.document3.doctype.value != null) {
      this.state.typep3 = global.$config.speakerprofile.document3.doctype.value
    }
  }

  componentDidMount(){
    this.getSpeaker()
  }

  render() {
    var speakerprofile = global.$config.speakerprofile;

    if (this.state.dataloaded) {
     
        return (
          <div className="container">
    
              {global.$config?.speakerprofile?.speakeroverview?.enable &&
                <Card className="card_wrapper">
                  <Row className='mt-4 mb-4'>
                      <Col>
                        <div className='speaker-overview-ttile' dangerouslySetInnerHTML={{ __html: global.$config.speakerprofile.speakeroverview.title.value }}></div>
                        <div className='speaker-overview-desc' dangerouslySetInnerHTML={{ __html: global.$config.speakerprofile.speakeroverview.desc.value }}></div>
                      </Col>
                  </Row>
                </Card>}
              <Card className="card_wrapper">
                  <CardHeader className="form-header-1 card_header">Speaker Information</CardHeader>
                  <Row>
                      <Col md={7}>
                          <Row className="form-labels mt-4 form-labels_1">
                              <Col md={8} className="labels_column">
                                <Label for="name" ><Icon path={mdiAccountTie} size={0.9} color="lightgray" className="form-label-icon" />Full Name</Label>
                                <Input type="text" name="fullname" className="form-control-sm" id="fullname" value={this.state.fullname || ''} onChange={this.handleInputChange} />
                              </Col>
                              <Col md={4} className="labels_column">
                                <Label for="data1" ><Icon path={mdiAccountTie} size={0.9} color="lightgray" className="form-label-icon" />Pronouns</Label>
                                <Input type="text" name="data1" placeholder={'she/her;  he/him;  they/them; ze/hir'} className="form-control-sm" id="Pronouns" value={this.state.data1 || ''} onChange={this.handleInputChange} />
                              </Col>
                          </Row>
    
                          <FormGroup className="form-labels">
                            <Label for="title" ><Icon path={mdiFormatText} size={0.9} color="lightgray" className="form-label-icon" />Title</Label>
                            <Input type="text" name="title" className="form-control-sm" id="title" value={this.state.title || ''} onChange={this.handleInputChange} />
                          </FormGroup>
    
                          <FormGroup className="form-labels">
                            <Label for="company" ><Icon path={mdiDomain} size={0.9} color="lightgray" className="form-label-icon" />Company</Label>
                            <Input type="text" name="company" className="form-control-sm" id="company" value={this.state.company || ''} onChange={this.handleInputChange} />
                          </FormGroup>
    
                          {this.state.enablevideo ? <FormGroup className="form-labels">
                                <Label for="videourl"><Icon path={mdiVideo} size={0.9} color="lightgray" className="form-label-icon" />Video</Label>
                                <div class="custom-file custom_video_file">
                                  <input type="file" name="videos3" class="custom-file-input" id="inputGroupFile04" value={this.state.videos3 || ''} onChange={this.uploadVideoToS3} accept="video/mp4,video/x-m4v,video/*" ref={ref => this.videos3 = ref} />
                                  <label class="custom-file-label" for="inputGroupFile04" style={{overflow:'hidden'}}>{this.state.videourl}</label>
                                </div>
                                <FormText style={{ marginTop: "5px" }}>Enter video link or upload file as video format</FormText>
                                {this.state.videos3progress > 0 ? <Progress animated color="success" value={this.state.videos3progress} className='mt-2' />: ''}
                                <FormText style={{ marginTop: "5px" }}>{this.state.videos3progresstext}</FormText>
                          </FormGroup> :""}
                      </Col>
                      <Col md={5} className="speaker_profile_icon_wrapper">
                        <FormGroup className="form-labels ">
                        <Label for="photo" className="custom-fileupload">
                            {
                              this.state.imageUrl == '' || this.state.imageUrl == null || this.state.imageUrl == ' ' || this.state.photoFileName == "profile.png" ?
                                <Icon path={mdiAccountCircle} size={8} color="lightgray" className="" />  : 
                                <img id="profilephoto" src={this.state.imageUrl || ''} alt="" />
                            }
                        </Label>
                            <input ref="uploadImg" type="file" name="photo" className="" id="photo" accept=".gif,.jpg,.jpeg,.png" onChange={this.fileUploader} hidden />
                            <p class="text"><span className="pen-icon">&#9998;</span></p>
                        </FormGroup>
                        <span className='delete-file-btn2' onClick={() => { if (window.confirm(`Do you want to delete the Image?`)) { this.removeImage() }; }} title={'Delete Image'}><Icon path={mdiDeleteForever} size={1} color="gray"/></span>
                      </Col>
                </Row>
                <Row className='mt-4'>
                      <Col md={12}>
                          <Label for="Description" className="description-title">
                            <Icon path={mdiMessageText} size={0.9} color="lightgray" className="form-label-icon" />
                            Speaker Bio{global.$config?.speakerprofile?.speakerbiolimit && <span> (max limit {global.$config.speakerprofile.speakerbiolimit} characters)</span>}
                            <p className='text-max-count'>{this.state.maxlength} / {global.$config?.speakerprofile?.speakerbiolimit || 10000}</p>
                          </Label>
                          <Editor
                              editorState={this.state.editorState}
                              wrapperClassName="demo-wrapper"
                              editorClassName="demo-editor"
                              onEditorStateChange={this.onEditorStateChange}
                              placeholder="Enter description"
                              handleBeforeInput={this.handleBeforeInput}
                              handlePastedText={this.handlePastedText}
                          />
                          <p className='dec-error-message'>{this.state.decError}</p>
                      </Col>
                </Row>
              </Card>
    
              <Card className="card_wrapper">
                <CardHeader className="form-header-2 card_header" >Contact Information</CardHeader>
                <Row className='mt-4'>
                  <Col md={4}>
                    <FormGroup className="form-labels">
                        <Label for="email"><Icon path={mdiEmailCheck} size={0.9} color="lightgray" className="form-label-icon" />Email</Label>
                        <Input type="text" name="email" className="form-control-sm" id="email" value={this.state.email || ''} onChange={this.handleInputChange} />
                    </FormGroup>
                  </Col>                                       
    
                  <Col md={4}>
                      <FormGroup className="form-labels">
                          <Label for="phone"><Icon path={mdiCellphoneIphone} size={0.9} color="lightgray" className="form-label-icon" />Phone</Label>
                          <Input type="text" name="phone" className="form-control-sm" id="phone" value={this.state.phone || ''} onChange={this.handleInputChange} />
                          <FormText>Enter in NNN-NNN-NNNN format</FormText>
                      </FormGroup>
                  </Col>
    
                  <Col md={4}>
                      <FormGroup className="form-labels">
                          <Label for="url"><Icon path={mdiWeb} size={0.9} color="lightgray" className="form-label-icon" />Website</Label>
                          <Input type="text" name="url" className="form-control-sm" id="url" value={this.state.url || ''} onChange={this.handleInputChange} />
                          <FormText>Enter http://www.yourcompany.com</FormText>
                      </FormGroup>
                  </Col>
              </Row>
    
              <Row className='mt-2'>
                <Col md={3}>
                    <FormGroup className="form-labels">
                    <Label for="social1"><Icon path={mdiFacebook} size={0.9} color="lightgray" className="form-label-icon"/>Facebook</Label>
                    <Input type="text" name="social1" className="form-control-sm" id="social1" value={this.state.social1 || ""} onChange={this.handleInputChange}/>
                    </FormGroup>
                </Col>
    
                <Col md={3}>
                    <FormGroup className="form-labels">
                    <Label for="social2"><Icon path={mdiTwitter} size={0.9} color="lightgray" className="form-label-icon"/>Twitter</Label>
                    <Input type="text" name="social2" className="form-control-sm" id="social2" value={this.state.social2 || ""} onChange={this.handleInputChange}/>
                    </FormGroup>
                </Col>
                                        
                <Col md={3}>
                    <FormGroup className="form-labels">
                    <Label for="social3"><Icon path={mdiLinkedin} size={0.9} color="lightgray" className="form-label-icon"/>Linkedin</Label>
                    <Input type="text" name="social3" className="form-control-sm" id="social3" value={this.state.social3 || ""} onChange={this.handleInputChange}/>
                    </FormGroup>
                </Col>
    
                <Col md={3}>
                    <FormGroup className="form-labels">
                    <Label for="social4"><Icon path={mdiInstagram} size={0.9} color="lightgray" className="form-label-icon"/>Instagram</Label>
                    <Input type="text" name="social4" className="form-control-sm" id="social4" value={this.state.social4 || ""} onChange={this.handleInputChange}/>
                    </FormGroup>
                </Col>
              </Row>
              </Card>
    
              <Card className="card_wrapper">
                <CardHeader className="form-header-3 card_header" >Documents</CardHeader>       
                <Row className='mt-4'>
                    <Col md={4}>
                        <Label for="" className="login-labels" ><b>{speakerprofile?.document1?.title_text && speakerprofile.document1.title_text||'Document 1'}</b></Label>
                        {
                          speakerprofile?.document1?.title?.hide && speakerprofile.document1.title.hide
                          ?
                          ''
                          :
                          <>
                            <Label for="titlep1" className="login-labels" ><Icon path={mdiFormatTitle} size={0.9} color="lightgray" className="form-label-icon" />Title</Label>
                            <Input type="text" name="titlep1" className="form-control-sm" id="titlep1"  value={this.state.titlep1 || ''} onChange={this.handleInputChange} />
                          </>
                        }
                    </Col>
    
                    <Col md={4}>
                      <Col>
                        <Label for="" className="login-labels" ><b>{speakerprofile?.document2?.title_text && speakerprofile.document2.title_text||'Document 2'}</b></Label>
                      </Col>
                      {
                        speakerprofile?.document2?.title?.hide && speakerprofile.document2.title.hide
                        ?
                        ''
                        :
                        <>
                          <Label for="title" className="login-labels" ><Icon path={mdiFormatTitle} size={0.9} color="lightgray" className="form-label-icon" />Title</Label>
                          <Input type="text" name="titlep2" className="form-control-sm" id="titlep2"  value={this.state.titlep2 || ''} onChange={this.handleInputChange} />                          
                        </>
                      }                          
                    </Col>
    
                    <Col md={4}>
                      <Label for="" className="login-labels" ><b>{speakerprofile?.document3?.title_text && speakerprofile.document3.title_text||'Document 3'}</b></Label>
                      {
                        speakerprofile?.document3?.title?.hide && speakerprofile.document3.title.hide
                        ?
                        ''
                        :    
                        <>
                          <Label for="title" className="login-labels" ><Icon path={mdiFormatTitle} size={0.9} color="lightgray" className="form-label-icon" />Title</Label>
                          <Input type="text" name="titlep3" className="form-control-sm" id="titlep3"  value={this.state.titlep3 || ''} onChange={this.handleInputChange} />                        
                        </>                    
                      }
                    </Col>
                </Row>
    
                <Row className='select_items'>
                  {
                    speakerprofile?.document1?.doctype?.hide && speakerprofile.document1.doctype.hide
                    ?
                    ''
                    :
                    <Col md={4} className='mt-4'>
                      <Label className="login-labels"><Icon path={mdiFormatListBulletedType} size={0.9} color="lightgray" className="form-label-icon" />Type</Label>
                      <Input type="select" name="typep1" id="typep1" className="form-control-sm" value={this.state.typep1 || ''} onChange={this.handleInputChange}>
                        <option value="Select">Select</option>
                        <option value="Abstract Presentation">Abstract Presentation</option>
                        <option value="Oral Presentation">Oral Presentation</option>
                        <option value="Other">Other</option>
                        <option value="Poster Presentation">Poster Presentation</option>
                        <option value="Select">Select</option>
                        <option value="Session Evaluation">Session Evaluation</option>
                        <option value="Session Handout">Session Handout</option>
                        <option value="Speaker Agreement">Speaker Agreement</option>
                        <option value="Speaker Resume">Speaker Resume</option>
                      </Input>
                    </Col>
                  }
    
                  {
                    speakerprofile?.document2?.doctype?.hide && speakerprofile.document2.doctype.hide
                    ?
                    ''
                    :
                    <Col md={4} className='mt-4'>
                      <Label className="login-labels"><Icon path={mdiFormatListBulletedType} size={0.9} color="lightgray" className="form-label-icon" />Type</Label>
                      <Input type="select" name="typep2" id="typep2" className="form-control-sm" value={this.state.typep2 || ''} onChange={this.handleInputChange}>
                        <option value="Select">Select</option>
                          <option value="Abstract Presentation">Abstract Presentation</option>
                          <option value="Oral Presentation">Oral Presentation</option>
                          <option value="Other">Other</option>
                          <option value="Poster Presentation">Poster Presentation</option>
                          <option value="Speaker Agreement">Speaker Agreement</option>
                          <option value="Session Evaluation">Session Evaluation</option>
                          <option value="Session Handout">Session Handout</option>
                          <option value="Speaker Resume">Speaker Resume</option>
                      </Input>
                    </Col>
                  }  
    
                  {
                    speakerprofile?.document3?.doctype?.hide && speakerprofile.document3.doctype.hide
                    ?
                    ''
                    :
                    <Col md={4} className='mt-4'>
                      <Label className="login-labels"><Icon path={mdiFormatListBulletedType} size={0.9} color="lightgray" className="form-label-icon" />Type</Label>
                      <Input type="select" name="typep3" id="typep3" className="form-control-sm" value={this.state.typep3 || ''} onChange={this.handleInputChange}>
                        <option value="Select">Select</option>
                        <option value="Abstract Presentation">Abstract Presentation</option>
                        <option value="Oral Presentation">Oral Presentation</option>
                        <option value="Other">Other</option>
                        <option value="Poster Presentation">Poster Presentation</option>
                        <option value="Speaker Agreement">Speaker Agreement</option>
                        <option value="Session Evaluation">Session Evaluation</option>
                        <option value="Session Handout">Session Handout</option>
                        <option value="Speaker Resume">Speaker Resume</option>
                      </Input>
                    </Col>
                  }  
              </Row>
    
    
              <Row >
                {
                  speakerprofile?.document1?.description?.hide && speakerprofile.document1.description.hide
                  ?
                  ''
                  :
                  <Col md={4} className='mt-4'>
                    <Label for="Speaker Bio" className="login-labels"><Icon path={mdiMessageReplyText} size={0.9} color="lightgray" className="form-label-icon" />Description</Label>
                    <Input type="textarea" name="descp1" id="descp1" className="form-control-sm" value={this.state.descp1 || ''} onChange={this.handleInputChange} />
                    <FormText>Description about Presentation</FormText>
                  </Col>
                }

                {
                  speakerprofile?.document2?.description?.hide && speakerprofile.document2.description.hide
                  ?
                  ''
                  :
                  <Col md={4} className='mt-4'>
                    <Label for="Speaker Bio" className="login-labels"><Icon path={mdiMessageReplyText} size={0.9} color="lightgray" className="form-label-icon" />Description</Label>
                    <Input type="textarea" name="descp2" id="descp2" className="form-control-sm" value={this.state.descp2 || ''} onChange={this.handleInputChange} />
                    <FormText>Description about Presentation</FormText>
                  </Col>                  
                }

                {
                  speakerprofile?.document3?.description?.hide && speakerprofile.document3.description.hide
                  ?
                  ''
                  :
                  <Col md={4} className='mt-4'>
                    <Label for="Speaker Bio" className="login-labels"><Icon path={mdiMessageReplyText} size={0.9} color="lightgray" className="form-label-icon" />Description</Label>
                    <Input type="textarea" name="descp3" id="descp3" className="form-control-sm" value={this.state.descp3 || ''} onChange={this.handleInputChange} />
                    <FormText>Description about Presentation</FormText>
                  </Col>                  
                }
              </Row>
    
              <Row className='mt-2'>
                <Col md={4}>
                    <Label for="file" className="login-labels"><Icon path={mdiFileDocument} size={0.9} color="lightgray" className="form-label-icon" />File</Label>
                    <span className='delete-file-btn' onClick={() => { if (window.confirm(`Do you want to delete file?`)) { this.removeDocument1() }; }} title={'Delete file'}><Icon path={mdiDeleteForever} size={1} color="gray"/></span>
                    <Input type="file" name="file1" className="form-control-sm file_upload_input" id="file1" accept=".doc, .docx, .pdf, .pptx" value={this.state.file1} onChange={this.uploadFile1} ref={ref => this.file1 = ref} />
                    { this.state.file1progress > 0 ? <Progress animated color="success" value={this.state.file1progress} className='mt-2' /> : '' }
                    <p className='fileprogress_text'>{this.state.file1progresstext}</p>
                    {
                      this.state.file1FileName.includes(" ") ? ('') :
                      (<Link className="download_file" to="route" onClick={(event) => this.generateSingedUrl(event, this.state.file1FileName)}><i className="fa fa-download"></i>Download File</Link>)
                    }
                </Col>
    
                <Col md={4}>
                    <Label for="file2" className="login-labels"><Icon path={mdiFileDocument} size={0.9} color="lightgray" className="form-label-icon" />File</Label>
                    <span className='delete-file-btn' onClick={() => { if (window.confirm(`Do you want to delete file?`)) { this.removeDocument2() }; }} title={'Delete file'}><Icon path={mdiDeleteForever} size={1} color="gray"/></span>
                    <Input type="file" name="file2" className="form-control-sm file_upload_input" id="file2" accept=".doc, .docx, .pdf, .pptx" value={this.state.file2} onChange={this.uploadFile2} ref={ref => this.file2 = ref} />
                    { this.state.file2progress > 0 ? <Progress animated color="success" value={this.state.file2progress} className='mt-2'/> : ''}
                    <p className='fileprogress_text'>{this.state.file2progresstext}</p>
                    {
                      this.state.file2FileName.includes(" ") ? ('') :
                      (<Link className="col-offset-md-1" to="route" onClick={(event) => this.generateSingedUrl(event, this.state.file2FileName)}><i className="fa fa-download file_downloaded"></i>Download File</Link>)
                    }
                </Col>
    
                <Col md={4}>
                    <Label for="file3" className="login-labels"><Icon path={mdiFileDocument} size={0.9} color="lightgray" className="form-label-icon" />File</Label>
                    <span className='delete-file-btn' onClick={() => { if (window.confirm(`Do you want to delete file?`)) { this.removeDocument3() }; }} title={'Delete file'}><Icon path={mdiDeleteForever} size={1} color="gray"/></span>
                    <Input type="file" name="file3" className="form-control-sm file_upload_input" id="file3" accept=".doc, .docx, .pdf, .pptx" value={this.state.file3} onChange={this.uploadFile3} ref={ref => this.file3 = ref} />
                    { this.state.file3progress > 0 ? <Progress animated color="success" value={this.state.file3progress} className='mt-2'/> : ""}
                    <p className='fileprogress_text'>{this.state.file3progresstext}</p>
                    {
                      this.state.file3FileName.includes(" ") ? ('') :
                      (<Link className="col-offset-md-1" to="route" onClick={(event) => this.generateSingedUrl(event, this.state.file3FileName)}><i className="fa fa-download"></i>Download File</Link>)
                    }
                </Col>
                
              </Row>
              {global.$config?.speakerprofile?.enabledocpermission && 
                <Row className="pb-3 pt-2 d-flex justify-content-center align-items-center">
                  <Col md={9} className="permission-label-div">
                    <label for="permission" className='permission-lable'>{global.$config.speakerprofile.enabledocpermission_text}</label>
                    <select id="permission" class="form-control-sm" value={this.state.docPermission} onChange={this.handleDocpermission}>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                  </Col>
                  
                </Row>
              }
            </Card>
            {this.state.message ?<div className="alert alert-dark alert_message" role="alert" id="message">{this.state.message}</div>: ""}
    
            <div className='action_btns_wrapper'>
              
              {this.state.isLoading ?
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="Loading-btn"
                  variant="success"
                  hidden={!this.state.isLoading} /> : 
                <button
                  className="btn form-submit-button submit_btn"
                  onClick={this.handleSubmit}
                  disabled={this.state.disabled}
                  type="submit"
                  isLoading={this.state.isLoading}
                  text="Submit"
                  loadingText="Submitting…"
                >Submit</button>}
    
              <button type="button" className="btn form-clear-btn" onClick={this.handleClear} >Clear</button>
            </div>
          </div>
        )
    }
    else {
      return(
        <div className='loading_section_wrapper'>
            <ReactSpinner/>
        </div>
      )
    }
    
  }
  handleDocpermission(e) {

    console.log(e.target.value);
    this.setState({docPermission:e.target.value})
  }

  handleInputChange(event) {
    const target = event.target;
    const name = target.name;
    let value = target.value

    if (name === 'photo') {
      this.state.photoFileHandle = event.target.files[0];
    }
    else if (name === 'file1') {
      this.state.file1FileHandle = event.target.files[0];
    }
    else if (name === 'file2') {
      this.state.file2FileHandle = event.target.files[0];
    }
    else if (name === 'file3') {
      this.state.file3FileHandle = event.target.files[0];
    }
    else {
      this.setState({[name]: value });
    }

  }

// FILE UPLOAD 1
  async uploadFile1(event) {
    if(event && event.target !== undefined){

      // GET TARGET VALUES
      const target = event.target;
      const name = target.name;
      let value = target.value

      // GET FILES
      this.state.file1FileHandle = target.files[0];
      this.setState({ file1progress: 25 });
      this.setState({ file1progresstext: "Uploading..." });

      // CREATE DOCUEMNT FILE NAME
      this.state.file1FileName = SpeakerProfileFunctions.getSpeakerFileName(this.state.id, this.state.file1FileHandle.name,'p1');
      this.setState({ file1progress: 50 });

      // CREATE S3 FILE NAME
      const s3File = SpeakerProfileFunctions.getS3SpeakerFileName(this.state.code,this.state.file1FileName);
      this.setState({ file1progress: 75 });

      // GET FILE EXTENSION
      const extension = this.state.file1FileName.split('.').pop();

      try {
        // STORE FILE
        let result = await Storage.put(s3File, this.state.file1FileHandle,{ contentType: 'application/' + extension })
       
        if (result.err == null) {
            this.setState({
                file1progress: 100,
                file1progresstext: "Presentation1 uploaded successfully"
            });
            this.file1.value = "";

            // DOWNLOAD COPY OF THE UPLOADED FILE
            let copayFiles = await SpeakerProfileFunctions.copyFiles(this.state.file1FileName,this.state.code);
            this.forceUpdate();
        }
      } 
      catch (error) {
        console.log(error)
        this.setState({
          file1progress: 0,
          file1progresstext: "Unable to upload file. Please try again"
        });
        this.forceUpdate();
      }

    }
  }

// FILE UPLOAD 2
  async uploadFile2(event) {
    if(event && event.target !== undefined){

      // GET TARGET VALUES
      const target = event.target;
      const name = target.name;
      let value = target.value

      // GET FILES
      this.state.file2FileHandle = target.files[0];
      this.setState({ file2progress: 25 });
      this.setState({ file2progresstext: "Uploading..." });

      // CREATE DOCUEMNT FILE NAME
      this.state.file2FileName = SpeakerProfileFunctions.getSpeakerFileName(this.state.id, this.state.file2FileHandle.name,'p2');
      this.setState({ file2progress: 50 });

      // CREATE S3 FILE NAME
      const s3File = SpeakerProfileFunctions.getS3SpeakerFileName(this.state.code,this.state.file2FileName);
      this.setState({ file2progress: 75 });

      // GET FILE EXTENSION
      const extension = this.state.file2FileName.split('.').pop();

      try {
        // STORE FILE
        let result = await Storage.put(s3File, this.state.file2FileHandle,{ contentType: 'application/' + extension })
       
        if (result.err == null) {
            this.setState({
                file2progress: 100,
                file2progresstext: "Presentation2 uploaded successfully"
            });
            this.file1.value = "";

            // DOWNLOAD COPY OF THE UPLOADED FILE
            let copayFiles = await SpeakerProfileFunctions.copyFiles(this.state.file2FileName,this.state.code);
            this.forceUpdate();
        }
      } 
      catch (error) {
        console.log(error)
        this.setState({
          file2progress: 0,
          file2progresstext: "Unable to upload file. Please try again"
        });
        this.forceUpdate();
      }

    }
  }

// FILE UPLOAD 3
  async uploadFile3(event) {
    if(event && event.target !== undefined){

      // GET TARGET VALUES
      const target = event.target;
      const name = target.name;
      let value = target.value

      // GET FILES
      this.state.file3FileHandle = target.files[0];
      this.setState({ file3progress: 25 });
      this.setState({ file3progresstext: "Uploading..." });

      // CREATE DOCUEMNT FILE NAME
      this.state.file3FileName = SpeakerProfileFunctions.getSpeakerFileName(this.state.id, this.state.file3FileHandle.name,'p3');
      this.setState({ file3progress: 50 });

      // CREATE S3 FILE NAME
      const s3File = SpeakerProfileFunctions.getS3SpeakerFileName(this.state.code,this.state.file3FileName);
      this.setState({ file3progress: 75 });

      // GET FILE EXTENSION
      const extension = this.state.file3FileName.split('.').pop();

      try {
        // STORE FILE
        let result = await Storage.put(s3File, this.state.file3FileHandle,{ contentType: 'application/' + extension })
       
        if (result.err == null) {
            this.setState({
                file3progress: 100,
                file3progresstext: "Presentation3 uploaded successfully"
            });
            this.file1.value = "";

            // DOWNLOAD COPY OF THE UPLOADED FILE
            let copayFiles = await SpeakerProfileFunctions.copyFiles(this.state.file3FileName,this.state.code);
            this.forceUpdate();
        }
      } 
      catch (error) {
        console.log(error)
        this.setState({
          file3progress: 0,
          file3progresstext: "Unable to upload file. Please try again"
        });
        this.forceUpdate();
      }

    }
  }

// REMOVE DOCUMENT 1
  removeDocument1(){
    this.setState({ file1 : "",file1FileName: " ",file1progress:0,file1progresstext:''})
  }
// REMOVE DOCUMENT 2
  removeDocument2(){
    this.setState({ file2 : "",file2FileName: " ",file2progress:0,file2progresstext:''})
  } 
// REMOVE DOCUMENT 3
  removeDocument3(){
    this.setState({ file3 : "",file3FileName: " ",file3progress:0,file3progresstext:''})
  }
  removeImage(){
    this.setState({ imageUrl : "",photoFileName: " "})
  }

// UPLOAD PROFILE IMAGE
  fileUploader(event) {

    // FILE PREVIEW
    const file = this.refs.uploadImg.files[0]
    const reader = new FileReader();

    reader.onloadend = () => {
      this.setState({imageUrl: reader.result})
    }
    if (file) {
        reader.readAsDataURL(file);
        
        this.setState({
            imageUrl: reader.result,
           
        })
        
    }
    else {
        this.setState({imageUrl: " "})
    }

    const target = event.target;
    const name = target.name;
    let value = target.value

    this.state.photoFileHandle = event.target.files[0];
    this.state.photoFileName = SpeakerProfileFunctions.getSpeakerPhotoFileName(this.state.id, this.state.photoFileHandle.name);

    // GET S3 FILE NAME
    const s3File = SpeakerProfileFunctions.getS3SpeakerPhotoFileName(this.state.code,this.state.photoFileName);

    // IMAGE UPLOAD TO S3
    Storage.put(s3File, this.state.photoFileHandle,
      {
          contentType: 'image/png'
      })
      .then(
          result => {
              this.forceUpdate();
          }
      )
      .catch(err => {
          this.state.message = "Unable to upload image. Please try again"
          this.forceUpdate();

      }
      );
  }

// VIDEO UPLOAD
  uploadVideoToS3(event) {
    const target = event.target;
    const name = target.name;
    let value = target.value;

    this.state.videotos3FileHandle = event.target.files[0];
    this.setState({ videos3progress: 25 });
    this.setState({ videos3progresstext: "Uploading..." });

    let date = new Date();
    let longFormat = date / 1000;
    let id = longFormat.toString();
    this.state.videotos3FileName = SpeakerProfileFunctions.getVideoS3FileName(id, this.state.videotos3FileHandle.name);
    this.setState({ videos3progress: 50 });

    const s3File = SpeakerProfileFunctions.getS3VideoFileName(this.state.code,this.state.videotos3FileName);
    this.setState({ videos3progress: 75 });

    console.log(this.state.videotos3FileHandle);
    Storage.put(s3File, this.state.videotos3FileHandle,
      {

          contentType: '/\Avideo/'
      })
      .then(
          result => {
              console.log(result);
              this.setState({ videos3progress: 100 });
              this.setState({ videos3progresstext: "Video uploaded successfully" });
              
              this.setState({ videourl: "https://event360live.s3.amazonaws.com/public/" + this.state.code + "/speakers/" + this.state.videotos3FileName })
              this.forceUpdate();
          }
      )
      .catch(err => {
          this.setState({ videos3progresstext: "Unable to upload Video. Please try again" });
          this.forceUpdate();
      }
      );
  }

  handleBeforeInput = () => {
    this.setState({
      maxlength: this.state.editorState.getCurrentContent().getPlainText().length,
      decError:""
    })

    if(global.$config?.speakerprofile?.speakerbiolimit) {
      var max_length = global.$config.speakerprofile.speakerbiolimit;
  
      if (this.state.editorState.getCurrentContent().getPlainText().length >= max_length) {
          this.setState({decError:` Text should be limited to ${max_length} characters or less.`})
          return 'handled';
      }
    }
  }

  handlePastedText = (pastedText) => {
    

    if(global.$config?.speakerprofile?.speakerbiolimit) {
      var max_length = global.$config.speakerprofile.speakerbiolimit;
  
      if (this.state.editorState.getCurrentContent().getPlainText().length + pastedText.length >= max_length) {
          this.setState({decError:` Text should be limited to ${max_length} characters or less.`})
          return 'handled';
      }
      else {
        this.setState({
          maxlength: this.state.editorState.getCurrentContent().getPlainText().length + pastedText.length,
          decError:""
        })
      }
    }
    else {
      this.setState({
        maxlength: this.state.editorState.getCurrentContent().getPlainText().length + pastedText.length,
        decError:""
      })
    }
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState
    });
  }

  async generateSingedUrl(event, filename) {
    event.preventDefault();

    try {
      let apiName = ServiceName.getUserService();
      let path = ""; //replace this with the path you have configured on your API

      let myInit = {
        body: {
          code: this.state.code,
          command: "getSignedUrl",
          bucket: "wesicore",
          key: `event360/${this.state.code}/upload/documents/${filename}`
        },
        response: true,
        headers: {},
      };
  
      console.log({myInit});
  
      let result = await API.post(apiName, path, myInit);
      console.log(result);

      if(result.data) {
        window.open(result.data)
      }

    } catch (error) {
      console.log(error);
    }
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ isLoading: true });
    this.state.desc = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
    let response = await SpeakerProfileFunctions.submitProfile(this.state)

    if (response.err == null) {
      this.state.message = "Profile successfully submitted";
      global.$companyid = String(this.state.id);
      this.setState({ isLoading: false });
      this.setState({ editorState: EditorState.createEmpty() })
      this.uploadFile1();
      this.uploadFile2();
      this.uploadFile3();
      
      this.setState({ file1progress: 0 });
      this.setState({ file2progress: 0 });
      this.setState({ file3progress: 0 });
      this.setState({ videos3progress: 0 });
      this.state.sortorder = '';
      
      this.forceUpdate();
    }
    else {
        this.state.message = "Error!! Unable to submit profile. Please try again. "
        this.setState({ isLoading: false });
        this.forceUpdate();
    }
    
  }

  handleClear(event) {
    event.preventDefault();
    this.state.message = "";
    this.setState({ disabled: false });
    SpeakerProfileFunctions.clearInputs(this.state);
    this.setState({ editorState: EditorState.createEmpty() });
    this.setState({ file1progress: 0 });
    this.setState({ file2progress: 0 });
    this.setState({ file3progress: 0 });
    this.setState({ videos3progress: 0 });
}

// GET SPEAKER PROFILE
  async getSpeaker() {
    
    let result = await SpeakerProfileFunctions.getSpeaker(this.state.code,this.state.userid);
   
    if (result.err == null && result.data != null && result.data.Items.length != 0) {
      
      let speakerObj = SpeakerProfileFunctions.getSpeakerObject(result.data.Items);
      this.setState({ id: speakerObj.id })
      this.setState({ type: speakerObj.type })
      this.setState({ company: speakerObj.company })
      this.setState({ fullname: speakerObj.fullname })
      this.setState({ photoFileName: speakerObj.photo })
      this.setState({ photoFileHandle: speakerObj.photo })
      this.setState({ title: speakerObj.title })
      this.setState({ selectedStype: speakerObj.stype })
      this.setState({ desc: speakerObj.desc })
      this.setState({ titlep1: speakerObj.titlep1 })
      this.setState({ titlep2: speakerObj.titlep2 })
      this.setState({ titlep3: speakerObj.titlep3 })
      this.setState({ typep1: speakerObj.typep1 })
      this.setState({ typep2: speakerObj.typep2 })
      this.setState({ typep3: speakerObj.typep3 })
      this.setState({ descp1: speakerObj.descp1 })
      this.setState({ descp2: speakerObj.descp2 })
      this.setState({ descp3: speakerObj.descp3 })
      this.setState({ submittedby: speakerObj.submittedby })
      this.setState({ file1FileName: speakerObj.file1 })
      this.setState({ file2FileName: speakerObj.file2 })
      this.setState({ file3FileName: speakerObj.file3 })
      this.setState({ udate: speakerObj.udate })
      this.setState({ speakercategory: speakerObj.scategory })
      this.setState({ email: speakerObj.email })
      this.setState({ phone: speakerObj.phone })
      this.setState({ url: speakerObj.url })
      this.setState({ social1: speakerObj.social1 });
      this.setState({ social2: speakerObj.social2 });
      this.setState({ social3: speakerObj.social3 });
      this.setState({ social4: speakerObj.social4 });
      this.setState({ social5: speakerObj.social5 });
      this.setState({ social6: speakerObj.social6 });
      this.setState({ linkedin: speakerObj.linkedin })
      this.setState({ createdby: speakerObj.createdby })
      this.setState({ sortorder: speakerObj.sortorder })
      this.setState({ videourl: speakerObj.videourl })
      this.setState({ data1: speakerObj.data1 })
      
      this.setState({ dataloaded: true })
      this.setState({ imageUrl: `https://s3.amazonaws.com/event360live/public/${this.state.code}/speakers/${this.state.photoFileName}?${new Date().getTime()}` });

      if (speakerObj.data2?.trim() !== "") {
        const parsedData = JSON.parse(speakerObj.data2);
        if (parsedData?.speakerconsent) {
          this.setState({ docPermission: parsedData.speakerconsent });
        }
      }

      const html = this.state.desc;
      const blocksFromHTML = htmlToDraft(html);
      const contentstate = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks);

      this.setState({ editorState: EditorState.createWithContent(contentstate) });
      this.onEditorStateChange = (editorState) => {
        this.setState({ editorState });
      }
      this.setState({maxlength: this.state.editorState.getCurrentContent().getPlainText().length})
    }
    else {
      this.setState({ dataloaded: true })
    }
  }
  
}
