import React from 'react';
import { API } from 'aws-amplify';
import { Row, Col, Button, FormGroup, Label, Input } from 'reactstrap';
import "./Schedule.css";
import Icon from '@mdi/react';
import { mdiCheck, mdiCodeTags, mdiDatabaseCheck } from '@mdi/js';
import Modal from "react-responsive-modal";
import 'react-responsive-modal/styles.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import UpdatePoints from '../Points/UpdatePoints';

const moment = require('moment-timezone');
var serviceNames = require('../ServiceNameMapper');
var timer;
class SessionCheckIns extends React.Component {
    constructor(props) {
        /*
        var timerintervals = [
        {
            "sid": "1274",
            "begin": "11/11/2020 03:30 AM",
            "end": "11/11/2020 03:35 AM",
            "data": "checkin 1",
            "title": "Checkin 1",
            "tag": "S1C1"
        }
        ]
        */

        var checkinmaindivstyles = '';
        var checkinsubmitbuttonstyles = '';
        var checkintime = '';
        var popupdesc = " ";
        var showcheckintimer = false;
        var checkincodestyle = {};

        if (global.$session.styles.checkin == null) {
            checkinmaindivstyles = global.$session.styles.timer.maindiv;
            checkinsubmitbuttonstyles = global.$session.styles.timer.submitbutton
            checkintime = global.$session.styles.timer.text
        }
        else {
            checkinmaindivstyles = global.$session.styles.checkin.maindiv;
            checkinsubmitbuttonstyles = global.$session.styles.checkin.submitbutton;
            checkintime = global.$session.styles.checkin.text;
        }

        if (global.$session.checkinpopupdesc != null) {
            popupdesc = global.$session.checkinpopupdesc;
        }

        if (global.$session.showcheckintimer != null) {
            showcheckintimer = global.$session.showcheckintimer;
        }

        if (global.$session.styles.checkincode != null) {
            checkincodestyle = global.$session.styles.checkincode;
        }

        super(props);
        this.state = {
            code: global.$code,
            s3: global.$s3,
            bucket: global.$s3bucket,
            timerintervals: props.session.CHECKINS,
            timer: '',
            secondsElapsed: 0,
            hidecheckinbutton: true,
            begin: '',
            end: '',
            data: '',
            sid: '',
            checkinname: 'CheckIn',
            checkinmaindivstyles: checkinmaindivstyles,
            checkinsubmitbuttonstyles: checkinsubmitbuttonstyles,
            checkintime: checkintime,
            checkstatus: 'not checked',
            tag: '',
            checkinname: '',
            dataloaded: false,
            lastcheckin: '',
            checkinvalue: '',
            checkinonce: '',
            overwrite: '',
            group: '',
            sessioncheckinids: [],
            checkinexist: false,
            alreadycheckedin: false,
            checkinbuttontype: 'image',
            openpopup: false,
            message: '',
            enablebuttons: false,
            popupdesc: popupdesc,
            successmsg: '',
            errormsg: '',
            checkcode: '',
            checkincode: '',
            checkindata: '',
            showcheckintimer: showcheckintimer,
            checkincodestyle: checkincodestyle,
            updateActivityPoints: false
        };
        //console.log(this.state);
        this.startTimer();
        this.handleInputChange = this.handleInputChange.bind(this);
        this.submitCheckin = this.submitCheckin.bind(this);
        this.submitCheckinWithCode = this.submitCheckinWithCode.bind(this);
        this.submitCheckinWithoutCode = this.submitCheckinWithoutCode.bind(this);
        this.handleCheckIn = this.handleCheckIn.bind(this);
    }
    componentWillUnmount() {
        //console.log("componentWillUnmount")
        clearInterval(timer)
    }

    onOpenPopup = () => {
        this.setState(prevState => ({
            openpopup: !prevState.openpopup
        }));
    };

    render() {

        return (
            <div style={this.state.checkinmaindivstyles}>
                {/*this.startTimer()*/}
                <div id="checkindiv">
                    <div className="checkin-time" id="checkin_time" style={this.state.checkintime} hidden={!this.state.showcheckintimer}>{this.state.timer}</div>
                    {
                        this.state.checkinbuttontype == "image" ?
                            (
                                <div>
                                    {
                                        this.state.checkcode == "Y" ?
                                            (
                                                <div>
                                                    <div className="checkin-success-msg">{this.state.successmsg}</div>
                                                    <div hidden={this.state.hidecheckinbutton}>
                                                        <div className="checkin-div">
                                                        <FormGroup className="form-labels" className="checkincode-input">
                                                            <Label for="checkincode" className="checkin-input-label" >Enter the code and checkin</Label>
                                                                <div className="checkin-code offset-md-3" style={this.state.checkincodestyle}>{this.state.checkindata.code}</div>
                                                            <Input type="text" name="checkincode" className="form-control-sm" id="checkincode" value={this.state.checkincode || ''} onChange={this.handleInputChange} />
                                                        </FormGroup>
                                                        <div className="checkin-error-msg">{this.state.errormsg}</div>
                                                        <Button className="btn-btn-primary" id="checkin_button" onClick={this.submitCheckinWithCode} style={this.state.checkinsubmitbuttonstyles}><img src={`https://event360live.s3.amazonaws.com/public/${this.state.code}/web/${this.state.checkinname}`} className="checkin-img" /></Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                            :
                                            (
                                                <div>
                                                    <div className="checkin-success-msg">{this.state.successmsg}</div>
                                                    <div className="checkin-div">
                                                        <Button className="btn-btn-primary" id="checkin_button" onClick={this.submitCheckinWithoutCode} style={this.state.checkinsubmitbuttonstyles} hidden={this.state.hidecheckinbutton}><img src={`https://event360live.s3.amazonaws.com/public/${this.state.code}/web/${this.state.checkinname}`} className="checkin-img" /></Button>
                                                    </div>
                                                </div>
                                            )
                                    }
                                </div>
                            )
                            :
                            ('')
                    }
                    {
                        this.state.checkinbuttontype == "text" ?
                            (

                                <Button className="btn-btn-primary" id="checkin_button" onClick={this.submitCheckin} style={this.state.checkinsubmitbuttonstyles} hidden={this.state.hidecheckinbutton}><Icon path={mdiCheck} size={1} color="lightgray" className="form-label-icon" />{this.state.checkinname}</Button>
                            )
                            :
                            ('')
                    }
                </div>
                <Modal
                    open={this.state.openpopup}
                    onClose={this.onOpenPopup}
                    blockScroll={false}
                    className="checkin-session-popup"
                    styles={{
                        modal: {
                            width: "100%"
                        }
                    }}
                    center>
                    <div className="popup-buttons-div">
                        {
                            this.state.enablebuttons == true ?
                                (
                                    <div>
                                        <div dangerouslySetInnerHTML={{ __html: this.state.popupdesc }}></div>
                                        <div className="session-checkin-message">{this.state.message}</div>
                                        <div className="checkin-popup-btn-div">
                                            <Button className="btn popup-confirm-button" onClick={this.handleCheckIn}>Yes</Button>
                                            <Button className="btn popup-confirm-button" onClick={this.onOpenPopup}>No</Button>
                                        </div>
                                    </div>
                                )
                                :
                                (
                                    <div>
                                        <div className="session-registration-message">{this.state.message}</div>
                                    </div>

                                )
                        }
                    </div>
                </Modal>
                {
                    global.$points.sessioncheckin != null && global.$points.sessioncheckin.enable.includes("Y") ?
                        (
                            this.state.updateActivityPoints == true?
                            (
                                <UpdatePoints session={global.$sessionobj} checkinid={this.state.checkindata.tag} component="session-checkin" area="sessioncheckin" points={this.state.checkindata.points} />
                            )
                            :
                            ('')
                        )
                        :
                        ('')
                }
            </div>
        )
    }
    getAdjustedCurrentDate() {
        var mode = global.$mode;
        if (mode == null || mode == '') {
            mode = 'live';
        }

        var currentdate = moment().tz(`${global.$settings.timezone}`).format('L LTS');

        if (mode == "live") {
            //Nothing - Just return the current date
        }
        else if (mode == "preview") {
            // Make current date/time as same as session begin date/time
            currentdate = global.$sessionobj.BEGIN;
        }
        else if (mode == "dryrun") {
            var eventdate = moment(global.$sessionobj.BEGIN).format('L');
            var currenttime = moment().tz(`${global.$settings.timezone}`).format('LTS');
            currentdate = eventdate + " " + currenttime
        }
        else if (mode == "ondemand") {   //Add 365 days to current date

            var postdate = moment().add(365, 'days').calendar();
            var posttime = moment().tz(`${global.$settings.timezone}`).format('LTS');
            currentdate = postdate + " " + posttime;
        }

        return currentdate;
    }

    startTimer() {
        var json = this.state.timerintervals;
        json = JSON.parse(json);
        //console.log(json);

        timer = setInterval(() => {
            json.forEach((item, index, array) => {
                //console.log(item, index, array);
                var beginDate = Date.parse(item.begin);

                var currentdate = moment().tz(`${global.$settings.timezone}`).format('L LTS');
                var presentDate = Date.parse(currentdate);

                var endDate = Date.parse(item.end);
                //console.log(presentDate >= beginDate, presentDate <= endDate);
                var time = moment().tz(`${global.$settings.timezone}`).format('hh:mm:ss A')

                if (presentDate >= beginDate && presentDate <= endDate) {
                    //console.log("true");
                    //console.log(this.state['show' + item.data]);
                    if (this.state['show' + item.data] == null || this.state['show' + item.data] == true)    //condition to hide the checkin buton after checkin
                    {
                        this.setState({
                            begin: item.begin,
                            end: item.end,
                            data: item.data,
                            sid: item.sid,
                            checkinname: item.title,
                            checkinbuttontype: item.buttontype,
                            tag: item.tag,
                            checkinonce: item.checkinonce,
                            group: item.group,
                            hidecheckinbutton: false,   //Show the button
                            checkcode: item.checkcode,
                            checkindata: item,
                            successmsg: '',
                            ['show' + item.data]: true
                        });
                        //console.log(this.state);

                        if (presentDate == endDate) {
                            //console.log("end")
                            this.state.hidecheckinbutton = true;
                        }
                    }

                }
                else {
                    //console.log(this.state.lastcheckin);
                    if (index === array.length - 1) {
                        if (presentDate >= endDate) {
                            if (document.getElementById("checkin_time") != null) {
                                document.getElementById("checkin_time").style.display = "none";
                            }
                        }
                    }
                }
                this.setState({ timer: time })
                //console.log(this.state.timer);
                //console.log(this.state.checkstatus);
                //console.log(this.state.hidecheckinbutton);
            })
        }, 1000 * 1);
    }

    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        let value = target.value

        //////console.log( "Target = " + name + ", Value = " + value);

        this.setState({ [name]: value });
    }

    handleCheckIn(event) {
        event.preventDefault();
        this.postBadgeScan();
    }
    submitCheckin(event) {
        event.preventDefault();
        this.findCheckIn()
    }

    validateCheckinCode() {
        //console.log(this.state.checkindata);
        //console.log(this.state.checkincode, this.state.checkincode == this.state.checkindata.code);
        if (this.state.checkincode == this.state.checkindata.code) {
            return true;
        }
        else {
            return false;
        }
    }

    submitCheckinWithCode(event) {
        event.preventDefault();

        //console.log(this.state.checkindata);
        //console.log(this.state.checkindata.code);
        if (this.validateCheckinCode()) {
            this.findCheckIn()
        }
        else {
            this.setState({ errormsg: this.state.checkindata.errormessage });
        }
    }

    submitCheckinWithoutCode(event) {
        event.preventDefault();
        this.findCheckIn()
    }
    getBadgeScanTableObjects(objs) {
        //console.log(objs);

        const tableObjs = []

        for (var i = 0; i < objs.length; i++) {
            var badgeObj = objs[i]

            //console.log(badgeObj)

            var newObj = Object()
            var tagid = badgeObj.tagid.S

            tableObjs.push(newObj);
        }

        return tableObjs;
    }

    findCheckIn() {
        let apiName = serviceNames.getBadgeScanService();
        let path = ''; //replace this with the path you have configured on your API

        let myInit = {
            body:
            {
                'code': this.state.code,
                'command': 'getCheckIn',
                'userid': global.$userid,
                'tagid': this.state.tag
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //console.log(myInit);

        API.post(apiName, path, myInit).then(response => {
            //console.log(response);

            if (response.err == null && response.data != null) {
                //console.log(response.data);
                if (response.data.result == false) {
                    //console.log("User already Checked in");
                    this.performSessionValidation(response.data);
                }
                else if (response.data.result == true) {
                   //console.log("User not checked in");
                    this.postBadgeScan();

                }
                //var badgescans = this.getBadgeScanTableObjects(response.data.Items)

            }
            else {
                //console.log("getBadgeScanTableObjects - failed!!");
                //console.log(response.err);
            }
        }
        ).catch(error => {
            //console.log(error.response)
        });
    }
    performSessionValidation(result) {
        result.data.map(item => {
            //console.log(item);
            var tag = item.title.S
            var sessionid = tag.substring(
                tag.lastIndexOf("C#ID:") + 5,
                tag.lastIndexOf(";N:"));
            //console.log(sessionid, this.props.session.SID)
            if (sessionid != this.props.session.SID) {
                //console.log("different session with same checkin id");
                this.setState({
                    openpopup: true,
                    enablebuttons: true,
                    message: "Please do all the checkins from one session to get the credits, You have already done the checkin in one of the parallel sessions, do you want to cancel that and continue with this session."
                })
            }
            else {
                //console.log("Already checkedin for this session");
                this.setState({
                    openpopup: true,
                    enablebuttons: false,
                    message: "You have already checked in this session."
                })
            }
        })
    }
    postBadgeScan() {
        let apiName = serviceNames.getBadgeScanService();
        let path = ''; //replace this with the path you have configured on your API

        var time = moment().tz(global.$settings.timezone).format('L, LTS');
        var btime = moment(this.props.session.BEGIN).format('LTS');
        var etime = moment(this.props.session.END).format('LTS');
        var bdate = moment(this.props.session.BEGIN).format('L');
        var edate = moment(this.props.session.END).format('L');

        if (this.state.group == "Y") {
            if (this.state.checkinonce == "Y") {
                this.state.overwrite = "Y";
            }
            else {
                this.state.overwrite = "N";
            }
        }
        else {
            this.state.overwrite = "N";
        }
        let myInit = {
            body:
            {
                'code': global.$code,
                'command': 'processCheckIn',
                'checkinonce': this.state.checkinonce,
                'overwrite': this.state.overwrite,
                'userid': global.$userid,
                'name': global.$fname + " " +global.$lname,
                'time': time,
                'title': `C#ID:${this.props.session.SID};N:${this.props.session.TITLE};P:${this.props.session.POINTS};BD:${bdate};BT:${btime};ED:${edate};ET:${etime};D:${this.state.data};GROUP:${this.state.group};TAG:${this.state.tag};CHECKINCODE:${this.state.checkincode}`,
                'phone': global.$phone,
                'email': global.$email,
                'company': global.$company,
                'points': '0',
                'regid': global.$regid,
                'scannedby': global.$userid,
                'tagid': this.state.tag
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //console.log(myInit);

        API.post(apiName, path, myInit).then(response => {
            //console.log(response);
            //var json = this.state.timerintervals;
            //json = JSON.parse(json); 
           //console.log('show' + this.state.data)
            this.setState({
                openpopup: false,
                hidecheckinbutton: true,
                lastcheckin: this.state.data,
                ['show' + this.state.data]: false,
                successmsg: this.state.checkindata.successmessage,
                errormsg: '',
                checkincode: '',
                updateActivityPoints: true
            });
            //console.log(this.state);
        }
        ).catch(error => {
            //console.log(error)
        });
    }
}
export default SessionCheckIns;


