import React from 'react';
import SponsorLayoutInfo from './SponsorLayoutInfo';
import Carousel from 'react-elastic-carousel'; //https://sag1v.github.io/react-elastic-carousel

class SponsorsSlider extends React.Component {
    constructor(props) {

        super(props);
        this.state = {
            slider: props.sponsors,
        };
    }

    render() {
        const slides = this.props.sponsors.map((item) => {
            //console.log(item)
            if(item.TYPE !="T")
            {
                return (
                    <SponsorLayoutInfo sponsor={item} />
                );
            }
        });
        const breakPoints = [
            { width: 0, itemsToShow: 1 },
            { width: 550, itemsToShow: 2 },
            { width: 850, itemsToShow: 4 },
            { width: 1024, itemsToShow: 6 }
        ]
        return (
            <div>
                <Carousel
                    itemsToShow={6}
                    breakPoints={breakPoints}
                    onResize={currentBreakPoint => console.log()}
                    enableAutoPlay
                    autoPlaySpeed={5000}
                    pagination={false}
                    disableArrowsOnEnd
                >
                    {slides}
                </Carousel>
            </div>
        )
    }

}
export default SponsorsSlider;
