/* eslint-disable */
import React, { Component } from 'react';
import ConversationsView from './ConversationsView';
import './Conversations.css';

/*
    Delegate COnversations flow

    Conversations
        1.ConversationsView
            2.ConversationsItemView
    3.Popup
    4.ConversationView
    5.ConversationItemView
    6.COnversationInput
*/

class Conversations extends React.Component {
    constructor(props) {

        super(props);

        this.state = {
            code: global.$code,
            dataloaded: false,
        };

    }

    render() {
        return (
            <div>
                <ConversationsView />
            </div>
        )
    }
}

export default Conversations;