/*

    src: https://stripe.com/docs/payments/integration-builder

*/
import React, { useState, useEffect } from "react";
import {
    CardElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import { API } from "aws-amplify";
import RegisterAttendee from "../RegisterAttendee";
import { Link } from "react-router-dom";
import { getPaymentService } from "../../ServiceNameMapper";


export default function StripeCheckoutForm(props) {
    const [succeeded, setSucceeded] = useState(false);      // LIVE
    //const [succeeded, setSucceeded] = useState(true);     // TEST
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [clientSecret, setClientSecret] = useState('');
    const stripe = useStripe();
    const elements = useElements();
    const [totalAmount, setTotalAmount] = useState(0);
    const [fee, setFee] = useState(0);
    const [regAmountBeforeFee, setRegAmountBeforeFee] = useState(0);
    const [doPayment, setDoPayment] = useState(true); //Live: true, Test: false

    //console.log(global.$regattendeeinfo.fname + " " + global.$regattendeeinfo.lname + ' - ' + global.$regattendeeinfo.email);
    //Fetch a PaymentIntent
    useEffect(() => {

        // Create PaymentIntent as soon as the page loads
        var discount = '';
        if (global.$regdiscountinfo != null && global.$regdiscountinfo != "" && global.$regdiscountinfo.discountprice != null) {
            //console.log(global.$regdiscountinfo.discountprice);
            discount = "Discount - " + global.$regdiscountinfo.discountprice;
        }
        var stripetrasactionfee = "0";
        if (global.$registrationform.payment.stripe != null && global.$registrationform.payment.stripe.transactionfee != null) {
            stripetrasactionfee = global.$registrationform.payment.stripe.transactionfee;
            setFee(stripetrasactionfee);
        }
        setRegAmountBeforeFee(global.$regamount);
        var amount = global.$regamount * 100   // LIVE 100 cents = 1 dollar       
        //var amount = 0.5 * 100               // TEST 50 cents  = 0.5 dollar 
        
        var totalamount = Number(global.$regamount) + (Number(global.$regamount) * Number(stripetrasactionfee) / Number(100))
        global.$transactionfee = ((Number(global.$regamount) * Number(stripetrasactionfee)) / Number(100));
       //console.log(global.$regamount);
        global.$regamount = Number(global.$regamount) + (Number(global.$regamount) * Number(stripetrasactionfee) / Number(100));
        global.$regamount = parseFloat(global.$regamount).toFixed(2)
        
       console.log(global.$regamount);
        
       //console.log("Fee = " + global.$transactionfee)
        totalamount = totalamount * 100
       console.log("total amount = " + totalamount);
        setTotalAmount(totalamount);

        var mode = "live";
        if (global.$registrationform.payment.stripe != null && global.$registrationform.payment.stripe.testmode != null) {
            if (global.$registrationform.payment.stripe.testmode == true) {
                mode = 'test';
            }
        }

        var desc = global.$regattendeeinfo.fname + " " + global.$regattendeeinfo.lname + ' - ' + global.$regattendeeinfo.email + ' - ' + global.$attendeeregitems + ',' + global.$guestregitems + ',' + discount

        // Used for Test
        if (doPayment) {

            let apiName = getPaymentService();
            let path = ''; //replace this with the path you have configured on your API
            let myInit = {
                body:
                {
                    'code': global.$code,
                    'command': 'createStripeOrder',
                    //'amount': global.$regamount * 100, 
                    'amount': totalamount,
                    'currency': 'usd',
                    'desc': desc.slice(0,950), //Stripe description max limit 1000
                    'mode': mode
                },
                response: true,
                convertEmptyValues: true,
                headers: {}
            }

            //console.log(myInit);

            API.post(apiName, path, myInit).then(response => {

                //console.log(response);

                if (response.err == null && response.data != null) {
                    //console.log(response.data);
                    setClientSecret(response.data);
                }
                else {
                //console.log(response.err);
                }
            }
            ).catch(error => {
            //console.log(error)
            });
        }
        else {
            console.log('doPayment is false')
        }

    }, []);

    //Style the card input
    const cardStyle = {
        style: {
            base: {
                color: "#32325d",
                fontFamily: 'Arial, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                    color: "#32325d"
                }
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a"
            }
        }
    };

    //Optional: Surface card errors
    const handleChange = async (event) => {
        // Listen for changes in the CardElement
        // and display any errors as the customer types their card details
        setDisabled(event.empty);
        if (doPayment) {
            setError(event.error ? event.error.message : "");
        }
        else if (event.error && event.error.code !== "incomplete_number"){
            setError(event.error ? event.error.message : "");
        }
    };

    const handleSubmit = async ev => {
        ev.preventDefault();
        setSucceeded(false);
        setProcessing(true);

        //console.log(clientSecret);
        if (doPayment) {
            const payload = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: elements.getElement(CardElement)
                }
            });
            
            //console.log(payload);
    
            if (payload.error) {
    
                //console.log('Payment Failed')
                //console.log(payload)
    
                setError(`Payment failed ${payload.error.message}`);
                setProcessing(false);
                setSucceeded(false);
    
            } else {
    
                //console.log('Payment success')
                //console.log(payload)
    
                setError(null);
                setProcessing(false);
                setSucceeded(true);
            }    
        }
        else {
            console.log('doPayment is false')
            //Testing do the payment
            setError(null);
            setProcessing(false);
            setSucceeded(true);
        }
    };

    const hideRegisterButton = () => {
        global.$hideregisterbutton = true;
    }
    //console.log(global.$attendeeregitems);
    //console.log(global.$guestregitems);
    //console.log(global.$regdiscountinfo);
    var totalregitem = '';
    if (global.$guestregitems != null && global.$regguestinfo != "") {
        if (global.$regdiscountinfo != null && global.$regdiscountinfo != "") {
            totalregitem = global.$attendeeregitems.toString() + ',' + global.$guestregitems.toString() + ",Discount - " + global.$regdiscountinfo.discountprice;
        }
        else {
            totalregitem = global.$attendeeregitems.toString() + ',' + global.$guestregitems.toString();
        }
    }
    else {
        totalregitem = global.$attendeeregitems.toString();
    }
    totalregitem = totalregitem.split(",");
   
    //console.log(totalAmount);
    return (
        <div>
            <form id="stripe-payment-form" onSubmit={handleSubmit}>

                {
                    /*
                    totalregitem.map(item => {
                        return (
                            <div className="payment-items">
                                {item}
                            </div>
                        )
                    })
                    */
                }
                <br />
                {
                    global.$code == "travcon2022exhibitor" || global.$code == "travcon2023exhibitor" || global.$code == "travcon2024exhibitor" ?
                        (
                            <div>
                                <p className="payment-amount">Total registration amount = ${parseFloat(regAmountBeforeFee).toFixed(2)}</p>
                                <p className="payment-amount">Transaction fee = ${Number(global.$transactionfee).toFixed(2)}</p>
                                <p className="payment-amount">Total Amount = ${parseFloat(Number(totalAmount) / 100).toFixed(2)}</p>

                                <p className="payment-amount"><b>NOTE: While payment is in progress, do not close this page or retry payment</b></p>

                            </div>
                        )
                        :
                        (
                            <div>
                                <p className="payment-amount">Total Amount ${parseFloat(global.$regamount).toFixed(2)}</p>
                                <p className="payment-amount"><b>NOTE: While payment is in progress, do not close this page or retry payment</b></p>
                            </div>    
                        )
                }
                <CardElement id="stripe-card-element" options={cardStyle} onChange={handleChange} />
                <button
                    disabled={processing || disabled || succeeded}
                    id="stripe_submit"
                >
                    <span id="button-text">
                        {processing ? (
                            <div className="stripe-spinner" id="stripe_spinner"></div>
                        ) : (
                            "Pay"
                        )}
                    </span>
                </button>
                {/* Show any error that happens when processing the payment */}
                {error && (
                    <div className="card-error" role="alert">
                        <p className="payment-success">Payment failed. Please check the information entered.</p>
                    </div>
                )}
                {/* Show a success message upon completion */}
                {
                    succeeded == true ?
                        (
                            <p className={succeeded ? "result-message" : "result-message hidden"}>
                                {
                                    //console.log(succeeded)
                                }
                                <RegisterAttendee regattendeestatus={global.$regattendeestatus} reggueststatus={global.$reggueststatus} type={global.$regusertype} hideRegisterButton={props.hideRegisterButton}/>
                                {/* {
                                    global.$loginstatus.includes("true") ?
                                        (
                                            ''
                                        )
                                        :
                                        (
                                            global.$deferredpromo == "Y" ?
                                                ('')
                                                :
                                                (
                                                    <p className="payment-login"><Link to={`/LoginForm`}>Click here to login</Link></p>
                                                )
                                        )
                                } */}
                            </p>
                        )
                        :
                        ('')
                }
            </form>
        </div>
    );

}



