import React from 'react';
import SessionWithTabs2 from './SessionWithTabs2';
import { API } from 'aws-amplify';

const moment = require('moment-timezone');
class SessionComponents2 extends React.Component {
    constructor(props) {
        super(props);
       //console.log(props);

        this.state = {
            code: global.$code,
            showfeedback: ''
        };
       //console.log(props);
    }

    render() {
       //console.log("session component: render");
       //console.log(this.props);
        return (
            <div>
                {this.displaySessionDetails()}
            </div>
        )
    }
    getSession() {
       //console.log("get sessions");
       //console.log(this.props.sid);
        //console.log(global.$schedulejson);

        var sessionObj = global.$schedulejson.find(globalSession => (globalSession.SID === this.props.sid) && (globalSession.STAG === this.props.stag))

        //////console.log(sessionObj);
        global.$sessionobj = sessionObj;
        var array = [];
       //console.log(sessionObj);
        
        if (sessionObj.T4 !== " " && sessionObj.T4 !== "") {
            array = sessionObj.T4;
            array = array.split(";");
            array = array[0];
            this.getResponses(array)
        }
        /*var d1 = new Date(sessionObj.BEGIN);
        var d2 = new Date(sessionObj.END);

        var beginDateFormatter = moment(d1).format('MMM D, h:mm A');
        var endDateFormatter = moment(d2).format('h:mm A');

        this.setState({
            sessiontitle: sessionObj.TITLE,
            begindate: beginDateFormatter,
            enddate: endDateFormatter,
            dataloaded: true
        });*/
    }
    getResponseTableObjects(objs) {
        //console.log(objs)
        var tableObjs = []
        for (var i = 0; i < objs.length; i++) {

            var responseObj = objs[i]
            //console.log(objs)
            if (this.state.sessionid == responseObj.sessionid.S) {
                //console.log("true1")
                //console.log(responseObj.userid.S);
                //console.log(global.$loginid);
                global.$showfeedback = "Y";
                if (global.$loginid == responseObj.userid.S) {
                    //console.log("true2");
                    this.setState({ showfeedback: "N" });
                    //this.state.showfeedback = "N";
                    //global.$showfeedback = "N";
                }
            }
            //console.log(this.state.showfeedback);
        }
        //console.log(this.state.showfeedback);
        return tableObjs

    }
    getResponses(item) {
        let apiName = 'PollingService';
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'code': this.state.code,
                'command': 'getPollResponses',
                'sessionid': this.props.sid,
                'questionid': item,
                'responsetype': "SessionFeedback"
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //console.log(myInit);

        API.post(apiName, path, myInit).then(response => {
            //console.log(response);

            if (response.err == null && response.data != null) {
                //console.log(response.data.Items);
                var responses = this.getResponseTableObjects(response.data.Items)
            }
            else {
                //console.log("getReportsTableObjects - failed!!");
                //console.log(response.err);
            }

        }
        ).catch(error => {
            //console.log(error)
        });
    }

    displaySessionDetails() {

        var currentdate = moment().tz(`${global.$settings.timezone}`).format('L LTS') // L = 9/29/2020  LTS = 10:37:01 PM 
        var sessionstarttime = Date.parse(global.$sessionobj.BEGIN);
        var sessionendtime = Date.parse(global.$sessionobj.END);

        //console.log(`SessionComponents: Timer: current = ${currentdate}, start = ${this.props.session.BEGIN}, end = ${this.props.session.BEGIN}` );

        var mode = global.$mode;
        if (mode == null || mode == '') {
            mode = 'live';
        }

        if (mode == "live") {
            //Nothing - Just retunr the current date
        }
        else if (mode == "preview") {
            // Make current date/time as same as session begin date/time
            currentdate = global.$sessionobj.BEGIN;
        }
        else if (mode == "dryrun") {
            var eventdate = moment(sessionstarttime).format('L');
            var currenttime = moment().tz(`${global.$settings.timezone}`).format('LTS');

            currentdate = eventdate + " " + currenttime
        }
        else if (mode == "ondemand") {   //Add 365 days to current date
            var postdate = moment().add(365, 'days').calendar();
            var posttime = moment().tz(`${global.$settings.timezone}`).format('LTS');
            currentdate = postdate + " " + posttime;
        }
        //console.log(currentdate);

        var curenttime = Date.parse(currentdate);

        var d1 = new Date(global.$sessionobj.BEGIN);
        var d2 = new Date(global.$sessionobj.END);

        var beginDateFormatter = moment(d1).format('MMM Do, h:mm A');
        var endDateFormatter = moment(d2).format('h:mm A');

        var sessionDateForCertificate = moment(d2).format('MMM Do, YYYY');
        //console.log(sessionDateForCertificate);

        //console.log(this.props.showfeedback);
        //console.log(this.props.session.QA.toString());
       //console.log(this.props);
        return (
            <div>
                <SessionWithTabs2
                    session={this.props.session}
                    showfeedback={this.props.showfeedback}
                    begindate={beginDateFormatter}
                    enddate={endDateFormatter}
                    curenttime={curenttime}
                    sessionstarttime={sessionstarttime}
                    sessionendtime={sessionendtime}
                    sessiondate={sessionDateForCertificate}
                    activetab = {this.props.activetab}
                    type = {this.props.type}
                />
            </div>
        )
    }

}
export default SessionComponents2;


