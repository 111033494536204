/*

    1. After register first time login - ask them to login and confirm password
    2. login - on success
        1. hide the first section in reg options
        2. auto fill the loggedin attendee details in attendee information
        3. disable the email
        4. skip the first section validation
*/

import React from 'react';
import { Row, Button, FormGroup, Label, Input, Col } from 'reactstrap';
import { Spinner } from 'react-bootstrap';
import Amplify, { Auth, API } from 'aws-amplify';
// import RegOptions from './RegOptions';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import ReactHtmlParser from 'react-html-parser';
import Icon from '@mdi/react';
import { mdiFilePdf, mdiFileDownload } from '@mdi/js';
import UserFunctions from "../Common/UserFunctions";
import APIFunctions from '../Common/APIFunctions';
import LoadingUI from './RegCommon/LoadingUI';
import EventcodeFunctions from './RegCommon/EventcodeFunctions';
var moment = require('moment-timezone');
var serviceNames = require('../ServiceNameMapper');

/*
    MyInfo is used for update scenario
*/

class MyInfo extends React.Component {
    constructor(props) {
        super(props);

        var attendeesummarydiv = {};
        var guestsummarydiv = {};
        var attendeesummarylabel = {};
        var attendeesummaryvalue = {};
        var guestsummarylabel = {};
        var guestsummaryvalue = {};
        if (global.$registrationform.styles.myinfo.attendeesummarydiv != null) {
            attendeesummarydiv = global.$registrationform.styles.myinfo.attendeesummarydiv
        }

        if (global.$registrationform.styles.myinfo.guestsummarydiv != null) {
            guestsummarydiv = global.$registrationform.styles.myinfo.attendeesummarylabel;
        }

        if (global.$registrationform.styles.myinfo.attendeesummarylabel != null) {
            attendeesummarylabel = global.$registrationform.styles.myinfo.attendeesummarylabel;
        }
        if (global.$registrationform.styles.myinfo.attendeesummaryvalue != null) {
            attendeesummaryvalue = global.$registrationform.styles.myinfo.attendeesummaryvalue;
        }
        if (global.$registrationform.styles.myinfo.guestsummarylabel != null) {
            guestsummarylabel = global.$registrationform.styles.myinfo.guestsummarylabel;
        }
        if (global.$registrationform.styles.myinfo.guestsummaryvalue != null) {
            guestsummaryvalue = global.$registrationform.styles.myinfo.guestsummaryvalue;
        }

        this.state = {
            code: global.$code,
            s3: global.$s3,
            bucket: global.$s3bucket,
            userid: global.$userid,
            password: global.$password,
            message: '',
            isLoading: false,
            purchases: [],
            dataloaded: false,
            attendeesummarydivstyles: attendeesummarydiv,
            attendeesummarylabel: attendeesummarylabel,
            attendeesummaryvalue: attendeesummaryvalue,
            guestsummarydivstyles: guestsummarydiv,
            guestsummarylabel: guestsummarylabel,
            guestsummaryvalue: guestsummaryvalue,
            showtable: false
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleDownload = this.handleDownload.bind(this);
        this.onPaymentAmountChangedFromMyInfo = this.onPaymentAmountChangedFromMyInfo.bind(this);
        //this.handleSubmit()
    }

    handleDownload(e) {
        e.preventDefault();
        this.downloadInvoice();
    }
    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        let value = target.value
        ////console.log( "Target = " + name + ", Value = " + value);
        this.setState({ [name]: value });
    }

    dateFormatter = (cell) => {
        //console.log(cell);
        var date = moment(cell * 1000).format("MM/DD/YYYY, hh:mm:ss A");
        return date;
    }

    itemFormatter = (cell) => {
        //console.log(cell);
        cell = cell.replace(/,/g, "<br>")
        return cell;
    }
    validateInputs() {
        const { userid, password } = this.state;
        //console.log(userid, password);
        if (userid === '') {
            this.setState({ message: 'Enter your registered email ID.' });
            return false;
        }
        if (password === '') {
            this.setState({ message: 'Enter your password.' });
            return false;
        }
        else {
            return true;
        }
    }

    render() {
        var logininfo = global.$registrationform.login
        //console.log("myinfo render");
        if (this.state.dataloaded) {
            return (
                <div>
                    <div className="my-info-div" style={global.$registrationform.styles.myinfo.maindiv}>
                        {this.renderReceiptButton(logininfo)}
                        {this.renderDesc(logininfo)}
                        {this.renderData()}
                    </div>
                </div>
            );
        }
        else {
            this.handleSubmit();
            return (
                <LoadingUI />
            )
        }
    }

    renderReceiptButton(logininfo) {
        return (
            <div className="myinfo-section-header">
                <h4 className="reg-option-section" style={global.$registrationform.styles.myinfo.header}>{logininfo.header}</h4>
                {
                    global.$registrationform.login.downloadreceipt != null && global.$registrationform.login.downloadreceipt == true ?
                        (
                            <div className="reg-payment-invoice" onClick={this.handleDownload} title="Download Receipt"><Icon path={mdiFileDownload} size={1.2} className="invoice-pdf-icon"></Icon></div>
                        )
                        :
                        ('')
                }
            </div>
        )
    }
    renderDesc(logininfo) {
        return (
            <div dangerouslySetInnerHTML={{ __html: logininfo.description }}></div>
        )
    }
    renderData() {
        if (this.state.showtable == true) {
            return (
                this.displaySummary(global.$loggedinuser, global.$loggedinguest)
            )
        }
    }
    onPaymentAmountChangedFromMyInfo() {
        this.props.onPaymentAmountChanged();
    }

    handleSubmit() {
        this.state.userid = this.state.userid.toLowerCase();
        global.$regloginstatus = true;

        this.getAttendeeInfo(this.state.userid, "attendee");
        this.getExhibitor(this.state.userid);
    }

    //NOTE: Move to common
    async getAttendeeInfo(userid, user) {

        var userObj = await UserFunctions.getAttendeeProfile(userid);
        if (userObj != null) {
            //console.log(userObj);
            var guestloaded = false;
            if (user == "attendee") {
                global.$loggedinuser = userObj;
                global.$regemail = userObj.userid;
                if (userObj.guests != "" && userObj.guests != " " && !EventcodeFunctions.isTravconAttendee(global.$code)) {
                    guestloaded = true;
                }
            }
            else if (user == "guest") {
                global.$loggedinguest = userObj;
                global.$regguestemail = userObj.userid;
                this.setState({ showtable: true })
            }
            //console.log(userObj.guests, guestloaded);
            if (userObj.guests != " " && guestloaded == true) {
                this.getAttendeeInfo(userObj.guests, "guest")
            }
            else {
                this.setState({
                    // message: "Successfully loggedin, find you registration details below",
                    isLoading: false,
                    showtable: true,
                    dataloaded: true
                });
            }
        }
        else {
            this.setState({
                isLoading: false,
                dataloaded: false
            });
        }
    }

    async getExhibitor(userid) {

        var exhibitorObj = await APIFunctions.getLoggedinExhibitor(userid);
       //console.log(exhibitorObj)
        if (exhibitorObj != null) {
            global.$loggedinexhibitor = exhibitorObj;
        }
        else {
            //////console.log(response.err);
        }
    }
    displaySummary(attendee, guest) {
       
    // remove space issue
        var regtype = [];
        if (attendee.regitemsjson && attendee.regitemsjson !="" && attendee.regitemsjson !=" ") {
            const regtypes = JSON.parse(attendee.regitemsjson)
            regtypes.map((regitem)=>{
                if (regitem.status == "active") {
                    if (regitem.subitems) {  
                        regitem.subitems.map((subitem) => {
                            regtype.push(`${regitem.name} - ${subitem.name}  -  ${subitem.qty} - $${Number(subitem.price * regitem.qty).toFixed(2)}`)
                        })
                    }
                    else {
                        regtype.push(`${regitem.name} - ${regitem.qty } - $${Number(regitem.price * regitem.qty).toFixed(2)}`)
                    }
                }         
            })
        }
        
        
        if (attendee != null) {
            regtype = regtype.toString()
            regtype = regtype.replace(/,/g, "<br>")
        }

        if (attendee != null && guest == null) {
            return (
                this.renderAttendeeSummary(attendee, regtype)
            )
        }
        else if (attendee != null && guest != null) {
            return (
                <div>
                    <Row>
                        <Col md={6}>
                            {this.renderAttendeeSummary(attendee, regtype)}
                        </Col>
                        <Col md={6}>
                            {this.renderGuestSummary(guest, regtype)}
                        </Col>
                    </Row>
                </div>
            )
        }
    }
    renderAttendeeSummary(attendee, regtype) {
        return (
            <div className="attendee-summary-div" style={this.state.attendeesummarydivstyles}>
                <Row>
                    <Col md={3}>
                        <div className="attendee-summary attendee-summary-label" style={this.state.attendeesummarylabel}>Name: </div>
                    </Col>
                    <Col md={3}>
                        <div className="attendee-summary attendee-summary-value" style={this.state.attendeesummaryvalue}>{attendee.fname + " " + attendee.lname}</div>
                    </Col>
                </Row>

                <Row>
                    <Col md={3}>
                        <div className="attendee-summary attendee-summary-label" style={this.state.attendeesummarylabel}>Email: </div>
                    </Col>
                    <Col md={3}>
                        <div className="attendee-summary attendee-summary-value" style={this.state.attendeesummaryvalue}>{attendee.userid}</div>
                    </Col>
                </Row>

                <Row>
                    <Col md={3}>
                        <div className="attendee-summary attendee-summary-label" style={this.state.attendeesummarylabel}>Phone: </div>
                    </Col>
                    <Col md={3}>
                        <div className="attendee-summary attendee-summary-value" style={this.state.attendeesummaryvalue}>{attendee.phone}</div>
                    </Col>
                </Row>
                {
                    global.$code == "travcon2021" || global.$code == "travcon2022" || global.$code == "travcon2023"|| global.$code == "travcon2024" ?
                        (
                            ''
                        )
                        :
                        (
                            <Row>
                                <Col md={3}>
                                    <div className="attendee-summary attendee-summary-label" style={this.state.attendeesummarylabel}>Company: </div>
                                </Col>
                                <Col md={6}>
                                    <div className="attendee-summary attendee-summary-value" style={this.state.attendeesummaryvalue}>{attendee.company}</div>
                                </Col>
                            </Row>
                        )
                }
                <Row>
                    <Col md={3}>
                        <div className="attendee-summary attendee-summary-label" style={this.state.attendeesummarylabel}>Registration Type: </div>
                    </Col>
                    <Col md={3}>
                        <div className="attendee-summary attendee-summary-value" style={this.state.attendeesummaryvalue}>{attendee.userstype}</div>
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                        <div className="attendee-summary attendee-summary-label" style={this.state.attendeesummarylabel}>Registration Status: </div>
                    </Col>
                    <Col md={3}>
                        <div className="attendee-summary attendee-summary-value" style={this.state.attendeesummaryvalue}>{attendee.regstatus}</div>
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                        <div className="attendee-summary attendee-summary-label" style={this.state.attendeesummarylabel}>Items Purchased: </div>
                    </Col>
                    <Col md={9}>
                        <div className="attendee-summary attendee-summary-value" style={this.state.attendeesummaryvalue}>{ReactHtmlParser(regtype)}</div>
                    </Col>
                </Row>
                {
                    global.$code == "travcon2022exhibitor" || global.$code == "travcon2023exhibitor" ||global.$code == "travcon2024exhibitor" || global.$code == "metro2022meeting" || global.$code == "metro2022conference" || global.$code == "metro2023convention" ?
                        (
                            <div>
                                <Row>
                                    <Col md={3}>
                                        <div className="attendee-summary attendee-summary-label" style={this.state.attendeesummarylabel}>Amount Paid: </div>
                                    </Col>
                                    <Col md={9}>
                                        <div className="attendee-summary attendee-summary-value" style={this.state.attendeesummaryvalue}>${Number(attendee.amountpaid).toFixed(2)}</div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={3}>
                                        <div className="attendee-summary attendee-summary-label attendee-summary-pending-amount-label" style={this.state.attendeesummarylabel}>Amount Pending: </div>
                                    </Col>
                                    <Col md={9}>
                                        <div className="attendee-summary attendee-summary-value attendee-summary-pending-amount" style={this.state.attendeesummaryvalue}>${Number(attendee.pendingamount).toFixed(2)}</div>
                                    </Col>
                                </Row>
                            </div>
                        )
                        :
                        (
                            ''
                        )
                }
            </div>
        )
    }
    renderGuestSummary(guest, regtype) {
        return (
            <div className="guest-summary-div" style={this.state.guestsummarydivstyles}>
                <Row>
                    <Col md={6}>
                        <div className="attendee-summary attendee-summary-label" style={this.state.guestsummarylabel}>Name: </div>
                    </Col>
                    <Col md={6}>
                        <div className="attendee-summary attendee-summary-value" style={this.state.guestsummaryvalue}>{guest.fname + " " + guest.lname}</div>
                    </Col>
                </Row>

                <Row>
                    <Col md={6}>
                        <div className="attendee-summary attendee-summary-label" style={this.state.guestsummarylabel}>Email: </div>
                    </Col>
                    <Col md={6}>
                        <div className="attendee-summary attendee-summary-value" style={this.state.guestsummaryvalue}>{guest.userid}</div>
                    </Col>
                </Row>

                <Row>
                    <Col md={6}>
                        <div className="attendee-summary attendee-summary-label" style={this.state.guestsummarylabel}>Phone: </div>
                    </Col>
                    <Col md={6}>
                        <div className="attendee-summary attendee-summary-value" style={this.state.guestsummaryvalue}>{guest.phone}</div>
                    </Col>
                </Row>
                {
                    global.$code == "travcon2021" ?
                        (
                            ''
                        )
                        :
                        (
                            <Row>
                                <Col md={6}>
                                    <div className="attendee-summary attendee-summary-label" style={this.state.guestsummarylabel}>Company: </div>
                                </Col>
                                <Col md={6}>
                                    <div className="attendee-summary attendee-summary-value" style={this.state.guestsummaryvalue}>{guest.company}</div>
                                </Col>
                            </Row>
                        )
                }
                <Row>
                    <Col md={6}>
                        <div className="attendee-summary attendee-summary-label" style={this.state.guestsummarylabel}>Registration Type: </div>
                    </Col>
                    <Col md={6}>
                        <div className="attendee-summary attendee-summary-value" style={this.state.guestsummaryvalue}>{guest.userstype}</div>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <div className="attendee-summary attendee-summary-label" style={this.state.guestsummarylabel}>Registration Status: </div>
                    </Col>
                    <Col md={6}>
                        <div className="attendee-summary attendee-summary-value" style={this.state.guestsummaryvalue}>{guest.regstatus}</div>
                    </Col>
                </Row>
            </div>

        )
    }
    downloadInvoice() {

        console.log('downloadInvoice')
        var user = global.$loggedinuser;
        //console.log(user);
        var date = new Date();
        var timestamp = date / 1000;

        let apiName = serviceNames.getRegistrationService();
        let path = ''; //replace this with the path you have configured on your API

        let myInit = {
            body:
            {
                'command': "generatePaymentReceiptForDownload",
                'code': this.state.code,
                'bucket': "event360live",
                'fname': user.fname,
                'lname': user.lname,
                'userid': user.userid,
                'amountpaid': parseFloat(user.amountpaid).toFixed(2),
                'pendingamount': parseFloat(user.pendingamount).toFixed(2),
                'totalamount': parseFloat(user.totalamount).toFixed(2),
                'promoamount': parseFloat(user.promoamount).toFixed(2),
                'refundamount': parseFloat(user.refundamount).toFixed(2),
                'city': user.city,
                'state': user.state,
                'street': user.street,
                'zip': user.zip,
                'country': user.country,
                'company': user.company,
                'timestamp': timestamp
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        console.log(myInit);

        API.post(apiName, path, myInit).then(response => {
            //console.log(response);
            //console.log("response.data ===> " + response.data + "response.err ===> " + response.err);
            this.setState({ isLoading: false })
            if (response.data.result.result == true) {
                var filepath = `https://event360live.s3.amazonaws.com/${response.data.result.key}`;

                setTimeout(() => {
                    window.open(filepath);
                }, 8000)

                setTimeout(() => {
                    this.deleteFileFromS3(response.data.result.key);
                }, 60000)

            }
            else {
                window.alert("Unable to download receipt");
            }
        }
        ).catch(error => {
            //console.log(error)
        });
    }

    //NOTE: is this required
    deleteFileFromS3(filepath) {
        //console.log("deleteGeneretedFileFromS3");
        // let apiName = 'DataService';
        let apiName = serviceNames.getDataService();
        let path = ''; //replace this with the path you have configured on your API

        let myInit = {
            body:
            {
                'command': "deleteGeneratedFilesFromS3",
                'code': this.state.code,
                'filepath': filepath
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //console.log(myInit);

        API.post(apiName, path, myInit).then(response => {
            //console.log("response.data ===> " + response.data + "response.err ===> " + response.err);
            this.setState({ isLoading: false })
            //console.log(response);
            if (response.data == null) {
            }
            else {
                //console.log(response.err);
            }
        }
        ).catch(error => {
            //console.log(error)
        });
    }

}

export default MyInfo;


