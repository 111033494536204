import React from 'react';
import { Row, Col, CarouselItem, CarouselIndicators, CarouselControl, Carousel } from 'reactstrap';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Avatar from 'react-avatar';
import ReactHtmlParser from 'react-html-parser';
import Icon from '@mdi/react';
import { mdiPhone, mdiEmail, mdiWeb } from '@mdi/js';
import VirtualExhibitorInfo from '../../../VirtualExpo/VirtualExhibitorInfo';
import ReactPlayer from 'react-player';
import UpdatePoints from '../../../Points/UpdatePoints';
import SponsorContactForm from '../../../Sponsors/SponsorContactForm';

class SponsorPopup extends React.Component {
    constructor(props) {

        super(props);
        this.state = {
            playing: false
        };
    }


    render() {

        return (
            <div>
                {this.displaysponsorInfo(this.props.sponsor)}
            </div>
        )
    }

    displaysponsorInfo(sponsor) {
       //console.log(sponsor);
        var videourl = "";
        if (sponsor.VIDEO1 == "" || sponsor.VIDEO1 == " ") {
            videourl = "empty";
        }
        else {
            videourl = sponsor.VIDEO1;
        }
        var image = "";
        if (sponsor.PHOTO == "profile.png" || sponsor.PHOTO == " " || sponsor.PHOTO == "") {
            image = "empty";
        }
        else {
            image = `https://s3.amazonaws.com/event360live/public/${global.$code}/sponsors/` + sponsor.LOGO
        }
        var styles = global.$config['sponsorsscroll'].popup.styles;
        return (
            <div className="sponsor-popup-div" style={styles.maindiv}>

                <Tabs className="tabs-menu" defaultActiveKey="info" transition={false} id="tabs" >
                    <Tab eventKey="info" title="Info" className="tab-item">
                        <div className="virtual-info-div" width="100%" height="calc(80vh - 100px)" style={this.state.popuptabstyle}>
                            <VirtualExhibitorInfo exhibitor={sponsor} />
                            {
                                global.$points.sponsorvisit != null && global.$points.sponsorvisit.enable.includes("Y") ?
                                    (
                                        <UpdatePoints sponsor={sponsor} component="sponsor" area="sponsorvisit" points={global.$points.sponsorisit.points} />
                                    )
                                    :
                                    ('')
                            }
                        </div>
                    </Tab>
                    {
                        videourl.includes("empty") ?
                            ('')
                            :
                            (
                                sponsor.DATA1.includes("video") ?
                                    (
                                        <Tab eventKey="video" title="Video" className="tab-item">
                                            <div className="virtual-video-div">
                                                <ReactPlayer
                                                    url={videourl}
                                                    width="100%"
                                                    height="450px"
                                                    controls={true}
                                                    className="virtual-video-player"
                                                    playing={this.state.playing}
                                                    onContextMenu={e => e.preventDefault()}
                                                    config={{
                                                        file: {
                                                            attributes: {
                                                                controlsList: 'nodownload',
                                                                disablepictureinpicture: 'true',
                                                            }
                                                        }
                                                    }}
                                                    onPlay={() => { this.setState({ playing: true }) }}
                                                />
                                                {console.log(this.state.playing)}
                                                {
                                                    this.state.playing ?
                                                        (
                                                            <div>
                                                                {
                                                                    global.$points.sponsorvideo != null && global.$points.sponsorvideo.enable.includes("Y") ?
                                                                        (
                                                                            <UpdatePoints sponsor={sponsor} component="sponsor" area="sponsorvideo" points={global.$points.sponsorvideo.points} />
                                                                        )
                                                                        :
                                                                        ('')
                                                                }
                                                            </div>
                                                        )
                                                        :
                                                        ('')
                                                }
                                            </div>
                                        </Tab>
                                    )
                                    :
                                    ('')
                            )
                    }
                    {
                        sponsor.DATA1.includes("contact") ?
                            (
                                <Tab eventKey="contact" title="Contact" className="tab-item">
                                    <div className="virtual-contact-div" style={this.state.popuptabstyle}>
                                        <SponsorContactForm email={sponsor.EMAIL} exhibitor={sponsor} />
                                    </div>
                                </Tab>
                            )
                            :
                            (
                                ''
                            )
                    }
                </Tabs>
                {/*<Row>
                    <Col md={3}>
                        <div className="sponsor-popup-img-div" style={styles.imgdiv}>
                            {
                                image.includes("empty") ?
                                    (
                                        ''
                                    )
                                    :
                                    (
                                        <div>
                                            <img src={image} className="sponsor-layout-img" style={styles.img} />
                                            {this.displaySponsorContactIcons(sponsor)}
                                        </div>
                                    )
                            }
                        </div>
                    </Col>
                    <Col md={9}>
                        <div className="sponsor-popup-div2" style={styles.infodiv}>
                            <p className="sponsor-popup-name" style={styles.company}>{sponsor.NAME}</p>
                            <p className="sponsor-popup-desc" style={styles.desc}>{ReactHtmlParser(sponsor.DESCRIPTION)}</p>
                        </div>
                    </Col>
                </Row>
                        </div>*/}
            </div>
        )
    }

    displaySponsorContactIcons(sponsor) {
       //console.log(sponsor);
        return (
            <div className="sponsor-contact-icons">
                {
                    sponsor.URL != null && sponsor.URL != "" && sponsor.URL != " " ?
                        (
                            <a href={sponsor.URL} target="_blank"><Icon path={mdiWeb} size={1.7} color="#828783" className="sponsor-contact-icon" /></a>
                        )
                        :
                        ('')
                }
                {
                    sponsor.EMAIL != null && sponsor.EMAIL != "" && sponsor.EMAIL != " " ?
                        (
                            <a href={"mailto:" + sponsor.EMAIL}><Icon path={mdiEmail} size={1.7} color="#828783" className="sponsor-contact-icon" /></a>
                        )
                        :
                        ('')
                }
                {
                    sponsor.PHONE != null && sponsor.PHONE != "" && sponsor.PHONE != " " ?
                        (
                            <a href={"tel:" + sponsor.PHONE}><Icon path={mdiPhone} size={1.7} color="#828783" className="sponsor-contact-icon" /></a>
                        )
                        :
                        ('')
                }
            </div>
        )
    }
}
export default SponsorPopup;
