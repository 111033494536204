/* eslint-disable */

import React from 'react';
import { Row, Col } from 'reactstrap';
import AuctionItems from './AuctionItems.jsx';
import './Auctions.css';
import { Analytics } from 'aws-amplify';

/*
    Auctions Component Hierarchy

    Auctions
        AuctionItems
            AuctionItem
                ItemInfo - Has Item image, description and Bids on the right
                    ItemBids
                        AddBid 
                        ListBids

*/

class Auctions extends React.Component {
    constructor(props) {

        super(props);
        this.state = {
            code: props.code
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        //console.log('auctions - render');
    Analytics.record({ name: 'PageView', attributes: { name: 'eventsites-' + this.state.code + '-auctions' } });
        return (
            <div>
                <img className="banner" src={global.$auctions.banner} />
                <div >
                    <div dangerouslySetInnerHTML={{ __html: global.$auctions.header }}></div>
                </div>
                <AuctionItems code={this.state.code} />
            </div>
        )
    }
}
export default Auctions;
