import React from 'react';
import { Row, Col, Input } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';
import CommonFunctions from '../RegCommon/CommonFunctions';
import RegItemRadio from './RegitemRadio';
import RegItemCheckbox from './RegItemCheckbox';
import RegItemNumber from './RegItemNumber';
import RegItemdropdown from './RegItemDropdown';
import TravconFunctions from '../RegCommon/CustomerFunctions/TravconFunctions';
import EventcodeFunctions from '../RegCommon/EventcodeFunctions';
import Icon from '@mdi/react';
import { mdiInformationOutline  } from '@mdi/js';
import RegMoreInfoPopup from './RegMoreInfoPopup';
// import RegItemStrings from '../RegCommon/RegItemStrings';

var moment = require('moment-timezone');

class RegItemContainer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            regitemid: props.itemid,
            regitem: props.regitem,
            groupname: props.groupname,
            regitemstyle: CommonFunctions.getRegItemStyle(),
            moreinfoPopup: false,
            moreInfo:''
        }
        //console.log(this.state);
        this.refreshComponent = this.refreshComponent.bind(this);
        this.showDetailPopup = this.showDetailPopup.bind(this)
    }

    refreshComponent() {
        //console.log("refresh");
        this.props.refreshComponent();
    }

    render() {
        var regitem = this.state.regitem;

        // Validate the regitem date and show/hide the reg item
        var disableregitem = this.validateRegItemDate(regitem);

        return (
            <div key={regitem.data5} hidden={disableregitem}>
                <Row className="reg-item-wrapper">
                    <Col md={6}>
                        {this.renderRegItemTitle(regitem)}
                    </Col>
                    <Col md={3} className="reg-price">
                        {this.renderRegItemPrice(regitem)}
                    </Col>
                    <Col md={3} className="reg-item-input-wrapper">
                        {this.renderRegItemInput(regitem, disableregitem, this.state.groupname)}
                    </Col>
                </Row>

                <RegMoreInfoPopup
                    showPopup={this.state.moreinfoPopup}
                    closePopup={this.showDetailPopup}
                    moreInfo={this.state.moreInfo}
                />
            </div>
        )
        //return null
    }

    validateRegItemDate(item) {
        var result = false;
        //console.log(item);
        if (item.begindate != " " && item.enddate != " ") {
            var currentdate = moment().tz(`${global.$settings.timezone}`).format('L LTS');
            var startdate = moment.tz(item.begindate ,'MM/DD/YYYY,hh:mm A').format('L LTS');
            var enddate = moment.tz(item.enddate ,'MM/DD/YYYY,hh:mm A').format('L LTS');

            if (Date.parse(startdate) >= Date.parse(currentdate)) {
                result = true;
            }
            else if (Date.parse(enddate) <= Date.parse(currentdate)){
                result = true;
            }
            else{
                result = false;
            }
           
        }

        return result;
    }

    validateRegItemCount(item, maxsignups, currentsignups) {
        var itemsoldout = false
        
        // For multi select dropdown items
        if (item.data4 == "dropdownprice" || item.data4 == "multiselect") {
            var result = false;
            global.$regdropdownoptions.map(dropdownitem => {
                if (item.data2.includes(dropdownitem.data5) ) {
                    var maxsignup = dropdownitem.maxsignups;
                    var currentsignup = dropdownitem.currentsignups;
                    
                    if ((Number(maxsignup) - Number(currentsignup)) > Number(0)) {
                        result = true;
                    }
                }
            })
            if (result == true) {
                itemsoldout = false;
            }
            else {
                itemsoldout = true;
            }
        }
        else { // for regular items
        
            if (EventcodeFunctions.isTravconExhibitor2023(global.$code) && item.name.includes("Platinum")) {
                var avalilablecount = TravconFunctions.getAvailableSignupsForPlatinumBooths();
                if (Number(avalilablecount) > Number(0)) {
                    itemsoldout = false;
                } else {
                    itemsoldout = true;
                }
            } else if (EventcodeFunctions.isTravconExhibitor2023(global.$code) && item.name.includes("Event Vendor + Platinum")) {
                var eventvendorcount = TravconFunctions.getAvailableSignupsForEventVendorBooths();
                var evpplatinumcount = TravconFunctions.getAvailableSignupsForPlatinumBooths();
                if (Number(eventvendorcount) <= Number(0) || Number(evpplatinumcount) <= Number(0)) {
                    itemsoldout = true;
                } else {
                    itemsoldout = false;
                }
            } else if (EventcodeFunctions.isTravconExhibitor2023(global.$code) && item.name.startsWith("Event Vendor") && !item.name.includes("Platinum")) {
                var avalilablecountss = TravconFunctions.getAvailableSignupsForEventVendorBooths();
                if (Number(avalilablecountss) > Number(0)) {
                    itemsoldout = false;
                } else {
                    itemsoldout = true;
                }
            } else {
                itemsoldout = this.soldoutFunction(maxsignups, currentsignups);
            }
            
        
            // TRAVCON2024 EXHIBITOR SOLDOUT REGITEMS
            if (EventcodeFunctions.isTravconExhibitor2024(global.$code)) {
                const availableCounts = {
                    "Platinum": TravconFunctions.getBoothCount("Platinum","Industry Supplier + Platinum",'travconplatinummax'),
                    "Industry Supplier + Platinum": TravconFunctions.getIndustrySupplierCount("Platinum","Industry Supplier +","Industry Supplier",'travconindustrysuppliermax'),
                    "Gold": TravconFunctions.getBoothCount("Gold","Industry Supplier + Gold",'travcongoldmax'),
                    "Industry Supplier + Gold": TravconFunctions.getIndustrySupplierCount("Gold","Industry Supplier +","Industry Supplier",'travconindustrysuppliermax'),
                    "Industry Supplier + Booth": TravconFunctions.getIndustrySupplierCount(null,"Industry Supplier","Industry Supplier",'travconindustrysuppliermax'),
                    "Industry Supplier": TravconFunctions.getIndustrySupplierItemCount("Industry Supplier","Industry Supplier +","travconindustrysuppliermax")
                };
                
                for (const key in availableCounts) {
                    if (item.name.startsWith(key)) {
                        if (Number(availableCounts[key]) <= 0) {
                            itemsoldout = true;
                            break;
                        }
                        else {
                            itemsoldout = false;
                        }
                        
                    }
                    else {
                        itemsoldout = this.soldoutFunction(maxsignups,currentsignups)
                    }
                }
                
            }

        }

        return itemsoldout;
    }

    soldoutFunction(maxsignups,currentsignups){
        if (Number(maxsignups) - Number(currentsignups) > Number(0)) {
            return false;
        }
        else {
            return true;
        }
    }


    getRegItemRemainingCount(regitem, maxsignups, currentsignups) {
        var signupsremaining = "";
       
        if (Number(maxsignups) < Number(10000)) {
            var signupscount = Number(maxsignups) - Number(currentsignups);

            // ? Get Platinum booth available count ( TRAVCON2023 EXHIBITOR )
            var avalilablecount = TravconFunctions.getAvailableSignupsForPlatinumBooths();
            var avalilableEVendorCount = TravconFunctions.getAvailableSignupsForEventVendorBooths();

            if (regitem.name.includes("Platinum")) {
                signupsremaining = avalilablecount + " Available";
            }
            else {
                signupsremaining = signupscount + " Available";
            }

            if (regitem.name.startsWith("Event Vendor") && !regitem.name.includes("Platinum")) {
                signupsremaining = avalilableEVendorCount + " Available"
            }
            if (regitem.name.startsWith("Event Vendor") && regitem.name.includes("Platinum")) {
                signupsremaining =  " Available"
            }

            // ? get Platinum and Industry Supplier subitem max count ( TRAVCON2024 EXHIBITOR )
            const availableCounts = {
                "Platinum": TravconFunctions.getBoothCount("Platinum","Industry Supplier + Platinum",'travconplatinummax'),
                "Industry Supplier + Platinum": TravconFunctions.getIndustrySupplierCount("Platinum","Industry Supplier +","Industry Supplier",'travconindustrysuppliermax'),
                "Gold": TravconFunctions.getBoothCount("Gold","Industry Supplier + Gold",'travcongoldmax'),
                "Industry Supplier + Gold": TravconFunctions.getIndustrySupplierCount("Gold","Industry Supplier +","Industry Supplier",'travconindustrysuppliermax'),
                "Industry Supplier + Booth": TravconFunctions.getIndustrySupplierCount(null,"Industry Supplier","Industry Supplier",'travconindustrysuppliermax'),
                "Industry Supplier": TravconFunctions.getIndustrySupplierItemCount("Industry Supplier","Industry Supplier +","travconindustrysuppliermax")
            };
            
            if (
                regitem.name.startsWith("Platinum") ||
                regitem.name.startsWith("Gold") ||
                regitem.name == "Industry Supplier" ||
                regitem.name.startsWith("Industry Supplier + Platinum") ||
                regitem.name.startsWith("Industry Supplier + Gold") ||
                regitem.name.startsWith("Industry Supplier + Booth")
            ) {
                signupsremaining = TravconFunctions.getSignupsRemaining(regitem, availableCounts);
            }

            

        }
        else if (Number(maxsignups) === Number(10001)) {
            signupsremaining =  "Available"
        }
        else {
            signupsremaining = "";
        }
        return signupsremaining;
    }

    /*
        regitem:
            data3 - price
            currency - dollar } rupee 

        retunrs $10 or R10
    */

    getRegItemPrice(item) {
       
        var itemprice = "";
        if (item.amount == 0) {
            itemprice = item.data3;
        }
        else {
            if (item.currency.includes("dollar")) {
                itemprice = "$" + item.amount;
            }
            else if (item.currency.includes("rupee")) {
                itemprice = "&#8377;" + item.amount;
            }
            else {
                itemprice = "$" + item.amount;
            }
        }
        return itemprice;
    }

    /*
        regitem.code - Has json format for new item text 

        {   
            "newlabel": {     
                "required": true,     
                "text": "<p style='font-size: 12px; padding: 4px 5px 3px 4px; marginTop: -10px; margin-left: 6px; margin-bottom: -20px; background-color: #41a0ba; color: white; font-weight: 600; border-radius: 4px'>New</p>" 
                } 
        }
    
    */

    getRegItemJson(item) {
        var itemjson = "";
        if (item.code != "" && item.code != " ") {
            itemjson = item.code;
            itemjson = JSON.parse(itemjson)
        }
        return itemjson;
    }

    showDetailPopup = (item) => {
        this.setState(prevState => ({
            moreinfoPopup: !prevState.moreinfoPopup,
            moreInfo: item ? item : ""
        }));
    }

    renderRegItemTitle(item) {

        // This is to show the regitem new label
        // get the regitem json from code field 
        var itemjson = this.getRegItemJson(item);
        var showDetailIcon = global.$registrationform.setup[this.props.tabid]?.showmoreinfopopup;

        return (
            <>
                <div className="attendee-reg-item"><div className='regitem-text' style={this.state.regitemstyle}>{item.name}</div>
                    {
                        item.code != "" && item.code != " " && itemjson.newlabel.required == true ?
                            (
                                <>
                                    <div className='newlable-div' dangerouslySetInnerHTML={{ __html: itemjson.newlabel.text }}></div>
                                    
                                </>
                            )
                            :
                            ('')
                    }
                    {showDetailIcon && <div className='reg-moreinfo-icon' ><Icon path={mdiInformationOutline} size={0.8} onClick={()=>this.showDetailPopup(item)} title={"More info"}/></div>}
                </div>
                {!showDetailIcon && <p className="attendee-reg-item-desc">{ReactHtmlParser(item.desc)}</p>}
            </>
        )
    }
    renderRegItemPrice(item) {
        // Get the reg item price

        var itemprice = this.getRegItemPrice(item).split('');
        
        if (itemprice.join('').trim() === "" && item.selectedamount) {
            itemprice = item.selectedamount.split('')
        }
       
        if (itemprice.length >= 6) {
            itemprice.splice(3, 0, ',');
        }

        // If the re item is no guest then don't display the price
        if (item.data3 == "noguest") {
            return "";
        }
        else {
            return (
                <div className="reg-price">
                    <div dangerouslySetInnerHTML={{ __html: itemprice.join('') }}></div>
                </div>
            )
        }
    }
    renderRegItemInput(regitem, disableregitem, groupname) {
        
        var maxsignups = regitem.maxsignups;
        var currentsignups = regitem.currentsignups;

        // Validate the regitem current/max signups and make the item sold out
        var itemsoldout = this.validateRegItemCount(regitem, maxsignups, currentsignups);

        //console.log(itemsoldout);

        // Check the max/current signups and get theremaining count 
        var signupsremaining = this.getRegItemRemainingCount(regitem, maxsignups, currentsignups);

        // this is a dynamic state variable based on ID for quantity
        // This is to store the quantity value 
        var quantityvalue = regitem.data5 + 'value';
        if (this.state[quantityvalue] == "" || this.state[quantityvalue] == null) {
            this.state[quantityvalue] = "0";
        }

        if (itemsoldout) {
            return (
                <div className="regitem-soldout">SOLD OUT</div>
            )
        }
        else {
            if (CommonFunctions.isRadioInput(regitem)) {
                return (
                    <RegItemRadio 
                        regitem={regitem} 
                        signupsremaining={signupsremaining} 
                        disableregitem={disableregitem} 
                        groupname={groupname} 
                        refreshComponent={this.refreshComponent} 
                        conditionalRegitems={this.props.conditionalRegitems}
                    />
                )
            }
            else if (CommonFunctions.isCheckboxInput(regitem)) {
                return (
                    <RegItemCheckbox 
                        regitem={regitem} 
                        signupsremaining={signupsremaining} 
                        disableregitem={disableregitem} 
                        groupname={groupname} 
                        refreshComponent={this.refreshComponent} 
                        conditionalRegitems={this.props.conditionalRegitems}
                    />
                )
            }
            else if (CommonFunctions.isNumberInput(regitem)) {
                return (
                    <RegItemNumber 
                        regitem={regitem} 
                        signupsremaining={signupsremaining} 
                        disableregitem={disableregitem} 
                        groupname={groupname} 
                        quantityvalue={quantityvalue} 
                        refreshComponent={this.refreshComponent} 
                        conditionalRegitems={this.props.conditionalRegitems}
                    />
                )
            }
            else if (CommonFunctions.isDropdownInput(regitem)) {
                return (
                    <RegItemdropdown 
                        regitem={regitem} 
                        signupsremaining={signupsremaining} 
                        disableregitem={disableregitem} 
                        groupname={groupname} 
                        refreshComponent={this.refreshComponent} 
                        conditionalRegitems={this.props.conditionalRegitems}
                    />
                )
            }
        }
    }
}

export default RegItemContainer;



