
import React, { Component } from "react";
import * as BABYLON from "babylonjs";
import { API, Analytics } from 'aws-amplify';
import { Row, Col} from 'reactstrap';
var serviceNames = require('../ServiceNameMapper');

var scene;

class VirtualExpoMultiRooms extends Component {
  
	constructor(props) {
    super(props);
    this.state = { 
        useWireFrame: false, 
        shouldAnimate: false,
        code: props.code,
        s3: props.s3,
        bucket: props.bucket,
        dataloaded: false,
       };

	this.getFilePath = this.getFilePath.bind(this);
  }

  componentDidMount = () => {
    
  //console.log('componentDidMount');

	//this.createScene();

	if (global.$exhibitorjson == null )
	{
		this.getExhibitorjson()
	}
	else
	{
		this.createScene();
	}

  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.onWindowResize, false);
  }

  onWindowResize = event => {
    this.engine.resize();
  };

 render() {

	console.log('render');
	Analytics.record({ name: 'PageView', attributes: { name: 'eventsites-'+this.state.code+'-virtualexpo'}});

	if ( global.$exhibitorjson != null ) 
	{
		return (<canvas
			style={{ width: window.innerWidth, height: window.innerHeight }}
			ref={canvas => {
				this.canvas = canvas;
			}}
		/>)
	}
	else
	{
		//this.getExhibitorjson()

		return (<canvas
			style={{ width: window.innerWidth, height: window.innerHeight }}
			ref={canvas => {
				this.canvas = canvas;
			}}
		/>)
	}

}//render


getExhibitorjson() {
	console.log("exhibitor json")
	
	let apiName = serviceNames.getWebsiteService();
	let path = ''; //replace this with the path you have configured on your API
	let myInit = {
	  body:
	  {
		'code': this.state.code,
		'command': 'getJsonFileFromS3',
		'bucket': this.state.bucket,
		'filename': 'exhibitors'
	  },
	  response: true,
	  convertEmptyValues: true,
	  headers: {}
	}
   //console.log(myInit);
  
	API.post(apiName, path, myInit).then(response => {
	  //console.log (response);
  
	  if (response.err == null && response.data != null) {
		let buf = Buffer.from(response.data.Body);
		//console.log(buf)
		//console.log(buf.toString());
		global.$exhibitorjson = JSON.parse(buf.toString());
		//this.setState({ dataloaded: true });
		this.createScene();
	  }
	  else {
	  //console.log(response.err);
	  }
	}
	).catch(error => {
	//console.log(error)
	});
  }

getFilePath(filename)
{
	return `https://s3.amazonaws.com/event360live/public/${this.state.code}/exhibitors/` + filename;
}

createScene()
{
	console.log('createScene');
	
	//if (this.canvas == null)
	if (global.$exhibitorjson == null )
	{
		console.log('exhibitorjson is null, so createScene is done!');
		return;
	}

	console.log('global exhibitors ok');
	console.log(global.$exhibitorjson);

	var totalBooths = global.$exhibitorjson.length;

	console.log('totalBooths = ' + totalBooths);

	var boothWidth 	= 8;
	var space 		= 3;
	var boothsPerSide = Math.floor(totalBooths / 2);

	console.log('boothsPerSide = ' + boothsPerSide);

	var floorW = 10;
 	var floorL = boothWidth * boothsPerSide + space * (boothsPerSide - 1) + space * 2 ;


	// start ENGINE
	this.engine = new BABYLON.Engine(this.canvas, true);

	//Create Scene
	scene = new BABYLON.Scene(this.engine);

	//This creates a basic Babylon Scene object (non-mesh)
	//var scene = new BABYLON.Scene(engine);
	scene.gravity = new BABYLON.Vector3(0, -9.81, 0);
	scene.collisionsEnabled = true;

	this.initCameras(scene);
	this.initLights(scene);

	//var groundW = floorW + 4; //Add a few more space for walls
	var groundW = floorW; //Add a few more space for walls
	var groundL = floorL; //Add a few more space for walls

	this.createGround(scene, groundW, groundL);

	var wallH = boothWidth * 1.75;
	this.createWalls(scene, groundW, groundL, wallH, boothWidth); 

	//this.createBooths(scene, totalBooths, floorW, boothWidth, space);

	// Add Events
	window.addEventListener("resize", this.onWindowResize, false);

		// Render Loop
		this.engine.runRenderLoop(() => {
			scene.render();
		});


//return scene;
}

  initCameras(scene)
  {
    
      // This creates and positions a free camera (non-mesh)
      var camera = new BABYLON.FreeCamera("camera1", new BABYLON.Vector3(0, 5, -10), scene);

      // This targets the camera to scene origin
      camera.setTarget(BABYLON.Vector3.Zero());

      // This attaches the camera to the canvas
      camera.attachControl(this.canvas, true);
    

	/*
      // Parameters : name, position, scene
      //var camera = new BABYLON.UniversalCamera("UniversalCamera", new BABYLON.Vector3(0, 1, 10), scene);
      var camera = new BABYLON.FreeCamera("UniversalCamera", new BABYLON.Vector3(0, 5, 0), scene);

      // Targets the camera to a particular position. In this case the scene origin
      //camera.setTarget(BABYLON.Vector3.Zero());

      // Attach the camera to the canvas
      camera.attachControl(this.canvas, false);  //NOTE:  False is required to avoid webpage scrolling

      camera.applyGravity = true;
      camera.ellipsoid = new BABYLON.Vector3(1, 1, 1);
	  camera.checkCollisions = true;
	*/  
  }

initLights(scene)
{
	// This creates a light, aiming 0,1,0 - to the sky (non-mesh)
    var light = new BABYLON.HemisphericLight("light", new BABYLON.Vector3(0, 1, 0), scene);

    // Default intensity is 1. Let's dim the light a small amount
    light.intensity = 0.7;
}

createGround(scene, floorW, floorL)
{
	// Our built-in 'ground' shape.
    var ground = BABYLON.MeshBuilder.CreateGround("ground", {width: floorW, height: floorL}, scene);
	ground.checkCollisions = true;

	this.createReferenceMarker(scene);
	
	var groundM = new BABYLON.StandardMaterial("groundM", scene);
	groundM.diffuseTexture   = new BABYLON.Texture(global.$config['virtualexporoom'].walls.floor.background, scene);
	groundM.specularTexture  = new BABYLON.Texture(global.$config['virtualexporoom'].walls.floor.background, scene);
	groundM.emissiveTexture  = new BABYLON.Texture(global.$config['virtualexporoom'].walls.floor.background, scene);
	groundM.ambientTexture   = new BABYLON.Texture(global.$config['virtualexporoom'].walls.floor.background, scene);
	ground.material = groundM;
	
	
	var centerMat = BABYLON.MeshBuilder.CreateCylinder("cone", {diameterTop: floorW/4, diameterBottom: floorW/4, height:.1}, scene);
	centerMat.position.x = 0;
    centerMat.position.y = 0; 
    centerMat.position.z = 0;
	centerMat.checkCollisions = true;
	
	var centerMatM = new BABYLON.StandardMaterial("centerMatM", scene);
	centerMatM.diffuseTexture  	= new BABYLON.Texture(global.$config['virtualexporoom'].walls.floor.centermat, scene);
	centerMatM.specularTexture  = new BABYLON.Texture(global.$config['virtualexporoom'].walls.floor.centermat, scene);
	centerMatM.emissiveTexture  = new BABYLON.Texture(global.$config['virtualexporoom'].walls.floor.centermat, scene);
	centerMatM.ambientTexture  	= new BABYLON.Texture(global.$config['virtualexporoom'].walls.floor.centermat, scene);
	centerMat.material = centerMatM;	
}

createRooms(scene)
{
	var rooms = global.$config['virtualexpomultirooms'].rooms;
	var roomNo;
	for (roomNo = 0; roomNo < rooms; roomNo++)
	{
		this.createRoom(scene, roomNo)
	}
}

createRoom(scene, roomNo)
{

}

createWalls(scene, wallWidth, wallLength, wallHeight, boothWidth) 
{
	var wallDepth = .01;

	var backwallM = new BABYLON.StandardMaterial("backwallM", scene);
	backwallM.diffuseTexture  	= new BABYLON.Texture(global.$config['virtualexporoom'].walls.back.background, scene);
	backwallM.specularTexture  = new BABYLON.Texture(global.$config['virtualexporoom'].walls.back.background, scene);
	backwallM.emissiveTexture  = new BABYLON.Texture(global.$config['virtualexporoom'].walls.back.background, scene);
	backwallM.ambientTexture  	= new BABYLON.Texture(global.$config['virtualexporoom'].walls.back.background, scene);

	var frontwall = BABYLON.MeshBuilder.CreateBox("frontwall", {height: wallHeight, width: wallWidth, depth:wallDepth}, scene);
    
	//Place it in the center and move back
    frontwall.position.x = wallWidth/2;  
    frontwall.position.y = wallHeight/2;
	//frontwall.position.z =  wallWidth/2 * -1;
	frontwall.position.z =  0;
    frontwall.checkCollisions = true;
	frontwall.material 	= backwallM;

	
    var backwall = BABYLON.MeshBuilder.CreateBox("backwall", {height: wallHeight, width: wallWidth, depth:wallDepth}, scene);
    
    backwall.position.x = wallWidth/2;  
    backwall.position.y = wallHeight/2;
    backwall.position.z =  wallWidth;
	backwall.checkCollisions = true;
	backwall.material 	= backwallM;

	//console.log('Virtual Room Config background = ' + global.$config['virtualexporoom'])
	console.log('Wall background = ' + global.$config['virtualexporoom'].walls.back.background)

	var rightwall = BABYLON.MeshBuilder.CreateBox("rightwall", {height: wallHeight, width: wallWidth, depth:wallDepth}, scene);
    rightwall.position.x = wallWidth;  
    rightwall.position.y = wallHeight/2 ;
    rightwall.position.z =  wallWidth/2;
	rightwall.rotate(BABYLON.Axis.Y, Math.PI/2, BABYLON.Space.WORLD);    
	rightwall.checkCollisions = true;
	rightwall.material 	= backwallM;
	
	var leftwall = BABYLON.MeshBuilder.CreateBox("leftwall", {height: wallHeight, width: wallWidth, depth:wallDepth}, scene);
    
	//Place it right and rotate
    leftwall.position.x = 0;  
    leftwall.position.y = wallHeight/2;
    leftwall.position.z =  wallWidth/2;
    leftwall.rotate(BABYLON.Axis.Y, 3 * Math.PI/2, BABYLON.Space.WORLD);
    leftwall.checkCollisions = true;		
	leftwall.material 	= backwallM;

    var topwall = BABYLON.MeshBuilder.CreateBox("topwall", {height: wallWidth, width: wallWidth, depth:wallDepth}, scene);
    
    topwall.position.x = wallWidth/2;  
    topwall.position.y = wallHeight;
    topwall.position.z =  wallWidth/2;
    topwall.rotate(BABYLON.Axis.X, Math.PI/2, BABYLON.Space.WORLD);
    topwall.checkCollisions = true;
		
	var topwallM = new BABYLON.StandardMaterial("topwallM", scene);
	topwallM.diffuseTexture  	= new BABYLON.Texture(global.$config['virtualexporoom'].walls.top.background, scene);
	topwallM.specularTexture  = new BABYLON.Texture(global.$config['virtualexporoom'].walls.top.background, scene);
	topwallM.emissiveTexture  = new BABYLON.Texture(global.$config['virtualexporoom'].walls.top.background, scene);
	topwallM.ambientTexture  	= new BABYLON.Texture(global.$config['virtualexporoom'].walls.top.background, scene);
	topwall.material = topwallM;
	
	/*
	var bannerW = boothWidth * 2;
	var bannerH = bannerW/4;
	var bannerD = .05;

	*/
	
	/*
	this.createWallBanners(scene, frontwall, 5, bannerW, bannerH, bannerD, "F");
	this.createWallBanners(scene, backwall, 5, bannerW, bannerH, bannerD, "B");
	this.createWallBanners(scene, leftwall, 5, bannerW, bannerH, bannerD, "L");
	this.createWallBanners(scene, rightwall, 5, bannerW, bannerH, bannerD, "R");	
	*/			


		/*
	var rightwallM = new BABYLON.StandardMaterial("rightwallM", scene);
	rightwallM.diffuseTexture  	= new BABYLON.Texture("https://wesicore.s3.amazonaws.com/test/rightwall.png", scene);
	rightwallM.specularTexture  = new BABYLON.Texture("https://wesicore.s3.amazonaws.com/test/rightwall.png", scene);
	rightwallM.emissiveTexture  = new BABYLON.Texture("https://wesicore.s3.amazonaws.com/test/rightwall.png", scene);
	rightwallM.ambientTexture  	= new BABYLON.Texture("https://wesicore.s3.amazonaws.com/test/rightwall.png", scene);
	rightwall.material = rightwallM;
	*/

	/*
	var leftwallM = new BABYLON.StandardMaterial("leftwallM", scene);
	leftwallM.diffuseTexture  	= new BABYLON.Texture("https://wesicore.s3.amazonaws.com/test/leftwall.png", scene);
	leftwallM.specularTexture  = new BABYLON.Texture("https://wesicore.s3.amazonaws.com/test/leftwall.png", scene);
	leftwallM.emissiveTexture  = new BABYLON.Texture("https://wesicore.s3.amazonaws.com/test/leftwall.png", scene);
	leftwallM.ambientTexture  	= new BABYLON.Texture("https://wesicore.s3.amazonaws.com/test/leftwall.png", scene);
	leftwall.material = leftwallM;
	*/

		
	/*
	var frontwallM = new BABYLON.StandardMaterial("frontwallM", scene);
	frontwallM.diffuseTexture  	= new BABYLON.Texture("https://wesicore.s3.amazonaws.com/test/frontwall.png", scene);
	frontwallM.specularTexture  = new BABYLON.Texture("https://wesicore.s3.amazonaws.com/test/frontwall.png", scene);
	frontwallM.emissiveTexture  = new BABYLON.Texture("https://wesicore.s3.amazonaws.com/test/frontwall.png", scene);
	frontwallM.ambientTexture  	= new BABYLON.Texture("https://wesicore.s3.amazonaws.com/test/frontwall.png", scene);
	frontwall.material = frontwallM;
	*/
}

createWallBanners(scene, parent, bannerY, bannerW, bannerH, bannerD, side) 
{
    var banner = BABYLON.MeshBuilder.CreateBox("banner", {height: bannerH, width: bannerW, depth:bannerD}, scene);
	banner.parent = parent;
	
	banner.position.x = 0;  
    banner.position.y = bannerY;
    banner.position.z =  0;
  
	
	/*
	//Create dynamic texture		
	var texture = new BABYLON.DynamicTexture("dynamic texture", {width:bannerW, height:bannerH}, scene);   
	//var textureContext = texture.getContext();

	var material = new BABYLON.StandardMaterial("Mat", scene);    				
	material.diffuseTexture = texture;
	
	banner.material = material;

	//Add text to dynamic texture
	var font = "bold 60px Arial";
	texture.drawText("2020 Virtual Conference", 200, 150, font, "green", "white", true, true);
	*/
	
	
	banner.actionManager = new BABYLON.ActionManager(scene);
	
	banner.actionManager.registerAction(
        new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOverTrigger, 
				function(event){
					
					console.log("banner OnPointerOverTrigger");
					
					var bannerM = new BABYLON.StandardMaterial("bannerM", scene);
					//tvM.emissiveColor = new BABYLON.Color3(1, 1, 1);
					//var videoT = new BABYLON.VideoTexture("video", "./wallbanner1.mp4", scene, true);
					var path = global.$config['virtualexporoom'].walls.back.video
					var videoT = new BABYLON.VideoTexture("video", path, scene, true);
					
					if (side == 'F')
					{
						videoT.uScale = -1; // Mirror Horizontally
						videoT.vScale = -1; // Mirror Vertically
					}
					else if (side == 'B')
					{ //not ok (This is the back wall from room perspective
					
						videoT.uScale = 1; // Mirror Horizontally
						videoT.vScale = 1; // Mirror Vertically
					}
					
					else{
						videoT.uScale = -1; // Mirror Horizontally
						videoT.vScale = -1; // Mirror Vertically
					}
					
					bannerM.diffuseTexture 	= videoT;
					bannerM.specularTexture = videoT;
					bannerM.emissiveTexture = videoT;
					bannerM.ambientTexture 	= videoT;
					
					banner.material = bannerM;
					banner.material.diffuseTexture.video.play();

				})//ExecuteCodeAction
    );//registerAction	
}

/*
    NOTE Z -ve is forward
*/

createBooths(scene, totalBooths, floorW, platformW, space)
{   
	var start = 0;
	var boothsPerSide = Math.floor(totalBooths/4);
	
	var startX = floorW/2 * -1 + space + platformW/2;
	var startY = 1;
	var startZ = floorW/2;
	//var platformR = 0;  //90 - 1.5 

    // Front
	//for (var i = 1; i<= totalBooths; i++)
	for (var i = start; i< boothsPerSide; i++)
	{
		this.createBooth(scene, i, platformW, startX, startY, startZ, 'F');
		startX = startX + platformW + space;
	}

	// Behind
    startX = floorW/2 * -1 + space + platformW/2;
	startY = 0;
	startZ = floorW/2 * -1;
	platformR = 3.14159;  //90 - 1.5 

	start = boothsPerSide;
	for (var i = start; i < (start+boothsPerSide); i++)
	{
		this.createBooth(scene, i, platformW, startX, startY, startZ, 'B');
		startX = startX + platformW + space;
	}

    //Right
    startX = (floorW/2) * -1
	startY = 0;
	startZ = floorW/2 * 1 - space - platformW/2;
	var platformR = -1.508;  //90 - 1.5

	start = start + boothsPerSide;
	//for (var i = 1; i <= totalBooths; i++)
	for (var i = start; i < (start+boothsPerSide); i++)
	{
		this.createBooth(scene, i, platformW, startX, startY, startZ, 'R');
		startZ = startZ - (platformW + space);
	}

    //Left
    startX = (floorW/2) * 1
	startY = 0;
	startZ = floorW/2 * 1 - space - platformW/2;
	var platformR = 1.508;  //90 - 1.5

	start = start + boothsPerSide;
	//for (var i = 1; i <= totalBooths; i++)
	for (var i = start; i < (start+boothsPerSide); i++)
	{
		this.createBooth(scene, i, platformW, startX, startY, startZ, 'L');
		startZ = startZ - (platformW + space);
	}	
}

createBooth(scene, boothNo, platformWidth, platformX, platformY, platformZ, platformR) 
{
	console.log('createBooth ' + boothNo);

	var exhibitor = null;

	//console.log(global.$exhibitorjson);

	if (global.$exhibitorjson != null)
	{
		exhibitor = global.$exhibitorjson[boothNo];
	}

	console.log(exhibitor);

	var platformW = platformWidth;
    var platformH = .1;
	//var platformD = platformWidth/4;
	var platformD = .5;

	//var backwallW = platformW/2;
	var backwallW = platformW;
	var backwallH = backwallW/2;
	var backwallD = .1;
	
	var backwallX = 0;
	var backwallY = backwallH;
	var backwallZ = 0;

	//var rightColW = backwallW / 2;
    //var rightColH = rightColW * 2;

	//var platformW = backwallW + rightColW +1;
    //var platformH = .1;
	
	//Bottom Platform
	var platform = BABYLON.MeshBuilder.CreateBox("platform", {height: platformH, width: platformW, depth:platformD}, scene);
 
	platform.position.x = platformX;
    platform.position.y = 3; 
    platform.position.z = platformZ;
    
    //platform.scaling.z = .25;
	//platform.rotation.y = platformR;
	platform.checkCollisions = true;

	//Backwall
    var backwall = BABYLON.MeshBuilder.CreateBox("backwall", {height: backwallH, width: backwallW, depth:backwallD}, scene);
	backwall.parent = platform

    backwall.position.x = 0;
    backwall.position.y = backwallH/2;
    backwall.position.z = 0;
    //backwall.scaling.z = .10;
    backwall.checkCollisions = true;
	
	var tvW = backwallW/2;
	var tvH = backwallH/2;
	var tvW = backwallW - .2;
	var tvH = backwallH - .2;
	this.createTV(scene, backwall, exhibitor, tvW, tvH, backwallD/4);

    //Right column
	/*
	var rightColumn = BABYLON.MeshBuilder.CreateCylinder("cone", {diameterTop: rightColW, diameterBottom: rightColW, height:rightColH}, scene);
    rightColumn.parent = platform;

	//Place right column at the end of backwall and tilt
	rightColumn.position.x = backwallW/2;
    rightColumn.position.y = rightColH/2;
    rightColumn.position.z = .5;
    
    rightColumn.scaling.z = .25;
	rightColumn.rotation.y = -15;
    rightColumn.checkCollisions = true;
	*/
    //createTable(scene, platform);

	
    var platformM = new BABYLON.StandardMaterial("platformM", scene);
	platformM.diffuseColor = new BABYLON.Color3(1, 1, 1);
	platformM.specularColor = new BABYLON.Color3(1, 1, 1);
	platformM.emissiveColor = new BABYLON.Color3(1, 1, 1);
	platformM.ambientColor = new BABYLON.Color3(1, 1, 1);
	platform.material = platformM;

    var backwallM = new BABYLON.StandardMaterial("backwallM", scene);
	backwallM.diffuseColor = new BABYLON.Color3(0, 0, 0);
	backwallM.specularColor = new BABYLON.Color3(0, 0, 0);
	backwallM.emissiveColor = new BABYLON.Color3(0, 0, 0);
	backwallM.ambientColor = new BABYLON.Color3(0, 0, 0);
	backwall.material = backwallM;

	/*
    var rightColumnM = new BABYLON.StandardMaterial("rightColumnM", scene);
	rightColumnM.diffuseColor = new BABYLON.Color3(1, 1, 1);
	rightColumn.material = rightColumnM;
	*/
		
	if (platformR == 'F')
	{
		platform.rotate(BABYLON.Axis.Y, Math.PI, BABYLON.Space.WORLD);
	}
	else if (platformR == 'B')
	{
		platform.rotate(BABYLON.Axis.Y, 2 * Math.PI, BABYLON.Space.WORLD);
	}
	else if (platformR == 'R')
	{
		platform.rotate(BABYLON.Axis.Y, Math.PI/2, BABYLON.Space.WORLD);
	}
	else if (platformR == 'L')
	{
		platform.rotate(BABYLON.Axis.Y, 3 * Math.PI/2, BABYLON.Space.WORLD);	
	}
}


createTV(scene, parent, exhibitor, tvW, tvH, tvD) 
{
	var self = this;

 	//var tvD = .25;
    var tv = BABYLON.MeshBuilder.CreateBox("tv", {height: tvH, width: tvW, depth:tvD}, scene);
    tv.parent = parent;

    tv.position.x 		= 0;  
    tv.position.y 		= 0;
    tv.position.z 		= tvD * 2;
    tv.checkCollisions 	= true;
	
	
	var logoM = new BABYLON.StandardMaterial("logoM", scene);
	
	/*
	var rnd = this.getRandomInt(11);
	if (rnd == 0)
	{
		rnd = 1;
	}
	*/
	
	//var path  = 'https://wesicore.s3.amazonaws.com/test/' + rnd +'.png';

	var path = this.getFilePath(exhibitor.LOGO);
	console.log(path);;

	var texture = new BABYLON.Texture(path, scene);
	
	//texture.invertY = true;
	
	texture.uScale = -1; // Mirror Horizontally
	texture.vScale = -1; // Mirror Vertically

	logoM.diffuseTexture   = texture;
	logoM.specularTexture  = texture;
	logoM.emissiveTexture  = texture;
	logoM.ambientTexture   = texture;
		
	tv.material = logoM;
	
	tv.actionManager = new BABYLON.ActionManager(scene);
	
	tv.actionManager.registerAction(
        new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOverTrigger, 
				function(event){
					
					console.log("tv OnPointerOverTrigger");
					
					var tv = event.meshUnderPointer; 
	
					var tvM = new BABYLON.StandardMaterial("tvM", scene);
					//tvM.emissiveColor = new BABYLON.Color3(1, 1, 1);

					//var path = self.getFilePath(exhibitor.VIDEOURL);
					//console.log(path);
				
					var videoT = new BABYLON.VideoTexture("video", exhibitor.VIDEOURL, scene, true);
					
					videoT.uScale = -1; // Mirror Horizontally
					videoT.vScale = -1; // Mirror Vertically
	
					tvM.diffuseTexture 	= videoT;
					tvM.specularTexture = videoT;
					tvM.emissiveTexture = videoT;
					tvM.ambientTexture 	= videoT;
					
					tv.material = tvM;
					tv.material.diffuseTexture.video.play();
					
					//window.open("http://doc.babylonjs.com/");
				})//ExecuteCodeAction
    );//registerAction
	
	tv.actionManager.registerAction(
        new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOutTrigger, 
				function(event){
					
					console.log("tv OnPointerOutTrigger");
					
					var tv = event.meshUnderPointer; 
					tv.material.diffuseTexture.video.pause();
	
					/*
					var tvM = new BABYLON.StandardMaterial("tvM", scene);
					//tvM.emissiveColor = new BABYLON.Color3(1, 1, 1);
					var videoT = new BABYLON.VideoTexture("video", "./vsvideo.mp4", scene, true);
					tvM.diffuseTexture = videoT;
					tv.material = tvM;
					tv.material.diffuseTexture.video.play();
					
					//window.open("http://doc.babylonjs.com/");
					*/
					
				})//ExecuteCodeAction
    );//registerAction
	
	/*
	tv.actionManager.registerAction(
        new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger, 
				function(event){
					
					console.log("tv onPickTrigger");
					
					var tv = event.meshUnderPointer; 
	
					var tvM = new BABYLON.StandardMaterial("tvM", scene);
					//tvM.emissiveColor = new BABYLON.Color3(1, 1, 1);
					var videoT = new BABYLON.VideoTexture("video", "./vsvideo.mp4", scene, true);
					tvM.diffuseTexture = videoT;
					tv.material = tvM;
					tv.material.diffuseTexture.video.play();
					
					//window.open("http://doc.babylonjs.com/");

				})//ExecuteCodeAction
    );//registerAction
	*/
	
	/*
	scene.onPointerDown = function () {
       tv.video.play();
    }
	*/
	
	/*
	tvM.diffuseColor = new BABYLON.Color3(1, 1, 1);
	tvM.specularColor = new BABYLON.Color3(1, 1, 1);
	tvM.emissiveColor = new BABYLON.Color3(1, 1, 1);
	tvM.ambientColor = new BABYLON.Color3(1, 1, 1);
	tv.material = tvM;	
	*/
	
	/*
	tv.material.diffuseTexture = new BABYLON.VideoTexture("video", "./vsvideo.mp4", scene, true);
	
	/*
	tv.onPointerUp = function () {
       videoTexture.video.play();
    }
	*/

}


createReferenceMarker(scene)
{
	var width = .5;
	var height = 1;
	
	var marker = BABYLON.MeshBuilder.CreateCylinder("cone", {diameterTop: width, diameterBottom: width, height:height}, scene);
	marker.position.x = 0;
    marker.position.y = height/2; 
    marker.position.z = 0;
	
	var markerM = new BABYLON.StandardMaterial("markerM", scene);
	markerM.diffuseColor 	= new BABYLON.Color3(1, 0, 0);
	markerM.specularColor 	= new BABYLON.Color3(1, 0, 0);
	markerM.emissiveColor 	= new BABYLON.Color3(1, 0, 0);
	markerM.ambientColor 	= new BABYLON.Color3(1, 0, 0);
	marker.material = markerM;
}


}
export default VirtualExpoMultiRooms;