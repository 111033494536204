import CommonFunctions from "./CommonFunctions";

// date convertion
const setDate = () => {
    var date = new Date();
    var timestamp = date / 1000;
    return timestamp;
}
// CREATE REGID
function createUserRegid(reginfo) {
    var timestamp = '';

    // USING SAME REGID
    if (CommonFunctions.isUserLoggedin() && reginfo.regid != "") {
        timestamp = reginfo.regid
    }
    else {// CREATE NEW REGID
        var longFormat = setDate().toString();
        longFormat = longFormat.replace(/\./g, "");
        var newdate = longFormat.substring(longFormat.length - 10, longFormat.length);
        timestamp = newdate;
        reginfo.regid = timestamp
    }
    
    return timestamp;
}

// SET PASSWORD
function createUserPassword(reginfo) {
    let password = "";

    if (CommonFunctions.isUserLoggedin() && reginfo.password != "") {
        password = reginfo.password
    }
    else {
        // CREATE PASSWORD USING WEBCONFIG KEY
        if (global.$registrationform.setup[global.$tabid].password && global.$registrationform.setup[global.$tabid].password != "") {
            var value = global.$registrationform.setup[global.$tabid].password;

            // SET PASSWORD USING WEBCONFIG value
            if (value == "phone" || value == "regid") {
                var numbers = reginfo[value].match(/\d+/g).toString()
                numbers = numbers.replace(/,/g,"")
                password = numbers
            }
            // SET CUSTOM PASSWORD
            else password = value; 
        }
        // SET DEFAULT PASSWORD (REGID)
        else password = reginfo.regid
    }
    
    
    return password;

    /*
    if (global.$code == "metro2022conference") {
        password = "MeTro2022";
    }
     if (global.$code == "metro2023convention") {
        password = "MeTro2023";
    }
     if (global.$code == "aicup2022") {
        password = "AiCuP2022";
    }
     if (global.$code == "aslatx2022") {
        password = "aslatx2022";
    }
    
    if (global.$code == "aslatx2022") {
        password = "aslatx2022";
    }
    
    if (global.$code == "metro2023meeting") {
        password = "MetroMeeting2023";
    }

    */
}

// add attendee and guest register items 
const setPromocodeDetails = () => {
    //let regitems;
    let promoobj = {}

    if (global.$guestregitems != null && global.$regguestinfo !== "") {
        if (global.$regdiscountinfo != null && global.$regdiscountinfo !== "") {
            promoobj.id = CommonFunctions.createdatetime().timestamp
            promoobj.name = "Promo or Deferral"
            promoobj.price = Number(global.$regdiscountinfo.discountprice).toFixed(2).toString()
            promoobj.type = "promo"
            promoobj.itemid = "1"
            promoobj.qty = "1"
            promoobj.status = "active"
            promoobj.data1 = global.$regdiscountinfo.discountvalue
            promoobj.data2 = ""
            promoobj.data3 = ""
            promoobj.data4 = ""
            promoobj.data5 = ""
            promoobj.cdate = CommonFunctions.createdatetime().datatime
            global.$selectedRegItems.push(promoobj)
            global.$purchaseRegitems.push(promoobj)
           
        }
        // else {
        //     if (CommonFunctions.isUserLoggedin()) {
        //         if (global.$guestexist != null && global.$guestexist === true) {
        //             regitems = global.$guestregitems.toString();
        //         }
        //         else {
        //             regitems = global.$totalregitems.toString();
        //         }
        //     }
        //     else {
        //         regitems = global.$totalregitems.toString();
        //     }
        // }
    }
    // else {
    //     if (global.$totalregitems != null) {
    //         regitems = global.$totalregitems.toString();
    //     }
    // }

   // return promoobj;
}

// add default usertype and emailtype
const setuserandemailtype = (user,type,regusertype) => {

    let emailtype;
    let usertype

    if (user === "attendee") {
        if (type === "attendee") {
            emailtype = "primaryattendee";
            usertype = "Attendee";
        }
        else if (type === "sponsor") {
            emailtype = "primarysponsor";
            usertype = "Sponsor";
        }
        else if (type === "exhibitor") {
            emailtype = "primaryexhibitor";
            usertype = "Exhibitor";
            
            if(regusertype == "Exhibitor Admin"){
                usertype = "Exhibitor Admin";
            }
        }
        else if (type === "groupadmin") {
            emailtype = "primaryattendee";
            usertype = "Group Admin";
        }
    }
    else if (user === "guest") {
        if (type === "attendee") {
            emailtype = "primaryguest";
            usertype = "Guest"
        }
        else if (type === "sponsor") {
            emailtype = "guestsponsor";
            usertype = "Exhibitor";
        }
        else if (type === "exhibitor") {
            emailtype = "primaryexhibitor";
            usertype = "Exhibitor";

            if(regusertype == "Exhibitor Admin"){
                usertype = "Exhibitor Admin";
            }

        }
    }
    

    return {emailtype,usertype}
}

// event functions-----------------------------------

// Added this check for metro automatic badge In-Person/Virtual
const setbadge = (user) => {
    let data4 = " ";

    if (CommonFunctions.isUserLoggedin()) {
        if (user === "guest") {
            if (global.$guestregitems.toString().includes("In-Person")) {
                data4 = "In-Person";
            }
            else if (global.$guestregitems.toString().includes("Virtual")) {
                data4 = "Virtual";
            }
        }
    }
    else {

        if (user === "attendee") {
            if (global.$attendeeregitems.toString().includes("In-Person")) {
                data4 = "In-Person";
            }
            else if (global.$attendeeregitems.toString().includes("Virtual")) {
                data4 = "Virtual";
            }
        }
        else if (user === "guest") {
            if (global.$guestregitems.toString().includes("In-Person")) {
                data4 = "In-Person";
            }
            else if (global.$guestregitems.toString().includes("Virtual")) {
                data4 = "Virtual";
            }
        }
    }
    return data4;
}

// set response messages
const messageFunction = () => {

    let successcardmsg = ""
    let successcheckmsg = ""
    let successpayrollmsg = ""
    let updatemsg = ""
    let successfree = ""
    let errormsg = ""
    let successachmsg = ""
    let successrazorpaymsg = ""
    let showloginlink = ""
    let errormsg1 = ""

    if (global.$registrationform.messages != null){

        successcardmsg = global.$registrationform.messages.successcard;
        successcheckmsg = global.$registrationform.messages.successcheck;
        successpayrollmsg = global.$registrationform.messages.successpayroll;
        updatemsg = global.$registrationform.messages.successupdate;
        successfree = global.$registrationform.messages.successfree;
        errormsg = global.$registrationform.messages.failure;
        errormsg1 = "Payment is processed but registration failed. Please contact support for further assistance."
        if (global.$registrationform.messages.successachmsg != null) {
            successachmsg = global.$registrationform.messages.successachmsg;
        }
        if (global.$registrationform.messages.successrazorpaymsg != null) {
            successrazorpaymsg = global.$registrationform.messages.successrazorpaymsg;
        }

        if (global.$registrationform.messages.showloginmessage != null) {
            showloginlink = global.$registrationform.messages.showloginmessage;
        }
    }

    return {
        "Card":successcardmsg,
        "Check":successcheckmsg,
        "PayLater":successcardmsg,
        "Payroll":successpayrollmsg,
        "Update":updatemsg,
        "Free":successfree,
        errormsg,
        "ACH":successachmsg,
        "PayOnline":successrazorpaymsg,
        showloginlink,
        errormsg1
    }
}

// set reg questions answer
const setQuestionAnswer = (questionTitle,questionValue,answer) => {
    var quevalue = "";
    if (questionTitle != "" && questionValue == "") {
        quevalue = 'q#title:' + questionTitle + ';answer:' + answer;
    }
    else if (questionValue != "") {
        quevalue = 'q#title:' + questionTitle + ';answer:' + questionValue;
    }
    return quevalue;
}

// check payment type
function checkPaymentType(type) {
    let result = false;
    if (global.$selectedPaymentOption == type) {
        result = true;
    }
    return result;
}



export default {
    setDate,
    createUserRegid,
    createUserPassword,
    setPromocodeDetails,
    setuserandemailtype,
    setbadge,
    messageFunction,
    setQuestionAnswer,
    checkPaymentType
}