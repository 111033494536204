import React from 'react';
import { Col, Row, Button, Form, FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardHeader, CardBody } from 'reactstrap';
import { API } from 'aws-amplify';
import RegisterAttendee from '../RegisterAttendee';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
var serviceNames = require('../../ServiceNameMapper');
var doPayment = true; //Live: true, Test: false

export default class ownForm extends React.Component {
    constructor(props) {
        super(props);
        // if (this.isTravconExhibitor(global.$code) && global.$selectedpaymenttype == "deposit") {
        //     if (global.$loginstatus == null || global.$loginstatus == "false") {
        //         global.$regamount = Number(global.$registrationform.payment.deposit.amount);
        //     }
        // }
        this.state = {
            bankname: '',
            accountnumber: '',
            accounttype: '',
            routingnumber: '',
            nameonaccount: '',
            amount: global.$regamount,
            paymentmessage: '',
            paid: false,    //  LIVE
            //paid: true,    //  TEST
            showerror: false,
            errormsg: "",
            disablesubmitbtn: false,
            showspinner: false
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.hideRegisterButtonFromACH = this.hideRegisterButtonFromACH.bind(this);
        this.sendPaymentDataToAnet = this.sendPaymentDataToAnet.bind(this);
        this.responseHandler = this.responseHandler.bind(this);
        //console.log(this.state);
        
        //console.log(global.$regamount);
    }

    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        let value = target.value

        //console.log("Target = " + name + ", Value = " + value);

        this.setState({ [name]: value });
        //this.setState({selectedCategory:value})
    }

    render() {

        var webconfig = global.$registrationform;
        return (
            <div>
                <p className="ach-payment-amount">Total Amount = ${this.state.amount}</p>
                <Card className="ach-card">
                    <CardHeader>ACH Payment</CardHeader>
                    <CardBody>
                        <Form className="" >
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="bankname" className="ach-form-label">Bank Name*</Label>
                                        <Input type="text" name="bankname" className="ach-form-control" id="bankname" value={this.state.bankname} onChange={this.handleInputChange} />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="accountname" className="ach-form-label">Name on Account*</Label>
                                        <Input type="text" name="nameonaccount" id="nameonaccount" className="ach-form-control" value={this.state.nameonaccount} onChange={this.handleInputChange} required />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="accountnumber" className="ach-form-label">Account Number*</Label>
                                        <Input type="text" name="accountnumber" id="accountnumber" className="ach-form-control" value={this.state.accountnumber} onChange={this.handleInputChange} required />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="routingnumber" className="ach-form-label">ABA Routing Number*</Label>
                                        <Input type="text" name="routingnumber" id="routingnumber" className="ach-form-control" value={this.state.routingnumber} onChange={this.handleInputChange} required />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="bankaccounttype">Bank Account Type*</Label>
                                        <Input type="select" name="accounttype" id="accounttype" onChange={this.handleInputChange}>
                                            <option value="">Select</option>
                                            <option value="businessChecking">Business Checking</option>
                                            <option value="checking">Checking</option>
                                            <option value="savings">Savings</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <br />
                        </Form>
                        {
                            this.state.showerror == true ?
                                (
                                    <p className="ach-payment-failed">{this.state.errormsg}</p>
                                )
                                :
                                ('')
                        }
                        <div className="loading-button-div">
                            <Spinner
                                animation="border"
                                role="status"
                                size="sm"
                                hidden={!this.state.showspinner}
                                className="loading-button-register"
                            />
                        </div>
                        <Button type="button" onClick={this.sendPaymentDataToAnet} color="primary" className="w-100" disabled={this.state.disablesubmitbtn}>Submit</Button>
                    </CardBody>
                </Card>
                {
                    this.state.paid == true ?
                        (
                            <div className="main">
                                { /*<p className="payment-success">Successfully paid.</p>*/}
                                <RegisterAttendee regattendeestatus={this.props.regattendeestatus} reggueststatus={this.props.reggueststatus} type={this.props.type} hideRegisterButton={this.hideRegisterButtonFromACH} />
                                {/* {
                                    global.$loginstatus.includes("true") ?
                                        (
                                            ''
                                        )
                                        :
                                        (

                                            global.$deferredpromo == "Y" ?
                                                ('')
                                                :
                                                (
                                                    <p className="payment-login" style={this.state.loginbuttonstyle}><Link to={`/LoginForm`}>Click here to login</Link></p>
                                                )
                                        )
                                } */}
                            </div>
                        )
                        :
                        ('')
                }
                <hr />
                <div className="ach-copyright">
                    <p>{webconfig.payment.ach.authorizenet.copyright}</p>
                    <a href={webconfig.payment.ach.authorizenet.sealcode} onClick={this.openACH.bind(this)} rel="noopener noreferrer" target="_blank"><img src="https://verify.authorize.net/anetseal/images/secure90x72.gif" alt="Authorize.Net Merchant - Click to Verify" /></a>
                </div>

            </div>
        )
    }

    openACH() {
        var webconfig = global.$registrationform;
        window.open(`${webconfig.payment.ach.authorizenet.sealcode}`, 'AuthorizeNetVerification', 'width=600,height=430,dependent=yes,resizable=yes,scrollbars=yes,menubar=no,toolbar=no,status=no,directories=no,location=yes'); return false;
    }
    hideRegisterButtonFromACH() {
        this.props.hideRegisterButton()
    }

    fieldsValidated() {
        if (this.state.accountnumber === "" || this.state.routingnumber === "" || this.state.nameonaccount === "" || this.state.accounttype === "" || this.state.bankname === " " || this.state.accountnumber === " " || this.state.routingnumber === " " || this.state.nameonaccount === " " || this.state.accounttype === " " || this.state.bankname === " ") {
            this.forceUpdate();
            return false;
        }
        else {
            return true;
        }
    }

    sendPaymentDataToAnet(event) {

        if (this.fieldsValidated()) {
            if(doPayment) {
                this.setState({
                    showerror: false,
                    errormsg: "",
                    disablesubmitbtn: true,
                    showspinner: true
                })
                event.preventDefault();
                var payment = global.$registrationform.payment;
    
                //console.log('in');
    
                var authData = {};
                authData.clientKey = `${payment.ach.authorizenet.clientkey}`;
                authData.apiLoginID = `${payment.ach.authorizenet.apiloginid}`;
    
                var bankData = {};
                //console.log(this.state);
    
                bankData.accountNumber = this.state.accountnumber
                bankData.routingNumber = this.state.routingnumber
                bankData.nameOnAccount = this.state.nameonaccount
                bankData.accountType = this.state.accounttype
                bankData.amount = this.state.amount
    
                // bankData.accountNumber = "123456"
                // bankData.routingNumber = "266086554"
                // bankData.nameOnAccount = "john"
                // bankData.accountType = "savings"
                // bankData.amount = "5"
    
                var secureData = {};
                secureData.authData = authData;
    
                secureData.bankData = bankData;
    
                //console.log(secureData);
    
                window.Accept.dispatchData(secureData, this.responseHandler);
            }
            else {
                this.setState({ paid: true, showspinner: false })
            }
        }
        else {
            this.setState({
                showerror: true,
                errormsg: "Fill all the required(*) fields to continue"
            })
        }
    }
    responseHandler(response) {
        //console.log('responseHandler');
        //console.log(response);
        try {
            if (response.messages.resultCode === "Error") {
                //this.createAuthNetPaymentOrder("response.opaqueData");
                var i = 0;
                while (i < response.messages.message.length) {
                    console.log(
                        response.messages.message[i].code + ": " +
                        response.messages.message[i].text
                    );
                    i = i + 1;
                }
                this.setState({
                    paid: false,
                    showerror: true,
                    showspinner: false,
                    errormsg: response.messages.message[i].text
                })
            }
            else {
                //console.log("Payment Success");
                //console.log(response);
                this.createAuthNetPaymentOrder(response.opaqueData);
            }
        } catch (error) {
            console.log(error);
            this.setState({
                paid: false,
                showerror: true,
                showspinner: false,
                errormsg: "Unable to make the payment"
            })
        }

    }
    createAuthNetPaymentOrder(response) {
        console.log("amount to charge in ACH = "+this.state.amount)
        var attendee = global.$regattendeeinfo;
        let apiName = serviceNames.getPaymentService();
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'command': 'authorizeDotNetACHPayment',
                'code': global.$code,
                'datavalue': response.dataValue,
                //'datavalue': "eyJjb2RlIjoiNTBfMl8wNjAwMDUyNDU3RUJEODY4MjE2N0ZBNTM3QjZFMDVENTY2RDE3RjkxM0E0RDUwOUQ5NEY3QjQ2OUVCNzI5QUM3MDc3MTI3ODRBREJCNkEyMUY0RTg1MDg0QzU3OEZFREQ5NEFGOEE0IiwidG9rZW4iOiI5NjI2ODQyMTc4MzYyMzkyMjAzNjAxIiwidiI6IjEuMSJ9",
                'datadescriptor': response.dataDescriptor,
                //'datadescriptor': "COMMON.ACCEPT.INAPP.PAYMENT",
                'amount': this.state.amount,
                'taxamount': '0.00',
                'taxname': '',
                'taxdescription': '',
                'firstname': attendee.fname,
                'lastname': attendee.lname,
                'company': attendee.company,
                'address': attendee.street,
                'city': attendee.city,
                'state': attendee.state,
                'zip': attendee.zip,
                'country': attendee.country,
                'email': attendee.email
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //console.log(myInit)
        API.post(apiName, path, myInit).then(response => {
            //console.log(response)
            //console.log(response.data)
            //console.loh(response.data.messages.message)
            if (response.data.errorMessage == null && response.data.responseCode != null) {
                this.setState({ paid: true, showspinner: false })
            }
            else {
                this.setState({
                    paid: false,
                    showerror: true,
                    showspinner: false,
                    errormsg: "Unable to make the payment"
                })
            }

        }).catch(error => {
            //console.log(error)
        });
    }
    
    /*
    isTravconExhibitor(code) {
        var result = false;
        if (code == "travcon2022exhibitor" || code == "travcon2023exhibitor") {
            result = true;
        }
        return result;
    }
    */
}