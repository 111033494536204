import React from 'react';
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators, Row, Container, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import apps4orglogo from "../images/apps4orglogo.png"
import './Contact.css';
import {CircleArrow as ScrollUpButton} from "react-scroll-up-button";
import {API, Analytics} from "aws-amplify";
import { mdiAccount, mdiEmail, mdiPhone, mdiMessage } from '@mdi/js';
import Icon from '@mdi/react';
var serviceNames = require('../ServiceNameMapper');

  class Contact extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        code: props.code,
        s3: props.s3,
        activeIndex: 0,
        name: '',
        email: '',
        phone: '',
        message: '',
        note: '',
        clearbuttonstyles: global.$contact.buttons.clear.styles,
        submitbuttonstyles: global.$contact.buttons.submit.styles,
        pagestyle: global.$contact.pagestyle,
        formstyles: global.$contact.form.styles
      }
      this.goToIndex = this.goToIndex.bind(this);
      this.onExiting = this.onExiting.bind(this);
      this.onExited = this.onExited.bind(this);
      this.handleInputChange  = this.handleInputChange.bind(this);
      this.handleSubmit       = this.handleSubmit.bind(this);
      this.handleClear        = this.handleClear.bind(this);       
     //console.log(this.state.code)
    }

    componentDidMount() {
      window.scrollTo(0, 0)
    }
    
    onExiting() {
      this.animating = true;
    }
    onExited() {
      this.animating = false;
    }
    goToIndex(newIndex) {
      if (this.animating) return;
      this.setState({ activeIndex: newIndex });
    }
    render() {
      
    Analytics.record({ name: 'PageView', attributes: { name: 'eventsites-'+this.state.code+'-contact'}});
      return (
        <div>
          <div>
            <img className="banner" src={global.$contact.banner}/> 
          </div>
          {
            global.$contact.form.enable.includes("Y")?
            (
              <div style={this.state.pagestyle}>
                <br />
                <br />
              <Row>
                <Col md={7}>    
                  <div dangerouslySetInnerHTML={{__html: global.$contact.image}}></div>
                </Col>
                <Col md={5}>
                  <div className="contact-info" style={this.state.formstyles}>
                      <div dangerouslySetInnerHTML={{__html: global.$contact.form.header}}></div>
                      <div dangerouslySetInnerHTML={{__html: global.$contact.form.messagebelowheader}}></div>
                    <form className="contact-form"> 
                          <FormGroup className="contact-form-group">
                            <Label className="label" for="name"><Icon path={mdiAccount} size={0.9} className="contact-icons"/>Name*</Label>
                            <Input type="text" name="name" id="name" value={this.state.name || ''} onChange={ this.handleInputChange} required/>
                          </FormGroup>
                          <FormGroup className="contact-form-group">
                              <Label className="label" for="enail"><Icon path={mdiEmail} size={0.9} className="contact-icons"/>Email*</Label>
                              <Input type="text" name="email" id="email" value={this.state.email || ''} onChange={ this.handleInputChange} required/>
                          </FormGroup>
                          <FormGroup className="contact-form-group">
                              <Label className="label" for="phone"><Icon path={mdiPhone} size={0.9} className="contact-icons"/>Phone</Label>
                              <Input type="text" name="phone" id="phone" value={this.state.phone || ''} onChange={ this.handleInputChange}/>
                          </FormGroup>
                          <FormGroup className="contact-form-group">
                            <Label className="label" for="message"><Icon path={mdiMessage} size={0.9} className="contact-icons"/>Message*</Label>
                            <Input type="textarea" className="contact-textarea" name="message" id="message" value={this.state.message || ''} onChange={ this.handleInputChange} required/>   
                          </FormGroup>
                    <div style={{textAlign: "center"}}>
                      <h6 style={{color:"green",height:'15px'}} id="note">{this.state.note}</h6>
                      <button type="button" className="btn contact-submit" style={this.state.submitbuttonstyles} onClick={this.handleSubmit}>{global.$contact.buttons.submit.text}</button>
                      <button color="danger" className="btn contact-submit" style={this.state.clearbuttonstyles} onClick={this.handleClear}>{global.$contact.buttons.clear.text}</button>
                    </div>
                    </form>
                  </div>
                </Col>
              </Row>
              <br/>
              <br/>
              </div>
            )
          :
            ('')
          }
          <div className="offset-1">
            <Row>
              <Col md={11}>
                {this.getRows()}
              </Col>
            </Row>
          </div>
          {
            global.$contact.map.enable.includes("Y")?
            (
              <Container>
                  <Row id="cards">               
                        <Col md="12" >
                        <iframe src={global.$contact.map.maplocation} id="map"></iframe>
                        </Col>
                  </Row>
              </Container>
            )
            :
            ('')
          }
          </div>
          );
          
      }
     
         
      handleInputChange(event) 
      {
          const target = event.target;
          const name = target.name;
          let  value = target.value    
  
          //console.log( "Target = " + name + ", Value = " + value);
              this.setState({[name]: value});    
      }
  
      validateInputs()
      {
          if ( this.state.name === "" || this.state.email === "" || this.state.message === "")
          {
              this.state.note = "Enter all required fields"
              this.forceUpdate();
              return false;
          }
          return true;
      }
    
      handleSubmit(event) 
      {
          event.preventDefault();
          if (this.validateInputs())
          {
              this.submitRegistration(event);
          }
      }
  
      handleClear(event) 
      {
          event.preventDefault();
          this.clearInputs();
          this.state.note= '';   
      }
    
      clearInputs()
      {
        this.state.name = "";
        this.state.email = "";
        this.state.phone = "";
        this.state.message = "";
        this.state.note = "";
        this.forceUpdate(); 
      }
      submitRegistration(event)
      {    
          
              let apiName = serviceNames.getWebsiteService(); 
              let path = ''; //replace this with the path you have configured on your API
              var date = new Date();  
              var longFormat = date/1000; 
              var today = new Date();

              var date = today.toLocaleString();
              
             //console.log(date);
              if(this.state.phone == "" || this.state.phone == null)
              {
                this.state.phone = " ";
              }
              
          let myInit =
          {
              body: 
              {
                  'command'   : 'postContacts',
                  'code'      : global.$code,
                  'id'        : longFormat.toString(),
                  'name'      : this.state.name,
                  'phone'     : this.state.phone,
                  'email'     : this.state.email,
                  'subject'   : this.state.message,
                  'created'   : date
              },
              response: true,
              convertEmptyValues:true,
              headers: {} 
          }
              this.setState({disabled:true});
           //console.log(myInit);
              ////console.log(this.state.code + "," + this.state.message + "," + this.state.type );
          API.post(apiName, path, myInit).then( response => 
          {   
              ////console.log ("response.data ===> " + response.data + "response.err ===> " + response.err);
              this.setState({disabled:false});
  
              if (response.err == null)
              {   
                  document.getElementById('note').innerHTML = "<p>Message submitted successfully</p>";               
                  this.state.note = "Message submitted successfully"
                 //console.log("postMessage - Success!!");
                  this.clearInputs();
              }
              else
              {
                  this.state.note = "Error!! Unable to submit message. Please try again.";
                  this.setState({ isLoading: false });
                  this.forceUpdate();                    
              }
  
          }).catch(error =>
          {
  
             //console.log(error.response)
              this.setState({disabled:false});
              this.setState({ isLoading: false });
          });
      }
      getRows()
      {
        var objs = global.$contact.rows
       //console.log(objs)
        const tableObjs = []
    
        const rowsObj = objs.map((item) =>
        <div>
        {
          item.type.includes("image-text")?
          (
            <div style={item.style}>
            <div dangerouslySetInnerHTML={{__html: item.title}}></div>
              <Row >
                <Col md={5}>
                  <div dangerouslySetInnerHTML={{__html: item.image}}></div>
                </Col>
                <Col md={5} className="offset-1">
                  <div dangerouslySetInnerHTML={{__html: item.text}}></div>
                </Col>
              </Row>
            </div>
          )
        :
          ('')
        }
        {
          item.type.includes("text-text")?
          (
            <div style={item.style}>
            <div dangerouslySetInnerHTML={{__html: item.title}}></div>
              <Row >
                <Col md={5}>
                  <div dangerouslySetInnerHTML={{__html: item.text1}}></div>
                </Col>
                <Col md={5} className="offset-1">
                  <div dangerouslySetInnerHTML={{__html: item.text2}}></div>
                </Col>
              </Row>
            </div>
          )
        :
          ('')
        }
        {
          item.type.includes("text-image")?
          (
            <div style={item.style}>
            <div dangerouslySetInnerHTML={{__html: item.title}}></div>
              <Row>
                <Col md={5}>
                  <div dangerouslySetInnerHTML={{__html: item.text}}></div>
                </Col>
                <Col md={5} className="offset-1">
                  <div dangerouslySetInnerHTML={{__html: item.image}}></div>
                </Col>
              </Row>
             
            </div>
          )
        :
          ('')
        }
        {
          item.type.includes("text-only")?
          (
            <div style={item.style}>
            <div dangerouslySetInnerHTML={{__html: item.title}}></div>
              <Row>
                <Col md={12}>
                  <div dangerouslySetInnerHTML={{__html: item.text}}></div>
                </Col>
              </Row>
            </div>
          )
        :
          ('')
        }
        {
          item.type.includes("image-only")?
          (
            <div style={item.style}>
            <div dangerouslySetInnerHTML={{__html: item.title}}></div>
              <Row>
                <Col md={12}>
                  <div dangerouslySetInnerHTML={{__html: item.image}}></div>
                </Col>
              </Row>
            </div>
          )
        :
          ('')
        }
        </div>
        );
    
        return(
          <div>
            {rowsObj}
          </div>
        )
    
      }
    
  }
export default  Contact;