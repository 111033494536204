import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import ReactPlayer from 'react-player';
import EmbedZoom from './EmbedZoom';

/*
            <iframe src={'https://d1y1dr9xzw7t4i.cloudfront.net/zoom/zoomframe.html'}/>
*/
const moment = require('moment-timezone');
var timer;

class SessionVideos2 extends React.Component {
    constructor(props) {
        super(props);
        //console.log(props);
        var enableembedzoom = "N";
        if (global.$session.embedzoom == null) {
            enableembedzoom = "N";
        }
        else {
            enableembedzoom = global.$session.embedzoom;
        }
        var tunroffvideotransition = false;

        if(global.$schedule.sessioninfo2.tunroffvideotransition !=null )
        {
            tunroffvideotransition = global.$schedule.sessioninfo2.tunroffvideotransition;
        }

        this.state = {
            code: global.$code,
            s3: global.$s3,
            bucket: global.$s3bucket,
            dataloaded: false,
            numPages: null,
            pageNumber: 1,
            showfeedback: props.showfeedback,
            begindate: props.session.BEGIN,
            enddate: props.session.END,
            presessionlink: props.session.PRESESSIONLINK,
            postsessionlink: props.session.POSTSESSIONLINK,
            livelink: props.session.ATTENDEELINK,
            sessionvideolink: props.session.SESSIONVIDEOLINK,
            loopvideo: false,
            embedzoom: enableembedzoom,
            tunroffvideotransition: tunroffvideotransition
        };
        //console.log(this.state)

    }

    componentWillUnmount() {
        //console.log("componentWillUnmount")
        clearInterval(timer)
    }


    render() {
       //console.log("Session Videos: render");
        return (
            this.getVideo()
        )
    }

    getAdjustedCurrentDate() {
        var mode = global.$mode;
        if (mode == null || mode == '') {
            mode = 'live';
        }

        var currentdate = moment().tz(`${global.$settings.timezone}`).format('L LTS');

        if (mode == "live") {
            //Nothing - Just return the current date
        }
        else if (mode == "preview") {
            // Make current date/time as same as session begin date/time
            currentdate = this.state.begindate;
        }
        else if (mode == "dryrun") {
            var eventdate = moment(this.state.begindate).format('L');
            var currenttime = moment().tz(`${global.$settings.timezone}`).format('LTS');
            currentdate = eventdate + " " + currenttime
        }
        else if (mode == "ondemand") {   //Add 365 days to current date

            var postdate = moment().add(365, 'days').calendar();
            var posttime = moment().tz(`${global.$settings.timezone}`).format('LTS');
            currentdate = postdate + " " + posttime;
        }

        //console.log(`SessionVideos:  current = ${currentdate}, start = ${this.state.begindate}, end = ${this.state.enddate}`);

        return currentdate;
    }

    getVideo() {


        var currentdate = this.getAdjustedCurrentDate();
        var curenttime = Date.parse(currentdate);
        var sessionstarttime = Date.parse(this.state.begindate);
        var sessionendtime = Date.parse(this.state.enddate);

        // Enable this to refresh the video dynamically when session trnsition to live
        if(this.state.tunroffvideotransition == false)
        {
            timer = setInterval(() => {
               //console.log("timer")
                var timercurrentdate = this.getAdjustedCurrentDate();
                var timercurenttime = Date.parse(timercurrentdate);
                var timersessionstarttime = Date.parse(this.state.begindate);
                var timersessionendtime = Date.parse(this.state.enddate);
    
                if (timercurenttime >= timersessionstarttime && timercurenttime <= timersessionendtime) { // Session is live
                   //console.log("Live Session:" + this.state.livelink);
                    this.setState({ dataloaded: true })
                }
            }, 60000)
        }
        
        //console.log(`SessionVideos: curenttime = ${curenttime}, start = ${sessionstarttime}, end = ${sessionendtime}` );

        if (this.state.presessionlink == "" || this.state.presessionlink == " ") {
            this.state.presessionlink = global.$session.defaultprevideo
        }

        if (this.state.postsessionlink == "" || this.state.postsessionlink == " ") {
            this.state.postsessionlink = global.$session.defaultpostvideo
        }

        /// Before Session starts
        if (this.state.presessionlink !== "" || this.state.presessionlink !== " ") {
            //console.log("Playing Presession Video: " + this.state.presessionlink);
            if (sessionstarttime > curenttime) {

                //console.log("Playing Presession Video: " + this.state.presessionlink);

                return (
                    <div>
                        <ReactPlayer
                            url={this.state.presessionlink}
                            className="sessioninfo2-react-player offset-md-3"
                            controls={true}
                            playing={true}
                            loop={this.state.loopvideo}
                            onContextMenu={e => e.preventDefault()}
                            config={{
                                file: {
                                    attributes: {
                                        controlsList: 'nodownload',
                                        disablepictureinpicture: 'true',
                                    }
                                }
                            }}
                        />
                        <br />
                    </div>
                )

                //TBD:  https://www.npmjs.com/package/react-player check responsive sample
                ////console.log("Playing Presession Video");
            }
        }

        if (this.state.postsessionlink !== "" || this.state.postsessionlink !== " ") {
            //console.log("Playing post session Video " + this.state.postsessionlink);
            if (curenttime > sessionendtime) {
                //console.log("Playing post session Video " + this.state.postsessionlink);
                return (
                    <div className="sessioninfo2-video-div">
                        <div>
                            <ReactPlayer
                                url={this.state.postsessionlink}
                                className="sessioninfo2-react-player offset-md-3"
                                controls={true}
                                playing={true}
                                loop={this.state.loopvideo}
                                onContextMenu={e => e.preventDefault()}
                                config={{
                                    file: {
                                        attributes: {
                                            controlsList: 'nodownload',
                                            disablepictureinpicture: 'true'
                                        }
                                    }
                                }}
                            />
                        </div>
                    </div>
                )
            }
        }

        ///Live Session
        if (this.state.livelink !== "" || this.state.livelink !== " ") {
            //console.log(this.state.livelink);        
            // Convert  "zoomus://zoom.us/join?confno=96465798331" === "https://zoom.us/j/94235975795" 
            //this.state.livelink = (this.state.livelink).replace(/zoomus:\/\/zoom.us\/join\?confno=/g, 'https://zoom.us/j/');

            /*
            10/17
            var livelink = (this.state.livelink).replace(/zoomus:\/\/zoom.us\/join\?confno=/g, 'https://zoom.us/j/');
            livelink = livelink.replace("&pwd", "?pwd");
            this.state.livelink = livelink
            */

            //console.log(this.state.livelink);

            if (curenttime >= sessionstarttime && curenttime <= sessionendtime) { // Session is live
                //.log("Live Session:" + this.state.livelink);
                return (
                    <div>
                        {this.showLiveVideos()}
                    </div>
                )
            }
        }
    }

    showLiveVideos() {
        //console.log("sessionvideo = " + this.state.sessionvideolink, "livelink = " + this.state.livelink)

        if (this.state.sessionvideolink == "" || this.state.sessionvideolink == " ") {
            this.state.sessionvideolink = "empty"
        }

        if (this.state.livelink == "" || this.state.livelink == " ") {
            this.state.livelink = "empty";
        }

        //console.log("sessionvideo = " + this.state.sessionvideolink, "livelink = " + this.state.livelink)
        //console.log(this.state);
        if (this.state.sessionvideolink !== "empty") {
            if (this.state.livelink !== "empty") //both session videos and livevideos will exist
            {
                return (
                    <div className="sessioninfo2-video-div">
                        <ReactPlayer
                            url={this.state.sessionvideolink}
                            controls={true}
                            className="sessioninfo2-react-player"
                            playing={true}
                            loop={this.state.loopvideo}
                            onContextMenu={e => e.preventDefault()}
                            config={{
                                file: {
                                    attributes: {
                                        controlsList: 'nodownload',
                                        disablepictureinpicture: 'true'
                                    }
                                }
                            }}
                        />
                        {
                            this.state.livelink.includes("zoom") ?
                                (
                                    <div>
                                        <Row>
                                            <Col>
                                                <div style={this.state.loginbuttonstyle} className="zoom-logo">
                                                    {/*<div  dangerouslySetInnerHTML={{__html: global.$session.zoomlogo}}></div>*/}
                                                    <br />
                                                    <a href={this.state.livelink} target="_blank" style={{ color: 'white' }}><Button type="submit" className={" btn btn-" + global.$session.styles.loginbuttonbgcolor} style={this.state.loginbuttonstyle}><i className='fa fa-video' style={{ fontSize: "15px", color: "white", background: "#129CF3", padding: "7px", borderRadius: "12px", marginRight: "15px" }}></i>Join</Button></a>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                )
                                :
                                ('')
                        }
                        {
                            this.state.livelink.includes("teams") ?
                                (
                                    <div>
                                        <Row>
                                            <Col>
                                                <div style={this.state.loginbuttonstyle}>
                                                    {/*<div dangerouslySetInnerHTML={{__html: global.$session.zoomlogo}}></div>*/}
                                                    <br />
                                                    <a href={this.state.livelink} target="_blank" style={{ color: 'white' }}><Button type="submit" className={" btn btn-" + global.$session.styles.loginbuttonbgcolor} style={this.state.loginbuttonstyle}><i className='fa fa-video' style={{ fontSize: "15px", color: "white", background: "#129CF3", padding: "7px", borderRadius: "12px", marginRight: "15px" }}></i>Join Meeting</Button></a>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                )
                                :
                                ('')
                        }

                        {
                            this.state.livelink.includes("vimeo") ?
                                (
                                    <div>
                                        <div style={this.state.loginbuttonstyle}>
                                            <ReactPlayer
                                                url={this.state.livelink}
                                                controls={true}
                                                className="sessioninfo2-react-player"
                                                playing={true}
                                                loop={this.state.loopvideo}
                                                onContextMenu={e => e.preventDefault()}
                                                config={{
                                                    file: {
                                                        attributes: {
                                                            controlsList: 'nodownload',
                                                            disablepictureinpicture: 'true'
                                                        }
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                )
                                :
                                (
                                    ''
                                )
                        }
                        {
                            this.state.livelink.includes("goto") ?
                                (

                                    <div>
                                        <Row>
                                            <Col md={12}>
                                                <div style={this.state.loginbuttonstyle} className="logmein-logo">
                                                    {/*<div dangerouslySetInnerHTML={{ __html: global.$session.logmeinlogo }}></div>*/}
                                                    <br />
                                                    <a href={this.state.livelink} target="_blank" style={{ color: 'white' }}><Button type="submit" className={"btn btn-" + global.$session.styles.loginbuttonbgcolor} style={this.state.loginbuttonstyle}><i className='fa fa-video' style={{ fontSize: "15px", color: "white", background: "#129CF3", padding: "7px", borderRadius: "12px", marginRight: "15px" }}></i>Join</Button></a>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>

                                )
                                :
                                ('')
                        }
                        {
                            this.state.livelink.includes("youtube") ?
                                (

                                    <div>
                                        <ReactPlayer
                                            url={this.state.livelink}
                                            controls={true}
                                            className="sessioninfo2-react-player"
                                            playing={true}
                                            loop={this.state.loopvideo}
                                            onContextMenu={e => e.preventDefault()}
                                            config={{
                                                file: {
                                                    attributes: {
                                                        controlsList: 'nodownload',
                                                        disablepictureinpicture: 'true'
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                )
                                :
                                ('')
                        }
                        {
                            this.state.livelink.includes("amazonaws.com") ?
                                (
                                    <div>
                                        <ReactPlayer
                                            url={this.state.livelink}
                                            controls={true}
                                            className="sessioninfo2-react-player"
                                            playing={true}
                                            loop={this.state.loopvideo}
                                            onContextMenu={e => e.preventDefault()}
                                            config={{
                                                file: {
                                                    attributes: {
                                                        controlsList: 'nodownload',
                                                        disablepictureinpicture: 'true'
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                )
                                :
                                (
                                    ''
                                )
                        }
                        {
                            this.state.livelink.includes("livestream") ?
                                (
                                    <div>
                                        <div style={this.state.loginbuttonstyle}>
                                            <iframe
                                                src={this.state.livelink}
                                                className="livestream-iframe"
                                            />
                                        </div>
                                    </div>
                                )
                                :
                                ('')
                        }
                    </div>
                )
            }
            else // only session videos will exist
            {
                return (
                    <div className="sessioninfo2-video-div">
                        <ReactPlayer
                            url={this.state.sessionvideolink}
                            controls={true}
                            className="sessioninfo2-react-player"
                            playing={true}
                            loop={this.state.loopvideo}
                            onContextMenu={e => e.preventDefault()}
                            config={{
                                file: {
                                    attributes: {
                                        controlsList: 'nodownload',
                                        disablepictureinpicture: 'true'
                                    }
                                }
                            }}
                        />
                    </div>)
            }
            //console.log("sessionvideo = " + this.state.sessionvideolink, "livelink = " + this.state.livelink)
        }
        else {  //only live videos will exist
            //console.log("sessionvideo = " + this.state.sessionvideolink, "livelink = " + this.state.livelink)
            return (
                <div className="sessioninfo2-video-div">
                    {
                        this.state.livelink.includes("zoom") ?
                            (
                                <div>
                                    <Row>
                                        <Col>
                                            <div style={this.state.loginbuttonstyle} className="zoom-logo">
                                                {/*<a href={this.state.livelink} target="_blank" style={{ color: 'white' }}><Button type="submit" className={" btn btn-" + global.$session.styles.loginbuttonbgcolor} style={this.state.loginbuttonstyle}><i className='fa fa-video' style={{ fontSize: "15px", color: "white", background: "#129CF3", padding: "7px", borderRadius: "12px", marginRight: "15px" }}></i>Join</Button></a>*/}
                                                {
                                                    this.state.embedzoom.includes("Y") ?
                                                        (
                                                            <EmbedZoom zoomurl={this.state.livelink} component={"session-layout2"} />
                                                        )
                                                        :
                                                        (
                                                            <div>
                                                                <div dangerouslySetInnerHTML={{ __html: global.$session.zoomlogo }}></div>
                                                                <br />
                                                                <a href={this.state.livelink} target="_blank" style={{ color: 'white' }}><Button type="submit" className={" btn btn-" + global.$session.styles.loginbuttonbgcolor} style={this.state.loginbuttonstyle}><i className='fa fa-video' style={{ fontSize: "15px", color: "white", background: "#129CF3", padding: "7px", borderRadius: "12px", marginRight: "15px" }}></i>Join</Button></a>
                                                            </div>
                                                        )
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            )
                            :
                            ('')
                    }
                    {
                        this.state.livelink.includes("teams") ?
                            (
                                <div>
                                    <Row>
                                        <Col style={{ textAlign: "center" }}>
                                            <div style={this.state.loginbuttonstyle}>
                                                {/*<div dangerouslySetInnerHTML={{__html: global.$session.zoomlogo}}></div>*/}
                                                <br />
                                                <a href={this.state.livelink} target="_blank" style={{ color: 'white' }}><Button type="submit" className={" btn btn-" + global.$session.styles.loginbuttonbgcolor} style={this.state.loginbuttonstyle}><i className='fa fa-video' style={{ fontSize: "15px", color: "white", background: "#129CF3", padding: "7px", borderRadius: "12px", marginRight: "15px" }}></i>Join Meeting</Button></a>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            )
                            :
                            ('')
                    }
                    {
                        this.state.livelink.includes("vimeo") ?
                            (
                                <div>
                                    <div style={this.state.loginbuttonstyle}>
                                        <ReactPlayer
                                            url={this.state.livelink}
                                            controls={true}
                                            className="sessioninfo2-react-player"
                                            playing={true}
                                            loop={this.state.loopvideo}
                                            onContextMenu={e => e.preventDefault()}
                                            config={{
                                                file: {
                                                    attributes: {
                                                        controlsList: 'nodownload',
                                                        disablepictureinpicture: 'true'
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            )
                            :
                            ('')
                    }
                    {
                        this.state.livelink.includes("livestream") ?
                            (
                                <div>
                                    <div style={this.state.loginbuttonstyle}>
                                        <iframe
                                            src={this.state.livelink}
                                            className="livestream-iframe"
                                        />
                                    </div>
                                </div>
                            )
                            :
                            ('')
                    }
                    {
                        this.state.livelink.includes("goto") ?
                            (

                                <div>
                                    <Row>
                                        <Col md={12}>
                                            <div style={this.state.loginbuttonstyle} className="logmein-logo">
                                                <div dangerouslySetInnerHTML={{ __html: global.$session.logmeinlogo }}></div>
                                                <br />
                                                <a href={this.state.livelink} target="_blank" style={{ color: 'white' }}><Button type="submit" className={"btn btn-" + global.$session.styles.loginbuttonbgcolor} style={this.state.loginbuttonstyle}><i className='fa fa-video' style={{ fontSize: "15px", color: "white", background: "#129CF3", padding: "7px", borderRadius: "12px", marginRight: "15px" }}></i>Join</Button></a>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                            )
                            :
                            ('')
                    }
                    {
                        this.state.livelink.includes("youtube") ?
                            (

                                <div>
                                    <div style={this.state.loginbuttonstyle}>
                                        <ReactPlayer
                                            url={this.state.livelink}
                                            controls={true}
                                            className="sessioninfo2-react-player"
                                            playing={true}
                                            loop={this.state.loopvideo}
                                            onContextMenu={e => e.preventDefault()}
                                            config={{
                                                file: {
                                                    attributes: {
                                                        controlsList: 'nodownload',
                                                        disablepictureinpicture: 'true'
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            )
                            :
                            ('')
                    }

                    {
                        this.state.livelink.includes("amazonaws.com") ?
                            (
                                <div>
                                    {console.log(this.state.livelink)}
                                    <ReactPlayer
                                        url={this.state.livelink}
                                        controls={true}
                                        className="sessioninfo2-react-player"
                                        playing={true}
                                        loop={this.state.loopvideo}
                                        onContextMenu={e => e.preventDefault()}
                                        config={{
                                            file: {
                                                attributes: {
                                                    controlsList: 'nodownload',
                                                    disablepictureinpicture: 'true',
                                                }
                                            }
                                        }}
                                    />
                                </div>
                            )
                            :
                            ('')
                    }
                </div>
            )
        }
    }
}
export default SessionVideos2;


