// const { getUserProfile } = require("../../Common/UserFunctions");
import UserFunctions from "../../Common/UserFunctions";

function validateTermsAndConditions(formtype) {

    //console.log('validateTermsAndConditions' + formtype)
    var terms1 = global.$terms1;
    var terms2 = global.$terms2;
    var terms3 = global.$terms3;

    // if(formtype == "attendee")
    // {
    //     terms1 = global.$attendeeterms1
    //     terms2 = global.$attendeeterms2
    //     terms3 = global.$attendeeterms3
    // }
    // else if(formtype == "sponsor")
    // {
    //     terms1 = global.$sponsorterms1
    //     terms2 = global.$sponsorterms2
    //     terms3 = global.$sponsorterms3
    // }
    // else if(formtype == "exhibitor")
    // {
    //     terms1 = global.$exhibitorterms1
    //     terms2 = global.$exhibitorterms2
    //     terms3 = global.$exhibitorterms3
    // }

    /*
    console.log(terms1, global.$terms1status)
    console.log(terms2, global.$terms2status)
    console.log(terms3, global.$terms3status)
    */

    /*
    console.log(global.$terms1)
    console.log(global.$terms2)
    console.log(global.$terms3)

    console.log(`${global.$terms1status},${global.$terms2status},${global.$terms3status}`)
    */

    /*
    // If term1 is required
    if (terms1 != null && terms1.required == true && terms2 != null && terms2.required == false && terms3 != null && terms3.required == false) {
        if (global.$terms1status == true) { // this global variable tells that user checked the terms or not
            return true;
        }
        else {
            return false;
        }
    } // if terms1 and 2 aer required
    else if (terms1 != null && terms1.required == true && terms2 != null && terms2.required == true && terms3 != null && terms3.required == false) {
        if (global.$terms1status == true && global.$terms2status == true) {
            return true;
        }
        else {
            return false;
        }
    } // If all 3 terms are required
    else if (terms1 != null && terms1.required == true && terms2 != null && terms2.required == true && terms3 != null && terms3.required == true) {
        if (global.$terms1status == true && global.$terms2status == true && global.$terms3status == true) {
            return true;
        }
        else {
            return false;
        }
    } // If all are optional
    //else if (terms1 != null && terms1.required == false && terms2 != null && terms2.required == false && terms3 != null && terms3.required == false) {
    else {
        return true;
    }
    */

    var term1result = true, term2result = true, term3result = true

    if (terms1 != null && terms1.required == true){
        term1result = global.$terms1status
    }

    if (terms2 != null && terms2.required == true){
        term2result = global.$terms2status
    }

    if (terms3 != null && terms3.required == true){
        term3result = global.$terms3status
    }
    console.log(term1result, term2result, term3result);

    return term1result && term2result && term3result

}
function validateEmailFormat(email) {

    const regexformat = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email != null && email != "") {
        return regexformat.test(email); // return true/false;
    }
    else {
        return false;
    }
}

function validateQuestions() {
    var validatequestions = true;

    // this global variable gives all the information about questions
    if (global.$regquestionsinfo != null) {
        // check question is required or not from config
        if (global.$regquestionsinfo.question1required == true) {
            // question should not be empty and answer should not equal to "Select"
            if (global.$regquestionsinfo.question1 == "" || global.$regquestionsinfo.question1 == "Select") {
                validatequestions = false;
            }
        }
        if (global.$regquestionsinfo.question2required == true) {
            if (global.$regquestionsinfo.question2 == "" || global.$regquestionsinfo.question2 == "Select") {
                validatequestions = false;
            }
        }
        if (global.$regquestionsinfo.question3required == true) {
            if (global.$regquestionsinfo.question3 == "" || global.$regquestionsinfo.question3 == "Select") {
                validatequestions = false;
            }
        }
        if (global.$regquestionsinfo.question4required == true) {
            if (global.$regquestionsinfo.question4 == "" || global.$regquestionsinfo.question4 == "Select") {
                validatequestions = false;
            }
        }
        if (global.$regquestionsinfo.question5required == true) {
            if (global.$regquestionsinfo.question5 == "" || global.$regquestionsinfo.question5 == "Select") {
                validatequestions = false;
            }
        }
        if (global.$regquestionsinfo.question6required == true) {
            if (global.$regquestionsinfo.question6 == "" || global.$regquestionsinfo.question6 == "Select") {
                validatequestions = false;
            }
        }
        if (global.$regquestionsinfo.question7required == true) {
            if (global.$regquestionsinfo.question7 == "" || global.$regquestionsinfo.question7 == "Select") {
                validatequestions = false;
            }
        }
        if (global.$regquestionsinfo.question8required == true) {
            if (global.$regquestionsinfo.question8 == "" || global.$regquestionsinfo.question8 == "Select") {
                validatequestions = false;
            }
        }
    }
    return validatequestions;
}

/*
async function isUserAlreadyRegistered(userid) {
    var getuser = await UserFunctions.getUserProfile(userid);
    // User already registered
    if (getuser.data != null && getuser.length != 0 && getuser.data.Item != null) {
        return false;
    }
    // User is not registered
    else {
        return true;
    }
}
*/

async function isUserAlreadyRegistered(userid) {
    var getuser = await UserFunctions.getUserProfile(userid);
    // User already registered
    if (getuser.data != null && getuser.length != 0 && getuser.data.Item != null) {
        return true;
    }
    else {
        // User is not registered
        return false;
    }
}

function validateFields(section, userinfo, usertype, form) {
    var validated = true;
    if (form[usertype][section].fname != null) {
        if (form[usertype][section].fname.required == true) {

            if (userinfo.fname.trim() == "") {
                validated = false;
            }
        }
        else {
            if (userinfo.fname.trim() == "") {
                userinfo.fname = " ";
            }
        }
    }
    if (form[usertype][section].lname != null) {

        if (form[usertype][section].lname.required == true) {
            if (userinfo.lname.trim() == "") {
                validated = false;
            }
        }
        else {
            if (userinfo.lname.trim() == "") {
                userinfo.lname = " ";
            }
        }
    }
    if (form[usertype][section].phone != null) {

        if (form[usertype][section].phone.required == true) {
            if (userinfo.phone == "") {
                validated = false;
            }
        }
        else {
            if (userinfo.phone == "") {
                userinfo.phone = " ";
            }
        }
    }
    if (form[usertype][section].memberid != null) {

        if (form[usertype][section].memberid.required == true) {
            if (userinfo.memberid == "") {
                validated = false;
            }
        }
        else {
            if (userinfo.memberid == "") {
                userinfo.memberid = " ";
            }
        }
    }
    if (form[usertype][section].company != null) {
        if (form[usertype][section].company.required == true) {
            if (userinfo.company == "") {
                validated = false;
            }
        }
        else {
            if (userinfo.company == "") {
                userinfo.company = " ";
            }
        }
    }
    if (form[usertype][section].title != null) {
        if (form[usertype][section].title.required == true) {
            if (userinfo.title == "") {
                validated = false;
            }
        }
        else {
            if (userinfo.title == "") {
                userinfo.title = " ";
            }
        }
    }
    if (form[usertype][section].officephone != null) {
        if (form[usertype][section].officephone.required == true) {
            if (userinfo.officephone == "") {
                validated = false;
            }
        }
        else {
            if (userinfo.officephone == "") {
                userinfo.officephone = " ";
            }
        }
    }
    if (form[usertype][section].street != null) {
        if (form[usertype][section].street.required == true) {
            if (userinfo.street == "") {
                validated = false;
            }
        }
        else {
            if (userinfo.street == "") {
                userinfo.street = " ";
            }
        }
    }
    if (form[usertype][section].city != null) {
        if (form[usertype][section].city.required == true) {
            if (userinfo.city == "") {
                validated = false;
            }
        }
        else {
            if (userinfo.city == "") {
                userinfo.city = " ";
            }
        }
    }
    if (form[usertype][section].state != null) {
        if (form[usertype][section].state.required == true) {
            if (userinfo.state == "") {
                validated = false;
            }
        }
        else {
            if (userinfo.state == "") {
                userinfo.state = " ";
            }
        }
    }
    if (form[usertype][section].zip != null) {
        if (form[usertype][section].zip.required == true) {
            if (userinfo.zip == "") {
                validated = false;
            }
        }
        else {
            if (userinfo.zip == "") {
                userinfo.zip = " ";
            }
        }
    }
    if (form[usertype][section].country != null) {
        if (form[usertype][section].country.required == true) {
            if (userinfo.country == "") {
                validated = false;
            }
        }
        else {
            if (userinfo.country == "") {
                userinfo.country = " ";
            }
        }
    }
    if (form[usertype][section].location != null) {
        if (form[usertype][section].location.required == true) {
            if (userinfo.location == "") {
                validated = false;
            }
        }
        else {
            if (userinfo.location == "") {
                userinfo.location = " ";
            }
        }
    }
    if (form[usertype][section].linkedin != null) {
        if (form[usertype][section].linkedin.required == true) {
            if (userinfo.linkedin == "") {
                validated = false;
            }
        }
        else {
            if (userinfo.linkedin == "") {
                userinfo.linkedin = " ";
            }
        }
    }
    if (form[usertype][section].facebook != null) {
        if (form[usertype][section].facebook.required == true) {
            if (userinfo.facebook == "") {
                validated = false;
            }
        }
        else {
            if (userinfo.facebook == "") {
                userinfo.facebook = " ";
            }
        }
    }
    if (form[usertype][section].twitter != null) {
        if (form[usertype][section].twitter.required == true) {
            if (userinfo.twitter == "") {
                validated = false;
            }
        }
        else {
            if (userinfo.twitter == "") {
                userinfo.twitter = " ";
            }
        }
    }
    if (form[usertype][section].myexpertise != null) {
        if (form[usertype][section].myexpertise.required == true) {
            if (userinfo.myexpertise == "") {
                validated = false;
            }
        }
        else {
            if (userinfo.myexpertise == "") {
                userinfo.myexpertise = " ";
            }
        }
    }
    if (form[usertype][section].myinterests != null) {
        if (form[usertype][section].myinterests.required == true) {
            if (userinfo.myinterests == "") {
                validated = false;
            }
        }
        else {
            if (userinfo.myinterests == "") {
                userinfo.myinterests = " ";
            }
        }
    }
    if (form[usertype][section].data1 != null) {
        if (form[usertype][section].data1.required == true) {
            if (userinfo.data1 == "") {
                validated = false;
            }
        }
        else {
            if (userinfo.data1 == "") {
                userinfo.data1 = " ";
            }
        }
    }
    if (form[usertype][section].data2 != null) {
        if (form[usertype][section].data2.required == true) {
            if (userinfo.data2 == "") {
                validated = false;
            }
        }
        else {
            if (userinfo.data2 == "") {
                userinfo.data2 = " ";
            }
        }
    }
    if (form[usertype][section].data3 != null) {
        if (form[usertype][section].data3.required == true) {
            if (userinfo.data3 == "") {
                validated = false;
            }
        }
        else {
            if (userinfo.data3 == "") {
                userinfo.data3 = " ";
            }
        }
    }
    if (form[usertype][section].data4 != null) {
        if (form[usertype][section].data4.required == true) {
            if (userinfo.data4 == "") {
                validated = false;
            }
        }
        else {
            if (userinfo.data4 == "") {
                userinfo.data4 = " ";
            }
        }
    }
    if (form[usertype][section].data5 != null) {
        if (form[usertype][section].data5.required == true) {
            if (userinfo.data5 == "") {
                validated = false;
            }
        }
        else {
            if (userinfo.data5 == "") {
                userinfo.data5 = " ";
            }
        }
    }
    //console.log(validated);
    return validated;
}

function validateFormFields(user, form, userinfo) {
    var usertype = "";
    
    if (user == "attendee") {
        usertype = "attendees";
    }
    if (user == "exhibitor") {
        usertype = "exhibitor";
    }
    else if (user == "guest") {
        usertype = "guests";
    }
    else if (user == "tab1") {
        usertype = "tab1";
    }
    else if (user == "tab2") {
        usertype = "tab2";
    }
    else if (user == "tab3") {
        usertype = "tab3";
    }
    else if (user == "tab4") {
        usertype = "tab4";
    }
    else if (user == "tab5") {
        usertype = "tab5";
    }

    var validated = true;
    
    // validate personal section form fileds in reg form
    if (form[usertype]["personal"].required == true) {
        validated = validateFields("personal", userinfo, usertype, form);
        //console.log(validated);
    }
    else {
        return validated;
    }

    // Validate work section fields in reg form
    if (validated == true) {
        if (form[usertype]["work"].required == true) {
            validated = validateFields("work", userinfo, usertype, form);
            //console.log(validated);
        }
    }
    else {
        return validated;
    }

    // Validate contact section fields in reg form
    if (validated == true) {
        if (form[usertype]["contact"].required == true) {
            validated = validateFields("contact", userinfo, usertype, form);
            //console.log(validated);
        }
    }
    else {
        return validated;
    }

    // Validate social section fields in reg form
    if (validated == true) {
        if (form[usertype]["social"].required == true) {
            validated = validateFields("social", userinfo, usertype, form);
            //console.log(validated);
        }
    }
    else {
        return validated;
    }

    // Validate other section fields in reg form
    if (validated == true) {
        if (form[usertype]["other"].required == true) {
            validated = validateFields("other", userinfo, usertype, form);
            //console.log(validated);
        }
    }
    else {
        return validated;
    }

    return validated;
}
function validateAttendeeAndGuestEmail(attendeemail, guestmail) {
    console.log(attendeemail,guestmail);
    if (attendeemail === guestmail) {
        return false;
    }
    else {
        return true;
    }
}

export default {
    validateTermsAndConditions,
    validateEmailFormat,
    validateQuestions,
    isUserAlreadyRegistered,
    validateFormFields,
    validateAttendeeAndGuestEmail
}