/* eslint-disable */
import React, { Component } from 'react';
import { API, Analytics } from 'aws-amplify';
import { Col, Row } from 'reactstrap';
import ConversationsItemView from './ConversationsItemView';

var timer;

class ConversationsView extends React.Component {
  constructor(props) {

    super(props);

    this.state = {
      code: global.$code,
      conversations: [],
      dataloaded: false,
      messagesstyle: global.$conversations.styles.messages,
    };

    this.loadConversations = this.loadConversations.bind(this);
    this.reloadHandler = this.reloadHandler.bind(this);
    timer = setInterval(() => {
      this.getConversations()
    }, 30000)
  }

  componentDidUpdate() {

    // There is a new message in the state, scroll to bottom of list
    //const objDiv = document.getElementById('messageList');
    //objDiv.scrollTop = objDiv.scrollHeight;
  }

  componentWillUnmount() {
    //console.log("componentWillUnmount")
    clearInterval(timer)
}
  loadConversations() {
    this.getConversations();
  }

  reloadHandler()
  {
    this.getConversations()
  }

  render() {
    //console.log("conversationsView: render");
    if (this.state.dataloaded) {

      const conversations = this.state.conversations.map((conversation, i) => {
        return (
          <ConversationsItemView
            conversations={conversation}
            fromMe='false'
            reloadHandler={this.reloadHandler}
          />
        );
      });

      return (
        <div>
          {/*<ConversationsInput code= {this.state.code} receiverid= {this.props.receiverid} onSend={this.loadMessages} />*/}
          <div className='messages conversations-list' id='messageList' style={this.state.messagesstyle}>
            {conversations}
          </div>
        </div>
      )
    }
    else {
      this.getConversations();
      return (
        <div className='messages' id='messageList'>
        </div>
      )
    }
  }

  getConversations() {
    let apiName = 'ChatService';
    let path = ''; //replace this with the path you have configured on your API
    let myInit = {
      body:
      {
        'code': this.state.code,
        'command': 'getConversations',
        'p1': global.$userid,
      },
      response: true,
      convertEmptyValues: true,
      headers: {}
    }

    //console.log(myInit);
    //console.log('ChatMessages:getMessages()');

    API.post(apiName, path, myInit).then(response => {
      //console.log(response);

      if (response.err == null && response.data != null) {
        //console.log(response.data.Items);
        var conversations = this.getChatTableObjects(response.data.Items);
        global.$userconversations = conversations;
        this.setState({
          conversations: conversations,
          dataloaded: true
        });
      }
      else {
        //console.log(response.err);
      }
    }
    ).catch(error => {
      //console.log(error)
    });
  }
  getChatTableObjects(objs) {
   //console.log(objs);

    const tableObjs = []

    for (var i = 0; i < objs.length; i++) {
      var chatObj = objs[i]

      //console.log(chatObj)

      var newObj = Object()

      newObj.cid = chatObj.cid.S
      newObj.cstatus = chatObj.cstatus.S
      newObj.lmsg = chatObj.lmsg.S
      newObj.lupdated = chatObj.lupdated.N
      newObj.mcount = chatObj.mcount.N
      newObj.p1 = chatObj.p1.S
      newObj.p1name = chatObj.p1name.S
      newObj.p1newmsg = chatObj.p1newmsg.S
      newObj.p2 = chatObj.p2.S
      newObj.p2name = chatObj.p2name.S
      newObj.p2newmsg = chatObj.p2newmsg.S

      tableObjs.push(newObj)
    }

   //console.log(tableObjs)

    return tableObjs
  }
} //Class

export default ConversationsView;