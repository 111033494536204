import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';
import ScheduleSpeakers from './ScheduleSpeakers';
import ReactPlayer from 'react-player';
import EmbedZoom from '../SessionInfo2/EmbedZoom';
import Icon from '@mdi/react';
import { API } from 'aws-amplify';
import { mdiVideo } from '@mdi/js';

var serviceNames = require('../ServiceNameMapper');

const moment = require('moment-timezone');
var seektoseconds = '0';
class SessionVideos extends React.Component {
    constructor(props) {
        super(props);
        //console.log(props);
        //console.log(global);
        var enableembedzoom = "N";
        if (global.$session.embedzoom == null) {
            enableembedzoom = "N";
        }
        else {
            enableembedzoom = global.$session.embedzoom;
        }

        var videocontrolsforliverecorded = '';
        if (global.$session.videocontrolsforliverecorded == null) {
            videocontrolsforliverecorded = "show";
        }
        else {
            videocontrolsforliverecorded = global.$session.videocontrolsforliverecorded
        }

        this.state = {
            code: global.$code,
            s3: global.$s3,
            bucket: global.$s3bucket,
            dataloaded: false,
            numPages: null,
            pageNumber: 1,
            showfeedback: props.showfeedback,
            begindate: props.session.BEGIN,
            enddate: props.session.END,
            presessionlink: props.session.PRESESSIONLINK,
            postsessionlink: props.session.POSTSESSIONLINK,
            livelink: props.session.ATTENDEELINK,
            sessionvideolink: props.session.SESSIONVIDEOLINK,
            loopvideo: false,
            videocontrolsforliverecorded: videocontrolsforliverecorded,
            embedzoom: enableembedzoom
        };
        //console.log(this.state)
        //this.e.seekTo(5);
    }

    render() {

        if(this.state.dataloaded == false) {
            return (
                <div className="loading-text">Loading....</div>
            )
        }
        else {
            return (
                this.getVideo()
            )            
        }
    }

    componentDidMount() {
        if (this.state.dataloaded == false) {
            this.loadData();
        }
    }
    
    getAdjustedCurrentDate() {
        var mode = global.$mode;
        if (mode == null || mode == '') {
            mode = 'live';
        }

        var currentdate = moment().tz(`${global.$settings.timezone}`).format('L LTS');

        if (mode == "live") {
            //Nothing - Just return the current date
        }
        else if (mode == "preview") {
            // Make current date/time as same as session begin date/time
            currentdate = this.state.begindate;
        }
        else if (mode == "dryrun") {
            var eventdate = moment(this.state.begindate).format('L');
            var currenttime = moment().tz(`${global.$settings.timezone}`).format('LTS');
            currentdate = eventdate + " " + currenttime
        }
        else if (mode == "ondemand") {   //Add 365 days to current date

            var postdate = moment().add(365, 'days').calendar();
            var posttime = moment().tz(`${global.$settings.timezone}`).format('LTS');
            currentdate = postdate + " " + posttime;
        }

        //console.log(`SessionVideos:  current = ${currentdate}, start = ${this.state.begindate}, end = ${this.state.enddate}`);

        return currentdate;
    }

    async loadData() {
        var presessionlink = this.state.presessionlink
        var postsessionlink = this.state.postsessionlink;
        var currentdate = this.getAdjustedCurrentDate();
        var curenttime = Date.parse(currentdate);
        var sessionstarttime = Date.parse(this.state.begindate);
        var sessionendtime = Date.parse(this.state.enddate);

        if (this.state.presessionlink === "" || this.state.presessionlink === " ") {
            const defaultPresessionlink = global.$session.defaultprevideo;
            if (defaultPresessionlink && defaultPresessionlink.trim()!== "") {
                presessionlink = defaultPresessionlink;
            }
        }

        if (this.state.postsessionlink === "" || this.state.postsessionlink === " ") {
            const defaultPostSessionLink = global.$session.defaultpostvideo;
            if (defaultPostSessionLink && defaultPostSessionLink.trim()!== "") {
                postsessionlink = defaultPostSessionLink;
            }
        }

        if (this.state.postsessionlink.trim() !== "" && !this.state.postsessionlink.startsWith('https')) {
            var signedUrl = await this.generateSignedUrl(this.state.postsessionlink);
            postsessionlink = signedUrl
        }

        
        this.setState({
            dataloaded: true,
            curenttime: curenttime,
            sessionstarttime: sessionstarttime,
            sessionendtime: sessionendtime,
            presessionlink: presessionlink,
            postsessionlink: postsessionlink,
        })
    }

    getVideo() {
        /*
        console.log(this.state.postsessionlink);
        var currentdate = this.getAdjustedCurrentDate();
        var curenttime = Date.parse(currentdate);
        var sessionstarttime = Date.parse(this.state.begindate);
        var sessionendtime = Date.parse(this.state.enddate);

        //console.log(`SessionVideos: curenttime = ${curenttime}, start = ${sessionstarttime}, end = ${sessionendtime}` );

        if (this.state.presessionlink == "" || this.state.presessionlink == " ") {
            this.state.presessionlink = global.$session.defaultprevideo
        }

        if (this.state.postsessionlink == "" || this.state.postsessionlink == " ") {
            this.state.postsessionlink = global.$session.defaultpostvideo
        }

        if(this.state.postsessionlink.startsWith('https') == false) {
            console.log(this.state.postsessionlink);
            var signedUrl = this.generateSignedUrl(this.state.postsessionlink);
            console.log(signedUrl);
            this.state.postsessionlink = signedUrl
        }
        */

        /// Before Session starts
        if (this.state.presessionlink !== "" || this.state.presessionlink !== " ") {
            if (this.state.sessionstarttime > this.state.curenttime) {

                return (
                    <div>
                        <ReactPlayer
                            url={this.state.presessionlink}
                            className="react-player"
                            controls={true}
                            playing={true}
                            loop={this.state.loopvideo}
                            onContextMenu={e => e.preventDefault()}
                            config={{
                                file: {
                                    attributes: {
                                        controlsList: 'nodownload',
                                        disablepictureinpicture: 'true',
                                    }
                                }
                            }}
                        />
                        <br />
                    </div>
                )

                //TBD:  https://www.npmjs.com/package/react-player check responsive sample
                ////console.log("Playing Presession Video");
            }
        }

        if (this.state.postsessionlink !== "" || this.state.postsessionlink !== " ") {
            if (this.state.curenttime > this.state.sessionendtime) {
                return (
                    <div>
                        <div>
                            <ReactPlayer
                                url={this.state.postsessionlink}
                                className="react-player"
                                controls={true}
                                playing={true}
                                loop={this.state.loopvideo}
                                onContextMenu={e => e.preventDefault()}
                                config={{
                                    file: {
                                        attributes: {
                                            controlsList: 'nodownload',
                                            disablepictureinpicture: 'true'
                                        }
                                    }
                                }}
                            />
                        </div>
                    </div>
                )
            }
        }

        ///Live Session
        if (this.state.livelink !== "" || this.state.livelink !== " ") {
            //console.log(this.state.livelink);        
            // Convert  "zoomus://zoom.us/join?confno=96465798331" === "https://zoom.us/j/94235975795" 
            //this.state.livelink = (this.state.livelink).replace(/zoomus:\/\/zoom.us\/join\?confno=/g, 'https://zoom.us/j/');

            /*
            10/17
            var livelink = (this.state.livelink).replace(/zoomus:\/\/zoom.us\/join\?confno=/g, 'https://zoom.us/j/');
            livelink = livelink.replace("&pwd", "?pwd");
            this.state.livelink = livelink
            */

            //console.log(this.state.livelink);

            if (this.state.curenttime >= this.state.sessionstarttime && this.state.curenttime <= this.state.sessionendtime) { // Session is live

                //console.log("Live Session:" + this.state.livelink);
                return (
                    <div>
                        {this.showLiveVideos()}
                    </div>
                )
            }
        }
    }

    async generateSignedUrl(postsessionlink) {
        console.log('generateSignedUrl')
        let apiName = serviceNames.getEventPlannerService();
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'command': 'generateSignedUrl',
                'uploadkey': `event360/${this.state.code}/upload/documents/${postsessionlink}`,
                'bucket': 'wesicore',
                'expiresIn': 60 // 1 mins
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //console.log(myInit);  

        var result = await API.post(apiName, path, myInit)
        //console.log(result);
        
        if(result.data.status == true) {
            return result.data.url
        }

        return postsessionlink;
    }

    showLiveVideos() {

        var styles = global.$session.styles;
        //console.log(this.state.livelink);
        var currenttimeintimezone = moment().tz(global.$settings.timezone).format('MM/DD/YYYY hh:mm:ss A');
        //console.log(currenttimeintimezone);

        var sessionbegintime = moment(this.props.session.BEGIN).format('MM/DD/YYYY hh:mm:ss A');
        //console.log(sessionbegintime);

        var a = moment(currenttimeintimezone);
        var b = moment(sessionbegintime);

        //console.log(a.diff(b, 'minutes'));
        //console.log(a.diff(b, 'seconds'));
        //console.log(a.diff(b, 'hours'));
        seektoseconds = a.diff(b, 'seconds');

        if (seektoseconds == null) {
            seektoseconds = "0"
        }

        //console.log("sessionvideo = " + this.state.sessionvideolink, "livelink = " + this.state.livelink)

        if (this.state.sessionvideolink == "" || this.state.sessionvideolink == " ") {
            this.state.sessionvideolink = "empty"
        }

        if (this.state.livelink == "" || this.state.livelink == " ") {
            this.state.livelink = "empty";
        }

        //console.log("sessionvideo = " + this.state.sessionvideolink, "livelink = " + this.state.livelink)

        if (this.state.sessionvideolink !== "empty") {
            if (this.state.livelink !== "empty") //both session videos and livevideos will exist
            {
                //console.log(seektoseconds);
                return (
                    <div>
                        <ReactPlayer
                            url={this.state.sessionvideolink}
                            controls={this.state.videocontrolsforliverecorded}
                            //ref={e => { this.e = e }}
                            className="react-player"
                            playing={true}
                            loop={this.state.loopvideo}
                            onContextMenu={e => e.preventDefault()}
                            config={{
                                file: {
                                    attributes: {
                                        controlsList: 'nodownload',
                                        disablepictureinpicture: 'true'
                                    }
                                }
                            }}
                        />

                        {/*<button onClick={() => this.e.seekTo(6)}>SeekTo</button>*/}
                        {
                            this.state.livelink.includes("zoom") ?
                                (
                                    <div>
                                        <Row>
                                            <Col>
                                                <div style={styles.joinbutton.zoom.div} className="zoom-logo">
                                                    {/*<div  dangerouslySetInnerHTML={{__html: global.$session.zoomlogo}}></div>*/}
                                                    <br />
                                                    <a href={this.state.livelink} target="_blank" style={{ color: 'white' }}><Button type="submit" className="btn" style={styles.joinbutton.zoom.button}><Icon path={mdiVideo} size={1.2} className="join-video-icon"/>{styles.joinbutton.zoom.text}</Button></a>
                                                    {/*<div class="iframe-container" style={{overflow: 'hidden', paddingTop: '56.25%', position: 'relative'}}>
                                                        <iframe allow="microphone; camera" style={{border: '0', height: '100%', left: '0', position: 'absolute', top: '0', width: '100%'}} src="https://zoom.us/wc/join/92320786912" frameborder="0"></iframe>
                                                    </div>*/
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                )
                                :
                                ('')
                        }
                        {
                            this.state.livelink.includes("externallink") ?
                                (
                                    <div>
                                        <Row>
                                            <Col>
                                                <div style={styles.joinbutton.external.div}>
                                                    <br />
                                                    <a href={this.state.livelink} target="_blank"><Button type="submit" className="btn" style={styles.joinbutton.external.button}><Icon path={mdiVideo} size={1.2} className="join-video-icon"/>{styles.joinbutton.external.text}</Button></a>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                )
                                :
                                ('')
                        }
                        {
                            this.state.livelink.includes("webex") ?
                                (
                                    <div>
                                        <Row>
                                            <Col>
                                                <div style={styles.joinbutton.joinbutton.webex.div} className="webex-logo">
                                                    <br />
                                                    <a href={this.state.livelink} target="_blank" style={{ color: 'white' }}><Button type="submit" className="btn" style={styles.joinbutton.webex.button}><Icon path={mdiVideo} size={1.2} className="join-video-icon"/>{styles.joinbutton.webex.text}</Button></a>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                )
                                :
                                ('')
                        }
                        {
                            this.state.livelink.includes("teams") ?
                                (
                                    <div>
                                        <Row>
                                            <Col>
                                                <div style={styles.joinbutton.teams.div}>
                                                <div dangerouslySetInnerHTML={{__html: global.$session.teamslogo}}></div>
                                                    <br />
                                                    <a href={this.state.livelink} target="_blank" style={{ color: 'white' }}><Button type="submit" className="btn" style={styles.joinbutton.teams.button}><Icon path={mdiVideo} size={1.2} className="join-video-icon"/>{styles.joinbutton.teams.text}</Button></a>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                )
                                :
                                ('')
                        }

                        {
                            this.state.livelink.includes("vimeo") ?
                                (
                                    <div>
                                        <div style={this.state.loginbuttonstyle}>
                                            <ReactPlayer
                                                url={this.state.livelink}
                                                controls={this.state.videocontrolsforliverecorded}
                                                className="react-player"
                                                playing={true}
                                                loop={this.state.loopvideo}
                                                onContextMenu={e => e.preventDefault()}
                                                config={{
                                                    file: {
                                                        attributes: {
                                                            controlsList: 'nodownload',
                                                            disablepictureinpicture: 'true'
                                                        }
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                )
                                :
                                (
                                    ''
                                )
                        }
                        {
                            this.state.livelink.includes("goto") ?
                                (

                                    <div>
                                        <Row>
                                            <Col md={12}>
                                                <div style={styles.joinbutton.goto} className="logmein-logo">
                                                    {/*<div dangerouslySetInnerHTML={{ __html: global.$session.logmeinlogo }}></div>*/}
                                                    <br />
                                                    <a href={this.state.livelink} target="_blank" style={{ color: 'white' }}><Button type="submit" className="btn" style={styles.joinbutton.goto.button}><Icon path={mdiVideo} size={1.2} className="join-video-icon"/>{styles.joinbutton.goto.text}</Button></a>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>

                                )
                                :
                                ('')
                        }
                        {
                            this.state.livelink.includes("youtube") ?
                                (

                                    <div>
                                        <ReactPlayer
                                            url={this.state.livelink}
                                            controls={this.state.videocontrolsforliverecorded}
                                            className="react-player"
                                            playing={true}
                                            loop={this.state.loopvideo}
                                            onContextMenu={e => e.preventDefault()}
                                            config={{
                                                file: {
                                                    attributes: {
                                                        controlsList: 'nodownload',
                                                        disablepictureinpicture: 'true'
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                )
                                :
                                ('')
                        }
                        {
                            this.state.livelink.includes("amazonaws.com") ?
                                (
                                    <div>
                                        <ReactPlayer
                                            url={this.state.livelink}
                                            controls={this.state.videocontrolsforliverecorded}
                                            className="react-player"
                                            playing={true}
                                            loop={this.state.loopvideo}
                                            onContextMenu={e => e.preventDefault()}
                                            config={{
                                                file: {
                                                    attributes: {
                                                        controlsList: 'nodownload',
                                                        disablepictureinpicture: 'true'
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                )
                                :
                                (
                                    ''
                                )
                        }
                        {
                            this.state.livelink.includes("livestream") ?
                                (
                                    <div>
                                        <div style={this.state.loginbuttonstyle}>
                                            <iframe
                                                src={this.state.livelink}
                                                allowfullscreen
                                                allow="autoplay;fullscreen;camera *;microphone *"
                                                id="myId"
                                                className="session-livestream"
                                                display="initial"
                                                position="relative"
                                                width="100%"
                                            />
                                        </div>
                                    </div>
                                )
                                :
                                ('')
                        }
                    </div>
                )
            }
            else // only session videos will exist
            {
                return (
                    <div>
                        <ReactPlayer
                            url={this.state.sessionvideolink}
                            controls={this.state.videocontrolsforliverecorded}
                            className="react-player"
                            playing={true}
                            loop={this.state.loopvideo}
                            onContextMenu={e => e.preventDefault()}
                            config={{
                                file: {
                                    attributes: {
                                        controlsList: 'nodownload',
                                        disablepictureinpicture: 'true'
                                    }
                                }
                            }}
                        />
                    </div>)
            }
            //console.log("sessionvideo = " + this.state.sessionvideolink, "livelink = " + this.state.livelink)
        }
        else {  //only live videos will exist
            //console.log("sessionvideo = " + this.state.sessionvideolink, "livelink = " + this.state.livelink)
            return (
                <div>
                    {
                        this.state.livelink.includes("zoom") ?
                            (
                                <div>
                                    <Row>
                                        <Col>
                                            {
                                                this.state.embedzoom.includes("Y") ?
                                                    (
                                                        <EmbedZoom zoomurl={this.state.livelink} component={"session-layout1"} />
                                                    )
                                                    :
                                                    (
                                                        styles.joinbutton != null && styles.joinbutton.zoom != null ?
                                                            (
                                                                <div style={styles.joinbutton.zoom.div} className="zoom-logo">
                                                                    <div dangerouslySetInnerHTML={{ __html: global.$session.zoomlogo }}></div>
                                                                    <br />
                                                                    <a href={this.state.livelink} target="_blank" style={{ color: 'white' }}><Button type="submit" className="btn" style={styles.joinbutton.zoom.button}><Icon path={mdiVideo} size={1.2} className="join-video-icon"/>{styles.joinbutton.zoom.text}</Button></a>
                                                                </div>
                                                            )
                                                            :
                                                            (
                                                                <div style={this.state.loginbuttonstyle} className="zoom-logo">
                                                                    <div dangerouslySetInnerHTML={{ __html: global.$session.zoomlogo }}></div>
                                                                    <br />
                                                                    <a href={this.state.livelink} target="_blank" style={{ color: 'white' }}><Button type="submit" className={" btn btn-" + global.$session.styles.loginbuttonbgcolor} style={this.state.loginbuttonstyle}><Icon path={mdiVideo} size={1.2} className="join-video-icon"/>Join</Button></a>
                                                                </div>
                                                            )
                                                    )
                                            }

                                        </Col>
                                    </Row>
                                </div>
                            )
                            :
                            ('')
                    }
                    {
                        this.state.livelink.includes("webex") ?
                            (
                                <div>
                                    <Row>
                                        <Col>
                                            <div style={styles.joinbutton.webex.div} className="zoom-logo">
                                                <div dangerouslySetInnerHTML={{ __html: global.$session.webexlogo }}></div>
                                                <br />
                                                <a href={this.state.livelink} target="_blank" style={{ color: 'white' }}><Button type="submit" className="btn" style={styles.joinbutton.webex.button}><Icon path={mdiVideo} size={1.2} className="join-video-icon"/>{styles.joinbutton.webex.text}</Button></a>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            )
                            :
                            ('')
                    }
                    {
                        this.state.livelink.includes("teams") ?
                            (
                                <div>
                                    <Row>
                                        <Col style={{ textAlign: "center" }}>
                                            <div style={styles.joinbutton.teams.div}>
                                                <div dangerouslySetInnerHTML={{__html: global.$session.teamslogo}}></div>
                                                <br />
                                                <a href={this.state.livelink} target="_blank" style={{ color: 'white' }}><Button type="submit" className="btn" style={styles.joinbutton.teams.button}><Icon path={mdiVideo} size={1.2} className="join-video-icon"/>{styles.joinbutton.teams.text}</Button></a>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            )
                            :
                            ('')
                    }
                    {
                        this.state.livelink.includes("vimeo") ?
                            (
                                <div>
                                    <div style={this.state.loginbuttonstyle}>
                                        <ReactPlayer
                                            url={this.state.livelink}
                                            controls={this.state.videocontrolsforliverecorded}

                                            className="react-player"
                                            playing={true}
                                            loop={this.state.loopvideo}
                                            onContextMenu={e => e.preventDefault()}
                                            config={{
                                                file: {
                                                    attributes: {
                                                        controlsList: 'nodownload',
                                                        disablepictureinpicture: 'true'
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            )
                            :
                            ('')
                    }
                    {
                        this.state.livelink.includes("livestream") ?
                            (
                                <div>
                                    <div style={this.state.loginbuttonstyle}>
                                        <iframe
                                            src={this.state.livelink}
                                            allowfullscreen
                                            allow="autoplay;fullscreen;camera *;microphone *"
                                            id="myId"
                                            className="session-livestream"
                                            display="initial"
                                            position="relative"
                                            width="100%"
                                        />
                                    </div>
                                </div>
                            )
                            :
                            ('')
                    }
                    {
                        this.state.livelink.includes("goto") ?
                            (

                                <div>
                                    <Row>
                                        <Col md={12}>
                                            <div style={styles.joinbutton.goto.div} className="logmein-logo">
                                                <div dangerouslySetInnerHTML={{ __html: global.$session.logmeinlogo }}></div>
                                                <br />
                                                <a href={this.state.livelink} target="_blank" style={{ color: 'white' }}><Button type="submit" className="btn" style={styles.joinbutton.goto.button}><Icon path={mdiVideo} size={1.2} className="join-video-icon"/>{styles.joinbutton.goto.text}</Button></a>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                            )
                            :
                            ('')
                    }
                    {
                        this.state.livelink.includes("youtube") ?
                            (

                                <div>
                                    <div style={this.state.loginbuttonstyle}>
                                        <ReactPlayer
                                            url={this.state.livelink}
                                            controls={this.state.videocontrolsforliverecorded}

                                            className="react-player"
                                            playing={true}
                                            loop={this.state.loopvideo}
                                            onContextMenu={e => e.preventDefault()}
                                            config={{
                                                file: {
                                                    attributes: {
                                                        controlsList: 'nodownload',
                                                        disablepictureinpicture: 'true'
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            )
                            :
                            ('')
                    }

                    {
                        this.state.livelink.includes("amazonaws.com") ?
                            (
                                <div>
                                    {console.log(this.state.livelink)}
                                    <ReactPlayer
                                        url={this.state.livelink}
                                        controls={this.state.videocontrolsforliverecorded}

                                        className="react-player"
                                        playing={true}
                                        loop={this.state.loopvideo}
                                        onContextMenu={e => e.preventDefault()}
                                        config={{
                                            file: {
                                                attributes: {
                                                    controlsList: 'nodownload',
                                                    disablepictureinpicture: 'true',
                                                }
                                            }
                                        }}
                                    />
                                </div>
                            )
                            :
                            ('')
                    }
                </div>
            )
        }
    }
}
export default SessionVideos;


