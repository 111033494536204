import React from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { Input, Row, Col } from 'reactstrap';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import ProfilePage from '../Components/ProfilePage';
import './Profile.css';
import Appointments from '../Appointments/Appointments';
import AppointmentsList from '../Appointments/AppointmentsList';

const moment = require('moment-timezone');

class ProfileTabs extends React.Component {
    constructor(props) {
        super(props);
       //console.log(props)
       //console.log(props.exhibitor);
        var enableAppointments = 'N';
        if(global.$profile.appointments == null)
        {
            enableAppointments = "N";
        }
        else
        {
            enableAppointments = global.$profile.appointments.enable;
        }

        this.state = {
            code: global.$code,
            s3: global.$s3,
            bucket: global.$s3bucket,
            enableappointments: enableAppointments
        };
    }

    render() {
        return (
            <div className="profile-tabs">
                <Tabs className="tabs-menu" defaultActiveKey="profile" transition={false} id="tabs" >
                    <Tab eventKey="profile" title="My Profile" className="profile-tab-item">
                        <ProfilePage />
                    </Tab>
                    {
                        this.state.enableappointments.includes("Y")?
                        (
                            <Tab eventKey="appointments" title="My Appointments" className="appointments-tab-item">
                                <AppointmentsList />
                            </Tab>
                        )
                        :
                        ('')
                    }
                </Tabs>
            </div>

        );
    }
}
export default ProfileTabs;


