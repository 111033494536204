import React from 'react';
import './Schedule.css';
import { Input, Row, Col } from 'reactstrap';
import ScheduleItem from './ScheduleItem';
import LiveSessions from './LiveSessions';
import ScheduleSubTabs from './ScheduleSubTabs';
import { Tabs, Tab, TabPanel, TabList } from 'react-web-tabs';
import 'react-web-tabs/dist/react-web-tabs.css';
import MySessions from './MySessions';

const moment = require('moment-timezone');

/*

- Schedule tabs component

                    Tabs(react-web-tabs)
                     |
    Enable sub tabs from webconfig fo each day
    |                                       |
    N                                       Y
    |                                       |
Schedule                       Sub Tabs(react-bootstrap-tabs)
                                            |
                                Add subtabs from webconfig

*/

var timer;

class ScheduleTabs extends React.Component {
    constructor(props) {
        super(props);
        
        //console.log(props.type);
        var livesessionstab = "";
        var numberoftabs = "";
        var activemenu = "";
        if(props.type == "schedule")
        {
            numberoftabs = global.$numberofdays
            activemenu = global.$schedule
        }
        else if(props.type == "posters")
        {
            numberoftabs = global.$numberofposterdays
            activemenu = global.$posters
        }

        if (activemenu.livetab == null || activemenu.livetab.enable == null) {
            livesessionstab = "N";
        }
        else {
            livesessionstab = activemenu.livetab.enable;
        }
        var day1subtabs = '';
        var day2subtabs = '';
        var day3subtabs = '';
        var day4subtabs = '';
        var day5subtabs = '';
        var day6subtabs = '';
        if (activemenu.day1subtabs == null) {
            day1subtabs = "N";
        }
        else {
            day1subtabs = activemenu.day1subtabs.enable
        }
        if (activemenu.day2subtabs == null) {
            day2subtabs = "N";
        }
        else {
            day2subtabs = activemenu.day2subtabs.enable
        }
        if (activemenu.day3subtabs == null) {
            day3subtabs = "N";
        }
        else {
            day3subtabs = activemenu.day3subtabs.enable
        }
        if (activemenu.day4subtabs == null) {
            day4subtabs = "N";
        }
        else {
            day4subtabs = activemenu.day4subtabs.enable
        }
        if (activemenu.day5subtabs == null) {
            day5subtabs = "N";
        }
        else {
            day5subtabs = activemenu.day5subtabs.enable
        }
        if (activemenu.day6subtabs == null) {
            day6subtabs = "N";
        }
        else {
            day6subtabs = activemenu.day6subtabs.enable
        }
        //console.log(global.$activeScheduleTab)
        this.state = {
            code: global.$code,
            s3: global.$s3,
            bucket: global.$s3bucket,
            dataloaded: false,
            open: false,
            activeTab: '1',
            setActiveTab: '1',
            userid: '',
            userpassword: '',
            search: props.search,
            numPages: null,
            pageNumber: 1,
            activeschedule: global.$activeScheduleTab,
            enablelivetab: livesessionstab,
            day1subtabs: day1subtabs,
            day2subtabs: day2subtabs,
            day3subtabs: day3subtabs,
            day4subtabs: day4subtabs,
            day5subtabs: day5subtabs,
            day6subtabs: day6subtabs,
            numberofTabs: numberoftabs,
            activeMenu: activemenu
        };
        
        this.refreshScheduleTabs = this.refreshScheduleTabs.bind(this);
        global.$livesessionexist = null;
    }

    refreshScheduleTabs() {
        this.setState({ dataloaded: true });
    }

    render() {
        
        return (
            <div>
                <Row>
                    <Col md={11}>

                        {
                            this.state.numberofTabs.includes("1") ?
                                (
                                    <Tabs
                                        defaultTab={this.state.activeschedule}
                                        onChange={(tabId) => {/*console.log(tabId)*/ }}
                                        className="session-tabs"
                                    >
                                        <TabList className="tabs-list">
                                            <Tab className="session-tab" tabFor={this.state.activeMenu.day1text}>{this.state.activeMenu.day1text}</Tab>
                                            {
                                                this.state.enablelivetab.includes("Y") ?
                                                    (
                                                        <Tab className="session-tab" tabFor={this.state.activeMenu.livetab.title}>{this.state.activeMenu.livetab.title}</Tab>
                                                    )
                                                    :
                                                    (
                                                        ''
                                                    )
                                            }
                                            {
                                                this.state.activeMenu.enablesessionregistration == true ?
                                                    (
                                                        <Tab className="session-tab" tabFor="mysessions">My Sessions</Tab>
                                                    )
                                                    :
                                                    (
                                                        ''
                                                    )
                                            }
                                        </TabList>
                                        <TabPanel tabId={this.state.activeMenu.day1text}>
                                            {
                                                this.state.day1subtabs.includes("Y") ?
                                                    (
                                                        <ScheduleSubTabs search={this.props.search} type={this.props.type} subtabs={this.state.activeMenu.day1subtabs} />
                                                    )
                                                    :
                                                    (
                                                        this.getSchedule(this.state.activeMenu.stag1, this.state.activeMenu.day1text)
                                                    )
                                            }
                                        </TabPanel>
                                        {
                                            this.state.enablelivetab.includes("Y") ?
                                                (
                                                    <TabPanel tabId={this.state.activeMenu.livetab.title}>
                                                        {this.getLiveSessions(this.state.activeMenu.livetab.stags, this.state.activeMenu.livetab.title)}
                                                        
                                                    </TabPanel>
                                                )
                                                :
                                                (
                                                    ''
                                                )
                                        }
                                        {
                                            this.state.activeMenu.enablesessionregistration == true ?
                                                (
                                                    <TabPanel tabId="mysessions">
                                                        {this.getSignupSessions(this.state.activeMenu.livetab.stags, "mysessions")}
                                                    </TabPanel>
                                                )
                                                :
                                                (
                                                    ''
                                                )
                                        }
                                    </Tabs>
                                )
                                :
                                ('')
                        }
                        {
                            this.state.numberofTabs.includes("2") ?
                                (
                                    <Tabs
                                        defaultTab={this.state.activeschedule}
                                        onChange={(tabId) => {/*console.log(tabId)*/ }}
                                        className="session-tabs"
                                    >
                                        <TabList className="tabs-list">
                                            <Tab className="session-tab" tabFor={this.state.activeMenu.day1text}>{this.state.activeMenu.day1text}</Tab>
                                            <Tab className="session-tab" tabFor={this.state.activeMenu.day2text}>{this.state.activeMenu.day2text}</Tab>
                                            {
                                                this.state.enablelivetab.includes("Y") ?
                                                    (
                                                        <Tab className="session-tab" tabFor={this.state.activeMenu.livetab.title}>{this.state.activeMenu.livetab.title}</Tab>
                                                    )
                                                    :
                                                    (
                                                        ''
                                                    )
                                            }
                                            {
                                                this.state.activeMenu.enablesessionregistration == true ?
                                                    (
                                                        <Tab className="session-tab" tabFor="mysessions">My Sessions</Tab>
                                                    )
                                                    :
                                                    (
                                                        ''
                                                    )
                                            }
                                        </TabList>
                                        <TabPanel tabId={this.state.activeMenu.day1text}>
                                            {
                                                this.state.day1subtabs.includes("Y") ?
                                                    (
                                                        <ScheduleSubTabs search={this.props.search} type={this.props.type} subtabs={this.state.activeMenu.day1subtabs} />
                                                    )
                                                    :
                                                    (
                                                        this.getSchedule(this.state.activeMenu.stag1, this.state.activeMenu.day1text)
                                                    )
                                            }
                                        </TabPanel>
                                        <TabPanel tabId={this.state.activeMenu.day2text}>
                                            {
                                                this.state.day2subtabs.includes("Y") ?
                                                    (
                                                        <ScheduleSubTabs search={this.props.search} type={this.props.type} subtabs={this.state.activeMenu.day2subtabs} />
                                                    )
                                                    :
                                                    (
                                                        this.getSchedule(this.state.activeMenu.stag2, this.state.activeMenu.day2text)
                                                    )
                                            }
                                        </TabPanel>
                                        {
                                            this.state.enablelivetab.includes("Y") ?
                                                (
                                                    <TabPanel tabId={this.state.activeMenu.livetab.title}>
                                                        {this.getLiveSessions(this.state.activeMenu.livetab.stags, this.state.activeMenu.livetab.title)}
                                                        
                                                    </TabPanel>
                                                )
                                                :
                                                (
                                                    ''
                                                )
                                        }
                                        {
                                            this.state.activeMenu.enablesessionregistration == true ?
                                                (
                                                    <TabPanel tabId="mysessions">
                                                        {this.getSignupSessions(this.state.activeMenu.livetab.stags, "mysessions")}
                                                    </TabPanel>
                                                )
                                                :
                                                (
                                                    ''
                                                )
                                        }
                                    </Tabs>

                                )
                                :
                                ('')
                        }
                        {
                            this.state.numberofTabs.includes("3") ?
                                (
                                    <Tabs
                                        defaultTab={this.state.activeschedule}
                                        onChange={(tabId) => {/*console.log(tabId)*/ }}
                                        className="session-tabs"
                                    >
                                        <TabList className="tabs-list">
                                            <Tab className="session-tab" tabFor={this.state.activeMenu.day1text}>{this.state.activeMenu.day1text}</Tab>
                                            <Tab className="session-tab" tabFor={this.state.activeMenu.day2text}>{this.state.activeMenu.day2text}</Tab>
                                            <Tab className="session-tab" tabFor={this.state.activeMenu.day3text}>{this.state.activeMenu.day3text}</Tab>
                                            {
                                                this.state.enablelivetab.includes("Y") ?
                                                    (
                                                        <Tab className="session-tab" tabFor={this.state.activeMenu.livetab.title}>{this.state.activeMenu.livetab.title}</Tab>
                                                    )
                                                    :
                                                    (
                                                        ''
                                                    )
                                            }{
                                                this.state.activeMenu.enablesessionregistration == true ?
                                                    (
                                                        <Tab className="session-tab" tabFor="mysessions">My Sessions</Tab>
                                                    )
                                                    :
                                                    (
                                                        ''
                                                    )
                                            }
                                        </TabList>
                                        <TabPanel tabId={this.state.activeMenu.day1text}>
                                            {
                                                (this.state.day1subtabs != null && this.state.day1subtabs.includes("Y")) ?
                                                    (
                                                        <ScheduleSubTabs search={this.props.search} type={this.props.type} subtabs={this.state.activeMenu.day1subtabs} />
                                                    )
                                                    :
                                                    (
                                                        this.getSchedule(this.state.activeMenu.stag1, this.state.activeMenu.day1text)
                                                    )
                                            }
                                        </TabPanel>
                                        <TabPanel tabId={this.state.activeMenu.day2text}>
                                            {
                                                (this.state.day1subtabs != null && this.state.day2subtabs.includes("Y")) ?
                                                    (
                                                        <ScheduleSubTabs search={this.props.search} type={this.props.type} subtabs={this.state.activeMenu.day2subtabs} />
                                                    )
                                                    :
                                                    (
                                                        this.getSchedule(this.state.activeMenu.stag2, this.state.activeMenu.day2text)
                                                    )
                                            }
                                        </TabPanel>
                                        <TabPanel tabId={this.state.activeMenu.day3text}>
                                            {
                                                (this.state.day1subtabs != null && this.state.day3subtabs.includes("Y")) ?
                                                    (
                                                        <ScheduleSubTabs search={this.props.search} type={this.props.type} subtabs={this.state.activeMenu.day3subtabs} />
                                                    )
                                                    :
                                                    (
                                                        this.getSchedule(this.state.activeMenu.stag3, this.state.activeMenu.day3text)
                                                    )
                                            }

                                        </TabPanel>
                                        {
                                            (this.state.enablelivetab.includes("Y")) ?
                                                (
                                                    <TabPanel tabId={this.state.activeMenu.livetab.title}>
                                                        {this.getLiveSessions(this.state.activeMenu.livetab.stags, this.state.activeMenu.livetab.title)}
                                                        
                                                    </TabPanel>
                                                )
                                                :
                                                (
                                                    ''
                                                )
                                        }
                                        {
                                            this.state.activeMenu.enablesessionregistration == true ?
                                                (
                                                    <TabPanel tabId="mysessions">
                                                        {this.getSignupSessions(this.state.activeMenu.livetab.stags, "mysessions")}

                                                    </TabPanel>
                                                )
                                                :
                                                (
                                                    ''
                                                )
                                        }
                                    </Tabs>
                                )
                                :
                                ('')
                        }
                        {
                            this.state.numberofTabs.includes("4") ?
                                (
                                    <Tabs
                                        defaultTab={this.state.activeschedule}
                                        onChange={(tabId) => {/*console.log(tabId)*/ }}
                                        className="session-tabs"
                                    >
                                        <TabList className="tabs-list">
                                            <Tab className="session-tab" tabFor={this.state.activeMenu.day1text}>{this.state.activeMenu.day1text}</Tab>
                                            <Tab className="session-tab" tabFor={this.state.activeMenu.day2text}>{this.state.activeMenu.day2text}</Tab>
                                            <Tab className="session-tab" tabFor={this.state.activeMenu.day3text}>{this.state.activeMenu.day3text}</Tab>
                                            <Tab className="session-tab" tabFor={this.state.activeMenu.day4text}>{this.state.activeMenu.day4text}</Tab>
                                            {
                                                this.state.enablelivetab.includes("Y") ?
                                                    (
                                                        <Tab className="session-tab" tabFor={this.state.activeMenu.livetab.title}>{this.state.activeMenu.livetab.title}</Tab>
                                                    )
                                                    :
                                                    (
                                                        ''
                                                    )
                                            }{
                                                this.state.activeMenu.enablesessionregistration == true ?
                                                    (
                                                        <Tab className="session-tab" tabFor="mysessions">My Sessions</Tab>
                                                    )
                                                    :
                                                    (
                                                        ''
                                                    )
                                            }
                                        </TabList>
                                        <TabPanel tabId={this.state.activeMenu.day1text}>
                                            {
                                                this.state.day1subtabs.includes("Y") ?
                                                    (
                                                        <ScheduleSubTabs search={this.props.search} type={this.props.type} subtabs={this.state.activeMenu.day1subtabs} />
                                                    )
                                                    :
                                                    (
                                                        this.getSchedule(this.state.activeMenu.stag1, this.state.activeMenu.day1text)
                                                    )
                                            }
                                        </TabPanel>
                                        <TabPanel tabId={this.state.activeMenu.day2text}>
                                            {
                                                this.state.day2subtabs.includes("Y") ?
                                                    (
                                                        <ScheduleSubTabs search={this.props.search} type={this.props.type} subtabs={this.state.activeMenu.day2subtabs} />
                                                    )
                                                    :
                                                    (
                                                        this.getSchedule(this.state.activeMenu.stag2, this.state.activeMenu.day2text)
                                                    )
                                            }
                                        </TabPanel>
                                        <TabPanel tabId={this.state.activeMenu.day3text}>
                                            {
                                                this.state.day3subtabs.includes("Y") ?
                                                    (
                                                        <ScheduleSubTabs search={this.props.search} type={this.props.type} subtabs={this.state.activeMenu.day3subtabs} />
                                                    )
                                                    :
                                                    (
                                                        this.getSchedule(this.state.activeMenu.stag3, this.state.activeMenu.day3text)
                                                    )
                                            }

                                        </TabPanel>
                                        <TabPanel tabId={this.state.activeMenu.day4text}>
                                            {
                                                this.state.day4subtabs.includes("Y") ?
                                                    (
                                                        <ScheduleSubTabs search={this.props.search} type={this.props.type} subtabs={this.state.activeMenu.day4subtabs} />
                                                    )
                                                    :
                                                    (
                                                        this.getSchedule(this.state.activeMenu.stag4, this.state.activeMenu.day4text)
                                                    )
                                            }

                                        </TabPanel>
                                        {
                                            this.state.enablelivetab.includes("Y") ?
                                                (
                                                    <TabPanel tabId={this.state.activeMenu.livetab.title}>
                                                        {this.getLiveSessions(this.state.activeMenu.livetab.stags, this.state.activeMenu.livetab.title)}
                                                        
                                                    </TabPanel>
                                                )
                                                :
                                                (
                                                    ''
                                                )
                                        }
                                        {
                                            this.state.activeMenu.enablesessionregistration == true ?
                                                (
                                                    <TabPanel tabId="mysessions">
                                                        {this.getSignupSessions(this.state.activeMenu.livetab.stags, "mysessions")}
                                                    </TabPanel>
                                                )
                                                :
                                                (
                                                    ''
                                                )
                                        }
                                    </Tabs>
                                )
                                :
                                ('')
                        }
                        {
                            this.state.numberofTabs.includes("5") ?
                                (
                                    <Tabs
                                        defaultTab={this.state.activeschedule}
                                        onChange={(tabId) => {/*console.log(tabId)*/ }}
                                        className="session-tabs"
                                    >
                                        <TabList className="tabs-list">
                                            <Tab className="session-tab" tabFor={this.state.activeMenu.day1text}>{this.state.activeMenu.day1text}</Tab>
                                            <Tab className="session-tab" tabFor={this.state.activeMenu.day2text}>{this.state.activeMenu.day2text}</Tab>
                                            <Tab className="session-tab" tabFor={this.state.activeMenu.day3text}>{this.state.activeMenu.day3text}</Tab>
                                            <Tab className="session-tab" tabFor={this.state.activeMenu.day4text}>{this.state.activeMenu.day4text}</Tab>
                                            <Tab className="session-tab" tabFor={this.state.activeMenu.day5text}>{this.state.activeMenu.day5text}</Tab>
                                            {
                                                this.state.enablelivetab.includes("Y") ?
                                                    (
                                                        <Tab className="session-tab" tabFor={this.state.activeMenu.livetab.title}>{this.state.activeMenu.livetab.title}</Tab>
                                                    )
                                                    :
                                                    (
                                                        ''
                                                    )
                                            }{
                                                this.state.activeMenu.enablesessionregistration == true ?
                                                    (
                                                        <Tab className="session-tab" tabFor="mysessions">My Sessions</Tab>
                                                    )
                                                    :
                                                    (
                                                        ''
                                                    )
                                            }
                                        </TabList>
                                        <TabPanel tabId={this.state.activeMenu.day1text}>
                                            {
                                                this.state.day1subtabs.includes("Y") ?
                                                    (
                                                        <ScheduleSubTabs search={this.props.search} type={this.props.type} subtabs={this.state.activeMenu.day1subtabs} />
                                                    )
                                                    :
                                                    (
                                                        this.getSchedule(this.state.activeMenu.stag1, this.state.activeMenu.day1text)
                                                    )
                                            }
                                        </TabPanel>
                                        <TabPanel tabId={this.state.activeMenu.day2text}>
                                            {
                                                this.state.day2subtabs.includes("Y") ?
                                                    (
                                                        <ScheduleSubTabs search={this.props.search} type={this.props.type} subtabs={this.state.activeMenu.day2subtabs} />
                                                    )
                                                    :
                                                    (
                                                        this.getSchedule(this.state.activeMenu.stag2, this.state.activeMenu.day2text)
                                                    )
                                            }
                                        </TabPanel>
                                        <TabPanel tabId={this.state.activeMenu.day3text}>
                                            {
                                                this.state.day3subtabs.includes("Y") ?
                                                    (
                                                        <ScheduleSubTabs search={this.props.search} type={this.props.type} subtabs={this.state.activeMenu.day3subtabs} />
                                                    )
                                                    :
                                                    (
                                                        this.getSchedule(this.state.activeMenu.stag3, this.state.activeMenu.day3text)
                                                    )
                                            }

                                        </TabPanel>
                                        <TabPanel tabId={this.state.activeMenu.day4text}>
                                            {
                                                this.state.day4subtabs.includes("Y") ?
                                                    (
                                                        <ScheduleSubTabs search={this.props.search} type={this.props.type} subtabs={this.state.activeMenu.day4subtabs} />
                                                    )
                                                    :
                                                    (
                                                        this.getSchedule(this.state.activeMenu.stag4, this.state.activeMenu.day4text)
                                                    )
                                            }

                                        </TabPanel>
                                        <TabPanel tabId={this.state.activeMenu.day5text}>
                                            {
                                                this.state.day5subtabs.includes("Y") ?
                                                    (
                                                        <ScheduleSubTabs search={this.props.search} type={this.props.type} subtabs={this.state.activeMenu.day5subtabs} />
                                                    )
                                                    :
                                                    (
                                                        this.getSchedule(this.state.activeMenu.stag5, this.state.activeMenu.day5text)
                                                    )
                                            }

                                        </TabPanel>
                                        {
                                            this.state.enablelivetab.includes("Y") ?
                                                (
                                                    <TabPanel tabId={this.state.activeMenu.livetab.title}>
                                                        {this.getLiveSessions(this.state.activeMenu.livetab.stags, this.state.activeMenu.livetab.title)}
                                                        {/*
                                                            global.$livesessionexist == null ?
                                                                (

                                                                    <div className="livesessions-message">
                                                                        Currently no sessions are live.
                                                                    </div>
                                                                )
                                                                :
                                                                ('')
                                                                */}
                                                    </TabPanel>
                                                )
                                                :
                                                (
                                                    ''
                                                )
                                        }
                                        {
                                            this.state.activeMenu.enablesessionregistration == true ?
                                                (
                                                    <TabPanel tabId="mysessions">
                                                        {this.getSignupSessions(this.state.activeMenu.livetab.stags, "mysessions")}
                                                    </TabPanel>
                                                )
                                                :
                                                (
                                                    ''
                                                )
                                        }
                                    </Tabs>

                                )
                                :
                                ('')
                        }
                        {
                            this.state.numberofTabs.includes("6") ?
                                (
                                    <Tabs
                                        defaultTab={this.state.activeschedule}
                                        onChange={(tabId) => {/*console.log(tabId)*/ }}
                                        className="session-tabs"
                                    >
                                        <TabList className="tabs-list">
                                            <Tab className="session-tab" tabFor={this.state.activeMenu.day1text}>{this.state.activeMenu.day1text}</Tab>
                                            <Tab className="session-tab" tabFor={this.state.activeMenu.day2text}>{this.state.activeMenu.day2text}</Tab>
                                            <Tab className="session-tab" tabFor={this.state.activeMenu.day3text}>{this.state.activeMenu.day3text}</Tab>
                                            <Tab className="session-tab" tabFor={this.state.activeMenu.day4text}>{this.state.activeMenu.day4text}</Tab>
                                            <Tab className="session-tab" tabFor={this.state.activeMenu.day5text}>{this.state.activeMenu.day5text}</Tab>
                                            <Tab className="session-tab" tabFor={this.state.activeMenu.day6text}>{this.state.activeMenu.day6text}</Tab>
                                            {
                                                this.state.enablelivetab.includes("Y") ?
                                                    (
                                                        <Tab className="session-tab" tabFor={this.state.activeMenu.livetab.title}>{this.state.activeMenu.livetab.title}</Tab>
                                                    )
                                                    :
                                                    (
                                                        ''
                                                    )
                                            }{
                                                this.state.activeMenu.enablesessionregistration == true ?
                                                    (
                                                        <Tab className="session-tab" tabFor="mysessions">My Sessions</Tab>
                                                    )
                                                    :
                                                    (
                                                        ''
                                                    )
                                            }
                                        </TabList>
                                        <TabPanel tabId={this.state.activeMenu.day1text}>
                                            {
                                                this.state.day1subtabs.includes("Y") ?
                                                    (
                                                        <ScheduleSubTabs search={this.props.search} type={this.props.type} subtabs={this.state.activeMenu.day1subtabs} />
                                                    )
                                                    :
                                                    (
                                                        this.getSchedule(this.state.activeMenu.stag1, this.state.activeMenu.day1text)
                                                    )
                                            }
                                        </TabPanel>
                                        <TabPanel tabId={this.state.activeMenu.day2text}>
                                            {
                                                this.state.day2subtabs.includes("Y") ?
                                                    (
                                                        <ScheduleSubTabs search={this.props.search} type={this.props.type} subtabs={this.state.activeMenu.day2subtabs} />
                                                    )
                                                    :
                                                    (
                                                        this.getSchedule(this.state.activeMenu.stag2, this.state.activeMenu.day2text)
                                                    )
                                            }
                                        </TabPanel>
                                        <TabPanel tabId={this.state.activeMenu.day3text}>
                                            {
                                                this.state.day3subtabs.includes("Y") ?
                                                    (
                                                        <ScheduleSubTabs search={this.props.search} type={this.props.type} subtabs={this.state.activeMenu.day3subtabs} />
                                                    )
                                                    :
                                                    (
                                                        this.getSchedule(this.state.activeMenu.stag3, this.state.activeMenu.day3text)
                                                    )
                                            }

                                        </TabPanel>
                                        <TabPanel tabId={this.state.activeMenu.day4text}>
                                            {
                                                this.state.day4subtabs.includes("Y") ?
                                                    (
                                                        <ScheduleSubTabs search={this.props.search} type={this.props.type} subtabs={this.state.activeMenu.day4subtabs} />
                                                    )
                                                    :
                                                    (
                                                        this.getSchedule(this.state.activeMenu.stag4, this.state.activeMenu.day4text)
                                                    )
                                            }

                                        </TabPanel>
                                        <TabPanel tabId={this.state.activeMenu.day5text}>
                                            {
                                                this.state.day5subtabs.includes("Y") ?
                                                    (
                                                        <ScheduleSubTabs search={this.props.search} type={this.props.type} subtabs={this.state.activeMenu.day5subtabs} />
                                                    )
                                                    :
                                                    (
                                                        this.getSchedule(this.state.activeMenu.stag5, this.state.activeMenu.day5text)
                                                    )
                                            }

                                        </TabPanel>
                                        <TabPanel tabId={this.state.activeMenu.day6text}>
                                            {
                                                this.state.day6subtabs.includes("Y") ?
                                                    (
                                                        <ScheduleSubTabs search={this.props.search} type={this.props.type} subtabs={this.state.activeMenu.day6subtabs} />
                                                    )
                                                    :
                                                    (
                                                        this.getSchedule(this.state.activeMenu.stag6, this.state.activeMenu.day6text)
                                                    )
                                            }

                                        </TabPanel>
                                        {
                                            this.state.enablelivetab.includes("Y") ?
                                                (
                                                    <TabPanel tabId={this.state.activeMenu.livetab.title}>
                                                        {this.getLiveSessions(this.state.activeMenu.livetab.stags, this.state.activeMenu.livetab.title)}
                                                        
                                                    </TabPanel>
                                                )
                                                :
                                                (
                                                    ''
                                                )
                                        }
                                        {
                                            this.state.activeMenu.enablesessionregistration == true ?
                                                (
                                                    <TabPanel tabId="mysessions">
                                                        {this.getSignupSessions(this.state.activeMenu.livetab.stags, "mysessions")}
                                                    </TabPanel>
                                                )
                                                :
                                                (
                                                    ''
                                                )
                                        }
                                    </Tabs>

                                )
                                :
                                ('')
                        }
                    </Col>
                </Row>
                <br />
                <br />
            </div>

        );
    }

    getSchedule(stag, activetab) {
        const results = global.$schedulejson.filter(schedule => {
            if (this.props.search == "")
                return true;
            else {
                if (schedule.TITLE.toLocaleLowerCase().includes(this.props.search.toLocaleLowerCase()) || schedule.DESCRIPTION.toLocaleLowerCase().includes(this.props.search.toLocaleLowerCase()) || schedule.T1.toLocaleLowerCase().includes(this.props.search.toLocaleLowerCase()) || schedule.SP.toLocaleLowerCase().includes(this.props.search.toLocaleLowerCase()))
                    return true;
                else
                    return false;
            }
        })
        //console.log(stag, activetab);
        //console.log(results);
        
        return results.map(schedule => {

            //console.log(schedule)
            if (schedule.SP == "") {
                schedule.SP = "empty";
            }

            if (schedule.FILE1 == "" || schedule.FILE1 == " ") {
                schedule.FILE1 = "empty";
            }

            var currentdate = this.getAdjustedCurrentDate(schedule.BEGIN, schedule.END)//moment().tz(`${global.$settings.timezone}`).format('L LTS')

            var currenttime = Date.parse(currentdate);
            var sessionstarttime = Date.parse(schedule.BEGIN);
            var sessionendtime = Date.parse(schedule.END);
            //schedule.SP = schedule.SP.replace(/;/g, "");
            //console.log(schedule.SP);

            if (schedule.T3 == "") {
                schedule.T3 = "emptyurl";
            }
            if (schedule.TRACKS == "") {
                schedule.TRACKS = "emptyurl";
            }
            //console.log(schedule.ATTENDEELINK)
            if (schedule.TAG.includes(stag)) {
                return (
                    <div key={schedule.SID}>
                        {
                            schedule.TYPE == "F" ?
                                (
                                    ''
                                )
                                :
                                (
                                    <ScheduleItem
                                        stag={stag}
                                        currentdate={currentdate}
                                        currenttime={currenttime}
                                        sessionstarttime={sessionstarttime}
                                        sessionendtime={sessionendtime}
                                        schedule={schedule}
                                        activetab={activetab}
                                        type={this.props.type}
                                        refreshScheduleTabs={this.refreshScheduleTabs}
                                    />
                                )
                        }
                    </div>
                );
            }
        })
    }
    getLiveSessions(stags, activetab) {
        var scheduletags = stags;
        //console.log(scheduletags);
        scheduletags = scheduletags.split(",");

        
        /*
        var livesessionsexist = false;
        var nolivessessionsshowed = false;
        var firsttime = true;
        if (global.$livesessionexist == true) {
                livesessionsexist = true;
            }
            if(stag = scheduletags[1])
            {
                firsttime = false;
            }    
        firsttime == false && livesessionsexist == false && nolivessessionsshowed == false ?
            (
                <div className="livesessions-message">
                        {nolivessessionsshowed = true}
                        Currently no sessions are live.
                    </div>
                )
                :
                ('')
            */
        return scheduletags.map(stag => {
            //console.log(stag);
            
            //console.log(global.$livesessionexist);
            
            return (
                <div>
                    {
                        stag != "ondemand"?
                        (
                            <LiveSessions stag={stag} activetab={activetab} search={this.props.search} type={this.props.type} />
                        )
                        :
                        (
                            ''
                        )
                        
                    }
                </div>
            )
        });

    }
    getSignupSessions(stags, activetab) {
        var scheduletags = stags;
        //console.log(scheduletags);
        scheduletags = scheduletags.split(",");
        //console.log(scheduletags);
        return scheduletags.map(stag => {
            //console.log(stag);
            return (
                <MySessions stag={stag} activetab={activetab} search={this.props.search} type={this.props.type} refreshScheduleTabs={this.refreshScheduleTabs} />
            )
        });
    }
    getAdjustedCurrentDate(begin, end) {
        var mode = global.$mode;
        if (mode == null || mode == '') {
            mode = 'live';
        }

        var currentdate = moment().tz(`${global.$settings.timezone}`).format('L LTS');

        if (mode == "live") {
            //Nothing - Just return the current date
        }
        else if (mode == "preview") {
            // Make current date/time as same as session begin date/time
            currentdate = begin;
        }
        else if (mode == "dryrun") {
            var eventdate = moment(begin).format('L');
            var currenttime = moment().tz(`${global.$settings.timezone}`).format('LTS');
            currentdate = eventdate + " " + currenttime
        }
        else if (mode == "ondemand") {   //Add 365 days to current date

            var postdate = moment().add(365, 'days').calendar();
            var posttime = moment().tz(`${global.$settings.timezone}`).format('LTS');
            currentdate = postdate + " " + posttime;
        }

        return currentdate;
    }
}
export default ScheduleTabs;


