/* eslint-disable */
import React, { Component } from 'react';
import { API, Analytics } from 'aws-amplify';
import { Col, Row } from 'reactstrap';
import PostItemView from './PostItemView';
import PostInput from './PostInput';
import './Conversations.css';
var serviceNames = require('../ServiceNameMapper');

var timer;
/*
conversations Menu : 
                    1. Conversations (public => group chat)
                        a. Chat Input
                        b. Chat Message
                        c. Chat Messages

                    2. People (Private => one to one chat)
                        a. List of People
                        b. Invitation
                        c. Chat Input
                        d. Chat Message
                        e. Chat Messages
*/


/*
Posts Classes:

PostsView
  PostInput
  PostItemView[]
    CommentsView
      CommentInput
      CommentItemView[]


TODO
----

Image upload for posts
Take photo from camera

Post styles - attendee photo, date, name
Like, comment count on the right 
Border around posts

Expand and hide comments

Timer refersh every one minutes

DONE
----
Comments height
Hide scrollbar

*/


class PostsView extends React.Component {
  constructor(props) {

    super(props);

    this.state = {
      code: this.props.code,
      receiverid: this.props.receiverid,
      messages: [],
      dataloaded: false,
      messagesstyle: global.$conversations.styles.messages
    };

    //console.log("Posts.constructor()"); 
    /*setInterval(() => {
      this.getMessages()
    }, 1000)
    */
    this.loadMessages = this.loadMessages.bind(this);
    // timer = setInterval(() => {
    //  //console.log("timer")
    //   this.getMessages()
    // }, 1000)
  }

  componentDidUpdate() {

    // There is a new message in the state, scroll to bottom of list
    //const objDiv = document.getElementById('messageList');
    //objDiv.scrollTop = objDiv.scrollHeight;
  }

  loadMessages() {
   //console.log('Posts:loadMessages()');

    this.getMessages();
  }

  render() {

    //console.log("Posts.render()"); 

    Analytics.record({ name: 'PageView', attributes: { name: 'eventsites-' + this.state.code + '-posts' } });
    if (this.state.dataloaded) {

      //console.log("Posts.render() dataloaded "); 

      const messages = this.state.messages.map((message, i) => {
        return (
          <PostItemView
            key={message.messageid}
            senderid={message.senderid}
            sendername={message.sname}
            message={message.message}
            messageid={message.messageid}
            image={message.image}
            lcount={message.lcount}
            ccount={message.ccount}
            receiverid={message.receiverid}
            likes={message.likes}
            fromMe='false'
            loadMessages={this.loadMessages} />
        );
      });

      return (
        <div>
          <PostInput code={this.state.code} receiverid={this.props.receiverid} onSend={this.loadMessages} />
          <div className='messages' id='messageList' style={this.state.messagesstyle}>
            {messages}
          </div>
        </div>
      )
    }
    else {
      this.getMessages();
      return (
        <div className='messages' id='messageList'>
        </div>
      )
    }
  }

  getMessages() {

    let apiName = serviceNames.getMessageService();
    let path = ''; //replace this with the path you have configured on your API
    let myInit = {
      body:
      {
        'code': this.state.code,
        'command': 'getMessages',
        'receiverid': this.state.receiverid,
        'chat': 'N'
      },
      response: true,
      convertEmptyValues: true,
      headers: {}
    }

    //console.log(myInit);
    //console.log('Posts:getMessages()');

    API.post(apiName, path, myInit).then(response => {
      //console.log (response);

      if (response.err == null && response.data != null) {
        //console.log(response.data.Items);
        this.setState({
          dataloaded: true,
          messages: this.getMessageObjects(response.data.Items)
        })
      }
      else {
        //console.log(response.err);
      }
    }
    ).catch(error => {
      //console.log(error)
    });
  }

  getMessageObjects(objs) {
    const messageObjs = []

    for (var i = 0; i < objs.length; i++) {

      var obj = objs[i]
      //console.log(obj);
      var newObj = Object()

      newObj.messageid = obj.messageid.N
      newObj.receiverid = obj.receiverid.S
      newObj.ccount = obj.ccount.N
      newObj.lcount = obj.lcount.N
      newObj.senderid = obj.senderid.S
      newObj.sname = obj.sname.S
      newObj.message = obj.message.S
      newObj.image = obj.image.S
      newObj.likes = obj.likes.L
      newObj.h = obj.h.N
      newObj.w = obj.w.N

      messageObjs.push(newObj)
    }
    return messageObjs
  }

} //Class

export default PostsView;