import React from 'react';
import { Component } from "react";
import { API } from 'aws-amplify';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CommonFunction2 from './RegCommon/CommonFunction2';
import CommonFunctions from './RegCommon/CommonFunctions';
import EventcodeFunctions from './RegCommon/EventcodeFunctions';
import TravconFunctions from './RegCommon/CustomerFunctions/TravconFunctions';
import RegItemStrings from './RegCommon/RegItemStrings';
import UserFunctions from '../Common/UserFunctions';
import CommonString from './RegCommon/CommonString';
var serviceNames = require('../ServiceNameMapper');

var moment = require('moment-timezone');

let paymenttype = CommonString.paymenttype;
let usertype = CommonString.users;
let status = CommonString.status

class RegisterAttendee extends Component {
    constructor(props) {
        super(props);

        this.state = {
            code: global.$code,
            s3: global.$s3,
            bucket: global.$s3bucket,
            isLoading: false,
            registered:false,
            hideSpinner:false,
            showloginlink: true,
            attendeeregistration:false,
            guestregistration: false,
            message:"",
            guestname:"",
            regitems:"",
            emailtype:"",
            usertype:"",
            totalamount:0,
            paymentstatus:"",
            regstatus:status.CONFIRMED,
            amountpending:0,
            amountpaid:0,
            showdirectory:" ",
            q1:"",
            q2:"",
            q3:"",
            q4:"",
            q5:"",
            q6:"",
            q7:"",
            q8:"",
            promocode:" ",
            regpromocodesforsignupcount:" ",
            promoamount:"0",
            dateregistered:" ",
            category:"",
            splevel:"",
            paymentid: ' ',
            userid:"",
            orderid: " ",
            mailstatus: "new",
            signature: ' ',
            regid:'',
            password:'',
            selectedpayment:"",
            regitemsjson:[],
            promocodes:'',
            createpromocode:"false",
            confirmpassword:"false",
            signupsessions:""
        }
        this.showloginLink = this.showloginLink.bind(this);
        this.addAttendee = this.addAttendee.bind(this);
    }

    
async componentDidMount(){
        
    // add attendee registration 
        if(global.$isattendeeregistered == null && global.$isattendeeregistered !== true){
            if(this.state.attendeeregistration === false){
               await this.addAttendee(global.$regattendeeinfo, usertype.ATTENDEE)  
            }
        }

    // add guest registration 
        if (global.$isguestregistered == null && global.$isguestregistered !== true){
            if (this.props.reggueststatus === true) {
                if (this.state.guestregistration === false){
                    await this.addAttendee(global.$regguestinfo, usertype.GUEST)
                }
            }
            
        }
    }
    
    // show login link function
    // Show the link after new registration and there is no pending payment (defferred promo)
    showloginLink() {
        if (this.state.registered === true) {
            if (!CommonFunctions.isUserLoggedin()) {
                if (global.$deferredpromo !== "Y") {
                    if (this.state.showloginlink === true) {
                        return (
                            <p className="payment-login">
                                <Link to={`/LoginForm`}>Click here to login</Link>
                            </p>
                        )
                    }else return ""
                    
                }
            }
        }
    }

    render() {
        
        return(
            <div>
                <div className="loading-button-div">
                    <Spinner
                        animation="border"
                        role="status"
                        size="sm"
                        hidden={this.state.hideSpinner}
                        className="loading-button-register"
                    />
                </div>
                <div className="payment-message" id="message">{this.state.message}</div>
                {
                    // this.showloginLink()
                }
      
            </div>
        )
    }

    async addAttendee(reginfo,user) {
        try {
            
            let timestamp = CommonFunction2.setDate();
            reginfo.email = reginfo.email.toLowerCase().trim()
            
            // CREATE REGID
            this.state.regid = CommonFunction2.createUserRegid(reginfo); 

            // CREATE PASSWORD 
            this.state.password = CommonFunction2.createUserPassword(reginfo);
            
            // CHECK CONFIRMPASSOWRD (TRUE/FALSE)
            if (global.$registrationform.setup[global.$tabid].confirmpassword && global.$registrationform.setup[global.$tabid].confirmpassword == true) {
                this.state.confirmpassword = "true"
            }

             // GET PRE-ASSIGNED SESSION
            if (global.$registrationform.setup[global.$tabid].signupsessions && global.$registrationform.setup[global.$tabid].signupsessions != "") {
                this.state.signupsessions = global.$registrationform.setup[global.$tabid].signupsessions
            }

            // add registration question and its answer
            if (global.$regquestionsinfo != null) this.setRegQuestions()
            
            // set user emailtype and  user usertype  
            this.state.emailtype = CommonFunction2.setuserandemailtype(user,this.props.type,reginfo.userstype).emailtype; 
            this.state.usertype = CommonFunction2.setuserandemailtype(user,this.props.type,reginfo.userstype).usertype;  

            // set userid for ( attendee) , guest userid is different
            if (global.$loginid && global.$loginid != null) {
                this.state.userid = global.$loginid;
            }
            else this.state.userid = reginfo.email
            

            if (user === usertype.ATTENDEE) {

                this.setState({ attendeeregistration: "inprogress"});
                // add attendee and guest regitems;     
                CommonFunction2.setPromocodeDetails();
                this.state.regitemsjson = global.$selectedRegItems           
                this.state.totalamount = global.$regamount;
            }
            else if (user === usertype.GUEST){

                this.setState({ guestregistration: "inprogress"});
                // add guest regitems;           
                this.state.regitemsjson = global.$guestRegitemsjson;
                // set guest userid
                this.state.userid = global.$regguestinfo.email     
                global.$regItemsForSignupCount = " "                
            }

            // add default reginfo details and show reginfo details
            this.setEmptyReginfo(reginfo,user);   
            
            if (CommonFunctions.isUserLoggedin()) {  

                this.state.dateregistered = reginfo.data5;
                this.state.mailstatus = 'update';
                
                if (user === usertype.ATTENDEE) {

                    // update registratrion set payment details
                    this.state.totalamount = Number(global.$regamount) + Number(reginfo.totalamount);
                    this.state.refundamount = Number(reginfo.refundamount);
                    this.state.amountpending = Number(reginfo.pendingamount);
                    this.state.amountpaid = Number(reginfo.amountpaid);

                    // set update regitems
                    this.setUpdateRegItems(reginfo);
                    // set update payment details for attendee
                    this.updateAttendeePayment(reginfo);
                }
                else if (user === usertype.GUEST){

                    // update registratrion set payment details for guest
                    this.state.totalamount = Number(reginfo.totalamount);
                    this.state.refundamount = Number(reginfo.refundamount);
                    this.state.amountpending = Number(reginfo.pendingamount);
                    this.state.amountpaid = Number(reginfo.amountpaid);

                    // set update guest regitems   
                    reginfo.regtype = this.state.regitems;
                    // set update payment details for guest
                    this.updateGuestPayment()
                }
            }
            else {

                // set registration date
                this.state.dateregistered = new Date(timestamp * 1000).toLocaleString();
                if (user === usertype.ATTENDEE) {
                    // attendee payment details
                    this.setAttendeePayment();        
                }
                else if (user === usertype.GUEST){
                    // guest payment details
                    this.setGuestPayment();           
                }
            }

            // enable create exhibitor profile 
            let createExprofile = "false"
            if ( (this.state.usertype === "Exhibitor" || this.state.usertype === "Exhibitor Admin" || this.state.usertype === "Sponsor") && global.$registrationform.setup[global.$tabid].createprofile) {
                if (this.state.mailstatus == "new") {
                    createExprofile = String(global.$registrationform.setup[global.$tabid].createprofile)
                }
            } 
  
            // add promocode and its details
            this.setPromocodeDetails(user,reginfo);

            // payment sub functions
            this.setCommonPaymentCondition(reginfo,user)
            
            // default event functions
            this.setDefaultEventChanges(user,reginfo);
            
            // get promocode regitems and  enable create promocode function
            this.setcreatepromocodeDetails()
            
             
            let myInit =
            {
                body:
                {
                    'code': global.$code,
                    'command': 'processRegistration',
                    'userid': this.state.userid,
                    'fname': reginfo.fname,
                    'lname': reginfo.lname,
                    'company': reginfo.company,
                    'title': reginfo.title,
                    'phone': reginfo.phone,
                    'email': reginfo.email,
                    'linkedin': reginfo.linkedin,
                    'twitter': reginfo.twitter,
                    'fb': reginfo.facebook,
                    'country': reginfo.country,
                    'state': reginfo.state,
                    'city': reginfo.city,
                    'street': reginfo.street,
                    'zip': reginfo.zip,
                    'regid': reginfo.regid,
                    'regtype':'',
                    'photo': reginfo.photo,
                    'memberid': reginfo.memberid,
                    'tag': 'tag',
                    'ep': ' ',
                    'submittedby': global.$regemail,
                    'udate': timestamp.toString(),
                    'visitpoints': reginfo.vpoints,
                    'activitypoints': reginfo.apoints,
                    'totalpoints': reginfo.tpoints,
                    'regstatus': this.state.regstatus,
                    'sessionpoints': reginfo.spoints,
                    'poolid': reginfo.poolid,
                    'password': reginfo.password,
                    'myexpertise': reginfo.myexpertise,
                    'myinterests': reginfo.myinterests,
                    'validscans': reginfo.validscans,
                    'officephone': reginfo.officephone,
                    'currentlocation': moment.tz.guess(),
                    'location': reginfo.location,
                    'locationtime': reginfo.locationtime,
                    'signindate': reginfo.signindate,
                    'lastseen': reginfo.lastseen,
                    'showdirectory': reginfo.showdirectory.toString(),
                    'purchasedate': timestamp.toString(),
                    'itemnames': JSON.stringify(global.$purchaseRegitems),
                    'amount': this.state.amountpaid.toString(),
                    'orderid': this.state.orderid,
                    'paymentid': this.state.paymentid,
                    'signature': this.state.signature,
                    'description': ' ',
                    'paymenttype': global.$selectedPaymentOption,
                    'promocode': this.state.promocode,
                    'promoamount': this.state.promoamount.toString(),
                    'totalamount': this.state.totalamount.toString(),
                    'amountpaid': this.state.amountpaid.toString(),
                    'paymentmode': global.$selectedPaymentOption,
                    'promocodes': this.state.promocode,
                    'guestname': this.state.guestname,
                    'pendingamount': this.state.amountpending.toString(),
                    'refundamount': '0',
                    'mailstatus': this.state.mailstatus,
                    'paymentuserid': global.$regemail,
                    'reguser': user,
                    'emailtype': this.state.emailtype,
                    'paymentstatus': this.state.paymentstatus,
                    'type': 'E',
                    'etype': "D",
                    'booth': " ",
                    'category': this.state.category,
                    'category1': reginfo.service,
                    'splevel': this.state.splevel,
                    'logo': " ",
                    'contact': reginfo.fname + " " + reginfo.lname,
                    'cdate': timestamp.toString(),
                    'id': this.state.regid,
                    'keywords': " ",
                    'url': reginfo.url,
                    'adfile': " ",
                    'adsrc': " ",
                    'auth': " ",
                    'points': "0",
                    'msg1': ' ',
                    'msg2': ' ',
                    'service': reginfo.service,
                    'desc': reginfo.desc,
                    'createdby': reginfo.email,
                    'sortorder': "0",
                    'videourl': " ",
                    'video1': " ",
                    'video2': " ",
                    'video3': " ",
                    'image1': " ",
                    'image2': " ",
                    'image3': " ",
                    'file1': " ",
                    'file2': " ",
                    'file3': " ",
                    'meeting1': " ",
                    'meeting2': " ",
                    'meeting3': " ",
                    'data1': reginfo.data1,
                    'data2': reginfo.data2,
                    'data3': reginfo.data3,
                    'data4': reginfo.data4,
                    'data5': this.state.dateregistered.toString(),
                    'schedule': " ",
                    "q1": this.state.q1,
                    "q2": this.state.q2,
                    "q3": this.state.q3,
                    "q4": this.state.q4,
                    "q5": this.state.q5,
                    "q6": this.state.q6,
                    "q7": this.state.q7,
                    "q8": this.state.q8,
                    "userstype": this.state.usertype,
                    "guests": reginfo.guests,
                    "sessions": reginfo.sessions,
                    "regnotes": reginfo.regnotes,
                    "checkinstatus": reginfo.checkinstatus,
                    "mname": reginfo.mname,
                    "regitemsforsignupcount": global.$regItemsForSignupCount,
                    "regpromocodesforsignupcount": this.state.regpromocodesforsignupcount,
                    "registereditemstoreducesignupcount": JSON.stringify(global.$registereditems),
                    "regitemtoreducecount": JSON.stringify(global.$regitemtoreducecount),
                    'regitemsjson':JSON.stringify(this.state.regitemsjson),
                    "createprofile":createExprofile,
                    "createpromocode":this.state.createpromocode,
                    "promocodesjson":this.state.promocodes,
                    "confirmpassword":this.state.confirmpassword,
                    "signupsessions":this.state.signupsessions
                },
                response: true,
                convertEmptyValues: true,
                headers: {}
            }        
            
            let apiName = serviceNames.getRegistrationService();
            let path = ''; //replace this with the path you have configured on your API          
            const result = await API.post(apiName, path, myInit);
                if (result.data.status === true) {                                   
                    if (CommonFunctions.isUserLoggedin()) {
                        this.finishRegistration(user,"update");
                        await this.getAttendeeInfo(global.$loginid, String(global.$userstype).toLowerCase())
                    } 
                    else {
                        this.finishRegistration(user,"new")
                    }
                }
                else {
                    this.setState({ hideSpinner: true,message:CommonFunction2.messageFunction().errormsg1})
                }

        } catch (error) {
            this.setState({ hideSpinner: true, message:CommonFunction2.messageFunction().errormsg1})
            console.log(error);
        }

    }
   
    
    setRegstatus(user) {
        if (user == usertype.ATTENDEE) {
            global.$isattendeeregistered = true;
            this.setState({ attendeeregistration: true,hideSpinner: true });
        }
        else if (user == usertype.GUEST) {
            global.$isguestregistered = true;
            this.setState({ guestregistration: true, hideSpinner: true });
        }
    }

    // add empty space and default data added in registration details
    setEmptyReginfo(attendeeinfo,user) {
        if (attendeeinfo.fname == "") {
            attendeeinfo.fname = " ";
        }
        if (attendeeinfo.lname == "") {
            attendeeinfo.lname = " ";
        }
        if (attendeeinfo.phone == "") {
            attendeeinfo.phone = " ";
        }
        if (attendeeinfo.memberid == "") {
            attendeeinfo.memberid = " ";
        }
        if (attendeeinfo.company == "") {
            attendeeinfo.company = " ";
        }
        if (attendeeinfo.title == "") {
            attendeeinfo.title = " ";
        }
        if (attendeeinfo.officephone == "") {
            attendeeinfo.officephone = " ";
        }
        if (attendeeinfo.street == "") {
            attendeeinfo.street = " ";
        }
        if (attendeeinfo.city == "") {
            attendeeinfo.city = " ";
        }
        if (attendeeinfo.zip == "") {
            attendeeinfo.zip = " ";
        }
        if (attendeeinfo.state == "") {
            attendeeinfo.state = " ";
        }
        if (attendeeinfo.country == "") {
            attendeeinfo.country = " ";
        }
        if (attendeeinfo.location == "" || attendeeinfo.location == null) {
            attendeeinfo.location = moment.tz.guess();
        }
        if (attendeeinfo.linkedin == "") {
            attendeeinfo.linkedin = " ";
        }
        if (attendeeinfo.facebook == "") {
            attendeeinfo.facebook = " ";
        }
        if (attendeeinfo.twitter == "") {
            attendeeinfo.twitter = " ";
        }
        if (attendeeinfo.myexpertise == "") {
            attendeeinfo.myexpertise = " ";
        }
        if (attendeeinfo.myinterests == "") {
            attendeeinfo.myinterests = " ";
        }
        if (attendeeinfo.data1 == "") {
            attendeeinfo.data2 = " ";
        }
        if (attendeeinfo.data3 == "") {
            attendeeinfo.data3 = " ";
        }
        if (attendeeinfo.lastseen == "") {
            attendeeinfo.lastseen = " ";
        }
        if (attendeeinfo.poolid == "" || attendeeinfo.poolid == null) {
            attendeeinfo.poolid = global.$settings.poolids.userpoolid;
        }
        if (attendeeinfo.submittedby == "") {
            attendeeinfo.submittedby = " ";
        }
        if (attendeeinfo.validscans == "") {
            attendeeinfo.validscans = " ";
        }
        if (attendeeinfo.password == "") {
            attendeeinfo.password = this.state.password;
        }
        if (attendeeinfo.regid == "") {
            attendeeinfo.regid = this.state.regid;
        }
        if (attendeeinfo.regtype == "") {
            attendeeinfo.regtype = this.state.regitems;
        }
        if (attendeeinfo.mname == "") {
            attendeeinfo.mname = " ";
        }
        if (attendeeinfo.locationtime == "") {
            attendeeinfo.locationtime = moment.tz(attendeeinfo.location).format('mm/dd/yyyy hh:mm:ss A');
        }
        if (attendeeinfo.signindate == "") {
            attendeeinfo.signindate = " ";
        }


        if (attendeeinfo.guests === "" || attendeeinfo.guests === " ") {
            if (user === usertype.ATTENDEE) {
                if (global.$guestexist === true) {   
                    if (global.$reguestemail != null) {       
                       attendeeinfo.guests = global.$reguestemail;
                    }
                    else {
                        attendeeinfo.guests = " ";
                    }
                }
            }
        }

        if (global.$guestexist === true && global.$guestfname != null) {
            attendeeinfo.guestname = global.$guestfname + " " + global.$guestlname;
            this.state.guestname = global.$guestfname + " " + global.$guestlname;
        }
      
        if (attendeeinfo.sessions === "") {
            attendeeinfo.sessions = " ";
        }
        if (attendeeinfo.checkinstatus === "") {
            attendeeinfo.checkinstatus = " ";
        }
        if (attendeeinfo.regnotes === "") {
            attendeeinfo.regnotes = " ";
        }
        if (attendeeinfo.photo === "") {
            attendeeinfo.photo = "common_profile.png";
        }
        if (attendeeinfo.data1 === "") {
            attendeeinfo.data1 = " ";
        }
        if (attendeeinfo.data2 === "") {
            attendeeinfo.data2 = " ";
        }
        if (attendeeinfo.data3 === "") {
            attendeeinfo.data3 = " ";
        }
        if (attendeeinfo.data4 === "") {
            attendeeinfo.data4 = " ";
        }
        if (attendeeinfo.data5 === "") {
            attendeeinfo.data5 = " ";
        }
        if (attendeeinfo.service === "") {
            attendeeinfo.service = " ";
        }
        if (attendeeinfo.desc === "") {
            attendeeinfo.desc = " ";
        }
        if (attendeeinfo.guests === "") {
            attendeeinfo.guests = " ";
        }
        if (this.state.guestname === "") {
            this.state.guestname = " ";
        }
        if (attendeeinfo.url === "") {
            attendeeinfo.url = " ";
        }

        // Added this check for metro automatic badge In-Person/Virtual
        attendeeinfo.data4 = CommonFunction2.setbadge(user);

        attendeeinfo.email = (attendeeinfo.email).trim();
        attendeeinfo.email = (attendeeinfo.email).toLowerCase();
        global.$regemail = (global.$regemail).trim();
        global.$regemail = (global.$regemail).toLowerCase();

        if (global.$regitemtoreducecount == null) {
            global.$regitemtoreducecount = " ";
        }
        if (global.$regItemsForSignupCount == null) {
            global.$regItemsForSignupCount = " "
        }

        return attendeeinfo;
    }

    // add registration questions 
    setRegQuestions(){
    
        if (global.$regquestionsinfo.question1 == "Select") {
            global.$regquestionsinfo.question1 = " ";
        }
        if (global.$regquestionsinfo.question2 == "Select") {
            global.$regquestionsinfo.question2 = " ";
        }
        if (global.$regquestionsinfo.question3 == "Select") {
            global.$regquestionsinfo.question3 = " ";
        }
        if (global.$regquestionsinfo.question4 == "Select") {
            global.$regquestionsinfo.question4 = " ";
        }
        if (global.$regquestionsinfo.question5 == "Select") {
            global.$regquestionsinfo.question5 = " ";
        }
        if (global.$regquestionsinfo.question6 == "Select") {
            global.$regquestionsinfo.question6 = " ";
        }
        if (global.$regquestionsinfo.question7 == "Select") {
            global.$regquestionsinfo.question7 = " ";
        }
        if (global.$regquestionsinfo.question8 == "Select") {
            global.$regquestionsinfo.question8 = " ";
        }

        this.state.q1 = CommonFunction2.setQuestionAnswer(global.$regquestionsinfo.question1title,global.$regquestionsinfo.question1value,global.$regquestionsinfo.question1)
        this.state.q2 = CommonFunction2.setQuestionAnswer(global.$regquestionsinfo.question2title,global.$regquestionsinfo.question2value,global.$regquestionsinfo.question2);
        this.state.q3 = CommonFunction2.setQuestionAnswer(global.$regquestionsinfo.question3title,global.$regquestionsinfo.question3value,global.$regquestionsinfo.question3);
        this.state.q4 = CommonFunction2.setQuestionAnswer(global.$regquestionsinfo.question4title,global.$regquestionsinfo.question4value,global.$regquestionsinfo.question4);
        this.state.q5 = CommonFunction2.setQuestionAnswer(global.$regquestionsinfo.question5title,global.$regquestionsinfo.question5value,global.$regquestionsinfo.question5);
        this.state.q6 = CommonFunction2.setQuestionAnswer(global.$regquestionsinfo.question6title,global.$regquestionsinfo.question6value,global.$regquestionsinfo.question6);
        this.state.q7 = CommonFunction2.setQuestionAnswer(global.$regquestionsinfo.question7title,global.$regquestionsinfo.question7value,global.$regquestionsinfo.question7);
        this.state.q8 = CommonFunction2.setQuestionAnswer(global.$regquestionsinfo.question8title,global.$regquestionsinfo.question8value,global.$regquestionsinfo.question8);
        
        // if (global.$code === "wspma2021") {
        //     if (global.$regquestionsinfo.question1 == "Yes") {
        //         this.state.showdirectory = "true";
        //     }
        //     else {
        //         this.state.showdirectory = "false" 
        //     }
        // }

        
    }

    // add attendee payment details
    setAttendeePayment(){

        this.state.totalamount = global.$regamount;

        if (global.$regamount === 0) {
            if (global.$deferredpromo === "Y") {               
                this.state.paymentstatus = status.PENDING;
                this.state.regstatus = status.CONFIRMED;
                this.state.amountpending = 0;
                
                if (global.$deferredpromoamount != null) {
                    this.state.amountpending = global.$deferredpromoamount;
                    
                }
            }
            else {
                this.state.paymentstatus = status.FREE;
                this.state.regstatus = status.CONFIRMED;      // free registration
            }
        }
        else if (global.$regamount !== 0 &&  CommonFunction2.checkPaymentType(paymenttype.CHECK)) {
            this.state.paymentstatus = status.PENDING;
            this.state.amountpaid = 0;
            this.state.amountpending = global.$regamount;
            
        }
        else if (global.$regamount !== 0 &&  CommonFunction2.checkPaymentType(paymenttype.PAY_BY_CHECK)) {
            this.state.paymentstatus = status.PENDING;
            this.state.amountpaid = 0;
            this.state.amountpending = global.$regamount;
            
        }
        else if (global.$regamount !== 0 && CommonFunction2.checkPaymentType(paymenttype.PAY_LATER)) {           
            this.state.paymentstatus = status.PENDING;
            this.state.amountpaid = 0;
            this.state.amountpending = global.$regamount;           
        }
        else if (global.$regamount !== 0 && CommonFunction2.checkPaymentType(paymenttype.PAYROLL)) {
            this.state.paymentstatus = status.PENDING;
            this.state.amountpaid = 0;
            this.state.amountpending = global.$regamount;         
        }
        else if (global.$regamount !== 0 && CommonFunction2.checkPaymentType(paymenttype.CREDIT_CARD)) {
            this.setPromoPaymentDetails()
        }
        else if (global.$regamount !== 0 && CommonFunction2.checkPaymentType(paymenttype.DEBIT_CARD)) {
            this.setPromoPaymentDetails()
        }
        else if (global.$regamount !== 0 && CommonFunction2.checkPaymentType(paymenttype.ACH)) {
            this.setPromoPaymentDetails()
        }
        else if (global.$regamount !== 0 && CommonFunction2.checkPaymentType(paymenttype.CARD_READER)) {
            this.setPromoPaymentDetails()
        }        
        else if (global.$regamount !== 0 && CommonFunction2.checkPaymentType(paymenttype.PAY_ONLINE)) {
            this.setPromoPaymentDetails()
        }
    }

    // check promocode payment 
    setPromoPaymentDetails(){
        if (global.$deferredpromo === "Y") {
            this.state.paymentstatus = "pending";
            this.state.amountpending = 0;
            if (global.$deferredpromoamount != null) {
                this.state.amountpending = global.$deferredpromoamount;
            }
            this.state.amountpaid = global.$regamount;
        }
        else {
            this.state.paymentstatus = "paid";
            this.state.amountpaid = global.$regamount;
            this.state.amountpending = 0;
        }
    }

    // add guest payment details
    setGuestPayment(){

        this.state.amountpaid = 0;
        this.state.amountpending = 0;
        this.state.totalamount = 0;
        
        if (global.$regamount === 0) {
            if (global.$deferredpromo === "Y") {
                this.state.paymentstatus = status.PENDING;
                this.state.regstatus = status.CONFIRMED;
                this.state.amountpending = 0;
            }
            else {
                this.state.paymentstatus = status.FREE;
                this.state.regstatus = status.CONFIRMED;      // free registration
            }
        }
        else if (global.$regamount !== 0 &&  CommonFunction2.checkPaymentType(paymenttype.CHECK)) {
            this.state.paymentstatus = status.PENDING;
            this.state.amountpaid = 0;
            this.state.amountpending = global.$regamount;
            
        }
        else if (global.$regamount !== 0 &&  CommonFunction2.checkPaymentType(paymenttype.PAY_BY_CHECK)) {
            this.state.paymentstatus = status.PENDING;
        }
        else if (global.$regamount !== 0 && CommonFunction2.checkPaymentType(paymenttype.PAY_LATER)) {
            this.state.paymentstatus = status.PENDING;
        }
        else if (global.$regamount !== 0 && CommonFunction2.checkPaymentType(paymenttype.PAYROLL)) {
            this.state.paymentstatus = status.PENDING;
        }
        else if (global.$regamount !== 0 && CommonFunction2.checkPaymentType(paymenttype.CREDIT_CARD)) {
            if (global.$deferredpromo === "Y") {
                this.state.paymentstatus = status.PENDING;
            }
            else {
                this.state.paymentstatus = status.PENDING;
            }
        }
        else if (global.$regamount !== 0 && CommonFunction2.checkPaymentType(paymenttype.DEBIT_CARD)) {
            if (global.$deferredpromo === "Y") {
                this.state.paymentstatus = status.PENDING;
            }
            else {
                this.state.paymentstatus = status.PENDING;
            }
        }
        else if (global.$regamount !== 0 && CommonFunction2.checkPaymentType(paymenttype.CARD_READER)) {
            if (global.$deferredpromo === "Y") {
                this.state.paymentstatus = status.PENDING;
            }
            else {
                this.state.paymentstatus = status.PENDING;
            }
        }
    }

    // add promocode and its details
    setPromocodeDetails(user,reginfo) {
        if (global.$regdiscountinfo != null && global.$regdiscountinfo != "" && global.$regdiscountinfo != " ") {
            if (global.$regdiscountinfo.discountvalue != null) {
                this.state.promocode = global.$regdiscountinfo.discountvalue;
                this.state.regpromocodesforsignupcount = global.$regdiscountinfo.discountvalue;
            }
            if (global.$regdiscountinfo.discountprice != null) {
                if (global.$deferredpromo != "Y") {
                    this.state.promoamount = global.$regdiscountinfo.discountprice;
                }
            }
        }

        if (user === usertype.GUEST) {
            this.state.promoamount = '0';
            this.state.promocode = " ";
            this.state.guestname = " ";  
        }

        if (CommonFunctions.isUserLoggedin()) {
            if (user == usertype.ATTENDEE) {
                this.state.regstatus = reginfo.regstatus;
                if (reginfo.promocodes != null && reginfo.promocodes != "" && reginfo.promocodes != " ") {
                    reginfo.promocodes = reginfo.promocodes.trim();
                    this.state.promocode = reginfo.promocodes + "," + this.state.promocode;
                }
                this.state.promoamount = Number(reginfo.promoamount) + Number(this.state.promoamount);
            }
        }
        else {
            if (global.$deferredpromo == "Y") {
                this.state.regstatus = status.CONFIRMED;
            }
        }
    }

    // set default event conditions
    setDefaultEventChanges(user,reginfo){
        if (global.$code == "afpikar2021") {
            if (global.$paymentid != null) {
                this.state.paymentid = global.$paymentid;
            }
        }

        if(EventcodeFunctions.isTravconAttendee(global.$code) && user === usertype.ATTENDEE){
            this.state.guestname = reginfo.guestname;
        }

        this.state.category = this.state.regitemsjson;
        this.state.splevel = this.state.regitemsjson;
        if (this.props.type === "exhibitor" || this.props.type === "sponsor") {
            this.state.category = " ";
            this.state.splevel = " "
        }
    }

    // common payment condition
    setCommonPaymentCondition(reginfo,user) {

        // Check to update the regstatus
        if (CommonFunction2.checkPaymentType(paymenttype.PAY_BY_CHECK) || CommonFunction2.checkPaymentType(paymenttype.CHECK) || CommonFunction2.checkPaymentType(paymenttype.PAYROLL) || CommonFunction2.checkPaymentType(paymenttype.PAY_LATER)) {
            if (global.$registrationform.setup[global.$tabid].payment.confirmedpaymentpending != null && global.$registrationform.setup[global.$tabid].payment.confirmedpaymentpending){
                this.state.regstatus = status.CONFIRMED;
            }
            else {
                this.state.regstatus = "Pending";
            }
            
        }
        else {
            if (global.$deferredpromo === "Y") {
                this.state.regstatus = status.CONFIRMED;
            }
            else {
                this.state.regstatus = status.CONFIRMED;
            }
        }


        if (Number(global.$regamount) == 0) {
            global.$selectedPaymentOption = paymenttype.FREE;
            this.state.selectedpayment = paymenttype.FREE; // free registration
            if (user == usertype.ATTENDEE) {
                if (global.$deferredpromo == "N") {
                    this.state.regstatus = status.PENDING
                }
            }
        }
        else {
            this.state.selectedpayment = global.$selectedPaymentOption;
        }


        if (this.state.paymentstatus == status.FREE) {       
            this.state.regstatus = status.CONFIRMED;
        }
        
        // payment details add Transaction fee
        if (global.$transactionfee != null && global.$transactionfee != "" && global.$regamount > 0 && EventcodeFunctions.isTravconExhibitor(global.$code) && CommonFunction2.checkPaymentType(paymenttype.CREDIT_CARD)) {
            // this.state.regitems = this.state.regitems + ",Transaction Fee - $" + global.$transactionfee;
            // reginfo.regtype = reginfo.regtype + ",Transaction Fee - $" + global.$transactionfee;
            var transobj = {
                id:CommonFunctions.createdatetime().timestamp,
                name:"Transaction Fee",
                price:Number(global.$transactionfee).toFixed(2).toString(),
                type:"payment",
                itemid: "2",
                qty:"1",
                status: "active",
                cdate:CommonFunctions.createdatetime().datatime,
                data1:"",
                data2:"",
                data3:"",
                data4:"",
                data5:""
            }
            global.$purchaseRegitems.push(transobj)
            global.$selectedRegItems.push(transobj)
            
        }

        // payment item converto to .00 (ex: anount = 10 then convert to 10.00)
        this.state.totalamount = parseFloat(this.state.totalamount).toFixed(2);
        this.state.amountpaid = parseFloat(this.state.amountpaid).toFixed(2);
        this.state.amountpending = parseFloat(this.state.amountpending).toFixed(2);
        this.state.promoamount = parseFloat(this.state.promoamount).toFixed(2);

    }

    // update registration add reg item
    setUpdateRegItems(reginfo){
        this.state.regitems = CommonFunctions.getUserSelectedRegItems();
        
        var commonstrings = RegItemStrings.commonConstants;

        // Create a set to keep track of added upgrade IDs
        const addedUpgradeIds = new Set();

        // Create the upgrade object outside of the loop
        var upgrade = {
            id: CommonFunctions.createdatetime().timestamp,
            name: "Booth Upgrade",
            price: Number(global.$upgradepricediff).toFixed(2).toString(),
            type: "booth upgrade",
            itemid: "3",
            qty: "1",
            status: "active",
            cdate: CommonFunctions.createdatetime().datatime,
            data1: "",
            data2: "",
            data3: "",
            data4: "",
            data5: ""
        };

        // Function to add the upgrade object to the arrays and the set
        const addUpgrade = () => {
            global.$selectedRegItems.push(upgrade);
            global.$purchaseRegitems.push(upgrade);
            addedUpgradeIds.add(upgrade.id);
        };

        this.state.regitems.forEach((regitem) => {
            if (regitem.name.includes("Booth Registration") || regitem.name.includes("Booth Bundle Registration")) {
                // Check if the upgrade object's ID is not in the set before adding it
                if (!addedUpgradeIds.has(upgrade.id)) {
                    addUpgrade();
                }
            } else if (
                regitem.name.includes(commonstrings.mainstageticket) ||
                regitem.name.includes(commonstrings.newbiebootcamp) ||
                regitem.name.includes(commonstrings.medicalspanishsun) ||
                regitem.name.includes(commonstrings.medicalspanishwed) ||
                regitem.name.includes(commonstrings.travcon2023medicalspanishsun) ||
                regitem.name.includes(commonstrings.travcon2023medicalspanishwed)
            ) {
                // Check if the upgrade object's ID is not in the set before adding it
                if (!addedUpgradeIds.has(upgrade.id)) {
                    addUpgrade();
                }
            }
            else if (
                global.$registrationform?.boothupgrade && 
                CommonFunctions.isUserLoggedin() && 
                global.$upgradepricediff !== null && 
                global.$upgradepricediff > 0 
            ){
                // Check if the upgrade object's ID is not in the set before adding it
                if (!addedUpgradeIds.has(upgrade.id)) {
                    addUpgrade();
                }
            }
        });
    }
    
    // update attendee payment
    updateAttendeePayment(reginfo){
        if (global.$regamount == 0) {
            if (global.$deferredpromo == "Y") {
                this.state.paymentstatus = status.PENDING;
                this.state.regstatus = status.CONFIRMED;
                if (global.$deferredpromoamount != null) {
                    if (EventcodeFunctions.isTravconExhibitor(global.$code)) {
                        this.state.amountpending = Number(global.$deferredpromoamount);
                    }
                    else {
                        this.state.amountpending = Number(global.$deferredpromoamount) + Number(reginfo.pendingamount);
                    }
                }
            }
            else {
                this.state.paymentstatus = status.FREE;
                this.state.regstatus = status.CONFIRMED;    // free registration
                this.state.amountpending = 0;
            }
        }
        else if (global.$regamount !== 0 &&  CommonFunction2.checkPaymentType(paymenttype.PAY_BY_CHECK)) {
            this.checkUpdatePaymentCondition(paymenttype.PAY_BY_CHECK,reginfo)
        }
        else if (global.$regamount != 0 && CommonFunction2.checkPaymentType(paymenttype.CHECK)) {
            this.checkUpdatePaymentCondition(paymenttype.CHECK,reginfo)
        }
        else if (global.$regamount != 0 && CommonFunction2.checkPaymentType(paymenttype.PAY_LATER)) {
            this.checkUpdatePaymentCondition(paymenttype.PAY_LATER,reginfo)
        }
        else if (global.$regamount != 0 && CommonFunction2.checkPaymentType(paymenttype.PAYROLL)) {
            this.checkUpdatePaymentCondition(paymenttype.PAYROLL,reginfo)
        }
        else if (global.$regamount != 0 && CommonFunction2.checkPaymentType(paymenttype.CREDIT_CARD)) {
            this.checkUpdatePaymentCondition(paymenttype.CREDIT_CARD,reginfo)
        }
        else if (global.$regamount != 0 && CommonFunction2.checkPaymentType(paymenttype.DEBIT_CARD)) {
            this.checkUpdatePaymentCondition(paymenttype.DEBIT_CARD,reginfo)
        }
        else if (global.$regamount != 0 && CommonFunction2.checkPaymentType(paymenttype.ACH)) {
            this.checkUpdatePaymentCondition(paymenttype.ACH,reginfo)
        }
        else if (global.$regamount != 0 && CommonFunction2.checkPaymentType(paymenttype.CARD_READER)) {
            this.checkUpdatePaymentCondition(paymenttype.CARD_READER,reginfo)
        }        
        else if (global.$regamount != 0 && CommonFunction2.checkPaymentType(paymenttype.PAY_ONLINE)) {
            this.checkUpdatePaymentCondition(paymenttype.PAY_ONLINE,reginfo)
        }
    }

    // update guest payment
    updateGuestPayment(){
        if (global.$regamount == 0) {
            if (global.$deferredpromo == "Y") {
                this.state.paymentstatus = status.PENDING;
                this.state.regstatus = status.CONFIRMED;
            }
            else {
                this.state.paymentstatus = status.FREE;
                this.state.regstatus = status.CONFIRMED;    // free registration
            }
        }
        else if (global.$regamount != 0 && CommonFunction2.checkPaymentType(paymenttype.CHECK)) {
            this.state.paymentstatus = status.PENDING;
        }
        else if (global.$regamount != 0 && CommonFunction2.checkPaymentType(paymenttype.PAY_LATER)) {
            this.state.paymentstatus = status.PENDING;
        }
        else if (global.$regamount != 0 && CommonFunction2.checkPaymentType(paymenttype.PAYROLL)) {
            this.state.paymentstatus = status.PENDING;
        }
        else if (global.$regamount != 0 && CommonFunction2.checkPaymentType(paymenttype.CREDIT_CARD)) {
            if (global.$deferredpromo == "Y") {
                this.state.paymentstatus = status.PENDING;
            }
            else {
                this.state.paymentstatus = status.PAID;
            }
        }
        else if (global.$regamount != 0 && CommonFunction2.checkPaymentType(paymenttype.DEBIT_CARD)) {
            if (global.$deferredpromo == "Y") {
                this.state.paymentstatus = status.PENDING;
            }
            else {
                this.state.paymentstatus = status.PAID;
            }
        }
    }

    // update registration -> check payment condtions
    checkUpdatePaymentCondition(type,reginfo){
        if (type === paymenttype.CHECK || type === paymenttype.PAY_LATER ||  type === paymenttype.PAYROLL || type === paymenttype.PAY_BY_CHECK) {
            this.state.paymentstatus = status.PENDING;
            if (this.state.regitems == "" || this.state.regitems == " ") {
                this.state.amountpending = Number(reginfo.pendingamount);
            }
            else {
                this.state.amountpending = Number(global.$regamount);
            }
        }
        else if (type === paymenttype.CREDIT_CARD || type === paymenttype.ACH || type === paymenttype.CARD_READER) {
            if (global.$deferredpromo == "Y") {
                this.state.paymentstatus = status.PENDING;
                if (global.$deferredpromoamount != null) {
                    if (EventcodeFunctions.isTravconExhibitor(global.$code)) {
                        this.state.amountpending = Number(global.$deferredpromoamount);
                    }
                    else {
                        this.state.amountpending = Number(global.$deferredpromoamount) + Number(reginfo.pendingamount);
                    }
                }
                this.state.amountpaid = Number(global.$regamount) + Number(reginfo.amountpaid);
            }
            else {
                this.state.paymentstatus = status.PAID;
                this.state.amountpaid = Number(global.$regamount) + Number(reginfo.amountpaid);
                this.state.amountpending = 0;
            }
        }
        else if (type === paymenttype.DEBIT_CARD || type === paymenttype.PAY_ONLINE) {
            if (global.$deferredpromo == "Y") {
                this.state.paymentstatus = status.PENDING;
                if (global.$deferredpromoamount != null) {
                    this.state.amountpending = Number(global.$deferredpromoamount) + Number(reginfo.pendingamount);
                }
                this.state.amountpaid = Number(global.$regamount) + Number(reginfo.amountpaid);
            }
            else {
                this.state.paymentstatus = status.PAID;
                this.state.amountpaid = Number(global.$regamount) + Number(reginfo.amountpaid);
            }
        }
    }

    // set response message and set some item after registration 
    finishRegistration(user,type){       
        if (type === "new") {
            if (CommonFunction2.checkPaymentType(paymenttype.PAY_BY_CHECK) ) {
                this.state.message = CommonFunction2.messageFunction().Check
            }
            else if (CommonFunction2.checkPaymentType(paymenttype.PAY_LATER) ) {       
                this.state.message = CommonFunction2.messageFunction().PayLater;
            }
            else if (CommonFunction2.checkPaymentType(paymenttype.CREDIT_CARD)) {
                this.state.message = CommonFunction2.messageFunction().Card;
            }
            else if (CommonFunction2.checkPaymentType(paymenttype.CARD_READER)) {
                this.state.message = CommonFunction2.messageFunction().Card;
            }
            else if (CommonFunction2.checkPaymentType(paymenttype.PAYROLL) ) {         
                this.state.message = CommonFunction2.messageFunction().Payroll;
            }
            else if (CommonFunction2.checkPaymentType(paymenttype.ACH) ) {        
                this.state.message = CommonFunction2.messageFunction().ACH;
            }
            else if (CommonFunction2.checkPaymentType(paymenttype.PAY_ONLINE) ) {        
                this.state.message = CommonFunction2.messageFunction().PayOnline;
            }
            else if (CommonFunction2.checkPaymentType(paymenttype.FREE) ) {           
                this.state.message = CommonFunction2.messageFunction().Free;
            }
        }
        else if (type === "update") {
            this.state.message = CommonFunction2.messageFunction().Update;
        }    

        this.setRegstatus(user);
        this.props.hideRegisterButton();
        this.setState( {registered : true});
    }

    // get attendee details -> after update registration
    async getAttendeeInfo(userid, user) {
        let userObj = await UserFunctions.getAttendeeProfile(userid);
            global.$fname = userObj.fname
            global.$lname = userObj.lname
            global.$email = userObj.email
            global.$userid = userObj.userid
            global.$company = userObj.company
            global.$phone = userObj.phone
            global.$title = userObj.title
            global.$regid = userObj.regid
            global.$memberid = userObj.memberid
            global.$attendeetype = userObj.userstype
            global.$userstype = userObj.userstype
            global.$regstatus = userObj.regstatus
            global.$attendeeguests = userObj.guests
            global.$loggedinuserregitems = userObj.regitemsjson;
            global.$regitemsjson = userObj.regitemsjson;
            global.$loggedinuser = userObj; 
            global.$attendeeDetails = userObj;
            if (user === usertype.ATTENDEE) {
                global.$regemail = userObj.userid;
            }
            else if (user === usertype.GUEST) {
                global.$loggedinguest = userObj;
                global.$regguestemail = userObj.userid;
                this.setState({ showtable: true })
            }
    }

    // enable create promocode and get promocode regitems
    setcreatepromocodeDetails(){
        if (global.$registrationform.setup[global.$tabid].createpromocodes && global.$registrationform.setup[global.$tabid].createpromocodes == true) {
            this.state.createpromocode = "true"
            this.state.promocodes = JSON.stringify(global.$registrationform.promocodes)
        }
        
    }

}
export default RegisterAttendee;