import React from 'react';
import { API } from 'aws-amplify';
import { Button } from 'reactstrap';
import Stopwatch from './Stopwatch';

const moment = require('moment-timezone');
var serviceNames = require('../ServiceNameMapper');
var timer;

class SessionCreditTimer extends React.Component {

  constructor(props) {

    super(props);

   //console.log(this.props.minutes)
    var currentTime = new Date();
    var endTime = new Date(currentTime);

    //console.log(currentTime, endTime);

    endTime.setMinutes(parseInt(currentTime.getMinutes()) + parseInt(this.props.minutes));

    //console.log(currentTime, endTime)
    this.state = {
      code: this.props.code,
      begindate: props.begindate,
      enddate: props.enddate,
      startTime: currentTime.getTime(),
      endTime: endTime.getTime(),
      currentTime: currentTime.getTime(),
      timermaindivstyles: global.$session.styles.timer.maindiv,
      timertextstyles: global.$session.styles.timer.text,
      timersubmitbuttonstyles: global.$session.styles.timer.submitbutton,
      showtime: currentTime.getTime()
    };

    this.submitCredit = this.submitCredit.bind(this);


    timer = setInterval(() => {

      //var currentTime = new Date();

      //console.log(this.state.currentTime > this.state.endTime)
      if (this.state.currentTime > this.state.endTime) {
        //console.log("timer ended")
        this.setState({
          currentTime: 'You can claim your credits now',
          showtime: 'You can claim your credits now'
        });
        clearInterval(timer)
      }
      else {

        var currentTime = new Date();

        this.setState({
          currentTime: currentTime.getTime(),
          showtime: currentTime.toLocaleTimeString()
        });
      }

    }, 1000 * 3);

  }

  componentWillUnmount() {
    clearInterval(timer)
  }

  render() {
    if (this.state.showtime == "You can claim your credits now") {
      return (
        <div className="timer-main-div" style={this.state.timermaindivstyles}>
          {this.showSubmitButton()}
        </div>
      )
    }
    else {
      return (
        <div className="timer-main-div" style={this.state.timermaindivstyles}>
          <Stopwatch minutes={this.props.minutes} type={"credittimer"}/>
        </div>
      )
    }
  }

  showSubmitButton() {
    if (this.state.currentTime == "You can claim your credits now") {
      return (
        <div>
          <h6 className="time-watched">Time watched: {this.props.minutes} Mins</h6>
          <Button id="submit" onClick={this.submitCredit} className="timer-button" style={this.state.timersubmitbuttonstyles}>Submit</Button>
        </div>
      )
    }
  }

  submitCredit() {
    let apiName = serviceNames.getBadgeScanService();
    let path = '';

    var time = moment().format('L, LTS');
    let myInit =
    {
      body:
      {
        'code': this.state.code,
        'command': 'postBadgeScan',
        'userid': global.$userid,
        'name': global.$fname + " " + global.$lname,
        'company': global.$company,
        'title': global.$title,
        'phone': global.$phone,
        'tagid': `C#:ID:${this.props.sessionid};N:${this.props.title};P:${this.props.points}`,
        'regid': global.$regid,
        'scannedby': global.$userid,
        'time': time,
        'points': this.props.points
      },
      response: true,
      headers: {}
    }

    //console.log(myInit);
    API.post(apiName, path, myInit).then(response => {

      //console.log(response);

      if (response.err == null) {
        //console.log('postBadgeScan ok');
        // Call the onSend callback with the chatInput message
        //console.log('ChatInput: calling onSend');
        //this.props.onSend(this.state.chatInput);
        document.getElementById("submit").style.display = "none";
        this.props.onSend();
      }
      else {

        //console.log('postBadgeScan failed');

        //console.log(response)
        /*
        this.setState({ 
            showSpinner: false,
            message: "Unable to place your bid. Please try again",
            bidmessagecolor:'color: red'
        });
        */
      }

    }
    ).catch(error => {
      /*
      this.setState({ showSpinner: false,
      message: "Unable to place your bid. Please try again",
      bidmessagecolor:'color: red'});
      */
      //console.log(error);
    });
  }
}

export default SessionCreditTimer;