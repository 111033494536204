/* eslint-disable */
import React, { Component } from 'react';
import Icon from '@mdi/react';
import { mdiPlus } from '@mdi/js';
import FindExhibitorMeetup from './FindExihibitorMeetup';
import { API } from 'aws-amplify';
import MeetupsList from './MeetupsList';
import { Row, Col } from 'reactstrap';

const moment = require('moment-timezone'); //moment-timezone
var timer;
class GetMeetups extends React.Component {
    constructor(props) {

        super(props);
        //console.log("props");

        this.state = {
            code: global.$code,
            bucket: props.bucket,
            dataloaded: false,
            signupdate: [],
            allmeetups: [],
            refreshmeetups: false
        };
        this.refreshMeetups = this.refreshMeetups.bind(this);
        timer = setInterval(() => {
            this.getMySignups()
            this.getAllMeetups()
        }, 180000)
    }

    refreshMeetups() {
        //console.log("meeups refresh");
        // this.setState({ dataloaded: false })
        this.getMySignups()
        this.getAllMeetups();
    }

    render() {
        var meetupconfig = global.$config["meetups"];
        //console.log("get Meetups render");
        //console.log(this.props);

        if (this.state.dataloaded == true) {

            var upcomingmeetups = [];
            var livemeetups = [];
            var finishedmeetups = [];
            var allmeetups = [];
            var currentdate = moment().tz(`${global.$settings.timezone}`).format('L LTS');
            
            this.state.allmeetups.map(meetup=>{         //Sort Meetups with Upcoming,Live.finished
                var currenttime = Date.parse(currentdate);
                var meetupstarttime = Date.parse(meetup.begintime);
                var meetupendtime = Date.parse(meetup.endtime);
    
                if ((meetupstarttime) > (currenttime)) {    //Upcoming
                    upcomingmeetups.push(meetup);
                }
                if ((currenttime) >= (meetupstarttime) && (currenttime) <= (meetupendtime)) { // Live
                    livemeetups.push(meetup);
                }
                if ((currenttime) > (meetupendtime)) {  //Finished
                    finishedmeetups.push(meetup);
                }
                livemeetups.sort(function (a, b) {
                    var nameA = a.begintime.toLowerCase(), nameB = b.begintime.toLowerCase()
                    //console.log(nameA);
                    //console.log(nameB);
                    if (nameA < nameB) //sort string ascending
                        return -1
                    if (nameA > nameB)
                        return 1
                    return 0 //default return value (no sorting) 
                })
                
                upcomingmeetups.sort(function (a, b) {
                    var nameA = a.begintime.toLowerCase(), nameB = b.begintime.toLowerCase()
                    //console.log(nameA);
                    //console.log(nameB);
                    if (nameA < nameB) //sort string ascending
                        return -1
                    if (nameA > nameB)
                        return 1
                    return 0 //default return value (no sorting) 
                })
                finishedmeetups.sort(function (a, b) {
                    var nameA = a.begintime.toLowerCase(), nameB = b.begintime.toLowerCase()
                    //console.log(nameA);
                    //console.log(nameB);
                    if (nameA < nameB) //sort string ascending
                        return -1
                    if (nameA > nameB)
                        return 1
                    return 0 //default return value (no sorting) 
                })
                var array1 = livemeetups.concat(upcomingmeetups);
                var array2 = array1.concat(finishedmeetups);
               //console.log(array2);
                allmeetups = array2
                
            })

            return (
                <div className="meetups-list-section">
                    {/* <FindExhibitorMeetup meetups={this.state.allmeetups} /> */}
                    <MeetupsList meetups={allmeetups} signupdata={this.state.signupdate} onRefreshMeetups={this.refreshMeetups} />
                </div>
            );
        }
        else {
            this.getMySignups();
            if (global.$signedupmeetups == null) {
                this.getMySignups();
            }
            else {
                this.getAllMeetups();
            }
            return (
                <div>
                    <Row>
                        <Col md={6} className="offset-5">
                            <div className="loading-div">
                                <div className="loading-text">Loading....</div>
                            </div>
                        </Col>
                    </Row>
                </div>
            )
        }
    }

    getMySignups() {
        let apiName = 'MeetupService';
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'code': global.$code,
                'command': 'getmysignups',
                'userid': global.$userid,
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }

        //console.log(myInit);
        API.post(apiName, path, myInit).then(response => {
            //console.log(response);

            if (response.err == null && response.data != null) {
                //console.log(response.data.Items);
                var signupdata = this.getSignupTableObjects(response.data.Items);
                //console.log(signupdata);
                global.$signedupmeetups = signupdata;
                this.setState({
                    signupdata: signupdata
                });

            }
            else {
                //console.log(response.err);
            }
        }
        ).catch(error => {
            //console.log(error)
        });
    }
    getSignupTableObjects(objs) {
        //console.log(objs);

        const tableObjs = []

        for (var i = 0; i < objs.length; i++) {
            var signupObj = objs[i]

            //console.log(signupObj)

            var newObj = Object()

            newObj.userid = signupObj.userid.S
            newObj.ownerid = signupObj.ownerid.S
            newObj.signupid = signupObj.signupid.S
            newObj.status = signupObj.signupstatus.S
            newObj.signuptype = signupObj.signuptype.S
            newObj.data1 = signupObj.data1.S
            newObj.data2 = signupObj.data2.S
            newObj.data3 = signupObj.data3.S
            newObj.data4 = signupObj.data4.S
            newObj.groupname = signupObj.groupname.S

            tableObjs.push(newObj)
        }

        //console.log(tableObjs)

        return tableObjs
    }
    getAllMeetups(event) {
        let apiName = 'MeetupService';
        let path = ''; //replace this with the path you have configured on your API

        let myInit =
        {
            body:
            {
                'command': 'getAllMeetups',
                'code': global.$code,
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //console.log(myInit);
        API.post(apiName, path, myInit).then(response => {
            //console.log(response);
            if (response.err == null) {
                var meetups = this.getMeetupTableObjs(response.data.Items);
               //console.log(meetups)
                this.setState({
                    allmeetups: meetups,
                    dataloaded: true
                });
            }
            else {
            }

        }).catch(error => {
            //console.log(error)
        });
    }

    getMeetupTableObjs(objs) {
       //console.log("");
        const tableObjs = [];

       //console.log("");
        for (var i = 0; i < objs.length; i++) {
            var meetupObj = objs[i]

            //console.log(meetupObj)

            var newObj = Object()

            newObj.meetupid = meetupObj.meetupid.S
            newObj.ownerid = meetupObj.ownerid.S
            newObj.title = meetupObj.title.S
            newObj.begintime = meetupObj.begintime.S
            newObj.endtime = meetupObj.endtime.S
            newObj.connectiontype = meetupObj.connectiontype.S
            newObj.connectionurl = meetupObj.connectionurl.S
            newObj.status = meetupObj.meetupstatus.S
            newObj.signupmax = meetupObj.signupmax.N
            newObj.signuprequired = meetupObj.signuprequired.S
            newObj.signupcount = meetupObj.signupcount.N
            newObj.desc = meetupObj.description.S
            newObj.data1 = meetupObj.data1.S
            newObj.data2 = meetupObj.data2.S
            newObj.data3 = meetupObj.data3.S
            newObj.data4 = meetupObj.data4.S
            newObj.data5 = meetupObj.data5.S
            newObj.video = meetupObj.video.S
            newObj.photo = meetupObj.photo.S


            tableObjs.push(newObj);
        }
        //console.log(livemeetups);
        //console.log(upcomingmeetups);
        //console.log(finishedmeetups);

        return tableObjs;
    }

} //Class

export default GetMeetups;