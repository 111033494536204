import React from 'react';
import { Row, Col } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';
import ScheduleSpeakers from './ScheduleSpeakers.jsx';
import SessionVideos from './SessionVideos.jsx';
import SessionHandouts from './SessionHandouts.jsx';
import SessionCheckIns from './SessionCheckIns.jsx';
import SessionCheckIns2 from './SessionCheckIns2.jsx';
import SessionGame from './SessionGame.jsx';

class SessionWithoutTabs extends React.Component {
    constructor(props) {
        super(props);
        //console.log(props);
        var sessioncheckins = "N";
        if (global.$session.enablesessioncheckins == null) {
            sessioncheckins = "N";
        }
        else {
            sessioncheckins = global.$session.enablesessioncheckins
        }

        this.state = {
            code: global.$code,
            titlestyle: global.$session.styles.sessiontitle,
            descstyle: global.$session.styles.sessiondesc,
            timestyle: global.$session.styles.sessiontime,
            speakers: this.props.session.SP,
            curenttime: '',
            sessionstarttime: '',
            sessionendtime: '',
            enablesessioncheckins: sessioncheckins,
            checkins: this.props.session.CHECKINS
        };
        //console.log(this.state);
        //console.log(global.$session)
        if (this.state.speakers == "" || this.state.speakers == " ") {
            this.state.speakers = "empty";
        }

        if (this.state.checkins == "" || this.state.checkins == " " || this.state.checkins == null) {
            this.state.checkins = "empty";
        }
    }

    render() {

        return (
            <div style={this.state.divstyles}>
                <Row>
                    <Col md={12}>
                        {
                            (this.props.curenttime >= this.props.sessionstarttime) ?
                                (
                                    <div>
                                        {
                                            this.state.enablesessioncheckins.includes("Y") ?
                                                (
                                                    <div>
                                                        {
                                                            this.state.checkins.includes("empty") ?
                                                                (
                                                                    ''
                                                                )
                                                                :
                                                                (
                                                                    <SessionCheckIns2 session={this.props.session} />
                                                                )
                                                        }
                                                    </div>
                                                )
                                                :
                                                ('')
                                        }
                                    </div>
                                )
                                :
                                ('')
                        }
                        <div className="video-without-tabs">
                            <br />
                            <SessionVideos session={this.props.session} />
                        </div>
                        <br />
                        <div>
                            {/*<div style={this.state.titlestyle}>{this.props.session.TITLE}</div>
                            <p style={this.state.timestyle}>{this.props.begindate} - {this.props.enddate}</p>*/}
                            <br />
                            <div style={this.state.descstyle}>{ReactHtmlParser(this.props.session.DESCRIPTION)}</div>
                            <div className="handouts">
                                <SessionHandouts
                                    beginDateFormatter={this.props.begindate}
                                    endDateFormatter={this.props.enddate}
                                    session={this.props.session}
                                />
                            </div>
                            <div className="game">
                                <SessionGame session={this.props.session}/>
                            </div>
                        </div>
                        <div>
                            {
                                this.state.speakers.includes("empty") ?
                                    (
                                        ''
                                    )
                                    :
                                    (
                                        <div>
                                            <div dangerouslySetInnerHTML={{ __html: global.$session.speakerstitle }}></div>
                                            <ScheduleSpeakers speakers={this.props.session.SP} SID={this.props.session.SID} />
                                        </div>

                                    )
                            }
                        </div>
                        <div>
                            {/* {
                                (this.props.curenttime >= this.props.sessionstarttime) ?
                                    (
                                        <div>
                                            {
                                                this.state.enablesessioncheckins.includes("Y") ?
                                                    (
                                                        <div>
                                                        {
                                                            this.state.checkins.includes("empty")?
                                                            (
                                                             ''   
                                                            )
                                                            :
                                                            (
                                                                <SessionCheckIns session={this.props.session}/>
                                                            )
                                                        }
                                                    </div>
                                                    )
                                                    :
                                                    ('')
                                            }
                                        </div>
                                    )
                                    :
                                    ('')
                            } */}
                        </div>
                        <br />
                    </Col>
                </Row>
            </div>
        )
    }
}
export default SessionWithoutTabs;


