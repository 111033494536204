import React from 'react';
import CommonFunctions from '../RegCommon/CommonFunctions';

class RegItemCheckbox extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            regitem: props.regitem,
            groupname: props.groupname,
            disableregitem: props.disableregitem,
            signupsremaining: props.signupsremaining
        }
    }

    render() {
        return (
            <div className='input-wrapper-section checkbox-input-wrapper'>
                {this.renderCheckboxInput(this.state.regitem, this.state.groupname, this.state.disableregitem)}
                {this.renderSignupsRemaining(this.state.signupsremaining)}
            </div>
        );
    }

    renderCheckboxInput(regitem, groupname, disableregitem) {
        // If user already registered for this reg item make this item as selected
        if (CommonFunctions.isUserAlreadyRegisteredForThisItem(regitem.data5)) {
            return (
                <input
                    type={regitem.inputtype}
                    className="reg-checkbox"
                    name={groupname}
                    value={regitem.name}
                    checked
                    disabled
                    onChange={() => this.handleCheckBox(regitem, groupname)}
                    key={regitem.data5}
                />
            )
        } else {
            return (
                <input
                    type={regitem.inputtype}
                    className="reg-checkbox"
                    name={groupname}
                    value={regitem.name}
                    // checked={regitem.isChecked}
                    onChange={() => this.handleCheckBox(regitem, groupname)}
                    disabled={disableregitem}
                    key={regitem.data5}
                />
            )
        }
    }

    renderSignupsRemaining(signupsremaining) {
        return (
            <div className="remaining-count available-count-text">{signupsremaining}</div>
        )
    }

    handleCheckBox(selected, groupname) {
        this.updateSelectedRegItemCheckbox(selected, groupname);
       // this.props.conditionalRegitems(selected)
    }

    updateSelectedRegItemCheckbox(selected, groupname) {
        //console.log(selected, groupname)
        global.$regitems.map(regitem => {
            if (regitem.groupname == groupname) {
                if (regitem.data5 == selected.data5) {
                    regitem.isChecked = !regitem.isChecked;   // EX: item is not checked in the array once the user clicks update it to true(opposite of what is already in array)
                    this.props.conditionalRegitems(regitem)
                    if (regitem.isChecked == true) {
                        regitem.itemprice = selected.amount
                        regitem.price = selected.amount
                        regitem.itemid = selected.data5
                    }
                    else {
                        regitem.itemprice = "0";
                        regitem.price = 0
                        regitem.itemid = ''
                    }
                }
            }
        })
        this.props.refreshComponent();
    }
}

export default RegItemCheckbox;