import React from 'react'
import { API } from 'aws-amplify';
import './Common.css'
import commonProfile from '../../images/common_profile.png';
import Avatar from 'react-avatar';
var serviceNames = require('../../ServiceNameMapper')

var attendees =
  [

  ]

class LoadPrivateImg extends React.Component {
  constructor(props) {
    //console.log(props)
    //console.log("constructor");
    super(props);

    var imageFile = props.id + '.png'
    //console.log(imageFile);

    this.state = {
      //imgid: props.id,
      imgid: imageFile,
      code: props.code,
      dataloaded: false,
      imgurl: '',
      fname: '',
      lname: ''
    };
    //console.log(this.state);
  }

  render() {
    //console.log("privateimg: render");
    if (this.state.dataloaded) {   //border-radius:50%;object-fit:contain;
      //console.log(this.state.imgurl)
      return (
        <div>
          {
            this.state.imgurl.includes("common_profile") ?
              (
                <div>
                  {
                    this.props.component.includes("chat") ?
                      (
                        <Avatar className="chat-avatar" name={`${this.props.name}`} />
                      )
                      :
                      ('')
                  }
                  {
                    this.props.component.includes("delegates") ?
                      (
                        <Avatar className="attendee-avatar" name={`${this.props.name}`}/>
                      )
                      :
                      ('')
                  }
                  {
                    this.props.component.includes("posts") ?
                      (
                        <Avatar className="posts-avatar" name={`${this.props.name}`} />
                      )
                      :
                      ('')
                  }
                  {
                    this.props.component.includes("attendee-conversations") ?
                      (
                        <Avatar className="attendee-conversations-avatar" name={`${this.props.name}`}/>
                      )
                      :
                      ('')
                  }
                  {
                    this.props.component.includes("bids") ?
                      (
                        <Avatar className="chat-avatar" name={`${this.props.name}`} />
                      )
                      :
                      ('')
                  }
                  {
                    this.props.component.includes("meetups") ?
                      (
                        <Avatar className="meetup-avatar" name={`${this.props.name}`} />
                      )
                      :
                      ('')
                  }
                </div>
              )
              :
              (
                <img src={this.state.imgurl} height={this.props.height} width={this.props.width} className="private-img" />
              )
          }
        </div>
      )
    }
    else {
      if (this.state.imgid != undefined) {
        if (this.state.imgid.includes(".png") || this.state.imgid.includes(".jpg") || this.state.imgid.includes(".gif")) {
          this.getS3PrivateImg()
        }
        else {
          if (global.$attendeejson != undefined) {
            this.findImgFilename()
          }
          else {
            this.getUsers();
          }
        }
      }
      return (<div></div>)
    }
  }


  getS3PrivateImg() {

    this.state.imgid = this.state.imgid.replace(/ /g, '_');
    this.state.imgid = this.state.imgid.replace(/@/g, '_');
    let apiName = serviceNames.getWebsiteService();
    let path = ''; //replace this with the path you have configured on your API
    let myInit = {
      body:
      {
        'code': this.state.code,
        'command': 'getS3PrivateImg',
        'bucket': 'wesicore',
        'key': 'event360/' + this.state.code + '/upload/profiles/' + this.state.imgid
      },
      response: true,
      convertEmptyValues: true,
      headers: {}
    }
    //console.logmyInit);
    API.post(apiName, path, myInit).then(response => {

      //console.log(response);

      if (response.err == null && response.data != null) {
        if (response.data.errorType == "NoSuchKey") {
          //console.log(response.data);
          this.setState({
            imgurl: "common_profile.png",
            dataloaded: true
          })
        }
        else {
          this.setState({
            imgurl: "data:image/jpeg;base64," + response.data,
            dataloaded: true
          });
        }

        //global.$imageUrl = "data:image/jpeg;base64,"+response.data;
        //console.log(global.$imageUrl);
        //console.log(this.state.imgurl);

      }
      else {
        //console.log("getS3PrivateImg - updating state ")
      }
    }
    ).catch(error => {
      //console.log(error.response)
    });
  }

  getAttendeesTableObjects(objs) {
    //console.log(objs);

    const tableObjs = []

    for (var i = 0; i < objs.length; i++) {
      var attendeeObj = objs[i]

      ///console.log(attendeeObj)

      var newObj = Object()

      newObj.fname = attendeeObj.fname.S
      newObj.lname = attendeeObj.lname.S
      newObj.photo = attendeeObj.photo.S
      newObj.title = attendeeObj.title.S
      newObj.userid = attendeeObj.userid.S
      newObj.email = attendeeObj.email.S
      newObj.phone = attendeeObj.phone.S
      newObj.company = attendeeObj.company.S
      newObj.myinterests = attendeeObj.myinterests.S
      newObj.myexpertise = attendeeObj.myexpertise.S
      newObj.street = attendeeObj.street.S
      newObj.city = attendeeObj.city.S
      newObj.state = attendeeObj.state.S
      newObj.zip = attendeeObj.zip.S
      newObj.linkedin = attendeeObj.linkedin.S
      newObj.twitter = attendeeObj.twitter.S
      newObj.facebook = attendeeObj.fb.S
      newObj.showdirectory = attendeeObj.showdirectory.S
      newObj.data1 = attendeeObj.data1.S
      newObj.data2 = attendeeObj.data2.S
      newObj.data3 = attendeeObj.data3.S
      newObj.data4 = attendeeObj.data4.S
      newObj.q1 = attendeeObj.q1.S.substring(
        attendeeObj.q1.S.lastIndexOf(";answer:") + 8);

      newObj.q2 = attendeeObj.q2.S.substring(
        attendeeObj.q2.S.lastIndexOf(";answer:") + 8);

      tableObjs.push(newObj)
    }

    //console.log(tableObjs)

    return tableObjs
  }

  getUsers() {
    let apiName = serviceNames.getUserService();
    let path = ''; //replace this with the path you have configured on your API
    let myInit = {
      body:
      {
        'code': this.state.code,
        'command': 'getUsers'
      },
      response: true,
      convertEmptyValues: true,
      headers: {}
    }
    //console.log(myInit);

    API.post(apiName, path, myInit).then(response => {
      //console.log ("response.data ===> " + response.data + "response.err ===> " + response.err);

      if (response.err == null && response.data != null) {
        //console.log("getAttendeesTableObjects - Success!!");
        //console.log(response.data.Items);

        attendees = this.getAttendeesTableObjects(response.data)

        global.$attendeejson = attendees;
        //console.logattendees.photo)
        this.findImgFilename()
      }
      else {
        //console.log("getAttendeesTableObjects - failed!!");
        //console.log(response.err);
      }
    }
    ).catch(error => {
      //console.log(error.response)
    });
  }

  findImgFilename() {

    global.$attendeejson.find(globalAttendee => {
      if (globalAttendee.userid == this.props.id) {
        this.setState({
          imgid: globalAttendee.photo,
          fname: globalAttendee.fname,
          lname: globalAttendee.lname
        });

        //console.log(this.state.imgid)
        if (this.state.imgid == " " || this.state.imgid == "" || this.state.imgid == "common_profile.png" || this.state.imgid.includes("common_profile")) {
          this.setState({ imgurl: "common_profile.png" });
        }
        else {
          this.getS3PrivateImg();
        }
      }
    })
  }

}
export default LoadPrivateImg;
