import React from 'react';
import './Schedule.css';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { Input, Row, Col } from 'reactstrap';
import ScheduleItem from './ScheduleItem';
import Schedule2 from './Schedule';
import LiveSessionItem from './LiveSessionItem';

const moment = require('moment-timezone');

/*

- Schedule tabs component

*/

var timer;

class LiveSessions extends React.Component {
    constructor(props) {
        super(props);
        //console.log(props)
        this.state = {
            code: global.$code,
            s3: global.$s3,
            bucket: global.$s3bucket,
            dataloaded: false,
            scheduletags: props.stags,
            activeschedule: props.activetab,
            livesessionsexist: false
        };
        //console.log("Schedule Tabs");
    }

    render() {
        return (
            <div>
                {this.getSchedule()}

            </div>
        );
    }

    getSchedule() {
        var stag = this.props.stag;
        //global.$livesessionexist = false;
        //console.log(stag);
        const results = global.$schedulejson.filter(schedule => {
            if (this.props.search == "")
                return true;
            else {
                if (schedule.TITLE.toLocaleLowerCase().includes(this.props.search.toLocaleLowerCase()) || schedule.DESCRIPTION.toLocaleLowerCase().includes(this.props.search.toLocaleLowerCase()) || schedule.T1.toLocaleLowerCase().includes(this.props.search.toLocaleLowerCase()) || schedule.SP.toLocaleLowerCase().includes(this.props.search.toLocaleLowerCase()))
                    return true;
                else
                    return false;
            }
        })
        //console.log(stag, activetab);

        return results.map(schedule => {

            //console.log(schedule)
            if (schedule.SP == "") {
                schedule.SP = "empty";
            }

            if (schedule.FILE1 == "" || schedule.FILE1 == " ") {
                schedule.FILE1 = "empty";
            }
            var currentdate = this.getAdjustedCurrentDate(schedule.BEGIN, schedule.END)//moment().tz(`${global.$settings.timezone}`).format('L LTS')

            var currenttime = Date.parse(currentdate);
            var sessionstarttime = Date.parse(schedule.BEGIN);
            var sessionendtime = Date.parse(schedule.END);

            schedule.SP = schedule.SP.replace(/;/g, "");

            if (schedule.T3 == "") {
                schedule.T3 = "emptyurl";
            }
            //console.log(schedule.ATTENDEELINK)
            if ((currenttime) >= (sessionstarttime) && (currenttime) <= (sessionendtime)) {
               //console.log(stag);
                if (schedule.TAG.includes(stag)) {
                    //console.log(stag);
                    global.$livesessionexist = true;

                    return (
                        <div key={schedule.SID}>
                            {/*console.log((currenttime) >= (sessionstarttime) && (currenttime) <= (sessionendtime))}
                                {console.log(stag)}
                        {console.log(schedule.TITLE)*/}

                            <ScheduleItem
                                stag={stag}
                                currentdate={currentdate}
                                currenttime={currenttime}
                                sessionstarttime={sessionstarttime}
                                sessionendtime={sessionendtime}
                                schedule={schedule}
                                activetab={this.state.activeschedule}
                                type={this.props.type}
                            />
                        </div>
                    );
                }
            }

        })
    }
    getAdjustedCurrentDate(begin, end) {
        var mode = global.$mode;
        if (mode == null || mode == '') {
            mode = 'live';
        }

        var currentdate = moment().tz(`${global.$settings.timezone}`).format('L LTS');

        if (mode == "live") {
            //Nothing - Just return the current date
        }
        else if (mode == "preview") {
            // Make current date/time as same as session begin date/time
            currentdate = begin;
        }
        else if (mode == "dryrun") {
            var eventdate = moment(begin).format('L');
            var currenttime = moment().tz(`${global.$settings.timezone}`).format('LTS');
            currentdate = eventdate + " " + currenttime
        }
        else if (mode == "ondemand") {   //Add 365 days to current date

            var postdate = moment().add(365, 'days').calendar();
            var posttime = moment().tz(`${global.$settings.timezone}`).format('LTS');
            currentdate = postdate + " " + posttime;
        }

        //console.log(`SessionVideos:  current = ${currentdate}, start = ${this.state.begindate}, end = ${this.state.enddate}` );

        return currentdate;
    }
}
export default LiveSessions;


