/* eslint-disable */
import React, { PureComponent } from 'react';
import { Link } from "react-router-dom";
import { API } from 'aws-amplify';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import './ExhibitorPreview.css';
import { Row, Col } from 'reactstrap';
import ReactPlayer from 'react-player';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { mdiPhone, mdiEmail, mdiWeb } from '@mdi/js';
import Icon from '@mdi/react';
var serviceNames = require('../ServiceNameMapper');

export default class FormPreview extends PureComponent {
  constructor(props) {
    //const globalvariables = global.$variables;
    super(props);
    this.state = {
      id: global.$userid,
      code: global.$code,
      userid: global.$userid,
      //exhibitorcategories: global.exhibitorcategories,
      company: '',
      url: '',
      country: 'United States',
      state: '',
      city: '',
      street: '',
      zip: '',
      contact: '',
      title: '',
      phone: '',
      email: '',
      desc: '',
      imageUrl: '',
      logoFileName: '',
      image1Url: '',
      image1FileName: '',
      video1: '',
      file1FileName: ' ',
      file2FileName: ' ',
      file3FileName: ' ',

      // type: 'S',
      // etype: [],
      // selectedEtype: '',

      // booth: '',
      // splevel: '',
      // category: [],
      // category1: '',

      // logoFileHandle: '',
      // fileFileName: '',
      // fileFileHandle: '',
      // adfile: '',
      // adsrc: [],
      // selectedSrc: '',

      // cdate: '',
      // disabled: false,
      // isLoading: false,
      // selectedCategory: "",
      // selected: '',
      // termsandconditons: '',
      // editorState: EditorState.createEmpty(),
      // rawDraftContentState: '',
      // keywords: '',
      // tag: '',
      // submittedby: '',
      // points: '',
      // auth: '',
      // createdby: '',
      // sortorder: '',
      // videos3: '',
      // videotos3FileName: ' ',
      // videotos3FileHandle: '',
      // videos3progress: 0,
      // videos3progresstext: '',
      // video1s3: '',
      // video1tos3FileName: ' ',
      // video1tos3FileHandle: '',
      // video1s3progress: 0,
      // video1s3progresstext: '',
      // video2s3: '',
      // video2tos3FileName: ' ',
      // video2tos3FileHandle: '',
      // video2s3progress: 0,
      // video2s3progresstext: '',
      // video3s3: '',
      // video3tos3FileName: ' ',
      // video3tos3FileHandle: '',
      // video3s3progress: 0,
      // video3s3progresstext: '',
      // videourl: '',
      // videourl: '',
      // video2: '',
      // video3: '',

      // image2: '',
      // image3: '',

      // image2Url: '',
      // image3Url: '',

      // image2FileName: '',
      // image3FileName: '',
      // image1FileHandle: '',
      // image2FileHandle: '',
      // image3FileHandle: '',

      // file1FileHandle: '',

      // file2FileHandle: '',

      // file3FileHandle: '',
      // file1progress: 0,
      // file1progresstext: '',
      // file2progress: 0,
      // file2progresstext: '',
      // file3progress: 0,
      // file3progresstext: '',
      // meeting1: '',
      // meeting2: '',
      // meeting3: '',
      // data1: '',
      // data2: '',
      // data3: '',
      // data4: '',
      // data5: '',
      // schedule: '',
    };

    if (this.state.code == "travcon2022exhibitor") {
      this.state.code = "travcon2022";
    }

    if (this.state.code == "travcon2023exhibitor") {
      this.state.code = "travcon2023";
    }
    this.getExhibitor();
  }

  getExhibitor() {
    const apiName = serviceNames.getEventPlannerService();
    const path = ''; // replace this with the path you have configured on your API
    let myInit = {
      body:
      {
        'code': this.state.code,
        'command': 'getLoggedinExhibitor',
        'createdby': this.state.userid,
        'type': this.props.type
      },
      response: true,
      convertEmptyValues: true,
      headers: {}
    }
    //console.log(myInit);

    API.post(apiName, path, myInit).then(response => {
      ////console.log (response.data);      
      if (response.err == null && response.data != null) {
        //console.log(response.data.Items);         
        let exhibitorObj = this.getExhibitorObject(response.data.data.Items)
        this.setState({ id: exhibitorObj.id })
        this.setState({ company: exhibitorObj.company })
        this.setState({ url: exhibitorObj.url })
        this.setState({ logoFileName: exhibitorObj.logo })
        this.setState({ country: exhibitorObj.country })
        this.setState({ street: exhibitorObj.street })
        this.setState({ city: exhibitorObj.city })
        this.setState({ state: exhibitorObj.state })
        this.setState({ zip: exhibitorObj.zip })
        this.setState({ logoFileHandle: exhibitorObj.logo })
        this.setState({ contact: exhibitorObj.contact })
        this.setState({ title: exhibitorObj.title })
        this.setState({ email: exhibitorObj.email })
        this.setState({ phone: exhibitorObj.phone })
        this.setState({ desc: exhibitorObj.desc })
        this.setState({ image1FileName: exhibitorObj.image1 })
        this.setState({ video1: exhibitorObj.video1 })
        this.setState({ file1FileName: exhibitorObj.file1 })
        this.setState({ file2FileName: exhibitorObj.file2 })
        this.setState({ file3FileName: exhibitorObj.file3 })
        // this.setState({ booth: exhibitorObj.booth })
        // this.setState({ selected: exhibitorObj.category })
        // this.setState({ category1: exhibitorObj.category1 })
        // this.setState({ splevel: exhibitorObj.splevel })
        // this.setState({ cdate: exhibitorObj.cdate })
        // this.setState({ udate: exhibitorObj.udate })
        // this.setState({ type: exhibitorObj.type })
        // this.setState({ selectedEtype: exhibitorObj.etype })
        // this.setState({ keywords: exhibitorObj.keywords })
        // this.setState({ tag: exhibitorObj.tag })
        // this.setState({ submittedby: exhibitorObj.submittedby })
        // this.setState({ auth: exhibitorObj.auth })
        // this.setState({ points: exhibitorObj.points })
        // this.setState({ createdby: exhibitorObj.createdby })
        // this.setState({ sortorder: exhibitorObj.sortorder })
        // this.setState({ videourl: exhibitorObj.videourl })

        // this.setState({ video2: exhibitorObj.video2 })
        // this.setState({ video3: exhibitorObj.video3 })         
        // this.setState({ image2FileName: exhibitorObj.image2 })
        // this.setState({ image3FileName: exhibitorObj.image3 })
        // this.setState({ file1FileName: exhibitorObj.file1 })
        // this.setState({ file2FileName: exhibitorObj.file2 })
        // this.setState({ file3FileName: exhibitorObj.file3 })
        // this.setState({ meeting1: exhibitorObj.meeting1 })
        // this.setState({ meeting2: exhibitorObj.meeting2 })
        // this.setState({ meeting3: exhibitorObj.meeting3 })
        // this.setState({ data1: exhibitorObj.data1 })
        // this.setState({ data2: exhibitorObj.data2 })
        // this.setState({ data3: exhibitorObj.data3 })
        // this.setState({ data4: exhibitorObj.data4 })
        // this.setState({ data5: exhibitorObj.data5 })
        // this.setState({ schedule: exhibitorObj.schedule })
        // this.setState({ dataloaded: true })
        //////console.log(this.state.imageUrl)
        this.setState({ imageUrl: `https://s3.amazonaws.com/event360live/public/${this.state.code}/exhibitors/${this.state.logoFileName}?${new Date().getTime()}` });
        this.setState({ image1Url: `https://s3.amazonaws.com/event360live/public/${this.state.code}/exhibitors/${this.state.image1FileName}?${new Date().getTime()}` });
        // this.setState({ image2Url: `https://s3.amazonaws.com/event360live/public/${this.state.code}/exhibitors/${this.state.image2FileName}?${new Date().getTime()}` });
        // this.setState({ image3Url: `https://s3.amazonaws.com/event360live/public/${this.state.code}/exhibitors/${this.state.image3FileName}?${new Date().getTime()}` });         
        //console.log(this.state.imageUrl);
      }
      else {
        //////console.log("getExhibitorsTableObjects - failed!!");
        //////console.log(response.err);
      }
    }
    ).catch(error => {
      //////console.log(error.response)
    });
  }

  getExhibitorObject(objs) {
    var obj = objs[0]
    ////console.log( obj) 
    var newObj = Object()
    newObj.id = obj.id.S
    newObj.type = obj.type.S
    newObj.etype = obj.etype.S
    newObj.logo = obj.logo.S
    newObj.company = obj.company.S
    newObj.street = obj.street.S
    newObj.city = obj.city.S
    newObj.state = obj.state.S
    newObj.zip = obj.zip.S
    newObj.booth = obj.booth.S
    newObj.category = obj.category.S
    newObj.category1 = obj.category1.S
    newObj.splevel = obj.splevel.S
    newObj.contact = obj.contact.S
    newObj.phone = obj.phone.S
    newObj.title = obj.title.S
    newObj.email = obj.email.S
    newObj.country = obj.country.S
    newObj.state = obj.state.S
    newObj.desc = obj.desc.S
    newObj.udate = obj.udate.N
    newObj.cdate = obj.cdate.N
    newObj.url = obj.url.S
    newObj.keywords = obj.keywords.S
    newObj.tag = obj.tag.S
    newObj.submittedby = obj.submittedby.S
    newObj.auth = obj.auth.S
    newObj.points = obj.points.N
    newObj.createdby = obj.createdby.S
    newObj.sortorder = obj.sortorder.N
    newObj.videourl = obj.videourl.S
    newObj.video1 = obj.video1.S
    newObj.video2 = obj.video2.S
    newObj.video3 = obj.video3.S
    newObj.image1 = obj.image1.S
    newObj.image2 = obj.image2.S
    newObj.image3 = obj.image3.S
    newObj.file1 = obj.file1.S
    newObj.file2 = obj.file2.S
    newObj.file3 = obj.file3.S
    newObj.meeting1 = obj.meeting1.S
    newObj.meeting2 = obj.meeting2.S
    newObj.meeting3 = obj.meeting3.S
    newObj.data1 = obj.data1.S
    newObj.data2 = obj.data2.S
    newObj.data3 = obj.data3.S
    newObj.data4 = obj.data4.S
    newObj.data5 = obj.data5.S
    newObj.schedule = obj.schedule.S
    //////console.log(newObj)
    //////console.log(newObj.category); 
    return newObj
  }

  getExhibitorDesc() {
    var desc = this.state.desc.trim();
    if (desc == "" || desc == " " || desc == null) {
      return (
        <div></div>
      )
    }
    else {
      return (
        <div className="virtual-exhibitor-desc-info">
          <p className="virtual-exhibitor-desc">{ReactHtmlParser(desc)}</p>
        </div>
      );
    }
  }

  getExhibitorContact() {
    return (
      <div className="virtual-exhibitor-info">
        {this.showDocuments()}
        {this.showContact()}
        {this.showAddress()}
      </div>
    );
  };

  showDocuments() {
    if (this.state.file1FileName.includes(" ") && this.state.file2FileName.includes(" ") && this.state.file3FileName.includes(" ")) {
      return ''
    }
    else {
      return (
        <div>
          <p className="virtual-exhibitor-contact-header1">Documents</p>
          <hr />
          <Row>
            {
              this.state.file1FileName.includes(" ") ?
                ('')
                :
                (
                  <Col>
                    {/* <Link
          to=""
          onClick={(event) => { event.preventDefault(); window.open('https://s3.amazonaws.com/event360live/public/' + this.state.code + '/Exhibitors/' + this.state.file1FileName); }}>Document1
        </Link>       */}
                    <a
                      href={`https://s3.amazonaws.com/event360live/public/${this.state.code}/Exhibitors/${this.state.file1FileName}`}
                      target="_blank"
                    >
                      Document1
                    </a>
                  </Col>
                )
            }
            {
              this.state.file2FileName.includes(" ") ?
                ('')
                :
                (
                  <Col>
                    <a
                      href={`https://s3.amazonaws.com/event360live/public/${this.state.code}/Exhibitors/${this.state.file2FileName}`}
                      target="_blank"
                    >
                      Document2
                    </a>
                  </Col>
                )
            }
            {
              this.state.file3FileName.includes(" ") ?
                ('')
                :
                (
                  <Col>
                    <a
                      href={`https://s3.amazonaws.com/event360live/public/${this.state.code}/Exhibitors/${this.state.file3FileName}`}
                      target="_blank"
                    >
                      Document3
                    </a>
                  </Col>
                )
            }
          </Row>
        </div>
      )
    }
  }

  showContact() {
    if (this.state.title == "" && this.state.contact == "" && this.state.phone == "" && this.state.email == "" && this.state.url == "") {
      return '';
    }
    else if (this.state.title == " " && this.state.contact == " " && this.state.phone == " " && this.state.email == " " && this.state.url == " ") {
      return '';
    }
    else {
      ////console.log("exist");
      return (
        <div>
          <p className="virtual-exhibitor-contact-header1">Contact</p>
          <hr />
          <div className="virtual-exhibitor-contact-info1">{this.state.contact}</div>
          <div className="virtual-exhibitor-contact-info1">{this.state.title}</div>
          <div className="virtual-exhibitor-contact-info1">
            <a href={`tel:${this.state.phone}`}>{this.state.phone}</a>
          </div>
          <div className="virtual-exhibitor-contact-info1">
            <a href={`mailto:${this.state.email}`}>{this.state.email}</a>
          </div>
          <div className="virtual-exhibitor-contact-info1">
            <a href={this.state.url} target="_blank">
              {
                this.state.url
              }
            </a>
          </div>
        </div>
      )

    }
    ////console.log("exist2");
  }

  showAddress() {
    if (this.state.country == '' || this.state.country == null && this.state.state == '' && this.state.city == '' && this.state.street == '' && this.state.zip == '') {
      return '';
    }
    else {
      return (
        <div>
          <p className="virtual-exhibitor-contact-header2">Address</p>
          <hr />
          <div className="virtual-exhibitor-contact-info2">{this.state.country}</div>
          <div className="virtual-exhibitor-contact-info2 ">{this.state.street}, {this.state.city}, {this.state.state} - {this.state.zip}</div>
        </div>
      )
    }
  }

  displayExhibitorContactIcons() {
    ////console.log(exhibitor);
    return (
      <div className="exhibitor-contact-icons">
        {
          this.state.url != null && this.state.url != '' && this.state.url != ' '
            ? (
              <a href={this.state.url} target="_blank"><Icon path={mdiWeb} size={1.7} color="#828783" className="exhibitor-contact-icon" /></a>
            )
            :
            ('')
        }
        {
          this.state.email != null && this.state.email != '' && this.state.email != ' '
            ? (
              <a href={"mailto:" + this.state.email}><Icon path={mdiEmail} size={1.7} color="#828783" className="exhibitor-contact-icon" /></a>
            )
            :
            ('')
        }
        {
          this.state.phone != null && this.state.phone != '' && this.state.phone != ' '
            ? (
              <a href={"tel:" + this.state.phone}><Icon path={mdiPhone} size={1.7} color="#828783" className="exhibitor-contact-icon" /></a>
            )
            :
            ('')
        }
      </div>
    )
  }

  render() {
    return (
      <div>
        <Tabs className="tabs-menu" defaultActiveKey="info" transition={false} id="tabs">
          <Tab eventKey="info" title="Info" className="tab-item">
            <div className="virtual-info-div" width="100%" height="calc(80vh - 100px)">
              <div>
                <Row>
                  <Col md={3}>
                    {
                      this.state.imageUrl != ''
                        ? (
                          <div className="virtual-exhibitor-logo-div">
                            <img className="virtual-exhibitor-logo" src={this.state.imageUrl} alt="" />
                            {this.displayExhibitorContactIcons()}
                          </div>
                        )
                        :
                        ('')
                    }
                  </Col>
                  <Col md={9}>
                    <p className="virtual-exhibitor-title">{this.state.company}</p>
                    {
                      this.state.image1Url != ''
                        ? (
                          <div className="virtual-exhibitor-banner">
                            <img className="virtual-exhibitor-img1" src={this.state.image1Url} alt="" />
                          </div>
                        )
                        :
                        ('')
                    }
                    <div className="virtual-exhibitor-info-div">
                      {this.getExhibitorDesc()}
                      <br />
                    </div>
                    <div className="virtual-exhibitor-contact-info-div">
                      {this.getExhibitorContact()}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Tab>
          {
            this.state.video1.includes("empty") || this.state.video1 == "" || this.state.video1 == " "
              ? ('')
              : (
                <Tab eventKey="video" title="Video" className="tab-item">
                  <div className="virtual-video-div">
                    <ReactPlayer
                      url={this.state.video1}
                      width="100%"
                      height="450px"
                      controls={true}
                      className="virtual-video-player"
                      onContextMenu={e => e.preventDefault()}
                      config={{
                        file: {
                          attributes: {
                            controlsList: 'nodownload',
                            disablepictureinpicture: 'true',
                          }
                        }
                      }}
                    />
                  </div>
                </Tab>
              )
          }
        </Tabs>
      </div>
    );
  }
}
