/* eslint-disable */
import React from 'react';
import { Col, Row, Button, Form, FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardHeader, CardBody } from 'reactstrap';
import { API } from 'aws-amplify';
import RegisterAttendee from '../RegisterAttendee';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import StripeACHFunctions from './StripeACHFunctions';
var serviceNames = require('../../ServiceNameMapper');
var stripeapikey =  '';
var stripe = ''
var doPayment = true; //Live: true, Test: false

export default class ownForm extends React.Component {
    constructor(props) {
        super(props);
        // if (this.isTravconExhibitor(global.$code) && global.$selectedpaymenttype == "deposit") {
        //     if (global.$loginstatus == null || global.$loginstatus == "false") {
        //         global.$regamount = Number(global.$registrationform.payment.deposit.amount);
        //     }
        // }
        this.state = {
            bankname: '',
            accountnumber: '',
            accounttype: '',
            routingnumber: '',
            nameonaccount: '',
            totalAmount: 0,
            regAmountBeforeFee: global.$regamount,
            email: global.$regattendeeinfo.email,
            amount: global.$regamount,
            paymentmessage: '',
            clientSecret: '',
            paid: false,    //  LIVE
            //paid: true,    //  TEST
            showerror: false,
            errormsg: "",
            disablesubmitbtn: false,
            showspinner: false,
            succeeded: false
        };
        global.$regattendeestatus = this.props.regattendeestatus;
        global.$reggueststatus = this.props.reggueststatus;
        global.$regusertype = this.props.type;

        this.handleInputChange = this.handleInputChange.bind(this);
        this.hideRegisterButtonFromACH = this.hideRegisterButtonFromACH.bind(this);
        this.createStripeACHPaymentOrder = this.createStripeACHPaymentOrder.bind(this);
        this.collectBankDetailsForPayment = this.collectBankDetailsForPayment.bind(this);
        //console.log(this.state);
        
        //console.log(global.$regamount);
        //this.calculatePayment();
    }

    componentDidMount() {
        stripeapikey = global.$registrationform && global.$registrationform.payment && global.$registrationform.payment.ach && global.$registrationform.payment.ach.stripe && global.$registrationform.payment.ach.stripe.clientkey ? global.$registrationform.payment.ach.stripe.clientkey : ''

        if(stripeapikey != null && stripeapikey != ""){
            stripe = Stripe(stripeapikey)
        }
        else {
            stripe = 'nokey'
        }

        var discount = '';
        if (global.$regdiscountinfo != null && global.$regdiscountinfo != "" && global.$regdiscountinfo.discountprice != null) {
            //console.log(global.$regdiscountinfo.discountprice);
            discount = "Discount - " + global.$regdiscountinfo.discountprice;
        }
        var stripetrasactionfee = "0";
        if (global.$registrationform.payment.stripe != null && global.$registrationform.payment.stripe.transactionfee != null) {
            stripetrasactionfee = global.$registrationform.payment.stripe.transactionfee;
        }
        
        var totalamount = Number(global.$regamount) + (Number(global.$regamount) * Number(stripetrasactionfee) / Number(100))
        global.$transactionfee = ((Number(global.$regamount) * Number(stripetrasactionfee)) / Number(100));
       //console.log(global.$regamount);
        global.$regamount = Number(global.$regamount) + (Number(global.$regamount) * Number(stripetrasactionfee) / Number(100));
        global.$regamount = parseFloat(global.$regamount).toFixed(2)
        
       //console.log(global.$regamount);
        
       //console.log("Fee = " + global.$transactionfee)
        totalamount = totalamount * 100
        console.log("total amount = " + totalamount);
        this.setState({
            totalAmount: totalamount
        });
    }

    /*
    calculatePayment() {
        var discount = '';
        if (global.$regdiscountinfo != null && global.$regdiscountinfo != "" && global.$regdiscountinfo.discountprice != null) {
            //console.log(global.$regdiscountinfo.discountprice);
            discount = "Discount - " + global.$regdiscountinfo.discountprice;
        }
        var stripetrasactionfee = "0";
        if (global.$registrationform.payment.stripe != null && global.$registrationform.payment.stripe.transactionfee != null) {
            stripetrasactionfee = global.$registrationform.payment.stripe.transactionfee;
        }
       
        var amount = global.$regamount * 100   // LIVE 100 cents = 1 dollar       
        //var amount = 0.5 * 100               // TEST 50 cents  = 0.5 dollar 
        
        var totalamount = Number(global.$regamount) + (Number(global.$regamount) * Number(stripetrasactionfee) / Number(100))
        global.$transactionfee = ((Number(global.$regamount) * Number(stripetrasactionfee)) / Number(100));
       //console.log(global.$regamount);
        global.$regamount = Number(global.$regamount) + (Number(global.$regamount) * Number(stripetrasactionfee) / Number(100));
        global.$regamount = parseFloat(global.$regamount).toFixed(2)
        
       //console.log(global.$regamount);
        
       //console.log("Fee = " + global.$transactionfee)
        totalamount = totalamount * 100
        //console.log("total amount = " + totalamount);
        this.setState({
            totalAmount: totalamount
        });
    }
    */

    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        let value = target.value

        //console.log("Target = " + name + ", Value = " + value);

        this.setState({ [name]: value });
        //this.setState({selectedCategory:value})
    }

    render() {
        var webconfig = global.$registrationform;
        return (
            <div>
                {
                    global.$code == "travcon2022exhibitor" || global.$code == "travcon2023exhibitor" || global.$code == "travcon2024exhibitor" ?
                        (
                            <div>
                                <p className="payment-amount">Total registration amount = ${parseFloat(this.state.regAmountBeforeFee).toFixed(2)}</p>
                                <p className="payment-amount">Transaction fee = ${(global.$transactionfee)}</p>
                                <p className="payment-amount">Total Amount = ${parseFloat(Number(this.state.totalAmount) / 100).toFixed(2)}</p>

                                <p className="payment-amount"><b>NOTE: While payment is in progress, do not close this page or retry payment</b></p>

                            </div>
                        )
                        :
                        (
                            <div>
                                <p className="payment-amount">Total Amount ${parseFloat(global.$regamount).toFixed(2)}</p>
                                <p className="payment-amount"><b>NOTE: While payment is in progress, do not close this page or retry payment</b></p>
                            </div>    
                        )
                }
                <hr />                
                <Card className="ach-card">
                    <CardHeader>ACH Payment</CardHeader>
                    <CardBody>
                        <Form className="" >
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="bankname" className="ach-form-label">Email</Label>
                                        <Input type="text" name="email" className="ach-form-control" id="bankname" value={this.state.email} onChange={this.handleInputChange} />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="accountname" className="ach-form-label">Name on Account*</Label>
                                        <Input type="text" name="nameonaccount" id="nameonaccount" className="ach-form-control" value={this.state.nameonaccount} onChange={this.handleInputChange} required />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <br />
                        </Form>
                        {
                            this.state.showerror == true ?
                                (
                                    <p className="ach-payment-failed">{this.state.errormsg}</p>
                                )
                                :
                                ('')
                        }
                        <div className="loading-button-div">
                            <Spinner
                                animation="border"
                                role="status"
                                size="sm"
                                hidden={!this.state.showspinner}
                                className="loading-button-register"
                            />
                        </div>
                        <Button type="button" onClick={this.createStripeACHPaymentOrder} color="primary" className="w-100" disabled={this.state.disablesubmitbtn}>Connect With Bank</Button>
                        {/* <Button type="button" onClick={this.collectBankDetailsForPayment} color="primary" className="w-100" disabled={this.state.disablesubmitbtn}>Enter Bank Details</Button> */}
                    </CardBody>
                </Card>
                {
                    this.state.succeeded == true
                    ?
                    (
                        <p className={this.state.succeeded ? "result-message" : "result-message hidden"}>
                            <RegisterAttendee regattendeestatus={global.$regattendeestatus} reggueststatus={global.$reggueststatus} type={global.$regusertype} hideRegisterButton={this.hideRegisterButtonFromACH} />                                
                        </p>
                    )
                    :
                    ''
                }

            </div>
        )
    }
    
    hideRegisterButtonFromACH() {
        this.props.hideRegisterButton()
    }

    fieldsValidated() {
        if (this.state.nameonaccount === "" || this.state.email === ""  || this.state.email === " " || this.state.nameonaccount === " ") {
            this.forceUpdate();
            return false;
        }
        else {
            return true;
        }
    }

    async createStripeACHPaymentOrder() {
        //var amount = 0.5 * 100 // Test purpose only
        var amount = this.state.totalAmount // Live Mode

        if(doPayment) {
            let apiName = serviceNames.getPaymentService();
            let path = ''; //replace this with the path you have configured on your API
            let myInit = {
                body:
                {
                    'code': global.$code,
                    'command': 'stripeACHPayment',
                    //'amount': global.$regamount * 100, 
                    'amount': amount,
                    'currency': 'USD',
                    'desc': global.$regattendeeinfo.fname + " " + global.$regattendeeinfo.lname + ' - ' + global.$regattendeeinfo.email + ' - ' + global.$attendeeregitems + ',' + global.$guestregitems + ',' + discount,
                    //'desc': 'payment notes'
                },
                response: true,
                convertEmptyValues: true,
                headers: {}
            }

            console.log(myInit);

            const result = await API.post(apiName,path,myInit)

            if (result.err == null && result.data != null) {
                //console.log(response.data);
                // this.setState({
                //     clientSecret: result.data
                // })

                await this.collectBankDetailsForPayment(result.data);
            }
            else {
            //console.log(result.err);
            }
        }
        else {
            this.setState({
                succeeded: true
            })
        }
    }

    async collectBankDetailsForPayment(clientSecret) {
        //console.log(clientSecret)
        var collectdetails = await StripeACHFunctions.collectBankAccountForPayment(clientSecret, this.state.nameonaccount, this.state.email, stripe);

        if(collectdetails.status && collectdetails.status == "success") {
            this.setState({
                succeeded: true
            })
        }
    }
    
}