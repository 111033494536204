import React from 'react';
import { Row, Col } from 'reactstrap';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Chat from '../Chat/Chat';
import { API } from 'aws-amplify';
import SessionTracks2 from './SessionTracks2';
import ScheduleTabs2 from './ScheduleTabs2';

/*
    react tabs docs  - https://github.com/freeranger/react-bootstrap-tabs-demo/blob/master/public/site.css
*/

const moment = require('moment-timezone');
class SessionTabs2 extends React.Component {
    constructor(props) {
        var enabletabsbeforelogin = "Y";
        var schedulestyle = {};

        if (global.$session.enabletabsbeforelogin == null) {
            enabletabsbeforelogin = "N";
        }
        else {
            enabletabsbeforelogin = global.$session.enabletabsbeforelogin;
        }

        if (global.$schedule.sessioninfo2 == null) {
            schedulestyle = global.$session.styles.iframe
        }
        else {
            schedulestyle = global.$schedule.sessioninfo2.styles.scheduletab
        }

        super(props);
        //console.log(props);
        this.state = {
            code: global.$code,
            s3: global.$s3,
            bucket: global.$s3bucket,
            dataloaded: false,
            numPages: null,
            pageNumber: 1,
            showfeedback: props.showfeedback,
            feedback: props.session.FB,
            livepoll: props.session.VT,
            liveqa: props.session.QA,
            sessionid: props.session.SID,
            sessionpoints: props.session.POINTS,
            title: props.session.TITLE,
            begindate: props.session.BEGIN,
            enddate: props.session.END,
            iframestyle: global.$session.styles.iframe,
            enabletabsbeforelogin: enabletabsbeforelogin,
            schedulestyle: schedulestyle
        };

        //console.log(props);
        //console.log(this.props.showfeedback);
        //console.log(this.state);
        //console.log(this.props.sessiondate, this.props.session.SP)

    }

    render() {

        if (this.state.dataloaded) {
            return (
                <div>
                    {this.getTabs()}
                </div>
            )
        }
        else {
            var array = this.props.session.T4;
            array = array.split(";");
            array = array[0];
            this.getResponses(array)
            return (
                <div></div>
            )
        }
    }
    getResponseTableObjects(objs) {
        //console.log(objs)
        if (objs == null) {
            objs = [];
        }
        var tableObjs = []
        for (var i = 0; i < objs.length; i++) {

            var responseObj = objs[i]
            //console.log(objs)
            if (this.state.sessionid == responseObj.sessionid.S) {
                //console.log("true1")
                //console.log(responseObj.userid.S);
                //console.log(global.$loginid);
                global.$showfeedback = "Y";
                if (global.$loginid == responseObj.userid.S) {
                    //console.log("true2");
                    this.setState({ showfeedback: "N" });
                    //this.state.showfeedback = "N";
                    //global.$showfeedback = "N";
                }
            }
            //console.log(this.state.showfeedback);
        }
        this.setState({ dataloaded: true })
        //console.log(this.state.dataloaded);
        return tableObjs

    }
    getResponses(item) {
        let apiName = 'PollingService';
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'code': this.state.code,
                'command': 'getPollResponses',
                'sessionid': this.state.sessionid,
                'questionid': item,
                'responsetype': "SessionFeedback"
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //console.log(myInit);

        API.post(apiName, path, myInit).then(response => {
            //console.log(response);

            if (response.err == null && response.data != null) {
                //console.log(response.data.Items);
                var responses = this.getResponseTableObjects(response.data.Items)
                //console.log(this.state.dataloaded);
            }
            else {
                //console.log("getReportsTableObjects - failed!!");
                //console.log(response.err);
            }

        }
        ).catch(error => {
            //console.log(error)
        });
    }

    getAdjustedCurrentDate() {
        var currentdate = moment().tz(`${global.$settings.timezone}`).format('L LTS')

        var mode = global.$mode;
        if (mode == null || mode == '') {
            mode = 'live';
        }

        if (mode == "live") {
            //Nothing - Just retunr the current date
        }
        else if (mode == "preview") {
            // Make current date/time as same as session begin date/time
            currentdate = this.state.begindate;
        }
        else if (mode == "dryrun") {
            var eventdate = moment(this.props.sessionstarttime).format('L');
            var timenow = moment().tz(`${global.$settings.timezone}`).format('LTS');
            currentdate = eventdate + " " + timenow
        }
        else if (mode == "ondemand") {   //Add 365 days to current date
            var postdate = moment().add(365, 'days').calendar();
            var posttime = moment().tz(`${global.$settings.timezone}`).format('LTS');
            currentdate = postdate + " " + posttime;
        }

        return currentdate;
    }

    getTabs() {
        //console.log("getTabs()");

        var sessionstarttime = Date.parse(this.state.begindate);
        var sessionendtime = Date.parse(this.state.enddate);

        var currentdate = this.getAdjustedCurrentDate();
        var currenttime = Date.parse(currentdate);

        //console.log("getTabs()");

        if (this.state.enabletabsbeforelogin == "Y") {
            if (sessionstarttime > currenttime) {
                return this.getLiveTabs();
            }
            if (currenttime >= sessionstarttime && currenttime <= sessionendtime) {
                return this.getLiveTabs();
            }
            else if (currenttime > sessionendtime) {
                return this.getPostTabs();
            }
        }
        else {
            if (sessionstarttime > currenttime) {
                //console.log("pre");
                return (
                    <div>
                        <Row>
                            <Col md={12}>
                                <Tabs defaultActiveKey="schedule" transition={false}>
                                    <Tab eventKey="schedule" title="Schedule" className="tab-item schedule-agenda-tab" style={this.state.schedulestyle}>
                                        <ScheduleTabs2
                                            session={this.props.session}
                                            activetab={this.props.activetab}
                                            type={this.props.type}
                                        />
                                    </Tab>
                                </Tabs>
                            </Col>
                        </Row>
                    </div>
                )
            }
            if (currenttime >= sessionstarttime && currenttime <= sessionendtime) {
                //console.log("live");
                return this.getLiveTabs();
            }
            else if (currenttime > sessionendtime) {
                //console.log("post");
                return this.getPostTabs();
            }
        }

    }
    getLiveTabs() {
        var feedback = this.state.feedback
        var livepoll = this.state.livepoll
        var chat = this.state.liveqa
        //chat = "Y" //- Chat Testing

        if (chat == "N" || chat == "") {
            chat = "empty"
        }
        if (livepoll == " " || livepoll == "") {
            livepoll = "empty"
        }
        if (feedback == " " || feedback == "") {
            feedback = "empty"
        }

        //console.log("feedback=" + feedback, "livepoll=" + livepoll, "chat=" + chat)

        if (feedback !== "empty" && livepoll == "empty" && chat == "empty")    //feedback exsit and livepoll, chat not exsit
        {
            //console.log(this.state.feedback + "?firstname=" + global.$attendeeDetails.fname + "&lastname=" + global.$attendeeDetails.lname + "&email=" + global.$attendeeDetails.email + "&userid=" + global.$attendeeDetails.userid + "&company=" + global.$attendeeDetails.company + "&title=" + encodeURIComponent(this.state.title) + "&sessionid=" + this.state.sessionid + "&sessionpoints=" + this.state.sessionpoints + "&sessiondate=" + this.props.sessiondate + "&sessionspeakers=" + this.props.session.SP )
            return (
                <div>
                    <Row>
                        <Col md={12}>
                            <Tabs defaultActiveKey="schedule" transition={false}>
                                <Tab eventKey="schedule" title="Schedule" className="tab-item schedule-agenda-tab" style={this.state.schedulestyle}>
                                    <ScheduleTabs2
                                        session={this.props.session}
                                        activetab={this.props.activetab}
                                        type={this.props.type} />
                                </Tab>
                                <Tab eventKey="feedback" title={global.$session.liveoptions.feedbacktitle} className="tab-item">
                                    {
                                        this.state.showfeedback.includes("Y") ?
                                            (
                                                <iframe src={this.state.feedback + "?firstname=" + global.$attendeeDetails.fname + "&lastname=" + global.$attendeeDetails.lname + "&email=" + global.$attendeeDetails.email + "&userid=" + global.$attendeeDetails.userid + "&company=" + global.$attendeeDetails.company + "&sessionid=" + this.state.sessionid + "&sessionpoints=" + this.state.sessionpoints + "&sessiondate=" + this.props.sessiondate + "&sessionspeakers=" + this.props.session.SP + "&title=" + encodeURIComponent(this.state.title)}
                                                    id="myId"
                                                    className="myClassname"
                                                    display="initial"
                                                    position="relative"
                                                    style={this.state.iframestyle}
                                                />
                                            )
                                            : 
                                            (
                                                <div>
                                                    {this.showMessageForClosedFeedback()}
                                                </div>
                                            )
                                    }
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>
                </div>
            )
        }
        else if (livepoll !== "empty" && feedback == "empty" && chat == "empty")    //livepoll exsit and feedback, chat not exsit
        {
            return (
                <div>
                    <Row>
                        <Col md={12}>
                            <Tabs defaultActiveKey="schedule" transition={false}>
                                <Tab eventKey="schedule" title="Schedule" className="tab-item schedule-agenda-tab" style={this.state.schedulestyle}>
                                    <ScheduleTabs2
                                        session={this.props.session}
                                        activetab={this.props.activetab}
                                        type={this.props.type} />
                                </Tab>
                                <Tab eventKey="livepoll" title={global.$session.liveoptions.livepolltitle} className="tab-item">
                                    <iframe src={this.state.livepoll}
                                        id="myId"
                                        className="myClassname"
                                        display="initial"
                                        position="relative"
                                        style={this.state.iframestyle}
                                    />
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>
                </div>
            )
        }
        else if (chat !== "empty" && feedback == "empty" && livepoll == "empty")    //chat exsit and livepoll, feedback not exsit
        {
            return (
                <div>
                    <Row>
                        <Col md={12}>
                            <Tabs defaultActiveKey="schedule" transition={false}>
                                <Tab eventKey="schedule" title="Schedule" className="tab-item schedule-agenda-tab" style={this.state.schedulestyle}>
                                    <ScheduleTabs2
                                        session={this.props.session}
                                        activetab={this.props.activetab}
                                        type={this.props.type} />
                                </Tab>
                                <Tab eventKey="chat" title={global.$session.liveoptions.chattitle} className="tab-item">
                                    <div className="chat-tab-sessioninfo2">
                                        <Chat code={this.state.code} sessionid={this.state.sessionid} />
                                    </div>
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>
                </div>
            )
        }
        if (feedback !== "empty" && livepoll !== "empty" && chat == "empty")    //feedback and livepoll exsit and chat not exsit
        {
            return (
                <div>
                    <Row>
                        <Col md={12}>
                            <Tabs defaultActiveKey="schedule" transition={false}>
                                <Tab eventKey="schedule" title="Schedule" className="tab-item schedule-agenda-tab" style={this.state.schedulestyle}>
                                    <ScheduleTabs2
                                        session={this.props.session}
                                        activetab={this.props.activetab}
                                        type={this.props.type} />
                                </Tab>
                                <Tab eventKey="feedback" title={global.$session.liveoptions.feedbacktitle} className="tab-item">
                                    {
                                        this.state.showfeedback.includes("Y") ?
                                            (

                                                <iframe src={this.state.feedback + "?firstname=" + global.$attendeeDetails.fname + "&lastname=" + global.$attendeeDetails.lname + "&email=" + global.$attendeeDetails.email + "&userid=" + global.$attendeeDetails.userid + "&company=" + global.$attendeeDetails.company + "&sessionid=" + this.state.sessionid + "&sessionpoints=" + this.state.sessionpoints + "&sessiondate=" + this.props.sessiondate + "&sessionspeakers=" + this.props.session.SP + "&title=" + encodeURIComponent(this.state.title)}
                                                    id="myId"
                                                    className="myClassname"
                                                    display="initial"
                                                    position="relative"
                                                    style={this.state.iframestyle}
                                                />
                                            )
                                            :
                                            (
                                                <div>
                                                    {this.showMessageForClosedFeedback()}
                                                </div>
                                            )
                                    }
                                </Tab>
                                <Tab eventKey="livepoll" title={global.$session.liveoptions.livepolltitle} className="tab-item">
                                    <iframe src={this.state.livepoll}
                                        id="myId"
                                        className="myClassname"
                                        display="initial"
                                        position="relative"
                                        style={this.state.iframestyle}
                                    />
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>
                </div>
            )
        }
        else if (livepoll !== "empty" && feedback == "empty" && chat !== "empty")   //chat and livepoll exsit and feedback not exsit
        {
            return (
                <div>
                    <Row>
                        <Col md={12}>
                            <Tabs defaultActiveKey="schedule" transition={false}>
                                <Tab eventKey="schedule" title="Schedule" className="tab-item schedule-agenda-tab" style={this.state.schedulestyle}>
                                    <ScheduleTabs2
                                        session={this.props.session}
                                        activetab={this.props.activetab}
                                        type={this.props.type} />
                                </Tab>
                                <Tab eventKey="chat" title={global.$session.liveoptions.chattitle} className="tab-item">
                                    <div className="chat-tab-sessioninfo2">
                                        <Chat code={this.state.code} sessionid={this.state.sessionid} />
                                    </div>
                                </Tab>
                                <Tab eventKey="livepoll" title={global.$session.liveoptions.livepolltitle} className="tab-item">
                                    <iframe src={this.state.livepoll}
                                        id="myId"
                                        className="myClassname"
                                        display="initial"
                                        position="relative"
                                        style={this.state.iframestyle}
                                    />
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>
                </div>
            )
        }
        else if (chat !== "empty" && feedback !== "empty" && livepoll == "empty")   //feedback and chat exsit and livepoll not exsit
        {
            //console.log("Adding chat.."); 

            return (
                <div>
                    <Row>
                        <Col md={12}>
                            <Tabs defaultActiveKey="schedule" transition={false}>
                                <Tab eventKey="schedule" title="Schedule" className="tab-item schedule-agenda-tab" style={this.state.schedulestyle}>
                                    <ScheduleTabs2
                                        session={this.props.session}
                                        activetab={this.props.activetab}
                                        type={this.props.type} />
                                </Tab>
                                <Tab eventKey="chat" title={global.$session.liveoptions.chattitle} className="tab-item">
                                    <div className="chat-tab-sessioninfo2">
                                        <Chat code={this.state.code} sessionid={this.state.sessionid} />
                                    </div>
                                </Tab>
                                <Tab eventKey="feedback" title={global.$session.liveoptions.feedbacktitle} className="tab-item">
                                    {
                                        this.state.showfeedback.includes("Y") ?
                                            (

                                                <iframe src={this.state.feedback + "?firstname=" + global.$attendeeDetails.fname + "&lastname=" + global.$attendeeDetails.lname + "&email=" + global.$attendeeDetails.email + "&userid=" + global.$attendeeDetails.userid + "&company=" + global.$attendeeDetails.company + "&sessionid=" + this.state.sessionid + "&sessionpoints=" + this.state.sessionpoints + "&sessiondate=" + this.props.sessiondate + "&sessionspeakers=" + this.props.session.SP + "&title=" + encodeURIComponent(this.state.title)}
                                                    id="myId"
                                                    className="myClassname"
                                                    display="initial"
                                                    position="relative"
                                                    style={this.state.iframestyle}
                                                />
                                            )
                                            :
                                            (
                                                <div>
                                                    {this.showMessageForClosedFeedback()}
                                                </div>
                                            )
                                    }
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>
                </div>
            )
        }
        else if (chat !== "empty" && feedback !== "empty" && livepoll !== "empty")  // all exist
        {
            //console.log(this.state)
            //console.log(this.state.feedback + "?firstname=" + global.$attendeeDetails.fname + "&lastname=" + global.$attendeeDetails.lname + "&email=" + global.$attendeeDetails.email + "&userid=" + global.$attendeeDetails.userid + "&company=" + global.$attendeeDetails.company + "&title=" + encodeURIComponent(this.state.title) + "&sessionid=" + this.state.sessionid + "&sessionpoints=" + this.state.sessionpoints + "&sessiondate=" + this.props.sessiondate + "&sessionspeakers=" + this.props.session.SP )

            return (
                <div>
                    <Row>
                        <Col md={12}>
                            <Tabs defaultActiveKey="schedule" transition={false}>
                                <Tab eventKey="schedule" title="Schedule" className="tab-item schedule-agenda-tab" style={this.state.schedulestyle}>
                                    <ScheduleTabs2
                                        session={this.props.session}
                                        activetab={this.props.activetab}
                                        type={this.props.type} />
                                </Tab>
                                <Tab eventKey="chat" title={global.$session.liveoptions.chattitle} className="tab-item">
                                    <div className="chat-tab-sessioninfo2">
                                        <Chat code={this.state.code} sessionid={this.state.sessionid} />
                                    </div>
                                </Tab>
                                <Tab eventKey="feedback" title={global.$session.liveoptions.feedbacktitle} className="tab-item">
                                    {
                                        this.state.showfeedback.includes("Y") ?
                                            (

                                                <iframe src={this.state.feedback + "?firstname=" + global.$attendeeDetails.fname + "&lastname=" + global.$attendeeDetails.lname + "&email=" + global.$attendeeDetails.email + "&userid=" + global.$attendeeDetails.userid + "&company=" + global.$attendeeDetails.company + "&sessionid=" + this.state.sessionid + "&sessionpoints=" + this.state.sessionpoints + "&sessiondate=" + this.props.sessiondate + "&sessionspeakers=" + this.props.session.SP + "&title=" + encodeURIComponent(this.state.title)}
                                                    id="myId"
                                                    className="myClassname"
                                                    display="initial"
                                                    position="relative"
                                                    style={this.state.iframestyle}
                                                />
                                            )
                                            :
                                            (
                                                <div>
                                                    {this.showMessageForClosedFeedback()}
                                                </div>
                                            )
                                    }
                                </Tab>
                                <Tab eventKey="livepoll" title={global.$session.liveoptions.livepolltitle} className="tab-item">
                                    <iframe src={this.state.livepoll}
                                        id="myId"
                                        className="myClassname"
                                        display="initial"
                                        position="relative"
                                        style={this.state.iframestyle}
                                    />
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>
                </div>
            )
        }
        else if (chat == "empty" && feedback == "empty" && livepoll == "empty")  // all not exist
        {
            //console.log(this.state)
            return (
                <div>
                    <Row>
                        <Col md={12}>
                            <Tabs defaultActiveKey="schedule" transition={false}>
                                <Tab eventKey="schedule" title="Schedule" className="tab-item schedule-agenda-tab" style={this.state.schedulestyle}>
                                    <ScheduleTabs2
                                        session={this.props.session}
                                        activetab={this.props.activetab}
                                        type={this.props.type} />
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>
                </div>
            )
        }

    }
    getPostTabs() {
        if (this.state.feedback == " " || this.state.feedback == "") {
            this.state.feedback = "empty"
        }
        var chat = this.state.liveqa

        if (chat == "N" || chat == "") {
            chat = "empty"
        }

        if (global.$session.enablefeedbackaftersession != null && global.$session.enablefeedbackaftersession == "Y" && global.$session.enablechataftersession != null && global.$session.enablechataftersession == "Y") {
            if (chat !== "empty" && this.state.feedback !== "empty") {
                return (
                    <div>
                        <Row>
                            <Col md={12}>
                                <Tabs defaultActiveKey="schedule" transition={false} className="myClass">
                                    <Tab eventKey="schedule" title="Schedule" className="tab-item schedule-agenda-tab" style={this.state.schedulestyle}>
                                        <ScheduleTabs2
                                            session={this.props.session}
                                            activetab={this.props.activetab}
                                            type={this.props.type} />
                                    </Tab>
                                    <Tab eventKey="chat" title={global.$session.liveoptions.chattitle} className="tab-item">
                                        <Chat code={this.state.code} sessionid={this.state.sessionid} />
                                    </Tab>
                                    <Tab eventKey="feedback" title={global.$session.liveoptions.feedbacktitle} className="tab-item">
                                        {
                                            this.state.showfeedback.includes("Y") ?
                                                (

                                                    <iframe src={this.state.feedback + "?firstname=" + global.$attendeeDetails.fname + "&lastname=" + global.$attendeeDetails.lname + "&email=" + global.$attendeeDetails.email + "&userid=" + global.$attendeeDetails.userid + "&company=" + global.$attendeeDetails.company + "&sessionid=" + this.state.sessionid + "&sessionpoints=" + this.state.sessionpoints + "&sessiondate=" + this.props.sessiondate + "&sessionspeakers=" + this.props.session.SP + "&title=" + encodeURIComponent(this.state.title)}
                                                        id="myId"
                                                        className="myClassname"
                                                        display="initial"
                                                        position="relative"
                                                        style={this.state.iframestyle}
                                                    />
                                                )
                                                :
                                                (
                                                    <div>
                                                        {this.showMessageForClosedFeedback()}
                                                    </div>
                                                )
                                        }
                                    </Tab>
                                </Tabs>
                            </Col>
                        </Row>
                    </div>
                )
            }
        }
        else if (global.$session.enablefeedbackaftersession != null && global.$session.enablefeedbackaftersession == "Y") {
            //console.log("feedback");
            if (this.state.feedback !== "empty") {
                return (
                    <div>
                        <Row>
                            <Col md={12}>
                                <Tabs defaultActiveKey="schedule" transition={false} className="myClass" className="myClass">
                                    <Tab eventKey="schedule" title="Schedule" className="tab-item schedule-agenda-tab" style={this.state.schedulestyle}>
                                        <ScheduleTabs2
                                            session={this.props.session}
                                            activetab={this.props.activetab}
                                            type={this.props.type} />
                                    </Tab>
                                    <Tab eventKey="feedback" title={global.$session.liveoptions.feedbacktitle} className="tab-item">
                                        {
                                            this.state.showfeedback.includes("Y") ?
                                                (

                                                    <iframe src={this.state.feedback + "?firstname=" + global.$attendeeDetails.fname + "&lastname=" + global.$attendeeDetails.lname + "&email=" + global.$attendeeDetails.email + "&userid=" + global.$attendeeDetails.userid + "&company=" + global.$attendeeDetails.company + "&sessionid=" + this.state.sessionid + "&sessionpoints=" + this.state.sessionpoints + "&sessiondate=" + this.props.sessiondate + "&sessionspeakers=" + this.props.session.SP + "&title=" + encodeURIComponent(this.state.title)}
                                                        id="myId"
                                                        className="myClassname"
                                                        display="initial"
                                                        position="relative"
                                                        style={this.state.iframestyle}
                                                    />
                                                )
                                                :
                                                (
                                                    <div>
                                                        {this.showMessageForClosedFeedback()}
                                                    </div>
                                                )
                                        }
                                    </Tab>
                                </Tabs>
                            </Col>
                        </Row>
                    </div >
                )
            }
        }
        else if (global.$session.enablechataftersession != null && global.$session.enablechataftersession == "Y") {
            if (this.state.chat !== "empty") {
                return (
                    <div>
                        <Row>
                            <Col md={12}>
                                <Tabs defaultActiveKey="schedule" transition={false} className="myClass">
                                    <Tab eventKey="schedule" title="Schedule" className="tab-item schedule-agenda-tab" style={this.state.schedulestyle}>
                                        <ScheduleTabs2
                                            session={this.props.session}
                                            activetab={this.props.activetab}
                                            type={this.props.type} />
                                    </Tab>
                                    <Tab eventKey="chat" title={global.$session.liveoptions.chattitle} className="tab-item">
                                        <Chat code={this.state.code} sessionid={this.state.sessionid} />
                                    </Tab>
                                </Tabs>
                            </Col>
                        </Row>
                    </div>
                )
            }
        }
        else {
            return (
                <div>
                    <Row>
                        <Col md={12}>
                            <Tabs defaultActiveKey="schedule" transition={false}>
                                <Tab eventKey="schedule" title="Schedule" className="tab-item schedule-agenda-tab" style={this.state.schedulestyle}>
                                    <ScheduleTabs2
                                        session={this.props.session}
                                        activetab={this.props.activetab}
                                        type={this.props.type} />
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>
                </div>
            )
        }
    }
    showMessageForClosedFeedback() {
        return (
            <div className="feedback-message">
                <h4>Thank you for submitting feedback.</h4>
            </div>
        )
    }
}
export default SessionTabs2;


