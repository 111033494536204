import React from 'react';
import { Row, Col, Button, Input } from 'reactstrap';
import { Link, } from 'react-router-dom';
import { API, Analytics } from 'aws-amplify';
import './Speakers2.css';
import ReactHtmlParser from 'react-html-parser';
import Sponsors from '../Components/Sponsors';
import commonProfile from '../images/common_profile.png';
var serviceNames = require('../ServiceNameMapper');

class Speakers2 extends React.Component {
    constructor(props) {
        super(props);
        this.state =
        {
            code: global.$code,
            open: false,
            bucket: global.$s3bucket,
            dataloaded: false,
            search: '',
            speakerheaderstyle: global.$speakers2.styles.categoryheader,
            speakernamestyle: global.$speakers2.styles.name,
            speakertitlestyle: global.$speakers2.styles.title,
            speakercompanystyle: global.$speakers2.styles.company,
            speakerimagestyle: global.$speakers2.styles.image,
            speakerdescstyle: global.$speakers2.styles.desc,
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        let value = target.value

       //console.log("Target = " + name + ", Value = " + value);

        this.setState({ [name]: value });
        //this.setState({selectedCategory:value})
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }


    render() {

        Analytics.record({ name: 'PageView', attributes: { name: 'eventsites-' + this.state.code + '-speakers2' } });
        if (this.state.dataloaded) {
            return (
                <div>
                    <img className="banner" src={global.$speakers.banner} />
                    <div >
                        <div dangerouslySetInnerHTML={{ __html: global.$speakers.header }}></div>
                    </div>
                    <div className="speakers2">
                        <Row>
                            <Col md={10} className="offset-md-1">
                                <div className="exhibitor-search-div">
                                    <Input type="text" name="search" className="exhibitor-search" value={this.state.search || ""} onChange={this.handleInputChange} placeholder="Search"><i class='fas fa-search'></i></Input>
                                </div>
                                {this.displaySpeakers()}
                            {
                                global.$speakers2.sponsors.enable.includes("Y") ?
                                    (
                                        <div className="speakers2-sponsors">
                                            <br />
                                            <br />
                                            <Sponsors />
                                            <br />
                                        </div>
                                    )
                                    :
                                    ('')
                            }
                            </Col>
                        </Row>
                    </div>
                </div>

            );
        }
        else {
            if (global.$speakerjson != null) {
                this.setState({ dataloaded: true });
            }
            else {
                this.getSpeakerjson()
            }

            return (
                <div>
                    <Row>
                        <Col md={6} className="offset-5">
                            <div className="loading-div">
                                <div className="loading-text">Loading....</div>
                            </div>
                        </Col>
                    </Row>
                </div>)
        }
    }
    getSpeakerjson() {
        let apiName = serviceNames.getWebsiteService();
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'code': this.state.code,
                'command': 'getJsonFileFromS3',
                'bucket': this.state.bucket,
                'filename': "speakers"
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //console.log(myInit);

        API.post(apiName, path, myInit).then(response => {
            //console.log ("response.data ===> " + response.data + "response.err ===> " + response.err);

            if (response.err == null && response.data != null) {
                let buf = Buffer.from(response.data.Body);
                //console.log(buf)
                //console.log(buf.toString());
                global.$speakerjson = JSON.parse(buf.toString());
               //console.log(global.$speakerjson);
                this.setState({ dataloaded: true });

            }
            else {
                ////console.log("getAttendeesTableObjects - failed!!");
               //console.log(response.err);
            }
        }
        ).catch(error => {
           //console.log(error)
        });

    }

    displaySpeakers() {
        const results = global.$speakerjson.filter(speaker => {
           //console.log(speaker)
            if (this.state.search == "")
                return true;
            else {
                if (speaker.DESCRIPTION.toLocaleLowerCase().includes(this.state.search.toLocaleLowerCase()) || speaker.NAME.toLocaleLowerCase().includes(this.state.search.toLocaleLowerCase()))
                    return true;
                else
                    return false;
            }
        })

        return results.map(speaker => {
           //console.log(speaker)
            if (speaker.PHOTO == "profile.png" || speaker.PHOTO == " " || speaker.PHOTO == "") {
                var image = commonProfile;
            }
            else {
                var image = `https://s3.amazonaws.com/event360live/public/${this.state.code}/speakers/` + speaker.PHOTO
            }
            return (
                <div key={speaker.ID}>
                    {
                        speaker.TYPE.includes("T") ?
                            (
                                <div>
                                    <Row>
                                        <Col md={12}>
                                            <div style={this.state.speakerheaderstyle}>{speaker.NAME}</div>
                                        </Col>
                                    </Row>
                                    <br />
                                </div>
                            )
                            :
                            (
                                <Row className="sponsors">
                                    <Col>
                                        <Row>
                                            <Col md={3} className="offset-md-1">
                                                <a href={speaker.URL} target="_blank"><img src={image} style={this.state.speakerimagestyle} /></a>
                                                <br />
                                            </Col>
                                            <br />
                                            <Col md={7} className="offset-md-1">
                                                <Link to={`./SpeakerInfo2/${speaker.NAME + "==SpeakerRouter"}`}>
                                                    <div style={this.state.speakernamestyle}>{speaker.NAME}</div>
                                                </Link>
                                                <div style={this.state.speakercompanystyle}>{speaker.COMPANY}</div>
                                                <div style={this.state.speakertitlestyle}>{speaker.TITLE}</div>
                                                <br />
                                                <div style={this.state.speakerdescstyle}>{ReactHtmlParser(speaker.DESCRIPTION)}</div>
                                                <br />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            )
                    }
                    <br />
                    <br />
                </div>
            );
        })
    }
}
export default Speakers2;
