import API from "@aws-amplify/api";
import { getEventPlannerService } from "../ServiceNameMapper";

async function getLoggedinExhibitor(userid) {
    try {
        let apiName = getEventPlannerService();
        let path = ''; //replace this with the path you have configured on your API
        let myInit =
        {
            body:
            {
                'code': global.$code,
                'command': 'getLoggedinExhibitor',
                'createdby': userid,
                'type': 'E'
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        var response = await API.post(apiName, path, myInit);
        //console.log(response);
        if (response.err == null && response.data != null) {
            let userObj = getExhibitorObject(response.data.data.Items)
            return userObj;
        }
        else {
            return null;
        }
    }
    catch (e) {
        console.log(e)
        return null
    }
}

function getExhibitorObject(objs) {
    //console.log(objs);
    //console.log(objs[0])
    var obj = objs[0]
    //console.log(obj)
    var newObj = Object()
    newObj.id = obj.id.S
    newObj.type = obj.type.S
    newObj.url = obj.url.S

    return newObj
}

export default { getLoggedinExhibitor }