/* eslint-disable */

import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { API } from 'aws-amplify';
import Modal from "react-responsive-modal";
import 'react-responsive-modal/styles.css';

import ListBids from './ListBids.jsx';
import AddBid from './AddBid.jsx';
// import PaypalAslatx from '../Registration/PaymentMethods/Customers/PaypalAslatx';
// import PaypalSnawa from '../Registration/PaymentMethods/Customers/PaypalSnawa';
var serviceNames = require('../ServiceNameMapper');

var auctionBids =
    [

    ]
/*
    ItemBids

    UI - This has Item bidding header (item info, Bid Buttion)

    Data:
        - This calls getAuction item to refresh latest # of bids and last bid
*/

class ItemBids extends React.Component {
    constructor(props) {
        //console.log(props)

        super(props);
        this.state = {
            code: props.code,
            itemid: props.itemid,
            bidsdivstyle: props.bidsdivstyle,
            bidssubdiv: props.bidssubdiv,
            bidspaynow: global.$auctions.styles.bidspaynow,
            bidspaynowamount: global.$auctions.styles.bidspaynowamountlabel,
            bidbuttonstyles: global.$auctions.styles.bidbutton,
            dataloaded: false,
            openpopup: false
        };
        this.refreshBids = this.refreshBids.bind(this);
    }

    componentDidMount() {
        //this.interval = setInterval(() => this.getAuctionItemBids(), 1000);
    }
    componentWillUnmount() {
        //clearInterval(this.interval);
    }

    // This reloads bids once bid is submitted.
    refreshBids() {
        this.setState({
            dataloaded: false
        })
    }

    onOpenPopup = () => {
        this.setState(prevState => ({
            openpopup: !prevState.openpopup
        }));
    };
    render() {

        //console.log('bidinfo - render');
        if (this.state.dataloaded) {

            let options = {
                timeZone: global.$settings.timezone,
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
            },

                formatter = new Intl.DateTimeFormat([], options);
            var currentdate = formatter.format(new Date());
            var curenttime = Date.parse(currentdate);
            var bidopentime = Date.parse(this.state.open);
            var bidclosetime = Date.parse(this.state.close);

            if ((curenttime >= bidopentime && curenttime <= bidclosetime) && this.state.status == 'O') {
                return (
                    <div style={this.state.bidsdivstyle}>

                        <div style={this.state.bidssubdiv}>
                            <div className="bids-div-1">
                                <div className="bid-open">
                                    Open
                                </div>
                                <AddBid
                                    code={this.state.code}
                                    itemid={this.state.itemid}
                                    startbid={this.state.startbid}
                                    bidinc={this.state.bidinc}
                                    lastbid={this.state.lastbid}
                                    bidcount={this.state.bidcount}
                                    open={this.state.open}
                                    close={this.state.close}
                                    status={this.state.status}
                                    refreshBids={this.refreshBids}
                                />
                            </div>
                        </div>

                        <div className="bids-div-3">
                            <ListBids
                                code={this.state.code}
                                itemid={this.state.itemid}
                            />
                        </div>

                    </div>
                );
            }
            else {
                return (
                    <div style={this.state.bidsdivstyle}>
                        <div className="bid-close">
                            Closed
                                </div>
                        {
                            global.$loginid.includes(this.state.lastbidder) ?
                                (
                                    <div style={this.state.bidspaynow}>
                                        <p style={this.state.bidspaynowamountlabel}>Amount($):  {this.state.lastbid}</p>
                                        {/*<Button style={this.state.bidbuttonstyles}><Link to={"./LoadExternalLinks/" + global.$auctions.paymentfile + "questionmarkfname=" + global.$attendeeDetails.fname + "&lname=" + global.$attendeeDetails.lname + "&email=" + global.$attendeeDetails.email + "&userid=" + global.$attendeeDetails.userid + "&amount=" + this.state.lastbid + "&itemname=" + this.state.title} >Pay Now</Link></Button>*/}
                                        <Button style={this.state.bidbuttonstyles} onClick={() => this.popup()}>Pay Now</Button>
                                        <Modal
                                            open={this.state.openpopup}
                                            onClose={this.onOpenPopup}
                                            blockScroll={false}
                                            className="sponsor-popup"
                                            styles={{
                                                modal: {
                                                    width: "100%"
                                                }
                                            }}
                                            center>
                                            {/* {
                                                this.state.code == "aslatx2021"?
                                                (
                                                    <PaypalAslatx
                                                        amount={this.state.lastbid}
                                                        itemname={this.state.title}
                                                        userid={global.$attendeeDetails.userid}
                                                        email={global.$attendeeDetails.email}
                                                        fname={global.$attendeeDetails.fname}
                                                        lname={global.$attendeeDetails.lname} />
                                                )
                                                :
                                                ('')
                                            }
                                            {
                                                this.state.code == "snawa2021conf"?
                                                (
                                                    <PaypalSnawa
                                                        amount={this.state.lastbid}
                                                        itemname={this.state.title}
                                                        userid={global.$attendeeDetails.userid}
                                                        email={global.$attendeeDetails.email}
                                                        fname={global.$attendeeDetails.fname}
                                                        lname={global.$attendeeDetails.lname} />
                                                )
                                                :
                                                ('')
                                            } */}
                                        </Modal>
                                    </div>
                                )
                                :
                                ('')
                        }
                        <div className="bids-div-3">
                            <ListBids code={this.state.code} itemid={this.state.itemid} />
                        </div>
                    </div>
                );
            }
        }
        else {
            this.getAuctionItem();
            return (
                <div>
                    <Row>
                        <Col md={6} className="offset-5">
                            <div className="loading-div">
                                <div className="loading-text">Loading....</div>
                            </div>
                        </Col>
                    </Row>
                </div>
            )
        }
    }

    popup() {
        this.onOpenPopup()
    }
    getAuctionItemObject(objs) {

        var obj = objs
        //console.log(obj)

        var newObj = Object()

        newObj.itemid = obj.itemid.S
        newObj.tag = obj.tag.S
        newObj.itemorder = obj.itemorder.S
        newObj.title = obj.title.S
        newObj.desc = obj.desc.S
        newObj.type = obj.type.S
        newObj.category = obj.category.S
        newObj.status = obj.status.S
        newObj.value = obj.value.N
        newObj.image1 = obj.image1.S
        newObj.startbid = obj.startbid.N
        newObj.bidinc = obj.bidinc.N
        newObj.lastbid = obj.lastbid.N
        newObj.bidcount = obj.bidcount.N
        newObj.lastbidtime = obj.lastbidtime.N
        newObj.ptype = obj.ptype.S
        newObj.payurl = obj.payurl.S
        newObj.open = obj.open.S
        newObj.close = obj.close.S
        newObj.lastbidder = obj.lastbidder.S
        newObj.h = obj.h.N
        newObj.w = obj.w.N

        //console.log(newObj)

        return newObj
    }

    getAuctionItem() {
        //console.log("ItemBids:getAuctionItem()");

        let apiusername = serviceNames.getAuctionService();
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'code': this.state.code,
                'command': 'getAuctionItem',
                'itemid': this.state.itemid
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //console.log(myInit);

        API.post(apiusername, path, myInit).then(response => {
            //console.log(response.data);

            if (response.err == null && response.data != null) {

                //console.log(response.data);
                let auctionItemObj = this.getAuctionItemObject(response.data.Item)

                this.setState({
                    title: auctionItemObj.title,
                    startbid: auctionItemObj.startbid,
                    bidinc: auctionItemObj.bidinc,
                    lastbid: auctionItemObj.lastbid,
                    bidcount: auctionItemObj.bidcount,
                    open: auctionItemObj.open,
                    close: auctionItemObj.close,
                    status: auctionItemObj.status,
                    lastbidder: auctionItemObj.lastbidder,
                    dataloaded: true
                })
            }
            else {
                //console.log(response.err);
            }
        }
        ).catch(error => {
            //console.log(error.response)
        });
    }
}
export default ItemBids;
