
import React, { Component } from "react";
import * as BABYLON from "babylonjs";
import { API, Analytics } from 'aws-amplify';
import { Row, Col} from 'reactstrap';
import { Modal } from 'react-responsive-modal';
import ReactPlayer from 'react-player';
import SponsorContactForm from '../Sponsors/SponsorContactForm';
import VirtualPopupTabs from "./VirtualPopupTabs";
var serviceNames = require('../ServiceNameMapper');

var scene;

/*

Notes:

Canvas width/height

			style={{ width: window.innerWidth, height: window.innerHeight }}

 			width: 100%;
            height: 100%;
			touch-action: none;
			overflow: hidden;
            width   : 100%;
            height  : 100%;
            margin  : 0;
            padding : 0;


Popup with Tabcontrol to show  video, videochat and contact form

Popup to show:
	Use Up, Down, Left and Right arrow keys to move
	Mouse Press + Move to look around

			
*/


class VirtualExpoHall extends Component {
  
	constructor(props) {
    super(props);
    this.state = { 
        useWireFrame: false, 
        shouldAnimate: false,
        code: props.code,
        s3: props.s3,
        bucket: props.bucket,
		dataloaded: false,
		playing: true,
		playvideoopen: false,
		videourl: '',
		videocallopen: false,
		contactformopen: false,
		alloptionsopen: false
       };

	this.getFilePath = this.getFilePath.bind(this);
  }

  componentDidMount = () => {
    
  //console.log('componentDidMount');

    
	if (global.$exhibitorjson == null )
	{
		this.getExhibitorjson()
	}
	else
	{
		this.createScene();
	}
	
	
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.onWindowResize, false);
  }

  onWindowResize = event => {


	console.log('VirtualExpoRoom:onWindowResize');

    this.engine.resize();
  };

  onOpenVideoCall = () => {
	console.log('onOpenVideoCall');
	this.setState(prevState => ({
		videocallopen: !prevState.videocallopen
	}));
  };

  onOpenPlayVideo = () => {
	//console.log('onOpenPlayVideoCall');
	this.setState(prevState => ({
		playvideoopen: !prevState.playvideoopen
	}));
  };

  onOpenContactForm = () => {
	////console.log('onOpenContactForm');
	this.setState(prevState => ({
		contactformopen: !prevState.contactformopen
	}));
  };

  onOpenAllOptions = () => {
	//console.log('onOpenAllOptions');
	this.setState(prevState => ({
		alloptionsopen: !prevState.alloptionsopen
	}));
  };


render() {

	//console.log('VirtualExpoRoom:render');

	Analytics.record({ name: 'PageView', attributes: { name: 'eventsites-'+this.state.code+'-virtualexpo'}});

		console.log(this.state);
		return (
				<div>
					<canvas
					style={{ width: "100%", height: "100%" }}
					ref={canvas => {
						this.canvas = canvas;

						//console.log('calling createScene in ref!!');
						//this.createScene();

					}}
					/>
					
					<Modal
                        open={this.state.playvideoopen}
                        onClose={this.onOpenPlayVideo}
                        styles={{
                            modal: {
                                width: "100%"
                            }
                        }}
                        center>
							<ReactPlayer
							url={this.state.videourl}
							width="100%"
							height="calc(80vh - 100px)"
							controls={true}
							playing={this.state.playing}
							/>
                    </Modal>

					<Modal
                        open={this.state.videocallopen}
                        onClose={this.onOpenVideoCall}
                        styles={{
                            modal: {
                                width: "100%"
                            }
                        }}
                        center>
                        <iframe src={this.state.chaturl}
                            id="myId"
                            className="myClassname"
                            display="initial"
                            position="relative"
                            width="100%"
                            height="calc(80vh - 100px)"
                            allow="camera *;microphone *"
                        />
                    </Modal>

					<Modal
                        open={this.state.contactformopen}
                        onClose={this.onOpenContactForm}
                        styles={{
                            modal: {
                                width: "100%"
                            }
                        }}
                        center>
							<SponsorContactForm email=''/>
                    </Modal>
					<Modal
                        open={this.state.alloptionsopen}
                        onClose={this.onOpenAllOptions}
                        styles={{
                            modal: {
                                width: "100%"
                            }
                        }}
                        center>
							<VirtualPopupTabs video={this.state.videourl} chat={this.state.chaturl}/>
                    </Modal>
				</div>
		)

}//render

getExhibitorjson() {
	console.log("exhibitor json")
	
	let apiName = serviceNames.getWebsiteService();
	let path = ''; //replace this with the path you have configured on your API
	let myInit = {
	  body:
	  {
		'code': this.state.code,
		'command': 'getJsonFileFromS3',
		'bucket': this.state.bucket,
		'filename': 'exhibitors'
	  },
	  response: true,
	  convertEmptyValues: true,
	  headers: {}
	}
   //console.log(myInit);
  
	API.post(apiName, path, myInit).then(response => {
	  //console.log (response);
  
	  if (response.err == null && response.data != null) {
		let buf = Buffer.from(response.data.Body);
		//console.log(buf)
		//console.log(buf.toString());
		global.$exhibitorjson = JSON.parse(buf.toString());
		
		//this.setState({ dataloaded: true });
		this.createScene();  


	}
	  else {
	  //console.log(response.err);
	  }
	}
	).catch(error => {
	//console.log(error)
	});
  }

getFilePath(filename)
{
	return `https://s3.amazonaws.com/event360live/public/${this.state.code}/exhibitors/` + filename;
}

  createScene()
  {
	 //console.log('createScene');
	  
	  //if (this.canvas == null)
	  if (global.$exhibitorjson == null )
	  {
		 //console.log('exhibitorjson is null, so createScene is done!');
		  return;
	  }

	//console.log('global exhibitors ok');
	//console.log(global.$exhibitorjson);
	
	var totalBooths = global.$exhibitorjson.length;
	
	 //console.log('totalBooths = ' + totalBooths);

      var boothWidth 	= 40;
	  var rows 			= 4;
	  var cols 			= Math.floor(totalBooths/rows);
	  var xspace 		= boothWidth/2;
	  var zspace 		= boothWidth/2;
	  var padding 		= boothWidth;
  
	  var boothsPerSide = Math.floor(totalBooths / 4);

	  //console.log('boothsPerSide = ' + boothsPerSide);

      //var floorW = boothWidth * boothsPerSide + space * (boothsPerSide - 1) + space * 2 ;
    
      var floorW = (boothWidth + ( xspace - 1) ) * cols + 2 * padding  ;

	  
      // start ENGINE
      this.engine = new BABYLON.Engine(this.canvas, true);

      //Create Scene
      scene = new BABYLON.Scene(this.engine);

      //This creates a basic Babylon Scene object (non-mesh)
      //var scene = new BABYLON.Scene(engine);
      scene.gravity = new BABYLON.Vector3(0, -9.81, 0);
      scene.collisionsEnabled = true;
	  
	this.initCameras(scene, floorW/2, boothWidth * 3, -1 * (floorW/4) );

	this.initLights(scene);
    
    //var groundW = floorW + 4; //Add a few more space for walls
    var groundW = floorW; //Add a few more space for walls
    this.createGround(scene, groundW * 4);
  
    var wallH = boothWidth * 2;
    //this.createWalls(scene, groundW, wallH, boothWidth); 
    
    this.createBooths(scene, totalBooths, floorW, boothWidth, xspace);

    // Add Events
    window.addEventListener("resize", this.onWindowResize, false);

        // Render Loop
        this.engine.runRenderLoop(() => {
          scene.render();
        });
    
  
    //return scene;
  }

  initCameras(scene, x, y, z)
  {
    /*
      // This creates and positions a free camera (non-mesh)
      var camera = new BABYLON.FreeCamera("camera1", new BABYLON.Vector3(0, 5, -10), scene);

      // This targets the camera to scene origin
      camera.setTarget(BABYLON.Vector3.Zero());

      // This attaches the camera to the canvas
      camera.attachControl(canvas, true);
    */

	
	
	//var camera = new BABYLON.FreeCamera("UniversalCamera", new BABYLON.Vector3(x, y, z), scene);
	var camera = new BABYLON.UniversalCamera("UniversalCamera", new BABYLON.Vector3(x, y, z), scene);
	camera.attachControl(this.canvas, false);  //NOTE:  False is required to avoid webpage scrolling
	

	//camera.rotate = new BABYLON.Vector3(0,  -1* Math.PI/4, 0);
	//camera.rotate = new BABYLON.Vector3(0,  -1* Math.PI/4, 0);
	//camera.noRotationConstraint = true;
	//camera.upVector = new BABYLON.Vector3(0,  10, 0);
    // Targets the camera to a particular position. In this case the scene origin
    //camera.setTarget(BABYLON.Vector3.Zero());


	//var camera = new BABYLON.FlyCamera("UniversalCamera", new BABYLON.Vector3(x, y, z), scene);
	//camera.attachControl(this.canvas, false);  //NOTE:  False is required to avoid webpage scrolling

      // Attach the camera to the canvas

      camera.applyGravity = true;
      camera.ellipsoid = new BABYLON.Vector3(1, 1, 1);
	  camera.checkCollisions = true;



	  //camera.minZ = 0.0001;
	  //camera.attachControl(canvas, true);
	  //camera.speed = 0.02;
	  //camera.angularSpeed = 0.05;
	  camera.angle = Math.PI/2;
	  camera.direction = new BABYLON.Vector3(Math.cos(camera.angle), 0, Math.sin(camera.angle));

	      //First remove the default management.
		  camera.inputs.removeByType("FreeCameraKeyboardMoveInput");
		  //camera.inputs.removeByType("FreeCameraMouseInput");
		   
		  //Key Input Manager To Use Keys to Move Forward and BackWard and Look to the Left or Right
		  var FreeCameraKeyboardWalkInput = function () {
			  this._keys = [];
			  this.keysUp = [38];
			  this.keysDown = [40];
			  this.keysLeft = [37];
			  this.keysRight = [39];
		  }
		  
		  //Add attachment controls
		  FreeCameraKeyboardWalkInput.prototype.attachControl = function (noPreventDefault) {
				  var _this = this;
				  var engine = this.camera.getEngine();
				  var element = engine.getInputElement();
				  if (!this._onKeyDown) {
					  element.tabIndex = 1;
					  this._onKeyDown = function (evt) {                 
						  if (_this.keysUp.indexOf(evt.keyCode) !== -1 ||
							  _this.keysDown.indexOf(evt.keyCode) !== -1 ||
							  _this.keysLeft.indexOf(evt.keyCode) !== -1 ||
							  _this.keysRight.indexOf(evt.keyCode) !== -1) {
							  var index = _this._keys.indexOf(evt.keyCode);
							  if (index === -1) {
								  _this._keys.push(evt.keyCode);
							  }
							  if (!noPreventDefault) {
								  evt.preventDefault();
							  }
						  }
					  };
					  this._onKeyUp = function (evt) {
						  if (_this.keysUp.indexOf(evt.keyCode) !== -1 ||
							  _this.keysDown.indexOf(evt.keyCode) !== -1 ||
							  _this.keysLeft.indexOf(evt.keyCode) !== -1 ||
							  _this.keysRight.indexOf(evt.keyCode) !== -1) {
							  var index = _this._keys.indexOf(evt.keyCode);
							  if (index >= 0) {
								  _this._keys.splice(index, 1);
							  }
							  if (!noPreventDefault) {
								  evt.preventDefault();
							  }
						  }
					  };
					  element.addEventListener("keydown", this._onKeyDown, false);
					  element.addEventListener("keyup", this._onKeyUp, false);
				  }
			  };
	  
			  //Add detachment controls
			  FreeCameraKeyboardWalkInput.prototype.detachControl = function () {
				  var engine = this.camera.getEngine();
				  var element = engine.getInputElement();
				  if (this._onKeyDown) {
					  element.removeEventListener("keydown", this._onKeyDown);
					  element.removeEventListener("keyup", this._onKeyUp);
					  BABYLON.Tools.UnregisterTopRootEvents([
						  { name: "blur", handler: this._onLostFocus }
					  ]);
					  this._keys = [];
					  this._onKeyDown = null;
					  this._onKeyUp = null;
				  }
			  };
	  			  
			//Keys movement control by checking inputs
			FreeCameraKeyboardWalkInput.prototype.checkInputs = function () {
				if (this._onKeyDown) {
					var camera = this.camera;
					for (var index = 0; index < this._keys.length; index++) {
						var keyCode = this._keys[index];
						var speed = camera.speed;
						if (this.keysLeft.indexOf(keyCode) !== -1) {
							camera.rotation.y -= camera.angularSpeed;
							camera.direction.copyFromFloats(0, 0, 0);                
						}
						else if (this.keysUp.indexOf(keyCode) !== -1) {
							camera.direction.copyFromFloats(0, 0, speed);               
						}
						else if (this.keysRight.indexOf(keyCode) !== -1) {
							camera.rotation.y += camera.angularSpeed;
							camera.direction.copyFromFloats(0, 0, 0);
						}
						else if (this.keysDown.indexOf(keyCode) !== -1) {
							camera.direction.copyFromFloats(0, 0, -speed);
						}
						if (camera.getScene().useRightHandedSystem) {
							camera.direction.z *= -1;
						}
						camera.getViewMatrix().invertToRef(camera._cameraTransformMatrix);
						BABYLON.Vector3.TransformNormalToRef(camera.direction, camera._cameraTransformMatrix, camera._transformedDirection);
						camera.cameraDirection.addInPlace(camera._transformedDirection);
					}
				}
			};
			  
	  
			  //Add the onLostFocus function
			  FreeCameraKeyboardWalkInput.prototype._onLostFocus = function (e) {
				  this._keys = [];
			  };
			  
			  //Add the two required functions for the control Name
			  FreeCameraKeyboardWalkInput.prototype.getClassName = function () {
				  return "FreeCameraKeyboardWalkInput";
			  };
	  
			  FreeCameraKeyboardWalkInput.prototype.getSimpleName = function () {
				  return "keyboard";
			  };
		  
		  //Add the new keys input manager to the camera.
		camera.inputs.add(new FreeCameraKeyboardWalkInput());
	  
  }

initLights(scene)
{
	// This creates a light, aiming 0,1,0 - to the sky (non-mesh)
    var light = new BABYLON.HemisphericLight("light", new BABYLON.Vector3(0, 1, 0), scene);

    // Default intensity is 1. Let's dim the light a small amount
    light.intensity = 0.7;
}

createGround(scene, floorW)
{
	// Our built-in 'ground' shape.
    var ground = BABYLON.MeshBuilder.CreateGround("ground", {width: floorW, height: floorW}, scene);
	ground.checkCollisions = true;

	this.createReferenceMarker(scene);
	
	var groundM = new BABYLON.StandardMaterial("groundM", scene);
	groundM.diffuseTexture   = new BABYLON.Texture(global.$config['virtualexporoom'].walls.floor.background, scene);
	groundM.specularTexture  = new BABYLON.Texture(global.$config['virtualexporoom'].walls.floor.background, scene);
	groundM.emissiveTexture  = new BABYLON.Texture(global.$config['virtualexporoom'].walls.floor.background, scene);
	groundM.ambientTexture   = new BABYLON.Texture(global.$config['virtualexporoom'].walls.floor.background, scene);
	ground.material = groundM;
	
	
	var centerMat = BABYLON.MeshBuilder.CreateCylinder("cone", {diameterTop: floorW/4, diameterBottom: floorW/4, height:.1}, scene);
	centerMat.position.x = 0;
    centerMat.position.y = 0; 
    centerMat.position.z = 0;
	centerMat.checkCollisions = true;
	
	var centerMatM = new BABYLON.StandardMaterial("centerMatM", scene);
	centerMatM.diffuseTexture  	= new BABYLON.Texture(global.$config['virtualexporoom'].walls.floor.centermat, scene);
	centerMatM.specularTexture  = new BABYLON.Texture(global.$config['virtualexporoom'].walls.floor.centermat, scene);
	centerMatM.emissiveTexture  = new BABYLON.Texture(global.$config['virtualexporoom'].walls.floor.centermat, scene);
	centerMatM.ambientTexture  	= new BABYLON.Texture(global.$config['virtualexporoom'].walls.floor.centermat, scene);
	centerMat.material = centerMatM;	
}

createWalls(scene, wallWidth, wallHeight, boothWidth) 
{
	var wallDepth = .01;

	var frontwall = BABYLON.MeshBuilder.CreateBox("frontwall", {height: wallHeight, width: wallWidth, depth:wallDepth}, scene);
    
	//Place it in the center and move back
    frontwall.position.x = 0;  
    frontwall.position.y = wallHeight/2;
    frontwall.position.z =  wallWidth/2 * -1;
    frontwall.checkCollisions = true;

	
    var backwall = BABYLON.MeshBuilder.CreateBox("backwall", {height: wallHeight, width: wallWidth, depth:wallDepth}, scene);
    
    backwall.position.x = 0;  
    backwall.position.y = wallHeight/2;
    backwall.position.z =  wallWidth/2;
    backwall.checkCollisions = true;
	
	/*
	var frontwallM = new BABYLON.StandardMaterial("frontwallM", scene);
	frontwallM.diffuseTexture  	= new BABYLON.Texture("https://wesicore.s3.amazonaws.com/test/frontwall.png", scene);
	frontwallM.specularTexture  = new BABYLON.Texture("https://wesicore.s3.amazonaws.com/test/frontwall.png", scene);
	frontwallM.emissiveTexture  = new BABYLON.Texture("https://wesicore.s3.amazonaws.com/test/frontwall.png", scene);
	frontwallM.ambientTexture  	= new BABYLON.Texture("https://wesicore.s3.amazonaws.com/test/frontwall.png", scene);
	frontwall.material = frontwallM;
	*/

	//console.log('Wall background = ' + global.$config['virtualexporoom'].walls.back.background)

	var backwallM = new BABYLON.StandardMaterial("backwallM", scene);
	backwallM.diffuseTexture  	= new BABYLON.Texture(global.$config['virtualexporoom'].walls.back.background, scene);
	backwallM.specularTexture  = new BABYLON.Texture(global.$config['virtualexporoom'].walls.back.background, scene);
	backwallM.emissiveTexture  = new BABYLON.Texture(global.$config['virtualexporoom'].walls.back.background, scene);
	backwallM.ambientTexture  	= new BABYLON.Texture(global.$config['virtualexporoom'].walls.back.background, scene);
	//backwall.material = backwallM;

	/*
	var rightwallM = new BABYLON.StandardMaterial("rightwallM", scene);
	rightwallM.diffuseTexture  	= new BABYLON.Texture("https://wesicore.s3.amazonaws.com/test/rightwall.png", scene);
	rightwallM.specularTexture  = new BABYLON.Texture("https://wesicore.s3.amazonaws.com/test/rightwall.png", scene);
	rightwallM.emissiveTexture  = new BABYLON.Texture("https://wesicore.s3.amazonaws.com/test/rightwall.png", scene);
	rightwallM.ambientTexture  	= new BABYLON.Texture("https://wesicore.s3.amazonaws.com/test/rightwall.png", scene);
	rightwall.material = rightwallM;
	*/

	/*
	var leftwallM = new BABYLON.StandardMaterial("leftwallM", scene);
	leftwallM.diffuseTexture  	= new BABYLON.Texture("https://wesicore.s3.amazonaws.com/test/leftwall.png", scene);
	leftwallM.specularTexture  = new BABYLON.Texture("https://wesicore.s3.amazonaws.com/test/leftwall.png", scene);
	leftwallM.emissiveTexture  = new BABYLON.Texture("https://wesicore.s3.amazonaws.com/test/leftwall.png", scene);
	leftwallM.ambientTexture  	= new BABYLON.Texture("https://wesicore.s3.amazonaws.com/test/leftwall.png", scene);
	leftwall.material = leftwallM;
	*/
				
    var rightwall = BABYLON.MeshBuilder.CreateBox("rightwall", {height: wallHeight, width: wallWidth, depth:wallDepth}, scene);
    
    rightwall.position.x = wallWidth/2 * -1;  
    rightwall.position.y = wallHeight/2 ;
    rightwall.position.z =  0;
	rightwall.rotate(BABYLON.Axis.Y, Math.PI/2, BABYLON.Space.WORLD);    
    rightwall.checkCollisions = true;
	
	var leftwall = BABYLON.MeshBuilder.CreateBox("leftwall", {height: wallHeight, width: wallWidth, depth:wallDepth}, scene);
    
	//Place it right and rotate
    leftwall.position.x = wallWidth/2;  
    leftwall.position.y = wallHeight/2;
    leftwall.position.z =  0;
    leftwall.rotate(BABYLON.Axis.Y, 3 * Math.PI/2, BABYLON.Space.WORLD);
    leftwall.checkCollisions = true;
		
	frontwall.material 	= backwallM;
	backwall.material 	= backwallM;
	rightwall.material 	= backwallM;
	leftwall.material 	= backwallM;

    var topwall = BABYLON.MeshBuilder.CreateBox("topwall", {height: wallWidth, width: wallWidth, depth:wallDepth}, scene);
    
    topwall.position.x = 0;  
    topwall.position.y = wallHeight;
    topwall.position.z =  0;
    topwall.rotate(BABYLON.Axis.X, Math.PI/2, BABYLON.Space.WORLD);
    topwall.checkCollisions = true;
		
	var topwallM = new BABYLON.StandardMaterial("topwallM", scene);
	topwallM.diffuseTexture  	= new BABYLON.Texture(global.$config['virtualexporoom'].walls.top.background, scene);
	topwallM.specularTexture  = new BABYLON.Texture(global.$config['virtualexporoom'].walls.top.background, scene);
	topwallM.emissiveTexture  = new BABYLON.Texture(global.$config['virtualexporoom'].walls.top.background, scene);
	topwallM.ambientTexture  	= new BABYLON.Texture(global.$config['virtualexporoom'].walls.top.background, scene);
	topwall.material = topwallM;
			
	var bannerW = boothWidth * 2;
	var bannerH = bannerW/4;
	var bannerD = .05;
	
	this.createWallBanners(scene, frontwall, 5, bannerW, bannerH, bannerD, "F");
	this.createWallBanners(scene, backwall, 5, bannerW, bannerH, bannerD, "B");
	this.createWallBanners(scene, leftwall, 5, bannerW, bannerH, bannerD, "L");
	this.createWallBanners(scene, rightwall, 5, bannerW, bannerH, bannerD, "R");				
}

createWallBanners(scene, parent, bannerY, bannerW, bannerH, bannerD, side) 
{
    var banner = BABYLON.MeshBuilder.CreateBox("banner", {height: bannerH, width: bannerW, depth:bannerD}, scene);
	banner.parent = parent;
	
	banner.position.x = 0;  
    banner.position.y = bannerY;
    banner.position.z =  0;
  
	banner.actionManager = new BABYLON.ActionManager(scene);
	
	var bannerM = new BABYLON.StandardMaterial("bannerM", scene);
	//tvM.emissiveColor = new BABYLON.Color3(1, 1, 1);
	//var videoT = new BABYLON.VideoTexture("video", "./wallbanner1.mp4", scene, true);
	var path = global.$config['virtualexporoom'].walls.back.video
	var videoT = new BABYLON.VideoTexture("video", path, scene, true);
	
	if (side == 'F')
	{
		videoT.uScale = -1; // Mirror Horizontally
		videoT.vScale = -1; // Mirror Vertically
	}
	else if (side == 'B')
	{ //not ok (This is the back wall from room perspective
	
		videoT.uScale = 1; // Mirror Horizontally
		videoT.vScale = 1; // Mirror Vertically
	}
	
	else{
		videoT.uScale = -1; // Mirror Horizontally
		videoT.vScale = -1; // Mirror Vertically
	}
	
	bannerM.diffuseTexture 	= videoT;
	bannerM.specularTexture = videoT;
	bannerM.emissiveTexture = videoT;
	bannerM.ambientTexture 	= videoT;
	
	banner.material = bannerM;
	banner.material.diffuseTexture.video.play();
}

/*
    NOTE Z -ve is forward
*/

createBooths(scene, totalBooths, floorW, platformW, space)
{
	var rows 	= 4;
	var cols 	= Math.floor(totalBooths/rows);
	var xspace 	= platformW/2;
	var zspace 	= platformW/2;
	var padding = platformW;

	console.log(`rows = ${rows}, cols = ${cols}`);

	for ( var r = 0; r < rows; r++)
	{
		for ( var c = 0; c < cols; c++)
		{
			var boothNo = r * rows + c;	

			var startX 	= padding + r * (platformW + xspace);
			var startZ  = padding + c * (platformW + zspace); 
			var startY  = 0; 

			console.log(`Booth No = ${boothNo}, x = ${startX}, z= ${startZ}`);

			this.createBooth(scene, boothNo, platformW, startX, startY, startZ, 'F');
		}
	}
}

createBooth(scene, boothNo, platformWidth, platformX, platformY, platformZ, platformR) 
{
	//console.log('createBooth ' + boothNo);

	var exhibitor = null;

	//console.log(global.$exhibitorjson);

	if (global.$exhibitorjson != null)
	{
		exhibitor = global.$exhibitorjson[boothNo];
	}

	//console.log(exhibitor);

	var platformW = platformWidth;
    //var platformH = .1;
	//var platformD = .5;
    var platformH = 1;
	var platformD = 1;

	var backwallW = platformW;
	var backwallH = backwallW / 2;
	//var backwallD = .1;
	var backwallD = 1;

	
	//var backwallX = 0;
	//var backwallY = backwallH;
	//var backwallZ = 0;

	//var rightColW = backwallW / 2;
    //var rightColH = rightColW * 2;

	//var platformW = backwallW + rightColW +1;
    //var platformH = .1;
	
	//Bottom Platform
	var platform = BABYLON.MeshBuilder.CreateBox("platform", {height: platformH, width: platformW, depth:platformD}, scene);
 
	platform.position.x = platformX;
    platform.position.y = 0; 
    platform.position.z = platformZ;
    
    //platform.scaling.z = .25;
	//platform.rotation.y = platformR;
	platform.checkCollisions = true;

	//Backwall
    var backwall = BABYLON.MeshBuilder.CreateBox("backwall", {height: backwallH, width: backwallW, depth:backwallD}, scene);
	backwall.parent = platform

    backwall.position.x = 0;
    backwall.position.y = backwallH/2;
    backwall.position.z = 0;
    //backwall.scaling.z = .10;
    backwall.checkCollisions = true;
	
	//var tvW = backwallW/2;
	//var tvH = backwallH/2;
	var tvW = backwallW - 2;
	//var tvH = backwallH - .2;
	var tvH = tvW/2;
	var tvD = backwallD/4
	this.createTV(scene, backwall, exhibitor, tvW, tvH, tvD);

	var commandX = 0;
	var commandH = 1;
	var commandY = tvH/2 + commandH/2 + .2;
	//var commandD = tvD;
	var commandD = .01;
	//var commandD = 0;

	//this.createCommandPanel(scene, backwall, exhibitor, commandX, commandY, commandD);

    //Right column
	/*
	var rightColumn = BABYLON.MeshBuilder.CreateCylinder("cone", {diameterTop: rightColW, diameterBottom: rightColW, height:rightColH}, scene);
    rightColumn.parent = platform;

	//Place right column at the end of backwall and tilt
	rightColumn.position.x = backwallW/2;
    rightColumn.position.y = rightColH/2;
    rightColumn.position.z = .5;
    
    rightColumn.scaling.z = .25;
	rightColumn.rotation.y = -15;
    rightColumn.checkCollisions = true;
	*/
    //createTable(scene, platform);

    var platformM = new BABYLON.StandardMaterial("platformM", scene);
	platformM.diffuseColor = new BABYLON.Color3(1, 1, 1);
	platformM.specularColor = new BABYLON.Color3(1, 1, 1);
	platformM.emissiveColor = new BABYLON.Color3(1, 1, 1);
	platformM.ambientColor = new BABYLON.Color3(1, 1, 1);
	platform.material = platformM;

    var backwallM = new BABYLON.StandardMaterial("backwallM", scene);
	backwallM.diffuseColor = new BABYLON.Color3(0, 0, 0);
	backwallM.specularColor = new BABYLON.Color3(0, 0, 0);
	backwallM.emissiveColor = new BABYLON.Color3(0, 0, 0);
	backwallM.ambientColor = new BABYLON.Color3(0, 0, 0);
	backwall.material = backwallM;

	/*
    var rightColumnM = new BABYLON.StandardMaterial("rightColumnM", scene);
	rightColumnM.diffuseColor = new BABYLON.Color3(1, 1, 1);
	rightColumn.material = rightColumnM;
	*/
		
	if (platformR == 'F')
	{
		platform.rotate(BABYLON.Axis.Y, Math.PI, BABYLON.Space.WORLD);
	}
	else if (platformR == 'B')
	{
		platform.rotate(BABYLON.Axis.Y, 2 * Math.PI, BABYLON.Space.WORLD);
	}
	else if (platformR == 'R')
	{
		platform.rotate(BABYLON.Axis.Y, Math.PI/2, BABYLON.Space.WORLD);
	}
	else if (platformR == 'L')
	{
		platform.rotate(BABYLON.Axis.Y, 3 * Math.PI/2, BABYLON.Space.WORLD);	
	}
}

createTV(scene, parent, exhibitor, tvW, tvH, tvD) 
{
	var self = this;

 	//var tvD = .25;
    var tv = BABYLON.MeshBuilder.CreateBox("tv", {height: tvH, width: tvW, depth:tvD}, scene);
    tv.parent = parent;

    tv.position.x 		= 0;  
    tv.position.y 		= 0;
    tv.position.z 		= tvD * 2;
    tv.checkCollisions 	= true;
	
	var logoM = new BABYLON.StandardMaterial("logoM", scene);
	
	var path = '';
	if ( exhibitor.TYPE == 'T')
	{
		path = global.$config['virtualexporoom'].default.background
	}
	else
	{
		//path = this.getFilePath(exhibitor.LOGO);
		path = this.getFilePath(exhibitor.ID + '-image1.png');
	}
	//console.log(path);

	var texture = new BABYLON.Texture(path, scene);
	
	//texture.invertY = true;
	
	texture.uScale = -1; // Mirror Horizontally
	texture.vScale = -1; // Mirror Vertically

	logoM.diffuseTexture   = texture;
	logoM.specularTexture  = texture;
	logoM.emissiveTexture  = texture;
	logoM.ambientTexture   = texture;
		
	tv.material = logoM;
	
	tv.actionManager = new BABYLON.ActionManager(scene);
	
	tv.actionManager.registerAction(
        new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOverTrigger, 
				function(event){
					
					//console.log("tv OnPointerOverTrigger");
					
					/*
					var tv = event.meshUnderPointer; 
	
					var tvM = new BABYLON.StandardMaterial("tvM", scene);
				
					if (exhibitor.TYPE == 'T')
					{
						return		
					}

					var videoT = new BABYLON.VideoTexture("video", exhibitor.VIDEOURL, scene, true);
					
					videoT.uScale = -1; // Mirror Horizontally
					videoT.vScale = -1; // Mirror Vertically
	
					tvM.diffuseTexture 	= videoT;
					tvM.specularTexture = videoT;
					tvM.emissiveTexture = videoT;
					tvM.ambientTexture 	= videoT;
					
					tv.material = tvM;
					tv.material.diffuseTexture.video.play();
					
					//window.open("http://doc.babylonjs.com/");
					*/

				})//ExecuteCodeAction
    );//registerAction
	
	tv.actionManager.registerAction(
        new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOutTrigger, 
				function(event){
					
					//console.log("tv OnPointerOutTrigger");

					/*
					var tv = event.meshUnderPointer; 
					//tv.material.diffuseTexture.video.pause();
					
					if (tv.material.diffuseTexture.video != null)
					{
						console.log("tv OnPointerOutTrigger - pausing video");

						tv.material.diffuseTexture.video.pause();
					}

					console.log("tv OnPointerOutTrigger - changing background...");

					var path = '';
					if ( exhibitor.TYPE == 'T')
					{
						path = global.$config['virtualexporoom'].default.background
					}
					else
					{
						path = self.getFilePath(exhibitor.LOGO);
					}
					console.log(path);

					var texture = new BABYLON.Texture(path, scene);
					
					//texture.invertY = true;
					texture.uScale = -1; // Mirror Horizontally
					texture.vScale = -1; // Mirror Vertically

					logoM.diffuseTexture   = texture;
					logoM.specularTexture  = texture;
					logoM.emissiveTexture  = texture;
					logoM.ambientTexture   = texture;
						
					tv.material = logoM;
					*/

				})//ExecuteCodeAction
    );//registerAction
	
	tv.actionManager.registerAction(
        new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger, 
				function(event){
					//console.log("tv onPickTrigger");
					global.$virtualexhibitor = exhibitor;
					self.onOpenAllOptions();
				})//ExecuteCodeAction
	);//registerAction		
}

createCommandPanel(scene, parent, exhibitor, panelX, panelY, panelD) 
{
	//console.log('createCommandPanel');

	var self = this;

	var path = '';

	var panelW = 3;
	var panelH = 1;
	//var panelD = 0;

	var commandW = 1;
	var commandH = 1;
	var commandZ = .1; // This is needed to get onPick event as commands have to out outside of the panel

	var panel = BABYLON.MeshBuilder.CreateBox( exhibitor.ID + "panel", {height: panelH, width: panelW, depth:panelD}, scene);
	panel.parent = parent;
	panel.position.x = panelX;  
    panel.position.y = panelY;
	panel.position.z = panelD * 2;

	var panelM = new BABYLON.StandardMaterial("panelM", scene);
	panelM.diffuseColor = new BABYLON.Color3(255, 0, 0);
	panelM.specularColor = new BABYLON.Color3(255, 0, 0);
	panelM.emissiveColor = new BABYLON.Color3(255, 0, 0);
	panelM.ambientColor = new BABYLON.Color3(255, 0, 0);
	panelM.alpha = 0;

	panel.material = panelM;
	

	//Video Option
	var video = BABYLON.MeshBuilder.CreateBox("video", {height: commandH, width: commandW, depth:panelD}, scene);
	video.parent = panel;
	video.position.x = 1;  
	video.position.y = 0;
	video.position.z = commandZ;
	
	var videoM = new BABYLON.StandardMaterial("videoM", scene);

	path = this.getFilePath('icon_video.png');	
	//console.log(path);
	var videoT = new BABYLON.Texture(path, scene);
	videoT.hasAlpha = true;
	videoT.uScale = -1; // Mirror Horizontally
	videoT.vScale = -1; // Mirror Vertically

	videoM.diffuseTexture   = videoT;
	videoM.specularTexture  = videoT;
	videoM.emissiveTexture  = videoT;
	videoM.ambientTexture   = videoT;
	video.material = videoM;

	video.actionManager = new BABYLON.ActionManager(scene);
	//video.checkCollisions 	= true;

	video.actionManager.registerAction(
		new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger, 
				function(event){					
					//console.log("video onPickTrigger");
					global.$virtualexhibitor = exhibitor;
					self.setState({videourl: exhibitor.VIDEOURL});
					self.onOpenPlayVideo();
				})//ExecuteCodeAction
	);//registerAction	


	var videoCall = BABYLON.MeshBuilder.CreateBox(exhibitor.ID + "videoCall", {height: commandH, width: commandW, depth:panelD}, scene);

	videoCall.parent = panel;
	videoCall.position.x = 0;  
    videoCall.position.y = 0;
	videoCall.position.z = commandZ;

	path = this.getFilePath('icon_videocall.png');	
	//console.log(path);
	
	var videoCallM = new BABYLON.StandardMaterial("videoM", scene);
	
	var videoCallT = new BABYLON.Texture(path, scene);

	videoCallT.uScale = -1; // Mirror Horizontally
	videoCallT.vScale = -1; // Mirror Vertically
	videoCallT.hasAlpha = true;

	videoCallM.diffuseTexture   = videoCallT;
	videoCallM.specularTexture  = videoCallT;
	videoCallM.emissiveTexture  = videoCallT;
	videoCallM.ambientTexture   = videoCallT;
	videoCall.material = videoCallM;
	videoCall.actionManager = new BABYLON.ActionManager(scene);
	//videoCall.checkCollisions 	= true;

	videoCall.actionManager.registerAction(
        new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger, 
				function(event){
					
					//console.log("videoCall onPickTrigger");
					global.$virtualexhibitor = exhibitor;

					self.setState({chaturl: 'https://apps4org.daily.co/virtualsummit-exhibitor1'});
					self.onOpenVideoCall();
	
				})//ExecuteCodeAction
	);//registerAction	
		
	//Contact Option
	var contact = BABYLON.MeshBuilder.CreateBox("contact", {height: commandH, width: commandW, depth:panelD}, scene);
	contact.parent = panel;
	contact.position.x = -1;  
    contact.position.y = 0;
	contact.position.z = commandZ;
	
	var contactM = new BABYLON.StandardMaterial("contactM", scene);

	path = this.getFilePath('icon_contact.png');	
	//console.log(path);
	var contactT = new BABYLON.Texture(path, scene);
	contactT.hasAlpha = true;
	contactT.uScale = -1; // Mirror Horizontally
	contactT.vScale = -1; // Mirror Vertically

	contactM.diffuseTexture   = contactT;
	contactM.specularTexture  = contactT;
	contactM.emissiveTexture  = contactT;
	contactM.ambientTexture   = contactT;
	contact.material = contactM;

	contact.actionManager = new BABYLON.ActionManager(scene);
	//video.checkCollisions 	= true;

	contact.actionManager.registerAction(
        new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger, 
				function(event){					
					//console.log("contact onPickTrigger");
					//self.setState({videourl: exhibitor.VIDEOURL});
					global.$virtualexhibitor = exhibitor;
					self.onOpenContactForm();
				})//ExecuteCodeAction
	);//registerAction	
	

		
	//console.log('createCommandPanel done');
}

createReferenceMarker(scene)
{
	var width = .5;
	var height = 1;
	
	var marker = BABYLON.MeshBuilder.CreateCylinder("cone", {diameterTop: width, diameterBottom: width, height:height}, scene);
	marker.position.x = 0;
    marker.position.y = height/2; 
    marker.position.z = 0;
	
	var markerM = new BABYLON.StandardMaterial("markerM", scene);
	markerM.diffuseColor 	= new BABYLON.Color3(1, 0, 0);
	markerM.specularColor 	= new BABYLON.Color3(1, 0, 0);
	markerM.emissiveColor 	= new BABYLON.Color3(1, 0, 0);
	markerM.ambientColor 	= new BABYLON.Color3(1, 0, 0);
	marker.material = markerM;

	/*
	height = height * 5;
	var marker2 = BABYLON.MeshBuilder.CreateCylinder("cone", {diameterTop: width, diameterBottom: width, height:height}, scene);
	marker2.position.x = 5;
    marker2.position.y = height/2; 
	marker2.position.z = 5;
	*/

}

/*
createTable(scene, parent) 
{
    //Table Leg
	var tableLegW = .1;
    var tableLegH = 1;
	var tableLeg = BABYLON.MeshBuilder.CreateCylinder("cone", {diameterTop: tableLegW, diameterBottom: tableLegW, height:tableLegH}, scene);
    tableLeg.parent = parent;

    tableLeg.position.x = -2.5;  //Fix: Make relative
    tableLeg.position.y = tableLegH/2;
    tableLeg.position.z =  - 1;
    tableLeg.checkCollisions = true;

    //Table Top
	var tableTopW = 1;
    var tableTopH = .1;
	var tableTop = BABYLON.MeshBuilder.CreateCylinder("cone", {diameterTop: tableTopW, diameterBottom: tableTopW, height:tableTopH}, scene);
    tableTop.parent = tableLeg;

	tableTop.position.x = 0;
    tableTop.position.y = tableLegH/2;
    tableTop.position.z = 0;
    tableTop.checkCollisions = true;
}

getRandomInt(max) 
{
  return Math.floor(Math.random() * Math.floor(max));
}

https://s3.amazonaws.com/event360live/public/virtualsummit/exhibitors/1603343418753-image1.png

*/


}
export default VirtualExpoHall;