import React from 'react';
import { Row, Col } from 'reactstrap';
import { API } from 'aws-amplify';
import './Common.css'
var serviceNames = require('../../ServiceNameMapper');

export default class SponsorsByCategory extends React.Component {
    constructor(props) {
        super(props);
        this.state =
        {
            code: global.$code,
            bucket: global.$s3bucket,
            sponsornamestyle: global.$sponsors.styles.name,
        };
        //console.log(this.state);
    }

    render() {
        if (this.state.dataloaded) {
            return (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: global.$config["sponsorsbycategory"].header }}></div>
                    <div className="sponsorsbycategory-maindiv" style={global.$config["sponsorsbycategory"].styles.maindiv}>
                        {this.displaySponsors()}
                        <br />
                    </div>
                </div>

            );
        }
        else {
            if (global.$sponsorjson != null) {
                this.setState({ dataloaded: true })
            }
            else {
                this.getSponsorjson()
            }
            return (
                <div></div>
            )
        }
    }
    getSponsorjson() {
        //console.log("sponsor json")
        let apiName = serviceNames.getWebsiteService();
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'code': this.state.code,
                'command': 'getSponsorsJson',
                'bucket': this.state.bucket
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //console.log(myInit);

        API.post(apiName, path, myInit).then(response => {
            //console.log ("response.data ===> " + response.data + "response.err ===> " + response.err);

            if (response.err == null && response.data != null) {
                let buf = Buffer.from(response.data.Body);
                //console.log(buf)
                //console.log(buf.toString());
                global.$sponsorjson = JSON.parse(buf.toString());
                this.setState({ dataloaded: true })

            }
            else {
                ////console.log("getAttendeesTableObjects - failed!!");
                //console.log(response.err);
            }
        }
        ).catch(error => {
            //console.log(error.response)
        });

    }

    displaySponsors() {
        //console.log(this.state.code)
        var styles = global.$config["sponsorsbycategory"].styles
        return global.$sponsorjson.map(sponsor => {
            var logo = `https://s3.amazonaws.com/event360live/public/${this.state.code}/exhibitors/` + sponsor.LOGO
            if (sponsor.TYPE.includes("T")) {
                return (
                    <div style={styles.categorydiv} className="sponsorsbycategory-category-div">
                        <br />
                        <Row>
                            <Col md={12}>
                                <div className="sponsorsbycategory-categoryname" style={styles.categoryname}>{sponsor.NAME}</div>
                            </Col>
                        </Row>
                    </div>
                )
            }
            else {
                if (sponsor.LOGO != "" && sponsor.LOGO != " ") {
                    if (sponsor.URL == "") {
                        return (
                            <div className="sponsorsbycategory-imgdiv" style={styles.imgdiv}>
                                <img src={logo} className="sponsorsbycategory-img" style={styles.img}/>
                            </div>
                        );
                    }
                    else {
                        return (
                            <div className="sponsorsbycategory-imgdiv" style={styles.imgdiv}>
                                <a href={sponsor.URL} target="_blank"><img src={logo} className="sponsorsbycategory-img" style={styles.img}/></a>
                            </div>
                        );
                    }

                }
            }
        })
    }

}