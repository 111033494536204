/* eslint-disable */
/* eslint-disable */
import React, { PureComponent, Provider } from 'react';
import Amplify, { API, Auth, Analytics } from 'aws-amplify';
import { TabContent, TabPane, Nav, NavItem, NavLink, FormGroup, Label, Input, Button, Card, CardHeader, CardBlock, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import ImageMapper from 'react-image-mapper';
import SessionInfo2 from '../SessionInfo2/SessionInfo2';
import Html1 from '../Components/Shared/Html1';
import RegistrationHome from '../Registration/RegistrationHome';

class SucessfullLogin extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      code: global.$code,
      loginformstyles: global.$login.form.styles,
      submitbuttonstyles: global.$login.buttons.submit.styles,
      emailstyle: global.$login.form.fields.emailstyles,
      passwordstyle: global.$login.form.fields.passwordstyles,
      forgotpasswordbuttonstyles: global.$login.buttons.forgotpassword.styles,
      changepasswordstyles: global.$profile.changepassword.styles,
      iconstyle: global.$login.form.iconstyles,
      pagestyle: global.$login.pagestyles,
      button1styles: global.$login.buttons.button1.styles,
      button2styles: global.$login.buttons.button2.styles,
      button3styles: global.$login.buttons.button3.styles,
      b1linkstyles: global.$login.buttons.button1.linkstyles,
      b2linkstyles: global.$login.buttons.button2.linkstyles,
      b3linkstyles: global.$login.buttons.button3.linkstyles,
      messagestyles: global.$login.messagestyles,
      imageWidth: window.innerWidth,
      imageHeight: window.innerHeight,
    };

    this.imageMapClicked = this.imageMapClicked.bind(this);

    // Add Events
    window.addEventListener("resize", this.onWindowResize, false);
  }
  componentDidMount() {
    if (this.props != null && this.props.onMenuReload != null) {
      this.props.onMenuReload();
    }
  }

  onWindowResize = event => {
    //console.log('reisized to: width', window.innerWidth + 'height =' + window.innerHeight);

    this.setState({
      imageWidth: window.innerWidth,
      imageHeight: window.innerHeight,
    });
  };

  imageMapClicked(area) {
    //console.log(area);
    if (area.route == 'signin') {
      this.props.history.push('/LoginForm')
    }
    else if (area.route == 'contact') {
      this.props.history.push('/Contact')
    }
    else if (area.route == 'sponsors') {
      this.props.history.push('/Sponsors')
    }
    else if (area.route == 'exhibitors' || area.route == 'Exhibitors') {
      this.props.history.push('/Exhibitors')
    }
    else if (area.route == 'speakers') {
      this.props.history.push('/Speakers')
    }
    else if (area.route == 'virtualexporoom') {
      this.props.history.push('/VirtualExpoRoom')
    }
    else if (area.route == 'schedule') {
      console
      global.$activeScheduleTab = `${area.data1}`;
      global.$activeScheduleSubTab = `${area.data2}`;
      this.props.history.push('/Schedule')
    }
    else if (area.route == 'posters') {
      global.$activeScheduleTab = `${area.data1}`;
      global.$activeScheduleSubTab = `${area.data2}`;
      this.props.history.push('/Posters')
    }
    else if (area.route == 'register') {
      this.props.history.push('/Registrationform')
    }
    else if (area.route == 'auctions') {
      this.props.history.push('/Auctions')
    }
    else if (area.route == 'LoginIntro') {
      this.props.history.push('/LoginIntro')
    }
  }

  render() {
    //console.log("login success page")
    Analytics.record({ name: 'PageView', attributes: { name: 'eventsites-' + this.state.code + '-login' } });

    /*
      Possible values: blank, imagemap, 3dhall
      Add 3dhall
    */
    //console.log(global.$login);
    var loginHome = "";
    if (global.$login.loginhome != null) {
      loginHome = global.$login.loginhome;
    }


    return (

      <div>
        {
          loginHome.includes('imagemap') ?
            (
              <div className="img-map">
                <ImageMapper
                  src={global.$login.imagemap.image}
                  map={global.$login.imagemap}
                  imgWidth={1920}
                  width={this.state.imageWidth}
                  onClick={area => this.imageMapClicked(area)}
                />
              </div>
            )
            :
            (
              loginHome.includes("schedule") ?
                (
                  <SessionInfo2 sid={global.$login.schedule.sid} stag={global.$login.schedule.stag} activetab={global.$login.schedule.activetab} type={global.$login.schedule.type} component="sessiontosession" />
                )
                :
                (
                  loginHome.includes("html1") ?
                    (
                      <Html1 />
                    )
                    :
                    (
                      loginHome.includes("Registrationform") ?
                        (
                          <RegistrationHome />
                        )
                        :
                        (
                          <div style={this.state.pagestyle}>
                            <Row>
                              <Col md={7}>
                                <div>
                                  <div dangerouslySetInnerHTML={{ __html: global.$login.leftcol }}></div>
                                </div>
                              </Col>
                              <Col md={5}>
                                <Row>
                                  <Col md={12}>
                                    <br />
                                    <form style={this.state.loginformstyles} className="login-form">
                                      <br />
                                      <br />
                                      <br />
                                      <br />
                                      <div dangerouslySetInnerHTML={{ __html: global.$login.form.successmessage }}></div>
                                      <br />
                                      <Row>
                                        <Col md={3} className="offset-1">
                                          <div style={this.state.button1styles}>
                                            <Link to={global.$login.buttons.button1.link} style={this.state.b1linkstyles}>{global.$login.buttons.button1.text}</Link>
                                          </div>
                                        </Col>
                                        <Col md={3} className="offset-1">
                                          <div style={this.state.button2styles}>
                                            <Link to={global.$login.buttons.button2.link} style={this.state.b2linkstyles}>{global.$login.buttons.button2.text}</Link>
                                          </div>
                                        </Col>
                                        <Col md={3} className="offset-1">
                                          <div style={this.state.button3styles}>
                                            <Link to={global.$login.buttons.button3.link} style={this.state.b3linkstyles}>{global.$login.buttons.button3.text}</Link>
                                          </div>
                                        </Col>
                                      </Row>
                                    </form>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        )
                    )
                )
            )
        }
      </div>
    );
  }

}

export default SucessfullLogin;
