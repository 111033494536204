import React from 'react';
import { Row, Jumbotron, Container, Col, Button, Form, FormGroup, Label, Input, Modal, Header, NavLink } from 'reactstrap';
import './Home.css';
import { Link } from 'react-router-dom';
import { API } from 'aws-amplify';
import Sponsors from './Sponsors.jsx';

import Amplify, { Analytics } from 'aws-amplify';
import SocialMediaFeeds from './Shared/SocialMediaFeeds';
import SponsorsByCategory from './Shared/SponsorsByCategory';
import ImageMapper from 'react-image-mapper';
import HomeSlider from './HomeSlider';
import DaysToGo from './DaysToGo';
import SpeakersLayout from './Shared/SpeakersLayout/SpeakersLayout';
import SponsorsLayout from './Shared/SponsorsLayout/SponsorsLayout';
import AdsLayout from './Shared/AdsLayout/AdsLayout';

export default class Conference extends React.Component {
  constructor(props) {
    super(props);
    var sponsorsposition = '';
    if (global.$home.sponsorsposition == null) {
      sponsorsposition = "bottom";
    }
    else {
      sponsorsposition = global.$home.sponsorsposition;
    }
    this.state =
    {
      code: props.code,
      s3: props.s3,
      bucket: props.bucket,
      rowimagestyle: global.$home.styles.rows.image,
      rowtextstyle: global.$home.styles.rows.text,
      rowheaderstyle: global.$home.styles.rows.header,
      leranmorestyle: global.$home.styles.learnmore,
      leranmoredivstyle: global.$home.styles.learnmorediv,
      sponsorsposition: sponsorsposition,
    };
    
    this.menuReloadHandler = this.menuReloadHandler.bind(this);
  }

  componentDidMount() {
    
    window.scrollTo(0, 0);
    Analytics.record({ name: 'PageView', attributes: { name: 'eventsites-' + this.state.code + '-home' } });
  }
  
  menuReloadHandler() {
    this.props.onMenuReload();
  }
  render() {
    return (
      <div>
        <HomeSlider {...this.props} onMenuReload={this.menuReloadHandler}/>
        {/*<marquee className="home-marquee">79TH ANUAL CONFERENCE OF THE ASSOCIATION OF PHISICIANS OF INDIA AT KOLKATA</marquee>*/}
        <DaysToGo />
        {
          this.state.sponsorsposition.includes("top") ?
            (
              <div>
                {
                  global.$home.sponsors.scroll.includes("Y") ?
                    (
                      <div>
                        <br />
                        <br />
                        {
                          global.$homesponsorslayout.includes("group") ?
                            (
                              <div>
                                <SponsorsByCategory />
                              </div>
                            )
                            :
                            (
                              <div>
                                <Sponsors />
                              </div>
                            )
                        }
                      </div>
                    )
                    :
                    ('')
                }
              </div>
            )
            :
            (
              ''
            )
        }
        <div>
          {this.getRows()}
        </div>
        {
          this.state.sponsorsposition.includes("bottom") ?
            (
              <div>
                {
                  global.$home.sponsors.scroll.includes("Y") ?
                    (
                      <div>
                        {
                          global.$homesponsorslayout.includes("group") ?
                            (
                              <div>
                                <SponsorsByCategory />
                              </div>
                            )
                            :
                            (
                              <div>
                                <Sponsors />
                              </div>
                            )
                        }
                      </div>
                    )
                    :
                    ('')
                }
              </div>
            )
            :
            (
              ''
            )
        }
      </div>
    );

  }
  getRows() {
    var objs = global.$home.rows
  
    const tableObjs = []

    const rowsObj = objs.map((item) =>
    
      <div >
        {
          item.type.includes("image-text") ?
            (
              <div style={item.style}>
                <div dangerouslySetInnerHTML={{ __html: item.title }}></div>
                <Row className="justify-content-center">
                  <Col md={5} className="offset-1">
                    <div dangerouslySetInnerHTML={{ __html: item.image }}></div>
                  </Col>
                  <Col md={5}>
                    <div dangerouslySetInnerHTML={{ __html: item.text }}></div>
                    {
                      item.learnmore.enable.includes("Y") ?
                        (
                          <div style={this.state.leranmoredivstyle}>
                            <Button className="btn btn-primary" style={this.state.leranmorestyle}><Link to={item.learnmore.link} style={this.state.leranmorestyle}>{item.learnmore.title}</Link></Button>
                          </div>
                        )
                        :
                        ('')
                    }
                    <div className="offset-1"></div>
                  </Col>
                </Row>
              </div>
            )
            :
            ('')
        }
        <div className='rows-wrapper'>
        {
          item.type.includes("text-image") ?
            (
              <div style={item.style}>
                <div dangerouslySetInnerHTML={{ __html: item.title }}></div>
                <Row>
                  <Col md={5} className="offset-1" >
                    <div dangerouslySetInnerHTML={{ __html: item.text }}></div>
                    {
                      item.learnmore.enable.includes("Y") ?
                        (
                          <div style={this.state.leranmoredivstyle}>
                            <Button className="btn btn-primary" style={this.state.leranmorestyle}><Link to={item.learnmore.link} style={this.state.leranmorestyle}>{item.learnmore.title}</Link></Button>
                          </div>
                        )
                        :
                        ('')
                    }
                  </Col>
                  <Col md={5} className="offset-1">
                    <div dangerouslySetInnerHTML={{ __html: item.image }}></div>
                  </Col>
                </Row>

              </div>
            )
            :
            ('')
        }
        </div>
        {
          item.type.includes("text-only") ?
            (
              <div style={item.style}>
                <div dangerouslySetInnerHTML={{ __html: item.title }}></div>
                <Row>
                  <Col md={10} className="offset-1">
                      <div className='home-reg-button-link' dangerouslySetInnerHTML={{ __html: item.text }} ></div>
                  </Col>
                </Row>
                {
                  item.learnmore.enable.includes("Y") ?
                    (
                      <div style={this.state.leranmoredivstyle} className="home-reg-button-div">
                        <Button className="btn btn-primary home-reg-button" style={this.state.leranmorestyle}><Link to={item.learnmore.link} style={this.state.leranmorestyle}>{item.learnmore.title}</Link></Button>
                      </div>
                    )
                    :
                    ('')
                }
              </div>
            )
            :
            ('')
        }
        {
          item.type.includes("image-only") ?
            (
              <div style={item.style}>
                <div dangerouslySetInnerHTML={{ __html: item.title }}></div>
                <Row>
                  <Col md={10} className="offset-1">
                    <div dangerouslySetInnerHTML={{ __html: item.image }}></div>
                  </Col>
                </Row>
                {
                  item.learnmore.enable.includes("Y") ?
                    (
                      <div style={this.state.leranmoredivstyle}>
                        <Button className="btn btn-primary" style={this.state.leranmorestyle}><Link to={item.learnmore.link} style={this.state.leranmorestyle}>{item.learnmore.title}</Link></Button>
                      </div>
                    )
                    :
                    ('')
                }
              </div>
            )
            :
            ('')
        }
        {
          item.type.includes("speakers-section") ?
            (
              <SpeakersLayout />
            )
            :
            ('')
        }
        {
          item.type.includes("sponsors-section") ?
            (
              <SponsorsLayout />
            )
            :
            ('')
        }
        {
          item.type.includes("ads-section") ?
            (
              <AdsLayout />
            )
            :
            ('')
        }
      </div>
    );

    return (
      <div>
        {rowsObj}
      </div>
    )

  }

}