import React from 'react';
import { mdiDownload } from '@mdi/js';
import Icon from '@mdi/react';
import { API, Analytics } from 'aws-amplify';
import { Document, Page, pdfjs } from "react-pdf/dist/entry.webpack";
import "react-pdf/dist/Page/AnnotationLayer.css";
var serviceNames = require('../ServiceNameMapper');
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class SessionHandouts extends React.Component {
    constructor(props) {
        super(props);
       //console.log(props);
        this.state = {
            code: global.$code,
            s3: global.$s3,
            bucket: global.$s3bucket,
            numPages: null,
            pageNumber: 1,
            file1: this.props.session.FILE1,
            file2: this.props.session.FILE2,
            file3: this.props.session.FILE3
        };
        if(this.state.file1 == "" || this.state.file1 == " ")
        {
            this.state.file1 = "empty"
        } 
        if(this.state.file2 == "" || this.state.file2 == " ")
        {
            this.state.file2 = "empty"
        }
        if(this.state.file3 == "" || this.state.file3 == " ")
        {
            this.state.file3 = "empty"
        }

        //this.generateSingedUrl = this.generateSingedUrl.bind(this);
    }

    async generateSingedUrl(event, filename) {
        console.log("generateSingedUrl");

        event.preventDefault();
    
        try {
          let apiName = serviceNames.getUserService();
          let path = ""; //replace this with the path you have configured on your API
    
          let myInit = {
            body: {
              code: this.state.code,
              command: "getSignedUrl",
              bucket: "wesicore",
              key: `event360/${this.state.code}/upload/documents/${filename}`
            },
            response: true,
            headers: {},
          };
      
          console.log({myInit});
      
          let result = await API.post(apiName, path, myInit);
          console.log(result);
    
          if(result.data) {
            window.open(result.data)
          }
    
        } catch (error) {
          console.log(error);
        }
    }

    render() {
        return (
            <div className="handouts">
                {
                    this.state.file1.includes("empty") ?
                        ('')
                        :
                        (
                            <div className="handout-div download-presentation-wrapper">
                                <a href="#" onClick={(event) => this.generateSingedUrl(event, this.state.file1)} target="_blank">
                                    <Icon path={mdiDownload} size={0.9} />Downloads
                                </a>
                                <br />
                            </div>
                        )
                }
                {
                    this.state.file2.includes("empty") ?
                        ('')
                        :
                        (
                            <div className="handout-div download-presentation-wrapper">
                                <a href="#" onClick={(event) => this.generateSingedUrl(event, this.state.file2)} target="_blank">
                                    <Icon path={mdiDownload} size={0.9} />Download
                                </a>
                                <br />
                            </div>
                        )
                }
                {
                    this.state.file3.includes("empty") ?
                        ('')
                        :
                        (
                            <div className="handout-div download-presentation-wrapper">
                                <a href="#" onClick={(event) => this.generateSingedUrl(event, this.state.file3)} target="_blank">
                                    <Icon path={mdiDownload} size={0.9} />Download
                                </a>
                                <br />
                            </div>
                        )
                }

            </div>
        )
    }

}
export default SessionHandouts;


