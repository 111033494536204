/* eslint-disable */
import React, { Component } from 'react';
import Icon from '@mdi/react';
import { mdiPlus, mdiNote, mdiDelete, mdiDownload, mdiPen, mdiLogin, mdiCancel, mdiAccountPlus, mdiAccountRemove, mdiClose } from '@mdi/js';
import { API } from 'aws-amplify';
import { Col, Row, Button, Label, FormGroup } from 'reactstrap';
import Modal from "react-responsive-modal";
import 'react-responsive-modal/styles.css';
import MeetupForm from './MeetupForm';
import Avatar from 'react-avatar';
import MeetupTabs from './MeetupTabs';
import Select from 'react-select';
import AddMeetup from './AddMeetup';

const moment = require('moment-timezone'); //moment-timezone

class MeetupsList extends React.Component {
    constructor(props) {

        super(props);

        this.state = {
            code: global.$code,
            bucket: props.bucket,
            dataloaded: false,
            allmeetups: [],
            mysignedupmeetups: props.signupdata,
            currentmeetup: '',
            openpopup: false,
            opentabs: false,
            allsignups: [],
            meetupcategorylist: "All,My Meetups,Live,Upcoming,Finished",
            meetupfilterlist: [],
            selectedCategory: '',
            selected: ''
        };

        this.handleMultipleCategory = this.handleMultipleCategory.bind(this);
        this.refreshMeetups = this.refreshMeetups.bind(this);

        var categories = this.state.meetupcategorylist;
        categories = categories.split(",");
        categories.map(category => {

            var options = []
            options = { value: category, label: category }

            this.state.meetupfilterlist.push(options);
        });
    }



    refreshMeetups() {
        this.props.onRefreshMeetups();
    }

    onOpenPopup = (meetup, action) => {
        //console.log('onOpenPopup');
        this.setState(prevState => ({
            currentmeetup: meetup,
            openpopup: !prevState.openpopup,
        }));
        //console.log(this.state.openpopup);
    };

    onOpenTabs = (meetup, action) => {
        //console.log('onOpenPopup');
        this.setState(prevState => ({
            currentmeetup: meetup,
            opentabs: !prevState.opentabs,
        }));

        //console.log(this.state.openpopup);
    };


    handleMultipleCategory(selectedCategory) {
        this.setState({ selectedCategory });
        var value = []
        if (selectedCategory == null) {
            selectedCategory = [];
        }
        if (selectedCategory.length == 0 || selectedCategory == null) {
            //console.log("zero length");
            this.setState({ selected: ' ' });
            selectedCategory.length = 0;
        }
        for (var i = 0; i < selectedCategory.length; i++) {
            //console.log(selectedCategory.length);
            var newObj = Object()
            newObj.value = selectedCategory[i].value
            value.push(newObj.value);
            //console.log(value)
            this.setState({ selected: value.toString() });
        }
    };

    render() {
        var meetupconfig = global.$config["meetups"];
        //console.log(meetupconfig);
        //console.log("meetup list render")
        if (this.state.dataloaded) {
            return (
                <div className="meetups-list-maindiv">
                    <Row>
                        <Col md={12}>
                            <div className="meetup-topbar offset-md-1">

                                {
                                    meetupconfig.addmeetup.enablefor.includes("exhibitors") ?
                                        (
                                            global.$userstype == "Exhibitor" ?
                                                (
                                                    <Row>
                                                        <Col md={6}>

                                                        </Col>
                                                        <Col md={4}>
                                                            <div className="meetup-filter-div">
                                                                <FormGroup className="form-labels meetup-filter-group">
                                                                    {/* <Label for="category" className="meetup-filter-label offset-md-2">Filter</Label> */}
                                                                    <Select
                                                                        id="category"
                                                                        isMulti
                                                                        className="basic-multi-select meetup-filter-select offset-md-4"
                                                                        value={this.state.selectedCategory}
                                                                        options={this.state.meetupfilterlist}
                                                                        placeholder="Filter Meetups"
                                                                        onChange={this.handleMultipleCategory} />
                                                                </FormGroup>
                                                            </div>
                                                        </Col>
                                                        <Col md={2}>
                                                            <div className="add-meetup-section">
                                                                <AddMeetup refreshMeetupsHome={this.refreshMeetups} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                )
                                                :
                                                (
                                                    <Row>
                                                        <Col md={6}>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="meetup-filter-div">
                                                                <FormGroup className="form-labels meetup-filter-group">
                                                                    {/* <Label for="category" className="meetup-filter-label offset-md-2">Filter</Label> */}
                                                                    <Select
                                                                        id="category"
                                                                        isMulti
                                                                        className="basic-multi-select meetup-filter-select-attendee"
                                                                        value={this.state.selectedCategory}
                                                                        options={this.state.meetupfilterlist}
                                                                        placeholder="Filter Meetups"
                                                                        onChange={this.handleMultipleCategory} />
                                                                </FormGroup>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                )
                                        )
                                        :
                                        ('')
                                }
                                {
                                    meetupconfig.addmeetup.enablefor.includes("attendees") ?
                                        (
                                            global.$userstype == "Attendee" ?
                                                (
                                                    <Row>
                                                        <Col md={6}>
                                                        </Col>
                                                        <Col md={4}>
                                                            <div className="meetup-filter-div">
                                                                <FormGroup className="form-labels meetup-filter-group">
                                                                    {/* <Label for="category" className="meetup-filter-label offset-md-2">Filter</Label> */}
                                                                    <Select
                                                                        id="category"
                                                                        isMulti
                                                                        className="basic-multi-select meetup-filter-select offset-md-4"
                                                                        value={this.state.selectedCategory}
                                                                        options={this.state.meetupfilterlist}
                                                                        placeholder="Filter Meetups"
                                                                        onChange={this.handleMultipleCategory} />
                                                                </FormGroup>
                                                            </div>
                                                        </Col>
                                                        <Col md={2}>
                                                            <div className="add-meetup-section">
                                                                <AddMeetup refreshMeetupsHome={this.refreshMeetups} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                )
                                                :
                                                (

                                                    <Row>
                                                        <Col md={6}>

                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="meetup-filter-div">
                                                                <FormGroup className="form-labels meetup-filter-group">
                                                                    {/* <Label for="category" className="meetup-filter-label offset-md-2">Filter</Label> */}
                                                                    <Select
                                                                        id="category"
                                                                        isMulti
                                                                        className="basic-multi-select meetup-filter-select-attendee"
                                                                        value={this.state.selectedCategory}
                                                                        options={this.state.meetupfilterlist}
                                                                        placeholder="Filter Meetups"
                                                                        onChange={this.handleMultipleCategory} />
                                                                </FormGroup>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                )
                                        )
                                        :
                                        ('')
                                }
                                {
                                    meetupconfig.addmeetup.enablefor.includes(`${global.$userid}`) ?
                                        (
                                            <Row>
                                                <Col md={6}>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="meetup-filter-div">
                                                        <FormGroup className="form-labels meetup-filter-group">
                                                            {/* <Label for="category" className="meetup-filter-label offset-md-2">Filter</Label> */}
                                                            <Select
                                                                id="category"
                                                                isMulti
                                                                className="basic-multi-select meetup-filter-select offset-md-4"
                                                                value={this.state.selectedCategory}
                                                                options={this.state.meetupfilterlist}
                                                                placeholder="Filter Meetups"
                                                                onChange={this.handleMultipleCategory} />
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                                <Col md={2}>
                                                    <div className="add-meetup-section">
                                                        <AddMeetup refreshMeetupsHome={this.refreshMeetups} />
                                                    </div>
                                                </Col>
                                            </Row>
                                        )
                                        :
                                        (
                                            ''
                                        )
                                }
                            </div>
                            <Row className="display-meetups-div">
                                {
                                    this.props.meetups.length != 0 ?
                                        (
                                            this.displayMeetups(this.props.meetups)
                                        )
                                        :
                                        (
                                            <div className="no-meetups-text offset-md-4">
                                                Currently no  meetups are scheduled
                                            </div>
                                        )
                                }
                            </Row>
                            <div className="add-meetup-popup-div">
                                <Modal
                                    open={this.state.openpopup}
                                    onClose={this.onOpenPopup}
                                    blockScroll={false}
                                    closeOnOverlayClick={false}
                                    classNames={{
                                        overlay: 'add-meetup-popup-overlay',
                                        modal: 'add-meetup-popup-modal',
                                    }}
                                    styles={{
                                        modal: {
                                            width: "100%"
                                        }
                                    }}
                                    center>
                                    <MeetupForm action="edit" meetup={this.state.currentmeetup} refreshAddMeetup={this.refreshMeetups} />

                                </Modal>
                            </div>
                            <div className="add-meetup-tabs-div">
                                <Modal
                                    open={this.state.opentabs}
                                    onClose={this.onOpenTabs}
                                    blockScroll={false}
                                    closeOnOverlayClick={false}
                                    classNames={{
                                        overlay: 'add-meetup-popup-overlay',
                                        modal: 'add-meetup-popup-modal',
                                    }}
                                    styles={{
                                        modal: {
                                            width: "100%"
                                        }
                                    }}
                                    center>
                                    <MeetupTabs meetup={this.state.currentmeetup} refreshListMeetup={this.refreshMeetups} />
                                </Modal>
                            </div>
                        </Col>
                    </Row>
                </div>
            );
        }
        else {
            this.getSignups();
            return (
                <div>
                    <Row>
                        <Col md={6} className="offset-5">
                            <div className="loading-div">
                                <div className="loading-text">Loading....</div>
                            </div>
                        </Col>
                    </Row>
                </div>
            );
        }
    }



    getSignups() {
        let apiName = 'MeetupService';
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'code': global.$code,
                'command': 'getsignups',
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }

        //console.log(myInit);

        API.post(apiName, path, myInit).then(response => {
            //console.log(response);

            if (response.err == null && response.data != null) {
                //console.log(response.data.Items);
                var signupdata = this.getSignupTableObjects2(response.data.Items);
                //console.log(signupdata);
                this.setState({
                    allsignups: signupdata,
                    dataloaded: true
                })
            }
            else {
                //console.log(response.err);
            }
        }
        ).catch(error => {
            //console.log(error)
        });
    }

    getSignupTableObjects2(objs) {
        //console.log(objs);

        const tableObjs = []

        for (var i = 0; i < objs.length; i++) {
            var signupObj = objs[i]

            //console.log(signupObj)

            var newObj = Object()
            newObj.userid = signupObj.userid.S
            newObj.ownerid = signupObj.ownerid.S
            newObj.signupid = signupObj.signupid.S
            newObj.status = signupObj.signupstatus.S
            newObj.signuptype = signupObj.signuptype.S
            newObj.data1 = signupObj.data1.S
            newObj.data2 = signupObj.data2.S
            newObj.data3 = signupObj.data3.S
            newObj.data4 = signupObj.data4.S
            newObj.groupname = signupObj.groupname.S

            tableObjs.push(newObj)
        }

        //console.log(tableObjs)

        return tableObjs
    }

    getSignupsCount(meetup, action) {
        var count = 0;
        var signupcount = 0;
        var signupdata = [];
        //console.log(this.state.allsignups);
        this.state.allsignups.map(item => {
            if (item.ownerid == meetup.ownerid && item.data3 == meetup.meetupid) {
                signupdata.push(item);
            }
        })
        //console.log(signupdata);
        signupcount = signupdata.length;
        //console.log(meetup.signupmax, signupcount);
        count = Number(meetup.signupmax) - Number(signupcount)

        //console.log(count);
        //return count;

        //console.log(count);
        if (action == "text") {
            if (Number(count) > Number(1)) {
                return count + " Signups Remaining";
            }
            else {
                if (Number(count) == Number(0)) {
                    return "Maximum signups reached";
                }
                else {
                    return count + " Signup Remaining";
                }
            }
        }
        else {
            if (Number(count) == Number(0)) {
                return false;
            }
            else {
                return true;
            }
        }
    }
    getAllSignups(meetup) {
        let apiName = 'MeetupService';
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'code': global.$code,
                'command': 'getsignedupusers',
                'ownerid': meetup.ownerid,
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }

        //console.log(myInit);
        API.post(apiName, path, myInit).then(response => {
            //console.log(response);

            if (response.err == null && response.data != null) {
                //console.log(response.data.Items);
                var signupdata = this.getSignupTableObjects(response.data.Items, meetup);
                //console.log(signupdata);
                var signupcount = 0;
                signupcount = signupdata.length;
                //console.log(signupcount, meetup.signupmax, Number(meetup.signupmax) == Number(signupcount))
                if (Number(meetup.signupmax) == Number(signupcount)) {
                    alert("Maximum signups reached for this meetup")
                    this.props.onRefreshMeetups();
                }
                else {
                    if (window.confirm("Do you want to signup for this meetup?")) {
                        this.handleBtn(meetup, "signup");
                    }
                }
                //console.log(signupdata);
            }
            else {
                //console.log(response.err);
            }
        }
        ).catch(error => {
            //console.log(error)
        });
    }

    getSignupTableObjects(objs, meetup) {
        //console.log(objs);

        const tableObjs = []

        for (var i = 0; i < objs.length; i++) {
            var signupObj = objs[i]

            //console.log(signupObj)

            var newObj = Object()
            if (signupObj.ownerid.S == meetup.ownerid && signupObj.data3.S == meetup.meetupid) {
                newObj.userid = signupObj.userid.S
                newObj.ownerid = signupObj.ownerid.S
                newObj.signupid = signupObj.signupid.S
                newObj.status = signupObj.signupstatus.S
                newObj.signuptype = signupObj.signuptype.S
                newObj.data1 = signupObj.data1.S
                newObj.data2 = signupObj.data2.S
                newObj.data3 = signupObj.data3.S
                newObj.data4 = signupObj.data4.S
                newObj.groupname = signupObj.groupname.S
            }
            //console.log(tableObjs)
            tableObjs.push(newObj)
        }

        var newArray = tableObjs.filter((ele) => {
            return ele.constructor === Object && Object.keys(ele).length > 0
        });
        //console.log(newArray);
        //console.log(tableObjs)

        return newArray;
    }

    handleBtn(meetup, action) {
        if (action == "edit") {
            this.onOpenPopup(meetup, action);
        }
        else if (action == "delete") {
            this.deleteMeetup(meetup, action);
        }
        else if (action == "signup") {
            this.registerMeetup(meetup, action);
        }
        else if (action == "join") {
            this.onOpenTabs(meetup, action);
        }
    }

    getAdjustedCurrentDate(begin, end) {
        var mode = global.$mode;
        if (mode == null || mode == '') {
            mode = 'live';
        }

        var currentdate = moment().tz(`${global.$settings.timezone}`).format('L LTS');

        if (mode == "live") {
            //Nothing - Just return the current date
        }
        else if (mode == "preview") {
            // Make current date/time as same as Meetup begin date/time
            currentdate = begin;
        }
        else if (mode == "dryrun") {
            var eventdate = moment(begin).format('L');
            var currenttime = moment().tz(`${global.$settings.timezone}`).format('LTS');
            currentdate = eventdate + " " + currenttime
        }
        else if (mode == "ondemand") {   //Add 365 days to current date

            var postdate = moment().add(365, 'days').calendar();
            var posttime = moment().tz(`${global.$settings.timezone}`).format('LTS');
            currentdate = postdate + " " + posttime;
        }

        return currentdate;
    }

    isMeetupLive(currenttime, meetupstarttime, meetupendtime) {
        //console.log(`ScheduleWatchNow: isMeetupLive: current = ${this.props.currenttime}, start = ${meetupstarttime}, end = ${meetupendtime}` );

        var mode = global.$mode

        if (mode == "live") {
            if ((currenttime) >= (meetupstarttime) && (currenttime) <= (meetupendtime)) {
                //console.log('ScheduleWatchNow.isMeetupLive - Live');
                return true;
            }
            else {
                //console.log('ScheduleWatchNow.isMeetupLive - Not Live');
                return false;
            }
        }
        else if (mode == "preview") {
            return true;
        }
        else if (mode == "dryrun") {
            var eventdate = moment(meetupstarttime).format('L');
            var currenttime = moment().tz(`${global.$settings.timezone}`).format('LTS');

            var dateformat = eventdate + " " + currenttime

            //console.log('ScheduleWatchNow.isMeetupLive - Adjusted Time ' + dateformat);

            var timeformat = Date.parse(dateformat);
            if ((timeformat) >= (meetupstarttime) && (timeformat) <= (meetupendtime)) {
                //console.log('ScheduleWatchNow.isMeetupLive - Live');
                return true;
            }
            else {
                //console.log('ScheduleWatchNow.isMeetupLive - Not Live');
                return false;
            }
        }
        else if (mode == "ondemand") {
            return false;
        }
    }

    isMeetupFinished(currenttime, meetupstarttime, meetupendtime) {
        //console.log(`ScheduleWatchNow: isMeetupLive: current = ${this.props.currenttime}, start = ${meetupstarttime}, end = ${meetupendtime}` );

        var mode = global.$mode

        if (mode == "live") {
            if ((currenttime) > (meetupendtime)) {
                //console.log('ScheduleWatchNow.isMeetupLive - Live');
                return true;
            }
            else {
                //console.log('ScheduleWatchNow.isMeetupLive - Not Live');
                return false;
            }
        }
        else {
            return false;
        }
    }
    isMeetupUpcoming(currenttime, meetupstarttime, meetupendtime) {
        //console.log(`ScheduleWatchNow: isMeetupLive: current = ${this.props.currenttime}, start = ${meetupstarttime}, end = ${meetupendtime}` );

        var mode = global.$mode

        if (mode == "live") {
            if ((meetupstarttime) > (currenttime)) {
                //console.log('ScheduleWatchNow.isMeetupLive - Live');
                return true;
            }
            else {
                //console.log('ScheduleWatchNow.isMeetupLive - Not Live');
                return false;
            }
        }
        else {
            return false;
        }
    }
    isMyMeetup(meetup) {
        var result = false;
        //console.log(global.$signedupmeetups)

        global.$signedupmeetups.map(signup => {
            //console.log(signup);
            //console.log(meetup.meetupid, signup.data3, meetup.meetupid == signup.data3)
            if (meetup.meetupid == signup.data3) {
                result = true;
            }
        })

        return result;
    }

    displayMeetups(meetups) {

        var results = [];
        var selected = this.state.selected
        if (selected != "" || selected != " ") {
            selected = selected.split(",");
        }
        //console.log(selected);
        //console.log(meetups);
        results = meetups.filter(meetup => {

            var currentdate = this.getAdjustedCurrentDate(meetup.begintime, meetup.endtime)//moment().tz(`${global.$settings.timezone}`).format('L LTS')

            //console.log(currentdate);

            var currenttime = Date.parse(currentdate);
            var meetupstarttime = Date.parse(meetup.begintime);
            var meetupendtime = Date.parse(meetup.endtime);

            if (selected == "" || selected == " ") {
                return true;
            }
            else {

                var categoryexist = false;

                selected.map(category => {
                    //console.log(category);
                    if (category == "Live") {
                        if (this.isMeetupLive(currenttime, meetupstarttime, meetupendtime)) {
                            categoryexist = true;
                        }
                        //return true;
                    }
                    else if (category == "Upcoming") {
                        if (this.isMeetupUpcoming(currenttime, meetupstarttime, meetupendtime)) {
                            categoryexist = true;
                        }
                    }
                    else if (category == "Finished") {
                        if (this.isMeetupFinished(currenttime, meetupstarttime, meetupendtime)) {
                            categoryexist = true;
                        }
                    }
                    else if (category == "My Meetups") {
                        if (this.isMyMeetup(meetup)) {
                            categoryexist = true;
                        }
                        if (meetup.ownerid == global.$userid) {
                            categoryexist = true;
                        }
                    }
                    else if (category == "All") {
                        categoryexist = true;
                    }
                })
                //console.log(categoryexist);
                if (categoryexist == true) {
                    //console.log("true");
                    return true;
                }
                else if (categoryexist == false) {
                    //console.log("false");
                    return false;
                }
            }
        })

        return results.map(meetup => {
            var image = "";
            if (meetup.photo == " " || meetup.photo == "") {
                image = "empty";
            }
            else {
                image = `https://s3.amazonaws.com/event360live/public/${global.$code}/meetups/` + meetup.photo
            }

            var date = moment(meetup.begintime).format("MMM Do");
            var btime = moment(meetup.begintime).format("hh:mm A");
            var etime = moment(meetup.endtime).format("hh:mm A");

            var currentdate = this.getAdjustedCurrentDate(meetup.begintime, meetup.endtime)//moment().tz(`${global.$settings.timezone}`).format('L LTS')

            //console.log(currentdate);

            var currenttime = Date.parse(currentdate);
            var meetupstarttime = Date.parse(meetup.begintime);
            var meetupendtime = Date.parse(meetup.endtime);

            var meetupconfig = global.$config["meetups"].card;

            return (
                <div className="meetup-card-div" style={meetupconfig.maindiv.styles}>
                    <Col md={12}>
                        <div className="meetup-card-status-label-div" style={meetupconfig.status.styles}>
                            {(() => {
                                if (this.isMeetupUpcoming(currenttime, meetupstarttime, meetupendtime) && meetup.status == "Open") {
                                    //console.log("spinner")
                                    return (
                                        <div className="meetup-card-status-label-upcoming" style={meetupconfig.status.upcoming.styles}>
                                            {meetupconfig.status.upcoming.title}
                                        </div>
                                    )
                                }
                            })()}
                        </div>
                        <div className="meetup-card-status-label-div" style={meetupconfig.status.styles}>
                            {(() => {
                                if (this.isMeetupLive(currenttime, meetupstarttime, meetupendtime) && meetup.status == "Open") {
                                    //console.log("spinner")
                                    return (
                                        <div className="meetup-card-status-label-live" style={meetupconfig.status.live.styles}>
                                            {meetupconfig.status.live.title}
                                        </div>
                                    )
                                }
                            })()}
                        </div>
                        <div className="meetup-card-status-label-div" style={meetupconfig.status.styles}>
                            {(() => {
                                if (this.isMeetupFinished(currenttime, meetupstarttime, meetupendtime) && meetup.status == "Open") {
                                    //console.log("spinner")
                                    return (
                                        <div className="meetup-card-status-label-finished" style={meetupconfig.status.finished.styles}>
                                            {meetupconfig.status.finished.title}
                                        </div>
                                    )
                                }
                            })()}
                        </div>
                        <div className="meetup-user-status-label-div" style={meetupconfig.status.signedup.styles.maindiv}>
                            {(() => {
                                if (this.isMyMeetup(meetup)) {
                                    //console.log("spinner")
                                    return (
                                        <div className="meetup-user-status-label" style={meetupconfig.status.signedup.styles.label}>
                                            {meetupconfig.status.signedup.title}
                                        </div>
                                    )
                                }
                            })()}
                        </div>
                        <div className="meetup-card-status-label-closed" style={meetupconfig.status.styles}>
                            {
                                meetup.status == "Close" ?
                                    (
                                        <div className="meetup-card-status-label-closed" style={meetupconfig.status.closed.styles}>
                                            {meetupconfig.status.closed.title}
                                        </div>
                                    )
                                    :
                                    ('')
                            }

                        </div>
                        {
                            meetup.ownerid == global.$userid ?
                                (
                                    <div className="meetup-host-status-label" style={meetupconfig.status.host.styles.label}>
                                        {meetupconfig.status.host.title}
                                    </div>
                                )
                                :
                                ('')
                        }
                        <div className="meetup-card-img-div" style={meetupconfig.img.styles.maindiv}>
                            {
                                image.includes("empty") ?
                                    (
                                        <div>
                                            <Avatar className="meetup-card-avatar" style={meetupconfig.img.styles.avatar} name={`${meetup.title}`} />
                                        </div>
                                    )
                                    :
                                    (
                                        <div>
                                            <img src={image} className="meetup-card-img" style={meetupconfig.img.styles.img} />
                                        </div>
                                    )
                            }
                        </div>
                        <div title={meetup.title} className="meetup-card-name" style={meetupconfig.title.styles} onClick={() => { this.handleBtn(meetup, "join") }}>
                            {meetup.title}
                        </div>
                        <div title={date + ', ' + btime + " - " + etime} className="meetup-card-date" style={meetupconfig.date.styles}>
                            {date + ', ' + btime + " - " + etime}
                        </div>
                        <hr />
                        <div className="meetup-card-btns-div">
                            {
                                this.isMeetupFinished(currenttime, meetupstarttime, meetupendtime) ?
                                    (
                                        <div className="signup-count-text" style={meetupconfig.signupstatus.styles}>
                                        </div>
                                    )
                                    :
                                    (
                                        meetup.status == "Close" ?
                                            (
                                                ''
                                            )
                                            :
                                            (
                                                meetup.signuprequired == "Yes" ?
                                                    (
                                                        <div className="signup-count-text" style={meetupconfig.signupstatus.styles}>
                                                            {this.getSignupsCount(meetup, "text")}
                                                        </div>
                                                    )
                                                    :
                                                    ('')
                                            )
                                    )
                            }
                            <div className="meetup-card-btn-div1" style={meetupconfig.buttons.maindiv.styles}>
                                {
                                    meetup.ownerid == global.$userid ?
                                        (
                                            <div className="meetup-card-btn-div" style={meetupconfig.buttons.host.styles.maindiv}>
                                                <Button title="Edit Meetup" className="meetup-card-edit-btn meetup-btn" onClick={() => { this.handleBtn(meetup, "edit") }} style={meetupconfig.buttons.host.styles.edit}><Icon path={mdiPen} size={1.5} className="meetup-card-icons" /></Button>
                                                <Button title="Delete Meetup" className="meetup-card-delete-btn meetup-btn" onClick={() => { if (window.confirm('Do you want to delete this meetup?')) { this.handleBtn(meetup, "delete") }; }} style={meetupconfig.buttons.host.styles.delete}><Icon path={mdiDelete} size={1.5} className="meetup-card-icons" /></Button>
                                                {/* <Button title="Download signedup users list" className="meetup-card-download-btn meetup-btn" onClick={() => { if (window.confirm('Do you want to downlaod list of signedup users?')) { this.handleBtn(meetup, "download") }; }}><Icon path={mdiDownload} size={1.5} className="meetup-card-icons" /></Button> */}
                                                {/* {
                                                    this.isMeetupLive(currenttime, meetupstarttime, meetupendtime) ?
                                                        //console.log("spinner")
                                                        (
                                                            <Button title="Start Meetup" className="meetup-card-join-btn meetup-btn" onClick={() => { if (window.confirm('Do you want to start this meetup ?')) { this.handleBtn(meetup, "join") }; }}><Icon path={mdiLogin} size={1.5} className="meetup-card-icons" /></Button>
                                                        )
                                                        :
                                                        ('')
                                                } */}
                                            </div>
                                        )
                                        :
                                        ('')
                                }
                                <div>
                                    {this.handleSignups(meetup)}
                                </div>
                            </div>
                        </div>

                    </Col>
                </div>
            )
        })
    }

    handleSignups(meetup) {
        //console.log("signup btn");
        //console.log(global.$signedupmeetups);
        //console.log(global.$signedupmeetups.length);

        var meetupconfig = global.$config["meetups"].card;

        var date = moment(meetup.begintime).format("MMM Do");
        var btime = moment(meetup.begintime).format("hh:mm A");
        var etime = moment(meetup.endtime).format("hh:mm A");

        var currentdate = this.getAdjustedCurrentDate(meetup.begintime, meetup.endtime)//moment().tz(`${global.$settings.timezone}`).format('L LTS')

        //console.log(currentdate);

        var currenttime = Date.parse(currentdate);
        var meetupstarttime = Date.parse(meetup.begintime);
        var meetupendtime = Date.parse(meetup.endtime);
        var signupids = [];

        if (global.$signedupmeetups != null && global.$signedupmeetups.length != 0) {
            global.$signedupmeetups.map(item => {
                signupids.push(item.data3);
            })
        }

        if (global.$signedupmeetups != null && global.$signedupmeetups.length != 0 && signupids.includes(meetup.meetupid)) {
            return global.$signedupmeetups.map(signup => {
                //console.log(signup);
                //console.log(meetup);

                if (signup.ownerid == meetup.ownerid && signup.data3 == meetup.meetupid) { // Cancel Signup
                    return (
                        <div className="meetup-card-user-btn-div" style={meetupconfig.buttons.attendee.styles.maindiv}>

                            {/* <Button title="Join Meetup" className="meetup-card-join-btn meetup-btn" onClick={() => { this.handleBtn(meetup, "join") }}><Icon path={mdiLogin} size={1.5} className="meetup-card-icons" /></Button> */}

                            {
                                this.isMeetupFinished(currenttime, meetupstarttime, meetupendtime) ?
                                    (
                                        <div>
                                            {/* <Button title="Cancel Signup" disabled className="meetup-card-signup-btn meetup-btn"><Icon path={mdiAccountRemove} size={1.5} className="meetup-card-icons" /></Button> */}
                                        </div>
                                    )
                                    :
                                    (
                                        meetup.status == "Close" ?
                                            (
                                                <div>
                                                    {/* <Button title="Cancel Signup" disabled className="meetup-card-signup-btn meetup-btn"><Icon path={mdiAccountRemove} size={1.5} className="meetup-card-icons" /></Button> */}
                                                </div>
                                            )
                                            :
                                            (
                                                <Button title="Cancel Signup" className="meetup-card-cancel-signup-btn meetup-btn" onClick={() => { if (window.confirm('Do you want to cancel your signup for this meetup ?')) { this.cancelMeetupSignup(signup) }; }} style={meetupconfig.buttons.attendee.styles.cancelsignup}><Icon path={mdiCancel} size={1.5} className="meetup-card-icons" /></Button>
                                            )
                                    )
                            }
                        </div >
                    )
                }
            })
        }
        else if (meetup.ownerid != global.$userid && meetup.signuprequired == "Yes" && meetup.status == "Open") { // Signup
            return (
                <div className="meetup-card-user-btn-div" style={meetupconfig.buttons.attendee.styles.maindiv}>
                    {
                        this.isMeetupFinished(currenttime, meetupstarttime, meetupendtime) ?
                            (
                                <div>
                                    {/* <Button title="Signup" disabled className="meetup-card-signup-btn meetup-btn" ><Icon path={mdiAccountPlus} size={1.5} className="meetup-card-icons" /></Button> */}
                                </div>
                            )
                            :
                            (
                                this.getSignupsCount(meetup, "count") ?
                                    (
                                        <Button title="Signup" className="meetup-card-signup-btn meetup-btn" onClick={() => { this.getAllSignups(meetup) }} style={meetupconfig.buttons.attendee.styles.signup}><Icon path={mdiAccountPlus} size={1.5} className="meetup-card-icons" /></Button>
                                    )
                                    :
                                    (
                                        ''
                                    )
                            )
                    }
                </div>
            )
        }
        // else if (meetup.ownerid != global.$userid && meetup.signuprequired == "No" && meetup.status == "Open") {
        //     return (
        //         <div className="meetup-card-user-btn-div">
        //             {
        //                 this.isMeetupLive(currenttime, meetupstarttime, meetupendtime) ?
        //                     //console.log("spinner")
        //                     (
        //                         <Button title="Join Meetup" className="meetup-card-join-btn meetup-btn" onClick={() => { if (window.confirm('Do you want to join this meetup ?')) { this.handleBtn(meetup, "join") }; }}><Icon path={mdiLogin} size={1.5} className="meetup-card-icons" /></Button>
        //                     )
        //                     :
        //                     ('')
        //             }
        //         </div>
        //     )
        // }
    }

    isSignupCountReached(meetup) {
        //console.log(signupids);
        var signupcount = 0;
        var result = false;

        signupcount = this.getAllSignups(meetup);

        //console.log(signupcount);
        //console.log(Number(meetup.signupmax), Number(signupcount), Number(meetup.signupmax) >= Number(signupcount))
        if (Number(meetup.signupmax) >= Number(signupcount)) {
            result = true;
        }
        else {
            result = false;
        }

        return result;
    }

    deleteMeetup(meetup) {
        ////console.log("deleteNotification");
        let apiName = 'MeetupService';
        let path = ''; //replace this with the path you have configured on your API
        let myInit =
        {
            body:
            {
                'code': this.state.code,
                'command': "deleteMeetup",
                'meetupid': meetup.meetupid,
                'ownerid': meetup.ownerid
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //console.log(myInit);

        API.post(apiName, path, myInit).then(response => {
            ////console.log (response.data);
            if (response.err == null) {
                this.props.onRefreshMeetups();
            }
            else {
                ////console.log(response.err);
            }
        }
        ).catch(error => {
            ////console.log(error.response)
        });
    }
    registerMeetup(meetup) {
        //console.log("registering Meetup");
        let apiName = 'MeetupService';
        let path = ''; //replace this with the path you have configured on your API
        var date = new Date();
        var timestamp = date / 1000;

        var meetupdata = JSON.stringify(meetup);
        var userdata = JSON.stringify(global.$attendeeDetails);

        let myInit = {
            body:
            {
                'code': global.$code,
                'command': 'signupMeetup',
                'ownerid': meetup.ownerid,//Meetupid
                'signupid': timestamp.toString(),
                'groupname': "Meetups", // for the parallel Meetups to group
                'signuptype': 'meetup',
                'userid': global.$userid,
                'data1': userdata,
                'data2': meetupdata,
                'data3': meetup.meetupid,
                'data4': ' ',
                'status': 'C'
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //console.log(myInit);
        API.post(apiName, path, myInit).then(response => {
            //console.log(response);
            if (response.err == null) {
                //console.log(response.data);
                this.getSignups();
                this.props.onRefreshMeetups();
            }
            else {
            }
        }
        ).catch(error => {
            //console.log(error)
        });
    }

    cancelMeetupSignup(signup) {
        ////console.log("deleteNotification");
        let apiName = 'MeetupService';
        let path = ''; //replace this with the path you have configured on your API
        let myInit =
        {
            body:
            {
                'code': this.state.code,
                'command': "cancelMeetupSignup",
                'signupid': signup.signupid,
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //console.log(myInit);

        API.post(apiName, path, myInit).then(response => {
            ////console.log (response.data);
            if (response.err == null) {
                this.getSignups();
                this.props.onRefreshMeetups();
            }
            else {
                ////console.log(response.err);
            }
        }
        ).catch(error => {
            ////console.log(error.response)
        });
    }
} //Class

export default MeetupsList;