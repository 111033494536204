import React from 'react';
import { API } from 'aws-amplify';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import UpdatePoints from '../Points/UpdatePoints';
var serviceNames = require('../ServiceNameMapper');

const styles = {
  getEmojiButton: {
    cssFloat: "right",
    border: "none",
    margin: 0,
    cursor: "pointer"
  },
  emojiPicker: {
    bottom: 10,
    right: 0,
    cssFloat: "right",
  }
};

class CommentInput extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      code: this.props.code,
      messageid: this.props.messageid,
      receiverid: this.props.receiverid,
      chatInput: '',
      showEmojis: false,
      updatepoints: false
    };

    // React ES6 does not bind 'this' to event handlers by default
    this.submitHandler = this.submitHandler.bind(this);
    this.textChangeHandler = this.textChangeHandler.bind(this);
  }

  submitHandler(event) {

    // Stop the form from refreshing the page on submit
    event.preventDefault();

    // Clear the input box
    this.setState({ chatInput: '' });

    // Call the onSend callback with the chatInput message
    //this.props.onSend(this.state.chatInput);

    this.postComment();
  }

  textChangeHandler(event) {
    this.setState({ chatInput: event.target.value });
  }

  addEmoji = e => {
    let sym = e.unified.split('-')
    let codesArray = []
    sym.forEach(el => codesArray.push('0x' + el))
    let emoji = String.fromCodePoint(...codesArray)
    this.setState({
      chatInput: this.state.chatInput + emoji
    })
  }

  showEmojis = e => {
    this.setState(
      {
        showEmojis: true
      },
      () => document.addEventListener("click", this.closeMenu)
    );
  };

  closeMenu = e => {
    //console.log(this.emojiPicker);
    if (this.emojiPicker !== null && !this.emojiPicker.contains(e.target)) {
      this.setState(
        {
          showEmojis: false
        },
        () => document.removeEventListener("click", this.closeMenu)
      );
    }
  };

  render() {
    return (
      <div>
        <form className="comment-input" onSubmit={this.submitHandler}>
          <input type="text"
            onChange={this.textChangeHandler}
            value={this.state.chatInput}
            placeholder="Write a comment..."
            required
          />
          <div className="comment-emoji-picker">
            {this.state.showEmojis ? (
              <span style={styles.emojiPicker} ref={el => (this.emojiPicker = el)}>
                <Picker
                  onSelect={this.addEmoji}
                  emojiTooltip={true}
                  title="Apps4org"
                  className="emoji"
                />
              </span>
            ) : (
                <p style={styles.getEmojiButton} onClick={this.showEmojis}>
                  {String.fromCodePoint(0x1f60a)}
                </p>
              )}
          </div>
        </form>
        {
          this.state.updatepoints ?
            (
              <div>
                {
                  global.$points.conversationcomment != null && global.$points.conversationcomment.enable.includes("Y") ?
                    (
                      <UpdatePoints receiverid={this.props.receiverid} component="conversation" area="conversationcomment" points={global.$points.conversationcomment.points} />
                    )
                    :
                    ('')
                }
              </div>
            )
            :
            ('')
        }
      </div>
    );
  } //render

  postComment() {
    let apiName = serviceNames.getMessageService();
    let path = '';

    var date = new Date()
    var timestamp = date / 1000

    //this.setState({ showSpinner: true })
    var str = this.state.chatInput;
    str = str.replace(/(?:\r\n|\r|\n)/g, '<br>');
   //console.log(str);

    let myInit =
    {
      body:
      {
        'code': this.state.code,
        'command': 'postComment',
        'messageid': this.state.messageid,
        'receiverid': this.state.receiverid,
        'activityid': timestamp.toString(),
        'sname': global.$attendeeDetails.fname + " " + global.$attendeeDetails.lname,
        'senderid': global.$attendeeDetails.userid,
        'receiverid': this.state.receiverid,
        'message': str,
        'type': 'C'
      },
      response: true,
      headers: {}
    }

    //console.log(myInit);
    API.post(apiName, path, myInit).then(response => {

      //console.log(response);

      if (response.err == null) {

        // Call the onSend callback with the chatInput message
        //console.log('CommentInput: calling onSend');
        //this.props.onSend(this.state.chatInput);
        this.setState({updatepoints: true})
        this.props.onSend();
      }
      else {

        //console.log(response.err)
        /*
        this.setState({ 
            showSpinner: false,
            message: "Unable to place your bid. Please try again",
            bidmessagecolor:'color: red'
        });
        */
      }

    }
    ).catch(error => {
      /*
      this.setState({ showSpinner: false,
      message: "Unable to place your bid. Please try again",
      bidmessagecolor:'color: red'});
      */
      //console.log(error);
    });
  }
}

export default CommentInput;