const ServiceName = require("../ServiceNameMapper");
const { API } = require("aws-amplify");


// GET SPEAKER OBJECT VALUES
function getSpeakerObject(objs) {
  let obj = objs[0];
  let newObj = Object();

  newObj.id = obj.id.S;
  newObj.fullname = obj.fullname.S;
  newObj.photo = obj.photo.S;
  newObj.company = obj.company.S;
  newObj.title = obj.title.S;
  newObj.stype = obj.stype.S;
  newObj.desc = obj.desc.S;
  newObj.titlep1 = obj.titlep1.S;
  newObj.titlep2 = obj.titlep2.S;
  newObj.titlep3 = obj.titlep3.S;
  newObj.type = obj.type.S;
  newObj.typep1 = obj.typep1.S;
  newObj.typep2 = obj.typep2.S;
  newObj.typep3 = obj.typep3.S;
  newObj.descp1 = obj.descp1.S;
  newObj.descp2 = obj.descp2.S;
  newObj.descp3 = obj.descp3.S;
  newObj.file1 = obj.file1.S;
  newObj.file2 = obj.file2.S;
  newObj.file3 = obj.file3.S;
  newObj.submittedby = obj.submittedby.S;
  newObj.udate = obj.udate.N;
  newObj.scategory = obj.scategory.S;
  newObj.email = obj.email.S;
  newObj.phone = obj.phone.S;
  newObj.url = obj.url.S;
  newObj.linkedin = obj.linkedin.S;
  newObj.createdby = obj.createdby.S;
  newObj.sortorder = obj.sortorder.N;
  newObj.videourl = obj.videourl.S;
  newObj.data1 = obj.data1.S;
  newObj.data2 = obj.data2.S;

  if (obj.social1 == null) {
    newObj.social1 = " ";
  } else {
    newObj.social1 = obj.social1.S;
  }

  if (obj.social2 == null) {
    newObj.social2 = " ";
  } else {
    newObj.social2 = obj.social2.S;
  }

  if (obj.social3 == null) {
    newObj.social3 = " ";
  } else {
    newObj.social3 = obj.social3.S;
  }

  if (obj.social4 == null) {
    newObj.social4 = " ";
  } else {
    newObj.social4 = obj.social4.S;
  }

  if (obj.social5 == null) {
    newObj.social5 = " ";
  } else {
    newObj.social5 = obj.social5.S;
  }

  if (obj.social6 == null) {
    newObj.social6 = " ";
  } else {
    newObj.social6 = obj.social6.S;
  }

  return newObj;
}

// CREATE DOCUMENT FILE NAME
function getSpeakerFileName(id, fileName, value) {
  let key = id + "_" + value;
  key = key.replace(/,/g, "_");
  key = key.replace(/ /g, "_");
  key = key.replace(/&/g, "_");
  key = key.replace(/\./g, "");
  key = key.replace(/__/g, "_");
  key = key.toLowerCase();

  fileName = fileName.toLowerCase();
  let fileExtension = fileName.split(".").pop();
  key = key + "." + fileExtension;

  return key;
}

// CREATE S3 FILE NAME
function getS3SpeakerFileName(code, fileName) {
  return code + "/speakers/" + fileName;
}

// CREATE S3 PHOTO FILE NAME
function getS3SpeakerPhotoFileName(code, photoFileName) {
  return code + "/speakers/" + photoFileName;
}

// CREATE S3 VIDEO FILE NAME
function getS3VideoFileName(code, videotoFileName) {
  return code + "/speakers/" + videotoFileName;
}

// GET COPY OF UPLOADED FILE
async function copyFiles(file, code) {
  try {
    let apiName = ServiceName.getUserService();
    let path = ""; //replace this with the path you have configured on your API

    /*
    let myInit = {
      body: {
        code: code,
        command: "copyS3Files",
        srcbucket: "event360live",
        desbucket: "wesicore",
        fileFrom: "speakers",
        fileTo: "upload/documents",
        photo: file,
      },
      response: true,
      headers: {},
    };
    */

    let myInit = {
      body: {
        code: code,
        command: "copyAndDeleteS3Files",
        srcbucket: "event360live",
        desbucket: "wesicore",
        fileFrom: "speakers",
        fileTo: "upload/documents",
        photo: file,
      },
      response: true,
      headers: {},
    };

    console.log({myInit});

    let result = await API.post(apiName, path, myInit);
    console.log(result);
    return result;
  } catch (error) {
    console.log(error)
  }
}

function getSpeakerPhotoFileName(id, fileName) {
  let key = id;
  let photoFileName = "";
  key = key.replace(/,/g, "_");
  key = key.replace(/ /g, "_");
  key = key.replace(/&/g, "_");
  key = key.replace(/\./g, "");
  key = key.replace(/__/g, "_");
  key = key.toLowerCase();

  fileName = fileName.toLowerCase();
  let fileExtension = fileName.split(".").pop();
  key = key+"profile"
  if (fileExtension == "jpg") {
    key = key + ".jpg";
  } else if (fileExtension == "gif") {
    key = key + ".gif";
  } else if (fileExtension == "png") {
    key = key + ".png";
  } else {
    photoFileName = "Format not supported";
  }

  return key;
}

function getVideoS3FileName(id, fileName) {
  let key = id + "_" + "s3";
  key = key.replace(/,/g, "_");
  key = key.replace(/ /g, "_");
  key = key.replace(/&/g, "_");
  key = key.replace(/\./g, "");
  key = key.replace(/__/g, "_");
  key = key.toLowerCase();

  key = key + ".mp4";

  fileName = fileName.toLowerCase();

  return key;
}

// GET SPEAKER PROFILE
async function getSpeaker(code, userid) {
  let apiName = ServiceName.getEventPlannerService();
  let path = ""; //replace this with the path you have configured on your API
  let myInit = {
    body: {
      code: code,
      id: userid,
      type: "speaker",
      command: "getSpeaker",
    },
    response: true,
    convertEmptyValues: true,
    headers: {},
  };

  let result = await API.post(apiName, path, myInit);
  return result;
}

function serEmptyInputsValues(profile) {

  if (profile.titlep1 == "") {
    profile.titlep1 = " ";
  }
  if (profile.typep1 == "") {
    profile.typep1 = " ";
  }
  if (profile.file1FileName == "") {
    profile.file1FileName = " ";
  }
  if (profile.descp1 == "") {
    profile.descp1 = " ";
  }

  if (profile.titlep2 == "") {
    profile.titlep2 = " ";
  }
  if (profile.typep2 == "") {
    profile.typep2 = " ";
  }
  if (profile.file2FileName == "") {
    profile.file2FileName = " ";
  }
  if (profile.descp2 == "") {
    profile.descp2 = " ";
  }

  if (profile.titlep3 == "") {
    profile.titlep3 = " ";
  }

  if (profile.typep3 == "") {
    profile.typep3 = " ";
  }

  if (profile.file3FileName == "") {
    profile.file3FileName = " ";
  }

  if (profile.descp3 == "") {
    profile.descp3 = " ";
  }

  if (profile.desc == "") {
    profile.desc = " ";
  }

  if (profile.company == "") {
    profile.company = " ";
  }

  if (profile.title == "") {
    profile.title = " ";
  }

  if (profile.photoFileHandle == "") {
    profile.photoFileHandle = "profile.png";
  }

  if (profile.photoFileName == "") {
    profile.photoFileName = "profile.png";
  }

  if (profile.photoFileName == ".png") {
    profile.photoFileName = profile.id + ".png";
  }

  if (profile.submittedby == "") {
    profile.submittedby = profile.userid;
  }

  if (profile.selectedStype == "") {
    profile.selectedStype = "D";
  }

  if (profile.speakercategory == "") {
    profile.speakercategory = " ";
  }

  if (profile.speakercategory == "select") {
    profile.speakercategory = " ";
  }

  if (profile.email == "") {
    profile.email = " ";
  }

  if (profile.social1 == "") {
    profile.social1 = " ";
  }

  if (profile.social2 == "") {
    profile.social2 = " ";
  }

  if (profile.social3 == "") {
    profile.social3 = " ";
  }

  if (profile.social4 == "") {
    profile.social4 = " ";
  }
  if (profile.social5 == "") {
    profile.social5 = " ";
  }

  if (profile.social6 == "") {
    profile.social6 = " ";
  }

  if (profile.phone == "") {
    profile.phone = " ";
  }

  if (profile.url == "") {
    profile.url = " ";
  }

  if (profile.linkedin == "") {
    profile.linkedin = " ";
  }

  if (profile.sortorder == "") {
    profile.sortorder = "0";
  }

  if (profile.type == "") {
    profile.type = "speaker";
  }

  if (profile.videotos3FileName == "") {
    profile.videotos3FileName = " ";
  }

  if (profile.videourl == "") {
    profile.videourl = " ";
  }
  if (profile.data1 == "") {
    profile.data1 = "";
  }
  if (profile.data2 == "") {
    profile.data2 = " ";
  }
  if (profile.data3 == "") {
    profile.data3 = " ";
  }
  if (profile.data4 == "") {
    profile.data4 = " ";
  }
  if (profile.data5 == "") {
    profile.data5 = " ";
  }
  if (profile.createdby == "") {
    profile.createdby = " "
  }
  if (profile.linkedin == "") {
    profile.linkedin = " "
  }
  if (profile.sortorder == "") {
    profile.sortorder = " "
  }
  if (profile.scategory == "") {
    profile.scategory = " "
  }
  if (profile.stype == "") {
    profile.stype = " "
  }
  if (profile.docPermission) {
    profile.data2 = {
      "speakerconsent":profile.docPermission
    }
  }
  
  return profile;
}

async function submitProfile(profile) {

  let apiName = ServiceName.getEventPlannerService();
  let path = ''; //replace this with the path you have configured on your API

  var date = new Date();
  var longFormat = date / 1000;

  let speakerprofile = serEmptyInputsValues(profile);
 
  let myInit = {
    body: {
      code: profile.code,
      command: "postSpeakerProfile",
      company: speakerprofile.company,
      createdby: speakerprofile.id,
      createtype: " ",
      data1: speakerprofile.data1,
      data2: JSON.stringify(speakerprofile.data2) ,
      data3: speakerprofile.data3,
      data4: speakerprofile.data4,
      data5: speakerprofile.data5,
      desc: speakerprofile.desc,
      descp1: speakerprofile.descp1,
      descp2: speakerprofile.descp2,
      descp3: speakerprofile.descp3,
      email: speakerprofile.email,
      file1: speakerprofile.file1FileName,
      file2: speakerprofile.file2FileName,
      file3: speakerprofile.file3FileName,
      fullname: speakerprofile.fullname,
      id: speakerprofile.id,
      linkedin: speakerprofile.linkedin,
      phone: speakerprofile.phone,
      photo: speakerprofile.photoFileName,
      scategory: speakerprofile.scategory,
      social1: speakerprofile.social1,
      social2: speakerprofile.social2,
      social3: speakerprofile.social3,
      social4: speakerprofile.social4,
      social5: speakerprofile.social5,
      social6: speakerprofile.social6,
      sortorder: speakerprofile.sortorder,
      stype: speakerprofile.stype,
      submittedby: speakerprofile.userid,
      title: speakerprofile.title,
      titlep1: speakerprofile.titlep1,
      titlep2: speakerprofile.titlep2,
      titlep3: speakerprofile.titlep3,
      type: speakerprofile.type,
      typep1: speakerprofile.typep1,
      typep2: speakerprofile.typep2,
      typep3: speakerprofile.typep3,
      udate: longFormat.toString(),
      url: speakerprofile.url,
      userid: global.$userid,
      updatespeaker: JSON.stringify(profile.updatespeaker),
      videourl: speakerprofile.videourl,
    },
    response: true,
    headers: {},
  };
  
  const result = await API.post(apiName, path, myInit)
  return result;
}

function clearInputs(profile) {
  profile.createdby = '';
  profile.submittedby = '';
  profile.fullname = '';
  profile.title = '';
  profile.selectedStype = '';
  profile.company = '';
  profile.imageUrl = '';
  profile.photo = '';
  profile.photoFileName = '';
  profile.photoFileHandle = '';
  profile.titlep1 = '';
  profile.typep1 = '';
  profile.descp1 = '';
  profile.file1 = '';
  profile.file1FileName = ' ';
  profile.file1FileHandle = '';
  profile.titlep2 = '';
  profile.typep2 = '';
  profile.descp2 = '';
  profile.file2 = '';
  profile.file2FileName = ' ';
  profile.file2FileHandle = '';
  profile.titlep3 = '';
  profile.typep3 = '';
  profile.descp3 = '';
  profile.file3 = '';
  profile.file3FileName = ' ';
  profile.file3FileHandle = '';
  profile.desc = '';
  //profile.id = '';
  //profile.editorState = '';
  profile.speakercategory = '';
  profile.email = '';
  profile.social1 = '';
  profile.social2 = '';
  profile.social3 = '';
  profile.social4 = '';
  profile.social5 = '';
  profile.social6 = '';
  profile.phone = '';
  profile.url = '';
  profile.linkedin = '';
  profile.file1progresstext = '';
  profile.file2progresstext = '';
  profile.file3progresstext = '';
  profile.videos3 = '';
  profile.videotos3FileName = '';
  profile.videotos3FileHandle = '';
  profile.data1 = '';
  profile.data2 = '';
  profile.data3 = '';
  profile.data4 = '';
  profile.data5 = '';
  profile.videourl = '';
}



export default {
  getSpeakerObject,
  getSpeakerFileName,
  getS3SpeakerFileName,
  copyFiles,
  getSpeaker,
  getSpeakerPhotoFileName,
  getS3SpeakerPhotoFileName,
  getVideoS3FileName,
  getS3VideoFileName,
  submitProfile,
  clearInputs
};
