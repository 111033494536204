import React from 'react';
import { API, Analytics } from 'aws-amplify';
import ScheduleTabs from './ScheduleTabs';
import { Row, Col } from 'reactstrap';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import ScheduleSpeakers from './ScheduleSpeakers';
import SessionTracks from './SessionTracks';
import ScheduleWatchNow from './ScheduleWatchNow';
import { Document, Page, pdfjs } from "react-pdf/dist/entry.webpack";
import "react-pdf/dist/Page/AnnotationLayer.css";
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class LiveSessionItem extends React.Component {
    constructor(props) {
        super(props);
       //console.log("LiveSession Item")
      //console.log("session begin date = "+props.schedule.BEGIN, "session end date = "+props.schedule.END);
        this.state = {
            code: global.$code,
            s3: global.$s3,
            bucket: global.$s3bucket,
            dataloaded: false,
            stag: props.stag,
            activetab: props.day,
            numPages: null,
            pageNumber: 1,
            search: '',
            scheduleheadertimestyles: global.$schedule.styles.scheduleheadertime,
            scheduleheadertitlestyles: global.$schedule.styles.scheduleheadertitle,
            scheduleheaderspeakerstyles: global.$schedule.styles.scheduleheaderspeakers,
            scheduletimestyles: global.$schedule.styles.scheduletime,
            scheduletitlestyles: global.$schedule.styles.scheduletitle,
            scheduledescstyles: global.$schedule.styles.scheduledesc,
            schedulespeakerstyles: global.$schedule.styles.schedulespeakers,
            file1: props.schedule.FILE1
        };
       //console.log(this.state);
        if(this.state.file1 == "" || this.state.file1 == " ")
        {
            return this.state.file1 == "empty";
        }
    }

    render() {
        return (
            <div>
                {
                    this.props.schedule.T3.includes("emptyurl") ?
                        (
                            <div>
                                {
                                    this.props.schedule.TYPE.includes("H") ?
                                        (
                                            <Row>
                                                <Col md={3}>
                                                    <h5 style={this.state.scheduleheadertimestyles}>{this.props.schedule.T1}</h5>
                                                </Col>
                                                <Col md={8}>
                                                    {
                                                        this.props.schedule.DESCRIPTION.includes(" ") ?
                                                            (
                                                                this.props.schedule.TITLE
                                                            )
                                                            :
                                                            (
                                                                <div>
                                                                    <h5 style={this.state.scheduleheadertitlestyles}>{this.props.schedule.TITLE}</h5>
                                                                </div>
                                                            )
                                                    }

                                                </Col>
                                            </Row>
                                        )
                                        :
                                        (
                                            <div>

                                                <Row>
                                                    <Col md={3}>
                                                        <h5 style={this.state.scheduletimestyles}>{this.props.schedule.T1}</h5>
                                                        {
                                                            this.props.type.includes("posters")?
                                                            (
                                                                <div>
                                                                    {
                                                                        this.state.file1.includes("empty") ?
                                                                            (<div></div>)
                                                                            :
                                                                            (
                                                                                <div>
                                                                                    <a href={"https://event360live.s3.amazonaws.com/public/" + this.state.code + "/schedule/" + this.state.file1} target="_blank">
                                                                                        {
                                                                                            this.state.file1.includes(".pdf")?
                                                                                            (    
                                                                                                <Document
                                                                                                    file={"https://event360live.s3.amazonaws.com/public/" + this.state.code + "/schedule/" + this.state.file1}
                                                                                                    onLoadSuccess={this.onDocumentLoadSuccess}
                                                                                                    className="pdf-thumbnail"
                                                                                                    loading="Loading..."
                                                                                                >
                                                                                                    <Page pageNumber={this.state.pageNumber} width={150} />
                                                                                                </Document>
                                                                                            )
                                                                                            :
                                                                                            ('')
                                                                                        }
                                                                                    </a>
                                                                                </div>
                                                                            )
                                                                    }
                                                                </div>
                                                            )
                                                            :
                                                            ('')
                                                        }
                                                        <ScheduleWatchNow
                                                            type={this.props.type}
                                                            stag={this.props.stag}
                                                            currentdate={this.props.currentdate}
                                                            currenttime={this.props.currenttime}
                                                            sessionstarttime={this.props.sessionstarttime}
                                                            sessionendtime={this.props.sessionendtime}
                                                            schedule={this.props.schedule}
                                                            activetab={this.props.activetab}
                                                        />
                                                    </Col>
                                                    <Col md={8}>
                                                        <div>
                                                            {
                                                                this.props.schedule.DESCRIPTION.includes(" ") ?
                                                                    (<div>
                                                                        <details open={global.$posters.showdesc}>
                                                                            <summary style={this.state.scheduletitlestyles}>{this.props.schedule.TITLE}
                                                                            </summary>
                                                                            <br />
                                                                            <p style={this.state.scheduledescstyles}>{ReactHtmlParser(this.props.schedule.DESCRIPTION)}</p>
                                                                            {
                                                                                this.props.schedule.SP.includes("empty") ?
                                                                                    (
                                                                                        ''
                                                                                    )
                                                                                    :
                                                                                    (
                                                                                        <ScheduleSpeakers speakers={this.props.schedule.SP} />
                                                                                    )
                                                                            }
                                                                            <h6 style={this.state.schedulespeakerstyles}></h6>
                                                                        </details>
                                                                    </div>
                                                                    )
                                                                    :
                                                                    (
                                                                        <div>
                                                                            <h5 style={this.state.scheduletitlestyles}>{this.props.schedule.TITLE}</h5>
                                                                            {
                                                                                this.props.schedule.SP.includes("empty") ?
                                                                                    (
                                                                                        ''
                                                                                    )
                                                                                    :
                                                                                    (
                                                                                        <ScheduleSpeakers speakers={this.props.schedule.SP} />
                                                                                    )
                                                                            }
                                                                            <h6 style={this.state.schedulespeakerstyles}></h6>

                                                                        </div>
                                                                    )
                                                            }
                                                        </div>

                                                    </Col>

                                                </Row>

                                            </div>
                                        )
                                }
                            </div>
                        )
                        :
                        (
                            <Row>
                                <Col md={12}>
                                    <SessionTracks sessionid={this.props.schedule.SID} />
                                </Col>
                            </Row>

                        )
                }
                < hr />
            </div>
        )
    }

}
export default LiveSessionItem;


