import React from 'react';
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators, Row, Jumbotron, Container, Col, Button, Form, FormGroup, Label, Input, Modal, Header, NavLink } from 'reactstrap';

const moment = require('moment-timezone');
/*var ads =
    [
        {
            "name": "apps4org",
            "ad1": "ad1.png",
            "ad2": "ad2.png",
            "ad3": "ad3.png"
        },
        {
            "name": "amazon",
            "ad1": "ad1.png",
            "ad2": "ad2.png",
            "ad3": "ad3.png"
        }
    ]
*/
var sponsorad1 = [];
var sponsorad2 = [];
var nextIndex ='';
class SessionSponsorSlider extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            code: global.$code,
            activeIndex: 0,
            type: props.type
        };

        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
        global.$sponsorjson.map(sponsor => {
            if (sponsor.TYPE == "T") {
            }
            else {
                if(this.state.type == 'ad1')
                {
                    sponsorad1.push(sponsor);
                }
                if(this.state.type == 'ad2')
                {
                    sponsorad2.push(sponsor);
                }
            }
        });
    }
    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        if(this.state.type == "ad1")
        {
            nextIndex = this.state.activeIndex === sponsorad1.length - 1 ? 0 : this.state.activeIndex + 1;
        }
        else
        {
            nextIndex = this.state.activeIndex === sponsorad2.length - 1 ? 0 : this.state.activeIndex + 1;    
        }
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.animating) return;
        if(this.state.type == "ad1")
        {
            nextIndex = this.state.activeIndex === 0 ? sponsorad1.length - 1 : this.state.activeIndex - 1;
        }
        else
        {
            nextIndex = this.state.activeIndex === 0 ? sponsorad2.length - 1 : this.state.activeIndex - 1;
        }
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }

    render() {
        //console.log(this.props.sessiondate);
       //console.log(sponsorad1);
       //console.log(sponsorad2);
        const { activeIndex } = this.state;
       //console.log(global.$sponsorjson);
        const slides = global.$sponsorjson.map(sponsor => {
            var logo = '';
            if (sponsor.TYPE == "T") {
                //console.log(sponsor.TYPE);
            }
            else {
                //console.log(sponsor.IMAGE1)
                //console.log(sponsor.IMAGE2)

                if (this.state.type == "ad1") {
                    logo = sponsor.IMAGE1
                }
                if (this.state.type == "ad2") {
                    logo = sponsor.IMAGE2
                }
                var image = `https://s3.amazonaws.com/event360live/public/${this.state.code}/exhibitors/` + logo
                //console.log(image)
                return (
                    <CarouselItem
                        onExiting={this.onExiting}
                        onExited={this.onExited}
                        key={image}
                    >
                        {
                            this.state.type.includes("ad1") ?
                                (
                                    <img className="session-sponsor-ad1" src={image} alt="" />
                                )
                                :
                                (
                                    <img className="session-sponsor-ad2" src={image} alt="" />
                                )
                        }
                    </CarouselItem>
                );
            }
        });
        return (
            <div>
                <Carousel activeIndex={activeIndex} next={this.next} previous={this.previous} ride="carousel">
                    {/*<CarouselIndicators items={global.$sponsorjson} activeIndex={activeIndex} onClickHandler={this.goToIndex} />*/}
                    {slides}
                    {/*<CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                    <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />*/}
                </Carousel>
            </div>
        )
    }

}
export default SessionSponsorSlider;
/*
   render() {
        //console.log(this.props.sessiondate);
        const { activeIndex } = this.state;
        var sponsorsads = [];
        if(global.$sponsor.ads == null)
        {
            sponsorsads = ads;
        }
        else
        {
            sponsorsads = global.$sponsor.ads
        }
       //console.log(sponsorsads);
        //var json = JSON.parse(ads);
        const slides = sponsorsads.map(sponsor => {
           //console.log(sponsor);
            var logo = '';

            if (this.state.type == "ad1") {
                logo = sponsor.ad1
            }
            if (this.state.type == "ad2") {
                logo = sponsor.ad2
            }
            var image = logo
           //console.log(image)
            return (
                <CarouselItem
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                    key={image}
                >
                    {
                        this.state.type.includes("ad1") ?
                            (
                                <img className="session-sponsor-ad1" src={image} alt="" />
                            )
                            :
                            (
                                <img className="session-sponsor-ad2" src={image} alt="" />
                            )
                    }
                </CarouselItem>
            );
        })
        return (
            <div>
                {console.log(slides)}
                <Carousel activeIndex={activeIndex} next={this.next} previous={this.previous} ride="carousel">
                    {/*<CarouselIndicators items={global.$sponsorjson} activeIndex={activeIndex} onClickHandler={this.goToIndex} />}
                    {slides}
                    {/*<CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                    <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />}
                </Carousel>
            </div>
        )
    }
render() {
        //console.log(this.props.sessiondate);
        const { activeIndex } = this.state;
       //console.log(global.$sponsorjson);
        const slides = global.$sponsorjson.map(sponsor => {
            var logo = '';
            if (sponsor.TYPE == "T") {
                //console.log(sponsor.TYPE);
            }
            else {
                //console.log(sponsor.IMAGE1)
                //console.log(sponsor.IMAGE2)

                if (this.state.type == "ad1") {
                    logo = sponsor.IMAGE1
                }
                if (this.state.type == "ad2") {
                    logo = sponsor.IMAGE2
                }
                var image = `https://s3.amazonaws.com/event360live/public/${this.state.code}/exhibitors/` + logo
                //console.log(image)
                return (
                    <CarouselItem
                        onExiting={this.onExiting}
                        onExited={this.onExited}
                        key={image}
                    >
                        {
                            this.state.type.includes("ad1") ?
                                (
                                    <img className="session-sponsor-ad1" src={image} alt="" />
                                )
                                :
                                (
                                    <img className="session-sponsor-ad2" src={image} alt="" />
                                )
                        }
                    </CarouselItem>
                );
            }
        });
        return (
            <div>
                <Carousel activeIndex={activeIndex} next={this.next} previous={this.previous} ride="carousel">
                    {/*<CarouselIndicators items={global.$sponsorjson} activeIndex={activeIndex} onClickHandler={this.goToIndex} />}
                    {slides}
                    {/*<CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                    <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />}
                </Carousel>
            </div>
        )
    }
*/

