import React from 'react';
import './App.css';
import MenuBar from "./Components/MenuBar";
import 'bootstrap/dist/css/bootstrap.min.css';
import Recaptcha from 'react-recaptcha';
//import { useParams } from "react-router";
import queryString from 'query-string';
import logo from './images/apps4orglogo.png';
import { API } from 'aws-amplify';

var s3bucket = "wesicore";

/*

  Changes to feature-registration-next
  feature-registration-next changes
  
  Changes from dev-suresh

  changes

  Changes from dev-suresh
  comment from suresh
*/

var AWS = require('aws-sdk');
var config = new AWS.Config(
  {
    accessKeyId: 'AKIAIARZGSELD5JWB54Q',    //ContentUpdateer Second Creds
    secretAccessKey: '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',
    region: 'us-east-1'
  });
var s3 = new AWS.S3(config);

global.$s3 = s3;
global.$s3bucket = s3bucket;

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state =
    {
      dataloaded: false
    }
    //this.getConfig();

  }

  componentWillMount() {
    //console.log("mount");
    this.getString();
  }

  render() {

    //window.location.href.includes("code")
    return (
      <div>
        {
          global.$code != null && global.$code == "learnpat2021" ?
            (
              <div id="google_translate_element"></div>
            )
            :
            ('')
        }
        {
          global.$code ?
            (
              <div>
                {/* <MenuBar2/> */}
                <MenuBar code={global.$code} s3={s3} bucket={s3bucket} />
              </div>
            )
            :
            (
              <div style={{ textAlign: "center", marginTop: "60px" }}>
                {/*<img src={logo} />*/}
                <br />
                <br />
                <a href={'https://d1y1dr9xzw7t4i.cloudfront.net/?code=' + window.name} onClick={this.reload()} className="refresh-site" target="_self"></a>
                <br />
                <br />
                <img className="banner" src={""} height="600px" width="100%" /><br />
              </div>

            )
        }
      </div>
    );

  }
  reload() {
    window.open('https://d1y1dr9xzw7t4i.cloudfront.net/?code=' + window.name, "_self");
  }

  getString() {
    //console.log(global)

    //console.log(window.location.search) 

    const url = queryString.parse(window.location.search);
    //console.log(url);
    //console.log(url.mode);

    global.$loginstatus = "false";
    //console.log(window.name)
    //console.log(global.$code);

    //global.$code = url.code;                // Uncomment for general build
    global.$homepage = url.homepage;

    //global.$code = 'aicup2022';           // Uncomment for customer builds
    //global.$code = 'aicup2021';           // Uncomment for customer builds
    //global.$code = 'snawa2021conf';   // Uncomment for customer builds
    //global.$code = 'travcon2021';         // Uncomment for customer builds
    //global.$code = 'travcon2022';         // Uncomment for customer builds
    //global.$code = 'travcon2022exhibitor'; // Uncomment for customer builds
    global.$code = 'travcon2023exhibitor'; // Uncomment for customer builds
    //global.$code = 'travcon2023';         // Uncomment for customer builds
    //global.$code = 'sfhha2021summit';     // Uncomment for customer builds
    //global.$code = 'caiforum2021';        // Uncomment for customer builds
    //global.$code = 'isfacon2020';         // Uncomment for customer builds
    //global.$code = 'sfhhaconf';           // Uncomment for customer builds
    //global.$code = 'tb2021reversetradeshow';         // Uncomment for customer builds
    //global.$code = 'tb2021tradeshow';           // Uncomment for customer builds
    //global.$code = 'learnpat2021';           // Uncomment for customer builds

    if (global.$code != undefined) {
      window.name = global.$code
      //console.log(window.name);
    }

    if (window.name == "undefined") {
      window.name = url.code;
      //console.log(window.name)
    }

    if (window.name == "") {
      window.name = url.code;
      //console.log(window.name)
    }

    if (window.name == " ") {
      window.name = url.code;
      //console.log(window.name)
    }

    //Mode
    global.$mode = url.mode;
    if (global.$mode == null) {
      global.$mode = "live";
    }
    //console.log(global.$mode);
  }
}

/*
  dev-vinoth
  
*/

/*
  changes to dev-joe
*/
export default App;
