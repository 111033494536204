/* eslint-disable */
import React, { Component } from 'react';
import Icon from '@mdi/react';
import { mdiPlus } from '@mdi/js';
import MeetupForm from './MeetupForm';
import Modal from "react-responsive-modal";
import 'react-responsive-modal/styles.css';
import "./Meetups.css";

class AddMeetup extends React.Component {
    constructor(props) {

        super(props);

        this.state = {
            code: global.$code,
            bucket: props.bucket,
            openpopup: false,
        };
        this.refreshAddMeetup = this.refreshAddMeetup.bind(this);
    }

    onOpenPopup = () => {
        //console.log('onOpenPopup');
        this.setState(prevState => ({
            openpopup: !prevState.openpopup
        }));
        //console.log(this.state.openpopup);
    };

    refreshAddMeetup(){
        this.props.refreshMeetupsHome();
    }

    render() {
        var meetupconfig = global.$config["meetups"].addmeetup;
       //console.log("add meetup render");
        return (
            <div>
                <div className="add-meetup-button" style={meetupconfig.button.styles} onClick={() => this.popup()}>
                    {/* <Icon path={mdiPlus} size={1.5} className="add-meetup-icon"/> */}
                    {meetupconfig.button.title}
                </div>
                <div className="add-meetup-popup-div">
                    <Modal
                        open={this.state.openpopup}
                        onClose={this.onOpenPopup}
                        blockScroll={false}
                        closeOnOverlayClick={false}
                        classNames={{
                            overlay: 'add-meetup-popup-overlay',
                            modal: 'add-meetup-popup-modal',
                          }}
                        styles={{
                            modal: {
                                width: "100%"
                            }
                        }}
                        center>
                        <MeetupForm action="add" refreshAddMeetup={this.refreshAddMeetup}/>
                    </Modal>
                </div>
            </div>
        );
    }

    popup() {
        this.onOpenPopup()
    }

} //Class

export default AddMeetup;