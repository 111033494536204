
import React from "react";
import { Button, Input, Row, Col, Label } from 'reactstrap';
import RegisterButton from "./RegisterButton";

class PaymentDropdown extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedpaymentoption: "",
            selectedpaymentdesc: ""
        };
    }

    handleDropdownChange = (event) => {
        const target = event.target;
        const name = target.name;
        let value = target.value;

        this.setState({ [name]: value });
        this.getSelectedPaymentOptionDesc();
        // this.props.onRefreshComponent();
    }

    render() {

        global.$selectedPaymentOption = this.state.selectedpaymentoption
       //console.log(global.$selectedPaymentOption)
        var configpayment = global.$registrationform.setup[this.props.tabid].payment;

        return (
            <div>
                {configpayment.hidden == null || configpayment.hidden === false ? (this.renderPaymentDrodpdown(configpayment)) : ("")}
                {this.renderRegisterButton()}
            </div>
        );
    }

    renderRegisterButton() {
        return (
            <RegisterButton formtype={this.props.formtype} selectedpaymentoption={this.state.selectedpaymentoption} tabid={this.props.tabid}/>
        )
    }
    renderPaymentDrodpdown(configpayment) {

        var dropdownOptions = configpayment.methods.values.split(",");  // config => "values": "Credit Card,ACH"
        //console.log(dropdownOptions);

        // select the first option by default;
        if (this.state.selectedpaymentoption == "") {
            this.setState({ selectedpaymentoption: dropdownOptions[0] })
        }

        let dropdownItems = dropdownOptions.map((item) => {

            // If differed promocode appiled then enable crad/ach payments only
            if (global.$deferredpromo != null && global.$deferredpromo == "Y") {
                if (item.includes("Card") || item.includes("ACH")) {
                    return (<option key={item} value={item}>{item}</option>)
                }
            }
            else {
                return (<option key={item} value={item}>{item}</option>)
            }
        });

        //console.log(dropdownItems);
        return (
            <div className="payment-dropdown-div" style={global.$registrationform.styles.paymentmethod.maindiv}>
                <Label for="label" className="payment-dropdown-label" style={global.$registrationform.styles.paymentmethod.fieldlabel}>{configpayment.methods.title}</Label>
                <Input type="select" className="form-control" id="payment_dropdown_item" name="selectedpaymentoption" value={this.state.selectedpaymentoption} onChange={this.handleDropdownChange}>
                    {dropdownItems}
                </Input>
                <div className="paymentmethod-desc" dangerouslySetInnerHTML={{ __html: this.state.selectedpaymentdesc }}></div>
            </div>
        )
    }

    getSelectedPaymentOptionDesc() {

        var payment = global.$registrationform.payment;

        if (this.state.selectedpaymentoption.includes("Credit")) {
            this.state.selectedpaymentdesc = payment.credit.description;
        }
        if (this.state.selectedpaymentoption.includes("Free")) {
            this.state.selectedpaymentdesc = payment.free.description;
        }
        if (this.state.selectedpaymentoption.includes("PayRoll")) {
            this.state.selectedpaymentdesc = payment.payroll.description;
        }
        if (this.state.selectedpaymentoption.includes("Check")) {
            this.state.selectedpaymentdesc = payment.check.description;
        }
        if (this.state.selectedpaymentoption.includes("ACH")) {
            this.state.selectedpaymentdesc = payment.ach.description;
        }
        if (this.state.selectedpaymentoption.includes("Reader")) {
            this.state.selectedpaymentdesc = payment.cardreader.stripe.description;
        }
        if (this.state.selectedpaymentoption.includes("Pay Online")) {
            this.state.selectedpaymentdesc = payment.razorpay.description;
        }
        if (this.state.selectedpaymentoption.includes("Pay Later")) {
            if (payment.paylater != null) {
                this.state.selectedpaymentdesc = payment.paylater.description;
            }
            else {
                this.state.selectedpaymentdesc = payment.check.description;
            }
        }
        if (this.state.selectedpaymentoption.includes("none")) {
            this.state.selectedpaymentdesc = "";
        }
        if (this.state.selectedpaymentoption.includes("Select")) {
            this.state.selectedpaymentdesc = "";
        }
    }

}
export default PaymentDropdown;