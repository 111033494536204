import React from 'react';
import CommonFunctions from '../RegCommon/CommonFunctions';
import EventcodeFunctions from '../RegCommon/EventcodeFunctions';

class RegitemRadio extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            regitem: props.regitem,
            groupname: props.groupname,
            disableregitem: props.disableregitem,
            signupsremaining: props.signupsremaining,
            dataloaded: false,
            checkedRadio:"true"
        }
    }


    render() {
        return (
             <div className='input-wrapper-section radio-input-wrapper'>
                {this.renderRadioInput(this.state.regitem, this.state.groupname, this.state.disableregitem)}
                {this.renderSignupsRemaining(this.state.signupsremaining)}
            </div>
        );
    }
    isUserSelectedItemFromThisGroup(groupname) {
        var result = false;
        global.$regitems.map(item => {
            if (item.isChecked == true && item.groupname == groupname) {
                result = true;
            }
        })
        return result;
    }
    renderRadioInput(regitem, groupname, disableregitem) {
        // If user already registered for this reg item make this item as selected
        // var itemSelected = this.isUserSelectedItemFromThisGroup(groupname);
        if (CommonFunctions.isUserAlreadyRegisteredForThisItem(regitem.data5)) {
            return (
                this.renderRegisteredItems(regitem, groupname)
            )
        }
        else {
            if (CommonFunctions.isUserLoggedin()) {
                return (
                    this.renderItemsAfterLogin(regitem, groupname)
                )
            }
            else {
                return (
                    <input
                        type={regitem.inputtype}
                        className="reg-radio"
                        id='true'
                        name={groupname}
                        value={regitem.name}
                        key={regitem.data5}
                        onClick={(e) => this.handleRadioButtons(e,regitem, groupname)}
                    />
                )
            }
        }

    }

    renderRegisteredItems(item, groupname) {
        if (global.$loggedinuserregitems != null) {
            let regitems = JSON.parse(global.$loggedinuserregitems).filter((regitem)=>regitem.status == 'active')
            return regitems.map(regitem => {               
                return (
                    <div style={{textAlign:'center'}}>
                        {
                            regitem.itemid === item.data5 ? (<b>Registered</b>) : ('')
                        }
                    </div>
                )
            })
        }
    }

    renderItemsAfterLogin(regitem, groupname) {
        var upgradegroupname = '';
        var defaulttab = CommonFunctions.getDefaultTab()
        if (global.$registrationform.setup[defaulttab].upgraderegitemsgroupnames) {
            upgradegroupname = global.$registrationform.setup[defaulttab].upgraderegitemsgroupnames;
        }

        if (groupname.includes("attendee") || groupname.includes("Attendee") || groupname.includes("Booth Choice") || groupname == upgradegroupname) // first group
        {
            if (EventcodeFunctions.isTravconExhibitor(global.$code)) {
                return (
                    <input
                        type={regitem.inputtype}
                        className="reg-radio"
                        id='true'
                        name={groupname}
                        value={regitem.name}
                        key={regitem.data5}
                        onClick={(e) => this.handleRadioButtons(e,regitem, groupname)}
                    />
                )
            }
            else if (EventcodeFunctions.isTravconAttendee(global.$code)) {
                return (
                    <input
                        type={regitem.inputtype}
                        className="reg-radio"
                        id='true'
                        name={groupname}
                        value={regitem.name}
                        key={regitem.data5}
                        onClick={(e) => this.handleRadioButtons(e,regitem, groupname)}
                    />
                )
            }
            else {
                return (
                    <input
                        type={regitem.inputtype}
                        className="reg-radio"
                        id='true'
                        name={groupname}
                        value={regitem.name}
                        key={regitem.data5}
                        onClick={(e) => this.handleRadioButtons(e,regitem, groupname)}
                        disabled
                    />
                )
            }
        }
        else if (groupname.includes("guest") || groupname.includes("Guest")) {
            if (global.$loggedinuser.guests != "" && global.$loggedinuser.guests != " ") {
                return (
                    <input
                        type={regitem.inputtype}
                        className="reg-radio"
                        id='true'
                        name={groupname}
                        value={regitem.name}
                        key={regitem.data5}
                        onClick={(e) => this.handleRadioButtons(e,regitem, groupname)}
                        disabled
                    />
                )
            }
            else {
                return (
                    <input
                        type={regitem.inputtype}
                        className="reg-radio"
                        id='true'
                        name={groupname}
                        value={regitem.name}
                        key={regitem.data5}
                        onClick={(e) => this.handleRadioButtons(e,regitem, groupname)}
                    />
                )
            }
        }
        else {
            return (
                <input
                        type={regitem.inputtype}
                        className="reg-radio"
                        id='true'
                        name={groupname}
                        value={regitem.name}
                        key={regitem.data5}
                        onClick={(e) => this.handleRadioButtons(e,regitem, groupname)}
                    />
            )
        }
    }

    renderSignupsRemaining(signupsremaining) {
        return (
            <div className='remaining-count available-count-text'>{signupsremaining}</div>
        )
    }

    handleRadioButtons(e,selected, groupname) { 
        if(e.target.id === this.state.checkedRadio){
            e.target.checked = true
            this.state.checkedRadio = ""
        
            this.updateSelectedRegItemRadio(selected, groupname,e);
            CommonFunctions.checkGuestRegItem(selected,true)
        }
        else {
            e.target.checked = false
            this.state.checkedRadio = e.target.id
            this.updateSelectedRegItemRadio("selected", groupname,e);
            CommonFunctions.checkGuestRegItem(selected,false);
        }     
    }

    updateSelectedRegItemRadio(selected, groupname,e) {
        global.$regitems.map(regitem => {
            // Check for radio input type and then update status
            if (regitem.groupname == groupname && CommonFunctions.isRadioInput(regitem)) {
                if (regitem.data5 == selected.data5) {
                    regitem.isChecked = true;
                    regitem.itemprice = selected.amount;
                    regitem.price = selected.amount
                    regitem.itemid = selected.data5
                    
                    if (!this.props.conditionalRegitems(regitem)) {
                        
                        regitem.isChecked = false;
                        regitem.itemprice = 0;
                        regitem.price = 0
                        regitem.itemid = ''
                        e.target.checked = false
                        
                    }
                }
                else {
                    console.log("error");
                    regitem.isChecked = false;
                    regitem.itemprice = 0;
                    regitem.price = 0
                    regitem.itemid = ''
                }
            }
        })
        this.props.refreshComponent();
        //this.setState({dataloaded: true})
    }
}

export default RegitemRadio;