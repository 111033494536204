import React from 'react';
import SessionWithTabs from './SessionWithTabs';
import SessionWithoutTabs from './SessionWithoutTabs';

const moment = require('moment-timezone');
class SessionComponents extends React.Component {
    constructor(props) {
        super(props);
      //console.log(props.session);

        this.state = {
            code: global.$code,
        };
    }

    render() {

        return (
            <div>
                {this.displaySessionDetails()}
            </div>
        )
    }

    displaySessionDetails() {
       
        var currentdate = moment().tz(`${global.$settings.timezone}`).format('L LTS') // L = 9/29/2020  LTS = 10:37:01 PM 
        var sessionstarttime = Date.parse(this.props.session.BEGIN);
        var sessionendtime = Date.parse(this.props.session.END);

        //console.log(`SessionComponents: Timer: current = ${currentdate}, start = ${this.props.session.BEGIN}, end = ${this.props.session.BEGIN}` );

        var mode = global.$mode;
        if (mode == null || mode == '')
        {
            mode = 'live';
        }

        if (mode == "live")
        {
            //Nothing - Just retunr the current date
        }
        else if (mode == "preview")
        {
            // Make current date/time as same as session begin date/time
            currentdate = this.props.session.BEGIN; 
        }
        else if (mode == "dryrun")
        {
            var eventdate = moment(sessionstarttime).format('L');
            var currenttime = moment().tz(`${global.$settings.timezone}`).format('LTS');
            
            currentdate = eventdate + " " + currenttime
        }
        else if (mode == "ondemand")
        {   //Add 365 days to current date
            var postdate = moment().add(365,'days').calendar();
            var posttime = moment().tz(`${global.$settings.timezone}`).format('LTS');
            currentdate = postdate + " " + posttime;
        }
        //console.log(currentdate);

        var curenttime = Date.parse(currentdate);

        var d1 = new Date(this.props.session.BEGIN);
        var d2 = new Date(this.props.session.END);

        var beginDateFormatter = moment(d1).format('MMM Do, h:mm A');
        var endDateFormatter = moment(d2).format('h:mm A');
        
        var sessionDateForCertificate = moment(d2).format('MMM Do, YYYY');
      //console.log(sessionDateForCertificate);

      //console.log(this.props.showfeedback);
       //console.log(this.props.session.QA.toString());
       
      //console.log(this.props)
        if (this.props.session.FB !== "" || this.props.session.VT !== "" || this.props.session.QA == "Y") {
            return (
                <div>
                    <SessionWithTabs
                        session={this.props.session}
                        showfeedback={this.props.showfeedback}
                        begindate={beginDateFormatter}
                        enddate={endDateFormatter}
                        curenttime={curenttime}
                        sessionstarttime={sessionstarttime}
                        sessionendtime={sessionendtime}
                        sessiondate = {sessionDateForCertificate}
                        stag = {this.props.stag}
                        activetab = {this.props.activetab}
                    />
                </div>
            )
        }
        else {
            ////console.log("without tabs")
            return (
                <SessionWithoutTabs
                    session={this.props.session}
                    showfeedback={this.props.showfeedback}
                    begindate={beginDateFormatter}
                    enddate={endDateFormatter}
                    curenttime={curenttime}
                    sessionstarttime={sessionstarttime}
                    sessionendtime={sessionendtime}
                    stag = {this.props.stag}
                    activetab = {this.props.activetab}
                />
            )
        }
    }

}
export default SessionComponents;


