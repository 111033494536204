import React from 'react';
import { Input, Row, Col } from 'reactstrap';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import './VirtualExpo.css';
import { mdiFacebook, mdiTwitter,mdiLinkedin, mdiInstagram } from '@mdi/js';
import Icon from '@mdi/react';

const moment = require('moment-timezone');

class VirtualExhibitorInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showEmail:global.$exhibitors.enableemail,
            showContact:global.$exhibitors.enablecontact,
            showAddress:global.$exhibitors.enableaddress,
        };

    }
    render() {
        var exhibitor = this.props.exhibitor
        //console.log(exhibitor)
        //console.log(`https://s3.amazonaws.com/event360live/public/${global.$code}/exhibitors/${exhibitor.LOGO}`)
        return (
            <div>
                <Row>
                    <Col md={3}>
                        {
                            exhibitor.LOGO != "" ?
                                (
                                    <div className="virtual-exhibitor-logo-div">
                                        <img className="virtual-exhibitor-logo" src={`https://s3.amazonaws.com/event360live/public/${global.$code}/exhibitors/${exhibitor.LOGO}`} />
                                        {this.displayExhibitorContactIcons(exhibitor)}
                                    </div>
                                )
                                :
                                ('')
                        }
                    </Col>
                    <Col md={9}>
                        <p className="virtual-exhibitor-title">{exhibitor.NAME}</p>
                        <div className="virtual-exhibitor-url"><a href={exhibitor.URL} target="_blank">{exhibitor.URL}</a></div>
                        {
                            exhibitor.IMAGE1 != "" ?
                                (
                                    <div className="virtual-exhibitor-banner">
                                        <img className="virtual-exhibitor-img1" src={`https://s3.amazonaws.com/event360live/public/${global.$code}/exhibitors/${exhibitor.IMAGE1}`} />
                                    </div>
                                )
                                :
                                ('')
                        }
                        <div className="virtual-exhibitor-info-div">
                            {this.getExhibitorDesc(exhibitor)}
                            <br />
                        </div>
                        <div className="virtual-exhibitor-contact-info-div">
                            {this.getExhibitorContact(exhibitor)}
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
    getExhibitorDesc(exhibitor) {
        var desc = exhibitor.DESCRIPTION.trim();
        if (desc == "" || desc == " " || desc == null) {
            return (
                <div></div>
            )
        }
        else {
            return (
                <div className="virtual-exhibitor-desc-info">
                    <p className="virtual-exhibitor-desc">{ReactHtmlParser(desc)}</p>
                </div>
            );
        }
    }
    getExhibitorContact(exhibitor) {
        return (
            <div className="virtual-exhibitor-info">
                {this.state.showContact ? this.showContact(exhibitor) : ""}
                {this.state.showAddress ? this.showAddress(exhibitor) : ""}
            </div>
        );
    };

    showContact(exhibitor) {
        if (exhibitor.TITLE == "" && exhibitor.CNAME == "" && exhibitor.PHONE == "" && exhibitor.EMAIL == "" && exhibitor.URL == "") {
            //console.log("empty")
            return '';
        }
        else {
            //console.log("exist");
            return (
                <div>
                    <p className="virtual-exhibitor-contact-header1">Contact</p>
                    <hr />
                    <div className="virtual-exhibitor-contact-info1">{exhibitor.CNAME}</div>
                    <div className="virtual-exhibitor-contact-info1">{exhibitor.TITLE}</div>
                    <div className="virtual-exhibitor-contact-info1"><a href={`tel:${exhibitor.PHONE}`}>{exhibitor.PHONE}</a></div>

                    {this.state.showEmail && this.state.showEmail === true  && <div className="virtual-exhibitor-contact-info1"><a href={`mailto:${exhibitor.EMAIL}`}>{exhibitor.EMAIL}</a></div> }
                    {this.state.showEmail === undefined || this.state.showEmail === ""  ? <div className="virtual-exhibitor-contact-info1"><a href={`mailto:${exhibitor.EMAIL}`}>{exhibitor.EMAIL}</a></div>:""}
                </div>
            )

        }
        //console.log("exist2");
    }
    showAddress(exhibitor) {
        if (exhibitor.COUNTRY == "" || exhibitor.COUNTRY == null && exhibitor.STATE == "" && exhibitor.CITY == "" && exhibitor.STREET == "" && exhibitor.ZIP == "") {
            return '';
        }
        else {
            return (
                <div>
                    <p className="virtual-exhibitor-contact-header2">Address</p>
                    <hr />
                    <div className="virtual-exhibitor-contact-info2">{exhibitor.COUNTRY}</div>
                    <div className="virtual-exhibitor-contact-info2 ">{exhibitor.STREET}, {exhibitor.CITY}, {exhibitor.STATE} - {exhibitor.ZIP}</div>
                </div>
            )
        }
    }

    displayExhibitorContactIcons(exhibitor) {
       // console.log(exhibitor);
        return (
            <div className="exhibitor-contact-icons">
                {
                    exhibitor.SOCIAL1 != null && exhibitor.SOCIAL1 != "" && exhibitor.SOCIAL1 != " " ?
                        (
                            <a href={exhibitor.SOCIAL1} target="_blank"><Icon path={mdiFacebook} size={1.6} color="#828783" className="exhibitor-contact-icon" /></a>
                        )
                        :
                        ('')
                }
                {
                    exhibitor.SOCIAL2 != null && exhibitor.SOCIAL2 != "" && exhibitor.SOCIAL2 != " " ?
                    (
                        <a href={exhibitor.SOCIAL2} target="_blank"><Icon path={mdiTwitter} size={1.6} color="#828783" className="exhibitor-contact-icon" /></a>
                    )
                    :
                    ('')
                }
                {
                    exhibitor.SOCIAL3 != null && exhibitor.SOCIAL3 != "" && exhibitor.SOCIAL3 != " " ?
                    (
                        <a href={exhibitor.SOCIAL3} target="_blank"><Icon path={mdiLinkedin} size={1.6} color="#828783" className="exhibitor-contact-icon" /></a>
                    )
                    :
                    ('')
                }
                {
                    exhibitor.SOCIAL4 != null && exhibitor.SOCIAL4 != "" && exhibitor.SOCIAL4 != " " ?
                    (
                        <a href={exhibitor.SOCIAL4} target="_blank"><Icon path={mdiInstagram} size={1.6} color="#828783" className="exhibitor-contact-icon" /></a>
                    )
                    :
                    ('')
                }
            </div>
        )
    }
}
export default VirtualExhibitorInfo;


