/* eslint-disable */
var serviceNames = require('../../ServiceNameMapper');
import { API } from "aws-amplify";
var discoveredReaders;
var paymentIntentId;

var terminal = StripeTerminal.create({
    // onFetchConnectionToken: async () => {
    //     let connectionTokenResult = await getConnectionToken;
    //     console.log(connectionTokenResult)
    //     return connectionTokenResult;
    // },
    onFetchConnectionToken: getConnectionToken,
    onUnexpectedReaderDisconnect: unexpectedDisconnect,
});

async function getConnectionToken() {

    //console.log("getConnectionToken")

    let apiName = serviceNames.getPaymentService();
    let path = ''; //replace this with the path you have configured on your API
    let myInit = {
        body:
        {
            'code': global.$code,
            'command': 'createStripeReaderConnectionToken',
            'mode': 'test'
        },
        response: true,
        convertEmptyValues: true,
        headers: {}
    }

    var response = await API.post(apiName, path, myInit);

    //console.log(myInit);
    //console.log(response);

    if (response.err == null && response.data != null) {
        //console.log(response);
        // this.setState = ({
        //     connectiontoken: response.data
        // })
        //this.discoverReaderHandler()
        return response.data
    }
    else {
       console.log(response.err);
    }
}

function unexpectedDisconnect() {
    // In this function, your app should notify the user that the reader disconnected.
    // You can also include a way to attempt to reconnect to a reader.
    console.log("Disconnected from reader")
}

async function discoverReaderHandler(location) {

    console.log('discoverReaderHandler')

    //var config = {simulated: true};
    var config = {simulated: false,
                  location: location};

    var discoverResult = await terminal.discoverReaders(config)

      if (discoverResult.error) {
        console.log('Failed to discover: ', discoverResult.error);
      } else if (discoverResult.discoveredReaders.length === 0) {
          console.log('No available readers.');
      } else {
          discoveredReaders = discoverResult.discoveredReaders;
          //console.log('terminal.discoverReaders', discoveredReaders);
          //connectReaderHandler(discoveredReaders);
          return discoveredReaders;
      }

    //console.log(readers)

    return discoveredReaders;
}

var amount = '50';

async function connectReaderHandler(discoveredReaders) {
    console.log("connectReaderHandler")
    // Just select the first reader here.
    var selectedReader = discoveredReaders[0];

    var connectResult = await terminal.connectReader(selectedReader)
    if (connectResult.error) {
        console.log('Failed to connect: ', connectResult.error);
    } else {
        console.log('Connected to reader: ', connectResult.reader.label);
        console.log('terminal.connectReader', connectResult)
        //createPaymentIntent(amount)
    }


    //console.log(connectResult);

    return connectResult;
}

async function createPaymentIntent(amount) {

  ///////////////////////////////////////////

  console.log("createPaymentIntent")

  let apiName = serviceNames.getPaymentService();
  let path = ''; //replace this with the path you have configured on your API
  let myInit = {
      body:
      {
          'code': global.$code,
          'command': 'createStripeReaderPaymentIntent',
          'amount': amount,  //Stripe requires minimum 50
          'currency': 'USD',
          'desc': global.$regattendeeinfo.fname + " " + global.$regattendeeinfo.lname + ' - ' + global.$regattendeeinfo.email + ' - ' + global.$attendeeregitems + ',' + global.$guestregitems + ',' + discount          
      },
      response: true,
      convertEmptyValues: true,
      headers: {}
  }
  
  console.log(myInit);
  var fetchPaymentIntentClientSecret = await API.post(apiName, path, myInit);
  //console.log(fetchPaymentIntentClientSecret);

    if (fetchPaymentIntentClientSecret.err == null && fetchPaymentIntentClientSecret.data != null) {
      
        //terminal.setSimulatorConfiguration({testCardNumber: '4242424242424242'});
      //terminal.collectPaymentMethod(fetchPaymentIntentClientSecret.data).then(function(result) {

      var result = await terminal.collectPaymentMethod(fetchPaymentIntentClientSecret.data)

      if (result.error) {
        // Placeholder for handling result.error
        console.log(result.error)

      } else {
          //console.log('terminal.collectPaymentMethod', result.paymentIntent);
          terminal.processPayment(result.paymentIntent).then(function(result) {
          if (result.error) {
            console.log(result.error)
          } else if (result.paymentIntent) {
              paymentIntentId = result.paymentIntent.id;
              console.log('terminal.processPayment', result.paymentIntent);
              //capture(paymentIntentId)
          }
        });
      }
        
    }
    else {
       console.log(fetchPaymentIntentClientSecret.err);
    }
    
    return result
}

async function capturePaymentIntent(paymentIntentId) {
    let apiName = serviceNames.getPaymentService();
    let path = ''; //replace this with the path you have configured on your API
    let myInit = {
        body:
        {
            'code': global.$code,
            'command': 'capturePaymentIntent',
            'payment_intent_id': paymentIntentId
        },
        response: true,
        convertEmptyValues: true,
        headers: {}
    }
    
    var response = await API.post(apiName, path, myInit);

    if (response.err == null && response.data != null) {
        //console.log(response);

        return response.data
    }
    else {
       console.log(response.err);
    }
}

export default { getConnectionToken, unexpectedDisconnect, discoverReaderHandler, terminal, connectReaderHandler, createPaymentIntent, capturePaymentIntent }