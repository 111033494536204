import React, { useRef } from 'react';
import './Sponsors.css';
import 'react-responsive-modal/styles.css';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
//https://react-multi-carousel.surge.sh/?selectedKind=Carousel&selectedStory=Custom%20arrow&full=0&addons=1&stories=1&panelRight=0&addonPanel=kadira%2Fjsx%2Fpanel

class SponsorSlider extends React.Component {
    constructor(props) {
        super(props);
        this.state =
        {
            // code: props.code,
            // open: false,
            // s3: props.s3,
            // bucket: props.bucket,
            // type: props.type,
            dataloaded: false,
            search: '',
            slider: global.$sponsorjson,
            activeIndex: 0,
            alloptionsopen: false,
            numberofimagesperslide: 4

        }
    }
    render() {
        const slides = global.$sponsorjson.map((sponsor, index) => {
            var logo = "";
            logo = `https://s3.amazonaws.com/event360live/public/${global.$code}/exhibitors/` + sponsor.LOGO

            if (sponsor.SPLEVEL.includes("Platinum")) {
                return (
                    <div className="sponsor-slider-div" key={sponsor.ID}>
                        <a href={sponsor.URL} target="_blank"><img src={logo} style={global.$exhibitors.newlayout.styles.sponsors.img} className="sponsor-slider-img" /></a>
                    </div>
                );
            }
        });

        const responsive = {
            desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 6,
                slidesToSlide: 6 // optional, default to 1.
            },
            tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 4,
                slidesToSlide: 4 // optional, default to 1.
            },
            mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 2,
                slidesToSlide: 2 // optional, default to 1.
            }
        };
        return (
            <div>
                <Carousel
                    swipeable={true}
                    draggable={true}
                    arrows={false}
                    responsive={responsive}
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={3000}
                    keyBoardControl={true}
                    // customTransition="all .5"
                    // transitionDuration={500}
                    containerClass="carousel-container"
                    // dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                >
                    {slides}
                </Carousel>
                <br></br>
                <br></br>
            </div>
        )

    }
}
export default SponsorSlider;
