import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import LoadPrivateImg from '../Components/Shared/LoadPrivateImg';
import commonProfile from '../images/common_profile.png';
import './Delegates.css';
import Avatar from 'react-avatar';
import ConversationView from './ConversationView';
import Modal from "react-responsive-modal";
import { API } from 'aws-amplify';
import { mdiLinkedin, mdiTwitter, mdiChat, mdiEmail, mdiPhone, mdiFacebook, mdiStar } from '@mdi/js';
import Icon from '@mdi/react';

class DelegateProfile2 extends React.Component {
    constructor(props) {
        //console.log(props.attendee);
        if (props.showdirectory == null) {
            props.showdirectory = "true"
        }
        var itemcardstyles = {};
        var cardstyles = {};
        var imgdivstyle = {};
        var imgstyle = {};
        var showcompany = true;
        var showtitle = true;
        var showq1 = false;
        var showq2 = false;
        var containerstyle = {
            /*"marginLeft": "10px !important",
                        "display": "flex",
                        "justifyContent": "center",
                        "alignItems": "center",
                        "height": "170px"*/
        }

        if (global.$attendees.profile2 !== null) {
            cardstyles = global.$attendees.profile2.styles.photosubdiv;
            imgdivstyle = global.$attendees.profile2.styles.imgdivstyle;
            containerstyle = global.$attendees.profile2.styles.containerstyle;
        }
        var enablechat = "";
        if (global.$attendees.enablechat == null) {
            enablechat = "N";
        }
        else {
            enablechat = global.$attendees.enablechat;
        }

        if (global.$attendees.showcompany != null) {
            showcompany = global.$attendees.showcompany;
        }
        if (global.$attendees.showtitle != null) {
            showtitle = global.$attendees.showtitle;
        }
        if (global.$attendees.showq1 != null) {
            showq1 = global.$attendees.showq1;
        }
        if (global.$attendees.showq2 != null) {
            showq2 = global.$attendees.showq2;
        }


        super(props);
        this.state = {
            code: props.code,
            userid: props.userid,
            company: props.company,
            fname: props.fname,
            lname: props.lname,
            title: props.title,
            photo: props.photo,
            phone: props.phone,
            email: props.email,
            street: props.street,
            city: props.city,
            state: props.state,
            zip: props.zip,
            linkedin: props.linkedin,
            showdirectory: props.showdirectory,
            twitter: props.twitter,
            facebook: props.facebook,
            cardstyles: cardstyles,
            imgdivstyle: imgdivstyle,
            cardcontainerstyle: containerstyle,
            openpopup: false,
            enablechat: enablechat,
            checkconversationstatus: false,
            showcompany: showcompany,
            showtitle: showtitle,
            showq1: showq1,
            showq2: showq2
        };
        //console.log(this.state);
        if (this.state.street == " " || this.state.street == "") {
            this.state.street = "empty";
        }
        else {
            this.state.street = this.state.street + ","
        }

        if (this.state.city == " " || this.state.city == "") {
            this.state.city = "empty";
        }
        else {
            this.state.city = this.state.city
        }

        if (this.state.state == " " || this.state.state == "") {
            this.state.state = "empty";
        }
        else {
            this.state.state = ", " + this.state.state
        }

        if (this.state.zip == " " || this.state.zip == "") {
            this.state.zip = "empty";
        }
        else {
            this.state.zip = " - " + this.state.zip
        }

        if (this.state.linkedin == " " || this.state.linkedin == "") {
            this.state.linkedin = "empty";
        }
        if (this.state.twitter == " " || this.state.twitter == "") {
            this.state.twitter = "empty";
        }
        if (this.state.facebook == " " || this.state.facebook == "") {
            this.state.facebook = "empty";
        }
    }
    onOpenPopup = () => {
        //console.log('onopenpopup');
        this.setState(prevState => ({
            openpopup: !prevState.openpopup
        }));
    };

    render() {
        //console.log('auctions - render');
        //console.log(this.state.showdirectory)
        //console.log(this.state);
        if (this.state.showdirectory == "true" || this.state.showdirectory == "TRUE") {
            return (
                <div>
                    {this.displayAttendees()}

                    <Modal
                        open={this.state.openpopup}
                        onClose={this.onOpenPopup}
                        blockScroll={false}
                        styles={{
                            modal: {
                                width: "70%"
                            }
                        }}
                        center>
                        <div className="conversations-popup">
                            <ConversationView type="chat" delegateid={this.state.userid} delegatename={this.state.fname} loginid={global.$userid} loginname={global.$fname} />
                        </div>
                    </Modal>
                </div>
            )
        }
        else {
            return (
                <div>
                    <Row>
                        <Col md={6} className="offset-5">
                            <div className="loading-div">
                                <div className="loading-text">Loading....</div>
                            </div>
                        </Col>
                    </Row>
                </div>
            )
        }
    }

    displayAttendees() {
        return (
            <div className="card2" style={this.state.cardstyles}>
                <div className="container container2" style={this.state.cardcontainerstyle}>
                    <Row className="card-attendee">
                        <Col md={3}>
                            <div className="attendee-img2 attendee-img2-div" style={this.state.imgdivstyle}>
                                {
                                    this.state.photo.includes("-1") ?
                                        (
                                            <div className="attendee-avatar2-div">
                                                <Avatar className="attendee-avatar2" name={`${this.state.fname + " " + this.state.lname}`} />
                                            </div>
                                        )
                                        :
                                        (
                                            <div className="attendee-avatar2-div">
                                                <LoadPrivateImg id={this.state.userid} name={this.state.fname} component="delegates" code={this.state.code} width="100" height="100" />
                                            </div>
                                        )
                                }
                                {this.displaySocialIcons()}
                            </div>
                        </Col>
                        <Col md={9} >
                            {
                                global.$points != null && global.$points.attendeebadgelevels != null && global.$points.attendeebadgelevels.enable == true ?
                                    (
                                        <div className="attendee-badge-level-div">
                                            {this.displayAttendeeBadgeLevel()}
                                        </div>
                                    )
                                    :
                                    ('')
                            }
                            <div className='attendee-info-wrapper'>
                            <div className="attendee-info-div2" >
                                <h4 className="attendee-name2"><b>{this.state.fname + " " + this.state.lname}</b></h4>
                                {
                                    this.state.showcompany == true ?
                                        (
                                            <p className="attendee-company2">{this.state.company}</p>
                                        )
                                        :
                                        ('')
                                }
                                {
                                    this.state.showtitle == true ?
                                        (
                                            <p className="attendee-title2">{this.state.title}</p>
                                        )
                                        :
                                        ('')
                                }
                                {
                                    this.state.showq1 == true ?
                                        (
                                            <p className="attendee-q1">{global.$attendees.q1title}{this.props.attendee.q1}</p>
                                        )
                                        :
                                        ('')
                                }
                                {
                                    this.state.showq2 == true ?
                                        (
                                            <p className="attendee-q2">{global.$attendees.q2title}{this.props.attendee.q2}</p>
                                        )
                                        :
                                        ('')
                                }
                                {
                                    global.$attendees.showaddress.includes("Y") ?
                                        (
                                            <div className="address-div2">
                                                {
                                                    this.state.street.includes("empty") ?
                                                        ('')
                                                        :
                                                        (
                                                            <p className="address-street2">{this.state.street}</p>
                                                        )
                                                }
                                                {
                                                    this.state.city.includes("empty") ?
                                                        ('')
                                                        :
                                                        (
                                                            <div>
                                                                {
                                                                    this.state.state.includes("empty") ?
                                                                        (
                                                                            <p className="address-city2">{this.state.city}</p>
                                                                        )
                                                                        :
                                                                        (
                                                                            <div>
                                                                                {
                                                                                    this.state.zip.includes("empty") ?
                                                                                        (
                                                                                            <p className="address-city2">{this.state.city}{this.state.state}</p>
                                                                                        )
                                                                                        :
                                                                                        (
                                                                                            <p className="address-city2">{this.state.city}{this.state.state}{this.state.zip}</p>
                                                                                        )
                                                                                }
                                                                            </div>
                                                                        )
                                                                }
                                                            </div>
                                                        )
                                                }
                                            </div>
                                        )
                                        :
                                        ('')
                                }
                            </div>
                            </div>
                            

                        </Col>

                    </Row>
                </div>
            </div>
        )
    }
    checkConversations() {
        this.getConversation();
    }
    displaySocialIcons() {
        return (
            <div>
                {
                    global.$attendees.showsocial.includes("Y") ?
                        (
                            <div className="social-div2">
                                {
                                    global.$attendees.showemail.includes("Y") ?
                                        (
                                            this.state.email != "" && this.state.email != " " && this.state.email != "empty" ?
                                                (
                                                    <a href={"mailto:" + this.state.email}><Icon path={mdiEmail} size={1.2} color="#828783" className="attendee2-card-icon" /></a>
                                                )
                                                :
                                                ('')
                                        )
                                        :
                                        ('')
                                }
                                {
                                    global.$attendees.showphone.includes("Y") ?
                                        (
                                            this.state.phone != "" && this.state.phone != " " && this.state.phone != "empty" ?
                                                (
                                                    <a href={"tel:" + this.state.phone}><Icon path={mdiPhone} size={1.2} color="#828783" className="attendee2-card-icon" /></a>
                                                )
                                                :
                                                ('')
                                        )
                                        :
                                        ('')
                                }
                                {
                                    this.state.linkedin.includes("empty") ?
                                        ('')
                                        :
                                        (
                                            <a href={this.state.linkedin} target="_blank"><Icon path={mdiLinkedin} size={1.2} color="#828783" className="attendee2-card-icon" /></a>
                                        )
                                }
                                {
                                    this.state.twitter.includes("empty") ?
                                        ('')
                                        :
                                        (
                                            <a href={this.state.twitter} target="_blank"><Icon path={mdiTwitter} size={1.2} color="#828783" className="attendee2-card-icon" /></a>
                                        )
                                }
                                {
                                    this.state.facebook.includes("empty") ?
                                        ('')
                                        :
                                        (
                                            <a href={this.state.facebook} target="_blank"><Icon path={mdiFacebook} size={1.2} color="#828783" className="attendee2-card-icon" /></a>
                                        )
                                }
                                {
                                    this.state.enablechat.includes("Y") ?
                                        (
                                            //this.findConversation()
                                            <div onClick={() => this.checkConversations()}>
                                                <Icon path={mdiChat} size={1.2} color="#828783" className="attendee2-card-icon delegate-chat" />
                                            </div>
                                        )
                                        :
                                        ('')
                                }
                            </div>
                        )
                        :
                        ('')
                }
            </div>
        )
    }

    createConversation() {
        let apiName = 'ChatService';
        let path = ''; //replace this with the path you have configured on your API
        var date = new Date();
        var timestamp = date / 1000;
        let myInit = {
            body:
            {
                'code': global.$code,
                'command': 'getConversation',
                'p1': global.$userid,
                'p2': this.state.userid,
                'p1name': global.$fname,
                'p2name': this.state.fname,
                'timestamp': timestamp.toString()
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }

        //console.log(myInit);
        //console.log('ChatMessages:getMessages()');

        API.post(apiName, path, myInit).then(response => {
            //console.log(response);

            if (response.err == null && response.data != null) {
                //console.log(response.data.Items);
                //var conversations = this.getChatTableObjects(response.data.Items);
                //alert("Invitation has been sent successfully");
            }
            else {
                //console.log(response.err);
            }
        }
        ).catch(error => {
            //console.log(error)
        });
    }

    getConversation() {
        let apiName = 'ChatService';
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'code': this.state.code,
                'command': 'getConversationForEventsites',
                'p1': global.$userid,
                'p2': this.state.userid,
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }

        //console.log(myInit);
        //console.log('ChatMessages:getMessages()');

        API.post(apiName, path, myInit).then(response => {
            //console.log(response);

            if (response.err == null && response.data != null) {
                //console.log(response.data);
                if (response.data == "create") {
                    if (global.$userid == this.state.userid) {
                        alert("Unable to send chat invitation");
                    }
                    else if (window.confirm('Do you want to send chat invitation?')) {
                        //console.log("Yes")
                        this.createConversation();
                    }
                }
                else {
                    if (response.data.Item.cstatus.S == "A") {
                        this.onOpenPopup();
                    }
                    else if (response.data.Item.cstatus.S == "I") {
                        alert("Chat invitation has been already sent");
                    }
                    else if (response.data.Item.cstatus.S == "D") {
                        alert("Your chat invitation has been declined");
                    }
                }
            }
            else {
                //console.log(response.err);
            }
        }
        ).catch(error => {
            //console.log(error)
        });
    }

    displayAttendeeBadgeLevel() {
        //console.log(global.$points);
        //console.log(global.$points.attendeebadgelevels.levels.gold);
        var levels = global.$points.attendeebadgelevels.levels;
        if (Number(this.props.attendee.totalpoints) >= Number(levels.platinum.minpoints) && Number(this.props.attendee.totalpoints) <= Number(levels.platinum.maxpoints)) {
            return (
                <div className="attendee2-badge-level-platinum" style={levels.platinum.styles}>
                    {levels.platinum.title} <Icon path={mdiStar} size={0.9} className="attendee-badge-level-icon" />{/*this.props.attendee.totalpoints*/}
                </div>
            )
        }
        else {
            if (Number(this.props.attendee.totalpoints) >= Number(levels.gold.minpoints) && Number(this.props.attendee.totalpoints) <= Number(levels.gold.maxpoints)) {
                return (
                    <div className="attendee2-badge-level-gold" style={levels.gold.styles}>
                        {levels.gold.title} <Icon path={mdiStar} size={0.9} className="attendee-badge-level-icon" />{/*this.props.attendee.totalpoints*/}
                    </div>
                )
            }
            else {
                if (Number(this.props.attendee.totalpoints) >= Number(levels.silver.minpoints) && Number(this.props.attendee.totalpoints) <= Number(levels.silver.maxpoints)) {
                    return (
                        <div className="attendee2-badge-level-silver" style={levels.silver.styles}>
                            {levels.silver.title} <Icon path={mdiStar} size={0.9} className="attendee-badge-level-icon" />{/*this.props.attendee.totalpoints*/}
                        </div>
                    )
                }
                else {
                    if (Number(this.props.attendee.totalpoints) >= Number(levels.bronze.minpoints) && Number(this.props.attendee.totalpoints) <= Number(levels.bronze.maxpoints)) {
                        return (
                            <div className="attendee2-badge-level-bronze" style={levels.bronze.styles}>
                                {levels.bronze.title} <Icon path={mdiStar} size={0.9} className="attendee-badge-level-icon" />{/*this.props.attendee.totalpoints*/}
                            </div>
                        )
                    }
                }
            }
        }
    }

}
export default DelegateProfile2;
