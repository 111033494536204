/* eslint-disable */

import React from 'react';
import { Row, Col } from 'reactstrap';
import LoadPrivateImg from '../Components/Shared/LoadPrivateImg';
import { API } from 'aws-amplify';
const moment = require('moment-timezone');
var serviceNames = require('../ServiceNameMapper');
var itemBids = 
[

];

class ListBids extends React.Component {
    constructor(props) {
       //console.log(props)

        super(props);
        this.state = {
            code: props.code,
            itemid: props.itemid,
            bidusernamestyle: global.$auctions.styles.bid.username,
            biddatestyle: global.$auctions.styles.bid.date,
            bidamountstyle: global.$auctions.styles.bid.amount,
            itemBids: itemBids
        };
    }

    render() {
            if (this.state.dataloaded) {
                return ( <div>
                    {this.displayItemBids()}
                </div>
                )

            }
            else {
                this.getAuctionItemBids()
                return (
                    <div>
                        <Row>
                            <Col md={6} className="offset-5">
                                <div className="loading-div">
                                    <div className="loading-text">Loading....</div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                )
            }
    }
    
    displayItemBids() {

        return this.state.itemBids.slice(0, 5).map(auctionbid => {

            //console.log(auctionbid)
            //var date = new Date(this.state.bidid * 1000);
            //var dateFormatter = date.toDateString();

            var d = new Date(auctionbid.bidid * 1000);
            
            var dateFormatter = moment(d).format('MMM D, h:mm A');

            return (
                <div key={auctionbid.bidid}>
                    <div>
                        <Row>
                            <Col md={8}>
                                <div className="bids-section">
                                    <div className="bid-img">
                                        <LoadPrivateImg id={auctionbid.userid} component="bids" code={this.state.code} height="50" width="50" />
                                    </div>
                                    <div className="bidders-list">
                                        <p style={this.state.bidusernamestyle}>{auctionbid.username}</p>
                                        <p style={this.state.biddatestyle}>{dateFormatter}</p>
                                    </div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div>
                                    <h4 style={this.state.bidamountstyle}>${auctionbid.amount}</h4>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <hr className="bid-hr" />
                </div>
            )
        })
    }

    getAuctionBidsTableObjects(objs) 
    {
        const tableObjs = []

        for (var i = 0; i < objs.length; i++) {

            var auctionBidsObj = objs[i]

            var newObj = Object()

            newObj.bidid = auctionBidsObj.bidid.N
            newObj.amount = auctionBidsObj.amount.N
            newObj.itemid = auctionBidsObj.itemid.S
            newObj.userid = auctionBidsObj.userid.S
            newObj.username = auctionBidsObj.username.S

            tableObjs.push(newObj)
        }

        return tableObjs
    }
    
    getAuctionItemBids() {

        let apiName = serviceNames.getAuctionService();
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'code': this.state.code,
                'command': 'getBids',
                'itemid': this.state.itemid
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
       //console.log(myInit);

        API.post(apiName, path, myInit).then(response => {
            //console.log ("response.data ===> " + response.data + "response.err ===> " + response.err);
            //console.log(response);

            if (response.err == null && response.data != null) {
                //console.log("getAuctionItemsTableObjects - Success!!");
                //console.log(response.data.Items);

                itemBids = this.getAuctionBidsTableObjects(response.data.Items)

                //global.$auctionbidsjson = auctionBids;
               //console.log(auctionBids.userid);

                this.setState({
                    dataloaded: true,
                    itemBids : itemBids
                })

               //console.log(this.state)
            }
            else {
                //console.log("getAuctionItemsTableObjects - failed!!");
                //console.log(response.err);
            }
        }
        ).catch(error => {
            //console.log(error)
        });
    }
}
export default ListBids;
