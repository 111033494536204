import React from 'react';
import { Link } from 'react-router-dom';
import SessionRegistration from './SessionRegistration';

const moment = require('moment-timezone');
class ScheduleWatchNow extends React.Component {
    constructor(props) {
        super(props);
        //console.log(props.schedule);
        var sessionlayout = 'sessioninfo1';
        if (global.$schedule.sessioninfo2 == null) {
            sessionlayout = "sessioninfo1";
        }
        else {
            if (global.$schedule.sessioninfo2.enable == "Y") {
                sessionlayout = "sessioninfo2";
            }
            else {
                sessionlayout = "sessioninfo1";
            }
        }

        this.state = {
            code: global.$code,
            s3: global.$s3,
            bucket: global.$s3bucket,
            dataloaded: false,
            stag: props.stag,
            activetab: props.day,
            videoiconstyles: global.$schedule.styles.videoicon,
            sessionlayout: sessionlayout
        };
        //console.log("timezone current time = "+this.props.currenttime,"session start time = "+this.props.sessionstarttime, (this.props.curenttime) <= (this.props.sessionendtime))
    }

    isSessionLive() {
        //console.log(`ScheduleWatchNow: isSessionLive: current = ${this.props.currenttime}, start = ${this.props.sessionstarttime}, end = ${this.props.sessionendtime}` );

        var mode = global.$mode
        

        if (mode == "live") {
    
            if ((this.props.currenttime) >= (this.props.sessionstarttime) && (this.props.currenttime) <= (this.props.sessionendtime)) {
                //console.log('ScheduleWatchNow.isSessionLive - Live');
                return true;
            }
            else {
                //console.log('ScheduleWatchNow.isSessionLive - Not Live');
                return false;
            }
        }
        else if (mode == "preview") {
            return true;
        }
        else if (mode == "dryrun") {
            var eventdate = moment(this.props.sessionstarttime).format('L');
            var currenttime = moment().tz(`${global.$settings.timezone}`).format('LTS');

            var dateformat = eventdate + " " + currenttime

            //console.log('ScheduleWatchNow.isSessionLive - Adjusted Time ' + dateformat);

            var timeformat = Date.parse(dateformat);
            if ((timeformat) >= (this.props.sessionstarttime) && (timeformat) <= (this.props.sessionendtime)) {
                //console.log('ScheduleWatchNow.isSessionLive - Live');
                return true;
            }
            else {
                //console.log('ScheduleWatchNow.isSessionLive - Not Live');
                return false;
            }
        }
        else if (mode == "ondemand") {
            return false;
        }
    }

    render() {
        if (this.props.schedule.TYPE !== "L") // should not show if the session is without details
        {

            if (this.props.schedule.ATTENDEELINK != "" || this.props.schedule.SESSIONVIDEOLINK != "" || this.props.schedule.PRESESSIONLINK != "" || global.$session.defaultprevideo !== "" || global.$session.defaultpostvideo !== "") {
                return (
                    <>
                        {
                            global.$loginstatus.includes("true") ?
                                (
                                    <>

                                        {
                                            (this.props.schedule.DATA2 != null && this.props.schedule.DATA2.includes("virtualexpo")) ?
                                                (
                                                    <div className="schedule-live-btn-div-wrapper">
                                                        <Link to={`/VirtualExpoRoom`} style={this.state.videoiconstyles}>Virtual Expo</Link>
                                                        {(() => {
                                                            if (this.isSessionLive() ) {
                                                                //console.log("spinner")
                                                                return (
                                                                    <div className="virtualexpo-live-btn">
                                                                        {/*<i className='fas fa-circle live-button-schedule'><p style={{color: "white"}} className="live-text-schedule">LIVE</p></i>*/}
                                                                        <p style={{ color: "white" }} className="live-text-schedule">LIVE</p>
                                                                    </div>
                                                                )
                                                            }
                                                        })()}

                                                    </div>
                                                )
                                                :
                                                (
                                                    <>
                                                        {
                                                            this.state.sessionlayout.includes("sessioninfo2") ?
                                                                (
                                                                    <div className="schedule-live-btn-div-wrapper">
                                                                        {
                                                                            (this.props.schedule.ATTENDEELINK != "" || this.props.schedule.SESSIONVIDEOLINK != "") && this.props.action === 'watchnow' ?
                                                                                (
                                                                                    <Link to={`/SessionInfo2/${this.props.schedule.SID + "==" + this.props.schedule.STAG + '==' + this.props.activetab + '==' + this.props.type}`} style={this.state.videoiconstyles}><i className="fa fa-video-camera" style={{ paddingRight: "10px" }}></i>Watch Now</Link>
                                                                                )
                                                                                :
                                                                                ('')
                                                                        }
                                                                        {
                                                                            this.props.schedule.STAG == "ondemand" ?
                                                                                (
                                                                                    ''
                                                                                )
                                                                                :
                                                                                (
                                                                                    <div>
                                                                                        {(() => {
                                                                                            if (this.isSessionLive() && this.props.action === 'live') {
                                                                                                //console.log("spinner")
                                                                                                return (
                                                                                                    <div className="live-button-schedule">
                                                                                                        {/*<i className='fas fa-circle live-button-schedule'><p style={{color: "white"}} className="live-text-schedule">LIVE</p></i>*/}
                                                                                                        <p style={{ color: "white" }} className="live-text-schedule">LIVE</p>
                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                        })()}
                                                                                    </div>
                                                                                )
                                                                        }
                                                                    </div>
                                                                )
                                                                :
                                                                (
                                                                    
                                                                    <div className="schedule-live-btn-div-wrapper">
                                                                        {
                                                                            (this.props.schedule.ATTENDEELINK != ""|| this.props.schedule.SESSIONVIDEOLINK != "") && this.props.action === 'watchnow'  ?
                                                                                (
                                                                                    <div className="schedule-live-btn-div">
                                                                                        <Link to={`/SessionInfo/${this.props.schedule.SID + "==" + this.props.schedule.STAG + '==' + this.props.activetab + '==' + this.props.type}`} style={this.state.videoiconstyles}><i className="fa fa-video-camera" style={{ paddingRight: "10px" }}></i>Watch Now</Link>
                                                                                    </div>)
                                                                                :
                                                                                ('')
                                                                        }
                                                                        {
                                                                            this.props.schedule.STAG == "ondemand" ?
                                                                                (
                                                                                    ''
                                                                                )
                                                                                :
                                                                                (
                                                                                    <>
                                                                                        {(() => {
                                                                                            if (this.isSessionLive() && this.props.action === 'live') {
                                                                                                //console.log("spinner")
                                                                                                return (
                                                                                                    <div className="live-button-schedule">
                                                                                                        {/*<i className='fas fa-circle live-button-schedule'><p style={{color: "white"}} className="live-text-schedule">LIVE</p></i>*/}
                                                                                                        <p style={{ color: "white" }} className="live-text-schedule">LIVE</p>
                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                        })()}
                                                                                    </>
                                                                                )
                                                                        }
                                                                    </div>
                                                                )
                                                        }
                                                    </>
                                                )
                                        } 
                                        
                                    </>
                                )
                                :
                                (
                                    <>
                                        {
                                            (this.props.schedule.DATA2 != null && this.props.schedule.DATA2.includes("virtualexpo")) ?
                                                (
                                                    <div className="schedule-live-btn-div-wrapper">
                                                        <Link to={`/LogInForm`} style={this.state.videoiconstyles}>Virtual Expo</Link>
                                                        {(() => {
                                                            if (this.isSessionLive() && this.props.action === 'live') {
                                                                
                                                                return (
                                                                    <div className="live-button-schedule">
                                                                        {/*<i className='fas fa-circle live-button-schedule'><p style={{color: "white"}} className="live-text-schedule">LIVE</p></i>*/}
                                                                        <p style={{ color: "white" }} className="live-text-schedule">LIVE</p>
                                                                    </div>
                                                                )
                                                            }
                                                        })()}


                                                    </div>

                                                )
                                                :
                                                (

                                                    <div className="schedule-live-btn-div-wrapper">
                                                        {
                                                            (this.props.schedule.ATTENDEELINK != "" || this.props.schedule.SESSIONVIDEOLINK != "") && this.props.action === 'watchnow' ?
                                                                (
                                                                    <div className="schedule-live-btn-div">
                                                                        <Link to={`/LogInForm`} style={this.state.videoiconstyles}><i className="fa fa-video-camera" style={{ paddingRight: "10px" }}></i>Watch Now</Link>
                                                                    </div>    
                                                                )
                                                                :
                                                                ('')
                                                        }
                                                        {
                                                            this.props.schedule.STAG == "ondemand" ?
                                                                (
                                                                    ''
                                                                )
                                                                :
                                                                (
                                                                    <>
                                                                        {(() => {
                                                                           
                                                                            if (this.isSessionLive() && this.props.action === 'live') {
                                                                                
                                                                                return (
                                                                                    <div className="live-button-schedule  without-login-live">
                                                                                        {/*<i className='fas fa-circle live-button-schedule'><p style={{color: "white"}} className="live-text-schedule">LIVE</p></i>*/}
                                                                                        <p style={{ color: "white" }} className="live-text-schedule">LIVE</p>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        })()}
                                                                    </>
                                                                )
                                                        }


                                                    </div>
                                                )
                                        }
                                    </>
                                )
                        }
                    </>
                )
            }
            else {
                return <></>
            }
        }
        else {
            return <></>
        }
    }

}
export default ScheduleWatchNow;


