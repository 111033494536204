 import React from 'react';
import { Col, Row, FormGroup, Label, Input, Button } from 'reactstrap';
import Icon from '@mdi/react';
import { mdiAccountTie, mdiDomain, mdiFormatTitle, mdiGoogleStreetView, mdiMapMarker, mdiZipBox, mdiMap, mdiGlobeModel, mdiEmail, mdiCellphoneIphone, mdiPhone, mdiPlus, mdiLinkedin, mdiFacebook, mdiTwitter, mdiOfficeBuilding, mdiCity, mdiSkiCrossCountry, mdiDatabase, mdiLocationEnter, mdiIdCard, mdiStarThreePoints, mdiWeb, mdiReproduction } from '@mdi/js';
import { RegionDropdown, CountryDropdown } from 'react-country-region-selector';
import APIFunctions from '../Common/APIFunctions';

class AttendeeInfo extends React.Component {
    constructor(props) {
        super(props);
        //console.log(props);
        var country = 'United States';

        if (global.$registrationform.country != null) {
            country = global.$registrationform.country
        }

        var enableshowdirectory = false;
        var showdirectoryquestion = "I would like to show my details in attendee list";
        var defaultshowdirectory = true;
        //console.log(global.$registrationform.showdirectory)
        if (global.$registrationform.showdirectory != null && global.$registrationform.showdirectory.enable == true) {
            enableshowdirectory = global.$registrationform.showdirectory.enable;
            showdirectoryquestion = global.$registrationform.showdirectory.text;
            defaultshowdirectory = global.$registrationform.showdirectory.default;

        }
        //console.log(showdirectoryquestion, enableshowdirectory)

        this.state = {
            userid: '',
            fname: '',
            lname: '',
            mname: '',
            company: '',
            title: '',
            phone: '',
            email: '',
            confirmemail:'',
            linkedin: '',
            twitter: '',
            facebook: '',
            country: country,
            state: '',
            city: '',
            street: '',
            zip: '',
            regid: '',
            regtype: '',
            photo: '',
            memberid: '',
            vpoints: '0',
            apoints: '0',
            tpoints: '0',
            regstatus: '',
            regtype: '',
            spoints: '0',
            myexpertise: '',
            myinterests: '',
            officephone: '',
            showdirectory: defaultshowdirectory,
            location: '',
            lastseen: '',
            poolid: global.$poolid,
            submittedby: '',
            udate: '',
            validscans: '',
            password: '',
            url: '',
            service: '',
            desc: '',
            locationtime: '',
            signindate: '',
            sessions: '',
            checkinstatus: '',
            regnotes: '',
            data1: '',
            data2: '',
            data3: '',
            data4: '',
            data5: '',
            q1: "",
            q2: "",
            q3: "",
            q4: "",
            q5: "",
            q6: "",
            q7: "",
            q8: "",
            userstype: '',
            guests: "",
            sessions: "",
            regnotes: "",
            checkinstatus: "",
            mname: "",
            totalamount: "0",
            amountpaid: "0",
            promoamount: "0",
            pendingamount: "0",
            refundamount: "0",
            paymentmode: "",
            promocodes: "",
            guestname: "",
            hideshowdirectory: !enableshowdirectory,
            showdirectoryquestion: showdirectoryquestion
        };
        this.selectCountry = this.selectCountry.bind(this);
        this.selectRegion = this.selectRegion.bind(this);
        this.selectResidentRegion = this.selectResidentRegion.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCheckbox = this.handleCheckbox.bind(this);


        //console.log(global.$loggedinuser);
    }

    handleCheckbox = (event) => {
        const target = event.target;
        const name = target.name;
        let value = target.value

        if ([name] == "showdirectory") {
            this.setState({ [name]: !this.state.showdirectory });
        }
    }

    selectCountry(val) {
        this.setState({ country: val });
    }

    selectRegion(val) {
        this.setState({ state: val })
    }
    selectResidentRegion(val) {
        this.setState({ data2: val })
    }

    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        let value = target.value

        //console.log("Target = " + name + ", Value = " + value);

        if (name == "email") {
            global.$regemail = value;
        }
        if (name == "confirmemail") {
            global.$confirmemail = value;
        }
        this.setState({ [name]: value });

    }
    render() {
        //console.log(global.$loggedinuser);
        global.$regattendeeinfo = this.state;
        //console.log(global.$regattendeeinfo)

        var personal = '';
        var work = '';
        var contact = '';
        var social = '';
        var other = '';
        var styles = {};
        
        if (this.props.type == "tab1") {
            personal = global.$registrationform.tab1.personal;
            work = global.$registrationform.tab1.work;
            contact = global.$registrationform.tab1.contact;
            social = global.$registrationform.tab1.social;
            other = global.$registrationform.tab1.other;
            styles = global.$registrationform.styles.tab1.fieldlabels
        }
        else if (this.props.type == "tab2") {
            personal = global.$registrationform.tab2.personal;
            work = global.$registrationform.tab2.work;
            contact = global.$registrationform.tab2.contact;
            social = global.$registrationform.tab2.social;
            other = global.$registrationform.tab2.other;
            styles = global.$registrationform.styles.tab2.fieldlabels
        }
        else if (this.props.type == "tab3") {
            personal = global.$registrationform.tab3.personal;
            work = global.$registrationform.tab3.work;
            contact = global.$registrationform.tab3.contact;
            social = global.$registrationform.tab3.social;
            other = global.$registrationform.tab3.other;
            styles = global.$registrationform.styles.tab3.fieldlabels
        }
        else if (this.props.type == "tab4") {
            personal = global.$registrationform.tab4.personal;
            work = global.$registrationform.tab4.work;
            contact = global.$registrationform.tab4.contact;
            social = global.$registrationform.tab4.social;
            other = global.$registrationform.tab4.other;
            styles = global.$registrationform.styles.tab4.fieldlabels
        }
        else if (this.props.type == "tab5") {
            personal = global.$registrationform.tab5.personal;
            work = global.$registrationform.tab5.work;
            contact = global.$registrationform.tab5.contact;
            social = global.$registrationform.tab5.social;
            other = global.$registrationform.tab5.other;
            styles = global.$registrationform.styles.tab5.fieldlabels
        }

        if (personal['confirmemail']?.validation) {
            global.$confirmemailcheck = true;
        }
        
        if (this.state.dataloaded) {

            if (this.state.showdirectory == "" || this.state.showdirectory == " ") {
                this.state.showdirectory = false;
            }
            
            return (
                <div>
                    
                    {
                        this.props.type == "tab1" ?
                            (
                                <div>
                                    <h4 className="reg-option-section" style={global.$registrationform.styles.tab1.header}>{global.$registrationform.tab1.header}</h4>
                                    <div dangerouslySetInnerHTML={{ __html: global.$registrationform.tab1.description }}></div>
                                </div>
                            )
                            :
                            ('')
                    }
                    {
                        this.props.type == "tab2" ?
                            (
                                <div>
                                    <h4 className="reg-option-section" style={global.$registrationform.styles.tab2.header}>{global.$registrationform.tab2.header}</h4>
                                    <div dangerouslySetInnerHTML={{ __html: global.$registrationform.tab2.description }}></div>
                                </div>
                            )
                            :
                            ('')
                    }
                    {
                        this.props.type == "tab3" ?
                            (
                                <div>
                                    <h4 className="reg-option-section" style={global.$registrationform.styles.tab3.header}>{global.$registrationform.tab3.header}</h4>
                                    <div dangerouslySetInnerHTML={{ __html: global.$registrationform.tab3.description }}></div>
                                </div>
                            )
                            :
                            ('')
                    }
                    {
                        this.props.type == "tab4" ?
                            (
                                <div>
                                    <h4 className="reg-option-section" style={global.$registrationform.styles.tab4.header}>{global.$registrationform.tab4.header}</h4>
                                    <div dangerouslySetInnerHTML={{ __html: global.$registrationform.tab4.description }}></div>
                                </div>
                            )
                            :
                            ('')
                    }
                    {
                        this.props.type == "tab5" ?
                            (
                                <div>
                                    <h4 className="reg-option-section" style={global.$registrationform.styles.tab5.header}>{global.$registrationform.tab5.header}</h4>
                                    <div dangerouslySetInnerHTML={{ __html: global.$registrationform.tab5.description }}></div>
                                </div>
                            )
                            :
                            ('')
                    }
                    {
                        personal.required == true ?
                            (
                                <div className="add-attendee-form">
                                    {this.getFieldsUI(personal, styles)}
                                </div>
                            )
                            :
                            ('')
                    }
                    {
                        work.required == true ?
                            (
                                <div className="add-attendee-form">
                                    {this.getFieldsUI(work, styles)}
                                </div>
                            )
                            :
                            ('')
                    }
                    {
                        contact.required == true ?
                            (
                                <div className="add-attendee-form">
                                    {this.getFieldsUI(contact, styles)}
                                </div>
                            )
                            :
                            ('')
                    }
                    {
                        social.required == true ?
                            (
                                <div className="add-attendee-form">
                                    {this.getFieldsUI(social, styles)}
                                </div>
                            )
                            :
                            ('')
                    }
                    {
                        other.required == true ?
                            (
                                <div className="add-attendee-form">
                                    {this.getFieldsUI(other, styles)}
                                </div>
                            )
                            :
                            ('')
                    }

                    <FormGroup className="form-labels offset-md-1 attendee-info-show-directory" hidden={this.state.hideshowdirectory}>
                        <Label>
                            <Input type="checkbox" id="showdirectory" name="showdirectory" checked={this.state.showdirectory} value={this.state.showdirectory} onChange={this.handleCheckbox} /><div dangerouslySetInnerHTML={{ __html: this.state.showdirectoryquestion }}></div>
                        </Label>
                    </FormGroup>
                </div>
            )
        }
        else {
            if (global.$loggedinuser != null) {
                var user = global.$loggedinuser;
                //console.log(user);
                this.setState({
                    userid: user.userid,
                    fname: user.fname,
                    lname: user.lname,
                    mname: user.mname,
                    company: user.company,
                    title: user.title,
                    phone: user.phone,
                    email: user.email,
                    linkedin: user.linkedin,
                    twitter: user.twitter,
                    facebook: user.fb,
                    country: user.country,
                    state: user.state,
                    city: user.city,
                    street: user.street,
                    zip: user.zip,
                    regid: user.regid,
                    regtype: user.regtype,
                    photo: user.photo,
                    memberid: user.memberid,
                    vpoints: user.visitpoints,
                    apoints: user.activitypoints,
                    tpoints: user.totalpoints,
                    regstatus: user.regstatus,
                    regtype: user.regtype,
                    spoints: user.sessionpoints,
                    myexpertise: user.myexpertise,
                    myinterests: user.myinterests,
                    officephone: user.officephone,
                    showdirectory: user.showdirectory,
                    location: user.location,
                    lastseen: user.lastseen,
                    poolid: user.poolid,
                    submittedby: user.submittedby,
                    udate: user.udate,
                    validscans: user.validscans,
                    password: user.password,
                    dataloaded: true,
                    locationtime: user.locationtime,
                    signindate: user.signindate,
                    guests: user.guests,
                    sessions: user.sessions,
                    checkinstatus: user.checkinstatus,
                    regnotes: user.regnotes,
                    data1: user.data1,
                    data2: user.data2,
                    data3: user.data3,
                    data4: user.data4,
                    data5: user.data5,
                    totalamount: user.totalamount,
                    pendingamount: user.pendingamount,
                    refundamount: user.refundamount,
                    paymentmode: user.paymentmode,
                    promocodes: user.promocodes,
                    amountpaid: user.amountpaid,
                    promoamount: user.promoamount,
                    guestname: user.guestname,
                    userstype: user.userstype,
                    regitemsjson: user.regitemsjson
                });
                if (global.$loggedinexhibitor != null) {
                    this.setState({ url: global.$loggedinexhibitor.url })
                }
                else {
                    this.getExhibitor()
                }
            }
            else {
                this.setState({ dataloaded: true });
            }
            return null;
        }
    }

    async getExhibitor() {
        var exhibitorObj = await APIFunctions.getLoggedinExhibitor(global.$userid);
        
        if (exhibitorObj != null) {
            global.$loggedinexhibitor = exhibitorObj;
            this.setState({ url: global.$loggedinexhibitor.url })
        }
        
    }

    getFieldsUI(section, styles) {

        return (
            <div>
                
                {
                    this.props.type == "tab1" ?
                        (
                            <h5 className="reg-option-section" style={global.$registrationform.styles.tab1.subheaders}>{section.header}</h5>
                        )
                        :
                        ('')
                }
                {
                    this.props.type == "tab2" ?
                        (
                            <h5 className="reg-option-section" style={global.$registrationform.styles.tab2.subheaders}>{section.header}</h5>
                        )
                        :
                        ('')
                }
                {
                    this.props.type == "tab3" ?
                        (
                            <h5 className="reg-option-section" style={global.$registrationform.styles.tab3.subheaders}>{section.header}</h5>
                        )
                        :
                        ('')
                }
                {
                    this.props.type == "tab4" ?
                        (
                            <h5 className="reg-option-section" style={global.$registrationform.styles.tab4.subheaders}>{section.header}</h5>
                        )
                        :
                        ('')
                }
                {
                    this.props.type == "tab5" ?
                        (
                            <h5 className="reg-option-section" style={global.$registrationform.styles.tab5.subheaders}>{section.header}</h5>
                        )
                        :
                        ('')
                }
                <div dangerouslySetInnerHTML={{ __html: section.description }}></div>
                <Row className="attendee-input-wrapper">
                    {
                        section.fname != null ?
                            (
                                <Col md={4} hidden={section.fname.hidden} style={{order:section.fname.position}}>
                                    <FormGroup className="form-labels">
                                        <Label for="fname" hidden={section.fname.hidden} style={styles} className="attendeeinfo-form-label"><Icon path={mdiAccountTie} size={0.9} color="lightgray" className="form-label-icon" hidden={section.fname.hidden} />{section.fname.text}<p className="attendee-info-required-symbol">{section.fname.requiredsymbol}</p></Label>
                                        <Input type="text" name="fname" className="form-control" id="fname" value={this.state.fname || ''} onChange={this.handleInputChange} hidden={section.fname.hidden} required={section.fname.required} placeholder={section.fname.placeholder ? section.fname.placeholder : ""}/>
                                    </FormGroup>
                                </Col>
                            ) 
                            :
                            ('')
                    }
                    {
                        section.mname != null ?
                            (
                                <Col md={4} hidden={section.mname.hidden} style={{order:section.mname.position}}>
                                    <FormGroup className="form-labels">
                                        <Label for="mname" hidden={section.mname.hidden} style={styles} className="attendeeinfo-form-label"><Icon path={mdiAccountTie} size={0.9} color="lightgray" className="form-label-icon" hidden={section.mname.hidden} />{section.mname.text}<p className="attendee-info-required-symbol">{section.mname.requiredsymbol}</p></Label>
                                        <Input type="text" name="mname" className="form-control" id="mname" value={this.state.mname || ''} onChange={this.handleInputChange} hidden={section.mname.hidden} required={section.mname.required} placeholder={section.mname.placeholder ? section.mname.placeholder : ""}/>
                                    </FormGroup>
                                </Col>
                            )
                            :
                            ('')
                    }
                    {
                        section.lname != null ?
                            (
                                <Col md={4} hidden={section.lname.hidden} style={{order:section.lname.position}}>
                                    <FormGroup className="form-labels">
                                        <Label for="lname" hidden={section.lname.hidden} style={styles} className="attendeeinfo-form-label"><Icon path={mdiAccountTie} size={0.9} color="lightgray" className="form-label-icon" hidden={section.lname.hidden} />{section.lname.text}<p className="attendee-info-required-symbol">{section.lname.requiredsymbol}</p></Label>
                                        <Input type="text" name="lname" className="form-control" id="" value={this.state.lname || ''} onChange={this.handleInputChange} hidden={section.lname.hidden} required={section.lname.required} placeholder={section.lname.placeholder ? section.lname.placeholder : ""}/>
                                    </FormGroup>
                                </Col>
                            )
                            :
                            ('')
                    }

                    {
                        global.$regloginstatus != null && global.$regloginstatus == true ?
                            (
                                section.email != null ?
                                    (
                                        <Col md={4} hidden={section.email.hidden} style={{order:section.email.position}}>
                                            <FormGroup className="form-labels">
                                                <Label for="email" hidden={section.email.hidden} style={styles} className="attendeeinfo-form-label"><Icon path={mdiEmail} size={0.9} color="lightgray" className="form-label-icon" hidden={section.email.hidden} />{section.email.text}<p className="attendee-info-required-symbol">{section.email.requiredsymbol}</p></Label>
                                                <Input type="email" name="email" className="form-control" id="reg-email" value={this.state.email || ''} onChange={this.handleInputChange} hidden={section.email.hidden} required={section.email.required} disabled placeholder={section.email.placeholder ? section.email.placeholder : ""}/>
                                            </FormGroup>
                                        </Col>
                                    )
                                    :
                                    ('')
                            )
                            :
                            (
                                section.email != null ?
                                    (
                                        <Col md={4} hidden={section.email.hidden} style={{order:section.email.position}}>
                                            <FormGroup className="form-labels">
                                                <Label for="email" hidden={section.email.hidden} style={styles} className="attendeeinfo-form-label"><Icon path={mdiEmail} size={0.9} color="lightgray" className="form-label-icon" hidden={section.email.hidden} />{section.email.text}<p className="attendee-info-required-symbol">{section.email.requiredsymbol}</p></Label>
                                                <Input type="email" name="email" className="form-control" id="reg-email" value={this.state.email || ''} onChange={this.handleInputChange} hidden={section.email.hidden} required={section.email.required} placeholder={section.email.placeholder ? section.email.placeholder : ""}/>
                                            </FormGroup>
                                        </Col>
                                    )
                                    :
                                    ('')
                            )
                    }
                    {
                        section.confirmemail != null ?
                        (
                            <Col md={4} hidden={section.confirmemail.hidden} style={{order:section.confirmemail.position}}>
                                <FormGroup className="form-labels">
                                    <Label for="confirmemail" hidden={section.confirmemail.hidden} style={styles} className="attendeeinfo-form-label"><Icon path={mdiEmail} size={0.9} color="lightgray" className="form-label-icon" hidden={section.confirmemail.hidden} />{section.confirmemail.text}<p className="attendee-info-required-symbol">{section.confirmemail.requiredsymbol}</p></Label>
                                    <Input type="email" name="confirmemail" className="form-control" id="reg-email" value={this.state.confirmemail || ''} onChange={this.handleInputChange} hidden={section.confirmemail.hidden} required={section.confirmemail.required} placeholder={section.confirmemail.placeholder ? section.confirmemail.placeholder : ""}/>
                                </FormGroup>
                            </Col>
                        ) : ('')
                    }
                    {
                        section.phone != null ?
                            (
                                <Col md={4} hidden={section.phone.hidden} style={{order:section.phone.position}}>
                                    <FormGroup className="form-labels">
                                        <Label for="phone" hidden={section.phone.hidden} style={styles} className="attendeeinfo-form-label"><Icon path={mdiPhone} size={0.9} color="lightgray" className="form-label-icon" hidden={section.phone.hidden} />{section.phone.text}<p className="attendee-info-required-symbol">{section.phone.requiredsymbol}</p></Label>
                                        <Input type="text" name="phone" className="form-control" id="" value={this.state.phone || ''} onChange={this.handleInputChange} hidden={section.phone.hidden} required={section.phone.required} placeholder={section.phone.placeholder ? section.phone.placeholder : ""}/>
                                    </FormGroup>
                                </Col>
                            )
                            :
                            ('')
                    }

                    {
                        section.linkedin != null ?
                            (
                                <Col md={4} hidden={section.linkedin.hidden} style={{order:section.linkedin.position}}>
                                    <FormGroup className="form-labels">
                                        <Label for="linkedin" hidden={section.linkedin.hidden} style={styles} className="attendeeinfo-form-label"><Icon path={mdiLinkedin} size={0.9} color="lightgray" className="form-label-icon" hidden={section.linkedin.hidden} />{section.linkedin.text}<p className="attendee-info-required-symbol">{section.linkedin.requiredsymbol}</p></Label>
                                        <Input type="text" name="linkedin" className="form-control" id="" value={this.state.linkedin || ''} onChange={this.handleInputChange} hidden={section.linkedin.hidden} required={section.linkedin.required}  placeholder={section.linkedin.placeholder ? section.linkedin.placeholder : ""}/>
                                    </FormGroup>
                                </Col>
                            )
                            :
                            ('')
                    }
                    {
                        section.facebook != null ?
                            (
                                <Col md={4} hidden={section.facebook.hidden} style={{order:section.facebook.position}}>
                                    <FormGroup className="form-labels">
                                        <Label for="facebook" hidden={section.facebook.hidden} style={styles} className="attendeeinfo-form-label"><Icon path={mdiFacebook} size={0.9} color="lightgray" className="form-label-icon" hidden={section.facebook.hidden} />{section.facebook.text}<p className="attendee-info-required-symbol">{section.facebook.requiredsymbol}</p></Label>
                                        <Input type="text" name="facebook" className="form-control" id="" value={this.state.facebook || ''} onChange={this.handleInputChange} hidden={section.facebook.hidden} required={section.facebook.required} placeholder={section.facebook.placeholder ? section.facebook.placeholder : ""}/>
                                    </FormGroup>
                                </Col>
                            )
                            :
                            ('')
                    }
                    {
                        section.twitter != null ?
                            (
                                <Col md={4} style={{order:section.twitter.position}}>
                                    <FormGroup className="form-labels">
                                        <Label for="twitter" hidden={section.twitter.hidden} style={styles} className="attendeeinfo-form-label"><Icon path={mdiTwitter} size={0.9} color="lightgray" className="form-label-icon" hidden={section.twitter.hidden} />{section.twitter.text}<p className="attendee-info-required-symbol">{section.twitter.requiredsymbol}</p></Label>
                                        <Input type="text" name="twitter" className="form-control" id="" value={this.state.twitter || ''} onChange={this.handleInputChange} hidden={section.twitter.hidden} required={section.twitter.required} placeholder={section.twitter.placeholder ? section.twitter.placeholder : ""}/>
                                    </FormGroup>
                                </Col>
                            )
                            :
                            ('')
                    }
                    {
                        section.company != null ?
                            (
                                <Col md={4} style={{order:section.company.position}}>
                                    <FormGroup className="form-labels" >
                                        <Label for="company" hidden={section.company.hidden} style={styles} className="attendeeinfo-form-label"><Icon path={mdiOfficeBuilding} size={0.9} color="lightgray" className="form-label-icon" hidden={section.company.hidden} />{section.company.text}<p className="attendee-info-required-symbol">{section.company.requiredsymbol}</p></Label>
                                        <Input type="text" name="company" className="form-control" id="" value={this.state.company || ''} onChange={this.handleInputChange} hidden={section.company.hidden} required={section.company.required} placeholder={section.company.placeholder ? section.company.placeholder : ""}/>
                                    </FormGroup>
                                </Col>
                            )
                            :
                            ('')
                    }
                    {
                        section.title != null ?
                            (
                                <Col md={4} style={{order:section.title.position}}>
                                    <FormGroup className="form-labels" >
                                        <Label for="title" hidden={section.title.hidden} style={styles} className="attendeeinfo-form-label"><Icon path={mdiFormatTitle} size={0.9} color="lightgray" className="form-label-icon" hidden={section.title.hidden} />{section.title.text}<p className="attendee-info-required-symbol">{section.title.requiredsymbol}</p></Label>
                                        <Input type="text" name="title" className="form-control" id="" value={this.state.title || ''} onChange={this.handleInputChange} hidden={section.title.hidden} required={section.title.required} placeholder={section.title.placeholder ? section.title.placeholder : ""}/>
                                    </FormGroup>
                                </Col>
                            )
                            :
                            ('')
                    }
                    {
                        section.officephone != null ?
                            (
                                <Col md={4} style={{order:section.officephone.position}}>
                                    <FormGroup className="form-labels"  >
                                        <Label for="officephone" hidden={section.officephone.hidden} style={styles} className="attendeeinfo-form-label"><Icon path={mdiPhone} size={0.9} color="lightgray" className="form-label-icon" hidden={section.officephone.hidden} />{section.officephone.text}<p className="attendee-info-required-symbol">{section.officephone.requiredsymbol}</p></Label>
                                        <Input type="text" name="officephone" className="form-control" id="" value={this.state.officephone || ''} onChange={this.handleInputChange} hidden={section.officephone.hidden} required={section.officephone.required} placeholder={section.officephone.placeholder ? section.officephone.placeholder : ""}/>
                                    </FormGroup>
                                </Col>
                            )
                            :
                            ('')
                    }
                    {
                        section.url != null ?
                            (
                                <Col md={4} style={{order:section.url.position}}>
                                    <FormGroup className="form-labels">
                                        <Label for="url" hidden={section.url.hidden} style={styles} className="attendeeinfo-form-label"><Icon path={mdiWeb} size={0.9} color="lightgray" className="form-label-icon" hidden={section.url.hidden} />{section.url.text}<p className="attendee-info-required-symbol">{section.url.requiredsymbol}</p></Label>
                                        <Input type="text" name="url" className="form-control" id="" value={this.state.url || ''} onChange={this.handleInputChange} hidden={section.url.hidden} required={section.url.required} placeholder={section.url.placeholder ? section.url.placeholder : ""}/>
                                    </FormGroup>
                                </Col>
                            )
                            :
                            ('')
                    }
                    {
                        section.service != null ?
                            (
                                <Col md={4} style={{order:section.service.position}}>
                                    <FormGroup className="form-labels" >
                                        <Label for="service" hidden={section.service.hidden} style={styles} className="attendeeinfo-form-label"><Icon path={mdiDomain} size={0.9} color="lightgray" className="form-label-icon" hidden={section.service.hidden} />{section.service.text}<p className="attendee-info-required-symbol">{section.service.requiredsymbol}</p></Label>
                                        <Input type="text" name="service" className="form-control" id="" value={this.state.service || ''} onChange={this.handleInputChange} hidden={section.service.hidden} required={section.service.required} placeholder={section.service.placeholder ? section.service.placeholder : ""}/>
                                    </FormGroup>
                                </Col>
                            )
                            :
                            ('')
                    }
                    {
                        section.desc != null ?
                            (
                                <Col md={4} style={{order:section.desc.position}}>
                                    <FormGroup className="form-labels" >
                                        <Label for="desc" hidden={section.desc.hidden} style={styles} className="attendeeinfo-form-label"><Icon path={mdiDatabase} size={0.9} color="lightgray" className="form-label-icon" hidden={section.desc.hidden} />{section.desc.text}<p className="attendee-info-required-symbol">{section.desc.requiredsymbol}</p></Label>
                                        <Input type="textarea" maxlength="150" name="desc" className="form-control" id="" value={this.state.desc || ''} onChange={this.handleInputChange} hidden={section.desc.hidden} required={section.desc.required} placeholder={section.desc.placeholder ? section.desc.placeholder : ""}/>
                                    </FormGroup>
                                </Col>
                            )
                            :
                            ('')
                    }
                    {
                        section.street != null ?
                            (
                                <Col md={4} style={{order:section.street.position}}>
                                    <FormGroup className="form-labels" >
                                        <Label for="street" hidden={section.street.hidden} style={styles} className="attendeeinfo-form-label"><Icon path={mdiGoogleStreetView} size={0.9} color="lightgray" className="form-label-icon" hidden={section.street.hidden} />{section.street.text}<p className="attendee-info-required-symbol">{section.street.requiredsymbol}</p></Label>
                                        <Input type="text" name="street" className="form-control" id="" value={this.state.street || ''} onChange={this.handleInputChange} hidden={section.street.hidden} required={section.street.required} placeholder={section.street.placeholder ? section.street.placeholder : ""}/>
                                    </FormGroup>
                                </Col>
                            )
                            :
                            ('')
                    }
                    {
                        section.city != null ?
                            (
                                <Col md={4} style={{order:section.city.position}}>
                                    <FormGroup className="form-labels" >
                                        <Label for="city" hidden={section.city.hidden} style={styles} className="attendeeinfo-form-label"><Icon path={mdiCity} size={0.9} color="lightgray" className="form-label-icon" hidden={section.city.hidden} />{section.city.text}<p className="attendee-info-required-symbol">{section.city.requiredsymbol}</p></Label>
                                        <Input type="text" name="city" className="form-control" id="" value={this.state.city || ''} onChange={this.handleInputChange} hidden={section.city.hidden} required={section.city.required} placeholder={section.city.placeholder ? section.city.placeholder : ""}/>
                                    </FormGroup>
                                </Col>
                            )
                            :
                            ('')
                    }
                    {
                        section.state != null ?
                            (
                                <Col md={4} style={{order:section.state.position}}>
                                    <FormGroup className="form-labels" >
                                        <Label for="state" hidden={section.state.hidden} style={styles} className="attendeeinfo-form-label"><Icon path={mdiCity} size={0.9} color="lightgray" className="form-label-icon" hidden={section.state.hidden} />{section.state.text}<p className="attendee-info-required-symbol">{section.state.requiredsymbol}</p></Label>
                                        <RegionDropdown name="state" className="form-control" id="region" placeholder="Select State" country={this.state.country || ''} value={this.state.state || ''} onChange={(val) => this.selectRegion(val)} hidden={section.state.hidden} required={section.state.required} style={{ width:'100%' }}/>
                                    </FormGroup>
                                </Col>
                            )
                            :
                            ('')
                    }
                    {
                        section.zip != null ?
                            (
                                <Col md={4} style={{order:section.zip.position}}>
                                    <FormGroup className="form-labels" >
                                        <Label for="zip" hidden={section.zip.hidden} style={styles} className="attendeeinfo-form-label"><Icon path={mdiZipBox} size={0.9} color="lightgray" className="form-label-icon" hidden={section.zip.hidden} />{section.zip.text}<p className="attendee-info-required-symbol">{section.zip.requiredsymbol}</p></Label>
                                        <Input type="text" name="zip" className="form-control" id="" value={this.state.zip || ''} onChange={this.handleInputChange} hidden={section.zip.hidden} required={section.zip.required} placeholder={section.zip.placeholder ? section.zip.placeholder : ""}/>
                                    </FormGroup>
                                </Col>
                            )
                            :
                            ('')
                    }
                    {
                        section.country != null ?
                            (
                                <Col md={4} style={{order:section.country.position}}>
                                    <FormGroup className="form-labels" >
                                        <Label for="country" hidden={section.country.hidden} style={styles} className="attendeeinfo-form-label"><Icon path={mdiGlobeModel} size={0.9} color="lightgray" className="form-label-icon" hidden={section.country.hidden} />{section.country.text}<p className="attendee-info-required-symbol">{section.country.requiredsymbol}</p></Label>
                                        <CountryDropdown name="country" className="form-control" id="country" value={this.state.country || ''} onChange={(val) => this.selectCountry(val)} hidden={section.country.hidden} required={section.country.required} style={{ width:'100%' }} placeholder={section.country.placeholder ? section.country.placeholder : ""}/>
                                    </FormGroup>
                                </Col>
                            )
                            :
                            ('')
                    }
                    {
                        section.memberid != null ?
                            (
                                <Col md={4} style={{order:section.memberid.position}}>
                                    <FormGroup className="form-labels">
                                        <Label for="memberid" hidden={section.memberid.hidden} style={styles} className="attendeeinfo-form-label"><Icon path={mdiIdCard} size={0.9} color="lightgray" className="form-label-icon" hidden={section.memberid.hidden} />{section.memberid.text}<p className="attendee-info-required-symbol">{section.memberid.requiredsymbol}</p></Label>
                                        <Input type="text" name="memberid" className="form-control" id="" value={this.state.memberid || ''} onChange={this.handleInputChange} hidden={section.memberid.hidden} required={section.memberid.required} placeholder={section.memberid.placeholder ? section.memberid.placeholder : ""}/>
                                    </FormGroup>
                                </Col>
                            )
                            :
                            ('')
                    }
                    {
                        section.data1 != null ?
                            (                               
                                <Col md={4} style={{order:section.data1.position}}>
                                    <FormGroup className="form-labels" >
                                        <Label for="data1" hidden={section.data1.hidden} style={styles} className="attendeeinfo-form-label"><Icon path={mdiDatabase} size={0.9} color="lightgray" className="form-label-icon" hidden={section.data1.hidden} />{section.data1.text}<p className="attendee-info-required-symbol">{section.data1.requiredsymbol}</p></Label>
                                        <Input type="text" name="data1" className="form-control" id="" placeholder={section.data1.placeholder ? section.data1.placeholder : ""} value={this.state.data1 || ''} onChange={this.handleInputChange} hidden={section.data1.hidden} required={section.data1.required} />
                                    </FormGroup>
                                </Col>
                            )
                            :
                            ('')
                    }
                    {
                        section.data2 != null ?
                            (
                                section.data2.statedropdown != null && section.data2.statedropdown == true ?
                                    (
                                        <Col md={4} style={{order:section.data2.position}}>
                                            <FormGroup className="form-labels" >
                                                <Label for="state" hidden={section.data2.hidden} style={styles} className="attendeeinfo-form-label"><Icon path={mdiCity} size={0.9} color="lightgray" className="form-label-icon" hidden={section.data2.hidden} />{section.data2.text}<p className="attendee-info-required-symbol">{section.data2.requiredsymbol}</p></Label>
                                                <RegionDropdown name="data2" className="form-control" id="region" placeholder="Select" country={this.state.country || ''} value={this.state.data2 || ''} onChange={(val) => this.selectResidentRegion(val)} hidden={section.data2.hidden} required={section.data2.required} />
                                            </FormGroup>
                                        </Col>
                                    )
                                    :
                                    (

                                        <Col md={4} style={{order:section.data2.position}}>
                                            <FormGroup className="form-labels">
                                                <Label for="data2" hidden={section.data2.hidden} style={styles} className="attendeeinfo-form-label"><Icon path={mdiDatabase} size={0.9} color="lightgray" className="form-label-icon" hidden={section.data2.hidden} />{section.data2.text}<p className="attendee-info-required-symbol">{section.data2.requiredsymbol}</p></Label>
                                                <Input type="text" name="data2" className="form-control" id="" value={this.state.data2 || ''} onChange={this.handleInputChange} hidden={section.data2.hidden} required={section.data2.required} placeholder={section.data2.placeholder ? section.data2.placeholder : ""}/>
                                            </FormGroup>
                                        </Col>
                                    )
                            )
                            :
                            ('')
                    }
                    {
                        section.data3 != null ?
                            (
                                <Col md={4} style={{order:section.data3.position}}>
                                    <FormGroup className="form-labels">
                                        <Label for="data3" hidden={section.data3.hidden} style={styles} className="attendeeinfo-form-label"><Icon path={mdiDatabase} size={0.9} color="lightgray" className="form-label-icon" hidden={section.data3.hidden} />{section.data3.text}<p className="attendee-info-required-symbol">{section.data3.requiredsymbol}</p></Label>
                                        <Input type="text" name="data3" className="form-control" id="" value={this.state.data3 || ''} onChange={this.handleInputChange} hidden={section.data3.hidden} required={section.data3.required} placeholder={section.data3.placeholder ? section.data3.placeholder : ""}/>
                                    </FormGroup>
                                </Col>
                            )
                            :
                            ('')
                    }
                    {
                        section.location != null ?
                            (
                                <Col md={4} style={{order:section.location.position}}>
                                    <FormGroup className="form-labels">
                                        <Label for="location" hidden={section.location.hidden} style={styles} className="attendeeinfo-form-label"><Icon path={mdiLocationEnter} size={0.9} color="lightgray" className="form-label-icon" hidden={section.location.hidden} />{section.location.text}<p className="attendee-info-required-symbol">{section.location.requiredsymbol}</p></Label>
                                        <Input type="text" name="location" className="form-control" id="" value={this.state.location || ''} onChange={this.handleInputChange} hidden={section.location.hidden} required={section.location.required} placeholder={section.location.placeholder ? section.location.placeholder : ""}/>
                                    </FormGroup>
                                </Col>
                            )
                            :
                            ('')
                    }
                    {
                        section.myexpertise != null ?
                            (
                                <Col md={4} style={{order:section.myexpertise.position}}>
                                    <FormGroup className="form-labels">
                                        <Label for="myexpertise" hidden={section.myexpertise.hidden} style={styles} className="attendeeinfo-form-label"><Icon path={mdiStarThreePoints} size={0.9} color="lightgray" className="form-label-icon" hidden={section.myexpertise.hidden} />{section.myexpertise.text}<p className="attendee-info-required-symbol">{section.myexpertise.requiredsymbol}</p></Label>
                                        <Input type="text" name="myexpertise" className="form-control" id="" value={this.state.myexpertise || ''} onChange={this.handleInputChange} hidden={section.myexpertise.hidden} required={section.myexpertise.required} placeholder={section.myexpertise.placeholder ? section.myexpertise.placeholder : ""}/>
                                    </FormGroup>
                                </Col>
                            )
                            :
                            ('')
                    }
                    {
                        section.myinterests != null ?
                            (
                                <Col md={4} style={{order:section.myinterests.position}} >
                                    <FormGroup className="form-labels">
                                        <Label for="myinterests" hidden={section.myinterests.hidden} style={styles} className="attendeeinfo-form-label"><Icon path={mdiStarThreePoints} size={0.9} color="lightgray" className="form-label-icon" hidden={section.myinterests.hidden} />{section.myinterests.text}<p className="attendee-info-required-symbol">{section.myinterests.requiredsymbol}</p></Label>
                                        <Input type="text" name="myinterests" className="form-control" id="" value={this.state.myinterests || ''} onChange={this.handleInputChange} hidden={section.myinterests.hidden} required={section.myinterests.required} placeholder={section.myinterests.placeholder ? section.myinterests.placeholder : ""}/>
                                    </FormGroup>
                                </Col>
                            )
                            :
                            ('')
                    }
                    {
                        section.guestname != null ?
                            (
                                <Col md={4} style={{order:section.guestname.position}}>
                                    <FormGroup className="form-labels">
                                        <Label for="guestname" hidden={section.guestname.hidden} style={styles} className="attendeeinfo-form-label"><Icon path={mdiAccountTie} size={0.9} color="lightgray" className="form-label-icon" hidden={section.guestname.hidden} />{section.guestname.text}<p className="attendee-info-required-symbol">{section.guestname.requiredsymbol}</p></Label>
                                        <Input type="text" name="guestname" className="form-control" id="" value={this.state.guestname || ''} onChange={this.handleInputChange} hidden={section.guestname.hidden} required={section.guestname.required} placeholder={section.guestname.placeholder ? section.guestname.placeholder : ""}/>
                                    </FormGroup>
                                </Col>
                            )
                            :
                            ('')
                    }
                    {
                        section.guests != null ?
                            (
                                <Col md={4} style={{order:section.guests.position}}>
                                    <FormGroup className="form-labels">
                                        <Label for="guests" hidden={section.guests.hidden} style={styles} className="attendeeinfo-form-label"><Icon path={mdiAccountTie} size={0.9} color="lightgray" className="form-label-icon" hidden={section.guests.hidden} />{section.guests.text}<p className="attendee-info-required-symbol">{section.guests.requiredsymbol}</p></Label>
                                        <Input type="text" name="guests" className="form-control" id="" value={this.state.guests || ''} onChange={this.handleInputChange} hidden={section.guests.hidden} required={section.guests.required} placeholder={section.guests.placeholder ? section.guests.placeholder : ""}/>
                                    </FormGroup>
                                </Col>
                            )
                            :
                            ('')
                    }
                </Row>
            </div>
        )
    }
}
export default AttendeeInfo;


