import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import CommonFunctions from './RegCommon/CommonFunctions';

class Terms extends React.Component {
    constructor(props) {
        super(props);
        //console.log(props);
        this.state = {
            code: global.$code,
            s3: global.$s3,
            bucket: global.$s3bucket,
            termstatus: false,
            additionaltermsstatus: false,
            additionalterms2status: false
        };
        if (global.$registrationform[this.props.tabid].terms.hide == false) {
            this.state.termstatus = true;
        }
    }

    handleRadioButtons() {
        this.setState({ termstatus: !this.state.termstatus });
    }
    render() {
        // Terms checkbox1
        var terms = '';

        // Terms checkbox2
        var additionalterms = "";

        // Terms checkbox3
        var additionalterms2 = "";

        if (CommonFunctions.activeTabIs(this.props.tabid, "tab1")) {
            if (global.$registrationform.tab1 && global.$registrationform.tab1.terms) {
                terms = global.$registrationform.tab1.terms;
                global.$terms1 = terms;
                
                if (global.$registrationform.tab1.additionalterms != null) {
                    additionalterms = global.$registrationform.tab1.additionalterms;
                    global.$terms2 = global.$registrationform.tab1.additionalterms;
                    global.$exhibitorterms2 = global.$registrationform.tab1.additionalterms;
                }
                if (global.$registrationform.tab1.additionalterms2 != null) {
                    additionalterms2 = global.$registrationform.tab1.additionalterms2;
                    global.$terms3 = global.$registrationform.tab1.additionalterms2;
                    global.$exhibitorterms3 = global.$registrationform.tab1.additionalterms2;
                }
            }    
        }
        else if (CommonFunctions.activeTabIs(this.props.tabid, "tab2")) {       
            if (global.$registrationform.tab2 && global.$registrationform.tab2.terms) {
                terms = global.$registrationform.tab2.terms;
                global.$terms1 = terms;
                if (global.$registrationform.tab2.additionalterms != null) {
                    additionalterms = global.$registrationform.tab2.additionalterms;
                    global.$terms2 = global.$registrationform.tab2.additionalterms;
                    global.$exhibitorterms2 = global.$registrationform.tab2.additionalterms;
                }
                if (global.$registrationform.tab2.additionalterms2 != null) {
                    additionalterms2 = global.$registrationform.tab2.additionalterms2;
                    global.$terms3 = global.$registrationform.tab2.additionalterms2;
                    global.$exhibitorterms3 = global.$registrationform.tab2.additionalterms2;
                }
            }
            
        }
        else if (CommonFunctions.activeTabIs(this.props.tabid, "tab3")) {
            if (global.$registrationform.tab3 && global.$registrationform.tab3.terms) {
                terms = global.$registrationform.tab3.terms;
                global.$terms1 = terms;
                if (global.$registrationform.tab3.additionalterms != null) {
                    additionalterms = global.$registrationform.tab3.additionalterms;
                    global.$terms2 = global.$registrationform.tab3.additionalterms;
                    global.$exhibitorterms2 = global.$registrationform.tab3.additionalterms;
                }
                if (global.$registrationform.tab3.additionalterms2 != null) {
                    additionalterms2 = global.$registrationform.tab3.additionalterms2;
                    global.$terms3 = global.$registrationform.tab3.additionalterms2;
                    global.$exhibitorterms3 = global.$registrationform.tab3.additionalterms2;
                }
            }
        }
        else if (CommonFunctions.activeTabIs(this.props.tabid, "tab4")) {
            if (global.$registrationform.tab4 && global.$registrationform.tab4.terms) {
                terms = global.$registrationform.tab4.terms;
                global.$terms1 = terms;
                if (global.$registrationform.tab4.additionalterms != null) {
                    additionalterms = global.$registrationform.tab4.additionalterms;
                    global.$terms2 = global.$registrationform.tab4.additionalterms;
                    global.$exhibitorterms2 = global.$registrationform.tab4.additionalterms;
                }
                if (global.$registrationform.tab4.additionalterms2 != null) {
                    additionalterms2 = global.$registrationform.tab4.additionalterms2;
                    global.$terms3 = global.$registrationform.tab4.additionalterms2;
                    global.$exhibitorterms3 = global.$registrationform.tab4.additionalterms2;
                }
            }
        }
        else if (CommonFunctions.activeTabIs(this.props.tabid, "tab5")) {
            if (global.$registrationform.tab5 && global.$registrationform.tab5.terms) {
                terms = global.$registrationform.tab5.terms;
                global.$terms1 = terms;
                if (global.$registrationform.tab5.additionalterms != null) {
                    additionalterms = global.$registrationform.tab5.additionalterms;
                    global.$terms2 = global.$registrationform.tab5.additionalterms;
                    global.$exhibitorterms2 = global.$registrationform.tab5.additionalterms;
                }
                if (global.$registrationform.tab5.additionalterms2 != null) {
                    additionalterms2 = global.$registrationform.tab5.additionalterms2;
                    global.$terms3 = global.$registrationform.tab5.additionalterms2;
                    global.$exhibitorterms3 = global.$registrationform.tab5.additionalterms2;
                }
            }
        }

        global.$terms1status = this.state.termstatus;
        global.$terms2status = this.state.additionaltermsstatus;
        global.$terms3status = this.state.additionalterms2status;
    

        return (
            <>
            
                { 
                    
                    global.$registrationform[this.props.tabid].terms.hide == null || global.$registrationform[this.props.tabid].terms.hide == false ?
                    (<div className="terms-div" style={global.$registrationform.styles.policy.maindiv}>
                        {this.renderSectionHeader(terms)}
                        {this.renderTerms1(terms)}
                        {this.renderTerms2(additionalterms)}
                        {this.renderTerms3(additionalterms2)}
                    </div>) : ('')
                }
            </>
        );
    }

    renderSectionHeader(terms) {
        return (
            <h4 className="reg-option-section" style={global.$registrationform.styles.policy.header}>{terms.header}</h4>
        )
    }
    renderTerms1(terms) {
        //console.log('renderTerms1')
        return (
            <div>
                <div dangerouslySetInnerHTML={{ __html: terms.description }}></div>
                <div className="term-question-div">
                    <input
                        type="checkbox"
                        className="terms-radio"
                        name="terms"
                        value={`${terms.question}`}
                        onChange={() => this.handleRadioButtons()}
                    />
                    <p className="term-question" style={global.$registrationform.styles.policy.fieldlabel}>{terms.question}</p>
                </div>
            </div>
        )
    }
    renderTerms2(additionalterms) {
        //console.log('renderTerms2')
        if (additionalterms != null && additionalterms != "" && additionalterms.required != null && additionalterms.required == true) {
            return (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: additionalterms.description }}></div>
                    <div className="term-question-div">
                        <input
                            type="checkbox"
                            className="terms-radio"
                            name="terms"
                            value={`${additionalterms.question}`}
                            onChange={() => this.setState({ additionaltermsstatus: !this.state.additionaltermsstatus })}
                        /><p className="term-question" style={global.$registrationform.styles.policy.fieldlabel}>{additionalterms.question}</p>
                    </div>
                </div>
            )
        }
    }
    renderTerms3(additionalterms2) {
        //console.log('renderTerms3')
        if (additionalterms2 != null && additionalterms2 != "" && additionalterms2.required != null && additionalterms2.required == true) {
            return (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: additionalterms2.description }}></div>
                    <div className="term-question-div">
                        <input
                            type="checkbox"
                            className="terms-radio"
                            name="terms"
                            value={`${additionalterms2.question}`}
                            onChange={() => this.setState({ additionalterms2status: !this.state.additionalterms2status })}
                        /><p className="term-question" style={global.$registrationform.styles.policy.fieldlabel}>{additionalterms2.question}</p>
                    </div>
                </div>
            )
        }
    }
}
export default Terms;


