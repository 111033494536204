import React from 'react';
import { FormGroup, Label, Input, FormText, Card, CardHeader, CardBlock, Row, Col, Progress } from 'reactstrap';
import Modal from "react-responsive-modal";
import VirtualPopupTabs from '../VirtualExpo/VirtualPopupTabs';
import Select from 'react-select';
import 'react-web-tabs/dist/react-web-tabs.css';
import 'react-responsive-modal/styles.css';
import { API, Analytics } from 'aws-amplify';
import './Sponsors.css';
import { Button } from 'react-bootstrap';
import SponsorSlider from './SponsorSlider';
var serviceNames = require('../ServiceNameMapper');

var sponsorjson =
    [

    ]
class ExhibitorLayout extends React.Component {
    constructor(props) {
        var videochat = "N";
        if (global.$sponsors.enablevideochat == null) {
            videochat = "N";
        }
        else {
            videochat = global.$sponsors.enablevideochat
        }

        var layout = 'list';
        //console.log(global.$exhibitors.layout);
        if (props.type == "sponsors") {
            if (global.$sponsors.layout == null) {
                layout = "list";
            }
            else {
                layout = global.$sponsors.layout;
            }
        }
        else if (props.type == "exhibitors") {
            if (global.$exhibitors.layout == null) {
                layout = "list";
            }
            else {
                layout = global.$exhibitors.layout;
            }
        }

        var imagemapimage = "";
        var imagemap = "";
        if (global.$exhibitors != null && global.$exhibitors.topbanner == "imagemap" && global.$exhibitors.layout == "both") {
            imagemapimage = global.$exhibitors.imagemaptabs.tabs.tab1.imagemap.image;
            imagemap = global.$exhibitors.imagemaptabs.tabs.tab1.imagemap;
        }
        var placeholdertext = "Filter by...";
        var filternote = "";
        var defaultfilter = "";
        var defaultSelectedCategory = [];
        if (global.$exhibitors.newlayout.filter != null) {
            placeholdertext = global.$exhibitors.newlayout.filter.placeholdertext;
            filternote = global.$exhibitors.newlayout.filter.filternote;
            if (global.$exhibitors.newlayout.filter.defaultfilter != "") {
                var options = []
                defaultfilter = global.$exhibitors.newlayout.filter.defaultfilter
                options = { value: global.$exhibitors.newlayout.filter.defaultfilter, label: global.$exhibitors.newlayout.filter.defaultfilter }
                defaultSelectedCategory.push(options);
            }
        }
        var exhibitorboothtitle = {}
        if (global.$exhibitors.newlayout.styles.exhibitors.boothtitle != null) {
            exhibitorboothtitle = global.$exhibitors.newlayout.styles.exhibitors.boothtitle
        }
        super(props);
        this.state =
        {
            type: props.type,
            bucket: props.bucket,
            dataloaded: false,
            search: '',
            sponsorheaderstyle: global.$sponsors.styles.categoryheader,
            sponsornamestyle: global.$sponsors.styles.name,
            sponsortitlestyle: global.$sponsors.styles.title,
            sponsorimagestyle: global.$sponsors.styles.image,
            sponsorboothstyle: global.$sponsors.styles.booth,
            sponsordescstyle: global.$sponsors.styles.desc,
            loadingtextstyles: global.$settings.loading.textstyle,
            loadingdivstyles: global.$settings.loading.divstyle,
            playvideobuttonstyles: global.$sponsors.playvideobuttonstyles,
            rowimagestyle: global.$sponsors.styles.rows.image,
            rowtextstyle: global.$sponsors.styles.rows.text,
            rowheaderstyle: global.$sponsors.styles.rows.header,
            leranmorestyle: global.$sponsors.styles.learnmore,
            leranmoredivstyle: global.$sponsors.styles.learnmorediv,
            enablevideochat: videochat,
            schedule: '',
            categorylist: [],
            selectedCategory: defaultSelectedCategory,
            selected: defaultfilter,
            alloptionsopen: false,
            filterplaceholder: placeholdertext,
            filternote: filternote,
            exhibitorboothtitle: exhibitorboothtitle
        }

        this.handleMultipleCategory = this.handleMultipleCategory.bind(this);
        var categories = global.$exhibitors.newlayout.categorieslist
        categories = categories.split(",");
        categories.map(category => {

            var options = []
            options = { value: category, label: category }

            this.state.categorylist.push(options);
        });
        //console.log(this.state.categorylist);
    }
    handleMultipleCategory(selectedCategory) {
        this.setState({ selectedCategory });
        var value = []
        if (selectedCategory == null) {
            selectedCategory = [];
        }
        if (selectedCategory.length == 0 || selectedCategory == null) {
            //console.log("zero length");
            this.setState({ selected: ' ' });
            selectedCategory.length = 0;
        }
        for (var i = 0; i < selectedCategory.length; i++) {
            //console.log(selectedCategory.length);
            var newObj = Object()
            newObj.value = selectedCategory[i].value
            value.push(newObj.value);
            //console.log(value)
            this.setState({ selected: value.toString() });
        }
    };

    onOpenAllOptions = () => {
        //console.log('onOpenAllOptions');
        this.setState(prevState => ({
            alloptionsopen: !prevState.alloptionsopen
        }));
    };

    render() {
        if (this.state.dataloaded) {
            return (
                <div>
                    <Row>
                        <Col md={10} className="offset-md-1">
                            {
                                global.$exhibitors.newlayout.enablesponsors == true ?
                                    (
                                        <div>
                                            <div className="sponsorpage-layout-header" style={global.$exhibitors.newlayout.styles.sponsorheader}>{global.$exhibitors.newlayout.sponsorheader}</div>
                                            <Row className="sponsorpage-layout-div">
                                                {this.displaySponsors()}
                                            </Row>
                                        </div>
                                    )
                                    :
                                    ('')
                            }
                            <div className="exhibitor-layout-header" style={global.$exhibitors.newlayout.styles.exhibitorheader}>{global.$exhibitors.newlayout.exhibitorheader}</div>
                            <div className="exhibitor-filter-div">
                                <FormGroup className="form-labels exhibitor-filter-group offset-md-3">
                                    <Select
                                        id="category"
                                        isMulti
                                        className="basic-multi-select exhibitor-filter-select"
                                        value={this.state.selectedCategory}
                                        options={this.state.categorylist}
                                        placeholder={this.state.filterplaceholder}
                                        onChange={this.handleMultipleCategory} />
                                    <FormText className="filter-note">{this.state.filternote}</FormText>
                                </FormGroup>
                            </div>
                            <div className="exhibitor-layout-div" style={{paddingBottom:'20px'}}>
                                <Row>
                                    {this.displayExhibitors()}
                                </Row>
                            </div>
                            <div className="exhibitor-layout-header" style={global.$exhibitors.newlayout.styles.exhibitorheader}>Event Vendors</div>
                            <div className="exhibitor-layout-div" style={{paddingTop:'40px'}}>
                                <Row>
                                    {this.displayEventVendor()}
                                </Row>
                            </div>


                            <Modal
                                open={this.state.alloptionsopen}
                                onClose={this.onOpenAllOptions}
                                blockScroll={false}
                                className="sponsor-popup"
                                styles={{
                                    modal: {
                                        width: "100%"
                                    }
                                }}
                                center>
                                <VirtualPopupTabs exhibitor={global.$sponsor} />
                            </Modal>
                        </Col>
                    </Row>
                </div>
            );
        }
        else {
            if (global.$exhibitors.newlayout.enablesponsors == true) {
                this.getSponsorjson();
            }
            else if (global.$exhibitors.newlayout.enablesponsorslider != null && global.$exhibitors.newlayout.enablesponsorslider == true) {
                this.getSponsorjson();
            }
            else {
                this.setState({ dataloaded: true })
            }

            return (
                <div>
                    <Row>
                        <Col md={6} className="offset-5">
                            <div className="loading-div">
                                <div className="loading-text">Loading Exhibitors....</div>
                            </div>
                        </Col>
                    </Row>
                </div>);
        }
    }

    displayExhibitors() {
        var results = [];
        //console.log(this.state.selected);
        var selected = this.state.selected
        if (selected != "" && selected != " " && selected != null) {
            selected = selected.split(",");
        }
        //console.log(selected);
        //console.log(global.$exhibitorjson);
        results = global.$exhibitorjson.filter(exhibitor => {
            //console.log(exhibitor.DATA3);
            if (selected == "" || selected == " ")
                return true;
            else {

                /*if (exhibitor.KEYWORDS.toLocaleLowerCase().includes(selected.toLocaleLowerCase())) {
                   //console.log("true");
                    return true;
                }
                else {
                   //console.log("false")
                    return false;
                }*/

                var categoryexist = false;

                selected.map(category => {
                    //console.log(category);
                    if (exhibitor.KEYWORDS.toLocaleLowerCase().includes(category.toLocaleLowerCase())) {
                        //console.log("true");
                        results.push(exhibitor);
                        categoryexist = true;
                        //return true;
                    }else{
                        console.log(exhibitor.data5);
                    }
                })
                //console.log(categoryexist);
                if (categoryexist == true) {
                    //console.log("true");
                    return true;
                }
                else if (categoryexist == false) {
                    //console.log("false");
                    return false;
                }
            }
            
        })

        const platinumSponsors = results.filter(sponsor => sponsor.DATA5.includes("Platinum"));
        const otherExhibitors = results.filter(sponsor => !sponsor.DATA5.includes("Platinum"));  
        let newResults = [];
        newResults = newResults.concat(platinumSponsors, otherExhibitors); 
      
        return newResults.map(sponsor => {
           
            var logo = "";
            logo = `https://s3.amazonaws.com/event360live/public/${global.$code}/exhibitors/` + sponsor.LOGO

            if (sponsor.DATA1 == "" && sponsor.VIDEO1 == "") {
                sponsor.DATA1 = "empty";
            }
            // sponsor.BOOTH !== "" valued exhibitors at the booth will not be shown here
            // T = category title
            if (sponsor.TYPE != "T" && sponsor.EXHIBITORTYPE != "S" && sponsor.BOOTH !== "") { 
                return (
                    <Col md={3}>
                        <div className="exhibitor-layout-card" style={global.$exhibitors.newlayout.styles.exhibitors.card} key={sponsor.ID}>
                            {sponsor.DATA5.includes("Platinum") && <span className='platinum-tag'>Platinum Sponsor</span>}
                            <div className="exhibitor-layout-imgdiv" style={global.$exhibitors.newlayout.styles.exhibitors.imgdiv}>
                                {
                                    sponsor.LOGO != "" ?
                                        (
                                            <a href={sponsor.URL} target="_blank"><img src={logo} style={global.$exhibitors.newlayout.styles.exhibitors.img} className="exhibitor-layout-img" /></a>
                                        )
                                        :
                                        ('')
                                }
                            </div>
                            <div className="exhibitor-layout-boothdiv">
                                {
                                    sponsor.BOOTH != "" && sponsor.BOOTH != " " ?
                                        (
                                            <p className="exhibitor-layout-boothtitle" style={this.state.exhibitorboothtitle}>{sponsor.BOOTH}</p>
                                        )
                                        :
                                        ('')
                                }
                            </div>
                            <div className="exhibitor-layout-buttondiv" style={global.$exhibitors.newlayout.styles.exhibitors.buttondiv}>
                                {
                                    sponsor.DATA1.includes("empty") ?
                                        (
                                            ''
                                        )
                                        :
                                        (
                                            <Button style={global.$exhibitors.newlayout.styles.exhibitors.button} className="exhibitor-layout-button" onClick={() => this.popup(sponsor)}>View Profile</Button>
                                        )
                                }
                            </div>
                        </div>
                    </Col>
                );
            }
            else {
                return null;
            }
        })
    }

    displayEventVendor(){
    
       return global.$exhibitorjson.filter(exhibitor => {
            return exhibitor.DATA3 === "eventvendors" 
        }).map((item) => {
            var logos = "";
            logos = `https://s3.amazonaws.com/event360live/public/${global.$code}/exhibitors/` + item.LOGO

            return(
                <Col md={3}>
                        <div className="exhibitor-layout-card" style={global.$exhibitors.newlayout.styles.exhibitors.card} key={item.ID}>
                            <div className="exhibitor-layout-imgdiv" style={global.$exhibitors.newlayout.styles.exhibitors.imgdiv}>
                                {
                                    item.LOGO != "" ?
                                        (
                                            <a href={item.URL} target="_blank"><img src={logos} style={global.$exhibitors.newlayout.styles.exhibitors.img} className="exhibitor-layout-img" /></a>
                                        )
                                        :
                                        ('')
                                }
                            </div>
                            <div className="exhibitor-layout-boothdiv">
                                {
                                    item.BOOTH != "" && item.BOOTH != " " ?
                                        (
                                            <p className="exhibitor-layout-boothtitle" style={this.state.exhibitorboothtitle}>{item.BOOTH}</p>
                                        )
                                        :
                                        ('')
                                }
                            </div>
                            <div className="exhibitor-layout-buttondiv" style={global.$exhibitors.newlayout.styles.exhibitors.buttondiv}>
                                {
                                    item.DATA1.includes("empty") ?
                                        (
                                            ''
                                        )
                                        :
                                        (
                                            <Button style={global.$exhibitors.newlayout.styles.exhibitors.button} className="exhibitor-layout-button" onClick={() => this.popup(item)}>View Profile</Button>
                                        )
                                }
                            </div>
                        </div>
                    </Col>
            )
        })
    }

    displaySponsors() {
        var results = [];
        results = global.$sponsorjson.filter(sponsor => {
            return true;
        })
        return results.map(sponsor => {
            //console.log(sponsor)
            var logo = "";
            logo = `https://s3.amazonaws.com/event360live/public/${global.$code}/exhibitors/` + sponsor.LOGO

            if (sponsor.DATA1 == "" && sponsor.VIDEO1 == "") {
                sponsor.DATA1 = "empty";
            }
            if (sponsor.TYPE != "T") {
                return (
                    <Col md={3}>
                        <div className="sponsorpage-layout-card" style={global.$exhibitors.newlayout.styles.sponsors.card} key={sponsor.ID}>
                            <div className="sponsorpage-layout-imgdiv" style={global.$exhibitors.newlayout.styles.sponsors.imgdiv}>
                                {
                                    sponsor.LOGO != "" ?
                                        (
                                            <a href={sponsor.URL} target="_blank"><img src={logo} style={global.$exhibitors.newlayout.styles.sponsors.img} className="sponsorpage-layout-img" /></a>
                                        )
                                        :
                                        ('')
                                }
                            </div>
                            <div className="sponsorpage-layout-buttondiv" style={global.$exhibitors.newlayout.styles.sponsors.buttondiv}>
                                {
                                    sponsor.DATA1.includes("empty") ?
                                        (
                                            ''
                                        )
                                        :
                                        (
                                            <Button style={global.$exhibitors.newlayout.styles.exhibitors.button} className="exhibitor-layout-button" onClick={() => this.popup(sponsor)}>View Profile</Button>
                                        )
                                }
                            </div>
                        </div>
                    </Col>
                );
            }
            else {
                return null;
            }
        })

    }

    popup(sponsor) {
        //console.log(sponsor);
        global.$sponsor = sponsor;
        this.onOpenAllOptions()
    }

    getSponsorjson() {
        //console.log("sponsor json")
        let apiName = serviceNames.getWebsiteService();
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'code': global.$code,
                'command': 'getJsonFileFromS3',
                'bucket': this.state.bucket,
                'filename': "sponsors"
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //console.log(myInit);

        API.post(apiName, path, myInit).then(response => {
            //console.log ("response.data ===> " + response.data + "response.err ===> " + response.err);

            if (response.err == null && response.data != null) {
                let buf = Buffer.from(response.data.Body);
                //console.log(buf)
                //console.log(buf.toString());
                global.$sponsorjson = JSON.parse(buf.toString());
                //console.log(global.$sponsorjson);
                this.setState({ dataloaded: true });

            }
            else {
                ////console.log("getAttendeesTableObjects - failed!!");
                //console.log(response.err);
            }
        }
        ).catch(error => {
            ////console.log(error.response)
        });

    }
}
export default ExhibitorLayout;
