
import React from 'react';
import { Row, Col, Label, Input, Button, Spinner } from 'reactstrap';
import { API } from 'aws-amplify';
var serviceNames = require('../ServiceNameMapper');

class AddBid extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            code: props.code,
            itemid: props.itemid,
            startbid: props.startbid,
            bidinc: props.bidinc,
            lastbid: props.lastbid,
            bidcount: props.bidcount,
            open: props.open,
            close: props.close,
            status: props.status,
            bidbuttonstyles: global.$auctions.styles.bidbutton,
            bidstyle: global.$auctions.styles.item.bid,
            lastbidstyle: global.$auctions.styles.item.lastbid,
            bidlablestyle: global.$auctions.styles.bidamountlabel,
            bidmessagestyle: global.$auctions.styles.bidmessagestatus,
            selectedauctionitem: [],
            bidamount: '',
            minbidamount: '',
            showSpinner: false,
            templastbid: '0',
            message: '',
            bidmessagecolor: 'color: green'
        };

        this.handleInputChange = this.handleInputChange.bind(this);
    }


    handleInputChange(event) {
        event.preventDefault();

        const target = event.target;
        const name = target.name;
        let value = target.value
        //console.log"Target = " + name + ", Value = " + value);

        if(value.includes("."))
        {
            this.setState({
                showSpinner: false,
                message: "Unable to enter amount",
                bidmessagecolor: 'red'
            });   
        }
        else if(value.length > 7)
        {
            this.setState({
                showSpinner: false,
                message: "Unable to enter amount",
                bidmessagecolor: 'red'
            });       
        }
        else
        {
            this.setState({ [name]: value });
        }
    }

    render() {

        //console.log('addbid - render');
        return (
            this.displayBidInfo()
        )
    }

    displayBidInfo() {

        let options = {
            timeZone: global.$settings.timezone,
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
        },
            formatter = new Intl.DateTimeFormat([], options);

        //console.log(formatter.format(new Date()));

        //console.log(this.state);
        var currentdate = formatter.format(new Date());
        var curenttime = Date.parse(currentdate);
        var bidopentime = Date.parse(this.state.open);
        var bidclosetime = Date.parse(this.state.close);

        if (this.state.minbidamount == "") {
            if (this.state.lastbid != "0") {
                this.state.minbidamount = parseFloat(this.state.lastbid) + parseFloat(this.state.bidinc)
                //this.state.templastbid = this.state.bidamount
            }
            else {
                this.state.minbidamount = this.state.startbid
            }
        }

        //Suresh: templastbid?
        if (this.state.templastbid == "0") {

            if (this.state.lastbid == "0") {
                if (this.state.bidamount == "") {
                    this.state.templastbid = "0"
                }
                else {
                    this.state.templastbid = this.state.bidamount
                }
            }
            else {
                this.state.templastbid = this.state.lastbid
            }
        }

        return (
            <div>
                <Row>
                    <Col style={{ float: "left" }}>
                        <p style={this.state.bidstyle}>Bids: {this.state.bidcount}</p>
                    </Col>
                    <Col style={{ float: "right" }} >
                        <p style={this.state.lastbidstyle}>Last Bid: ${this.state.lastbid}</p>
                    </Col>
                </Row>
                <div className="bidamount-div" >
                    <Label style={this.state.bidlablestyle}>Bid Amount</Label>
                    <Input type="number" name="bidamount" className="bidamount-form-control" id="bidamount" value={this.state.bidamount} onChange={this.handleInputChange} required/>
                </div>
                <p>Minimum bid amount should be ${this.state.minbidamount}</p>

                <div className="bids-div-2">
                    <div id="bid-message" style={{ color: this.state.bidmessagecolor }}><p>{this.state.message}</p></div>
                    <Button style={this.state.bidbuttonstyles} onClick={() => this.postBid(this.state.itemid, this.state.bidinc, this.state.lastbid)} >
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="Loading-button"
                            variant="success"
                            hidden={!this.state.showSpinner} />Bid</Button>
                </div>
            </div >
        )
    }
    postBid(itemid, bidinc, lastbid) {

        let apiName = serviceNames.getAuctionService();
        let path = ''; //replace this with the path you have configured on your API
        var date = new Date()
        var timestamp = date / 1000

        //var amount = document.getElementById('bidamount').value
        //console.log(`Bid Amount Entered = {amount}`)
        let options = {
            timeZone: global.$settings.timezone,
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
        },
            formatter = new Intl.DateTimeFormat([], options);

        //console.log(formatter.format(new Date()));

        //console.log(this.state);
        var currentdate = formatter.format(new Date());
        var curenttime = Date.parse(currentdate);
        var bidopentime = Date.parse(this.state.open);
        var bidclosetime = Date.parse(this.state.close);
        if ((curenttime >= bidopentime && curenttime <= bidclosetime) && this.state.status == 'O') {

            if (parseInt(this.state.bidamount) < parseInt(lastbid) || parseInt(this.state.bidamount) < parseInt(this.state.minbidamount)) {

                //if (amount < lastbid || amount < this.state.minbidamount) {
                //document.getElementById("bid-message").innerHTML = "<p style='color: red'>You must enter an amount over the last bid </p>";
                //this.setState({ showSpinner: false });

               //console.log(this.state.bidamount, lastbid, this.state.minbidamount);
                this.setState({
                    showSpinner: false,
                    message: "You must enter an amount greater than the previous bid",
                    bidmessagecolor: 'red'
                });
            }
            else {

                this.setState({ showSpinner: true })

                let myInit =
                {
                    body:
                    {
                        'code': this.state.code,
                        'command': 'postBid',
                        'username': global.$attendeeDetails.fname + " " + global.$attendeeDetails.lname,
                        'userid': global.$attendeeDetails.userid,
                        'amount': this.state.bidamount.toString(),
                        //'amount': amount.toString(),

                        'itemid': itemid,
                        'bidid': timestamp.toString()
                    },
                    response: true,
                    headers: {}

                }

                //this.setState({ disabled: true });  Suresh:  Is this required?

               //console.log(myInit);
                API.post(apiName, path, myInit).then(response => {

                   //console.log(response);
                    if (response.err == null) {

                        //document.getElementById("bid-message").innerHTML = "<p style='color: green'>Bid successfully submitted</p>";

                        if (response.data == null) {  //
                            this.setState({
                                showSpinner: false,
                                message: "Bid successfully submitted",
                                bidmessagecolor: 'green'
                            });

                            //This tells the parent component to reload bigs
                            this.props.refreshBids();
                        }
                        else {
                            if (response.data.errorMessage.includes("refresh")) {
                                this.setState({
                                    showSpinner: false,
                                    message: "You have been outbid. Please try again.",
                                    bidmessagecolor: 'green'
                                });

                                //This tells the parent component to reload bigs
                                this.props.refreshBids();
                            }
                        }

                    }
                    else {
                       //console.log(response.err)
                        //this.setState({ showSpinner: false })
                        ////console.log("postSpeakerProfile - failed!!");
                        //document.getElementById("bid-message").innerHTML = "<p style='color: red'>Unable to place your bid. Please try again</p>";
                        this.setState({
                            showSpinner: false,
                            message: "Unable to place your bid. Please try again",
                            bidmessagecolor: 'red'
                        });
                    }
                }
                ).catch(error => {

                   //console.log(error);

                    /*
                    this.setState({ showSpinner: false,
                    message: "Unable to place your bid. Please try again",
                    bidmessagecolor:'color: red'});
                    */

                });

            }
        }
        else {
            this.setState({
                showSpinner: false,
                message: "Auction item has been closed",
                bidmessagecolor: 'red'
            });
        }
    }
}
export default AddBid;
