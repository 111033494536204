import React from 'react';
import { Row, Col } from 'reactstrap';

const moment = require('moment-timezone');

class DaysToGo extends React.Component {
    constructor(props) {

        super(props);
        this.state = {
            days: 0,
            hours: 0,
            min: 0,
            sec: 0,
            date: '',
            daystogostyle: global.$home.daystogo.styles.row,
            countdownstyle: global.$home.daystogo.styles.countdown,
        };
       //console.log(this.state)
        this.interval = setInterval(() => {
            const date = this.calculateCountdown(this.state.date);
            date ? this.setState(date) : this.stop();
        }, 1000);
    }
    componentWillUnmount() {
        this.stop();
      }
    
    calculateCountdown(endDate) {
        //console.log(endDate);
        var currentdate = moment.tz(`${global.$settings.timezone}`).format("YYYY-MM-DDTHH:mm:ss");
        //console.log(global.$home.daystogo.enddate, currentdate);
        let diff = (Date.parse(new Date(global.$home.daystogo.enddate)) - Date.parse(currentdate)) / 1000;

        //console.log(diff)
        // clear countdown when date is reached
        if (diff <= 0) return false;

        const timeLeft = {
            years: 0,
            days: 0,
            hours: 0,
            min: 0,
            sec: 0,
            millisec: 0,
        };

        // calculate time difference between now and expected date
        if (diff >= (365.25 * 86400)) { // 365.25 * 24 * 60 * 60
            timeLeft.years = Math.floor(diff / (365.25 * 86400));
            diff -= timeLeft.years * 365.25 * 86400;
        }
        //console.log(timeLeft)
        if (diff >= 86400) { // 24 * 60 * 60
            timeLeft.days = Math.floor(diff / 86400);
            diff -= timeLeft.days * 86400;
        }
        if (diff >= 3600) { // 60 * 60
            timeLeft.hours = Math.floor(diff / 3600);
            diff -= timeLeft.hours * 3600;
        }
        if (diff >= 60) {
            timeLeft.min = Math.floor(diff / 60);
            diff -= timeLeft.min * 60;
        }
        timeLeft.sec = diff;

        //console.log(timeLeft);
        return timeLeft;
    }

    stop() {
        clearInterval(this.interval);
    }

    addLeadingZeros(value) {
        value = String(value);
        while (value.length < 2) {
            value = '0' + value;
        }
        return value;
    }

    render() {
        const countDown = this.state;

        return (
            <div>
                {
                    global.$home.daystogo.enable.includes('Y') ?
                        (
                            <Row>
                                <Col md={12} style={this.state.daystogostyle}>
                                    <div dangerouslySetInnerHTML={{ __html: global.$home.daystogo.title }}></div>
                                    <div style={this.state.countdownstyle}>

                                        <span className="Countdown-col">
                                            <span className="Countdown-col-element">
                                                <strong>{this.addLeadingZeros(countDown.days)}</strong>
                                                <span>{countDown.days === 1 ? 'Day' : 'Days'}</span>
                                            </span>
                                        </span>

                                        <span className="Countdown-col">
                                            <span className="Countdown-col-element">
                                                <strong>{this.addLeadingZeros(countDown.hours)}</strong>
                                                <span>Hours</span>
                                            </span>
                                        </span>

                                        <span className="Countdown-col">
                                            <span className="Countdown-col-element">
                                                <strong>{this.addLeadingZeros(countDown.min)}</strong>
                                                <span>Min</span>
                                            </span>
                                        </span>

                                        <span className="Countdown-col">
                                            <span className="Countdown-col-element">
                                                <strong>{this.addLeadingZeros(countDown.sec)}</strong>
                                                <span>Sec</span>
                                            </span>
                                        </span>

                                    </div>
                                </Col>
                            </Row>
                        )
                        :
                        ('')
                }
            </div>
        )
    }
}
export default DaysToGo;
