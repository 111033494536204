import React from 'react';
import { API } from 'aws-amplify';
import SessionSignups from './SessionSignups';
import { getSignupService } from '../ServiceNameMapper';
class SessionRegistration extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            dataloaded: false,
            signupdata: ''
        }
        this.refreshSessionRegistration = this.refreshSessionRegistration.bind(this);
    }
    refreshSessionRegistration()
    {
       //console.log("refresh");
        this.props.refreshScheduleItem();
    }
    render() {
        if (this.state.dataloaded) {
           //console.log(this.state);
            return (
                <SessionSignups
                    signupdata={this.state.signupdata}
                    schedule={this.props.schedule} 
                    refreshSessionRegistration={this.refreshSessionRegistration}
                    />
            )
        }
        else {
            this.getMySignups();
            return null;
        }
    }

    getMySignups() {
        let apiName = getSignupService();
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'code': global.$code,
                'command': 'getmysignups',
                'userid': global.$userid,
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }

       //console.log(myInit);
        API.post(apiName, path, myInit).then(response => {
            //console.log(response);

            if (response.err == null && response.data != null) {
               //console.log(response.data.Items);
                var signupdata = this.getSignupTableObjects(response.data.Items);
                global.$signupdata = signupdata;
                this.setState({
                    signupdata: signupdata,
                    dataloaded: true
                });

            }
            else {
               //console.log(response.err);
            }
        }
        ).catch(error => {
           //console.log(error)
        });
    }
    getSignupTableObjects(objs) {
        //console.log(objs);

        const tableObjs = []

        for (var i = 0; i < objs.length; i++) {
            var signupObj = objs[i]

            //console.log(signupObj)

            var newObj = Object()

            newObj.userid = signupObj.userid.S
            newObj.ownerid = signupObj.ownerid.S
            newObj.signupid = signupObj.signupid.S
            newObj.status = signupObj.signupstatus.S
            newObj.signuptype = signupObj.signuptype.S
            newObj.data1 = signupObj.data1.S
            newObj.data2 = signupObj.data2.S
            newObj.data3 = signupObj.data3.S
            newObj.data4 = signupObj.data4.S
            newObj.groupname = signupObj.groupname.S

            tableObjs.push(newObj)
        }

        //console.log(tableObjs)

        return tableObjs
    }
}

export default SessionRegistration;