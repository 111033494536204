/* eslint-disable */
import React, { Component } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { Row, Col, Button } from 'reactstrap';
import { mdiVideo } from '@mdi/js';
import Icon from '@mdi/react';
import { API } from 'aws-amplify';
import Avatar from 'react-avatar';
import MeetupAttendees from './MeetupAttendees';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

const moment = require('moment-timezone'); //moment-timezone
class MeetupTabs extends React.Component {
    constructor(props) {

        super(props);

        this.state = {
            code: global.$code,
            bucket: props.bucket,
            signedupusers: [],
            dataloaded: false,
            activetab: "info"
        };
    }

    isMyMeetup(meetup) {
        var result = false;
        //console.log(global.$signedupmeetups)

        global.$signedupmeetups.map(signup => {
            //console.log(signup);
            //console.log(meetup.meetupid, signup.data3, meetup.meetupid == signup.data3)
            if (meetup.meetupid == signup.data3) {
                result = true;
            }
        })

        return result;
    }

    isMeetupFinished(currenttime, meetupstarttime, meetupendtime) {
        //console.log(`ScheduleWatchNow: isSessionLive: current = ${this.props.currenttime}, start = ${meetupstarttime}, end = ${meetupendtime}` );

        var mode = global.$mode

        if (mode == "live") {
            if ((currenttime) > (meetupendtime)) {
                //console.log('ScheduleWatchNow.isSessionLive - Live');
                return true;
            }
            else {
                //console.log('ScheduleWatchNow.isSessionLive - Not Live');
                return false;
            }
        }
        else {
            return false;
        }
    }
    isMeetupUpcoming(currenttime, meetupstarttime, meetupendtime) {
        //console.log(`ScheduleWatchNow: isSessionLive: current = ${this.props.currenttime}, start = ${meetupstarttime}, end = ${meetupendtime}` );

        var mode = global.$mode

        if (mode == "live") {
            if ((meetupstarttime) > (currenttime)) {
                //console.log('ScheduleWatchNow.isSessionLive - Live');
                return true;
            }
            else {
                //console.log('ScheduleWatchNow.isSessionLive - Not Live');
                return false;
            }
        }
        else {
            return false;
        }
    }
    render() {
        var meetupconfig = global.$config["meetups"];
        //console.log(meetupconfig);


        if (this.props.meetup != null) {
            var currentdate = this.getAdjustedCurrentDate(this.props.meetup.begintime, this.props.meetup.endtime)//moment().tz(`${global.$settings.timezone}`).format('L LTS')

            var currenttime = Date.parse(currentdate);
            var meetupstarttime = Date.parse(this.props.meetup.begintime);
            var meetupendtime = Date.parse(this.props.meetup.endtime);


            var date = moment(this.props.meetup.begintime).format("MMM Do");
            var btime = moment(this.props.meetup.begintime).format("hh:mm A");
            var etime = moment(this.props.meetup.endtime).format("hh:mm A");
            if (this.isMeetupLive(currenttime, meetupstarttime, meetupendtime) && this.props.meetup.status == "Open") {
                this.state.activetab = "videochat";
            }
            return (
                <div>
                    <Tabs className="tabs-menu" defaultActiveKey={this.state.activetab} transition={false} id="tabs" >
                        <Tab eventKey="info" title="Info" className="tab-item">
                            {this.displayMeetupInfo(this.props.meetup)}
                        </Tab>
                        <Tab eventKey="videochat" title="Video Chat" className="tab-item">
                            {
                                this.isMyMeetup(this.props.meetup) ?
                                    (
                                        <div>
                                            {
                                                this.isMeetupLive(currenttime, meetupstarttime, meetupendtime) ?
                                                    (
                                                        this.props.meetup.status == "Open" ?
                                                            (
                                                                this.displayVideoChat(this.props.meetup, meetupconfig)
                                                            )
                                                            :
                                                            (
                                                                <div className="meetup-chat-offline-message">
                                                                    <br />
                                                                    Video chat closed by host
                                                                </div>
                                                            )
                                                    )
                                                    :
                                                    (
                                                        this.isMeetupUpcoming(currenttime, meetupstarttime, meetupendtime) ?
                                                            (
                                                                this.props.meetup.status == "Close" ?
                                                                    (
                                                                        <div className="meetup-chat-offline-message">
                                                                            <br />
                                                                                Video chat closed by host
                                                                        </div>
                                                                    )
                                                                    :
                                                                    (
                                                                        <div className="meetup-chat-offline-message">
                                                                            <br />
                                                                            Video chat scheduled at <b>{date + ', ' + btime + " - " + etime}</b>
                                                                        </div>
                                                                    )
                                                            )
                                                            :
                                                            (
                                                                this.isMeetupFinished(currenttime, meetupstarttime, meetupendtime) ?
                                                                    (
                                                                        <div className="meetup-chat-offline-message">
                                                                            <br />
                                                                            Meetup Finished
                                                                        </div>
                                                                    )
                                                                    :
                                                                    (
                                                                        this.props.meetup.status == "Close" ?
                                                                            (
                                                                                <div className="meetup-chat-offline-message">
                                                                                    <br />
                                                                                Video chat closed by host
                                                                                </div>
                                                                            )
                                                                            :
                                                                            (
                                                                                ''
                                                                            )
                                                                    )
                                                            )
                                                    )
                                            }
                                        </div>

                                    )
                                    :
                                    (
                                        this.isMeetupLive(currenttime, meetupstarttime, meetupendtime) ?
                                            (
                                                this.props.meetup.ownerid == global.$userid ?
                                                    (
                                                        this.displayVideoChat(this.props.meetup, meetupconfig)
                                                    )
                                                    :
                                                    (
                                                        this.props.meetup.status == "Open" ?
                                                            (
                                                                this.props.meetup.signuprequired == "Yes" ?
                                                                    (
                                                                        <div className="meetup-chat-offline-message">
                                                                            <br />
                                                                            You should signup to join the video chat.
                                                                            <br />
                                                                            <div className="meetup-video-chat-signup" onClick={() => { this.getAllSignups(this.props.meetup) }}>click here to signup</div>
                                                                        </div>
                                                                    )
                                                                    :
                                                                    (
                                                                        this.displayVideoChat(this.props.meetup, meetupconfig)
                                                                    )
                                                            )
                                                            :
                                                            (
                                                                <div className="meetup-chat-offline-message">
                                                                    <br />
                                                                            Video chat closed by host
                                                                </div>
                                                            )
                                                    )
                                            )
                                            :
                                            (
                                                this.isMeetupUpcoming(currenttime, meetupstarttime, meetupendtime) ?
                                                    (
                                                        this.props.meetup.ownerid == global.$userid ?
                                                            (
                                                                this.displayVideoChat(this.props.meetup, meetupconfig)
                                                            )
                                                            :
                                                            (
                                                                this.props.meetup.status == "Close" ?
                                                                    (
                                                                        <div className="meetup-chat-offline-message">
                                                                            <br />
                                                                            Video chat closed by host
                                                                        </div>
                                                                    )
                                                                    :
                                                                    (
                                                                        <div className="meetup-chat-offline-message">
                                                                            <br />
                                                                        Video chat scheduled at <b>{date + ', ' + btime + " - " + etime}</b>
                                                                        </div>
                                                                    )
                                                            )
                                                    )
                                                    :
                                                    (
                                                        this.isMeetupFinished(currenttime, meetupstarttime, meetupendtime) ?
                                                            (
                                                                this.props.meetup.ownerid == global.$userid ?
                                                                    (
                                                                        this.displayVideoChat(this.props.meetup, meetupconfig)
                                                                    )
                                                                    :
                                                                    (
                                                                        <div className="meetup-chat-offline-message">
                                                                            <br />
                                                                        Meetup Finished
                                                                        </div>
                                                                    )
                                                            )
                                                            :
                                                            (
                                                                this.props.meetup.ownerid == global.$userid ?
                                                                    (
                                                                        this.displayVideoChat(this.props.meetup, meetupconfig)
                                                                    )
                                                                    :
                                                                    (
                                                                        this.props.meetup.status == "Close" ?
                                                                            (
                                                                                <div className="meetup-chat-offline-message">
                                                                                    <br />
                                                                    Video chat closed by host
                                                                                </div>
                                                                            )
                                                                            :
                                                                            (
                                                                                ''
                                                                            )
                                                                    )
                                                            )
                                                    )
                                            )

                                    )
                            }

                        </Tab>
                        <Tab eventKey="users" title="Attendees" className="tab-item">
                            <MeetupAttendees meetup={this.props.meetup} />
                        </Tab>
                    </Tabs>
                </div>
            );
        }
        else {
            return null;
        }
    }

    displayMeetupInfo(meetup) {

        var meetupconfig = global.$config["meetups"].tabs.info;

        var image = "";
        if (meetup.photo == " " || meetup.photo == "") {
            image = "empty";
        }
        else {
            image = `https://s3.amazonaws.com/event360live/public/${global.$code}/meetups/` + meetup.photo
        }

        var date = moment(meetup.begintime).format("MMM Do");
        var btime = moment(meetup.begintime).format("hh:mm A");
        var etime = moment(meetup.endtime).format("hh:mm A");

        return (
            <div>
                <Row>
                    <Col md={12}>
                        <div className="meetup-popup-tabs-img-div" style={meetupconfig.styles.imgdiv}>
                            {
                                image.includes("empty") ?
                                    (
                                        <div>
                                            <Avatar className="meetup-popup-tabs-avatar" name={`${meetup.title}`} style={meetupconfig.styles.avatar} />
                                        </div>
                                    )
                                    :
                                    (
                                        <div>
                                            <img src={image} className="meetup-popup-tabs-img" style={meetupconfig.styles.img} />
                                        </div>
                                    )
                            }

                            <div className="meetup-popup-tabs-name" style={meetupconfig.styles.title}>
                                {meetup.title}
                            </div>
                            <div className="meetup-popup-tabs-date" style={meetupconfig.styles.date}>
                                {date + ', ' + btime + " - " + etime}
                            </div>
                            <div className="meetup-popup-tabs-desc" style={meetupconfig.styles.desc}>
                                {ReactHtmlParser(meetup.desc)}
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }

    displayVideoChat(meetup, meetupconfig) {
        //console.log(meetup);
        //console.log(meetupconfig);
        return (
            <div>
                {
                    meetup.connectionurl.includes("zoom") ?
                        (
                            <div style={meetupconfig.tabs.videochat.joinbutton.zoom.styles.div} className="meetuptabs-zoom-videochat">
                                <div dangerouslySetInnerHTML={{ __html: meetupconfig.tabs.videochat.joinbutton.zoom.header }}></div>
                                <br />
                                <a href={this.props.meetup.connectionurl} target="_blank"><Button type="submit" className="btn meetupstabs-zoom-btn" style={meetupconfig.tabs.videochat.joinbutton.zoom.styles.button}><Icon path={mdiVideo} size={1.2} className="join-video-icon" />{meetupconfig.tabs.videochat.joinbutton.zoom.text}</Button></a>
                                zoom
                            </div>
                        )
                        :
                        ('')
                }
                {
                    meetup.connectionurl.includes("externallink") ?
                        (
                            <div style={meetupconfig.tabs.videochat.joinbutton.external.styles.div} className="meetuptabs-external-videochat">
                                <div dangerouslySetInnerHTML={{ __html: meetupconfig.tabs.videochat.joinbutton.external.header }}></div>
                                <br />
                                <a href={this.props.meetup.connectionurl} target="_blank" ><Button type="submit" className="btn meetupstabs-external-btn" style={meetupconfig.tabs.videochat.joinbutton.external.styles.button}><Icon path={mdiVideo} size={1.2} className="join-video-icon" />{meetupconfig.tabs.videochat.joinbutton.external.text}</Button></a>
                            </div>
                        )
                        :
                        ('')
                }
                {
                    meetup.connectionurl.includes("daily.co") ?
                        (
                            <iframe src={meetup.connectionurl}
                                id="myId"
                                className="meetuptabs-video-chat"
                                display="initial"
                                position="relative"
                                width="100%"
                                allow="camera *;microphone *"
                            />
                        )
                        :
                        ('')
                }
            </div>
        )
    }

    getAdjustedCurrentDate(begin, end) {
        var mode = global.$mode;
        if (mode == null || mode == '') {
            mode = 'live';
        }

        var currentdate = moment().tz(`${global.$settings.timezone}`).format('L LTS');

        if (mode == "live") {
            //Nothing - Just return the current date
        }
        else if (mode == "preview") {
            // Make current date/time as same as session begin date/time
            currentdate = begin;
        }
        else if (mode == "dryrun") {
            var eventdate = moment(begin).format('L');
            var currenttime = moment().tz(`${global.$settings.timezone}`).format('LTS');
            currentdate = eventdate + " " + currenttime
        }
        else if (mode == "ondemand") {   //Add 365 days to current date

            var postdate = moment().add(365, 'days').calendar();
            var posttime = moment().tz(`${global.$settings.timezone}`).format('LTS');
            currentdate = postdate + " " + posttime;
        }

        return currentdate;
    }

    isMeetupLive(currenttime, meetupstarttime, meetupendtime) {
        //console.log(`ScheduleWatchNow: isSessionLive: current = ${this.props.currenttime}, start = ${meetupstarttime}, end = ${meetupendtime}` );

        var mode = global.$mode

        if (mode == "live") {
            if ((currenttime) >= (meetupstarttime) && (currenttime) <= (meetupendtime)) {
                //console.log('ScheduleWatchNow.isSessionLive - Live');
                return true;
            }
            else {
                //console.log('ScheduleWatchNow.isSessionLive - Not Live');
                return false;
            }
        }
        else if (mode == "preview") {
            return true;
        }
        else if (mode == "dryrun") {
            var eventdate = moment(meetupstarttime).format('L');
            var currenttime = moment().tz(`${global.$settings.timezone}`).format('LTS');

            var dateformat = eventdate + " " + currenttime

            //console.log('ScheduleWatchNow.isSessionLive - Adjusted Time ' + dateformat);

            var timeformat = Date.parse(dateformat);
            if ((timeformat) >= (meetupstarttime) && (timeformat) <= (meetupendtime)) {
                //console.log('ScheduleWatchNow.isSessionLive - Live');
                return true;
            }
            else {
                //console.log('ScheduleWatchNow.isSessionLive - Not Live');
                return false;
            }
        }
        else if (mode == "ondemand") {
            return false;
        }
    }
    getAllSignups(meetup) {
        let apiName = 'MeetupService';
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'code': global.$code,
                'command': 'getsignedupusers',
                'ownerid': meetup.ownerid,
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }

        //console.log(myInit);
        API.post(apiName, path, myInit).then(response => {
            //console.log(response);

            if (response.err == null && response.data != null) {
                //console.log(response.data.Items);
                var signupdata = this.getSignupTableObjects(response.data.Items, meetup);
                //console.log(signupdata);
                var signupcount = 0;
                signupcount = signupdata.length;
                //console.log(signupcount, meetup.signupmax, Number(meetup.signupmax) == Number(signupcount))
                if (Number(meetup.signupmax) == Number(signupcount)) {
                    alert("Maximum signups reached for this meetup")
                    this.props.refreshListMeetup();
                }
                else {
                    if (window.confirm("Do you want to signup for this meetup?")) {
                        this.registerMeetup(meetup);
                    }
                }
                //console.log(signupdata);
            }
            else {
                //console.log(response.err);
            }
        }
        ).catch(error => {
            //console.log(error)
        });
    }

    getSignupTableObjects(objs, meetup) {
        //console.log(objs);

        const tableObjs = []

        for (var i = 0; i < objs.length; i++) {
            var signupObj = objs[i]

            //console.log(signupObj)

            var newObj = Object()
            if (signupObj.ownerid.S == meetup.ownerid && signupObj.data3.S == meetup.meetupid) {
                newObj.userid = signupObj.userid.S
                newObj.ownerid = signupObj.ownerid.S
                newObj.signupid = signupObj.signupid.S
                newObj.status = signupObj.signupstatus.S
                newObj.signuptype = signupObj.signuptype.S
                newObj.data1 = signupObj.data1.S
                newObj.data2 = signupObj.data2.S
                newObj.data3 = signupObj.data3.S
                newObj.data4 = signupObj.data4.S
                newObj.groupname = signupObj.groupname.S
            }
            //console.log(tableObjs)
            tableObjs.push(newObj)
        }

        var newArray = tableObjs.filter((ele) => {
            return ele.constructor === Object && Object.keys(ele).length > 0
        });
        //console.log(newArray);
        //console.log(tableObjs)

        return newArray;
    }

    registerMeetup(meetup) {
        //console.log("registering session");
        let apiName = 'MeetupService';
        let path = ''; //replace this with the path you have configured on your API
        var date = new Date();
        var timestamp = date / 1000;

        var meetupdata = JSON.stringify(meetup);
        var userdata = JSON.stringify(global.$attendeeDetails);

        let myInit = {
            body:
            {
                'code': global.$code,
                'command': 'signupMeetup',
                'ownerid': meetup.ownerid,//sessionid
                'signupid': timestamp.toString(),
                'groupname': "Meetups", // for the parallel sessions to group
                'signuptype': 'meetup',
                'userid': global.$userid,
                'data1': userdata,
                'data2': meetupdata,
                'data3': meetup.meetupid,
                'data4': ' ',
                'status': 'C'
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        //console.log(myInit);
        API.post(apiName, path, myInit).then(response => {
            //console.log(response);
            if (response.err == null) {
                //console.log(response.data);
                this.props.refreshListMeetup();
            }
            else {
            }
        }
        ).catch(error => {
            //console.log(error)
        });
    }

} //Class

export default MeetupTabs;