/* eslint-disable */
import React, { Component } from 'react';
import { API} from 'aws-amplify';
import Icon from '@mdi/react';
import { mdiChatAlert } from '@mdi/js';
import './NotifyMe.css';
import { Link } from 'react-router-dom';


var timer;

class AttendeeChatNotifications extends React.Component {
    constructor(props) {

        super(props);

        this.state = {
            code: global.$code,
            newmessage: false,
            dataloaded: false,
        };

        timer = setInterval(() => {
            this.getConversations()
        }, 60000)
    }

    componentWillUnmount() {
        //console.log("componentWillUnmount")
        clearInterval(timer)
    }

    render() {
        if (this.state.dataloaded) {
            return (
                <div className="menubar-chat-notifications-div">
                    <Link to={`../Delegateslist2`} style={global.$header.menu.chatnotifications.icon.style}><Icon path={mdiChatAlert} size={1.7} className="menubar-chat-notifications-icon"/></Link>
                    {
                        this.state.shownewmessage == true ?
                            (
                                <p className="menubar-chat-notifications-label" style={global.$header.menu.chatnotifications.label.style}>{global.$header.menu.chatnotifications.label.text}</p>
                            )
                            :
                            ('')
                    }
                </div>
            )
        }
        else {
            this.getConversations();
            return null;
        }
    }

    getConversations() {
        let apiName = 'ChatService';
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'code': this.state.code,
                'command': 'getConversations',
                'p1': global.$userid,
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }

        //console.log(myInit);
        //console.log('ChatMessages:getMessages()');

        API.post(apiName, path, myInit).then(response => {
            //console.log(response);

            if (response.err == null && response.data != null) {
                //console.log(response.data.Items);
                var conversations = this.getChatTableObjects(response.data.Items);
                //global.$userconversations = conversations;
                //console.log(conversations);
                var array = [];
                conversations.map((item, index) => {
                    if (item.p1 == global.$userid) {
                        if (item.p2newmsg == "true") {
                            array.push("new");
                        }
                    }
                    else if (item.p2 == global.$userid) {
                        if (item.p1newmsg == "true") {
                            array.push("new");
                        }
                    }
                })
                if (array.includes("new")) {
                    this.setState({
                        shownewmessage: true,
                        dataloaded: true
                    })
                }
                else {
                    this.setState({
                        shownewmessage: false,
                        dataloaded: true
                    })
                }
            }
            else {
                //console.log(response.err);
            }
        }
        ).catch(error => {
            //console.log(error)
        });
    }
    getChatTableObjects(objs) {
        //console.log(objs);

        const tableObjs = []

        for (var i = 0; i < objs.length; i++) {
            var chatObj = objs[i]

            //console.log(chatObj)

            var newObj = Object()

            newObj.cid = chatObj.cid.S
            newObj.cstatus = chatObj.cstatus.S
            newObj.lmsg = chatObj.lmsg.S
            newObj.lupdated = chatObj.lupdated.N
            newObj.mcount = chatObj.mcount.N
            newObj.p1 = chatObj.p1.S
            newObj.p1name = chatObj.p1name.S
            newObj.p1newmsg = chatObj.p1newmsg.S
            newObj.p2 = chatObj.p2.S
            newObj.p2name = chatObj.p2name.S
            newObj.p2newmsg = chatObj.p2newmsg.S

            tableObjs.push(newObj)
        }

        //console.log(tableObjs)

        return tableObjs
    }
} //Class

export default AttendeeChatNotifications;