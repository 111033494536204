import React from 'react';
import { Row, Col } from 'reactstrap';
import Iframe from 'react-iframe';
import { Analytics } from 'aws-amplify';

class Registrationform extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        iframestyles: global.$registration.form.styles
      };
    }
    
  componentDidMount() {
    window.scrollTo(0, 0)
  }
render()
{
    
    Analytics.record({ name: 'PageView', attributes: { name: 'eventsites-'+this.state.code+'-registration'}});
    return(
        <div>
            <br/>
            <br/>
            <Row>
                    <Col md={12}>
                    <iframe src={global.$header.register.url}
                            id="myId"
                            className="myClassname"
                            style={this.state.iframestyles}
                            />
                    </Col>
                </Row>
            {/*{
                global.$loginstatus.includes(false)?
                (

                <Row>
                    <Col md={12}>
                    <iframe src={global.$header.register.url}
                            id="myId"
                            className="myClassname"
                            style={this.state.iframestyles}
                            />
                    </Col>
                </Row>
                )
                :
                (
                    <div dangerouslySetInnerHTML={{__html: global.$registration.afterlogin}}></div>
                )
            }*/}
            <br/>
            <br/>
        </div>
    )
}
}
export default Registrationform;

/*import React from 'react';
import { Container, FormGroup,Label,Input} from 'reactstrap';
import './Registration.css';
import {API} from "aws-amplify";

class Registrationform extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          code: props.code,
          fname: '',
          lname: '',
          company: '',
          title: '',
          email: '',
          phone: '',
          street: '',
          city: '',
          state: '',
          zip: '',
          country: '',
          note: ''
      }
      this.handleInputChange  = this.handleInputChange.bind(this);
      this.handleSubmit       = this.handleSubmit.bind(this);
      this.handleClear        = this.handleClear.bind(this);           
     //console.log(this.state.code)        
    }
    
      render() {
        return(
            <div>
            <Container>
            
            <form className="reg-form" action="https://www.example.com/payment/success/" method="POST"> 
              <h2 className="offset-md-4 registration">Registration Form</h2>
                  <FormGroup>
                    <Label className="label" for="fname"><i className='fas fa-user'></i>First Name*</Label>
                    <Input type="text" name="fname" id="fname" placeholder="Enter your first name" value={this.state.fname || ''} onChange={ this.handleInputChange}/>
                  </FormGroup>
              <FormGroup>
                    <Label className="label" for="lname"><i className='fas fa-user'></i>Last Name*</Label>
                    <Input type="text" name="lname" id="lname" placeholder="Enter your last name" value={this.state.lname || ''} onChange={ this.handleInputChange}/>
                  </FormGroup> 
             <FormGroup>
                 <Label className="label" for="company"><i className='fas fa-industry'></i>Company* </Label>
                 <Input type="text" name="company" id="company" placeholder="Enter your company" value={this.state.company || ''} onChange={ this.handleInputChange}/>
             </FormGroup>
             <FormGroup>
                 <Label className="label" for="title"><i className='fas fa-text-height'></i>Title* </Label>
                 <Input type="text" name="title" id="title" placeholder="Enter your title" value={this.state.title || ''} onChange={ this.handleInputChange}/>
             </FormGroup>
             <FormGroup>
                 <Label className="label" for="enail"><i className='fas fa-envelope'></i>Email*</Label>
                 <Input type="text" name="email" id="email" placeholder="Enter your email" value={this.state.email || ''} onChange={ this.handleInputChange}/>
             </FormGroup>
             <FormGroup>
                 <Label className="label" for="phone"><i className='fas fa-mobile-alt'></i>Phone*</Label>
                 <Input type="text" name="phone" id="phone" placeholder="Enter your phone number" value={this.state.phone || ''} onChange={ this.handleInputChange}/>
             </FormGroup>
                  <FormGroup>
                    <Label className="label" for="street"><i className='fas fa-street-view'></i>Street*</Label>
                    <Input type="text" name="street" id="street" placeholder="Enter your street" value={this.state.street || ''} onChange={ this.handleInputChange}/>
                  </FormGroup>
                  <FormGroup>
                    <Label className="label" for="city"><i className='fas fa-city'></i>City*</Label>
                    <Input type="text" name="city" id="city" placeholder="Enter your city" value={this.state.city || ''} onChange={ this.handleInputChange}/>
                  </FormGroup>
                  <FormGroup>
                    <Label className="label" for="zip"><i className='fas fa-map-pin'></i>Zip*</Label>
                    <Input type="text" name="zip" id="zip" placeholder="Enter your pincode" value={this.state.zip || ''} onChange={ this.handleInputChange}/>
                  </FormGroup>
                <FormGroup>
                    <Label className="label" for="country"><i className='fas fa-globe-americas'></i>Country*</Label>
                    <Input type="text" name="country" id="country" placeholder="Enter your country" value={this.state.country || ''} onChange={ this.handleInputChange}/>
                </FormGroup>
                <FormGroup>
                    <Label className="label" for="state"><i className='fas fa-city'></i>State*</Label>
                    <Input type="text" name="state" id="state" placeholder="Enter your State" value={this.state.state || ''} onChange={ this.handleInputChange}/>
                </FormGroup>
             <button type="button" className="offset-md-5 btn-submit" onClick={this.handleSubmit}>submit</button>
             <button color="danger" className="btn-cancel" onClick={this.handleClear}>cancel</button>
             <h6 style={{color:"green", padding:"20px"}}>{this.state.note}</h6>
             </form>      
             
             </Container>
             </div>
        );

      }
    handleInputChange(event) 
    {
        const target = event.target;
        const name = target.name;
        let  value = target.value    

        //console.log( "Target = " + name + ", Value = " + value);
            this.setState({[name]: value});    
    }

    validateInputs()
    {
        if ( this.state.fname === "" || this.state.lname === "" || this.state.company === "" || this.state.email === "" || this.state.phone === "" || this.state.country === "" || this.state.state === "" || this.state.city === "" || this.state.street === "" || this.state.zip === "")
        {
            this.state.note = "Enter all the required inputs"
            this.forceUpdate();
            return false;
        }
        return true;
    }
  
    handleSubmit(event) 
    {
        event.preventDefault();
        if (this.validateInputs())
        {
            this.submitRegistration(event);
        }
    }

    handleClear(event) 
    {
        event.preventDefault();
        this.clearInputs();
        this.state.note= '';
        var date = new Date();  
        var longFormat = date*1; 
        return this.state.nid = longFormat.toString();   
    }
  
    clearInputs()
    {
      this.state.fname = "";
      this.state.lname = "";
      this.state.company = "";
      this.state.email = "";
      this.state.phone = "";
      this.state.title = "";
      this.state.country = "";
      this.state.state = "";
      this.state.city = "";
      this.state.street = "";
      this.state.zip = "";  
      this.forceUpdate(); 
    }
    submitRegistration(event)
    {    
        
            let apiName = 'EventsitesService'; 
            let path = ''; //replace this with the path you have configured on your API
            var date = new Date();  
            var longFormat = date/1000; 
            
        let myInit =
        {
            body: 
            {
                'code'      : this.state.code,
                'command'   : 'postRegistration',
                'fname'     : this.state.fname,
                'lname'     : this.state.lname,
                'company'   : this.state.company,  
                'title'     : this.state.title,
                'phone'     : this.state.phone,
                'email'     : this.state.email,
                'country'   : this.state.country,
                'state'     : this.state.state,
                'city'      : this.state.city,
                'street'    : this.state.street,
                'zip'       : this.state.zip,
                'linkedin'  : ' ',
                'twitter'   : ' ',
                'fb'        : ' ',
                'regid'     : ' ',
                'regtype'   : ' ',
                'photo'     : ' ',
                'memberid'  : ' ',
                'tag'       : 'tag',
                'ep'        : ' ',
                'submittedby': ' ',
                'udate'     : ' ', 
                'visitpoints': ' ',
                'activitypoints': ' ',
                'totalpoints': ' ',
                'regstatus' : ' ',
                'sessionpoints': ' ',
                'poolid'    : ' ',
                'password'  : ' ',
                'myexpertise'  : ' ',
                'myinterests'  : ' ',
                'validscans': ' '

            },
            response: true,
            convertEmptyValues:true,
            headers: {} 
        }
            this.setState({disabled:true});
         //console.log(myInit);
            ////console.log(this.state.code + "," + this.state.message + "," + this.state.type );
        API.post(apiName, path, myInit).then( response => 
        {   
            ////console.log ("response.data ===> " + response.data + "response.err ===> " + response.err);
            this.setState({disabled:false});

            if (response.err == null)
            {                  
                ////console.log("postNotification - Success!!");
                this.state.note = "Registration successfull";
                this.clearInputs();
                //this.state.nid="addnew";
                //this.setState(this.state.nid="addnew");     
                var date = new Date();  
                var longFormat = date*1; 
                this.state.nid = longFormat.toString();
                //console.log(this.state.nid);
            }
            else
            {
                ////console.log("postNotification - failed!!");
                this.state.note = "Error!! Unable to submit message. Please try again. "
                this.setState({ isLoading: false });
                this.forceUpdate();                    
            }

        }).catch(error =>
        {

            ////console.log(error.response)
            this.setState({disabled:false});
            this.setState({ isLoading: false });
        });
    }
    
    }
    
    export default Registrationform;*/