import React from "react";
import SpeakerLayoutInfo from "./SpeakerLayoutInfo";
import Carousel from "react-elastic-carousel"; //https://sag1v.github.io/react-elastic-carousel

class SpeakersSlider extends React.Component {
  constructor(props) {
    var itemsperslide = 6;
    if (global.$config["speakersscroll"].itemsperslide != null) {
      itemsperslide = global.$config["speakersscroll"].itemsperslide;
    }

    super(props);
    this.state = {
      slider: props.speakers,
      numberofimagesperslide: itemsperslide,
    };
  }

  render() {
    const slides = this.props.speakers.map((item) => {
      //console.log(item)
      if (item.TYPE != "T") {
        return <SpeakerLayoutInfo speaker={item} />;
      }
    });
    const breakPoints = [
      { width: 0, itemsToShow: 1 },
      { width: 550, itemsToShow: 2 },
      { width: 850, itemsToShow: 4 },
      { width: 1024, itemsToShow: this.state.numberofimagesperslide },
    ];
    return (
      <div>
        <Carousel
          itemsToShow={this.state.numberofimagesperslide}
          breakPoints={breakPoints}
          onResize={(currentBreakPoint) => console.log()}
          enableAutoPlay
          autoPlaySpeed={5000}
          pagination={false}
        >
          {slides}
        </Carousel>
      </div>
    );
  }
}
export default SpeakersSlider;
