/* eslint-disable*/
import React, { Component, Fragment } from "react";
import { Col, Row } from "reactstrap";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import commonProfile from "../images/common_profile.png";
import ReactHtmlParser from 'react-html-parser';
import "./Speakers.css";
import SpeakerPopup from "../Components/Shared/SpeakersLayout/SpeakerPopup";
import { Button } from "react-bootstrap";

class SpeakerCategory extends Component{

    constructor(props){
        super(props);
        this.state = {
            code: props.code,
            speaker:props.speaker,
            speakertitlestyle: global.$speakers.styles.speakertitle,
            speakerimagestyle: global.$speakers.styles.speakerimage,
            speakerheaderstyle: global.$speakers.styles.categoryheader,
            categorybuttonstyle: global.$speakers.styles.categorylist ? global.$speakers.styles.categorylist.categorybutton : {"color":'white'},
            categorytitlestyle: global.$speakers.styles.categorylist ? global.$speakers.styles.categorylist.categorytitle : {"color":'black'},
            categorynamestyle: global.$speakers.styles.categorylist ? global.$speakers.styles.categorylist.categoryname : {"color":'black'},
            speakerCategory:global.$speakers.speakercategory,
            speakerlist:global.$speakers.speakerlist,
            alloptionsopen: false,
            showMore:true
        }
        this.displaySpeakerCategory_2 = this.displaySpeakerCategory_2.bind(this);
        //this.displaySpeakerCategory_1 = this.displaySpeakerCategory_1.bind(this);
        this.popup = this.popup.bind(this);
        this.onOpenAllOptions = this.onOpenAllOptions.bind(this);
    }

    paragraphHandler =(e) => {
      this.setState((prevState) => ({
        showMore: !prevState.showMore,
      }));

      this.state.showMore ?
        (e.target.parentElement.children[1].firstElementChild.children[1].classList.remove('showSection')) 
      : 
        (e.target.parentElement.children[1].firstElementChild.children[1].classList.add('showSection'))
    }


// display list speakers
  displaySpeakerCategory_1(){
    return this.state.speaker.map((speaker) => {
      if (
        speaker.PHOTO == "profile.png" ||
        speaker.PHOTO == " " ||
        speaker.PHOTO == ""
      ) {
        var image = commonProfile;
      } else {
        var image =
          `https://s3.amazonaws.com/event360live/public/${this.state.code}/speakers/` +
          speaker.PHOTO;
      }
      if(speaker.CATEGORY !== "Speakers" && speaker.CATEGORY !== ""){
        
      return(
       <Fragment>
              {
                speaker.TYPE.includes("T") ? (
                  <Col md={12}>
                    <div style={this.state.categorynamestyle}>{speaker.NAME}</div>
                  </Col>
                ) : (

                <Col md={12} className="list-speaker-wrapper">
                    <div className="list-speaker-image">
                        <img src={image} alt=''/> 
                      </div>

                      <div className="list-speaker-details">
                          <div  className="list-speakers-name" style={this.state.categorytitlestyle}>{ speaker.NAME}</div>
                          <div className="list-speakers-description">
                          {ReactHtmlParser(speaker.DESCRIPTION)}
                          </div>
                          <Button onClick={this.paragraphHandler}   style={ this.state.categorybuttonstyle}>Show More</Button>
                      </div>
                </Col>

              )}
        </Fragment>
      )
      }
    })
    
        
  }

// display rounded speakers
  displaySpeakerCategory_2(){
    return this.state.speaker.map((speaker) => {
      if (
        speaker.PHOTO == "profile.png" ||
        speaker.PHOTO == " " ||
        speaker.PHOTO == ""
      ) {
        var image = commonProfile;
      } else {
        var image =
          `https://s3.amazonaws.com/event360live/public/${this.state.code}/speakers/` +
          speaker.PHOTO;
      }
      
        return (
          <Fragment>
            {speaker.TYPE.includes("T") ? (
              <Col md={12}>
                  <div style={{textAlign:'left'}}>
                    <p style={this.state.categorynamestyle}>{speaker.NAME}</p>
                  </div>
              </Col>
            ) : (
              <Col md={3} key={speaker.ID}>
                <div className="speaker-wrapper">
                    {/* <Link to={`./speakerInfo/${speaker.NAME + "==SpeakerRouter"}`}> <img src={image} style={this.state.speakerimagestyle} /></Link> */}
                    <div onClick={() => this.popup(speaker)} className="speaker-img-popup-div ">
                        <img src={image} alt="" style={this.state.speakerimagestyle} />
                    </div>
                    <div style={this.state.speakertitlestyle} onClick={() => this.popup(speaker)} className="speaker-img-popup-div speaker-name-div">
                        <h6 >{speaker.NAME}</h6>
                    </div>
                </div>
              </Col>
            )}
          </Fragment>
        );
    });
  }

//  open popup model and close
  onOpenAllOptions = () => {
    this.setState((prevState) => ({
      alloptionsopen: !prevState.alloptionsopen,
    }));
  };

// show model
  popup(speaker) {
    global.$speaker = speaker;
    this.onOpenAllOptions();
  }


    render(){
        return(
            <div> 
                <div>
                  { 
                    this.state.speakerCategory && this.state.speakerlist &&
                    <Row className="speakers-lists">
                      {this.displaySpeakerCategory_1()}
                    </Row>
                  }

                  { 
                    this.state.speakerCategory && !this.state.speakerlist &&
                    <Row className="speakers-lists">
                      {this.displaySpeakerCategory_2()}
                    </Row>
                  }
                </div>
                <Modal
                    open={this.state.alloptionsopen}
                    onClose={this.onOpenAllOptions}
                    blockScroll={false}
                    styles={{
                      modal: {
                        width: "100%",
                      },
                    }}
                    classNames={{
                      modal: 'speakersCustomModal',
                    }}
                    center
                  >
                    <SpeakerPopup speaker={global.$speaker} />
                </Modal>
               
            </div>
        )
    }


    
}
export default SpeakerCategory