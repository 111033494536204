import React from 'react';
import {API} from 'aws-amplify';
import ChatMessage from './ChatMessage';
import ChatInput from './ChatInput';
var serviceNames = require('../ServiceNameMapper');

var timer;
var messagesCount = 0;

class ChatMessages extends React.Component 
{
  constructor(props) {
    
    super(props);

    this.state = { 
      code: this.props.code,
      sessionid: this.props.sessionid,
      messages: [],
      dataloaded: false
    };
    this.loadMessages = this.loadMessages.bind(this);

    //console.log("ChatMessages.constructor()"); 
    timer = setInterval(() => {
      this.getMessages()
    }, 1000)
  }

  componentDidUpdate() {
    
    // There is a new message in the state, scroll to bottom of list
    //const objDiv = document.getElementById('messageList');
    //objDiv.scrollTop = objDiv.scrollHeight;

  }

  componentWillUnmount() {
    //console.log("componentWillUnmount")
    clearInterval(timer)
  }

  loadMessages() 
  {
   //console.log('ChatMessages:loadMessages()');
   const objDiv = document.getElementById('messageList');
   objDiv.scrollTop = objDiv.scrollHeight;
   this.getMessages();
    
  }

  render() {

   //console.log("ChatMessages.render()"); 

    if (this.state.dataloaded) {

       //console.log("ChatMessages.render() dataloaded "); 

        const messages = this.state.messages.map((message, i) => {
          return (
            <ChatMessage
              key         = {message.messageid}
              senderid    = {message.senderid}              
              sendername  = {message.sname}
              message     = {message.message}
              messageid   = {message.messageid}
              fromMe='false' />
          );
        });

      return (
        <div>
                <div className='chat-messages' id='messageList'>
                    { messages }
                </div>

                <ChatInput code= {this.state.code} sessionid= {this.state.sessionid} onSend={this.loadMessages} />
 
        </div>
      )
    }
    else {
      this.getMessages();
      return (
          <div className='chat-messages' id='messageList'>
          </div>
      )
    }
  }

  getMessages() {

      let apiName = serviceNames.getMessageService(); 
      let path = ''; //replace this with the path you have configured on your API
      let myInit = {
          body: 
          {
              'code'      : this.state.code,
              'command'   : 'getMessages',
              'receiverid': this.state.sessionid,
              'chat'      : 'Y'
          },
          response: true,
          convertEmptyValues:true,
          headers: {} 
      }
      
      //console.log(myInit);
     //console.log('ChatMessages:getMessages()');
      
      API.post(apiName, path, myInit).then( response => 
          {   
              //console.log(response);
            
              if (response.err == null && response.data!= null)
              {                  
                //console.log(response.data.Items);
                this.setState({
                    dataloaded: true,
                    messages : this.getMessageObjects(response.data.Items)
                })
                //console.log(this.state.messages.length);
                //console.log(messagesCount)

                if(messagesCount < this.state.messages.length)
                {
                  //console.log("true");                  
                  const objDiv = document.getElementById('messageList');
                  objDiv.scrollTop = objDiv.scrollHeight;
                }

                messagesCount = this.state.messages.length;
                //console.log(messagesCount)
              }
              else
              {
                 //console.log(response.err);
              }
          }
        ).catch(error =>
        {
           //console.log(error)
        });      
  }
  
  getMessageObjects(objs) 
  {
      const messageObjs = []

      for (var i = 0; i < objs.length; i++) {

          var obj = objs[i]
          var newObj = Object()

          newObj.receiverid = obj.receiverid.S
          newObj.messageid  = obj.messageid.N
          newObj.ccount     = obj.ccount.N
          newObj.lcount     = obj.lcount.N
          newObj.h          = obj.h.N
          newObj.w          = obj.w.N
          newObj.image      = obj.image.S
          newObj.message    = obj.message.S
          newObj.senderid   = obj.senderid.S
          newObj.sname      = obj.sname.S
          
          messageObjs.push(newObj)
      }
      return messageObjs
  }

} //Class

export default ChatMessages;