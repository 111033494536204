import React from 'react';
import './Schedule.css';
import ScheduleItem from './ScheduleItem';
import { API } from 'aws-amplify';
import { getSignupService } from '../ServiceNameMapper';

const moment = require('moment-timezone');

/*

- Schedule tabs component

*/

var timer;

class MySessions extends React.Component {
    constructor(props) {
        super(props);
        //console.log(props)
        this.state = {
            code: global.$code,
            s3: global.$s3,
            bucket: global.$s3bucket,
            dataloaded: false,
            scheduletags: props.stags,
            activeschedule: props.activetab,
            livesessionsexist: false,
            signupdata: ''
        };
        //console.log("Schedule Tabs");
        this.refreshScheduleTabs  = this.refreshScheduleTabs.bind(this);
    }

    render() {
        if (this.state.dataloaded) {
           //console.log(this.state);
            return (
                <div>
                    {this.getSchedule()}
                </div>
            )
        }
        else {
            this.getMySignups();
            return null;
        }
    }

    
    getMySignups() {
        let apiName = getSignupService();
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'code': global.$code,
                'command': 'getmysignups',
                'userid': global.$userid,
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }

       //console.log(myInit);
        API.post(apiName, path, myInit).then(response => {
            //console.log(response);

            if (response.err == null && response.data != null) {
               //console.log(response.data.Items);
                var signupdata = this.getSignupTableObjects(response.data.Items);
                global.$signupdata = signupdata;
                this.setState({
                    signupdata: signupdata,
                    dataloaded: true
                });

            }
            else {
               //console.log(response.err);
            }
        }
        ).catch(error => {
           //console.log(error)
        });
    }
    getSignupTableObjects(objs) {
        //console.log(objs);

        const tableObjs = []

        for (var i = 0; i < objs.length; i++) {
            var signupObj = objs[i]

            //console.log(signupObj)

            var newObj = Object()

            newObj.userid = signupObj.userid.S
            newObj.ownerid = signupObj.ownerid.S
            newObj.signupid = signupObj.signupid.S
            newObj.status = signupObj.signupstatus.S
            newObj.signuptype = signupObj.signuptype.S
            newObj.data1 = signupObj.data1.S
            newObj.data2 = signupObj.data2.S
            newObj.data3 = signupObj.data3.S
            newObj.data4 = signupObj.data4.S
            newObj.groupname = signupObj.groupname.S

            tableObjs.push(newObj)
        }

        //console.log(tableObjs)

        return tableObjs
    }

    getSchedule() {
        var stag = this.props.stag;
        //console.log(stag);
        const results = global.$schedulejson.filter(schedule => {
            if (this.props.search == "")
                return true;
            else {
                if (schedule.TITLE.toLocaleLowerCase().includes(this.props.search.toLocaleLowerCase()) || schedule.DESCRIPTION.toLocaleLowerCase().includes(this.props.search.toLocaleLowerCase()) || schedule.T1.toLocaleLowerCase().includes(this.props.search.toLocaleLowerCase()) || schedule.SP.toLocaleLowerCase().includes(this.props.search.toLocaleLowerCase()))
                    return true;
                else
                    return false;
            }
        })
        //console.log(stag, activetab);
        return results.map(schedule => {

            //console.log(schedule)
            if (schedule.SP == "") {
                schedule.SP = "empty";
            }

            if (schedule.FILE1 == "" || schedule.FILE1 == " ") {
                schedule.FILE1 = "empty";
            }
            var currentdate = this.getAdjustedCurrentDate(schedule.BEGIN, schedule.END)//moment().tz(`${global.$settings.timezone}`).format('L LTS')

            var currenttime = Date.parse(currentdate);
            var sessionstarttime = Date.parse(schedule.BEGIN);
            var sessionendtime = Date.parse(schedule.END);

            schedule.SP = schedule.SP.replace(/;/g, "");

            if (schedule.T3 == "") {
                schedule.T3 = "emptyurl";
            }
            //console.log(schedule.ATTENDEELINK)
            if (schedule.TAG.includes(stag)) {
                if (Number(global.$signupdata.length) != 0) {
                    return global.$signupdata.map(data => {
                        //console.log(data.ownerid, schedule.SID, data.ownerid == schedule.SID)
                        if (data.ownerid == schedule.SID) {
                            //console.log("item")
                            return (
                                <div key={data.signupid}>
                                    <ScheduleItem
                                        stag={stag}
                                        currentdate={currentdate}
                                        currenttime={currenttime}
                                        sessionstarttime={sessionstarttime}
                                        sessionendtime={sessionendtime}
                                        schedule={schedule}
                                        activetab={this.state.activeschedule}
                                        type={this.props.type}
                                        from="Mysessions"
                                        refreshScheduleTabs={this.refreshScheduleTabs}
                                    />
                                </div>
                            );
                        }
                    })
                }
            }
        })
    }
    refreshScheduleTabs()
    {
       //console.log("refresh")
        this.props.refreshScheduleTabs();
    }
    getAdjustedCurrentDate(begin, end) {
        var mode = global.$mode;
        if (mode == null || mode == '') {
            mode = 'live';
        }

        var currentdate = moment().tz(`${global.$settings.timezone}`).format('L LTS');

        if (mode == "live") {
            //Nothing - Just return the current date
        }
        else if (mode == "preview") {
            // Make current date/time as same as session begin date/time
            currentdate = begin;
        }
        else if (mode == "dryrun") {
            var eventdate = moment(begin).format('L');
            var currenttime = moment().tz(`${global.$settings.timezone}`).format('LTS');
            currentdate = eventdate + " " + currenttime
        }
        else if (mode == "ondemand") {   //Add 365 days to current date

            var postdate = moment().add(365, 'days').calendar();
            var posttime = moment().tz(`${global.$settings.timezone}`).format('LTS');
            currentdate = postdate + " " + posttime;
        }

        //console.log(`SessionVideos:  current = ${currentdate}, start = ${this.state.begindate}, end = ${this.state.enddate}` );

        return currentdate;
    }
}
export default MySessions;


