import React from 'react';
import LoadPrivateImg from '../Components/Shared/LoadPrivateImg';
import PostImage from '../Posts/PostImage';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { Row, Col, Button } from 'reactstrap';
import Modal from "react-responsive-modal";
import ConversationInput from './ConversationInput';
import { API } from 'aws-amplify';
import ConversationItemView from './ConversationItemView';

//const moment = require('moment-timezone');
var timer = '';
var messagesCount = 0;

class ConversationView extends React.Component {
    constructor(props) {

        super(props);
        //console.log(props);

        this.state = {
            conversation: '',
            conversations: '',
            dataloaded: false
        };

        this.loadMessages = this.loadMessages.bind(this);
        timer = setInterval(() => {
            //console.log("timer")
            this.getConversation()
        }, 1000)
    }

    componentWillUnmount() {
        //console.log("componentWillUnmount")
        clearInterval(timer)
    }
    componentDidMount() {
        // this.loadMessages();
    }

    componentDidUpdate() {
        //this.loadMessages();
    }

    loadMessages() {
        //console.log('ConversationView:loadMessages()');
        const objDiv = document.getElementById('messageList2');
        objDiv.scrollTop = objDiv.scrollHeight;

        /*
            var div = document.getElementById(id);
            div.scrollTop = div.scrollHeight - div.clientHeight;
        */

        this.getConversation();
    }

    render() {

        if (this.state.dataloaded) {
            //console.log(this.state.conversations);
            var messages = this.state.conversation.map((message, i) => {
                //console.log(message);
                return (
                    <ConversationItemView
                        key={message.lupdated}
                        conversation={message}
                        conversations={this.state.conversations}
                        fromMe='false'
                        senderid={this.props.loginid}
                        receiverid={this.props.delegateid}
                    />
                );
            });

            return (
                <div>
                    <div>
                        {
                            this.props.type.includes("exhibitors") ?
                                (
                                    <div className='chat-messages exhibitor-chat' id='messageList2'>
                                        {messages}
                                    </div>
                                )
                                :
                                (
                                    <div className='chat-messages attendee-chat' id='messageList2'>
                                        {messages}
                                    </div>
                                )
                        }
                    </div>
                    <div className="attendee-conversation-input-div">
                        <ConversationInput code={global.$code} senderid={this.props.loginid} receiverid={this.props.delegateid} conversation={this.state.conversations} onSend={this.loadMessages} />
                    </div>
                </div>
            )
        }
        else {
            this.getAndCreateConversation();
            this.getConversation();
            return (<div>

            </div>)
        }
    }

    getConversation() {
        var cid;
        var senderid = this.props.loginid;
        var receiverid = this.props.delegateid;
        //console.log(senderid, receiverid);
        var array = [];
        array.push(senderid, receiverid);
        //console.log(array);
        array.sort();
        //console.log(array);
        var array1 = array[0];
        var array2 = array[1];
        cid = array1 + "-" + array2
        //console.log(cid);

        let apiName = 'ChatService';
        let path = ''; //replace this with the path you have configured on your API
        var longformat = new Date();
        var timestamp = longformat / 1000;
        let myInit = {
            body:
            {
                'code': global.$code,
                'command': 'getMessages',
                'cid': cid,
                'timestamp': timestamp.toString()
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }

        //console.log(myInit);
        //console.log('ChatMessages:getMessages()');

        API.post(apiName, path, myInit).then(response => {
            //console.log(response);

            if (response.err == null && response.data != null) {
                //console.log(response.data);
                var conversation = this.getChatMessagesObjects(response.data.Items);
                this.setState({
                    conversation: conversation,
                    dataloaded: true
                });

                //this.loadMessages();
                //console.log(messagesCount)
                //console.log(conversation.length)
                //console.log(this.state.conversation)

                if (messagesCount < conversation.length) {
                    const objDiv = document.getElementById('messageList2');
                    objDiv.scrollTop = objDiv.scrollHeight;

                    /*
                        var div = document.getElementById(id);
                        div.scrollTop = div.scrollHeight - div.clientHeight;
                    */
                }

                messagesCount = conversation.length;

            }
            else {
                //console.log(response.err);
            }
        }
        ).catch(error => {
            //console.log(error)
        });
    }
    getChatMessagesObjects(objs) {
        //console.log(objs);

        const tableObjs = []
        for (var i = 0; i < objs.length; i++) {

            var chatObj = objs[i]

            //console.log(chatObj)

            var newObj = Object()
            //console.log(chatObj);
            newObj.cid = chatObj.cid.S
            newObj.msg = chatObj.msg.S
            newObj.senderid = chatObj.senderid.S
            newObj.timestamp = chatObj.timestamp.N
            tableObjs.push(newObj)

        }
        //console.log(tableObjs)

        return tableObjs
    }
    getAndCreateConversation() {
        let apiName = 'ChatService';
        let path = ''; //replace this with the path you have configured on your API
        var date = new Date();
        var timestamp = date/1000;
        let myInit = {
            body:
            {
                'code': global.$code,
                'command': 'getConversation',
                'p1': global.$userid,
                'p2': this.props.delegateid,
                'p1name': global.$fname,
                'p2name': this.props.delegatename,
                'timestamp': timestamp.toString()
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }

       //console.log(myInit);
        //console.log('ChatMessages:getMessages()');

        API.post(apiName, path, myInit).then(response => {
            //console.log(response);

            if (response.err == null && response.data != null) {
                //console.log(response.data);
                var conversation = this.getChatTableObjects(response.data.Item);
            }
            else {
                //console.log(response.err);
            }
        }
        ).catch(error => {
            //console.log(error)
        });
    }
    getChatTableObjects(objs) {
        //console.log(objs);

        const tableObjs = []

        var chatObj = objs

        //console.log(chatObj)

        var newObj = Object()

        newObj.cid = chatObj.cid.S
        newObj.cstatus = chatObj.cstatus.S
        newObj.lmsg = chatObj.lmsg.S
        newObj.lupdated = chatObj.lupdated.N
        newObj.mcount = chatObj.mcount.N
        newObj.p1 = chatObj.p1.S
        newObj.p1name = chatObj.p1name.S
        newObj.p1newmsg = chatObj.p1newmsg.S
        newObj.p2 = chatObj.p2.S
        newObj.p2name = chatObj.p2name.S
        newObj.p2newmsg = chatObj.p2newmsg.S
        tableObjs.push(newObj)

        //console.log(tableObjs)
        this.setState({
            conversations: newObj
        });

        return tableObjs
    }

    /*getConversations() {
        let apiName = 'ChatService';
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'code': global.$code,
                'command': 'getConversations',
                'p1': global.$userid,
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }

       //console.log(myInit);
        //console.log('ChatMessages:getMessages()');

        API.post(apiName, path, myInit).then(response => {
           //console.log(response);

            if (response.err == null && response.data != null) {
                //console.log(response.data.Items);
                if (response.data.Items.length == 0) {
                   //console.log("create")
                    this.createConversation();
                }
                else {
                   //console.log("exist");
                    var conversations = this.getChatTableObjects(response.data.Items);
                }

                if(messagesCount < this.state.conversations.length)
                {
                  //console.log("true");                  
                  const objDiv = document.getElementById('messageList');
                  objDiv.scrollIntoView({ behavior: "smooth" });
                }

                messagesCount = this.state.conversations.length;

            }
            else {
               //console.log(response.err);
            }
        }
        ).catch(error => {
            //console.log(error)
        });
    }
    getChatTableObjects(objs) {
       //console.log(objs);
        var cid;
        var senderid = this.props.loginid;
        var receiverid = this.props.delegateid;
        //console.log(senderid, receiverid);
        var array = [];
        array.push(senderid, receiverid);
        //console.log(array);
        array.sort();
        //console.log(array);
        var array1 = array[0];
        var array2 = array[1];
        cid = array1 + "-" + array2

        const tableObjs = []

        for (var i = 0; i < objs.length; i++) {
            var chatObj = objs[i]

           //console.log(chatObj)

            var newObj = Object()
           //console.log(cid, chatObj.cid.S)
            if (cid == chatObj.cid.S) {

                newObj.cid = chatObj.cid.S
                newObj.cstatus = chatObj.cstatus.S
                newObj.lmsg = chatObj.lmsg.S
                newObj.lupdated = chatObj.lupdated.N
                newObj.mcount = chatObj.mcount.N
                newObj.p1 = chatObj.p1.S
                newObj.p1name = chatObj.p1name.S
                newObj.p2 = chatObj.p2.S
                newObj.p2name = chatObj.p2name.S
                tableObjs.push(newObj)
            }
            else {
               //console.log("create");
                this.createConversation();
            }
        }
       //console.log(tableObjs)
        this.setState({
            conversations: tableObjs
        });


        return tableObjs
    }

    createConversation() {
        let apiName = 'ChatService';
        let path = ''; //replace this with the path you have configured on your API
        var date = new Date();
        var timestamp = date / 1000;
        let myInit = {
            body:
            {
                'code': global.$code,
                'command': 'getConversation',
                'p1': this.props.loginid,
                'p2': this.props.delegateid,
                'p1name': this.props.loginname,
                'p2name': this.props.delegatename,
                'timestamp': timestamp.toString()
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }

       //console.log(myInit);
        //console.log('ChatMessages:getMessages()');

        API.post(apiName, path, myInit).then(response => {
           //console.log(response);

            if (response.err == null && response.data != null) {
               //console.log(response.data.Items);
                var conversations = this.getChatTableObjects(response.data.Items);

            }
            else {
                //console.log(response.err);
            }
        }
        ).catch(error => {
            //console.log(error)
        });
    }*/

}


export default ConversationView;