/*
  layout in webconfig

    "layout": "list" > header image with list
    "layout": "imagemap" > only image with mapping
    "layout": "both" > both image mapping and list of sponsors 

*/

import React from 'react';
import { Row, Col, Button, Input } from 'reactstrap';
import { Link, Route, Switch } from 'react-router-dom';
import { API, Analytics } from 'aws-amplify';
import './Sponsors.css';
import { CircleArrow as ScrollUpButton } from "react-scroll-up-button";
import { HourGlass } from 'react-awesome-spinners';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import ReactPlayer from 'react-player';
import Modal from "react-responsive-modal";
import 'react-responsive-modal/styles.css';
import SponsorVideo from './SponsorVideo';
import SponsorVideoChat from './SponsorVideoChat';
import VirtualPopupTabs from '../VirtualExpo/VirtualPopupTabs';
import ImageMapper from 'react-image-mapper';
import UpdatePoints from '../Points/UpdatePoints';
import { Tabs, Tab, TabPanel, TabList } from 'react-web-tabs';
import 'react-web-tabs/dist/react-web-tabs.css';
import ExhibitorLayout from './ExhibitorLayout';
import SponsorsByCategory from '../Components/Shared/SponsorsByCategory';
import SponsorSlider from './SponsorSlider';
var serviceNames = require('../ServiceNameMapper');

var sponsorjson = [];
class Sponsors extends React.Component {
  constructor(props) {
    var videochat = "N";
    if (global.$sponsors.enablevideochat == null) {
      videochat = "N";
    }
    else {
      videochat = global.$sponsors.enablevideochat
    }

    var layout = 'list';
    //console.log(global.$exhibitors.layout);
    var menutype = '';
    if (props.type == "sponsors") {
      menutype = global.$sponsors
      if (global.$sponsors.layout == null) {
        layout = "list";
      }
      else {
        layout = global.$sponsors.layout;
      }
    }
    else if (props.type == "exhibitors") {
      menutype = global.$exhibitors;
      if (global.$exhibitors.layout == null) {
        layout = "list";
      }
      else {
        layout = global.$exhibitors.layout;
      }
    }

    var imagemapimage = "";
    var imagemap = "";
    if (global.$exhibitors != null && global.$exhibitors.topbanner == "imagemap" && global.$exhibitors.layout == "both") {
      imagemapimage = global.$exhibitors.imagemaptabs.tabs.tab1.imagemap.image;
      imagemap = global.$exhibitors.imagemaptabs.tabs.tab1.imagemap;
    }

    super(props);
    this.state =
    {
      code: props.code,
      open: false,
      s3: props.s3,
      bucket: props.bucket,
      type: props.type,
      dataloaded: false,
      search: '',
      sponsorheaderstyle: menutype.styles.categoryheader,
      sponsornamestyle: menutype.styles.name,
      sponsortitlestyle: menutype.styles.title,
      sponsorimagestyle: menutype.styles.image,
      sponsorboothstyle: menutype.styles.booth,
      sponsordescstyle: menutype.styles.desc,
      loadingtextstyles: global.$settings.loading.textstyle,
      loadingdivstyles: global.$settings.loading.divstyle,
      playvideobuttonstyles: menutype.playvideobuttonstyles,
      rowimagestyle: menutype.styles.rows.image,
      rowtextstyle: menutype.styles.rows.text,
      rowheaderstyle: menutype.styles.rows.header,
      leranmorestyle: menutype.styles.learnmore,
      leranmoredivstyle: menutype.styles.learnmorediv,
      enablevideochat: videochat,
      schedule: '',
      showpopuptabs: false,
      alloptionsopen: false,
      imageWidth: window.innerWidth,
      imageHeight: window.innerHeight,
      layout: layout,
      showimagemap: false,
      imagemapimage: imagemapimage,
      imagemap: imagemap,
      activeimgmaptab: "1"
    }
    //console.log(global.$sponsors)

    this.handleInputChange = this.handleInputChange.bind(this);
    // Add Events
    window.addEventListener("resize", this.onWindowResize, false);
  }

  handleInputChange(event) {
    const target = event.target;
    const name = target.name;
    let value = target.value

    //console.log("Target = " + name + ", Value = " + value);

    this.setState({ [name]: value });
    //this.setState({selectedCategory:value})
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (global.$exhibitors != null && global.$exhibitors.newlayout != null && global.$exhibitors.newlayout.enablesponsorslider != null && global.$exhibitors.newlayout.enablesponsorslider == true) {
      this.getSponsorsForScrolling();
    }
  }

  onOpenAllOptions = () => {
    //console.log('onOpenAllOptions');
    this.setState(prevState => ({
      alloptionsopen: !prevState.alloptionsopen
    }));
    //console..log(this.state.alloptionsopen);
  };

  onWindowResize = event => {
    //console.log('reisized to: width', window.innerWidth + 'height =' + window.innerHeight);
    this.setState({
      imageWidth: window.innerWidth,
      imageHeight: window.innerHeight,
    });
  };


  imageMapClicked(area) {
    //console.log(area);
    var results = [];
    //console.log(this.state.type)
    if (this.state.type == "sponsors") {
      //console.log(global.$sponsorjson)
      results = global.$sponsorjson.filter(sponsor => {
        //console.log(sponsor);
        if (sponsor.NAME == area.name)
          return true;
        else {
          return false;
        }
      })
    }
    if (this.state.type == "exhibitors") {

      //console..log(global.$exhibitorjson)
      results = global.$exhibitorjson.filter(exhibitor => {
        //console..log(area.name);
        if (exhibitor.NAME == area.name)
          return true;
        else {
          return false;
        }
      })
    }
    return results.map(sponsor => {
      //console..log(sponsor)
      //console..log(sponsor.NAME == area.name)
      if (sponsor.NAME == area.name) {
        //console..log("true");
        this.popup(sponsor)
      }
    })

  }

  render() {
    //console.log("sponsors: render")
    //console.log(this.state.layout);
    //console.log(global.$exhibitors);
    //console.log(this.state.type);
    //console..log(this.state.alloptionsopen);
    Analytics.record({ name: 'PageView', attributes: { name: 'eventsites-' + this.state.code + '-sponsors' } });
    if (this.state.dataloaded) {
      return (
        <div>
          {
            this.state.type.includes("sponsors") ?
              (
                <div>
                  {
                    this.state.layout.includes("list") ?
                      (
                        <div>
                          <div className="home-banner-div">
                            <img className="banner" src={global.$sponsors.banner} />
                          </div>
                          <div >
                            <div dangerouslySetInnerHTML={{ __html: global.$sponsors.header }}></div>
                          </div>
                          {this.getSponsorListUI("sponsors")}
                        </div>
                      )
                      :
                      ('')
                  }
                  {
                    this.state.layout.includes("onlylogos") ?
                      (
                        <div>
                          <div className="home-banner-div">
                            <img className="banner" src={global.$sponsors.banner} />
                          </div>
                          <div >
                            <div dangerouslySetInnerHTML={{ __html: global.$sponsors.header }}></div>
                          </div>
                          <SponsorsByCategory />
                        </div>
                      )
                      :
                      ('')
                  }
                  {
                    this.state.layout.includes("imagemap") ?
                      (
                        <div>
                          {
                            global.$sponsors.topbanner != null && global.$sponsors.topbanner.includes('imagemap') ?
                              (
                                <div className="img-map">
                                  <ImageMapper
                                    src={global.$sponsors.imagemap.image}
                                    map={global.$sponsors.imagemap}
                                    imgWidth={1920}
                                    width={this.state.imageWidth}
                                    onClick={area => this.imageMapClicked(area)}
                                    className="image-mapper"
                                  />
                                </div>
                              )
                              :
                              (
                                <div>
                                  <div className="home-banner-div">
                                    <img className="banner" src={global.$sponsors.banner} />
                                  </div>
                                  <div >
                                    <div dangerouslySetInnerHTML={{ __html: global.$sponsors.header }}></div>
                                  </div>
                                </div>
                              )
                          }
                        </div>
                      )
                      :
                      ('')
                  }
                  {
                    this.state.layout.includes("both") ?
                      (
                        <div>
                          {
                            global.$sponsors.topbanner != null && global.$sponsors.topbanner.includes('imagemap') ?
                              (
                                <div className="img-map">
                                  <ImageMapper
                                    src={global.$sponsors.imagemap.image}
                                    map={global.$sponsors.imagemap}
                                    imgWidth={1920}
                                    width={this.state.imageWidth}
                                    onClick={area => this.imageMapClicked(area)}
                                    className="image-mapper"
                                  />
                                </div>
                              )
                              :
                              (
                                <div>
                                  <div className="home-banner-div">
                                    <img className="banner" src={global.$sponsors.banner} />
                                  </div>
                                  <div >
                                    <div dangerouslySetInnerHTML={{ __html: global.$sponsors.header }}></div>
                                  </div>
                                </div>
                              )
                          }
                          {this.getSponsorListUI("sponsors")}
                        </div>
                      )
                      :
                      ('')
                  }
                </div>
              )
              :
              (
                ''
              )
          }
          {
            this.state.type.includes("exhibitors") ?
              (
                <div>
                  {
                    this.state.layout.includes("list") ?
                      (
                        <div>
                          <div className="home-banner-div">
                            <img className="banner" src={global.$exhibitors.banner} alt="" />
                          </div>
                          <div >
                            <div dangerouslySetInnerHTML={{ __html: global.$exhibitors.header }}></div>
                          </div>
                          {this.getSponsorListUI("exhibitors")}
                        </div>
                      )
                      :
                      ('')
                  }
                  {
                    this.state.layout.includes("imagemap") ?
                      (
                        <div>
                          {
                            global.$exhibitors.topbanner != null && global.$exhibitors.topbanner.includes('imagemap') ?
                              (
                                <div className="img-map">
                                  {this.getImageMap()}
                                </div>
                              )
                              :
                              (
                                <div>
                                  <div className="home-banner-div">
                                    <img className="banner" src={global.$exhibitors.banner} alt=""/>
                                  </div>
                                  <div >
                                    <div dangerouslySetInnerHTML={{ __html: global.$exhibitors.header }}></div>
                                  </div>
                                </div>
                              )
                          }
                        </div>
                      )
                      :
                      ('')
                  }
                  {
                    this.state.layout.includes("both") ?
                      (
                        <div>
                          {
                            global.$exhibitors.newlayout != null && global.$exhibitors.newlayout.banner !== "" ?
                              (
                                <div className="img-map">
                                  {this.getImageMap()}
                                  <img className="banner" src={global.$exhibitors.newlayout.banner} alt=""/>
                                  <div dangerouslySetInnerHTML={{ __html:  global.$exhibitors.header }}></div>
                                  <ImageMapper
                                    //src={global.$sponsors.imagemap.image}
                                    src={this.state.imagemapimage}
                                    //map={global.$sponsors.imagemap}
                                    map={this.state.imagemap}
                                    imgWidth={1920}
                                    width={this.state.imageWidth}
                                    onClick={area => this.imageMapClicked(area)}
                                    className="image-mapper"
                                  />

                                </div>
                              )
                              :
                              
                                <div>
                                  <div className="home-banner-div">
                                    <img className="banner" src={global.$exhibitors.banner} alt=""/>
                                  </div>
                                  <div >
                                    <div dangerouslySetInnerHTML={{ __html: global.$exhibitors.header }}></div>
                                  </div>
                                </div>
                              
                          }
                          {
                           
                            global.$sponsorjson != null && global.$exhibitors.newlayout && global.$exhibitors.newlayout.enablesponsorslider && global.$exhibitors.newlayout.enablesponsorslider === true ?
                              (
                                <div className="sponsor-slider">
                                  <SponsorSlider />
                                </div>
                              )
                              :
                              ('')
                          }
                          
                          {this.getSponsorListUI("exhibitors")}
                        </div>
                      )
                      :
                      ('')
                  }
                </div>
              )
              :
              (
                ''
              )
          }
        </div>
      );
    }
    else {
      if (this.state.type == "sponsors") {
        if (global.$sponsorjson != null) {
          this.setState({ dataloaded: true });
        }
        else {
          this.getSponsorjson()
        }
      }
      if (this.state.type == "exhibitors") {
        if (global.$exhibitorjson != null) {
          this.setState({ dataloaded: true });
        }
        else {
          this.getSponsorjson()
        }
      }

      return (
        <div>
          <Row>
            <Col md={6} className="offset-5">
              <div className="loading-div">
                <div className="loading-text">Loading....</div>
              </div>
            </Col>
          </Row>
        </div>)
    }
  }
  handleImageMap(data) {
    //console.log(data);
    this.setState({
      showimagemap: true,
      imagemapimage: data.imagemap.image,
      imagemap: data.imagemap,
      activeimgmaptab: data.key
    })
  }
  getImageMap() {
    var exhibitors = global.$exhibitors;
    var imagetabs = global.$exhibitors.imagemaptabs
    //console.log(imagetabs);
    if (imagetabs != null && imagetabs.enable == true) {
      return (
        <div>
          {
            imagetabs.numoftabs == "5" ?
              (
                <div className="imagemaptabs-div">
                  {
                    this.state.activeimgmaptab == "1" ?
                      (
                        <Button className="imgmap-btn1 active-img-map-tab" onClick={() => this.handleImageMap(imagetabs.tabs.tab1)} style={imagetabs.tabs.tab1.activestyle}>{imagetabs.tabs.tab1.title}</Button>
                      )
                      :
                      (
                        <Button className="imgmap-btn1" onClick={() => this.handleImageMap(imagetabs.tabs.tab1)} style={imagetabs.tabs.tab1.style}>{imagetabs.tabs.tab1.title}</Button>
                      )
                  }
                  {
                    this.state.activeimgmaptab == "2" ?
                      (
                        <Button className="imgmap-btn2 active-img-map-tab" onClick={() => this.handleImageMap(imagetabs.tabs.tab2)} style={imagetabs.tabs.tab2.activestyle}>{imagetabs.tabs.tab2.title}</Button>
                      )
                      :
                      (
                        <Button className="imgmap-btn2" onClick={() => this.handleImageMap(imagetabs.tabs.tab2)} style={imagetabs.tabs.tab2.style}>{imagetabs.tabs.tab2.title}</Button>
                      )
                  }
                  {
                    this.state.activeimgmaptab == "3" ?
                      (
                        <Button className="imgmap-btn3 active-img-map-tab" onClick={() => this.handleImageMap(imagetabs.tabs.tab3)} style={imagetabs.tabs.tab3.activestyle}>{imagetabs.tabs.tab3.title}</Button>
                      )
                      :
                      (
                        <Button className="imgmap-btn3" onClick={() => this.handleImageMap(imagetabs.tabs.tab3)} style={imagetabs.tabs.tab3.style}>{imagetabs.tabs.tab3.title}</Button>
                      )
                  }
                  {
                    this.state.activeimgmaptab == "4" ?
                      (
                        <Button className="imgmap-btn4 active-img-map-tab" onClick={() => this.handleImageMap(imagetabs.tabs.tab4)} style={imagetabs.tabs.tab4.activestyle}>{imagetabs.tabs.tab4.title}</Button>
                      )
                      :
                      (
                        <Button className="imgmap-btn4" onClick={() => this.handleImageMap(imagetabs.tabs.tab4)} style={imagetabs.tabs.tab4.style}>{imagetabs.tabs.tab4.title}</Button>
                      )
                  }
                  {
                    this.state.activeimgmaptab == "5" ?
                      (
                        <Button className="imgmap-btn5 active-img-map-tab" onClick={() => this.handleImageMap(imagetabs.tabs.tab5)} style={imagetabs.tabs.tab5.activestyle}>{imagetabs.tabs.tab5.title}</Button>
                      )
                      :
                      (
                        <Button className="imgmap-btn5" onClick={() => this.handleImageMap(imagetabs.tabs.tab5)} style={imagetabs.tabs.tab5.style}>{imagetabs.tabs.tab5.title}</Button>
                      )
                  }
                </div>
              )
              :
              ('')
          }
          {
            imagetabs.numoftabs == "4" ?
              (
                <div className="imagemaptabs-div">
                  {
                    this.state.activeimgmaptab == "1" ?
                      (
                        <Button className="imgmap-btn1 active-img-map-tab" onClick={() => this.handleImageMap(imagetabs.tabs.tab1)} style={imagetabs.tabs.tab1.activestyle}>{imagetabs.tabs.tab1.title}</Button>
                      )
                      :
                      (
                        <Button className="imgmap-btn1" onClick={() => this.handleImageMap(imagetabs.tabs.tab1)} style={imagetabs.tabs.tab1.style}>{imagetabs.tabs.tab1.title}</Button>
                      )
                  }
                  {
                    this.state.activeimgmaptab == "2" ?
                      (
                        <Button className="imgmap-btn2 active-img-map-tab" onClick={() => this.handleImageMap(imagetabs.tabs.tab2)} style={imagetabs.tabs.tab2.activestyle}>{imagetabs.tabs.tab2.title}</Button>
                      )
                      :
                      (
                        <Button className="imgmap-btn2" onClick={() => this.handleImageMap(imagetabs.tabs.tab2)} style={imagetabs.tabs.tab2.style}>{imagetabs.tabs.tab2.title}</Button>
                      )
                  }
                  {
                    this.state.activeimgmaptab == "3" ?
                      (
                        <Button className="imgmap-btn3 active-img-map-tab" onClick={() => this.handleImageMap(imagetabs.tabs.tab3)} style={imagetabs.tabs.tab3.activestyle}>{imagetabs.tabs.tab3.title}</Button>
                      )
                      :
                      (
                        <Button className="imgmap-btn3" onClick={() => this.handleImageMap(imagetabs.tabs.tab3)} style={imagetabs.tabs.tab3.style}>{imagetabs.tabs.tab3.title}</Button>
                      )
                  }
                  {
                    this.state.activeimgmaptab == "4" ?
                      (
                        <Button className="imgmap-btn4 active-img-map-tab" onClick={() => this.handleImageMap(imagetabs.tabs.tab4)} style={imagetabs.tabs.tab4.activestyle}>{imagetabs.tabs.tab4.title}</Button>
                      )
                      :
                      (
                        <Button className="imgmap-btn4" onClick={() => this.handleImageMap(imagetabs.tabs.tab4)} style={imagetabs.tabs.tab4.style}>{imagetabs.tabs.tab4.title}</Button>
                      )
                  }
                </div>
              )
              :
              ('')
          }
          {
            imagetabs.numoftabs == "3" ?
              (
                <div className="imagemaptabs-div">
                  {
                    this.state.activeimgmaptab == "1" ?
                      (
                        <Button className="imgmap-btn1 active-img-map-tab" onClick={() => this.handleImageMap(imagetabs.tabs.tab1)} style={imagetabs.tabs.tab1.activestyle}>{imagetabs.tabs.tab1.title}</Button>
                      )
                      :
                      (
                        <Button className="imgmap-btn1" onClick={() => this.handleImageMap(imagetabs.tabs.tab1)} style={imagetabs.tabs.tab1.style}>{imagetabs.tabs.tab1.title}</Button>
                      )
                  }
                  {
                    this.state.activeimgmaptab == "2" ?
                      (
                        <Button className="imgmap-btn2 active-img-map-tab" onClick={() => this.handleImageMap(imagetabs.tabs.tab2)} style={imagetabs.tabs.tab2.activestyle}>{imagetabs.tabs.tab2.title}</Button>
                      )
                      :
                      (
                        <Button className="imgmap-btn2" onClick={() => this.handleImageMap(imagetabs.tabs.tab2)} style={imagetabs.tabs.tab2.style}>{imagetabs.tabs.tab2.title}</Button>
                      )
                  }
                  {
                    this.state.activeimgmaptab == "3" ?
                      (
                        <Button className="imgmap-btn3 active-img-map-tab" onClick={() => this.handleImageMap(imagetabs.tabs.tab3)} style={imagetabs.tabs.tab3.activestyle}>{imagetabs.tabs.tab3.title}</Button>
                      )
                      :
                      (
                        <Button className="imgmap-btn3" onClick={() => this.handleImageMap(imagetabs.tabs.tab3)} style={imagetabs.tabs.tab3.style}>{imagetabs.tabs.tab3.title}</Button>
                      )
                  }
                </div>
              )
              :
              ('')
          }
          {
            imagetabs.numoftabs == "2" ?
              (
                <div className="imagemaptabs-div">
                  {
                    this.state.activeimgmaptab == "1" ?
                      (
                        <Button className="imgmap-btn1 active-img-map-tab" onClick={() => this.handleImageMap(imagetabs.tabs.tab1)} style={imagetabs.tabs.tab1.activestyle}>{imagetabs.tabs.tab1.title}</Button>
                      )
                      :
                      (
                        <Button className="imgmap-btn1" onClick={() => this.handleImageMap(imagetabs.tabs.tab1)} style={imagetabs.tabs.tab1.style}>{imagetabs.tabs.tab1.title}</Button>
                      )
                  }
                  {
                    this.state.activeimgmaptab == "2" ?
                      (
                        <Button className="imgmap-btn2 active-img-map-tab" onClick={() => this.handleImageMap(imagetabs.tabs.tab2)} style={imagetabs.tabs.tab2.activestyle}>{imagetabs.tabs.tab2.title}</Button>

                      )
                      :
                      (
                        <Button className="imgmap-btn2" onClick={() => this.handleImageMap(imagetabs.tabs.tab2)} style={imagetabs.tabs.tab2.style}>{imagetabs.tabs.tab2.title}</Button>
                      )
                  }
                </div>
              )
              :
              ('')
          }

        </div >
      )
    }
    else {
      return (
        <ImageMapper
          src={global.$sponsors.imagemap.image}
          map={global.$sponsors.imagemap}
          imgWidth={1920}
          width={this.state.imageWidth}
          onClick={area => this.imageMapClicked(area)}
          className="image-mapper"
        />
      )
    }
  }
  displayImage(src, map) {
    //console.log(src, map);
    return (
      <ImageMapper
        src={src}
        map={map}
        imgWidth={1920}
        width={this.state.imageWidth}
        onClick={area => this.imageMapClicked(area)}
        className="image-mapper"
      />
    )
  }
  getSponsorListUI(type) {
    return (
      <div>
        <div>
          {this.getRows()}
        </div>
        <br />
        <div>
          {
            global.$exhibitors != null && global.$exhibitors.newlayout != null && global.$exhibitors.newlayout.enable == true && type == "exhibitors" ?
              (
                <div>
                  <ExhibitorLayout
                    type={this.state.type}
                    bucket={this.state.bucket} />
                  <Modal
                    open={this.state.alloptionsopen}
                    onClose={this.onOpenAllOptions}
                    blockScroll={false}
                    className="sponsor-popup"
                    styles={{
                      modal: {
                        width: "100%"
                      }
                    }}
                    classNames={{
                      modal: 'speakersCustomModal',
                    }}
                    center>
                    <VirtualPopupTabs exhibitor={global.$sponsor} />
                  </Modal>
                </div>
              )
              :
              (
                <Row>
                  <Col md={10} className="offset-md-1">
                    <div className="exhibitor-search-div">
                      <Input type="text" name="search" className="exhibitor-search" value={this.state.search || ""} onChange={this.handleInputChange} placeholder="Search"><i class='fas fa-search'></i></Input>
                    </div>
                    

                    <Modal
                      open={this.state.alloptionsopen}
                      onClose={this.onOpenAllOptions}
                      blockScroll={false}
                      className="sponsor-popup"
                      styles={{
                        modal: {
                          width: "100%"
                        }
                      }}
                      classNames={{
                        modal: 'speakersCustomModal',
                      }}
                      center>
                      <VirtualPopupTabs exhibitor={global.$sponsor} onClose={this.onOpenAllOptions}/>
                    </Modal>
                  </Col>

                  <Col>
                      {this.displaySponsors()}
                  </Col>
                </Row>
              )
          }
        </div>
      </div>
    )
  }

  getSponsorjson() {
    ////console.log("sponsor json")
    var filename = this.state.type;

    if (this.state.type == "exhibitors" && global.$exhibitors.filename != null) {
      filename = global.$exhibitors.filename;
    }
    else if (this.state.type == "sponsors" && global.$sponsors.filename != null) {
      filename = global.$sponsors.filename;
    }

    let apiName = serviceNames.getWebsiteService();
    let path = ''; //replace this with the path you have configured on your API
    let myInit = {
      body:
      {
        'code': this.state.code,
        'command': 'getJsonFileFromS3',
        'bucket': this.state.bucket,
        'filename': filename
      },
      response: true,
      convertEmptyValues: true,
      headers: {}
    }
    //console.log(myInit);

    API.post(apiName, path, myInit).then(response => {
      //console.log ("response.data ===> " + response.data + "response.err ===> " + response.err);

      if (response.err == null && response.data != null) {
        let buf = Buffer.from(response.data.Body);
        //console.log(buf)
        //console.log(buf.toString());
        if (this.state.type == "sponsors") {
          global.$sponsorjson = JSON.parse(buf.toString());
          this.setState({ dataloaded: true });
        }
        if (this.state.type == "exhibitors") {
          global.$exhibitorjson = JSON.parse(buf.toString());
          this.setState({ dataloaded: true });
        }

      }
      else {
        ////console.log("getAttendeesTableObjects - failed!!");
        //console.log(response.err);
      }
    }
    ).catch(error => {
      ////console.log(error.response)
    });

  }

  getSponsorsForScrolling() {
    //console.log("sponsor json")
    let apiName = serviceNames.getWebsiteService();
    let path = ''; //replace this with the path you have configured on your API
    let myInit = {
      body:
      {
        'code': global.$code,
        'command': 'getJsonFileFromS3',
        'bucket': this.state.bucket,
        'filename': "sponsors"
      },
      response: true,
      convertEmptyValues: true,
      headers: {}
    }
    //console.log(myInit);

    API.post(apiName, path, myInit).then(response => {
      //console.log ("response.data ===> " + response.data + "response.err ===> " + response.err);

      if (response.err == null && response.data != null) {
        let buf = Buffer.from(response.data.Body);
        //console.log(buf)
        //console.log(buf.toString());
        global.$sponsorjson = JSON.parse(buf.toString());
        //console.log(global.$sponsorjson);

      }
      else {
        ////console.log("getAttendeesTableObjects - failed!!");
        //console.log(response.err);
      }
    }
    ).catch(error => {
      ////console.log(error.response)
    });
  }

//show the Exhibitor file
  renderDocuments(FILE){
    if(FILE !== ""){
        if(FILE.includes('.pdf')){
            return(
              <a href={`https://s3.amazonaws.com/event360live/public/${this.state.code}/Exhibitors/${FILE}`} target="_blank"><img src='https://event360live.s3.amazonaws.com/public/pdf.png' /></a>
            )
        }
        else if(FILE.includes('.ppt')){
            return(
              <a href={`https://s3.amazonaws.com/event360live/public/${this.state.code}/Exhibitors/${FILE}`} target="_blank"><img src='https://event360live.s3.amazonaws.com/public/ppt.png' /></a>
            )
        }
       
    }
  }



  displaySponsors() {
    var results = [];
    if (this.state.type == "sponsors") {
      results = global.$sponsorjson.filter(sponsor => {
        //console.log(sponsor)
        if (this.state.search == "")
          return true;
        else {
          if (sponsor.DESCRIPTION.toLocaleLowerCase().includes(this.state.search.toLocaleLowerCase()) || sponsor.NAME.toLocaleLowerCase().includes(this.state.search.toLocaleLowerCase()) || sponsor.BOOTH.toLocaleLowerCase().includes(this.state.search.toLocaleLowerCase()) || sponsor.KEYWORDS.toLocaleLowerCase().includes(this.state.search.toLocaleLowerCase()))
            return true;
          else
            return false;
        }
      })
    }
    if (this.state.type == "exhibitors") {
      results = global.$exhibitorjson.filter(exhibitor => {
        //console.log(exhibitor)
        if (this.state.search == "")
          return true;
        else {
          if (exhibitor.DESCRIPTION.toLocaleLowerCase().includes(this.state.search.toLocaleLowerCase()) || exhibitor.NAME.toLocaleLowerCase().includes(this.state.search.toLocaleLowerCase()) || exhibitor.BOOTH.toLocaleLowerCase().includes(this.state.search.toLocaleLowerCase()) || exhibitor.KEYWORDS.toLocaleLowerCase().includes(this.state.search.toLocaleLowerCase()))
            return true;
          else
            return false;
        }
      })
    }

    return results.map(sponsor => {
      
      var logo = "";
      if (this.state.type == "sponsors") {
        logo = `https://s3.amazonaws.com/event360live/public/${this.state.code}/exhibitors/` + sponsor.LOGO
      }
      if (this.state.type == "exhibitors") {
        logo = `https://s3.amazonaws.com/event360live/public/${this.state.code}/exhibitors/` + sponsor.LOGO
      }
      if (sponsor.DATA1 == "" && sponsor.VIDEO1 == "") {
        sponsor.DATA1 = "empty";
      }
      return (
        <div key={sponsor.ID}>
          {
            sponsor.TYPE.includes("T") ?
              (
                <div>
                  <Row>
                    <Col md={12}>
                      <div style={this.state.sponsorheaderstyle}>{sponsor.NAME}</div>
                    </Col>
                  </Row>
                  <br />
                </div>
              )
              :
              (
                <div style={{display:'flex',flexDirection:'column',justifyContent:'flex-start'}}>
                  <Row className="sponsors">
                    <Col >
                      <Row>
                        <Col md={2} className="offset-md-1">
                          {
                            sponsor.LOGO != "" ?
                              (
                                <a href={sponsor.URL} target="_blank"><img src={logo} style={this.state.sponsorimagestyle} /></a>
                              )
                              :
                              ('')
                          }
                          <div style={this.state.sponsorboothstyle}>{sponsor.BOOTH}</div>

                        </Col>
                        <Col md={7} className="sponsors-details-p">
                          {
                            sponsor.TYPE.includes("W") ?
                              (
                                <div>
                                  {
                                    sponsor.URL.includes("") ?
                                      (
                                        <div style={this.state.sponsornamestyle}>{sponsor.NAME}</div>
                                      )
                                      :
                                      (
                                        <a href={sponsor.URL} target="_blank"><div style={this.state.sponsornamestyle}>{sponsor.NAME}</div></a>
                                      )
                                  }
                                </div>
                              )
                              :
                              (
                                <div>
                                  <div style={this.state.sponsornamestyle}>{sponsor.NAME}</div>
                                </div>

                              )
                          }
                          
                          <div style={this.state.sponsordescstyle} className="exhibitor-desc">{ReactHtmlParser(sponsor.DESCRIPTION)}</div>
                          <div className='exhibitor-file'>
                              {this.renderDocuments(sponsor.FILE1)}
                              {this.renderDocuments(sponsor.FILE2)}
                              {this.renderDocuments(sponsor.FILE3)}
                          </div>

                          <div className="video-section">
                        
                            {
                              sponsor.DATA1.includes("empty") ?
                                (
                                  ''
                                )
                                :
                                (
                                  <Button style={this.state.playvideobuttonstyles} onClick={() => this.popup(sponsor)}>Learn More</Button>
                                )
                            }

                            {/*<SponsorVideo videourl={sponsor.VIDEO1} />  
                          {
                            this.state.enablevideochat.includes("Y") ?
                              (
                                <div>
                                  {/*<SponsorVideoChat chaturl={"https://apps4org.daily.co/virtualsummit-exhibitor1"} />}
                                  <SponsorVideoChat chaturl={sponsor.MEETING1} schedule={sponsor.SCHEDULE}/>
                                </div>
                              )
                              :
                              ('')
                          }*/}
                          </div>
                         
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <hr />
                </div>
              )
          }
        </div>
      );
    })
  }
  popup(sponsor) {
    //console..log(sponsor);
    global.$sponsor = sponsor;
    //this.onOpenAllOptions()
    this.setState({ alloptionsopen: true });
  }
  getRows() {

    var objs = global.$sponsors.rows

    if (this.props.type == "exhibitors") {
      objs = global.$exhibitors.rows
    }
    //console.log(objs)
    const tableObjs = []

    const rowsObj = objs.map((item) =>
      <div>
        {
          item.type.includes("image-text") ?
            (
              <div style={item.style}>
                <div dangerouslySetInnerHTML={{ __html: item.title }}></div>
                <Row className="justify-content-center">
                  <Col md={5} className="offset-1">
                    <div dangerouslySetInnerHTML={{ __html: item.image }}></div>
                  </Col>
                  <Col md={5} className="offset-1 ">
                    <div dangerouslySetInnerHTML={{ __html: item.text }}></div>
                    {
                      item.learnmore.enable.includes("Y") ?
                        (
                          <div style={this.state.leranmoredivstyle}>
                            <Button className="btn btn-primary" style={this.state.leranmorestyle}><Link to={item.learnmore.link} style={this.state.leranmorestyle}>{item.learnmore.title}</Link></Button>
                          </div>
                        )
                        :
                        ('')
                    }
                  </Col>
                </Row>
              </div>
            )
            :
            ('')
        }
        {
          item.type.includes("text-image") ?
            (
              <div style={item.style}>
                <div dangerouslySetInnerHTML={{ __html: item.title }}></div>
                <Row>
                  <Col md={5} className="offset-1" >
                    <div dangerouslySetInnerHTML={{ __html: item.text }}></div>
                    {
                      item.learnmore.enable.includes("Y") ?
                        (
                          <div style={this.state.leranmoredivstyle}>
                            <Button className="btn btn-primary" style={this.state.leranmorestyle}><Link to={item.learnmore.link} style={this.state.leranmorestyle}>{item.learnmore.title}</Link></Button>
                          </div>
                        )
                        :
                        ('')
                    }
                  </Col>
                  <Col md={5} className="offset-1">
                    <div dangerouslySetInnerHTML={{ __html: item.image }}></div>
                  </Col>
                </Row>

              </div>
            )
            :
            ('')
        }
        {
          item.type.includes("text-only") ?
            (
              <div style={item.style}>
                <div dangerouslySetInnerHTML={{ __html: item.title }}></div>
                <Row>
                  <Col md={10} className="offset-1">
                    <div dangerouslySetInnerHTML={{ __html: item.text }}></div>
                  </Col>
                </Row>
                {
                  item.learnmore.enable.includes("Y") ?
                    (
                      <div style={this.state.leranmoredivstyle}>
                        <Button className="btn btn-primary" style={this.state.leranmorestyle}><Link to={item.learnmore.link} style={this.state.leranmorestyle}>{item.learnmore.title}</Link></Button>
                      </div>
                    )
                    :
                    ('')
                }
              </div>
            )
            :
            ('')
        }
        {
          item.type.includes("image-only") ?
            (
              <div style={item.style}>
                <div dangerouslySetInnerHTML={{ __html: item.title }}></div>
                <Row>
                  <Col md={10} className="offset-1">
                    <div dangerouslySetInnerHTML={{ __html: item.image }}></div>
                  </Col>
                </Row>
                {
                  item.learnmore.enable.includes("Y") ?
                    (
                      <div style={this.state.leranmoredivstyle}>
                        <Button className="btn btn-primary" style={this.state.leranmorestyle}><Link to={item.learnmore.link} style={this.state.leranmorestyle}>{item.learnmore.title}</Link></Button>
                      </div>
                    )
                    :
                    ('')
                }
              </div>
            )
            :
            ('')
        }
      </div>
    );

    return (
      <div>
        {rowsObj}
      </div>
    )

  }

}
export default Sponsors;
