import React from 'react';
import { Row, Col, Button, FormGroup, Label, Input } from 'reactstrap';
import { API } from 'aws-amplify';
import CommonFunctions from "../RegCommon/CommonFunctions";
import EventCodeFunctions from "../RegCommon/EventcodeFunctions";
import TravconFunctions from "../RegCommon/CustomerFunctions/TravconFunctions";
var serviceNames = require('../../ServiceNameMapper');

class Discounts extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            discountvalue: "",
            message: "",
            discountprice: "0",
            selectedattendeeregoption: false,
            selectedguestregoption: "",
            totalregitems: "",
            totalamount: "0",
            loginstatus: 'new'
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.validateCustomPromocode = this.validateCustomPromocode.bind(this);

        if (global.$regloginstatus == true) {
            this.state.loginstatus = "update";
        }
    }
    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        let value = target.value
        this.setState({ [name]: value });
    }
    validateCode = () => {
    
        this.validateRegOptions(global.$regsectiondetails);
        if (this.state.selectedattendeeregoption == true) {
            if (global.$regemail != null && global.$regemail != "" && global.$regemail != " ") {
                if (Number(this.state.totalamount) > 0) {
                    this.state.message = "";
                    this.validatePromoCode();
                }
                else {
                    this.setState({ message: "Promotion code entered is not applicable for the selected registration option" });
                }
            }
            else {
                this.setState({ message: "Please enter all the required fields to apply the promotional code" });
            }
        }
    }
    render() {
        var discounts = global.$registrationform.setup[this.props.tabid].payment.discount;
        if (global.$code == "afpikar2021") {
            if (this.state.message.includes("$")) {
                this.state.message = this.state.message.replace("$", "&#8377;")
            }
        }

        return (
            <>
                <div className="reg-discounts-div" style={global.$registrationform.styles.discount.maindiv}>
                    <div className='reg-discounts-input'>
                        <Label for="discount" style={global.$registrationform.styles.discount.header}>{discounts.header}</Label>
                        <Input type="text" name="discountvalue" className="form-control" id="discount" value={this.state.discountvalue || ''} onChange={this.handleInputChange} />
                    </div>
                    <div className="discount-apply-btn">
                        <Button className="btn btn-discount" onClick={this.validateCode} style={global.$registrationform.styles.discount.button}>Apply</Button>
                    </div>
                </div>
                <p className="discount-message" style={global.$registrationform.styles.discount.message}><div dangerouslySetInnerHTML={{ __html: this.state.message }}></div></p>
            </>
        );
    }

    validateCustomPromocode() {
      
        if (this.state.discountvalue.includes("SPONSORSHIP-ITEMS") && this.state.loginstatus === "update") {
            const pendingamount = CommonFunctions.getLoggedInUserPendingAmount();
            
            this.setState({
                discountprice: parseFloat(pendingamount),
                message: "Code successfully applied."
            }, () => {
                global.$deferredpromo = "Y"
                global.$deferredpromoamount = parseFloat(pendingamount)
                global.$regdiscountinfo = this.state;
                this.props.onPromoCodeApplied();
            });
        }
       
    }

    validateRegOptions(regsectiondetails) {
        var selectedregoptions = CommonFunctions.getUserSelectedRegItems();
        var totalprice = 0;
        var totalregitems = [];
        var section1required = true;

        // handles reg option validation and adds total price
        var section1required = true;

        var groupnames = global.$registrationform.setup[this.props.tabid].groupnames;
        var section1 = CommonFunctions.getValueFromMeCard(groupnames, "one");
        section1required = global.$registrationform.setup[this.props.tabid].section1required;
        
        if (section1required == true && !CommonFunctions.isUserLoggedin()) {
            var result = false;
            selectedregoptions.map((regitem) => {
                if (regitem.groupname == section1) {
                    result = true;
                }
            })
    
            if (result == false) {
                this.setState({
                    message: "Please select the registration options to continue.",
                    isLoading: false
                });
                return;
            }
        }

        var pendingamount = CommonFunctions.getLoggedInUserPendingAmount(); // previous balance
        var registeredItems = CommonFunctions.getLoggedInUserRegItems(); // Previously registered Items
        var selectedRegItems = CommonFunctions.getUserSelectedRegItems(); // Currently  selected items to purchas

        totalprice = pendingamount;
        // handles reg option validation and adds total price
        // Step8: add final regitems and final price
        if (selectedRegItems.length != 0) {
            selectedregoptions.map((regitem) => {
                this.state.selectedattendeeregoption = true;
                // get total amount to pay
                if (CommonFunctions.isUserLoggedin() && EventCodeFunctions.isTravconExhibitor(global.$code)) {
                    var amount = TravconFunctions.findTheAmountfromSelectedItem(regitem, registeredItems);
                    
                    if (regitem.inputtype == "dropdown" && regitem.selecteddropdown) {
                        for (const item of regitem.selecteddropdown) {
                            totalprice += Number(item.price);
                            regitem.selectedprice = item.price
                        }
                    }                         
                    else {
                        if (regitem.quantity == "") {
                            totalprice = Number(totalprice) + Number(amount)
                        }
                        else if (regitem.quantity != "") {   // only when the raffle quantity exists
                            totalprice = Number(totalprice) + (Number(amount) * Number(regitem.quantity))
                        }
                    }
                     
                 }
                 else {

                    if (regitem.inputtype == "dropdown" && regitem.selecteddropdown) {
                        for (const item of regitem.selecteddropdown) {
                            totalprice += Number(item.price);
                            regitem.selectedprice = item.price
                        }
                    }
                    else {
                        if (regitem.quantity == "") {
                            totalprice = Number(totalprice) + Number(regitem.itemprice)
                       
                        }
                        else if (regitem.quantity != "") {   // only when the raffle quantity exists
                            totalprice = Number(totalprice) + (Number(regitem.itemprice) * Number(regitem.quantity))
                        }
                    }
                }
                // USED TO UPDATED THE BOOTHUPGRADE REGITEM PRICE ( PREVIOUSE REGITEM PRICE - NEW REGITEM )
                totalprice = CommonFunctions.getBoothUpgradeRegitemPrice(registeredItems,regitem,totalprice,this.props.tabid)

                // geting regitem id(data5)
                if (regitem.groupname.includes("guest")) {
                    totalregitems.push(regitem.data5);
                }
                else {
                    if(regitem.selecteddropdown){
                        regitem.selecteddropdown.map((item)=>{
                            totalregitems.push(item.itemid);
                        })
                    }else {
                        totalregitems.push(regitem.data5);
                    }                   
                }
            })
            
            // To prevent negative amount
            if (Number(totalprice) < 0) {
                totalprice = 0;
            }
        }
        else {
            if (CommonFunctions.isUserLoggedin()) {
                if (totalprice != null && totalprice != "" && totalprice != 0) {
                    // To prevent negative amount
                    if (Number(totalprice) < 0) {
                        totalprice = 0;
                    }
                }
            }
            else {
                this.setState({
                    message: "Please select the registration options to continue.",
                    isLoading: false
                });
            }
            // return;
        }

        if (EventCodeFunctions.isTravconExhibitor(global.$code) || EventCodeFunctions.isMetro(global.$code)) {
            if (CommonFunctions.isUserLoggedin()) {
                this.state.selectedattendeeregoption = true;
            }
            if (CommonFunctions.isUserLoggedin() && global.$regattendeeinfo != null && global.$regattendeeinfo.pendingamount != null && totalprice != null) {
                var regitemsjson = JSON.parse(global.$regattendeeinfo.regitemsjson);
                regitemsjson.map((regitem)=> totalregitems.push(`${regitem.name} - $${regitem.price}` ))
                
            }
            else if (CommonFunctions.isUserLoggedin() && global.$loggedinuser != null && global.$regattendeeinfo.pendingamount != null && totalprice != null) {
                regitemsjson = JSON.parse(global.$loggedinuser.regitemsjson);
                regitemsjson.map((regitem)=> totalregitems.push(`${regitem.name} - $${regitem.price}` ))
            }
        }

        
        this.state.totalregitems = totalregitems;
        this.state.totalamount = totalprice;
    }

    //NOTE: async/await
    validatePromoCode() {
        let apiName = serviceNames.getRegistrationService();
        let path = ''; //replace this with the path you have configured on your API
        let myInit = {
            body:
            {
                'code': global.$code,
                'command': 'validatePromotionalCode',
                'userid': global.$regemail,
                'regitems': this.state.totalregitems.toString(),
                'promocodes': this.state.discountvalue,
                'timezone': global.$settings.timezone,
                'totalamount': global.$totalamountfordiscount,
                'loginstatus': this.state.loginstatus
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
       
        API.post(apiName, path, myInit).then(response => {

            if (response.err == null && response.data != null) {
                
                global.$deferredpromo = response.data.deferredpromo;
                if (response.data.deferredpromo != null && response.data.deferredpromo == "Y") {
                    global.$deferredpromoamount = parseFloat(response.data.amount);
                }
                if (global.$registrationform.setup[this.props.tabid].payment.discount.promocode != null && this.state.discountvalue.includes(`${global.$registrationform.setup[this.props.tabid].payment.discount.promocode}`)) {
                    if (parseFloat(response.data.amount) == 0) {
                        this.setState({
                            discountprice: parseFloat(response.data.amount),
                            message: "Code successfully applied"
                        });
                    }
                    else {
                        this.setState({
                            discountprice: parseFloat(response.data.amount),
                            message: response.data.message
                        });
                    }
                }
                else {
                    this.setState({
                        discountprice: parseFloat(response.data.amount),
                        message: response.data.message
                    });
                }

                this.validateCustomPromocode()

                global.$regdiscountinfo = this.state;
                // Update parent component
                this.props.onPromoCodeApplied()
            }
            
        }
        ).catch(error => {
           
        });
    }
}
export default Discounts;

/*
this.props.data.map(item => {
                //console.log(item);
                //console.log(this.state.discountvalue);
                var begindate = '';
                var enddate = '';
                var disablecoupon = false;
                if (item.begindate != " " && item.enddate != " ") {
                    //console.log(item)
                    begindate = Date.parse(item.begindate);
                    enddate = Date.parse(item.enddate);
                    //console.log(begindate, enddate);

                    if (begindate >= enddate) {
                        //console.log("begindate => enddate")
                        disablecoupon = true;
                    }
                    else if (begindate < enddate) {
                        //console.log("begindate <= enddate")
                        disablecoupon = false;
                    }
                }
                if (disablecoupon == false) {
                    if (item.type == "registration-discount" && item.code.toLowerCase() == this.state.discountvalue.toLowerCase()) {

                        /*
                            localeCompare returns -1 if string1 is sorted before string2
                            localeCompare returns 0 if the two strings are equal
                            localeCompare returns 1 if string1 is sorted after string2

                       if ((item.code).localeCompare(this.state.discountvalue) == 0) {
                        //console.log("0")
                        if (item.value.includes("all")) {
                            if (this.state.currency == "dollar") {
                                this.setState({ message: "Discount $" + item.amount + " Applied !!" });
                            }
                            else if (this.state.currency == "rupee") {
                                this.setState({ message: "Discount " + item.amount + "/- Applied !!" });
                            }
                            global.$regdiscountinfo = this.state;
                        }
                        else {
                            if (item.data1 != " " && item.data1 != "")   //users are coming from the data1 field
                            {
                                //console.log("emails exist");
                                if (item.data1.includes(global.$regemail)) {
                                    if (this.state.currency == "dollar") {
                                        this.setState({ message: "Discount $" + item.amount + " Applied !!" });
                                    }
                                    else if (this.state.currency == "rupee") {
                                        this.setState({ message: "Discount " + item.amount + "/- Applied !!" });
                                    }
                                    global.$regdiscountinfo = this.state;
                                }
                                else {
                                    //console.log("user not match")
                                    this.setState({ message: "Sorry, You are not eligible for this discount" });
                                }
                            }
                            else {
                                //console.log("no emails exist");
                                if (this.state.currency == "dollar") {
                                    this.setState({ message: "Discount $" + item.amount + " Applied !!" });
                                }
                                else if (this.state.currency == "rupee") {
                                    this.setState({ message: "Discount " + item.amount + "/- Applied !!" });
                                }
                                global.$regdiscountinfo = this.state;

                            }
                        }
                    }
                    else {
                        //console.log("1/-1")
                        this.setState({ message: "Error !!, Verify the code entered." });
                        global.$regdiscountinfo = "";
                    }
                }
                else if (item.type == "registration-discount" && item.code.toLowerCase() != this.state.discountvalue.toLowerCase()) {
                    //console.log("1/-1")
                    //console.log("false");
                    this.setState({ message: "Error !!, Verify the code entered." });
                    global.$regdiscountinfo = "";
                }
            }
            else if (disablecoupon == true) {
                this.setState({ message: "This coupon has expired" });
            }
        })
*/