import React from 'react';
import { Row, Col, CarouselItem, CarouselIndicators, CarouselControl, Carousel } from 'reactstrap';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Avatar from 'react-avatar';
import ReactHtmlParser from 'react-html-parser';
import Icon from '@mdi/react';
import { mdiWeb, mdiEmail, mdiPhone, mdiLinkedin } from '@mdi/js';

class SpeakerPopup3 extends React.Component {
    constructor(props) {

        super(props);
        this.state = {
        };
    }


    render() {

        return (
            <div>
                <Tabs className="tabs-menu" defaultActiveKey="info" transition={false} id="tabs" >
                    <Tab eventKey="info" title="Info" className="tab-item">
                        {this.displaySpeakerInfo(this.props.speaker)}
                    </Tab>
                </Tabs>
            </div>
        )
    }

    displaySpeakerInfo(speaker) {
        var image = "";
        if (speaker.PHOTO == "profile.png" || speaker.PHOTO == " " || speaker.PHOTO == "") {
            image = "empty";
        }
        else {
            image = `https://s3.amazonaws.com/event360live/public/${global.$code}/speakers/` + speaker.PHOTO
        }
        var speakers = global.$config['speakers3'].popup;
        return (
            <div className="speaker-popup-div" style={speakers.styles.maindiv}>
                <Row>
                    <Col md={3}>
                        <div className="speaker-popup-img-div" style={speakers.styles.imgdiv}>
                            {
                                image.includes("empty") ?
                                    (
                                        <div>
                                            <Avatar className="speaker3-popup-avatar" name={`${speaker.NAME}`} style={speakers.styles.avatarimg} />
                                        </div>
                                    )
                                    :
                                    (
                                        <div>
                                            <img src={image} className="speaker3-popup-img" style={speakers.styles.img} />
                                        </div>
                                    )
                            }
                        </div>
                        {
                            speakers.socialicons.enable == true ?
                                (
                                    <div className="speakers3-socialicons-div">
                                        {
                                            speakers.socialicons.url.enable == true && speaker.URL != null && speaker.URL != "" ?
                                                (
                                                    <a href={speaker.URL} target="_blank" style={speakers.socialicons.url.style}><Icon path={mdiWeb} size={1.7} color="#828783" className="speakers3-contact-icon" /></a>
                                                )
                                                :
                                                ('')
                                        }
                                        {
                                            speakers.socialicons.email.enable == true && speaker.EMAIL != "" && speaker.EMAIL != " " ?
                                                (
                                                    <a href={"mailto:" + speaker.EMAIL} style={speakers.socialicons.email.style}><Icon path={mdiEmail} size={1.7} color="#828783" className="speakers3-contact-icon" /></a>
                                                )
                                                :
                                                ('')
                                        }
                                        {
                                            speakers.socialicons.phone.enable == true && speaker.PHONE != "" && speaker.PHONE != " " ?
                                                (
                                                    <a href={"tel:" + speaker.PHONE}><Icon path={mdiPhone} size={1.7} color="#828783" className="speakers3-contact-icon" /></a>
                                                )
                                                :
                                                ('')
                                        }
                                        {
                                            speakers.socialicons.linkedin.enable == true && speaker.LINKEDIN != "" && speaker.LINKEDIN != " " ?
                                                (
                                                    <a href={speaker.LINKEDIN}><Icon path={mdiLinkedin} size={1.7} color="#828783" className="speakers3-contact-icon" /></a>
                                                )
                                                :
                                                ('')
                                        }
                                    </div>
                                )
                                :
                                ('')
                        }
                    </Col>
                    <Col md={9}>
                        {console.log(speaker)}
                        <div className="speaker3-popup-div2" style={speakers.styles.infodiv}>
                            <h4 className="speaker3-popup-name" style={speakers.styles.name}><b>{speaker.NAME}</b></h4>
                            <p className="speaker3-popup-title" style={speakers.styles.title}>{speaker.TITLE}</p>
                            <p className="speaker3-popup-company" style={speakers.styles.company}>{speaker.COMPANY}</p>
                            <p className="speaker3-popup-desc" style={speakers.styles.desc}>{ReactHtmlParser(speaker.DESCRIPTION)}</p>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}
export default SpeakerPopup3;
