import React from 'react'
import Modal from "react-responsive-modal";
import Icon from '@mdi/react';
import { mdiAlertCircleOutline } from '@mdi/js';

function RegOptionError({showPopup,closePopup,errorMessage}) {


  return (
    <div>
        <Modal
            open={showPopup}
            onClose={closePopup}
            blockScroll={false}
            closeOnOverlayClick={true}
            styles={{
                modal: {minWidth: '350px',borderRadius:'10px' },
                closeIcon: { display:'none' }
            }}
            center>
            <div className='error-message-wrapper'>
                <Icon path={mdiAlertCircleOutline} size={1} color={'red'}/>
                <h6>{errorMessage}</h6>
            </div>
                       
        </Modal>
    </div>
  )
}

export default RegOptionError