/* eslint-disable */
/* eslint-disable */
import React, { PureComponent, Provider } from 'react';
import Amplify, { API, Auth, Analytics } from 'aws-amplify';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import './LogInForm.css';
import { HourGlass } from 'react-awesome-spinners';
import { TabContent, TabPane, Nav, NavItem, NavLink, FormGroup, Label, Input, Button, Card, CardHeader, CardBlock, Row, Col } from 'reactstrap';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import Icon from '@mdi/react';
import { mdiAccountGroup, mdiAccountMultiple, mdiAccountTie, mdiOnepassword, mdiAccountCircle, mdiKey, mdiEmail,mdiEye,mdiEyeOff } from '@mdi/js';
import SucessfullLogin from './SucessfullLogin';
import LoginHome from './LoginHome';
import LoginIntro from './LoginIntro';
var serviceNames = require('../ServiceNameMapper')

/*
console.log(global)
console.log("Login: + " + global.$poolid)
console.log("Login: + " + global.$clientid)
*/

class LogInForm extends React.Component {

  constructor(props) {

    super(props);

    var loginhome = "";
    if (global.$login.loginhome == null) {
      loginhome = "successlogin";
    }
    else {
      loginhome = global.$login.loginhome;
    }

    var showloginintrovideo = false;
    if (global.$login.enableintrovideo != null) {
      showloginintrovideo = global.$login.enableintrovideo;
    }

    this.state = {
      code: global.$code,
      userid: "",
      userpassword: "",
      selected: false,
      loggedin: false,
      message: "",
      showPassword: false,
      isLoading: false,
      spinner: true,
      newpwd: '',
      otp: '',
      newpwdfirsttime: '',
      showintro: false,
      loginformstyles: global.$login.form.styles,
      submitbuttonstyles: global.$login.buttons.submit.styles,
      emailstyle: global.$login.form.fields.emailstyles,
      passwordstyle: global.$login.form.fields.passwordstyles,
      forgotpasswordbuttonstyles: global.$login.buttons.forgotpassword.styles,
      changepasswordstyles: global.$profile.changepassword.styles,
      iconstyle: global.$login.form.iconstyles,
      pagestyle: global.$login.pagestyles,
      button1styles: global.$login.buttons.button1.styles,
      button2styles: global.$login.buttons.button2.styles,
      button3styles: global.$login.buttons.button3.styles,
      b1linkstyles: global.$login.buttons.button1.linkstyles,
      b2linkstyles: global.$login.buttons.button2.linkstyles,
      b3linkstyles: global.$login.buttons.button3.linkstyles,
      messagestyles: global.$login.messagestyles,
      loginhome: loginhome,
      showloginintrovideo: showloginintrovideo,
      regstatus: false,
      emailPlaceholder:"Enter your registered email",
      passwordPlaceholder:"Enter your password.",
      showPassword: false
    };
    if (this.state.code == "travcon2022exhibitor") {
      this.state.code = "travcon2022";
    }
    if (this.state.code == "travcon2023exhibitor") {
      this.state.code = "travcon2023";
    }
    if (this.state.code == "travcon2024exhibitor") {
      this.state.code = "travcon2024";
    }
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleDisplayNewPassword = this.handleDisplayNewPassword.bind(this);
    this.handleDisplyOTPField = this.handleDisplyOTPField.bind(this);
    this.processChangePassword = this.processChangePassword.bind(this);
    this.menuReloadHandler = this.menuReloadHandler.bind(this);
    this.handleShowPassword = this.handleShowPassword.bind(this)

    if (global.$loginstatus == "undefined") {
      global.$loginstatus = "false";
    }
    //console.log(global.$poolid)
    //console.log(global.$clientid)
    if (global.$login.form && global.$login.form.placeholder != null) {
      if (global.$login.form.placeholder.email && global.$login.form.placeholder.email != null) {
        this.state.emailPlaceholder = global.$login.form.placeholder.email
      }
      if (global.$login.form.placeholder.password && global.$login.form.placeholder.password != null) {
        this.state.passwordPlaceholder = global.$login.form.placeholder.password
      }
    }
    
  }

  menuReloadHandler() {
    this.props.onMenuReload();
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }
  showPassword = (e) => {
    e.preventDefault();
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  };

  handleSubmit = (event) => {
    if (this.validateInputs()) {


      Amplify.configure({
        Auth: {
          //identityPoolId:         'us-east-1:f21ee086-a0ae-4bfc-a1b7-58913104d709', //exhibitors_sponsors_ip
          //region:                 'us-east-1',

          //userPoolId:             'us-east-1_LtVLdp322', //us-east-1_LtVLdp322 - AppAdmin 
          //userPoolWebClientId:    '2cbpuu5f2vql93d28pk0he39do' //2cbpuu5f2vql93d28pk0he39do  - AppAdmin

          //userPoolId:             'us-east-1_cx9zgTYXf',        //us-east-1_cx9zgTYXf - devtest 
          //userPoolWebClientId:    '5dfap44mptvq1b5bie84eaoav8'  //4ul2rsbo8viha7u2lfv7s32pej  - devtest without secretkey
          //userPoolWebClientId:    '4ul2rsbo8viha7u2lfv7s32pej' //4ul2rsbo8viha7u2lfv7s32pej  - devtest with secretkey

          userPoolId: global.$poolid,    //us-east-1_cx9zgTYXf - devtest 
          userPoolWebClientId: global.$clientid,  //4ul2rsbo8viha7u2lfv7s32pej  - devtest without secretkey
        },
      })


      //this.setState({loggedin:true}) 
      this.state.userid = this.state.userid.toLowerCase();
      this.setState({ spinner: false });
      this.getAttendeeInfo();

      // if(this.state.regstatus == true)
      // {
      //   this.beginAuth()
      // }

      this.setState({ isLoading: true });
    }
    else {
      // this.setState({loggedin:false})
    }
  }
  validateInputs() {
    const { userid, userpassword } = this.state;
    const { role } = this.state;
    if (userid === '') {
      this.setState({ message: 'Enter the email id' });
      return false;
    }
    if (userpassword === '') {
      this.setState({ message: 'Enter the password' });
      return false;
    }
    else {
      return true;
    }
  }
  validateInputsForChangePassword() {
    const { userid } = this.state;
    if (userid === '') {
      this.setState({ message: 'Enter the email id' });
      return false;
    }
    else {
      return true;
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const name = target.name;
    let value = target.value;

    //console.log(name + " = " + value);

    this.setState({
      [event.target.name]: event.target.value
    });
  }

  async beginAuth() {
    try {
      //console.log('beginAuth ' + this.state.userid + ' ' + this.state.userpassword);

      const user = await Auth.signIn(this.state.userid, this.state.userpassword);

      global.$loginid = this.state.userid
      global.$password = this.state.userpassword

      //console.log(user)

      //console.log(global.$regstatus);
      /*if(global.$regstatus == "Confirmed")
      {*/
      if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {

        //console.log('SMS_MFA || SOFTWARE_TOKEN_MFA');

        // You need to get the code from the UI inputs
        // and then trigger the following function with a button click
        //const code = getCodeFromUserInput();
        // If MFA is enabled, sign-in should be confirmed with the confirmation code

        /*
        const loggedUser = await Auth.confirmSignIn(
            user,   // Return object from Auth.signIn()
            code,   // Confirmation code  
            mfaType // MFA Type e.g. SMS, TOTP.
        );
 
        
        */
      }
      else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {

        //console.log('NEW_PASSWORD_REQUIRED');

        //New User Created in //console 

        //var pwd = prompt("Enter New Password:", "")
        document.getElementById("newpwdfirsttime").style.display = "block";
        document.getElementById("newpwdfirsttimelabel").style.display = "block";
        var pwd = this.state.newpwdfirsttime
        //console.log(pwd);

        const loggedUser = await Auth.completeNewPassword(
          user,  // the Cognito User Object
          pwd   // the new password
        );
        //console.log(pwd)
        pwd = pwd.trim();

        if (pwd !== "") {
          //console.log(pwd);
          //global.$loginstatus = 'true';
          //this.props.onMenuReload();
          global.$password = pwd;
          this.setState({
            showintro: true,
            loggedin: true
          })
          this.updateUserSignin();
          this.props.onMenuReload();
          global.$loginstatus = 'true';
          this.setState({
            message: "",
            isLoading: false
          });
          //this.getAttendeeInfo()
        }
        else {
          //console.log(false);
          this.setState({ loggedin: false })
          global.$loginstatus = 'false';
        }
        //console.log(loggedUser)
      }
      else if (user.challengeName === 'MFA_SETUP') {
        // This happens when the MFA method is TOTP
        // The user needs to setup the TOTP before using it
        // More info please check the Enabling MFA part
        //Auth.setupTOTP(user);
        this.setState({
          isLoading: false
        });
      }
      else {
        // The user directly signs in
        //console.log('user logged-in successfully')
        ////console.log(user)

        //this.state.message = "Succssfully Loggedin";

        //this.getAttendeeInfo();

        this.updateUserSignin();
        this.props.onMenuReload();
        global.$loginstatus = 'true';

        this.setState({
          isLoading: false,
          loggedin: true,
          message: ''
        });
      
        //global.$loginstatus = 'true';     
        //console.log(global.$loginstatus);
        //this.setState({isLoading: false});
        //this.setState({ loggedin: true });


        //Console.log("Calling onMenuReload()");
        //this.props.onMenuReload();
      }
      /*}
      else
      {
        this.setState({
        message: "Your registration is not confirmed. Please try again once confirmed",
        isLoading: false,
        disabled: false,});  
      }*/

    }
    catch (err) {
      //console.log(err)
      //this.state.message = "Unable to login.  Please check your credentials and try again. "  
      //this.setState({ disabled: false });
      //this.setState({ isLoading: false });

      if (err.message == "Password does not conform to policy: Password not long enough") {
        this.state.message = "Minimum password length should be atleast eight characters"
      }
      else {
        this.state.message = err.message;
      }

      //global.$loginstatus = 'true';   // comment for live, uncomment for local login testing

      this.setState({
        isLoading: false,
        disabled: false,
        message: err.message
      });

      this.forceUpdate();
    }
  }

  cancelChangePassword = event => {

    this.setState({ message: '' })
    document.getElementById("newpwd").style.display = "none";
    document.getElementById("newpwdlabel").style.display = "none";
    document.getElementById("newpwdbutton").style.display = "none";
    document.getElementById("cancelbutton").style.display = "none";
    document.getElementById("otp").style.display = "none";
    document.getElementById("otplabel").style.display = "none";
    document.getElementById("otpbutton").style.display = "none";
    document.getElementById("newpwdfirsttime").style.display = "none";
    document.getElementById("newpwdfirsttimelabel").style.display = "none";
    document.getElementById("password-field").style.display = "block";
    document.getElementById("login-btns-div").style.display = "block";

  }

  handleDisplayNewPassword = event => {

    if (this.state.userid != "") {
      this.setState({ message: '' })
      document.getElementById("newpwd").style.display = "block";
      document.getElementById("newpwdlabel").style.display = "block";
      document.getElementById("newpwdbutton").style.display = "block";
      document.getElementById("cancelbutton").style.display = "block";
      document.getElementById("password-field").style.display = "none";
      document.getElementById("login-btns-div").style.display = "none";
    }
    else {
      this.setState({ message: 'Enter your registered email' });
    }
  }
  handleDisplyOTPField = event => {
    //console.log(this.state.newpwd, this.state.newpwd.length)
    if (this.state.newpwd.length >= 8) {
      Amplify.configure({
        Auth: {
          userPoolId: global.$poolid,    //us-east-1_cx9zgTYXf - devtest 
          userPoolWebClientId: global.$clientid,  //4ul2rsbo8viha7u2lfv7s32pej  - devtest without secretkey
        },
      })
      this.state.userid = this.state.userid.toLowerCase()
      this.handleChangePassword();
    }
    else {
      this.setState({ message: "Minimum password length should be atleast 8 characters" });
    }

  }
  handleChangePassword() {
    //console.log(this.state.newpwd, this.state.userid)
    Auth.forgotPassword(this.state.userid)
      .then(data => {
        console.log(data)
        if (this.state.newpwd != "") {

          this.setState({ message: '' })
          document.getElementById("newpwd").style.display = "none";
          document.getElementById("newpwdlabel").style.display = "none";
          document.getElementById("newpwdbutton").style.display = "none";
          document.getElementById("otp").style.display = "block";
          document.getElementById("otplabel").style.display = "block";
          document.getElementById("otpbutton").style.display = "block";
          document.getElementById("cancelbutton").style.display = "block";
          document.getElementById("newpwdfirsttime").style.display = "none";
          document.getElementById("newpwdfirsttimelabel").style.display = "none";
          document.getElementById("password-field").style.display = "none";
          document.getElementById("login-btns-div").style.display = "none";
        }
      })
      .catch(err => {
        console.log(err)
        if (err.code == "LimitExceededException") {
          this.setState({ message: "Attempt limit exceeded, please try after some time." });
        }
        else {
          this.setState({ message: 'Unable to change password please try again' })
        }
      })

  }
  processChangePassword = event => {

    var new_password = this.state.newpwd;
    var code = this.state.otp;

    this.setState({ message: '' })


    //console.log(new_password);
    //console.log(code);

    //var new_password = prompt("Enter New Password:", "")

    //var code = prompt("Enter Code Sent to " + data.CodeDeliveryDetails.Destination, "")

    //console.log(this.state.userid, code, new_password);
    Auth.forgotPasswordSubmit(this.state.userid, code, new_password)
      .then(data => {
        console.log(data);
        //console.log(data)document.getElementById("newpwd").style.display = "none";
        document.getElementById("newpwdlabel").style.display = "none";
        document.getElementById("newpwdbutton").style.display = "none";
        document.getElementById("otp").style.display = "none";
        document.getElementById("otplabel").style.display = "none";
        document.getElementById("otpbutton").style.display = "none";
        document.getElementById("cancelbutton").style.display = "none";
        document.getElementById("newpwdfirsttime").style.display = "none";
        document.getElementById("newpwdfirsttimelabel").style.display = "none";
        document.getElementById("password-field").style.display = "block";
        document.getElementById("login-btns-div").style.display = "block";
        this.setState({ message: 'Password successfully changed. Enter your new password to login' })
        //this.setState({ message: 'Password successfully changed' });
        this.setState({ userpassword: '' });
      })
      .catch(err => {
        console.log(err)
        this.setState({ message: err.message })
        //this.setState({ message: 'Unable to change password' });
      })

    //////console.log("preocessChangePassword - success")
  }
  render() {

    const { showPassword } = this.state;
    const { message } = this.state;
    //console.log(this.context);

    global.$variables = this.state //global variable
    //console.log("eventcode: "+this.state.code)

    ////console.log(global.$variables);
    //  <SucessfullLogin />
    //  <LoginHome s3={global.$s3} code={this.state.code} bucket={global.$s3bucket}/>
    //  Todo:  add loginhome = '3dhall'
    //     

    Analytics.record({ name: 'PageView', attributes: { name: 'eventsites-' + this.state.code + '-loginpage' } });
    return (

      <div>
        {
          global.$loginstatus.includes('true') ?
            (
              <div>
                {
                  /*
                  this.state.loginhome.includes("3dhall")?
                  (
                    <LoginHome />
                  )
                  :
                  (
                    <SucessfullLogin />
                  )
                  */
                  <SucessfullLogin {...this.props} onMenuReload={this.menuReloadHandler} />

                }
                {
                  this.state.showloginintrovideo == true ?
                    (
                      this.state.showintro == true ?
                        (
                          <LoginIntro openpopup={true} type="firstlogin" />
                        )
                        :
                        ('')
                    )
                    :
                    ('')
                }
              </div>
            )
            :
            (
              <div style={this.state.pagestyle}>
                <Row>
                  <Col md={7}>
                    <div dangerouslySetInnerHTML={{ __html: global.$login.leftcol }}></div>
                  </Col>
                  <Col md={5}>
                    <br />
                    <Row>
                      <Col md={12}>
                        <form style={this.state.loginformstyles} className="login-form">
                          <div className="login-user-icon-div">
                            {/*<i className='fas fa-user-circle login-circle-icon' style={this.state.iconstyle}></i>*/}
                            <Icon path={mdiAccountCircle} size={4} className="login-circle-icon" />
                          </div>
                          <div dangerouslySetInnerHTML={{ __html: global.$login.form.header }}></div>
                          <FormGroup>
                            <Label for="email" style={this.state.emailstyle}><Icon path={mdiEmail} size={0.9} className="login-icons" />Email*</Label>
                            <Input type="email" name="userid" id="userid" placeholder={this.state.emailPlaceholder} value={this.state.userid || ''} onChange={this.handleInputChange} />
                          </FormGroup>
                          <FormGroup style={{ marginLeft: "0px", width: "100%" }} id="password-field">
                            <Label for="userpassword" style={this.state.passwordstyle}><Icon path={mdiKey} size={0.9} className="login-icons" />Password*</Label>
                            <div className='password-wrapper'>
                              <Input type={this.state.showPassword ? "text" : "password"} name="userpassword" id="userpassword" className="show-password" placeholder={this.state.passwordPlaceholder} value={this.state.userpassword || ''} onChange={this.handleInputChange} />
                              <Icon path={this.state.showPassword ? mdiEye : mdiEyeOff} size={0.9} className="login-eye" onClick={this.handleShowPassword}/>
                            </div>
                            {/*<Row>
                              <Col md={11}>
                                <Input type={showPassword ? 'text' : 'password'} name="userpassword" id="userpassword" className="show-password" placeholder="Enter your password" value={this.state.userpassword || ''} onChange={this.handleInputChange} />
                              </Col>
                              <Col md={1}>
                                <button
                                  className="btn btn-primary show-pwd"
                                  onClick={e => this.showPassword(e)}
                                  type="button"
                                ><EyeIcon />
                                </button>
                              </Col>
                          </Row>*/}
                          </FormGroup>
                          <FormGroup className="form-labels">
                            <Label for="newpwdfirsttime" id="newpwdfirsttimelabel" style={this.state.emailstyle}><Icon path={mdiKey} size={0.9} className="login-icons" />Enter New Password</Label>
                            <Input type="text" style={{ display: "none" }} name="newpwdfirsttime" className="form-control-sm" id="newpwdfirsttime" value={this.state.newpwdfirsttime || ''} onChange={this.handleInputChange} />
                          </FormGroup>
                          <FormGroup className="form-labels">
                            <Label for="newpwd" id="newpwdlabel" style={this.state.emailstyle}><Icon path={mdiKey} size={0.9} className="login-icons" />New Password</Label>
                            <Input type="text" style={{ display: "none" }} name="newpwd" className="form-control-sm" id="newpwd" value={this.state.newpwd || ''} onChange={this.handleInputChange} />
                          </FormGroup>
                          <FormGroup className="form-labels">
                            <Label for="otp" id="otplabel" style={this.state.emailstyle}><Icon path={mdiOnepassword} size={0.9} className="login-icons" />Enter verification code sent to your email</Label>
                            <Input type="text" style={{ display: "none" }} name="otp" className="form-control-sm" id="otp" value={this.state.otp || ''} onChange={this.handleInputChange} />
                          </FormGroup>
                          <br />
                          <div id="changepassword-result" style={this.state.messagestyles}></div>
                          <div className="alert" role="alert" id="message_result" style={this.state.messagestyles}>
                            {message}
                          </div>
                          <div style={{ textAlign: "center" }} id="login-btns-div">
                            <Row style={{ textAlign: "center" }}>
                              <Col>
                                <Button className={"btn-login"} onClick={this.handleSubmit} style={this.state.submitbuttonstyles}>
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    className="Loading-button"
                                    variant="light"
                                    hidden={!this.state.isLoading}
                                  />{global.$login.buttons.submit.text}
                                </Button>
                              </Col>
                            </Row>

                            <Button className="pwd-button" style={this.state.forgotpasswordbuttonstyles} onClick={this.handleDisplayNewPassword}>
                              Forgot the password? {global.$login.buttons.forgotpassword.text}
                            </Button>
                          </div>
                          <div className="btns-div offset-md-2">
                            <div className="new-pwd-button">
                              <button type="button" id="newpwdbutton" style={this.state.changepasswordstyles} onClick={this.handleDisplyOTPField}>Change</button>
                            </div>
                            <div className="otp-button">
                              <button type="button" id="otpbutton" style={this.state.changepasswordstyles} onClick={this.processChangePassword} >Ok</button>
                            </div>
                            <div className="cancel-button">
                              <button type="button" id="cancelbutton" style={this.state.changepasswordstyles} onClick={this.cancelChangePassword}>Cancel</button>
                            </div>
                          </div>
                        </form>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            )
        }
      </div>
    );
  }

  validateJson(data3) {
    try {
      let companyid = JSON.parse(data3);
      return companyid;
    } catch (error) {
      return "";
    }
  }

  getAttendeeInfo() {
    let apiName = serviceNames.getUserService();
    let path = ''; //replace this with the path you have configured on your API
    let myInit = {
      body:
      {
        'command': 'getUserProfile',
        'code': global.$code,
        'userid': this.state.userid,
      },
      response: true,
      convertEmptyValues: true,
      headers: {}
    }
    //console.log(myInit);

    API.post(apiName, path, myInit).then(response => {
      //console.log(response);

      if (response.err == null && response.data != null) {
        //console.log(response);

        //console.log("getAttendeeInfo - calling getS3PrivateImg")

        let userObj = this.getUserObject(response.data.Item)
        global.$attendeeDetails = userObj;
        
        //console.log(userObj)
        global.$fname = userObj.fname
        global.$lname = userObj.lname
        global.$email = userObj.email
        global.$userid = userObj.userid
        global.$company = userObj.company
        global.$phone = userObj.phone
        global.$title = userObj.title
        global.$regid = userObj.regid
        global.$memberid = userObj.memberid
        global.$attendeetype = userObj.userstype
        global.$userstype = userObj.userstype
        global.$regstatus = userObj.regstatus
        global.$attendeeguests = userObj.guests
        global.$loggedinuserregitems = userObj.regitemsjson;
        global.$regitemsjson = userObj.regitemsjson;
        global.$loggedinuser = userObj; // for registration form to edit
        
        if (userObj.data3 !="" && userObj.data3 != " ") {
            //let jsonData = JSON.parse(userObj.data3);
            let jsonData = this.validateJson(userObj.data3);
            
            if (jsonData.companyid && jsonData.companyid != null) {
                global.$companyid = jsonData.companyid
            }
            if (jsonData.sponsorid && jsonData.sponsorid != null) {
                global.$sponsorid = jsonData.sponsorid
            }
        }
  

        if (userObj.photo == "" || userObj.photo == " " || userObj.photo == "common_profile.png") {
          global.$imageUrl = "common_profile.png"
        }
        else {
          //global.$imageUrl = userObj.photo;  
          this.getS3PrivateImg(userObj.photo)
        }

        if (global.$regstatus == "Confirmed" || global.$regstatus == "Checkin") {
          // this.updateUserSignin();
          // this.props.onMenuReload();
          // global.$loginstatus = 'true';
          // this.setState({
          //   message: "",
          //   isLoading: false
          // });
          //this.setState({regstatus: true});
          if (global.$code == "travcon2022exhibitor" || global.$code == "travcon2023exhibitor" || global.$code == "travcon2023exhibitor") {
            if (global.$userstype == "Exhibitor" || global.$userstype == "Exhibitor Admin") {
              this.beginAuth()
            }
            else
            {
              this.setState({
                message: "Login is allowed only for exhibitor primary contact or admin.",
                isLoading: false,
                disabled: false,
              });
            }
          }
          else {
            this.beginAuth()
          }
        }
        else {
          this.setState({
            message: "Your registration is not confirmed. Please try again once confirmed",
            isLoading: false,
            disabled: false,
          });
        }
        //this.setState({ userphoto: "/event360/"+global.$code + "/upload/profiles/"+userObj.photo })

        //console.log("getAttendeeInfo - calling getS3PrivateImg")


      }
      else {
        //console.log("getAttendeeInfo - error")
      }
    }
    ).catch(error => {
      //console.log(error)
      this.setState({
        message: "Unable to login. Please check your credentials and try again.",
        isLoading: false
      });
    });
  }

  getUserObject(objs) {
    //var obj = objs[0] 
    //console.log( objs)

    var newObj = Object()
    newObj.userid = objs.userid.S
    newObj.fname = objs.fname.S
    newObj.lname = objs.lname.S
    newObj.photo = objs.photo.S
    newObj.company = objs.company.S
    newObj.phone = objs.phone.S
    newObj.title = objs.title.S
    newObj.email = objs.email.S
    newObj.country = objs.country.S
    newObj.state = objs.state.S
    newObj.street = objs.street.S
    newObj.city = objs.city.S
    newObj.state = objs.state.S
    newObj.zip = objs.zip.S
    newObj.linkedin = objs.linkedin.S
    newObj.twitter = objs.twitter.S
    newObj.fb = objs.fb.S
    newObj.regid = objs.regid.S
    newObj.regtype = objs.regtype.S
    newObj.memberid = objs.memberid.S
    newObj.visitpoints = objs.visitpoints.N
    newObj.activitypoints = objs.activitypoints.N
    newObj.totalpoints = objs.totalpoints.N
    newObj.sessionpoints = objs.sessionpoints.N
    newObj.regstatus = objs.regstatus.S
    newObj.password = objs.password.S
    newObj.myexpertise = objs.myexpertise.S
    newObj.myinterests = objs.myinterests.S
    newObj.validscans = objs.validscans.S
    newObj.currentlocation = objs.currentlocation.S
    newObj.lastseen = objs.lastseen.S
    newObj.showdirectory = objs.showdirectory.S
    newObj.officephone = objs.officephone.S
    newObj.location = objs.location.S
    newObj.locationtime = objs.locationtime.S
    newObj.signindate = objs.signindate.S
    newObj.data1 = objs.data1.S
    newObj.data2 = objs.data2.S
    newObj.data3 = objs.data3.S
    newObj.data4 = objs.data4.S
    newObj.data5 = objs.data5.S
    newObj.q1 = objs.q1.S
    newObj.q2 = objs.q2.S
    newObj.q3 = objs.q3.S
    newObj.q4 = objs.q4.S
    newObj.q5 = objs.q5.S
    newObj.q6 = objs.q6.S
    newObj.q7 = objs.q7.S
    newObj.q8 = objs.q8.S
    newObj.userstype = objs.userstype.S
    newObj.guests = objs.guests.S
    newObj.sessions = objs.sessions.S
    newObj.regnotes = objs.regnotes.S
    newObj.checkinstatus = objs.checkinstatus.S
    newObj.mname = objs.mname.S
    newObj.poolid = objs.poolid.S
    newObj.totalamount = objs.totalamount.N
    newObj.amountpaid = objs.amountpaid.N
    newObj.promoamount = objs.promoamount.N
    newObj.pendingamount = objs.pendingamount.N
    newObj.refundamount = objs.refundamount.N
    newObj.paymentmode = objs.paymentmode.S
    newObj.promocodes = objs.promocodes.S
    newObj.guestname = objs.guestname.S
    newObj.regitemsjson = objs.regitemsjson.S
    //console.log(newObj)
    //////console.log(newObj.category);

    return newObj
  }

  getS3PrivateImg(userphoto) {
    let apiName = serviceNames.getWebsiteService();
    let path = ''; //replace this with the path you have configured on your API
    let myInit = {
      body:
      {
        'code': this.state.code,
        'command': 'getS3PrivateImg',
        'bucket': 'wesicore',
        'key': 'event360/' + this.state.code + '/upload/profiles/' + userphoto
      },
      response: true,
      convertEmptyValues: true,
      headers: {}
    }

    //console.log(myInit);

    API.post(apiName, path, myInit).then(response => {
      //console.log ("response.data ===> " + response.data + "response.err ===> " + response.err);

      //console.log(response);

      if (response.err == null && response.data != null) {

        //console.log(response.data);

        //var obj = response.data;
        //this.state.imageUrl = obj;

        this.setState({ imageUrl: "data:image/jpeg;base64," + response.data });

        global.$imageUrl = this.state.imageUrl;

        //console.log("Calling onMenuReload()");
        // Refresh menus
        this.props.onMenuReload();

      }
      else {
        //console.log("getS3PrivateImg - updating state ")
      }
    }
    ).catch(error => {
      //console.log(error)
    });
  }
  updateUserSignin() {
    let apiName = serviceNames.getUserService();
    let path = ''; //replace this with the path you have configured on your API
    var date = new Date();
    var timestamp = date / 1000;

    let myInit = {
      body:
      {
        'code': this.state.code,
        'command': 'updateusersignin',
        'userid': this.state.userid,
        'signintime': timestamp.toString()
      },
      response: true,
      convertEmptyValues: true,
      headers: {}
    }

    //console.log(myInit);

    API.post(apiName, path, myInit).then(response => {
      //console.log ("response.data ===> " + response.data + "response.err ===> " + response.err);

      //console.log(response);

      if (response.err == null && response.data != null) {

        //console.log(response.data);

        //var obj = response.data;
        //this.state.imageUrl = obj;

      }
      else {
        //console.log("getS3PrivateImg - updating state ")
      }
    }
    ).catch(error => {
      //console.log(error)
    });
  }
  handleShowPassword() {
    this.setState(prevState => ({
      showPassword: !prevState.showPassword
    }));
  }
}

export default LogInForm;
