/* eslint-disable */
import React, { Component } from 'react';
import { API } from 'aws-amplify';
import CommentInput from './CommentInput';
import CommentItemView from './CommentItemView';
import { Col, Button, Row } from 'reactstrap';
import UpdatePoints from '../Points/UpdatePoints';
import Icon from '@mdi/react';
import { mdiComment, mdiThumbUp } from '@mdi/js';
var serviceNames = require('../ServiceNameMapper');

/*

*/

class CommentsView extends React.Component {
  constructor(props) {

    super(props);

    this.state = {
      code: this.props.code,
      messageid: this.props.messageid,
      receiverid: this.props.receiverid,
      comments: [],
      dataloaded: false,
      writecomment: false,
      viewcomments: false,
      likebuttonstyles: global.$conversations.styles.likebutton,
      commentbuttonstyles: global.$conversations.styles.commentbutton,
      disablelike: false,
      updatepoints: false
    };

    //console.log(this.props.likes.length)

    for (var i = 0; i < this.props.likes.length; i++) {
      var obj = this.props.likes[i]
      //console.log(obj.M.senderid.S === global.$loginid);
      if (obj.M.senderid.S === global.$loginid) {
        this.setState({ disablelike: true });
        //console.log("like disabled")
      }
    }

    //console.log("Posts.constructor()"); 
    /*setInterval(() => {
      this.getMessages()
    }, 1000)
    */
    this.loadComments = this.loadComments.bind(this);
    this.submitLike = this.submitLike.bind(this);
    this.submitComment = this.submitComment.bind(this);
    this.loadMessages = this.loadMessages.bind(this);
  }

  loadMessages() {
   //console.log('Posts:loadMessages()');

    this.props.loadMessages();
  }
  componentDidUpdate() {

    // There is a new message in the state, scroll to bottom of list
    //const objDiv = document.getElementById('messageList');
    //objDiv.scrollTop = objDiv.scrollHeight;

  }

  loadComments() {
    //console.log('CommentsView:loadComments()');
    this.getComments();
  }

  submitLike(e) {
    e.preventDefault();
    this.setState({ updatepoints: true });
    this.postLike()
  }
  submitComment(e) {
    this.setState({ writecomment: true });
    this.setState({ viewcomments: true })
    this.props.loadMessages()
  }
  render() {

    //console.log("Posts.render()");

    if (this.state.dataloaded) {

      //console.log("Posts.render() dataloaded ");

      const comments = this.state.comments.map((comment, i) => {
        return (
          <CommentItemView
            messageid={comment.messageid}
            commentid={comment.activityid}
            senderid={comment.senderid}
            sendername={comment.sname}
            message={comment.message}
          />
        );
      });

      return (
        <div>
          <div>
            <hr />
            <div className="like-comment-buttons-div">
              <Row>
                <Col>
                  <Button className="like-button" onClick={this.submitLike} style={this.state.likebuttonstyles} disabled={this.state.disablelike}><Icon path={mdiThumbUp} size={0.7} class='far fa-thumbs-up'></Icon>Likes {this.props.lcount}</Button>
                </Col>
                <Col>
                  <Button className="comment-button" onClick={this.submitComment} style={this.state.commentbuttonstyles}><Icon path={mdiComment} size={0.7} class='far fa-comment-alt'></Icon>Comments {this.props.ccount}</Button>
                </Col>
              </Row>
            </div>
          </div>
          {this.writecomment()}
          {this.viewcomments(comments)}
          {
            this.state.updatepoints ?
              (
                <div>
                  {
                    global.$points.conversationlike != null && global.$points.conversationlike.enable.includes("Y") ?
                      (
                        <UpdatePoints receiverid={this.props.receiverid} component="conversation" area="conversationlike" points={global.$points.conversationlike.points} />
                      )
                      :
                      ('')
                  }
                </div>
              )
              :
              ('')
          }
        </div>
      )
    }
    else {
      this.getComments();
      return (
        <div className='comments' id='commentList'>
        </div>
      )
    }
  }

  writecomment() {
    if (this.state.writecomment == true) {
      return (
        <div>
          <br />
          <CommentInput code={this.state.code}
            messageid={this.state.messageid}
            receiverid={this.state.receiverid}
            onSend={this.loadComments} />
        </div>
      )
    }
  }
  viewcomments(comments) {
    if (this.state.viewcomments == true) {
      return (
        <div className='comments' id='commentList'>
          {comments}
        </div>
      )
    }
  }

  getComments() {

    let apiName = serviceNames.getMessageService();
    let path = ''; //replace this with the path you have configured on your API
    let myInit = {
      body:
      {
        'code': this.state.code,
        'command': 'getComments',
        'messageid': this.state.messageid,
      },
      response: true,
      convertEmptyValues: true,
      headers: {}
    }

    //console.log(myInit);
    //console.log('CommentsList:getComments()');

    API.post(apiName, path, myInit).then(response => {
      //console.log (response);

      if (response.err == null && response.data != null) {
        //console.log(response.data.Items);
        this.setState({
          dataloaded: true,
          comments: this.getCommentObjects(response.data.Items)
        })
      }
      else {
        //console.log(response.err);
      }
    }
    ).catch(error => {
      //console.log(error)
    });
  }

  getCommentObjects(objs) {
    const objects = []

    for (var i = 0; i < objs.length; i++) {

      var obj = objs[i]
      var newObj = Object()

      newObj.messageid = obj.messageid.N
      newObj.activityid = obj.activityid.N
      newObj.message = obj.message.S
      newObj.senderid = obj.senderid.S
      newObj.sname = obj.sname.S
      newObj.type = obj.type.S

      objects.push(newObj)
    }
    return objects
  }

  postLike() {
    let apiName = serviceNames.getMessageService();
    let path = ''; //replace this with the path you have configured on your API
    var date = new Date()
    var timestamp = date / 1000

    let myInit = {
      body:
      {
        'code': this.state.code,
        'command': 'postComment',
        'messageid': this.props.messageid,
        'receiverid': this.props.receiverid,
        'activityid': timestamp.toString(),
        'type': 'L',
        'message': this.props.message,
        'senderid': this.props.senderid,
        'sname': this.props.sendername
      },
      response: true,
      convertEmptyValues: true,
      headers: {}
    }

    //console.log(myInit);
    //console.log('CommentsList:getComments()');

    API.post(apiName, path, myInit).then(response => {
      //console.log (response);

      if (response.err == null && response.data != null) {
        this.setState({ disablelike: true });
        //console.log(response.data);
        this.props.loadMessages()
      }
      else {
        //console.log(response.err);
      }
    }
    ).catch(error => {
      //console.log(error)
    });
  }


} //Class

export default CommentsView;