import React from 'react';
import { API } from 'aws-amplify';
import ReactPlayer from 'react-player';
//import commonProfile from '../../images/common_profile.png';
var serviceNames = require('../ServiceNameMapper')

var attendees =
  [

  ]

class PostImage extends React.Component {
  constructor(props) {
    //console.log(props)
    super(props);

    //var imageFile = props.id + '.png'
    //console.log(imageFile);

    this.state = {
      imgid: props.id,
      //imgid: imageFile,
      code: props.code,
      dataloaded: false,
      imgurl: ''
    };
  }

  render() {
    if (this.state.dataloaded) {   //border-radius:50%;object-fit:contain;
      //console.log(this.state.imgurl);
      return (
        <div className="post-img-div">
          {
            this.state.imgid.includes(".mp4") ?
              (
                <ReactPlayer
                  url={"https://event360live.s3.amazonaws.com/public/" + this.state.code + "/posts/" + this.state.imgid}
                  controls={true}
                  playing={false}
                  onContextMenu={e => e.preventDefault()}
                  config={{
                    file: {
                      attributes: {
                        controlsList: 'nodownload',
                        disablepictureinpicture: 'true'
                      }
                    }
                  }}
                />
              )
              :
              ('')
          }
          <img src={this.state.imgurl} height={this.props.height} width={this.props.width} className="post-image" />
        </div>
      )
    }
    else {
      if (this.state.imgid != undefined) {
        if (this.state.imgid.includes(".mp4")) {
          this.setState({ dataloaded: true });
        }
        else if (this.state.imgid.includes(".png") || this.state.imgid.includes(".jpg") || this.state.imgid.includes(".gif")) {
          if (this.state.imgid.includes("post")) {
            this.getS3PrivateImg()
          }
          else {
            this.setState({
              imgurl: "https://event360live.s3.amazonaws.com/public/" + this.state.code + "/messages/" + this.state.imgid,
              dataloaded: true
            })
          }
        }
        else {
          if (global.$attendeejson != undefined) {
            this.findImgFilename()
          }
          else {
            this.getUsers();
          }
        }
      }
      return (<div></div>)
    }
  }


  getS3PrivateImg() {


    this.state.imgid = this.state.imgid.replace(/ /g, '_');
    this.state.imgid = this.state.imgid.replace(/@/g, '_');
    let apiName = serviceNames.getWebsiteService();
    let path = ''; //replace this with the path you have configured on your API
    let myInit = {
      body:
      {
        'code': this.state.code,
        'command': 'getS3PrivateImg',
        'bucket': 'wesicore',
        'key': 'event360/' + this.state.code + '/upload/posts/' + this.state.imgid
      },
      response: true,
      convertEmptyValues: true,
      headers: {}
    }
    //console.log(myInit);
    API.post(apiName, path, myInit).then(response => {

      //console.log(response);

      if (response.err == null && response.data != null) {
        if (response.data.errorType == "NoSuchKey") {
          //console.log(response.data);
          this.setState({
            //imgurl: commonProfile,  //TBD: Is this required for t
            dataloaded: true
          })
        }
        else {
          this.setState({
            imgurl: "data:image/jpeg;base64," + response.data,
            dataloaded: true
          });
        }

        //global.$imageUrl = "data:image/jpeg;base64,"+response.data;
        //console.log(global.$imageUrl);
        //console.log(this.state.imgurl);

      }
      else {
        //console.log("getS3PrivateImg - updating state ")
      }
    }
    ).catch(error => {
      //console.log(error.response)
    });
  }

  getAttendeesTableObjects(objs) {
    //console.log(objs);

    const tableObjs = []

    for (var i = 0; i < objs.length; i++) {
      var attendeeObj = objs[i]

      ///console.log(attendeeObj)

      var newObj = Object()

      newObj.fname = attendeeObj.fname.S
      newObj.lname = attendeeObj.lname.S
      newObj.photo = attendeeObj.photo.S
      newObj.title = attendeeObj.title.S
      newObj.userid = attendeeObj.userid.S
      newObj.phone = attendeeObj.phone.S
      newObj.company = attendeeObj.company.S
      newObj.email = attendeeObj.email.S
      newObj.myinterests = attendeeObj.myinterests.S
      newObj.myexpertise = attendeeObj.myexpertise.S
      newObj.street = attendeeObj.street.S
      newObj.city = attendeeObj.city.S
      newObj.state = attendeeObj.state.S
      newObj.zip = attendeeObj.zip.S
      newObj.linkedin = attendeeObj.linkedin.S
      newObj.twitter = attendeeObj.twitter.S
      newObj.facebook = attendeeObj.fb.S
      newObj.showdirectory = attendeeObj.showdirectory.S
      newObj.data1 = attendeeObj.data1.S
      newObj.data2 = attendeeObj.data2.S
      newObj.data3 = attendeeObj.data3.S
      newObj.data4 = attendeeObj.data4.S
      newObj.q1 = attendeeObj.q1.S
      newObj.q2 = attendeeObj.q2.S
      newObj.q3 = attendeeObj.q3.S
      newObj.q4 = attendeeObj.q4.S
      newObj.q5 = attendeeObj.q5.S
      newObj.q6 = attendeeObj.q6.S
      newObj.q7 = attendeeObj.q7.S
      newObj.q8 = attendeeObj.q8.S

      tableObjs.push(newObj)
    }

    //console.log(tableObjs)

    return tableObjs
  }

  getUsers() {
    let apiName = serviceNames.getUserService();
    let path = ''; //replace this with the path you have configured on your API
    let myInit = {
      body:
      {
        'code': this.state.code,
        'command': 'getUsers'
      },
      response: true,
      convertEmptyValues: true,
      headers: {}
    }
    //console.log(myInit);

    API.post(apiName, path, myInit).then(response => {
      //console.log ("response.data ===> " + response.data + "response.err ===> " + response.err);

      if (response.err == null && response.data != null) {
        //console.log("getAttendeesTableObjects - Success!!");
        //console.log(response.data.Items);

        attendees = this.getAttendeesTableObjects(response.data)

        global.$attendeejson = attendees;
        //console.logattendees.photo)
        this.findImgFilename()
      }
      else {
        //console.log("getAttendeesTableObjects - failed!!");
        //console.log(response.err);
      }
    }
    ).catch(error => {
      //console.log(error.response)
    });
  }

  findImgFilename() {
    global.$attendeejson.find(globalAttendee => {
      //console.log(globalAttendee);
      if (globalAttendee.userid == this.state.imgid) {
        this.setState({ imgid: globalAttendee.photo });
        this.getS3PrivateImg();
      }
    })
  }

}
export default PostImage;
