import React from 'react';
import { Row, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { API, Analytics } from "aws-amplify";
import UpdatePoints from '../Points/UpdatePoints';
import Icon from '@mdi/react';
import { mdiAccount, mdiEmail, mdiMessage, mdiPhone } from '@mdi/js';

var serviceNames = require('../ServiceNameMapper');

class SponsorContactForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            code: props.code,
            s3: props.s3,
            activeIndex: 0,
            name: global.$fname,
            email: global.$email,
            phone: global.$phone,
            message: '',
            note: '',
            clearbuttonstyles: global.$sponsors.contact.buttons.clear.styles,
            submitbuttonstyles: global.$sponsors.contact.buttons.submit.styles,
            pagestyle: global.$sponsors.contact.pagestyle,
            formstyles: global.$sponsors.contact.form.styles,
            updatepoints: false
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClear = this.handleClear.bind(this);
        //console.log(this.state.code)
        //console.log(this.props.email);
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {

        Analytics.record({ name: 'PageView', attributes: { name: 'eventsites-' + this.state.code + '-contact' } });
        return (
            <div style={this.state.pagestyle}>
                <br />
                <br />
                <Row>
                    <Col>
                        <div className="sponsor-contact-info" style={this.state.formstyles}>
                            <form className="sponsor-contact-form">
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label className="label" for="name"><Icon path={mdiAccount} size={0.9} className='sponsor-contactform-icon'></Icon>Name*</Label>
                                            <Input type="text" name="name" id="name" value={this.state.name || ''} onChange={this.handleInputChange} required />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label className="label" for="enail"><Icon path={mdiEmail} size={0.9} className='sponsor-contactform-icon'></Icon>Email*</Label>
                                            <Input type="text" name="email" id="email" value={this.state.email || ''} onChange={this.handleInputChange} required />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label className="label" for="phone"><Icon path={mdiPhone} size={0.9} className='sponsor-contactform-icon'></Icon>Phone</Label>
                                            <Input type="text" name="phone" id="phone" value={this.state.phone || ''} onChange={this.handleInputChange} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <Label className="label" for="message"><Icon path={mdiMessage} size={0.9} className='sponsor-contactform-icon'></Icon>Message*</Label>
                                        <Input type="textarea" style={{ height: "230px" }} name="message" id="message" value={this.state.message || ''} onChange={this.handleInputChange} required />
                                    </Col>
                                </Row>
                                <br />
                                <div style={{ textAlign: "center" }}>
                                    <h6 style={{ color: "green", padding: "20px" }} id="note">{this.state.note}</h6>
                                    <button type="button" className="btn" style={this.state.submitbuttonstyles} onClick={this.handleSubmit}>{global.$contact.buttons.submit.text}</button>
                                    <button color="danger" className="btn" style={this.state.clearbuttonstyles} onClick={this.handleClear}>{global.$contact.buttons.clear.text}</button>
                                </div>
                            </form>
                        </div>
                    </Col>
                </Row>
                <br />
                <br />
                {
                    this.state.updatepoints?
                    (
                        <div>
                            {
                                global.$points.exhibitorcontact != null && global.$points.exhibitorcontact.enable.includes("Y") ?
                                (
                                    <UpdatePoints sponsor={this.props.exhibitor} component="exhibitor" area="exhibitorcontact" points={global.$points.exhibitorcontact.points} />
                                )
                                :
                                ('')                 
                            }
                        </div>
                    )
                    :
                    ('')
                }
            </div>
        );

    }


    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        let value = target.value

        //console.log( "Target = " + name + ", Value = " + value);
        this.setState({ [name]: value });
    }

    validateInputs() {
        if (this.state.name === "" || this.state.email === "" || this.state.message === "") {
            this.state.note = "Enter all the required inputs"
            this.forceUpdate();
            return false;
        }
        return true;
    }

    handleSubmit(event) {
        event.preventDefault();
        if (this.validateInputs()) {
        this.submitRegistration(event);
        }
    }

    handleClear(event) {
        event.preventDefault();
        this.clearInputs();
        this.state.note = '';
    }

    clearInputs() {
        this.state.name = "";
        this.state.email = "";
        this.state.phone = "";
        this.state.message = "";
        this.state.note = "";
        this.forceUpdate();
    }
    submitRegistration(event) {

        // let apiName = 'EmailService';
        let apiName = serviceNames.getEmailService();
        let path = ''; //replace this with the path you have configured on your API
        //console.log(this.props.email);
        if (this.state.phone == "" || this.state.phone == null) {
            this.state.phone = " ";
        }

        let myInit =
        {
            body:
            {
                'command': 'sendEmailToExhibitor',
                'code': global.$code,
                'toemail': this.props.email,
                'name': this.state.name,
                'phone': this.state.phone,
                'email': this.state.email,
                'subject': this.state.message,
            },
            response: true,
            convertEmptyValues: true,
            headers: {}
        }
        this.setState({ disabled: true });
        //console.log(myInit);
        ////console.log(this.state.code + "," + this.state.message + "," + this.state.type );
        API.post(apiName, path, myInit).then(response => {
            ////console.log ("response.data ===> " + response.data + "response.err ===> " + response.err);
            //console.log(response);
            if (response.err == null) {
                document.getElementById('note').innerHTML = "<p>Message submitted successfully</p>";
                this.state.note = "Message submitted successfully"
                this.setState({updatepoints: true})
                //console.log("postMessage - Success!!");
                this.clearInputs();
            }
            else {
                this.state.note = "Error!! Unable to submit message. Please try again.";
                this.setState({ isLoading: false });
                this.forceUpdate();
            }

        }).catch(error => {

            //console.log(error)
            this.setState({ disabled: false });
            this.setState({ isLoading: false });
        });
    }
}
export default SponsorContactForm;