import React from 'react';
import RegInfo from './RegInfo';
import MyInfo from './MyInfo';
import Questions from './Questions';
import { Row, Col } from 'reactstrap';
// import Payment from './Payment';
import PaymentContainer from './Payment/PaymentContainer';
import Terms from './Terms';
import APIFunctions from './RegCommon/APIFunctions';
import LoadingUI from './RegCommon/LoadingUI';
import CommonFunctions from './RegCommon/CommonFunctions';
import EventcodeFunctions from './RegCommon/EventcodeFunctions';
import AttendeeInfo from './AttendeeInfo';
import GuestInfo from './GuestInfo';
import RegOptionsContainer from './RegOptions/RegOptionsContainer';

class RegistrationFormContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            code: global.$code,
            s3: global.$s3,
            bucket: global.$s3bucket,
            dataloaded: false,
            regdata: '',
            updateamout: false
        };
        this.onPaymentAmountChanged = this.onPaymentAmountChanged.bind(this)
        this.hanleRefresh = this.hanleRefresh.bind(this);
       
    }

    componentDidMount(){
        this.getData();
    }

    render() {

        if (this.state.dataloaded) {
            return (
                <div className="reg-home">
                    <Row>
                        <Col md={10} className="offset-md-1">
                            {this.renderRegInfoSection()} 
                            { 
                            this.props.enablenewregistration === true && CommonFunctions.isUserLoggedin() === false  ? 
                                (<>
                                    { this.renderComponents() }
                                </>)
                                :("")
                            }
                            {
                            CommonFunctions.isUserLoggedin()  ?
                            this.props.enableupdateregistration === false ?
                                (<>
                                    {this.renderRegFormSection()}
                                    {this.renderGuestFormSection()}
                                    {this.renderQuestionsSection()}
                                    {this.renderTermsSection()}
                                    {this.renderPaymentSection()}
                                </>)
                                :
                                (<>
                                    { this.renderComponents() }
                                </>)
                                : 
                                ("")
                            }


                        </Col>
                    </Row>
                </div>
            );
        }
        else {
            //this.getData();
            return (
                <LoadingUI />
            )
        }
    }


    renderComponents(){
        
        return(
            <>
                {this.renderMyInfoSection()}    
                {global.$registrationform[this.props.tabid]?.attendeeformshowbeforeregform === true && this.renderRegFormSection()} 
                {this.renderRegOptionsSection()}
                {global.$registrationform[this.props.tabid]?.questions?.showbeforeattendeeform && this.renderQuestionsSection()}               
                {(global.$registrationform[this.props.tabid]?.attendeeformshowbeforeregform === false || global.$registrationform[this.props.tabid]?.attendeeformshowbeforeregform == null) ? this.renderRegFormSection() : ('')}
                {this.renderGuestFormSection()}                    
                {!global.$registrationform[this.props.tabid]?.questions?.showbeforeattendeeform && this.renderQuestionsSection()}                
                {this.renderTermsSection()}        
                {this.renderPaymentSection()}  
            </>
        )
    }

    renderRegInfoSection() {
        return (
            <RegInfo data={this.state.regdata} type={this.props.type} tabid={this.props.tabid}/>
        )
    }
    renderMyInfoSection() {
        
        //MyInfo section is shown only after login or registration update scenario

        if (global.$registrationform.login != null && global.$registrationform.login.required == true) {
            if (CommonFunctions.isUserLoggedin()) {
                return (
                    <div className="my-info">
                        <MyInfo data={this.state.regdata} type={this.props.type} tabid={this.props.tabid}/>
                    </div>
                )
            }
        }
    }

    renderRegOptionsSection() {
        return (
            <div className="reg-options">
                <RegOptionsContainer 
                    data={this.state.regdata} 
                    tabid={this.props.tabid} 
                    type={this.props.type} 
                    onPaymentAmountChanged={this.onPaymentAmountChanged} 
                    hanleRefresh={this.hanleRefresh}
                />

            </div>
        )
    }
    renderRegFormSection() {
        
        return (
            <div className="attendee-info-div" style={global.$registrationform.styles[this.props.tabid].maindiv}>
                <AttendeeInfo data={this.state.regdata} type={this.props.tabid} />
            </div>
        )
    }
    renderGuestFormSection() {
        if (global.$registrationform.guests.required == true ) {
            return (
                <div className="attendee-info-div" style={global.$registrationform.styles.guestinfo.maindiv}>
                    <div>
                        <GuestInfo data={this.state.regdata} type={this.props.tabid} />
                    </div>
                </div>
            )
        }
    }

    renderQuestionsSection() {
        return (
            <div className="reg-questions">
                <Questions data={this.state.regdata} type={this.props.tabid} />
            </div>
        )
    }

    renderTermsSection() {
        return (
            <Terms tabid={this.props.tabid} />
        )
    }

    renderPaymentSection() {
        return (
            <div className="reg-payments">
                <PaymentContainer data={this.state.regdata} type={this.props.type} tabid={this.props.tabid} onPaymentAmountChanged={this.onPaymentAmountChanged} />
            </div>
        )
    }

    async getData() {
        var data = await APIFunctions.getRegistrationDetails();
       //console.log(data);
        if (data.err == "") {
            this.setState({
                regdata: data.data,
                dataloaded: true
            });
        }
    }
    
    // This is to refresh the parent component from children component
    onPaymentAmountChanged() {
        //Clear any discounts applied
        global.$regdiscountinfo = ""

        console.log('RFC:onPaymentAmountChanged')
        //console.log(CommonFunctions.printGlobalRegItems())
        //console.log(CommonFunctions.printGlobalAmounts())

        this.setState({
            updateamout: true
        });
    }

    hanleRefresh(text){
        if (text == "refresh") {
            this.setState({
                dataloaded: false
            });
            this.getData()
            console.log('Refresh Tab');
        }
        
    }
    


    /*
    OLD CODE
    if (this.props.tabid == "tab1") {
            return (
                <div className="attendee-info-div" style={global.$registrationform.styles[this.props.tabid].maindiv}>
                    <AttendeeInfo data={this.state.regdata} type={this.props.tabid} />
                </div>
            )
        }
        else if (this.props.tabid == "tab2") {
            return (
                <div className="attendee-info-div" style={global.$registrationform.styles[this.props.tabid].maindiv}>
                    <AttendeeInfo data={this.state.regdata} type={this.props.tabid} />
                </div>
            )
        }
        else if (this.props.tabid == "tab3") {
            return (
                <div className="attendee-info-div" style={global.$registrationform.styles[this.props.tabid].maindiv}>
                    <AttendeeInfo data={this.state.regdata} type={this.props.tabid} />
                </div>
            )
        }
        else if (this.props.tabid == "tab4") {
            return (
                <div className="attendee-info-div" style={global.$registrationform.styles[this.props.tabid].maindiv}>
                    <AttendeeInfo data={this.state.regdata} type={this.props.tabid} />
                </div>
            )
        }
        else if (this.props.tabid == "tab5") {
            return (
                <div className="attendee-info-div" style={global.$registrationform.styles[this.props.tabid].maindiv}>
                    <AttendeeInfo data={this.state.regdata} type={this.props.tabid} />
                </div>
            )
        }
    */
}
export default RegistrationFormContainer;


