import React, { useState } from "react";
import {
  Nav,
  NavItem,
  Navbar,
  Collapse,
  NavbarToggler,
  NavbarBrand,
  Row,
  Button,
  Col,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import { Link } from "react-router-dom";
import Home from "./Home";
import Contact from "../Contact/Contact";
import Sponsors from "../Sponsors/Sponsors";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import "./MenuBar.css";
import { CircleArrow as ScrollUpButton } from "react-scroll-up-button";
import Amplify, { API, Auth } from "aws-amplify";
import Registrationform from "../Registration/Registrationform";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import ScrollToTop from "react-scroll-to-top";
import "react-scroll-to-top/lib/index.css";
import { createBrowserHistory } from "history";

import Speakers from "../Speakers/Speakers";
import SpeakerInfo from "../Speakers/SpeakerInfo";
import Schedule from "../Schedule/Schedule";
import LoginForm from "../Login/LoginForm";
import ProfilePage from "./ProfilePage";
import LoadExternalLinks from "./Shared/LoadExternalLinks";
import Delegateslist from "../Delegates/Delegateslist";
import Auctions from "../Auctions/Auctions.jsx";
import PostsView from "../Posts/PostsView";
import Conversations from "../Posts/Conversations";
import Evaluation from "./Evaluation";
import SponsorInfo from "../Sponsors/SponsorInfo";
import sponsorsVertical from "./Shared/SponsorsVertical";
import SessionTracks from "../Schedule/SessionTracks";
import VirtualExpoRoom from "../VirtualExpo/VirtualExpoRoom";
import VirtualExpoHall from "../VirtualExpo/VirtualExpoHall";
import VirtualExpoMultiRooms from "../VirtualExpo/VirtualExpoMultiRooms";
import SessionInfo from "../Schedule/SessionInfo";
import Speakers2 from "../Speakers2/Speakers2";
import SpeakerInfo2 from "../Speakers2/SpeakerInfo2";
import SponsorsByCategory from "./Shared/SponsorsByCategory";
import Html1 from "./Shared/Html1";
import Html2 from "./Shared/Html2";
import Html3 from "./Shared/Html3";
import Html4 from "./Shared/Html4";
import Html5 from "./Shared/Html5";
import Html6 from "./Shared/Html6";
import Avatar from "react-avatar";
import Notifications from "../Notifications/Notifications";
import NotifyMe from "react-notification-timeline";
import ProfileTabs from "../Profile/ProfileTabs";
import SessionInfo2 from "../SessionInfo2/SessionInfo2";
import Delegateslist2 from "../Delegates/Delegateslist2";
import Speakers3 from "../Speakers3/Speakers3";
import SucessfullLogin from "../Login/SucessfullLogin";
// import RegistrationHome from "../Registration2/RegistrationHome";
import RegistrationHome from "../Registration/RegistrationHome";
import { mdiAccountCircle } from "@mdi/js";
import Icon from "@mdi/react";
import LoginIntro from "../Login/LoginIntro";
import FooterContactForm from "./FooterContactForm";
import AttendeeChatNotifications from "../Notifications/AttendeeChatNotifications";
import MeetupsHome from "../Meetups/MeetupsHome";
import ExhibitorSubmitForm from "../SubmissionForms/ExhibitorSubmitForm";
import RepresentativeList from "../Representative/RepresentativeList.js";
import RepresentativeEditor from "../Representative/RepresentativeEditor";
import Menu1 from "./Shared/Menu1";
import Menu2 from "./Shared/menu2";
import Menu3 from "./Shared/menu3";
import Menu4 from "./Shared/Menu4";
import Menu5 from "./Shared/Menu5";
import SpeakerProfile from "../Speaker/SpeakerProfile";
import SponsorSubmitForm from "../SubmissionForms/SponsorSubmitForm";

var serviceNames = require('../ServiceNameMapper');

/*
dev-adarsh branch

  registerexternal
    <a target="_blank" className="contact menu-item" style={this.state.registerexternalstyle} href={global.$header.menu.registerexternal.externallink}>{global.$header.menu.registerexternal.title}</a>
*/

const queryString = require("query-string");
var configjson = [];
var data = [];
var notificationslength = 0;
var timer = "";
var menuItems = "";
const browserHistory = createBrowserHistory();
class MenuBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      code: props.code,
      s3: props.s3,
      dropdownOpen: false,
      isOpen: false,
      dropdownOpenSchedule: false,
      dropdownOpenTrade: false,
      collapsed: true,
      bucket: props.bucket,
      dataloaded: false,
      footerstyle: "",
      headerstyle: "",
      loginstyles: "",
      registerstyles: "",
      hrstyle: "",
      scrollerstyles: "",
      versionstyle: "",
      userphoto: "",
      imageUrl: "",
      imagestyles: "",
      registerexternalstyle: "",
      menutextcolor: "",
      menuhovercolor: "white !important",
      profileiconstyles: "",
      dropdown1login: "N",
      dropdown2login: "N",
      dropdown3login: "N",
      dropdown4login: "N",
      dropdown5login: "N",
      notifications: []
    };

    this.toggle = this.toggle.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.toggleSchedule = this.toggleSchedule.bind(this);
    this.onMouseEnterSchedule = this.onMouseEnterSchedule.bind(this);
    this.onMouseLeaveSchedule = this.onMouseLeaveSchedule.bind(this);
    this.toggleTrade = this.toggleTrade.bind(this);
    this.onMouseEnterTrade = this.onMouseEnterTrade.bind(this);
    this.onMouseLeaveTrade = this.onMouseLeaveTrade.bind(this);
    this.toggleAbstracts = this.toggleAbstracts.bind(this);
    this.onMouseEnterAbstracts = this.onMouseEnterAbstracts.bind(this);
    this.onMouseLeaveAbstracts = this.onMouseLeaveAbstracts.bind(this);
    this.newWindow = this.newWindow.bind(this);
    this.newApp = this.newApp.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    //console.log(this.state.code);
    //console.log(props.s3);

    //this.getConfig();
    //console.log(configjson)
    //console.log(global.$loginstatus);
    /*timer = setInterval(() => {
            this.getNotfications()
        }, 10000 * 2)
        */
    this.menuReloadHandler = this.menuReloadHandler.bind(this)
  }

  componentWillUnmount() {
    //console.log("componentWillUnmount")
    //clearInterval(timer)
  }
  toggleMenu(e) {
    
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }
  newWindow() {
    const url =
      "https://play.google.com/store/apps/details?id=com.apps4org.eventslite";
    window.open(url, "_blank");
  }
  newApp() {
    const url = "https://www.apple.com/in/search/eventslite?src=globalnav";
    window.open(url, "_blank");
  }

  handleLogout() {
    global.$loginstatus = "false";
    
    this.menuReloadHandler();
    this.clearGlobalVariables()
    console.log(global);
  }

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  onMouseEnter() {
    this.setState({ dropdownOpen: true });
  }

  onMouseLeave() {
    this.setState({ dropdownOpen: false });
  }
  toggleSchedule() {
    this.setState((prevState) => ({
      dropdownOpenSchedule: !prevState.dropdownOpen,
    }));
  }

  onMouseEnterSchedule() {
    this.setState({ dropdownOpenSchedule: true });
  }

  onMouseLeaveSchedule() {
    this.setState({ dropdownOpenSchedule: false });
  }

  toggleTrade() {
    this.setState((prevState) => ({
      dropdownOpenTrade: !prevState.dropdownOpen,
    }));
  }

  onMouseEnterTrade() {
    this.setState({ dropdownOpenTrade: true });
  }

  onMouseLeaveTrade() {
    this.setState({ dropdownOpenTrade: false });
  }

  toggleAbstracts() {
    this.setState((prevState) => ({
      dropdownOpenAbstacts: !prevState.dropdownOpen,
    }));
  }

  onMouseEnterAbstracts() {
    this.setState({ dropdownOpenAbstacts: true });
  }

  onMouseLeaveAbstracts() {
    this.setState({ dropdownOpenAbstacts: false });
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  menuReloadHandler() {
    //console.log('MenuBar:menuReloadHandler()');

    this.setState({
      refresh: true,
    });
    
  }

  handleChangePassword = (event) => {
    Amplify.configure({
      Auth: {
        //identityPoolId:         'us-east-1:f21ee086-a0ae-4bfc-a1b7-58913104d709', //exhibitors_sponsors_ip
        //region:                 'us-east-1',

        //userPoolId:             'us-east-1_LtVLdp322', //us-east-1_LtVLdp322 - AppAdmin
        //userPoolWebClientId:    '2cbpuu5f2vql93d28pk0he39do' //2cbpuu5f2vql93d28pk0he39do  - AppAdmin

        //userPoolId:             'us-east-1_cx9zgTYXf',        //us-east-1_cx9zgTYXf - devtest
        //userPoolWebClientId:    '5dfap44mptvq1b5bie84eaoav8'  //4ul2rsbo8viha7u2lfv7s32pej  - devtest without secretkey
        //userPoolWebClientId:    '4ul2rsbo8viha7u2lfv7s32pej' //4ul2rsbo8viha7u2lfv7s32pej  - devtest with secretkey

        userPoolId: global.$poolid, //us-east-1_cx9zgTYXf - devtest
        userPoolWebClientId: global.$clientid, //4ul2rsbo8viha7u2lfv7s32pej  - devtest without secretkey
      },
    });
    global.$loginid = global.$loginid.toLowerCase();
    this.processChangePassword();
  };
  processChangePassword() {
    Auth.forgotPassword(global.$loginid)
      .then((data) => {
        //console.log(data)

        var new_password = prompt("Enter New Password:", "");

        var code = prompt(
          "Enter Code Sent to " + data.CodeDeliveryDetails.Destination,
          ""
        );

        Auth.forgotPasswordSubmit(global.$loginid, code, new_password)
          .then((data) => {
            this.setState({ message: "Password successfully changed" });
          })
          .catch((err) => {
            ////console.log(err)
            this.setState({ message: "Unable to change password" });
          });
      })
      .catch((err) => {
        ////console.log(err)
        this.setState({ message: "Unable to change password" });
      });

    //////console.log("preocessChangePassword - success")
  }

  render() {
    //console.log("Menubar: render()", global.$loginstatus)
    //console.log(this.state.menutextcolor);
    if (this.state.dataloaded) {
      //console.log("Menubar: render()", global.$loginstatus)
      //console.log(global.$header.menu.exhibitorsubmitform.usertype);
      //console.log(data);
      /*global.$attendeeregitems = null;

            global.$guestregitems = null;

            global.$regdiscountinfo = null;

            global.$regemail = null;

            global.$regamount = null;

            global.$loggedinuser = null;

            global.$totalregitems = null;

            global.$regloginstatus = null;

            global.$regsectiondetails = null;

            global.$selectedregoptions = null;

            global.$regattendeeinfo = null;

            global.$regguestinfo = null;

            global.$loggedinuserregitems = null;

            global.$regquestionsinfo = null;

            global.$regdetails = null;*/
      var showexhibitormenus = false;
      var showsponsormenu = false;
      let enablespeakerprofile = false;
      const pattern = /Speaker/;

      if (pattern.test(global.$userstype)) {
        enablespeakerprofile = true
      }
      
      if (global.$header.menu.exhibitorsubmitform && global.$header.menu.exhibitorsubmitform.usertype) {
          let exhibitorusertypes = global.$header.menu.exhibitorsubmitform.usertype.split(";");
          if (global.$userstype && global.$userstype != "") {
            let usertypes = global.$userstype.split(",");

            for (let i = 0; i < exhibitorusertypes.length; i++) {
              if (usertypes.includes(exhibitorusertypes[i])) {
                showexhibitormenus = true          
              }
            }        
          }         
      }

      if (global.$header.menu.sponsorsubmitform && global.$header.menu.sponsorsubmitform.usertype) {
        let sponsorusertypes = global.$header.menu.sponsorsubmitform.usertype.split(";");
        if (global.$userstype && global.$userstype != "") {
          let usertypes = global.$userstype.split(",");

          for (let i = 0; i < sponsorusertypes.length; i++) {
            console.log(sponsorusertypes[i],usertypes);
            if (usertypes.includes(sponsorusertypes[i])) {
              showsponsormenu = true          
            }
          }        
        }         
    }

      if (global.$imageUrl == null) {
        global.$imageUrl = "common_profile.png";

      }
      var arr = [];
      arr.push(global.$menuItems);

      //Set home page based on if user signed in or not.
      var loginHome = "home";
      if (global.$loginstatus == "true") {
        if (global.$login.loginhome != null && global.$login.loginhome != "") {
          loginHome = "loginsuccess";
        }
      }

      //console.log(`global.$login = ${global.$loginstatus}, loginHome = ${loginHome}`)
      /*if (global.$loginstatus == "true") {
               //console.log(global.$attendeeDetails, global.$attendeeDetails[`${global.$header.menu.menu1.searchfield}`], global.$attendeeDetails[`${global.$header.menu.menu1.searchfield}`].includes(`${global.$header.menu.menu1.searchkeyword}`));
            }*/
      var logolink = "/Home";
      if (global.$header != null && global.$header.logolink != null) {
        logolink = global.$header.logolink
      };

      return arr.map((menu) => {
       
        return (
          <Router history={browserHistory}>
            <div>
              <Navbar
                light
                expand="xl"
                sticky={"top"}
                style={this.state.headerstyle}
                className="menubar"
              >
                <NavbarBrand href="/">
                  {
                    logolink != "/Home" ?
                      (
                        <LinkContainer to="/" onClick={()=>window.open(`${logolink}`, "_self")} target="_self">
                          <img id="menulogo" src={global.$header.logo} />
                        </LinkContainer>

                      )
                      :
                      (
                        <LinkContainer to={`${logolink}`} target="_self">
                          <img id="menulogo" src={global.$header.logo} />
                        </LinkContainer>
                      )
                  }
                </NavbarBrand>
                <NavbarToggler onClick={this.toggleMenu} />
                <Collapse isOpen={this.state.isOpen} navbar >
                  <Nav className="menubar-items" navbar style={this.state.headerstyle} >
                    {menu.includes("home") ? (
                      loginHome == "home" ? (
                        <LinkContainer
                          activeStyle={{
                            fontWeight: "bold",
                            color: global.$header.menuactivecolor,
                          }}
                          style={{ color: this.state.menutextcolor }}
                          to="/Home"
                          className="home menu-item active"
                          active
                        >
                          <NavItem
                            className="navitem"
                            onClick={this.toggleMenu}
                          >
                            {
                              global.$header.menu.home != null && global.$header.menu.home.title != null ?
                                (
                                  `${global.$header.menu.home.title}`
                                )
                                :
                                (
                                  'HOME'
                                )
                            }
                          </NavItem>
                        </LinkContainer>
                      ) : global.$header.menu.home != null &&
                        global.$header.menu.home.titleafterlogin != null ? (
                        <LinkContainer
                          activeStyle={{
                            fontWeight: "bold",
                            color: global.$header.menuactivecolor,
                          }}
                          style={{ color: this.state.menutextcolor }}
                          to="/LoginHome"
                          className="home menu-item active"
                          active
                        >
                          <NavItem
                            className="navitem"
                            onClick={this.toggleMenu}
                          >
                            {global.$header.menu.home.titleafterlogin}
                          </NavItem>
                        </LinkContainer>
                      ) : (
                        <LinkContainer
                          activeStyle={{
                            fontWeight: "bold",
                            color: global.$header.menuactivecolor,
                          }}
                          style={{ color: this.state.menutextcolor }}
                          to="/LoginHome"
                          className="home menu-item active"
                          active
                        >
                          <NavItem
                            className="navitem"
                            onClick={this.toggleMenu}
                          >
                            HOME
                          </NavItem>
                        </LinkContainer>
                      )
                    ) : (
                      ""
                    )}
                    {menu.includes("representative") ? (
                      global.$header.menu.representative.login.includes(
                        "Y"
                      ) && showexhibitormenus ? (
                        global.$loginstatus.includes(true) &&
                          global.$userstype == "Exhibitor" || global.$userstype == "Exhibitor Admin" ? (
                          <LinkContainer
                            activeStyle={{
                              fontWeight: "bold",
                              color: global.$header.menuactivecolor,
                            }}
                            style={
                              global.$header.menu.representative.styles
                            }
                            to={global.$header.menu.representative.link}
                            className="menu-item"
                          >
                            <NavItem
                              className="navitem text-nowrap"
                              onClick={this.toggleMenu}
                              
                            >
                              {global.$header.menu.representative.title}
                            </NavItem>
                          </LinkContainer>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    
                    
                    {menu.includes("schedule") ? (
                      <LinkContainer
                        activeStyle={{
                          fontWeight: "bold",
                          color: global.$header.menuactivecolor,
                        }}
                        style={{ color: this.state.menutextcolor }}
                        to="/Schedule"
                        className="menu-item"
                      >
                        <NavItem className="navitem" onClick={this.toggleMenu}>
                          SCHEDULE
                        </NavItem>
                      </LinkContainer>
                    ) : (
                      ""
                    )}
                    {menu.includes("menu1") ?
                      (
                        global.$header.menu.menu1.showafterlogin != null && global.$header.menu.menu1.showafterlogin.includes("Y") ?
                          (
                            <div>
                              {global.$loginstatus.includes("true") ? (
                                global.$header.menu.menu1.enablesearch != null && global.$header.menu.menu1.enablesearch == true ? (global.$attendeeDetails[`${global.$header.menu.menu1.searchfield}`].includes(`${global.$header.menu.menu1.searchkeyword}`) == true ?
                                  (
                                    <LinkContainer
                                      activeStyle={{
                                        fontWeight: "bold",
                                        color: global.$header.menuactivecolor,
                                      }}
                                      style={{ color: this.state.menutextcolor }}
                                      to={global.$header.menu.menu1.link}
                                      className="menu-item"
                                    >
                                      <NavItem
                                        className="navitem text-nowrap"
                                        onClick={this.toggleMenu}
                                      >
                                        {global.$header.menu.menu1.title}
                                      </NavItem>
                                    </LinkContainer>
                                  )
                                  :
                                  ("")
                                )
                                  :
                                  (
                                    <LinkContainer
                                      activeStyle={{
                                        fontWeight: "bold",
                                        color: global.$header.menuactivecolor,
                                      }}
                                      style={{ color: this.state.menutextcolor }}
                                      to={global.$header.menu.menu1.link}
                                      className="menu-item"
                                    >
                                      <NavItem
                                        className="navitem text-nowrap"
                                        onClick={this.toggleMenu}
                                      >
                                        {global.$header.menu.menu1.title}
                                      </NavItem>
                                    </LinkContainer>
                                  )
                              )
                                :
                                ('')
                              }
                            </div>
                          )
                          :
                          (
                            <div>
                              {global.$header.menu.menu1.login.includes("Y") ? (
                                <div>
                                  {global.$loginstatus.includes("true") ? (
                                    global.$header.menu.menu1.enablesearch != null &&
                                      global.$header.menu.menu1.enablesearch == true ? (
                                      global.$attendeeDetails[
                                        `${global.$header.menu.menu1.searchfield}`
                                      ].includes(
                                        `${global.$header.menu.menu1.searchkeyword}`
                                      ) == true ? (
                                        <LinkContainer
                                          activeStyle={{
                                            fontWeight: "bold",
                                            color: global.$header.menuactivecolor,
                                          }}
                                          style={{ color: this.state.menutextcolor }}
                                          to={global.$header.menu.menu1.link}
                                          className="menu-item"
                                        >
                                          <NavItem
                                            className="navitem text-nowrap"
                                            onClick={this.toggleMenu}
                                          >
                                            {global.$header.menu.menu1.title}
                                          </NavItem>
                                        </LinkContainer>
                                      ) : (
                                        ""
                                      )
                                    ) : (
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{ color: this.state.menutextcolor }}
                                        to={global.$header.menu.menu1.link}
                                        className="menu-item"
                                      >
                                        <NavItem
                                          className="navitem text-nowrap"
                                          onClick={this.toggleMenu}
                                        >
                                          {global.$header.menu.menu1.title}
                                        </NavItem>
                                      </LinkContainer>
                                    )
                                  ) : (
                                    <div>
                                      {global.$header.menu.menu1.showmenubeforloginandredirecttologin.includes(
                                        "Y"
                                      ) ? (
                                        <LinkContainer
                                          activeStyle={{
                                            fontWeight: "bold",
                                            color: global.$header.menuactivecolor,
                                          }}
                                          style={{ color: this.state.menutextcolor }}
                                          to="/LoginForm"
                                          className="menu-item"
                                        >
                                          <NavItem
                                            className="navitem text-nowrap"
                                            onClick={this.toggleMenu}
                                          >
                                            {global.$header.menu.menu1.title}
                                          </NavItem>
                                        </LinkContainer>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <LinkContainer
                                  activeStyle={{
                                    fontWeight: "bold",
                                    color: global.$header.menuactivecolor,
                                  }}
                                  style={{ color: this.state.menutextcolor }}
                                  to={global.$header.menu.menu1.link}
                                  className="menu-item"
                                >
                                  <NavItem
                                    className="navitem text-nowrap"
                                    onClick={this.toggleMenu}
                                  >
                                    {global.$header.menu.menu1.title}
                                  </NavItem>
                                </LinkContainer>
                              )}
                            </div>
                          )
                      )
                      :
                      ("")
                    }

                    {menu.includes("menu2") ?
                      (
                        global.$header.menu.menu2.showafterlogin != null && global.$header.menu.menu2.showafterlogin.includes("Y") ?
                          (
                            <div>
                              {global.$loginstatus.includes("true") ? (
                                global.$header.menu.menu2.enablesearch != null && global.$header.menu.menu2.enablesearch == true ? (global.$attendeeDetails[`${global.$header.menu.menu2.searchfield}`].includes(`${global.$header.menu.menu2.searchkeyword}`) == true ?
                                  (
                                    <LinkContainer
                                      activeStyle={{
                                        fontWeight: "bold",
                                        color: global.$header.menuactivecolor,
                                      }}
                                      style={{ color: this.state.menutextcolor }}
                                      to={global.$header.menu.menu2.link}
                                      className="menu-item"
                                    >
                                      <NavItem
                                        className="navitem text-nowrap"
                                        onClick={this.toggleMenu}
                                      >
                                        {global.$header.menu.menu2.title}
                                      </NavItem>
                                    </LinkContainer>
                                  )
                                  :
                                  ("")
                                )
                                  :
                                  (
                                    <LinkContainer
                                      activeStyle={{
                                        fontWeight: "bold",
                                        color: global.$header.menuactivecolor,
                                      }}
                                      style={{ color: this.state.menutextcolor }}
                                      to={global.$header.menu.menu2.link}
                                      className="menu-item"
                                    >
                                      <NavItem
                                        className="navitem text-nowrap"
                                        onClick={this.toggleMenu}
                                      >
                                        {global.$header.menu.menu2.title}
                                      </NavItem>
                                    </LinkContainer>
                                  )
                              )
                                :
                                ('')
                              }
                            </div>
                          )
                          :
                          (
                            <div>
                              {global.$header.menu.menu2.login.includes("Y") ? (
                                <div>
                                  {global.$loginstatus.includes("true") ? (
                                    global.$header.menu.menu2.enablesearch != null &&
                                      global.$header.menu.menu2.enablesearch == true ? (
                                      global.$attendeeDetails[
                                        `${global.$header.menu.menu2.searchfield}`
                                      ].includes(
                                        `${global.$header.menu.menu2.searchkeyword}`
                                      ) == true ? (
                                        <LinkContainer
                                          activeStyle={{
                                            fontWeight: "bold",
                                            color: global.$header.menuactivecolor,
                                          }}
                                          style={{ color: this.state.menutextcolor }}
                                          to={global.$header.menu.menu2.link}
                                          className="menu-item"
                                        >
                                          <NavItem
                                            className="navitem text-nowrap"
                                            onClick={this.toggleMenu}
                                          >
                                            {global.$header.menu.menu2.title}
                                          </NavItem>
                                        </LinkContainer>
                                      ) : (
                                        ""
                                      )
                                    ) : (
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{ color: this.state.menutextcolor }}
                                        to={global.$header.menu.menu2.link}
                                        className="menu-item"
                                      >
                                        <NavItem
                                          className="navitem text-nowrap"
                                          onClick={this.toggleMenu}
                                        >
                                          {global.$header.menu.menu2.title}
                                        </NavItem>
                                      </LinkContainer>
                                    )
                                  ) : (
                                    <div>
                                      {global.$header.menu.menu2.showmenubeforloginandredirecttologin.includes(
                                        "Y"
                                      ) ? (
                                        <LinkContainer
                                          activeStyle={{
                                            fontWeight: "bold",
                                            color: global.$header.menuactivecolor,
                                          }}
                                          style={{ color: this.state.menutextcolor }}
                                          to="/LoginForm"
                                          className="menu-item"
                                        >
                                          <NavItem
                                            className="navitem text-nowrap"
                                            onClick={this.toggleMenu}
                                          >
                                            {global.$header.menu.menu2.title}
                                          </NavItem>
                                        </LinkContainer>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <LinkContainer
                                  activeStyle={{
                                    fontWeight: "bold",
                                    color: global.$header.menuactivecolor,
                                  }}
                                  style={{ color: this.state.menutextcolor }}
                                  to={global.$header.menu.menu2.link}
                                  className="menu-item"
                                >
                                  <NavItem
                                    className="navitem text-nowrap"
                                    onClick={this.toggleMenu}
                                  >
                                    {global.$header.menu.menu2.title}
                                  </NavItem>
                                </LinkContainer>
                              )}
                            </div>
                          )
                      )
                      :
                      ("")
                    }
                    {menu.includes("menu3") ?
                      (
                        global.$header.menu.menu3.showafterlogin != null && global.$header.menu.menu3.showafterlogin.includes("Y") ?
                          (
                            <div>
                              {global.$loginstatus.includes("true") ? (
                                global.$header.menu.menu3.enablesearch != null && global.$header.menu.menu3.enablesearch == true ? (global.$attendeeDetails[`${global.$header.menu.menu3.searchfield}`].includes(`${global.$header.menu.menu3.searchkeyword}`) == true ?
                                  (
                                    <LinkContainer
                                      activeStyle={{
                                        fontWeight: "bold",
                                        color: global.$header.menuactivecolor,
                                      }}
                                      style={{ color: this.state.menutextcolor }}
                                      to={global.$header.menu.menu3.link}
                                      className="menu-item"
                                    >
                                      <NavItem
                                        className="navitem text-nowrap"
                                        onClick={this.toggleMenu}
                                      >
                                        {global.$header.menu.menu3.title}
                                      </NavItem>
                                    </LinkContainer>
                                  )
                                  :
                                  ("")
                                )
                                  :
                                  (
                                    <LinkContainer
                                      activeStyle={{
                                        fontWeight: "bold",
                                        color: global.$header.menuactivecolor,
                                      }}
                                      style={{ color: this.state.menutextcolor }}
                                      to={global.$header.menu.menu3.link}
                                      className="menu-item"
                                    >
                                      <NavItem
                                        className="navitem text-nowrap"
                                        onClick={this.toggleMenu}
                                      >
                                        {global.$header.menu.menu3.title}
                                      </NavItem>
                                    </LinkContainer>
                                  )
                              )
                                :
                                ('')
                              }
                            </div>
                          )
                          :
                          (
                            <div>
                              {global.$header.menu.menu3.login.includes("Y") ? (
                                <div>
                                  {global.$loginstatus.includes("true") ? (
                                    global.$header.menu.menu3.enablesearch != null &&
                                      global.$header.menu.menu3.enablesearch == true ? (
                                      global.$attendeeDetails[
                                        `${global.$header.menu.menu3.searchfield}`
                                      ].includes(
                                        `${global.$header.menu.menu3.searchkeyword}`
                                      ) == true ? (
                                        <LinkContainer
                                          activeStyle={{
                                            fontWeight: "bold",
                                            color: global.$header.menuactivecolor,
                                          }}
                                          style={{ color: this.state.menutextcolor }}
                                          to={global.$header.menu.menu3.link}
                                          className="menu-item"
                                        >
                                          <NavItem
                                            className="navitem text-nowrap"
                                            onClick={this.toggleMenu}
                                          >
                                            {global.$header.menu.menu3.title}
                                          </NavItem>
                                        </LinkContainer>
                                      ) : (
                                        ""
                                      )
                                    ) : (
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{ color: this.state.menutextcolor }}
                                        to={global.$header.menu.menu3.link}
                                        className="menu-item"
                                      >
                                        <NavItem
                                          className="navitem text-nowrap"
                                          onClick={this.toggleMenu}
                                        >
                                          {global.$header.menu.menu3.title}
                                        </NavItem>
                                      </LinkContainer>
                                    )
                                  ) : (
                                    <div>
                                      {global.$header.menu.menu3.showmenubeforloginandredirecttologin.includes(
                                        "Y"
                                      ) ? (
                                        <LinkContainer
                                          activeStyle={{
                                            fontWeight: "bold",
                                            color: global.$header.menuactivecolor,
                                          }}
                                          style={{ color: this.state.menutextcolor }}
                                          to="/LoginForm"
                                          className="menu-item"
                                        >
                                          <NavItem
                                            className="navitem text-nowrap"
                                            onClick={this.toggleMenu}
                                          >
                                            {global.$header.menu.menu3.title}
                                          </NavItem>
                                        </LinkContainer>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <LinkContainer
                                  activeStyle={{
                                    fontWeight: "bold",
                                    color: global.$header.menuactivecolor,
                                  }}
                                  style={{ color: this.state.menutextcolor }}
                                  to={global.$header.menu.menu3.link}
                                  className="menu-item"
                                >
                                  <NavItem
                                    className="navitem text-nowrap"
                                    onClick={this.toggleMenu}
                                  >
                                    {global.$header.menu.menu3.title}
                                  </NavItem>
                                </LinkContainer>
                              )}
                            </div>
                          )
                      )
                      :
                      ("")
                    }
                    {menu.includes("menu4") ?
                      (
                        global.$header.menu.menu4.showafterlogin != null && global.$header.menu.menu4.showafterlogin.includes("Y") ?
                          (
                            <div>
                              {global.$loginstatus.includes("true") ? (
                                global.$header.menu.menu4.enablesearch != null && global.$header.menu.menu4.enablesearch == true ? (global.$attendeeDetails[`${global.$header.menu.menu4.searchfield}`].includes(`${global.$header.menu.menu4.searchkeyword}`) == true ?
                                  (
                                    <LinkContainer
                                      activeStyle={{
                                        fontWeight: "bold",
                                        color: global.$header.menuactivecolor,
                                      }}
                                      style={{ color: this.state.menutextcolor }}
                                      to={global.$header.menu.menu4.link}
                                      className="menu-item"
                                    >
                                      <NavItem
                                        className="navitem text-nowrap"
                                        onClick={this.toggleMenu}
                                      >
                                        {global.$header.menu.menu4.title}
                                      </NavItem>
                                    </LinkContainer>
                                  )
                                  :
                                  ("")
                                )
                                  :
                                  (
                                    <LinkContainer
                                      activeStyle={{
                                        fontWeight: "bold",
                                        color: global.$header.menuactivecolor,
                                      }}
                                      style={{ color: this.state.menutextcolor }}
                                      to={global.$header.menu.menu4.link}
                                      className="menu-item"
                                    >
                                      <NavItem
                                        className="navitem text-nowrap"
                                        onClick={this.toggleMenu}
                                      >
                                        {global.$header.menu.menu4.title}
                                      </NavItem>
                                    </LinkContainer>
                                  )
                              )
                                :
                                ('')
                              }
                            </div>
                          )
                          :
                          (
                            <div>
                              {global.$header.menu.menu4.login.includes("Y") ? (
                                <div>
                                  {global.$loginstatus.includes("true") ? (
                                    global.$header.menu.menu4.enablesearch != null &&
                                      global.$header.menu.menu4.enablesearch == true ? (
                                      global.$attendeeDetails[
                                        `${global.$header.menu.menu4.searchfield}`
                                      ].includes(
                                        `${global.$header.menu.menu4.searchkeyword}`
                                      ) == true ? (
                                        <LinkContainer
                                          activeStyle={{
                                            fontWeight: "bold",
                                            color: global.$header.menuactivecolor,
                                          }}
                                          style={{ color: this.state.menutextcolor }}
                                          to={global.$header.menu.menu4.link}
                                          className="menu-item"
                                        >
                                          <NavItem
                                            className="navitem text-nowrap"
                                            onClick={this.toggleMenu}
                                          >
                                            {global.$header.menu.menu4.title}
                                          </NavItem>
                                        </LinkContainer>
                                      ) : (
                                        ""
                                      )
                                    ) : (
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{ color: this.state.menutextcolor }}
                                        to={global.$header.menu.menu4.link}
                                        className="menu-item"
                                      >
                                        <NavItem
                                          className="navitem text-nowrap"
                                          onClick={this.toggleMenu}
                                        >
                                          {global.$header.menu.menu4.title}
                                        </NavItem>
                                      </LinkContainer>
                                    )
                                  ) : (
                                    <div>
                                      {global.$header.menu.menu4.showmenubeforloginandredirecttologin.includes(
                                        "Y"
                                      ) ? (
                                        <LinkContainer
                                          activeStyle={{
                                            fontWeight: "bold",
                                            color: global.$header.menuactivecolor,
                                          }}
                                          style={{ color: this.state.menutextcolor }}
                                          to="/LoginForm"
                                          className="menu-item"
                                        >
                                          <NavItem
                                            className="navitem text-nowrap"
                                            onClick={this.toggleMenu}
                                          >
                                            {global.$header.menu.menu4.title}
                                          </NavItem>
                                        </LinkContainer>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <LinkContainer
                                  activeStyle={{
                                    fontWeight: "bold",
                                    color: global.$header.menuactivecolor,
                                  }}
                                  style={{ color: this.state.menutextcolor }}
                                  to={global.$header.menu.menu4.link}
                                  className="menu-item"
                                >
                                  <NavItem
                                    className="navitem text-nowrap"
                                    onClick={this.toggleMenu}
                                  >
                                    {global.$header.menu.menu4.title}
                                  </NavItem>
                                </LinkContainer>
                              )}
                            </div>
                          )
                      )
                      :
                      ("")
                    }

                    {menu.includes("menu5") ?
                      (
                        global.$header.menu.menu5.showafterlogin != null && global.$header.menu.menu5.showafterlogin.includes("Y") ?
                          (
                            <div>
                              {global.$loginstatus.includes("true") ? (
                                global.$header.menu.menu5.enablesearch != null && global.$header.menu.menu5.enablesearch == true ? (global.$attendeeDetails[`${global.$header.menu.menu5.searchfield}`].includes(`${global.$header.menu.menu5.searchkeyword}`) == true ?
                                  (
                                    <LinkContainer
                                      activeStyle={{
                                        fontWeight: "bold",
                                        color: global.$header.menuactivecolor,
                                      }}
                                      style={{ color: this.state.menutextcolor }}
                                      to={global.$header.menu.menu5.link}
                                      className="menu-item"
                                    >
                                      <NavItem
                                        className="navitem text-nowrap"
                                        onClick={this.toggleMenu}
                                      >
                                        {global.$header.menu.menu5.title}
                                      </NavItem>
                                    </LinkContainer>
                                  )
                                  :
                                  ("")
                                )
                                  :
                                  (
                                    <LinkContainer
                                      activeStyle={{
                                        fontWeight: "bold",
                                        color: global.$header.menuactivecolor,
                                      }}
                                      style={{ color: this.state.menutextcolor }}
                                      to={global.$header.menu.menu5.link}
                                      className="menu-item"
                                    >
                                      <NavItem
                                        className="navitem text-nowrap"
                                        onClick={this.toggleMenu}
                                      >
                                        {global.$header.menu.menu5.title}
                                      </NavItem>
                                    </LinkContainer>
                                  )
                              )
                                :
                                ('')
                              }
                            </div>
                          )
                          :
                          (
                            <div>
                              {global.$header.menu.menu5.login.includes("Y") ? (
                                <div>
                                  {global.$loginstatus.includes("true") ? (
                                    global.$header.menu.menu5.enablesearch != null &&
                                      global.$header.menu.menu5.enablesearch == true ? (
                                      global.$attendeeDetails[
                                        `${global.$header.menu.menu5.searchfield}`
                                      ].includes(
                                        `${global.$header.menu.menu5.searchkeyword}`
                                      ) == true ? (
                                        <LinkContainer
                                          activeStyle={{
                                            fontWeight: "bold",
                                            color: global.$header.menuactivecolor,
                                          }}
                                          style={{ color: this.state.menutextcolor }}
                                          to={global.$header.menu.menu5.link}
                                          className="menu-item"
                                        >
                                          <NavItem
                                            className="navitem text-nowrap"
                                            onClick={this.toggleMenu}
                                          >
                                            {global.$header.menu.menu5.title}
                                          </NavItem>
                                        </LinkContainer>
                                      ) : (
                                        ""
                                      )
                                    ) : (
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{ color: this.state.menutextcolor }}
                                        to={global.$header.menu.menu5.link}
                                        className="menu-item"
                                      >
                                        <NavItem
                                          className="navitem text-nowrap"
                                          onClick={this.toggleMenu}
                                        >
                                          {global.$header.menu.menu5.title}
                                        </NavItem>
                                      </LinkContainer>
                                    )
                                  ) : (
                                    <div>
                                      {global.$header.menu.menu5.showmenubeforloginandredirecttologin.includes(
                                        "Y"
                                      ) ? (
                                        <LinkContainer
                                          activeStyle={{
                                            fontWeight: "bold",
                                            color: global.$header.menuactivecolor,
                                          }}
                                          style={{ color: this.state.menutextcolor }}
                                          to="/LoginForm"
                                          className="menu-item"
                                        >
                                          <NavItem
                                            className="navitem text-nowrap"
                                            onClick={this.toggleMenu}
                                          >
                                            {global.$header.menu.menu5.title}
                                          </NavItem>
                                        </LinkContainer>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <LinkContainer
                                  activeStyle={{
                                    fontWeight: "bold",
                                    color: global.$header.menuactivecolor,
                                  }}
                                  style={{ color: this.state.menutextcolor }}
                                  to={global.$header.menu.menu5.link}
                                  className="menu-item"
                                >
                                  <NavItem
                                    className="navitem text-nowrap"
                                    onClick={this.toggleMenu}
                                  >
                                    {global.$header.menu.menu5.title}
                                  </NavItem>
                                </LinkContainer>
                              )}
                            </div>
                          )
                      )
                      :
                      ("")
                    }
                    {menu.includes("menu6") ?
                      (
                        global.$header.menu.menu6.showafterlogin != null && global.$header.menu.menu6.showafterlogin.includes("Y") ?
                          (
                            <div>
                              {global.$loginstatus.includes("true") ? (
                                global.$header.menu.menu6.enablesearch != null && global.$header.menu.menu6.enablesearch == true ? (global.$attendeeDetails[`${global.$header.menu.menu6.searchfield}`].includes(`${global.$header.menu.menu6.searchkeyword}`) == true ?
                                  (
                                    <LinkContainer
                                      activeStyle={{
                                        fontWeight: "bold",
                                        color: global.$header.menuactivecolor,
                                      }}
                                      style={{ color: this.state.menutextcolor }}
                                      to={global.$header.menu.menu6.link}
                                      className="menu-item"
                                    >
                                      <NavItem
                                        className="navitem text-nowrap"
                                        onClick={this.toggleMenu}
                                      >
                                        {global.$header.menu.menu6.title}
                                      </NavItem>
                                    </LinkContainer>
                                  )
                                  :
                                  ("")
                                )
                                  :
                                  (
                                    <LinkContainer
                                      activeStyle={{
                                        fontWeight: "bold",
                                        color: global.$header.menuactivecolor,
                                      }}
                                      style={{ color: this.state.menutextcolor }}
                                      to={global.$header.menu.menu6.link}
                                      className="menu-item"
                                    >
                                      <NavItem
                                        className="navitem text-nowrap"
                                        onClick={this.toggleMenu}
                                      >
                                        {global.$header.menu.menu6.title}
                                      </NavItem>
                                    </LinkContainer>
                                  )
                              )
                                :
                                ('')
                              }
                            </div>
                          )
                          :
                          (
                            <div>
                              {global.$header.menu.menu6.login.includes("Y") ? (
                                <div>
                                  {global.$loginstatus.includes("true") ? (
                                    global.$header.menu.menu6.enablesearch != null &&
                                      global.$header.menu.menu6.enablesearch == true ? (
                                      global.$attendeeDetails[
                                        `${global.$header.menu.menu6.searchfield}`
                                      ].includes(
                                        `${global.$header.menu.menu6.searchkeyword}`
                                      ) == true ? (
                                        <LinkContainer
                                          activeStyle={{
                                            fontWeight: "bold",
                                            color: global.$header.menuactivecolor,
                                          }}
                                          style={{ color: this.state.menutextcolor }}
                                          to={global.$header.menu.menu6.link}
                                          className="menu-item"
                                        >
                                          <NavItem
                                            className="navitem text-nowrap"
                                            onClick={this.toggleMenu}
                                          >
                                            {global.$header.menu.menu6.title}
                                          </NavItem>
                                        </LinkContainer>
                                      ) : (
                                        ""
                                      )
                                    ) : (
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{ color: this.state.menutextcolor }}
                                        to={global.$header.menu.menu6.link}
                                        className="menu-item"
                                      >
                                        <NavItem
                                          className="navitem text-nowrap"
                                          onClick={this.toggleMenu}
                                        >
                                          {global.$header.menu.menu6.title}
                                        </NavItem>
                                      </LinkContainer>
                                    )
                                  ) : (
                                    <div>
                                      {global.$header.menu.menu6.showmenubeforloginandredirecttologin.includes(
                                        "Y"
                                      ) ? (
                                        <LinkContainer
                                          activeStyle={{
                                            fontWeight: "bold",
                                            color: global.$header.menuactivecolor,
                                          }}
                                          style={{ color: this.state.menutextcolor }}
                                          to="/LoginForm"
                                          className="menu-item"
                                        >
                                          <NavItem
                                            className="navitem text-nowrap"
                                            onClick={this.toggleMenu}
                                          >
                                            {global.$header.menu.menu6.title}
                                          </NavItem>
                                        </LinkContainer>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <LinkContainer
                                  activeStyle={{
                                    fontWeight: "bold",
                                    color: global.$header.menuactivecolor,
                                  }}
                                  style={{ color: this.state.menutextcolor }}
                                  to={global.$header.menu.menu6.link}
                                  className="menu-item"
                                >
                                  <NavItem
                                    className="navitem text-nowrap"
                                    onClick={this.toggleMenu}
                                  >
                                    {global.$header.menu.menu6.title}
                                  </NavItem>
                                </LinkContainer>
                              )}
                            </div>
                          )
                      )
                      :
                      ("")
                    }

                    {menu.includes("menu7") ?
                      (
                        global.$header.menu.menu7.showafterlogin != null && global.$header.menu.menu7.showafterlogin.includes("Y") ?
                          (
                            <div>
                              {global.$loginstatus.includes("true") ? (
                                global.$header.menu.menu7.enablesearch != null && global.$header.menu.menu7.enablesearch == true ? (global.$attendeeDetails[`${global.$header.menu.menu7.searchfield}`].includes(`${global.$header.menu.menu7.searchkeyword}`) == true ?
                                  (
                                    <LinkContainer
                                      activeStyle={{
                                        fontWeight: "bold",
                                        color: global.$header.menuactivecolor,
                                      }}
                                      style={{ color: this.state.menutextcolor }}
                                      to={global.$header.menu.menu7.link}
                                      className="menu-item"
                                    >
                                      <NavItem
                                        className="navitem text-nowrap"
                                        onClick={this.toggleMenu}
                                      >
                                        {global.$header.menu.menu7.title}
                                      </NavItem>
                                    </LinkContainer>
                                  )
                                  :
                                  ("")
                                )
                                  :
                                  (
                                    <LinkContainer
                                      activeStyle={{
                                        fontWeight: "bold",
                                        color: global.$header.menuactivecolor,
                                      }}
                                      style={{ color: this.state.menutextcolor }}
                                      to={global.$header.menu.menu7.link}
                                      className="menu-item"
                                    >
                                      <NavItem
                                        className="navitem text-nowrap"
                                        onClick={this.toggleMenu}
                                      >
                                        {global.$header.menu.menu7.title}
                                      </NavItem>
                                    </LinkContainer>
                                  )
                              )
                                :
                                ('')
                              }
                            </div>
                          )
                          :
                          (
                            <div>
                              {global.$header.menu.menu7.login.includes("Y") ? (
                                <div>
                                  {global.$loginstatus.includes("true") ? (
                                    global.$header.menu.menu7.enablesearch != null &&
                                      global.$header.menu.menu7.enablesearch == true ? (
                                      global.$attendeeDetails[
                                        `${global.$header.menu.menu7.searchfield}`
                                      ].includes(
                                        `${global.$header.menu.menu7.searchkeyword}`
                                      ) == true ? (
                                        <LinkContainer
                                          activeStyle={{
                                            fontWeight: "bold",
                                            color: global.$header.menuactivecolor,
                                          }}
                                          style={{ color: this.state.menutextcolor }}
                                          to={global.$header.menu.menu7.link}
                                          className="menu-item"
                                        >
                                          <NavItem
                                            className="navitem text-nowrap"
                                            onClick={this.toggleMenu}
                                          >
                                            {global.$header.menu.menu7.title}
                                          </NavItem>
                                        </LinkContainer>
                                      ) : (
                                        ""
                                      )
                                    ) : (
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{ color: this.state.menutextcolor }}
                                        to={global.$header.menu.menu7.link}
                                        className="menu-item"
                                      >
                                        <NavItem
                                          className="navitem text-nowrap"
                                          onClick={this.toggleMenu}
                                        >
                                          {global.$header.menu.menu7.title}
                                        </NavItem>
                                      </LinkContainer>
                                    )
                                  ) : (
                                    <div>
                                      {global.$header.menu.menu7.showmenubeforloginandredirecttologin.includes(
                                        "Y"
                                      ) ? (
                                        <LinkContainer
                                          activeStyle={{
                                            fontWeight: "bold",
                                            color: global.$header.menuactivecolor,
                                          }}
                                          style={{ color: this.state.menutextcolor }}
                                          to="/LoginForm"
                                          className="menu-item"
                                        >
                                          <NavItem
                                            className="navitem text-nowrap"
                                            onClick={this.toggleMenu}
                                          >
                                            {global.$header.menu.menu7.title}
                                          </NavItem>
                                        </LinkContainer>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <LinkContainer
                                  activeStyle={{
                                    fontWeight: "bold",
                                    color: global.$header.menuactivecolor,
                                  }}
                                  style={{ color: this.state.menutextcolor }}
                                  to={global.$header.menu.menu7.link}
                                  className="menu-item"
                                >
                                  <NavItem
                                    className="navitem text-nowrap"
                                    onClick={this.toggleMenu}
                                  >
                                    {global.$header.menu.menu7.title}
                                  </NavItem>
                                </LinkContainer>
                              )}
                            </div>
                          )
                      )
                      :
                      ("")
                    }
                    {menu.includes("menu8") ?
                      (
                        global.$header.menu.menu8.showafterlogin != null && global.$header.menu.menu8.showafterlogin.includes("Y") ?
                          (
                            <div>
                              {global.$loginstatus.includes("true") ? (
                                global.$header.menu.menu8.enablesearch != null && global.$header.menu.menu8.enablesearch == true ? (global.$attendeeDetails[`${global.$header.menu.menu8.searchfield}`].includes(`${global.$header.menu.menu8.searchkeyword}`) == true ?
                                  (
                                    <LinkContainer
                                      activeStyle={{
                                        fontWeight: "bold",
                                        color: global.$header.menuactivecolor,
                                      }}
                                      style={{ color: this.state.menutextcolor }}
                                      to={global.$header.menu.menu8.link}
                                      className="menu-item"
                                    >
                                      <NavItem
                                        className="navitem text-nowrap"
                                        onClick={this.toggleMenu}
                                      >
                                        {global.$header.menu.menu8.title}
                                      </NavItem>
                                    </LinkContainer>
                                  )
                                  :
                                  ("")
                                )
                                  :
                                  (
                                    <LinkContainer
                                      activeStyle={{
                                        fontWeight: "bold",
                                        color: global.$header.menuactivecolor,
                                      }}
                                      style={{ color: this.state.menutextcolor }}
                                      to={global.$header.menu.menu8.link}
                                      className="menu-item"
                                    >
                                      <NavItem
                                        className="navitem text-nowrap"
                                        onClick={this.toggleMenu}
                                      >
                                        {global.$header.menu.menu8.title}
                                      </NavItem>
                                    </LinkContainer>
                                  )
                              )
                                :
                                ('')
                              }
                            </div>
                          )
                          :
                          (
                            <div>
                              {global.$header.menu.menu8.login.includes("Y") ? (
                                <div>
                                  {global.$loginstatus.includes("true") ? (
                                    global.$header.menu.menu8.enablesearch != null &&
                                      global.$header.menu.menu8.enablesearch == true ? (
                                      global.$attendeeDetails[
                                        `${global.$header.menu.menu8.searchfield}`
                                      ].includes(
                                        `${global.$header.menu.menu8.searchkeyword}`
                                      ) == true ? (
                                        <LinkContainer
                                          activeStyle={{
                                            fontWeight: "bold",
                                            color: global.$header.menuactivecolor,
                                          }}
                                          style={{ color: this.state.menutextcolor }}
                                          to={global.$header.menu.menu8.link}
                                          className="menu-item"
                                        >
                                          <NavItem
                                            className="navitem text-nowrap"
                                            onClick={this.toggleMenu}
                                          >
                                            {global.$header.menu.menu8.title}
                                          </NavItem>
                                        </LinkContainer>
                                      ) : (
                                        ""
                                      )
                                    ) : (
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{ color: this.state.menutextcolor }}
                                        to={global.$header.menu.menu8.link}
                                        className="menu-item"
                                      >
                                        <NavItem
                                          className="navitem text-nowrap"
                                          onClick={this.toggleMenu}
                                        >
                                          {global.$header.menu.menu8.title}
                                        </NavItem>
                                      </LinkContainer>
                                    )
                                  ) : (
                                    <div>
                                      {global.$header.menu.menu8.showmenubeforloginandredirecttologin.includes(
                                        "Y"
                                      ) ? (
                                        <LinkContainer
                                          activeStyle={{
                                            fontWeight: "bold",
                                            color: global.$header.menuactivecolor,
                                          }}
                                          style={{ color: this.state.menutextcolor }}
                                          to="/LoginForm"
                                          className="menu-item"
                                        >
                                          <NavItem
                                            className="navitem text-nowrap"
                                            onClick={this.toggleMenu}
                                          >
                                            {global.$header.menu.menu8.title}
                                          </NavItem>
                                        </LinkContainer>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <LinkContainer
                                  activeStyle={{
                                    fontWeight: "bold",
                                    color: global.$header.menuactivecolor,
                                  }}
                                  style={{ color: this.state.menutextcolor }}
                                  to={global.$header.menu.menu8.link}
                                  className="menu-item"
                                >
                                  <NavItem
                                    className="navitem text-nowrap"
                                    onClick={this.toggleMenu}
                                  >
                                    {global.$header.menu.menu8.title}
                                  </NavItem>
                                </LinkContainer>
                              )}
                            </div>
                          )
                      )
                      :
                      ("")
                    }
                    {menu.includes("menulogin") ? (
                      <div>
                        {global.$header.menu.menulogin.login.includes("Y") ? (
                          <div>
                            {global.$loginstatus.includes("true") ? (
                              <LinkContainer
                                activeStyle={{
                                  fontWeight: "bold",
                                  color: global.$header.menuactivecolor,
                                }}
                                style={{ color: this.state.menutextcolor }}
                                to={global.$header.menu.menulogin.link}
                                className="menu-item"
                              >
                                <NavItem
                                  className="navitem"
                                  onClick={this.toggleMenu}
                                >
                                  {global.$header.menu.menulogin.title}
                                </NavItem>
                              </LinkContainer>
                            ) : (
                              <div>
                                {global.$header.menu.menulogin.showmenubeforloginandredirecttologin.includes(
                                  "Y"
                                ) ? (
                                  <LinkContainer
                                    activeStyle={{
                                      fontWeight: "bold",
                                      color: global.$header.menuactivecolor,
                                    }}
                                    style={{ color: this.state.menutextcolor }}
                                    to="/LoginForm"
                                    className="menu-item"
                                  >
                                    <NavItem
                                      className="navitem"
                                      onClick={this.toggleMenu}
                                    >
                                      {global.$header.menu.menulogin.title}
                                    </NavItem>
                                  </LinkContainer>
                                ) : (
                                  ""
                                )}
                              </div>
                            )}
                          </div>
                        ) : (
                          <LinkContainer
                            activeStyle={{
                              fontWeight: "bold",
                              color: global.$header.menuactivecolor,
                            }}
                            style={{ color: this.state.menutextcolor }}
                            to={global.$header.menu.menulogin.link}
                            className="menu-item"
                          >
                            <NavItem
                              className="navitem"
                              onClick={this.toggleMenu}
                            >
                              {global.$header.menu.menulogin.title}
                            </NavItem>
                          </LinkContainer>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    {
                      menu.includes("speakerprofile") ?
                       (global.$loginstatus.includes("true") && enablespeakerprofile ?
                        (
                          <LinkContainer
                            activeStyle={{
                              fontWeight: "bold",
                              color: global.$header.menuactivecolor,
                            }}
                            style={
                              global.$header.menu.speakerprofile.styles
                            }
                            to={global.$header.menu.speakerprofile.link}
                            className="menu-item"
                          >
                          <NavItem
                            className="navitem text-nowrap"
                            onClick={this.toggleMenu}
                            
                          >
                            {global.$header.menu.speakerprofile.title}
                          </NavItem>
                        </LinkContainer>) : ("")) : ("")
                    }
                    {menu.includes("posters") ? (
                      <LinkContainer
                        activeStyle={{
                          fontWeight: "bold",
                          color: global.$header.menuactivecolor,
                        }}
                        style={{ color: this.state.menutextcolor }}
                        to="/Posters"
                        className="menu-item"
                      >
                        <NavItem className="navitem" onClick={this.toggleMenu}>
                          {global.$header.postersmenutitle}
                        </NavItem>
                      </LinkContainer>
                    ) : (
                      ""
                    )}
                    {menu.includes("speakers") ? (
                      <LinkContainer
                        activeStyle={{
                          fontWeight: "bold",
                          color: global.$header.menuactivecolor,
                        }}
                        style={{ color: this.state.menutextcolor }}
                        to="/Speakers"
                        className="menu-item"
                      >
                        <NavItem className="navitem" onClick={this.toggleMenu}>
                          SPEAKERS
                        </NavItem>
                      </LinkContainer>
                    ) : (
                      ""
                    )}
                    {menu.includes("speaker1") ? (
                      <LinkContainer
                        activeStyle={{
                          fontWeight: "bold",
                          color: global.$header.menuactivecolor,
                        }}
                        style={{ color: this.state.menutextcolor }}
                        to={global.$header.menu.speakers1.link}
                        className="menu-item"
                      >
                        <NavItem className="navitem" onClick={this.toggleMenu}>
                          {global.$header.menu.speakers1.title}
                        </NavItem>
                      </LinkContainer>
                    ) : (
                      ""
                    )}
                    {menu.includes("speaker2") ? (
                      <LinkContainer
                        activeStyle={{
                          fontWeight: "bold",
                          color: global.$header.menuactivecolor,
                        }}
                        style={{ color: this.state.menutextcolor }}
                        to="/Speakers2"
                        className="menu-item"
                      >
                        <NavItem className="navitem" onClick={this.toggleMenu}>
                          {global.$header.menu.speakers2.title}
                        </NavItem>
                      </LinkContainer>
                    ) : (
                      ""
                    )}
                    {menu.includes("speaker3") ? (
                      <LinkContainer
                        activeStyle={{
                          fontWeight: "bold",
                          color: global.$header.menuactivecolor,
                        }}
                        style={{ color: this.state.menutextcolor }}
                        to="/Speakers3"
                        className="menu-item"
                      >
                        <NavItem className="navitem" onClick={this.toggleMenu}>
                          {global.$header.menu.speakers3.title}
                        </NavItem>
                      </LinkContainer>
                    ) : (
                      ""
                    )}
                    {menu.includes("exhibitors") ? (
                      <LinkContainer
                        activeStyle={{
                          fontWeight: "bold",
                          color: global.$header.menuactivecolor,
                        }}
                        style={{ color: this.state.menutextcolor }}
                        to="/Exhibitors"
                        className="exhibitors menu-item"
                      >
                        <NavItem className="navitem" onClick={this.toggleMenu}>
                          EXHIBITORS
                        </NavItem>
                      </LinkContainer>
                    ) : (
                      ""
                    )}
                    {menu.includes("sponsors") ? (
                      <LinkContainer
                        activeStyle={{
                          fontWeight: "bold",
                          color: global.$header.menuactivecolor,
                        }}
                        style={{ color: this.state.menutextcolor }}
                        to="/Sponsors"
                        className="sponsors menu-item"
                      >
                        <NavItem className="navitem" onClick={this.toggleMenu}>
                          SPONSORS
                        </NavItem>
                      </LinkContainer>
                    ) : (
                      ""
                    )}
                    {menu.includes("trade") ? (
                      <LinkContainer
                        activeStyle={{
                          fontWeight: "bold",
                          color: global.$header.menuactivecolor,
                        }}
                        style={{ color: this.state.menutextcolor }}
                        to={global.$header.menu.trade.link}
                        className="sponsors menu-item"
                      >
                        <NavItem
                          className="navitem text-nowrap"
                          onClick={this.toggleMenu}
                        >
                          {global.$header.menu.trade.title}
                        </NavItem>
                      </LinkContainer>
                    ) : (
                      ""
                    )}
                    {menu.includes("e-submitform") ? (
                      global.$header.menu.exhibitorsubmitform.login.includes(
                        "Y"
                      ) && showexhibitormenus ? (
                        global.$loginstatus.includes(true) ?
                        (<LinkContainer
                        activeStyle={{
                          fontWeight: "bold",
                          color: global.$header.menuactivecolor,
                        }}
                        style={
                          global.$header.menu.exhibitorsubmitform.styles
                        }
                        to={global.$header.menu.exhibitorsubmitform.link}
                        className="sponsors menu-item"
                      >
                        <NavItem
                          className="navitem text-nowrap"
                          onClick={this.toggleMenu}
                        >
                          {global.$header.menu.exhibitorsubmitform.title}
                        </NavItem>
                      </LinkContainer>) : ('')
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {
                      menu.includes("e-submitform") ? (
                         showsponsormenu && global.$header.menu.sponsorsubmitform.login.includes("Y") ?
                        (
                          global.$loginstatus.includes(true) ?
                          (<LinkContainer
                            activeStyle={{
                              fontWeight: "bold",
                              color: global.$header.menuactivecolor,
                            }}
                            style={
                              global.$header.menu.sponsorsubmitform.styles
                            }
                            to={global.$header.menu.sponsorsubmitform.link}
                            className="sponsors menu-item"
                          >
                            <NavItem
                              className="navitem text-nowrap"
                              onClick={this.toggleMenu}
                            >
                              {global.$header.menu.sponsorsubmitform.title}
                            </NavItem>
                          </LinkContainer>) : ('')
                        ) : ("")
                      ):("")
                    }
                    {menu.includes("auctions") ? (
                      <LinkContainer
                        activeStyle={{
                          fontWeight: "bold",
                          color: global.$header.menuactivecolor,
                        }}
                        style={{ color: this.state.menutextcolor }}
                        to="/Auctions"
                        className="auctions menu-item"
                      >
                        <NavItem className="navitem" onClick={this.toggleMenu}>
        
                          {global.$header.menu.auctions && global.$header.menu.auctions.title != undefined ? global.$header.menu.auctions.title : ""}
                        </NavItem>
                      </LinkContainer>
                    ) : (
                      ""
                    )}
                    {menu.includes("meetups") ? (
                      global.$header.menu.meetups.login.includes("Y") ? (
                        global.$loginstatus.includes(true) ? (
                          <LinkContainer
                            activeStyle={{
                              fontWeight: "bold",
                              color: global.$header.menuactivecolor,
                            }}
                            style={global.$header.menu.meetups.style}
                            to={global.$header.menu.meetups.link}
                            className="meetups menu-item"
                          >
                            <NavItem
                              className="navitem text-nowrap"
                              onClick={this.toggleMenu}
                            >
                              {global.$header.menu.meetups.title}
                            </NavItem>
                          </LinkContainer>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {menu.includes("virtualexpo") ? (
                      <div>
                        {global.$header.menu.virtualexpo.login.includes("Y") ? (
                          <div>
                            {global.$loginstatus.includes(true) ? (
                              <LinkContainer
                                activeStyle={{
                                  fontWeight: "bold",
                                  color: global.$header.menuactivecolor,
                                }}
                                style={{ color: this.state.menutextcolor }}
                                to={global.$header.menu.virtualexpo.link}
                                className="menu-item"
                              >
                                <NavItem
                                  className="navitem text-nowrap"
                                  onClick={this.toggleMenu}
                                >
                                  {global.$header.menu.virtualexpo.title}
                                </NavItem>
                              </LinkContainer>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          <LinkContainer
                            activeStyle={{
                              fontWeight: "bold",
                              color: global.$header.menuactivecolor,
                            }}
                            style={{ color: this.state.menutextcolor }}
                            to={global.$header.menu.virtualexpo.link}
                            className="menu-item"
                          >
                            <NavItem
                              className="navitem text-nowrap"
                              onClick={this.toggleMenu}
                            >
                              {global.$header.menu.virtualexpo.title}
                            </NavItem>
                          </LinkContainer>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    {menu.includes("dropdown1") ? (
                      <div>
                        {this.state.dropdown1login.includes("Y") ? (
                          <div>
                            {global.$loginstatus.includes(true) ? (
                              <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle
                                  nav
                                  caret
                                  className="nav-dropdown"
                                  id="dropdown-menu"
                                  activeStyle={{
                                    fontWeight: "bold",
                                    color: "black",
                                  }}
                                >
                                  <p
                                    className="dropdown-title"
                                    style={global.$dropdown1.style}
                                  >
                                    {global.$dropdown1.title}
                                  </p>
                                </DropdownToggle>
                                <DropdownMenu right>
                                  <DropdownItem>
                                    {global.$dropdown1.item1.login.includes(
                                      "Y"
                                    ) ? (
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown1.item1.style}
                                        to={global.$dropdown1.item1.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown1.item1.title}
                                        </NavItem>
                                      </LinkContainer>
                                    ) : (
                                      ""
                                    )}
                                  </DropdownItem>
                                  {global.$dropdown1.item2.login.includes(
                                    "Y"
                                  ) ? (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown1.item2.style}
                                        to={global.$dropdown1.item2.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown1.item2.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  ) : (
                                    ""
                                  )}
                                  {global.$dropdown1.item3.login.includes(
                                    "Y"
                                  ) ? (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown1.item3.style}
                                        to={global.$dropdown1.item3.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown1.item3.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  ) : (
                                    ""
                                  )}
                                  {global.$dropdown1.item4.login.includes(
                                    "Y"
                                  ) ? (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown1.item4.style}
                                        to={global.$dropdown1.item4.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown1.item4.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  ) : (
                                    ""
                                  )}
                                  {global.$dropdown1.item5.login.includes(
                                    "Y"
                                  ) ? (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown1.item5.style}
                                        to={global.$dropdown1.item5.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown1.item5.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  ) : (
                                    ""
                                  )}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle
                              nav
                              caret
                              className="nav-dropdown"
                              id="dropdown-menu"
                              activeStyle={{
                                fontWeight: "bold",
                                color: "black",
                              }}
                            >
                              <p
                                className="dropdown-title"
                                style={global.$dropdown1.style}
                              >
                                {global.$dropdown1.title}
                              </p>
                            </DropdownToggle>
                            <DropdownMenu right>
                              {global.$dropdown1.item1.login.includes("Y") ? (
                                <div>
                                  {global.$loginstatus.includes(true) ? (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown1.item1.style}
                                        to={global.$dropdown1.item1.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown1.item1.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                <div>
                                  {global.$dropdown1.item1.title.includes(
                                    "empty"
                                  ) ? (
                                    <div></div>
                                  ) : (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown1.item1.style}
                                        to={global.$dropdown1.item1.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown1.item1.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  )}
                                </div>
                              )}
                              {global.$dropdown1.item2.login.includes("Y") ? (
                                <div>
                                  {global.$loginstatus.includes(true) ? (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown1.item2.style}
                                        to={global.$dropdown1.item2.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown1.item2.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                <div>
                                  {global.$dropdown1.item2.title.includes(
                                    "empty"
                                  ) ? (
                                    <div></div>
                                  ) : (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown1.item2.style}
                                        to={global.$dropdown1.item2.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown1.item2.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  )}
                                </div>
                              )}
                              {global.$dropdown1.item3.login.includes("Y") ? (
                                <div>
                                  {global.$loginstatus.includes(true) ? (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown1.item3.style}
                                        to={global.$dropdown1.item3.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown1.item3.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                <div>
                                  {global.$dropdown1.item3.title.includes(
                                    "empty"
                                  ) ? (
                                    <div></div>
                                  ) : (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown1.item3.style}
                                        to={global.$dropdown1.item3.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown1.item3.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  )}
                                </div>
                              )}
                              {global.$dropdown1.item4.login.includes("Y") ? (
                                <div>
                                  {global.$loginstatus.includes(true) ? (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown1.item4.style}
                                        to={global.$dropdown1.item4.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown1.item4.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                <div>
                                  {global.$dropdown1.item4.title.includes(
                                    "empty"
                                  ) ? (
                                    <div></div>
                                  ) : (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown1.item4.style}
                                        to={global.$dropdown1.item4.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown1.item4.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  )}
                                </div>
                              )}
                              {global.$dropdown1.item5.login.includes("Y") ? (
                                <div>
                                  {global.$loginstatus.includes(true) ? (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown1.item5.style}
                                        to={global.$dropdown1.item5.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown1.item5.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                <div>
                                  {global.$dropdown1.item5.title.includes(
                                    "empty"
                                  ) ? (
                                    <div></div>
                                  ) : (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown1.item5.style}
                                        to={global.$dropdown1.item5.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown1.item5.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  )}
                                </div>
                              )}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    {menu.includes("dropdown2") ? (
                      <div>
                        {this.state.dropdown2login.includes("Y") ? (
                          <div>
                            {global.$loginstatus.includes(true) ? (
                              <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle
                                  nav
                                  caret
                                  className="nav-dropdown"
                                  id="dropdown-menu"
                                  activeStyle={{
                                    fontWeight: "bold",
                                    color: "black",
                                  }}
                                >
                                  <p
                                    className="dropdown-title"
                                    style={global.$dropdown2.style}
                                  >
                                    {global.$dropdown2.title}
                                  </p>
                                </DropdownToggle>
                                <DropdownMenu right>
                                  <DropdownItem>
                                    {global.$dropdown2.item1.login.includes(
                                      "Y"
                                    ) ? (
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown2.item1.style}
                                        to={global.$dropdown2.item1.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown2.item1.title}
                                        </NavItem>
                                      </LinkContainer>
                                    ) : (
                                      ""
                                    )}
                                  </DropdownItem>
                                  {global.$dropdown2.item2.login.includes(
                                    "Y"
                                  ) ? (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown2.item2.style}
                                        to={global.$dropdown2.item2.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown2.item2.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  ) : (
                                    ""
                                  )}
                                  {global.$dropdown2.item3.login.includes(
                                    "Y"
                                  ) ? (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown2.item3.style}
                                        to={global.$dropdown2.item3.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown2.item3.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  ) : (
                                    ""
                                  )}
                                  {global.$dropdown2.item4.login.includes(
                                    "Y"
                                  ) ? (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown2.item4.style}
                                        to={global.$dropdown2.item4.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown2.item4.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  ) : (
                                    ""
                                  )}
                                  {global.$dropdown2.item5.login.includes(
                                    "Y"
                                  ) ? (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown2.item5.style}
                                        to={global.$dropdown2.item5.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown2.item5.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  ) : (
                                    ""
                                  )}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle
                              nav
                              caret
                              className="nav-dropdown"
                              id="dropdown-menu"
                              activeStyle={{
                                fontWeight: "bold",
                                color: "black",
                              }}
                            >
                              <p
                                className="dropdown-title"
                                style={global.$dropdown2.style}
                              >
                                {global.$dropdown2.title}
                              </p>
                            </DropdownToggle>
                            <DropdownMenu right>
                              {global.$dropdown2.item1.login.includes("Y") ? (
                                <div>
                                  {global.$loginstatus.includes(true) ? (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown2.item1.style}
                                        to={global.$dropdown2.item1.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown2.item1.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                <div>
                                  {global.$dropdown2.item1.title.includes(
                                    "empty"
                                  ) ? (
                                    <div></div>
                                  ) : (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown2.item1.style}
                                        to={global.$dropdown2.item1.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown2.item1.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  )}
                                </div>
                              )}
                              {global.$dropdown2.item2.login.includes("Y") ? (
                                <div>
                                  {global.$loginstatus.includes(true) ? (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown2.item2.style}
                                        to={global.$dropdown2.item2.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown2.item2.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                <div>
                                  {global.$dropdown2.item2.title.includes(
                                    "empty"
                                  ) ? (
                                    <div></div>
                                  ) : (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown2.item2.style}
                                        to={global.$dropdown2.item2.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown2.item2.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  )}
                                </div>
                              )}
                              {global.$dropdown2.item3.login.includes("Y") ? (
                                <div>
                                  {global.$loginstatus.includes(true) ? (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown2.item3.style}
                                        to={global.$dropdown2.item3.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown2.item3.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                <div>
                                  {global.$dropdown2.item3.title.includes(
                                    "empty"
                                  ) ? (
                                    <div></div>
                                  ) : (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown2.item3.style}
                                        to={global.$dropdown2.item3.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown2.item3.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  )}
                                </div>
                              )}
                              {global.$dropdown2.item4.login.includes("Y") ? (
                                <div>
                                  {global.$loginstatus.includes(true) ? (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown2.item4.style}
                                        to={global.$dropdown2.item4.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown2.item4.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                <div>
                                  {global.$dropdown2.item4.title.includes(
                                    "empty"
                                  ) ? (
                                    <div></div>
                                  ) : (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown2.item4.style}
                                        to={global.$dropdown2.item4.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown2.item4.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  )}
                                </div>
                              )}
                              {global.$dropdown2.item5.login.includes("Y") ? (
                                <div>
                                  {global.$loginstatus.includes(true) ? (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown2.item5.style}
                                        to={global.$dropdown2.item5.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown2.item5.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                <div>
                                  {global.$dropdown2.item5.title.includes(
                                    "empty"
                                  ) ? (
                                    <div></div>
                                  ) : (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown2.item5.style}
                                        to={global.$dropdown2.item5.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown2.item5.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  )}
                                </div>
                              )}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    {menu.includes("dropdown3") ? (
                      <div>
                        {this.state.dropdown3login.includes("Y") ? (
                          <div>
                            {global.$loginstatus.includes(true) ? (
                              <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle
                                  nav
                                  caret
                                  className="nav-dropdown"
                                  id="dropdown-menu"
                                  activeStyle={{
                                    fontWeight: "bold",
                                    color: "black",
                                  }}
                                >
                                  <p
                                    className="dropdown-title"
                                    style={global.$dropdown3.style}
                                  >
                                    {global.$dropdown3.title}
                                  </p>
                                </DropdownToggle>
                                <DropdownMenu right>
                                  <DropdownItem>
                                    {global.$dropdown3.item1.login.includes(
                                      "Y"
                                    ) ? (
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown3.item1.style}
                                        to={global.$dropdown3.item1.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown3.item1.title}
                                        </NavItem>
                                      </LinkContainer>
                                    ) : (
                                      ""
                                    )}
                                  </DropdownItem>
                                  {global.$dropdown3.item2.login.includes(
                                    "Y"
                                  ) ? (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown3.item2.style}
                                        to={global.$dropdown3.item2.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown3.item2.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  ) : (
                                    ""
                                  )}
                                  {global.$dropdown3.item3.login.includes(
                                    "Y"
                                  ) ? (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown3.item3.style}
                                        to={global.$dropdown3.item3.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown3.item3.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  ) : (
                                    ""
                                  )}
                                  {global.$dropdown3.item4.login.includes(
                                    "Y"
                                  ) ? (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown3.item4.style}
                                        to={global.$dropdown3.item4.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown3.item4.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  ) : (
                                    ""
                                  )}
                                  {global.$dropdown3.item5.login.includes(
                                    "Y"
                                  ) ? (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown3.item5.style}
                                        to={global.$dropdown3.item5.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown3.item5.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  ) : (
                                    ""
                                  )}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle
                              nav
                              caret
                              className="nav-dropdown"
                              id="dropdown-menu"
                              activeStyle={{
                                fontWeight: "bold",
                                color: "black",
                              }}
                            >
                              <p
                                className="dropdown-title"
                                style={global.$dropdown3.style}
                              >
                                {global.$dropdown3.title}
                              </p>
                            </DropdownToggle>
                            <DropdownMenu right>
                              {global.$dropdown3.item1.login.includes("Y") ? (
                                <div>
                                  {global.$loginstatus.includes(true) ? (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown3.item1.style}
                                        to={global.$dropdown3.item1.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown3.item1.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                <div>
                                  {global.$dropdown3.item1.title.includes(
                                    "empty"
                                  ) ? (
                                    <div></div>
                                  ) : (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown3.item1.style}
                                        to={global.$dropdown3.item1.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown3.item1.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  )}
                                </div>
                              )}
                              {global.$dropdown3.item2.login.includes("Y") ? (
                                <div>
                                  {global.$loginstatus.includes(true) ? (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown3.item2.style}
                                        to={global.$dropdown3.item2.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown3.item2.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                <div>
                                  {global.$dropdown3.item2.title.includes(
                                    "empty"
                                  ) ? (
                                    <div></div>
                                  ) : (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown3.item2.style}
                                        to={global.$dropdown3.item2.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown3.item2.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  )}
                                </div>
                              )}
                              {global.$dropdown3.item3.login.includes("Y") ? (
                                <div>
                                  {global.$loginstatus.includes(true) ? (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown3.item3.style}
                                        to={global.$dropdown3.item3.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown3.item3.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                <div>
                                  {global.$dropdown3.item3.title.includes(
                                    "empty"
                                  ) ? (
                                    <div></div>
                                  ) : (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown3.item3.style}
                                        to={global.$dropdown3.item3.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown3.item3.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  )}
                                </div>
                              )}
                              {global.$dropdown3.item4.login.includes("Y") ? (
                                <div>
                                  {global.$loginstatus.includes(true) ? (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown3.item4.style}
                                        to={global.$dropdown3.item4.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown3.item4.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                <div>
                                  {global.$dropdown3.item4.title.includes(
                                    "empty"
                                  ) ? (
                                    <div></div>
                                  ) : (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown3.item4.style}
                                        to={global.$dropdown3.item4.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown3.item4.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  )}
                                </div>
                              )}
                              {global.$dropdown3.item5.login.includes("Y") ? (
                                <div>
                                  {global.$loginstatus.includes(true) ? (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown3.item5.style}
                                        to={global.$dropdown3.item5.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown3.item5.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                <div>
                                  {global.$dropdown3.item5.title.includes(
                                    "empty"
                                  ) ? (
                                    <div></div>
                                  ) : (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown3.item5.style}
                                        to={global.$dropdown3.item5.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown3.item5.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  )}
                                </div>
                              )}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    {menu.includes("dropdown4") ? (
                      <div>
                        {this.state.dropdown4login.includes("Y") ? (
                          <div>
                            {global.$loginstatus.includes(true) ? (
                              <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle
                                  nav
                                  caret
                                  className="nav-dropdown"
                                  id="dropdown-menu"
                                  activeStyle={{
                                    fontWeight: "bold",
                                    color: "black",
                                  }}
                                >
                                  <p
                                    className="dropdown-title"
                                    style={global.$dropdown4.style}
                                  >
                                    {global.$dropdown4.title}
                                  </p>
                                </DropdownToggle>
                                <DropdownMenu right>
                                  <DropdownItem>
                                    {global.$dropdown4.item1.login.includes(
                                      "Y"
                                    ) ? (
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown4.item1.style}
                                        to={global.$dropdown4.item1.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown4.item1.title}
                                        </NavItem>
                                      </LinkContainer>
                                    ) : (
                                      ""
                                    )}
                                  </DropdownItem>
                                  {global.$dropdown4.item2.login.includes(
                                    "Y"
                                  ) ? (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown4.item2.style}
                                        to={global.$dropdown4.item2.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown4.item2.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  ) : (
                                    ""
                                  )}
                                  {global.$dropdown4.item3.login.includes(
                                    "Y"
                                  ) ? (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown4.item3.style}
                                        to={global.$dropdown4.item3.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown4.item3.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  ) : (
                                    ""
                                  )}
                                  {global.$dropdown4.item4.login.includes(
                                    "Y"
                                  ) ? (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown4.item4.style}
                                        to={global.$dropdown4.item4.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown4.item4.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  ) : (
                                    ""
                                  )}
                                  {global.$dropdown4.item5.login.includes(
                                    "Y"
                                  ) ? (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown4.item5.style}
                                        to={global.$dropdown4.item5.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown4.item5.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  ) : (
                                    ""
                                  )}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle
                              nav
                              caret
                              className="nav-dropdown"
                              id="dropdown-menu"
                              activeStyle={{
                                fontWeight: "bold",
                                color: "black",
                              }}
                            >
                              <p
                                className="dropdown-title"
                                style={global.$dropdown4.style}
                              >
                                {global.$dropdown4.title}
                              </p>
                            </DropdownToggle>
                            <DropdownMenu right>
                              {global.$dropdown4.item1.login.includes("Y") ? (
                                <div>
                                  {global.$loginstatus.includes(true) ? (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown4.item1.style}
                                        to={global.$dropdown4.item1.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown4.item1.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                <div>
                                  {global.$dropdown4.item1.title.includes(
                                    "empty"
                                  ) ? (
                                    <div></div>
                                  ) : (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown4.item1.style}
                                        to={global.$dropdown4.item1.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown4.item1.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  )}
                                </div>
                              )}
                              {global.$dropdown4.item2.login.includes("Y") ? (
                                <div>
                                  {global.$loginstatus.includes(true) ? (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown4.item2.style}
                                        to={global.$dropdown4.item2.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown4.item2.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                <div>
                                  {global.$dropdown4.item2.title.includes(
                                    "empty"
                                  ) ? (
                                    <div></div>
                                  ) : (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown4.item2.style}
                                        to={global.$dropdown4.item2.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown4.item2.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  )}
                                </div>
                              )}
                              {global.$dropdown4.item3.login.includes("Y") ? (
                                <div>
                                  {global.$loginstatus.includes(true) ? (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown4.item3.style}
                                        to={global.$dropdown4.item3.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown4.item3.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                <div>
                                  {global.$dropdown4.item3.title.includes(
                                    "empty"
                                  ) ? (
                                    <div></div>
                                  ) : (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown4.item3.style}
                                        to={global.$dropdown4.item3.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown4.item3.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  )}
                                </div>
                              )}
                              {global.$dropdown4.item4.login.includes("Y") ? (
                                <div>
                                  {global.$loginstatus.includes(true) ? (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown4.item4.style}
                                        to={global.$dropdown4.item4.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown4.item4.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                <div>
                                  {global.$dropdown4.item4.title.includes(
                                    "empty"
                                  ) ? (
                                    <div></div>
                                  ) : (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown4.item4.style}
                                        to={global.$dropdown4.item4.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown4.item4.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  )}
                                </div>
                              )}
                              {global.$dropdown4.item5.login.includes("Y") ? (
                                <div>
                                  {global.$loginstatus.includes(true) ? (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown4.item5.style}
                                        to={global.$dropdown4.item5.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown4.item5.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                <div>
                                  {global.$dropdown4.item5.title.includes(
                                    "empty"
                                  ) ? (
                                    <div></div>
                                  ) : (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown4.item5.style}
                                        to={global.$dropdown4.item5.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown4.item5.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  )}
                                </div>
                              )}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    {menu.includes("dropdown5") ? (
                      <div>
                        {this.state.dropdown5login.includes("Y") ? (
                          <div>
                            {global.$loginstatus.includes(true) ? (
                              <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle
                                  nav
                                  caret
                                  className="nav-dropdown"
                                  id="dropdown-menu"
                                  activeStyle={{
                                    fontWeight: "bold",
                                    color: "black",
                                  }}
                                >
                                  <p
                                    className="dropdown-title"
                                    style={global.$dropdown5.style}
                                  >
                                    {global.$dropdown5.title}
                                  </p>
                                </DropdownToggle>
                                <DropdownMenu right>
                                  <DropdownItem>
                                    {global.$dropdown5.item1.login.includes(
                                      "Y"
                                    ) ? (
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown5.item1.style}
                                        to={global.$dropdown5.item1.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown5.item1.title}
                                        </NavItem>
                                      </LinkContainer>
                                    ) : (
                                      ""
                                    )}
                                  </DropdownItem>
                                  {global.$dropdown5.item2.login.includes(
                                    "Y"
                                  ) ? (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown5.item2.style}
                                        to={global.$dropdown5.item2.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown5.item2.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  ) : (
                                    ""
                                  )}
                                  {global.$dropdown5.item3.login.includes(
                                    "Y"
                                  ) ? (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown5.item3.style}
                                        to={global.$dropdown5.item3.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown5.item3.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  ) : (
                                    ""
                                  )}
                                  {global.$dropdown5.item4.login.includes(
                                    "Y"
                                  ) ? (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown5.item4.style}
                                        to={global.$dropdown5.item4.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown5.item4.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  ) : (
                                    ""
                                  )}
                                  {global.$dropdown5.item5.login.includes(
                                    "Y"
                                  ) ? (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown5.item5.style}
                                        to={global.$dropdown5.item5.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown5.item5.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  ) : (
                                    ""
                                  )}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle
                              nav
                              caret
                              className="nav-dropdown"
                              id="dropdown-menu"
                              activeStyle={{
                                fontWeight: "bold",
                                color: "black",
                              }}
                            >
                              <p
                                className="dropdown-title"
                                style={global.$dropdown5.style}
                              >
                                {global.$dropdown5.title}
                              </p>
                            </DropdownToggle>
                            <DropdownMenu right>
                              {global.$dropdown5.item1.login.includes("Y") ? (
                                <div>
                                  {global.$loginstatus.includes(true) ? (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown5.item1.style}
                                        to={global.$dropdown5.item1.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown5.item1.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                <div>
                                  {global.$dropdown5.item1.title.includes(
                                    "empty"
                                  ) ? (
                                    <div></div>
                                  ) : (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown5.item1.style}
                                        to={global.$dropdown5.item1.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown5.item1.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  )}
                                </div>
                              )}
                              {global.$dropdown5.item2.login.includes("Y") ? (
                                <div>
                                  {global.$loginstatus.includes(true) ? (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown5.item2.style}
                                        to={global.$dropdown5.item2.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown5.item2.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                <div>
                                  {global.$dropdown5.item2.title.includes(
                                    "empty"
                                  ) ? (
                                    <div></div>
                                  ) : (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown5.item2.style}
                                        to={global.$dropdown5.item2.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown5.item2.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  )}
                                </div>
                              )}
                              {global.$dropdown5.item3.login.includes("Y") ? (
                                <div>
                                  {global.$loginstatus.includes(true) ? (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown5.item3.style}
                                        to={global.$dropdown5.item3.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown5.item3.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                <div>
                                  {global.$dropdown5.item3.title.includes(
                                    "empty"
                                  ) ? (
                                    <div></div>
                                  ) : (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown5.item3.style}
                                        to={global.$dropdown5.item3.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown5.item3.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  )}
                                </div>
                              )}
                              {global.$dropdown5.item4.login.includes("Y") ? (
                                <div>
                                  {global.$loginstatus.includes(true) ? (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown5.item4.style}
                                        to={global.$dropdown5.item4.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown5.item4.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                <div>
                                  {global.$dropdown5.item4.title.includes(
                                    "empty"
                                  ) ? (
                                    <div></div>
                                  ) : (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown5.item4.style}
                                        to={global.$dropdown5.item4.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown5.item4.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  )}
                                </div>
                              )}
                              {global.$dropdown5.item5.login.includes("Y") ? (
                                <div>
                                  {global.$loginstatus.includes(true) ? (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown5.item5.style}
                                        to={global.$dropdown5.item5.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown5.item5.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                <div>
                                  {global.$dropdown5.item5.title.includes(
                                    "empty"
                                  ) ? (
                                    <div></div>
                                  ) : (
                                    <DropdownItem>
                                      <LinkContainer
                                        activeStyle={{
                                          fontWeight: "bold",
                                          color: global.$header.menuactivecolor,
                                        }}
                                        style={{
                                          color: this.state.menutextcolor,
                                        }}
                                        style={global.$dropdown5.item5.style}
                                        to={global.$dropdown5.item5.link}
                                        className="dropdown-item menu-item"
                                      >
                                        <NavItem onClick={this.toggleMenu}>
                                          {global.$dropdown5.item5.title}
                                        </NavItem>
                                      </LinkContainer>
                                    </DropdownItem>
                                  )}
                                </div>
                              )}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    {menu.includes("html1") ? (
                      <div>
                        {global.$header.menu.html1.login.includes("Y") ? (
                          <div>
                            {global.$loginstatus.includes(true) ? (
                              <LinkContainer
                                activeStyle={{
                                  fontWeight: "bold",
                                  color: global.$header.menuactivecolor,
                                }}
                                style={{ color: this.state.menutextcolor }}
                                to={`/Html1/${global.$header.menu.html1.link}questionmarkfirstname=${global.$fname}&lastname=${global.$lname}&email=${global.$email}&userid=${global.$userid}&company=${global.$company}`}
                                className="menu-item"
                              >
                                <NavItem
                                  className="navitem"
                                  onClick={this.toggleMenu}
                                >
                                  {global.$header.menu.html1.title}
                                </NavItem>
                              </LinkContainer>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          <LinkContainer
                            activeStyle={{
                              fontWeight: "bold",
                              color: global.$header.menuactivecolor,
                            }}
                            style={{ color: this.state.menutextcolor }}
                            to={`/Html1`}
                            className="menu-item"
                          >
                            <NavItem
                              className="navitem"
                              onClick={this.toggleMenu}
                            >
                              {global.$header.menu.html1.title}
                            </NavItem>
                          </LinkContainer>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    {menu.includes("html2") ? (
                      <div>
                        {global.$header.menu.html2.login.includes("Y") ? (
                          <div>
                            {global.$loginstatus.includes(true) ? (
                              <LinkContainer
                                activeStyle={{
                                  fontWeight: "bold",
                                  color: global.$header.menuactivecolor,
                                }}
                                style={{ color: this.state.menutextcolor }}
                                to={`/Html2/${global.$header.menu.html2.link}questionmarkfirstname=${global.$fname}&lastname=${global.$lname}&email=${global.$email}&userid=${global.$userid}&company=${global.$company}`}
                                className="menu-item"
                              >
                                <NavItem
                                  className="navitem"
                                  onClick={this.toggleMenu}
                                >
                                  {global.$header.menu.html2.title}
                                </NavItem>
                              </LinkContainer>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          <LinkContainer
                            activeStyle={{
                              fontWeight: "bold",
                              color: global.$header.menuactivecolor,
                            }}
                            style={{ color: this.state.menutextcolor }}
                            to={`/Html2`}
                            className="menu-item"
                          >
                            <NavItem
                              className="navitem"
                              onClick={this.toggleMenu}
                            >
                              {global.$header.menu.html2.title}
                            </NavItem>
                          </LinkContainer>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    {menu.includes("html3") ? (
                      <div>
                        {global.$header.menu.html3.login.includes("Y") ? (
                          <div>
                            {global.$loginstatus.includes(true) ? (
                              <LinkContainer
                                activeStyle={{
                                  fontWeight: "bold",
                                  color: global.$header.menuactivecolor,
                                }}
                                style={{ color: this.state.menutextcolor }}
                                to={`/Html3/${global.$header.menu.html3.link}questionmarkfirstname=${global.$fname}&lastname=${global.$lname}&email=${global.$email}&userid=${global.$userid}&company=${global.$company}`}
                                className="menu-item"
                              >
                                <NavItem
                                  className="navitem"
                                  onClick={this.toggleMenu}
                                >
                                  {global.$header.menu.html3.title}
                                </NavItem>
                              </LinkContainer>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          <LinkContainer
                            activeStyle={{
                              fontWeight: "bold",
                              color: global.$header.menuactivecolor,
                            }}
                            style={{ color: this.state.menutextcolor }}
                            to={`/Html3`}
                            className="menu-item"
                          >
                            <NavItem
                              className="navitem"
                              onClick={this.toggleMenu}
                            >
                              {global.$header.menu.html3.title}
                            </NavItem>
                          </LinkContainer>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    {menu.includes("html4") ? (
                      <div>
                        {global.$header.menu.html4.login.includes("Y") ? (
                          <div>
                            {global.$loginstatus.includes(true) ? (
                              <LinkContainer
                                activeStyle={{
                                  fontWeight: "bold",
                                  color: global.$header.menuactivecolor,
                                }}
                                style={{ color: this.state.menutextcolor }}
                                to={`/Html4/${global.$header.menu.html4.link}questionmarkfirstname=${global.$fname}&lastname=${global.$lname}&email=${global.$email}&userid=${global.$userid}&company=${global.$company}`}
                                className="menu-item"
                              >
                                <NavItem
                                  className="navitem"
                                  onClick={this.toggleMenu}
                                >
                                  {global.$header.menu.html4.title}
                                </NavItem>
                              </LinkContainer>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          <LinkContainer
                            activeStyle={{
                              fontWeight: "bold",
                              color: global.$header.menuactivecolor,
                            }}
                            style={{ color: this.state.menutextcolor }}
                            to={`/html4`}
                            className="menu-item"
                          >
                            <NavItem
                              className="navitem"
                              onClick={this.toggleMenu}
                            >
                              {global.$header.menu.html4.title}
                            </NavItem>
                          </LinkContainer>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    {menu.includes("html5") ? (
                      <div>
                        {global.$header.menu.html5.login.includes("Y") ? (
                          <div>
                            {global.$loginstatus.includes(true) ? (
                              <LinkContainer
                                activeStyle={{
                                  fontWeight: "bold",
                                  color: global.$header.menuactivecolor,
                                }}
                                style={{ color: this.state.menutextcolor }}
                                to={`/html5/${global.$header.menu.html5.link}questionmarkfirstname=${global.$fname}&lastname=${global.$lname}&email=${global.$email}&userid=${global.$userid}&company=${global.$company}`}
                                className="menu-item"
                              >
                                <NavItem
                                  className="navitem"
                                  onClick={this.toggleMenu}
                                >
                                  {global.$header.menu.html5.title}
                                </NavItem>
                              </LinkContainer>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          <LinkContainer
                            activeStyle={{
                              fontWeight: "bold",
                              color: global.$header.menuactivecolor,
                            }}
                            style={{ color: this.state.menutextcolor }}
                            to={`/html5`}
                            className="menu-item"
                          >
                            <NavItem
                              className="navitem"
                              onClick={this.toggleMenu}
                            >
                              {global.$header.menu.html5.title}
                            </NavItem>
                          </LinkContainer>
                        )}
                      </div>
                    ) : (
                      ""
                    )}

                    {menu.includes("html6") ? (
                      <div>
                        {global.$header.menu.html6.login.includes("Y") ? (
                          <div>
                            {global.$loginstatus.includes(true) ? (
                              <LinkContainer
                                activeStyle={{
                                  fontWeight: "bold",
                                  color: global.$header.menuactivecolor,
                                }}
                                style={{ color: this.state.menutextcolor }}
                                to={`/html6/${global.$header.menu.html6.link}questionmarkfirstname=${global.$fname}&lastname=${global.$lname}&email=${global.$email}&userid=${global.$userid}&company=${global.$company}`}
                                className="menu-item"
                              >
                                <NavItem
                                  className="navitem"
                                  onClick={this.toggleMenu}
                                >
                                  {global.$header.menu.html6.title}
                                </NavItem>
                              </LinkContainer>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          <LinkContainer
                            activeStyle={{
                              fontWeight: "bold",
                              color: global.$header.menuactivecolor,
                            }}
                            style={{ color: this.state.menutextcolor }}
                            to={`/html6`}
                            className="menu-item"
                          >
                            <NavItem
                              className="navitem"
                              onClick={this.toggleMenu}
                            >
                              {global.$header.menu.html6.title}
                            </NavItem>
                          </LinkContainer>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    {global.$loginstatus.includes(true) ? (
                      <div>
                        {menu.includes("nominations") ? (
                          <LinkContainer
                            activeStyle={{
                              fontWeight: "bold",
                              color: global.$header.menuactivecolor,
                            }}
                            style={{ color: this.state.menutextcolor }}
                            to={`/LoadExternalLinks/${global.$header.menu.nominations.externallink}questionmarkfirstname=${global.$fname}&lastname=${global.$lname}&email=${global.$email}&userid=${global.$userid}&company=${global.$company}`}
                            className="menu-item"
                          >
                            <NavItem
                              className="navitem"
                              onClick={this.toggleMenu}
                            >
                              {global.$header.menu.nominations.title}
                            </NavItem>
                          </LinkContainer>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    {global.$loginstatus.includes(true) ? (
                      <div>
                        {menu.includes("evaluation") ? (
                          <LinkContainer
                            activeStyle={{
                              fontWeight: "bold",
                              color: global.$header.menuactivecolor,
                            }}
                            style={{ color: this.state.menutextcolor }}
                            to={`/Evaluation/${global.$header.menu.evaluation.externallink}questionmarkfirstname=${global.$fname}&lastname=${global.$lname}&email=${global.$email}&userid=${global.$userid}&company=${global.$company}`}
                            className="menu-item"
                          >
                            <NavItem
                              className="navitem"
                              onClick={this.toggleMenu}
                            >
                              {global.$header.menu.evaluation.title}
                            </NavItem>
                          </LinkContainer>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    {global.$loginstatus.includes(true) ? (
                      <div>
                        {menu.includes("feedback") ? (
                          <LinkContainer
                            activeStyle={{
                              fontWeight: "bold",
                              color: global.$header.menuactivecolor,
                            }}
                            style={{ color: this.state.menutextcolor }}
                            to={`/LoadExternalLinks/${global.$header.menu.feedback.externallink}`}
                            className="menu-item"
                          >
                            <NavItem
                              className="navitem"
                              onClick={this.toggleMenu}
                            >
                              {global.$header.menu.feedback.title}
                            </NavItem>
                          </LinkContainer>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    {global.$loginstatus.includes(true) ? (
                      <div>
                        {menu.includes("conversations") ? (
                          <LinkContainer
                            activeStyle={{
                              fontWeight: "bold",
                              color: global.$header.menuactivecolor,
                            }}
                            style={{ color: this.state.menutextcolor }}
                            to="/conversations"
                            className="conversations menu-item"
                          >
                            <NavItem
                              className="navitem"
                              onClick={this.toggleMenu}
                            >
                              {global.$header.menu.conversations.title}
                            </NavItem>
                          </LinkContainer>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    {global.$loginstatus.includes(true) ? (
                      <div>
                        {menu.includes("attendees") ? (
                          <LinkContainer
                            activeStyle={{
                              fontWeight: "bold",
                              color: global.$header.menuactivecolor,
                            }}
                            style={{ color: this.state.menutextcolor }}
                            to="/Delegateslist"
                            className="attendees menu-item"
                          >
                            <NavItem
                              className="navitem"
                              onClick={this.toggleMenu}
                            >
                              ATTENDEES
                            </NavItem>
                          </LinkContainer>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    {menu.includes("contact") ? (
                      global.$header.menu.contact != null ? (
                        <LinkContainer
                          activeStyle={{
                            fontWeight: "bold",
                            color: global.$header.menuactivecolor,
                          }}
                          style={{ color: this.state.menutextcolor }}
                          to={global.$header.menu.contact.link}
                          className="contact menu-item"
                        >
                          <NavItem
                            className="navitem"
                            onClick={this.toggleMenu}
                          >
                            {global.$header.menu.contact.title}
                          </NavItem>
                        </LinkContainer>
                      ) : (
                        <LinkContainer
                          activeStyle={{
                            fontWeight: "bold",
                            color: global.$header.menuactivecolor,
                          }}
                          style={{ color: this.state.menutextcolor }}
                          to="/Contact"
                          className="contact menu-item"
                        >
                          <NavItem
                            className="navitem"
                            onClick={this.toggleMenu}
                          >
                            CONTACT
                          </NavItem>
                        </LinkContainer>
                      )
                    ) : (
                      ""
                    )}

                    {menu.includes("menu9") ? (
                      <div>
                        {global.$header.menu.menu9.login.includes("Y") ? (
                          <div>
                            {global.$loginstatus.includes("true") ? (
                              global.$header.menu.menu9.enablesearch != null &&
                                global.$header.menu.menu9.enablesearch == true ? (
                                global.$attendeeDetails[
                                  `${global.$header.menu.menu9.searchfield}`
                                ].includes(
                                  `${global.$header.menu.menu9.searchkeyword}`
                                ) == true ? (
                                  <LinkContainer
                                    activeStyle={{
                                      fontWeight: "bold",
                                      color: global.$header.menuactivecolor,
                                    }}
                                    style={{ color: this.state.menutextcolor }}
                                    to={global.$header.menu.menu9.link}
                                    className="menu-item"
                                  >
                                    <NavItem
                                      className="navitem text-nowrap"
                                      onClick={this.toggleMenu}
                                    >
                                      {global.$header.menu.menu9.title}
                                    </NavItem>
                                  </LinkContainer>
                                ) : (
                                  ""
                                )
                              ) : (
                                <LinkContainer
                                  activeStyle={{
                                    fontWeight: "bold",
                                    color: global.$header.menuactivecolor,
                                  }}
                                  style={{ color: this.state.menutextcolor }}
                                  to={global.$header.menu.menu9.link}
                                  className="menu-item"
                                >
                                  <NavItem
                                    className="navitem text-nowrap"
                                    onClick={this.toggleMenu}
                                  >
                                    {global.$header.menu.menu9.title}
                                  </NavItem>
                                </LinkContainer>
                              )
                            ) : (
                              <div>
                                {global.$header.menu.menu9.showmenubeforloginandredirecttologin.includes(
                                  "Y"
                                ) ? (
                                  <LinkContainer
                                    activeStyle={{
                                      fontWeight: "bold",
                                      color: global.$header.menuactivecolor,
                                    }}
                                    style={{ color: this.state.menutextcolor }}
                                    to="/LoginForm"
                                    className="menu-item"
                                  >
                                    <NavItem
                                      className="navitem text-nowrap"
                                      onClick={this.toggleMenu}
                                    >
                                      {global.$header.menu.menu9.title}
                                    </NavItem>
                                  </LinkContainer>
                                ) : (
                                  ""
                                )}
                              </div>
                            )}
                          </div>
                        ) : (
                          <LinkContainer
                            activeStyle={{
                              fontWeight: "bold",
                              color: global.$header.menuactivecolor,
                            }}
                            style={{ color: this.state.menutextcolor }}
                            to={global.$header.menu.menu9.link}
                            className="menu-item"
                          >
                            <NavItem
                              className="navitem text-nowrap"
                              onClick={this.toggleMenu}
                            >
                              {global.$header.menu.menu9.title}
                            </NavItem>
                          </LinkContainer>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    {menu.includes("menu10") ? (
                      <div>
                        {global.$header.menu.menu10.login.includes("Y") ? (
                          <div>
                            {global.$loginstatus.includes("true") ? (
                              global.$header.menu.menu10.enablesearch != null &&
                                global.$header.menu.menu10.enablesearch == true ? (
                                global.$attendeeDetails[
                                  `${global.$header.menu.menu10.searchfield}`
                                ].includes(
                                  `${global.$header.menu.menu10.searchkeyword}`
                                ) == true ? (
                                  <LinkContainer
                                    activeStyle={{
                                      fontWeight: "bold",
                                      color: global.$header.menuactivecolor,
                                    }}
                                    style={{ color: this.state.menutextcolor }}
                                    to={global.$header.menu.menu10.link}
                                    className="menu-item"
                                  >
                                    <NavItem
                                      className="navitem text-nowrap"
                                      onClick={this.toggleMenu}
                                    >
                                      {global.$header.menu.menu10.title}
                                    </NavItem>
                                  </LinkContainer>
                                ) : (
                                  ""
                                )
                              ) : (
                                <LinkContainer
                                  activeStyle={{
                                    fontWeight: "bold",
                                    color: global.$header.menuactivecolor,
                                  }}
                                  style={{ color: this.state.menutextcolor }}
                                  to={global.$header.menu.menu10.link}
                                  className="menu-item"
                                >
                                  <NavItem
                                    className="navitem text-nowrap"
                                    onClick={this.toggleMenu}
                                  >
                                    {global.$header.menu.menu10.title}
                                  </NavItem>
                                </LinkContainer>
                              )
                            ) : (
                              <div>
                                {global.$header.menu.menu10.showmenubeforloginandredirecttologin.includes(
                                  "Y"
                                ) ? (
                                  <LinkContainer
                                    activeStyle={{
                                      fontWeight: "bold",
                                      color: global.$header.menuactivecolor,
                                    }}
                                    style={{ color: this.state.menutextcolor }}
                                    to="/LoginForm"
                                    className="menu-item"
                                  >
                                    <NavItem
                                      className="navitem text-nowrap"
                                      onClick={this.toggleMenu}
                                    >
                                      {global.$header.menu.menu10.title}
                                    </NavItem>
                                  </LinkContainer>
                                ) : (
                                  ""
                                )}
                              </div>
                            )}
                          </div>
                        ) : (
                          <LinkContainer
                            activeStyle={{
                              fontWeight: "bold",
                              color: global.$header.menuactivecolor,
                            }}
                            style={{ color: this.state.menutextcolor }}
                            to={global.$header.menu.menu10.link}
                            className="menu-item"
                          >
                            <NavItem
                              className="navitem text-nowrap"
                              onClick={this.toggleMenu}
                            >
                              {global.$header.menu.menu10.title}
                            </NavItem>
                          </LinkContainer>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    {menu.includes("registerexternal") ? (
                      <a
                        target="_blank"
                        className="contact menu-item"
                        style={this.state.registerexternalstyle}
                        href={global.$header.menu.registerexternal.externallink}
                      >
                        {global.$header.menu.registerexternal.title}
                      </a>
                    ) : (
                      ""
                    )}
                    {menu.includes("registration") ? (
                      /*<a target="_blank" className="btn btn-warning" style={{background: global.$registration.bgcolor}} href={global.$registration.url}>Registration</a>*/
                      <Row>
                        <Col>
                          <LinkContainer
                            activeStyle={{
                              fontWeight: "bold",
                              color: global.$header.menuactivecolor,
                            }}
                            style={{ color: this.state.menutextcolor }}
                            style={this.state.registerstyles}
                            to="/Registrationform"
                            className="login menu-item"
                          >
                            <NavItem onClick={this.toggleMenu}>
                              {global.$header.register.title}
                            </NavItem>
                          </LinkContainer>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                    {menu.includes("register2") ? (
                      <div>
                        {global.$header.menu.register2.hideafterlogin != null &&
                          global.$header.menu.register2.hideafterlogin == true ? (
                          <div>
                            {global.$loginstatus.includes("true") ? (
                              ""
                            ) : (
                              <Row>
                                <Col>
                                  {global.$userstype == "Guest" ? (
                                    ""
                                  ) : (
                                    <LinkContainer
                                      activeStyle={{
                                        fontWeight: "bold",
                                        color: global.$header.menuactivecolor,
                                      }}
                                      style={{
                                        color: this.state.menutextcolor,
                                      }}
                                      style={
                                        global.$header.menu.register2.styles
                                      }
                                      to={global.$header.menu.register2.link}
                                      className=" register-menu menu-item"
                                    >
                                      <NavItem onClick={this.toggleMenu}>
                                        {global.$header.menu.register2.title}
                                      </NavItem>
                                    </LinkContainer>
                                  )}
                                </Col>
                              </Row>
                            )}
                          </div>
                        ) : (
                          <div>
                            {global.$loginstatus.includes("true") ? (
                              <Row>
                                <Col>
                                  {global.$userstype == "Guest" ? (
                                    ""
                                  ) : (
                                    <LinkContainer
                                      activeStyle={{
                                        fontWeight: "bold",
                                        color: global.$header.menuactivecolor,
                                      }}
                                      style={{
                                        color: this.state.menutextcolor,
                                      }}
                                      style={
                                        global.$header.menu.register2.styles
                                      }
                                      to={global.$header.menu.register2.link}
                                      className=" register-menu menu-item"
                                    >
                                      <NavItem onClick={this.toggleMenu}>
                                        {
                                          global.$header.menu.register2.titleafterlogin != null ?
                                            (
                                              `${global.$header.menu.register2.titleafterlogin}`
                                            )
                                            :
                                            (
                                              'My Registration'
                                            )
                                        }
                                      </NavItem>
                                    </LinkContainer>
                                  )}
                                </Col>
                              </Row>
                            ) : (
                              <LinkContainer
                                activeStyle={{
                                  fontWeight: "bold",
                                  color: global.$header.menuactivecolor,
                                }}
                                style={{ color: this.state.menutextcolor }}
                                style={global.$header.menu.register2.styles}
                                to={global.$header.menu.register2.link}
                                className=" register-menu menu-item"
                              >
                                <NavItem onClick={this.toggleMenu}>
                                  {global.$header.menu.register2.title}
                                </NavItem>
                              </LinkContainer>
                            )}
                          </div>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    {menu.includes("notifications") ? (
                      <div>
                        {global.$loginstatus.includes("true") ? (
                          <div className="notifications-menu">
                            <Notifications />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    {menu.includes("chatnotifications") ? (
                      <div>
                        {global.$loginstatus.includes("true") ? (
                          <div className="chat-notifications-menu">
                            <AttendeeChatNotifications />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    {menu.includes("login") ? (
                      <div>
                        {global.$loginstatus.includes(false) ? (
                          <Row>
                            <Col>
                              <LinkContainer
                                activeStyle={{
                                  fontWeight: "bold",
                                  color: global.$header.menuactivecolor,
                                }}
                                style={{ color: this.state.menutextcolor }}
                                style={this.state.loginstyles}
                                to="/LoginForm"
                                className="login login-menu menu-item"
                              >
                                <NavItem
                                  className="text-nowrap"
                                  onClick={this.toggleMenu}
                                >
                                  {global.$header.login.title}
                                </NavItem>
                              </LinkContainer>
                            </Col>
                          </Row>
                        ) : (
                          <Row>
                            <Col>
                              <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle
                                  nav
                                  className="nav-dropdown"
                                  activeStyle={{
                                    fontWeight: "bold",
                                    color: "gray",
                                  }}
                                >
                                  {/*<i className='fas fa-user-circle' style={this.state.profileiconstyles}></i>*/}
                                  <div
                                    style={this.state.profileiconstyles}
                                    className="profile-icon"
                                  >
                                    <Icon
                                      path={mdiAccountCircle}
                                      size={1.5}
                                      className="menubar-icons"
                                    />
                                  </div>
                                </DropdownToggle>
                                <DropdownMenu right>
                                  <DropdownItem>
                                    {this.state.userphoto.includes(
                                      "common_profile"
                                    ) ? (
                                      <div className="menu-avatar-div">
                                        {/*<img className="profile-image offset-md-1" src={'https://s3.amazonaws.com/event360live/public/' + global.$code + '/attendees/common_profile.png'} />*/}
                                        <Avatar
                                          className="menu-avatar"
                                          name={`${global.$fname +
                                            " " +
                                            global.$lname}`}
                                        />
                                      </div>
                                    ) : (
                                      <div className="menu-avatar-div">
                                        {global.$imageUrl.includes(
                                          "common_profile"
                                        ) ||
                                          global.$imageUrl == "-1" ||
                                          global.$imageUrl == "" ||
                                          global.$imageUrl == " " ? (
                                          <Avatar
                                            className="menu-avatar"
                                            name={`${global.$fname +
                                              " " +
                                              global.$lname}`}
                                          />
                                        ) : (
                                          <img
                                            src={global.$imageUrl}
                                            style={this.state.imagestyles}
                                          />
                                        )}
                                      </div>
                                    )}
                                    <br />
                                    <p className="menu-profile-name">
                                      <b>{`${global.$fname} ${global.$lname}`}</b>
                                    </p>
                                    <br />
                                    <br />
                                    <LinkContainer
                                      to="/ProfileTabs"
                                      className="edit-profile"
                                    >
                                      <NavItem
                                        className="text-nowrap"
                                        onClick={this.toggleMenu}
                                      >
                                        <Button className="btn btn-primary">
                                          Profile
                                        </Button>
                                      </NavItem>
                                    </LinkContainer>
                                  </DropdownItem>
                                  <hr />
                                  <DropdownItem>
                                    <LinkContainer
                                      activeStyle={{
                                        fontWeight: "bold",
                                        color: global.$header.menuactivecolor,
                                      }}
                                      style={{
                                        color: this.state.menutextcolor,
                                      }}
                                      style={this.state.loginstyles}
                                      to="/LogInForm"
                                      className="login menu-item"
                                    >
                                      <NavItem onClick={this.handleLogout}>
                                        <i
                                          class="fas fa-sign-out-alt"
                                          style={{ paddingRight: "10px" }}
                                        ></i>
                                        Logout
                                      </NavItem>
                                    </LinkContainer>
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </Col>
                          </Row>
                        )}
                      </div>
                    ) : (
                      ""
                    )
                    
                    }
                  </Nav>
                </Collapse>
              </Navbar>
              <Switch>
                <Route
                  path="/Notifications"
                  render={(props) => <Notifications {...props} />}
                />
              </Switch>
              <Switch>
                <Route
                  path="/ProfileTabs"
                  render={(props) => <ProfileTabs {...props} />}
                />
              </Switch>
              <Switch>
                <Route path="/Html1" render={(props) => <Html1 {...props} />} />
              </Switch>
              <Switch>
                <Route path="/Html2" render={(props) => <Html2 {...props} />} />
              </Switch>
              <Switch>
                <Route path="/Html3" render={(props) => <Html3 {...props} />} />
              </Switch>
              <Switch>
                <Route path="/Html4" render={(props) => <Html4 {...props} />} />
              </Switch>
              <Switch>
                <Route path="/Html5" render={(props) => <Html5 {...props} />} />
              </Switch>
              <Switch>
                <Route path="/Html6" render={(props) => <Html6 {...props} />} />
              </Switch>
              <Switch>
                <Route path="/Menu1" render={(props) => <Menu1 {...props} />} />
              </Switch>
              <Switch>
                <Route path="/Menu2" render={(props) => <Menu2 {...props} />} />
              </Switch>
              <Switch>
                <Route path="/Menu3" render={(props) => <Menu3 {...props} />} />
              </Switch>
              <Switch>
                <Route path="/Menu4" render={(props) => <Menu4 {...props} />} />
              </Switch>
              <Switch>
                <Route path="/Menu5" render={(props) => <Menu5 {...props} />} />
              </Switch>
              <Switch>
                <Route
                  path="/Home"
                  render={(props) => (
                    <Home
                      {...props}
                      s3={this.state.s3}
                      code={this.state.code}
                      bucket={this.state.bucket}
                      onMenuReload={this.menuReloadHandler}
                    />
                  )}
                />
              </Switch>
              <Switch>
                <Route
                  path="/LoginIntro"
                  render={(props) => (
                    <LoginIntro
                      {...props}
                      s3={this.state.s3}
                      code={this.state.code}
                      bucket={this.state.bucket}
                      openpopup={true}
                    />
                  )}
                />
              </Switch>
             

              <Route
                path="/Speakers"
                render={(props) => (
                  <Speakers
                    {...props}
                    s3={this.state.s3}
                    code={this.state.code}
                    bucket={this.state.s3bucket}
                    onchange={this.handleChange}
                  />
                  // <Speaker_2 
                  // {...props}
                  //   s3={this.state.s3}
                  //   code={this.state.code}
                  //   bucket={this.state.s3bucket}
                  //   onchange={this.handleChange}
                  // />
                )}
              />
              
              <Route
                path="/SpeakerInfo/:value"
                render={(props) => (
                  <SpeakerInfo
                    {...props}
                    s3={this.state.s3}
                    code={this.state.code}
                    bucket={this.state.s3bucket}
                    onchange={this.handleChange}
                  />
                )}
              />

              <Route
                path="/Speakers2"
                render={(props) => (
                  <Speakers2
                    {...props}
                    s3={this.state.s3}
                    code={this.state.code}
                    bucket={this.state.s3bucket}
                    onchange={this.handleChange}
                  />
                )}
              />

              <Route
                path="/Speakers3"
                render={(props) => (
                  <Speakers3
                    {...props}
                    s3={this.state.s3}
                    code={this.state.code}
                    bucket={this.state.s3bucket}
                    onchange={this.handleChange}
                  />
                )}
              />

              <Route
                path="/SpeakerInfo2/:value"
                render={(props) => (
                  <SpeakerInfo2
                    {...props}
                    s3={this.state.s3}
                    code={this.state.code}
                    bucket={this.state.s3bucket}
                    onchange={this.handleChange}
                  />
                )}
              />
              <Route
                path="/Schedule"
                render={(props) => <Schedule {...props} type={"schedule"} />}
              />

              <Route
                path="/Posters"
                render={(props) => <Schedule {...props} type={"posters"} />}
              />

              <Route
                path="/SessionInfo/:value"
                render={(props) => <SessionInfo {...props} />}
              />

              <Route
                path="/SessionInfo2/:value"
                render={(props) => <SessionInfo2 {...props} />}
              />
              <Switch>
                <Route
                  path="/Exhibitors"
                  render={(props) => (
                    <Sponsors
                      {...props}
                      s3={this.state.s3}
                      code={this.state.code}
                      bucket={this.state.bucket}
                      type={"exhibitors"}
                    />
                  )}
                />
              </Switch>
              <Switch>
                <Route
                  path="/Sponsors"
                  render={(props) => (
                    <Sponsors
                      {...props}
                      s3={this.state.s3}
                      code={this.state.code}
                      bucket={this.state.bucket}
                      type={"sponsors"}
                    />
                  )}
                />
              </Switch>
              <Switch>
                <Route
                  path="/SponsorsVertical"
                  render={(props) => (
                    <sponsorsVertical
                      {...props}
                      s3={this.state.s3}
                      code={this.state.code}
                      bucket={this.state.bucket}
                    />
                  )}
                />
              </Switch>
              <Switch>
                <Route
                  path="/SponsorsByCategory"
                  render={(props) => (
                    <SponsorsByCategory
                      {...props}
                      s3={this.state.s3}
                      code={this.state.code}
                      bucket={this.state.bucket}
                    />
                  )}
                />
              </Switch>
              <Switch>
                <Route
                  path="/Auctions"
                  render={(props) => (
                    <Auctions
                      {...props}
                      s3={this.state.s3}
                      code={this.state.code}
                      bucket={this.state.bucket}
                    />
                  )}
                />
              </Switch>
              <Switch>
                <Route
                  path="/Delegateslist"
                  render={(props) => (
                    <Delegateslist {...props} code={this.state.code} />
                  )}
                />
              </Switch>
              <Switch>
                <Route
                  path="/Delegateslist2"
                  render={(props) => (
                    <Delegateslist2 {...props} code={this.state.code} />
                  )}
                />
              </Switch>
              <Switch>
                <Route
                  path="/Contact"
                  render={(props) => (
                    <Contact
                      {...props}
                      s3={this.state.s3}
                      code={this.state.code}
                    />
                  )}
                />
              </Switch>
              <Switch>
                <Route
                  path="/MeetupsHome"
                  render={(props) => (
                    <MeetupsHome
                      {...props}
                      s3={this.state.s3}
                      code={this.state.code}
                    />
                  )}
                />
              </Switch>

              <Route
                path="/LoginForm"
                render={(props) => (
                  <LoginForm {...props} onMenuReload={this.menuReloadHandler} />
                )}
              />

              <Switch>
                <Route
                  path="/Registrationform"
                  render={(props) => <Registrationform {...props} />}
                />
              </Switch>

              <Switch>
                <Route
                  path="/RegistrationHome"
                  render={(props) => <RegistrationHome {...props} />}
                />
              </Switch>

              <Switch>
                <Route
                  path="/ProfilePage"
                  render={(props) => <ProfilePage {...props} />}
                />
              </Switch>

              <Switch>
                <Route
                  path="/SpeakerProfile"
                  render={(props) => <SpeakerProfile {...props} />}
                />
              </Switch>

              <Switch>
                <Route
                  path="/LoadExternalLinks/:value"
                  render={(props) => <LoadExternalLinks {...props} />}
                />
              </Switch>

              <Switch>
                <Route
                  path="/Evaluation/:value"
                  render={(props) => <Evaluation {...props} />}
                />
              </Switch>

              <Switch>
                <Route
                  path="/SponsorInfo/:value"
                  render={(props) => <SponsorInfo {...props} />}
                />
              </Switch>

              <Switch>
                <Route
                  path="/SessionTracks/:value"
                  render={(props) => <SessionTracks {...props} />}
                />
              </Switch>

              <Switch>
                <Route
                  path="/Conversations"
                  render={(props) => (
                    <Conversations
                      {...props}
                      s3={this.state.s3}
                      code={this.state.code}
                      receiverid="public"
                    />
                  )}
                />
              </Switch>

              <Switch>
                <Route
                  path="/VirtualExpoRoom"
                  render={(props) => (
                    <VirtualExpoRoom
                      {...props}
                      s3={this.state.s3}
                      code={this.state.code}
                      bucket={this.state.bucket}
                    />
                  )}
                />
              </Switch>

              <Switch>
                <Route
                  path="/VirtualExpoRoom1"
                  render={(props) => (
                    <VirtualExpoRoom
                      {...props}
                      s3={this.state.s3}
                      code={this.state.code}
                      bucket={this.state.bucket}
                      tag={"room1"}
                    />
                  )}
                />
              </Switch>

              <Switch>
                <Route
                  path="/VirtualExpoRoom2"
                  render={(props) => (
                    <VirtualExpoRoom
                      {...props}
                      s3={this.state.s3}
                      code={this.state.code}
                      bucket={this.state.bucket}
                      tag={"room2"}
                    />
                  )}
                />
              </Switch>

              <Switch>
                <Route
                  path="/VirtualExpoMultiRooms"
                  render={(props) => (
                    <VirtualExpoMultiRooms
                      {...props}
                      s3={this.state.s3}
                      code={this.state.code}
                      bucket={this.state.bucket}
                    />
                  )}
                />
              </Switch>

              <Switch>
                <Route
                  path="/VirtualExpoHall"
                  render={(props) => (
                    <VirtualExpoHall
                      {...props}
                      s3={this.state.s3}
                      code={this.state.code}
                      bucket={this.state.bucket}
                    />
                  )}
                />
              </Switch>

              <Switch>
                <Route
                  path="/ExhibitorSubmitForm"
                  render={(props) => (
                    <ExhibitorSubmitForm
                      {...props}
                      s3={this.state.s3}
                      code={this.state.code}
                      bucket={this.state.bucket}
                    />
                  )}
                />
              </Switch>

              <Switch>
                <Route
                  path="/SponsorSubmitForm"
                  render={(props) => (
                    <SponsorSubmitForm
                      {...props}
                      s3={this.state.s3}
                      code={this.state.code}
                      bucket={this.state.bucket}
                    />
                  )}
                />
              </Switch>

              <Switch>
                <Route
                  path="/Representative"
                  render={(props) => (
                    <RepresentativeList
                      {...props}
                      s3={this.state.s3}
                      code={this.state.code}
                      bucket={this.state.bucket}
                    />
                  )}
                />
              </Switch>

              <Switch>
                <Route
                  path="/RepresentativeEditor/:userid"
                  render={(props) => (
                    <RepresentativeEditor
                      {...props}
                      s3={this.state.s3}
                      code={this.state.code}
                      bucket={this.state.bucket}
                    />
                  )}
                />
              </Switch>

              <Switch>
                <Route
                  path="/LoginHome"
                  render={(props) => (
                    <SucessfullLogin
                      {...props}
                      s3={this.state.s3}
                      code={this.state.code}
                      bucket={this.state.bucket}
                    />
                  )}
                />
              </Switch>
              {
                global.$code == "aslatx2022" || global.$code == "travcon2022"?
                (
                  <Redirect to="/RegistrationHome" />
                )
                :
                (
                  <Redirect to="/Home" />
                )
              }
              {/* {
                global.$code === "travcon2022" && global.$homepage === "exhibitors" ? <Redirect to={'/Exhibitors'} /> : 
                global.$code === "travcon2022" && global.$homepage === "sponsors" ? <Redirect to={'/Sponsors'} /> : 
                global.$code === "travcon2022" && global.$homepage === "schedule" ? <Redirect to={'/Schedule'} /> : 
                global.$code === "travcon2022" && global.$homepage === "registration" ? <Redirect to={'/RegistrationHome'} /> : ""
                global.$code === "travcon2022" && global.$homepage === "login" ? <Redirect to={'/LoginForm'} /> : ""
              } */}
              
              
              {
                global.$homepage === "exhibitors" ? <Redirect to={'/Exhibitors'} /> : 
                global.$homepage === "sponsors" ? <Redirect to={'/Sponsors'} /> : 
                global.$homepage === "schedule" ? <Redirect to={'/Schedule'} /> : 
                global.$homepage === "registration" ? <Redirect to={'/RegistrationHome'} /> : 
                global.$homepage === "login" ? <Redirect to={'/LoginForm'} /> :
                global.$homepage === "speakers" ? <Redirect to={'/Speakers'} /> : ""
              }


              

              <footer style={this.state.footerstyle}>
                <div>
                  {global.$footer.numberofcols.includes("2") ? (
                    <Row>
                      <Col md={5} className="offset-md-1">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: global.$footer.col1,
                          }}
                        ></div>
                      </Col>
                      <Col md={5}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: global.$footer.col2,
                          }}
                        ></div>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  {global.$footer.numberofcols.includes("3") ? (
                    <Row>
                      <Col md={3} className="offset-md-1">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: global.$footer.col1,
                          }}
                        ></div>
                      </Col>
                      <Col md={4}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: global.$footer.col2,
                          }}
                        ></div>
                      </Col>
                      <Col md={4}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: global.$footer.col3,
                          }}
                        ></div>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  {global.$contact.footer != null &&
                    global.$contact.footer.enable == true ? (
                    <FooterContactForm />
                  ) : (
                    ""
                  )}
                  <hr style={this.state.hrstyle} />
                  <Row>
                    <Col md={6}>
                      <div className="copyright offset-md-2">
                        {ReactHtmlParser(global.$footer.copyright)}
                      </div>
                    </Col>
                    <Col md={2}>
                      <div>
                        <p style={this.state.versionstyle}>Version:9.9</p>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="social-icons offset-md-5">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: global.$footer.socialicons,
                          }}
                        ></div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </footer>
              <ScrollToTop
                color={global.$scrolltotop.color}
                style={this.state.scrollerstyles}
              />
            </div>
          </Router>
        );
      });
    } else {
      if (global.$arr != null) {
        this.setState({ dataloaded: true });
      } else {
        //this.getNotfications();
        //console.log("getconfig");
        this.getConfig();
      }
      return <div></div>;
    }
  }

  getConfig() {
    //console.log("MenuBar: getConfig");

    let apiName = serviceNames.getWebsiteService();
    let path = ""; //replace this with the path you have configured on your API
    let myInit = {
      body: {
        code: global.$code,
        command: "getWebConfig",
        bucket: global.$s3bucket,
      },
      response: true,
      convertEmptyValues: true,
      headers: {},
    };
    //console.log(myInit);

    API.post(apiName, path, myInit)
      .then((response) => {
        //console.log ("response.data ===> " + response.data + "response.err ===> " + response.err);

        //console.log(response);
        //console.log(response.data);
        //console.log(response.data.Body);

        if (response.err == null && response.data != null) {
          let buf = Buffer.from(response.data.Body);
          //console.log(buf)
          //console.log(JSON.parse(buf.toString()));
          var arr = JSON.parse(buf .toString());
          //console.log(arr);
          //var obj = buf.toString();
          global.$config = arr;
          //console.log(arr[code]);
          global.$menuItems = arr["header"].menus;
          global.$header = arr["header"];
          global.$home = arr["home"];
          global.$footer = arr["footer"];
          global.$login = arr["login"];
          global.$schedule = arr["schedule"];
          global.$posters = arr["posters"];
          global.$session = arr["session"];
          global.$settings = arr["settings"];
          global.$sponsors = arr["sponsors"];
          global.$exhibitors = arr["exhibitors"];
          global.$speakers = arr["speakers"];
          global.$contact = arr["contact"];
          global.$registration = arr["registration"];
          global.$login = arr["login"];
          global.$profile = arr["profile"];
          global.$scrolltotop = arr["scrolltotop"];
          global.$numberofdays = arr["schedule"].numberofdays;
          global.$numberofposterdays = arr["posters"].numberofdays;
          global.$attendees = arr["attendees"];
          global.$auctions = arr["auctions"];
          global.$chat = arr["chat"];
          global.$conversations = arr["conversations"];
          global.$virtualexpo = arr["virtualexpo"];
          if (arr["registrationform"] == null) {
            global.$registrationform = "null";
          } else {
            global.$registrationform = arr["registrationform"];
          }
          if (arr["messages"] == null) {
            global.$messages = "null";
          } else {
            global.$messages = arr["messages"];
          }
          if (arr["appointments"] == null) {
            global.$appointments = "null";
          } else {
            global.$appointments = arr["appointments"];
          }
          //console.log(global.$messages);

          if (arr["speakers2"] == null) {
            global.$speakers2 = "null";
          } else {
            global.$speakers2 = arr["speakers2"];
          }

          if (arr["points"] == null) {
            global.$points = "null";
          } else {
            global.$points = arr["points"];
          }
          //console.log(arr["header"]);
          //console.log(arr["header"].menutextcolor);
          if (arr["header"].menutextcolor == null) {
            //console.log("default");
            this.setState({ menutextcolor: "white" });
          } else {
            //console.log("added");
            this.setState({ menutextcolor: arr["header"].menutextcolor });
          }

          if (arr["home"].sponsors.layout == null) {
            //console.log("default");
            global.$homesponsorslayout = "horizantal";
          } else {
            //console.log(arr["home"].sponsors.layout);
            global.$homesponsorslayout = arr["home"].sponsors.layout;
          }

          if (arr["home"].styles.profileicon == null) {
            //console.log("default");
            this.setState({
              profileiconstyles: {
                paddingRight: "10px",
                fontSize: "25px",
                color: "white",
              },
            });
          } else {
            //console.log(arr["home"].styles.profileicon);
            this.setState({
              profileiconstyles: arr["home"].styles.profileicon,
            });
          }

          if (arr["header"].menu.dropdown1 == null) {
            global.$dropdown1 = "";
            this.setState({ dropdown1login: "N" });
          } else {
            //console.log(arr["header"].menu.dropdown1);
            global.$dropdown1 = arr["header"].menu.dropdown1;
            this.setState({
              dropdown1login: arr["header"].menu.dropdown1.login,
            });
          }

          if (arr["header"].menu.dropdown2 == null) {
            global.$dropdown2 = "";
            this.setState({ dropdown2login: "N" });
          } else {
            //console.log(arr["header"].menu.dropdown2);
            global.$dropdown2 = arr["header"].menu.dropdown2;
            this.setState({
              dropdown2login: arr["header"].menu.dropdown2.login,
            });
          }

          if (arr["header"].menu.dropdown3 == null) {
            global.$dropdown3 = "";
            this.setState({ dropdown3login: "N" });
          } else {
            //console.log(arr["header"].menu.dropdown3);
            global.$dropdown3 = arr["header"].menu.dropdown3;
            this.setState({
              dropdown3login: arr["header"].menu.dropdown3.login,
            });
          }

          if (arr["header"].menu.dropdown4 == null) {
            global.$dropdown4 = "";
            this.setState({ dropdown4login: "N" });
          } else {
            //console.log(arr["header"].menu.dropdown4);
            global.$dropdown4 = arr["header"].menu.dropdown4;
            this.setState({
              dropdown4login: arr["header"].menu.dropdown4.login,
            });
          }
          if (arr["header"].menu.dropdown5 == null) {
            global.$dropdown5 = "";
            this.setState({ dropdown5login: "N" });
          } else {
            //console.log(arr["header"].menu.dropdown5);
            global.$dropdown5 = arr["header"].menu.dropdown5;
            this.setState({
              dropdown5login: arr["header"].menu.dropdown5.login,
            });
          }
          //console.log(global.$speakers2);

          global.$activeScheduleTab = global.$schedule.day1text;

          if (arr["schedule"].numberofdays == "1") {
            global.$scheduleday1 = arr["schedule"].day1;
            global.$stag1 = arr["schedule"].stag1;
            global.$day1text = arr["schedule"].day1text;
          }
          if (arr["schedule"].numberofdays == "2") {
            global.$scheduleday1 = arr["schedule"].day1;
            global.$scheduleday2 = arr["schedule"].day2;
            global.$stag1 = arr["schedule"].stag1;
            global.$stag2 = arr["schedule"].stag2;
            global.$day1text = arr["schedule"].day1text;
            global.$day2text = arr["schedule"].day2text;
          }
          if (arr["schedule"].numberofdays == "3") {
            global.$scheduleday1 = arr["schedule"].day1;
            global.$scheduleday2 = arr["schedule"].day2;
            global.$scheduleday3 = arr["schedule"].day3;
            global.$stag1 = arr["schedule"].stag1;
            global.$stag2 = arr["schedule"].stag2;
            global.$stag3 = arr["schedule"].stag3;
            global.$day1text = arr["schedule"].day1text;
            global.$day2text = arr["schedule"].day2text;
            global.$day3text = arr["schedule"].day3text;
          }

          if (arr["posters"].numberofdays == "1") {
            global.$posterstag1 = arr["posters"].stag1;
            global.$posterday1text = arr["posters"].day1text;
          }

          if (arr["posters"].numberofdays == "2") {
            global.$posterstag1 = arr["posters"].stag1;
            global.$posterstag2 = arr["posters"].stag2;
            global.$posterday1text = arr["posters"].day1text;
            global.$posterday2text = arr["posters"].day2text;
          }
          if (arr["posters"].numberofdays == "3") {
            global.$posterstag1 = arr["posters"].stag1;
            global.$posterstag2 = arr["posters"].stag2;
            global.$posterstag3 = arr["posters"].stag3;
            global.$posterday1text = arr["posters"].day1text;
            global.$posterday2text = arr["posters"].day2text;
            global.$posterday3text = arr["posters"].day3text;
          }

          global.$sponsorscrollonsessiondetails =
            arr["session"].sponsorscrollonsessiondetails;
          global.$closedvideo = arr["session"].closedvideo;
          global.$schedulebanner = arr["schedule"].banner;
          global.$poolid = global.$settings.poolids.userpoolid;
          global.$clientid = global.$settings.poolids.userpoolwebclientid;

          this.setState({
            footerstyle: global.$footer.style,
            headerstyle: global.$header.style,
            loginstyles: global.$header.login.styles,
            registerstyles: global.$header.register.styles,
            hrstyle: global.$footer.hrstyle,
            scrollerstyles: global.$scrolltotop.styles,
            versionstyle: global.$footer.version.style,
            imagestyles: global.$profile.photo.imgstyles,
            registerexternalstyle: global.$header.menu.registerexternal.style,
            dataloaded: true,
          });
        } else {
          //console.log("getAttendeesTableObjects - failed!!");
          //console.log(response.err);

          //FIX:  Error condition, we can display standard error page
        }
      })
      .catch((error) => {
        //console.log(error.response)
        // FIX:  Error condition, we can display standard error page
      });
  }
  getNotfications() {
    let apiName = serviceNames.getEventPlannerService();
    // let apiName = "NotificationService";
    let path = ""; //replace this with the path you have configured on your API
    let myInit = {
      body: {
        code: global.$code,
        command: "getNotifications",
        status: "All",
      },
      response: true,
      convertEmptyValues: true,
      headers: {},
    };
    //console.log(myInit);

    API.post(apiName, path, myInit)
      .then((response) => {
        //console.log(notificationslength)
        //console.log(response.data.Count);
        if (response.err == null && response.data != null) {
          if (notificationslength < response.data.Count) {
            var notifications = this.getNotificationsTableObjects(
              response.data.data.Items
            );
          }
          notificationslength = response.data.Count;
        } else {
          //console.log("getNotificationsTableObjects - failed!!");
          //console.log(response.err);
        }
      })
      .catch((error) => {
        //console.log(error)
      });
  }

  getNotificationsTableObjects(objs) {
    //console.log(notificationslength)
    const tableObjs = [];

    for (var i = 0; i < objs.length; i++) {
      var notificationObj = objs[i];

      var newObj = Object();
      if (notificationObj.nstatus.S == "L") {
        newObj.message = notificationObj.message.S;
        newObj.nid = notificationObj.nid.N;
      } else {
        newObj.message = "";
        newObj.nid = "";
      }
      tableObjs.push(newObj);
    }
    //console.log(tableObjs);
    tableObjs.forEach(function (item) {
      //console.log(item);
      data.push({
        update: item.message,
        timestamp: Number(item.nid),
      });
    });
    var obj = {};

    for (
      var i = 0, len = data.length;
      i < len;
      i++ //to remove duplicate objects ina an array
    )
      obj[data[i]["timestamp"]] = data[i];

    data = new Array();

    for (var key in obj) //to remove duplicate objects ina an array
      data.push(obj[key]);

    this.setState({ notifications: data });
    //console.log(this.state.notifications);
    return tableObjs;
  }

  clearGlobalVariables() {
    
    global.$loggedinexhibitor = null
    global.$loginid = null
    global.$password = null
    global.$variables = null
    global.$selectedregoptions = null;
    global.$registereditems = null;
    global.$totalregitems = null;
    global.$attendeeregitems = null;
    global.$guestregitems = null;
    global.$regItemsForSignupCount = null;

    global.$deferredpromo = null;
    global.$deferredpromoamount = null;
    global.$regamount = null;
    global.$finalamount = null;

    global.$hideregisterbutton = null;
    global.$selectedPaymentOption = null;
    global.$regdiscountinfo = null;
    global.$regattendeestatus = null;
    global.$reggueststatus = null;
    global.$regemail = null;
    global.$reguestemail = null;
    global.$regattendeeinfo = null;
    global.$regguestinfo = null;
    global.$loggedinguest = null;
    global.$guestexist = null;
    global.$terms1status = null;
    global.$terms2status = null;
    global.$terms3status = null;
    global.$paymentid = null;
    global.$upgradepricediff = null;

    global.$regitemtoreducecount = null;
    global.$isattendeeregistered = null;
    global.$isguestregistered = null;
    global.$transactionfee = null;
    global.$openpaymentpopup = false; // this will cntrols payment popup
    global.$attendeeDetails = null
    global.$loggedinuser = null
    global.$regloginstatus = null
    global.$regitemsjson = null
    global.$loggedinuserregitems = null
    global.$fname = null;
    global.$lname = null;
    global.$email = null;
    global.$userid = null;
    global.$company = null;
    global.$phone = null;
    global.$title = null;
    global.$regid = null;
    global.$memberid = null;
    global.$attendeetype = null;
    global.$userstype = null;
    global.$regstatus = null;
    global.$attendeeguests = null;
    global.$loggedinuserregitems = null;
    global.$regitemsjson = null;
    global.$loggedinuser = null;
    global.$companyid = null
  }
}
export default MenuBar;
